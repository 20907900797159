import Page from './TreatmentRecommendations.Control';

export default Page;

/**
 *

 Select/Deselect Treatment

    - TestRecommendation.selectTreatment

 Change Recommendation Dosage

    - RecommendedTreatmentMutation.override({
        id:  RecommendedTreatment ID
        dosage
        comment
        instruction
    })

 Reset Recommendation dosage

    - RecommendedTreatmentMitation.reset({id:RecommendedTreatment})


 Remove Manual problem  (Observartion) from Report

    - TestReportMutation.update.manualProblems.delete([ManualProblemID])


 /// IMPORTANT: MeasurementType MUST BE UNIQUE
 Add Manual problem  (Observartion) from Report

    -TestReportMutation.update.manualProblems.assign([ManualProblemID])

 Create  One/Many Sample

    - TestReportMutation.update(reportId).sampleSet.update.samples.create([{
        value // new value
        measurementType: ID!
    }])

 Edit One/Many Sample

    - TestReportMutation.update(reportId).sampleSet.update.samples.update([{
        id: SampleID,
        value // new value
    }])

 Remove One/Many Samples

    - TestReportMutation.update(reportId).sampleSet.update.samples.delete([{
        id: SampleID,
    }])


 Edit Note

    - TestReportMutation.update(reportId).note = newString

 Delete Note:

    - TestReportMutation.update(reportId).note = null



 */
