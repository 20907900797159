import * as React from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { userActions } from './actions';
import { UserFeatureAccess, UserProfile } from './reducers/user';
import { AuthActionProps } from './actions/user';
import { createStructuredSelector } from 'reselect';
import * as fromUser from './reducers/user';
import { bindActionCreators } from 'redux';

export type { AuthActionProps } from './actions/user';

export interface AuthStateProps {
    isAuthenticating: boolean;
    isAuthenticated: boolean;
    isFetchingProfile: boolean;
    isProfileReady: boolean;
    user: UserProfile;
    authError?: string;
    userFeatureAccess: UserFeatureAccess;
    canAccessFeature: (flag: string) => boolean;
    isAdmin: boolean;
}

export const memoizedUserSelector = createStructuredSelector({
    isAuthenticating: fromUser.getIsAuthenticating,
    isAuthenticated: fromUser.getIsAuthenticated,
    isFetchingProfile: fromUser.getIsFetchingProfile,
    isProfileReady: fromUser.getIsProfileReady,
    user: fromUser.getUser,
    authError: fromUser.getAuthError,
    userFeatureAccess: fromUser.getFeatureAccess,
    canAccessFeature: fromUser.canAccessFeature,
    isAdmin: (state: any) => fromUser.canAccessFeature(state)('ADMIN_ACCESS'),
});

export interface ComponentEnhancer<TInner, TOuter> {
    (component: React.ComponentType<TInner>): React.ComponentType<TOuter>;
}

export const connectWithAuth = <P extends any>(): ComponentEnhancer<P & AuthConnectProps, P> =>
    // @ts-ignore
    connect(
        (state: RootStateOrAny) => ({
            AuthState: memoizedUserSelector(state.pwapi),
        }),
        (dispatch) => ({
            AuthAction: bindActionCreators(userActions, dispatch),
        })
    );

export interface AuthConnectProps {
    AuthState: AuthStateProps;
    AuthAction: AuthActionProps;
}
