import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const PoolQuery = gql`
    query QueryPoolFiles($id: ID!) {
        node(id: $id) {
            ... on Pool {
                id
                files(sort: { createdAt: true }) {
                    edges {
                        node {
                            id
                            isPublic
                            note
                            fileName
                            mimeType
                            location
                            createdAt
                            isImage
                            url
                            imageUrl
                            checkMutations {
                                delete
                                update
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useQueryPoolFiles = createUseQueryNodeHook<NodeType.Pool>(PoolQuery);
