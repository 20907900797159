import * as React from 'react';
import { PageLayout, Panel } from '@poolware/components';
import { PageControlProps } from './Page.Control';
import { PoolForm } from '../Components/PoolForm';

class Page extends React.Component<PageControlProps> {
    state = {
        isMutating: false,
    };

    onSubmit = async (formValues) => {
        this.setState({ isMutating: true });
        try {
            const { site } = this.props;
            await this.props.PoolMutator.createPool({
                name: formValues.name,
                volume: formValues.volume,
                type: formValues.type,
                sanitiser: formValues.sanitiser,
                site: site,
                entity: site.entity,
            });
            this.props.AppNavigator.replaceToOrigin();
        } catch (error) {
            console.error('there was an error sending the query', error);
            this.setState({ isMutating: false });
            throw new Error(error);
        }
    };

    onCancel = () => {
        this.props.AppNavigator.replaceToOrigin();
    };

    render() {
        const organisationId = this.props?.site?.entity?.franchise?.organisationType?.id;
        const { site } = this.props;
        const initialValues = {
            name: '',
            volume: '',
            type: null,
            sanitiser: null,
        };

        return (
            <PageLayout>
                <PageLayout.BodySection width={'screen-md'}>
                    <PoolForm
                        header={'New Pool'}
                        icon={'life ring'}
                        onCancel={this.onCancel}
                        onSubmit={this.onSubmit}
                        initialValues={initialValues}
                        organisationId={organisationId}
                        submitButton={{ content: 'Create' }}
                    >
                        <Panel.Item label="Site Name" content={site.name} />
                        <Panel.ItemAddress label="Address" content={site.address} />
                        <Panel.ItemEntity label="Customer" content={site.entity.user} />
                        <Panel.Divider />
                    </PoolForm>
                </PageLayout.BodySection>
            </PageLayout>
        );
    }
}

export default Page;
