import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const PoolQuery = gql`
    query QueryPoolFields($id: ID!) {
        node(id: $id) {
            ... on Pool {
                id
                attachedFlagFields {
                    id
                    value
                    fieldDeclaration {
                        id
                        name
                        priority
                        tag
                        description
                    }
                }
            }
        }
    }
`;

export const useQueryPoolField = createUseQueryNodeHook<NodeType.Pool>(PoolQuery);
