import * as React from 'react';
import {
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import ViewBrand from '../ViewBrand';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';
import { compose } from '@ez/tools';

const BrandViewPageComp: React.FC<IAppNavigatorProps<{ brandId: string }> & IProductCatalogMutators> = (props) => {
    const handleOnDelete = async () => {
        try {
            const { brandId } = props.AppNavigator.params;
            await props.mutateBrand.delete({ id: brandId });
            props.AppNavigator.replace(`/brands`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem
                        icon={'chevron left'}
                        color={'grey'}
                        onClick={() => props.AppNavigator.navigate('/brands', { relativeToModule: true })}
                    >
                        All Brands
                    </MenuBarItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown icon={'wrench'} title="More...">
                        <MenuBarDropdownItemWithConfirm
                            icon={'trash'}
                            color="red"
                            title={'Delete Brand'}
                            confirm={{
                                confirmMessage: {
                                    header: 'Delete Brand?',
                                    content: 'Will be deleted permanently!!!',
                                },
                                confirmButton: {
                                    content: 'Delete Brand',
                                    icon: 'trash',
                                },
                                negative: true,
                            }}
                            name="deleteButton"
                            onClick={handleOnDelete}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>
            <ViewBrand />
        </VStack>
    );
};

export const ViewBrandDetailsPage = compose(
    withAppNavigator(),
    withProductCatalogMutators(['BrandsList', 'QueryBrandNode'])
)(BrandViewPageComp);
