import * as React from 'react';
import { CreateStaffRegistrationInputType } from '@poolware/api';
import { FormikDefaultForm, FormikInputField, FormikSelectField, toastError } from '@poolware/components';
import { PageControlProps } from './Page.Control';
import { useAppNavigator } from '@poolware/react-app-navigator';
// import { generateFakeStaff } from './faker';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    password: Yup.string().min(10).max(64, 'Too Long!').required('Required'),
    role: Yup.mixed().required('Required'),
    firstName: Yup.string().max(100, 'Too Long!').required('Required'),
    lastName: Yup.string().max(100).required('Required'),
    email: Yup.string().email().required('Required'),
});

const StaffEditPage: React.FC<PageControlProps> = (props) => {
    const { AppNavigator } = useAppNavigator();

    const roleOptions = props.roles?.map((node: any) => {
        return { text: node.name, value: node.id };
    });

    let initialValues = {
        role: undefined,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    };

    const onSubmit = async (values: typeof initialValues) => {
        const staffRegConfig: CreateStaffRegistrationInputType = {
            firstName: values.firstName?.trim(),
            lastName: values.lastName?.trim(),
            role: values.role,
            email: values.email?.trim(),
            password: values.password,
            franchise: props.franchise?.id,
        };
        try {
            await props.StaffMutator.createRegistration(staffRegConfig);
            AppNavigator.replaceToOrigin();
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to register staff', description: error.message });
        }
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    // if (isDevelopment) {
    //     initialValues = { ...initialValues, ...generateFakeStaff(props.roles) };
    // }

    return (
        <>
            <FormikDefaultForm
                validationSchema={validationSchema}
                header={'New Staff'}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                submitButton={{ content: 'Register' }}
            >
                <FormikInputField name={'firstName'} label={'First Name'} required={true} />
                <FormikInputField name={'lastName'} label={'Last Name'} required={true} />
                <FormikSelectField options={roleOptions} name={'role'} label={'Role'} required={true} />
                <FormikInputField name={'email'} label={'email'} required={true} />
                <FormikInputField
                    name={'password'}
                    label={'Password'}
                    type={'password'}
                    autoComplete={'new-password'}
                    required={true}
                />
            </FormikDefaultForm>
        </>
    );
};

export default StaffEditPage;
