import gql from 'graphql-tag';
import { addressFragment, customerContactDetailsFragment } from '@poolware/api';

export const QLServiceJobRecur = gql`
    query QueryServiceJob($id: ID!) {
        node(id: $id) {
            id
            ... on ServiceJob {
                id
                archivedAt
                title
                serviceJobNumber
                description
                stage {
                    id
                    title
                    status
                    type
                }
                dueDate
                implicitType
                isImplicit
                stopsAt
                lastAppointmentDate
                createdAt
                updatedAt
                isRecurring
                followedFrom {
                    id
                    title
                    serviceJobNumber
                }
                group {
                    id
                    title
                }
                address {
                    id
                    ...AddressFragment
                }
                customer {
                    id
                    ...CustomerContactDetailsFragment
                }
                staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
                pool {
                    id
                    name
                    bottleNumber
                    volume
                    type {
                        id
                        name
                    }
                    site {
                        id
                        accessKeyLocation
                    }
                    sanitiser {
                        id
                        name
                    }
                }
                createdBy {
                    id
                    user {
                        id
                        firstName
                        lastName
                        staff {
                            id
                        }
                    }
                }
                entity {
                    id
                    franchise {
                        id
                        name
                    }
                }
                usedTemplate {
                    id
                    templateTitle
                    workOrderTemplateAssocs {
                        edges {
                            node {
                                id
                                workOrderTemplate {
                                    id
                                    templateTitle
                                }
                            }
                        }
                    }
                }
                recurrentAppointments {
                    edges {
                        node {
                            id
                            patternBlockId
                            startDate
                            endPatternDate
                            baseInstance {
                                id
                                duration
                                staff {
                                    id
                                    user {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                            recurrence {
                                pattern {
                                    dayOfMonth
                                    interval
                                    type
                                    month
                                    index
                                    daysOfWeek
                                }
                                range {
                                    endDate
                                    numberOfOccurrences
                                    timeZone
                                    interval
                                    type
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${addressFragment}
    ${customerContactDetailsFragment}
`;
