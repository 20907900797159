import * as React from 'react';
import { useState } from 'react';
import invariant from 'invariant';
import { Dropdown, Table } from 'semantic-ui-react';

import { NodeType } from '@poolware/api';
import { getLabelForAppointmentStatus, IconAppointmentStatus, mapConfFromStatusMap } from '../utils';
import styled from 'styled-components';

export interface DropdownStatusChangeProps {
    appointmentItem: NodeType.AppointmentItem;
    onChangeStatus?: (string) => any;
}

export const DropdownStatusChange: React.FC<DropdownStatusChangeProps> = ({ appointmentItem, onChangeStatus }) => {
    const [loading, setLoading] = useState(false);
    invariant(appointmentItem, 'Expected appointment item');
    const { status } = appointmentItem;

    const buttonConfs = mapConfFromStatusMap(status);
    const options = buttonConfs.map((c) => {
        return {
            key: c.value,
            text: c.title,
            value: c.value,
            icon: c.icon,
        };
    });

    const trigger = (
        <span>
            <IconAppointmentStatus status={status} />
            {getLabelForAppointmentStatus(status)}
        </span>
    );

    const handleOnChange = async (e, { value }) => {
        setLoading(true);
        await onChangeStatus(value);
        setLoading(false);
    };

    return (
        <Dropdown
            lazyLoad={true}
            loading={loading}
            trigger={trigger}
            onChange={handleOnChange}
            value={status}
            options={options}
        />
    );
};

export const TrimmedOneLiner = styled.div`
    white-space: nowrap;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TableContainer = styled.div`
    flex: 1 1;
    width: 100%;
    min-height: 0;
    overflow-x: auto;
    padding-bottom: 80px;

    position: relative;

    .table-cont {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        background-color: white;
        //table-layout: fixed;
        position: relative;

        @media print {
            -webkit-print-color-adjust: exact;
        }

        th,
        td {
            padding: 0.125em 0.5em;
            white-space: nowrap;
            //overflow: hidden;
        }

        thead {
            color: white;
            overflow: hidden;
            background-color: #335a7d;
            height: 32px;
            z-index: 2;

            th {
                @media screen {
                    // disable on touch devices
                    position: sticky;
                    z-index: 2;
                    top: 0;
                }

                background-color: #335a7d;
                padding: 0.25em 1em;
                text-align: left;
                top: 0 !important;
                height: 32px;
            }
        }

        tbody {
            tr.empty-row {
                td {
                    font-style: italic;
                    color: #4c5f68;
                }
            }

            tr.section {
                //border-top: none;
                font-size: 1em;

                th {
                    @media screen and (pointer: fine) {
                        // disable on touch devices
                        position: sticky;
                        z-index: 1;
                        top: 31px;
                    }

                    background-color: #e1edfa;
                    padding: 0.25em 1em 0.5em 1em;
                    text-align: left;
                    height: 32px;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 100%;
                    }

                    &:before {
                        top: 0;
                        border-top: 2px solid #bcd0e1;
                    }

                    &:after {
                        bottom: 0;
                        border-bottom: 2px solid #bcd0e1;
                    }

                    .count {
                        width: 32px;
                        display: inline-block;
                        font-size: 0.7em;
                    }
                }
            }

            tr.data {
                font-size: smaller;
                border-bottom: 1px solid #bcd0e1;
                height: 46px !important;

                td {
                    border-bottom: 1px solid #bcd0e1;
                    border-top: 1px solid #bcd0e1;
                    border-right: 1px solid #f5f5f5;
                }

                td:last-child {
                    border-right: none;
                }

                &.expanded,
                &.selected {
                    td {
                        background-color: #fffbeb;
                    }
                }
                &.selected {
                    td {
                        border-top: 2px solid #ec4899;
                        border-bottom: 1px solid #ec4899;
                    }
                }

                &.expanded > td.first {
                    background-color: #d3d5db;
                }

                td.first {
                    text-align: center;
                    cursor: pointer;
                    padding-left: 1em;

                    &:hover {
                        background-color: #dcdfe6;
                    }
                }

                td.time {
                    width: 100px !important;
                    text-align: right;
                }

                td.job {
                    min-width: 120px;
                }

                td.group {
                    .cont {
                        padding: 0.25em;
                        border: 1px solid #bcd0e1;
                        border-left-width: 8px;
                    }
                }
            }

            tr.row-expanded {
                &.invisible {
                    visibility: hidden;
                }

                td {
                    white-space: unset;
                    padding: 0;
                }
            }
        }
    }
`;

export const RowCellStyled = styled(Table.Cell)`
    &&& {
        border-top: 1px solid #bcd0e1;
    }
`;
