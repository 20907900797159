import gql from 'graphql-tag';
import { addressFragment, customerContactDetailsFragment, customerFragment, staffFragment } from '@poolware/api';

export const appointmentItemFragment = gql`
    fragment AppointmentItemFragment_ServiceJob on AppointmentItem {
        id
        startDate
        duration
        status
        isRecurring
        staff {
            ...StaffFragment
        }
        customer {
            ...CustomerFragment
        }
        address {
            ...AddressFragment
        }
        pool {
            id
            name
            bottleNumber
            address {
                ...AddressFragment
            }
        }
        deleted
        note
    }
    ${addressFragment}
    ${staffFragment}
    ${customerFragment}
`;

export const jobTodoFragment = gql`
    fragment JobTodoFragment on JobTodo {
        id
        title
        description
        jobNumber
        index
        price
        status
        timeEstimate
        createdBy {
            id
        }
        workOrder {
            id
        }
        items {
            edges {
                node {
                    id
                    completedAt
                    completedBy {
                        id
                    }
                    status
                    title
                    description
                    orderIndex
                }
            }
        }
    }
`;

export const serviceJobFragmentFull = gql`
    fragment ServiceJobFragment on ServiceJob {
        id
        title
        serviceJobNumber
        description
        stage {
            id
            title
            type
            status
        }
        dueDate
        isRecurring
        address {
            id
            ...AddressFragment
        }
        customer {
            id
            ...CustomerContactDetailsFragment
        }
        pool {
            id
            name
            bottleNumber
            volume
            type {
                id
                name
            }
            site {
                id
                accessKeyLocation
            }
            sanitiser {
                id
                name
            }
        }
        createdBy {
            id
            user {
                id
                firstName
                lastName
                staff {
                    id
                }
            }
        }
        entity {
            id
            franchise {
                id
                name
            }
        }
        createdAt
        updatedAt
    }
    ${addressFragment}
    ${customerContactDetailsFragment}
`;
