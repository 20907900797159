import * as React from 'react';
import { useState } from 'react';
import { Panel, toastError, toastSuccess } from '@poolware/components';
import { IEmailMessageMutators, NodeType, withEmailMessageMutators } from '@poolware/api';
import { Button, Form, Header, Icon, Radio } from 'semantic-ui-react';
import * as validate from 'validate.js';
import styled from 'styled-components';

const ContactLabel = styled.span`
    color: #7e7e7e;
    padding-left: 5px;
`;

const AttachmentBox = styled.div`
    margin-top: 15px;
    //border-top: 1px solid #bababc;
    > div {
        margin: 10px 0;
    }
`;

const formatContactLabel = (contact: NodeType.Contact) => (props) => {
    return (
        <label>
            {contact.data}
            {contact.label && <ContactLabel>({contact.label})</ContactLabel>}
        </label>
    );
};

interface MessagePayload {
    email: string;
    note?: string;
}

const OTHER = 'other';

const SelectContactForm: React.FC<{
    contacts: NodeType.Contact[];
    onChange: (input: Partial<MessagePayload>) => any;
    validationError?: string;
}> = ({ contacts, validationError, onChange }) => {
    const [value, setValue] = useState(null);
    const [newEmail, setNewEmail] = useState('');

    const [note, setNote] = useState(null);

    const handleNoteChange = (e, { value }) => {
        setNote(value);
        onChange({ note: value });
    };

    const handleEmailChange = (e, { value }) => {
        setValue(value);
        if (value === OTHER) {
            onChange({ email: newEmail });
        } else {
            onChange({ email: value });
        }
    };

    const onNewEmail = (e, { value }) => {
        setNewEmail(value);
        onChange({ email: value });
    };

    const EmailLabel = () => (
        <label>Email {validationError && <span style={{ paddingLeft: '0.5rem' }}>{validationError}</span>}</label>
    );

    const showRadioSelect = contacts.length > 0;

    if (!showRadioSelect) {
        return (
            <Form>
                <Form.Input label={'Email to:'} error={!!validationError} value={newEmail} onChange={onNewEmail} />
                <Form.TextArea
                    name="note"
                    label="Message (optional)"
                    type="text"
                    defaultValue={''}
                    value={note}
                    onChange={handleNoteChange}
                />
            </Form>
        );
    }

    return (
        <Form>
            <Header size={'small'}>To:</Header>
            {contacts.map((contact) => {
                return (
                    <Form.Field key={contact.id}>
                        <Radio
                            label={formatContactLabel(contact)}
                            name="radioGroup"
                            value={contact.data}
                            checked={value === contact.data}
                            onChange={handleEmailChange}
                        />
                    </Form.Field>
                );
            })}
            <Form.Field>
                <Radio
                    label={'Other'}
                    name="radioGroup"
                    value={OTHER}
                    checked={OTHER === value}
                    onChange={handleEmailChange}
                />
            </Form.Field>
            {value === OTHER && (
                <Form.Input
                    disabled={value !== OTHER}
                    error={!!validationError}
                    label={EmailLabel}
                    value={newEmail}
                    onChange={onNewEmail}
                />
            )}
            <Form.TextArea
                name="note"
                label="Message (optional)"
                type="text"
                defaultValue={''}
                value={note}
                onChange={handleNoteChange}
            />
        </Form>
    );
};

const validateEmail = (value: string): string | null => {
    const errors = validate.single(value, { presence: true, email: true });
    if (!errors) {
        return undefined;
    } else {
        return errors[0] as string;
    }
};

interface EmailReportPanelProps {
    report: NodeType.TestReport;
    contacts: NodeType.Contact[];
    onCancel: () => any;
    onFinish: () => any;
}
const EmailReportPanel: React.FC<EmailReportPanelProps & IEmailMessageMutators> = ({
    report,
    contacts,
    onCancel,
    onFinish,
    EmailMessageMutator,
}) => {
    const [isDirty, setIsDirty] = useState(false);
    const [validationError, setError] = useState(undefined);
    const [mutating, setMutate] = useState(false);
    const [payload, setPayload] = useState({ email: null } as MessagePayload);

    const onSubmit = async () => {
        const error = validateEmail(payload.email);
        if (error) {
            setError(error);
            return;
        }

        try {
            setMutate(true);
            await EmailMessageMutator.sendTestReport(report, payload.email, payload.note);
            toastSuccess({ title: 'Success!', description: 'Email was successfully sent', icon: 'send' });
            onFinish();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to email report', description: e.message });
        }
        setMutate(false);
    };

    const onChange = (value: Partial<MessagePayload>) => {
        setIsDirty(true);
        setPayload({ ...payload, ...value });
        setError(undefined);
    };

    const emailContacts = contacts.filter((c) => c.type?.validatorTag === 'EMAIL');

    return (
        <Panel>
            <Panel.Header>Email Water Test Report</Panel.Header>
            <Panel.Body>
                <SelectContactForm contacts={emailContacts} onChange={onChange} validationError={validationError} />
                <AttachmentBox>
                    <div>
                        <label>Attachment:</label>
                    </div>
                    <div>
                        <span>
                            <Icon color={'red'} name={'file pdf'} />
                            report.pdf
                        </span>
                    </div>
                </AttachmentBox>
            </Panel.Body>
            <Panel.Footer clearing>
                <Button onClick={onCancel} disabled={mutating} floated={'left'} content={'Cancel'} secondary />
                <Button onClick={onSubmit} disabled={mutating || !isDirty} floated={'right'} loading={mutating} primary>
                    <Icon name={'send'} />
                    Send
                </Button>
            </Panel.Footer>
        </Panel>
    );
};

export default withEmailMessageMutators([])(EmailReportPanel) as React.ComponentType<EmailReportPanelProps>;
