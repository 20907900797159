import { ContentEditableText, Panel, PanelItemProps } from '@poolware/components';
import * as React from 'react';

export interface PanelItemEditableProps extends PanelItemProps {
    content: string;
    editable?: boolean;
    onCommit: (newValue: string) => any;
}
export const PanelItemEditable: React.FC<PanelItemEditableProps> = ({ editable, onCommit, content, ...props }) => {
    return (
        <Panel.Item {...props}>
            <ContentEditableText
                // style={{width: "100%"}}
                editable={editable}
                value={content}
                onCommit={onCommit}
            />
        </Panel.Item>
    );
};
