import { InferProps, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { uncontrollable } from 'uncontrollable';
import clsx from 'clsx';

import { notify } from './utils/helpers';
import { CalNavigate, CalViewMode } from './types';
import { mergeWithDefaults } from './localizer';
import message from './utils/messages';
import moveDate from './utils/move';
import VIEWS from './Views';
import Toolbar from './Toolbar';
import NoopWrapper from './NoopWrapper';

import omit from 'lodash/omit';
import defaults from 'lodash/defaults';
import transform from 'lodash/transform';
import mapValues from 'lodash/mapValues';
import { wrapAccessor } from './utils/accessors';
import { CalendarBaseProps } from './CalendarPropsTypes';
import { CalendarContextValueType, RBCContext } from './CalendarContext';
import { LayoutAlgo } from './utils/DayEventLayout';

function _viewNames(_views) {
    return !Array.isArray(_views) ? Object.keys(_views) : _views;
}

function isValidView(view, conf?: { views?: InferProps<typeof CalViewMode> }) {
    let names = _viewNames(conf?.views);
    return names.indexOf(view) !== -1;
}

/**
 * react-big-calendar is a full featured Calendar component for managing events and dates. It uses
 * modern `flexbox` for layout, making it super responsive and performant. Leaving most of the layout heavy lifting
 * to the browser. __note:__ The default styles use `height: 100%` which means your container must set an explicit
 * height (feel free to adjust the styles to suit your specific needs).
 *
 * Big Calendar is unopiniated about editing and moving events, preferring to let you implement it in a way that makes
 * the most sense to your app. It also tries not to be prescriptive about your event data structures, just tell it
 * how to find the start and end datetimes and you can pass it whatever you want.
 *
 * One thing to note is that, `react-big-calendar` treats event start/end dates as an _exclusive_ range.
 * which means that the event spans up to, but not including, the end date. In the case
 * of displaying events on whole days, end dates are rounded _up_ to the next day. So an
 * event ending on `Apr 8th 12:00:00 am` will not appear on the 8th, whereas one ending
 * on `Apr 8th 12:01:00 am` will. If you want _inclusive_ ranges consider providing a
 * function `endAccessor` that returns the end date + 1 day for those events that end at midnight.
 */
export interface CalendarProps extends CalendarBaseProps {
    style?;
    className?: string;
}

const defaultProps: Partial<CalendarProps> = {
    elementProps: {},
    toolbar: true,
    view: CalViewMode.WEEK,
    views: [CalViewMode.MONTH, CalViewMode.WEEK, CalViewMode.DAY, CalViewMode.AGENDA],
    step: 30,
    length: 30,
    drilldownView: CalViewMode.DAY,
    longPressThreshold: 250,
    getNow: () => new Date(),
    dayLayoutAlgorithm: LayoutAlgo.Overlap,
    rtl: false,
    accessors: {
        title: 'title',
        tooltip: 'title',
        allDay: 'allDay',
        start: 'start',
        end: 'end',
        resource: 'resourceId',
        resourceId: 'id',
        resourceTitle: 'title',
    },
};

const Calendar: React.FC<CalendarProps> = (props) => {
    let {
        drilldownView,
        getDrilldownView,
        view,
        toolbar,
        events,
        style,
        className,
        elementProps,
        date: current,
        getNow,
        length,
        showMultiDayTimes,
        components: _0,
        formats: _1,
        messages: _2,
        culture: _3,
        accessors,
        rtl,
        longPressThreshold,
        localizer,
        views,
        ...rest
    } = props;

    current = current || getNow();

    const getContext = (props: CalendarProps): CalendarContextValueType => {
        const {
            eventPropGetter,
            slotPropGetter,
            slotGroupPropGetter,
            dayPropGetter,
            view,
            views,
            localizer,
            culture,
            messages = {},
            components = {},
            formats = {},
            accessors,
        } = props;
        let names = _viewNames(views);
        const msgs = message(messages);
        return {
            longPressThreshold: props.longPressThreshold,
            rtl: props.rtl,
            viewNames: names,
            localizer: mergeWithDefaults(localizer, culture, formats, msgs),
            culture: props.culture,
            getters: {
                eventProp: (...args) => (eventPropGetter && eventPropGetter(...args)) || {},
                slotProp: (...args) => (slotPropGetter && slotPropGetter(...args)) || {},
                slotGroupProp: (...args) => (slotGroupPropGetter && slotGroupPropGetter(...args)) || {},
                dayProp: (...args) => (dayPropGetter && dayPropGetter(...args)) || {},
            },
            components: defaults(components[view] || {}, omit(components, names), {
                rootViewWrapper: NoopWrapper,
                eventWrapper: NoopWrapper,
                eventContainerWrapper: NoopWrapper,
                dateCellWrapper: NoopWrapper,
                weekWrapper: NoopWrapper,
                timeSlotWrapper: NoopWrapper,
            }),
            accessors: {
                start: wrapAccessor(accessors?.start),
                end: wrapAccessor(accessors?.end),
                allDay: wrapAccessor(accessors?.allDay),
                tooltip: wrapAccessor(accessors?.tooltip),
                title: wrapAccessor(accessors?.title),
                resource: wrapAccessor(accessors?.resource),
                resourceId: wrapAccessor(accessors?.resourceId),
                resourceTitle: wrapAccessor(accessors?.resourceTitle),
            },
        };
    };
    const rbcContext = useMemo(() => getContext(props), [props]);

    // @ts-ignore
    // const slotMetrics = useMemo(() => TimeSlotUtils.getSlotMetrics(props), []);

    const getViews = (): { [key: string]: React.ComponentType } => {
        const views = props.views;
        if (Array.isArray(views)) {
            return transform(views, (obj, name) => (obj[name] = VIEWS[name]), {});
        }

        if (typeof views === 'object') {
            return mapValues(views, (value, key) => {
                if (value === true) {
                    return VIEWS[key];
                }

                return value;
            });
        }

        return VIEWS;
    };
    const getView = (): any => {
        const views = getViews();
        if (props.views) {
            return views[props.view]; // || views[props.views[0]];
        }
    };

    const _getDrilldownView = (date) => {
        if (!getDrilldownView) {
            return drilldownView;
        }
        return getDrilldownView(date, view, Object.keys(getViews()));
    };

    /**
     *
     * @param date
     * @param viewComponent
     * @param {'month'|'week'|'work_week'|'day'|'agenda'} [view] - optional
     * parameter. It appears when range change on view changing. It could be handy
     * when you need to have both: range and view type at once, i.e. for manage rbc
     * state via url
     */
    const handleRangeChange = (date, viewComponent, view?) => {
        let { onRangeChange, localizer } = props;

        if (onRangeChange) {
            if (viewComponent.range) {
                onRangeChange(viewComponent.range(date, { localizer }), view);
            } else {
                if (process.env.NODE_ENV !== 'production') {
                    console.error('onRangeChange prop not supported for this view');
                }
            }
        }
    };

    const handleNavigate = useCallback(
        (action, newDate) => {
            let ViewComponent = getView();
            let today = getNow();
            const _date = moveDate(ViewComponent, {
                ...rest,
                action,
                date: newDate || current || today,
                today,
            });
            props.onNavigate?.(_date, view, action);
            handleRangeChange(_date, ViewComponent);
        },
        [
            props.onNavigate, //
            current,
            getView,
            getNow,
            handleRangeChange,
        ]
    );

    const handleViewChange = useCallback(
        (view) => {
            if (view !== props.view && isValidView(view, props)) {
                props.onView(view);
            }
            let views = getViews();
            handleRangeChange(props.date || props.getNow(), views[view], view);
        },
        [
            props.view, //
            props.date,
            props.onView,
            props.getNow,
            getViews,
            handleRangeChange,
        ]
    );

    const handleSelectEvent = useCallback(
        (...args) => {
            notify(props.onSelectEvent, args);
        },
        [props.onSelectEvent]
    );

    const handleDoubleClickEvent = useCallback(
        (...args) => {
            notify(props.onDoubleClickEvent, args);
        },
        [props.onDoubleClickEvent]
    );

    const handleSelectSlot = useCallback(
        (slotInfo) => {
            notify(props.onSelectSlot, slotInfo);
        },
        [props.onSelectSlot]
    );

    const handleDrillDown = useCallback(
        (date, view) => {
            if (props.onDrillDown) {
                props.onDrillDown(date, view);
                return;
            }

            if (view) {
                handleViewChange(view);
            }
            handleNavigate(CalNavigate.DATE, date);
        },
        [
            props.onDrillDown, //
            props.views,
            handleViewChange,
            handleNavigate,
        ]
    );

    let View = getView();

    let CalToolbar = rbcContext.components.toolbar || Toolbar;
    const label = View.title(current, { localizer: rbcContext.localizer, length });

    return (
        <RBCContext.Provider value={rbcContext}>
            <div {...elementProps} className={clsx(className, 'rbc-calendar', props.rtl && 'rbc-rtl')} style={style}>
                {toolbar && (
                    <div>
                        <CalToolbar
                            date={current}
                            view={view}
                            views={rbcContext.viewNames}
                            label={label}
                            onView={handleViewChange}
                            onNavigate={handleNavigate}
                            localizer={rbcContext.localizer}
                        />
                    </div>
                )}
                <View
                    {...rest}
                    events={events}
                    date={current}
                    getNow={getNow}
                    length={length}
                    showMultiDayTimes={showMultiDayTimes}
                    getDrilldownView={_getDrilldownView}
                    onNavigate={handleNavigate}
                    onDrillDown={handleDrillDown}
                    onSelectEvent={handleSelectEvent}
                    onDoubleClickEvent={handleDoubleClickEvent}
                    onSelectSlot={handleSelectSlot}
                />
            </div>
        </RBCContext.Provider>
    );
};

Calendar.defaultProps = defaultProps;

export default uncontrollable(Calendar, {
    view: 'onView',
    date: 'onNavigate',
    selected: 'onSelectEvent',
});
