import { createUseQueryNodeHook } from '@ez/api-core';
import { NodeType } from '@poolware/api';
import gql from 'graphql-tag';
import { productDocumentFragment } from '../ProductDocs/use-query-product-doc';
import { productTraitFragment } from '../../common/use-query-product-connection';

const QL = gql`
    query ProductDetails_traitsTable($id: ID!) {
        node(id: $id) {
            ... on Product {
                id
                name
                createdAt
                updatedAt
                sku
                customSku
                supplier {
                    id
                    name
                }
                brand {
                    id
                    name
                    company {
                        id
                        name
                    }
                }
                organisationType {
                    id
                    name
                }
                franchise {
                    id
                    name
                }
                documents {
                    edges {
                        node {
                            ...ProductDocumentFragment
                        }
                    }
                }
                traits {
                    ...ProductTraitFragment_Calcs
                    declaration {
                        id
                        id
                        tag
                        name
                        parent {
                            id
                            name
                        }
                        subDeclarations {
                            id
                            name
                        }
                        integerFields {
                            id
                            name
                            unit
                        }
                        floatFields {
                            id
                            name
                            unit
                        }
                        stringFields {
                            id
                            name
                            tag
                        }
                        selectionFields {
                            id
                            name
                            hint
                            tag
                            options {
                                id
                                name
                                priority
                                description
                            }
                        }
                        flagFields {
                            id
                            name
                            tag
                        }

                        parentDeclarations {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
    ${productTraitFragment}
    ${productDocumentFragment}
`;

export const useQueryProduct = createUseQueryNodeHook<NodeType.Product>(QL);
