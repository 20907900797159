import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Icon } from 'semantic-ui-react';
import { ModuleIconNames } from '../../../../../constants';
import { JobTodoDetailItem, JobTodoDetailItemLabel, JobTodoDetailItemValue, SummaryContent } from './components';
import { ButtonWithPopup, Display, IconWithPopup, MenuButtonItemConf } from '@poolware/components';
import { cn } from '@ez/tools';

export const JobTodoView: React.FC<{
    title: string;
    disabled: boolean;
    isLinked: boolean;
    description: string;
    todoItems: { id: NodeType.ID; title?: string }[];
    time?: number;
    price?: number;
    menuItems: MenuButtonItemConf[];
}> = ({ title, disabled, isLinked, description, todoItems, time, price, menuItems }) => {
    return (
        <>
            <div className={cn('row-content-main', { disable: disabled }, { linked: isLinked })}>
                <div className={'row-content-details'}>
                    <details open={true}>
                        <summary>
                            <Icon name={ModuleIconNames.WorkOrderItem} /> {title}{' '}
                            {isLinked ? (
                                <IconWithPopup name={'linkify'} popup={{ content: 'Linked to Task Template' }} />
                            ) : (
                                ''
                            )}
                        </summary>
                        <SummaryContent>
                            {description && (
                                <Display.Text style={{ color: '#444444', fontSize: '1em' }} value={description} />
                            )}
                            <div>
                                {todoItems.map((item) => {
                                    return (
                                        <div className={'item-row'} key={item.id}>
                                            <div className={'item-icon'}>
                                                <Icon name={'checkmark box'} />
                                            </div>
                                            <div className={'item-title'}>{item.title}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </SummaryContent>
                    </details>
                </div>
                <div className={'row-content-price'}>
                    <JobTodoDetailItem>
                        <JobTodoDetailItemLabel>Duration: </JobTodoDetailItemLabel>
                        <JobTodoDetailItemValue>{time ? `${time} min` : '--'}</JobTodoDetailItemValue>
                    </JobTodoDetailItem>
                    {false && (
                        <JobTodoDetailItem>
                            <JobTodoDetailItemLabel>Cost: </JobTodoDetailItemLabel>
                            <JobTodoDetailItemValue>{price ? `\$ ${price}` : '--'}</JobTodoDetailItemValue>
                        </JobTodoDetailItem>
                    )}
                </div>
            </div>

            <div className={'row-content-actions'}>
                {menuItems.map((mi, index) => {
                    return (
                        <ButtonWithPopup
                            popup={{ content: mi.name, position: 'right center' }}
                            key={index}
                            size={'mini'}
                            basic={true}
                            icon={mi.icon}
                            onClick={mi.onClick as any}
                            content={mi.content}
                        />
                    );
                })}
            </div>
        </>
    );
};

export const JobTodoTemplateView: React.FC<{
    jobTodoTemplate: NodeType.JobTodoTemplate;
    isEditingMode?: boolean;
    menuItems: MenuButtonItemConf[];
}> = ({ jobTodoTemplate, isEditingMode, menuItems }) => {
    const todoItems = fromEdges(jobTodoTemplate.items).sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1));
    const isGlobalJobTodoTemplate = !Boolean(jobTodoTemplate.ownedByWorkOrderTemplate?.id);

    return (
        <JobTodoView
            title={jobTodoTemplate.title}
            description={jobTodoTemplate.description}
            menuItems={menuItems}
            isLinked={isGlobalJobTodoTemplate}
            disabled={isEditingMode}
            time={jobTodoTemplate.timeEstimate}
            price={jobTodoTemplate.recommendedPrice}
            todoItems={todoItems}
        />
    );
};
