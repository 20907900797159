import { CountryCode, CountryProfiles } from './constants';
import { usePersistedString } from '@ez/tools';

const COUNTRY_KEY = 'address.country-code';

export const useDefaultCountry = (country: CountryCode = CountryCode.AU) => {
    const [countryCode, setCountryCode] = usePersistedString<CountryCode>(COUNTRY_KEY, country);

    const setDefaultCountryCode = (_country: CountryCode) => {
        if (CountryProfiles.findIndex((cp) => cp.short === _country) !== -1) {
            setCountryCode(_country);
        }
    };
    return { defaultCountryCode: countryCode, setDefaultCountryCode };
};
