import * as React from 'react';
import { useEffect } from 'react';

import { Provider as ReduxProvider } from 'react-redux';
import { ViewerProvider } from './Viewer';
import { AppRoot } from './AppRoot';
import { AppConfigProvider } from './app-config';
import { AppNavRouterProvider } from '@poolware/react-app-navigator';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { initRollbar } from './withTracker';
import { getHistory } from './create-app-router';
import { AppErrorBoundary, AppFatalErrorMessage } from './components';
import { ApolloClientProvider } from './create-apollo-client';
import { ThemeProvider } from './app-theme';
import { AppUpdater } from './AppUpdater';
import { HelmetProvider } from 'react-helmet-async';

export interface AppProvidersProps {
    router?: {
        basename?: string;
        history?: any;
    };
    reduxStore;
}

export const AppRootProvider: React.FC<AppProvidersProps> = (props) => {
    useEffect(() => {
        initRollbar();
    }, []);

    const r: AppProvidersProps['router'] = {
        ...getHistory(),
        ...props.router,
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <HelmetProvider>
                <AppConfigProvider>
                    <AppNavRouterProvider router={r}>
                        <AppErrorBoundary FallbackComponent={AppFatalErrorMessage}>
                            <ApolloClientProvider>
                                <ReduxProvider store={props.reduxStore}>
                                    <ThemeProvider>
                                        <AppUpdater>
                                            <ViewerProvider>
                                                <AppRoot>{props.children}</AppRoot>
                                            </ViewerProvider>
                                        </AppUpdater>
                                    </ThemeProvider>
                                </ReduxProvider>
                            </ApolloClientProvider>
                        </AppErrorBoundary>
                    </AppNavRouterProvider>
                </AppConfigProvider>
            </HelmetProvider>
        </DndProvider>
    );
};
