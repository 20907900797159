import * as React from 'react';
import { useServiceJobListActions } from '../../redux';
import { useQueryServiceJobGroupConnection } from '../../queries/use-query-service-job-group-connection';
import { ActionBarFilterByServiceJobGroup } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobGroup';
import { mapServiceJobGroupsToOptions } from '../../components/ActionBarFilters/ActionBarServiceGroups';
import { useMemo } from 'react';

export const FilterByServiceJobGroup: React.FC = () => {
    const { Actions, State } = useServiceJobListActions();
    const { connectionData } = useQueryServiceJobGroupConnection({});
    const options = useMemo(() => mapServiceJobGroupsToOptions(connectionData), [connectionData]);
    if (!connectionData.length) {
        return null;
    }

    return (
        <ActionBarFilterByServiceJobGroup
            setServiceJobGroup={Actions.setServiceJobGroup}
            setServiceJobTemplate={Actions.setServiceJobTemplate}
            options={options}
            value={State.filters?.serviceGroupId || State.filters?.serviceJobTemplateId}
            persistStorageKey={'sj.single.filter.service-group.toggle'}
        />
    );
};
