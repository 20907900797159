import * as _ from 'lodash';
import { AppNavContextType, ModuleRouteContextType } from './module-route';
import invariant from 'invariant';
const queryString = require('query-string');

export const extractParam = (props, paramName) => {
    return (
        _.get(props, `match.params.${paramName}`) || _.get(props, `params.${paramName}`) || _.get(props, `${paramName}`)
    );
};

const concatPath = (a: string, b: string) => {
    a = a.replace(/(\/+$)/g, ''); // strip ending slashes;
    b = b.replace(/(^\/+)/g, ''); // strip starting slashes
    const p = `${a}/${b}`;
    return p;
};

export const preparePath = (conf: {
    routeCtx: ModuleRouteContextType;
    appNavCtx?: AppNavContextType;
    pathname: string | readonly string[];
    relativeToModule?: boolean;
    moduleId?: string;
}): string | readonly string[] => {
    const { moduleId, appNavCtx, routeCtx, pathname, relativeToModule = true } = conf;

    invariant(!(pathname instanceof Array), 'arrays are not excepted');
    if (pathname instanceof Array) {
        // Arrays are note supported
        return pathname;
    }

    if (moduleId) {
        invariant(appNavCtx, 'appNavCtx is required');
        const moduleRootPath = appNavCtx?.modules[moduleId];
        if (moduleRootPath) {
            const p = concatPath(moduleRootPath, pathname);
            return p;
        } else {
            return pathname;
        }
    }

    if (relativeToModule) {
        let uri = routeCtx?.parentMatch?.url;
        invariant(uri, 'routeCtx.parentMatch.url is required');
        const p = concatPath(uri, pathname);
        return p;
    }
    return pathname;
};

export type ParsedRouteType<Params = any, QueryVars = any> = {
    params: Params;
    query: QueryVars;
    isModal: boolean;
    returnTo?: string;
    returnToSearch?: any;
    tab?: string;
    state?: any;
};

export function parseCurrentRoute<Params, QueryVars>({ match, location }): ParsedRouteType<Params, QueryVars> {
    const params: Params = (match && match.params) || {};
    const query = (location && queryString.parse(location.search)) || {};
    const state = (location && location.state) || {};
    const { tab } = query;
    const { modal, returnTo } = state;
    return {
        state,
        params,
        query: query as QueryVars,
        tab,
        isModal: modal,
        returnTo,
    };
}
