import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { ServiceJobPrintView } from './ServiceJobPrintView';
import { WorkOrderPrintView } from './WorkOrderPrintView';
import * as React from 'react';
import { InvoicePagePrintView } from '../Invoice/View/Page.PrintView';

export const ServiceJobPrintRouter = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={`/sj/:sjId`} component={ServiceJobPrintView} />
            <ModuleRoute path={`/wo/:woId`} component={WorkOrderPrintView} />
            <ModuleRoute path={`/invoice/:id`} component={InvoicePagePrintView} />
        </ModuleRootSwitch>
    );
};
