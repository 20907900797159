import clsx from 'clsx';
import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { commonProps } from './CalendarPropsTypes';
import { useRbcContext } from './CalendarContext';

function stringifyPercent(v) {
    return typeof v === 'string' ? v : v + '%';
}

const propTypes = {
    ...commonProps,
    rtl: PropTypes.bool,
    event: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    continuesEarlier: PropTypes.bool,
    continuesLater: PropTypes.bool,
    label: PropTypes.any,
};

interface TimeGridEventProps extends InferProps<typeof propTypes> {
    style;
    className?: string;
    componentEvent: React.ElementType;
    componentEventWrapper: React.ElementType;
}

export const TimeGridEvent: React.FC<TimeGridEventProps> = (props) => {
    const { accessors, getters } = useRbcContext();
    const {
        rtl,
        event,
        style,
        className,
        selected,
        label,
        continuesEarlier,
        continuesLater,
        onClick,
        onDoubleClick,
        componentEvent: Event,
        componentEventWrapper: EventWrapper,
    } = props;

    let title = accessors.title(event);
    let tooltip = accessors.tooltip(event);
    let end = accessors.end(event);
    let start = accessors.start(event);

    let userProps = getters.eventProp(event, start, end, selected);

    let { height, top, width, xOffset } = style;
    const inner = [
        // <div key="1" className="rbc-event-label">
        //     {label}
        // </div>,
        <div key="2" className="rbc-event-content">
            {Event ? <Event event={event} title={title} /> : title}
        </div>,
    ];

    return (
        <EventWrapper type="time" {...props}>
            <div
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                style={{
                    ...userProps.style,
                    top: stringifyPercent(top),
                    [rtl ? 'right' : 'left']: stringifyPercent(xOffset),
                    width: stringifyPercent(width),
                    height: stringifyPercent(height),
                }}
                title={tooltip ? (typeof label === 'string' ? label + ': ' : '') + tooltip : undefined}
                className={clsx('rbc-event', className, userProps.className, {
                    'rbc-selected': selected,
                    'rbc-event-continues-earlier': continuesEarlier,
                    'rbc-event-continues-later': continuesLater,
                })}
            >
                {inner}
            </div>
        </EventWrapper>
    );
};

TimeGridEvent.propTypes = propTypes;
