import * as React from 'react';
import { ProductSearchResultsTable } from './ProductSearchResultsTable';
import { ActionBar, MenuBar, StickyMenuBar, VStack } from '@poolware/components';
import {
    GAS_HEATER_TAG,
    HEATER_CAPACITY_TAG,
    HEATER_INPUT_TAG,
    HEATER_PUMP_TAG,
} from '../../common/use-equipment-catalog';
import { usePersistedState, usePersistedToggle } from '@ez/tools';
import { SuggestInputOrgSwitcher, useOrgSwitcher } from '@poolware/app-shell';
import { BrandingType } from '../../common/types';

type HeaterType = typeof HEATER_PUMP_TAG | typeof GAS_HEATER_TAG;

const ProductHome: React.FC = () => {
    const [hideInactive, setHideInactive] = usePersistedToggle('heatcalc.admin.hideinactive', false);
    const [filterType, setFilterType] = usePersistedState<HeaterType>(
        'heatcalc.admin.HEATER_PUMP_TAG',
        HEATER_PUMP_TAG
    );
    const [brandType, setBrandType] = usePersistedState<BrandingType>('heatcalc.admin.HEATER_PUMP_BRAND', null);
    const { organisation } = useOrgSwitcher();
    const onFilterChange = (
        checked: boolean,
        type: typeof HEATER_PUMP_TAG | typeof GAS_HEATER_TAG,
        _brandType: BrandingType
    ) => {
        setFilterType(type);
        setBrandType(_brandType);
    };

    let fieldTags = {
        [HEATER_PUMP_TAG]: [HEATER_CAPACITY_TAG, HEATER_INPUT_TAG],
        [GAS_HEATER_TAG]: [HEATER_CAPACITY_TAG],
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem icon={'calculator'}>Heaters Calc Configuration</MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <div tw={'p-1 w-80'}>
                        <SuggestInputOrgSwitcher />
                    </div>
                </MenuBar.Section>
            </StickyMenuBar>

            <div tw={'flex flex-row space-x-2'}>
                <div tw={'w-60 space-y-2'}>
                    <ActionBar>
                        <ActionBar.Header content={'Heater Type'} icon={'filter'} />
                        <ActionBar.ItemCheckbox
                            radio={true}
                            checked={filterType == HEATER_PUMP_TAG && brandType !== BrandingType.Aquatight}
                            onChange={(checked) => onFilterChange(checked, HEATER_PUMP_TAG, null)}
                            content={'Heat Pumps'}
                        />
                        <ActionBar.ItemCheckbox
                            radio={true}
                            checked={filterType == HEATER_PUMP_TAG && brandType === BrandingType.Aquatight}
                            onChange={(checked) => onFilterChange(checked, HEATER_PUMP_TAG, BrandingType.Aquatight)}
                            content={'Heat Pumps (Aquatight)'}
                        />
                        <ActionBar.ItemCheckbox
                            radio={true}
                            checked={filterType == GAS_HEATER_TAG}
                            onChange={(checked) => onFilterChange(checked, GAS_HEATER_TAG, null)}
                            content={'Gas Heaters'}
                        />
                    </ActionBar>
                    <ActionBar.Checkbox onChange={setHideInactive} content={'Hide inactive'} checked={hideInactive} />
                </div>
                <div tw={'flex-grow'}>
                    <ProductSearchResultsTable
                        declarationTag={filterType}
                        fieldTags={fieldTags[filterType]}
                        organisationType={organisation}
                        hideInactive={hideInactive}
                        brandType={brandType}
                    />
                </div>
            </div>
        </VStack>
    );
};

export default ProductHome;
