import * as React from 'react';
import {
    FormikCheckboxField,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    MenuBarHeaderItem,
    MenuBarSection,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType, useMutationServiceJobTemplate } from '@poolware/api';
import {
    FormikSuggestInputFranchise,
    FormikSuggestInputOrganisationType,
    PageContent,
    useViewer,
} from '@poolware/app-shell';
import { FormikServiceJobGroupSuggestInput } from '../../../connected-components/ServiceJobGroupSuggestInput';

import * as Yup from 'yup';
import { useServiceJobTemplateListActions } from '../List/reducer';

const validationSchema = Yup.object().shape({
    templateTitle: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').required('Required'),
    providedTitle: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').notRequired().nullable(),
    group: Yup.mixed().required('Required'),
});

export const ServiceJobTemplateNew: React.FC = (props) => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin } = useViewer();

    const { State } = useServiceJobTemplateListActions();

    const { create } = useMutationServiceJobTemplate();

    const initialValues: Partial<NodeType.ServiceJobTemplate> = {
        templateTitle: '',
        providedTitle: '',
        group: { id: State.filters?.serviceGroupId },
        franchise: undefined,
        organisationType: undefined,
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin('/sj-template', { relativeToModule: true });
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const title = values.templateTitle?.trim() || '';
            const res = await create({
                templateTitle: title,
                providedTitle: title,
                group: values.group?.id,
                canBeRecurring: values.canBeRecurring,
                franchise: isAdmin ? values.franchise?.id : undefined,
                organisationType: isAdmin ? values.organisationType?.id : undefined,
                workOrderTemplateAssocs: [
                    {
                        priority: 0,
                        workOrderTemplate: {
                            create: {
                                templateTitle: title,
                                providedTitle: title,
                            },
                        },
                    },
                ],
            });

            const id = res?.data?.ServiceJobTemplate?.ServiceJobTemplate?.id;
            AppNavigator.replace(`/sj-template/${id}/edit`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>New Service Template</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <PageContent pageWidth={'narrow'}>
                <FormikDefaultForm
                    header={'New Service Template'}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    submitButton={{ content: 'Next...' }}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                >
                    {isAdmin && (
                        <>
                            <FormikSuggestInputOrganisationType name={'organisationType'} label={'Organisation'} />
                            <FormikSuggestInputFranchise name={'franchise'} label={'Franchise'} />
                        </>
                    )}
                    <FormikInputField label={'Template Title'} name={'templateTitle'} required={true} />
                    <FormikServiceJobGroupSuggestInput name={'group'} label={'Service Group'} required={true} />
                    <FormikTextareaField label={'Template Description'} name={'templateDescription'} />
                    {false && <FormikCheckboxField name={'canBeRecurring'} label={'Can be recurring'} />}
                </FormikDefaultForm>
            </PageContent>
        </VStack>
    );
};
