import * as React from 'react';
import * as _ from 'lodash';
import { Field } from 'formik';
import { ColorPillWithColorPicker } from '@poolware/components';
import { IconButton } from '../../Components/IconButton';
import { FieldContentContainer } from './common';
import { ColorPalette } from '@poolware/api';
import { FormikFormFieldLabel } from '@poolware/components';

interface ColorFieldProps {
    name: string;
    label: string;
    required?: boolean;
    palette?: ColorPalette;
}

const ColorField: React.FC<ColorFieldProps> = ({ label, name, palette, required }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (newColor: string) => form.setFieldValue(field.name, newColor);
                const value = _.get(field, 'value', undefined);
                return (
                    <div className="field">
                        <FormikFormFieldLabel label={label} name={name} required={required} />
                        <FieldContentContainer>
                            <ColorPillWithColorPicker value={value} palette={palette} onChange={handleChange} />
                            {value && (
                                <IconButton
                                    data-testid={'ColorField-clear-button'}
                                    name={'close'}
                                    onClick={() => handleChange(null)}
                                />
                            )}
                        </FieldContentContainer>
                    </div>
                );
            }}
        </Field>
    );
};

export default ColorField;
