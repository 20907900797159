import { useWorkOrderListActions, WorkOrderBookingStatus, WorkOrderRecurStatus } from '../../redux';
import {
    QueryWorkOrdersConnectionProps,
    SearchByAssignmentState,
    useQueryWorkOrdersConnection,
} from '../../queries/use-query-work-orders-connection';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import { useMemo } from 'react';

export const useConnectedQueryWorkOrdersConnection = () => {
    const { WorkOrderListState, WorkOrderListAction } = useWorkOrderListActions();

    const queryConf = useMemo(() => {
        const { staffIds, workOrderNumber, bookingStatus, recurStatus } = WorkOrderListState.filters;

        const queryConf: QueryWorkOrdersConnectionProps = {};
        if (staffIds.length > 0) {
            if (staffIds.includes(UNASSIGNED_APPOINTMENT_STAFF_ID)) {
                queryConf.assignmentState = SearchByAssignmentState.UNASSIGNED;
            } else {
                queryConf.assignedToStaff = staffIds[0];
            }
        }

        if (workOrderNumber) {
            queryConf.workOrderNumber = workOrderNumber;
        }

        switch (bookingStatus) {
            case WorkOrderBookingStatus.ANY:
                queryConf.hasAppointment = null;
                break;
            case WorkOrderBookingStatus.BOOKED:
                queryConf.hasAppointment = true;
                break;
            case WorkOrderBookingStatus.UNBOOKED:
                queryConf.hasAppointment = false;
                break;
        }

        switch (recurStatus) {
            case WorkOrderRecurStatus.ANY:
                queryConf.isRecurring = null;
                break;
            case WorkOrderRecurStatus.RECURRING:
                queryConf.isRecurring = true;
                break;
            case WorkOrderRecurStatus.ONEOFF:
                queryConf.isRecurring = false;
                break;
        }
        return queryConf;
    }, [WorkOrderListState.filters]);

    return useQueryWorkOrdersConnection({
        ...queryConf,
        pageIndex: WorkOrderListState.page.index,
        pageSize: WorkOrderListState.page.size,
        setPageIndex: WorkOrderListAction.setPageIndex,
    });
};
