import * as React from 'react';
import { AnyNode, FilterType, ProductCatalogActionProps, useProductCatalogActions } from '../redux';
import { NodeType, TraitValueType } from '@poolware/api';
import {
    QueryProductConnection,
    QueryProductConnectionExternalProps,
    useQueryProductConnection,
} from './QueryProductConnection';

export interface QueryProductListProps extends ProductCatalogActionProps, QueryProductConnectionExternalProps {
    pageSize?: number;
}

const prepareQueryConf = (input: {
    ProductCatalogState;
    ProductCatalogAction;
    pageSize?: number;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}): QueryProductConnectionExternalProps => {
    const { ProductCatalogState, ProductCatalogAction, pageSize, organisationType } = input;
    const { page } = ProductCatalogState;
    const name = ProductCatalogState.getFilterValueByType(FilterType.NAME) as string;
    const sku = ProductCatalogState.getFilterValueByType(FilterType.SKU) as string;
    const customSku = ProductCatalogState.getFilterValueByType(FilterType.CUSTOM_SKU) as string;
    const brand = ProductCatalogState.getFilterValueByType(FilterType.BRAND) as AnyNode;
    const supplier = ProductCatalogState.getFilterValueByType(FilterType.SUPPLIER) as AnyNode;
    const productCompany = ProductCatalogState.getFilterValueByType(FilterType.PRODUCT_COMPANY) as AnyNode;

    const declarationIds = ProductCatalogState.getFilterItemsByType(FilterType.DECLARATION).map((fi) => {
        if (fi.value) {
            // @ts-ignore
            return fi.value.id;
        } else {
            return null;
        }
    });

    const traitFieldFilters = ProductCatalogState.getFilterItemsByType(FilterType.TRAIT_FIELD);
    const traitValues = traitFieldFilters
        .map((tf) => {
            if (tf.value.type === TraitValueType.Selection) {
                const isOr = tf.value.isOr ? tf.value.isOr : undefined;
                return {
                    type: TraitValueType.Selection,
                    missing: tf.value.missing,
                    missingFieldId: tf.value.missingFieldId,
                    isOr: isOr,
                };
            }
            return undefined;
        })
        .filter(Boolean);

    return {
        productCompanyId: productCompany?.id,
        brandId: brand?.id,
        supplierId: supplier?.id,
        name: name,
        sku: sku,
        customSku: customSku,
        pageSize: pageSize !== undefined ? pageSize : 50,
        pageIndex: page.index,
        setPageIndex: ProductCatalogAction.setPageIndex,
        traits: { op: 'and' as 'and', ids: declarationIds },
        traitsValues: { op: 'and' as 'and', values: traitValues },
        fetchPolicy: 'cache-first' as 'cache-first',
        organisationType: organisationType,
    };
};

export const QueryProductList: React.FC<QueryProductListProps> = (props) => {
    const { ProductCatalogState, ProductCatalogAction } = useProductCatalogActions();
    const conf = prepareQueryConf({ pageSize: props.pageSize, ProductCatalogAction, ProductCatalogState });
    return <QueryProductConnection {...conf} {...props} />;
};

export interface UseConnectedQueryProductConnectionInput extends Omit<QueryProductListProps, 'children'> {}

export const useConnectedQueryProductConnection = (props: UseConnectedQueryProductConnectionInput) => {
    const conf = prepareQueryConf(props);
    return useQueryProductConnection(conf);
};
