import * as React from 'react';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from 'lodash';
import { useAppointmentMutators } from '../../queries/use-appointment-mutators/use-appointment-mutators';
import { DebugJSON, toastError } from '@poolware/components';
import * as URLBuilder from '../url-builder';
import { refetchAppointments } from '../Queries/query-appointment-items-calendar';
import moment from 'moment';
import { useCalendarActions } from '../../redux';
import { TableContainer } from './ComponentsVirtual';
import { CalendarEventMode, CalendarEventType } from '../CalendarView/types';
import { CalViewMode } from '../types';
import { useViewer, withErrorBoundary } from '@poolware/app-shell';
import { useDebouncedCallback, useSafeState } from '@ez/tools';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentsTable } from './AppointmentsTable';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';
import { useAppointmentViewCtx } from '../AppointmentDock';

export interface AppointmentsSectionedTableViewProps {
    events: CalendarEventType[];
    onSelectEvent: (event: CalendarEventType | null) => any;
}

type ScrollEvent = SyntheticEvent<HTMLDivElement>;

const useTableDef = (events) => {
    return useMemo(() => {
        const eventsWithDateLabel = events
            .filter((e) => e.mode === CalendarEventMode.APPOINTMENT_ITEM)
            .sort((a, b) => {
                return a > b ? 1 : -1;
            })
            .map((event) => {
                return {
                    ...event,
                    dateLabel: moment(event.start).format('ddd,  DD MMM YYYY'),
                };
            });

        const sortedDateStrings = eventsWithDateLabel.reduce((acc, event) => {
            if (!acc.includes(event.dateLabel)) {
                acc.push(event.dateLabel);
            }
            return acc;
        }, []);

        const groupedData = _.groupBy(eventsWithDateLabel, (event) => {
            return event.dateLabel;
        });

        return {
            sections: Object.keys(groupedData)
                .sort((a, b) => {
                    return sortedDateStrings.indexOf(a) > sortedDateStrings.indexOf(b) ? 1 : -1;
                })
                .map((key) => {
                    return {
                        sectionTitle: key,
                        data: groupedData[key],
                    };
                }),
        };
    }, [events]);
};

const AppointmentsTableViewControl: React.FC<AppointmentsSectionedTableViewProps> = (props) => {
    const { events } = props;
    const { AppNavigator } = useAppNavigator();
    const { AppointmentMutator } = useAppointmentMutators([refetchAppointments()]);
    const { CalendarAction, CalendarState } = useCalendarActions();
    const { modulesAccess } = useViewer();
    const CalLayoutState = useCalendarLayoutState();
    const previewItemCtx = useAppointmentViewCtx();

    const tableContRef = useRef<any>();

    const tableDef = useTableDef(events);

    const rowsCount = tableDef.sections.reduce((acc, val) => {
        return acc + val.data.length + 1;
    }, 0);

    const { viewMode } = CalendarState;
    const count = events.length;
    const canChangeStaff = !(modulesAccess.FieldServices?.calendarSingleStaffMode || viewMode === CalViewMode.MONTH);

    const [range, setRange] = useState({ startIndex: 0, endIndex: rowsCount });

    const getVisibleRange = ({ clientHeight, scrollHeight, scrollTop }) => {
        const rowHeight = scrollHeight / rowsCount;
        const visibleRowNum = Math.ceil(clientHeight / rowHeight);
        let startIndex = Math.ceil(scrollTop / rowHeight);
        let endIndex = Math.ceil(startIndex + visibleRowNum);
        // apply over-fetch factor
        const overFetchNum = visibleRowNum * 2;
        startIndex = Math.max(0, startIndex - overFetchNum);
        endIndex = Math.min(rowsCount, endIndex + overFetchNum);
        const res = { startIndex, endIndex, visibleRowNum, rowHeight };
        return res;
    };

    useEffect(() => {
        if (!tableContRef.current) return;
        tableContRef.current.scrollTop = 0;
        const { startIndex, endIndex } = getVisibleRange(tableContRef.current);
        setRange({ startIndex, endIndex });
    }, [CalendarState.filters, events.length]);

    const _setVisibilityIndex = (start, end) => {
        if (start !== range.startIndex || end !== range.endIndex) {
            // console.log(`applied range ${start} -> ${end}`);
            setRange({ startIndex: start, endIndex: end });
        }
    };

    const [setVisibilityIndex] = useDebouncedCallback(_setVisibilityIndex, 50, { leading: false });

    const onChangeStaff = async (appointmentItem, newStaff) => {
        const currentStaffId = _.get(appointmentItem, 'staff.id');
        const newStaffId = _.get(newStaff, 'id');

        if (currentStaffId === newStaffId) {
            return;
        }

        try {
            await AppointmentMutator.changeAppointmentItemStaff(appointmentItem, newStaff);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change staff', description: error.message });
        }
    };

    const onChangeStatus = async (appointmentItem, newStatus) => {
        try {
            await AppointmentMutator.changeAppointmentItemStatus(appointmentItem, newStatus);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change status', description: error.message });
        }
    };

    const onAppointmentItemEdit = async (item: NodeType.AppointmentItem) => {
        const id = item.id;
        AppNavigator.navigate(URLBuilder.Scheduler.edit(id), {
            setOrigin: true,
            modal: true,
            state: {
                appointmentItem: item,
            },
        });
    };

    const onDidDelete = async () => {};

    const onShowInCalendar = (item: NodeType.AppointmentItem) => {
        CalendarAction.showAppointmentInCalendar(item);
    };

    const _onScroll = (event: ScrollEvent) => {
        if (!event?.currentTarget) {
            return;
        }
        const { clientHeight, scrollHeight, scrollTop } = event.currentTarget;
        const res = getVisibleRange({ clientHeight, scrollHeight, scrollTop });
        setVisibilityIndex(res.startIndex, res.endIndex);
    };

    const showAppointmentPreview = (event: CalendarEventType | null) => {
        return props.onSelectEvent?.(event);
    };

    const onSwitchToDockMode = (item: NodeType.AppointmentItem) => {
        CalendarAction.setPreviewAppt(item?.id);
        CalLayoutState.setDockState(true);
    };

    return (
        <TableContainer>
            <div className={'table-cont'} onScroll={_onScroll} ref={tableContRef}>
                <AppointmentsTable
                    range={range}
                    tableDef={tableDef}
                    count={count}
                    onAppointmentItemEdit={onAppointmentItemEdit}
                    onChangeStatus={onChangeStatus}
                    onChangeStaff={onChangeStaff}
                    canChangeStaff={canChangeStaff}
                    onDidDelete={onDidDelete}
                    onShowInCalendar={onShowInCalendar}
                    onSelectedEvent={showAppointmentPreview}
                    selectedEventId={previewItemCtx.appointmentItemId}
                    // mode={CalLayoutState.isDockOpen ? 'select' : 'expand'}
                    mode={'select'}
                    onSwitchToDockMode={onSwitchToDockMode}
                />
            </div>
        </TableContainer>
    );
};

export const AppointmentsTableView = withErrorBoundary()(AppointmentsTableViewControl);
