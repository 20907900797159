import gql from 'graphql-tag';
import { fromEdges, NodeType } from '@poolware/api';
import { AnyNode, FilterType, ProductCatalogActionProps } from '../redux';
import * as _ from 'lodash';
import { flattenTraitDeclaration, ProductTraitFlattened } from '../utils';
import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';
import { productTraitDeclarationFragment } from './fragments';

export const traitDeclarationsGQL = gql`
    query ProductSearchAuxData($traitIds: [ID!]!, $productCompanyId: ID) {
        viewer {
            resolveNodes(ids: $traitIds) {
                ...ProductTraitDeclarationFragment
                ... on ProductTraitDeclaration {
                    parentDeclarations {
                        id
                        name
                        ...ProductTraitDeclarationFragment
                    }
                }
            }
            brands(searchTraits: { declarations: $traitIds }, search: { company: { id: $productCompanyId } }) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            productCompany(searchTraits: { declarations: $traitIds }) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
    ${productTraitDeclarationFragment}
`;

export const useQueryProductSearchAuxData = (ProductCatalogState: ProductCatalogActionProps['ProductCatalogState']) => {
    const options = useMemo<QueryHookOptions>(() => {
        const declarationFilters = ProductCatalogState.getFilterItemsByType(FilterType.DECLARATION);
        const skip = declarationFilters.length === 0;
        const productCompanyFilter = ProductCatalogState.getFilterValueByType(FilterType.PRODUCT_COMPANY) as AnyNode;

        const declarationIds = ProductCatalogState.getFilterItemsByType(FilterType.DECLARATION)
            .map((fi) => _.get(fi, 'value.id'))
            .filter((f) => !!f);

        const productCompanyId = productCompanyFilter ? productCompanyFilter.id : undefined;

        return {
            variables: {
                traitIds: declarationIds,
                productCompanyId: productCompanyId,
            },
            skip: skip,
            // fetchPolicy: 'cache-first', // 'network-only',
        };
    }, [ProductCatalogState]);

    const queryResult = useQuery<{ viewer: NodeType.Viewer }>(traitDeclarationsGQL, options);

    const { loading, error, data } = queryResult;

    if (!data && (error || loading)) {
        return {
            queryResult,
            traitDeclarations: [],
            flattenedDeclarations: [],
            relatedBrands: [],
            relatedCompanies: [],
        };
    }

    const traitDeclarations = fromEdges<NodeType.ProductTraitDeclaration>(data?.viewer?.resolveNodes);
    const relatedBrands = fromEdges(data?.viewer?.brands);
    const relatedCompanies = fromEdges(data?.viewer?.productCompany);

    // Flatten trait declarations
    let flattenedDeclarations: ProductTraitFlattened[] = [];
    if (traitDeclarations) {
        traitDeclarations.forEach((traitDeclaration) => {
            if (traitDeclaration) {
                flattenedDeclarations.push(flattenTraitDeclaration(traitDeclaration));
                traitDeclaration.parentDeclarations.forEach((decl) => {
                    flattenedDeclarations.push(flattenTraitDeclaration(decl));
                });
            }
        });
        flattenedDeclarations = _.uniqBy(flattenedDeclarations, 'trait.id');
        flattenedDeclarations = flattenedDeclarations.sort((l, r) => (l.traitLevel < r.traitLevel ? -1 : 1));
    }

    return {
        queryResult,
        traitDeclarations,
        flattenedDeclarations,
        relatedBrands,
        relatedCompanies,
    };
};
