import * as React from 'react';
import { NodeType, useMutationCustomer } from '@poolware/api';
import { Display, FormikDefaultForm, IconButton, Panel, toastError, useModalCtrl } from '@poolware/components';
import { FormikSuggestInputStaff } from '@poolware/app-shell';
import { Modal } from 'semantic-ui-react';

interface PanelCustomerNotesProps {
    customer: NodeType.Customer;
}

export interface PanelFormEditCustomerOfficerProps {
    customer: NodeType.Customer;
    onDone: () => any;
}

const PanelFormEditCustomerOfficer: React.FC<PanelFormEditCustomerOfficerProps> = ({ customer, onDone }) => {
    const initialValues = {
        ...customer,
    };

    const { update } = useMutationCustomer({ refetchQueries: ['CustomerQuery'] });

    const onSubmit = async (values: typeof initialValues) => {
        try {
            await update({
                id: customer.id,
                assignedTo: values.assignedTo?.id || null,
            });
            onDone();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            debug={false}
            header={'Customer Officer'}
            initialValues={initialValues}
            submitButton={{ content: 'Submit' }}
            onSubmit={onSubmit}
            onCancel={onDone}
        >
            <FormikSuggestInputStaff label={'Staff'} name={'assignedTo'} />
        </FormikDefaultForm>
    );
};

export const PanelCustomerOfficer: React.FC<PanelCustomerNotesProps> = ({ customer }) => {
    const modalCtrl = useModalCtrl();
    return (
        <>
            <Panel>
                <Panel.Body>
                    <Panel.Item label={'CX Officer'}>
                        <Display.Entity value={customer?.assignedTo} />
                        <IconButton name={'edit'} onClick={modalCtrl.onOpen} />
                    </Panel.Item>
                </Panel.Body>
            </Panel>
            <Modal {...modalCtrl}>
                <PanelFormEditCustomerOfficer customer={customer} onDone={modalCtrl.onClose} />
            </Modal>
        </>
    );
};
