const poolVolumeCalc_Kidney = (input: {
    width1: number;
    width2: number;
    length: number;
    depthDeepEnd: number;
    depthShallowEnd: number;
}) => {
    const { width1, width2, length, depthDeepEnd, depthShallowEnd } = input;
    const surfaceAreaTop = 0.45 * (width1 + width2) * length;
    const volume = surfaceAreaTop * ((depthDeepEnd + depthShallowEnd) / 2);
    return {
        volume,
        surfaceAreaTop,
    };
};

const poolVolumeCalc_Oval = (input: {
    diameter1: number;
    diameter2: number;
    depthDeepEnd: number;
    depthShallowEnd: number;
}) => {
    const { diameter1, diameter2, depthShallowEnd, depthDeepEnd } = input;
    const averageDepth = (depthDeepEnd + depthShallowEnd) / 2;
    const r1 = diameter1 / 2;
    const r2 = diameter2 / 2;
    const surfaceAreaTop = r1 * r2 * Math.PI;
    const volume = averageDepth * surfaceAreaTop;
    return {
        volume,
        surfaceAreaTop,
    };
};

const poolVolumeCalc_Round = (input: { diameter: number; depthDeepEnd: number; depthShallowEnd: number }) => {
    const { diameter, depthShallowEnd, depthDeepEnd } = input;
    return poolVolumeCalc_Oval({
        diameter1: diameter,
        diameter2: diameter,
        depthDeepEnd: depthDeepEnd,
        depthShallowEnd: depthShallowEnd,
    });
};

const poolVolumeCalc_Rect = (input: {
    length: number;
    width: number;
    depthDeepEnd: number;
    depthShallowEnd: number;
}) => {
    const { length, width, depthDeepEnd, depthShallowEnd } = input;
    const averageDepth = (depthDeepEnd + depthShallowEnd) / 2;
    const surfaceAreaTop = length * width;
    const volume = surfaceAreaTop * averageDepth;

    return {
        volume,
        surfaceAreaTop,
    };
};

const poolVolumeCalc_Square = (input: { length: number; depthDeepEnd: number; depthShallowEnd: number }) => {
    return poolVolumeCalc_Rect({
        ...input,
        width: input.length,
    });
};

export const PoolSizeCalc = {
    rect: poolVolumeCalc_Rect,
    square: poolVolumeCalc_Square,
    oval: poolVolumeCalc_Oval,
    round: poolVolumeCalc_Round,
    kidney: poolVolumeCalc_Kidney,
};
