import * as React from 'react';
import { useState } from 'react';
import {
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikInputField,
    FormikTextareaField,
    Panel,
    toastError,
} from '@poolware/components';
import { NodeType, useMutationWarrantyClaimCase } from '@poolware/api';
import { Modal } from 'semantic-ui-react';
import * as _ from 'lodash';
import * as Yup from 'yup';

export interface OfficeNotesPanelProps {
    claimCase: NodeType.WarrantyClaimCase;
    refetchQuery;
}

const validationSchema = Yup.object().shape({
    externalSRN: Yup.string().nullable().max(100, 'Too Long!'),
    externalRMA: Yup.string().nullable().max(100, 'Too Long!'),
    externalSO: Yup.string().nullable().max(100, 'Too Long!'),
    note: Yup.string().nullable().max(2000, 'Too Long!'),
});

const OfficeNotesPanelForm: React.FC<FormikDefaultFormProps> = (props) => {
    return (
        <FormikDefaultForm header={'Office Use'} validationSchema={validationSchema} {...props}>
            <FormikInputField label="SRN" name={'externalSRN'} />
            {/*<FormikInputField label="SO" name={'externalSO'} />*/}
            <FormikInputField label="RMA" name={'externalRMA'} />
            <FormikTextareaField rows={8} label={'Notes'} name={'note'} />
        </FormikDefaultForm>
    );
};

const OfficeNotesPanelView: React.FC<{ claimCase: NodeType.WarrantyClaimCase; onEdit: () => any }> = ({
    onEdit,
    claimCase,
}) => {
    return (
        <Panel>
            <Panel.Header
                color={'black'}
                content={'Office Use'}
                button={{ content: 'Edit', icon: 'edit', onClick: onEdit }}
            />
            <Panel.Body secondary={true}>
                <Panel.Item label={'SRN'} content={claimCase.externalSRN || '--'} />
                {/*<Panel.Item label={'SO'} content={claimCase.externalSO || '--'} />*/}
                <Panel.Item label={'RMA'} content={claimCase.externalRMA || '--'} />
                <Panel.ItemText labelWidth={'100%'} label={'Notes'} content={claimCase.note} />
            </Panel.Body>
        </Panel>
    );
};

export const PanelOfficeNotes: React.FC<OfficeNotesPanelProps> = ({ refetchQuery, claimCase }) => {
    const { update } = useMutationWarrantyClaimCase({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    const [open, setOpen] = useState(false);

    const onEdit = () => {
        setOpen(true);
    };

    const initialValues = {
        ...claimCase,
    };

    const onCaseUpdate = async (values: typeof initialValues) => {
        try {
            await update({
                id: claimCase.id,
                externalSRN: _.trim(values.externalSRN),
                externalRMA: _.trim(values.externalRMA),
                externalSO: _.trim(values.externalSO),
                note: _.trim(values.note),
            });
            setOpen(false);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <>
            <OfficeNotesPanelView claimCase={claimCase} onEdit={onEdit} />
            <Modal centered={false} open={open} closeOnDimmerClick={false}>
                <OfficeNotesPanelForm
                    onCancel={() => setOpen(false)}
                    onSubmit={onCaseUpdate}
                    initialValues={initialValues}
                />
            </Modal>
        </>
    );
};
