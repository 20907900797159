import * as React from 'react';
import { Field } from 'formik';
import { DatePickerButton, DatePickerButtonProps } from '@poolware/components';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

interface FormikDatePickerProps extends Omit<DatePickerButtonProps, 'onChange'> {
    name: string;
}

export const FormikDatePicker: React.FC<FormikDatePickerProps> = ({ monthsShown, name, ...props }) => {
    const innerWidth = window?.innerWidth;

    let _monthsShown;
    if (innerWidth >= 1024) {
        _monthsShown = monthsShown || 3;
    } else if (innerWidth >= 768) {
        _monthsShown = 2;
    } else {
        _monthsShown = 1;
    }

    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (date, e) => {
                    form.setFieldValue(field.name, date);
                };
                // Date picker expects Date object
                const value = typeof field.value === 'string' ? new Date(field.value) : field.value;
                return (
                    <DatePickerButton
                        monthsShown={_monthsShown}
                        selected={value}
                        onChange={handleChange}
                        dropdownMode="select"
                        {...props}
                    />
                );
            }}
        </Field>
    );
};
