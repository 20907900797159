import * as React from 'react';
import * as _ from 'lodash';
import { DebouncedInput } from '@poolware/components';
import { useServiceJobListActions } from '../../redux';

export const FilterByServiceJobNumber: React.FC<{ label?: string; placeholder?: string }> = ({
    label,
    placeholder,
}) => {
    const { State, Actions } = useServiceJobListActions();
    const { serviceJobNumber } = State.filters;

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            Actions.setServiceJobNumber(null);
        } else {
            Actions.setServiceJobNumber(_.trim(term));
        }
    };

    return (
        <DebouncedInput
            label={label}
            initialValue={serviceJobNumber}
            placeholder={placeholder || 'Search by Service Case Number...'}
            onChange={handleChange}
        />
    );
};

export const FilterByWorkOrderNumber: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { State, Actions } = useServiceJobListActions();
    const { workOrderNumber } = State.filters;

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            Actions.setWorkOrderNumber(null);
        } else {
            Actions.setWorkOrderNumber(_.trim(term));
        }
    };

    return (
        <DebouncedInput
            label={label}
            initialValue={workOrderNumber}
            placeholder={placeholder || 'Search by Work Order ID...'}
            onChange={handleChange}
        />
    );
};

export const FilterByCustomerCRN: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { State, Actions } = useServiceJobListActions();
    const { customerCRN } = State.filters;

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            Actions.setCustomerCRN(null);
        } else {
            Actions.setCustomerCRN(_.trim(term));
        }
    };

    return (
        <DebouncedInput
            label={label}
            initialValue={customerCRN}
            placeholder={placeholder || 'Search by Customer CRN...'}
            onChange={handleChange}
        />
    );
};
