import * as React from 'react';
import gql from 'graphql-tag';
import { QueryStats, QueryStatsProps } from './QueryStats';

const QL_reportsVolumeTotal = gql`
    query GetFranchiseActivity($resolution: DateRangeResolutionEnum, $startDate: Date, $endDate: Date) {
        viewer {
            stats {
                newCustomers(resolution: $resolution, startDate: $startDate, endDate: $endDate) {
                    edges {
                        node {
                            date
                            count
                            franchiseId
                            franchiseName
                        }
                    }
                }
            }
        }
    }
`;

export const QueryStatsNewCustomers: React.FC<QueryStatsProps> = (props) => {
    return <QueryStats query={QL_reportsVolumeTotal} queryPath={'viewer.stats.newCustomers'} {...props} />;
};
