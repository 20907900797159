import { CalViewMode } from '../Scheduler/types';
import { DateRange } from '../constants';
import * as _ from 'lodash';
import moment from 'moment';
import invariant from 'invariant';

export const sanitizeViewMode = (viewMode, defaultValue: CalViewMode) => {
    switch (viewMode) {
        case CalViewMode.DAY:
        //fallthrough
        case CalViewMode.WEEK:
        //fallthrough
        case CalViewMode.MONTH:
        //fallthrough
        case CalViewMode.AGENDA:
            return viewMode;
        default:
            console.error(
                `Unsupported calendar view mode "${viewMode}". Falling back to default view mode "${defaultValue}"`
            );
            return defaultValue;
    }
};

export const computeDateRange = (activeDate: Date, viewMode: CalViewMode): DateRange => {
    invariant(activeDate && _.isDate(activeDate), `Expected 'activeDate' to be of type Date. Value: ${activeDate}`);

    invariant(viewMode && _.isString(viewMode), `Expected 'viewMode' to be of type String. Value: ${activeDate}`);

    let newStartDate;
    let newEndDate;

    switch (viewMode) {
        case CalViewMode.DAY:
            newStartDate = moment(activeDate).startOf('day');
            newEndDate = moment(activeDate).endOf('day');
            break;
        case CalViewMode.WEEK:
            newStartDate = moment(activeDate).startOf('isoWeek');
            newEndDate = moment(activeDate).endOf('isoWeek');
            break;
        default:
            console.error('Unsupported view mode. Falling back to agenda months');
        //fallthrough
        case CalViewMode.AGENDA:
        // fallthrough
        case CalViewMode.MONTH:
            // Add some margins for monthly view grid.
            // First day of month does not always falls on Monday.
            newStartDate = moment(activeDate).startOf('month').startOf('isoWeek');
            newEndDate = moment(activeDate).endOf('month').endOf('isoWeek');
            break;
    }

    return {
        startDate: newStartDate.toDate(),
        endDate: newEndDate.toDate(),
    };
};

export enum RECUR_FILTER {
    ALL,
    RECURRING,
    ONEOFF,
}
