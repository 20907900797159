import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const QL = gql`
    query QueryJobTodoTemplate($id: ID!) {
        node(id: $id) {
            id
            ... on JobTodoTemplate {
                title
                description
                recommendedPrice
                timeEstimate
                organisation {
                    id
                    name
                }
                franchise {
                    id
                    name
                }
                items {
                    edges {
                        node {
                            id
                            title
                            description
                            orderIndex
                        }
                    }
                }
                checkMutations {
                    update
                    delete
                }
            }
        }
    }
`;

export const useQueryJobTodoTemplate = createUseQueryNodeHook<NodeType.JobTodoTemplate>(QL);
