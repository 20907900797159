import { take } from 'redux-saga/effects';
import * as _ from 'lodash';
import { makeDebugger } from '@ez/tools';
import { navigateActions } from './browser-navigation';

const debug = makeDebugger('saga:abort-on-nav');

export function* sagaAbortOnNavigation(sagaName) {
    while (true) {
        const { payload } = yield take(navigateActions.NAVIGATION_ACTION.BROWSER_NAVIGATED);
        // Every saga has a name associated with it.
        // When a saga initiates a browser navigation it also sets its name in the history state.
        // The following `if` statement checks whether the navigation was initiated via a saga action.
        // if it is not, then break the loop an quit.
        debug(`Navigation detected, saga ${sagaName}, payload ${payload}`);
        if (payload?.action === 'REPLACE') {
            // Ignore 'REPLACE' type navigation
            // Replace is usually used to setup search query in URL.
            continue;
        }
        if (_.get(payload, 'action') === 'POP' || sagaName !== _.get(payload, 'location.state.saga')) {
            debug('abort saga', sagaName);
            return;
        }
    }
}
