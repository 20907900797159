import { bindActionCreators, combineReducers } from 'redux';
import { connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createAction, createAutoBoundReducerActions, createReducer } from '@ez/tools';
import { ConnectionPageStateType, createPageIndexReducerActions, defaultConnectionPageState } from '@poolware/api';
import { DateRange } from '@poolware/app-service-jobs';
import moment from 'moment';
import { SemanticICONS } from 'semantic-ui-react';

const SCOPE = '@AppointmentReports';

export type ApptStatusFilterItem = {
    token: string;
    displayString: string;
    icon: SemanticICONS;
};

export type AppointmentReportsStateType = {
    filters: {
        franchise: { id?: string; name?: string };
        staffIds: string[];
        dateRange: DateRange;
        excludeAppointmentGroupsIds: string[];
        includeStatuses: ApptStatusFilterItem[];
        hasActiveFilters: boolean;
    };
    page: ConnectionPageStateType;
};

const activeDate = moment().endOf('day');
const newStartDate = moment(activeDate).subtract(6, 'days').startOf('day').toDate();
const newEndDate = activeDate.toDate();

export const initialState: AppointmentReportsStateType = {
    filters: {
        franchise: {},
        staffIds: [],
        excludeAppointmentGroupsIds: [],
        dateRange: {
            startDate: newStartDate,
            endDate: newEndDate,
        },
        includeStatuses: [],
        hasActiveFilters: false,
    },
    page: defaultConnectionPageState,
};

// Actions and action creators

enum ACTION_TYPE {
    CLEAR_ALL_FILTER = '@AppointmentReports/CLEAR_ALL_FILTER',
}

const CustomActions = {
    clearAllFilters: () => createAction(ACTION_TYPE.CLEAR_ALL_FILTER, undefined, SCOPE),
};

export const hasActiveFilters = (state: AppointmentReportsStateType['filters']) => {
    return (
        state.staffIds.length > 0 || state.excludeAppointmentGroupsIds.length > 0 || state.includeStatuses.length > 0
    );
};

const FiltersReducerActions = createAutoBoundReducerActions(initialState.filters, SCOPE, {
    setDateRangeFilter: { field: 'dateRange' },
    setStaffFilter: { field: 'staffIds' },
    setFranchiseFilter: { field: 'franchise' },
    setAppointmentStatusFilter: { field: 'includeStatuses' },
    setAppointmentGroupFilter: { field: 'excludeAppointmentGroupsIds' },
});

const filtersReducer = createReducer(
    initialState.filters,
    (state, action: any) => {
        const reducer = FiltersReducerActions.reducers[action.type];
        if (reducer) {
            reducer(state, action.payload);
        } else if (action.type === ACTION_TYPE.CLEAR_ALL_FILTER) {
            return initialState.filters;
        }
        state.hasActiveFilters = hasActiveFilters(state);
        return state;
    },
    SCOPE
);

const PageIndexReducerActions = createPageIndexReducerActions({
    scope: SCOPE,
    resetIndexActionTypes: [...FiltersReducerActions.actionTypes, ACTION_TYPE.CLEAR_ALL_FILTER],
});

export const reducer = combineReducers({
    filters: filtersReducer,
    page: PageIndexReducerActions.reducer,
});

// Memoization

const memoizedSelector = createStructuredSelector<AppointmentReportsStateType, AppointmentReportsStateType>({
    filters: (state) => state.filters,
    page: (state) => state.page,
});

const allActions = {
    ...CustomActions,
    ...PageIndexReducerActions.actions,
    ...FiltersReducerActions.actions,
};

export const withAppointmentReportsActions = () =>
    connect(
        (state: RootStateOrAny) => {
            return {
                AppointmentReportsState: memoizedSelector(state.appointmentsReport),
            };
        },
        (dispatch) => ({
            AppointmentReportsAction: bindActionCreators(allActions, dispatch),
        })
    );

export const useAppointmentReportsActions = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny) => memoizedSelector(state.appointmentsReport));
    const action = bindActionCreators(allActions, dispatch);

    return {
        AppointmentReportsState: state,
        AppointmentReportsAction: action,
    };
};

export interface AppointmentReportsActionProps {
    AppointmentReportsState: AppointmentReportsStateType;
    AppointmentReportsAction: typeof allActions;
}
