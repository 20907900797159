import * as React from 'react';
import { FormikDefaultForm, FormikInputField } from '@poolware/components';
import { FormikPoolVolumeInputField } from './FormikPoolVolumeInputField';
import { FormikDefaultFormProps } from '@poolware/components';
import { FormikPoolTypeSelectField } from './FormikPoolTypeSelectField';
import { FormikPoolSanitiserSelectField } from './FormikPoolSanitiserSelectField';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(1, 'Too Short!').max(150, 'Too Long!').required('Required').nullable(),
    volume: Yup.number().required('Required'),
    type: Yup.mixed().required('Required'),
    sanitiser: Yup.mixed().required('Required'),
});

interface PoolFormProps extends FormikDefaultFormProps {
    organisationId: string;
}

export const PoolForm: React.FC<PoolFormProps> = (props) => {
    const { organisationId, initialValues, children } = props;
    return (
        <FormikDefaultForm
            debug={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={props.onSubmit}
            onCancel={props.onCancel}
            submitOnEnter={false}
        >
            {children}
            <FormikInputField name={'name'} required={true} label="Pool Name" placeholder="e.g. Main Pool" />
            <FormikPoolVolumeInputField name={'volume'} required={true} label={'Pool Volume (Litres)'} />
            <FormikPoolTypeSelectField
                name={'type'}
                required={true}
                label={'Pool Type'}
                disabled={!organisationId}
                organisationId={organisationId}
            />
            <FormikPoolSanitiserSelectField
                name={'sanitiser'}
                required={true}
                label={'Sanitiser Type'}
                disabled={!organisationId}
                organisationId={organisationId}
            />
        </FormikDefaultForm>
    );
};
