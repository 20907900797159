import * as React from 'react';
import { Helmet } from '@poolware/app-shell';
import { PageControlProps } from './Page.Control';
import { NodeType } from '@poolware/api';
import PoolEquipmentPanel from './PoolEquipmentPanel';
import WaterTestHistory from './WaterTestHistory';
import {
    PrintPageHeader,
    PrintPageNoteText,
    PrintSection,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionItem,
    PrintSectionProps,
    stringFormatters,
    usePrintToggles,
} from '@poolware/components';

const PoolDetailsPanel: React.FC<PrintSectionProps & { pool: NodeType.Pool }> = ({ pool = { id: null }, ...rest }) => {
    const address = pool?.site?.address;
    const entity = pool?.entity;
    const customerCrn = pool?.entity?.user?.customer?.crn || '--';

    return (
        <PrintSection {...rest}>
            <PrintSectionHeader>Pool details</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintSectionItem label="Pool Number">{pool.bottleNumber || '--'}</PrintSectionItem>
                    <PrintSectionItem label="Pool Name">{pool.name || '--'}</PrintSectionItem>
                    <PrintSectionItem label="Volume">{pool.volume ? `${pool.volume} Litres` : '--'}</PrintSectionItem>
                    <PrintSectionItem label="Type" content={pool?.type?.name || '--'} />
                    <PrintSectionItem label="Sanitiser" content={pool?.sanitiser?.name || '--'} />
                </PrintSectionColumn>
                <PrintSectionColumn>
                    <PrintSectionItem label="Customer CRN">{customerCrn}</PrintSectionItem>
                    <PrintSectionItem label="Customer Name" content={stringFormatters.formatEntityName(entity)} />
                    <PrintSectionItem label="Address" content={stringFormatters.formatAddress(address)} />
                </PrintSectionColumn>
            </PrintSectionBody>
        </PrintSection>
    );
};

const PoolNotesPanel: React.FC<PrintSectionProps & { note: string }> = ({ note, ...rest }) => {
    return (
        <PrintSection {...rest}>
            <PrintSectionHeader>Pool Notes</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintPageNoteText formatted={true} limit={10000} value={note} />
                </PrintSectionColumn>
            </PrintSectionBody>
        </PrintSection>
    );
};

const PoolSitePanel: React.FC<PrintSectionProps & { pool: NodeType.Pool }> = ({ pool, ...rest }) => {
    return (
        <PrintSection {...rest}>
            <PrintSectionHeader>Site details</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintSectionItem label={'Site Name'} content={pool.site.name} />
                </PrintSectionColumn>
                <PrintSectionColumn>
                    <PrintSectionItem label={'Site Access'} content={pool.site.accessKeyLocation} />
                </PrintSectionColumn>
            </PrintSectionBody>
        </PrintSection>
    );
};

const PoolPage: React.FC<PageControlProps> = (props) => {
    const defaults = {
        site: false,
        notes: false,
        equipment: false,
        testHistory: false,
    };

    const toggleProps = usePrintToggles('print.toggles.pool', defaults);

    const { pool } = props;

    const pageTitle = `${pool.bottleNumber} - ${stringFormatters.formatEntityName(pool.entity)}`;

    return (
        <>
            <Helmet title={pageTitle} />
            <div>
                <PrintPageHeader>Pool Profile</PrintPageHeader>
                <PoolDetailsPanel pool={pool} />
                <PoolSitePanel pool={pool} {...toggleProps.site} />
                <PoolNotesPanel note={pool.note} {...toggleProps.notes} />
                <PoolEquipmentPanel poolId={pool.id} {...toggleProps.equipment} />
                <WaterTestHistory poolId={pool.id} {...toggleProps.testHistory} />
            </div>
        </>
    );
};

export default PoolPage;
