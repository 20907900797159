import { bindActionCreators, combineReducers } from 'redux';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { NodeType } from '@poolware/api';
import { createStructuredSelector } from 'reselect';
import { ActionsUnion, createAction, createAutoBoundReducerActions, createReducer } from '@ez/tools';
import { useMemo } from 'react';

//// ***** TYPES ****** ////

export type WarrantyDetailsType = {
    customer?: NodeType.Customer;
    staff?: NodeType.Staff;
    pool?: NodeType.Pool;
    address?: NodeType.Address;
    returnTo?: any;
};

export type BookingMode = {
    sagaMode?: boolean;
    sagaModeName?: string;
};

export enum BookingReturnDest {
    POOL = 'pool',
    CUSTOMER = 'customer',
    WARRANTY = 'warranty',
}

interface WarrantyStateType {
    details: WarrantyDetailsType;
    mode: BookingMode;
    returnToDetails: {
        returnDestination: BookingReturnDest;
        label: string;
    };
}

const initialState: WarrantyStateType = {
    details: {},
    mode: {
        sagaMode: false,
        sagaModeName: null,
    },
    returnToDetails: {
        returnDestination: BookingReturnDest.WARRANTY,
        label: 'Go To Warranty',
    },
};

const SCOPE = '@WarrantyBooking';

//// ***** ACTIONS ******** ////

export enum WARRANTY_ACTION {
    SET_DETAILS = '@WarrantyBooking/SET_DETAILS',
    NEW = '@WarrantyBooking/NEW',
    CREATED = '@WarrantyBooking/CREATED',
    RESET = '@WarrantyBooking/RESET',
    ABORT = '@WarrantyBooking/ABORT',
}

const WarrantyActionCustom = {
    newWarranty: (payload?: { details: WarrantyDetailsType; returnTo?: any }) =>
        createAction(WARRANTY_ACTION.NEW, payload),
    setDetails: (payload: Partial<WarrantyDetailsType>) => createAction(WARRANTY_ACTION.SET_DETAILS, payload),
    created: (payload?: any) => createAction(WARRANTY_ACTION.CREATED, payload),
    abort: () => createAction(WARRANTY_ACTION.ABORT),
    reset: () => createAction(WARRANTY_ACTION.RESET),
};

const ModeReducerActions = createAutoBoundReducerActions(initialState.mode, SCOPE, {
    setSagaMode: { field: 'sagaMode' },
    setSagaModeName: { field: 'sagaModeName' },
});

const modeReducer = createReducer(
    initialState.mode,
    (state, action: any) => {
        const reducer = ModeReducerActions.reducers[action.type];
        if (reducer) {
            reducer(state, action.payload);
        } else if (action.type === WARRANTY_ACTION.RESET) {
            return initialState.mode;
        }
        return state;
    },
    SCOPE
);

export const WarrantyAction = { ...ModeReducerActions.actions, ...WarrantyActionCustom };

////******** REDUCERS **********////

const detailsReducer = createReducer(
    initialState.details,
    (state, action: ActionsUnion<typeof WarrantyActionCustom>) => {
        switch (action.type) {
            case WARRANTY_ACTION.SET_DETAILS:
                return { ...state, ...action.payload };
            case WARRANTY_ACTION.RESET:
            case WARRANTY_ACTION.ABORT:
            case WARRANTY_ACTION.CREATED:
                return {};
            default:
                return state;
        }
    },
    SCOPE
);

export const reducer = combineReducers({
    details: detailsReducer,
    mode: modeReducer,
});

// Selectors
type SelectorExtended = {
    isSagaMode: boolean;
} & WarrantyStateType;
const _memoizedSelector = createStructuredSelector<WarrantyStateType, SelectorExtended>({
    details: (state) => state.details,
    mode: (state) => state?.mode,
    returnToDetails: (state) => state?.returnToDetails,
    isSagaMode: (state) => state?.mode?.sagaMode,
});

export const useWarrantyActions = () => {
    const state = useSelector((state: RootStateOrAny) => _memoizedSelector(state.warranty.booking));
    const dispatch = useDispatch();

    return useMemo(() => {
        return {
            State: state,
            Action: bindActionCreators(WarrantyAction, dispatch),
        };
    }, [dispatch, state]);
};
