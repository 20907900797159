import * as React from 'react';
import { useMemo } from 'react';

import { AppSidebarConfig, AppSidebarMenuItem } from '@poolware/components';
import { SemanticICONS } from 'semantic-ui-react';
import { ModuleNavLink } from '@poolware/react-app-navigator';
import { AppSidebarSecondary, SidebarSecondary } from '@poolware/components';

const ReloadableLinkComp = React.forwardRef<any, any>(({ to, router, onClick, exact = false, ...rest }, ref) => {
    return (
        <div ref={ref}>
            <ModuleNavLink to={to} exact={exact} {...rest} />
        </div>
    );
});

export const SidebarSecondaryMenuItemNavLink = SidebarSecondary.createMenuItem(ReloadableLinkComp);

export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = ({}) => {
    const createItemConf = (input: {
        to: string;
        icon?: SemanticICONS;
        secondaryIcon?: SemanticICONS;
        label: string | React.ReactNode;
        sub?: boolean;
        exact?: boolean;
        as?: any;
    }): AppSidebarMenuItem => {
        const { exact, icon, to, sub, label, secondaryIcon, as } = input;
        return {
            to,
            icon,
            exact,
            label: label,
            secondaryIcon: secondaryIcon,
            as: as || SidebarSecondaryMenuItemNavLink,
            sub: sub,
        };
    };

    const config: AppSidebarConfig = useMemo(
        () => ({
            menu: [
                {
                    header: '',
                    items: [
                        createItemConf({
                            to: '/products',
                            icon: 'cog',
                            label: 'Products',
                        }),
                        createItemConf({
                            to: '/docs',
                            icon: 'cog',
                            label: 'Product Docs',
                        }),
                    ],
                },
            ],
        }),
        []
    );

    return <AppSidebarSecondary config={config} />;
};
