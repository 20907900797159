import React, { useContext } from 'react';

export interface CalendarViewComponents {
    agenda: {
        date: React.ElementType;
        time: React.ElementType;
        event: React.ElementType;
    };
    day: {
        header: React.ElementType;
        event: React.ElementType;
    };
    week: {
        header: React.ElementType;
        event: React.ElementType;
    };
    month: {
        header: React.ElementType;
        dateHeader: React.ElementType;
        event: React.ElementType;
    };
}

export interface CalendarAccessorsFn {
    start: (any) => any;
    end: (any) => any;
    allDay: (any) => any;
    tooltip: (any) => any;
    title: (any) => any;
    resource: (any) => any;
    resourceId: (any) => any;
    resourceTitle: (any) => any;
}

export interface CalendarContextValueType {
    localizer: any;
    culture: any;
    longPressThreshold: number;
    accessors: CalendarAccessorsFn;
    getters: {
        eventProp: (...any) => any;
        slotProp: (...any) => any;
        slotGroupProp: (...any) => any;
        dayProp: (...any) => any;
    };
    rtl?: boolean;
    viewNames;
    components: {
        rootViewWrapper: React.ElementType;
        eventContainerWrapper: React.ElementType;
        event: React.ElementType;
        eventWrapper: React.ElementType;
        dateCellWrapper: React.ElementType;
        weekWrapper: React.ElementType;
        timeSlotWrapper: React.ElementType;
        timeGutterHeader: React.ElementType;
        resourceHeader: React.ElementType;
        toolbar: React.ElementType;
        header: React.ElementType;
    } & CalendarViewComponents;
}

export const RBCContext = React.createContext<CalendarContextValueType>(null);

export const useRbcContext = () => useContext(RBCContext);
