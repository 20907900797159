import * as React from 'react';
import { useState } from 'react';
import { FormikDefaultForm, FormikTextareaField } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { FeedEvent } from './FeedEvent';
import styled from 'styled-components';
import * as Yup from 'yup';
import { NotesForm } from './NotesForm';

const FormContainer = styled.div`
    margin: 0 0 0.75em 0.5em;
    padding: 0;
`;

export interface JobNote {
    id?: NodeType.ID;
    note?: string;
    createdAt?: Date;
    updatedAt?: Date;
    addedBy?: NodeType.Staff;
}

interface NotesFeedItemProps {
    note: JobNote;
    placeholder?: string;
    onSubmit?: (values: JobNote) => any;
    onDelete?: (values: JobNote) => any;
}

export const NotesFeedEvent: React.FC<NotesFeedItemProps> = (props) => {
    const [isEditing, setEditing] = useState(false);

    const { note: noteNode, placeholder, onSubmit, onDelete } = props;

    const handleOnSubmit = async (values: JobNote) => {
        await onSubmit(values);
        setEditing(false);
    };

    const handleOnDelete = () => {
        onDelete?.(noteNode);
    };

    const handleOnEdit = () => {
        setEditing(true);
    };

    if (!isEditing) {
        return <FeedEvent note={noteNode} onEdit={onSubmit && handleOnEdit} onDelete={onDelete && handleOnDelete} />;
    }

    const { note, ...rest } = noteNode;
    const initialValue = {
        note: note || '',
        ...rest,
    };

    return (
        <FormContainer>
            <NotesForm
                onSubmit={handleOnSubmit}
                submitButton={{ content: 'Update' }}
                onCancel={() => {
                    setEditing(false);
                }}
                initialValues={initialValue}
                placeholder={placeholder}
            />
        </FormContainer>
    );
};
