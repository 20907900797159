import * as React from 'react';
import { DataTable, TableDef } from './DataTable';

const testStateToHumanString = (State: '=' | '-' | '+' | '#' | string): string => {
    switch (State) {
        case '=':
            return 'OK';
        case '-':
            return 'under';
        case '+':
            return 'over';
        case '#':
            return 'error';
        default:
            return String(State);
    }
};

export const WaterTestResultTable: React.FC<{ data: any[] }> = ({ data }) => {
    /*
    {
          "Name": "390 nm",
          "Value": 0.59,
          "FactorCode": 1,
          "Decimals": 2,
          "Units": "AU",
          "State": "=",
          "TimeStamp": "2000-08-07 14:49:23.23",
          "Tag": null
        },

     */
    const tableDef: TableDef<any> = [
        {
            header: 'Name',
            cell: (pd) => pd.Name,
        },
        {
            header: 'Value',
            cell: (pd) => pd.Value,
        },
        {
            header: 'FactorCode',
            cell: (pd) => pd.FactorCode,
        },
        // {
        //     header: 'Decimals',
        //     cell: (pd) => pd.Decimals,
        // },
        {
            header: 'Units',
            cell: (pd) => pd.Units,
        },
        {
            header: 'State',
            cellProps: (pd) => {
                if (!pd) {
                    return undefined;
                }

                if (pd.State === '#') {
                    return { error: true };
                } else if (pd.State !== '=') {
                    return { warning: true };
                } else {
                    return undefined;
                }
            },
            cell: (pd) => testStateToHumanString(pd.State),
        },
        // {
        //     header: 'Tag',
        //     cell: (pd) => pd.Tag,
        // },
        // {
        //     header: 'TimeStamp',
        //     cell: (pd) => pd.TimeStamp,
        // },
    ];
    return <DataTable data={data} tableDef={tableDef} color={'green'} />;
};

export const WaterTestWarningsTable: React.FC<{ data: any[] }> = ({ data }) => {
    if (!data || data.length === 0) {
        return null;
    }

    /*
          {
            "Source":0,
            "Code":8,
            "Timestamp":"10/4/2018 1:38:54 PM",
            "Details":"Full Single Failure"
    }
     */
    const tableDef: TableDef<any> = [
        {
            header: 'Source',
            cellProps: () => ({ warning: true }),
            cell: (pd) => pd.Source,
        },
        {
            header: 'Code',
            cellProps: () => ({ warning: true }),
            cell: (pd) => pd.Code,
        },
        {
            header: 'Details',
            cellProps: () => ({ warning: true }),
            cell: (pd) => pd.Details,
        },
        // {
        //     header: 'TimeStamp',
        //     cellProps: () => ({ warning: true }),
        //     cell: (pd) => pd.TimeStamp,
        // },
    ];
    return <DataTable data={data} tableDef={tableDef} color={'orange'} />;
};

export const WaterTestErrorsTable: React.FC<{ data: any[] }> = ({ data }) => {
    if (!data || data.length === 0) {
        return null;
    }

    /*
          {
            "Source":0,
            "Code":8,
            "Timestamp":"10/4/2018 1:38:54 PM",
            "Details":"Full Single Failure"
    }

     */
    const tableDef: TableDef<any> = [
        {
            header: 'Source',
            cellProps: () => ({ error: true }),
            cell: (pd) => pd.Source,
        },
        {
            header: 'Code',
            cellProps: () => ({ error: true }),
            cell: (pd) => pd.Code,
        },
        {
            header: 'Details',
            cellProps: () => ({ error: true }),
            cell: (pd) => pd.Details,
        },
        // {
        //     header: 'Timestamp',
        //     cellProps: () => ({ error: true }),
        //     cell: (pd) => pd.TimeStamp,
        // },
    ];
    return <DataTable data={data} tableDef={tableDef} color={'red'} />;
};
