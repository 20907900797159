import * as React from 'react';
import { Field } from 'formik';
import { FormInput } from 'semantic-ui-react';
import * as _ from 'lodash';
import { TraitDeclarationPicker_TreeModal } from '../../../components-api-connected';

const ParentTraitDeclarationInput: React.FC<{ name: string }> = ({ name }) => {
    return (
        <Field
            name={name}
            render={(renderProps) => {
                const { form, field } = renderProps;
                const { name, value } = field;
                const errors = form.errors[name];
                // const rootError = typeof errors === 'string' ? errors : null;
                // const options = _.get(form.values, name);
                const onSelect = (parent) => {
                    form.setFieldValue(field.name, parent);
                };
                return (
                    <FormInput action label={'Parent Trait'} readOnly value={_.get(value, 'name') || ''}>
                        <input style={{ backgroundColor: '#f6f6f6' }} />
                        <TraitDeclarationPicker_TreeModal onSelect={onSelect} />
                    </FormInput>
                );
            }}
        />
    );
};

export default ParentTraitDeclarationInput;
