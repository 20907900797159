import _set from 'lodash/set';
import { NodeType, SortDirection, useDefaultConnectionState, useQueryConnection } from '@poolware/api';
import gql from 'graphql-tag';

const projectVersionFragment = gql`
    fragment ProjectVersionFragment on ProjectVersion {
        id
        releaseNumber
        description
        enabledFrom
        versionNumber
        remoteFileURL
        changelogSummary
        requirements
        fileURL
        project {
            id
        }
        fileUpload {
            id
        }
    }
`;

const QL = gql`
    query QueryProjectHostingConnection($first: Int, $page: Int) {
        viewer {
            projectHosting(first: $first, page: $page) {
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
                edges {
                    node {
                        id
                        uuid
                        name
                        platformKey
                        description
                        priority
                        enabled
                        enabledFrom
                        releaseCount
                        franchiseRestricted
                        remoteThumbnailURL
                        updatedAt
                        createdAt
                        pinnedVersion {
                            ...ProjectVersionFragment
                        }
                        latestRelease {
                            ...ProjectVersionFragment
                        }
                        recommendedVersion {
                            ...ProjectVersionFragment
                        }
                        thumbnailURL
                        fileURL
                        organisation {
                            id
                        }
                        thumbnailFile {
                            id
                        }
                    }
                }
            }
        }
    }
    ${projectVersionFragment}
`;

export interface UseQueryProjectHostingConnectionProps {
    searchClaimId?: string;
}

function mapPropsToSearchQuery(props?: UseQueryProjectHostingConnectionProps): any {
    if (!props) {
        return undefined;
    }

    const { searchClaimId } = props;
    const search: NodeType.WarrantyClaimViewerWarrantyClaimsSearch = {};
    if (searchClaimId) {
        _set(search, 'name.like', searchClaimId);
    }
    return search;
}

export function useQueryProjectHostingConnection(props?: UseQueryProjectHostingConnectionProps) {
    const connectionPath = 'viewer.projectHosting';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 40,
        sortKey: 'claim',
        sortDirection: SortDirection.DES,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.Project>({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
