import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import styled from 'styled-components';
import Page from './Page';

export const PageWrapperDiv = styled.div`
    display: block;
    width: 100%;
    margin: 0;
    padding: 1rem 1rem 3rem 1rem;

    @media screen and (max-width: 768px) {
        padding: 0.5rem;
    }
`;

export const Router = () => (
    <PageWrapperDiv>
        <ModuleRootSwitch>
            <ModuleRoute path={'/'} exact={true} component={Page} />
        </ModuleRootSwitch>
    </PageWrapperDiv>
);
