import gql from 'graphql-tag';
import { addressFragment, createUseQueryNodeHook, NodeType } from '@poolware/api';

const QL = gql`
    query CustomerAddressListQuery($id: ID!) {
        node(id: $id) {
            ... on Customer {
                id
                crn
                contactName
                root {
                    id
                    contacts {
                        edges {
                            node {
                                id
                                data
                                isPrimary
                                label
                                type {
                                    id
                                    identificationTag
                                    validatorTag
                                    name
                                }
                            }
                        }
                    }
                }
                sites(first: 100) {
                    edges {
                        node {
                            id
                            name
                            address {
                                ...AddressFragment
                            }
                            pools {
                                edges {
                                    node {
                                        id
                                        name
                                        bottleNumber
                                        address {
                                            id
                                            ...AddressFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                billAddress {
                    ...AddressFragment
                }
                primaryAddress {
                    ...AddressFragment
                }
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
    ${addressFragment}
`;

export function useQueryCustomerDetails(customerId: NodeType.ID) {
    return createUseQueryNodeHook<NodeType.Customer>(QL)(customerId);
}
