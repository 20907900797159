import { ModuleAccessViewer, useViewer } from '@poolware/app-shell';
import * as React from 'react';
import { MenuBarHeaderItem, MenuBarSection, PageLayout, StickyMenuBar } from '@poolware/components';

export interface ModulesAccessProps {}

export const ModulesAccess: React.FC<ModulesAccessProps> = ({}) => {
    const { modulesAccess, viewer } = useViewer();
    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem title={'Modules Access'} />
                </MenuBarSection>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-lg'}>
                <ModuleAccessViewer modulesAccess={modulesAccess} viewer={viewer} />
            </PageLayout.BodySection>
        </PageLayout>
    );
};
