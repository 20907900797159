import * as React from 'react';
import { Helmet, NotFoundPage } from '@poolware/app-shell';
import { ModuleLink, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { Divider, List } from 'semantic-ui-react';
import { DevOnly } from '@poolware/components';
import { RedirectToPDFReport } from './RedirectToPDFReport';
import { RedirectToNewWaterTest } from './RedirectToNewWaterTest';
import { RedirectToNewWarrantyClaim } from './RedirectToNewWarrantyClaim';
import { RedirectToNewAppointmentBooking } from './RedirectToNewAppointmentBooking';

const TEST_NEW_WATER_TEST_LINK = '/r/water-test/new/?testRefId=123123&poolNumber=P002-000053';
const TEST_NEW_WARRANTY_CLAIM_LINK = '/r/warranty-claims/new/?crn=002-000053';
const TEST_NEW_APPT_LINK = '/r/scheduler/new/?crn=002-000053';

export const TestLinks: React.FC = () => {
    return (
        <DevOnly>
            <h2>Test links</h2>
            <List>
                <List.Item>
                    <ModuleLink relativeToModule={true} to={`/report-pdf/VGVzdFJlcG9ydDo1MDE=`}>
                        Open PDF
                    </ModuleLink>
                </List.Item>
            </List>
            <List>
                <List.Item>
                    <ModuleLink
                        relativeToModule={false}
                        moduleId={'WATER_TEST'}
                        to={`/new?testRefId=123123&poolId=P002-000053`}
                    >
                        New Water test
                    </ModuleLink>
                </List.Item>
            </List>

            <Divider />
            <List>
                <List.Item>
                    <a href={TEST_NEW_WATER_TEST_LINK}>New Water test: {TEST_NEW_WATER_TEST_LINK}</a>
                </List.Item>
                <List.Item>
                    <a href={TEST_NEW_WARRANTY_CLAIM_LINK}>New Warranty claim: {TEST_NEW_WARRANTY_CLAIM_LINK}</a>
                </List.Item>
                <List.Item>
                    <a href={TEST_NEW_APPT_LINK}>New Appointment: {TEST_NEW_APPT_LINK}</a>
                </List.Item>
            </List>
        </DevOnly>
    );
};

export const Redirector: React.FC = ({}) => {
    return (
        <>
            <Helmet title={'Redirect'} />
            <ModuleRoot>
                <ModuleSwitch notFound={NotFoundPage}>
                    <ModuleRoute path={'/'} exact={true} component={TestLinks} />
                    <ModuleRoute path={'/report-pdf/:id'} component={RedirectToPDFReport} />
                    <ModuleRoute path={'/water-test/new'} component={RedirectToNewWaterTest} />
                    <ModuleRoute path={'/warranty-claims/new'} component={RedirectToNewWarrantyClaim} />
                    <ModuleRoute path={'/scheduler/new'} component={RedirectToNewAppointmentBooking} />
                </ModuleSwitch>
            </ModuleRoot>
        </>
    );
};
