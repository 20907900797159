import gql from 'graphql-tag';
import * as _ from 'lodash';
import {
    NodeType,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';

const QL = gql`
    query QueryWarrantyClaimCasesConnection(
        $first: Int
        $page: Int
        $sort: WarrantyClaimCaseViewerWarrantyClaimCasesSort
        $search: WarrantyClaimCaseViewerWarrantyClaimCasesSearch
    ) {
        viewer {
            warrantyClaimCases(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        externalSRN
                        lastMessageAt
                        lastMessageReadAt
                        claim {
                            id
                            status
                            statusMessage
                            statusGroup
                            warrantyClaimNumber
                            assetName
                            assetSerial
                            assetInstallDate
                            claimedAt
                            submittedAt
                            claimedAt
                            confirmedAt
                            createdAt
                            updatedAt
                            createdAt
                            franchise {
                                id
                                name
                                email
                            }
                            refCustomer {
                                id
                                contactName
                                companyName
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export interface UseQueryWarrantyClaimsConnectionProps
    extends QueryConnectionExternalProps<NodeType.WarrantyClaimCase> {
    searchClaimId?: string;
    franchiseId?: NodeType.ID;
    statusGroup?: NodeType.WarrantyClaimStatusGroupEnum | string;
    customerName?: string;
}

function mapPropsToSearchQuery(
    props?: UseQueryWarrantyClaimsConnectionProps
): NodeType.WarrantyClaimViewerWarrantyClaimsSearch {
    if (!props) {
        return undefined;
    }

    const { searchClaimId, statusGroup, customerName, franchiseId } = props;
    const search: NodeType.WarrantyClaimViewerWarrantyClaimsSearch = {};
    if (searchClaimId?.trim()) {
        _.set(search, 'claim.claimNumber', searchClaimId);
    }

    if (customerName?.trim()) {
        _.set(search, 'claim.refCustomer.anyString', customerName);
    }

    if (franchiseId) {
        _.set(search, 'claim.franchise.id', franchiseId);
    }

    if (statusGroup != undefined) {
        switch (statusGroup) {
            case NodeType.WarrantyClaimStatusGroupEnum.CLOSED:
            case NodeType.WarrantyClaimStatusGroupEnum.ON_HOLD:
            case NodeType.WarrantyClaimStatusGroupEnum.PROCESSING:
            case NodeType.WarrantyClaimStatusGroupEnum.NEW:
                _.set(search, 'claim.statusGroup.is', statusGroup);
                break;
        }
    }

    return search;
}

export function useQueryWarrantyClaimCaseConnection(props?: UseQueryWarrantyClaimsConnectionProps) {
    const connectionPath = 'viewer.warrantyClaimCases';

    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 30,
        sortKey: 'claim',
        sortDirection: SortDirection.DES,
        search: mapPropsToSearchQuery(props),
        ...props,
    });

    return useQueryConnection<NodeType.WarrantyClaimCase>({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
        pollInterval: 5 * 60000, //ms
    });
}
