import Page from './Page';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';

import { withApolloLoading } from '@poolware/components';
import { prepareCustomerContactItems } from '../../../CommonComponents/Contacts/Contacts';
import { customerFragment, NodeType, VendMutatorProps, withVendMutators } from '@poolware/api';
import {
    BookingActionProps,
    ServiceJobActionProps,
    withBookingActions,
    withServiceJobActions,
} from '@poolware/app-service-jobs';
import ContactItem from '../../../CommonComponents/Contacts/ContactItem';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';

const fragmentCustomerServiceJobs = gql`
    fragment CustomersServiceJobs on Customer {
        id
        #        serviceJobs(search: { isRecurring: { is: true } }, sort: { createdAt: false }) {
        #            edges {
        #                node {
        #                    id
        #                    serviceJobNumber
        #                    endsAt
        #                    title
        #                    createdAt
        #                }
        #            }
        #        }
    }
`;

const CustomerQuery = gql`
    query CustomerQuery($customerId: ID!) {
        customer: node(id: $customerId) {
            id
            ...CustomerFragment
            ...CustomersServiceJobs
            ... on Customer {
                id
                note
                registeredAt
                assignedTo {
                    id
                    user {
                        id
                        title
                        firstName
                        lastName
                    }
                }
                vendLink {
                    id
                }
            }
        }
    }
    ${fragmentCustomerServiceJobs}
    ${customerFragment}
`;

export default compose(
    withAppNavigator(),
    graphql(CustomerQuery, {
        options: (props: any) => ({
            variables: { customerId: props.params.customerId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading({ loaderPaths: 'data.customer', show404ForPath: 'data.customer.id' }),
    mapProps((props) => {
        let customer = props?.data?.customer;
        const contactItems = prepareCustomerContactItems(customer);
        return {
            ...props,
            customer,
            contactItems,
        };
    }),
    withBookingActions(),
    withServiceJobActions(),
    withVendMutators(['CustomerQuery'])
)(Page);

export interface PageControlProps
    extends IAppNavigatorProps,
        BookingActionProps,
        VendMutatorProps,
        ServiceJobActionProps {
    customer: NodeType.Customer;
    contactItems: ContactItem[][];
}
