import { InferProps } from 'prop-types';
import React from 'react';

import Week from './Week';
import TimeGrid from './TimeGrid';
import { commonViewProps } from './CalendarPropsTypes';

function workWeekRange(date, options) {
    return Week.range(date, options).filter((d) => [6, 0].indexOf(d.getDay()) === -1);
}

const propTypes = {
    ...commonViewProps,
};

interface WorkWeekProps extends InferProps<typeof propTypes> {}

class WorkWeek extends React.Component<WorkWeekProps> {
    public static propTypes = propTypes;
    public static defaultProps = TimeGrid.defaultProps;
    public static range = workWeekRange;
    public static navigate = Week.navigate;
    public static title = (date, { localizer }) => {
        let [start, ...rest] = workWeekRange(date, { localizer });
        return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
    };

    render() {
        let { date, ...props } = this.props;
        let range = workWeekRange(date, this.props);

        return <TimeGrid {...props} range={range} eventOffset={15} />;
    }
}

export default WorkWeek;
