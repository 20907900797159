import * as React from 'react';
import { ScrollableLayout, toastError, useModalCtrl } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { Modal } from 'semantic-ui-react';
import { useViewer } from '@poolware/app-shell';
import { AppointmentViewDeleteButtons, ApptDeleteMode } from './AppointmentView.DeleteButtons';
import { sagaAppointmentBookingRescheduleName, useBookingActions, useCalendarActions } from '../../redux';
import { AppointmentViewDockHeaderProps, AppointmentViewHeader } from './AppointmentView.Header';
import { AppointmentViewPanelAppointmentItem } from './PanelAppointmentItem/AppointmentView.PanelAppointmentItem';
import { PanelWorkOrder } from './PanelWorkOrder';
import { AppointmentViewPanelJobNote } from './AppointmentView.PanelJobNote';
import { usePanelNavigationStack } from '../PanelNavigationStack';
import tw, { css, styled } from 'twin.macro';
import { AppointmentEditBaseData } from './PanelAppointmentItem/AppointmentEdit.BaseData';
import { useAppointmentViewCtx } from './AppointmentViewContext';
import { RelatedAppointmentsMode, RelatedAppointmentsTable } from './PanelRelatedAppointments/RelatedAppointmentsTable';
import { AppointmentEditRecur } from './PanelAppointmentItem/AppointmentEdit.Recur';
import { AppointmentEditMode } from './components';

export interface AppointmentViewProps {
    appointmentItem?: NodeType.AppointmentItem;
    canCreateSale?: boolean;
    appointmentGroups?: NodeType.AppointmentGroup[];
    onClose: () => any | Promise<any>;
    onDidDelete?: () => any | Promise<any>;
    onShowInCalendar?: (item: NodeType.AppointmentItem) => any | Promise<any>;
    showServiceJobLink?: boolean;
    allowDockModeOption?: boolean;
    canShowDockModePin?: boolean;
}

const OrangeOverlay = styled.div(() => [
    tw`h-full absolute inset-0`,
    tw`border border-solid rounded-md`,
    css`
        border-color: #f2711c;
        user-select: none;
        pointer-events: none;
        z-index: 12;
        background: rgb(242 113 28 / 5%);

        &:before {
            user-select: none;
            pointer-events: none;
            content: ' ';
            display: block;
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(242 113 28 / 5%);
        }
    `,
]);

export const AppointmentView: React.FC<AppointmentViewProps> = (props) => {
    const { modulesAccess } = useViewer();
    const { CalendarAction } = useCalendarActions();
    const { BookingState } = useBookingActions();
    const panelStack = usePanelNavigationStack();
    const previewItemCtx = useAppointmentViewCtx();
    const deleteModalCtrl = useModalCtrl();

    const { appointmentItem, appointmentGroups, canCreateSale } = props;

    const isRescheduling =
        BookingState.mode?.sagaMode && BookingState.mode?.sagaModeName == sagaAppointmentBookingRescheduleName;

    const appointmentItemMutator = previewItemCtx.appointmentItemMutator;
    const serviceJobMutator = previewItemCtx.serviceJobMutator;
    const workOrderMutator = previewItemCtx.workOrderMutator;

    const canDelete = !modulesAccess.FieldServices?.calendarSingleStaffMode;
    const canEdit = !modulesAccess.FieldServices?.calendarSingleStaffMode;

    const onClose = () => {
        props.onClose?.();
    };

    const onDelete = async (deleteMode: ApptDeleteMode) => {
        if (!canDelete) {
            toastError({ title: 'Failed to delete appointment', description: 'No enough permissions' });
            onClose();
            return;
        }
        try {
            if (deleteMode === ApptDeleteMode.SINGLE) {
                await appointmentItemMutator.mutateAppointmentItem.delete({
                    id: appointmentItem.id,
                    future: false,
                });
            } else if (deleteMode === ApptDeleteMode.ALLFUTURE) {
                const isYes = window.confirm('Delete ALL Future Appointments?');
                if (!isYes) {
                    return;
                }
                await appointmentItemMutator.mutateAppointmentItem.delete({
                    id: appointmentItem.id,
                    future: true,
                    withinSharedPatternBlockId: true,
                });
            } else if (deleteMode === ApptDeleteMode.WO_AND_APPT) {
                await workOrderMutator.delete({
                    id: appointmentItem?.workOrder?.id,
                    keepImplicit: false,
                    keepAppointment: false,
                });
            }
            await props.onDidDelete?.();
            props.onClose();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete appointment', description: e.message });
        }
    };

    // Handle edit appointment event.
    const onAppointmentItemEdit = (mode: AppointmentEditMode) => {
        switch (mode) {
            case AppointmentEditMode.Single:
                panelStack.pushStackPanel({
                    component: AppointmentEditBaseData,
                    name: AppointmentEditMode.Single,
                    props: { appointmentItem: appointmentItem, future: false },
                });
                break;
            case AppointmentEditMode.Future:
                panelStack.pushStackPanel({
                    component: AppointmentEditBaseData,
                    name: AppointmentEditMode.Future,
                    props: { appointmentItem: appointmentItem, future: true },
                });
                break;
            case AppointmentEditMode.Recurrence:
                panelStack.pushStackPanel({
                    component: AppointmentEditRecur,
                    name: AppointmentEditMode.Recurrence,
                    props: { appointmentItem: appointmentItem, future: true },
                });
                break;
        }
    };

    const onChangeStatus = async (newStatus) => {
        try {
            await appointmentItemMutator.mutateAppointmentItem.update({
                id: NodeType.extractId(appointmentItem),
                status: newStatus,
            });

            /// HACK: move this logic to backend.
            const wo = appointmentItem?.workOrder;
            if (!wo?.stage) {
                return;
            }
            if (
                wo?.stage?.type === NodeType.ServiceJobStageType.Opened ||
                wo?.stage?.type !== NodeType.ServiceJobStageType.InProgress
            ) {
                // Change WO status only if appointment status is changed.
                const smap = {
                    finished: NodeType.ServiceJobStageType.Finished,
                    failed: NodeType.ServiceJobStageType.Canceled,
                };
                const woStatus = smap[newStatus];
                if (woStatus !== undefined) {
                    await workOrderMutator.update({ id: wo?.id, stageType: woStatus });
                }
            }

            ///
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change status', description: error.message });
        }
    };

    const onChangeStaff = async (newStaff) => {
        const currentStaffId = appointmentItem?.staff?.id;
        const newStaffId = newStaff?.id;

        if (currentStaffId === newStaffId) {
            return;
        }

        try {
            await appointmentItemMutator.AppointmentMutator.changeAppointmentItemStaff(appointmentItem, newStaff);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change staff', description: error.message });
        }
    };

    const onGroupChange = async (newGroup: NodeType.AppointmentGroup) => {
        const currentGroupId = appointmentItem?.group?.id;
        const newGroupId = newGroup?.id;

        if (currentGroupId === newGroupId) {
            return;
        }

        try {
            const res = await appointmentItemMutator.AppointmentMutator.changeAppointmentItemGroup(
                appointmentItem,
                newGroup
            );
            previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change group', description: error.message });
        }
    };

    const onColorChange = async (newColor) => {
        if (appointmentItem?.color === newColor) {
            return;
        }

        try {
            const res = await appointmentItemMutator.AppointmentMutator.changeAppointmentItemColor(
                appointmentItem,
                newColor
            );
            previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
        } catch (error) {
            console.error(error);
            toastError({ title: 'Failed to change color', description: error.message });
        }
    };

    const onChangeDescription = async (description: string) => {
        try {
            const hasWo = !!appointmentItem?.workOrder?.id;
            if (hasWo) {
                await workOrderMutator.update({ id: appointmentItem?.workOrder?.id, description: description });
            } else {
                const res = await appointmentItemMutator.AppointmentMutator.updateAppointmentItem(appointmentItem, {
                    note: description,
                });
                previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
            }
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onShowInCalendar = () => {
        props.onShowInCalendar?.(appointmentItem);
    };

    const onRelatedAppointmentSelect = (item: NodeType.AppointmentItem) => {
        CalendarAction.setPreviewAppt(item.id);
        previewItemCtx.setAppointmentItemId(item.id);
    };

    const dockHeaderProps: AppointmentViewDockHeaderProps = {
        appointmentItem: appointmentItem,
        appointmentGroups: appointmentGroups,
        canCreateSale: canCreateSale,
        canEdit: canEdit,
        onClose: onClose,
        onEdit: onAppointmentItemEdit,
        onDelete: canDelete ? deleteModalCtrl.onOpen : undefined,
        onChangeStatus: onChangeStatus,
        onShowInCalendar: onShowInCalendar,
        canShowDockModePin: props.canShowDockModePin,
    };

    return (
        <ScrollableLayout block={false} style={isRescheduling ? { pointerEvents: 'none' } : undefined}>
            {isRescheduling && <OrangeOverlay />}

            <AppointmentViewHeader {...dockHeaderProps} />
            <ScrollableLayout.BodyScroll>
                <div tw={'px-2 py-4 mb-4 space-y-4 text-sm'}>
                    <AppointmentViewPanelAppointmentItem
                        canEdit={canEdit}
                        onEdit={onAppointmentItemEdit}
                        appointmentItem={appointmentItem}
                        appointmentGroups={appointmentGroups}
                        onChangeStaff={onChangeStaff}
                        onGroupChange={onGroupChange}
                        onColorChange={onColorChange}
                    />
                    <AppointmentViewPanelJobNote // Job notes
                        appointmentItem={appointmentItem}
                        onChange={onChangeDescription}
                    />
                    <PanelWorkOrder
                        canEdit={canEdit}
                        appointmentItemMutator={appointmentItemMutator}
                        appointmentItem={appointmentItem}
                        onCloseAppointment={onClose}
                        serviceJobMutator={serviceJobMutator}
                        workOrderMutator={workOrderMutator}
                    />
                    {false && (
                        <RelatedAppointmentsTable
                            appointmentItem={appointmentItem}
                            mode={RelatedAppointmentsMode.FuturePast}
                            onAppointmentSelect={onRelatedAppointmentSelect}
                        />
                    )}
                </div>
            </ScrollableLayout.BodyScroll>
            {deleteModalCtrl.open && (
                <Modal open={deleteModalCtrl.open} onClose={deleteModalCtrl.onClose} size={'tiny'}>
                    <AppointmentViewDeleteButtons
                        appointmentItem={appointmentItem}
                        onDelete={onDelete}
                        onCancel={deleteModalCtrl.onClose}
                    />
                </Modal>
            )}
        </ScrollableLayout>
    );
};
