import { NodeType } from '@poolware/api';
import { tempByStateCity as tempByStateCityAU } from './tempbycity-AU';
import { tempByStateCity as tempByStateCityNZ } from './tempbycity-NZ';

export const tempConf = {
    AUS: tempByStateCityAU,
    NZ: tempByStateCityNZ,
};

export const monthAndDates = [
    { month: 'January', Days: 31, index: 0 },
    { month: 'February', Days: 28, index: 1 },
    { month: 'March', Days: 31, index: 2 },
    { month: 'April', Days: 30, index: 3 },
    { month: 'May', Days: 31, index: 4 },
    { month: 'June', Days: 30, index: 5 },
    { month: 'July', Days: 31, index: 6 },
    { month: 'August', Days: 31, index: 7 },
    { month: 'September', Days: 30, index: 8 },
    { month: 'October', Days: 31, index: 9 },
    { month: 'November', Days: 30, index: 10 },
    { month: 'December', Days: 31, index: 11 },
];

export interface HeaterModel {
    id: string;
    sku: string;
    name: string;
    capacity: number;
    input?: number;
    product?: NodeType.Product;
}

export interface GasHeaterModel extends HeaterModel {}

export interface HeatPumpModel extends HeaterModel {
    cop?: number;
}

export const fallbackGasHeaterCatalog: GasHeaterModel[] = [
    { id: '1', sku: '1', name: 'Turbotemp 125', capacity: 125 },
    { id: '2', sku: '2', name: 'Turbotemp 200', capacity: 200 },
    { id: '3', sku: '3', name: 'Turbotemp 300', capacity: 300 },
    { id: '4', sku: '4', name: 'Turbotemp 400', capacity: 400 },
    { id: '5', sku: '5', name: 'Turbotemp 800', capacity: 800 },
];

export const getGasHeaterByCapacity = (capacity: number) => {
    return fallbackGasHeaterCatalog.find((h) => h.capacity === capacity);
};

export const fallbackHeatPumpCatalog: HeatPumpModel[] = [
    { id: '278094', sku: '278094', name: 'Electroheat MKIV - 9kW', capacity: 9, input: 1.5 },
    { id: '278122', sku: '278122', name: 'Electroheat MKIV - 12kW', capacity: 12, input: 2.3 },
    { id: '2781210', sku: '2781210', name: 'Electroheat ECO-V - 12kW', capacity: 12, input: 2.1 },
    { id: '278151', sku: '278151', name: 'Electroheat MKIV - 15kW', capacity: 15, input: 2.9 },
    { id: '278191', sku: '278191', name: 'Electroheat MKIV - 19kW', capacity: 19, input: 3.8 },
    { id: '278231', sku: '278231', name: 'Electroheat MKIV - 23kW', capacity: 23, input: 4.2 },
    { id: '2782510', sku: '2782510', name: 'Electroheat ECO-V - 25kW', capacity: 25, input: 5.4 },
    { id: '2782533', sku: '2782533', name: 'Electroheat Plus 25 kW 3PH', capacity: 25, input: 5.5 },
    { id: '2783133', sku: '2783133', name: 'Electroheat Plus 31 kW 3PH', capacity: 31, input: 5.8 },
    { id: '2783733', sku: '2783733', name: 'Electroheat Plus 37 kW 3PH', capacity: 37, input: 6.9 },
    { id: '2784433', sku: '2784433', name: 'Electroheat Plus 44 kW 3PH', capacity: 44, input: 7.7 },
];

export const getHeaterByCapacity = (capacity: number) => {
    return fallbackHeatPumpCatalog.find((h) => h.capacity === capacity);
};
