import * as React from 'react';
import { Button, Header, Menu, Modal } from 'semantic-ui-react';
import * as ReactDOM from 'react-dom';

export type CallBackFnType = (future: boolean, cancelled: boolean) => any;
export interface ConfirmFutureProps {
    children: (input: { requestFutureConfirm: (fn: CallBackFnType) => any }) => any;
}

const ConfirmFutureModalContent: React.FC<{
    onConfirm: (future: boolean) => any;
    onCancel: () => any;
    open: boolean;
    component: React.ReactNode;
}> = ({ open, component, onConfirm, onCancel }) => {
    return (
        <Modal open={open} size={'small'} centered={false}>
            <Header icon="refresh" content="Changing recurring appointment" />
            <Modal.Content>
                {component}
                <Menu stackable fluid borderless secondary compact size={'mini'}>
                    <Menu.Menu position={'left'}>
                        <Menu.Item>
                            <Button
                                fluid
                                basic
                                color={'red'}
                                onClick={() => onConfirm(true)}
                                content={'Update All Future Appointments'}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <Button
                                fluid
                                basic
                                color={'purple'}
                                onClick={() => onConfirm(false)}
                                content={'Update Only This Appointment'}
                            />
                        </Menu.Item>
                    </Menu.Menu>
                    <Menu.Menu position="right">
                        <Menu.Item>
                            <Button fluid basic secondary onClick={onCancel} icon={'cancel'} content={'Cancel'} />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Modal.Content>
        </Modal>
    );
};

const confirmRoot = document.createElement('div');
const body = document.querySelector('body');
body.appendChild(confirmRoot);

export const confirmFutureImperative = async (confirm?): Promise<'cancel' | 'future' | 'current'> => {
    return new Promise((resolve) => {
        const giveAnswer = (answer: 'cancel' | 'future' | 'current') => {
            ReactDOM.unmountComponentAtNode(confirmRoot);
            resolve(answer);
        };

        ReactDOM.render(
            <ConfirmFutureModalContent
                onConfirm={(future) => giveAnswer(future ? 'future' : 'current')}
                onCancel={() => giveAnswer('cancel')}
                component={confirm}
                open={true}
            />,
            confirmRoot
        );
    });
};
