import gql from 'graphql-tag';
import { appointmentItemFragment, recurrenceFragment } from '@poolware/api';

export const connectionPath = 'viewer.appointmentItems';
export const AppointmentItemsQuery = gql`
    query CustomerAppointmentsList(
        $startDate: Date!
        $endDate: Date
        $first: Int
        $franchise: ID
        $customer: ID
        $pool: ID
        $reverse: Boolean
        $before: String
        $after: String
    ) {
        viewer {
            appointmentItems(
                before: $before
                after: $after
                startDate: $startDate
                endDate: $endDate
                first: $first
                franchise: $franchise
                customer: $customer
                pool: $pool
                reverse: $reverse
            ) {
                edges {
                    node {
                        id
                        ...AppointmentItemFragment
                        isRecurring
                        startDate
                        duration
                        appointment {
                            id
                            recurrence {
                                ...RecurrenceFragment
                            }
                        }
                        vendSale {
                            id
                            historyUrl
                        }
                        staff {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        pool {
                            id
                            volume
                            type {
                                id
                                name
                            }
                            site {
                                id
                                name
                                accessKeyLocation
                            }
                        }
                        vendSale {
                            id
                            historyUrl
                        }
                    }
                    cursor
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
            }
        }
    }
    ${appointmentItemFragment}
    ${recurrenceFragment}
`;
