import styled from 'styled-components';
import * as React from 'react';

const HighlightedToken = styled.span`
    background-color: yellow;
    color: black;
`;

const Highlighter: React.FC<{ inputText?: string; token?: string }> = ({ inputText, token }) => {
    let highlightedText = inputText;

    if (token) {
        const index = inputText.indexOf(token);
        if (index >= 0) {
            return (
                <>
                    {inputText.substring(0, index)}
                    <HighlightedToken>{inputText.substring(index, index + token.length)}</HighlightedToken>
                    {inputText.substring(index + token.length)}
                </>
            );
        }
    }
    return <>{highlightedText}</>;
};

export default Highlighter;
