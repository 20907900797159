import { fromEdges, NodeType } from '@poolware/api';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@poolware/components';
import Page from './Page';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

export const QL = gql`
    query PoolTestReportsList_Print($pageSize: Int, $page: Int, $poolId: ID!, $search: TestReportViewerReportsSearch!) {
        viewer {
            reports(first: $pageSize, page: $page, search: $search) {
                edges {
                    node {
                        id
                        isArchived
                        isFinalised
                        docVersion
                        createdAt
                        updatedAt
                        reportBy {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
            me {
                staff {
                    id
                }
            }
        }
        pool: node(id: $poolId) {
            ... on Pool {
                id
            }
        }
    }
`;

export default compose(
    graphql(QL, {
        options: (props: any) => ({
            variables: {
                pageSize: 5,
                poolId: props.poolId,
                page: 0,
                search: {
                    pool: {
                        id: props.poolId,
                    },
                },
            },
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.pool.id' }),
    mapProps((props) => {
        const reports = fromEdges(props?.data?.viewer?.reports);

        return {
            ...props,
            reports,
        };
    })
)(Page);

export interface PageControlProps {
    reports: NodeType.TestReport[];
}
