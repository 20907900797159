export namespace WaterlinkAPI {
    export interface AboutReply {
        version: string;
        documentation: string;
    }

    export interface StatusReply {
        Status: number;
        StatusString: string;
        RMN: string;
        SerialNum: string;
        Code: number;
        Progress: number;
        CanDoWatertest: boolean;
        CanGetLog: boolean;
        CanDoUpdate: boolean;
        Name: string;
        CurentFirmware: number;
        CurrentFirmwareString: string;
        AvailableFirmware: number;
        AvailFirmwareString: string;
    }

    export interface OptionType {
        Name: string;
        Value: number;
        Tags: string[];
    }

    export interface OptionsReply {
        SampleTypes?: OptionType[];
        ReagentTypes?: OptionType[];
    }

    export interface WatertestRequest {
        reagent: string;
        sample: string;
    }

    export type UnitType = 'ppm' | 'ppb' | string;

    export interface WatertestResultEntry {
        FactorCode: number;
        Units: UnitType;
        Name: string;
        Value: number;
        Decimals: number;
        State: '=' | '-' | '+' | '#' | string;
        TimeStamp: string;
        Tag: string;
    }

    export interface WatertestReply {
        Results: WatertestResultEntry[];
        Errors: {
            Source: number;
            Code: number;
            Timestamp: string;
            Details: string;
        }[];
        Warnings: {
            Source: number;
            Code: number;
            TimeStamp: string;
            Details: string;
        }[];
    }
}
