import { compose, mapProps } from '@ez/tools';
import Page from './TreatmentRecommendations';
import { recommendedTreatmentFragment, testProblemFragment, testReportFragment } from '../../graphql-fragments';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { fromEdges, NodeType, TestReportMutatorsProps, withTestReportMutators } from '@poolware/api';
import { withApolloLoading } from '@poolware/components';

const QL = gql`
    query ViewWaterTestRecommendations($reportId: ID!) {
        report: node(id: $reportId) {
            id
            ...TestReportFragment
            ... on TestReport {
                recommendations(first: 1000) {
                    edges {
                        node {
                            id
                            selectedTreatment {
                                id
                                ...RecommendedTreatmentFragment
                            }
                            problem {
                                id
                                ...TestProblemFragment
                            }
                            recommendedTreatments(first: 1000) {
                                edges {
                                    node {
                                        id
                                        ...RecommendedTreatmentFragment
                                    }
                                }
                            }
                        }
                    }
                }
                outcome(first: 1000) {
                    edges {
                        node {
                            value
                            measurementType {
                                id
                                ...MeasurementFragment
                            }
                        }
                    }
                }
            }
        }
    }
    ${testReportFragment}
    ${recommendedTreatmentFragment}
    ${testProblemFragment}
`;

export default compose(
    graphql(QL, {
        options: (props: any) => ({
            variables: { reportId: props.testId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading(),
    mapProps((props) => {
        let report = props?.data?.report as NodeType.TestReport;
        let chemicalTargets = fromEdges(report?.pool?.chemicalTargets);
        let manualProblems = fromEdges(report?.manualProblems);
        let discoveredProblems = fromEdges(report?.discoveredProblems);

        return {
            ...props,
            report,
            manualProblems,
            chemicalTargets,
            discoveredProblems,
        };
    }),
    withTestReportMutators(['ViewWaterTestRecommendations', 'ViewWaterTest'])
)(Page);

export interface PageControlProps extends TestReportMutatorsProps {
    report: NodeType.TestReport;
    chemicalTargets?: NodeType.PoolChemicalTarget[];
    manualProblems?: NodeType.ManualProblem[];
    discoveredProblems?: any[];
}
