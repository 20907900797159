import gql from 'graphql-tag';

import { NodeType, QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@poolware/api';
import { productDocumentFragment } from '../admin/ProductDocs/use-query-product-doc';
import { BrandingType } from './types';
import { BRAND_AQUATIGHT_TAG } from './use-equipment-catalog';

export const productTraitFragment = gql`
    fragment ProductTraitFragment_Calcs on ProductTrait {
        id
        product {
            id
            name
        }
        floatValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        integerValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        stringValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        selectionValues {
            id
            field {
                id
                tag
                name
            }
            value {
                id
                name
            }
        }
        flagValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        declaration {
            id
            name
            tag
            stringFields {
                id
                tag
                name
            }
        }
    }
`;

export const productFragment = gql`
    fragment ProductFragment_Calc on Product {
        id
        traits {
            id
            ...ProductTraitFragment_Calcs
        }
        name
        createdAt
        updatedAt
        sku
        customSku
        supplier {
            id
            name
        }
        brand {
            id
            name
            company {
                id
                name
            }
        }
        brochure {
            id
            title
            url
            mimeType
        }
        organisationType {
            id
            name
        }
        franchise {
            id
            name
        }
        documents {
            edges {
                node {
                    ...ProductDocumentFragment
                }
            }
        }
    }
    ${productTraitFragment}
    ${productDocumentFragment}
`;

const connectionPath = 'viewer.products';

const QLConnection = gql`
    query QueryProductsConnection(
        $first: Int
        $page: Int
        $search: ProductViewerProductsSearch
        $sort: ProductViewerProductsSort
        $searchTraits: ProductViewerProductsSearchTraits
    ) {
        viewer {
            products(first: $first, page: $page, search: $search, sort: $sort, searchTraits: $searchTraits) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        ...ProductFragment_Calc
                    }
                }
            }
        }
    }
    ${productFragment}
`;

export interface QueryProductConnectionExternalProps extends QueryConnectionExternalProps<NodeType.Product> {
    declarationTag?: string;
    flagTag?: {
        declarationTag: string;
        fieldTag: string;
        value: boolean;
    };
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
    brandType?: BrandingType;
}

function mapPropsToSearchQuery(props: QueryProductConnectionExternalProps) {
    const { declarationTag, organisationType } = props;
    let search: NodeType.ProductViewerProductsSearch = {};

    if (declarationTag) {
        search.declarations = {
            tag: {
                is: declarationTag,
            },
        };
    }

    if (organisationType) {
        search.organisationType = {
            id: NodeType.extractId(organisationType),
        };
    }

    return search;
}

const mapPropsToSearchTraitsQuery = (props: QueryProductConnectionExternalProps) => {
    const { flagTag, brandType } = props;
    const search: NodeType.ProductViewerProductsSearchTraits = {};

    if (flagTag?.fieldTag && flagTag?.declarationTag) {
        search.traitsValues = {
            and: [
                {
                    flag: {
                        value: flagTag.value,
                        tag: {
                            fieldTag: flagTag?.fieldTag,
                            declarationTag: flagTag?.declarationTag,
                            organisation: NodeType.extractIdOrUndefined(props.organisationType),
                        },
                    },
                },
            ],
        };
        search.traitsValues.and.push({
            flag: {
                value: brandType === BrandingType.Aquatight,
                tag: {
                    fieldTag: BRAND_AQUATIGHT_TAG,
                    declarationTag: flagTag?.declarationTag,
                    organisation: NodeType.extractIdOrUndefined(props.organisationType),
                },
            },
        });
    }

    return search;
};

export function useQueryProductConnection(props?: Omit<QueryProductConnectionExternalProps, 'children'>) {
    const { variables, connectionState } = useDefaultConnectionState({
        search: mapPropsToSearchQuery(props),
        defaultPageSize: 100,
        variables: { searchTraits: mapPropsToSearchTraitsQuery(props) },
    });

    return useQueryConnection<NodeType.Product>({
        query: props.query || QLConnection,
        fetchPolicy: props.fetchPolicy,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
