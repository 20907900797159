import clsx from 'clsx';
import PropTypes, { InferProps } from 'prop-types';
import React, { Component } from 'react';

import * as TimeSlotUtils from './utils/TimeSlots';
import { TimeSlotGroup } from './TimeSlotGroup';
import { commonProps } from './CalendarPropsTypes';
import { RBCContext } from './CalendarContext';

const propTypes = {
    ...commonProps,
    min: PropTypes.instanceOf(Date).isRequired,
    max: PropTypes.instanceOf(Date).isRequired,
    timeslots: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    getNow: PropTypes.func.isRequired,
    resource: PropTypes.string,
    date: PropTypes.instanceOf(Date).isRequired,
};

interface TimeGutterProps extends InferProps<typeof propTypes> {
    className?: string;
    width: number;
}

export default class TimeGutter extends Component<TimeGutterProps> {
    public static propTypes = propTypes;
    private slotMetrics: ReturnType<typeof TimeSlotUtils.getSlotMetrics>;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    constructor(props, context) {
        super(props, context);

        const { min, max, timeslots, step } = this.props;
        this.slotMetrics = TimeSlotUtils.getSlotMetrics({
            min,
            max,
            timeslots,
            step,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { min, max, timeslots, step } = nextProps;
        this.slotMetrics = this.slotMetrics.update({
            min,
            max,
            timeslots,
            step,
        });
    }

    renderSlot = (value, idx) => {
        if (idx !== 0) return null;
        const { getNow } = this.props;
        const { localizer } = this.context;

        const isNow = this.slotMetrics.dateIsInGroup(getNow(), idx);
        return (
            <span className={clsx('rbc-label', isNow && 'rbc-now')}>{localizer.format(value, 'timeGutterFormat')}</span>
        );
    };

    render() {
        const { resource } = this.props;

        return (
            <div className="rbc-time-gutter rbc-time-column" style={{ width: this.props.width }}>
                {this.slotMetrics.groups.map((grp, idx) => {
                    return <TimeSlotGroup key={idx} group={grp} resource={resource} renderSlot={this.renderSlot} />;
                })}
            </div>
        );
    }
}
