import { FilterItem, FilterType, ProductCatalogActionProps } from '../../redux';
import { ProductTraitFlattened, ProductTraitFlattenedValue } from '../../utils';
import { NodeType, TraitValueType } from '@poolware/api';
import invariant from 'invariant';
import produce from 'immer';
import * as _ from 'lodash';

export interface FilterPill {
    type: FilterType;
    typeName: string;
    filterValue: string;
    onClick: () => any;
}

type FilterToPillResolverFn = (filterItem: FilterItem, onClick: () => any) => FilterPill;

const filterToPillResolver: { [key in FilterType]?: FilterToPillResolverFn } = {
    [FilterType.NAME]: (filterItem, onClick) => ({
        type: FilterType.NAME,
        typeName: 'Name',
        filterValue: filterItem.value,
        onClick: onClick,
    }),
    [FilterType.SKU]: (filterItem, onClick) => ({
        type: FilterType.SKU,
        typeName: 'SKU',
        filterValue: filterItem.value,
        onClick: onClick,
    }),
    [FilterType.CUSTOM_SKU]: (filterItem, onClick) => ({
        type: FilterType.CUSTOM_SKU,
        typeName: 'Custom SKU',
        filterValue: filterItem.value,
        onClick: onClick,
    }),
    [FilterType.BRAND]: (filterItem, onClick) => ({
        type: FilterType.BRAND,
        typeName: 'Brand',
        filterValue: filterItem.value.name,
        onClick: onClick,
    }),
    [FilterType.PRODUCT_COMPANY]: (filterItem, onClick) => ({
        type: FilterType.PRODUCT_COMPANY,
        typeName: 'Company',
        filterValue: filterItem.value.name,
        onClick: onClick,
    }),
    [FilterType.SUPPLIER]: (filterItem, onClick) => ({
        type: FilterType.SUPPLIER,
        typeName: 'Supplier',
        filterValue: filterItem.value.name,
        onClick: onClick,
    }),
    [FilterType.DECLARATION]: (filterItem, onClick) => ({
        type: FilterType.DECLARATION,
        typeName: 'Trait',
        filterValue: filterItem.value.name,
        onClick: onClick,
    }),
};

export interface PillSelectOption<T = any> {
    active: boolean;
    name: string;
    onClick: () => any;
}

export interface PillSelect<T = any> {
    name: string;
    subName?: string;
    options: PillSelectOption<T>[];
    invertOption?: PillSelectOption<T>;
}

export interface TraitFieldValueSelectPills<T = any> {
    traitField: any;
    select: PillSelect<T>;
}

export interface TraitDeclarationPills<T = any> {
    declaration: NodeType.ProductTraitDeclaration;
    traitFieldSelects: TraitFieldValueSelectPills<T>[];
}

type PreparePillUIModelInputType = {
    flattenedDeclarations?: ProductTraitFlattened[];
    relatedBrands?: NodeType.Brand[];
    relatedCompanies?: NodeType.ProductCompany[];
} & ProductCatalogActionProps;

const prepareFilterPills = (input: PreparePillUIModelInputType): FilterPill[] => {
    const { ProductCatalogState, ProductCatalogAction } = input;
    invariant(ProductCatalogState, 'ProductCatalogState is undefined');
    const { filters } = ProductCatalogState;
    invariant(filters, 'ProductCatalogState.filters is undefined');
    const { filterItems } = filters;

    const pills: FilterPill[] = filterItems
        .map<FilterPill>((filterItem) => {
            const resolver = filterToPillResolver[filterItem.type];
            const filter = filterItem.value;
            if (!resolver || !filter) {
                return undefined;
            }
            const onClick = () => {
                ProductCatalogAction.removeFilterItem(filterItem);
            };
            return resolver(filterItem, onClick);
        })
        .filter(Boolean);

    return pills;
};

export const prepareTraitDeclarationPills = (input: PreparePillUIModelInputType): TraitDeclarationPills[] => {
    const { ProductCatalogState, ProductCatalogAction, flattenedDeclarations } = input;
    invariant(ProductCatalogState, 'ProductCatalogState is undefined');
    const { filters } = ProductCatalogState;
    invariant(filters, 'ProductCatalogState.filters is undefined');
    const { filterItems } = filters;

    const prepareTraitFieldSelects_SelectionType = (
        fd: ProductTraitFlattened,
        value: ProductTraitFlattenedValue
    ): PillSelect<NodeType.ProductDeclarationSelectionChoice> => {
        invariant(value.type === TraitValueType.Selection, 'Wrong type');
        const traitField = value.traitField;
        invariant(traitField, 'traitField is undefined');
        const traitFieldId = traitField.id;

        // Get field options options
        const options: NodeType.ProductDeclarationSelectionChoice[] = traitField.options;
        // find corresponding FilterItem stored in the store.

        const findOrCreateFilterItem = (traitFieldId: string) => {
            const storedFilterItem = filterItems.find((fi) => {
                return fi.filterId === traitFieldId;
            });

            // There is no filter item stored yet. Create a new one
            const defaultFilterItem = {
                type: FilterType.TRAIT_FIELD,
                filterId: traitFieldId,
                value: {
                    type: TraitValueType.Selection,
                    missingFieldId: undefined,
                    missing: false,
                    isOr: [],
                },
            };

            return produce(defaultFilterItem, (draft) => {
                return _.merge(draft, storedFilterItem);
            });
        };

        const storedFilterItem = findOrCreateFilterItem(traitFieldId);

        const prepareSelectOptionItem = (o: NodeType.ProductDeclarationSelectionChoice, allowMany = true) => {
            const isActive = storedFilterItem.value.isOr.includes(o.id);
            if (!isActive) {
                const nextFilter = produce(storedFilterItem, (nextState) => {
                    if (allowMany) {
                        nextState.value.isOr.push(o.id);
                    } else {
                        nextState.value.isOr = [o.id];
                    }
                    nextState.value.missing = false;
                    nextState.value.missingFieldId = undefined;
                });
                return {
                    active: isActive,
                    name: o.name,
                    onClick: () => ProductCatalogAction.addFilterItem(nextFilter),
                };
            } else {
                const nextFilter = produce(storedFilterItem, (nextState) => {
                    nextState.value.isOr = nextState.value.isOr.filter((oId) => oId !== o.id);
                    nextState.value.missing = false;
                });
                if (nextFilter.value.isOr.length === 0) {
                    return {
                        active: isActive,
                        name: o.name,
                        onClick: () => ProductCatalogAction.removeFilterItem(nextFilter),
                    };
                } else {
                    return {
                        active: isActive,
                        name: o.name,
                        onClick: () => ProductCatalogAction.addFilterItem(nextFilter),
                    };
                }
            }
        };

        const optionItems: PillSelectOption<NodeType.ProductDeclarationSelectionChoice>[] = options.map((o) =>
            prepareSelectOptionItem(o)
        );

        const prepareInvertOption = () => {
            const isActive = storedFilterItem.value.missing === true;
            if (!isActive) {
                const nextFilter = produce(storedFilterItem, (nextState) => {
                    nextState.value.isOr = [];
                    nextState.value.missing = true;
                    nextState.value.missingFieldId = traitFieldId;
                });
                return {
                    active: isActive,
                    name: 'Not Set',
                    onClick: () => ProductCatalogAction.addFilterItem(nextFilter),
                };
            } else {
                const nextFilter = produce(storedFilterItem, (nextState) => {
                    nextState.value.isOr = [];
                    nextState.value.missing = false;
                    nextState.value.missingFieldId = undefined;
                });
                return {
                    active: isActive,
                    name: 'Not Set',
                    onClick: () => ProductCatalogAction.removeFilterItem(nextFilter),
                };
            }
        };
        const invertOption = prepareInvertOption();

        return {
            name: fd.trait.name,
            subName: value.name,
            options: optionItems,
            invertOption: invertOption,
        };
    };

    const prepareTraitFieldSelects = (fd: ProductTraitFlattened) => {
        const prepareTraitFieldValueSelect = (value) => {
            if (value.type === TraitValueType.Selection) {
                return {
                    traitField: value.traitField,
                    select: prepareTraitFieldSelects_SelectionType(fd, value),
                };
            }
            return undefined;
        };

        const traitFieldValuePills: TraitFieldValueSelectPills[] = fd.values
            .map(prepareTraitFieldValueSelect)
            .filter(Boolean);

        return {
            declaration: fd.trait,
            traitFieldSelects: traitFieldValuePills,
        };
    };

    return flattenedDeclarations.map(prepareTraitFieldSelects);
};

function prepareBrandsSelect(input: PreparePillUIModelInputType): PillSelect<NodeType.Brand> {
    const { relatedBrands, ProductCatalogState, ProductCatalogAction } = input;
    invariant(ProductCatalogState, 'ProductCatalogState is undefined');
    const { filters } = ProductCatalogState;
    invariant(filters, 'ProductCatalogState.filters is undefined');
    const { filterItems } = filters;

    const prepareOptions = (brands?: NodeType.Brand[]): PillSelectOption<NodeType.Brand>[] => {
        if (!brands || brands.length == 0) {
            return [];
        }
        const checkActive = (option: NodeType.Brand) => {
            return (
                filterItems.findIndex((fi) => {
                    if (fi.type !== FilterType.BRAND) return false;
                    return fi.filterId === option.id;
                }) !== -1
            );
        };

        const remove = (node: NodeType.Brand) => () => {
            ProductCatalogAction.removeFilterItem({ type: FilterType.BRAND, filterId: null, value: node });
        };

        const replace = (node: NodeType.Brand) => () => {
            ProductCatalogAction.replaceFilterItem({ type: FilterType.BRAND, filterId: node.id, value: node });
        };

        return brands.map((o) => {
            const isActive = checkActive(o);
            return {
                active: isActive,
                name: o.name,
                onClick: isActive ? remove(o) : replace(o),
            };
        });
    };

    return {
        name: 'Brands',
        options: prepareOptions(relatedBrands),
    };
}

function prepareProductCompaniesSelect(input: PreparePillUIModelInputType): PillSelect<NodeType.ProductCompany> {
    const { relatedCompanies, ProductCatalogState, ProductCatalogAction } = input;
    invariant(ProductCatalogState, 'ProductCatalogState is undefined');
    const { filters } = ProductCatalogState;
    invariant(filters, 'ProductCatalogState.filters is undefined');
    const { filterItems } = filters;

    const prepareOptions = (
        productCompanies?: NodeType.ProductCompany[]
    ): PillSelectOption<NodeType.ProductCompany>[] => {
        if (!productCompanies || productCompanies.length == 0) {
            return [];
        }
        const checkActive = (option: NodeType.ProductCompany) => {
            return (
                filterItems.findIndex((fi) => {
                    if (fi.type !== FilterType.PRODUCT_COMPANY) return false;
                    return fi.filterId === option.id;
                }) !== -1
            );
        };

        const remove = (node: NodeType.ProductCompany) => () => {
            ProductCatalogAction.removeFilterItem({ type: FilterType.PRODUCT_COMPANY, filterId: null, value: node });
        };

        const replace = (node: NodeType.ProductCompany) => () => {
            ProductCatalogAction.replaceFilterItem({
                type: FilterType.PRODUCT_COMPANY,
                filterId: node.id,
                value: node,
            });
        };

        return productCompanies.map((o) => {
            const isActive = checkActive(o);
            return {
                active: isActive,
                name: o.name,
                onClick: isActive ? remove(o) : replace(o),
            };
        });
    };

    return {
        name: 'Brands',
        options: prepareOptions(relatedCompanies),
    };
}

export const preparePillsUIModel = (input: PreparePillUIModelInputType) => {
    return {
        pills: prepareFilterPills(input),
        traitDeclarationPills: prepareTraitDeclarationPills(input),
        brandSelect: prepareBrandsSelect(input),
        productCompanySelect: prepareProductCompaniesSelect(input),
    };
};
