/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import * as React from 'react';
import * as ReactGA from 'react-ga';

import { getAppConfig, getRollbar } from './app-config';
import { makeDebugger } from '@ez/tools';
import { RouteComponentProps, withRouter } from 'react-router';
const debug = makeDebugger('tracker');

const config = getAppConfig();
const token = config?.google_analytics?.analyticsId;

let gaInitialized = false;

if (token) {
    ReactGA.initialize(token);
    gaInitialized = true;
}

export const trackException = ({ fatal, error, info }) => {
    if (config.rollbar.enabled) {
        const Rollbar = getRollbar();
        if (Rollbar) {
            if (fatal) {
                Rollbar.critical(error);
            } else {
                Rollbar.error(error);
            }
        }
    }
};

export const setTrackId = (trackId) => {
    if (!trackId) {
        return;
    }

    if (gaInitialized) {
        ReactGA.set({
            userId: trackId,
        });
    }

    const Rollbar: any = getRollbar();

    if (Rollbar) {
        Rollbar.configure({
            payload: {
                person: {
                    id: trackId,
                },
            },
        });
    }
};

export const initRollbar = () => {
    const Rollbar: any = getRollbar();
    const config = getAppConfig();

    if (Rollbar) {
        const { rollbar } = config;
        Rollbar.configure({
            accessToken: rollbar.accessToken,
            payload: {
                environment: rollbar.environment,
                client: {
                    javascript: {
                        source_map_enabled: true,
                        code_version: rollbar.code_version,
                        guess_uncaught_frames: true,
                    },
                },
            },
        });
    }
};

const isDevelopment = process.env.NODE_ENV === 'development';

export const withTracker =
    (options = {}) =>
    (WrappedComponent) => {
        if (!isDevelopment && !gaInitialized) {
            return WrappedComponent;
        }

        const trackPage = (page) => {
            debug('page: ', page);
            if (!gaInitialized) {
                return;
            }

            ReactGA.set({
                page,
                ...options,
            });
            ReactGA.pageview(page);
        };

        class WithTracker extends React.Component<RouteComponentProps> {
            public static displayName = `withTracker(${WrappedComponent.displayName || WrappedComponent.name}`;

            getPathname = (props) => {
                if (!props || !props.location) {
                    return '/';
                }
                return props.location.pathname;
            };

            componentDidMount() {
                const page = this.getPathname(this.props);
                trackPage(page);
            }

            UNSAFE_componentWillReceiveProps(nextProps) {
                const currentPage = this.getPathname(this.props);
                const nextPage = this.getPathname(nextProps);

                if (currentPage !== nextPage) {
                    trackPage(nextPage);
                }
            }

            render() {
                return <WrappedComponent {...this.props} />;
            }
        }

        return withRouter(WithTracker);
    };
