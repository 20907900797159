import * as React from 'react';
import gql from 'graphql-tag';
import { NodeType, QueryConnection, QueryConnectionExternalProps, useDefaultConnectionState } from '@poolware/api';

const connectionPath = 'viewer.productCompany';
const QL = gql`
    query ProductCompanies(
        $first: Int
        $page: Int
        $search: ProductCompanyViewerProductCompanySearch
        $sort: ProductCompanyViewerProductCompanySort
    ) {
        viewer {
            productCompany(first: $first, page: $page, search: $search, sort: $sort) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        name
                        description
                        createdAt
                        updatedAt
                        parentCompany {
                            id
                            name
                        }
                        franchise {
                            id
                            name
                        }
                        brands {
                            edges {
                                node {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export interface QueryProductCompanyConnectionProps extends QueryConnectionExternalProps<NodeType.ProductCompany> {
    searchName?: string;
}

export const QueryProductCompanyConnection: React.FC<QueryProductCompanyConnectionProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.search = { name: { like: props.searchName } };
    variables.sort = { updatedAt: false };

    return (
        <QueryConnection
            query={props.query || QL}
            variables={variables}
            connectionConfig={connectionState}
            connectionPath={connectionPath}
            children={props.children}
        />
    );
};
