import {
    addressFragment,
    NodeType,
    QueryConnectionConfigProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';

const QL = gql`
    query QueryFranchisesConnection(
        $page: Int
        $sort: FranchiseViewerFranchisesSort
        $search: FranchiseViewerFranchisesSearch
    ) {
        viewer {
            franchises(first: 25, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        name
                        email
                        types
                        phoneNumber
                        address {
                            id
                            ...AddressFragment
                        }
                        organisationType {
                            id
                            name
                            logo
                        }
                        manager {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        staffCount
                        createdAt
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
    ${addressFragment}
`;

export interface QueryFranchisesConnectionProps extends Partial<QueryConnectionConfigProps> {
    name?: string;
}

const mapPropsToSearchQuery = (props: QueryFranchisesConnectionProps): NodeType.FranchiseViewerFranchisesSearch => {
    if (props?.name) {
        return { name: { like: props.name } };
    }
    return {};
};

export function useQueryFranchisesConnection(props?: QueryFranchisesConnectionProps) {
    const connectionPath = 'viewer.franchises';
    const { variables, connectionState } = useDefaultConnectionState(props);

    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection({
        query: QL,
        // fetchPolicy: 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
