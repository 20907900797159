import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@poolware/components';
import { fromEdges, NodeType, siteFragment } from '@poolware/api';

import Sites from './Sites';

const SitesQuery = gql`
    query CustomerSitesList($customerId: ID!) {
        customer: node(id: $customerId) {
            ... on Customer {
                id
                sites(first: 100) {
                    edges {
                        node {
                            id
                            ...SiteFragment
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }
        }
    }
    ${siteFragment}
`;

export default compose(
    graphql(SitesQuery, {
        options: (props: any) => ({
            variables: { customerId: props.customerId },
        }),
    }),
    withApolloLoading(),
    mapProps((props) => {
        return {
            ...props,
            sites: fromEdges(props?.data?.customer?.sites),
        };
    })
)(Sites);

export interface ControlProps {
    sites: NodeType.Site[];
    customerId: NodeType.ID;
}
