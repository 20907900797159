import * as React from 'react';
import { Form } from 'semantic-ui-react';
import {
    DevOnly,
    FormikCheckboxField,
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikInputField,
    FormSectionHeader,
    LinkButton,
} from '@poolware/components';
import { FormikContactsInputFields } from './FormikContactsInputFields';
import {
    AddressValidationSchema,
    FormikAddressInputFields,
} from '../../../CommonComponents/Address/FormikAddressInputFields';
import { getIn } from 'formik';
import { useViewer } from '@poolware/app-shell';
import * as Yup from 'yup';
import { validationSchemaContactItem } from '../../../CommonComponents/Contacts/validation-schemas';
// import ButtonFaker from './faker';

const SimpleString = Yup.string().max(200, 'Too Long!').nullable();

export const validationSchemaCustomerForm = Yup.object({
    user: Yup.object({
        firstName: SimpleString.required('Required'),
        lastName: SimpleString.required('Required'),
        contactName: SimpleString.nullable().notRequired(),
        companyName: SimpleString.nullable().notRequired(),
    }),
    primaryAddress: AddressValidationSchema.required('Required'),
    billAddress: Yup.object().when('isBillingAddressSame', {
        is: false,
        then: AddressValidationSchema.required('Required'),
        otherwise: Yup.object().notRequired(),
    }),
    site: Yup.object().when('isCreateSiteWithSameAddress', {
        is: true,
        then: Yup.object().shape({
            name: SimpleString.required('Required'),
        }),
        otherwise: Yup.object().notRequired(),
    }),
    isBillingAddressSame: Yup.boolean().required(),
    isCreateSiteWithSameAddress: Yup.boolean().required(),
    // @ts-ignore
    contacts: Yup.array().of(validationSchemaContactItem).max(10, 'Max 10 contacts').notRequired(),
});

const ClearButton: React.FC<{ onClick }> = ({ onClick }) => {
    return (
        <LinkButton style={{ color: '#284d8a' }} onClick={onClick}>
            clear
        </LinkButton>
    );
};

// export const NO_CUSTOMER_TITLE_VALUE = '';

export const customerTitleOptions = [
    // { key: 1, value: NO_CUSTOMER_TITLE_VALUE, text: '--' },
    { key: 'Mr.', value: 'Mr.', text: 'Mr.' },
    { key: 'Mrs.', value: 'Mrs.', text: 'Mrs.' },
    { key: 'Miss', value: 'Miss', text: 'Miss' },
    { key: 'Mx.', value: 'Mx.', text: 'Mx.' },
    { key: 'Dr.', value: 'Dr.', text: 'Dr.' },
    { key: 'Prof.', value: 'Prof.', text: 'Prof.' },
    { key: 'Rev.', value: 'Rev.', text: 'Rev.' },
];

const FormFragmentUserNameFields: React.FC = () => {
    const { modulesAccess } = useViewer();
    const shouldShowTitle = modulesAccess.Customers?.nameTitle;
    if (shouldShowTitle) {
        return (
            <Form.Group>
                <FormikInputField
                    width={3}
                    name={'user.title'}
                    autoComplete={'disabled'}
                    label="Title"
                    datalist={customerTitleOptions}
                    required={false}
                />
                <FormikInputField
                    width={6}
                    name={'user.firstName'}
                    label="First Name"
                    autoComplete={'disabled'}
                    required={true}
                />
                <FormikInputField
                    width={7}
                    name={'user.lastName'}
                    autoComplete={'disabled'}
                    label="Last Name"
                    required={true}
                />
            </Form.Group>
        );
    } else {
        return (
            <Form.Group widths={'equal'}>
                <FormikInputField
                    name={'user.firstName'}
                    label="First Name"
                    autoComplete={'disabled'}
                    required={true}
                />
                <FormikInputField name={'user.lastName'} autoComplete={'disabled'} label="Last Name" required={true} />
            </Form.Group>
        );
    }
};

export interface NewCustomerFormProps extends FormikDefaultFormProps {
    hideNewSite: boolean;
}

const NewCustomerForm: React.FC<NewCustomerFormProps> = (props) => {
    const { validationSchema, onSubmit, onCancel, initialValues } = props;
    return (
        <FormikDefaultForm
            validationSchema={validationSchema || validationSchemaCustomerForm}
            debug={true}
            header={'New Customer'}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitButton={{ content: 'Create' }}
        >
            {(formBag) => {
                const { values } = formBag;
                const isBillingAddressSame = getIn(values, 'isBillingAddressSame');
                const isCreateSiteWithSameAddress = getIn(values, 'isCreateSiteWithSameAddress');
                const onClearPrimAddress = () => {
                    formBag.setFieldValue('primaryAddress', formBag.initialValues?.primaryAddress);
                };

                const onClearBillAddress = () => {
                    formBag.setFieldValue('billAddress', formBag.initialValues?.billAddress);
                };

                return (
                    <>
                        <FormSectionHeader>Contact Details</FormSectionHeader>
                        <FormFragmentUserNameFields />
                        <Form.Group widths="equal">
                            <FormikInputField
                                name={'user.contactName'}
                                label="Contact Name"
                                autoComplete={'disabled'}
                            />
                            <FormikInputField
                                name={'user.companyName'}
                                label="Company Name"
                                autoComplete={'disabled'}
                            />
                        </Form.Group>

                        <FormSectionHeader button={[<ClearButton onClick={onClearPrimAddress} />]}>
                            Primary Address
                        </FormSectionHeader>
                        <FormikAddressInputFields name={'primaryAddress'} />

                        <FormikCheckboxField
                            name={'isBillingAddressSame'}
                            label="Billing address is the same as the 'Primary Address'"
                        />

                        {!isBillingAddressSame && (
                            <>
                                <FormSectionHeader button={[<ClearButton onClick={onClearBillAddress} />]}>
                                    Billing Address
                                </FormSectionHeader>
                                <FormikAddressInputFields name={'billAddress'} />
                            </>
                        )}

                        {!props.hideNewSite && (
                            <>
                                <FormikCheckboxField
                                    name={'isCreateSiteWithSameAddress'}
                                    label="Create a new site with the same address as the 'Primary Address'"
                                />

                                {isCreateSiteWithSameAddress && (
                                    <>
                                        <FormSectionHeader>New Site</FormSectionHeader>
                                        <FormikInputField
                                            name={'site.name'}
                                            label="Site Name"
                                            placeholder={'Main Site'}
                                            autoComplete={'disabled'}
                                            required={true}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        <FormSectionHeader>Contacts</FormSectionHeader>
                        <FormikContactsInputFields name={'contacts'} />
                        <DevOnly>{/*<ButtonFaker />*/}</DevOnly>
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};

export default NewCustomerForm;
