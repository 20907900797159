import * as React from 'react';
import { GasHeaterModel, HeatPumpModel } from '../data';
import { ConnectionTableDef, DefaultConnectionTable } from '@poolware/components';
import { Checkbox, Icon } from 'semantic-ui-react';

interface EquipmentListTableProps {
    onSelect: (selected: GasHeaterModel) => any;
    heaterCatalog?: HeatPumpModel[];
    autoRecommendedHeater?: HeatPumpModel;
    userSelectedHeater?: HeatPumpModel;
}

export const EquipmentListTable: React.FC<EquipmentListTableProps> = ({
    autoRecommendedHeater,
    heaterCatalog,
    userSelectedHeater,
    onSelect,
}) => {
    const cellProps = (h) => {
        if (!userSelectedHeater || !h) {
            return undefined;
        }
        if (h.PartNo === userSelectedHeater.sku) {
            return {
                style: { backgroundColor: '#caf0ff', fontWeight: 'bold' },
            };
        }
    };
    const tableDef: ConnectionTableDef<HeatPumpModel> = [
        {
            header: 'Part No',
            width: 4,
            cell: (h) => {
                const isRecommended = h.sku === autoRecommendedHeater?.sku;
                const isSelected = h.sku === userSelectedHeater?.sku;
                return (
                    <div tw={'flex flex-row'}>
                        <Checkbox radio={true} checked={isSelected} />
                        <div tw={'w-8 px-2'}>{isRecommended && <Icon name={'star'} color={'yellow'} />}</div>
                        <div>{h.sku || '--'}</div>
                    </div>
                );
            },
            cellProps,
        },
        { header: 'Heater', width: 6, cell: (h) => h.name, cellProps },
        { header: 'Capacity', cell: (h) => h.capacity, cellProps },
        { header: 'Input power', cell: (h) => h.input, cellProps },
    ];

    return <DefaultConnectionTable onRowClick={onSelect} connectionData={heaterCatalog} tableDef={tableDef} />;
};
