import { call, cancelled, put, race, take } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { WARRANTY_ACTION, WarrantyAction, WarrantyDetailsType } from './reducer-warranty-booking';
import { Action } from '@ez/tools';

const sagaName = 'SAGA_NEW_WARRANTY_FROM_CUSTOMER';

type StartActionType = Action<
    any,
    {
        details: WarrantyDetailsType;
        returnTo?: any;
    }
>;

export const createWarrantyBookingSaga = (store, URLBuilder) => {
    function* watchHandler(sagaName, action: StartActionType) {
        const navigate = (location, state: any = {}) => {
            return navigateActions.browserNavigation(location, { ...state, saga: sagaName });
        };

        const { details, returnTo } = action.payload;

        try {
            yield put(WarrantyAction.setSagaMode(true));
            yield put(WarrantyAction.setDetails(details));
            yield put(navigate(URLBuilder.WarrantyClaim().new));
            yield take([WARRANTY_ACTION.CREATED]);
        } catch (e) {
            yield put(WarrantyAction.abort());
        } finally {
            if (yield cancelled()) {
                yield put(navigate(returnTo));
            }
        }
    }

    function* watchAbort(sagaName) {
        yield race({
            navigationAbort: call(sagaAbortOnNavigation, sagaName),
            actionAbort: take(WARRANTY_ACTION.ABORT),
        });
    }

    return function* () {
        while (true) {
            yield put(WarrantyAction.reset());
            const startAction = yield take(WARRANTY_ACTION.NEW);
            yield race({
                saga: call(watchHandler, sagaName, startAction),
                abort: call(watchAbort, sagaName),
            });
        }
    };
};
