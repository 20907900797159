import * as React from 'react';
import { Optionable, OptionableL2 } from '@poolware/components';
import { useViewer } from '@poolware/app-shell';
import { useQueryServiceJobGroupConnection } from '../../queries/use-query-service-job-group-connection';
import { fromEdges, NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    ActionBarServiceGroups,
    OptionableL3OnChangeFn,
} from '../../components/ActionBarFilters/ActionBarServiceGroups';

export const ServiceJobGroupsMenuPanel: React.FC<{
    readOnly?: boolean;
    selectedGroupId: NodeType.ID | null | undefined;
    onSelectChange: (id: NodeType.ID | null | undefined) => any;
}> = ({ selectedGroupId, onSelectChange, readOnly }) => {
    const { viewer } = useViewer();
    const { connectionData, refetchQuery } = useQueryServiceJobGroupConnection();
    const { AppNavigator } = useAppNavigator();

    const canEdit = viewer.Permissions?.ServiceJobGroup?.update;
    const groups = fromEdges(connectionData);

    const onEditGroups = () => {
        AppNavigator.navigateRelative('/sj-template/edit-groups');
    };

    const handleOnChange: OptionableL3OnChangeFn<NodeType.ServiceJobGroup> = (optionL1, optionL2) => {
        if (optionL2) {
            onSelectChange(optionL2?.value?.id || null);
        } else if (optionL1) {
            onSelectChange(optionL1?.value?.id || null);
        } else {
            onSelectChange(null);
        }
    };

    const options = groups.map<OptionableL2<NodeType.ServiceJobGroup, NodeType.ServiceJobGroup>>((group) => {
        const subGroups = fromEdges(group.children);
        return {
            value: group,
            icon: 'folder outline',
            text: group.title,
            options: subGroups.map<Optionable<NodeType.ServiceJobGroup>>((t) => ({
                value: t,
                text: t.title,
                icon: 'folder outline',
            })),
        };
    });

    return (
        <>
            <ActionBarServiceGroups
                headerTitle={'Service Group'}
                value={selectedGroupId}
                onChange={handleOnChange}
                options={options}
                onEditGroups={canEdit && onEditGroups}
                collapsable={false}
                maxHeight={1000}
            />
        </>
    );
};
