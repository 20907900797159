import * as React from 'react';
import { Button, Divider, Message } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import { ButtonWithPopup, Panel, VStack } from '@poolware/components';
import { ServiceJobLink, WorkOrderLink } from '../../Links';
import { extractRecurrenceData } from '../utils';

export enum ApptDeleteMode {
    SINGLE,
    ALLFUTURE,
    WO_AND_APPT,
}

interface Props {
    appointmentItem: NodeType.AppointmentItem;
    onCancel: () => any;
    onDelete: (mode: ApptDeleteMode) => any;
}

const PanelDeleteAppointmentForSingleService: React.FC<Props> = ({ appointmentItem, onCancel, onDelete }) => {
    const { serviceJob, workOrder } = appointmentItem;

    if (!serviceJob || serviceJob.isRecurring) {
        return <div>Error</div>;
    }

    return (
        <Panel>
            <Panel.Header content={'Delete Appointment?'} />
            <Panel.Body>
                <VStack>
                    <ButtonWithPopup
                        fluid={true}
                        showLoaderOnClick={true}
                        icon={'trash'}
                        basic={true}
                        color={'orange'}
                        content={'Delete Appointment'}
                        onClick={() => onDelete(ApptDeleteMode.SINGLE)}
                    />

                    {workOrder && (
                        <ButtonWithPopup
                            fluid={true}
                            showLoaderOnClick={true}
                            popup={{ content: 'Delete appointment and work order' }}
                            icon={'trash'}
                            basic={true}
                            color={'red'}
                            content={'Delete Appointment and Work Order'}
                            onClick={() => onDelete(ApptDeleteMode.WO_AND_APPT)}
                        />
                    )}
                </VStack>
                <Divider hidden={true} />

                <Message info={true}>
                    <p>
                        This appointment is part of{' '}
                        <ServiceJobLink id={serviceJob.id}>
                            Service Case #{serviceJob?.serviceJobNumber}.
                        </ServiceJobLink>
                    </p>

                    {workOrder && (
                        <p>
                            <WorkOrderLink id={workOrder.id}>Work Order #{workOrder.workOrderNumber}</WorkOrderLink> is
                            associated with this appointment
                        </p>
                    )}
                </Message>
            </Panel.Body>
            <Panel.Footer>
                <Button basic={true} color={'blue'} fluid={true} content={'Cancel'} onClick={onCancel} />
            </Panel.Footer>
        </Panel>
    );
};
const PanelDeleteAppointmentForSingleImplicitService: React.FC<Props> = ({ appointmentItem, onCancel, onDelete }) => {
    const { serviceJob } = appointmentItem;

    if (!serviceJob || !serviceJob.isImplicit || serviceJob.isRecurring) {
        console.error('PanelDeleteAppointmentForSingleImplicitService: Invalid state. Should not be here.');
        return <div tw={'p-4'}>Error. Please contact support.</div>;
    }

    return (
        <Panel>
            <Panel.Header content={'Delete Appointment?'} />
            <Panel.Body>
                <VStack>
                    <ButtonWithPopup
                        fluid={true}
                        showLoaderOnClick={true}
                        icon={'trash'}
                        basic={true}
                        color={'orange'}
                        content={'Delete Appointment'}
                        onClick={() => onDelete(ApptDeleteMode.WO_AND_APPT)}
                    />
                </VStack>
            </Panel.Body>
            <Panel.Footer>
                <Button basic={true} color={'blue'} fluid={true} content={'Cancel'} onClick={onCancel} />
            </Panel.Footer>
        </Panel>
    );
};

const PanelDeleteAppointmentForRecurringService: React.FC<Props> = ({ appointmentItem, onCancel, onDelete }) => {
    const { serviceJob } = appointmentItem;

    if (!serviceJob || !serviceJob.isRecurring) {
        return <div>Error</div>;
    }

    return (
        <Panel>
            <Panel.Header content={'Delete Appointment?'} />
            <Panel.Body>
                <VStack>
                    <ButtonWithPopup
                        fluid={true}
                        showLoaderOnClick={true}
                        icon={'trash'}
                        basic={true}
                        color={'orange'}
                        content={'Delete Appointment'}
                        onClick={() => onDelete(ApptDeleteMode.SINGLE)}
                    />
                    <Divider hidden={true} />
                    <Button
                        {...buttonProps}
                        size={'tiny'}
                        onClick={() => onDelete(ApptDeleteMode.ALLFUTURE)}
                        icon={'trash'}
                        content={'Delete All Future Appointments'}
                    />
                </VStack>
                <Divider hidden={true} />

                <Message info={true}>
                    <p>
                        This appointment is managed by{' '}
                        <ServiceJobLink id={serviceJob.id}>
                            Recurring Service #{serviceJob?.serviceJobNumber}.
                        </ServiceJobLink>
                    </p>
                </Message>
            </Panel.Body>
            <Panel.Footer>
                <Button basic={true} color={'blue'} fluid={true} content={'Cancel'} onClick={onCancel} />
            </Panel.Footer>
        </Panel>
    );
};

const PanelDeleteAppointmentForSimpleAppointment: React.FC<Props> = ({ appointmentItem, onCancel, onDelete }) => {
    const { serviceJob } = appointmentItem;
    const { isRecurring } = extractRecurrenceData(appointmentItem);

    if (serviceJob) {
        console.error('PanelDeleteAppointmentForSimpleAppointment: Invalid state. Should not be here.');
        return <div tw={'p-4'}>Error. Please contact support.</div>;
    }

    if (isRecurring) {
        return (
            <Panel>
                <Panel.Header content={'Delete Appointment?'} />
                <Panel.Body>
                    <VStack>
                        <Button
                            {...buttonProps}
                            color={'purple'}
                            onClick={() => onDelete(ApptDeleteMode.SINGLE)}
                            icon={'trash'}
                            content={'Delete Appointment'}
                        />
                        <Button
                            {...buttonProps}
                            size={'tiny'}
                            onClick={() => onDelete(ApptDeleteMode.ALLFUTURE)}
                            icon={'trash'}
                            content={'Delete All Future Appointments'}
                        />
                        <Divider hidden={true} />
                    </VStack>
                </Panel.Body>
                <Panel.Footer>
                    <Button basic={true} color={'blue'} fluid={true} content={'Cancel'} onClick={onCancel} />
                </Panel.Footer>
            </Panel>
        );
    } else {
        return (
            <Panel>
                <Panel.Header content={'Delete Appointment?'} />
                <Panel.Body>
                    <Button
                        {...buttonProps}
                        negative
                        onClick={() => onDelete(ApptDeleteMode.SINGLE)}
                        icon={'trash'}
                        content={'Delete Appointment'}
                    />
                </Panel.Body>
                <Panel.Footer>
                    <Button basic={true} color={'blue'} fluid={true} content={'Cancel'} onClick={onCancel} />
                </Panel.Footer>
            </Panel>
        );
    }
};

export const AppointmentViewDeleteButtons: React.FC<Props> = ({ appointmentItem, onCancel, onDelete }) => {
    const { serviceJob } = appointmentItem;

    if (serviceJob) {
        if (serviceJob.isImplicit && !serviceJob.isRecurring) {
            return (
                <PanelDeleteAppointmentForSingleImplicitService
                    appointmentItem={appointmentItem}
                    onCancel={onCancel}
                    onDelete={onDelete}
                />
            );
        } else if (!serviceJob.isRecurring) {
            return (
                <PanelDeleteAppointmentForSingleService
                    appointmentItem={appointmentItem}
                    onCancel={onCancel}
                    onDelete={onDelete}
                />
            );
        } else {
            return (
                <PanelDeleteAppointmentForRecurringService
                    appointmentItem={appointmentItem}
                    onCancel={onCancel}
                    onDelete={onDelete}
                />
            );
        }
    } else {
        return (
            <PanelDeleteAppointmentForSimpleAppointment
                appointmentItem={appointmentItem}
                onCancel={onCancel}
                onDelete={onDelete}
            />
        );
    }
};

const buttonProps: any = {
    style: { margin: '0 .25em 0 0' },
    fluid: true,
    basic: true,
};
