import * as React from 'react';
import { createPortal } from 'react-dom';

export default class IFrame extends React.Component<any> {
    public iframeRoot: any;
    public node: any;

    public componentDidMount() {
        this.node.addEventListener('load', this.handleLoad);
    }

    public componentWillUnmount() {
        this.node.removeEventListener('load', this.handleLoad);
    }

    public handleLoad = () => {
        this.iframeRoot = this.node.contentDocument?.body;
        this.forceUpdate();
    };

    public render() {
        const { children, style } = this.props;
        return (
            <iframe
                title={'email-iframe'}
                frameBorder="0"
                style={style}
                srcDoc={`<!DOCTYPE html>`}
                ref={(node) => (this.node = node)}
            >
                {this.iframeRoot && createPortal(children, this.iframeRoot)}
            </iframe>
        );
    }
}
