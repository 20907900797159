import * as React from 'react';
import { CalendarEventBaseProps } from './types';
import { Popup } from 'semantic-ui-react';
import { CalendarEventMode, CalendarEventType } from '../types';
import { Display } from '@poolware/components';
import { styled, css } from 'twin.macro';

const CalendarEventDaySummaryStyled = styled.div(() => [
    css`
        display: flex;
        height: 100%;
        flex-direction: column;
        font-size: 0.9rem;
        min-height: 30px;
        align-items: center;
        justify-content: space-around;
        margin: 0.5em;
        cursor: unset;

        &.item {
            font-weight: bold;

            & > .count {
                padding: 0.25em 0.5em;
                min-width: 2em;
                text-align: center;
                border-radius: 1em;
                background-color: #597480;
                color: white;
            }
        }

        &.popup.inverted {
            background-color: unset;
            color: #f9ffde;
        }
    `,
]);

export const CalendarEventPopoverContent: React.FC<{ event: CalendarEventType }> = React.memo(({ event }) => {
    const { mode } = event;
    if (mode !== CalendarEventMode.DAY_SUMMARY) return null;

    const { daySummary } = event;

    return (
        <CalendarEventDaySummaryStyled className={'popup inverted'}>
            <Display.Date value={event.start} format={'LL'} />
            <div>Number of appointments: {daySummary.count}</div>
        </CalendarEventDaySummaryStyled>
    );
});

export const CalendarBlockEvent: React.FC<{ event: CalendarEventType }> = React.memo(({ event }) => {
    const { mode } = event;
    if (mode !== CalendarEventMode.DAY_SUMMARY) return null;

    const { daySummary } = event;

    return (
        <CalendarEventDaySummaryStyled className={'item'}>
            <div className={'count'}>{daySummary.count}</div>
        </CalendarEventDaySummaryStyled>
    );
});

export const CalendarEventDaySummary: React.FC<CalendarEventBaseProps> = ({ event, draggable }) => {
    return (
        <Popup
            hoverable
            mouseEnterDelay={400}
            trigger={
                <div style={{ height: '100%' }}>
                    <CalendarBlockEvent event={event} />
                </div>
            }
            position="top center"
            wide
            inverted
            content={<CalendarEventPopoverContent event={event} />}
            closeOnTriggerClick={true}
            closeOnDocumentClick={true}
        />
    );
};
