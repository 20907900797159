import * as React from 'react';
import * as _ from 'lodash';
import * as URLBuilder from '../../url-builder';
import { Link } from 'react-router-dom';
import {
    ColorPillWithColorPicker,
    DevOnly,
    Display,
    Panel,
    SectionHeader,
    stringFormatters,
} from '@poolware/components';
import { deserializeColorPalette, NodeType } from '@poolware/api';
import { DropdownStaff } from '@poolware/app-shell';
import AppointmentGroupSingleSelector from '../../Components/AppointmentGroupSingleSelector';
import { IconButton } from '../../Components/IconButton';
import { extractRecurrenceData, recurrenceToString } from '../../utils';
import { ModuleIconNames } from '../../../constants';
import { AppointmentEditMode, ButtonSectionHeader } from '../components';
import { Popup } from 'semantic-ui-react';

export interface AppointmentPreviewContentItemsProps {
    appointmentItem?: NodeType.AppointmentItem;
    appointmentGroups?: NodeType.AppointmentGroup[];
    onEdit: (mode: AppointmentEditMode) => any;
    onGroupChange;
    onChangeStaff;
    onColorChange;
    canEdit: boolean;
}

const ButtonEditApptTime: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    onClick: (value?: AppointmentEditMode) => any;
}> = ({ onClick, appointmentItem }) => {
    const { isRecurring } = extractRecurrenceData(appointmentItem);

    if (!isRecurring) {
        return (
            <ButtonSectionHeader icon={'edit'} content={'Edit'} onClick={() => onClick(AppointmentEditMode.Single)} />
        );
    }
    const options = [
        // {
        //     key: AppointmentEditMode.Single,
        //     icon: 'edit',
        //     text: 'Edit Appointment',
        //     value: AppointmentEditMode.Single,
        //     onClick: () => onClick(AppointmentEditMode.Single),
        // },
        // {
        //     key: AppointmentEditMode.Future,
        //     icon: 'edit',
        //     text: 'Future Appointments',
        //     value: AppointmentEditMode.Future,
        //     onClick: () => onClick(AppointmentEditMode.Future),
        // },
        {
            key: AppointmentEditMode.Recurrence,
            icon: 'edit',
            text: 'Change Recurrence',
            value: AppointmentEditMode.Recurrence,
            onClick: () => onClick(AppointmentEditMode.Recurrence),
        },
    ];

    return (
        <SectionHeader.ButtonGroup>
            <SectionHeader.Button icon={'edit'} onClick={() => onClick(AppointmentEditMode.Single)} content={'Edit'} />
            <SectionHeader.DropdownButton options={options} icon={'ellipsis vertical'} />
        </SectionHeader.ButtonGroup>
    );
};

export const PanelItemsCustomerAndLocation: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    showEmptyFields?: boolean;
}> = ({ appointmentItem, showEmptyFields }) => {
    const { address, pool, customer } = appointmentItem;

    let poolURL = null;
    if (customer && pool) {
        poolURL = URLBuilder.Customer(customer.id).Pool(pool.id).view;
    }

    const poolName = _.truncate(pool?.name || '--', { length: 40 });
    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${poolName} (${poolVolume} L, ${poolType})`;
    const poolSanitiser = pool?.sanitiser?.name;
    const poolSiteAccess = pool?.site?.accessKeyLocation;

    return (
        <>
            {customer && <Panel.SectionHeader size={'small'} icon={'address card outline'} content={'Customer'} />}

            {customer ? (
                <>
                    <Panel.Item label="Customer" labelIcon="user">
                        <Link to={URLBuilder.Customer(customer.id).view}>
                            {stringFormatters.formatEntityName(customer)} / {customer.crn}
                        </Link>
                    </Panel.Item>
                    {customer.primaryPhone && (
                        <Panel.Item label={'Phone'} labelIcon={'phone'}>
                            <Display.Phone value={customer.primaryPhone?.data} />
                            {customer.primaryPhone?.label && (
                                <i tw={'text-gray-400 pl-1'}>({customer.primaryPhone?.label})</i>
                            )}
                        </Panel.Item>
                    )}
                    {customer.primaryEmail && (
                        <Panel.Item label={'Email'} labelIcon={'mail'}>
                            <Display.Email value={customer.primaryEmail?.data} />
                            {customer.primaryEmail.label && (
                                <i tw={'text-gray-400 pl-1'}>({customer.primaryEmail?.label})</i>
                            )}
                        </Panel.Item>
                    )}
                </>
            ) : (
                <>{showEmptyFields && <Panel.Item label="Customer" labelIcon="user" content={'--'} />}</>
            )}

            {(address || pool) && <Panel.SectionHeader size={'small'} icon={'building outline'} content={'Location'} />}

            {address && (
                <Panel.Item label="Address" labelIcon="marker">
                    <Display.Address value={address} showMapLink={true} />
                </Panel.Item>
            )}
            {!address && showEmptyFields && <Panel.Item label="Address" labelIcon="marker" content={'--'} />}

            {pool ? (
                <>
                    <Panel.Item label="Pool" labelIcon="life ring">
                        {poolURL ? <Link to={poolURL}>{poolString}</Link> : poolString}
                    </Panel.Item>
                    {poolSanitiser && (
                        <Panel.Item label="Pool Sanitiser" labelIcon="box">
                            {poolSanitiser}
                        </Panel.Item>
                    )}
                    {!address && (
                        <Panel.ItemAddress
                            label="Pool Address"
                            labelIcon={'marker'}
                            showMapLink={true}
                            content={pool.address}
                        />
                    )}
                    {poolSiteAccess && (
                        <Panel.Item label="Site Access Key" labelIcon={'key'}>
                            <div tw={'max-h-40'}>
                                <Display.FormattedText value={poolSiteAccess} limit={180} />
                            </div>
                        </Panel.Item>
                    )}
                </>
            ) : (
                <>{showEmptyFields && <Panel.Item label="Pool" labelIcon="life ring" content={'--'} />}</>
            )}
        </>
    );
};

export const AppointmentViewPanelAppointmentItem: React.FC<AppointmentPreviewContentItemsProps> = ({
    appointmentItem,
    appointmentGroups,
    onChangeStaff,
    onColorChange,
    onGroupChange,
    canEdit,
    onEdit,
}) => {
    const { startDate, duration, address, pool, staff, customer, group, color, isRecurring, isRecurrentOrigin } =
        appointmentItem;

    const selectedGroup = group ? appointmentGroups.find((ag) => ag.id === group.id) : null;
    const palette = selectedGroup ? deserializeColorPalette(selectedGroup) : null;
    const { patternBlockId, recurrence } = extractRecurrenceData(appointmentItem);

    const editButton = <ButtonEditApptTime onClick={onEdit} appointmentItem={appointmentItem} />;

    return (
        <Panel>
            <Panel.Body>
                <DevOnly hidden={true}>
                    <Panel.Item label={'patternBlockId'} content={patternBlockId} />
                </DevOnly>

                <Panel.SectionHeader
                    size={'small'}
                    icon={ModuleIconNames.Appointment}
                    content={'Appointment'}
                    button={canEdit && editButton}
                />
                <Panel.Item label="Date" labelIcon="clock">
                    <Display.Date value={startDate} format={'LL'} />
                </Panel.Item>
                <Panel.Item label="Time" labelIcon="clock">
                    <Display.DateRange startDate={startDate} duration={duration} dayFormat={null} />{' '}
                    <span tw={'pl-1'}>({duration} min)</span>
                </Panel.Item>
                {(isRecurring || isRecurrentOrigin) && (
                    <Panel.Item label="Recurring" labelIcon="refresh">
                        <Popup
                            trigger={
                                <div style={{ maxWidth: '330px', fontSize: '0.95em' }}>
                                    {recurrenceToString(recurrence)}
                                </div>
                            }
                        >
                            <div tw={'text-sm text-gray-500'}>Appointment TZ: {recurrence?.range?.timeZone}</div>
                        </Popup>
                    </Panel.Item>
                )}

                <Panel.Divider />

                <Panel.Item label={'Appt. Group'} labelIcon={'circle'}>
                    <AppointmentGroupSingleSelector
                        readonly={!canEdit}
                        selectedGroupId={group && group.id}
                        onChange={onGroupChange}
                    />
                </Panel.Item>

                <Panel.Item label={'Color Tag'} labelIcon={'square'}>
                    <ColorPillWithColorPicker
                        readonly={!canEdit}
                        palette={palette}
                        value={color}
                        onChange={onColorChange}
                    />
                    <IconButton hidden={!canEdit || !color} name={'close'} onClick={() => onColorChange(null)} />
                </Panel.Item>

                <Panel.Item label="Technician" labelIcon="spy">
                    <DropdownStaff readonly={!canEdit} value={staff?.id} onChange={onChangeStaff} />
                </Panel.Item>

                <PanelItemsCustomerAndLocation appointmentItem={appointmentItem} />
            </Panel.Body>
        </Panel>
    );
};
