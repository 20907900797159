import gql from 'graphql-tag';
import { addressFragment } from '@poolware/api';

export const appointmentItemFragment = gql`
    fragment Calendar_AppointmentItemFragment on AppointmentItem {
        id
        startDate
        duration
        status
        group {
            id
            title
            description
            defaultColor
            priority
        }
        color
        isRecurring
        isRecurrentOrigin
        patternBlockId
        staff {
            id
            user {
                id
                firstName
                lastName
            }
        }
        customer {
            id
            crn
            contactName
            companyName
            user {
                id
                firstName
                lastName
            }
        }
        address {
            ...AddressFragment
        }
        pool {
            id
            name
            bottleNumber
            volume
            address {
                ...AddressFragment
            }
        }
        deleted
        note
        workOrder {
            id
            title
            workOrderNumber
        }
        serviceJob {
            id
            title
            serviceJobNumber
            isRecurring
            isImplicit
        }
    }
    ${addressFragment}
`;

export const AppointmentsListQuery = gql`
    query SchedulerAppointmentsList(
        $startDate: Date!
        $endDate: Date!
        $first: Int
        $franchise: ID
        $customer: ID
        $after: String
        $staff: ID
        $search: AppointmentItemSearchCommon
        $isRecurrent: Boolean
    ) {
        viewer {
            appointmentItems(
                startDate: $startDate
                endDate: $endDate
                first: $first
                after: $after
                franchise: $franchise
                customer: $customer
                staff: $staff
                search: $search
                isRecurrent: $isRecurrent
            ) {
                edges {
                    node {
                        ...Calendar_AppointmentItemFragment
                    }
                }
            }
        }
    }
    ${appointmentItemFragment}
`;

export const QueryAppointmentItemsMonth = gql`
    query QueryAppointmentItemsMonth(
        $startDate: Date!
        $endDate: Date!
        $first: Int
        $franchise: ID
        $customer: ID
        $after: String
        $staff: ID
        $search: AppointmentItemSearchCommon
        $isRecurrent: Boolean
    ) {
        viewer {
            appointmentItems(
                startDate: $startDate
                endDate: $endDate
                first: $first
                after: $after
                franchise: $franchise
                customer: $customer
                staff: $staff
                search: $search
                isRecurrent: $isRecurrent
            ) {
                edges {
                    node {
                        id
                        startDate
                        duration
                        status
                        staff {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        group {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export const refetchAppointments = (props?) => {
    return 'SchedulerAppointmentsList';

    // invariant(props.CalendarState, 'scheduler is required. Use redux connect to extract scheduler from the store.');
    // const vars = variablesConfigMapper(props.CalendarState);
    // return {
    //     query: AppointmentsListQuery,
    //     variables: vars,
    // };
};
