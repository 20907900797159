import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const QL = gql`
    query QueryEmailMessage($id: ID!) {
        node(id: $id) {
            id
            ... on EmailMessage {
                createdAt
                id
                isSent
                subject
                recipientEmail
                type {
                    name
                    id
                }
                messageBody
                submittedBy {
                    id
                    franchise {
                        id
                        name
                    }
                    user {
                        id
                        firstName
                        lastName
                    }
                }
            }
        }
    }
`;

export const useQueryEmailMessage = createUseQueryNodeHook<NodeType.EmailMessage>(QL);
