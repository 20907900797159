import styled from 'styled-components';

export const ActionButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;

    .stage-message {
        flex: 1 1 auto;
        padding: 0;
        margin-right: 1em;
        //background-color: #4c9b20;
        display: flex;
        flex-direction: column;
    }

    .stage-buttons {
        //background-color: #20349b;
        flex-basis: 140px;
        flex-shrink: 0;
        flex-grow: 1;
        text-align: end;
    }
`;
