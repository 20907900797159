import * as React from 'react';
import { useWorkOrderFeedActions } from '../../redux';
import { ActionBarFilterByServiceStageType } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobStageType';

export const FilterByServiceJobStageType: React.FC = () => {
    const { Action, State } = useWorkOrderFeedActions();

    return (
        <ActionBarFilterByServiceStageType
            setServiceJobStageType={Action.setServiceStageType}
            setServiceJobStageStatus={Action.setServiceStageStatus}
            valueStageTypes={State.filters.serviceStageType}
            valueStageStatus={State.filters.serviceStageStatus}
        />
    );
};
