import View from './Pages/Page';
import New from './AppointmentForm/Page.New';
import NewWorkOrderAppointment from './AppointmentForm/Page.NewWorkOrder';
import WhereToGo from './Pages/Page.WhereToGo';
import { PageRescheduleConfirm } from './AppointmentForm/Page.RescheduleConfirm';
import Edit from './AppointmentForm/Page.Edit';
import { Edit as CalendarGroupEditorEdit, New as CalendarGroupEditorNew } from './CalendarGroupEditor';

export const SchedulerPages = {
    View,
    New,
    NewWorkOrderAppointment,
    WhereToGo,
    PageRescheduleConfirm,
    Edit,
    CalendarGroupEditorNew,
    CalendarGroupEditorEdit,
};

export * from './AppointmentDock/';
export * from './AppointmentPreview/';
export * from './utils';
export * from './Queries';
export * from './types';
