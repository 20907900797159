import * as React from 'react';
import { Panel } from '@poolware/components';
import { Header, Message } from 'semantic-ui-react';
import { useWaterlink } from '../api-react';

export const WaterTestUnavailablePanel: React.FC = () => {
    const waterlink = useWaterlink();
    const canDoWaterTest = waterlink.device.online;
    if (canDoWaterTest) {
        return null;
    }

    const isServerOnline = waterlink.server.online;
    const isDeviceOnline = waterlink.device.online;

    return (
        <Panel>
            <Panel.Header>Water Test</Panel.Header>
            <Panel.Body>
                <Message warning={true}>
                    <Header size={'small'}>Cannot perform water tests.</Header>
                    <Panel.Body>
                        <Panel.Item label={'Server'}>{isServerOnline ? 'Online' : 'Offline'}</Panel.Item>
                        <Panel.Item label={'Server URL'}>{waterlink.server.serverBaseUrl}</Panel.Item>
                    </Panel.Body>
                    <Panel.Body>
                        <Panel.Item label={'Device'}>{isDeviceOnline ? 'Online' : 'Offline'}</Panel.Item>
                    </Panel.Body>
                </Message>
            </Panel.Body>
        </Panel>
    );
};
