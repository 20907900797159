import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Display, FormFieldLabel, FormikDefaultForm, Panel, toastError, useModalCtrl } from '@poolware/components';
import { Modal } from 'semantic-ui-react';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { PanelCustomerAndLocation } from '../../components/PanelCustomerAndLocation';
import { CustomerAddressList } from '@poolware/app-shell';
import { getIn } from 'formik';
import { useRecurCaseCtx } from './RecurCaseProvider';

export const PanelServiceJobCustomerAndLocation: React.FC<{
    serviceJob: NodeType.ServiceJob;
    readOnly?: boolean;
    onEdit;
}> = ({ serviceJob, readOnly, onEdit }) => {
    const customer = serviceJob?.customer;
    const pool = serviceJob?.pool;
    const address = serviceJob?.address;

    let button = null;
    if (readOnly) {
        button = null;
    } else if (!pool && address) {
        button = {
            content: 'Add Pool',
            icon: 'plus',
            autoHideContent: false,
            onClick: () => onEdit(),
        };
    } else if (!address && !pool) {
        button = {
            content: 'Add Location',
            icon: 'plus',
            autoHideContent: false,
            onClick: () => onEdit(),
        };
    } else {
        button = {
            content: 'Edit',
            icon: 'edit',
            autoHideContent: false,
            onClick: () => onEdit(),
        };
    }
    return <PanelCustomerAndLocation customer={customer} pool={pool} address={address} headerButton={button} />;
};

export const FormJobCustomerAndLocation: React.FC<{ serviceJob: NodeType.ServiceJob; onDone }> = ({
    serviceJob,
    onDone,
}) => {
    const { updateRecurringJob } = useServiceJobMutators({ refetchQueries: ['QueryServiceJob'] });
    const initialValues = { ...serviceJob };

    const onSubmit = async (values: typeof initialValues, actions) => {
        const updateConf = {
            id: serviceJob.id,
            address: values.address?.id || null,
            pool: values.pool?.id || null,
        };
        try {
            await updateRecurringJob(updateConf);
            onDone(false);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    return (
        <FormikDefaultForm
            debug={false}
            icon={'user'}
            initialValues={initialValues}
            onSubmit={onSubmit}
            header={'Customer'}
            onCancel={onDone}
        >
            {(formikBag) => {
                const customer = getIn(formikBag.values, 'customer');
                const pool = getIn(formikBag.values, 'pool');
                const address = getIn(formikBag.values, 'address');

                const onAddressSelect = (address: NodeType.Address) => {
                    formikBag.setFieldValue('pool', null);
                    formikBag.setFieldValue('address', address);
                };

                const onPoolSelect = (pool: NodeType.Pool) => {
                    formikBag.setFieldValue('pool', pool);
                    if (pool && pool.address) {
                        formikBag.setFieldValue('address', pool.address);
                    } else {
                        formikBag.setFieldValue('address', null);
                    }
                };

                if (!customer?.id) {
                    return <>No customer</>;
                }
                return (
                    <div>
                        <div>
                            <Panel.Item label={'Customer CRN'}>{customer.crn}</Panel.Item>

                            <Panel.Item label={'Customer'}>
                                <Display.Entity value={customer} />
                                {customer.contactName && <> ({customer.contactName})</>}
                            </Panel.Item>

                            {customer?.companyName && (
                                <Panel.Item label={'Company'} iconMarker={'building'}>
                                    {customer.companyName}
                                </Panel.Item>
                            )}
                        </div>
                        <Panel.Divider />
                        <div className={'field'}>
                            <FormFieldLabel label={'Address'} />
                            <div style={{ paddingLeft: '0.5rem' }}>
                                <CustomerAddressList
                                    selectedPoolId={pool && pool.id}
                                    selectedAddressId={address && address.id}
                                    customerId={customer.id}
                                    onAddressSelect={onAddressSelect}
                                    onPoolSelect={onPoolSelect}
                                />
                            </div>
                        </div>
                    </div>
                );
            }}
        </FormikDefaultForm>
    );
};

export const PanelFormCustomerAndLocation: React.FC = () => {
    const modalCtrl = useModalCtrl();
    const { serviceJob, isStopped } = useRecurCaseCtx();
    return (
        <>
            <PanelServiceJobCustomerAndLocation
                readOnly={isStopped}
                serviceJob={serviceJob}
                onEdit={modalCtrl.onOpen}
            />
            {modalCtrl.open && (
                <Modal {...modalCtrl}>
                    <FormJobCustomerAndLocation serviceJob={serviceJob} onDone={modalCtrl.onClose} />
                </Modal>
            )}
        </>
    );
};
