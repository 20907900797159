import moment from 'moment';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';
import Appointments from './Appointments';
import { withApolloLoading } from '@poolware/components';
import { fromEdges } from '@poolware/api';

const AppointmentsQuery = gql`
    query CustomerAppointmentList($customerId: ID!, $startDate: Date!, $endDate: Date!) {
        customer: node(id: $customerId) {
            ... on Customer {
                id
                user {
                    id
                    firstName
                    lastName
                }
                appointments(first: 10, startDate: $startDate, endDate: $endDate) {
                    edges {
                        node {
                            id
                            startDate
                            duration
                            note
                            pool {
                                id
                            }
                            staff {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                            status
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }
        }
    }
`;

const variablesConfigMapper = ({ customerId, startDate, endDate }) => {
    const isoStartDate = moment().subtract(6, 'month').startOf('hour').toISOString();
    const isoEndDate = moment().add(6, 'month').startOf('hour').toISOString();
    return {
        customerId: customerId,
        startDate: isoStartDate,
        endDate: isoEndDate,
    };
};

export default compose(
    graphql(AppointmentsQuery, {
        options: (props: any) => ({
            variables: variablesConfigMapper(props),
            // pollInterval: 30000, //30 sec
            // fetchPolicy: 'cache-and-network'
        }),
    }),
    withApolloLoading(),
    mapProps((props) => {
        return {
            ...props,
            refetch: props.data.refetch,
            appointments: fromEdges(props?.data?.customer?.appointments),
        };
    })
)(Appointments);
