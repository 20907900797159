import Page from './Page';

import { compose, mapProps } from '@ez/tools';

import { NodeType, poolFragment, PoolMutatorProps, withPoolMutators } from '@poolware/api';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@poolware/components';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';

export const PoolQuery = gql`
    query PoolDetailsQuery($poolId: ID!) {
        viewer {
            me {
                staff {
                    id
                    root {
                        id
                        franchise {
                            id
                            name
                        }
                    }
                }
            }
        }
        pool: node(id: $poolId) {
            id
            ...PoolFragment
            ... on Pool {
                id
                site {
                    id
                    name
                    accessKeyLocation
                }
                entity {
                    id
                    user {
                        id
                        customer {
                            id
                            contactName
                            crn
                        }
                    }
                    franchise {
                        id
                        name
                    }
                }
            }
        }
    }
    ${poolFragment}
`;

export default compose(
    withPageProps(),
    graphql(PoolQuery, {
        options: (props: any) => ({
            variables: { poolId: props.params.poolId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.pool.id' }),
    mapProps((props) => {
        let pool = props?.data?.pool;
        let me = props?.data?.me;
        return {
            ...props,
            pool,
            me,
        };
    }),
    withPoolMutators(['PoolDetailsQuery'])
)(Page);

export interface PageControlProps extends PoolMutatorProps, IWithPageProps {
    pool: NodeType.Pool;
    me: NodeType.Me;
}
