import { call, cancelled, put, race, take } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { NodeType } from '@poolware/api';
import { BOOKING_ACTION, BookingAction } from './reducer-appointment-booking';
import { CalViewLayoutMode } from '../Scheduler';
import { CalendarAction } from './reducers-calendar-state';

export const sagaAppointmentBookingRescheduleName = 'SAGA_APPOINTMENT_RESCHEDULE';

export const createWatchAppointmentBookingReschedule = (store, URLBuilder) => {
    function* handler(sagaName, { payload }) {
        const navigate = (url, state: any = {}, replace: boolean = false) => {
            return navigateActions.browserNavigation(url, { ...state, saga: sagaName }, replace);
        };

        const returnTo = payload?.returnTo || URLBuilder.Scheduler.home;
        const appointmentItem: NodeType.AppointmentItem = payload.appointmentItem || {};

        try {
            yield put(BookingAction.setSagaMode(true, sagaAppointmentBookingRescheduleName));
            yield put(CalendarAction.setLayoutMode(CalViewLayoutMode.GRID));
            yield put(BookingAction.setDetails({ appointmentItem: appointmentItem }));

            yield put(navigate(URLBuilder.Scheduler.home, undefined));

            yield put(CalendarAction.setPreviewAppt(appointmentItem?.id));
            yield put(BookingAction.setSelectingSlotMode(true));
            yield take([BOOKING_ACTION.TIME_SLOT_SELECTED]);

            yield put(navigate('/scheduler/reschedule-confirm', { modal: true }, true));

            yield take([BOOKING_ACTION.BOOKING_CREATED]);
            yield put(navigate(returnTo, undefined, true));
        } catch (e) {
            yield put(BookingAction.abort());
        } finally {
            if (yield cancelled()) {
                yield put(navigate(returnTo));
            }
        }
    }

    function* abortWatcher(sagaName) {
        yield race({
            navigationAbort: call(sagaAbortOnNavigation, sagaName),
            actionAbort: take(BOOKING_ACTION.ABORT),
        });
    }

    function* watch() {
        while (true) {
            yield put(BookingAction.reset());
            const startAction = yield take(BOOKING_ACTION.START_APPT_RESCHEDULE);
            yield race({
                saga: call(handler, sagaAppointmentBookingRescheduleName, startAction),
                abort: call(abortWatcher, sagaAppointmentBookingRescheduleName),
            });
        }
    }

    return watch;
};
