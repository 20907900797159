import * as React from 'react';
import { useCallback, useMemo } from 'react';
import PoolDetails from '../../../CommonComponents/PoolDetails';
import {
    FormikFormDebug,
    FormikTextareaField,
    HeaderButtonProps,
    Panel,
    SectionHeader,
    toastError,
    VStack,
} from '@poolware/components';
import { Button, Checkbox, Divider, Form, Header, Icon, Segment } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import { useWaterlink } from '@poolware/waterlink';
import { prepareInputConf } from './preapare-input-conf';
import { WaterlinkPanel } from './WaterlinkPanel';
import { ManualProblems } from './ManualProblems';
import { WaterTestFields } from './WaterTestInputFields';
import { isMobileDevice, useIsMounted, usePersistedToggle } from '@ez/tools';

import './styles.scss';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import styled from 'styled-components';
import { usePersistedFormState } from './hooks';
import 'twin.macro';

const AUTO_OPEN_PDF_PERSIST_KEY = 'pw.watertest.auto-open-pdf';
const AUTO_SUBMIT_TEST_REPORT_PERSIST_KEY = 'pw.watertest.auto-open-pdf';
const KeyWATERLINK_PANEL_EXPANDED_PERSIST_KEY = 'pw.watertest.waterlink.expanded';

interface WaterTestFormProps {
    initialValues;
    chemicalTargets;
    onSubmit;
    onCancel;
    header;
    pool;
    prevSampleSets?: NodeType.SampleSet[];
    waterlinkAllowed: boolean;
    showOpenPDFCheckbox: boolean;
}

const FooterCheckboxContainer = styled.div`
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    color: rgba(0, 0, 0, 0.6);
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
`;

const FooterButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    #spacer {
        flex-grow: 2;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        & > * {
            margin-bottom: 1em !important;
        }
        #spacer {
            display: none;
        }
        #pdf-checkbox {
            order: 3;
        }
    }
`;

const isMobile = isMobileDevice();

const WaterlinkOnlineIcon = <Icon color="green" name="lab" />;

const WaterlinkOfflineIcon = <Icon color="red" name="dont" />;

export const WaterTestForm: React.FC<WaterTestFormProps> = ({
    chemicalTargets,
    initialValues,
    onSubmit,
    onCancel,
    pool,
    header,
    prevSampleSets,
    waterlinkAllowed,
    showOpenPDFCheckbox,
}) => {
    const {
        autoSubmitReport,
        expandWaterlinkPanel,
        setExpandWaterlinkPanel,
        setAutoSubmitReport,
        setAutoOpenPDF,
        autoOpenPDF,
    } = usePersistedFormState();

    const isMounted = useIsMounted();

    const inputFieldsConf = useMemo(
        () =>
            prepareInputConf({
                name: 'measurements',
                chemicalTargets,
                prevSampleSets,
            }),
        [chemicalTargets, prevSampleSets]
    );

    const waterlink = useWaterlink();
    const isServiceEnabled = waterlink.serviceEnabled;
    const isServerOnline = waterlink.server.online;
    const isDeviceOnline = waterlink.device.online;

    const showWaterlinkPanel = expandWaterlinkPanel && waterlinkAllowed && isServerOnline && isServiceEnabled;

    const waterlinkButton = useMemo((): HeaderButtonProps => {
        if (!waterlinkAllowed || isMobile) {
            return null;
        }

        if (!isServiceEnabled) {
            return {
                color: 'grey',
                popup: {
                    wide: true,
                    content: (
                        <div>
                            Waterlink Service Integration is disabled.
                            <br /> Check "Integrations &gt; Waterlink" settings
                        </div>
                    ),
                },
                content: 'Waterlink Disabled',
                icon: WaterlinkOfflineIcon,
            };
        }

        if (!isServerOnline) {
            return {
                color: 'grey',
                popup: {
                    content: 'Waterlink Connect 2 service is unreachable',
                },
                content: 'Waterlink Offline',
                icon: WaterlinkOfflineIcon,
                onClick: () => setExpandWaterlinkPanel(false),
            };
        } else if (!isDeviceOnline) {
            return {
                color: 'grey',
                popup: {
                    content: 'Waterlink device is offline',
                },
                content: 'Waterlink Offline',
                icon: WaterlinkOfflineIcon,
                onClick: () => setExpandWaterlinkPanel(!expandWaterlinkPanel),
            };
        } else {
            if (expandWaterlinkPanel) {
                return {
                    color: 'green',
                    content: 'Hide Waterlink',
                    icon: 'cancel',
                    onClick: () => setExpandWaterlinkPanel(!expandWaterlinkPanel),
                };
            } else {
                return {
                    color: 'green',
                    content: 'Use Waterlink',
                    popup: { content: 'Waterlink is available' },
                    icon: WaterlinkOnlineIcon,
                    onClick: () => setExpandWaterlinkPanel(!expandWaterlinkPanel),
                };
            }
        }
    }, [
        isServerOnline,
        isDeviceOnline,
        setExpandWaterlinkPanel,
        expandWaterlinkPanel,
        waterlinkAllowed,
        isServiceEnabled,
    ]);

    const handleOnSubmit = async (values: any, actions: FormikActions<any>) => {
        actions.setSubmitting(true);
        try {
            const newValues = { ...values, openPDFAfterSubmit: autoOpenPDF };
            await onSubmit(newValues, actions);
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e });
            toastError({ title: 'Failed', description: e.message });
        }
        if (isMounted()) {
            actions.setSubmitting(false);
        }
    };

    return (
        <div className={'water-test-form'}>
            <Formik initialValues={initialValues} enableReinitialize={false} onSubmit={handleOnSubmit}>
                {(formikBag) => {
                    const { isSubmitting } = formikBag;
                    const onWaterlinkTestFinish = () => {
                        if (autoSubmitReport) {
                            formikBag.submitForm();
                        }
                    };

                    return (
                        <VStack>
                            <Form autoComplete={'off'}>
                                <Panel>
                                    <Panel.Header>{header}</Panel.Header>
                                    <Panel.Body>
                                        <PoolDetails pool={pool} />
                                        <Divider />
                                        <div>
                                            <Segment color="grey">
                                                <SectionHeader size={'small'} button={waterlinkButton}>
                                                    Measurements
                                                </SectionHeader>
                                                {showWaterlinkPanel && (
                                                    <Segment tertiary color={'purple'}>
                                                        <VStack space={1}>
                                                            <WaterlinkPanel
                                                                inputFieldsConf={inputFieldsConf}
                                                                onTestFinish={onWaterlinkTestFinish}
                                                            />
                                                            <div>
                                                                <Checkbox
                                                                    checked={autoSubmitReport}
                                                                    onChange={(_, { checked }) =>
                                                                        setAutoSubmitReport(checked)
                                                                    }
                                                                    label={'Auto Submit report on test finish'}
                                                                />
                                                                {showOpenPDFCheckbox && (
                                                                    <>
                                                                        <br />
                                                                        <Checkbox
                                                                            checked={autoOpenPDF}
                                                                            onChange={(_, { checked }) =>
                                                                                setAutoOpenPDF(checked)
                                                                            }
                                                                            label={'Open PDF on Submit'}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </VStack>
                                                    </Segment>
                                                )}
                                                <WaterTestFields
                                                    inputFieldsConf={inputFieldsConf}
                                                    prevSampleSets={prevSampleSets}
                                                    chemicalTargets={chemicalTargets}
                                                />
                                            </Segment>

                                            <Segment color="grey">
                                                <FormikTextareaField
                                                    dataTestId={'notes'}
                                                    label={'Notes'}
                                                    name={'note'}
                                                    rows={8}
                                                />
                                            </Segment>

                                            <Segment color="grey">
                                                <Header sub>Observations</Header>
                                                <ManualProblems name={'manualProblems'} />
                                            </Segment>
                                        </div>
                                    </Panel.Body>
                                    <Panel.Footer secondary clearing>
                                        <FooterButtonsContainer>
                                            <Button
                                                primary
                                                type="button"
                                                basic
                                                disabled={isSubmitting}
                                                onClick={onCancel}
                                            >
                                                Cancel
                                            </Button>
                                            <div id={'spacer'} />
                                            {showOpenPDFCheckbox && (
                                                <FooterCheckboxContainer id={'pdf-checkbox'}>
                                                    <Checkbox
                                                        checked={autoOpenPDF}
                                                        onChange={(_, { checked }) => setAutoOpenPDF(checked)}
                                                        label={'Open PDF on Submit'}
                                                    />
                                                </FooterCheckboxContainer>
                                            )}

                                            <Button
                                                primary
                                                type={'button'}
                                                onClick={(e) => formikBag.handleSubmit(e as any)}
                                                loading={isSubmitting}
                                                disabled={isSubmitting}
                                                content={'Submit'}
                                            />
                                        </FooterButtonsContainer>
                                    </Panel.Footer>
                                </Panel>
                                <FormikFormDebug hidden={false} />
                            </Form>
                        </VStack>
                    );
                }}
            </Formik>
            {initialValues?.refId && <span tw={'my-4 text-sm text-gray-400'}>Ref. ID: {initialValues?.refId}</span>}
        </div>
    );
};
