import gql from 'graphql-tag';

export const productTraitFragment = gql`
    fragment ProductTraitFragment_Catalog on ProductTrait {
        id
        product {
            id
            name
        }
        floatValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        integerValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        stringValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        selectionValues {
            id
            field {
                id
                tag
                name
            }
            value {
                id
                name
            }
        }
        flagValues {
            id
            value
            field {
                id
                tag
                name
            }
        }
        declaration {
            id
            name
            tag
        }
    }
`;

export const productTraitDeclarationFragment = gql`
    fragment ProductTraitDeclarationFragment on ProductTraitDeclaration {
        id
        tag
        name
        parent {
            id
            name
        }
        subDeclarations {
            id
            name
        }
        franchise {
            id
            name
        }
        organisationType {
            id
            name
        }
        integerFields {
            id
            name
            tag
            hint
            optional
            unit
            group {
                id
                name
            }
            maxValue
            minValue
            searchOrder
            searchAsDistinct
        }
        floatFields {
            id
            name
            tag
            hint
            optional
            unit
            group {
                id
                name
            }
            maxValue
            minValue
            searchOrder
            searchAsDistinct
        }
        stringFields {
            id
            name
            tag
            hint
            optional
            group {
                id
                name
            }
            maxLength
            isHtml
            searchOrder
            searchAsDistinct
        }
        selectionFields {
            id
            name
            tag
            hint
            optional
            group {
                id
                name
            }
            searchOrder
            searchAsDistinct
            tag
            options {
                id
                name
                priority
                description
            }
        }
        flagFields {
            id
            tag
            searchOrder
            searchAsDistinct
            name
            hint
            optional
            group {
                id
                name
            }
        }
    }
`;
