import * as React from 'react';
import {
    FormikDatePickerInputField,
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikInputField,
    FormikTextareaField,
    FormSectionBody,
    FormSectionHeader,
} from '@poolware/components';
import { Form } from 'semantic-ui-react';
import { FormikCustomerFields } from './CustomerField';
import { NodeType } from '@poolware/api';
import { FormikSuggestOfficeEmail } from './FormikSelectDestinationEmail';

import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    assetSerial: Yup.string()
        .trim()
        // .min(1, 'Too Short!')
        .required('Required')
        .max(128, 'Too Long!')
        .nullable(),
    installerName: Yup.string()
        .trim()
        // .min(1, 'Too Short!')
        .max(250, 'Too Long!')
        .nullable(),
    assetName: Yup.string()
        .trim()
        // .min(1, 'Too Short!')
        .max(250, 'Too Long!')
        .nullable(),
    faultDescription: Yup.string().trim().max(2000).nullable(),
    comment: Yup.string().trim().max(2000).nullable(),
    receiverWarranter: Yup.object().required('Required').nullable(),
    emailReplyTo: Yup.string().notRequired().trim().email().nullable(),
});

export interface WarrantyClaimFormValues {
    isNew: boolean;
    refCustomer?: NodeType.Customer;
    // refPool?: NodeType.Pool;
    refAsset?: NodeType.Item;
    address?: NodeType.Address;
    assetName?: string;
    assetSerial?: string;
    assetLocation?: string;
    assetInstallDate?: Date;
    claimedAt?: Date;
    installerName?: string;
    faultDescription?: string;
    comment?: string;
    submittedAt?: Date;
    receiverWarranter?: NodeType.FranchiseWarranter;
    customerEmail: {
        value?: string;
        isAdhoc: boolean;
        adhocValue?: string;
    };
    customerPhone: {
        value?: string;
        isAdhoc: boolean;
        adhocValue?: string;
    };
    customerPhoneIsAdhoc: boolean;
    customerPhoneAdhoc?: string;
    customerReadOnly: boolean;
    emailReplyTo: string;
}

export const prepareMutation = (values: WarrantyClaimFormValues) => {
    const address = values.address as NodeType.Address;
    const addressId = address?.id;
    const canUpdateCustomer = !values.customerReadOnly;

    const refCustomer = !canUpdateCustomer ? undefined : values.refCustomer?.id || null;

    return {
        customerEmail: values.customerEmail?.value || null,
        customerPhone: values.customerPhone?.value || null,
        receiverWarranter: values.receiverWarranter?.id,
        refCustomer: refCustomer,
        address: { id: addressId || null },
        assetName: values.assetName?.trim() || null,
        assetSerial: values.assetSerial?.trim() || null,
        assetInstallDate: values.assetInstallDate,
        faultDescription: values.faultDescription?.trim() || null,
        installerName: values.installerName?.trim() || null,
        claimedAt: values.claimedAt,
        comment: values.comment?.trim() || '',
        emailCC: values.emailReplyTo?.trim() || null,
        emailReplyTo: values.emailReplyTo?.trim() || null,
    };
};

export const prepareInitValues = (warrantyClaim?: NodeType.WarrantyClaim): WarrantyClaimFormValues => {
    if (warrantyClaim) {
        return {
            isNew: false,
            receiverWarranter: warrantyClaim.receiverWarranter,
            refCustomer: warrantyClaim.refCustomer || null,
            address: warrantyClaim.address,

            refAsset: warrantyClaim.refAsset,
            assetName: warrantyClaim.assetName,
            assetSerial: warrantyClaim.assetSerial,
            assetLocation: warrantyClaim.assetLocation,
            assetInstallDate: warrantyClaim.assetInstallDate,
            claimedAt: warrantyClaim.claimedAt,
            installerName: warrantyClaim.installerName,
            faultDescription: warrantyClaim.faultDescription,

            customerPhone: {
                value: warrantyClaim.customerPhone || '',
                isAdhoc: false,
                adhocValue: '',
            },
            customerEmail: {
                value: warrantyClaim.customerEmail || '',
                isAdhoc: false,
                adhocValue: '',
            },
            customerPhoneAdhoc: '',
            customerPhoneIsAdhoc: false,
            // Don't allow changing customer field, if there's already customer set on the claim.
            customerReadOnly: !!warrantyClaim.refCustomer?.id,

            comment: warrantyClaim.comment,
            emailReplyTo: warrantyClaim.emailReplyTo?.trim(),
            // refPool : warrantyClaim.refPool,
            // addedBy: warrantyClaim.addedBy,
            // submittedAt: warrantyClaim.submittedAt,
        };
    } else {
        return {
            isNew: true,
            receiverWarranter: null,
            refCustomer: null,
            address: null,
            // refPool : warrantyClaim.refPool,

            refAsset: null,
            assetName: '',
            assetSerial: '',
            assetLocation: '',
            assetInstallDate: null,
            claimedAt: new Date(),
            installerName: '',

            customerEmail: { value: '', isAdhoc: false, adhocValue: '' },
            customerPhone: { value: '', isAdhoc: false, adhocValue: '' },
            customerPhoneIsAdhoc: false,
            customerReadOnly: false,
            faultDescription: '',
            comment: '',
            emailReplyTo: undefined,
        };
    }
};

export interface WarrantyClaimFormProps extends FormikDefaultFormProps<WarrantyClaimFormValues> {
    emailDestinations?: any[];
    offices: NodeType.FranchiseWarranter[];
}

export const WarrantyClaimForm: React.FC<WarrantyClaimFormProps> = ({ initialValues, offices, ...props }) => {
    return (
        <FormikDefaultForm
            submitOnEnter={false}
            validationSchema={validationSchema}
            header={'Warranty Claim Request'}
            icon={'certificate'}
            initialValues={initialValues}
            {...props}
        >
            {(forrmBag) => {
                // const { values } = forrmBag;
                // const isBillingAddressSame = getIn(values, 'isBillingAddressSame');
                // const isCreateSiteWithSameAddress = getIn(values, 'isCreateSiteWithSameAddress');
                const onCustomize = () => {};
                return (
                    <>
                        <FormSectionHeader>Destination Office</FormSectionHeader>
                        <FormSectionBody>
                            <FormikSuggestOfficeEmail
                                name={'receiverWarranter'}
                                label={'Destination Office'}
                                offices={offices}
                                required={true}
                            />
                            <FormikInputField name={'emailReplyTo'} label={'From'} />
                        </FormSectionBody>

                        <FormSectionHeader>Customer Details</FormSectionHeader>
                        <FormSectionBody>
                            <FormikCustomerFields />
                        </FormSectionBody>

                        <>
                            <FormSectionHeader>Product/Asset Details</FormSectionHeader>
                            <FormSectionBody>
                                <Form.Group widths="equal">
                                    <FormikInputField name={'assetName'} label={'Product/Asset Name'} />
                                    <FormikDatePickerInputField name={'claimedAt'} label={'Claim Date'} />
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <FormikInputField required={true} name={'assetSerial'} label={'Serial Number'} />
                                    <FormikDatePickerInputField
                                        name={'assetInstallDate'}
                                        label={'Date of installation'}
                                    />
                                </Form.Group>
                                <FormikInputField name={'installerName'} label={'Installer Supplier'} />

                                <FormikTextareaField rows={8} name={'faultDescription'} label={'Fault Description'} />
                            </FormSectionBody>
                            <FormSectionHeader>Other Details</FormSectionHeader>
                            <FormSectionBody>
                                <FormikTextareaField
                                    rows={8}
                                    name={'comment'}
                                    label={'Comments and Special Instructions'}
                                />
                            </FormSectionBody>
                        </>
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};
