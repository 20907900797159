import * as React from 'react';
import { useMemo } from 'react';
import { Icon } from 'semantic-ui-react';
import { ConnectionTableDef, DefaultConnectionTable, Display, TruncatedDiv } from '@poolware/components';
import { mapServiceJobStageTypeToIconProps, ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import styled from 'styled-components';
import { useWorkOrderFeedActions, WorkOrderBookingStatus } from '../../redux';

const CustomerCellContainer = styled(TruncatedDiv)`
    display: flex;
    flex-direction: column;
`;

const creatTableDef = (mode: WorkOrderBookingStatus): ConnectionTableDef<NodeType.WorkOrderFeedItem> => [
    mode === WorkOrderBookingStatus.BOOKED && {
        header: 'Appointment',
        width: 1,
        cell: (item) => {
            if (!item.appointmentItem) return null;
            return (
                <span>
                    <Icon name={'calendar alternate outline'} />
                    <Display.Date value={item.appointmentItem?.startDate} format={'ddd,  ll, LT'} />
                </span>
            );
        },
    },
    {
        header: 'Work Order ID',
        width: 1,
        cell: (item) => {
            if (!item.workOrder) {
                return <i tw={'text-gray-500 '}>pending...</i>;
            }
            return (
                <span>
                    <Icon {...mapServiceJobStageTypeToIconProps(item.workOrder.stage?.type)} />
                    {item.workOrder.workOrderNumber}
                </span>
            );
        },
    },
    {
        header: 'Work Order',
        width: 4,
        cell: (item) => {
            const { serviceJob, title = '--' } = item;
            const groupTitle = serviceJob?.group?.title;
            const isRecurring = serviceJob?.isRecurring;
            const sjTitle = mode === WorkOrderBookingStatus.UNBOOKED ? serviceJob?.title : null;
            return (
                <div tw={'flex flex-col'}>
                    <div>
                        <Icon name={ModuleIconNames.WorkOrder} />
                        {isRecurring && <Icon color={'grey'} name={ModuleIconNames.RecurringService} />}
                        {_.truncate(title, { length: 50 })}
                    </div>
                    <div tw={'text-xs text-gray-700'} style={{ paddingLeft: 1 }}>
                        <span>
                            {groupTitle && <span tw={'font-bold text-purple-600'}>{groupTitle}</span>}
                            {groupTitle && sjTitle && <Icon name={'caret right'} />}
                            {sjTitle && (
                                <>
                                    {!isRecurring && <Icon color={'grey'} name={ModuleIconNames.ServiceCase} />}
                                    {_.truncate(sjTitle, { length: 40 })}
                                </>
                            )}
                        </span>
                    </div>
                </div>
            );
        },
    },
    {
        header: 'Assigned To',
        cell: (item) => {
            const { staff } = item;
            if (!staff) {
                return null;
            }

            return (
                <>
                    <Icon name={'spy'} />
                    <Display.Entity value={staff} truncateLength={42} />
                </>
            );
        },
    },
    {
        header: 'Customer',
        width: 5,
        cell: (item) => {
            const { customer, address, workOrder, pool } = item;
            // const color = workOrder?.status === NodeType.WorkOrderStatusEnum.Open ? '#2185d0' : '#444444';

            return (
                <CustomerCellContainer maxWidth={300}>
                    {customer && (
                        <div>
                            <Icon name={'user'} />
                            <code style={{ color: '#444444' }}>
                                <b>{customer.crn}</b>
                            </code>{' '}
                            - <Display.Entity value={customer} />
                        </div>
                    )}
                    {customer?.companyName && (
                        <div>
                            <Icon name={'building'} />
                            {customer.companyName}
                        </div>
                    )}
                    {address && (
                        <div>
                            <Icon name={'map marker alternate'} />
                            <Display.Address value={address} />
                        </div>
                    )}
                    {pool && (
                        <div>
                            <Icon name={'life ring'} />
                            Pool - {pool.volume}L {pool.type?.name && `, ${pool.type?.name}`}
                        </div>
                    )}
                </CustomerCellContainer>
            );
        },
    },
    mode === WorkOrderBookingStatus.UNBOOKED && {
        header: 'Created',
        cell: (item) => {
            if (item.workOrder?.createdAt) {
                return <Display.Date value={item.workOrder?.createdAt} />;
            } else {
                return null;
            }
        },
    },
];

const tableCellProps: any = {
    verticalAlign: 'top',
    style: {
        borderTop: '1px solid hsl(206, 50%, 85%)',
    },
};

export interface PageTableProps {
    onView: (item: NodeType.WorkOrderFeedItem, refetchQuery?: any) => any;
    connectionState;
    connectionData;
}

export const WorkOrdersTable: React.FC<PageTableProps> = ({ onView, connectionState, connectionData }) => {
    const { State } = useWorkOrderFeedActions();

    const tableDef = useMemo(() => {
        return creatTableDef(State.filters.woBookingStatus);
    }, [State.filters.woBookingStatus]);

    return (
        <DefaultConnectionTable
            debug={false}
            onRowClick={(item) => onView(item)}
            tableProps={{ color: ModuleColorNames.WorkOrder }}
            tableDef={tableDef}
            tableCellProps={tableCellProps}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
