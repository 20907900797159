import gql from 'graphql-tag';
import { NodeType, useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const QL = gql`
    query FranchiseTreatmentsQuery($pageSize: Int, $page: Int) {
        viewer {
            me {
                franchise {
                    id
                    franchiseProblemViews(first: $pageSize, page: $page) {
                        pageMeta {
                            pageCount
                            totalCount
                        }
                        edges {
                            node {
                                id
                                treatmentViews {
                                    edges {
                                        node {
                                            id
                                            isBlacklisted
                                            globalPriority
                                            localPriority
                                            testTreatment {
                                                id
                                                name
                                                affects {
                                                    edges {
                                                        node {
                                                            id
                                                            primary
                                                            measurementType {
                                                                id
                                                                name
                                                            }
                                                        }
                                                    }
                                                }
                                                consumableProduct {
                                                    id
                                                    product {
                                                        id
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                testProblem {
                                    id
                                    name
                                    criteria {
                                        edges {
                                            node {
                                                id
                                                measurementType {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export interface UseQueryFranchiseTreatmentsQueryConnectionProps {}

function mapPropsToSearchQuery(props?: UseQueryFranchiseTreatmentsQueryConnectionProps) {
    if (!props) {
        return undefined;
    }

    const search = {};
    return search;
}

export function useQueryFranchiseTreatmentsConnection(props?: UseQueryFranchiseTreatmentsQueryConnectionProps) {
    const connectionPath = 'viewer.me.franchise.franchiseProblemViews';
    const { variables, connectionState } = useDefaultConnectionState({ defaultPageSize: 200 });
    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection<NodeType.FranchiseTestProblemView>({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
