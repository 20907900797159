import * as React from 'react';
import { useEffect, useState } from 'react';
import { DevOnly, IconButton } from '@poolware/components';
import * as _ from 'lodash';
import { Button } from 'semantic-ui-react';

export function createAutocompleteEditableItem<T = any>(input: {
    inputComp: React.ComponentType<any>;
    renderItem: (item: T) => React.ReactNode;
}): React.FC<{ value: T; onChange: (value: T) => any }> {
    const { inputComp: Input, renderItem } = input;
    return ({ value, onChange }) => {
        const [initalValue, setInitialValue] = useState(value);
        const [newValue, setNewValue] = useState(value);

        const [isEdit, setEdit] = useState(false);

        const handleItemSelect = (value: T | null) => {
            setNewValue(value);
        };

        useEffect(() => {
            setNewValue(value);
            setInitialValue(value);
        }, [isEdit]);

        if (!isEdit) {
            return (
                <div>
                    <span>{renderItem(value)}</span>
                    <IconButton onClick={() => setEdit(!isEdit)} name={'edit'} />
                </div>
            );
        }

        const canSave = _.get(initalValue, 'id') !== _.get(newValue, 'id');

        const handleOnSave = () => {
            if (canSave && onChange) {
                onChange(newValue);
            }
            setEdit(false);
        };

        return (
            <>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1 1 200px' }}>
                        <Input value={newValue} onChange={handleItemSelect} />
                    </div>
                    <div style={{ marginLeft: '0.5em', display: 'flex' }}>
                        <Button
                            size={'mini'}
                            disabled={!canSave}
                            icon={'save'}
                            onClick={handleOnSave}
                            content={'Save'}
                        />
                        <Button size={'mini'} basic onClick={() => setEdit(false)} content={'Cancel'} />
                    </div>
                </div>
                <DevOnly hidden>
                    <pre>{JSON.stringify({ value, newValue }, null, 2)}</pre>
                </DevOnly>
            </>
        );
    };
}
