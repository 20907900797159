import * as React from 'react';
import { fromEdges } from '@poolware/api';
import {
    EmptyBoxImage,
    MenuBarItem,
    MenuBarSection,
    Panel,
    SectionHeader,
    StickyMenuBar,
    VStack,
} from '@poolware/components';
import { PageControlProps } from './Page.Control';
import { groupItemsByProductDeclaration } from '../utils';
import PoolDetails from '../../../CommonComponents/PoolDetails';
import EquipmentList from './EquipmentList';
import * as URLBuilder from '../../../../routes/url-builder';
import styled from 'styled-components';

const StyledEmptyBoxImgContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const NoEquipmentImg = () => (
    <StyledEmptyBoxImgContainer>
        <div>
            <EmptyBoxImage />
        </div>
        <div>
            <small>No Equipment</small>
        </div>
    </StyledEmptyBoxImgContainer>
);

export const PoolPage: React.FC<PageControlProps> = (props) => {
    const { pool, customerId, poolProductDeclarations } = props;
    const poolItems = fromEdges(pool?.items);
    const groups = groupItemsByProductDeclaration(poolProductDeclarations, poolItems);

    const onGoBack = () => {
        const customerLink = URLBuilder.Customer(customerId).Pool(pool.id).view;
        props.AppNavigator.navigateToOrigin(customerLink);
    };

    const handleOnProductAdd = () => {
        props.AppNavigator.navigate('/new', { relativeToModule: true, setOrigin: true });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem title={'Back'} icon={'chevron left'} onClick={onGoBack} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarItem title={'Add Equipment'} icon={'plus'} color={'green'} onClick={handleOnProductAdd} />
                </MenuBarSection>
            </StickyMenuBar>

            <Panel>
                <Panel.Header icon={'life ring'} content="Pool" />
                <Panel.Body>
                    <PoolDetails pool={pool} />
                </Panel.Body>
            </Panel>

            <SectionHeader
            // button={{
            //     content: 'Add Equipment',
            //     color: 'teal',
            //     icon: 'plus',
            //     onClick: handleOnProductAdd,
            // }}
            >
                Pool Equipment
            </SectionHeader>

            {poolItems.length > 0 ? (
                <EquipmentList
                    groups={groups}
                    onRemoveProductItem={(item) => props.PoolMutator.removeProductItem(pool, item.id)}
                />
            ) : (
                <NoEquipmentImg />
            )}
        </VStack>
    );
};

export default PoolPage;
