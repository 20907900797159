import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';
import { fragmentAbstractDoc } from './fragments';
import { InvoiceDoc } from '../constants';

const QL = gql`
    query QueryAbstractDoc($id: ID!) {
        node(id: $id) {
            id
            ...QueryAbstractDocFull
        }
    }
    ${fragmentAbstractDoc}
`;

export interface AbstractDocumentTyped<T = any> extends NodeType.AbstractDocument {
    jsonBody: T;
}

export const useQueryAbstractDoc = createUseQueryNodeHook<AbstractDocumentTyped>(QL);
export const useQueryInvoiceDoc = createUseQueryNodeHook<AbstractDocumentTyped<InvoiceDoc.DocContent>>(QL);
