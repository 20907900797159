import * as React from 'react';

import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';

import View from './PoolEquipmentListView';
import New from './RegisterNew';
import EquipmentItemDetails from './ViewEquipmentItem';

export const Router = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path="/new" exact={true} component={New} />
            <ModuleRoute path="/" exact={true} component={View} />
            <ModuleRoute path="/:itemId" component={EquipmentItemDetails} />
        </ModuleRootSwitch>
    );
};
