import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useAppConfig } from '@poolware/app-shell';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Icon } from 'semantic-ui-react';
import { DevOnly, toastError } from '@poolware/components';

// Used by the mobile app to open pdf link.
// Must not be removed.

export const RedirectToPDFReport: React.FC = () => {
    const { params } = useAppNavigator<{ id?: string }>();
    const appConfig = useAppConfig();
    const reportId = params?.id;
    const PdfURL = reportId ? `${appConfig.api.restAPIBaseUrl}/report?id=${reportId}` : null;
    const openedRef = useRef(false);

    useEffect(() => {
        if (!reportId || openedRef.current) return;
        openedRef.current = true;
        try {
            window.open(PdfURL, '_self');
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    }, [reportId, openedRef]);

    return (
        <div>
            <h3>Redirecting...</h3>
            <p>If report does not open automatically, please click on the link below.</p>
            <p>
                <a href={PdfURL}>
                    <Icon name={'file pdf'} color={'red'} /> PDF Report
                </a>
            </p>
            <DevOnly>
                <p style={{ paddingTop: '3rem', color: '#888888' }}>
                    <small>Report id: {params.id}</small>
                </p>
            </DevOnly>
        </div>
    );
};
