import * as React from 'react';
import { Panel, withApolloLoading } from '@poolware/components';
import { fromEdges, NodeType } from '@poolware/api';
import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import * as _ from 'lodash';
import gql from 'graphql-tag';
import { productTraitFragment } from '../../../../queries/fragments';
import { ProductTraitsTable } from './ProductTraitsTable';
import { queryNames } from '../../query-names';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../../queries';

const ProductTraitsPanel: React.FC<PageControlProps> = ({ product, ProductCatalogMutator, ...props }) => {
    const productTraits = fromEdges(_.get(product, 'traits'));
    return (
        <Panel>
            <Panel.Header content={'Technical Specification'} />
            <Panel.Body>
                <ProductTraitsTable traits={productTraits} />
            </Panel.Body>
        </Panel>
    );
};

const QL = gql`
    query ProductDetails_traitsTable($id: ID!) {
        product: node(id: $id) {
            ... on Product {
                id
                name
                addedBy {
                    id
                }
                traits {
                    ...ProductTraitFragment_Catalog
                    declaration {
                        id
                        id
                        tag
                        name
                        parent {
                            id
                            name
                        }
                        subDeclarations {
                            id
                            name
                        }
                        integerFields {
                            id
                            name
                            unit
                        }
                        floatFields {
                            id
                            name
                            unit
                        }
                        stringFields {
                            id
                            name
                        }
                        selectionFields {
                            id
                            name
                            hint
                            tag
                            options {
                                id
                                name
                                priority
                                description
                            }
                        }
                        parentDeclarations {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
    ${productTraitFragment}
`;

export default compose(
    graphql(QL, {
        options: (props: ProductTraitsTableExternalProps) => {
            return {
                variables: { id: props.productId },
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.product.id' }),
    mapProps((props) => {
        const product = _.get(props, 'data.product');
        return {
            ...props,
            product,
        };
    }),
    withProductCatalogMutators([...queryNames, 'ProductDetails_traitsTable'])
)(ProductTraitsPanel) as React.ComponentType<ProductTraitsTableExternalProps>;

interface ProductTraitsTableExternalProps {
    productId: NodeType.ID;
}

export interface PageControlProps extends ProductTraitsTableExternalProps, IProductCatalogMutators {
    product: NodeType.Product;
}
