import * as React from 'react';
import gql from 'graphql-tag';
import {
    NodeType,
    QueryConnection,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import * as _ from 'lodash';

const connectionPath = 'viewer.productDeclarations';
const QL = gql`
    query TraitDeclarations(
        $after: String
        $first: Int
        $before: String
        $last: Int
        $page: Int
        $sort: ProductTraitDeclarationViewerProductDeclarationsSort
        $search: ProductTraitDeclarationViewerProductDeclarationsSearch
    ) {
        viewer {
            productDeclarations(
                after: $after
                first: $first
                before: $before
                last: $last
                page: $page
                sort: $sort
                search: $search
            ) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        tag
                        name
                        franchise {
                            id
                            name
                        }
                        organisationType {
                            id
                            name
                        }
                        parent {
                            id
                            name
                        }
                        subDeclarations {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export interface QueryProductDeclarationsConnectionProps
    extends QueryConnectionExternalProps<NodeType.ProductTraitDeclaration> {
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}

const mapPropsViewerProductDeclarationsConnection = (props?: QueryProductDeclarationsConnectionProps) => {
    const search: NodeType.ProductTraitDeclarationViewerProductDeclarationsSearch = {};

    if (props?.organisationType) {
        _.set(search, 'organisationType.id', NodeType.extractId(props?.organisationType));
    }

    return search;
};

export function useQueryViewerProductDeclarationsConnection(props?: QueryProductDeclarationsConnectionProps) {
    const { variables, connectionState } = useDefaultConnectionState({
        search: mapPropsViewerProductDeclarationsConnection(props),
        ...props,
    });

    return useQueryConnection({
        query: props?.query || QL,
        fetchPolicy: 'cache-and-network',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}

export const QueryProductDeclarationsConnection: React.FC<QueryProductDeclarationsConnectionProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState({
        search: mapPropsViewerProductDeclarationsConnection(props),
        ...props,
    });
    return (
        <QueryConnection
            fetchPolicy={props.fetchPolicy}
            query={props.query || QL}
            variables={variables}
            connectionConfig={connectionState}
            connectionPath={connectionPath}
            children={props.children}
        />
    );
};

export const withQueryProductDeclarationsConnection = (
    options: Omit<QueryProductDeclarationsConnectionProps, 'children'>
) => (C) => {
    return (props) => (
        <QueryProductDeclarationsConnection {...options}>
            {(connectionProps) => {
                return <C {...props} {...connectionProps} />;
            }}
        </QueryProductDeclarationsConnection>
    );
};
