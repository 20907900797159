import { NodeType } from '@poolware/api';
import { StatsGroupByEnum } from '../Queries/QueryStats';

export const getDateFormatterForResolution = (resolution: NodeType.DateRangeResolutionEnum): string => {
    switch (resolution) {
        case NodeType.DateRangeResolutionEnum.Day:
            return 'll';
        case NodeType.DateRangeResolutionEnum.Week:
            return 'll';
        case NodeType.DateRangeResolutionEnum.Month:
            return 'MMM YYYY';
        case NodeType.DateRangeResolutionEnum.Quarter:
            return 'MMM YYYY';
        case NodeType.DateRangeResolutionEnum.Year:
            return 'YYYY';
    }
};

export const getResolutionOptions = () => {
    return [
        { text: 'Day', value: NodeType.DateRangeResolutionEnum.Day },
        { text: 'Week', value: NodeType.DateRangeResolutionEnum.Week },
        { text: 'Month', value: NodeType.DateRangeResolutionEnum.Month },
        { text: 'Year', value: NodeType.DateRangeResolutionEnum.Year },
    ];
};
export const getGroupByOptions = (isAdmin: boolean) => {
    const options = [
        { text: 'Total', value: StatsGroupByEnum.None },
        { text: 'Staff', value: StatsGroupByEnum.Staff },
    ];
    if (isAdmin) {
        options.push({ text: 'Franchise', value: StatsGroupByEnum.Franchise });
    }
    return options;
};
