import * as React from 'react';
import { ModuleRedirect, ModuleRootSwitch, ModuleRoute, useAppNavigator } from '@poolware/react-app-navigator';
import { PageLayout } from '@poolware/components';
import { Sidebar } from './Sidebar';
import { matchPath } from 'react-router';
import { routeConfig } from './route-config';

const PagesRoute = () => {
    const defaultPath = routeConfig[0].route?.path;
    return (
        <>
            <ModuleRootSwitch>
                <ModuleRedirect path={'/'} exact to={defaultPath} />
                {routeConfig.map((rc, i) => {
                    return <ModuleRoute key={i} path={rc.route.path} exact={true} component={rc.route.component} />;
                })}
            </ModuleRootSwitch>
        </>
    );
};

export const Router = () => {
    const { isModal, location } = useAppNavigator();

    // TODO: find a better way to hide settings sidebar
    const match = matchPath(location.pathname, {
        path: '/dev/service-job-template-picker',
        exact: true,
        strict: false,
    });

    if (match) {
        return <PagesRoute />;
    }

    return <PageLayout.SideBarLayout main={<PagesRoute />} leftSidebar={<Sidebar />} />;
};
