import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Display } from '@poolware/components';
import ContactItem from '../../CommonComponents/Contacts/ContactItem';
import styled from 'styled-components';
import _sortBy from 'lodash/sortBy';

const ContactLabel = styled.i`
    padding-left: 0.5em;
    color: grey;
`;

const lookupSpanFormatter = (contact: NodeType.Contact) => {
    if (!contact.type) return null;
    return () => (
        <>
            <Display.ContactItem value={contact} noLink={true} />
            {contact.label && <ContactLabel>({contact.label})</ContactLabel>}
        </>
    );
};

const mapEntityContactsToContactItems = (entityContacts) => {
    return _sortBy(entityContacts, (c) => c?.type?.name).map((contact: NodeType.Contact) => {
        const typeName = contact?.type?.name;
        return new ContactItem({
            label: typeName,
            view: lookupSpanFormatter(contact),
        });
    });
};

export type ContactItemsGroupType = {
    customerDetailsGroup: ContactItem[];
    addressGroup: ContactItem[];
    contactItemsGroup: ContactItem[];
};

export const prepareCustomerContactItems = (
    customer: NodeType.Customer,
    onlyContacts = false
): ContactItemsGroupType | null => {
    // Contact items extracted from "Customer" and "User" objects

    if (!customer) return null;

    const customerDetailsGroup = [
        new ContactItem({
            label: 'CRN',
            view: () => <Display.Span value={customer.crn} />,
        }),

        new ContactItem({
            label: 'Name',
            view: () => <Display.Entity value={customer} />,
        }),
        new ContactItem({
            label: 'Contact Name',
            view: () => {
                return customer.contactName ? (
                    <Display.Span style={{ textTransform: 'capitalize' }} value={customer.contactName} />
                ) : (
                    ''
                );
            },
        }),
    ];

    const addressGroup = [
        new ContactItem({
            label: 'Primary Address',
            view: () => <Display.Address value={customer.primaryAddress} />,
        }),
        new ContactItem({
            label: 'Bill Address',
            view: () => (customer.billAddress ? <Display.Address value={customer.billAddress} /> : ''),
        }),
    ];

    // Contact items extracted from "Entity" object
    const entityContacts = fromEdges(customer?.user?.entity?.contacts);
    const contactItemsGroup = mapEntityContactsToContactItems(entityContacts);

    return {
        customerDetailsGroup,
        addressGroup,
        contactItemsGroup,
    };
};
