import * as React from 'react';
import styled from 'styled-components';
import { useWindowSize } from '@ez/tools';
import { MenuBarHeaderItem, MenuBarSection, StickyMenuBar, VStack } from '@poolware/components';
import { PageContainer } from '../../Layout/PageContainer';

export interface PageProps {}

const DivContainer = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    max-width: 800px;
    margin-left: auto;
    margin-top: 10px;
    margin-right: auto;
    @media screen and (max-width: 768px) {
        margin: 0.5em;
    }
`;

export const Page: React.FC<PageProps> = () => {
    const { innerHeight } = useWindowSize();
    const height = innerHeight + 80;
    return (
        <PageContainer>
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem icon={'lab'}>SDS Search</MenuBarHeaderItem>
                    </MenuBarSection>
                </StickyMenuBar>
                <DivContainer>
                    <iframe
                        title={'SDS Search'}
                        frameBorder="0"
                        width="100%"
                        height={height}
                        style={{ overflowY: 'scroll' }}
                        src="https://go.lupinsys.com/waterco/harms/public/materials?groups%5B%5D=d0db9b3c9255a4a9879d04ecd6d8d655"
                    />
                </DivContainer>
            </VStack>
        </PageContainer>
    );
};

export default Page;
