import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Grid, Segment } from 'semantic-ui-react';
import {
    AppointmentViewContextProvider,
    AppointmentViewDock,
    SchedulerModuleRouterID,
    useAppointmentViewCtx,
    useBookingActions,
} from '@poolware/app-service-jobs';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentPresentationMode, AppointmentsList } from './AppointmetnsList';
import { QueryAppointmentsForCustomer } from './QueryAppointmentsForCustomer';
import invariant from 'invariant';
import { ScrollY, SectionHeader, SlideInDock, useModalCtrl, VStack } from '@poolware/components';
import tw from 'twin.macro';
import { useViewer } from '@poolware/app-shell';

const NoAppointmentsMessage = tw.div`text-sm text-gray-600`;

interface AppointmentsListProps {
    forward?: boolean;
    onSelect?: (item: NodeType.AppointmentItem, connectionRefetch?: () => any) => any;
    refetch?: () => Promise<any>;
    customerId: NodeType.ID;
    poolId: NodeType.ID;
    mode: AppointmentPresentationMode;
}

const TodayAppointments: React.FC<AppointmentsListProps> = ({ customerId, poolId, onSelect, mode }) => {
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();

    return (
        <QueryAppointmentsForCustomer startDate={startDate} endDate={endDate} customerId={customerId} poolId={poolId}>
            {({ connectionData, connectionState }) => {
                if (connectionData.length === 0) {
                    return <NoAppointmentsMessage>No appointments today.</NoAppointmentsMessage>;
                }

                return (
                    <div tw={'rounded -m-1 p-2 pb-4 bg-green-50'}>
                        <AppointmentsList
                            appointments={connectionData}
                            onSelect={(item) => onSelect(item, connectionState.refetch)}
                            mode={mode}
                        />
                    </div>
                );
            }}
        </QueryAppointmentsForCustomer>
    );
};

const Appointments: React.FC<AppointmentsListProps> = ({ customerId, poolId, forward = true, onSelect, mode }) => {
    const startDate = forward
        ? moment().add(1, 'day').startOf('day').toDate()
        : moment().subtract(1, 'day').endOf('day').toDate();

    return (
        <QueryAppointmentsForCustomer
            pageSize={8}
            startDate={startDate}
            reverse={!forward}
            customerId={customerId}
            poolId={poolId}
        >
            {({ connectionData, connectionState, loadMore }) => {
                const appointments = connectionData.sort((a, b) => {
                    return forward
                        ? moment(a.startDate).isBefore(b.startDate)
                            ? -1
                            : 1
                        : moment(a.startDate).isAfter(b.startDate)
                        ? -1
                        : 1;
                });

                if (appointments.length === 0) {
                    if (forward) {
                        return <NoAppointmentsMessage>No future appointments.</NoAppointmentsMessage>;
                    } else {
                        return <NoAppointmentsMessage>No previous appointments.</NoAppointmentsMessage>;
                    }
                }

                // console.log(connectionState);
                const hasNext = connectionState.pageInfo.hasNextPage;

                return (
                    <VStack>
                        <ScrollY maxHeight={2000}>
                            <AppointmentsList
                                appointments={connectionData}
                                onSelect={(item) => onSelect(item, connectionState.refetch)}
                                mode={mode}
                            />
                        </ScrollY>
                        {hasNext && (
                            <Button
                                size={'small'}
                                basic
                                fluid
                                loading={connectionState.isFetchingMore}
                                content={'Show more...'}
                                onClick={() => loadMore({ forward: forward })}
                            />
                        )}
                    </VStack>
                );
            }}
        </QueryAppointmentsForCustomer>
    );
};

export interface TabAppointmentsProp {
    customer?: NodeType.Customer;
    pool?: NodeType.Pool;
    refetch?: () => Promise<any>;
    mode: AppointmentPresentationMode;
}

const AppointmentsPanelComp: React.FC<TabAppointmentsProp> = (props) => {
    const { customer, pool, mode } = props;
    const { appLayoutMode } = useViewer();
    const [refreshKey, setRefreshKey] = useState('1');
    const previewItemCtx = useAppointmentViewCtx();
    const modalCtrl = useModalCtrl();

    useEffect(() => {
        const rq = ['CustomerAppointmentsList'];
        previewItemCtx.addRefetchQueries(rq);
        return () => {
            previewItemCtx.removeRefetchQueries(rq);
        };
    }, []);

    invariant(customer?.id || pool?.id, 'Customer id or pool id is required');

    const { AppNavigator, location } = useAppNavigator();
    const { BookingAction } = useBookingActions();

    const onSelectAppt = (apptItem: NodeType.AppointmentItem) => {
        if (!apptItem) {
            console.error('apptItem is not provided');
            return;
        }
        if (appLayoutMode.isMobile) {
            AppNavigator.navigateRelative(`/${apptItem?.id}/preview`, {
                moduleId: SchedulerModuleRouterID,
                setOrigin: true,
            });
        } else {
            previewItemCtx.setAppointmentItemId(apptItem?.id);
            modalCtrl.onOpen();
        }
    };

    const onCloseAppt = () => {
        previewItemCtx.setAppointmentItemId(null);
        modalCtrl.onClose();
    };

    const onDidDelete = async () => {
        onCloseAppt();
        // HACK: unable call apollo's 'refetch' callback from here.
        // Using React key to force reload react component tree.
        setRefreshKey(new Date().toISOString());
    };

    const onBookAppointment = () => {
        switch (mode) {
            case AppointmentPresentationMode.Customer:
                {
                    BookingAction.startFromCustomer({
                        details: { customer: customer },
                        returnTo: location,
                    });
                }
                break;
            case AppointmentPresentationMode.Pool: {
                const customer = pool?.entity?.user?.customer;
                BookingAction.startFromPool({
                    details: { customer: customer, pool: pool },
                    returnTo: location,
                });
                break;
            }
        }
    };

    const button = {
        content: 'Book Appointment',
        icon: 'plus',
        color: 'green',
        onClick: onBookAppointment,
    };

    return (
        <VStack key={refreshKey}>
            <SectionHeader button={button} size={'small'} content={`Appointments`} />
            <>
                <Segment color={'blue'} basic={true} tw={'px-0!'}>
                    <SectionHeader>Today</SectionHeader>
                    <TodayAppointments
                        mode={mode}
                        customerId={customer?.id}
                        poolId={pool?.id}
                        onSelect={onSelectAppt}
                    />
                </Segment>
                <Segment color={'grey'} basic={true} tw={'px-0!'}>
                    <Grid divided columns={2} stackable reversed="mobile" padded={false}>
                        <Grid.Column>
                            <SectionHeader>Past Appointments</SectionHeader>
                            <Appointments
                                mode={mode}
                                forward={false}
                                customerId={customer?.id}
                                poolId={pool?.id}
                                onSelect={onSelectAppt}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <SectionHeader>Future Appointments</SectionHeader>
                            <Appointments
                                mode={mode}
                                forward={true}
                                customerId={customer?.id}
                                poolId={pool?.id}
                                onSelect={onSelectAppt}
                            />
                        </Grid.Column>
                    </Grid>
                </Segment>
            </>
            {modalCtrl.open && previewItemCtx.appointmentItemId && (
                <SlideInDock onClose={onCloseAppt}>
                    <AppointmentViewDock
                        onClose={onCloseAppt}
                        // appointmentItem={previewItemCtx.appointmentItem}
                        appointmentItemId={previewItemCtx.appointmentItemId}
                        onDidDelete={onDidDelete}
                    />
                </SlideInDock>
            )}
        </VStack>
    );
};

export const AppointmentsPanel: React.FC<TabAppointmentsProp> = (props) => {
    return (
        <AppointmentViewContextProvider>
            <AppointmentsPanelComp {...props} />
        </AppointmentViewContextProvider>
    );
};
