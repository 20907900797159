import * as React from 'react';
import { NodeType } from '@poolware/api';
import { FormikSuggestInputProps, SuggestInput, withFormikSuggestField } from '@poolware/components';
import { usePersistedString } from '@ez/tools';

export interface OfficeSuggestInputProps extends FormikSuggestInputProps<NodeType.FranchiseWarranter> {
    offices: NodeType.FranchiseWarranter[];
}

export const OfficeSuggestInput: React.FC<OfficeSuggestInputProps> = ({ onChange, offices, ...rest }) => {
    const [_, setReceiverId] = usePersistedString('WRT.reciever-id', null);

    const onItemSelect = (item: NodeType.FranchiseWarranter) => {
        setReceiverId(item?.id || null);
        onChange(item);
    };

    const itemTextLabel = (item: NodeType.FranchiseWarranter) => {
        return item?.name;
    };

    const getOptionValue = (item: NodeType.FranchiseWarranter) => {
        return item?.id;
    };

    const formatOptionLabel = (o, ctx) => {
        const isCtxValue = ctx.context === 'value';
        const item = o.item;

        if (isCtxValue) {
            return (
                <>
                    {item?.name} | {item?.email}
                </>
            );
        }

        return (
            <>
                <>{item?.name}</>
                <span style={{ fontSize: '0.9em', float: 'right', fontStyle: 'italic' }}>{item?.email}</span>
            </>
        );
    };

    return (
        <SuggestInput
            {...rest}
            formatOptionLabel={formatOptionLabel}
            items={offices}
            loading={false}
            // loading={connectionState.loading}
            onItemSelect={onItemSelect}
            itemTextLabel={itemTextLabel}
            getOptionValue={getOptionValue}
        />
    );
};

export interface FormikSuggestOfficeEmailExtProps {
    offices: NodeType.FranchiseWarranter[];
}
export const FormikSuggestOfficeEmail = withFormikSuggestField<FormikSuggestOfficeEmailExtProps>(OfficeSuggestInput);
