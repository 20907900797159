import gql from 'graphql-tag';
import { appointmentItemFragment, jobTodoFragment } from '../../queries/fragments';
import { createUseQueryNodeHook, customerContactDetailsFragment, NodeType } from '@poolware/api';

const QL = gql`
    query QueryWorkOrder($id: ID!) {
        node(id: $id) {
            id
            ... on WorkOrder {
                title
                workOrderNumber
                description
                address {
                    id
                    ...AddressFragment
                }
                pool {
                    id
                    name
                    address {
                        id
                        ...AddressFragment
                    }
                }
                customer {
                    id
                    ...CustomerContactDetailsFragment
                }
                assignedTo {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
                address {
                    ...AddressFragment
                }
                pool {
                    id
                    name
                    bottleNumber
                    volume
                    type {
                        id
                        name
                    }
                    site {
                        id
                        accessKeyLocation
                    }
                    sanitiser {
                        id
                        name
                    }
                }
                testReports {
                    edges {
                        node {
                            id
                            createdAt
                            pdfUrl
                            pool {
                                id
                                entity {
                                    id
                                    user {
                                        id
                                        firstName
                                        lastName
                                        customer {
                                            id
                                            contactName
                                            crn
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                appointmentItem {
                    id
                    ...AppointmentItemFragment_ServiceJob
                }
                serviceJob {
                    id
                    title
                    serviceJobNumber
                    description
                    dueDate
                    isRecurring
                    customer {
                        id
                    }
                    pool {
                        id
                    }
                    address {
                        id
                    }
                    entity {
                        id
                        franchise {
                            id
                            name
                        }
                    }
                }
                stage {
                    id
                    title
                    type
                    status
                }
                stageCandidates {
                    id
                    title
                    type
                }
                stageHistory {
                    edges {
                        node {
                            fromStage {
                                id
                                title
                                type
                            }
                            toStage {
                                id
                                title
                                type
                            }
                            startedAt
                            endedAt
                            startedBy {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
                jobs {
                    edges {
                        node {
                            id
                            ...JobTodoFragment
                        }
                    }
                }
                files(sort: { createdAt: true }) {
                    edges {
                        node {
                            id
                            isPublic
                            note
                            fileName
                            mimeType
                            location
                            createdAt
                            isImage
                            imageUrl
                            url
                            checkMutations {
                                delete
                                update
                            }
                        }
                    }
                }
            }
        }
    }
    ${jobTodoFragment}
    ${customerContactDetailsFragment}
    ${appointmentItemFragment}
`;

export const useQueryWorkOrder = createUseQueryNodeHook<NodeType.WorkOrder>(QL);
