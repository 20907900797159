import { InferProps } from 'prop-types';
import React from 'react';

import * as dates from './utils/dates';
import { CalNavigate } from './types';
import TimeGrid from './TimeGrid';
import { commonViewProps } from './CalendarPropsTypes';

const propTypes = {
    ...commonViewProps,
};

class Day extends React.Component<InferProps<typeof propTypes>> {
    render() {
        let { date, ...props } = this.props;
        let range = Day.range(date);

        return <TimeGrid date={date} {...props} range={range} eventOffset={10} />;
    }

    public static range = (date) => {
        return [dates.startOf(date, 'day')];
    };

    public static navigate = (date: Date, action: CalNavigate) => {
        switch (action) {
            case CalNavigate.PREVIOUS:
                return dates.add(date, -1, 'day');

            case CalNavigate.NEXT:
                return dates.add(date, 1, 'day');

            default:
                return date;
        }
    };

    public static title = (date, { localizer }) => localizer.format(date, 'dayHeaderFormat');

    public static propTypes = propTypes;
}

export default Day;
