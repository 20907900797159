import * as React from 'react';
import { ModuleRedirect, ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import * as Product from './Products';

const Index = () => {
    return <ModuleRedirect to={'/products'} />;
};

export const Router = () => {
    return (
        <ModuleRootSwitch notFound={NotFoundPage}>
            <ModuleRoute path={'/'} exact component={Index} />
            <ModuleRoute path={'/products'} component={Product.Router} />
        </ModuleRootSwitch>
    );
};
