import * as React from 'react';

import { Container, FormikDefaultForm, FormikInputField, toastError } from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationServiceJobGroup } from '@poolware/api';
import * as Yup from 'yup';
import { FormikSuggestInputFranchise, FormikSuggestInputOrganisationType } from '@poolware/app-shell';

const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    organisationType: Yup.mixed().required('Required'),
});

export interface NewPageProps {}

export const New: React.FC<NewPageProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { create } = useMutationServiceJobGroup({ refetchQueries: ['QueryServiceJobGroupConnection'] });

    const onCancel = () => {
        AppNavigator.replaceToOrigin('/', { relativeToModule: true });
    };

    const initialValues = {
        title: '',
        franchise: null,
        organisationType: null,
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const res = await create({
                title: values.title?.trim() || '',
                franchise: values.franchise?.id || null,
                organisationType: values?.organisationType?.id,
            });

            AppNavigator.replaceToOrigin();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };
    return (
        <Container>
            <FormikDefaultForm
                validationSchema={validationSchema}
                header={'New Service Group'}
                initialValues={initialValues}
                submitButton={{ content: 'Create' }}
                onSubmit={onSubmit}
                onCancel={onCancel}
            >
                <FormikSuggestInputOrganisationType label={'Organisation'} name={'organisationType'} required={true} />
                <FormikSuggestInputFranchise label={'Franchise'} name={'franchise'} />
                <FormikInputField label={'Group Name'} name={'title'} required={true} />
            </FormikDefaultForm>
        </Container>
    );
};
