import * as React from 'react';
import { useMemo } from 'react';
import {
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    FormSectionBody,
    FormSectionHeader,
    toastError,
} from '@poolware/components';
import {
    FormikCustomerAndLocationInputFields,
    FormikSuggestInputStaff,
    PageContent,
    useViewer,
} from '@poolware/app-shell';
import { useAppNavigator } from '@poolware/react-app-navigator';
import * as Yup from 'yup';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { Icon, Segment } from 'semantic-ui-react';
import { useServiceJobActions } from '../../index';
import { NodeType } from '@poolware/api';
import { FormikServiceJobTemplateSelect } from '../../connected-components/FormikServiceJobTemplateSelect';
import { sanitizeRecurrence } from '../../queries/use-appointment-mutators';
import Recurrence, {
    validationSchemaRecurrencePattern,
} from '../../Scheduler/AppointmentForm/FormFields/RecurrenceField';
import TimeField from '../../Scheduler/AppointmentForm/FormFields/TimeField';
import { defaultRecurrence } from '../../Scheduler/AppointmentForm/common';

const validationSchema = Yup.object().shape({
    customer: Yup.object().required('Required'),
    sjTemplate: Yup.object().nullable().required('Required'),
    woTemplate: Yup.object().nullable().required('Required'),
    startDate: Yup.date().required('Required'),
    title: Yup.string().required('Required').max(250),
    description: Yup.string().notRequired().max(4000),
    recurrence: validationSchemaRecurrencePattern.required('Required'),
});

type ServiceJobFormValueType = {
    title: string;
    description: string;
    customer: any;
    pool: any;
    address: any;
    dueDate: any;
    startDate: Date;
    duration: number;
    staff: any;
    recurrence: any;
    sjTemplate: any;
    woTemplate: any;
    staffReadOnly?: boolean;
};

export const ServiceJobRecurNew: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess, viewer } = useViewer();

    const sjActions = useServiceJobActions();
    const { customer, address, pool, returnTo } = sjActions.ServiceJobState.details;

    const { create } = useServiceJobMutators({ refetchQueries: ['QueryServiceJob'] });

    let initialValues = useMemo<ServiceJobFormValueType>(() => {
        const initialValues = {
            title: '',
            description: '',
            customer: customer,
            pool: pool,
            address: address,
            dueDate: null,
            startDate: new Date(),
            duration: 60,
            sjTemplate: null,
            woTemplate: null,
            staff: null,
            staffReadOnly: false,
            recurrence: defaultRecurrence,
        };
        if (modulesAccess.FieldServices?.calendarSingleStaffMode) {
            // lock field only if `me.staff` is available
            initialValues.staffReadOnly = !!viewer.me?.staff;
            initialValues.staff = viewer.me?.staff;
        }

        return initialValues;
    }, [modulesAccess]);

    const onSubmit = async (values: ServiceJobFormValueType) => {
        try {
            const resp = await create({
                entity: { create: {} },
                address: values.address?.id,
                pool: values.pool?.id,
                customer: values.customer?.id,
                title: values.title,
                description: values.description,
                template: values.sjTemplate?.id,
                autoInflateTemplate: false,
                staff: values?.staff?.id,
                recurrentAppointment: {
                    duration: values.duration,
                    startDate: values.startDate,
                    recurrence: sanitizeRecurrence(values.recurrence),
                    workOrderTemplate: values.woTemplate.id,
                },
            });

            const id = resp?.data?.ServiceJob?.ServiceJob?.id;

            sjActions.ServiceJobAction.serviceJobCreated({ id: id });
            AppNavigator.replace(`/sj/${id}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to create', description: e.message });
        }
    };

    const onCancel = () => {
        sjActions.ServiceJobAction.abort();
        AppNavigator.navigateToOrigin(returnTo);
    };

    return (
        <PageContent pageWidth={'narrow'}>
            <FormikDefaultForm
                enableReinitialize={true}
                headerColor={ModuleColorNames.ServiceCall}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                submitOnEnter={false}
                validationSchema={validationSchema}
                header={
                    <>
                        <Icon name={ModuleIconNames.ServiceCall} />
                        {'New Recurring Service'}
                    </>
                }
                submitButton={{ content: 'Create' }}
            >
                {({ setFieldValue, values }) => {
                    const onDidSelectTemplate = (
                        serviceJobTemplate: NodeType.ServiceJobTemplate,
                        workOrderTemplates: NodeType.WorkOrderTemplate[]
                    ) => {
                        setFieldValue('title', serviceJobTemplate?.templateTitle, true);
                        setFieldValue('woTemplate', workOrderTemplates?.[0]);
                    };
                    return (
                        <>
                            <FormikServiceJobTemplateSelect
                                required={true}
                                name={'sjTemplate'}
                                onDidSelect={onDidSelectTemplate}
                                requiredWorkOrder={true}
                                selectWorkOrder={'single'}
                                previewWorkOrderIds={[values.woTemplate?.id]}
                            />
                            <FormikInputField name={'title'} label={'Subject'} required={true} />

                            <FormSectionHeader>Pattern</FormSectionHeader>
                            <FormSectionBody>
                                {/*<BookingButton />*/}
                                <Segment>
                                    <TimeField />
                                    <Recurrence />
                                </Segment>
                            </FormSectionBody>

                            <FormikCustomerAndLocationInputFields customerRequired={true} />

                            <FormikTextareaField name={'description'} label={'Notes'} required={false} rows={10} />

                            <FormSectionHeader>Staff</FormSectionHeader>
                            <FormSectionBody>
                                <FormikSuggestInputStaff
                                    name={'staff'}
                                    label={'Assigned To'}
                                    readOnly={values.staffReadOnly}
                                />
                                <small>All future appointments will be assigned to this staff member by default</small>
                            </FormSectionBody>
                        </>
                    );
                }}
            </FormikDefaultForm>
        </PageContent>
    );
};
