import {
    NodeType,
    QueryConnectionConfigProps,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';
import * as _ from 'lodash';

const QL = gql`
    query QueryServiceJobTemplateConnection(
        $first: Int
        $page: Int
        $search: ServiceJobTemplateViewerServiceJobTemplatesSearch
        $sort: ServiceJobTemplateViewerServiceJobTemplatesSort
    ) {
        viewer {
            serviceJobTemplates(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        templateTitle
                        providedTitle
                        templateDescription
                        providedDescription
                        canBeRecurring
                        archivedAt
                        isDefault
                        isPublished
                        group {
                            id
                            title
                        }
                        franchise {
                            id
                            name
                        }
                        organisationType {
                            id
                            name
                        }
                        workOrderTemplateAssocs {
                            edges {
                                node {
                                    id
                                    priority
                                    workOrderTemplate {
                                        id
                                        templateTitle
                                        templateDescription
                                        jobTodoTemplateAssocs {
                                            edges {
                                                node {
                                                    id
                                                    priority
                                                    jobTodoTemplate {
                                                        id
                                                        title
                                                        description
                                                        items {
                                                            edges {
                                                                node {
                                                                    id
                                                                    orderIndex
                                                                    title
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
`;

export interface QueryServiceJobTemplateConnectionProps extends Partial<QueryConnectionConfigProps> {
    groupId: NodeType.ID;
    includeArchived?: boolean;
    includeUnpublished?: boolean;
}

const mapPropsToSearchQuery = (
    props: QueryServiceJobTemplateConnectionProps
): NodeType.ServiceJobTemplateViewerServiceJobTemplatesSearch => {
    if (!props) {
        return undefined;
    }

    let variables: NodeType.ServiceJobTemplateViewerServiceJobTemplatesSearch = {};
    if (props.groupId) {
        variables = _.set(variables, 'group.id', props.groupId);
    }

    if (props.includeArchived) {
        variables = _.set(variables, 'archivedAt', {});
    }

    if (props.includeUnpublished) {
        variables = _.set(variables, 'isPublished', {});
    }

    return variables;
};

export function useQueryServiceJobTemplateConnection(
    props?: QueryConnectionExternalProps<NodeType.ServiceJobTemplate> & QueryServiceJobTemplateConnectionProps
) {
    const connectionPath = 'viewer.serviceJobTemplates';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultSortKey: 'createdAt',
        defaultSortDirection: SortDirection.DES,
        search: mapPropsToSearchQuery(props),
        ...props,
    });

    return useQueryConnection<NodeType.ServiceJobTemplate>({
        query: QL,
        // fetchPolicy: 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
