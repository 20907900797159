import { NodeType } from '@poolware/api';
import moment from 'moment';
import { AppointmentFormValuesType } from './AppointmentForm';
import * as _ from 'lodash';
import { getClientTimeZone } from '../../queries/use-appointment-mutators';

export const defaultRecurrence: NodeType.Recurrence = {
    pattern: {
        type: NodeType.PatternTypeEnum.Weekly,
        interval: 1,
        daysOfWeek: [NodeType.WeekdayEnum.Monday],
        dayOfMonth: 1,
        index: NodeType.WeekIndexEnum.First,
    },
    range: {
        type: NodeType.RecurrenceTypeEnum.NoEnd,
        numberOfOccurrences: 10,
        endDate: moment().add(1, 'year').toDate(),
        timeZone: getClientTimeZone(),
        interval: 1,
    },
};

export function difference(object, base): any {
    const isDate = (x) => {
        return Object.prototype.toString.call(x) === '[object Date]';
    };

    return _.transform(object, (result, value, key) => {
        if (isDate(value) && isDate(base[key])) {
            // @ts-ignore
            if (value.getTime() !== base[key].getTime()) {
                result[key] = value;
            }
        } else if (!_.isEqual(value, base[key])) {
            result[key] = _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
        }
    });
}

export const getAppointmentChangeDiff = (
    values: Partial<AppointmentFormValuesType>,
    initialValues: Partial<AppointmentFormValuesType>,
    // TODO: this is a temporary hack.
    ignoreRecurrenceLocked: boolean = false
) => {
    const diff = difference(values, initialValues);
    // console.log(diff);
    // console.log(_.isEmpty(diff));

    if (_.isEmpty(diff)) {
        return {};
    }

    const { isRecurrenceLocked, recurrence, startDate, duration, ...restDiff } = diff;

    let newRecurrencePattern = undefined;
    if (isRecurrenceLocked !== undefined && !isRecurrenceLocked && diff.recurrence) {
        newRecurrencePattern = values.recurrence;
    }

    // HACK: TODO: need to refactor this.
    if (ignoreRecurrenceLocked && diff.recurrence) {
        newRecurrencePattern = values.recurrence;
    }

    const enhancedDiff: any = {
        ...restDiff,
    };

    if (diff.startDate || diff.duration || newRecurrencePattern) {
        // Start Date or duration changed.
        // Both startDate and duration values are required.
        enhancedDiff.startDate = values.startDate;
        enhancedDiff.duration = values.duration;
        enhancedDiff.recurrence = newRecurrencePattern;
    }

    if (restDiff?.group?.id) {
        // Special Case
        enhancedDiff.group = restDiff?.group;
    }

    return enhancedDiff;
};
