import * as React from 'react';
import loadable from '@loadable/component';
import { Button, Container, Icon, Message } from 'semantic-ui-react';
import { PageSkeletonLoader } from '@poolware/components';

const ErrorMessage = () => (
    <Container>
        <Message error icon>
            <Icon name="warning sign" color={'yellow'} />
            <Message.Content>
                <Message.Header>Oops! Failed to load page!</Message.Header>
                <p>
                    <br />
                    {/*<Button size={'small'} primary onClick={retry}>*/}
                    {/*    Try Again*/}
                    {/*</Button>*/}
                    <Button size={'small'} basic onClick={() => window.location.reload()}>
                        Reload This Page
                    </Button>
                </p>
            </Message.Content>
        </Message>
    </Container>
);

interface ModuleLoadableInput {
    loader: any;
    loading?: any;
    delay?: number;
    timeout?: number;
}

export const ModuleLoadable = (opts: ModuleLoadableInput) => {
    return loadable(opts.loader, { fallback: opts.loading || <PageSkeletonLoader /> });
};

// export const ModuleLoadable = (opts: ModuleLoadableInput) => {
//     return Loadable({
//         loading: LoadPlaceholder,
//         delay: 200, // 0.2 seconds,
//         timeout: 10000, // 10 seconds
//         ...opts,
//     });
// };
