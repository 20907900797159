import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

export const productDocumentFragment = gql`
    fragment ProductDocumentFragment on ProductDocument {
        id
        title
        type
        createdAt
        url
        mimeType
        isImage
        franchise {
            id
            name
        }
        organisationType {
            id
            name
        }
        company {
            id
            name
        }
        brand {
            id
            name
        }
        uploadedBy {
            id
            user {
                id
                firstName
                lastName
            }
        }
        checkMutations {
            delete
            update
        }
    }
`;
const QL = gql`
    query QueryProductDocument($id: ID!) {
        node(id: $id) {
            id
            ...ProductDocumentFragment
        }
    }
    ${productDocumentFragment}
`;

export const useQueryProductDoc = createUseQueryNodeHook<NodeType.ProductDocument>(QL);
