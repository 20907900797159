import { useEffect, useMemo, useRef, useState } from 'react';
import { NodeType } from '@poolware/api';
import { ConnectionTableDef } from '@poolware/components';
import { Checkbox } from 'semantic-ui-react';
import * as React from 'react';

export interface ConfProps<T extends NodeType.Node = NodeType.Node> {
    selectedItems: T[];
    connectionData: T[];
    setSelectedItems: (items: T[]) => any;
    tableDef: ConnectionTableDef<NodeType.Node>;
    multiSelect?: boolean;
}

export const useTableRowSelect = (conf: ConfProps) => {
    const { connectionData, tableDef, multiSelect = false, selectedItems, setSelectedItems } = conf;

    // const filteredConnectionData = connectionData.filter((t) => {
    //     return externalSelectedItems.findIndex((et) => et.id === t.id) === -1;
    // });

    const onRowClick = (item: NodeType.JobTodoTemplate) => {
        const isSelected = selectedItems.findIndex((p) => p.id === item.id) !== -1;

        if (multiSelect) {
            if (isSelected) {
                // remove clicked row from the list
                setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
            } else {
                // add clicked row to the list
                setSelectedItems([...selectedItems, item]);
            }
        } else {
            if (isSelected) {
                // deselect all
                setSelectedItems([]);
            } else {
                // select only one
                setSelectedItems([item]);
            }
        }
    };

    const memoizedTableDef: ConnectionTableDef<NodeType.JobTodoTemplate> = useMemo(() => {
        const td: ConnectionTableDef<NodeType.JobTodoTemplate> = [
            {
                header: '',
                width: 1,
                cell: (item) => {
                    const isSelected = selectedItems.findIndex((p) => p.id === item.id) !== -1;
                    return <Checkbox checked={isSelected} />;
                },
            },
            ...tableDef,
        ];
        return td;
    }, [selectedItems, tableDef, connectionData]);

    return {
        onRowClick,
        tableDef: memoizedTableDef,
        selectedItems,
    };
};
