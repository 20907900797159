import * as React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { useServiceJobListActions } from '../../redux';

export const ClearAllFilters: React.FC = () => {
    const { Actions, State } = useServiceJobListActions();
    const clearAll = () => {
        Actions.clearAllFilters();
    };

    const { hasActiveFilters } = State.filters;
    return (
        <Segment basic style={{ padding: '0.25em' }}>
            <Button
                disabled={!hasActiveFilters}
                basic
                size={'tiny'}
                icon={'close'}
                color={hasActiveFilters ? 'purple' : 'grey'}
                onClick={clearAll}
                content={'Clear all filters'}
                fluid
                compact
            />
        </Segment>
    );
};
