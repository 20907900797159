import { fromEdges, NodeType } from '@poolware/api';
import { isAfter, isValid, parseJSON } from 'date-fns';
import { BlockGroupType } from './types';
import { useMemo } from 'react';
import * as _ from 'lodash';

export const getBlockRange = (appointments: NodeType.Appointment[]) => {
    const start = appointments
        .map((a) => a.startDate)
        .map((a) => parseJSON(a))
        .filter(isValid)
        .reduce((acc, val) => {
            if (!acc) return val;
            return isAfter(acc, val) ? val : acc;
        }, null);

    const end = appointments
        .map((a) => a.endPatternDate)
        .map((a) => parseJSON(a))
        .filter(isValid)
        .reduce((acc, val) => {
            if (!acc) return val;
            return isAfter(val, acc) ? val : acc;
        }, null);

    return { start, end };
};

export const getColorForIndex = (index: number) => `hsl(${index * 20}, 70%, 60%)`;
export const getColorForPatternBlockId = (uuid: string) => (uuid ? `#${uuid.slice(0, 6)}` : undefined);

export const useMemoPrepareBlocks = (serviceJob: NodeType.ServiceJob): BlockGroupType[] => {
    return useMemo(() => {
        const blocks = _.groupBy(fromEdges(serviceJob?.recurrentAppointments), 'patternBlockId');
        const blockGroups = Object.keys(blocks)
            .filter((key) => key !== 'null')
            .map<BlockGroupType>((key, index) => {
                const appointments = blocks[key];
                const range = getBlockRange(appointments);
                return {
                    color: getColorForPatternBlockId(key),
                    recurrence: appointments[0]?.recurrence,
                    appointmentDuration: appointments[0]?.baseInstance?.duration,
                    serviceJob: serviceJob,
                    start: range.start,
                    end: range.end,
                    id: key,
                    patternBlockId: key,
                    appointments: appointments,
                };
            });
        return blockGroups;
    }, [serviceJob]);
};
