import Page from './Page';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose, mapProps } from '@ez/tools';
import { withApolloLoading } from '@poolware/components';
import { customerFragment, NodeType } from '@poolware/api';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';
import { ContactItemsGroupType, prepareCustomerContactItems } from '../common/prepare-contacts';

const CustomerQuery = gql`
    query CustomerQuery($customerId: ID!) {
        customer: node(id: $customerId) {
            id
            ...CustomerFragment
            ... on Customer {
                id
                note
                vendLink {
                    id
                }
            }
        }
    }
    ${customerFragment}
`;

export default compose(
    withPageProps(),
    graphql(CustomerQuery, {
        options: (props: any) => ({
            variables: { customerId: props.params.customerId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.customer.id' }),
    mapProps((props) => {
        let customer = props?.data?.customer;
        const contactItems = prepareCustomerContactItems(customer);
        return {
            ...props,
            customer,
            contactItems,
        };
    })
)(Page);

export interface PageControlProps extends IWithPageProps {
    customer: NodeType.Customer;
    contactItems: ContactItemsGroupType;
}
