import gql from 'graphql-tag';
import { NodeType, createUseQueryNodeHook } from '@poolware/api';
import { serviceJobFragmentFull } from '../../queries/fragments';

const fragmentWorkOrderInvoiceConnectionSimple = gql`
    fragment WorkOrderInvoiceConnection on WorkOrder {
        invoices: abstractDocuments(search: { typeTag: { is: "INVOICE" } }) {
            edges {
                node {
                    id
                    typeTag
                    refNumber
                    statusTag
                }
            }
        }
    }
`;

const QL = gql`
    query QueryServiceJob($id: ID!) {
        node(id: $id) {
            id
            ...ServiceJobFragment
            ... on ServiceJob {
                group {
                    id
                    title
                }
                staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
                stageCandidates {
                    id
                    title
                    type
                }
                stageHistory {
                    edges {
                        node {
                            fromStage {
                                id
                                title
                                type
                            }
                            toStage {
                                id
                                title
                                type
                            }
                            startedAt
                            endedAt
                            startedBy {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
                files {
                    edges {
                        node {
                            id
                            isPublic
                            note
                            fileName
                            mimeType
                            location
                            createdAt
                            isImage
                            url
                            checkMutations {
                                delete
                                update
                            }
                        }
                    }
                }
                workOrders(sort: { createdAt: true }) {
                    edges {
                        node {
                            id
                            title
                            description
                            workOrderNumber
                            createdAt
                            stage {
                                id
                                title
                                type
                                status
                            }
                            appointmentItem {
                                id
                                startDate
                            }
                            files {
                                pageMeta {
                                    totalCount
                                }
                            }
                            ...WorkOrderInvoiceConnection
                            testReports {
                                pageMeta {
                                    totalCount
                                }
                            }
                            notes {
                                pageMeta {
                                    totalCount
                                }
                            }
                            assignedTo {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                                role {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${serviceJobFragmentFull}
    ${fragmentWorkOrderInvoiceConnectionSimple}
`;

export const useQueryServiceJob = createUseQueryNodeHook<NodeType.ServiceJob>(QL);
