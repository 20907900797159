import * as React from 'react';
import { Modal } from 'semantic-ui-react';
import { useField } from 'formik';
import { Button, FormikFormFieldLabel, IconButton, useModalCtrl } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { PoolTypeTable } from './PoolTypeTable';

interface FormikPoolTypeSelectFieldProps {
    name: string;
    label: string;
    required?: boolean;
    disabled?: boolean;
    organisationId?: string;
}

export const FormikPoolTypeSelectField: React.FC<FormikPoolTypeSelectFieldProps> = ({
    name,
    label,
    required,
    organisationId,
}) => {
    const modalCtrl = useModalCtrl();
    const [field, meta, helpers] = useField<NodeType.PoolType>({ name: name });

    const onSelect = (sanitiser: NodeType.PoolType) => {
        helpers.setValue(sanitiser);
        modalCtrl.onClose();
    };

    const onClear = (e) => {
        e.preventDefault();
        e.stopPropagation();
        helpers.setValue(null);
    };

    return (
        <div className={'ui field'}>
            <FormikFormFieldLabel label={label} name={name} required={required} />
            <div
                tw={'bg-gray-200 rounded-md flex flex-row items-center gap-2 cursor-pointer'}
                onClick={modalCtrl.onOpen}
            >
                <div tw={'flex-grow px-4'}>{field.value?.name || ''}</div>
                {field.value && <IconButton name={'cancel'} color={'grey'} onClick={onClear}></IconButton>}
                <Button
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, margin: 0 }}
                    // size={'small'}
                    icon={'search'}
                    onClick={modalCtrl.onOpen}
                />
            </div>

            <Modal {...modalCtrl}>
                <Modal.Header>Select Pool Type</Modal.Header>
                <div tw={'p-4'}>
                    <PoolTypeTable organisationId={organisationId} onCancel={modalCtrl.onClose} onSelect={onSelect} />
                </div>
            </Modal>
        </div>
    );
};
