import * as React from 'react';
import { styled } from 'twin.macro';

const ContainerGridDiv = styled.div<{ templateColumns?: string }>`
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: ${(props) => props.templateColumns || '300px 1fr'};
    grid-template-areas: 'sidebar main';
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    grid-template-rows: auto;
    @media print {
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas: 'main';
    }
`;

const GridAreaSidebarDiv = styled.div`
    grid-area: sidebar;
    @media print {
        display: none;
    }
`;
const GridAreaMainDiv = styled.div`
    grid-area: main;
`;

export const SideBarLayout: React.FC<{
    leftSidebar?: React.ReactNode;
    leftOnClose?: () => any;
    main: React.ReactNode;
}> = ({ leftSidebar, main }) => {
    return (
        <ContainerGridDiv>
            <GridAreaSidebarDiv>{leftSidebar}</GridAreaSidebarDiv>
            <GridAreaMainDiv>{main}</GridAreaMainDiv>
        </ContainerGridDiv>
    );
};
