import { CostEstimationModel, CostEstimationMonthly, MonthlyHeatPumpProfile, ResourcePriceUnit } from '../common/types';
import { HeatPumpModel } from '../data';
import * as _ from 'lodash';

export interface CostEstimationHeatPumpInputProps {
    monthHeatProfiles: MonthlyHeatPumpProfile[];
    withCover: boolean;
    electricityCost: number;
    operatingDailyHoursPerMonth: number[];
    selectedModel: HeatPumpModel;
    effectiveCapacityFactor?: number;
    minimumOutput: number;
}

export const computeCostEstimationHeatPump = (
    input: CostEstimationHeatPumpInputProps
): CostEstimationModel<HeatPumpModel> => {
    const {
        monthHeatProfiles,
        selectedModel,
        electricityCost,
        operatingDailyHoursPerMonth,
        effectiveCapacityFactor = 1,
        minimumOutput,
    } = input;
    if (!selectedModel) {
        return null;
    }

    if (operatingDailyHoursPerMonth?.length !== 12) {
        throw new Error('Expected operatingDailyHoursPerMonth to have data for 12 months');
    }

    const maxOperatingHours = Math.max(...operatingDailyHoursPerMonth);

    const costArr: CostEstimationMonthly[] = [];

    for (let monthHeatProfile of monthHeatProfiles) {
        let modelInputPower = selectedModel.input;
        let modelCapacityPower = selectedModel.capacity;
        let costRunningMonthly = 0;
        let costRunningDaily = 0;
        const operatingHours = operatingDailyHoursPerMonth[monthHeatProfile.monthProfile.index];

        //calculate model input power
        if (!selectedModel) {
            modelInputPower = 0;
        }
        const coverCoef = !input.withCover ? 1.3 : 1;

        let overCapacityFactor = input.minimumOutput / modelCapacityPower;
        if (overCapacityFactor < 1.3 && overCapacityFactor > 0.7) {
            overCapacityFactor = modelCapacityPower / input.minimumOutput;
        }

        // overCapacityFactor = Math.pow(overCapacityFactor, 1.2);

        let loadCoef = monthHeatProfile.heatUp / modelCapacityPower;
        loadCoef = Math.pow(loadCoef, 2);
        // loadCoef *= 0.9;

        if (!monthHeatProfile.isSelected) {
            loadCoef = 0;
        }

        const runningHours = operatingHours * loadCoef;
        costRunningDaily = modelInputPower * electricityCost * runningHours * coverCoef * overCapacityFactor;
        costRunningMonthly = costRunningDaily * monthHeatProfile.monthProfile.days;

        costArr.push({
            monthId: monthHeatProfile.monthProfile.id,
            costRunningMonthly,
            costRunningDaily,
            loadCoef: loadCoef,
            runningHours,
        });
    }

    //calculate total cost
    const totalCost = costArr.reduce((acc, i) => acc + i.costRunningMonthly, 0);

    //calculate max value

    const numberOfUnits = Math.round(minimumOutput / selectedModel.capacity);

    return {
        numberOfUnits,
        minimumOutput,
        costYearly: totalCost,
        recommendedModel: selectedModel,
        costByMonth: costArr,
        resourcePrice: electricityCost,
        resourcePriceUnit: ResourcePriceUnit.DollarsPerKW,
        operatingHours: maxOperatingHours,
    };
};

export type HeatPumpCostEstimationResultType = ReturnType<typeof computeCostEstimationHeatPump>;
