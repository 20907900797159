import { createBrowserHistory } from 'history';

let _history = createBrowserHistory();
let _basename: string = undefined;

export const setHistoryBasename = (basename: string) => {
    _basename = basename;
    _history = createBrowserHistory({ basename });
};

export const getHistory = () => ({ history: _history, basename: _basename });
