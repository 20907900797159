import gql from 'graphql-tag';
import { NodeType, QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const QL = gql`
    query SentEmailQuery(
        $first: Int
        $page: Int
        $sort: EmailMessageViewerSentEmailsSort
        $search: EmailMessageViewerSentEmailsSearch
    ) {
        viewer {
            sentEmails(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        createdAt
                        id
                        isSent
                        subject
                        recipientEmail
                        type {
                            name
                            id
                        }
                        submittedBy {
                            id
                            franchise {
                                id
                                name
                            }
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export function useQuerySentEmailsConnection(props?: QueryConnectionExternalProps<NodeType.EmailMessage>) {
    const connectionPath = 'viewer.sentEmails';
    const { variables, connectionState } = useDefaultConnectionState(props);
    return useQueryConnection<NodeType.EmailMessage>({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
        skip: props.skip,
    });
}
