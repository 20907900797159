import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { useCalendarActions } from '../../../redux';

const ClearAllSection: React.FC = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();

    const clearAll = () => {
        CalendarAction.clearAllFilters();
    };

    const { hasActiveFilters } = CalendarState.filters;
    return (
        <div>
            <Button
                disabled={!hasActiveFilters}
                basic
                size={'tiny'}
                icon={'close'}
                color={hasActiveFilters ? 'purple' : 'grey'}
                onClick={clearAll}
                content={'Clear all filters'}
                fluid
                compact
            />
        </div>
    );
};

export default ClearAllSection;
