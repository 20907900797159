import gql from 'graphql-tag';
import { serviceJobFragmentFull } from './fragments';
import { NodeType, createUseQueryNodeHook } from '@poolware/api';

const QL = gql`
    query QueryServiceJob($id: ID!) {
        node(id: $id) {
            id
            ...ServiceJobFragment
            ... on ServiceJob {
                group {
                    id
                    title
                }
                staff {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }

                stageCandidates {
                    id
                    title
                    type
                }
                files {
                    edges {
                        node {
                            id
                            isPublic
                            note
                            fileName
                            mimeType
                            location
                            createdAt
                            isImage
                            url
                            checkMutations {
                                delete
                                update
                            }
                        }
                    }
                }

                workOrders(sort: { createdAt: true }) {
                    edges {
                        node {
                            id
                            title
                            description
                            workOrderNumber
                            createdAt
                            stage {
                                id
                                title
                                type
                                status
                            }
                            appointmentItem {
                                id
                                startDate
                            }
                            assignedTo {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                                role {
                                    id
                                    name
                                }
                            }
                            jobs {
                                edges {
                                    node {
                                        id
                                        items {
                                            edges {
                                                node {
                                                    id
                                                    status
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${serviceJobFragmentFull}
`;

export const useQueryServiceJob = createUseQueryNodeHook<NodeType.ServiceJob>(QL);
