import * as React from 'react';
import invariant from 'invariant';
import QueryAppointmentItemsConnection from './QueryAppointmentItemsConnection';
import { NodeType, QueryConnectionResult } from '@poolware/api';
import { PageSkeletonLoader } from '@poolware/components';
import produce from 'immer';
import { AppointmentItemsQuery, connectionPath } from './query-appointment-items-connection';

interface QueryAppointmentsForCustomerChildrenProps extends Partial<QueryConnectionResult<NodeType.AppointmentItem>> {
    loadMore: ({ forward: boolean }) => any;
}

interface QueryAppointmentsForCustomerProps {
    customerId?: NodeType.ID;
    poolId?: NodeType.ID;
    startDate: Date;
    endDate?: Date;
    after?: string;
    before?: string;
    pageSize?: number;
    reverse?: boolean;
    children: (props: QueryAppointmentsForCustomerChildrenProps) => any;
}

export const QueryAppointmentsForCustomer: React.FC<QueryAppointmentsForCustomerProps> = ({
    customerId,
    poolId,
    startDate,
    endDate,
    pageSize,
    after,
    before,
    reverse,
    ...props
}) => {
    invariant(customerId || poolId, 'Customer id is required');

    return (
        <QueryAppointmentItemsConnection
            after={after}
            before={before}
            query={AppointmentItemsQuery}
            connectionPath={connectionPath}
            pageSize={pageSize || 100}
            reverse={reverse}
            fetchPolicy={'cache-and-network'}
            dateRange={{
                startDate: startDate,
                endDate: endDate,
            }}
            customerId={customerId}
            poolId={poolId}
        >
            {({ connectionData, connectionState }) => {
                if (connectionState.isInitialLoading) {
                    return <PageSkeletonLoader lineCount={2} lineHeight={8} />;
                }

                const handleLoadMore = async ({ forward }) => {
                    const endCursor = connectionState?.pageInfo?.endCursor;
                    const variables = {
                        after: endCursor,
                    };
                    try {
                        await connectionState.fetchMore({
                            variables: variables as any,
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;
                                try {
                                    const newState = produce(prev, (draft) => {
                                        (draft?.viewer?.appointmentItems?.edges || []).push(
                                            ...fetchMoreResult.viewer.appointmentItems.edges
                                        );
                                        // TODO: NOTE: the following code works when fetching data with `after=endCursor` query.
                                        //  It hasn't been tested for the case when fetching data with `before=starCursor` query.

                                        draft.viewer.appointmentItems.pageInfo.endCursor =
                                            fetchMoreResult.viewer.appointmentItems.pageInfo.endCursor;
                                        draft.viewer.appointmentItems.pageInfo.hasNextPage =
                                            fetchMoreResult.viewer.appointmentItems.pageInfo.hasNextPage;
                                        draft.viewer.appointmentItems.pageInfo.hasPreviousPage =
                                            fetchMoreResult.viewer.appointmentItems.pageInfo.hasPreviousPage;
                                    });
                                    return newState;
                                } catch (e) {
                                    console.error(e);
                                    return prev;
                                }
                            },
                        });
                    } catch (e) {
                        console.error(e);
                    }
                };

                return props.children({ connectionData, connectionState, loadMore: handleLoadMore });
            }}
        </QueryAppointmentItemsConnection>
    );
};
