import * as React from 'react';
import { FieldArray } from 'formik';
import { NodeType } from '@poolware/api';
import { Button, Divider, Modal, Table } from 'semantic-ui-react';
import ManualProblemPicker from './ManualProblemPicker';
import Instructions from '../ViewReport/Components/Instructions';

export class ManualProblems extends React.Component<any, any> {
    state = {
        showPicker: false,
    };

    closePicker = () => {
        this.setState({ showPicker: false });
    };

    showPicker = () => {
        this.setState({ showPicker: true });
    };

    render() {
        return (
            <FieldArray name={this.props.name}>
                {({ form, ...arrayHelpers }) => {
                    const problems = form.values[this.props.name] || [];
                    const selectManualProblem = (problemsToAdd: NodeType.ManualProblem[]) => {
                        problemsToAdd.forEach(arrayHelpers.push);
                        this.closePicker();
                    };
                    return (
                        <div>
                            {problems.length > 0 && (
                                <Table>
                                    <Table.Body>
                                        {problems.map((problem, index) => {
                                            return (
                                                <Table.Row key={problem.id}>
                                                    <Table.Cell>
                                                        <div>
                                                            <b>{problem.name}</b>
                                                            <div>
                                                                <Instructions
                                                                    usePre={true}
                                                                    content={problem.solution}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Table.Cell>
                                                    <Table.Cell width="1" textAlign={'right'}>
                                                        <Button
                                                            basic={true}
                                                            size={'small'}
                                                            data-testid={problem.id}
                                                            type={'button'}
                                                            name={problem.id}
                                                            onClick={() => arrayHelpers.remove(index)}
                                                            icon="trash"
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            )}

                            <Divider />

                            <Button
                                type={'button'}
                                icon="plus"
                                content={'Add'}
                                basic
                                color="green"
                                circular
                                onClick={this.showPicker}
                            />

                            <Modal open={this.state.showPicker} closeOnDimmerClick={true} onClose={this.closePicker}>
                                <ManualProblemPicker
                                    selectedItemIds={problems.map((p) => p.id)}
                                    onCancel={this.closePicker}
                                    onSubmit={selectManualProblem}
                                />
                            </Modal>
                        </div>
                    );
                }}
            </FieldArray>
        );
    }
}
