import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { LinkButton } from '@poolware/components';
import tw, { css, styled } from 'twin.macro';

const CustomerLabelContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    white-space: nowrap;
    width: 100%;
    color: white;
`;

const TopMessageBarContainer = styled.div(() => [
    tw`fixed top-0 left-0 right-0 shadow-md`,
    css`
        font-size: 16px;
        height: var(--topBar, 46px);
        background-color: #f2711c;
        z-index: 999;
    `,
]);

const LinkButtonStyled = styled(LinkButton)`
    color: white;
    width: 100px;
    border: 1px solid white;
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
`;

export const SelectSlotTopMessageBar = ({ leftLabel, centerLabel, onCancel }) => {
    const handleCancel = (e) => {
        e.preventDefault();
        onCancel && onCancel();
    };
    return (
        <TopMessageBarContainer>
            <div tw={'flex flex-row flex-row items-center space-x-4 h-full p-2'}>
                <div tw={'flex-1 text-left px-4'}>
                    <LinkButtonStyled onClick={handleCancel}>
                        <Icon name={'cancel'} /> Cancel
                    </LinkButtonStyled>
                </div>
                <div tw={'flex-1 text-center'}>
                    <span tw={'text-base md:text-xl font-bold'}>{centerLabel}</span>
                </div>

                <div tw={'flex-1 text-right px-4'}>
                    {leftLabel && <CustomerLabelContainer>{leftLabel}</CustomerLabelContainer>}
                </div>
            </div>
        </TopMessageBarContainer>
    );
};
