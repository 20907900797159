import * as React from 'react';
import { compose } from '@ez/tools';
import { NodeType } from '@poolware/api';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { Panel } from '@poolware/components';
import { sanitizeValues, TraitDeclarationFieldFormConfig } from '../../types';
import { FormBuilder } from '../../../components/FormBuilder';
import * as _ from 'lodash';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, TraitValueType, withProductCatalogMutators } from '../../../queries';

class NewTraitDeclarationFieldForm extends React.Component<PageControlProps> {
    mapFormValuesToPayload = (formValues) => {
        const { config } = this.props;
        formValues = sanitizeValues(formValues, config);
        if (config.type === TraitValueType.Selection) {
            const { options, ...rest } = formValues;
            return {
                ...rest,
                options: _.cloneDeep(options)
                    .reverse()
                    .map((c, index) => ({ name: c.name, priority: index })),
            };
        }
        return formValues;
    };

    onSubmit = async (values, actions) => {
        try {
            const { traitDeclarationId, config, ProductCatalogMutator } = this.props;
            const payload = this.mapFormValuesToPayload(values);
            const res = await ProductCatalogMutator.updateTraitDeclaration_addField(config.mutationFiledName)(
                traitDeclarationId,
                payload
            );
            this.props.onSubmit();
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e.message });
        }
    };

    onCancel = () => {
        this.props.onCancel();
    };

    render() {
        const { config } = this.props;
        return (
            <Panel>
                <Panel.Header>New Field - {config.name} type</Panel.Header>
                <Panel.Body>
                    <FormBuilder config={config} onSubmit={this.onSubmit} onCancel={this.onCancel} />
                </Panel.Body>
            </Panel>
        );
    }
}

export interface ExternalProps {
    traitDeclarationId: NodeType.ID;
    config: TraitDeclarationFieldFormConfig;
    onSubmit: () => any;
    onCancel: () => any;
}

export interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators, ExternalProps {}

export default compose(
    withAppNavigator(),
    withProductCatalogMutators(queryNames)
)(NewTraitDeclarationFieldForm) as React.ComponentType<ExternalProps>;
