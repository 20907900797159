import * as React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, CheckboxProps, Icon, Message } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import { DevOnly, IconButton } from '@poolware/components';

export const DebugPanel = styled.div`
    z-index: 10000;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 0.7em;
    background-color: #ffb07a;
    padding: 5px 10px 10px;
    max-height: 500px;
    overflow-y: scroll;
`;

export const AsyncCheckbox: React.FC<CheckboxProps> = ({ onChange, ...rest }) => {
    const [isChanging, setChanging] = useState(false);

    const handleChange = useCallback(
        async (event, data) => {
            setChanging(true);
            await onChange(event, data);
            setChanging(false);
        },
        [onChange]
    );

    if (isChanging) {
        return <Icon loading={true} name={'spinner'} />;
    }

    return <Checkbox toggle {...rest} onChange={handleChange} />;
};

export const PriorityChanger: React.FC<{
    onPriorityChange: (newValue: number) => any;
    priority: number;
    maxPriority: number;
}> = ({ onPriorityChange, priority }) => {
    const [isChangingUp, setChangingUp] = useState(false);
    const [isChangingDown, setChangingDown] = useState(false);

    const onNewValue = (setChanging) => async (newValue: number) => {
        setChanging(true);
        await onPriorityChange(newValue);
        setChanging(false);
    };

    const onUp = () => {
        return onNewValue(setChangingUp)(priority + 1);
    };
    const onDown = () => {
        return onNewValue(setChangingDown)(priority - 1);
    };

    const canUp = true; ///priority <= maxPriority;
    const canDown = priority > 0;
    const isChanging = isChangingDown || isChangingUp;

    return (
        <span style={{ whiteSpace: 'nowrap' }}>
            <IconButton
                disabled={!canUp || isChanging}
                loading={isChangingUp}
                onClick={onUp}
                name={isChangingUp ? 'spinner' : 'chevron up'}
                color={'black'}
            />
            {priority}
            <IconButton
                disabled={!canDown || isChanging}
                loading={isChangingDown}
                onClick={onDown}
                name={isChangingDown ? 'spinner' : 'chevron down'}
                color={'black'}
            />
        </span>
    );
};

export const ConsumableProduct: React.FC<{ consumableProduct?: NodeType.ConsumableProduct }> = ({
    consumableProduct,
}) => {
    return <span>{consumableProduct?.product?.name || '--'}</span>;
};

const StyledAffectSpan = styled.span`
    font-size: 0.9em;
    font-stretch: condensed;
    font-family: monospace, serif;
`;

export const AffectsList: React.FC<{ affects: NodeType.TreatmentAffect[] }> = ({ affects }) => {
    return (
        <>
            {affects.map((a) => {
                return <StyledAffectSpan key={a.id}>{a.measurementType && a.measurementType.name}</StyledAffectSpan>;
            })}
        </>
    );
};

export const RequestError: React.FC<{ error?: any }> = ({ error }) => {
    const errorMsg = error?.networkError?.result || error;
    return (
        <Message error>
            <Message.Header>
                We're sorry but something went wrong <Icon name={'frown'} />
                <DevOnly>{errorMsg}</DevOnly>
            </Message.Header>
        </Message>
    );
};
