import * as React from 'react';
import { IconButton, toastError } from '@poolware/components';
import { ModulesAccess, ViewerContextType } from '../Viewer';
import { Icon } from 'semantic-ui-react';

const CopyToClipboardButton: React.FC<{ value: string }> = ({ value }) => {
    const onCopy = async () => {
        try {
            await window.navigator?.clipboard.writeText(value);
        } catch (e) {
            toastError(e);
        }
    };

    // clipboard only work in secure context (i.e. https)
    if (!window.isSecureContext) return null;
    return <IconButton name={'clipboard'} onClick={onCopy} />;
};

const DisplayFeatureFlagKey: React.FC<{ value: string }> = ({ value }) => {
    if (!value) return null;
    return (
        <div tw={'text-xs font-mono text-gray-600 flex flex-row gap-2'}>
            <div>{value}</div>
            <CopyToClipboardButton value={value} />
        </div>
    );
};

export const ModuleAccessViewer: React.FC<{
    modulesAccess: ModulesAccess;
    showFeatureFlagKeys?: boolean;
    viewer?: ViewerContextType['viewer'];
}> = ({ modulesAccess, showFeatureFlagKeys = true, viewer }) => {
    return (
        <div tw={'text-sm'}>
            {viewer && (
                <div tw={'pb-2'}>
                    Viewer:
                    <span tw={'pl-2 text-blue-600'}>
                        {viewer.organisation?.name} <Icon name={'caret right'} />
                        {viewer.franchise?.name} <Icon name={'caret right'} />
                        {viewer.me?.staff?.role?.name}
                    </span>
                </div>
            )}
            <div>
                {Object.keys(modulesAccess)
                    .sort()
                    .map((key) => {
                        const featureModule = modulesAccess[key];
                        const fields = Object.keys(featureModule)
                            .filter((ckey) => {
                                return !ckey.endsWith('_FLAG_KEY');
                            })
                            .map((ckey) => {
                                return {
                                    fieldName: ckey,
                                    fieldFlag: featureModule[ckey + '_FLAG_KEY'],
                                    fieldValue: featureModule[ckey],
                                };
                            });

                        return (
                            <div key={key}>
                                <div tw={'text-lg bg-gray-300 px-2 rounded-md'}>{key}</div>
                                <div tw={'flex flex-col divide-y divide-gray-300 divide-dashed'}>
                                    {fields.map((f) => {
                                        return (
                                            <div tw={'pl-4 py-0 flex flex-row border-0'} key={f.fieldName}>
                                                <div tw={'w-60 flex-initial text-right pr-4'}>{f.fieldName}</div>
                                                <div tw={'w-10'}>
                                                    {f.fieldValue === true && (
                                                        <Icon name={'checkmark box'} color={'grey'} />
                                                    )}
                                                    {f.fieldValue === false && (
                                                        <Icon name={'square outline'} color={'grey'} />
                                                    )}
                                                    {typeof f.fieldValue !== 'boolean' && f.fieldValue}
                                                </div>
                                                {showFeatureFlagKeys && (
                                                    <div tw={'flex-1'}>
                                                        {f.fieldFlag?.map?.((fl, i) => {
                                                            return <DisplayFeatureFlagKey key={i} value={fl} />;
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
