import PropTypes, { InferProps } from 'prop-types';
import {
    accessor,
    dateFormat,
    dateRangeFormat,
    DayLayoutAlgorithmPropType,
    views as componentViews,
} from './utils/propTypes';
import { CalViewMode } from './types';
import React from 'react';

export const CalendarPropsTypes = {
    localizer: PropTypes.object.isRequired,

    /**
     * Props passed to main calendar `<div>`.
     *
     */
    elementProps: PropTypes.object,

    /**
     * The current date value of the calendar. Determines the visible view range.
     * If `date` is omitted then the result of `getNow` is used; otherwise the
     * current date is used.
     *
     * @controllable onNavigate
     */
    date: PropTypes.instanceOf(Date),

    /**
     * An array of event objects to display on the calendar. Events objects
     * can be any shape, as long as the Calendar knows how to retrieve the
     * following details of the event:
     *
     *  - start time
     *  - end time
     *  - title
     *  - whether its an "all day" event or not
     *  - any resource the event may be related to
     *
     * Each of these properties can be customized or generated dynamically by
     * setting the various "accessor" props. Without any configuration the default
     * event should look like:
     *
     * ```js
     * Event {
     *   title: string,
     *   start: Date,
     *   end: Date,
     *   allDay?: boolean
     *   resource?: any,
     * }
     * ```
     */
    events: PropTypes.arrayOf(PropTypes.object),

    /**
     * Accessor for the event title, used to display event information. Should
     * resolve to a `renderable` value.
     *
     * ```js
     * string | (event: Object) => string
     * ```
     *
     * @type {(func|string)}
     */
    //titleAccessor: accessor,

    /**
     * Accessor for the event tooltip. Should
     * resolve to a `renderable` value. Removes the tooltip if null.
     *
     * ```js
     * string | (event: Object) => string
     * ```
     *
     * @type {(func|string)}
     */
    //tooltipAccessor: accessor,

    /**
     * Determines whether the event should be considered an "all day" event and ignore time.
     * Must resolve to a `boolean` value.
     *
     * ```js
     * string | (event: Object) => boolean
     * ```
     *
     * @type {(func|string)}
     */
    //allDayAccessor: accessor,

    /**
     * The start date/time of the event. Must resolve to a JavaScript `Date` object.
     *
     * ```js
     * string | (event: Object) => Date
     * ```
     *
     * @type {(func|string)}
     */
    //startAccessor: accessor,

    /**
     * The end date/time of the event. Must resolve to a JavaScript `Date` object.
     *
     * ```js
     * string | (event: Object) => Date
     * ```
     *
     * @type {(func|string)}
     */
    //endAccessor: accessor,

    /**
     * Returns the id of the `resource` that the event is a member of. This
     * id should match at least one resource in the `resources` array.
     *
     * ```js
     * string | (event: Object) => Date
     * ```
     *
     * @type {(func|string)}
     */
    //resourceAccessor: accessor,

    /**
     * An array of resource objects that map events to a specific resource.
     * Resource objects, like events, can be any shape or have any properties,
     * but should be uniquly identifiable via the `resourceIdAccessor`, as
     * well as a "title" or name as provided by the `resourceTitleAccessor` prop.
     */
    resources: PropTypes.arrayOf(PropTypes.object),

    /**
     * Provides a unique identifier for each resource in the `resources` array
     *
     * ```js
     * string | (resource: Object) => any
     * ```
     *
     * @type {(func|string)}
     */
    //resourceIdAccessor: accessor,

    /**
     * Provides a human readable name for the resource object, used in headers.
     *
     * ```js
     * string | (resource: Object) => any
     * ```
     *
     * @type {(func|string)}
     */
    //resourceTitleAccessor: accessor,

    /**
     * Determines the current date/time which is highlighted in the views.
     *
     * The value affects which day is shaded and which time is shown as
     * the current time. It also affects the date used by the Today button in
     * the toolbar.
     *
     * Providing a value here can be useful when you are implementing time zones
     * using the `startAccessor` and `endAccessor` properties.
     *
     * @type {func}
     * @default () => new Date()
     */
    getNow: PropTypes.func,

    /**
     * Callback fired when the `date` value changes.
     *
     * @controllable date
     */
    onNavigate: PropTypes.func,

    /**
     * Callback fired when the `view` value changes.
     *
     * @controllable view
     */
    onView: PropTypes.func,

    /**
     * Callback fired when date header, or the truncated events links are clicked
     *
     */
    onDrillDown: PropTypes.func,

    /**
     *
     * ```js
     * (dates: Date[] | { start: Date; end: Date }, view: 'month'|'week'|'work_week'|'day'|'agenda'|undefined) => void
     * ```
     *
     * Callback fired when the visible date range changes. Returns an Array of dates
     * or an object with start and end dates for BUILTIN views. Optionally new `view`
     * will be returned when callback called after view change.
     *
     * Custom views may return something different.
     */
    onRangeChange: PropTypes.func,

    /**
     * A callback fired when a date selection is made. Only fires when `selectable` is `true`.
     *
     * ```js
     * (
     *   slotInfo: {
     *     start: Date,
     *     end: Date,
     *     resourceId:  (number|string),
     *     slots: Array<Date>,
     *     action: "select" | "click" | "doubleClick",
     *     bounds: ?{ // For "select" action
     *       x: number,
     *       y: number,
     *       top: number,
     *       right: number,
     *       left: number,
     *       bottom: number,
     *     },
     *     box: ?{ // For "click" or "doubleClick" actions
     *       clientX: number,
     *       clientY: number,
     *       x: number,
     *       y: number,
     *     },
     *   }
     * ) => any
     * ```
     */
    onSelectSlot: PropTypes.func,

    /**
     * Callback fired when a calendar event is selected.
     *
     * ```js
     * (event: Object, e: SyntheticEvent) => any
     * ```
     *
     * @controllable selected
     */
    onSelectEvent: PropTypes.func,

    /**
     * Callback fired when a calendar event is clicked twice.
     *
     * ```js
     * (event: Object, e: SyntheticEvent) => void
     * ```
     */
    onDoubleClickEvent: PropTypes.func,

    /**
     * Callback fired when dragging a selection in the Time views.
     *
     * Returning `false` from the handler will prevent a selection.
     *
     * ```js
     * (range: { start: Date, end: Date, resourceId: (number|string) }) => ?boolean
     * ```
     */
    onSelecting: PropTypes.func,

    /**
     * Callback fired when a +{count} more is clicked
     *
     * ```js
     * (events: Object, date: Date) => any
     * ```
     */
    onShowMore: PropTypes.func,

    /**
     * The selected event, if any.
     */
    selected: PropTypes.object,

    /**
     * The string name of the destination view for drill-down actions, such
     * as clicking a date header, or the truncated events links. If
     * `getDrilldownView` is also specified it will be used instead.
     *
     * Set to `null` to disable drill-down actions.
     *
     * ```js
     * <Calendar
     *   drilldownView="agenda"
     * />
     * ```
     */
    drilldownView: PropTypes.string,

    /**
     * Functionally equivalent to `drilldownView`, but accepts a function
     * that can return a view name. It's useful for customizing the drill-down
     * actions depending on the target date and triggering view.
     *
     * Return `null` to disable drill-down actions.
     *
     * ```js
     * <Calendar
     *   getDrilldownView={(targetDate, currentViewName, configuredViewNames) =>
     *     if (currentViewName === 'month' && configuredViewNames.includes('week'))
     *       return 'week'
     *
     *     return null;
     *   }}
     * />
     * ```
     */
    getDrilldownView: PropTypes.func,

    /**
     * Determines the end date from date prop in the agenda view
     * date prop + length (in number of days) = end date
     */
    length: PropTypes.number,

    /**
     * Determines whether the toolbar is displayed
     */
    toolbar: PropTypes.bool,

    /**
     * Show truncated events in an overlay when you click the "+_x_ more" link.
     */
    popup: PropTypes.bool,

    /**
     * Distance in pixels, from the edges of the viewport, the "show more" overlay should be positioned.
     *
     * ```jsx
     * <Calendar popupOffset={30}/>
     * <Calendar popupOffset={{x: 30, y: 20}}/>
     * ```
     */
    popupOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })]),

    /**
     * Allows mouse selection of ranges of dates/times.
     *
     * The 'ignoreEvents' option prevents selection code from running when a
     * drag begins over an event. Useful when you want custom event click or drag
     * logic
     */
    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),

    /**
     * Specifies the number of miliseconds the user must press and hold on the screen for a touch
     * to be considered a "long press." Long presses are used for time slot selection on touch
     * devices.
     *
     * @type {number}
     * @default 250
     */
    longPressThreshold: PropTypes.number,

    /**
     * Determines the selectable time increments in week and day views
     */
    step: PropTypes.number,

    /**
     * The number of slots per "section" in the time grid views. Adjust with `step`
     * to change the default of 1 hour long groups, with 30 minute slots.
     */
    timeslots: PropTypes.number,

    /**
     *Switch the calendar to a `right-to-left` read direction.
     */
    rtl: PropTypes.bool,

    /**
     * Optionally provide a function that returns an object of className or style props
     * to be applied to the the event node.
     *
     * ```js
     * (
     *    event: Object,
     *    start: Date,
     *    end: Date,
     *    isSelected: boolean
     * ) => { className?: string, style?: Object }
     * ```
     */
    eventPropGetter: PropTypes.func,

    /**
     * Optionally provide a function that returns an object of className or style props
     * to be applied to the time-slot node. Caution! Styles that change layout or
     * position may break the calendar in unexpected ways.
     *
     * ```js
     * (date: Date, resourceId: (number|string)) => { className?: string, style?: Object }
     * ```
     */
    slotPropGetter: PropTypes.func,

    /**
     * Optionally provide a function that returns an object of props to be applied
     * to the time-slot group node. Useful to dynamically change the sizing of time nodes.
     * ```js
     * () => { style?: Object }
     * ```
     */
    slotGroupPropGetter: PropTypes.func,

    /**
     * Optionally provide a function that returns an object of className or style props
     * to be applied to the the day background. Caution! Styles that change layout or
     * position may break the calendar in unexpected ways.
     *
     * ```js
     * (date: Date) => { className?: string, style?: Object }
     * ```
     */
    dayPropGetter: PropTypes.func,

    /**
     * Support to show multi-day events with specific start and end times in the
     * main time grid (rather than in the all day header).
     *
     * **Note: This may cause calendars with several events to look very busy in
     * the week and day views.**
     */
    showMultiDayTimes: PropTypes.bool,

    /**
     * Constrains the minimum _time_ of the Day and Week views.
     */
    min: PropTypes.instanceOf(Date),

    /**
     * Constrains the maximum _time_ of the Day and Week views.
     */
    max: PropTypes.instanceOf(Date),

    /**
     * Determines how far down the scroll pane is initially scrolled down.
     */
    scrollToTime: PropTypes.instanceOf(Date),

    /**
     * Specify a specific culture code for the Calendar.
     *
     * **Note: it's generally better to handle this globally via your i18n library.**
     */
    culture: PropTypes.string,

    /**
     * Localizer specific formats, tell the Calendar how to format and display dates.
     *
     * `format` types are dependent on the configured localizer; both Moment and Globalize
     * accept strings of tokens according to their own specification, such as: `'DD mm yyyy'`.
     *
     * ```jsx
     * let formats = {
     *   dateFormat: 'dd',
     *
     *   dayFormat: (date, , localizer) =>
     *     localizer.format(date, 'DDD', culture),
     *
     *   dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
     *     localizer.format(start, { date: 'short' }, culture) + ' – ' +
     *     localizer.format(end, { date: 'short' }, culture)
     * }
     *
     * <Calendar formats={formats} />
     * ```
     *
     * All localizers accept a function of
     * the form `(date: Date, culture: ?string, localizer: Localizer) -> string`
     */
    formats: PropTypes.shape({
        /**
         * Format for the day of the month heading in the Month view.
         * e.g. "01", "02", "03", etc
         */
        dateFormat,

        /**
         * A day of the week format for Week and Day headings,
         * e.g. "Wed 01/04"
         *
         */
        dayFormat: dateFormat,

        /**
         * Week day name format for the Month week day headings,
         * e.g: "Sun", "Mon", "Tue", etc
         *
         */
        weekdayFormat: dateFormat,

        /**
         * The timestamp cell formats in Week and Time views, e.g. "4:00 AM"
         */
        timeGutterFormat: dateFormat,

        /**
         * Toolbar header format for the Month view, e.g "2015 April"
         *
         */
        monthHeaderFormat: dateFormat,

        /**
         * Toolbar header format for the Week views, e.g. "Mar 29 - Apr 04"
         */
        dayRangeHeaderFormat: dateRangeFormat,

        /**
         * Toolbar header format for the Day view, e.g. "Wednesday Apr 01"
         */
        dayHeaderFormat: dateFormat,

        /**
         * Toolbar header format for the Agenda view, e.g. "4/1/2015 – 5/1/2015"
         */
        agendaHeaderFormat: dateRangeFormat,

        /**
         * A time range format for selecting time slots, e.g "8:00am – 2:00pm"
         */
        selectRangeFormat: dateRangeFormat,

        agendaDateFormat: dateFormat,
        agendaTimeFormat: dateFormat,
        agendaTimeRangeFormat: dateRangeFormat,

        /**
         * Time range displayed on events.
         */
        eventTimeRangeFormat: dateRangeFormat,

        /**
         * An optional event time range for events that continue onto another day
         */
        eventTimeRangeStartFormat: dateFormat,

        /**
         * An optional event time range for events that continue from another day
         */
        eventTimeRangeEndFormat: dateFormat,
    }),

    /**
     * Customize how different sections of the calendar render by providing custom Components.
     * In particular the `Event` component can be specified for the entire calendar, or you can
     * provide an individual component for each view type.
     *
     * ```jsx
     * let components = {
     *   event: MyEvent, // used by each view (Month, Day, Week)
     *   eventWrapper: MyEventWrapper,
     *   eventContainerWrapper: MyEventContainerWrapper,
     *   dateCellWrapper: MyDateCellWrapper,
     *   timeSlotWrapper: MyTimeSlotWrapper,
     *   timeGutterHeader: MyTimeGutterWrapper,
     *   toolbar: MyToolbar,
     *   agenda: {
     *   	 event: MyAgendaEvent // with the agenda view use a different component to render events
     *     time: MyAgendaTime,
     *     date: MyAgendaDate,
     *   },
     *   day: {
     *     header: MyDayHeader,
     *     event: MyDayEvent,
     *   },
     *   week: {
     *     header: MyWeekHeader,
     *     event: MyWeekEvent,
     *   },
     *   month: {
     *     header: MyMonthHeader,
     *     dateHeader: MyMonthDateHeader,
     *     event: MyMonthEvent,
     *   }
     * }
     * <Calendar components={components} />
     * ```
     */
    components: PropTypes.shape({
        event: PropTypes.elementType,

        // wraps calendars root View
        rootViewWrapper: PropTypes.elementType,

        // wraps individual event (e.g. DayColumnt)
        eventWrapper: PropTypes.elementType,

        // wraps group of events (e.g. see DayColumn)
        eventContainerWrapper: PropTypes.elementType,

        dateCellWrapper: PropTypes.elementType,
        timeSlotWrapper: PropTypes.elementType,
        timeGutterHeader: PropTypes.elementType,
        resourceHeader: PropTypes.elementType,

        toolbar: PropTypes.elementType,

        agenda: PropTypes.shape({
            event: PropTypes.elementType,
            date: PropTypes.elementType,
            time: PropTypes.elementType,
        }),
        day: PropTypes.shape({
            event: PropTypes.elementType,
            header: PropTypes.elementType,
        }),
        week: PropTypes.shape({
            event: PropTypes.elementType,
            header: PropTypes.elementType,
        }),
        month: PropTypes.shape({
            event: PropTypes.elementType,
            header: PropTypes.elementType,
            dateHeader: PropTypes.elementType,
        }),
    }),

    /**
     * String messages used throughout the component, override to provide localizations
     */
    messages: PropTypes.shape({
        allDay: PropTypes.node,
        previous: PropTypes.node,
        next: PropTypes.node,
        today: PropTypes.node,
        month: PropTypes.node,
        week: PropTypes.node,
        day: PropTypes.node,
        agenda: PropTypes.node,
        date: PropTypes.node,
        time: PropTypes.node,
        event: PropTypes.node,
        noEventsInRange: PropTypes.node,
        showMore: PropTypes.func,
    }),
};

export interface CalendarBaseProps extends InferProps<typeof CalendarPropsTypes> {
    /**
     * A day event layout(arrangement) algorithm.
     * `overlap` allows events to be overlapped.
     * `no-overlap` resizes events to avoid overlap.
     * or custom `Function(events, minimumStartDifference, slotMetrics, accessors)`
     */
    dayLayoutAlgorithm: 'overlap' | 'no-overlap' | ((events, minimumStartDifference, slotMetrics, accessors) => string);
    accessors?: {
        start: string | ((any) => any);
        end: string | ((any) => any);
        allDay: string | ((any) => any);
        tooltip: string | ((any) => any);
        title: string | ((any) => any);
        resource: string | ((any) => any);
        resourceId: string | ((any) => any);
        resourceTitle: string | ((any) => any);
    };
    className?: string;
    debug?: boolean;
    /**
     * An array of built-in view names to allow the calendar to display.
     * accepts either an array of builtin view names,
     *
     * ```jsx
     * views={['month', 'day', 'agenda']}
     * ```
     * or an object hash of the view name and the component (or boolean for builtin).
     *
     * ```jsx
     * views={{
     *   month: true,
     *   week: false,
     *   myweek: WorkWeekViewComponent,
     * }}
     * ```
     *
     * Custom views can be any React component, that implements the following
     * interface:
     *
     * ```js
     * interface View {
     *   static title(date: Date, { formats: DateFormat[], culture: string?, ...props }): string
     *   static navigate(date: Date, action: 'PREV' | 'NEXT' | 'DATE'): Date
     * }
     * ```
     *
     * @type Views ('month'|'week'|'work_week'|'day'|'agenda')
     * @View
     ['month', 'week', 'day', 'agenda']
     */
    views: CalViewMode[] | { [Props in keyof CalViewMode]: boolean | React.ComponentType };

    /**
     * The current view of the calendar.
     *
     * @default 'month'
     * @controllable onView
     */
    view?: CalViewMode;

    /**
     * The initial view set for the Calendar.
     * @type Calendar.Views ('month'|'week'|'work_week'|'day'|'agenda')
     * @default 'month'
     */
    defaultView?: CalViewMode;
}

export const commonProps = {
    // accessors: PropTypes.shape({
    //     start: PropTypes.func,
    //     end: PropTypes.func,
    //     allDay: PropTypes.func,
    //     tooltip: PropTypes.func,
    //     title: PropTypes.func,
    //     resource: PropTypes.func,
    //     resourceId: PropTypes.func,
    //     resourceTitle: PropTypes.func,
    // }).isRequired,
    // components: PropTypes.shape({
    //     rootViewWrapper: PropTypes.any,
    //     eventWrapper: PropTypes.any,
    //     eventContainerWrapper: PropTypes.any,
    //     dateCellWrapper: PropTypes.any,
    //     weekWrapper: PropTypes.any,
    //     timeSlotWrapper: PropTypes.any,
    //     timeGutterHeader: PropTypes.any,
    //     resourceHeader: PropTypes.any,
    //     // header: PropTypes.any,
    //     // dateHeader: PropTypes.any,
    //     // event: PropTypes.any,
    //     // date: PropTypes.any,
    //     // time: PropTypes.any,
    // }).isRequired,
    // getters: PropTypes.shape({
    //     eventProp: PropTypes.func,
    //     slotProp: PropTypes.func,
    //     slotGroupProp: PropTypes.func,
    //     dayProp: PropTypes.func,
    // }).isRequired,
    // localizer: PropTypes.objectOf(PropTypes.any).isRequired,
};

export const commonViewProps = {
    events: PropTypes.array.isRequired,
    date: PropTypes.instanceOf(Date),
    getNow: PropTypes.func.isRequired,
    showMultiDayTimes: PropTypes.bool,
};
