import * as React from 'react';
import { useContext } from 'react';
import { DndContext } from './DndContext';

export interface DebugDndProps {}

const DebugDNDItem: React.FC<{ label; value }> = ({ label, value }) => {
    return (
        <div style={{}}>
            <b>{label}</b>
            <div style={{ maxHeight: 200, overflow: 'auto', backgroundColor: 'wheat' }}>
                <pre style={{ lineHeight: 1, whiteSpace: 'pre-wrap', fontSize: 'x-small' }}>
                    {JSON.stringify(value, null, 2)}
                </pre>
            </div>
        </div>
    );
};
export const DebugDnd: React.FC<DebugDndProps> = ({}) => {
    const s = useContext(DndContext);
    return (
        <div>
            <DebugDNDItem
                label={'dragAndDropAction'}
                value={{
                    interacting: s?.draggable?.dragAndDropAction?.interacting,
                    action: s?.draggable?.dragAndDropAction?.action,
                    direction: s?.draggable?.dragAndDropAction?.direction,
                    event: s?.draggable?.dragAndDropAction?.event,
                }}
            />
            <DebugDNDItem label={'dragFromOutsideItem'} value={s?.draggable?.dragFromOutsideItem?.()} />
        </div>
    );
};
