import * as React from 'react';

import { IContactMutatorsProps, NodeType, withContactMutators } from '@poolware/api';
import { createFormHandler } from './create-form-handler';
import {
    createContactItemForm,
    createDeleteConfirm,
    createMakePrimaryContactItemForm,
} from './create-contact-editor-forms';

const refetchQuery = ['CustomerEditQuery', 'FranchiseShopEditQuery'];

export const NewContactItemPage = (
    entity: NodeType.Entity | NodeType.FranchiseShop,
    contactType: NodeType.ContactType
) => {
    const contactTypeName = contactType?.name;
    return createFormHandler({
        header: contactTypeName,
        form: createContactItemForm(contactTypeName),
        initialValues: { type: contactType, entity },
        withMutator: withContactMutators(refetchQuery),
        mutate: (props: IContactMutatorsProps, formValues) => {
            const { data, label, isPrimary } = formValues;
            return props.ContactMutator.createContact(entity, { contactType, data, label, isPrimary });
        },
    });
};

export const EditContactItemPage = (contact: NodeType.Contact) => {
    const contactType = contact?.type;
    const contactTypeName = contactType?.name;
    return createFormHandler({
        header: contactTypeName,
        form: createContactItemForm(contactTypeName),
        initialValues: contact,
        withMutator: withContactMutators(refetchQuery),
        mutate: (props: IContactMutatorsProps, formValues) => {
            return props.ContactMutator.updateContact(contact, formValues);
        },
    });
};

export const MakePrimaryContactItemPage = (contact: any) => {
    const contactType = contact?.type;
    const contactTypeName = contactType?.name;
    return createFormHandler({
        header: contactTypeName,
        form: createMakePrimaryContactItemForm(contactTypeName),
        initialValues: contact,
        withMutator: withContactMutators(refetchQuery),
        mutate: (props: IContactMutatorsProps, formValues) => {
            return props.ContactMutator.setAsPrimary(contact);
        },
    });
};

export const DeleteContactItemPage = (contact: any) => {
    const contactType = contact?.type;
    return createFormHandler({
        form: createDeleteConfirm({ header: `Delete ${contactType?.name || ''}?` }),
        initialValues: contact,
        withMutator: withContactMutators(refetchQuery),
        mutate: (props: IContactMutatorsProps) => {
            return props.ContactMutator.deleteContact(contact);
        },
    });
};
