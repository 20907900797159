import * as React from 'react';
import { useMemo } from 'react';
import { Icon } from 'semantic-ui-react';
import { AppSwitcherMenuItems, DropdownMenuAccount, useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../routes/url-builder';
import { IconServiceCall } from '../components/AppIcons';
import { useServiceJobActions } from '@poolware/app-service-jobs';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Navbar } from '@poolware/components';

interface Props {}

const AppNavbar: React.FC<Props> = () => {
    const { ServiceJobAction } = useServiceJobActions();
    const { isAuthenticated } = useViewer();
    const { AppNavigator, location } = useAppNavigator();
    const { modulesAccess } = useViewer();

    const onSearch = () => {
        AppNavigator.navigate(URLBuilder.Search.search);
    };

    const onServiceCase = () => {
        ServiceJobAction.newServiceJob({
            returnTo: location,
        });
    };

    const onNewCustomer = () => {
        AppNavigator.navigate(URLBuilder.Customers.new);
    };

    const onNewWarrantyClaim = () => {
        AppNavigator.navigate(URLBuilder.WarrantyClaim().new);
    };

    const onNewAppointment = () => {
        AppNavigator.navigate(URLBuilder.Scheduler.new, { modal: true });
    };

    const createDropdownItem = (input: { onClick: any; icon?: React.ReactNode; label: string }, index) => {
        return (
            <Navbar.DropdownMenuItem onClick={input.onClick} key={index}>
                {input.icon}
                {input.label}
            </Navbar.DropdownMenuItem>
        );
    };

    const plusItems = useMemo(() => {
        const items = [
            modulesAccess.FieldServices?.enabled && {
                icon: <IconServiceCall />,
                label: 'New Case',
                onClick: onServiceCase,
            },
            modulesAccess.Customers?.enabled && {
                icon: <Icon color={'blue'} name={'user'} />,
                label: 'New Customer',
                onClick: onNewCustomer,
            },
            modulesAccess.Warranties?.enabled && {
                icon: <Icon name={'certificate'} />,
                label: 'New Warranty Claim',
                onClick: onNewWarrantyClaim,
            },
        ];
        return items.filter(Boolean);
    }, [modulesAccess]);

    const renderPlusMenu = () => {
        if (plusItems.length === 0) return null;
        return (
            <Navbar.DropdownMenu color={'green'} icon={'plus'}>
                {plusItems.map(createDropdownItem)}
            </Navbar.DropdownMenu>
        );
    };

    const authenticatedNav = () => {
        return [
            <Navbar.MenuSection key={'left'} position="left">
                <Navbar.MenuItem onClick={onSearch} color={'teal'} icon={'search'} content={'Search'} />
            </Navbar.MenuSection>,
            <Navbar.MenuSection key={'right'} position="right">
                <AppSwitcherMenuItems />
                {renderPlusMenu()}
                <DropdownMenuAccount />
            </Navbar.MenuSection>,
        ];
    };

    const unauthenticatedNav = () => {
        return null;
    };

    return isAuthenticated ? authenticatedNav() : unauthenticatedNav();
};

export default AppNavbar;
