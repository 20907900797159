import 'core-js/es';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppRootProvider, setHistoryBasename } from '@poolware/app-shell';
import { store } from './redux/store';
import App from './App';
import { WaterlinkProvider } from '@poolware/waterlink';
import '@ez/components/css/semantic.min.css';
import './styles/index.scss';
import '@poolware/react-datepicker/lib/react-datepicker.min.css';
import '@poolware/react-big-calendar/lib/css/react-big-calendar.css';
import '@poolware/react-big-calendar/lib/addons/dragAndDrop/styles.css';

if (process.env.REACT_APP_ROUTER_BASENAME) {
    setHistoryBasename(process.env.REACT_APP_ROUTER_BASENAME);
}

ReactDOM.render(
    <AppRootProvider reduxStore={store}>
        <WaterlinkProvider>
            <App />
        </WaterlinkProvider>
    </AppRootProvider>,
    document.getElementById('root')
);
