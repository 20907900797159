import * as React from 'react';
import { TopFilterBar } from '../../components/TopFilterBar';
import { useRecurServiceJobListActions } from '../../redux/reducer-recur-service-job-list';

export const FilterSummary: React.FC = () => {
    const { State, Actions } = useRecurServiceJobListActions();
    const filters = State.filters;
    if (!filters?.hasActiveFilters) {
        return <div className={'filter-summary'} />;
    }

    return (
        <>
            <TopFilterBar.SummaryFilterIcon />
            {filters.serviceJobTemplateId || filters.serviceGroupId ? (
                <TopFilterBar.SummaryItem>Group</TopFilterBar.SummaryItem>
            ) : null}
            {filters.customerCRN ? <TopFilterBar.SummaryItem>CRN</TopFilterBar.SummaryItem> : null}
            {filters.staffIds?.length > 0 && <TopFilterBar.SummaryItem>Staff</TopFilterBar.SummaryItem>}
            {filters.workOrderNumber ? <TopFilterBar.SummaryItem>Work ID</TopFilterBar.SummaryItem> : null}
            {filters.serviceJobNumber ? <TopFilterBar.SummaryItem>Case ID</TopFilterBar.SummaryItem> : null}
            <TopFilterBar.SummaryClearButton onClick={Actions.clearAllFilters} />
        </>
    );
};

export const ToolbarQuickFilters = ({ showSidebar, setShowSidebar }) => {
    const { State } = useRecurServiceJobListActions();
    const { hasActiveFilters } = State.filters;

    return (
        <TopFilterBar>
            {false && (
                <TopFilterBar.Row>
                    <TopFilterBar.ShowHideToggle
                        showSidebar={showSidebar}
                        setShowSidebar={setShowSidebar}
                        hasActiveFilters={hasActiveFilters}
                    />
                    <TopFilterBar.Spacer />
                </TopFilterBar.Row>
            )}
            {hasActiveFilters && (
                <TopFilterBar.RowSummary>
                    <FilterSummary />
                </TopFilterBar.RowSummary>
            )}
        </TopFilterBar>
    );
};
