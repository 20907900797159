import * as React from 'react';
import { useMemo } from 'react';
import {
    ConnectionTableDef,
    DebouncedInput,
    DefaultConnectionTable,
    Display,
    FormInputDate,
    MenuBarHeaderItem,
    MenuBarSection,
    StickyMenuBar,
    VStack,
} from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { useQueryPoolsConnection } from './use-query-pool-connection';
import { SuggestInputFranchise, useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../../../../routes/url-builder';
import { useFilterPools } from './use-filter-pools';
import { Form, Segment } from 'semantic-ui-react';

export interface PageProps {}

const useTableDef = (isAdmin?: boolean) => {
    return useMemo(() => {
        const tableDef: ConnectionTableDef<NodeType.Pool> = [
            {
                header: 'Type',
                width: '1',
                cell: (pool) => {
                    return pool?.type?.name || '--';
                },
            },
            {
                header: 'Sanitiser',
                width: '1',
                cell: (pool) => {
                    return pool?.sanitiser?.name;
                },
            },
            {
                header: 'Volume',
                sortKey: 'volume',
                width: '1',
                cell: (pool) => {
                    return `${pool?.volume || '--'} L`;
                },
            },
            {
                header: 'Customer',
                width: '1',
                cell: (pool) => {
                    return <Display.Entity value={pool.entity} />;
                },
            },
            {
                header: 'Address',
                width: '1',
                cell: (pool) => {
                    const address = pool?.site?.address;
                    return <Display.Address value={address} />;
                },
            },
            {
                header: 'Bottle',
                width: '1',
                cell: (pool) => {
                    return <>{pool.bottleNumber}</>;
                },
            },
            {
                header: 'Registered',
                sortKey: 'createdAt',
                width: '1',
                cell: (pool) => {
                    return <Display.Date format={'ll'} value={pool.createdAt} />;
                },
            },
        ];

        if (isAdmin) {
            tableDef.push({
                header: 'Franchise',
                cell: (r) => {
                    return <>{r.franchise?.name}</>;
                },
            });
        }

        return tableDef;
    }, [isAdmin]);
};

export const Page: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin } = useViewer();

    const { state, actions } = useFilterPools();

    const { connectionData, connectionState } = useQueryPoolsConnection({
        ...state,
        // ...actions,
    });

    const tableDef = useTableDef(isAdmin);

    const onView = (pool: NodeType.Pool) => {
        const customerId = pool?.entity?.user?.customer?.id;
        const linkViewPool = URLBuilder.Customer(customerId).Pool(pool.id).view;
        AppNavigator.navigate(linkViewPool);
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>Pools</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <Segment>
                <Form size={'small'}>
                    <Form.Group widths={'equal'}>
                        <DebouncedInput
                            rightIcon={'search'}
                            label={'Pool Type'}
                            initialValue={state.poolType}
                            onChange={actions.setPoolType}
                        />
                        <DebouncedInput
                            rightIcon={'search'}
                            label={'Sanitiser'}
                            initialValue={state.sanitiser}
                            onChange={actions.setSanitiserName}
                        />
                        {isAdmin && (
                            <SuggestInputFranchise
                                label={'Franchise'}
                                value={state.franchiseNode}
                                onChange={actions.setFranchiseNode}
                            />
                        )}
                    </Form.Group>
                    <Form.Group widths={'equal'}>
                        {false && (
                            <>
                                <FormInputDate
                                    label={'Last Test >='}
                                    onChange={actions.setLastTestAfter}
                                    value={state.lastTestAfter}
                                />
                                <FormInputDate
                                    label={'Last Test <='}
                                    onChange={actions.setLastTestBefore}
                                    value={state.lastTestBefore}
                                />
                            </>
                        )}
                        <DebouncedInput
                            type={'number'}
                            step={500}
                            rightIcon={'search'}
                            label={'Volume >='}
                            initialValue={state.volumeGT}
                            onChange={actions.setVolumeGT}
                        />
                        <DebouncedInput
                            type={'number'}
                            step={500}
                            rightIcon={'search'}
                            label={'Volume <='}
                            initialValue={state.volumeLT}
                            onChange={actions.setVolumeLT}
                        />
                    </Form.Group>
                </Form>
            </Segment>

            <div>Total count: {connectionState.totalCount}</div>
            <DefaultConnectionTable
                debug={true}
                onRowClick={onView}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
            {/*<DebugJSON data={state} />*/}
        </VStack>
    );
};
