import styled from 'styled-components';
import * as React from 'react';
import tw from 'twin.macro';
import { LinkButton } from '@poolware/components';
import { Button, Icon } from 'semantic-ui-react';

const TopFilterBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${tw`py-3  gap-2`}

    & > .row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: start;

        & > * + * {
            margin-left: 0.5rem;
        }
    }

    .filter-summary {
        font-size: 0.8em;
        height: 1em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        & > * + * {
            margin-left: 0.25rem;
        }

        & > span {
            text-decoration: underline;
            font-weight: bold;
        }
    }

    .spacer {
        flex-grow: 1;
    }
`;

const ToolbarContainerRow = tw.div`flex flex-row flex-wrap gap-2 justify-between md:flex-nowrap`;
const ToolbarContainerRowSummary = tw.div`flex flex-row flex-wrap gap-2 text-sm h-6 items-center`;
const Spacer = tw.div`flex-grow`;

const ShowHideToggle: React.FC<{ showSidebar; setShowSidebar; hasActiveFilters }> = ({
    showSidebar,
    setShowSidebar,
    hasActiveFilters,
}) => (
    <Button
        basic
        compact
        color={hasActiveFilters ? 'purple' : 'grey'}
        onClick={() => setShowSidebar(!showSidebar)}
        icon={'filter'}
    />
);

const SummaryItem: React.FC = (props) => <span tw={'underline font-bold'} {...props} />;

const SummaryClearButton: React.FC<{ onClick: () => any }> = ({ onClick }) => (
    <span tw={'font-bold pl-2'}>
        <LinkButton onClick={onClick} content={'clear x'} />
    </span>
);

const SummaryFilterIcon: React.FC = () => (
    <div>
        <Icon name={'filter'} />
    </div>
);

export class TopFilterBar extends React.Component {
    public static Row = ToolbarContainerRow;
    public static RowSummary = ToolbarContainerRowSummary;
    public static Spacer = Spacer;
    public static ShowHideToggle = ShowHideToggle;
    public static SummaryItem = SummaryItem;
    public static SummaryClearButton = SummaryClearButton;
    public static SummaryFilterIcon = SummaryFilterIcon;

    render() {
        return <TopFilterBarContainer {...this.props} />;
    }
}
