import * as React from 'react';
import { ModuleLink, useAppNavigator } from '@poolware/react-app-navigator';
import { Display, HeaderButtonType, Panel, SectionHeader } from '@poolware/components';
import { Button, Icon, List, Table } from 'semantic-ui-react';
import { fromEdges, NodeType } from '@poolware/api';

interface WorkOrderReportPanelProps {
    workOrder: NodeType.WorkOrder;
}

export const WorkOrderWaterTestReportsPanel: React.FC<WorkOrderReportPanelProps> = ({ workOrder }) => {
    const { AppNavigator } = useAppNavigator();

    const onNewWaterTest = () => {
        AppNavigator.navigate('/new', {
            moduleId: 'WATER_TEST',
            setOrigin: true,
            query: {
                poolId: workOrder?.pool?.id,
                woId: workOrder?.id,
            },
            state: {
                workOrder: workOrder,
            },
        });
    };
    const reports = fromEdges(workOrder.testReports);

    const button: HeaderButtonType = {
        content: 'Add',
        icon: 'plus',
        onClick: onNewWaterTest,
        locked: !workOrder?.pool?.id ? 'Pool is not linked to work order' : false,
    };

    return (
        <Panel>
            <Panel.Header color={'grey'} icon={'lab'} size={'small'} content={'Water Test'} button={button} />
            <Panel.Body>
                {reports.length > 0 && (
                    <List>
                        {reports.map((testReport) => {
                            return (
                                <List.Item key={testReport.id}>
                                    <div tw={'flex flex-row gap-4'}>
                                        <Display.Date value={testReport.createdAt} format={'LL'} />{' '}
                                        <div tw={'flex flex-row gap-2'}>
                                            <ModuleLink
                                                relativeToModule={true}
                                                moduleId={'WATER_TEST'}
                                                to={`${testReport.id}`}
                                            >
                                                Open Report <Icon name={'external'} />
                                            </ModuleLink>
                                            <span tw={'w-2'}>|</span>
                                            <a target={'_blank'} href={testReport.pdfUrl} rel="noopener noreferrer">
                                                Download PDF <Icon name={'file pdf'} color={'red'} />
                                            </a>
                                        </div>
                                    </div>
                                </List.Item>
                            );
                        })}
                    </List>
                )}
            </Panel.Body>
        </Panel>
    );
};
