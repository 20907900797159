import * as React from 'react';
import { Calendar, CalendarDnD, DnDInteractionInfoType, momentLocalizer } from '@poolware/react-big-calendar';
import moment from 'moment';
import 'moment/locale/en-au';
import { CalendarEvent } from './CalendarEvent';
import { CalendarToolbar } from '../CalendarToolbar';
import { isMobileDevice } from '@ez/tools';
import { CalendarResourceType } from './convert-appointment-items-to-events';
import { CalViewLayoutMode, CalViewMode } from '../types';
import { ListDayView, ListMonthView, ListWeekView } from './CalendarLayoutViews';
import { CalendarTimeGutterHeader } from './CalendarTimeGutterHeader';
// import '@poolware/react-big-calendar/lib/css/react-big-calendar.css';
// import '@poolware/react-big-calendar/lib/addons/dragAndDrop/styles.css';

// Enforce AU locale, and set the first day of week to Monday ('1')
// TODO: make it configurable via user setting at some point
moment.updateLocale('en-au', {
    week: {
        dow: 1,
    },
});
moment.locale('en-au');

const localizer = momentLocalizer(moment);

const DnDCalendar = isMobileDevice() ? Calendar : CalendarDnD;

export type CalendarViewMode = 'month' | 'week' | 'work_week' | 'day' | 'agenda';

const calendarGridViews = {
    month: true,
    week: true,
    day: true,
};

const calendarListViews = {
    month: ListMonthView,
    week: ListWeekView,
    day: ListDayView,
};

interface CalendarProps {
    view: CalendarViewMode;
    layoutMode: CalViewLayoutMode;
    date: Date;
    events: any[];
    selectedEvent?: any;
    onView: (newViewMode: CalendarViewMode) => any | Promise<any>;
    onNavigate: (newActiveDate: Date) => any | Promise<any>;
    onDragStart?: (event: any) => any | Promise<any>;
    onDragOver?: (event: any) => any | Promise<any>;
    onDropFromOutside?: (event: DnDInteractionInfoType) => any | Promise<any>;
    dragFromOutsideItem?: () => any;
    onEventMove: (event: any) => any | Promise<any>;
    onEventResize: (event: any) => any | Promise<any>;
    onSelectEvent: (slot: any, event: any) => any | Promise<any>;
    onSelectSlot: (slot: any) => any | Promise<any>;
    resources: CalendarResourceType[];
    resizable?: boolean;
    draggable?: boolean;
    selectable?: boolean;
}

const defaultState = {
    defaultDate: new Date(),
    scrollToTime: moment().hour(4).minute(30).toDate(),
    min: moment('0000', 'hhmm').toDate(),
    max: moment('2359', 'hhmm').toDate(),
    error: null,
};

const Components = {
    toolbar: CalendarToolbar,
    event: CalendarEvent,
    timeGutterHeader: CalendarTimeGutterHeader,
};

const Formats = {
    dayHeaderFormat: (date, culture, localizer) => localizer.format(date, 'ddd, DD MMM', culture),

    dayRangeHeaderFormat: ({ start, end }, culture, local) => {
        const isSameMonth = moment(start).isSame(end, 'month');
        if (isSameMonth) {
            return local.format(start, 'DD', culture) + ' - ' + local.format(end, 'DD MMM', culture);
        } else {
            return local.format(start, 'DD MMM', culture) + ' - ' + local.format(end, 'DD MMM', culture);
        }
    },
};

export default class CalendarView extends React.Component<CalendarProps, any> {
    public static defaultProps = {
        view: 'weeks',
        layoutMode: CalViewLayoutMode.GRID,
        resizable: false,
    };

    constructor(props, context) {
        super(props, context);
        this.state = defaultState;
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        console.error(error, info);
    }

    slotPropGetter = (date: Date): { className?: string; style?: Object } => {
        const hour = moment(date).hour();

        if (hour >= 6 && hour < 18) {
            return { className: 'workingHours' };
        }

        return { className: 'nonWorkingHours' };
    };

    onSelectEvent = (slot, e) => {
        let { onSelectEvent, selectable } = this.props;
        if (onSelectEvent && selectable) {
            onSelectEvent(slot, e);
        }
    };

    onEventResize = (event) => {
        return this.props.onEventResize(event);
    };

    onEventMove = async (event) => {
        return this.props.onEventMove(event);
    };

    onSelectSlot = (slot: any) => {
        return this.props.onSelectSlot(slot);
    };

    onNavigate = (newActiveDate: Date) => {
        return this.props.onNavigate(newActiveDate);
    };

    onView = (newViewMode: CalViewMode) => {
        return this.props.onView(newViewMode);
    };

    getViews = () => {
        let { view, layoutMode } = this.props;
        if (view === 'month') {
            return calendarGridViews;
        }
        return layoutMode === CalViewLayoutMode.GRID ? calendarGridViews : calendarListViews;
    };

    resizableAccessor = () => {
        return this.props.resizable;
    };

    getDrilldownView = (targetDate, currentViewName, configuredViewNames) => {
        if (currentViewName === CalViewMode.MONTH && configuredViewNames.includes(CalViewMode.WEEK)) {
            return CalViewMode.WEEK;
        }
        if (currentViewName === CalViewMode.WEEK && configuredViewNames.includes(CalViewMode.DAY)) {
            return CalViewMode.DAY;
        }
        return null;
    };

    render() {
        let {
            events,
            resources,
            view,
            date,
            draggable,
            selectable,
            onDragOver,
            onDragStart,
            dragFromOutsideItem,
            onDropFromOutside,
        } = this.props;
        const views = this.getViews();
        const CalComp = draggable ? DnDCalendar : Calendar;

        return (
            <CalComp
                getDrilldownView={this.getDrilldownView}
                localizer={localizer}
                timeslots={4} //
                step={15} //min
                view={view}
                events={events}
                resources={resources}
                defaultDate={this.state.defaultDate}
                defaultView={CalViewMode.WEEK}
                scrollToTime={this.state.scrollToTime}
                min={this.state.min}
                max={this.state.max}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
                onDropFromOutside={onDropFromOutside}
                dragFromOutsideItem={dragFromOutsideItem}
                onSelectEvent={this.onSelectEvent}
                onSelectSlot={this.onSelectSlot}
                onView={this.onView}
                date={date}
                onNavigate={this.onNavigate}
                showMultiDayTimes
                eventPropGetter={CalendarEvent.eventPropGetter}
                slotPropGetter={this.slotPropGetter}
                views={views}
                onEventMove={this.onEventMove}
                resizableAccessor={this.resizableAccessor}
                selectable={selectable}
                onEventResize={this.onEventResize}
                components={Components}
                formats={Formats}
                debug={false}
            />
        );
    }
}
