import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { NodeType } from '@poolware/api';

export const ContactsQuery = gql`
    query ContactTypes {
        viewer {
            contactTypes(first: 50) {
                edges {
                    node {
                        id
                        name
                        validatorTag
                        identificationTag
                        iconPath
                    }
                }
            }
        }
    }
`;

export const useQueryContactTypes = () => useQuery<NodeType.Query>(ContactsQuery, { fetchPolicy: 'cache-first' });
