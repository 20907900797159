import * as React from 'react';
import { useState } from 'react';
import { compose } from '@ez/tools';
import { Panel, toastError } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { SuggestBrandInput, SuggestSupplierInput } from '../../../components-api-connected';
import { Button, Segment } from 'semantic-ui-react';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, Product_UpdateInput, withProductCatalogMutators } from '../../../queries';

const BulkEdit: React.FC<PageControlProps> = ({ ProductCatalogMutator, products, onFinish }) => {
    const productCount = products.length;

    const [isSubmitting, setSubmitting] = useState(false);
    const [values, setValues] = useState({ supplier: undefined, brand: undefined });

    const canUpdate = values.supplier !== undefined || values.brand !== undefined;

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            const createSingleMutationInput = (product) => {
                const mutationInput: Product_UpdateInput = {
                    product: product,
                };

                mutationInput.supplier = values.supplier;
                mutationInput.brand = values.brand;

                return mutationInput;
            };
            await ProductCatalogMutator.updateProducts(products.map(createSingleMutationInput));
            onFinish();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to Update', description: e.message });
        }
        setSubmitting(true);
    };

    const onUpdate = (fieldName) => (newValue) => {
        setValues({ ...values, [fieldName]: newValue });
    };

    return (
        <Panel>
            <Panel.Header content={`Bulk edit ${productCount} products`} />
            <Panel.Body>
                <Panel.Item label={'Supplier'}>
                    <div style={{ maxWidth: '400px' }}>
                        <SuggestSupplierInput
                            isClearable={false}
                            value={values.supplier ? values.supplier.id : undefined}
                            onChange={(value) => {
                                onUpdate('supplier')(value);
                            }}
                        />
                    </div>
                </Panel.Item>
                <Panel.Item label={'Brand'}>
                    <div style={{ maxWidth: '400px' }}>
                        <SuggestBrandInput
                            isClearable={false}
                            value={values.brand}
                            onChange={(value) => onUpdate('brand')(value)}
                        />
                    </div>
                </Panel.Item>
                <Segment clearing basic compact>
                    <Button primary type="button" basic disabled={isSubmitting} floated={'left'} onClick={onFinish}>
                        Cancel
                    </Button>

                    <Button
                        primary
                        type={'submit'}
                        onClick={handleSubmit}
                        loading={isSubmitting}
                        disabled={isSubmitting || !canUpdate}
                        content={`Update ${productCount} products`}
                        floated={'right'}
                    />
                </Segment>
            </Panel.Body>
        </Panel>
    );
};

export default compose(withProductCatalogMutators(queryNames))(BulkEdit);

export interface PageControlProps extends IProductCatalogMutators {
    products: NodeType.Product[];
    onFinish: () => any;
}
