import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import * as React from 'react';
import LoginPage from './LoginPage';
import { NotFoundPage } from '../NotFound';

export const Router = () => {
    return (
        <ModuleRootSwitch notFound={NotFoundPage}>
            <ModuleRoute path={'/'} exact component={LoginPage} />
        </ModuleRootSwitch>
    );
};
