import {
    defaultOptionValueComparatorFn,
    FormLabel,
    Optionable,
    OptionableComponentL2,
    OptionableL2,
} from '@poolware/components';
import { Button, Dropdown, Icon, SemanticCOLORS, SemanticSIZES, SemanticWIDTHS } from 'semantic-ui-react';
import * as React from 'react';
import { useSafeState } from '@ez/tools';

const NO_MUTATION = '____NO_MUTATIONS____';

export interface StageFilterSelectButtonsProps extends OptionableComponentL2<any, any> {
    color?: SemanticCOLORS;
    label?: string;
    fluid?: boolean;
    widths?: SemanticWIDTHS | 'equal';
    vertical?: boolean;
    size?: SemanticSIZES;
}

const CompoundText: React.FC<{ left: string; right: string }> = ({ left, right }) => {
    return (
        <span>
            <span tw={'uppercase'}>{left}</span> <Icon tw={'m-0!'} name={'caret right'} />
            {right}
        </span>
    );
};

const styleButtonSingle = {
    padding: '0 1.2571429em',
    margin: 0,
    width: '100%',
    whiteSpace: 'nowrap',
};
const styleButtonInGroup = {
    ...styleButtonSingle,
    width: 'calc(100% - 2.5em)',
};

export const ButtonsSelectGroupL2: React.FC<StageFilterSelectButtonsProps> = ({
    label,
    onChange,
    options,
    value,
    fluid = true,
    color,
    size = 'tiny',
    valueComparator = defaultOptionValueComparatorFn,
}) => {
    const [mutatingValue, setMutatingValue] = useSafeState(NO_MUTATION);
    const handleClickL1 = (optionL1: OptionableL2 | null) => async (e) => {
        if (!onChange) {
            return;
        }
        setMutatingValue(optionL1.value);
        try {
            await onChange(optionL1, null);
        } catch (e) {}
        setMutatingValue(NO_MUTATION);
    };

    const handleClickL2 = async (optionL1: OptionableL2 | null, optionL2: Optionable<any>) => {
        await onChange(optionL1, optionL2);
    };

    return (
        <div>
            {label && <FormLabel>{label}</FormLabel>}
            <div tw={'gap-2 flex flex-row w-full'}>
                {options.map((o1, index) => {
                    const isO1Active = valueComparator(value, o1);
                    const isMutating = valueComparator(mutatingValue, o1);
                    const isMutationInProgress = mutatingValue !== NO_MUTATION;
                    const subOptions = o1.options;
                    const activeSubOption = subOptions?.find((so) => valueComparator(value, so));
                    const buttonTitle = activeSubOption ? (
                        <CompoundText left={o1.text} right={activeSubOption.text} />
                    ) : (
                        o1.text
                    );
                    const isActive = isO1Active || !!activeSubOption;
                    const _color = isActive ? color || 'teal' : 'grey';

                    if (subOptions?.length > 0) {
                        return (
                            <Button.Group
                                fluid={fluid}
                                size={size}
                                basic={!isActive}
                                key={index}
                                color={_color}
                                style={{ border: 'none' }}
                                disabled={isMutationInProgress}
                            >
                                <Button
                                    key={index}
                                    style={styleButtonInGroup}
                                    disabled={isMutationInProgress}
                                    loading={isMutating}
                                    onClick={handleClickL1(o1)}
                                >
                                    {o1.icon}
                                    {buttonTitle}
                                </Button>
                                <Dropdown
                                    trigger={<></>}
                                    style={{ borderLeft: 0, marginLeft: -1, width: '2em' }}
                                    className="button icon"
                                    floating
                                >
                                    <Dropdown.Menu>
                                        {subOptions?.map((o2, i) => {
                                            const isActive = o2 == activeSubOption;
                                            return (
                                                <Dropdown.Item
                                                    key={i}
                                                    active={isActive}
                                                    onClick={() => handleClickL2(o1, o2)}
                                                >
                                                    {o2.icon}
                                                    {o2.text}
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Button.Group>
                        );
                    } else {
                        return (
                            <Button
                                fluid={fluid}
                                style={styleButtonSingle}
                                key={index}
                                disabled={isMutationInProgress}
                                loading={isMutating}
                                basic={!isActive}
                                color={_color}
                                active={isActive}
                                onClick={handleClickL1(o1)}
                                content={buttonTitle}
                                icon={o1.icon}
                                size={size}
                            />
                        );
                    }
                })}
            </div>
        </div>
    );
};
