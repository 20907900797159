import * as Yup from 'yup';

const SimpleString = Yup.string().max(100, 'Too Long!').nullable();

export const validationSchemaPhoneNumber = Yup.string()
    .min(4, 'Minimum 4 symbols')
    .max(32, 'Max 32 symbols')
    .trim()
    .matches(
        /^\s*[\d.\-+()*#]{3,32}\s*$/,
        "<span>Only Digits and <pre style='display: inline; font-size: 1.2em'> . - + * # </pre> symbols are allowed</span>"
    )
    .required('Required');

export const validationSchemaContactItem = Yup.lazy((value: any) => {
    switch (value?.type?.validatorTag) {
        case 'PHONE':
            return Yup.object().shape({
                data: validationSchemaPhoneNumber.nullable(),
                label: SimpleString.notRequired(),
            });
        case 'EMAIL':
            return Yup.object().shape({
                data: Yup.string().email('Not valid email').required('Required').nullable(),
                label: SimpleString.notRequired(),
            });
        default:
            return Yup.object().shape({
                data: SimpleString.required('Required'),
                label: SimpleString.notRequired(),
            });
    }
});
