import * as React from 'react';
import { ActionBarFilterByStaff } from '../../components/ActionBarFilters/ActionBarFilterByStaff';
import { useRecurServiceJobListActions } from '../../redux/reducer-recur-service-job-list';
import { useViewer } from '@poolware/app-shell';

export const FilterByStaff: React.FC = () => {
    const { State, Actions } = useRecurServiceJobListActions();
    const { modulesAccess } = useViewer();
    const singleStaffMode = modulesAccess.FieldServices?.calendarSingleStaffMode;
    const { staffIds = [] } = State.filters;

    return (
        <ActionBarFilterByStaff
            forceMeFilter={singleStaffMode}
            staffIds={staffIds}
            setStaffFilter={({ includeIds }) => Actions.setStaffFilter(includeIds)}
            useUnassignedFilter={true}
            singleSelect={true}
        />
    );
};
