import gql from 'graphql-tag';
import { SortDirection, useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const fragmentCustomerServiceJobs = gql`
    fragment CustomersServiceJobs on Customer {
        id
        serviceJobs(search: { isRecurring: { is: true } }, sort: { createdAt: false }) {
            edges {
                node {
                    id
                }
            }
        }
    }
`;

const fragmentCustomerPools = gql`
    fragment CustomersPools on Customer {
        id
        pools {
            edges {
                node {
                    id
                }
            }
        }
    }
`;

const QL = gql`
    query QueryCustomersConnection(
        $first: Int
        $page: Int
        $search: CustomerViewerCustomersSearch
        $sort: CustomerViewerCustomersSort
    ) {
        viewer {
            customers(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        contactName
                        companyName
                        nextAppointment {
                            id
                            startDate
                        }
                        user {
                            id
                            title
                            firstName
                            lastName
                            entity {
                                id
                                chainUpdate
                                franchise {
                                    id
                                    name
                                }
                            }
                        }
                        crn
                        vendLink {
                            id
                        }
                        ...CustomersServiceJobs
                        ...CustomersPools
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${fragmentCustomerServiceJobs}
    ${fragmentCustomerPools}
`;

export function useQueryCustomersConnection() {
    const connectionPath = 'viewer.customers';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultSortKey: 'chainUpdate',
        defaultSortDirection: SortDirection.DES,
        defaultPageSize: 25,
    });
    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
