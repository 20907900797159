import * as React from 'react';
import { PrintPageHeader, PrintSectionConsumer, PrintSectionProvider } from '@poolware/components';
import { MonthlyDataTable } from '../MonthlyDataTable';
import { RecommendationPanel } from './RecommendationPanel';
import { Helmet } from '@poolware/app-shell';
import { PoolProfilePanel } from './PoolProfilePanel';
import { useFormikContext } from 'formik';
import { CostEstimation, FormikHeatCalcValueType, MonthlyHeatGasProfile } from '../../common/types';
import { PrintPageContainer } from '../../common/PrintPageContainer';

export const PagePrint: React.FC<{
    monthlyHeatProfiles: MonthlyHeatGasProfile[];
    costEstimation: CostEstimation;
}> = ({ monthlyHeatProfiles, costEstimation }) => {
    const pageTitle = 'Gas Heater';

    const { values } = useFormikContext<FormikHeatCalcValueType>();
    const toggles = {
        monthlyTable: false,
        recommendedHeater: false,
        heatupCostEstimates: false,
    };

    return (
        <PrintPageContainer>
            <PrintSectionProvider moduleName={'print.toggles.gas-calc'} defaultState={toggles}>
                <Helmet title={pageTitle} />
                <div>
                    <PrintPageHeader>{pageTitle}</PrintPageHeader>
                    <PoolProfilePanel />
                    <RecommendationPanel costEstimation={costEstimation} />
                    <PrintSectionConsumer name={'monthlyTable'}>
                        <MonthlyDataTable
                            selectedMonths={values.selectedMonths}
                            costEstimation={costEstimation?.gas}
                            monthlyHeatProfiles={monthlyHeatProfiles}
                        />
                    </PrintSectionConsumer>
                </div>
            </PrintSectionProvider>
        </PrintPageContainer>
    );
};
