import { createReduxStore } from '@poolware/app-shell';
import {
    deserializeSchedulerState,
    deserializeServiceJob,
    reducerAppointmentBooking,
    reducerCalendarState,
    reducerServiceJob,
    serializeSchedulerState,
    serializeServiceJob,
} from '@poolware/app-service-jobs';
import { reducer as appointmentsReportReducer } from '../Pages/Reports/redux';
import { reducer as productCatalogReducer } from '@poolware/app-catalog';
import { reducerHeatCalc } from '@poolware/app-calculators';
import { reducerWarranty } from '@poolware/app-warranty-claims';

import { createRootSaga } from './sagas';

export const rootReducersMap = {
    scheduler: reducerCalendarState,
    booking: reducerAppointmentBooking,
    serviceJob: reducerServiceJob,
    appointmentsReport: appointmentsReportReducer,
    catalog: productCatalogReducer,
    heatCalcs: reducerHeatCalc,
    warranty: reducerWarranty,
};

export const reduxStateDeserializers = {
    scheduler: deserializeSchedulerState,
    serviceJob: deserializeServiceJob,
};

export const reduxStateSerializers = {
    serviceJob: serializeServiceJob,
    scheduler: serializeSchedulerState,
};

export const store = createReduxStore({
    rootReducersMapObject: rootReducersMap,
    rootSagaCreator: createRootSaga,
    deserializers: reduxStateDeserializers,
    serializers: reduxStateSerializers,
});
