import { addressFragment } from '@poolware/api';
import gql from 'graphql-tag';

export const QL = gql`
    query QueryServiceJobList(
        $pageSize: Int
        $page: Int
        $search: ServiceJobViewerServiceJobsSearch
        $sort: ServiceJobViewerServiceJobsSort
    ) {
        viewer {
            serviceJobs(first: $pageSize, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        title
                        serviceJobNumber
                        description
                        stage {
                            id
                            title
                            type
                            status
                        }
                        dueDate
                        isRecurring
                        lastAppointmentDate
                        address {
                            id
                            ...AddressFragment
                        }
                        customer {
                            id
                            crn
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        group {
                            id
                            title
                        }
                        workOrders {
                            edges {
                                node {
                                    id
                                    workOrderNumber
                                    title
                                    stage {
                                        id
                                        title
                                        type
                                        status
                                    }
                                }
                            }
                        }
                        pool {
                            id
                            name
                            volume
                            bottleNumber
                        }
                        createdAt
                        updatedAt
                    }
                }
                pageMeta {
                    pageCount
                }
            }
        }
    }
    ${addressFragment}
`;
