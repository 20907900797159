import * as React from 'react';

import { Button, Divider, Icon, Segment } from 'semantic-ui-react';
import VendEditForm from './VendEditForm';
import { PageControlProps } from './Page.Control';
import { MenuButtonItemConf, Panel } from '@poolware/components';

class VendCard extends React.Component<PageControlProps> {
    state = {
        isEditing: false,
        isDeleting: false,
    };

    onSave = async () => {
        this.setState({ isEditing: false });
    };

    onEdit = () => {
        this.setState({ isEditing: true });
    };

    onDelete = async () => {
        const r = window.confirm('Delete Vend Integration?');
        if (r !== true) {
            return;
        }

        this.setState({ isDeleting: true });
        await this.props.VendMutator.deleteVendAPI(this.props.vend);
        this.setState({ isDeleting: false });
    };

    onCancel = () => {
        this.setState({ isEditing: false });
    };

    hasPermissionToCreate = () => {
        return !!this.props.viewerContext?.modulesAccess?.VendIntegration?.admin;
    };

    hasPermissionToEdit = () => {
        return !!this.props.viewerContext?.modulesAccess?.VendIntegration?.admin;
    };

    hasPermissionToReadKey = () => {
        return !!this.props.viewerContext?.modulesAccess?.VendIntegration?.admin;
    };

    renderRead() {
        const { vend, franchise } = this.props;
        const isNew = !(vend && vend.id);

        const createIcon = (iconName, color) => <Icon name={iconName} color={color} />;

        const createHeader = (iconName?: string, color?: string) => (
            <span>
                <Icon name="settings" />
                Vend Integration {iconName && createIcon(iconName, color)}
            </span>
        );

        if (isNew) {
            return (
                <Panel>
                    <Panel.Header headerComp={createHeader()} />
                    <Panel.Body>
                        <Panel.Item label="Franchise" content={franchise?.name} />
                        <Divider />
                        {this.hasPermissionToCreate() && (
                            <Button fluid basic onClick={this.onEdit}>
                                <Icon name="plus" />
                                Register Vend
                            </Button>
                        )}
                    </Panel.Body>
                </Panel>
            );
        } else {
            const HeaderComp = vend.vendTest
                ? createHeader('checkmark', 'green')
                : createHeader('warning circle', 'red');
            const redactedKey = '*******************';

            const canDelete = !!this.props.viewerContext?.modulesAccess?.VendIntegration?.allowDelete;
            const menuItems: MenuButtonItemConf[] = [
                {
                    key: 1,
                    name: 'Edit',
                    icon: 'edit',
                    disabled: !this.hasPermissionToEdit(),
                    onClick: this.onEdit,
                },
                canDelete && {
                    key: 2,
                    name: 'Delete',
                    icon: 'trash',
                    onClick: this.onDelete,
                },
            ];

            return (
                <Panel>
                    <Panel.Header
                        headerComp={HeaderComp}
                        button={{
                            content: 'Edit',
                            icon: 'chevron down',
                            menuItems: menuItems,
                        }}
                    />
                    <Panel.Body>
                        <Panel.Item label="Franchise" content={franchise?.name} />
                        <Panel.Item label="API Key" content={redactedKey} />
                        <Panel.Item label="Vend Shop URL" content={vend.shopUri} />
                    </Panel.Body>
                </Panel>
            );
        }
    }

    renderEdit() {
        const { vend, franchise } = this.props;
        return (
            <Panel>
                <Panel.Header content="Vend Integration" />
                <Panel.Body>
                    <Panel.Item label="Franchise" content={franchise?.name} />
                    <Divider />
                    <Segment secondary clearing>
                        <VendEditForm
                            initialValues={vend}
                            VendMutator={this.props.VendMutator}
                            onSave={this.onSave}
                            onCancel={this.onCancel}
                        />
                    </Segment>
                </Panel.Body>
            </Panel>
        );
    }

    render() {
        return (
            <Segment basic loading={this.state.isDeleting} style={{ padding: 0 }}>
                {this.state.isEditing ? this.renderEdit() : this.renderRead()}
            </Segment>
        );
    }
}

class Page extends React.Component<PageControlProps> {
    render() {
        return <VendCard {...this.props} />;
    }
}

export default Page;
