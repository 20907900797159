import * as React from 'react';
import { NodeType } from '@poolware/api';
import { useQueryWarrantyClaimsConnection } from './use-query-warranty-claims-connection';
import { ConnectionTableDef, DefaultConnectionTable, Display, SectionHeader } from '@poolware/components';
import { Icon } from 'semantic-ui-react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useWarrantyActions } from '@poolware/app-warranty-claims';

export interface WarrantyCasesProps {
    customer?: NodeType.Customer;
}

export const WarrantyCases: React.FC<WarrantyCasesProps> = ({ customer }) => {
    const { AppNavigator, location } = useAppNavigator();
    const { Action } = useWarrantyActions();
    const { connectionState, connectionData } = useQueryWarrantyClaimsConnection({ customerId: customer?.id });
    const tableDef: ConnectionTableDef<NodeType.WarrantyClaim> = [
        {
            header: 'Status',
            width: '1',
            cell: (r) => {
                const isSubmitted = Boolean(r.submittedAt);
                const isConfirmed = Boolean(r.confirmedAt);
                if (isSubmitted) {
                    if (isConfirmed) {
                        return (
                            <>
                                <Icon name={'checkmark'} color={'green'} />
                                Submitted & Confirmed
                            </>
                        );
                    } else {
                        return (
                            <>
                                <Icon name={'checkmark'} color={'green'} />
                                Submitted
                            </>
                        );
                    }
                } else {
                    return (
                        <>
                            <Icon name={'edit'} color={'grey'} />
                            Draft
                        </>
                    );
                }
            },
        },
        {
            header: 'Claim ID',
            // sortKey: 'name',
            cell: (r) => <>{r.warrantyClaimNumber}</>,
        },
        {
            header: 'Customer',
            cell: (r) => <Display.Entity value={r.refCustomer} />,
        },
        {
            header: 'Asset/Product',
            cell: (r) => <>{r.assetName}</>,
        },
        {
            header: 'Asset Serial',
            cell: (r) => <>{r.assetSerial}</>,
        },
        {
            header: 'Install Date',
            cell: (r) => <Display.Date format={'LL'} value={r.assetInstallDate} />,
        },
        {
            header: 'Claim Date',
            cell: (r) => <Display.Date format={'LL'} value={r.claimedAt} />,
        },
        {
            header: 'Submit Date',
            cell: (r) => <Display.Date format={'LLL'} value={r.submittedAt} />,
        },
        {
            header: 'Submitted by',
            cell: (r) => <Display.Entity value={r.addedBy} />,
        },
    ];

    const onViewCase = (item: NodeType.WarrantyClaim) => {
        AppNavigator.navigate(`/warranty-claims/${item.id}`);
    };

    const onNewWarranty = () => {
        Action.newWarranty({ details: { customer: customer }, returnTo: location });
    };

    const button = {
        content: 'New',
        icon: 'plus',
        color: 'blue',
        onClick: onNewWarranty,
    };

    return (
        <>
            <SectionHeader size={'small'} content={'Warranty Claims'} button={button} />
            <DefaultConnectionTable
                onRowClick={onViewCase}
                tableProps={{ color: 'grey' }}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </>
    );
};
