import { call, cancelled, put, race, take } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { SERVICE_JOB_ACTION, ServiceJobAction, ServiceJobBookingDetails } from './reducer-service-job-booking';

export const createWatchServiceJobRecurActions = (store, URLBuilder) => {
    const sagaName = 'SAGA_SERVICE_JOB_RECUR_CREATE';

    function* watchFn(sagaName, { payload }) {
        const navigate = (url, state: any = {}) => {
            return navigateActions.browserNavigation(url, { ...state, saga: sagaName });
        };

        const { customer, address, pool, returnTo, staff } = payload as ServiceJobBookingDetails;

        try {
            yield put(
                ServiceJobAction.setServiceJobDetails({
                    staff: staff,
                    customer: customer,
                    pool: pool,
                    address: address,
                })
            );

            yield put(navigate('/service-jobs/sj/recur/new', { returnTo }));

            yield take(SERVICE_JOB_ACTION.SERVICE_JOB_CREATED);
        } catch (e) {
            yield put(ServiceJobAction.abort());
        } finally {
            if (yield cancelled()) {
                yield put(navigate(returnTo || '/service-jobs/sj/single'));
            }
        }
    }

    function* watchAbortFn(sagaName) {
        yield race({
            navigationAbort: call(sagaAbortOnNavigation, sagaName),
            actionAbort: take(SERVICE_JOB_ACTION.ABORT),
        });
    }

    function* watchServiceJobRecurActions() {
        while (true) {
            yield put(ServiceJobAction.reset());
            const startAction = yield take(SERVICE_JOB_ACTION.NEW_SERVICE_JOB_RECUR);
            yield race({
                saga: call(watchFn, sagaName, startAction),
                abort: call(watchAbortFn, sagaName),
            });
        }
    }

    return watchServiceJobRecurActions;
};
