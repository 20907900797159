import * as React from 'react';
import {
    ConnectionPaginationButtons,
    MenuBarHeaderItem,
    MenuBarSection,
    PageSkeletonLoader,
    StickyMenuBar,
    StickyMenuContainer,
    toastError,
    VStack,
} from '@poolware/components';
import { fromEdges, NodeType, useMutationFranchiseTestTreatmentView } from '@poolware/api';
import { Segment, Table } from 'semantic-ui-react';
import { AffectsList, AsyncCheckbox, ConsumableProduct, PriorityChanger, RequestError } from './components';
import { useQueryFranchiseTreatmentsConnection } from './use-query-franchise-treatments-connection';

const Page: React.FC = () => {
    const { connectionData, connectionState, refetchQuery } = useQueryFranchiseTreatmentsConnection();
    const { setBlacklist, setLocalPriority } = useMutationFranchiseTestTreatmentView({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    const onPriorityChange = (treatmentView: NodeType.FranchiseTestTreatmentView) => async (newValue: number) => {
        try {
            await setLocalPriority({
                id: treatmentView.id,
                priority: newValue,
            });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change priority', description: e.message });
        }
    };

    const onBlacklistChange = (treatmentView: NodeType.FranchiseTestTreatmentView) => async (newValue: boolean) => {
        try {
            await setBlacklist({
                id: treatmentView.id,
                blacklist: newValue,
            });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Operation failed', description: e.message });
        }
    };

    const renderTable = () => {
        const testProblemViews = connectionData;
        if (!testProblemViews) {
            return <RequestError />;
        }

        return (
            <div style={{ overflowX: 'auto' }}>
                <Table basic={true} unstackable={true} collapsing={false} compact={'very'}>
                    <Table.Header style={{ backgroundColor: '#dadada' }}>
                        <Table.Row>
                            <Table.HeaderCell>Problem</Table.HeaderCell>
                            <Table.HeaderCell>Treatments</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {testProblemViews.length === 0 && (
                            <Table.Row>
                                <Table.Cell
                                    collspan={2}
                                    style={{ textAlign: 'center', padding: '10px', fontStyle: 'italic' }}
                                    colSpan={6}
                                >
                                    No problems available
                                </Table.Cell>
                            </Table.Row>
                        )}

                        {testProblemViews.map((problemView) => {
                            const { testProblem, treatmentViews } = problemView;
                            const deEdgedTreatmentViews = fromEdges(treatmentViews)
                                .filter((t) => {
                                    // remove treatments without product
                                    return t.testTreatment && t.testTreatment.consumableProduct;
                                })
                                .sort((a, b) => (a.localPriority > b.localPriority ? -1 : 1));
                            const treatmentsCount = deEdgedTreatmentViews.length;
                            const maxPriority = deEdgedTreatmentViews.reduce((acc, t) => {
                                const priority = Number(t.localPriority || 0);
                                return priority > acc ? priority : acc;
                            }, 0);

                            if (treatmentsCount === 0) {
                                // remove problem without treatments
                                return null;
                            }

                            return (
                                <Table.Row key={problemView.id}>
                                    <Table.Cell
                                        verticalAlign={'top'}
                                        style={{ textAlign: 'left', width: '150px', borderBottom: '1px solid black' }}
                                    >
                                        <b>{testProblem.name}</b>
                                    </Table.Cell>

                                    <Table.Cell
                                        style={{ padding: '5px 20px 20px 10px', borderBottom: '1px solid black' }}
                                    >
                                        <Table basic={false} unstackable={true} collapsing={false} compact={'very'}>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell style={{ width: '200px' }}>
                                                        Treatment
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '200px' }}>
                                                        Affect
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ minWidth: '200px' }}>
                                                        Product
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '200px', textAlign: 'center' }}>
                                                        Priority
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell style={{ width: '100px', textAlign: 'center' }}>
                                                        Use
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {deEdgedTreatmentViews.map((treatmentView, index) => {
                                                    const {
                                                        isBlacklisted,
                                                        localPriority,
                                                        testTreatment,
                                                    } = treatmentView;
                                                    if (!testTreatment) return '---';
                                                    const { consumableProduct, affects } = testTreatment;
                                                    const deEdgedAffects = fromEdges(affects);
                                                    return (
                                                        <Table.Row key={treatmentView.id}>
                                                            <Table.Cell disabled={isBlacklisted}>
                                                                {testTreatment.name}
                                                            </Table.Cell>
                                                            <Table.Cell disabled={isBlacklisted}>
                                                                <AffectsList affects={deEdgedAffects} />
                                                            </Table.Cell>
                                                            <Table.Cell disabled={isBlacklisted}>
                                                                <ConsumableProduct
                                                                    consumableProduct={consumableProduct}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell disabled={isBlacklisted} textAlign={'center'}>
                                                                {/*<ContentEditableText*/}
                                                                {/*    value={(localPriority || 0).toString()}*/}
                                                                {/*    inputType={'number'}*/}
                                                                {/*    onCommit={onPriorityChange(treatmentView)}*/}
                                                                {/*/>*/}
                                                                <PriorityChanger
                                                                    priority={Number(localPriority)}
                                                                    maxPriority={maxPriority}
                                                                    onPriorityChange={onPriorityChange(treatmentView)}
                                                                />
                                                            </Table.Cell>
                                                            <Table.Cell style={{ textAlign: 'center' }}>
                                                                <AsyncCheckbox
                                                                    toggle={true}
                                                                    checked={!isBlacklisted}
                                                                    onChange={(event, data) =>
                                                                        onBlacklistChange(treatmentView)(!data.checked)
                                                                    }
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>
        );
    };

    const { pageCount, error, isInitialLoading } = connectionState;

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'lab'}>Water Test Preferences</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            {pageCount > 1 && (
                <>
                    <StickyMenuContainer>
                        <Segment basic secondary style={{ width: '100%', padding: '0.5em' }}>
                            <ConnectionPaginationButtons
                                connectionData={connectionData}
                                connectionState={connectionState}
                            />
                        </Segment>
                    </StickyMenuContainer>
                </>
            )}
            {error && <RequestError error={error} />}
            {isInitialLoading ? <PageSkeletonLoader /> : renderTable()}
        </VStack>
    );
};

export default Page;
