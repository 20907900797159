import * as React from 'react';
import { useState } from 'react';
import { HeaderButtonType, IconButton, Panel } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { Icon } from 'semantic-ui-react';
import { JobStageViewModels, JobStageViewModelType } from './constants';

const StageTypeRender: React.FC<{
    serviceJobTemplate: NodeType.ServiceJobTemplate;
    stageVM: JobStageViewModelType[];
    editMode: boolean;
    title: string;
}> = ({ stageVM, editMode, title, serviceJobTemplate }) => {
    const stageReport = serviceJobTemplate.stageReport;
    return (
        <div tw={'flex-grow flex flex-col'}>
            <div tw={'text-center text-lg p-2 bg-blue-100'}>{title}</div>
            <div tw={'flex flex-row justify-between gap-4 p-2'}>
                {stageVM?.map((stageVM) => {
                    const fallbackStages = stageReport.filter(
                        (sr) =>
                            sr.ownershipType === NodeType.ServiceJobStageTemplateOwnershipType.Fallback &&
                            sr.stage?.type === stageVM.type
                    );
                    const ownStages = stageReport.filter(
                        (sr) =>
                            sr.ownershipType === NodeType.ServiceJobStageTemplateOwnershipType.Own &&
                            sr.stage?.type === stageVM.type
                    );
                    return (
                        <div key={stageVM.type} tw={'flex-grow flex flex-col gap-y-2'}>
                            <div tw={'py-2 text-lg font-bold'}>
                                <Icon {...stageVM.icon} />
                                {stageVM.title}
                            </div>
                            {fallbackStages.map(({ stage }) => {
                                return (
                                    <div
                                        key={stage?.id}
                                        tw={'mr-4 rounded px-2 bg-gray-100 flex flex-row justify-between text-sm'}
                                    >
                                        <span>{stage?.title}</span> {editMode && <IconButton name={'cancel'} />}
                                    </div>
                                );
                            })}
                            {ownStages.map(({ stage }) => {
                                return (
                                    <div
                                        key={stage?.id}
                                        tw={'mr-4 rounded px-2 bg-blue-50 flex flex-row justify-between  text-sm'}
                                    >
                                        <span>{stage?.title}</span>
                                        {editMode && <IconButton name={'cancel'} />}
                                    </div>
                                );
                            })}
                            <div tw={'text-center -mt-1 p-0'}>
                                {editMode && <IconButton size={'small'} name={'plus'} />}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export interface PanelTemplateStagesProps {
    serviceJobTemplate: NodeType.ServiceJobTemplate;
}

export const PanelTemplateStages: React.FC<PanelTemplateStagesProps> = ({ serviceJobTemplate }) => {
    const [editMode, setEditMode] = useState(false);

    const button: HeaderButtonType = editMode
        ? { content: 'Done', onClick: () => setEditMode(false) }
        : { content: 'Edit', icon: 'edit', onClick: () => setEditMode(true) };

    const stageVMActive = JobStageViewModels.filter((svm) => svm.statusType === NodeType.ServiceJobStageStatus.Active);
    const stageVMSuspended = JobStageViewModels.filter(
        (svm) => svm.statusType === NodeType.ServiceJobStageStatus.Suspended
    );
    const stageVMClosed = JobStageViewModels.filter((svm) => svm.statusType === NodeType.ServiceJobStageStatus.Closed);

    return (
        <Panel>
            <Panel.Header basic={true} icon={'puzzle'} content={'Stages'} button={button} />
            <Panel.Body>
                <div tw={'flex flex-row justify-evenly gap-1 p-2'}>
                    <StageTypeRender
                        serviceJobTemplate={serviceJobTemplate}
                        stageVM={stageVMActive}
                        editMode={editMode}
                        title={'Active'}
                    />
                    <StageTypeRender
                        serviceJobTemplate={serviceJobTemplate}
                        stageVM={stageVMSuspended}
                        editMode={editMode}
                        title={'Suspended'}
                    />
                    <StageTypeRender
                        serviceJobTemplate={serviceJobTemplate}
                        stageVM={stageVMClosed}
                        editMode={editMode}
                        title={'Closed'}
                    />
                </div>

                <div>
                    {serviceJobTemplate.assignedStages?.map((stage) => {
                        return <div key={stage.id}>{stage.title}</div>;
                    })}
                </div>
            </Panel.Body>
        </Panel>
    );
};
