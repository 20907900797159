import * as React from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import {
    confirmModalImperative,
    Container,
    FormikDefaultForm,
    FormSectionHeader,
    Panel,
    toastError,
} from '@poolware/components';
import { NodeType } from '@poolware/api';
import { FormikServiceJobTemplateSelect } from '../../../connected-components/FormikServiceJobTemplateSelect';
import { ServiceJobTemplatePreview } from '../../../components/ServiceJobTemplatePreview';
import { useServiceJobMutators } from '../../../queries/mutators-service-job';
import * as Yup from 'yup';
import { FormikRecurAppointmentSplitter } from './FormikRecurAppointmentSplitter';
import { useAppNavigator } from '@poolware/react-app-navigator';

export interface ReplaceTemplateWizardProps {
    serviceJob: NodeType.ServiceJob;
    onCancel: () => any;
}

const validationSchema = Yup.object().shape({
    template: Yup.mixed().required('Required'),
    newServiceCaseTemplate: Yup.mixed().required('Required'),
    splitFromAppointmentItem: Yup.mixed().required('Required'),
});

const ConfirmationMessage = () => {
    return (
        <div>
            If you proceed the following will happen:
            <ul>
                <li>Current recurring service will be stopped at the selected date.</li>
                <li>New recurring service will be created with the selected template.</li>
                <li>Customer information will be copied to the new service.</li>
            </ul>
        </div>
    );
};

export const ReplaceTemplateWizard: React.FC<ReplaceTemplateWizardProps> = ({ onCancel, serviceJob }) => {
    const { splitRecurrentJob } = useServiceJobMutators({ refetchQueries: ['QueryServiceJob'] });
    const { AppNavigator } = useAppNavigator();

    const initialValues = {
        template: serviceJob.usedTemplate,
        newServiceCaseTemplate: null as NodeType.ServiceJob,
        newWorkOrderTemplate: null,
        splitFromAppointmentItem: null,
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const isYes = await confirmModalImperative({
                confirmMessage: {
                    header: 'Start New Recurring Service?',
                    content: <ConfirmationMessage />,
                },
                cancelButton: {
                    content: 'Cancel',
                },
                confirmButton: {
                    content: 'Start New Service',
                },
            });

            if (!isYes) {
                return;
            }

            const result = await splitRecurrentJob({
                serviceTemplate: values.newServiceCaseTemplate?.id,
                workOrderTemplate: values.newWorkOrderTemplate?.id,
                fromAppointmentItem: values.splitFromAppointmentItem?.id,
            });
            const newServiceJobId = result.data.ServiceJob.ServiceJob.id;

            const isNav = await confirmModalImperative({
                confirmMessage: {
                    header: 'New Service Created Successfully',
                    content: <>Navigate to the new Service?</>,
                },
                cancelButton: {
                    content: 'Stay on Current',
                },
                confirmButton: {
                    content: 'Navigate to New Service',
                },
            });

            if (isNav) {
                AppNavigator.navigate(`/sj/recur/${newServiceJobId}`, {
                    relativeToModule: true,
                });
            }
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <Container>
            <FormikDefaultForm
                debug={true}
                header={'Change Template'}
                initialValues={initialValues}
                submitButton={{ content: 'Start Service with New Template' }}
                onSubmit={onSubmit}
                onCancel={onCancel}
                validationSchema={validationSchema}
            >
                {({ setFieldValue, values }) => {
                    const onDidSelectNewTemplate = (
                        serviceJobTemplate: NodeType.ServiceJobTemplate,
                        workOrderTemplates: NodeType.WorkOrderTemplate[]
                    ) => {
                        setFieldValue('newWorkOrderTemplate', workOrderTemplates?.[0]);
                    };
                    const selectedWOIds = [values.newWorkOrderTemplate?.id];
                    return (
                        <div>
                            <FormSectionHeader>Current Template</FormSectionHeader>
                            <Segment>
                                <Panel.Item label={'Current Template'}>
                                    {serviceJob?.usedTemplate?.templateTitle}
                                </Panel.Item>
                                <div tw={'ml-4 p-2 rounded border border-gray-300 border-solid  rounded'}>
                                    <ServiceJobTemplatePreview
                                        serviceJobTemplate={serviceJob?.usedTemplate}
                                        workOrderIds={[]}
                                    />
                                </div>
                            </Segment>

                            <div tw={'my-auto p-6 w-full flex justify-center '}>
                                <Icon name={'arrow circle down'} size={'large'} />
                            </div>

                            <FormSectionHeader>New Template</FormSectionHeader>
                            <Segment>
                                <FormikServiceJobTemplateSelect
                                    label={'New Template'}
                                    required={true}
                                    name={'newServiceCaseTemplate'}
                                    selectWorkOrder={'single'}
                                    previewWorkOrderIds={selectedWOIds}
                                    onDidSelect={onDidSelectNewTemplate}
                                />
                                <FormikRecurAppointmentSplitter
                                    mode={'split'}
                                    label={'Start from Appointment'}
                                    name={'splitFromAppointmentItem'}
                                    serviceJob={serviceJob}
                                    headerLeft={serviceJob.title}
                                    headerRight={values.newServiceCaseTemplate?.title || 'New Template'}
                                />
                            </Segment>
                        </div>
                    );
                }}
            </FormikDefaultForm>
        </Container>
    );
};
