export enum CountryCode {
    AU = 'AU',
    NZ = 'NZ',
    MY = 'MY',
}

export type LatLng = {
    lat: number;
    lng: number;
};

export type StateType = {
    short: string;
    full: string;
};

export type AddressType = {
    destinationName?: string;
    streetName?: string;
    suburb?: string;
    city?: string;
    postCode?: string;
    state?: string;
    streetNumber?: string;
    country?: string;
};

export type CountryProfileType = {
    short: CountryCode;
    full: string;
    states: StateType[];
    loc: LatLng;
    addressFields: (keyof AddressType)[];
};

export const AUS_STATES: StateType[] = [
    { short: 'ACT', full: 'Australian Capital Territory' },
    { short: 'NSW', full: 'New South Wales' },
    { short: 'NT', full: 'Northern Territory' },
    { short: 'QLD', full: 'Queensland' },
    { short: 'SA', full: 'South Australia' },
    { short: 'TAS', full: 'Tasmania' },
    { short: 'VIC', full: 'Victoria' },
    { short: 'WA', full: 'Western Australia' },
];

export const NZ_STATES: StateType[] = [
    { short: 'Auckland', full: 'Auckland' },
    { short: 'Canterbury', full: 'Canterbury' },
    { short: 'Wellington', full: 'Wellington' },
    { short: 'Waikato', full: 'Waikato' },
    { short: 'Bay of Plenty', full: 'Bay of Plenty' },
    { short: 'Manawatu-Wanganui', full: 'Manawatu-Wanganui' },
    { short: 'Otago', full: 'Otago' },
    { short: 'Northland', full: 'Northland' },
    { short: "Hawke's Bay", full: "Hawke's Bay" },
    { short: 'Taranaki', full: 'Taranaki' },
    { short: 'Southland', full: 'Southland' },
    { short: 'Tasman', full: 'Tasman' },
    { short: 'Nelson', full: 'Nelson' },
    { short: 'Gisborne', full: 'Gisborne' },
    { short: 'Marlborough', full: 'Marlborough' },
    { short: 'West Coast', full: 'West Coast' },
];

export const MY_STATES: StateType[] = [
    { short: 'Wilayah Persekutuan Kuala Lumpur', full: 'Wilayah Persekutuan Kuala Lumpur' },
    { short: 'Wilayah Persekutuan Putrajaya', full: 'Wilayah Persekutuan Putrajaya' },
    { short: 'Wilayah Persekutuan Labuan', full: 'Wilayah Persekutuan Labuan' },
    { short: 'Johor', full: 'Johor' },
    { short: 'Kedah', full: 'Kedah' },
    { short: 'Kelantan', full: 'Kelantan' },
    { short: 'Malacca', full: 'Malacca' },
    { short: 'Negeri Sembilan', full: 'Negeri Sembilan' },
    { short: 'Pahang', full: 'Pahang' },
    { short: 'Perak', full: 'Perak' },
    { short: 'Penang', full: 'Penang' },
    { short: 'Perlis', full: 'Perlis' },
    { short: 'Sabah', full: 'Sabah' },
    { short: 'Sarawak', full: 'Sarawak' },
    { short: 'Selangor', full: 'Selangor' },
    { short: 'Terengganu', full: 'Terengganu' },
];

export const CountryProfiles: CountryProfileType[] = [
    {
        short: CountryCode.AU,
        full: 'Australia',
        states: AUS_STATES,
        loc: { lat: -34, lng: 141 },
        addressFields: ['destinationName', 'streetName', 'city', 'postCode', 'state', 'streetNumber', 'country'],
    },
    {
        short: CountryCode.NZ,
        full: 'New Zealand',
        states: NZ_STATES,
        loc: { lat: -41.2, lng: 174.7 },
        addressFields: [
            'destinationName',
            'streetName',
            'suburb',
            'city',
            'postCode',
            'state',
            'streetNumber',
            'country',
        ],
    },
    {
        short: CountryCode.MY,
        full: 'Malaysia',
        states: MY_STATES,
        loc: { lat: 3.14, lng: 101.6 },
        addressFields: [
            'destinationName',
            'streetName',
            'suburb',
            'city',
            'postCode',
            'state',
            'streetNumber',
            'country',
        ],
    },
];

export const CountryProfileMap = {
    [CountryCode.AU]: CountryProfiles[0],
    [CountryCode.NZ]: CountryProfiles[1],
    [CountryCode.MY]: CountryProfiles[2],
};

export const getCountryForCode = (countryCode: CountryCode = CountryCode.AU): CountryProfileType => {
    return CountryProfileMap[countryCode];
};
