import { call, cancelled, put, race, take } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { CalcMode, HEAT_CALC_ACTION, HeatCalcAction, HeatCalcDetailsStateType } from './reducer-heat-calc';

interface HandlerPayload {
    payload: HeatCalcDetailsStateType;
}

export const createWatchHeatCalcSaga = ({ rootPath = 'calcs' }: { rootPath: string }) => {
    const sagaName = 'SAGA_HEAT_CALC';

    const navigate = (url, state: any = {}) => {
        return navigateActions.browserNavigation(url, { ...state, saga: sagaName });
    };

    function* handler({ payload }: HandlerPayload) {
        try {
            // Reset all

            console.log(payload);

            if (payload?.mode === CalcMode.NEW_DOC || payload?.mode === CalcMode.DEFAULT) {
                yield put(navigate(`/${rootPath}/combined/new`));
                const formAction = yield take([HEAT_CALC_ACTION.SAVE]);
            } else if (payload?.mode === CalcMode.OPEN_DOC) {
                if (payload?.details?.heaterRecommendation?.id) {
                    yield put(navigate(`/${rootPath}/combined/rc/${payload?.details.heaterRecommendation?.id}`));
                } else {
                    console.error(
                        "expected 'heaterRecommendation' in redux action payload for CalcMode.OPEN_DOC  mode"
                    );
                    yield put(navigate(`/${rootPath}/combined`));
                }
            } else {
                console.error('unexpected mode ' + payload.mode);
            }
            // console.log(formAction);
        } catch (e) {
            console.error(e);
            yield put(HeatCalcAction.abort());
        } finally {
            if (yield cancelled()) {
                yield put(HeatCalcAction.reset());
            }
        }
    }

    function* watch() {
        while (true) {
            yield put(HeatCalcAction.reset());
            const startAction = yield take(HEAT_CALC_ACTION.OPEN);
            yield race({
                saga: call(handler, startAction),
                navigationAbort: call(sagaAbortOnNavigation, sagaName),
                actionAbort: take(HEAT_CALC_ACTION.ABORT),
            });
        }
    }

    return watch;
};
