import { NodeType } from '@poolware/api';

export type OrderEstimateResult = {
    totalPrice: number;
    totalTime: number;
};
export const getWorkOrderSummary = (jobTodos: NodeType.JobTodo[]): OrderEstimateResult => {
    return jobTodos.reduce<OrderEstimateResult>(
        (acc, curr) => {
            return {
                totalPrice: acc.totalPrice + Number(curr.price),
                totalTime: acc.totalTime + curr.timeEstimate,
            };
        },
        { totalPrice: 0, totalTime: 0 }
    );
};

export const getWorkOrderTemplateSummary = (jobTodos: NodeType.JobTodoTemplate[]): OrderEstimateResult => {
    return jobTodos.reduce<OrderEstimateResult>(
        (acc, curr) => {
            return {
                totalPrice: acc.totalPrice + Number(curr.recommendedPrice),
                totalTime: acc.totalTime + curr.timeEstimate,
            };
        },
        { totalPrice: 0, totalTime: 0 }
    );
};
