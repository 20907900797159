import * as React from 'react';
import { List } from 'semantic-ui-react';
import { PageControlProps } from './EquipmentList.Control';
import { groupItemsByProductDeclaration } from '../../../Equipment/utils';
import styled from 'styled-components';

interface EquipmentListProps extends PageControlProps {
    poolId: string;
    onClick: (any) => any;
}

const NameSpan = styled.span`
    padding-left: 0.5em;
`;

const EquipmentList: React.FC<EquipmentListProps> = (props) => {
    let { items, poolProductDeclarations } = props;

    if (!items?.length) {
        return (
            <small>
                <i>No equipment registered</i>
            </small>
        );
    }

    const groups = groupItemsByProductDeclaration(poolProductDeclarations, items);

    return (
        <List>
            {groups.map((g, i) => {
                const count = g.items.length;
                if (count === 0) {
                    return null;
                }
                return (
                    <List.Item key={i}>
                        <List.Header>{g.name}</List.Header>
                        <List.Content>
                            <List.List>
                                {g.items.map((item) => {
                                    return (
                                        <List.Item key={item.id}>
                                            <List.Content>
                                                <NameSpan>{item?.product?.name || '--'}</NameSpan>
                                            </List.Content>
                                        </List.Item>
                                    );
                                })}
                            </List.List>
                        </List.Content>
                    </List.Item>
                );
            })}
        </List>
    );
};

export default EquipmentList;
