import styled from 'styled-components';

export const PrintPageContainer = styled.div`
    @page {
        size: A4;
    }

    background-color: white;

    @media print {
        padding: 0 0.5cm;
        margin: 0;
    }

    @media screen {
        max-width: 768px;
        margin: 0 auto;
        padding: 1rem 7rem 1rem 2rem;
    }

    -webkit-print-color-adjust: exact !important;
`;
