import { CostEstimationModel, CostEstimationMonthly, MonthlyHeatPumpProfile, ResourcePriceUnit } from '../common/types';
import { HeatPumpModel } from '../data';
import * as _ from 'lodash';

export interface CostEstimationHeatPumpInputProps {
    monthHeatProfiles: MonthlyHeatPumpProfile[];
    withCover: boolean;
    electricityCost: number;
    operatingHours: number;
    selectedModel: HeatPumpModel;
}

export const computeCostEstimationHeatPump = (
    input: CostEstimationHeatPumpInputProps
): CostEstimationModel<HeatPumpModel> => {
    const { monthHeatProfiles, selectedModel, electricityCost, operatingHours } = input;
    if (!selectedModel) {
        return null;
    }

    const costArr: CostEstimationMonthly[] = [];

    for (let monthHeatProfile of monthHeatProfiles) {
        let modelInputPower = selectedModel.input;
        let modelCapacityPower = selectedModel.capacity;
        let costRunningMonthly = 0;
        let costRunningDaily = 0;

        //calculate model input power
        if (!selectedModel) {
            modelInputPower = 0;
        } else {
            modelInputPower = selectedModel.input;
        }

        //calculate cost/mth/day
        if (monthHeatProfile.heatTotal <= 0) {
            costRunningMonthly = 0;
        } else {
            const coverCoef = 1; /// withCover ? 0.5 : 1; cover coeffient is already applied to tempHeatTotal
            const loadCoef = (coverCoef * monthHeatProfile.heatTotal) / modelCapacityPower;
            costRunningDaily = loadCoef * electricityCost * modelInputPower * operatingHours;
            costRunningMonthly = costRunningDaily * monthHeatProfile.monthProfile.days;
        }

        costArr.push({
            monthId: monthHeatProfile.monthProfile.id,
            costRunningMonthly,
            costRunningDaily,
        });
    }

    //calculate total cost
    const totalCost = costArr.reduce((acc, i) => acc + i.costRunningMonthly, 0);

    //calculate max value
    const max_value = _.maxBy(monthHeatProfiles, (p) => p.heatTotal);

    //calculate minimum output
    let minimumOutput = Math.round(max_value?.heatTotal);

    //calculate quantity
    let quantity;
    if (minimumOutput > selectedModel.capacity) {
        quantity = Math.ceil(minimumOutput / selectedModel.capacity);
    } else {
        if (minimumOutput > 0) {
            quantity = 1;
        } else {
            quantity = 0;
        }
    }

    return {
        numberOfUnits: quantity,
        minimumOutput,
        operatingHours,
        costYearly: totalCost,
        recommendedModel: selectedModel,
        costByMonth: costArr,
        resourcePrice: electricityCost,
        resourcePriceUnit: ResourcePriceUnit.DollarsPerKW,
    };
};

export type HeatPumpCostEstimationResultType = ReturnType<typeof computeCostEstimationHeatPump>;
