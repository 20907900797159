import * as React from 'react';
import { FormikDefaultForm, FormikInputField } from '@poolware/components';
import { VendMutator } from '@poolware/api';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    key: Yup.string().max(500, 'Too Long!').required('Required').nullable(),
    shopUri: Yup.string().required('Required').nullable(),
});

export interface VendEditFormProps {
    VendMutator: VendMutator;
    initialValues: any;
    onCancel: () => any;
    onSave: () => any;
}

class VendEditForm extends React.Component<VendEditFormProps> {
    onSave = async (values) => {
        const vendId = values.id;
        const key = values.key;
        const shopUri = values.shopUri;

        if (!vendId && this.props.VendMutator.createVendAPI) {
            await this.props.VendMutator.createVendAPI({ key: key, shopUri: shopUri });
        } else if (vendId) {
            await this.props.VendMutator.updateVendAPI({ vend: vendId, key: key, shopUri: shopUri });
        } else {
            console.error('Expected createVendAPI or updateVendAPI function to be provided');
        }

        if (this.props.onSave) {
            this.props.onSave();
        }
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel && onCancel();
    };

    render() {
        const { initialValues } = this.props;

        return (
            <FormikDefaultForm
                initialValues={initialValues || {}}
                submitButton={{ content: 'Submit' }}
                onSubmit={this.onSave}
                onCancel={this.onCancel}
                validationSchema={validationSchema}
            >
                <FormikInputField label={'API key'} name={'key'} required={true} />
                <FormikInputField
                    label={'Vend Shop URL'}
                    name={'shopUri'}
                    required={true}
                    placeholder={'Ex. https://myvend.vendhq.com/'}
                />
            </FormikDefaultForm>
        );
    }
}

export default VendEditForm;
