import * as React from 'react';
import { compose } from '@ez/tools';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../queries';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { FormikDefaultForm, FormikInputField } from '@poolware/components';
import * as Yup from 'yup';
import { Container } from 'semantic-ui-react';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
});

class NewProductCompany extends React.Component<PageControlProps> {
    onSubmit = async (values, actions) => {
        try {
            const resId = await this.props.ProductCatalogMutator.createProductCompany(values);
            this.props.AppNavigator.replace(`/${resId}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e.message });
        }
    };

    onCancel = () => {
        this.props.AppNavigator.replaceToOrigin();
    };

    render() {
        const initialValues = {
            name: '',
        };

        return (
            <Container text>
                <FormikDefaultForm
                    header={'New Product Company'}
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                >
                    <FormikInputField label={'Company Name'} name={'name'} required={true} />
                </FormikDefaultForm>
            </Container>
        );
    }
}

export interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {}

export default compose(withAppNavigator(), withProductCatalogMutators(['ProductCompanies']))(NewProductCompany);
