import * as React from 'react';
import { FeatureFlag } from './feature-flags';
import { useViewer } from './Viewer';

export const FeatureAccess: React.FC<{
    featureFlag: FeatureFlag | string;
}> = ({ featureFlag, children }) => {
    const { canAccessFeature } = useViewer();
    const canAccess = canAccessFeature(featureFlag);
    if (!canAccess) {
        return null;
    }
    return <>{children}</>;
};

export const AdminOnly: React.FC = ({ children }) => {
    return <FeatureAccess featureFlag={FeatureFlag.ADMIN}>{children}</FeatureAccess>;
};
