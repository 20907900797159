import * as React from 'react';
import { useMemo } from 'react';
import { Icon } from 'semantic-ui-react';
import { ConnectionTableDef, DefaultConnectionTable, Display, TruncatedDiv } from '@poolware/components';
import { IconServiceJobStage, ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import styled from 'styled-components';

const CustomerCellContainer = styled(TruncatedDiv)`
    display: flex;
    flex-direction: column;
`;

const creatTableDef = (onDragStart, onDragEnd): ConnectionTableDef<NodeType.WorkOrder> => [
    {
        header: 'Work Order',
        cell: (workOrder) => {
            const { serviceJob, assignedTo: staff, title = '--' } = workOrder;
            const groupTitle = serviceJob?.group?.title;
            const isRecurring = serviceJob?.isRecurring;
            const sjTitle = serviceJob?.title;

            return (
                <div
                    tw={'flex flex-col cursor-move bg-white bg-opacity-50'}
                    draggable="true"
                    onDragStart={(e) => onDragStart(workOrder, e)}
                    onDragEnd={onDragEnd}
                >
                    <div>
                        <IconServiceJobStage stage={workOrder?.stage} />
                        {workOrder ? workOrder?.workOrderNumber : <i tw={'text-gray-500 '}>pending...</i>}
                    </div>
                    <div>
                        <Icon name={ModuleIconNames.WorkOrder} />
                        {isRecurring && <Icon color={'grey'} name={ModuleIconNames.RecurringService} />}
                        {_.truncate(title, { length: 32 })}
                    </div>
                    <div tw={'text-xs text-gray-700'} style={{ paddingLeft: 1 }}>
                        <span>
                            {groupTitle && <span tw={'font-bold text-purple-600'}>{groupTitle}</span>}
                            {groupTitle && sjTitle && <Icon name={'caret right'} />}
                            {sjTitle && (
                                <>
                                    {!isRecurring && <Icon color={'grey'} name={ModuleIconNames.ServiceCase} />}
                                    {_.truncate(sjTitle, { length: 32 })}
                                </>
                            )}
                        </span>
                    </div>
                    {staff && (
                        <div>
                            <Icon name={'spy'} />
                            <Display.Entity value={staff} truncateLength={32} />
                        </div>
                    )}
                </div>
            );
        },
    },
    {
        header: 'Customer',
        cell: (workOrder) => {
            const { address, pool, customer } = workOrder;
            // const color = workOrder?.status === NodeType.WorkOrderStatusEnum.Open ? '#2185d0' : '#444444';

            return (
                <CustomerCellContainer maxWidth={200}>
                    {customer && (
                        <div>
                            <Icon name={'user'} />
                            <code style={{ color: '#444444' }}>
                                <b>{customer.crn}</b>
                            </code>{' '}
                            - <Display.Entity value={customer} />
                        </div>
                    )}
                    {customer?.companyName && (
                        <div>
                            <Icon name={'building'} />
                            {customer.companyName}
                        </div>
                    )}
                    {address && (
                        <div>
                            <Icon name={'map marker alternate'} />
                            <Display.Address value={address} />
                        </div>
                    )}
                    {pool && (
                        <div>
                            <Icon name={'life ring'} />
                            Pool - {pool.volume}L {pool.type?.name && `, ${pool.type?.name}`}
                        </div>
                    )}
                </CustomerCellContainer>
            );
        },
    },
];

const tableCellProps: any = {
    verticalAlign: 'top',
    style: {
        borderTop: '1px solid hsl(206, 50%, 85%)',
    },
};

export interface PageTableProps {
    onView: (item: NodeType.WorkOrder, refetchQuery?: any) => any;
    connectionState;
    connectionData;
    onDragStart: any;
    onDragEnd: any;
}

export const WorkOrdersTable: React.FC<PageTableProps> = ({
    onDragEnd,
    onDragStart,
    onView,
    connectionState,
    connectionData,
}) => {
    const tableDef = useMemo(() => creatTableDef(onDragStart, onDragEnd), [onDragStart, onDragEnd]);

    return (
        <DefaultConnectionTable
            debug={false}
            tableProps={{ size: 'small', color: ModuleColorNames.WorkOrder }}
            tableDef={tableDef}
            tableCellProps={tableCellProps}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
