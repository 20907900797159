import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const CustomerQuery = gql`
    query CustomerVendQuery($id: ID!) {
        node(id: $id) {
            id
            ... on Customer {
                id
                user {
                    id
                    firstName
                    lastName
                    entity {
                        id
                        franchise {
                            id
                            vend {
                                id
                            }
                        }
                    }
                }
                vendLink {
                    id
                    isDeleted
                    vendUUID
                    vendURL
                    vendCustomer {
                        id
                    }
                }
            }
        }
    }
`;

export const useQueryCustomerVendDetails = createUseQueryNodeHook<NodeType.Customer>(CustomerQuery);
