import Page from './Page';

import { compose, mapProps } from '@ez/tools';

import { NodeType, poolFragment, PoolMutatorProps, withPoolMutators } from '@poolware/api';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@poolware/components';
import {
    BookingActionProps,
    ServiceJobActionProps,
    withBookingActions,
    withServiceJobActions,
} from '@poolware/app-service-jobs';
import { withViewer, WithViewerProps } from '@poolware/app-shell';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';

export const PoolQuery = gql`
    query PoolDetailsQuery($poolId: ID!) {
        pool: node(id: $poolId) {
            id
            ...PoolFragment
            ... on Pool {
                id
                site {
                    id
                    accessKeyLocation
                }
                poolBottleLeases(search: { returnedAt: { is: null } }) {
                    edges {
                        node {
                            id
                            leasedAt
                            returnedAt

                            bottle {
                                id
                                scanCode
                            }
                        }
                    }
                }
                entity {
                    id
                    user {
                        id
                        customer {
                            id
                            contactName
                            crn
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                    franchise {
                        id
                        name
                    }
                }
            }
        }
    }
    ${poolFragment}
`;

export default compose(
    withAppNavigator(),
    withViewer(),
    graphql(PoolQuery, {
        options: (props: any) => ({
            variables: { poolId: props.params.poolId },
            fetchPolicy: 'cache-and-network',
        }),
    }),
    withApolloLoading({ show404ForPath: 'data.pool.id' }),
    mapProps((props) => {
        let pool = props?.data?.pool;
        return {
            ...props,
            pool,
        };
    }),
    withPoolMutators(['PoolDetailsQuery']),
    withServiceJobActions(),
    withBookingActions()
)(Page);

export interface PageControlProps
    extends WithViewerProps,
        PoolMutatorProps,
        BookingActionProps,
        ServiceJobActionProps,
        IAppNavigatorProps {
    pool: NodeType.Pool;
}
