import * as _ from 'lodash';
import { DatePickerButton } from '@poolware/components';
import moment from 'moment';
import { CalNavigate } from '@poolware/react-big-calendar';
import * as React from 'react';

const highlightDates = [{ 'react-datepicker__day--highlighted-custom': [new Date()] }];

export const ToolbarDatePicker: React.FC<{ label; activeDate; navigate: (action: CalNavigate, date: Date) => any }> = ({
    label,
    activeDate,
    navigate,
}) => {
    const innerWidth = _.get(window, 'innerWidth', 1000);
    let monthsShown = 3;
    if (innerWidth >= 1280) {
        monthsShown = 3;
    } else if (innerWidth >= 768) {
        monthsShown = 2;
    } else {
        monthsShown = 1;
    }

    return (
        <DatePickerButton
            icon={'calendar alternate outline'}
            content={label}
            size={'tiny'}
            basic
            color="teal"
            labelPosition={'left'}
            monthsShown={monthsShown}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            dateFormat={'dd MMMM yyyy'}
            selected={activeDate}
            todayButton={'Today'}
            shouldCloseOnSelect={false}
            popperPlacement="bottom"
            arrowPosition={'center'}
            highlightDates={highlightDates}
            onChange={(value) => {
                try {
                    const newDate = moment(value).toDate();
                    navigate(CalNavigate.DATE, newDate);
                } catch (e) {
                    navigate(CalNavigate.DATE, activeDate);
                }
            }}
        />
    );
};
