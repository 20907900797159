import * as React from 'react';
import { MenuBarHeaderItem, MenuBarItem, MenuBarSection, StickyMenuBar, VStack } from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMemo, useState } from 'react';
import { CatalogProvider } from '../common/use-catalog';
import { useHeatCalcActions } from '../redux';
import { usePersistedString } from '@ez/tools';
import { deSerializeToForm, formDefaultValues } from '../common/state-serializer';
import { FormikHeatCalcValueType } from '../common/types';
import { getYearProfile } from '../common/use-city-temperature-profile';
import { computePoolVolume } from '../common/FormikInputComponents';
import { Formik } from 'formik';
import { GasHeaterCalc } from './Calc';

interface HeatingCalcsProps {
    print?: boolean;
}

const GasHeaterCalcContainer: React.FC<HeatingCalcsProps> = ({ print }) => {
    const { HeatCalcState } = useHeatCalcActions();
    const [defaultCountry] = usePersistedString<string>('HEATER_CALC.COUNTRY', 'AUS');
    const [defaultState] = usePersistedString<string>('HEATER_CALC.STATE', 'NSW');
    const [defaultCity] = usePersistedString<string>('HEATER_CALC.CITY', 'SYDNEY');

    const rc = useMemo(() => {
        return deSerializeToForm(HeatCalcState.details?.heaterRecommendation?.payload);
    }, [HeatCalcState.details]);

    const initialValues = useMemo<FormikHeatCalcValueType>(() => {
        const yearlyProfile = getYearProfile(defaultCountry, defaultState, defaultCity);
        const selectedMonths = yearlyProfile.map((y) => y.id);
        const defaultConf = {
            ...formDefaultValues,
            selectedMonths: selectedMonths,
            yearProfile: yearlyProfile,
            city: defaultCity,
            country: defaultCountry,
            state: defaultState,
            ...rc,
        };
        const { volume, surfaceAreaTop } = computePoolVolume(defaultConf);
        return {
            ...defaultConf,
            poolVolume: volume,
            poolSurfaceAreaTop: surfaceAreaTop,
        };
    }, [rc]);

    return (
        <Formik initialValues={initialValues} onSubmit={null}>
            <GasHeaterCalc print={print} />
        </Formik>
    );
};

export const Page: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const [print, setPrint] = useState(false);

    const goBack = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    const onPrintModeChange = () => {
        setPrint(!print);
    };

    const onPrint = () => {
        window.print();
    };

    return (
        <CatalogProvider>
            <VStack>
                {print ? (
                    <StickyMenuBar>
                        <MenuBarSection>
                            <MenuBarItem onClick={() => setPrint(false)} icon={'close'} title={'Cancel'} />
                        </MenuBarSection>
                        <MenuBarSection position={'right'}>
                            <MenuBarItem icon={'print'} color={'blue'} title={'Print'} onClick={onPrint} />
                        </MenuBarSection>
                    </StickyMenuBar>
                ) : (
                    <StickyMenuBar>
                        <MenuBarSection>
                            <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'} />
                        </MenuBarSection>
                        <MenuBarSection position={'right'}>
                            <MenuBarItem icon={'print'} onClick={onPrintModeChange} />
                            <MenuBarHeaderItem>Gas Heater Selector</MenuBarHeaderItem>
                        </MenuBarSection>
                    </StickyMenuBar>
                )}

                <GasHeaterCalcContainer print={print} />
            </VStack>
        </CatalogProvider>
    );
};
