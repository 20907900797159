import { ButtonWithPopup, Display, FormLabel, toastError } from '@poolware/components';
import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { Button, Checkbox, Divider, Modal } from 'semantic-ui-react';
import { withApollo, WithApolloClient } from 'react-apollo';
import styled from 'styled-components';
import { querySaleDetails } from '../query-sale-details';
import { usePersistedToggle } from '@ez/tools';

const VendSaleConfirmationModal: React.FC<{ webRegistryUrl; onModalClose; onVendOpen }> = ({
    webRegistryUrl,
    onModalClose,
    onVendOpen,
}) => {
    const openVendRegistry = () => {
        window.open(webRegistryUrl, '_blank');
        onVendOpen();
    };

    return (
        <Modal size={'small'} open={!!webRegistryUrl} centered={false} closeIcon onClose={onModalClose}>
            <Modal.Header>Successfully created sale on VEND!</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Button
                        primary
                        fluid
                        onClick={openVendRegistry}
                        color={'green'}
                        icon={'external'}
                        content={'Open in VEND Register'}
                        target={'_blank'}
                    />
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
};

const NotesDiv = styled.div`
    background-color: ivory;
    border: 1px solid #bfbfbf;
    padding: 5px;
    border-radius: 4px;
    p {
        line-height: 1em;
        font-size: 0.9em;
    }

    max-height: 160px;
    overflow: auto;
`;

const DimmingDiv = styled.div<{ dim?: boolean }>`
    ${({ dim }) => {
        if (dim) {
            return 'opacity: 0.4';
        }
    }}
`;

const VendSaleCreateModal: React.FC<{
    client;
    AppointmentMutator;
    appointmentItem: NodeType.AppointmentItem;
    onModalClose;
    onSaleCreated;
    open: boolean;
}> = ({ onModalClose, onSaleCreated, appointmentItem, open, client, AppointmentMutator }) => {
    const [updating, setUpdating] = useState(false);
    const [includeNotes, setIncludeNotes] = usePersistedToggle('vendsale.include.note', true);

    const onCreateSale = async () => {
        const { note } = appointmentItem;
        setUpdating(true);
        try {
            // await wait(1000);
            await AppointmentMutator.addSale(appointmentItem, includeNotes ? { note } : undefined);
            const webRegistryUrl = await querySaleDetails(client, appointmentItem.id);
            onSaleCreated(webRegistryUrl);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to create screate sale', description: e.message });
        }
        setUpdating(false);
    };

    const { note } = appointmentItem;

    return (
        <Modal size={'small'} open={open} centered={false} closeIcon onClose={onModalClose}>
            <Modal.Header>Create sale?</Modal.Header>

            {note && (
                <Modal.Content>
                    <Checkbox
                        checked={includeNotes}
                        label={'Include appointment notes into sale'}
                        onClick={(_, { checked }) => {
                            setIncludeNotes(checked);
                        }}
                    />
                    <Divider hidden />
                    <DimmingDiv dim={!includeNotes}>
                        <FormLabel>Appointment Notes</FormLabel>
                        <NotesDiv>
                            <Display.Text value={note} />
                        </NotesDiv>
                    </DimmingDiv>
                </Modal.Content>
            )}

            <Modal.Actions>
                <Button loading={updating} basic floated={'left'} secondary onClick={onModalClose} content={'Cancel'} />
                <Button loading={updating} primary onClick={onCreateSale} content={'Create Sale on Vend'} />
            </Modal.Actions>
        </Modal>
    );
};

interface VendSaleButtonComponentProps {
    AppointmentMutator: any;
    canCreateSale: boolean;
    appointmentItem: NodeType.AppointmentItem;
    buttonProps?: any;
}

const VendSaleButtonComponent: React.FC<WithApolloClient<VendSaleButtonComponentProps>> = ({
    client,
    AppointmentMutator,
    canCreateSale,
    appointmentItem,
    buttonProps,
}) => {
    const { vendSale, customer, paymentUrl } = appointmentItem;

    const [webRegistryUrl, setWebRegistryUrl] = useState(null);
    const [showCreateSale, setShowCreateSale] = useState(false);

    if (!canCreateSale) {
        return null;
    }

    const onStarSale = () => {
        setShowCreateSale(true);
    };

    const onSaleCreated = (webRegistryUrl) => {
        setShowCreateSale(false);
        setWebRegistryUrl(webRegistryUrl);
    };

    const onCloseModal = () => {
        setShowCreateSale(false);
        setWebRegistryUrl(null);
    };

    const isDisabled = !customer;
    const popup = customer
        ? undefined
        : {
              content: 'Customer is required',
          };

    const buttonStylingProps: any = {
        basic: true,
        size: 'mini',
        icon: 'tag',
        fluid: true,
        ...buttonProps,
    };

    // Note: vendSale can be null even if paymentUrl exists.
    // This can happen if a sale was manually deleted from Vend.
    // Need to check for both `paymentUrl` and `vendSale`
    const hasSale = paymentUrl && vendSale;
    return (
        <>
            {hasSale ? (
                <Button
                    {...buttonStylingProps}
                    color={'green'}
                    as={'a'}
                    disabled={!vendSale}
                    href={vendSale?.historyUrl}
                    target={'_blank'}
                    content={'Open sale'}
                />
            ) : (
                <ButtonWithPopup
                    {...buttonStylingProps}
                    color={'green'}
                    disabled={isDisabled}
                    onClick={onStarSale}
                    content={'Take Payment'}
                    popup={popup}
                />
            )}

            <VendSaleConfirmationModal
                onModalClose={onCloseModal}
                onVendOpen={onCloseModal}
                webRegistryUrl={webRegistryUrl}
            />

            <VendSaleCreateModal
                client={client}
                AppointmentMutator={AppointmentMutator}
                onModalClose={onCloseModal}
                appointmentItem={appointmentItem}
                open={showCreateSale}
                onSaleCreated={onSaleCreated}
            />
        </>
    );
};

export default withApollo<VendSaleButtonComponentProps>(VendSaleButtonComponent);
