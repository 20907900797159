import * as React from 'react';
import { PageLayout, toastError } from '@poolware/components';
import { Icon } from 'semantic-ui-react';
import { ModuleIconNames } from '../../constants';
import { ServiceJobLink } from '../../Links';
import * as _ from 'lodash';
import { ToolbarContentContainer } from '../../components/page-layout';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import { fromEdges, NodeType } from '@poolware/api';
import { ButtonsChangeJobStage } from '../../components/ButtonsChangeJobStage';

export interface WorkOrderPageHeaderToolbarProps {
    workOrder: NodeType.WorkOrder;
    workOrderMutators: ReturnType<typeof useWorkOrderMutators>;
    embeddedMode?: boolean;
}

export const PageHeaderToolbar: React.FC<WorkOrderPageHeaderToolbarProps> = ({
    workOrder,
    workOrderMutators,
    embeddedMode = false,
}) => {
    const onChangeStage = async (newStage: NodeType.ServiceJobStage) => {
        if (newStage?.id === workOrder.stage?.id) {
            return;
        }
        try {
            await workOrderMutators.update({ id: workOrder.id, stage: newStage?.id });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change status', description: e.message });
        }
    };

    const stageHistory = fromEdges(workOrder.stageHistory);

    return (
        <ToolbarContentContainer
            main={
                <div tw={'flex flex-col gap-2'}>
                    <PageLayout.PageHeader>
                        <Icon name={ModuleIconNames.WorkOrder} style={{ marginRight: '0.25rem' }} />
                        <div>
                            {workOrder?.workOrderNumber} - {_.truncate(workOrder?.title, { length: 100 })}
                        </div>
                    </PageLayout.PageHeader>

                    {!embeddedMode && (
                        <PageLayout.PageHeaderSub>
                            <Icon name={ModuleIconNames.ServiceCall} />
                            <span style={{ paddingRight: '0.5em' }}>Parent Case: </span>
                            <ServiceJobLink id={workOrder?.serviceJob?.id}>
                                {workOrder?.serviceJob?.serviceJobNumber} -{' '}
                                {_.truncate(workOrder?.serviceJob?.title, { length: 100 })}
                            </ServiceJobLink>
                        </PageLayout.PageHeaderSub>
                    )}
                </div>
            }
            actions={
                <>
                    <ButtonsChangeJobStage
                        stage={workOrder.stage}
                        stages={workOrder.stageCandidates}
                        onChangeStage={onChangeStage}
                        stageHistory={stageHistory}
                    />
                </>
            }
        />
    );
};
