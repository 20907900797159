import * as React from 'react';
import { NotFoundPage } from '@poolware/app-shell';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { InvoicePageView } from './View';
import { PageList } from './List';
import { InvoicePageEdit } from './Edit';
import { InvoiceSettingsPage } from './Settings';

export const Router = () => {
    return (
        <ModuleRootSwitch notFound={NotFoundPage}>
            <ModuleRoute path={`/`} exact={true} component={PageList} />
            <ModuleRoute path={`/settings`} component={InvoiceSettingsPage} />
            <ModuleRoute path={`/:id/edit`} component={InvoicePageEdit} />
            <ModuleRoute path={`/:id`} component={InvoicePageView} />
        </ModuleRootSwitch>
    );
};
