import Page from './Page';
import { compose, mapProps } from '@ez/tools';
import { AuthConnectProps, fromEdges, NodeType, StaffMutatorProps, withStaffMutators } from '@poolware/api';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@poolware/components';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';

const QL = gql`
    query RolesQuery($franchiseId: ID!) {
        franchise: node(id: $franchiseId) {
            id
            ... on Franchise {
                id
                name
            }
        }
        viewer {
            roles(first: 1000) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export default compose(
    withPageProps(),
    graphql(QL, {
        options: (props: any) => {
            const franchiseId = props?.query?.franchiseId || props.franchiseId || '';
            return {
                variables: { franchiseId },
            };
        },
    }),
    withApolloLoading(),
    mapProps((props: AuthConnectProps & any) => {
        const franchiseId = props?.query?.franchiseId || props.franchiseId;
        const roles = fromEdges(props?.data?.viewer?.roles);
        const franchise = franchiseId ? props.data?.franchise : undefined;

        // TODO: HACK: DUPE2
        // The filter below is a quick hack.
        // Remove it when the backend adds support for role lists.
        const { AuthState } = props;
        const isAdmin = AuthState.isAdmin;
        const filteredRoles = roles.filter((node: any) => {
            if (isAdmin) return true;
            return node.name !== 'Admin' && node.name !== 'Head Office';
        });
        // End of HACK

        return {
            ...props,
            roles: filteredRoles,
            franchise,
        };
    }),
    withStaffMutators(['StaffDetailsQuery', 'QueryStaffConnection', 'StaffListQuery', 'FranchiseViewQuery'])
)(Page);

export interface PageControlProps extends IWithPageProps, StaffMutatorProps {
    franchise?: NodeType.Franchise;
    roles: NodeType.Role[];
}
