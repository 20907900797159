import gql from 'graphql-tag';
import {
    createUseQueryNodeHook,
    MutationHookOptionsCustomContract,
    NodeType,
    useMutationCustomContract,
} from '@poolware/api';

const contractFragment = gql`
    fragment CustomContractFragment on CustomContract {
        id
        contractNumber
        startDate
        endDate
        terminationDate
        note
        rate
        createdAt
        updatedAt
        status
        term
        frequency
        entity {
            id
        }
        files {
            edges {
                node {
                    id
                    isPublic
                    note
                    fileName
                    mimeType
                    location
                    createdAt
                    isImage
                    url
                    checkMutations {
                        delete
                        update
                    }
                }
            }
        }
        checkMutations {
            delete
            update
        }
    }
`;

const QL = gql`
    query CustomContract($id: ID!) {
        node(id: $id) {
            ...CustomContractFragment
        }
    }

    ${contractFragment}
`;

export const useQueryCustomContract = createUseQueryNodeHook<NodeType.CustomContract>(QL);

export const useCustomContractMutator = (props: MutationHookOptionsCustomContract) => {
    const mutationCustomContract = useMutationCustomContract(props);

    const addFiles = async (pool: NodeType.CustomContract, fileIds: NodeType.ID[]) => {
        return await mutationCustomContract.update({
            id: pool.id,
            files: {
                assign: fileIds,
            },
        });
    };

    const removeFiles = async (pool: NodeType.CustomContract, files: NodeType.FileUpload[]) => {
        const fileIds = files.map((f) => f.id);
        return await mutationCustomContract.update({
            id: pool.id,
            files: {
                delete: fileIds,
            },
        });
    };
    return {
        addFiles,
        removeFiles,
        ...mutationCustomContract,
    };
};
