import * as React from 'react';
import {
    Panel,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionConsumer,
    PrintSectionHeader,
    PrintSectionItem,
} from '@poolware/components';
import { Header } from 'semantic-ui-react';
import { CalcDocType, CostEstimation } from '../../common/types';
import { getRecommendationSummary } from '../../common/compute-recommendation-summary';

export const RecommendationPanel: React.FC<{ costEstimation: CostEstimation }> = ({ costEstimation }) => {
    let summary = getRecommendationSummary({ type: CalcDocType.HeatPump, costEstimation });

    if (!summary) {
        return (
            <div>
                <Header>Recommended Heater</Header>
                <Panel>
                    <Panel.Body>No recommendations</Panel.Body>
                </Panel>
            </div>
        );
    }

    return (
        <>
            <PrintSectionHeader>Recommended Heater</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintSectionConsumer name={'recommendedHeater'}>
                        <PrintSectionItem labelWidth={'8cm'} label={'Required Heat Output'}>
                            {summary?.minimumOutput} KW/Hour
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Heater Model'}>
                            <span tw={'text-blue-800 font-bold'}>{summary.model?.name}</span>
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Heating Capacity'}>
                            {summary.model?.capacity} kW{' '}
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Input Power'}>
                            {summary.model?.input} kW
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Number of units'}>
                            {summary.numberOfUnits} unit{summary.numberOfUnits > 1 && 's'}
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Estimated Cost (Yearly)'}>
                            <span tw={'text-red-800 font-bold'}>${summary.costYearly}</span>
                        </PrintSectionItem>
                        <PrintSectionItem labelWidth={'8cm'} label={'Electricity cost'}>
                            {summary.resourcePrice}
                        </PrintSectionItem>
                    </PrintSectionConsumer>
                </PrintSectionColumn>
            </PrintSectionBody>
        </>
    );
};
