import styled from 'styled-components';
import * as React from 'react';

export const TruncatedDivWithHover = styled.div<{ maxWidth?: number }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => props.maxWidth || 300}px;
    overflow: hidden;
    &:hover:before {
        content: attr(data-text);
        overflow: visible;
        white-space: normal;
        height: auto;
        position: absolute;
        left: auto;
        top: auto;
        width: 100%;
        max-width: ${(props) => props.maxWidth || 300}px;
        padding-left: 0.25em;
        margin-left: -0.25em;
        background-color: white;
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
        word-wrap: break-word;
        display: block;
    }
`;

const TruncatedDivExpandable = styled.div<{ maxWidth?: number }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => props.maxWidth || 300}px;
    overflow: hidden;
    &:hover {
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
    }
`;

const TruncatedDivSimple = styled.div<{ maxWidth?: number }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => props.maxWidth || 300}px;
    overflow: hidden;
`;

export const TruncatedDiv: React.FC<{ maxWidth?: number; usePopup?: boolean; expandable?: boolean }> = ({
    maxWidth,
    children,
    usePopup = false,
    expandable = true,
}) => {
    if (expandable == false) {
        return <TruncatedDivSimple>{children}</TruncatedDivSimple>;
    }
    if (usePopup) {
        return (
            <TruncatedDivWithHover maxWidth={maxWidth} data-text={children}>
                {children}
            </TruncatedDivWithHover>
        );
    } else {
        return <TruncatedDivExpandable maxWidth={maxWidth}>{children}</TruncatedDivExpandable>;
    }
};
