import * as React from 'react';
import * as URLBuilder from '../../routes/url-builder';
import { ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { SchedulerPrintRouter, ServiceJobPrintRouter } from '@poolware/app-service-jobs';
import PrintPageLayout from '../../Layout/PrintPageLayout';
import PrintPageContainer from './common/PrintPageContainer';
import CustomerDetails from './CustomerDetails';
import PoolDetails from './PoolDetails';

const PrintURL = URLBuilder.Print();

export const Router = () => {
    return (
        <PrintPageLayout>
            <ModuleRoot>
                <PrintPageContainer>
                    <ModuleSwitch>
                        <ModuleRoute
                            path={`/${PrintURL.Customer().subPath}/${PrintURL.Customer().paramName}`}
                            component={CustomerDetails}
                        />
                        <ModuleRoute
                            path={`/${PrintURL.Pool().subPath}/${PrintURL.Pool().paramName}`}
                            component={PoolDetails}
                        />
                        <ModuleRoute path={`/scheduler`} component={SchedulerPrintRouter} />
                        <ModuleRoute path={`/service-jobs`} component={ServiceJobPrintRouter} />
                    </ModuleSwitch>
                </PrintPageContainer>
            </ModuleRoot>
        </PrintPageLayout>
    );
};
