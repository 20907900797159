import * as React from 'react';
import { useMemo } from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import { FeatureFlag, SidebarSecondaryMenuItemNavLink, useAppConfig, useTheme, useViewer } from '@poolware/app-shell';
import { routeConfig } from './route-config';
import { AppSidebarMenuItem, AppSidebarSecondary, AppSidebarConfig } from '@poolware/components';

export interface SidebarProps {}

export const Sidebar: React.FC<SidebarProps> = ({}) => {
    const { ThemeState } = useTheme();
    const { viewer, canAccessFeature } = useViewer();
    const appConfig = useAppConfig();

    const createItemConf = (input: {
        to: string;
        icon?: SemanticICONS;
        secondaryIcon?: SemanticICONS;
        label: string | React.ReactNode;
        flag?: FeatureFlag | string;
        sub?: boolean;
        exact?: boolean;
        as?: any;
    }): AppSidebarMenuItem => {
        const { exact, flag, icon = 'cogs', to, sub, label, secondaryIcon, as } = input;
        if (flag && !canAccessFeature(flag)) {
            return null;
        }
        return {
            to,
            icon,
            exact,
            label: label,
            secondaryIcon: secondaryIcon,
            as: as || SidebarSecondaryMenuItemNavLink,
            sub: sub,
        };
    };

    // @ts-ignore
    const config: AppSidebarConfig = useMemo(() => {
        const menus = routeConfig
            .filter((rc) => !!rc.menuItem)
            .map((rc) => {
                return createItemConf({
                    flag: rc.flag,
                    to: `/dev${rc.route.path}`,
                    icon: rc.menuItem.icon,
                    label: rc.menuItem.label,
                });
            });

        return {
            menu: [
                {
                    header: 'Dev Pages',
                    items: menus,
                },
                {
                    header: 'Other',
                    items: [
                        createItemConf({
                            to: '/r',
                            icon: 'cog',
                            label: 'Redirector',
                        }),
                    ],
                },
            ],
        };
    }, [appConfig, ThemeState, viewer, canAccessFeature]);

    return <AppSidebarSecondary config={config} />;
};
