import { WaterlinkAPI } from '../api-client';
import { useReducer } from 'react';
import { useInterval } from '@ez/tools';
import { WaterlinkServiceType } from './use-waterlink-service';
import * as _ from 'lodash';

export interface WaterTestDiskOptions {
    reagent: number;
    sample: number;
}

export type WaterTestResultType = {
    timestamp: Date;
    testId: string;
    options: WaterTestDiskOptions;
    data: WaterlinkAPI.WatertestReply | null;
};

export type ReducerStateType = {
    testId: string;
    isRunning: boolean;
    progress: number;
    lastResults: WaterTestResultType | null;
    error?: Error;
};

const initialState: ReducerStateType = {
    testId: null,
    isRunning: false,
    progress: 0,
    lastResults: null,
    error: undefined,
};

const TEST_START = 'TEST_START';
const TEST_PROGRESS = 'TEST_PROGRESS';
const TEST_FINISH = 'TEST_FINISH';
const TEST_FAILED = 'TEST_FAILED';

const fixResults = (results: WaterlinkAPI.WatertestResultEntry[]): WaterlinkAPI.WatertestResultEntry[] => {
    if (!results) {
        return results;
    }

    return results.map((r) => {
        // Work around for a Waterlink bug.
        // Waterlink occasionally returns FactorCode = 0 instead of 4 for combined Chlorine
        if (r.FactorCode === 0 && r.Name === 'Combined Cl') {
            r.FactorCode = 4;
        }
        return r;
    });
};

const useTestRunnerReducer = () => {
    function reducer(state: ReducerStateType, action) {
        switch (action.type) {
            case TEST_START: {
                const { testId } = action.value;
                return {
                    ...state,
                    isRunning: true,
                    progress: 0,
                    lastResults: null,
                    testId: testId,
                    error: undefined,
                };
            }
            case TEST_FINISH: {
                let value = action.value as WaterTestResultType;
                const FixedResults = fixResults(value?.data?.Results);
                _.set(value, 'data.Results', FixedResults);

                return {
                    ...state,
                    isRunning: false,
                    progress: 100,
                    lastResults: value,
                    error: undefined,
                };
            }
            case TEST_PROGRESS:
                return {
                    ...state,
                    progress: action.value,
                };
            case TEST_FAILED: {
                const { testId, error } = action.value;
                return {
                    ...state,
                    progress: 0,
                    isRunning: false,
                    lastResults: null,
                    testId: testId,
                    error: error,
                };
            }
            default:
                throw new Error('unknown  action' + action.type);
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    const dispatchSetProgress = (value) => dispatch({ type: TEST_PROGRESS, value: value });
    const dispatchWaterTestStart = (value: { testId: string }) =>
        dispatch({
            type: TEST_START,
            value: value,
        });
    const dispatchSetResults = (value: WaterTestResultType) =>
        dispatch({
            type: TEST_FINISH,
            value: value,
        });

    const dispatchWaterTestFailed = (value: { testId: string; error?: Error }) =>
        dispatch({
            type: TEST_FAILED,
            value: value,
        });

    return {
        state,
        dispatch,
        dispatchSetProgress,
        dispatchWaterTestStart,
        dispatchWaterTestFailed,
        dispatchSetResults,
    };
};

export const useWaterTestRunner = (waterlinkService: WaterlinkServiceType) => {
    const { state, dispatchSetProgress, dispatchSetResults, dispatchWaterTestStart, dispatchWaterTestFailed } =
        useTestRunnerReducer();

    const startWaterTest = async (options: WaterTestDiskOptions, testId?: string): Promise<WaterTestResultType> => {
        if (state.isRunning) {
            throw new Error('Test is already running');
        }

        testId = testId || String(Math.round(Math.random() * 10000));

        dispatchWaterTestStart({ testId });

        let data = null;
        try {
            data = await waterlinkService.performWaterTest(options);
        } catch (e) {
            console.error(e);
            dispatchWaterTestFailed({ testId, error: e });
            throw new Error(e);
        }

        if (!data || !data.Results) {
            // Something has failed
            const error = new Error('Waterlink did not return test results');
            dispatchWaterTestFailed({ testId, error: error });
            throw error;
        }

        const resultValue: WaterTestResultType = {
            timestamp: new Date(),
            testId,
            data,
            options,
        };

        dispatchSetResults(resultValue);

        return resultValue;
    };

    async function refreshStatus() {
        try {
            if (state.isRunning) {
                const device = await waterlinkService.getDeviceStatus();
                if (device && device.rawStatus) {
                    dispatchSetProgress(device.rawStatus.Progress);
                }
            } else {
                // setProgress(100);
            }
        } catch (e) {
            console.error(e);
            dispatchSetProgress(0);
        }
    }

    useInterval(refreshStatus, 1500);

    return {
        ...state,
        startWaterTest,
    };
};
