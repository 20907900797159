import { ModuleColorNames, ModuleIconNames } from '@poolware/app-service-jobs';
import { Icon, IconProps, Popup } from 'semantic-ui-react';
import * as React from 'react';

export const AppIconNames = {
    ServiceJob: ModuleIconNames,
    Pool: 'life ring' as const,
    Warranty: 'certificate' as const,
};
export const AppColorNames = {
    ServiceJob: ModuleColorNames,
};

export const IconServiceCall: React.FC<IconProps> = (props) => (
    <Icon {...props} name={AppIconNames.ServiceJob.ServiceCall} color={AppColorNames.ServiceJob.ServiceCall} />
);

export const IconServiceCallPlus: React.FC<IconProps> = (props) => (
    <Icon.Group style={{ marginRight: '.35714286em' }}>
        <IconServiceCall />
        <Icon corner name="add" color={'orange'} {...props} />
    </Icon.Group>
);

export const IconRecurServiceCall: React.FC<IconProps> = (props) => (
    <Icon
        {...props}
        name={AppIconNames.ServiceJob.RecurringService}
        color={AppColorNames.ServiceJob.RecurringService}
    />
);

export const IconRecurServiceCallPlus: React.FC<IconProps> = (props) => (
    <Icon.Group style={{ marginRight: '.35714286em' }}>
        <IconRecurServiceCall />
        <Icon corner name="add" color={'orange'} {...props} />
    </Icon.Group>
);

export const IconAppointment: React.FC<IconProps> = (props) => (
    <Icon name={'calendar alternate outline'} color={'green'} />
);
export const IconAppointmentPlus: React.FC<IconProps> = (props) => (
    <Icon.Group style={{ marginRight: '.35714286em' }}>
        <IconAppointment />
        <Icon corner name="add" color={'green'} {...props} />
    </Icon.Group>
);

export const StaffIcon: React.FC<{ active: boolean; manager?: boolean }> = ({ active, manager }) => {
    if (active) {
        const iconName = manager ? 'spy' : 'user';
        return <Icon name={iconName} />;
    } else {
        const iconName = 'user cancel';
        return <Popup content={'Deactivated account'} trigger={<Icon name={iconName} color={'orange'} />} />;
    }
};
