import * as React from 'react';
import { FieldArray, getIn } from 'formik';
import { Button, Header, Label, Message, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import { FormikInputField } from '@poolware/components';

const getArrayFieldError = (form, name: string, index: number) => {
    if (typeof form.errors[name] === 'string') {
        return null;
    }

    const fieldName = `${name}[${index}]`;
    const error = getIn(form.errors, fieldName);
    const touch = getIn(form.touched, fieldName);
    return touch && error ? error : null;
};

const SelectionTypeFormFieldComponent: React.FC<{ name: string }> = ({ name }) => {
    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                const errors = form.errors[name];
                const rootError = typeof errors === 'string' ? errors : null;
                const options = _.get(form.values, name);
                return (
                    <Segment basic clearing compact>
                        <Header sub>Options</Header>
                        {options.map((option, index) => {
                            const fieldName = `${name}[${index}].name`;
                            const fieldError = getArrayFieldError(form, name, index);
                            // console.log(" value", fieldName, option);
                            // console.log(" error", fieldName, fieldError);
                            return (
                                <FormikInputField
                                    labelPosition="right"
                                    action
                                    key={index}
                                    name={fieldName}
                                    type={'text'}
                                    error={fieldError}
                                >
                                    <Label>{index}</Label>
                                    <input />
                                    {index !== 0 && (
                                        <Button
                                            type="button"
                                            disabled={index === 0}
                                            basic
                                            onClick={() => arrayHelpers.move(index, index - 1)}
                                            icon={'chevron up'}
                                        />
                                    )}
                                    {index !== options.length - 1 && (
                                        <Button
                                            type="button"
                                            basic
                                            disabled={index === options.length - 1}
                                            onClick={() => arrayHelpers.move(index, index + 1)}
                                            icon={'chevron down'}
                                        />
                                    )}
                                    <Button
                                        type="button"
                                        basic
                                        onClick={() => arrayHelpers.remove(index)}
                                        icon={'trash'}
                                    />
                                </FormikInputField>
                            );
                        })}

                        {rootError && <Message error>{errors}</Message>}

                        <Button basic type="button" floated={'right'} onClick={() => arrayHelpers.push('')}>
                            Add Option
                        </Button>
                    </Segment>
                );
            }}
        />
    );
};

export default SelectionTypeFormFieldComponent;
