import * as Yup from 'yup';

export const SimpleString = Yup.string().max(200, 'Too Long!').nullable();

export const validationSchemaPhoneNumber = Yup.string()
    .min(4, 'Minimum 4 symbols')
    .max(32, 'Max 32 symbols')
    .trim()
    .matches(
        /^\s*[\d.\-+()*#]{3,32}\s*$/,
        "<span>Only Digits and <pre style='display: inline; font-size: 1.2em'> . - + * # </pre> symbols are allowed</span>"
    );

export const emailValidator = Yup.string().email('Not valid email').nullable();

export const calculatorValidationSchema = Yup.object({
    newCustomer: Yup.object().when('newCustomerMode', {
        is: true,
        then: Yup.object().shape({
            firstName: SimpleString.required('Required'),
            lastName: SimpleString.required('Required'),
            contactName: SimpleString.nullable().notRequired(),
            companyName: SimpleString.nullable().notRequired(),
            email: emailValidator.notRequired(),
            mobile: validationSchemaPhoneNumber.notRequired(),
        }),
        otherwise: Yup.object().notRequired().nullable(),
    }),
    customer: Yup.object().when('newCustomerMode', {
        is: false,
        then: Yup.object().required('Required'),
        otherwise: Yup.object().notRequired().nullable(),
    }),
});
