import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { useBookingActions } from '../../redux';

export function useBookAppointmentForWorkOrder(input: { workOrder?: NodeType.WorkOrder }) {
    const { workOrder: initialWorkOrder } = input;
    const nav = useAppNavigator();
    const { BookingAction } = useBookingActions();

    const bookFn = (workOrder: NodeType.WorkOrder = initialWorkOrder) => {
        if (!workOrder) {
            throw new Error('Work order is not provided');
        }
        const customer = workOrder?.customer;
        const address = workOrder?.address;
        const staff = workOrder?.assignedTo;
        const pool = workOrder?.pool;

        BookingAction.startFromServiceJob({
            details: {
                workOrder,
                customer,
                pool,
                address,
                staff,
            },
            returnTo: nav.location.pathname,
        });
    };

    return bookFn;
}
