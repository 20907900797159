import { CostEstimationModel, CostEstimationMonthly, MonthlyHeatGasProfile, ResourcePriceUnit } from '../common/types';
import { GasHeaterModel } from '../data';
import * as _ from 'lodash';

interface CostEstimationGasHeaterInputProps {
    gasHeatProfile: MonthlyHeatGasProfile[];
    operatingHours: number;
    withCover: boolean;
    gasCost: number;
    selectedModel: GasHeaterModel;
}

export const computeCostEstimationGasHeater = (
    input: CostEstimationGasHeaterInputProps
): CostEstimationModel<GasHeaterModel> => {
    const { operatingHours, gasHeatProfile, gasCost, selectedModel } = input;
    if (!selectedModel) {
        return null;
    }
    const costArr: CostEstimationMonthly[] = [];

    for (let heatProfile of gasHeatProfile) {
        const isSelected = heatProfile.isSelected;
        const monthProfile = heatProfile.monthProfile;

        let costToHeatUp = 0;
        let monthlyRunningCost = 0;
        let dailyRunningCost = 0;

        if (isSelected) {
            const gasCostDollar = gasCost / 100;
            costToHeatUp = heatProfile.heatUpMJ * gasCostDollar;
            dailyRunningCost = heatProfile.heatRunningMJ * gasCostDollar;
            monthlyRunningCost = monthProfile?.days * dailyRunningCost;
        }

        costArr.push({
            monthId: heatProfile.monthProfile.id,
            costToHeatUp,
            costRunningMonthly: monthlyRunningCost,
            costRunningDaily: dailyRunningCost,
        });
    }

    const totalCost = costArr.reduce((acc, i) => i.costRunningMonthly + acc, 0);

    const max_value = _.maxBy(gasHeatProfile, (p) => p.heatUpMJ);
    //calculate minimum output
    let minimumOutput = Math.round(max_value?.heatUpMJ / max_value?.heatUpTimeHr);

    //calculate quantity
    let quantity = 0;
    if (minimumOutput > selectedModel.capacity) {
        quantity = Math.ceil(minimumOutput / selectedModel.capacity);
    } else {
        if (minimumOutput > 0) {
            quantity = 1;
        } else {
            quantity = 0;
        }
    }

    return {
        minimumOutput,
        operatingHours,
        numberOfUnits: quantity,
        costByMonth: costArr,
        costYearly: totalCost,
        recommendedModel: selectedModel,
        resourcePriceUnit: ResourcePriceUnit.CentsPerMJ,
        resourcePrice: gasCost,
    };
};
