import { MonthlyHeatGasProfile } from '../common/types';
import { GasHeaterModel } from '../data';
import * as _ from 'lodash';

interface RecommendedGasHeaterModelInputProps {
    monthlyHeatProfiles: MonthlyHeatGasProfile[];
    // operatingHours: number;
    heaterCatalog: GasHeaterModel[];
}

export function computeRecommendedGasHeaterModel(input: RecommendedGasHeaterModelInputProps) {
    let { monthlyHeatProfiles } = input;

    if (
        !input.heaterCatalog ||
        input.heaterCatalog.length === 0 ||
        !monthlyHeatProfiles ||
        monthlyHeatProfiles?.length === 0
    ) {
        return {
            recommendedModels: [],
            recommendedModel: null,
            minimumOutput: 0,
            quantity: 0,
        };
    }

    const capacitySorted = input.heaterCatalog.sort(function (a, b) {
        if (a.capacity > b.capacity) return 1;
        if (a.capacity < b.capacity) return -1;
        return 0;
    });

    const highestCapacityHeater = capacitySorted[capacitySorted.length - 1];
    const highestCapacity = highestCapacityHeater.capacity;

    const recommendedModels: GasHeaterModel[] = [];

    for (let heatProfile of monthlyHeatProfiles) {
        const isSelected = heatProfile.isSelected;
        const monthProfile = heatProfile.monthProfile;

        let recommendedModel: GasHeaterModel | undefined = undefined;
        // let costToHeatUp = 0;
        // let dailyRunningCost = 0;

        if (isSelected) {
            const capacityRequired = heatProfile.heatUpMJ / heatProfile?.heatUpTimeHr;
            if (capacityRequired > highestCapacity) {
                recommendedModel = undefined;
            } else {
                if (capacityRequired === 0) {
                    recommendedModel = undefined;
                } else {
                    recommendedModel = capacitySorted.find(function (item) {
                        return item.capacity > capacityRequired;
                    });

                    if (!recommendedModel) {
                        recommendedModel = highestCapacityHeater;
                    }
                }
            }
            // costToHeatUp = (heatProfile.heatUp * input.operatingHours * input.gasCost) / 100;
            // dailyRunningCost = (heatProfile.heatSurfaceLost * input.gasCost) / 100;
        }

        recommendedModels.push(recommendedModel);
    }

    const max_value = _.maxBy(monthlyHeatProfiles, (p) => p?.minimumOutputMJ);

    //calculate minimum output
    let minimumOutput = max_value?.minimumOutputMJ; // Math.round(max_value?.heatUpMJ / max_value?.heatUpTimeHr);

    let recommendedHeater: GasHeaterModel | undefined = undefined;

    //calculate quantity
    let quantity = 0;
    if (minimumOutput > highestCapacity) {
        quantity = minimumOutput / highestCapacity;
        recommendedHeater = highestCapacityHeater;
    } else {
        if (minimumOutput > 0) {
            recommendedHeater = recommendedModels
                .filter((m) => !!m)
                .sort((a, b) => (a.capacity < b.capacity ? -1 : 1))
                .find((m) => {
                    return m.capacity >= minimumOutput;
                });
            quantity = 1;
        } else {
            quantity = 0;
        }
    }

    return {
        recommendedModels,
        recommendedModel: recommendedHeater,
        minimumOutput,
        quantity,
    };
}

export type GasHeaterRecommendedModelResultType = ReturnType<typeof computeRecommendedGasHeaterModel>;
