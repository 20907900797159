import * as React from 'react';
import { AppointmentReportsActionProps, withAppointmentReportsActions } from '../../../redux';
import { NodeType, QueryStaffConnection } from '@poolware/api';
import { ActionBar, stringFormatters } from '@poolware/components';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '@poolware/app-service-jobs';

const { formatEntityName } = stringFormatters;

export const StaffFilter: React.FC<{ setStaffFilter: ({ includeIds }) => void; staffIds; staffList }> = ({
    setStaffFilter,
    staffIds,
    staffList,
}) => {
    const onFilterChange = (staffId: NodeType.ID, checked: boolean) => {
        // Check whether the staff is in the inclusion list
        const isInFilterArray = staffIds.findIndex((id) => id === staffId);

        if (checked) {
            // if staff is checked, then add it to exclusion list.
            if (isInFilterArray < 0) {
                // it is not in the list, adding it.
                const newFilters = [...staffIds, staffId];
                setStaffFilter({ includeIds: newFilters });
            }
        } else {
            // if staff is unchecked, then remove it from inclusion list.
            if (isInFilterArray >= 0) {
                // it is already in the list, removing it
                let a = [...staffIds];
                a.splice(isInFilterArray, 1);
                setStaffFilter({ includeIds: a });
            }
        }
    };

    const onShowAll = (checked: boolean) => {
        if (checked) {
            setStaffFilter({ includeIds: [] });
        }
    };

    return (
        <ActionBar>
            <ActionBar.Header content={'Filter by Staff'} icon={'filter'} />
            <ActionBar.ItemCheckbox
                dividing={true}
                checked={staffIds.length === 0}
                onChange={(checked) => onShowAll(checked)}
                content={'All'}
            />
            <ActionBar.Scroll>
                <ActionBar.ItemCheckbox
                    checked={staffIds.includes(UNASSIGNED_APPOINTMENT_STAFF_ID)}
                    onChange={(checked) => onFilterChange(UNASSIGNED_APPOINTMENT_STAFF_ID, checked)}
                    content={'Unassigned'}
                    icon="user outline"
                    labelStyle={{ fontStyle: 'italic' }}
                />

                {staffList.map((staff) => {
                    return (
                        <ActionBar.ItemCheckbox
                            key={staff.id}
                            checked={staffIds.includes(staff.id)}
                            onChange={(checked) => onFilterChange(staff.id, checked)}
                            icon={'user'}
                            content={formatEntityName(staff)}
                        />
                    );
                })}
            </ActionBar.Scroll>
        </ActionBar>
    );
};

const StaffFilterWrapper: React.FC<AppointmentReportsActionProps> = (props) => {
    const { staffIds = [], franchise } = props.AppointmentReportsState.filters;

    return (
        <QueryStaffConnection franchiseId={franchise.id} staffIds={staffIds} showDeleted={false} showDisabled={true}>
            {({ connectionData: staffList }) => {
                return (
                    <StaffFilter
                        staffList={staffList}
                        staffIds={staffIds}
                        setStaffFilter={({ includeIds }) => props.AppointmentReportsAction.setStaffFilter(includeIds)}
                    />
                );
            }}
        </QueryStaffConnection>
    );
};

export default withAppointmentReportsActions()(StaffFilterWrapper);
