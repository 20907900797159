import { all, fork } from 'redux-saga/effects';

import watch1 from './appointment-booking-from-customer-page';
import watch4 from './appointment-booking-from-pool-page';
import watchZendesk from './zendesk';
import {
    createWatchAppointmentBookingDuplicate,
    createWatchAppointmentBookingFromCalendar,
    createWatchAppointmentBookingFromDropFromOutside,
    createWatchAppointmentBookingFromServiceJob,
    createWatchAppointmentBookingReschedule,
    createWatchNewServiceJob,
    createWatchServiceJobRecurActions,
    createWatchShowAppointmentInCalendar,
} from '@poolware/app-service-jobs';
import { createWatchBrowserNavigate } from '@poolware/app-shell';
import * as URLBuilder from './../../routes/url-builder';

import { createWatchHeatCalcSaga } from '@poolware/app-calculators';
import { createWarrantyBookingSaga } from '@poolware/app-warranty-claims';

export const createRootSaga = (store) => {
    const { watchBrowserNavigate } = createWatchBrowserNavigate(store);
    const watch2 = createWatchAppointmentBookingFromCalendar(store, URLBuilder);
    const watch3 = createWatchAppointmentBookingReschedule(store, URLBuilder);
    const watch5 = createWatchAppointmentBookingDuplicate(store, URLBuilder);
    const watch6 = createWatchNewServiceJob(store, URLBuilder);
    const watch7 = createWatchServiceJobRecurActions(store, URLBuilder);
    const watch8 = createWatchShowAppointmentInCalendar(store, URLBuilder);
    const watch9 = createWatchAppointmentBookingFromServiceJob(store, URLBuilder);
    const watch10 = createWatchAppointmentBookingFromDropFromOutside(store, URLBuilder);
    const watch11 = createWatchHeatCalcSaga(store);
    const watch12 = createWarrantyBookingSaga(store, URLBuilder);
    // const watch10 = createWatchAppointmentSlotSelectForRecurService(store, URLBuilder);

    return function* root() {
        yield all([
            fork(watchBrowserNavigate),
            fork(watch1),
            fork(watch2),
            fork(watch3),
            fork(watch4),
            fork(watch5),
            fork(watch6),
            fork(watch7),
            fork(watch8),
            fork(watch9),
            fork(watch10),
            fork(watch11),
            fork(watch12),
            fork(watchZendesk),
        ]);
    };
};
