import * as React from 'react';
import { useState } from 'react';
import { IconWithPopup, LinkButton, toastError } from '@poolware/components';
import { getApiClient } from '@poolware/api';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Button, Icon, List } from 'semantic-ui-react';
import { isMobileDevice, truncateMiddle, wait } from '@ez/tools';
import * as _ from 'lodash';
import { useViewer } from '../Viewer';

const apiClient = getApiClient();

const MAX_FILE_SIZE = 20 * 1024 * 1024;

function humanFileSize(bytes, si?: boolean): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const DnDContainer = styled.div`
    //flex: 1;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //position: relative;
    padding: 0;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${(props) => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #7b7b7b;
    outline: none;
    transition: border 0.24s ease-in-out;
`;

const FileListContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    outline: none;

    & > .dnd-file-list {
        min-width: 280px;
        padding: 1em 0 2em;
    }
`;

const PaddedSpan = styled.span`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
`;

enum FileStatus {
    PENDING,
    UPLOADING,
    UPLOADED,
    FAILED,
}

type FileHandle = {
    tempId: string;
    status: FileStatus;
    error?: string;
    file: any;
};
const randomID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

export interface FileAttachmentsPanelProps {
    onFileUploadComplete: (fileId: string) => Promise<any>;
    onDone: () => any;
    dzOptions?: DropzoneOptions;
}

export const FileAttachmentUploader: React.FC<FileAttachmentsPanelProps> = ({
    onFileUploadComplete,
    onDone,
    dzOptions,
}) => {
    const _isMobileDevice = isMobileDevice();
    const [isUploading, setUploading] = useState(false);
    const [uploadDone, setUploadDone] = useState(false);

    const [files, setFiles] = useState<FileHandle[]>([
        // { tempId: '23', status: FileStatus.UPLOADED, file: { name: 'Test1-Test1-Test1-Test1-', size: 3444 } },
        // { tempId: '22', status: FileStatus.FAILED, file: { name: 'Test3', size: 12000000 } },
        // { tempId: '24', status: FileStatus.PENDING, file: { name: 'Test3', size: 123 } },
        // { tempId: '25', status: FileStatus.UPLOADING, file: { name: 'Test3', size: 123 } },
    ]);

    const onDropAccepted = (acceptedFiles: any[]) => {
        const newFiles = acceptedFiles.map<FileHandle>((file) => ({
            tempId: randomID(),
            status: FileStatus.PENDING,
            file: file,
        }));
        setFiles([...files, ...newFiles]);
    };

    const onDropRejected = (rejectedFiles: any[]) => {
        // console.log(rejectedFiles);
        const errors = (
            <ul>
                {rejectedFiles?.map((f, index) => {
                    return (
                        <li key={index}>
                            {f?.file?.name} - <b>{f?.errors?.map((e) => e.message).join('. ')}</b>
                        </li>
                    );
                })}
            </ul>
        );
        // @ts-ignore
        toastError({ title: 'Invalid file', description: errors, time: 15000 });
    };

    const { open, getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDropAccepted,
        onDropRejected,
        maxSize: MAX_FILE_SIZE,
        ...dzOptions,
        disabled: isUploading,
    });

    const patchFile = (tempId, delta: Partial<FileHandle>) => {
        setFiles((files) => files.map((f) => (f.tempId === tempId ? { ...f, ...delta } : f)));
    };

    const removeFile = (tempId) => {
        setFiles((files) => files.filter((f) => f.tempId !== tempId));
    };

    const startUpload = async () => {
        setUploading(true);
        // await wait(1000);
        // setUploadDone(true);
        // setTimeout(onDone, 1000);
        // return;

        for (const file of files) {
            patchFile(file.tempId, { status: FileStatus.UPLOADING });
            // await wait(1000);
            try {
                const result = await apiClient.uploadFile(file.file);
                // console.log(result);
                await onFileUploadComplete(result.id);
                patchFile(file.tempId, { status: FileStatus.UPLOADED });
                // setTimeout(onDone, 1000);
            } catch (e) {
                console.error(e);
                patchFile(file.tempId, { status: FileStatus.FAILED, error: e.message });
            }
        }
        setUploadDone(true);
        setUploading(false);
    };

    return (
        <div>
            <div tw={'flex flex-col mx-auto w-full max-w-screen-sm pb-4'}>
                {files.length > 0 && (
                    <div tw={'px-0 py-4'}>
                        <List>
                            {files.map((fhandle) => {
                                const { file, status, error, tempId } = fhandle;
                                return (
                                    <List.Item key={tempId}>
                                        <List.Icon name="file" />
                                        <List.Content>
                                            <span tw={'text-base'}>
                                                {truncateMiddle(file.name, 16, 4)} - {humanFileSize(file.size)}
                                            </span>
                                            <span tw={'px-1'}>
                                                {status === FileStatus.UPLOADING && (
                                                    <Icon name={'spinner'} loading={true} />
                                                )}
                                                {status === FileStatus.UPLOADED && (
                                                    <Icon name={'checkmark'} color={'green'} />
                                                )}
                                            </span>

                                            {status === FileStatus.FAILED && (
                                                <span tw={'px-1'}>
                                                    <IconWithPopup
                                                        popup={{ content: error }}
                                                        name={'warning sign'}
                                                        color={'red'}
                                                    />{' '}
                                                    Failed to upload
                                                </span>
                                            )}

                                            {(status === FileStatus.PENDING || status === FileStatus.FAILED) && (
                                                <PaddedSpan>
                                                    <LinkButton onClick={() => removeFile(tempId)}>(remove)</LinkButton>
                                                </PaddedSpan>
                                            )}
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </div>
                )}
            </div>

            {!uploadDone && !isUploading && (
                <DnDContainer {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
                    <div tw={'p-2 text-sm text-gray-400'}>Add files</div>
                    <div tw={'p-8 pt-6'}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the files here ...</p>
                        ) : (
                            <>
                                <div tw={'text-center'}>
                                    <div>
                                        {!_isMobileDevice && <>Drag 'n' drop files here or </>}
                                        <LinkButton disabled={isUploading} onClick={() => open()}>
                                            Open File Dialog
                                        </LinkButton>
                                    </div>
                                    <div tw={'text-sm pt-2'}>Max size per file {humanFileSize(MAX_FILE_SIZE)}</div>
                                </div>
                            </>
                        )}
                    </div>
                </DnDContainer>
            )}

            <div tw={'pt-4 flex flex-col mx-auto w-full max-w-screen-sm'}>
                <div tw={'mx-auto pt-4'}>
                    {files.length > 0 && !uploadDone && (
                        <Button
                            size={'small'}
                            basic={true}
                            color={'blue'}
                            disabled={isUploading}
                            loading={isUploading}
                            onClick={startUpload}
                            content={'Start Upload'}
                        />
                    )}

                    {uploadDone && (
                        <Button
                            size={'small'}
                            icon={'check circle outline'}
                            basic={true}
                            content={'Done'}
                            color={'green'}
                            onClick={onDone}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
