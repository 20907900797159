import * as React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { ScrollY } from '@poolware/components';

export const SelectPanel22: React.FC<{ options; onSelect; value }> = ({ options, onSelect, value }) => {
    const handleOnChange = (e) => {
        const value = e.target.value;
        onSelect(value);
    };

    return (
        <select size={6} onClick={handleOnChange}>
            {options.map((rt, index) => {
                return (
                    <option key={rt.key || index} value={rt.value} selected={rt.value === value}>
                        {rt.text}
                    </option>
                );
            })}
        </select>
    );
};

export const SelectPanel: React.FC<{ options; onSelect; value }> = ({ options, onSelect, value }) => {
    const handleOnChange = (e, { value }) => {
        onSelect(value);
    };

    return (
        <Dropdown
            selection
            item
            selectOnBlur={false}
            selectOnNavigation={false}
            options={options}
            value={value}
            onChange={handleOnChange}
        />
    );
};

export const SelectPanel12: React.FC<{ options; onSelect; value }> = ({ options, onSelect, value }) => {
    const handleOnChange = (e) => {
        const value = e.target.value;
        onSelect(value);
    };

    return (
        <ScrollY maxHeight={240}>
            <Menu vertical fluid size={'tiny'}>
                {options.map((rt, index) => {
                    const isActive = rt.value === value;
                    return (
                        <Menu.Item
                            name={rt.text}
                            color={isActive ? 'blue' : undefined}
                            active={isActive}
                            onClick={() => handleOnChange({ target: { value: rt.value } })}
                        />
                        // <option key={index} value={rt.value} selected={rt.value === value}>
                        //     {rt.text}
                        // </option>
                    );
                })}
            </Menu>
        </ScrollY>
    );
};
