import { QueryHookOptions, useQuery } from '@apollo/react-hooks';
import { OperationVariables } from '@apollo/react-common';
import { DocumentNode } from 'graphql';
import { NodeType } from '../api-types';

export function createUseQueryNodeHook<TData = any, TVariables = OperationVariables>(query: DocumentNode) {
    return (id: NodeType.ID, options?: QueryHookOptions<TData, TVariables>) => {
        const variables = {
            id: id,
        };

        //
        // Apollo hook ignores options.query.
        // see https://github.com/apollographql/apollo-client/issues/7194
        //

        const q = options?.query || query;

        const { loading, error, data, ...rest } = useQuery<any>(q, {
            variables: variables,
            ...options,
        });

        if (error) {
            console.error(error);
        }

        const node: TData = data?.node;

        const refetchQuery = {
            query: options?.query || query,
            variables: variables,
        };

        return {
            error,
            loading,
            node,
            data,
            refetchQuery,
            ...rest,
        };
    };
}
