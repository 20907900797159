import { connect } from 'formik';
import {
    getCodeFactorForTag,
    getNormalizedResultValue,
    useWaterlink,
    WaterTestPanel,
    WaterTestResultType,
} from '@poolware/waterlink';
import { Segment } from 'semantic-ui-react';
import { Panel } from '@poolware/components';
import * as React from 'react';
import { InputFieldsConfType } from './preapare-input-conf';

// import { WaterTestPanel } from '../../../Waterlink/WaterlinkTestPanel';

export interface WaterlinkPanelProps {
    inputFieldsConf: InputFieldsConfType;
    onTestFinish: () => any;
}

export const WaterlinkPanel = connect<WaterlinkPanelProps>(({ formik, inputFieldsConf, onTestFinish }) => {
    const waterlink = useWaterlink();
    const isServerOnline = waterlink.server.online;
    const isDeviceOnline = waterlink.device.online;

    if (!isServerOnline) {
        return null;
    }

    if (!isDeviceOnline) {
        return <Segment tertiary>Waterlink device is offline</Segment>;
    }

    const handleOnTestFinish = (testResult: WaterTestResultType) => {
        const { data } = testResult;
        const Results = data && data.Results && data.Results.length > 0 ? data.Results : undefined;
        if (!Results) {
            return;
        }
        const findResultByTagId = (tagId: string) => {
            // tagID is a string token associated with a MeasurementType.

            const factorCode = getCodeFactorForTag(tagId);
            if (factorCode === undefined) {
                return undefined;
            }

            const result = Results.find((r) => factorCode.includes(r.FactorCode));
            if (!result) {
                return undefined;
            }

            return getNormalizedResultValue(result);
        };

        /*
            Exception case.
            If both CALCIUM_HARDNESS and CALCIUM_HARDNESS_SALT fields are present,
            need to ignore  CALCIUM_HARDNESS and only populate CALCIUM_HARDNESS_SALT.
         */
        let calcHardness = undefined;
        let calcHardnessSalt = undefined;

        inputFieldsConf.forEach((ic) => {
            const value = findResultByTagId(ic.tagIdentifier);
            if (value === undefined) return;

            // == exception == //
            if (ic.tagIdentifier === 'CALCIUM_HARDNESS') {
                calcHardness = { fieldName: ic.fieldName, value };
                return;
            } else if (ic.tagIdentifier === 'CALCIUM_HARDNESS_SALT') {
                calcHardnessSalt = { fieldName: ic.fieldName, value };
                return;
            }
            // =============== //

            formik.setFieldValue(ic.fieldName, value);
        });

        // == Exception  == //
        if (calcHardnessSalt) {
            // ignore CH, set CHSalt field
            formik.setFieldValue(calcHardnessSalt.fieldName, calcHardnessSalt.value);
        } else if (calcHardness) {
            formik.setFieldValue(calcHardness.fieldName, calcHardness.value);
        }
        // ================ //

        onTestFinish();
    };

    return (
        <Panel>
            <Panel.Header icon={'lab'}>Waterlink</Panel.Header>
            <Panel.Body>
                <WaterTestPanel onTestFinish={handleOnTestFinish} />
            </Panel.Body>
        </Panel>
    );
});
