import * as React from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { usePersistedString } from '@ez/tools';
import { useAppConfig } from '../app-config';

export type OrgSwitcherContextType = {
    organisation?: NodeType.OrganisationType | null;
    orgs: NodeType.OrganisationType[];
    setOrg: (org: NodeType.NodeOrId<NodeType.OrganisationType>) => any;
    loading: boolean;
};

export const OrgSwitcherContext = React.createContext<OrgSwitcherContextType>({
    organisation: null,
    orgs: [],
    setOrg: () => {
        throw Error('Uninitialised');
    },
    loading: false,
});

export const useOrgSwitcher = () => useContext(OrgSwitcherContext);

export interface OrgProviderInjectedProps {
    orgSwitcher: OrgSwitcherContextType;
}

export const withOrgSwitcher =
    () =>
    (C: React.ElementType<OrgProviderInjectedProps>): React.FC =>
    (props) => {
        const contextType = useOrgSwitcher();
        return <C orgSwitcher={contextType} {...props} />;
    };

const OrganisationListQuery = gql`
    query OrganisationListQuery {
        viewer {
            organisationTypes {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const OrgSwitcherProvider: React.FC = (props) => {
    const [currentOrgId, setCurrentOrgId] = usePersistedString('system.default.org.id', null);
    const [allOrgs, setAllOrgs] = useState<NodeType.OrganisationType[]>([]);
    const config = useAppConfig();
    const { data, error, loading } = useQuery(OrganisationListQuery, { fetchPolicy: 'cache-first' });

    const disabledOrgs = config?.disabledOrgs || [];
    useEffect(() => {
        if (loading || error) {
            return;
        }
        let fetchedOrgs = fromEdges<any>(data?.viewer?.organisationTypes);
        if (disabledOrgs.length > 0) {
            fetchedOrgs = fetchedOrgs.filter((org) => !disabledOrgs.includes(org.id));
        }
        setAllOrgs(fetchedOrgs);
    }, [loading, data, error]);

    const setOrg = (org: NodeType.NodeOrId<NodeType.OrganisationType>) => {
        if (!org) {
            setCurrentOrgId(null);
        } else {
            setCurrentOrgId(NodeType.extractId(org));
        }
    };

    const organisation = useMemo(() => {
        return allOrgs.find((o) => o.id === currentOrgId);
    }, [allOrgs, currentOrgId]);

    const value: OrgSwitcherContextType = {
        organisation: organisation,
        orgs: allOrgs,
        setOrg,
        loading,
    };
    return <OrgSwitcherContext.Provider value={value} {...props} />;
};
