import * as React from 'react';
import { FormikDefaultForm, FormikInputField } from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationCustomerTag } from '@poolware/api';

export interface PageProps {}

export const PageNew: React.FC<PageProps> = () => {
    const { AppNavigator } = useAppNavigator<{ id?: string }>();
    const { create } = useMutationCustomerTag({ refetchQueries: ['QueryCustomerTagsConnection'] });

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onSubmit = async (values) => {
        await create({ name: values.name });
        goBack();
    };

    return (
        <FormikDefaultForm
            header={'New Customer Tag'}
            icon={'tag'}
            initialValues={{ name: '' }}
            onSubmit={onSubmit}
            onCancel={goBack}
        >
            <FormikInputField name={'name'} label={'Tag Name'} />
        </FormikDefaultForm>
    );
};
