import * as React from 'react';

import * as URLBuilder from '../../routes/url-builder';
import { Franchises } from './index';
import * as Staff from '../Staff';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { PageContainer } from '../../Layout/PageContainer';
import { NotFoundPage } from '@poolware/app-shell';

const HQAdmin = URLBuilder.HQAdmin();

const franchiseListRoutes = () => {
    return (
        <PageContainer>
            <ModuleRootSwitch>
                <ModuleRoute path="/new" component={Franchises.New} />
                <ModuleRoute path="/:franchiseId/edit" component={Franchises.Edit} />
                <ModuleRoute path="/:franchiseId/franchise-shop/new" component={Franchises.NewFranchiseShop} />
                <ModuleRoute
                    path="/:franchiseId/franchise-shop/:shopId/edit"
                    component={Franchises.EditFranchiseShop}
                />
                <ModuleRoute path="/:franchiseId/staff/new" component={Staff.New} />
                <ModuleRoute path="/:franchiseId/staff/:staffId" component={Staff.View} />
                <ModuleRoute path="/:franchiseId/staff/:staffId/edit" component={Staff.Edit} />
                <ModuleRoute path="/:franchiseId" component={Franchises.ViewFranchise} />
            </ModuleRootSwitch>
        </PageContainer>
    );
};

export const Router = () => {
    return (
        <ModuleRootSwitch notFound={NotFoundPage}>
            <ModuleRoute path={HQAdmin.Franchise().subPath} exact component={Franchises.List} />
            <ModuleRoute path={HQAdmin.Franchise().subPath} component={franchiseListRoutes} />
        </ModuleRootSwitch>
    );
};
