import { compose, withProps } from '@ez/tools';
import {
    convertToConfigValue,
    IMutateBrand,
    IMutateProduct,
    IMutateProductCompany,
    IMutateProductDeclarationSelectionField,
    IMutateProductTrait,
    IMutateProductTraitDeclaration,
    NodeType,
    useMutationBrand,
    useMutationProduct,
    useMutationProductCompany,
    useMutationProductDeclarationSelectionField,
    useMutationProductTrait,
    useMutationProductTraitDeclaration,
    withBrandMutator,
    withProductCompanyMutator,
    withProductDeclarationSelectionFieldMutator,
    withProductMutator,
    withProductTraitDeclarationMutator,
    withProductTraitMutator,
} from '@poolware/api';
import * as _ from 'lodash';
import { MutationHookOptions } from '@apollo/react-hooks';

export enum TraitValueType {
    Int = 'Int',
    Float = 'Float',
    String = 'String',
    Selection = 'Selection',
    Flag = 'Flag',
}

export interface TraitValue {
    type: TraitValueType;
}

export interface TraitValue_DeleteInput extends TraitValue {
    valueId: NodeType.ID;
}

export interface TraitValue_CreateInput extends TraitValue {
    value: any;
    fieldId: NodeType.ID;
}

export interface TraitValue_UpdateInput extends TraitValue {
    valueId: NodeType.ID;
    value: any;
}

export interface Product_CreateInput {
    name: string;
    organisationType: NodeType.NodeOrId<NodeType.OrganisationType>;
    franchise?: NodeType.NodeOrId<NodeType.Franchise>;
    description?: string;
    identification?: string;
    sku?: string;
    customSku?: string;
    familyCode?: string;
    isOneOff?: boolean;
    isGrouping?: boolean;
    brand?: NodeType.NodeOrId<NodeType.Brand>;
    supplier?: NodeType.NodeOrId<NodeType.ProductCompany>;
    traits: [
        {
            id: NodeType.ID;
        }
    ];
}

export interface Product_UpdateInput {
    product: NodeType.NodeOrId<NodeType.Product>;
    name?: string;
    description?: string;
    identification?: string;
    sku?: string;
    customSku?: string;
    familyCode?: string;
    brand?: NodeType.NodeOrId<NodeType.Brand>;
    supplier?: NodeType.NodeOrId<NodeType.ProductCompany>;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
    franchise?: NodeType.NodeOrId<NodeType.Franchise>;
}

export interface Brand_UpdateInput {
    name?: string;
    identification?: string;
    franchise?: NodeType.NodeOrId<NodeType.Franchise>;
    company?: NodeType.NodeOrId<NodeType.ProductCompany>;
}

export interface ProductCompany_UpdateInput {
    name?: string;
    description?: string;
    franchise?: NodeType.NodeOrId<NodeType.Franchise>;
    parentCompany?: NodeType.NodeOrId<NodeType.ProductCompany>;
}

export interface ProductTraitDeclaration_CreateInput {
    name: string;
    tag?: string;
    isCategory?: boolean;
    organisationType: NodeType.ID;
    franchise?: NodeType.NodeOrId<NodeType.Franchise>;
    parent?: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>;
    // integerFields: [CreateProductTraitDeclarationListProductDeclarationIntegerFieldInput]
    // floatFields: [CreateProductTraitDeclarationListProductDeclarationFloatFieldInput]
    // stringFields: [CreateProductTraitDeclarationListProductDeclarationStringFieldInput]
    // measurementFields: [CreateProductTraitDeclarationListProductDeclarationMeasurementFieldInput]
    // unitFields: [CreateProductTraitDeclarationListProductDeclarationUnitFieldInput]
    // selectionFields: [CreateProductTraitDeclarationListProductDeclarationSelectionFieldInput]
    // relatedSanitisers: CreateProductTraitDeclarationListSanitiser
}

interface MutatorsInjected
    extends IMutateProduct,
        IMutateProductCompany,
        IMutateProductDeclarationSelectionField,
        IMutateProductTrait,
        IMutateBrand,
        IMutateProductTraitDeclaration {}

const createMutator = ({
    mutateProduct,
    mutateProductDeclarationSelectionField,
    mutateProductCompany,
    mutateBrand,
    mutateProductTrait,
    mutateProductTraitDeclaration,
}: MutatorsInjected) => {
    const createProducts = async (inputs: Product_CreateInput[]): Promise<NodeType.ID[]> => {
        const createSingleMutationConfig = (input: Product_CreateInput) => {
            const conf: NodeType.CreateProductMutationInput = {
                name: _.trim(input.name),
                organisationType: NodeType.extractId(input.organisationType),
                franchise: input.franchise ? NodeType.extractId(input.franchise) : null,
                description: input.description ? _.trim(input.description) : undefined,
                identification: input.identification ? _.trim(input.identification) : undefined,
                sku: input.sku ? _.trim(input.sku) : undefined,
                customSku: input.customSku ? _.trim(input.customSku) : undefined,
                familyCode: input.familyCode ? _.trim(input.familyCode) : undefined,
                isOneOff: input.isOneOff,
                isGrouping: input.isGrouping,
                brand: input.brand ? { id: NodeType.extractId(input.brand) } : undefined,
                supplier: input.supplier ? { id: NodeType.extractId(input.supplier) } : undefined,
            };

            if (input.traits) {
                conf.traits = input.traits
                    .filter((it) => !!it)
                    .map((inputTrait) => {
                        return {
                            declaration: inputTrait.id,
                        };
                    });
            }

            return conf;
        };

        const res = await mutateProduct.create(inputs.map(createSingleMutationConfig));
        const results = _.get(res, 'data.Product.results') || [];
        const productIds = results.map((r) => _.get(r, 'Product.id'));
        return productIds;
    };

    const updateProducts = async (inputs: Product_UpdateInput[]) => {
        const createSingleMutationConfig = (input) => {
            const conf: NodeType.UpdateProductMutationInput = {
                id: NodeType.extractId(input.product),
                name: input.name !== undefined ? _.trim(input.name) : undefined,
                description: input.description !== undefined ? _.trim(input.description) : undefined,
                identification: input.identification !== undefined ? _.trim(input.identification) : undefined,
                sku: input.sku !== undefined ? _.trim(input.sku) : undefined,
                customSku: input.customSku !== undefined ? _.trim(input.customSku) : undefined,
                familyCode: input.familyCode !== undefined ? _.trim(input.familyCode) : undefined,
                franchise: convertToConfigValue({ values: input, key: 'franchise', isSourceNode: true }),
                brand: convertToConfigValue({ values: input, key: 'brand', isSourceNode: true, isSwitch: true }),
                supplier: convertToConfigValue({ values: input, key: 'supplier', isSourceNode: true, isSwitch: true }),
            };
            return conf;
        };

        return mutateProduct.update(inputs.map(createSingleMutationConfig));
    };

    const deleteProducts = async (products: NodeType.NodeOrId<NodeType.Product>[]) => {
        const createSingleMutation = (product: NodeType.NodeOrId<NodeType.Product>) => ({
            id: NodeType.extractId(product),
        });
        return mutateProduct.delete(products.map(createSingleMutation));
    };

    const deleteProductTraits = async (productTraits: NodeType.NodeOrId<NodeType.ProductTrait>[]) => {
        return mutateProductTrait.delete(
            productTraits.map((productTrait) => ({ id: NodeType.extractId(productTrait) }))
        );
    };

    const createProductTraits = async (
        inputs: {
            product: NodeType.NodeOrId<NodeType.Product>;
            declaration: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>;
            createValues?: TraitValue_CreateInput[];
        }[]
    ) => {
        const createSingleMutationConfig = (input) => {
            const { product, declaration, createValues } = input;

            const toCreate_GroupedByType = _.groupBy(createValues, (v) => v.type);

            const createMutationPartial = (type: TraitValueType) => {
                return (
                    toCreate_GroupedByType[type] &&
                    toCreate_GroupedByType[type].map((i) => ({
                        value: i.value,
                        field: i.fieldId,
                    }))
                );
            };

            const mutationConfig = {
                product: NodeType.extractId(product),
                declaration: NodeType.extractId(declaration),
                floatValues: createMutationPartial(TraitValueType.Float),
                selectionValues: createMutationPartial(TraitValueType.Selection),
                stringValues: createMutationPartial(TraitValueType.String),
                integerValues: createMutationPartial(TraitValueType.Int),
            };
            return mutationConfig;
        };

        const mutaitonConfigs = inputs.map(createSingleMutationConfig);

        const res = await mutateProductTrait.create(mutaitonConfigs);
        return res;
    };

    const updateProductTraits = async (
        inputs: {
            productTrait: NodeType.NodeOrId<NodeType.ProductTrait>;
            deleteValues?: TraitValue_DeleteInput[];
            createValues?: TraitValue_CreateInput[];
            updateValues?: TraitValue_UpdateInput[];
        }[]
    ) => {
        const createSingleMutationConfig = (input) => {
            const { productTrait, deleteValues, createValues, updateValues } = input;

            const toCreate_GroupedByType = _.groupBy(createValues, (v) => v.type);
            const toUpdate_GroupedByType = _.groupBy(updateValues, (v) => v.type);
            const toDelete_GroupedByType = _.groupBy(deleteValues, (v) => v.type);
            // debugger;

            const createMutationPartial = (type: TraitValueType) => {
                return {
                    delete: toDelete_GroupedByType[type] && toDelete_GroupedByType[type].map((i) => i.valueId),
                    update:
                        toUpdate_GroupedByType[type] &&
                        toUpdate_GroupedByType[type].map((i) => ({
                            id: i.valueId,
                            value: i.value,
                        })),
                    create:
                        toCreate_GroupedByType[type] &&
                        toCreate_GroupedByType[type].map((i) => ({
                            value: i.value,
                            field: i.fieldId,
                        })),
                };
            };

            return {
                id: NodeType.extractId(productTrait),
                floatValues: createMutationPartial(TraitValueType.Float),
                selectionValues: createMutationPartial(TraitValueType.Selection),
                stringValues: createMutationPartial(TraitValueType.String),
                integerValues: createMutationPartial(TraitValueType.Int),
                flagValues: createMutationPartial(TraitValueType.Flag),
            };
        };

        return mutateProductTrait.update(inputs.map(createSingleMutationConfig));
    };

    const createTraitDeclaration = async (input: ProductTraitDeclaration_CreateInput): Promise<{ id: NodeType.ID }> => {
        const { name, tag, parent } = input;
        const mutationInput = {
            name: name ? _.trim(name) : undefined,
            tag: tag ? _.trim(tag) : undefined,
            organisationType: NodeType.extractId(input.organisationType),
            franchise: convertToConfigValue({ values: input, key: 'franchise', isSourceNode: true }),
            parent: parent ? NodeType.extractId(parent) : undefined,
        };

        const resp = await mutateProductTraitDeclaration.create(mutationInput);

        return _.get(resp, 'data.ProductTraitDeclaration.ProductTraitDeclaration');
    };

    const updateTraitDeclaration = async (pd: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>, input) => {
        // const tag = _.trim(input.tag);
        return mutateProductTraitDeclaration.update({
            id: NodeType.extractId(pd),
            ...input,
            // tag: tag ? tag : undefined,
        });
    };

    // TODO: This is temporary. Make it better, replace with enum or something.
    type FieldName =
        | 'integerFields'
        | 'floatFields'
        | 'stringFields'
        | 'measuredFields'
        | 'dosageFields'
        | 'selectionFields'
        | 'flagFields';

    const updateTraitDeclaration_addField =
        (fieldName: FieldName) =>
        async (
            pd: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>,
            fieldConf: NodeType.UpdateProductTraitDeclarationListProductDeclarationIntegerFieldCreateInput
        ) => {
            let createPayload: any = fieldConf;
            if (fieldName === 'selectionFields') {
                createPayload = _.pick(
                    fieldConf,
                    'searchOrder',
                    'searchAsDistinct',
                    'name',
                    'hint',
                    'optional',
                    'group',
                    'options'
                );
            }

            return mutateProductTraitDeclaration.update({
                id: NodeType.extractId(pd),
                [fieldName]: {
                    create: [createPayload],
                },
            });
        };

    const updateTraitDeclaration_updateField =
        (fieldName: FieldName) =>
        async (pd: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>, fieldId: NodeType.ID, fieldConf: Object) => {
            return mutateProductTraitDeclaration.update({
                id: NodeType.extractId(pd),
                [fieldName]: {
                    update: [
                        {
                            id: fieldId,
                            ...fieldConf,
                        },
                    ],
                },
            });
        };

    const updateProductDeclarationSelectionField = async (
        sf: NodeType.NodeOrId<NodeType.ProductDeclarationSelectionField>,
        fieldConf: Object,
        options: { delete?: NodeType.ID[]; create?: any[]; update?: any[] }
    ) => {
        return mutateProductDeclarationSelectionField.update({
            id: NodeType.extractId(sf),
            ...fieldConf,
            options: options,
        });
    };

    const updateTraitDeclaration_deleteField =
        (fieldName: FieldName) =>
        async (pd: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>, fieldId: NodeType.ID) => {
            // const tag = _.trim(input.tag);
            return mutateProductTraitDeclaration.update({
                id: NodeType.extractId(pd),
                [fieldName]: {
                    delete: [fieldId],
                },
            });
        };

    const createProductCompany = async (
        input: NodeType.CreateProductCompanyMutationInput
    ): Promise<NodeType.ID | null> => {
        const res = await mutateProductCompany.create({
            name: _.trim(input.name),
            description: _.trim(input.description),
            franchise: convertToConfigValue({ values: input, key: 'franchise', isSourceNode: true }),
            parentCompany: input.parentCompany,
        });
        return _.get(res, 'data.ProductCompany.ProductCompany.id', null);
    };

    const updateProductCompany = async (
        company: NodeType.NodeOrId<NodeType.ProductCompany>,
        input: ProductCompany_UpdateInput
    ) => {
        console.warn('Add parameters validation');
        return mutateProductCompany.update({
            id: NodeType.extractId(company),
            name: input.name !== undefined ? _.trim(input.name) : undefined,
            description: input.description !== undefined ? _.trim(input.description) : undefined,
            franchise: convertToConfigValue({ values: input, key: 'franchise', isSourceNode: true }),
        });
    };

    const updateBrand = async (brand: NodeType.NodeOrId<NodeType.Brand>, input: Brand_UpdateInput) => {
        console.warn('Add parameters validation');
        const config: NodeType.UpdateBrandMutationInput = {
            id: NodeType.extractId(brand),
            name: input.name !== undefined ? _.trim(input.name) : undefined,
            identification: input.identification !== undefined ? _.trim(input.identification) : undefined,
            franchise: convertToConfigValue({ values: input, key: 'franchise', isSourceNode: true }),
            company: convertToConfigValue({ values: input, key: 'company', isSourceNode: true, isSwitch: true }),
        };

        return mutateBrand.update(config);
    };

    const deleteDeclaration = async (declaration: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>) => {
        return mutateProductTraitDeclaration.delete({
            id: NodeType.extractId(declaration),
        });
    };

    return {
        ProductCatalogMutator: {
            createProductTraits,
            updateProductTraits,
            deleteProductTraits,
            createTraitDeclaration,
            updateTraitDeclaration,
            updateTraitDeclaration_addField,
            updateTraitDeclaration_deleteField,
            updateTraitDeclaration_updateField,
            updateProductDeclarationSelectionField,
            createProducts,
            updateProducts,
            deleteProducts,
            createProductCompany,
            updateProductCompany,
            deleteDeclaration,
            updateBrand,
        },
    };
};

export const withProductCatalogMutators = (refetchQueries?) =>
    compose(
        withProductTraitMutator(refetchQueries),
        withProductDeclarationSelectionFieldMutator(refetchQueries),
        withProductTraitDeclarationMutator(refetchQueries),
        withProductCompanyMutator(refetchQueries),
        withProductMutator(refetchQueries),
        withBrandMutator(refetchQueries),
        withProps(createMutator)
    );

export interface IProductCatalogMutators extends MutatorsInjected, ReturnType<typeof createMutator> {}

export const useProductCatalogMutators = (options: MutationHookOptions<any, any>) => {
    const mutateProductTrait = useMutationProductTrait(options);
    const mutateProductDeclarationSelectionField = useMutationProductDeclarationSelectionField(options);
    const mutateProduct = useMutationProduct(options);
    const mutateProductCompany = useMutationProductCompany(options);
    const mutateBrand = useMutationBrand(options);
    const mutateProductTraitDeclaration = useMutationProductTraitDeclaration(options);

    return {
        mutateProductTrait,
        ...createMutator({
            mutateProductTrait,
            mutateProductDeclarationSelectionField,
            mutateProduct,
            mutateProductCompany,
            mutateBrand,
            mutateProductTraitDeclaration,
        }),
    };
};
