import * as React from 'react';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { Display, Panel } from '@poolware/components';
import { CustomerLink, WorkOrderLink } from '../../../Links';
import { PanelItemServiceJob } from '../../../Scheduler/AppointmentDock/PanelWorkOrder/PanelWorkOrder.Instantiated';
import { ModuleIconNames } from '../../../constants';

export const InvoiceLinkEntitiesPanel: React.FC<{ doc: NodeType.AbstractDocument }> = ({ doc }) => {
    if (!doc) return null;

    const { workOrder, serviceJob, customer } = doc;
    const woTitle = _.truncate(workOrder?.title, { length: 40 });

    return (
        <Panel tw={'text-sm'}>
            <Panel.Header>Related to</Panel.Header>
            <Panel.Body>
                {customer && (
                    <>
                        <Panel.Item iconMarker={'user'} label={'Customer'}>
                            <CustomerLink id={customer?.id}>
                                <Display.Entity value={customer} />
                            </CustomerLink>
                            {customer.contactName && <> ({customer.contactName})</>}
                        </Panel.Item>
                        {customer?.companyName && (
                            <Panel.Item label={'Company'} iconMarker={'building'}>
                                {customer.companyName}
                            </Panel.Item>
                        )}
                        {customer?.primaryAddress && (
                            <Panel.ItemAddress
                                label={'Prim. Address'}
                                iconMarker={'marker'}
                                content={customer.primaryAddress}
                            />
                        )}{' '}
                        {customer?.billAddress && (
                            <Panel.ItemAddress
                                label={'Bill. Address'}
                                iconMarker={'marker'}
                                content={customer.billAddress}
                            />
                        )}
                        <Panel.Divider />
                    </>
                )}

                {serviceJob && <PanelItemServiceJob serviceJob={doc.serviceJob} />}
                {workOrder && (
                    <Panel.Item iconMarker={ModuleIconNames.WorkOrder} label={'Work Order'}>
                        <WorkOrderLink id={workOrder?.id}>#{workOrder?.workOrderNumber}</WorkOrderLink>
                        {woTitle}
                    </Panel.Item>
                )}
            </Panel.Body>
        </Panel>
    );
};
