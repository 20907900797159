import gql from 'graphql-tag';
import { addressFragment } from '@poolware/api';

export const QLServiceJobRecurList = gql`
    query QueryServiceJobList(
        $first: Int
        $page: Int
        $search: ServiceJobViewerServiceJobsSearch
        $sort: ServiceJobViewerServiceJobsSort
    ) {
        viewer {
            serviceJobs(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        title
                        serviceJobNumber
                        stage {
                            id
                            title
                            status
                            type
                        }
                        stopsAt
                        lastAppointmentDate
                        isRecurring
                        group {
                            id
                            title
                        }
                        address {
                            id
                            ...AddressFragment
                        }
                        customer {
                            id
                            crn
                            companyName
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        pool {
                            id
                            name
                            volume
                            type {
                                id
                                name
                            }
                        }
                        createdAt
                        updatedAt
                        entity {
                            id
                            franchise {
                                id
                                name
                            }
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${addressFragment}
`;
