import { PoolwareAPIClient } from './index';

let _api: PoolwareAPIClient = null;

export const setApiBaseURL = (apiBaseURL) => {
    _api = new PoolwareAPIClient(apiBaseURL);
};

export const getApiClient = (): PoolwareAPIClient | null => {
    return _api;
};
