import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import { Form, Message } from 'semantic-ui-react';
import * as _ from 'lodash';
import {
    DevOnly,
    FormikCheckboxField,
    FormikFormButtons,
    FormikFormDebug,
    FormikInputField,
    FormikSelectField,
    FormikTextareaField,
} from '@poolware/components';

export enum FormFieldPrimitiveType {
    Int,
    Float,
    Boolean,
    Flag,
    String,
    Select,
    Text,
    ID,
}

export type FormFieldInputConfig = {
    type: FormFieldPrimitiveType;
    name: string;
    key: string;
    required?: boolean;
    initialValue?: any;
    options?: any[];
    component?: any;
};

export interface FormBuilderConfig {
    fields: FormFieldInputConfig[];
    validationSchema?: any;
    initialValues?: any;
}

export interface ExternalProps {
    config: FormBuilderConfig;
    initialValues?: Object;
    onSubmit: (values, actions, config: FormBuilderConfig) => any;
    onCancel: () => any;
    children?: (props: FormikProps<any>) => React.ReactNode;
    debug?: boolean;
    hideButtons?: boolean;
}

export interface PageControlProps extends ExternalProps {}

// @ts-ignore
export const TextInput = FormikInputField;

// @ts-ignore
export const TextAreaInput = FormikTextareaField;

export const NumberInput = (props) => <FormikInputField type="number" {...props} />;

// @ts-ignore
export const BooleanInput = FormikCheckboxField;

// @ts-ignore
export const SelectInput = FormikSelectField;

export const mapPrimitiveTypeToComponent = (type: FormFieldPrimitiveType) => {
    switch (type) {
        case FormFieldPrimitiveType.Int:
            return NumberInput;
        case FormFieldPrimitiveType.Float:
            return NumberInput;
        case FormFieldPrimitiveType.Flag:
        case FormFieldPrimitiveType.Boolean:
            return BooleanInput;
        case FormFieldPrimitiveType.String:
            return TextInput;
        case FormFieldPrimitiveType.Text:
            return TextAreaInput;
        case FormFieldPrimitiveType.ID:
            return TextInput;
        case FormFieldPrimitiveType.Select:
            return SelectInput;
        default:
            throw new Error('Unknown form input type');
    }
};

export class FormBuilder extends React.Component<PageControlProps> {
    onSubmit = async (values, actions) => {
        actions.setSubmitting(true);
        try {
            const { onSubmit, config } = this.props;
            await onSubmit(values, actions, config);
        } catch (e) {
            actions.setStatus({ error: e.message });
        }
        actions.setSubmitting(false);
    };

    onCancel = () => {
        this.props.onCancel && this.props.onCancel();
    };

    prepareInitialValues = (config: FormBuilderConfig) => {
        if (this.props.initialValues) {
            return this.props.initialValues;
        }

        const providedInitialValues = config.initialValues || {};
        // return providedInitialValues;

        // TODO: move this somewhere else
        return config.fields.reduce((acc, cf) => {
            const value = providedInitialValues[cf.key];
            if (cf.type === FormFieldPrimitiveType.Boolean) {
                acc[cf.key] = Boolean(value);
            } else if (cf.type === FormFieldPrimitiveType.String || cf.type === FormFieldPrimitiveType.Text) {
                acc[cf.key] = value || '';
            } else if (cf.type === FormFieldPrimitiveType.Int || cf.type === FormFieldPrimitiveType.Float) {
                const num = Number(value);
                if (value === null || value === undefined || !_.isFinite(num)) {
                    acc[cf.key] = '';
                } else {
                    acc[cf.key] = num;
                }
            } else {
                acc[cf.key] = value === undefined ? '' : value;
            }
            return acc;
        }, {});
    };

    render() {
        const { config, children, debug, hideButtons } = this.props;
        const initialValues = this.prepareInitialValues(config);
        return (
            <>
                <Formik
                    onSubmit={this.onSubmit}
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={config.validationSchema}
                    render={(formikBag) => {
                        const { errors, status, touched, isSubmitting, handleSubmit, values } = formikBag;
                        const hasValidationError = Object.keys(errors).length > 0;
                        return (
                            <Form size={'small'} error={!!(status && status.error) || hasValidationError}>
                                {status && status.error && <Message error>{JSON.stringify(status.error)}</Message>}
                                {config.fields.map((fieldConfig) => {
                                    const FieldInputComponent =
                                        fieldConfig.component || mapPrimitiveTypeToComponent(fieldConfig.type);

                                    return (
                                        <FieldInputComponent
                                            key={fieldConfig.key}
                                            required={fieldConfig.required}
                                            name={fieldConfig.key}
                                            label={fieldConfig.name}
                                            options={fieldConfig.options}
                                            // inline
                                            // labelStyle={{width: "150px", textAlign: "right"}}
                                        />
                                    );
                                })}

                                {children && children(formikBag)}
                                {!hideButtons && (
                                    <FormikFormButtons submitOnEnter={false} handleCancel={this.onCancel} />
                                )}
                                <FormikFormDebug hidden={!debug} />
                            </Form>
                        );
                    }}
                />
            </>
        );
    }
}
