import gql from 'graphql-tag';
import { createUseQueryNodeHook, customerContactDetailsFragment, NodeType } from '@poolware/api';

const QL = gql`
    query QueryCustomerDetails($id: ID!) {
        node(id: $id) {
            id
            ...CustomerContactDetailsFragment
        }
    }
    ${customerContactDetailsFragment}
`;

export const useQueryCustomerDetails = createUseQueryNodeHook<NodeType.Customer>(QL);
