import gql from 'graphql-tag';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { useQuery } from 'react-apollo';
import { ViewerContextType } from './ViewerContextType';
import { useEffect, useState } from 'react';

const checkPermissionsFragment = gql`
    fragment CheckPermissionsFragment on Viewer {
        checkPermissions {
            AppointmentGroup {
                create
                delete
                update
            }
            Site {
                create
                delete
                update
            }
            Pool {
                create
                delete
                update
            }
            Customer {
                delete
                update
                create
            }
            StaffRegistration {
                create
                update
                forcePassword
                delete
                disable
                enable
            }
            Franchise {
                create
                delete
                update
            }
            FranchiseShop {
                create
                delete
                update
            }
            Staff {
                create
                update
            }
            StaffTeam {
                create
                update
                delete
                addStaff
            }
            CustomerTag {
                create
                delete
                update
            }
            ServiceJobGroup {
                create
                delete
                update
            }
        }
    }
`;

export const MeQuery = gql`
    query MeQuery {
        viewer {
            me {
                featureFlags
                staff {
                    id
                    registration {
                        id
                        email
                        disabledAt
                    }
                    role {
                        id
                        name
                    }
                    user {
                        id
                        firstName
                        lastName
                        entity {
                            id
                            franchise {
                                id
                                name
                                types
                                vend {
                                    id
                                }
                                organisationType {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
            ...CheckPermissionsFragment
        }
    }
    ${checkPermissionsFragment}
`;

type availablePermissionKeys =
    | 'Site'
    | 'Pool'
    | 'AppointmentGroup'
    | 'Customer'
    | 'StaffRegistration'
    | 'Franchise'
    | 'FranchiseShop'
    | 'Staff'
    | 'StaffTeam'
    | 'CustomerTag'
    | 'ServiceJobGroup';

export type ViewerPermissionsType = Pick<NodeType.ViewerPermissions, availablePermissionKeys>;

export const defaultViewerPermissions: ViewerPermissionsType = {
    Site: {
        delete: false,
        update: false,
        create: false,
    },
    Pool: {
        delete: false,
        update: false,
        create: false,
    },
    AppointmentGroup: {
        create: false,
        delete: false,
        update: false,
    },
    Customer: {
        delete: false,
        update: false,
        create: false,
    },
    StaffRegistration: {
        create: false,
        update: false,
        forcePassword: false,
        delete: false,
        disable: false,
        enable: false,
    },
    Franchise: {
        create: false,
        delete: false,
        update: false,
    },
    FranchiseShop: {
        create: false,
        delete: false,
        update: false,
    },
    Staff: {
        create: false,
        update: false,
    },
    StaffTeam: {
        create: false,
        update: false,
        delete: false,
        addStaff: false,
    },
    CustomerTag: {
        create: false,
        delete: false,
        update: false,
    },
    ServiceJobGroup: {
        create: false,
        delete: false,
        update: false,
    },
};

export const useQueryMe = () => {
    const [loadingInitial, setFirstLoad] = useState(true);
    const { loading, error, data, refetch } = useQuery<NodeType.Query>(MeQuery, { fetchPolicy: 'cache-and-network' });
    useEffect(() => {
        if (!loading && loadingInitial) {
            setFirstLoad(false);
        }
    }, [loading, error, data]);

    if (error) {
        console.error(error);
    }

    const res = { data };
    const me = res?.data?.viewer?.me;
    const franchise = me?.staff?.user?.entity?.franchise;
    const organisation = me?.staff?.user?.entity?.franchise?.organisationType;
    const isVendPresent = !!me?.staff?.user?.entity?.franchise?.vend?.id;

    let permissions: ViewerPermissionsType;
    const receivedPermissions = res?.data?.viewer.checkPermissions || {};
    permissions = _.merge(defaultViewerPermissions, receivedPermissions);

    const resData: ViewerContextType['viewer'] = {
        me,
        isVendPresent,
        franchise: !franchise
            ? undefined
            : {
                  name: franchise.name,
                  id: franchise.id,
                  types: franchise.types,
              },
        organisation: !organisation
            ? undefined
            : {
                  id: organisation.id,
                  name: organisation.name,
              },
        Permissions: permissions,
    };

    return {
        error,
        loading,
        loadingInitial,
        refetch,
        data: resData,
    };
};
