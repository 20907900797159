import * as React from 'react';
import { useOrgSwitcher } from './OrgProvider';
import { NodeType } from '@poolware/api';
import { SuggestInput } from '@poolware/components';
import { Dropdown } from 'semantic-ui-react';
import { Navbar } from '@poolware/components';

export interface OrgSwitcherProps {}

export const SuggestInputOrgSwitcher: React.FC<OrgSwitcherProps> = () => {
    const { organisation, setOrg, orgs, loading } = useOrgSwitcher();

    const itemRenderer = (item: NodeType.OrganisationType): string => {
        return item?.name || '-';
    };

    return (
        <SuggestInput
            value={organisation}
            items={orgs}
            loading={loading}
            onItemSelect={setOrg}
            itemTextLabel={itemRenderer}
        />
    );
};
const ALL_ORGS = '__ALL_ORGS_KEY__';

export const NavbarOrgSwitcher: React.FC = () => {
    const { organisation, setOrg, orgs } = useOrgSwitcher();

    const orgOptions = orgs.map((o) => ({
        text: o.name,
        value: o?.id,
    }));

    const options = [
        {
            text: 'All',
            value: ALL_ORGS,
        },
        ...orgOptions,
    ];

    const onChangeOrg = ({ value }) => {
        setOrg(value === ALL_ORGS ? null : value);
    };

    return (
        <>
            <Navbar.DropdownMenu color="yellow" label={organisation?.name} icon={'building'} simple={false}>
                {options.map((option, index) => {
                    return (
                        <Dropdown.Item
                            key={index}
                            as={'a'}
                            icon={'building'}
                            text={option.text}
                            onClick={() => onChangeOrg(option)}
                        />
                    );
                })}
            </Navbar.DropdownMenu>
        </>
    );
};
