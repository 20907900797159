import { useDefaultConnectionState, useQueryConnection } from '@ez/api-core';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { productDocumentFragment } from './use-query-product-doc';

const QL = gql`
    query QueryProductDocsConnection(
        $first: Int
        $page: Int
        $sort: ProductDocumentViewerProductDocumentsSort
        $search: ProductDocumentViewerProductDocumentsSearch
    ) {
        viewer {
            productDocuments(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        ...ProductDocumentFragment
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${productDocumentFragment}
`;

export interface UseQueryProductDocsConnectionProps {
    type?: NodeType.ProductDocumentType;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}

function mapPropsToSearchQuery(props?: UseQueryProductDocsConnectionProps) {
    if (!props) {
        return undefined;
    }

    const { type, organisationType } = props;
    const search = {};
    if (type) {
        _.set(search, 'type.is', type);
    }
    if (organisationType) {
        _.set(search, 'organisationType.id', NodeType.extractId(organisationType));
    }
    return search;
}

export function useQueryProductsDocsConnection(props?: UseQueryProductDocsConnectionProps) {
    const connectionPath = 'viewer.productDocuments';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 20,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.ProductDocument>({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
