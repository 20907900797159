import * as React from 'react';
import { Display, FormikDefaultForm, Panel, toastError } from '@poolware/components';
import { useBookingActions, useCalendarActions } from '../../redux';
import { AppointmentFormValuesType, AppointmentValidationSchema } from './AppointmentForm';
import { useAppointmentMutators } from '../../queries/use-appointment-mutators';
import TimeFieldGroup from './FormFields/TimeField';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Message, Segment } from 'semantic-ui-react';

export const PageRescheduleConfirm: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const { BookingState, BookingAction } = useBookingActions();
    const { CalendarState, CalendarAction } = useCalendarActions();
    const { AppointmentMutator } = useAppointmentMutators([
        'SchedulerAppointmentsList',
        'AppointmentItemQueryDockView',
    ]);

    let initialValues = {
        appointmentItem: BookingState.details.appointmentItem,
        startDate: BookingState.details.startDate,
        duration: BookingState.details.duration,
        staff: BookingState.details.staff,
    };

    const onSubmit = async (values: AppointmentFormValuesType) => {
        try {
            const res = await AppointmentMutator.updateAppointmentItem(values.appointmentItem, {
                startDate: values.startDate,
                duration: values.duration,
                staff: values.staff,
            });

            // TODO: HACK: NOTE:
            // Recurrent appointments often change their id on update.
            // If appointment is being previewed in docker, need to swap previewAppId
            const oldId = values.appointmentItem?.id;
            const newId = res.data?.AppointmentItem?.AppointmentItem?.id;
            if (!!CalendarState.previewApptId && newId !== oldId) {
                CalendarAction.setPreviewAppt(newId);
            }
            ///

            if (BookingState.isSagaMode) {
                BookingAction.bookingCreated();
            } else {
                AppNavigator.replaceToOrigin('/scheduler');
            }
        } catch (error) {
            toastError({ title: 'Failed to update appointment', description: error.message });
            console.error('there was an error sending the query', error);
        }
    };

    const onCancel = () => {
        if (BookingState.isSagaMode) {
            BookingAction.abort();
        } else {
            AppNavigator.replaceToOrigin('/scheduler');
        }
    };

    return (
        <FormikDefaultForm
            header={'Confirm Reschedule'}
            enableReinitialize={true}
            debug={true}
            validationSchema={AppointmentValidationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitButton={{ content: 'Reschedule', disabled: !initialValues.appointmentItem }}
        >
            {!initialValues.appointmentItem && <Segment color={'red'}>Failed to load appointment</Segment>}
            <Panel.SectionHeader>Current Booking Time</Panel.SectionHeader>
            <Panel.Item label="Date" labelIcon="clock">
                <Display.DateRange
                    startDate={initialValues.appointmentItem?.startDate}
                    duration={initialValues.appointmentItem?.duration}
                />
            </Panel.Item>
            <Panel.Item label="Duration" labelIcon="clock">
                {initialValues.appointmentItem?.duration} min
            </Panel.Item>
            <Panel.SectionHeader>New Booking Time</Panel.SectionHeader>
            <TimeFieldGroup />
        </FormikDefaultForm>
    );
};
