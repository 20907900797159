import gql from 'graphql-tag';
import { NodeType, createUseQueryNodeHook } from '@poolware/api';

const QL = gql`
    query QueryServiceJobTemplate($id: ID!) {
        node(id: $id) {
            id
            ... on ServiceJobTemplate {
                templateTitle
                templateDescription
                providedTitle
                providedDescription
                requiresAddress
                requiresPool
                requiresCustomer
                requiresStaff
                requiresContact
                priority
                isPublished
                canBeRecurring
                createdAt
                updatedAt
                archivedAt
                group {
                    id
                    title
                }
                stageReport {
                    isActive
                    ownershipType
                    stage {
                        id
                        title
                        type
                    }
                }
                ownerOfWorkOrderTemplates {
                    edges {
                        node {
                            id
                        }
                    }
                }
                checkMutations {
                    delete
                    update
                }
                organisationType {
                    id
                    name
                }
                franchise {
                    id
                    name
                }
                workOrderTemplateAssocs {
                    edges {
                        node {
                            id
                            isSuggestion
                            priority
                            forwardWorkFor
                            forwardContact
                            forwardAddress
                            forwardStaff
                            forwardPool
                            createdAt
                            updatedAt
                            workOrderTemplate {
                                id
                                templateTitle
                                ownedByServiceJobTemplate {
                                    id
                                }
                                jobTodoTemplateAssocs {
                                    edges {
                                        node {
                                            id
                                            priority
                                            jobTodoTemplate {
                                                id
                                                title
                                                description
                                                organisation {
                                                    id
                                                    name
                                                }
                                                franchise {
                                                    id
                                                    name
                                                }
                                                items {
                                                    edges {
                                                        node {
                                                            id
                                                            title
                                                            description
                                                            orderIndex
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            addedBy {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useQueryServiceJobTemplate = createUseQueryNodeHook<NodeType.ServiceJobTemplate>(QL);
