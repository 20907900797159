import * as React from 'react';
import moment from 'moment';
import { Header, Icon, List } from 'semantic-ui-react';
import {
    Display,
    PrintSection,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionProps,
} from '@poolware/components';

import { getLabelForAppointmentStatus, IconAppointmentStatus } from '@poolware/app-service-jobs';

const sortAndSplitAppointment = (appointments) => {
    const now = moment();

    appointments = appointments.map((a) => {
        return {
            ...a,
            startDate: moment(a.startDate), // convert startDate to moment object.
        };
    });

    const pastAppointments = appointments
        .filter(({ startDate }) => startDate.isBefore(now, 'd'))
        .sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? 1 : -1;
        });

    const futureAppointments = appointments
        .filter(({ startDate }) => startDate.isAfter(now, 'd'))
        .sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? -1 : 1;
        });

    const todayAppointments = appointments
        .filter(({ startDate }) => startDate.isSame(now, 'd'))
        .sort((a, b) => {
            return a.startDate.isBefore(b.startDate) ? -1 : 1;
        });

    return {
        pastAppointments: pastAppointments,
        todayAppointments: todayAppointments,
        futureAppointments: futureAppointments,
    };
};

const AppointmentsListItem = ({ appointment }) => {
    const { startDate, duration, staff, status } = appointment;
    return (
        <List.Item>
            <List.Icon name="calendar outline" verticalAlign="middle" />
            <List.Content>
                <span>
                    <Icon name={'clock'} />
                    <Display.DateRange startDate={startDate} duration={duration} />
                </span>
                <br />
                <IconAppointmentStatus status={status} /> Status: {getLabelForAppointmentStatus(status)}
                <br />
                {staff && (
                    <React.Fragment>
                        <Icon name="spy" />
                        <Display.Entity value={staff} />
                        <br />
                    </React.Fragment>
                )}
            </List.Content>
        </List.Item>
    );
};

const AppointmentsList: React.FC<{ appointments }> = ({ appointments }) => (
    <List divided>
        {appointments.map((a) => (
            <AppointmentsListItem key={a.id} appointment={a} />
        ))}
    </List>
);

interface TabAppointmentsProps extends PrintSectionProps {
    customerId: string;
    appointments: any[];
}

class Appointments extends React.PureComponent<TabAppointmentsProps> {
    public static defaultProps = {
        appointments: [],
    };

    render() {
        const sortedAppointments = sortAndSplitAppointment(this.props.appointments);
        const { pastAppointments, todayAppointments, futureAppointments } = sortedAppointments;

        const { showPrintToggle, noPrint, onPrintChange } = this.props;

        const panelProps = { showPrintToggle, noPrint, onPrintChange };
        return (
            <PrintSection {...panelProps}>
                <PrintSectionHeader>Appointments</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn paddingVal={2}>
                        <Header sub content={'Past Appointments'} />
                        <AppointmentsList appointments={pastAppointments} />
                    </PrintSectionColumn>
                    <PrintSectionColumn paddingVal={2}>
                        {todayAppointments.length > 0 && (
                            <>
                                <Header sub content={'Today'} />
                                <AppointmentsList appointments={todayAppointments} />
                            </>
                        )}
                        <Header sub content={'Future Appointments'} />
                        <AppointmentsList appointments={futureAppointments} />
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSection>
        );
    }
}

export default Appointments;
