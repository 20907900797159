import * as React from 'react';
import { Helmet } from '@poolware/app-shell';

import CustomerAppointmentsPanel from './CustomerAppointmentsPanel';
import CustomerSitesPanel from './CustomerSitesPanel';
import { PageControlProps } from './Page.Control';
import {
    Display,
    PrintPageBreak,
    PrintPageHeader,
    PrintPageNoteText,
    PrintPageSubHeader,
    PrintSection,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionProps,
    stringFormatters,
    usePrintToggles,
} from '@poolware/components';

import { CustomerDetailsPanel } from '../common/CustomerDetailsPanel';

interface CustomerPageProps extends PageControlProps {}

const CustomerNotesPanel: React.FC<PrintSectionProps & { note: string }> = ({ note, ...rest }) => {
    return (
        <PrintSection {...rest}>
            <PrintSectionHeader>Customer Notes</PrintSectionHeader>
            <PrintSectionBody>
                <PrintSectionColumn>
                    <PrintPageNoteText formatted={true} limit={10000} value={note} />
                </PrintSectionColumn>
            </PrintSectionBody>
        </PrintSection>
    );
};

const CustomerPage: React.FC<CustomerPageProps> = (props) => {
    const { customer, contactItems } = props;

    const pageTitle = `${customer.crn} - ${stringFormatters.formatEntityName(customer)}`;

    const noPrintDefaults = {
        note: false,
        appointments: false,
        sites: false,
    };
    const toggles = usePrintToggles('print.toggles.customer', noPrintDefaults);

    return (
        <>
            <Helmet title={pageTitle} />
            <div>
                <PrintPageHeader>Customer Profile</PrintPageHeader>
                <PrintPageSubHeader>
                    {customer && (
                        <>
                            <Display.Entity value={customer} /> - <Display.Span value={customer.crn} />
                        </>
                    )}
                </PrintPageSubHeader>

                <CustomerDetailsPanel contactItems={contactItems} customer={customer} />

                <CustomerNotesPanel note={customer.note} {...toggles.note} />

                <CustomerSitesPanel customerId={customer.id} {...toggles.sites} />

                <PrintPageBreak />

                <CustomerAppointmentsPanel customerId={customer.id} {...toggles.appointments} />
            </div>
        </>
    );
};

export default CustomerPage;
