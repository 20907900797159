import * as React from 'react';
import { compose } from '@ez/tools';
import { IAppNavigatorProps, ModuleLink, ModuleNavLink, withAppNavigator } from '@poolware/react-app-navigator';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBarItem,
    MenuBarSection,
    StickyMenuBar,
    VStack,
} from '@poolware/components';
import { Icon, Menu } from 'semantic-ui-react';
import { fromEdges, NodeType } from '@poolware/api';
import { QueryProductCompanyConnection } from '../../../queries/QueryProductCompanyConnection';

class ListProductCompanies extends React.Component<PageControlProps> {
    onAdd = () => {
        this.props.AppNavigator.navigate('/new-company', { relativeToModule: true, setOrigin: true });
    };

    render() {
        return (
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <Menu.Item as={ModuleNavLink} to={'/'}>
                            All Companies
                        </Menu.Item>
                        <Menu.Item as={ModuleNavLink} to={'/brands'}>
                            All Brands
                        </Menu.Item>
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        <MenuBarItem icon={'plus'} color={'green'} onClick={this.onAdd} title={'Add Company'} />
                    </MenuBarSection>
                </StickyMenuBar>

                <QueryProductCompanyConnection>
                    {({ connectionState, connectionData }) => {
                        const tableDef: ConnectionTableDef<NodeType.ProductCompany> = [
                            {
                                header: 'Product Company',
                                cellProps: {
                                    width: 2,
                                },
                                cell: (pd) => <ModuleLink to={`/${pd.id}`}>{pd.name}</ModuleLink>,
                            },
                            {
                                header: 'Brands',
                                cellProps: {
                                    singleLine: false,
                                },
                                cell: (pd) => {
                                    const brands = fromEdges(pd?.brands)
                                        .map((b) => b.name)
                                        .join(', ');
                                    return <>{brands}</>;
                                },
                            },
                            {
                                header: 'Visibility',
                                cellProps: {
                                    width: 2,
                                },
                                cell: (pd) => {
                                    const { franchise } = pd;
                                    if (franchise) {
                                        return (
                                            <div>
                                                <Icon name={'building'} />
                                                {franchise.name}
                                            </div>
                                        );
                                    } else {
                                        return 'Global';
                                    }
                                },
                            },
                        ];

                        return (
                            <DefaultConnectionTable
                                connectionData={connectionData}
                                connectionState={connectionState}
                                tableProps={{ singleLine: false }}
                                tableDef={tableDef}
                            />
                        );
                    }}
                </QueryProductCompanyConnection>
            </VStack>
        );
    }
}

export default compose(withAppNavigator())(ListProductCompanies);

export interface PageControlProps extends IAppNavigatorProps {}
