import * as React from 'react';
import { connect, getIn } from 'formik';
import { Divider, Form } from 'semantic-ui-react';
import { FormikRadioField, FormSectionBody } from '@poolware/components';
import { validationSchemaWorkOrderItems } from './WorkOrderItemFormFields/FormikInputJobTodoItems';
import * as Yup from 'yup';
import { FormikPickerServiceJobTemplate } from './FormikPickerServiceJobTemplate';
import { FormikInputJobTodo } from './WorkOrderItemFormFields/FormikInputJobTodo';

export enum NewOrderType {
    NEW = 'NEW',
    SERVICE_JOB_TEMPLATE = 'SERVICE_JOB_TEMPLATE',
}

export const validationSchemaWorkOrderItem = Yup.object({
    jobTemplate: Yup.mixed()
        .when('workOrderItemsType', {
            is: NewOrderType.SERVICE_JOB_TEMPLATE,
            then: Yup.object().required('Required'),
            otherwise: Yup.object().notRequired(),
        })
        .nullable(),
    title: Yup.string()
        .when('workOrderItemsType', {
            is: NewOrderType.NEW,
            then: Yup.string().max(100).required('Required'),
            otherwise: Yup.string().notRequired(),
        })
        .nullable(),
    todoItems: Yup.mixed().when('workOrderItemsType', {
        is: NewOrderType.NEW,
        then: validationSchemaWorkOrderItems,
        otherwise: Yup.mixed().notRequired(),
    }),
    description: Yup.string()
        .when('workOrderItemsType', {
            is: NewOrderType.NEW,
            then: Yup.string().max(1024).notRequired(),
            otherwise: Yup.string().notRequired().nullable(),
        })
        .nullable(),
});

export const FormikFragmentInitialStateWorkOrderItem = {
    title: '',
    description: '',
    todoItems: [
        {
            orderIndex: 0,
            id: 1,
            title: 'Job Complete',
        },
    ],
    workOrderItemsType: NewOrderType.SERVICE_JOB_TEMPLATE,
};

export const FormikFragmentWorkOrderJobPayload = connect<{ name?: string; options: FormikRadioField['options'] }>(
    ({ formik, name, options }) => {
        const nn = (fieldName) => (name ? `${name}.${fieldName}` : fieldName);
        const orderType = getIn(formik.values, nn('type'));
        const showOptionsPicker = options?.length > 1;

        return (
            <>
                {showOptionsPicker && (
                    <>
                        <Form.Group inline>
                            <FormikRadioField inline={true} name={nn('type')} options={options} />
                        </Form.Group>
                        <Divider />
                    </>
                )}

                <FormSectionBody>
                    {orderType === NewOrderType.SERVICE_JOB_TEMPLATE && (
                        <FormikPickerServiceJobTemplate name={nn('serviceJobTemplate')} />
                    )}
                    {orderType === NewOrderType.NEW && <FormikInputJobTodo name={nn('adHocWorkOrder')} />}
                </FormSectionBody>
            </>
        );
    }
);
