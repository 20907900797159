import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef, Display } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { InvoiceDoc, InvoiceStatusLabel } from '../constants';
import { useViewer } from '@poolware/app-shell';
import { DisplayCurrency } from '../components/DisplayCurrency';

export const useInvoiceListTableDef = (conf?: {
    showCaseColumn?: boolean;
}): ConnectionTableDef<NodeType.AbstractDocument> => {
    const { isAdmin } = useViewer();
    return useMemo(
        () => [
            {
                header: 'Ref Number',
                sortKey: 'refNumber',
                width: '1',
                cell: (r) => {
                    return r.refNumber;
                },
            },
            {
                header: 'Issued',
                sortKey: 'issuedAt',
                width: '1',
                cell: (r) => {
                    return <Display.Date value={r.issuedAt} format={'ll'} />;
                },
            },
            {
                header: 'Status',
                width: '1',
                cell: (r) => {
                    return <InvoiceStatusLabel status={r.statusTag} />;
                },
            },

            {
                header: 'Amount',
                width: 1,
                cell: (r) => {
                    if (!r.jsonBody) {
                        return null;
                    }
                    const invoice: InvoiceDoc.DocContent = r?.jsonBody;
                    return <DisplayCurrency value={invoice.totalAmountPayable} />;
                },
            },

            {
                header: 'Customer',
                cell: (r) => {
                    return <Display.Entity value={r.customer} />;
                },
            },
            {
                header: 'Work Order',
                cell: (r) => {
                    const { workOrder } = r;
                    if (!workOrder) {
                        return null;
                    }
                    return (
                        <div>
                            #{workOrder.workOrderNumber} - {workOrder?.title}
                        </div>
                    );
                },
            },
            conf?.showCaseColumn && {
                header: 'Case',
                cell: (r) => {
                    const { serviceJob } = r;
                    if (!serviceJob) {
                        return null;
                    }
                    const groupTitle = serviceJob?.group?.title;
                    return (
                        <div>
                            {groupTitle && <span tw={'pl-1 font-bold text-purple-600'}>{groupTitle}</span>}#
                            {serviceJob.serviceJobNumber}
                            {serviceJob?.title && <span> - {serviceJob?.title}</span>}
                        </div>
                    );
                },
            },

            false && {
                header: 'Type',
                cell: (r) => {
                    return <div>{r.typeTag}</div>;
                },
            },
            isAdmin && {
                header: 'Franchise',
                cell: (r) => {
                    return <div>{r.franchise?.name}</div>;
                },
            },
        ],
        [isAdmin, conf]
    );
};
