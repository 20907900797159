import { bindActionCreators, combineReducers } from 'redux';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { createAction, createAutoBoundReducerActions, createReducer } from '@ez/tools';
import { createStructuredSelector } from 'reselect';
import { ConnectionPageStateType, createPageIndexReducerActions, defaultConnectionPageState } from '@poolware/api';

const SCOPE = '@ServiceJobTemplateList';
enum ACTION_TYPES {
    CLEAR_ALL_FILTER = '@ServiceJobTemplateList/CLEAR_ALL_FILTER',
}

export type ServiceJobListStateType = {
    filters: {
        serviceGroupId: string;
        hasActiveFilters: boolean;
        includeArchived: boolean;
    };
    page: ConnectionPageStateType;
};

const initialState: ServiceJobListStateType = {
    filters: {
        serviceGroupId: null,
        hasActiveFilters: false,
        includeArchived: false,
    },
    page: defaultConnectionPageState,
};

const FiltersReducerActions = createAutoBoundReducerActions(initialState.filters, SCOPE, {
    setServiceJobGroup: { field: 'serviceGroupId' },
    setIncludeArchived: { field: 'includeArchived' },
});

const CustomActions = {
    clearAllFilters: () => createAction(ACTION_TYPES.CLEAR_ALL_FILTER, undefined, SCOPE),
};

const PageIndexReducerActions = createPageIndexReducerActions({
    scope: SCOPE,
    initialState: initialState.page,
    resetIndexActionTypes: [...FiltersReducerActions.actionTypes, ACTION_TYPES.CLEAR_ALL_FILTER],
});

const hasActiveFilters = (state: ServiceJobListStateType['filters']) => {
    return !!state.serviceGroupId;
};

const filtersReducer = createReducer(
    initialState.filters, //
    (state, action: any): ServiceJobListStateType['filters'] => {
        const reducer = FiltersReducerActions.reducers[action.type];
        if (reducer) {
            reducer(state, action.payload);
        } else {
            if (action.type === ACTION_TYPES.CLEAR_ALL_FILTER) {
                return initialState.filters;
            }
        }
        state.hasActiveFilters = hasActiveFilters(state);
        return state;
    },
    SCOPE
);

export const reducerSJTList = combineReducers({
    filters: filtersReducer,
    page: PageIndexReducerActions.reducer,
});

const _memoizedSelector = createStructuredSelector<ServiceJobListStateType, ServiceJobListStateType>({
    filters: (state) => state.filters,
    page: (state) => state.page,
});

export const useServiceJobTemplateListActions = () => {
    const state = useSelector((state: RootStateOrAny) => _memoizedSelector(state.serviceJob.serviceJobListTemplate));
    const dispatch = useDispatch();
    const allActions = { ...FiltersReducerActions.actions, ...PageIndexReducerActions.actions, ...CustomActions };

    return {
        State: state,
        Actions: bindActionCreators(allActions, dispatch),
    };
};
