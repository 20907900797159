import gql from 'graphql-tag';
import { NodeType } from '@poolware/api';
import { useQuery } from 'react-apollo';

const checkPermissionsFragment = gql`
    fragment CheckPermissionsFragment on Viewer {
        checkPermissions {
            AppointmentGroup {
                create
                delete
                update
            }
            Site {
                create
                delete
                update
            }
            Pool {
                create
                delete
                update
            }
            Customer {
                delete
                update
                create
            }
            StaffRegistration {
                create
                update
                forcePassword
                delete
                disable
                enable
            }
            Franchise {
                create
                delete
                update
            }
            FranchiseShop {
                create
                delete
                update
            }
            Staff {
                create
                update
            }
            CustomerTag {
                create
                delete
                update
            }
            ServiceJobGroup {
                create
                delete
                update
            }
        }
    }
`;

export const MeQuery = gql`
    query MeQuery {
        viewer {
            me {
                featureFlags
                staff {
                    role {
                        id
                        name
                    }
                }
            }
            ...CheckPermissionsFragment
        }
    }
    ${checkPermissionsFragment}
`;

export const useQueryPermissions = () => {
    const { loading, error, data, refetch } = useQuery<{ viewer: NodeType.Viewer }>(MeQuery, {
        fetchPolicy: 'cache-and-network',
    });

    return {
        error,
        loading,
        viewerPermissions: data.viewer?.checkPermissions,
        refetch,
        data,
    };
};
