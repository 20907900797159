import * as React from 'react';
import { TreeNode, unboxTreeNode } from '../utils';
import { List } from 'semantic-ui-react';

const defaultItemRenderer = (value) => <span>{value}</span>;

export interface TreeRendererProps<T = any> {
    tree: TreeNode<T>[];
    itemRenderer?: (pd: T) => any;
}

export const TreeRenderer: React.FC<TreeRendererProps> = ({ tree, itemRenderer = defaultItemRenderer }) => {
    const renderTreeItem = (node: TreeNode<any>) => {
        const value = unboxTreeNode(node);
        return (
            <List.Item key={node.id}>
                <List.Icon name={node.parentId ? 'caret right' : 'caret right'} color={'grey'} />
                <List.Content>
                    {itemRenderer(value)}
                    {node.children && renderTree(node.children)}
                </List.Content>
            </List.Item>
        );
    };

    const renderTree = (tree: any[], root = false) => {
        if (tree.length === 0) return null;
        return (
            <List size={root ? 'small' : undefined}>
                {tree.map((pd) => {
                    return renderTreeItem(pd);
                })}
            </List>
        );
    };

    return renderTree(tree, true);
};
