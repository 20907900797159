import { combineReducers } from 'redux';
import * as SJB from './reducer-service-job-booking';
import * as WO from './reducer-work-order-list';
import * as WF from './reducer-work-order-feed';
import * as SJ from './reducer-service-job-list';
import * as RJ from './reducer-recur-service-job-list';
import { reducerSJTList } from '../ServiceJobTemplatesManager/ServiceJobTemplate/List/reducer';

export const reducerServiceJob = combineReducers({
    booking: SJB.reducerBooking,
    workOrderList: WO.reducerWOList,
    workOrderFeed: WF.reducerWOFeed,
    serviceJobList: SJ.reducerSJList,
    recurJobList: RJ.reducerRSJList,
    serviceJobListTemplate: reducerSJTList,
});

export const deserializeServiceJob = (restoredState?: ReturnType<typeof reducerServiceJob>) => {
    return {
        serviceJobList: SJ.deserializeServiceJobList(restoredState?.serviceJobList),
        workOrderList: WO.deserializeWorkOrderList(restoredState?.workOrderList),
        workOrderFeed: WF.deserializeWorkOrderFeed(restoredState?.workOrderFeed),
        booking: restoredState?.booking,
    };
};

export const serializeServiceJob = (state?: ReturnType<typeof reducerServiceJob>) => {
    return {
        serviceJobList: SJ.serializeServiceJobList(state?.serviceJobList),
        workOrderList: WO.serializeWorkOrderList(state?.workOrderList),
        workOrderFeed: WF.serializeWorkOrderFeed(state?.workOrderFeed),
        booking: state?.booking,
    };
};
