import { NodeType } from '@poolware/api';
import { createAction, createReducer } from '@ez/tools';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useEffect, useMemo, useReducer } from 'react';
import { DateRange } from '@poolware/components';

export type FilterType = {
    sanitiser: string;
    poolType: string | null;
    lastTestDateRange: DateRange | null;
    lastTestBefore: Date | null;
    lastTestAfter: Date | null;
    customerName: string | null;
    franchiseNode: NodeType.Franchise | null;
    volumeGT?: string;
    volumeLT?: string;
    pageIndex: number;
};

const searchReducerInitialState: FilterType = {
    lastTestAfter: null,
    lastTestBefore: null,
    lastTestDateRange: null,
    sanitiser: null,
    poolType: null,
    customerName: null,
    franchiseNode: null,
    volumeGT: null,
    volumeLT: null,
    pageIndex: 0,
};

type ActionMap<M extends { [index: string]: any }, Ext> = {
    type: keyof M | Ext;
    payload?: any;
};

const searchReducer = createReducer(
    searchReducerInitialState,
    (state: FilterType, action: ActionMap<FilterType, 'clear'>) => {
        if (action.type === 'pageIndex') {
            state.pageIndex = action.payload;
            return;
        }
        if (action.type === 'clear') {
            return searchReducerInitialState;
        }
        // Reset pageIndex whenever any of the search queries change.
        // @ts-ignore
        state[action.type] = action.payload;
        state.pageIndex = 0;
        return state;
    }
);

export const useFilterPools = () => {
    const { query, AppNavigator } = useAppNavigator();

    const initialValues = useMemo<FilterType>(() => {
        const fid = query?.fid;
        const fin = query?.fin;
        return {
            ...searchReducerInitialState,
            franchiseNode: fid && fin ? { id: fid, name: fin } : null,
            // pageIndex: Number(query?.page) || 0,
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [state, dispatch] = useReducer(searchReducer, initialValues);

    useEffect(() => {
        AppNavigator.setSearchQueries({
            fid: state.franchiseNode?.id,
            fin: state.franchiseNode?.name,
            // page: state.pageIndex?.toString() || '0',
        });
    }, [state]);

    const actions = useMemo(() => {
        function ca<T extends keyof FilterType = keyof FilterType>(name: T) {
            return (value: FilterType[T]) => dispatch(createAction(name, value));
        }

        const setCustomerName = ca('customerName');
        const setLastTestDateRange = ca('lastTestDateRange');
        const setLastTestBefore = ca('lastTestBefore');
        const setLastTestAfter = ca('lastTestAfter');
        const setVolumeGT = ca('volumeGT');
        const setVolumeLT = ca('volumeLT');
        const setPoolType = ca('poolType');
        const setSanitiserName = ca('sanitiser');
        const setFranchiseNode = ca('franchiseNode');
        const setPageIndex = ca('pageIndex');
        const clearFilters = () => dispatch(createAction('clear'));
        return {
            setPoolType,
            setSanitiserName,
            setCustomerName,
            setFranchiseNode,
            setPageIndex,
            setLastTestDateRange,
            setLastTestBefore,
            setLastTestAfter,
            setVolumeGT,
            setVolumeLT,
            clearFilters,
        };
    }, [dispatch]);

    return {
        state,
        actions,
    };
};
