import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import {
    ConnectionErrorMessage,
    EmbeddedPage,
    MenuBar,
    PageSkeletonLoader,
    Panel,
    StickyMenuBar,
    toastError,
    useModalCtrl,
    VStack,
} from '@poolware/components';
import { Modal } from 'semantic-ui-react';
import { useCustomContractMutator, useQueryCustomContract } from './use-query-custom-contract';
import { NotFoundPage } from '@poolware/app-shell';
import 'twin.macro';
import { ContractStatus } from './components';
import { FormCustomContractEdit } from './FormCustomContract';
import { PanelFileAttachments } from './PanelFileAttachments/PanelFileAttachments';

export interface PanelCustomContractProps {
    contractId: NodeType.ID;
    onClose: () => any;
    refetchQueries: any[];
}
export const CustomContractView: React.FC<PanelCustomContractProps> = ({
    contractId,
    onClose,
    refetchQueries = [],
}) => {
    const modalCtrlEdit = useModalCtrl(false);

    const { node, refetchQuery, loading, error } = useQueryCustomContract(contractId);
    const _refetchQueries = [...refetchQueries, refetchQuery];
    const { addFiles, removeFiles } = useCustomContractMutator({ refetchQueries: _refetchQueries });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <NotFoundPage />;
    }

    const onRemoveFiles = async (files: NodeType.FileUpload[]) => {
        try {
            await removeFiles(node, files);
        } catch (e) {
            toastError(e);
        }
    };

    const onAddFiles = async (fileIds: NodeType.ID[]) => {
        try {
            await addFiles(node, fileIds);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <>
            <EmbeddedPage>
                <EmbeddedPage.Menu>
                    <StickyMenuBar>
                        <MenuBar.Section>
                            <MenuBar.Item onClick={onClose} icon={'cancel'} title={'Close'} />
                            <MenuBar.HeaderItem color={'blue'} icon={'file alternate'}>
                                Customer Contract - {node.contractNumber}
                            </MenuBar.HeaderItem>
                        </MenuBar.Section>
                    </StickyMenuBar>
                </EmbeddedPage.Menu>
                <EmbeddedPage.Body>
                    <VStack>
                        <Panel>
                            <Panel.Header
                                content={'Customer Contract'}
                                button={{ content: 'Edit', icon: 'edit', onClick: modalCtrlEdit.onOpen }}
                            />
                            <Panel.Body>
                                <Panel.Item label={'Status'}>
                                    <ContractStatus contract={node} />
                                </Panel.Item>
                                <Panel.Item label={'Contract Number'} content={node.contractNumber} />
                                <Panel.ItemDate label={'Start Date'} content={node.startDate} format={'ll'} />
                                <Panel.ItemDate label={'End Date'} content={node.endDate} format={'ll'} />
                                <Panel.ItemDate
                                    label={'Termination Date'}
                                    content={node.terminationDate}
                                    format={'ll'}
                                    defaultString={'--'}
                                />
                                <Panel.Item label={'Frequency'} content={node.frequency || ''} />
                                <Panel.Item label={'Contract Term'} content={node.term || ''} />
                                <Panel.Item label={'Rate'} content={node.rate || ''} />
                                <Panel.ItemText label={'Notes'} content={node.note} />
                            </Panel.Body>
                        </Panel>
                        <PanelFileAttachments
                            fileAttachments={fromEdges(node.files)}
                            addFiles={onAddFiles}
                            removeFiles={onRemoveFiles}
                            readOnly={false}
                        />
                    </VStack>
                </EmbeddedPage.Body>
            </EmbeddedPage>
            <Modal {...modalCtrlEdit} closeOnDimmerClick={false}>
                <FormCustomContractEdit
                    contract={node}
                    refetchQueries={_refetchQueries}
                    onComplete={modalCtrlEdit.onClose}
                    onDidDelete={onClose}
                />
            </Modal>
        </>
    );
};
