import * as React from 'react';
import { useCalendarActions } from '../../../redux';
import { AppointmentStatusFilter } from './components/AppointmentStatusFilter';

export const FilterByAppointmentStatus: React.FC = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();
    const { includeStatuses = [] } = CalendarState.filters;
    return (
        <AppointmentStatusFilter
            includeStatuses={includeStatuses}
            setAppointmentStatusFilter={CalendarAction.setAppointmentStatusFilter}
        />
    );
};
