import * as React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { HeatLossModel } from '../common/types';
import { Segment } from 'semantic-ui-react';

interface HeatGraphProps {
    withCover: boolean;
    heatLoss: HeatLossModel[];
}

const tooltipFormatter = (value, name, props) => {
    return Number(value).toFixed(2);
};
const HeatGraph: React.FC<HeatGraphProps> = ({ heatLoss, withCover }) => {
    const data = heatLoss.map((hl) => ({
        ...hl,
    }));

    const allValues = data.flatMap((i) => {
        return [i.covered, i.uncovered];
    });

    let max = Math.round(Math.max(...allValues) * 1.1 + 5);
    max = Math.max(max, 50);

    const barFillColor = {
        covered: '#ffb32d',
        uncovered: '#ff4f51',
    };
    const barStrokeColor = {
        covered: withCover ? '#cb3e00' : '#ffb32d',
        uncovered: !withCover ? '#a00600' : '#ff5b58',
    };

    return (
        <Segment style={{ width: '100%', height: '320px' }}>
            <h4>Heat Loss Covered vs Uncovered</h4>

            <ResponsiveContainer>
                <BarChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 30,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis domain={[0, max]} />
                    <Tooltip formatter={tooltipFormatter} />
                    <Legend />
                    <Bar
                        name="Covered"
                        dataKey="covered"
                        barSize={20}
                        fill={barFillColor['covered']}
                        stroke={barStrokeColor['covered']}
                        strokeWidth={1}
                    />
                    <Bar
                        name="Uncovered"
                        dataKey="uncovered"
                        barSize={20}
                        fill={barFillColor['uncovered']}
                        stroke={barStrokeColor['uncovered']}
                        strokeWidth={1}
                    />
                </BarChart>
            </ResponsiveContainer>
        </Segment>
    );
};

export default HeatGraph;
