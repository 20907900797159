import * as React from 'react';
import { useState } from 'react';
import { Field, getIn } from 'formik';
import { FormikFormFieldLabel } from '@poolware/components';
import { Button, Divider, Form, Modal } from 'semantic-ui-react';
import { ProductPreview } from './components';
import { ProductPicker } from '@poolware/app-catalog';

export const ProductPickerField: React.FC<{ label; name; required; onNewProductCreateRequest }> = ({
    label,
    name,
    required,
    onNewProductCreateRequest,
}) => {
    const [showPicker, setShowPicker] = useState(false);
    const closePicker = () => {
        setShowPicker(false);
    };
    const openPicker = () => {
        setShowPicker(true);
    };

    const handleOnNewProductCreate = (suggestedValues) => {
        setShowPicker(false);
        onNewProductCreateRequest(suggestedValues);
    };

    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleOnNewProductSelect = (product) => {
                    form.setFieldValue(field.name, product);
                    closePicker();
                };

                const handleRemove = () => {
                    form.setFieldValue(field.name, null);
                };

                const productName = field.value?.['name'] || '';
                const touched = getIn(form.touched, name);
                const error = getIn(form.errors, name);

                let hasError = Boolean(touched && !!error);
                const labelComp = <FormikFormFieldLabel htmlFor={name} label={label} name={name} required={required} />;

                return (
                    <>
                        <Form.Input
                            error={hasError}
                            value={productName}
                            type={'text'}
                            label={labelComp}
                            readOnly={true}
                            action
                        >
                            <input
                                style={{ backgroundColor: '#f8f8f8', cursor: 'pointer' }}
                                onClick={() => openPicker()}
                            />
                            {field.value && <Button onClick={handleRemove} type={'button'} basic icon={'delete'} />}
                            <Button type={'button'} icon={'search'} content={'Find in Catalog'} onClick={openPicker} />
                        </Form.Input>

                        {field.value && (
                            <>
                                <ProductPreview product={field.value} />
                                <Divider hidden={true} />{' '}
                            </>
                        )}

                        <Modal open={showPicker} centered={false} onClose={closePicker} closeOnEscape={false}>
                            <Modal.Content>
                                <ProductPicker
                                    onNewProductCreate={handleOnNewProductCreate}
                                    onCancel={closePicker}
                                    onSelect={handleOnNewProductSelect}
                                />
                            </Modal.Content>
                        </Modal>
                    </>
                );
            }}
        </Field>
    );
};
