import {
    addressFragment,
    createUseQueryConnectionHook,
    NodeType,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { QueryConnectionConfigProps } from '@poolware/api';

const QL = gql`
    query QueryWorkOrdersConnection(
        $first: Int
        $page: Int
        $search: WorkOrderViewerWorkOrdersSearch
        $sort: WorkOrderViewerWorkOrdersSort
    ) {
        viewer {
            workOrders(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        title
                        createdAt
                        stage {
                            id
                            title
                            status
                            type
                        }
                        workOrderNumber
                        appointmentItem {
                            id
                            startDate
                        }
                        serviceJob {
                            id
                            serviceJobNumber
                            title
                            stage {
                                id
                                title
                                status
                                type
                            }
                            isRecurring
                        }
                        assignedTo {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                            role {
                                id
                                name
                            }
                        }
                        customer {
                            id
                            crn
                            companyName
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        pool {
                            id
                            name
                            volume
                            type {
                                id
                                name
                            }
                        }
                        address {
                            id
                            ...AddressFragment
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${addressFragment}
`;

export enum SearchByAssignmentState {
    ANY = 1,
    UNASSIGNED = 1,
    ASSIGNED = 2,
}

export interface QueryWorkOrdersConnectionProps {
    isRecurring?: boolean;
    includeArchived?: boolean;
    workOrderNumber?: string;
    hasAppointment?: boolean;
    assignedToStaff?: NodeType.NodeOrId<NodeType.Staff>;
    assignmentState?: SearchByAssignmentState;
    serviceJob?: NodeType.NodeOrId<NodeType.ServiceJob>;
    stageType?: NodeType.ServiceJobStageType[];
}

const mapPropsToSearchQuery = (props: QueryWorkOrdersConnectionProps): NodeType.WorkOrderViewerWorkOrdersSearch => {
    if (!props) {
        return undefined;
    }

    const { assignedToStaff, assignmentState, workOrderNumber, hasAppointment, serviceJob, isRecurring } = props;

    const search: NodeType.WorkOrderViewerWorkOrdersSearch = {};

    if (hasAppointment !== null && hasAppointment !== undefined) {
        _.set(search, 'hasAppointment', Boolean(hasAppointment));
    }

    if (isRecurring !== undefined && isRecurring !== null) {
        _.set(search, 'serviceJob.isRecurring.is', Boolean(isRecurring));
    }

    if (assignmentState === SearchByAssignmentState.ASSIGNED) {
        _.set(search, 'assignedTo.not', null);
    }

    if (assignmentState === SearchByAssignmentState.UNASSIGNED) {
        _.set(search, 'assignedTo.id', null);
    }

    if (assignedToStaff) {
        _.set(search, 'assignedTo.id', NodeType.extractId(assignedToStaff));
    }

    if (workOrderNumber !== undefined && workOrderNumber !== null) {
        _.set(search, 'workOrderNumber', workOrderNumber);
    }
    if (serviceJob) {
        _.set(search, 'serviceJob.id', NodeType.extractId(serviceJob));
    }

    if (props.stageType) {
        _.set(search, 'stageType', props.stageType?.filter(Boolean));
    }

    if (props.includeArchived) {
        // pass empty object to include work orders archived at any time
        _.set(search, 'archivedAt', {});
    }

    return search;
};

const connectionPath = 'viewer.workOrders';

export const useQueryWorkOrdersConnection = createUseQueryConnectionHook<
    NodeType.WorkOrder,
    NodeType.WorkOrderViewerWorkOrdersSearch,
    QueryWorkOrdersConnectionProps
>({
    query: QL,
    connectionPath,
    mapPropsToSearchVariables: mapPropsToSearchQuery,
    connectionConfig: {
        defaultPageSize: 20,
        defaultSortDirection: SortDirection.DES,
        sortKey: 'createdAt',
        debugName: 'useQueryWorkOrdersConnection',
    },
});
//
// export function useQueryWorkOrdersConnection1(props?: QueryWorkOrdersConnectionProps) {
//     const { variables, connectionState } = useDefaultConnectionState({
//         defaultSortKey: 'createdAt',
//         defaultSortDirection: SortDirection.DES,
//         search: mapPropsToSearchQuery(props),
//         ...props,
//     });
//
//     return useQueryConnection<NodeType.WorkOrder>({
//         query: QL,
//         fetchPolicy: 'cache-and-network',
//         connectionPath: connectionPath,
//         connectionConfig: connectionState,
//         variables: variables,
//     });
// }
