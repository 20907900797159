import * as React from 'react';
import { useEffect, useState } from 'react';
import { ModuleLink } from '@poolware/react-app-navigator';
import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';
import { Button, Icon, List, Modal, Segment } from 'semantic-ui-react';
import { TraitDeclarationPicker_Tree } from '../../../components-api-connected';
import styled from 'styled-components';

const DeclarationPickerContainer = styled(Segment)`
    max-height: 500px;
    overflow-y: auto;
`;

const DeclarationPickerModal: React.FC<{
    traitDeclaration: NodeType.ProductTraitDeclaration;
    onUpdate: (newValue) => any;
}> = ({ traitDeclaration, onUpdate }) => {
    const originalParent = _.get(traitDeclaration, 'parent');

    const [modalOpen, setModalOpen] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [selectedParent, setSelectedParent] = useState(originalParent);

    useEffect(() => {
        // reset selection on modal open;
        setSelectedParent(originalParent);
    }, [modalOpen]);

    const parentDeclarations = fromEdges(_.get(traitDeclaration, 'parentDeclarations'));
    parentDeclarations.reverse();

    if (parentDeclarations.length === 0) {
        parentDeclarations.push({ id: null, name: '---  ' });
    }

    const onSelect = async (newParent?: NodeType.ProductTraitDeclaration) => {
        if (newParent && newParent.id === traitDeclaration.id) return;
        setSelectedParent(newParent);
    };
    const allowUpdate = !isUpdating && _.get(selectedParent, 'id') !== _.get(originalParent, 'id');

    const commitUpdate = async () => {
        if (!allowUpdate) return;
        setUpdating(true);
        const newId = selectedParent ? selectedParent.id : selectedParent;
        await onUpdate(newId);
        setUpdating(false);
        setModalOpen(false);
    };

    return (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            trigger={
                <span style={{ marginLeft: '10px' }}>
                    <Button
                        icon={'linkify'}
                        content={'Change parent'}
                        basic
                        compact
                        size={'mini'}
                        onClick={() => setModalOpen(true)}
                    />
                </span>
            }
        >
            <Modal.Header>Select new parent for "{traitDeclaration.name}"</Modal.Header>
            <Modal.Content>
                <button onClick={() => onSelect(null)}>Remove parent</button>
                <DeclarationPickerContainer>
                    <TraitDeclarationPicker_Tree onSelect={(pd) => onSelect(pd)} current={[selectedParent]} />
                </DeclarationPickerContainer>
            </Modal.Content>
            <Modal.Actions>
                <span style={{ padding: '0.5em 0', float: 'left' }}>
                    Current Parent: <b>{_.get(originalParent, 'name', 'N/A')}</b>{' '}
                    <Icon name={'long arrow alternate right'} /> New Parent:{' '}
                    <b>{_.get(selectedParent, 'name', 'N/A')}</b> {'  '}
                </span>
                <Button disabled={isUpdating} onClick={() => setModalOpen(false)}>
                    Cancel
                </Button>
                <Button
                    disabled={!allowUpdate}
                    loading={isUpdating}
                    positive
                    icon="checkmark"
                    labelPosition="right"
                    content="Change"
                    onClick={commitUpdate}
                />
            </Modal.Actions>
        </Modal>
    );
};

export const TraitRelationshipTree: React.FC<{
    traitDeclaration: NodeType.ProductTraitDeclaration;
    onUpdate: (newValue) => any;
    onAddSubdeclaration: (parent: NodeType.ProductTraitDeclaration) => any;
}> = ({ traitDeclaration, onUpdate, onAddSubdeclaration }) => {
    const parentDeclarations = fromEdges(_.get(traitDeclaration, 'parentDeclarations'));
    parentDeclarations.reverse();

    if (parentDeclarations.length === 0) {
        parentDeclarations.push({ id: null, name: '---' });
    }

    const subDeclarations = fromEdges(_.get(traitDeclaration, 'subDeclarations'));

    const renderSelfAndChildren = () => {
        return (
            <List.Item>
                <List.Icon name={'sort'} />
                <List.Content>
                    <b>{traitDeclaration.name}</b>{' '}
                    <List.List>
                        {subDeclarations.map((pd) => {
                            return (
                                <List.Item key={pd.id}>
                                    <span>
                                        <Icon name={'caret right'} />
                                        <ModuleLink to={`/${pd.id}`}>{pd.name}</ModuleLink>
                                    </span>
                                </List.Item>
                            );
                        })}
                        <List.Item>
                            <span>
                                <Icon name={'caret right'} />
                                <Button
                                    icon={'plus'}
                                    content={'Add sub declaration'}
                                    basic
                                    compact
                                    size={'mini'}
                                    onClick={() => onAddSubdeclaration(traitDeclaration)}
                                />
                            </span>
                        </List.Item>
                    </List.List>
                </List.Content>
            </List.Item>
        );
    };

    return (
        <List>
            {parentDeclarations.map((pd, index) => {
                const isLast = parentDeclarations.length - 1 === index;
                return (
                    <List.Item key={pd.id}>
                        <List.Icon name={'caret down'} />
                        <List.Content>
                            {pd.id ? <ModuleLink to={`/${pd.id}`}>{pd.name}</ModuleLink> : pd.name}
                            {isLast && (
                                <DeclarationPickerModal traitDeclaration={traitDeclaration} onUpdate={onUpdate} />
                            )}
                            {isLast && <List.List>{renderSelfAndChildren()}</List.List>}
                        </List.Content>
                    </List.Item>
                );
            })}
        </List>
    );
};
