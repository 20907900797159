import * as React from 'react';
import { NodeType, QueryConnection, QueryConnectionExternalProps, useDefaultConnectionState } from '@poolware/api';
import moment from 'moment';
import { DateRange, filterAppointmentItems } from '@poolware/app-service-jobs';

export interface QueryAppointmentItemsConnectionExternalProps
    extends QueryConnectionExternalProps<NodeType.AppointmentItem> {
    dateRange: DateRange;
    customerId?: string;
    poolId?: string;
    franchiseId?: string;
    filters?: any;
    pollInterval?: number;
    reverse?: boolean;
}

export const mapPropsToSearchQuery = (props: Omit<QueryAppointmentItemsConnectionExternalProps, 'children'>) => {
    if (!props) {
        return undefined;
    }
    const { poolId, dateRange, franchiseId, customerId } = props;
    const isoStartDate = moment(dateRange.startDate).toISOString();
    const isoEndDate = dateRange.endDate ? moment(dateRange.endDate).add(1, 's').toISOString() : undefined;

    return {
        reverse: props.reverse,
        first: props.pageSize || 1000,
        franchise: franchiseId ? franchiseId : undefined,
        customer: customerId ? customerId : undefined,
        pool: poolId,
        startDate: isoStartDate,
        endDate: isoEndDate,
    };
};

const QueryAppointmentItemsConnection: React.FC<QueryAppointmentItemsConnectionExternalProps> = ({
    filters = {},
    ...props
}) => {
    let { variables, connectionState } = useDefaultConnectionState(props);
    variables = { ...variables, ...mapPropsToSearchQuery(props) };
    const postfilter = (connectionData) => filterAppointmentItems(connectionData, filters);
    return (
        <QueryConnection
            query={props.query}
            pollInterval={props.pollInterval}
            skip={props.skip}
            fetchPolicy={props.fetchPolicy}
            errorPolicy={props.errorPolicy || 'ignore'}
            variables={variables}
            connectionPath={props.connectionPath}
            connectionConfig={connectionState}
            postfetchFilter={postfilter}
            children={props.children}
        />
    );
};

export default QueryAppointmentItemsConnection;
