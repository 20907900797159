import { ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import * as React from 'react';
import ProductsList from './ProductsList';
import ProductView from './ProductView';
import { Router as ProductDocsRouter } from './ProductDocs';
import { Sidebar } from './SecondarySidebar';

export const AdminRouterModuleID = 'HEATCALCS_ADMIN';

export const AdminRouter = () => {
    return (
        <ModuleRoot moduleName={'AdminRouterModuleID'}>
            <div tw={'flex flex-row h-full space-x-8'}>
                <div tw={'w-48 flex-shrink-0 h-full'}>
                    <Sidebar />
                </div>
                <div tw={'flex-grow flex-auto h-full'}>
                    <ModuleSwitch>
                        <ModuleRedirect path={'/'} to={'/products'} exact={true} />
                        <ModuleRoute path={'/products/:id'} component={ProductView} />
                        <ModuleRoute path={'/products'} component={ProductsList} />
                        <ModuleRoute path={'/docs'} component={ProductDocsRouter} />
                    </ModuleSwitch>
                </div>
            </div>
        </ModuleRoot>
    );
};
