import { toastError } from '@poolware/components';
import { fromEdges, NodeType } from '@poolware/api';
import * as React from 'react';
import { FileAttachmentsViewer } from '@poolware/app-shell';
import { useServiceJobMutators } from '../../../queries/mutators-service-job';

export interface FileAttachmentPanelProps {
    serviceJob: NodeType.ServiceJob;
    readOnly?: boolean;
    refetchQuery: any;
    title?: string;
}

const defaultTitle = 'Service Case File Attachments';

export const PanelFileAttachments: React.FC<FileAttachmentPanelProps> = ({
    serviceJob,
    readOnly = false,
    refetchQuery,
    title = defaultTitle,
}) => {
    const { ServiceJobMutator } = useServiceJobMutators({ refetchQueries: [refetchQuery] });

    const onFileAttachmentRemove = async (file: NodeType.FileUpload[]) => {
        try {
            return ServiceJobMutator.removeFile(serviceJob, file);
        } catch (e) {
            toastError(e);
        }
    };

    const onFileUploadComplete = async (fileId) => {
        await ServiceJobMutator.addFiles(serviceJob, [fileId]);
    };

    const fileAttachments = fromEdges(serviceJob.files);

    return (
        <FileAttachmentsViewer
            files={fileAttachments}
            onFileRemove={onFileAttachmentRemove}
            onFileDidUpload={onFileUploadComplete}
            readOnly={readOnly}
            title={title}
        />
    );
};
