import * as React from 'react';
import { AppointmentReportsActionProps, withAppointmentReportsActions } from '../../../redux';
import { DateRange } from '@poolware/app-service-jobs';
import { ActionBar, DateRangePickerButton } from '@poolware/components';

const DateRangeFilter: React.FC<AppointmentReportsActionProps> = ({
    AppointmentReportsState,
    AppointmentReportsAction,
}) => {
    const { filters } = AppointmentReportsState;
    const { dateRange } = filters;

    const handleDateRangeChange = (newDateRange: DateRange) => {
        AppointmentReportsAction.setDateRangeFilter(newDateRange);
    };

    return (
        <ActionBar>
            <ActionBar.Header content={'Date Range'} icon={'filter'} />
            <DateRangePickerButton
                initialDateRange={dateRange}
                maxRangeInWks={8}
                onChange={handleDateRangeChange}
                maxDate={new Date()}
            />
        </ActionBar>
    );
};

export default withAppointmentReportsActions()(DateRangeFilter);
