import * as React from 'react';
import { useCallback, useState } from 'react';
import SearchResultsTable from './SearchResultTable';
import { NodeType } from '@poolware/api';
import { VStack } from '@poolware/components';
import { Button, Segment } from 'semantic-ui-react';

export type NewProductCreateContextType = {
    declaration?: NodeType.ProductTraitDeclaration;
    name?: string;
    brand?: NodeType.Brand;
};

export interface ProductPickerExternalProps {
    onSelect: (item: NodeType.Product) => any;
    onCancel: () => any;
    onNewProductCreate?: (context: NewProductCreateContextType) => any;
}

const ProductPicker: React.FC<ProductPickerExternalProps> = ({ onSelect, onCancel, onNewProductCreate }) => {
    const [selectedItems, setSelectedItems] = useState<NodeType.Product[]>([]);

    const handleSelectChange = useCallback((items) => {
        setSelectedItems(items);
    }, []);

    const handleSubmit = () => {
        onSelect(selectedItems[0]);
    };

    const hasSelected = selectedItems && selectedItems.length > 0;

    return (
        <VStack>
            <SearchResultsTable onNewProductCreate={onNewProductCreate} onSelectedItemsChange={handleSelectChange} />
            <Segment basic clearing padded={false} style={{ marginTop: 0, padding: 0 }}>
                <Button primary={false} floated={'left'} onClick={onCancel}>
                    Cancel
                </Button>

                <Button disabled={!hasSelected} primary={true} floated={'right'} onClick={handleSubmit}>
                    Select
                </Button>
            </Segment>
        </VStack>
    );
};

export default ProductPicker;
