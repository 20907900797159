import { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useDebouncedCallback, useInterval } from '@ez/tools';
import { WaterlinkAPI, WaterlinkAPIClientInterface } from '../api-client';
import { useHeartbeat } from './use-heartbeat';
const semver = require('semver');

const SUPPORTED_API_VERSION = '>=1.5';

export type DeviceStatusType = {
    online: boolean;
    rawStatus: WaterlinkAPI.StatusReply;
    canStartTest: boolean;
    statusCode: number;
    statusString: string;
};

export type DeviceOptionsType = {
    all: WaterlinkAPI.OptionsReply | null;
    pool: WaterlinkAPI.OptionsReply | null;
    spa: WaterlinkAPI.OptionsReply | null;
    coolingwater: WaterlinkAPI.OptionsReply | null;
    drinkingwater: WaterlinkAPI.OptionsReply | null;
};

export const defaultDeviceStatus: DeviceStatusType = {
    online: false,
    rawStatus: null,
    canStartTest: false,
    statusCode: -1,
    statusString: 'Unknown',
};

export const defaultDeviceOptions: DeviceOptionsType = {
    all: null,
    pool: null,
    spa: null,
    coolingwater: null,
    drinkingwater: null,
};

const waterlinkStatusCodeToStringMapper = {
    0: 'Offline',
    1: 'Busy',
    2: 'Updating',
    3: 'Error',
    4: 'Requires Firmware Update',
    5: 'Ready',
};

export const useWaterlinkService = (apiClient: WaterlinkAPIClientInterface, serviceEnabled) => {
    const heartbeat = useHeartbeat();
    const [serverOnline, setServerOnline] = useState(false);
    const [isSupportedAPIVersion, setIsValidAPIVersion] = useState(true);
    const [serviceAPIVersion, setServiceAPIVersion] = useState('0');
    const [device, setDevice] = useState<DeviceStatusType>(defaultDeviceStatus);
    const [deviceOptions, setDeviceOptions] = useState<DeviceOptionsType>(defaultDeviceOptions);

    const parseRawStatus = (rawStatus: WaterlinkAPI.StatusReply) => {
        const statusCode = Number(rawStatus.Status);
        const isOnline = statusCode !== 0;
        let canStartTest = rawStatus.CanDoWatertest;

        setDevice({
            online: isOnline,
            rawStatus: rawStatus,
            canStartTest: canStartTest,
            statusCode: statusCode,
            statusString: waterlinkStatusCodeToStringMapper[statusCode] || 'Error',
        });

        heartbeat.reset();
    };

    const resetAll = () => {
        setDevice(defaultDeviceStatus);
        setDeviceOptions(defaultDeviceOptions);
        setServerOnline(false);
    };

    async function refreshServerAPIVersion() {
        try {
            if (!apiClient || !serviceEnabled) {
                return;
            }
            const resp = await apiClient.getAbout();
            if (resp) {
                // version check.
                setServiceAPIVersion(resp.version);

                const isSatisfies = semver.satisfies(semver.coerce(resp.version), SUPPORTED_API_VERSION);
                if (isSatisfies) {
                    setIsValidAPIVersion(true);
                } else {
                    setIsValidAPIVersion(false);
                }
            } else {
                setIsValidAPIVersion(false);
            }
        } catch (e) {
            resetAll();
            heartbeat.pushError();
            // console.error(e);
        }
    }

    async function refreshDeviceStatus() {
        try {
            if (!apiClient || !serviceEnabled) {
                resetAll();
                heartbeat.pushError();
                return;
            }
            const _rawStatus = await apiClient.getStatus();
            if (_rawStatus) {
                setServerOnline(true);
                parseRawStatus(_rawStatus);
            } else {
                resetAll();
                heartbeat.pushError();
            }
        } catch (e) {
            resetAll();
            heartbeat.pushError();
            // console.error(e);
        }
    }

    async function refreshDeviceOptions() {
        try {
            if (!serviceEnabled || !device || !device.online) {
                // offline or service disabled
                setDeviceOptions(defaultDeviceOptions);
                return;
            }

            const all = await apiClient.getOptions();

            if (_.get(all, 'StatusString') === 'Offline') {
                // still offline :(
                setDeviceOptions(defaultDeviceOptions);
                return;
            }

            // const pool = await waterlinkAPIClient.getOptions('pool');
            // const spa = await waterlinkAPIClient.getOptions('spa');
            // const drinkingWater = await waterlinkAPIClient.getOptions('drinkingwater');
            // const coolingWater = await waterlinkAPIClient.getOptions('coolingwater');
            setDeviceOptions({
                ...deviceOptions,
                all: all,
                // pool: pool,
                // spa: spa,
                // drinkingwater: drinkingWater,
                // coolingwater: coolingWater,
            });
        } catch (e) {
            // console.error(e);
            setDeviceOptions(defaultDeviceOptions);
        }
    }

    const [refreshDeviceStatusDebounced] = useDebouncedCallback(
        async () => {
            await refreshDeviceStatus();
        },
        1000,
        { leading: true }
    );

    const [refreshDeviceOptionsDebounced] = useDebouncedCallback(
        async () => {
            await refreshDeviceOptions();
        },
        1000,
        { leading: true }
    );

    const [refreshAll] = useDebouncedCallback(async () => {
        await refreshServerAPIVersion();
        await refreshDeviceStatus();
        await refreshDeviceOptions();
    }, 1000);

    useEffect(() => {
        heartbeat.reset();
        refreshAll();
    }, [apiClient, device.online, serviceEnabled]);

    const heartbeatInterval = serviceEnabled ? heartbeat.intervalMs : null;
    useInterval(refreshDeviceStatusDebounced, heartbeatInterval);

    const getDeviceStatus = async () => {
        return device;
    };

    const performWaterTest = async (options: { reagent: number; sample: number }) => {
        try {
            return await apiClient.doWaterTest(options);
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    return {
        isSupportedAPIVersion,
        serviceAPIVersion,
        device,
        serverOnline,
        deviceOptions,
        performWaterTest,
        getDeviceStatus,
        refreshAll,
        refreshDeviceOptions: refreshDeviceOptionsDebounced,
        refreshDeviceStatus: refreshDeviceStatusDebounced,
    };
};

export type WaterlinkServiceType = ReturnType<typeof useWaterlinkService>;
