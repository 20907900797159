import * as React from 'react';
import { useMemo } from 'react';
import { Divider } from 'semantic-ui-react';
import ContactsCard from '../../../CommonComponents/Contacts/Contacts';
import * as URLBuilder from '../../../../routes/url-builder';
import { Link } from 'react-router-dom';
import { AdminOnly, useAppLayoutMode, useViewer } from '@poolware/app-shell';

import TabSites from './TabSites';
import { PageControlProps } from './Page.Control';
import VendMenuItems from './VendMenuItems';
import {
    Display,
    MenuBar,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    PageHeader,
    PageLayout,
    PageTabs,
    Panel,
    StickyMenuBar,
    TabPaneConf,
    toast,
    useTabCtrl,
    VStack,
} from '@poolware/components';
import { PanelCustomerNotes } from './PanelCustomerNotes';
import PoolOwnershipHistoryPanel from './PoolOwnershipHistory';
import { AppIconNames, IconAppointment, IconRecurServiceCall, IconServiceCall } from '../../../../components/AppIcons';
import { PanelCustomerTags } from './PanelCustomerTags';
import { SalesPanel } from './TabSales';
import { WarrantyCases } from './WarrantyCases';
import { CustomContractList } from './CustomContract';
import { HeaterRecommendations } from './HeaterRecommendations';
import { PanelCustomerOfficer } from './PanelCustomerOfficer';
import { PanelServiceJobsSummary } from './PanelServiceJobsSummary';
import { PanelRecurringServicesHistory, PanelServiceCasesHistory } from '../../common-panels/ServiceJobHistoryPanel';
import { AppointmentsPanel } from '../../common-panels/AppointmentsPanel';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { PanelCustomerRegDate } from './PanelCustomerRegDate';
import { AppointmentPresentationMode } from '../../common-panels/AppointmentsPanel/AppointmetnsList';
import { TabStyleMODE } from '@ez/components';

const Tabs = Object.freeze({
    sites: 'sites',
    notes: 'notes',
    calendar: 'appointments',
    sales: 'sales',
    history: 'history',
    services: 'services',
    recServices: 'rec-services',
    warrantyClaims: 'warrantyClaims',
    contracts: 'contracts',
    heaterRecs: 'heater-rec',
});

interface CustomerPageProps extends PageControlProps {}

const CustomerPage: React.FC<CustomerPageProps> = (props) => {
    const { viewer, modulesAccess } = useViewer();
    const { customer, contactItems } = props;
    const { AppNavigator } = useAppNavigator();
    const { isMobile } = useAppLayoutMode();

    const navigate = (pathname) => {
        props.AppNavigator.navigate(pathname, {
            setOrigin: true,
            query: {
                customerId: customer.id,
            },
        });
    };

    const canEdit = viewer.Permissions?.Customer?.update || viewer.Permissions?.Customer?.delete;

    const onCustomerEdit = () => {
        if (canEdit) {
            navigate(URLBuilder.Customer(props.customer.id).edit);
        } else {
            const error = 'Cannot edit customer. No permissions';
            toast({ type: 'error', title: 'Cannot edit customer', description: 'No permissions' });
            console.error(error);
        }
    };

    const onBookAppointment = async () => {
        props.BookingAction.startFromCustomer({
            details: { customer: customer },
            returnTo: props.location,
        });
    };

    const onPrint = () => {
        const url = URLBuilder.Print().Customer(customer.id).view;
        props.AppNavigator.navigate(url, { setOrigin: true });
    };

    const onServiceCall = () => {
        props.ServiceJobAction.newServiceJob({
            customer: customer,
            address: customer.primaryAddress,
            returnTo: props.location.pathname,
        });
    };

    const onRecurServiceCall = () => {
        props.ServiceJobAction.newRecurServiceJob({
            customer: customer,
            address: customer.primaryAddress,
            returnTo: props.location.pathname,
        });
    };
    const onViewServiceTab = () => {
        props.AppNavigator.setTab(Tabs.recServices);
    };

    const franchiseVend = customer?.user?.entity?.franchise?.vend;

    const canPrint = modulesAccess.System?.printAccess;
    const accessCalendar = modulesAccess.FieldServices?.appointments;
    const accessServiceJobs = modulesAccess.FieldServices?.serviceJobs;
    const accessServiceJobRecur = modulesAccess.FieldServices?.recurringCasesShowPageLink;

    const panes: TabPaneConf[] = useMemo(() => {
        const customerVendLink = customer?.vendLink;

        return [
            modulesAccess.Pools?.enabled && {
                title: 'Sites & Pools',
                icon: 'map marker alternate',
                key: Tabs.sites,
                render: () => <TabSites customerId={customer.id} />,
            },
            accessServiceJobs && {
                key: Tabs.services,
                title: 'Cases',
                icon: AppIconNames.ServiceJob.ServiceCase,
                render: () => <PanelServiceCasesHistory customer={customer} />,
            },
            accessServiceJobRecur && {
                key: Tabs.recServices,
                title: 'Rec. Services',
                icon: AppIconNames.ServiceJob.RecurringService,
                render: () => <PanelRecurringServicesHistory customerId={customer.id} />,
            },
            accessCalendar && {
                key: Tabs.calendar,
                title: 'Appointments',
                icon: AppIconNames.ServiceJob.Appointment,
                render: () => <AppointmentsPanel customer={customer} mode={AppointmentPresentationMode.Customer} />,
            },
            modulesAccess.Warranties?.enabled && {
                key: Tabs.warrantyClaims,
                title: 'Warranty Claims',
                icon: AppIconNames.Warranty,
                render: () => <WarrantyCases customer={customer} />,
            },
            modulesAccess.Customers?.contracts && {
                key: Tabs.contracts,
                title: 'Contracts',
                icon: 'file alternate',
                render: () => <CustomContractList customerId={customer.id} />,
            },
            modulesAccess.Calculators?.saveRecommendations && {
                key: Tabs.heaterRecs,
                title: 'Recommendations',
                icon: 'calculator',
                render: () => <HeaterRecommendations customer={customer} />,
            },
            customerVendLink && {
                key: Tabs.sales,
                title: 'Sales',
                icon: 'tag',
                render: () => <SalesPanel customerId={customer.id} />,
            },
            modulesAccess.Pools?.enabled && {
                key: Tabs.history,
                title: 'Ownership History',
                icon: 'history',
                render: () => <PoolOwnershipHistoryPanel customerId={customer.id} />,
            },
        ];
    }, [customer, accessServiceJobs, modulesAccess]);

    const tabCtrl = useTabCtrl({ panes, defaultTab: AppNavigator.tab, onTabChange: AppNavigator.setTab });

    const panelButton = [
        {
            content: 'Edit',
            icon: canEdit ? 'edit' : 'lock',
            disabled: !canEdit,
            popup: canEdit
                ? undefined
                : {
                      content: 'Not enough permissions',
                  },
            onClick: onCustomerEdit,
        },
    ];

    const menuGroup1: MenuBarGroupProps = useMemo(() => {
        return {
            menu: {
                icon: 'plus',
                color: 'green',
                title: 'New...',
                // direction: 'left',
                menuMode: MenuBarGroupMODE.DROPDOWN_ON_TABLET,
            },
            items: [
                accessServiceJobs && {
                    icon: <IconServiceCall />,
                    onClick: onServiceCall,
                    title: 'New Case',
                },
                accessServiceJobRecur && {
                    icon: <IconRecurServiceCall />,
                    onClick: onRecurServiceCall,
                    title: 'New Recurring Service',
                },
                modulesAccess.FieldServices?.appointments && {
                    icon: <IconAppointment />,
                    onClick: onBookAppointment,
                    title: 'Book Appointment',
                },
                // accessHeatRec && {
                //     icon: 'calculator',
                //     onClick: () => HeatCalcAction.openCalc({ customer: customer }),
                //     title: 'Calc',
                // },
            ],
        };
    }, [accessServiceJobs, accessServiceJobRecur]);

    const menuGroup2: MenuBarGroupProps = {
        menu: {
            icon: 'bars',
            color: 'red',
            menuMode: MenuBarGroupMODE.DROPDOWN_ON_MOBILE,
        },
        items: [canPrint && { icon: 'print', color: 'blue', onClick: onPrint, title: 'Print' }],
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section position={'left'}>
                    {!isMobile && franchiseVend && <VendMenuItems customerId={customer.id} />}
                    <MenuBar.HeaderItem icon={'user'}>Customer</MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Group {...menuGroup1} />
                    <MenuBar.Group {...menuGroup2} />
                </MenuBar.Section>
            </StickyMenuBar>

            <PageHeader>
                {customer.crn} - <Display.Entity value={customer} />
                {customer.companyName && <span style={{ paddingLeft: '0.5em' }}>/ {customer.companyName}</span>}
            </PageHeader>

            <PageLayout.TwoColumns>
                <VStack>
                    <Panel>
                        <Panel.Header color={'grey'} icon="user" button={panelButton}>
                            Customer Details
                        </Panel.Header>
                        <Panel.Body>
                            <AdminOnly>
                                <Panel.Item color={'red'} label="Franchise">
                                    <Link to={URLBuilder.Franchise(customer?.user?.entity?.franchise?.id).view}>
                                        {customer?.user?.entity?.franchise?.name}
                                    </Link>
                                </Panel.Item>
                            </AdminOnly>
                            <ContactsCard contactItems={contactItems} />
                        </Panel.Body>
                    </Panel>
                    {modulesAccess.Customers?.cxofficer && <PanelCustomerOfficer customer={customer} />}
                    {modulesAccess.Customers?.registrationDate && <PanelCustomerRegDate customer={customer} />}
                </VStack>
                <VStack>
                    <PanelCustomerNotes customer={customer} />
                    <PanelCustomerTags customer={customer} />
                    <PanelServiceJobsSummary customer={customer} onViewAll={onViewServiceTab} />
                </VStack>
            </PageLayout.TwoColumns>

            <Divider hidden />

            <PageTabs {...tabCtrl} />
        </VStack>
    );
};

export default CustomerPage;
