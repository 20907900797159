import * as React from 'react';
import styled from 'styled-components';

import { checkCookie, detectBrowser } from '@ez/tools';

const browser = detectBrowser();

const BrowserWarningContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 1.1rem;
    background-color: #dd9ca6;
    z-index: 100000;
    padding: 0.5em 0.25em;
    text-align: center;
`;

const cookiesEnabled = checkCookie();
export const UnsupportedBrowserWarning: React.FC = () => {
    switch (browser?.name) {
        case 'chrome':
        case 'firefox':
        case 'edge':
            break;
        case 'android':
            return (
                <BrowserWarningContainer>
                    We're sorry, but this browser is not supported by Poolware. We recommend Chrome.
                </BrowserWarningContainer>
            );
        case 'ie':
            return (
                <BrowserWarningContainer>
                    We're sorry, but Internet Explorer is not supported by Poolware. IE is considered obsolete. We
                    recommend Chrome.
                </BrowserWarningContainer>
            );
        default:
            break;
    }

    if (!cookiesEnabled) {
        return (
            <BrowserWarningContainer>
                This service does not work without cookies enabled. Please enabled cookies in your web browser settings.
            </BrowserWarningContainer>
        );
    }

    return null;
};
