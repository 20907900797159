import * as React from 'react';
import { Button, ButtonProps, Dropdown, Icon, Modal, SemanticCOLORS, SemanticSIZES } from 'semantic-ui-react';
import { mapServiceJobStageTypeToIconProps, ModuleColorNames } from '../constants';
import { useWorkOrderMutators } from '../queries/mutators-work-order';
import { NodeType } from '@poolware/api';
import { useSafeState } from '@ez/tools';
import { useViewer } from '@poolware/app-shell';
import { PanelStageHistory, PanelStageHistoryType } from './PanelStageHistory';
import { useModalCtrl } from '@poolware/components';
import tw, { styled } from 'twin.macro';

export interface WorkOrderPageHeaderToolbarProps {
    workOrder: NodeType.WorkOrder;
    workOrderMutators: ReturnType<typeof useWorkOrderMutators>;
    embeddedMode?: boolean;
}

const mapBaseStageTypeToLabel = (st: NodeType.ServiceJobStageStatus): string => {
    switch (st) {
        default:
        case NodeType.ServiceJobStageStatus.Active:
            return 'Active';
        case NodeType.ServiceJobStageStatus.Suspended:
            return 'Suspend';
        case NodeType.ServiceJobStageStatus.Closed:
            return 'Closed';
    }
};

const NO_MUTATION = '____NO_MUTATIONS____';

const _stageButtonStyle: any = {
    whiteSpace: 'nowrap',
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
};

const StageButton: React.FC<{
    stages: NodeType.ServiceJobStage[];
    stageBasicType: NodeType.ServiceJobStageStatus;
    activeStage: NodeType.ServiceJobStage;
    onChange: (stage: NodeType.ServiceJobStage) => Promise<any>;
    color?: SemanticCOLORS;
    size?: SemanticSIZES;
    position?: 'first' | 'last' | 'middle' | 'auto';
}> = ({
    onChange,
    stages,
    activeStage,
    color = ModuleColorNames.StatusChangeButton,
    size = 'small',
    position = 'auto',
    stageBasicType,
}) => {
    const [mutatingValue, setMutatingValue] = useSafeState(NO_MUTATION);

    if (stages.length === 0) {
        return null;
    }

    const handleClick = async (stage: NodeType.ServiceJobStage) => {
        if (!onChange) return;
        setMutatingValue(stage?.id);
        try {
            await onChange(stage);
        } catch (e) {}
        setMutatingValue(NO_MUTATION);
    };

    const activeStageInGroup = stages.find((s) => !!activeStage && s.id === activeStage?.id);
    const isActiveGroup = !!activeStageInGroup;

    const _color = isActiveGroup ? color || 'teal' : 'grey';
    const isMutating = mutatingValue !== NO_MUTATION;

    const buttonProps: ButtonProps = {
        fluid: true,
        disabled: isMutating,
        loading: isMutating,
        color: _color,
        size: size,
        style: _stageButtonStyle,
    };

    if (stages.length === 1) {
        const stage = stages[0];
        const isActiveStage = activeStage?.id === stage.id;
        const icon = <Icon {...mapServiceJobStageTypeToIconProps(stage?.type)} />;
        const content = stage?.title;

        return (
            <Button {...buttonProps} basic={!isActiveStage} active={isActiveStage} onClick={() => handleClick(stage)}>
                {icon} {content}
            </Button>
        );
    } else {
        const content = activeStageInGroup?.title || mapBaseStageTypeToLabel(stageBasicType);
        const icon = <Icon {...mapServiceJobStageTypeToIconProps(activeStageInGroup?.type)} />;
        const trigger = (
            <Button {...buttonProps} basic={!isActiveGroup} active={isActiveGroup}>
                {icon} {content}
            </Button>
        );

        return (
            <Dropdown icon={false} floating className="icon" fluid={true} trigger={trigger}>
                <Dropdown.Menu>
                    {stages.map((s) => {
                        const isActive = s.id == activeStage?.id;
                        return (
                            <Dropdown.Item key={s.id} active={isActive} onClick={() => handleClick(s)}>
                                <Icon {...mapServiceJobStageTypeToIconProps(s.type)} />
                                {s.title}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
};

export const ContainerButtonsChangeStatus = styled.div(() => [
    tw`px-2 py-4 mb-4 rounded-sm`,
    tw`shadow-inner bg-gray-50 border-solid border-blue-400 border border-l-0 border-r-0`,
    tw`w-full flex flex-col gap-2`,
]);

export const ButtonsChangeJobStage: React.FC<{
    onChangeStage: (newStage: NodeType.ServiceJobStage) => Promise<any>;
    stages: NodeType.ServiceJobStage[];
    stageHistory?: PanelStageHistoryType;
    stage: NodeType.ServiceJobStage;
}> = ({ onChangeStage, stages, stage, stageHistory }) => {
    const { appLayoutMode } = useViewer();
    const modalCtrl = useModalCtrl();
    const stagesOpen = stages.filter((s) => s.type === NodeType.ServiceJobStageType.Opened);
    const stagesInProg = stages.filter((s) => s.type === NodeType.ServiceJobStageType.InProgress);
    const stagesAcReq = stages.filter((s) => s.type === NodeType.ServiceJobStageType.ActionRequired);
    const stagesSusp = stages.filter((s) => s.type === NodeType.ServiceJobStageType.Suspended);
    const stagesClosed = stages.filter(
        (s) => s.type === NodeType.ServiceJobStageType.Finished || s.type === NodeType.ServiceJobStageType.Canceled
    );

    const stageButtons = (
        <>
            <StageButton
                activeStage={stage}
                stages={stagesOpen}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Active}
                position={'first'}
            />
            <StageButton
                activeStage={stage}
                stages={stagesInProg}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Active}
            />
            <StageButton
                activeStage={stage}
                stages={stagesAcReq}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Active}
            />
            <StageButton
                activeStage={stage}
                stages={stagesSusp}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Suspended}
            />
            <StageButton
                activeStage={stage}
                stages={stagesClosed}
                onChange={onChangeStage}
                stageBasicType={NodeType.ServiceJobStageStatus.Closed}
                position={'last'}
            />
        </>
    );
    const icon = <Icon {...mapServiceJobStageTypeToIconProps(stage?.type)} />;
    return (
        <>
            <ContainerButtonsChangeStatus>
                {appLayoutMode.gteTablet ? (
                    <div tw={'flex-grow flex flex-row gap-3'}>
                        {stageButtons}
                        {stageHistory && (
                            <Button
                                size={'small'}
                                basic={true}
                                style={_stageButtonStyle}
                                icon={'history'}
                                onClick={modalCtrl.onOpen}
                            />
                        )}
                    </div>
                ) : (
                    <div tw={'flex-grow flex flex-col gap-2'}>
                        <div tw={'flex flex-row'}>
                            <b>Status</b>:
                            <div tw={'pl-3'}>
                                {icon} {stage?.title}
                            </div>
                        </div>
                        <Modal
                            centered={false}
                            trigger={
                                <Button
                                    size={'small'}
                                    color={'blue'}
                                    fluid={true}
                                    basic={true}
                                    primary={false}
                                    content={'Change Status'}
                                />
                            }
                        >
                            <div tw={'flex-grow flex flex-col gap-6 p-4 bg-white rounded shadow'}>{stageButtons}</div>
                        </Modal>
                    </div>
                )}
            </ContainerButtonsChangeStatus>
            {stageHistory && (
                <Modal size={'tiny'} {...modalCtrl}>
                    <PanelStageHistory stageHistory={stageHistory} />
                </Modal>
            )}
        </>
    );
};
