import clsx from 'clsx';
import PropTypes, { InferProps } from 'prop-types';
import React, { Component } from 'react';

import BackgroundWrapper from './BackgroundWrapper';
import { commonProps } from './CalendarPropsTypes';
import { useRbcContext } from './CalendarContext';

const propTypes = {
    renderSlot: PropTypes.func,
    group: PropTypes.array.isRequired,
    resource: PropTypes.any,
};

interface TimeSlotGroupProps extends InferProps<typeof propTypes> {}

export const TimeSlotGroup: React.FC<TimeSlotGroupProps> = (props) => {
    const { getters, components: { timeSlotWrapper: Wrapper = BackgroundWrapper } = {} } = useRbcContext();
    const { renderSlot, resource, group } = props;

    const groupProps = getters ? getters.slotGroupProp() : {};
    return (
        <div className="rbc-timeslot-group" {...groupProps}>
            {group.map((value, idx) => {
                const slotProps = getters ? getters.slotProp(value, resource) : {};
                return (
                    <Wrapper key={idx} value={value} resource={resource}>
                        <div {...slotProps} className={clsx('rbc-time-slot', slotProps.className)}>
                            {renderSlot && renderSlot(value, idx)}
                        </div>
                    </Wrapper>
                );
            })}
        </div>
    );
};

TimeSlotGroup.propTypes = propTypes;
