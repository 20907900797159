import { useAppNavigator } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import { useBookingActions } from '../../redux';

export function useBookAppointmentForServiceJob(input: { serviceJob?: NodeType.ServiceJob }) {
    const { serviceJob: initialServiceJob } = input;
    const nav = useAppNavigator();
    const { BookingAction } = useBookingActions();

    const bookFn = (serviceJob: NodeType.ServiceJob = initialServiceJob) => {
        if (!serviceJob) {
            throw new Error('Service Job is not provided');
        }
        const customer = serviceJob?.customer;
        const address = serviceJob?.address;
        const staff = serviceJob?.staff;
        const pool = serviceJob?.pool;

        BookingAction.startFromServiceJob({
            details: {
                serviceJob,
                customer,
                pool,
                address,
                staff,
            },
            returnTo: nav.location.pathname,
        });
    };

    return bookFn;
}
