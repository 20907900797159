import * as React from 'react';
import { IMPLICIT_FILTER, useServiceJobListActions } from '../../redux';
import { ActionBar, IconWithPopup } from '@poolware/components';

const popup = {
    content: (
        <div>
            Show/hide automatically created service cases. For example, cases created as a part of Appointment jobs.
        </div>
    ),
};
export const FilterByImplicit: React.FC = () => {
    const { Actions, State } = useServiceJobListActions();
    return (
        <ActionBar.ItemCheckbox
            checked={State.filters.isImplicit === IMPLICIT_FILTER.ANY}
            onChange={(checked) =>
                Actions.setImplicitFilter(checked ? IMPLICIT_FILTER.ANY : IMPLICIT_FILTER.NONINMPLICIT_ONLY)
            }
            content={
                <small style={{ color: '#888888' }}>
                    Show automatically created cases <IconWithPopup popup={popup} name={'info circle'} />
                </small>
            }
        />
    );
};
