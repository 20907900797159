import * as React from 'react';
import { Button, Icon, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as URLBuilder from '../../../../../routes/url-builder';
import _truncate from 'lodash/truncate';
import { NodeType } from '@poolware/api';
import tw, { styled } from 'twin.macro';
import { withAppNavigator } from '@poolware/react-app-navigator';

const PoolDescription: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    return (
        <List size="small">
            {pool.type && (
                <List.Item>
                    <b>Type:</b> {pool?.type?.name}
                </List.Item>
            )}
            {pool.volume && (
                <List.Item>
                    <b>Volume:</b> {pool?.volume}
                </List.Item>
            )}
            {pool.sanitiser && (
                <List.Item>
                    <b>Sanitiser:</b> {pool?.sanitiser?.name}
                </List.Item>
            )}
        </List>
    );
};

interface ActionButtonProps {
    customerId;
    poolId;
}

const NewTestButton = withAppNavigator<ActionButtonProps>()(({ customerId, poolId, AppNavigator }) => {
    const link = URLBuilder.Customer(customerId).Pool(poolId).WaterTest().new;
    const onClick = () => {
        AppNavigator.navigate(link, {
            setOrigin: true,
            query: {
                poolId: poolId,
            },
        });
    };

    return (
        <Button
            color="green"
            size="tiny"
            // icon={"lab"}
            compact
            onClick={onClick}
            icont={'plus'}
            content={'New Test'}
        />
    );
});

const PoolDetailsButton = withAppNavigator<ActionButtonProps>()(({ customerId, poolId, AppNavigator }) => {
    const link = URLBuilder.Customer(customerId).Pool(poolId).view;
    const onClick = () => {
        AppNavigator.navigate(link, {
            query: {
                poolId: poolId,
            },
        });
    };

    return (
        <Button
            size="tiny"
            color="grey"
            // icon={"eye"}
            basic={true}
            compact
            onClick={onClick}
            content={'View Pool'}
        />
    );
});

const PoolRow = styled.div(() => [
    tw`flex flex-col md:flex-row gap-2`,
    tw`border-0 border-l-4 rounded border-solid border-gray-400 shadow`,
]);

const PoolList: React.FC<{ pools: NodeType.Pool[]; customerId: string; onAddPool: () => any }> = ({
    pools,
    customerId,
    onAddPool,
}) => {
    return (
        <>
            <div tw={'mt-2'}>
                <div tw={'flex flex-col gap-3 w-full'}>
                    {pools.length === 0 && (
                        <PoolRow>
                            <div tw={'flex-grow p-2'}>
                                <div tw={'h-full flex flex-col justify-center'}>
                                    <div tw={'italic text-gray-600'}>No pools registered for this site</div>
                                </div>
                            </div>
                            <div tw={'flex-initial flex p-2'}>
                                <div tw={'flex flex-row gap-2 justify-end w-full items-center'}>
                                    <Button onClick={onAddPool} color="teal" size={'tiny'} basic={true}>
                                        <Icon name="plus" />
                                        Register Pool
                                    </Button>
                                </div>
                            </div>
                        </PoolRow>
                    )}
                    {pools.map((pool) => {
                        const linkViewPool = URLBuilder.Customer(customerId).Pool(pool.id).view;
                        const bottleNumber = pool.bottleNumber;

                        return (
                            <PoolRow key={pool.id}>
                                <Link to={linkViewPool} tw={'hover:bg-gray-100 p-2 w-full md:max-w-lg md:w-1/4'}>
                                    <div tw={'flex flex-col gap-1'}>
                                        <div style={{ color: '#4183c4' }}>{_truncate(pool.name, { length: 50 })}</div>
                                        {bottleNumber && <div tw={'text-sm text-gray-800'}>{bottleNumber}</div>}
                                    </div>
                                </Link>

                                <div tw={'flex-grow p-2'}>
                                    <PoolDescription pool={pool} />
                                </div>

                                <div tw={'flex-initial flex p-2'}>
                                    <div tw={'flex flex-row gap-2 justify-end w-full items-center'}>
                                        <PoolDetailsButton poolId={pool.id} customerId={customerId} />
                                        <NewTestButton poolId={pool.id} customerId={customerId} />
                                    </div>
                                </div>
                            </PoolRow>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default PoolList;
