import * as React from 'react';
import * as _ from 'lodash';
import { Message } from 'semantic-ui-react';
import { Display } from '@poolware/components';

import PickerTable from './PickerTable';
import { NodeType } from '@poolware/api';
import styled from 'styled-components';

interface ItemPickerProps<T = any> {
    selectedId?: string;
    onSubmit?: (T) => any;
    onCancel?: () => any;
    items: T[];

    searchValues?: any;
    setSearch?: (key: string) => (value: string) => any;

    isLoadingPage?: boolean;
    canPaginate?: boolean;
    currentPage?: number;
    pageCount?: number;
    setPage?: (number) => any;
}

const SecondaryInfo = styled.span`
    margin-top: 4px;
    color: grey;
    font-size: 0.9em;
`;

export class CustomerPicker extends React.Component<ItemPickerProps<NodeType.Customer>> {
    render() {
        const { setSearch, searchValues, items, ...rest } = this.props;

        return (
            <div>
                <PickerTable
                    items={items}
                    noContentRenderer={() => (
                        <Message>
                            <Message.Header>No customers found</Message.Header>
                        </Message>
                    )}
                    cellRenderer={(person: NodeType.Customer) => (
                        <span>
                            <span>{person.crn}</span> - <Display.Entity value={person} defaultString={''} />
                            <br />
                            <Display.Address
                                style={{ color: 'grey', fontSize: '0.8em' }}
                                value={person.primaryAddress}
                                defaultString={''}
                            />
                        </span>
                    )}
                    {...rest}
                />
            </div>
        );
    }
}

export class PoolPicker extends React.Component<ItemPickerProps<NodeType.Pool>> {
    render() {
        const { setSearch, searchValues, items, ...rest } = this.props;

        return (
            <div>
                <PickerTable
                    items={items}
                    noContentRenderer={() => (
                        <Message>
                            <Message.Header>No customers found</Message.Header>
                        </Message>
                    )}
                    cellRendererSecondary={(pool: NodeType.Pool) => {
                        return (
                            <>
                                <div>
                                    <Display.Address value={pool.address} defaultString={''} />
                                </div>
                                <SecondaryInfo>
                                    Bottle: {pool.bottleNumber}, {pool.name}
                                </SecondaryInfo>
                            </>
                        );
                    }}
                    cellRenderer={(pool: NodeType.Pool) => {
                        const customer = _.get(pool, 'entity.user.customer');
                        const customerCrn = _.get(pool, 'entity.user.customer.crn');
                        return (
                            <>
                                <div>
                                    <Display.Entity value={customer} defaultString={''} />
                                </div>
                                <SecondaryInfo>CRN: {customerCrn}</SecondaryInfo>
                            </>
                        );
                    }}
                    {...rest}
                />
            </div>
        );
    }
}
