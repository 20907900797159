import { fromEdges, NodeType } from '@poolware/api';
import invariant from 'invariant';

export type GroupItem = {
    name: string;
    ppd?: NodeType.PoolProductDeclaration;
    items: NodeType.Item[];
};

export const groupItemsByProductDeclaration = (
    poolProductDeclarations: NodeType.PoolProductDeclaration[],
    items: NodeType.Item[]
): GroupItem[] => {
    invariant(poolProductDeclarations, 'poolProductDeclarations is not provided');

    const bucketItems = items.map((item) => {
        const traits = fromEdges(item?.product?.traits);
        const declarations: NodeType.ProductTraitDeclaration[] = traits.map((t) => t?.declaration).filter((d) => !!d); // remove nulls

        const declarationsAssignedToItem = declarations.map((d) => d.id);

        const pddDeclarationIds = poolProductDeclarations
            .filter((pdd) => {
                const declarationId = pdd?.declaration?.id;
                return declarationsAssignedToItem.includes(declarationId);
            })
            .map((p) => p.id);

        return {
            item: item,
            ppdIds: pddDeclarationIds.slice(0, 1), // take only one declaration
        };
    });

    const groups: GroupItem[] = poolProductDeclarations.map((ppd) => {
        return {
            name: ppd?.declaration?.name || ppd.name,
            ppd: ppd,
            items: bucketItems.filter((bi) => bi.ppdIds.includes(ppd.id)).map((bi) => bi.item),
        };
    });

    groups.push({
        name: groups.length > 0 ? 'Other Equipment' : 'Equipment',
        ppd: undefined,
        items: bucketItems.filter((bi) => bi.ppdIds.length === 0).map((bi) => bi.item),
    });

    return groups;
};
