import PropTypes, { InferProps } from 'prop-types';
import React from 'react';

const propTypes = {
    label: PropTypes.node,
    date: PropTypes.instanceOf(Date),
    drilldownView: PropTypes.string,
    onDrillDown: PropTypes.func,
    isOffRange: PropTypes.bool,
};

const DateHeader: React.FC<InferProps<typeof propTypes>> = ({ label, drilldownView, onDrillDown }) => {
    if (!drilldownView) {
        return <span>{label}</span>;
    }

    return (
        <a href="#" onClick={onDrillDown}>
            {label}
        </a>
    );
};

export default DateHeader;
