export const tempByStateCity = {
    NZ: {
        AUCKLAND: [23, 23, 22, 18, 14, 12, 11, 12, 13, 15, 18, 21],
        BLENHEIM: [19, 19, 18, 16, 12, 10, 10, 11, 12, 13, 16, 17],
        BLUFF: [15, 14, 13, 10, 8, 5, 5, 7, 9, 10, 12, 14],
        CHRISTCHURCH: [21, 20, 15, 12, 9, 7, 6, 8, 10, 12, 16, 18],
        DUNEDIN: [15, 15, 14, 12, 9, 7, 6, 7, 8, 10, 12, 14],
        GREYMOUTH: [17, 18, 15, 13, 10, 8, 7, 8, 9.5, 12, 14, 16],
        HAMILTON: [21, 22, 19, 16, 13, 11, 10, 11, 13, 15, 18, 20],
        'KERI KERI': [23, 23, 22, 19, 16, 13, 12, 13, 14, 15, 17, 21],
        NAPIER: [23, 22, 20, 14, 13, 10, 9, 10, 13, 15, 17.5, 21],
        NELSON: [20, 20, 16, 13, 10, 7, 7, 8, 11, 12, 14, 18],
        'PALMERSTON NORTH': [20, 20, 18, 14, 12, 9, 9, 9, 11, 13, 14, 18],
        QUEENSTOWN: [16, 16, 13, 9.5, 7, 4, 3, 5, 8, 10, 12, 14],
        ROTARUA: [18, 17, 16, 13, 10, 8, 7, 8, 10, 12, 14, 16],
        TAURANGA: [22, 22, 20, 17, 14, 11, 10.5, 11, 13, 14, 16, 20],
        WELLINGTON: [18, 18, 16, 14, 12, 9.5, 9, 9.5, 11, 12, 14, 17],
        WHANGAREI: [22, 22, 21, 18, 14, 13, 12, 12, 13, 15, 17, 20],
    },
};
