import {
    BrowserRouter,
    Link,
    LinkProps,
    NavLink,
    NavLinkProps,
    Redirect,
    RedirectProps,
    Route,
    RouteProps,
    Router,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import * as React from 'react';
import { useContext, useState } from 'react';
import invariant from 'invariant';
import { preparePath } from './utils';

const PageNotFound = () => <div>Page not found!</div>;

export interface ModuleRouteContextType {
    path: string;
    parentMatch: any;
    moduleName?: string;
    parentCtxValue?: ModuleRouteContextType;
}

export interface AppNavContextType {
    modules: { [key: string]: string };
    registerModules: (modules: { [key: string]: string }) => any;
}

export const AppNavContext = React.createContext<AppNavContextType>(null);
export const ModuleRouteContext = React.createContext<ModuleRouteContextType>(null);

export interface IWithModuleRouteContextProps {
    routeContext: ModuleRouteContextType;
    appNavContext: AppNavContextType;
}

export const withModuleRouteContext = () => (C) => (props) => {
    return (
        <ModuleRouteContext.Consumer>
            {(ctx) => {
                return <C routeContext={ctx} {...props} />;
            }}
        </ModuleRouteContext.Consumer>
    );
};
export const withAppNavContext = () => (C) => (props) => {
    return (
        <AppNavContext.Consumer>
            {(ctx) => {
                return <C appNavContext={ctx} {...props} />;
            }}
        </AppNavContext.Consumer>
    );
};

export const useModuleContext = () => {
    const ctx = useContext(ModuleRouteContext);
    invariant(ctx, 'Must be used inside <ModuleRouteContext.Provider>. Missing `ModuleRouteContext`.');
    return ctx;
};

export const useAppNavContext = () => {
    const ctx = useContext(AppNavContext);
    invariant(ctx, 'Must be used inside <AppNavRouterProvider>. Missing `AppNavContext`.');
    return ctx;
};

export const AppNavRouterProvider: React.FC<{
    router: {
        basename?: string;
        history?;
        initialEntries?: any;
        RouterComponent?: any;
    };
}> = ({ router, children }) => {
    let AppRouter: any = null;

    const [modules, setModules] = useState({});

    if (router?.RouterComponent) {
        AppRouter = router?.RouterComponent;
    } else {
        // Don't use BrowserRouter, if `history` is provided.
        AppRouter = router?.history ? Router : BrowserRouter;
    }

    const registerModules = (modules: { [moduleId: string]: string }) => {
        setModules(modules);
    };

    const conf = {
        basename: router?.basename,
        history: router?.history,
        initialEntries: router?.initialEntries,
    };

    return (
        <AppRouter {...conf}>
            <AppNavContext.Provider value={{ modules, registerModules }}>
                <ModuleRoot>{children}</ModuleRoot>
            </AppNavContext.Provider>
        </AppRouter>
    );
};

export const ModuleRoot: React.FC<{ moduleName?: string }> = ({ children, moduleName }) => {
    const ctx = useContext(ModuleRouteContext);
    const match = useRouteMatch();

    return (
        <ModuleRouteContext.Provider value={{ path: match.path, parentMatch: match, moduleName, parentCtxValue: ctx }}>
            {children}
        </ModuleRouteContext.Provider>
    );
};

export interface ModuleSwitchProps {
    notFound?: any;
}

export const ModuleSwitch: React.FC<ModuleSwitchProps> = ({ children, notFound }) => {
    return (
        <ModuleRouteContext.Consumer>
            {(ctx) => {
                invariant(ctx, 'You should not use <ModuleSwitch> outside a <ModuleRootRoute>');
                if (children && notFound) {
                }
                const { path } = ctx;

                const remappedChildren = React.Children.map(children, (child) => {
                    if (!React.isValidElement(child)) {
                        return child;
                    }
                    if (!child.props) {
                        return child;
                    }

                    const childProps: any = child.props;
                    // @ts-ignore
                    const childTypeName = child.type && child.type.name;
                    const isModuleRoute = childTypeName === ModuleRoute.name || ModuleRedirect.name === childTypeName;
                    if (!isModuleRoute) {
                        console.error(
                            `You should not use ${childTypeName} inside <ModuleSwitch>. Only <ModuleRoute> or <ModuleRedirect> is allowed`
                        );
                        return child;
                    }

                    const hasPath = childProps.path !== undefined;

                    if (hasPath) {
                        // invariant(
                        //     isModuleRoute,
                        //     `You should not use ${childTypeName} inside <ModuleSwitch>. Only <ModuleRoute> or <ModuleRedirect> is allowed`
                        // );
                        const childPath = childProps.path || '';
                        const newPath = `${path}${childPath}`;

                        return React.cloneElement<any>(child, {
                            ...child.props,
                            path: newPath,
                            isInSwitch: isModuleRoute,
                        });
                    } else {
                        return React.cloneElement<any>(child, { ...child.props });
                    }
                });

                let notFoundRenderer = null;
                if (notFound) {
                    const newPath = `${path}/*`;
                    notFoundRenderer = <Route path={newPath} component={notFound} />;
                }

                return (
                    <Switch>
                        {remappedChildren}
                        {notFoundRenderer}
                    </Switch>
                );
            }}
        </ModuleRouteContext.Consumer>
    );
};

export const ModuleRootSwitch: React.FC<ModuleSwitchProps> = (props) => {
    return (
        <ModuleRoot>
            <ModuleSwitch notFound={PageNotFound} {...props} />
        </ModuleRoot>
    );
};

export interface AppNavLinkProps {
    relativeToModule?: boolean;
    moduleId?: string;
}

export interface ModuleLinkProps extends LinkProps, AppNavLinkProps {
    to: string;
}
export const ModuleLink: React.FC<ModuleLinkProps> = (props) => {
    const routeCtx = useModuleContext();
    const appNavCtx = useAppNavContext();
    const { relativeToModule, to, moduleId, ...rest } = props;
    const newTo = preparePath({ relativeToModule, pathname: to, routeCtx, appNavCtx, moduleId });
    // @ts-ignore
    return <Link {...rest} to={newTo} />;
};

export interface ModuleRedirectProps extends RedirectProps, AppNavLinkProps {}

export const ModuleRedirect: React.FC<ModuleRedirectProps> = (props) => {
    const routeCtx = useModuleContext();
    const appNavCtx = useAppNavContext();
    const { relativeToModule, to, moduleId, ...rest } = props;
    // @ts-ignore
    const newTo = preparePath({ relativeToModule, pathname: to, routeCtx, appNavCtx, moduleId });
    // @ts-ignore
    return <Redirect {...rest} to={newTo} />;
};

export interface ModuleNavLinkProps extends NavLinkProps, AppNavLinkProps {}

export const ModuleNavLink: React.FC<ModuleNavLinkProps> = (props) => {
    const routeCtx = useModuleContext();
    const appNavCtx = useAppNavContext();
    const { relativeToModule, to, moduleId, ...rest } = props;
    // @ts-ignore
    const newTo = preparePath({ relativeToModule, pathname: to, routeCtx, appNavCtx, moduleId });
    // @ts-ignore
    return <NavLink {...rest} to={newTo} />;
};

export interface ModuleRouteProps extends RouteProps, AppNavLinkProps {}

export const ModuleRoute: React.FC<ModuleRouteProps> = (props) => {
    const routeCtx = useModuleContext();
    const appNavCtx = useAppNavContext();
    const { path, moduleId, relativeToModule, ...rest } = props;
    const newPath = path ? preparePath({ routeCtx, appNavCtx, pathname: path, moduleId }) : undefined;
    return <Route {...rest} path={newPath as any} />;
};
