import * as React from 'react';
import { HeaderButtonType, Panel, toastError } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { ServiceJobLink } from '../../../Links';
import { ModuleIconNames } from '../../../constants';
import { useAppointmentMutators } from '../../../queries/use-appointment-mutators';
import invariant from 'invariant';
import { ButtonSectionHeader } from '../components';

const PanelItemServiceJob: React.FC<{ serviceJob: NodeType.ServiceJob }> = ({ serviceJob }) => {
    if (!serviceJob || (serviceJob.isImplicit && !serviceJob.isRecurring)) {
        return null;
    }

    const groupTitle = serviceJob?.group?.title;

    const label = serviceJob.isRecurring ? 'Recurring Service' : 'Service Case';

    return (
        <>
            {groupTitle && (
                <Panel.Item iconMarker={ModuleIconNames.ServiceCase} label={'Group'}>
                    {groupTitle && <span tw={'pl-1 font-bold text-purple-600'}>{groupTitle}</span>}
                </Panel.Item>
            )}
            <Panel.Item iconMarker={serviceJob.isRecurring ? 'refresh' : ModuleIconNames.ServiceCase} label={label}>
                <ServiceJobLink id={serviceJob.id}>#{serviceJob.serviceJobNumber}</ServiceJobLink>
                {serviceJob?.title && <span> - {serviceJob?.title}</span>}
            </Panel.Item>
        </>
    );
};

export const PanelWorkOrderPendingWo: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    appointmentItemMutator: ReturnType<typeof useAppointmentMutators>;
}> = (props) => {
    const { appointmentItem, appointmentItemMutator } = props;
    const { serviceJob, isWorkOrderPending } = appointmentItem;
    invariant(isWorkOrderPending, 'Work order is not pending');

    const onStartWorkOrder = async () => {
        try {
            await appointmentItemMutator.mutateAppointmentItem.startWorkOrder({ id: appointmentItem.id });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const button: HeaderButtonType = <ButtonSectionHeader content={'Start W/O'} onClick={onStartWorkOrder} />;

    return (
        <Panel>
            <Panel.Body>
                <Panel.SectionHeader size={'small'} icon={ModuleIconNames.WorkOrder} button={button}>
                    Work Order
                </Panel.SectionHeader>
                {serviceJob && <PanelItemServiceJob serviceJob={serviceJob} />}
                <Panel.Item iconMarker={ModuleIconNames.WorkOrder} label={'Work Order'} wrap={false}>
                    <i>pending...</i>
                </Panel.Item>
            </Panel.Body>
        </Panel>
    );
};
