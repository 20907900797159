import * as React from 'react';
import { Form, Header } from 'semantic-ui-react';
import { FormikDefaultForm, FormikInputField } from '@poolware/components';
import { FormikAddressInputFields } from '../../../../CommonComponents/Address/FormikAddressInputFields';

const SectionHeader = (props) => (
    <React.Fragment>
        <Header size={'small'} color={'grey'} dividing {...props} />
    </React.Fragment>
);

export default class NewCustomerForm extends React.Component<any> {
    render() {
        const { validationSchema, onSubmit, onCancel, initialValues } = this.props;
        return (
            <FormikDefaultForm
                validationSchema={validationSchema}
                debug={false}
                header={'New Customer'}
                initialValues={initialValues}
                onSubmit={onSubmit}
                onCancel={onCancel}
                submitButton={{ content: 'Create' }}
            >
                {() => {
                    return (
                        <>
                            <SectionHeader>Contact Details</SectionHeader>
                            <Form.Group widths="equal">
                                <FormikInputField
                                    name={'user.firstName'}
                                    label="First Name"
                                    autoComplete={'disabled'}
                                    required={true}
                                />
                                <FormikInputField
                                    name={'user.lastName'}
                                    autoComplete={'disabled'}
                                    label="Last Name"
                                    required={true}
                                />
                            </Form.Group>

                            <Form.Group widths="equal">
                                <FormikInputField
                                    name={'user.contactName'}
                                    label="Contact Name"
                                    autoComplete={'disabled'}
                                />
                                <FormikInputField
                                    name={'user.companyName'}
                                    label="Company Name"
                                    autoComplete={'disabled'}
                                />
                            </Form.Group>

                            <SectionHeader>Primary Address</SectionHeader>
                            <FormikAddressInputFields name={'primaryAddress'} />
                        </>
                    );
                }}
            </FormikDefaultForm>
        );
    }
}
