import * as React from 'react';
import { useMemo } from 'react';
import { Checkbox, Icon } from 'semantic-ui-react';
import { ConnectionTableDef, DefaultConnectionTable, Panel } from '@poolware/components';

interface DataTableControlProp {
    selectedItems: string[];
    setSelectedItems?: (newItems) => any;
    tableData;
    tableDefPartial;
}
export const MonthlyDataTableBase: React.FC<DataTableControlProp> = ({
    tableData,
    tableDefPartial,
    setSelectedItems,
    selectedItems,
}) => {
    if (!tableData || tableData?.length === 0) {
        // Table data must have profiles for 12 months
        return (
            <Panel>
                <Panel.Body>
                    <div tw={'text-red-500'}>
                        <Icon name={'warning sign'} color={'yellow'} />
                        Failed to compute monthly heat profiles.
                    </div>
                </Panel.Body>
            </Panel>
        );
    }

    const handleSingleCheckbox = (pd, checked: boolean) => {
        if (!setSelectedItems) {
            return;
        }
        if (checked) {
            setSelectedItems([...selectedItems, pd.id]);
        } else {
            setSelectedItems(selectedItems.filter((sm) => sm !== pd.id));
        }
    };

    const handleAllCheckbox = (checked: boolean) => {
        if (!setSelectedItems) return;

        if (checked) {
            setSelectedItems(tableData.map((y) => y.id));
        } else {
            setSelectedItems([]);
        }
    };

    const onRowClick = (item) => {
        handleSingleCheckbox(item, !item.isSelected);
    };

    const tableDef: ConnectionTableDef<any> = useMemo(() => {
        const isIndeterminate = selectedItems.length > 0 && tableData.length !== selectedItems.length;
        const isChecked = tableData.length === selectedItems.length && selectedItems.length > 0;
        const isDisabled = tableData.length === 0;

        const tableDef: ConnectionTableDef<any> = [
            {
                header: (
                    <Checkbox
                        disabled={isDisabled}
                        onChange={(e, data) => handleAllCheckbox(data.checked)}
                        checked={isChecked}
                        indeterminate={isIndeterminate}
                    />
                ),
                cell: (pd) => {
                    const isSelected = pd.isSelected;
                    return (
                        <Checkbox checked={isSelected} onChange={(e, data) => handleSingleCheckbox(pd, data.checked)} />
                    );
                },
            },
            ...tableDefPartial,
        ];
        return tableDef;
    }, [selectedItems]);

    return (
        <DefaultConnectionTable
            tableCellProps={{ textAlign: 'center' }}
            tableHeaderCellProps={{ textAlign: 'center' }}
            onRowClick={onRowClick}
            connectionData={tableData}
            tableDef={tableDef}
        />
    );
};
