import { bindActionCreators, combineReducers } from 'redux';
import { connect, useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { NodeType } from '@poolware/api';
import { createStructuredSelector } from 'reselect';
import { ActionsUnion, createAction, createReducer } from '@ez/tools';
import { convertDateRange } from '../Scheduler/utils/convert-date-range';

//// ***** TYPES ****** ////

export type BookingDetails = {
    appointmentItem?: NodeType.AppointmentItem;
    customer?: NodeType.Customer;
    recurrence?: NodeType.Recurrence;
    staff?: NodeType.Staff;
    pool?: NodeType.Pool;
    serviceJob?: NodeType.ServiceJob;
    workOrder?: NodeType.WorkOrder;
    address?: NodeType.Address;
    startDate?: Date;
    duration?: number;
    isRecurring?: boolean;
    color?: string;
    group?: NodeType.AppointmentGroup;
    note?: string;
    sjTemplate?: NodeType.ServiceJobTemplate;
    woTemplate?: NodeType.WorkOrderTemplate;
    followUpFromAppointment?: NodeType.AppointmentItem;
    followUpFromWorkOrder?: NodeType.WorkOrder;
};

export type BookingMode = {
    sagaMode?: boolean;
    sagaModeName?: string;
    selectingSlot?: boolean;
};

export enum BookingReturnDest {
    SERVICE_JOB = 'service-call',
    CALENDAR = 'calendar',
    POOL = 'pool',
    CUSTOMER = 'customer',
}

type BookingStateType = {
    details: BookingDetails;
    mode: BookingMode;
    returnToDetails: {
        returnDestination: BookingReturnDest;
        label: string;
    };
};

const initialState: BookingStateType = {
    details: {},
    mode: {
        sagaMode: false,
        sagaModeName: null,
        selectingSlot: false,
    },
    returnToDetails: {
        returnDestination: BookingReturnDest.CALENDAR,
        label: 'Go To Calendar',
    },
};

//// ***** ACTIONS ******** ////

export enum BOOKING_ACTION {
    SET_DETAILS = '@BookingAction/SET_DETAILS',
    SET_MODE = '@BookingAction/SET_MODE',
    START_APPT_DUPLICATE = '@BookingAction/START_APPT_DUPLICATE',
    START_APPT_RESCHEDULE = '@BookingAction/START_APPT_RESCHEDULE',
    START_FROM_SERVICE_JOB = '@BookingAction/START_FROM_SERVICE_JOB',
    START_FROM_CUSTOMER = '@BookingAction/START_FROM_CUSTOMER',
    START_SLOT_SELECT_FOR_RECUR_SERVICE = '@BookingAction/START_SLOT_SELECT_FOR_RECUR_SERVICE',
    START_FROM_POOL = '@BookingAction/START_FROM_POOL',
    START_FROM_CALENDAR = '@BookingAction/START_FROM_CALENDAR',
    START_FROM_CALENDAR_DROP_FROM_OUTSIDE = '@BookingAction/START_FROM_CALENDAR_DROP_FROM_OUTSIDE',
    RESET = '@BookingAction/RESET',
    ABORT = '@BookingAction/ABORT',
    TIME_SLOT_SELECTED = '@BookingAction/TIME_SLOT_SELECTED',
    BOOKING_CREATED = '@BookingAction/BOOKING_CREATED',
    NAVIGATE_TO_AFTER_CREATION = '@BookingAction/NAVIGATE_TO_AFTER_CREATION',
}

export const BookingAction = {
    startAppointmentReschedule: (payload: { appointmentItem: NodeType.AppointmentItem; returnTo?: any }) =>
        createAction(BOOKING_ACTION.START_APPT_RESCHEDULE, payload),
    startAppointmentDupe: (appointmentItem: NodeType.AppointmentItem) =>
        createAction(BOOKING_ACTION.START_APPT_DUPLICATE, { appointmentItem }),
    startFromCalendar: (payload?: { details?: BookingDetails; returnTo?: any }) =>
        createAction(BOOKING_ACTION.START_FROM_CALENDAR, payload),
    startFromCalendarDropFromOutside: (payload?: { details?: BookingDetails; returnTo?: any }) =>
        createAction(BOOKING_ACTION.START_FROM_CALENDAR_DROP_FROM_OUTSIDE, payload),
    startFromCustomer: (payload?: { details?: BookingDetails; returnTo?: any }) =>
        createAction(BOOKING_ACTION.START_FROM_CUSTOMER, payload),
    startFromPool: (payload?: { details?: BookingDetails; returnTo?: any }) =>
        createAction(BOOKING_ACTION.START_FROM_POOL, payload),
    startFromServiceJob: (payload?: { details?: BookingDetails; returnTo?: any }) =>
        createAction(BOOKING_ACTION.START_FROM_SERVICE_JOB, payload),
    startSlotSelectForRecurService: (payload?: { details?: BookingDetails; returnTo?: any }) =>
        createAction(BOOKING_ACTION.START_SLOT_SELECT_FOR_RECUR_SERVICE, payload),

    setDetails: (payload: BookingDetails) => createAction(BOOKING_ACTION.SET_DETAILS, payload),

    setMode: (payload: BookingMode) => createAction(BOOKING_ACTION.SET_MODE, payload),
    setSelectingSlotMode: (isSelectingSlot: boolean) => BookingAction.setMode({ selectingSlot: isSelectingSlot }),
    setSagaMode: (isSagaMode: boolean, sagaName?: string) =>
        BookingAction.setMode({ sagaMode: isSagaMode, sagaModeName: sagaName }),

    timeSlotSelected: ({ startDate, endDate }) =>
        createAction(BOOKING_ACTION.TIME_SLOT_SELECTED, { startDate, endDate }),
    bookingCreated: (payload?: any) => createAction(BOOKING_ACTION.BOOKING_CREATED, payload),
    navigateToAfterCreation: (destination: string) =>
        createAction(BOOKING_ACTION.NAVIGATE_TO_AFTER_CREATION, destination),

    abort: () => createAction(BOOKING_ACTION.ABORT),
    reset: () => createAction(BOOKING_ACTION.RESET),
};

export type BookingAction = ActionsUnion<typeof BookingAction>;

////******** REDUCERS **********////

const details = createReducer(initialState.details, (state, action: BookingAction) => {
    switch (action.type) {
        case BOOKING_ACTION.SET_DETAILS:
            return { ...state, ...action.payload };
        case BOOKING_ACTION.TIME_SLOT_SELECTED:
            const { startDate, endDate } = action.payload;
            const r = convertDateRange({ startDate, endDate });
            state.startDate = r.startDate;
            state.duration = r.duration;
            break;
        case BOOKING_ACTION.RESET:
        case BOOKING_ACTION.ABORT:
            return {};
        default:
            return state;
    }
});

const mode = createReducer(initialState.mode, (state, action: BookingAction) => {
    switch (action.type) {
        case BOOKING_ACTION.SET_MODE:
            return { ...state, ...action.payload };
        case BOOKING_ACTION.RESET:
        case BOOKING_ACTION.ABORT:
            return initialState.mode;
        default:
            return state;
    }
});

const returnToDetails = createReducer(initialState.returnToDetails, (state, action: BookingAction) => {
    switch (action.type) {
        case BOOKING_ACTION.START_FROM_SERVICE_JOB:
            return { returnDestination: BookingReturnDest.SERVICE_JOB, label: 'Go To Service Case' };
        case BOOKING_ACTION.START_FROM_CUSTOMER:
            return { returnDestination: BookingReturnDest.CUSTOMER, label: 'Go To Customer' };
        case BOOKING_ACTION.START_FROM_POOL:
            return { returnDestination: BookingReturnDest.POOL, label: 'Go To Pool' };
        case BOOKING_ACTION.START_FROM_CALENDAR_DROP_FROM_OUTSIDE:
        // fallthrough
        case BOOKING_ACTION.START_FROM_CALENDAR:
        // fallthrough
        case BOOKING_ACTION.RESET:
        // fallthrough
        case BOOKING_ACTION.ABORT:
            return initialState.returnToDetails;
        default:
            return state;
    }
});

export const reducerAppointmentBooking = combineReducers<BookingStateType>({
    details,
    mode,
    returnToDetails,
});

// Selectors

type SelectorExtended = {
    isSelectingSlot: boolean;
    isSagaMode: boolean;
    isRescheduling: boolean;
} & BookingStateType;

const memoizedSelector = createStructuredSelector<BookingStateType, SelectorExtended>({
    mode: (state) => state?.mode,
    details: (state) => state?.details,
    returnToDetails: (state) => state?.returnToDetails,
    isSelectingSlot: (state) => {
        return state?.mode?.selectingSlot || false;
    },
    isSagaMode: (state) => state?.mode?.sagaMode,
    isRescheduling: (state) =>
        //Note: using text string here instead of importing `sagaAppointmentBookingRescheduleName` constant from saga file.
        // The import is causing circular reference error.
        // TODO: need to fix
        !!state?.mode?.sagaMode && state?.mode?.sagaModeName === 'SAGA_APPOINTMENT_RESCHEDULE',
});

export interface BookingActionProps {
    BookingAction: typeof BookingAction;
    BookingState: ReturnType<typeof memoizedSelector>;
}

export const withBookingActions = () =>
    connect(
        (state: RootStateOrAny) => {
            return { BookingState: memoizedSelector(state.booking) };
        },
        (dispatch) => {
            return { BookingAction: bindActionCreators(BookingAction, dispatch) };
        }
    );

export const useBookingActions = (): BookingActionProps => {
    const state = useSelector((state: RootStateOrAny) => memoizedSelector(state.booking));
    const dispatch = useDispatch();

    return {
        BookingState: state,
        BookingAction: bindActionCreators(BookingAction, dispatch),
    };
};
