import * as React from 'react';
import IFrame from './Frame';

export const TemplateRendererFrame: React.FC<{ content?: string; style?: any }> = ({ content, style }) => {
    const finalStyle = { width: '100%', minHeight: '700px', border: '1px solid #d4d4d5', ...style };
    return (
        <IFrame style={finalStyle}>
            <div dangerouslySetInnerHTML={{ __html: content || '' }} />
        </IFrame>
    );
};
