import * as React from 'react';
import { NodeType, useMutationAppointmentGroup } from '@poolware/api';
import {
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    PageLayout,
    Panel,
    toastError,
} from '@poolware/components';
import * as URLBuilder from '../url-builder';
import { useAppNavigator } from '@poolware/react-app-navigator';
import _trim from 'lodash/trim';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').required('Required'),
    description: Yup.string().max(512, 'Too Long!').notRequired(),
});

export const CalendarGroupNewForm: React.FC<{
    onCancel: () => void;
    onFinish: (id?: NodeType.ID) => any;
    refetchQueries?: any[];
}> = ({ onCancel, onFinish, refetchQueries = [] }) => {
    const { create } = useMutationAppointmentGroup({
        refetchQueries: ['SideBarDataQuery_AppointmentGroups', ...refetchQueries],
    });

    const initialValues = {
        title: '',
        description: '',
        defaultColor: 'gray',
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const resp = await create({
                title: _trim(values.title),
                description: _trim(values.description),
                defaultColor: _trim(values.defaultColor),
                colorPalette: [],
            });
            const id = resp.data?.AppointmentGroup?.AppointmentGroup?.id;
            onFinish(id);
        } catch (e) {
            toastError({ title: 'Failed to create appointment group', description: e.message });
        }
    };

    return (
        <FormikDefaultForm
            header={'New Appointment Group'}
            initialValues={initialValues}
            submitButton={{ content: 'Create' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <FormikInputField label={'Title'} name={'title'} required={true} />
            <FormikTextareaField label={'Description'} name={'description'} />
        </FormikDefaultForm>
    );
};

export const CalendarGroupNewPage: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };
    const onFinish = (id: NodeType.ID) => {
        AppNavigator.replace(URLBuilder.Scheduler.editAppointmentGroup(id));
    };

    return (
        <PageLayout width={'screen-md'}>
            <CalendarGroupNewForm onFinish={onFinish} onCancel={onCancel} />
        </PageLayout>
    );
};
