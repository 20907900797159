import * as React from 'react';
import { NodeType } from '@poolware/api';
import { DefaultConnectionTable } from '@poolware/components';
import { ConnectionTableDef } from '@poolware/components';
import { useQueryViewerPoolSanitisersConnection } from '@poolware/frontend-admin/src/Pages/Conexp/conexp-generated/connection-query-hooks-1';
import { useQueryPoolSanitisersConnection } from './use-query-sanitisers-connection';

export interface ConsumableProductPickerProps {
    onCancel: () => void;
    onSelect: (product: NodeType.Sanitiser) => void;
    organisationId?: string;
}

export const SanitisersTable: React.FC<ConsumableProductPickerProps> = ({ onCancel, onSelect, organisationId }) => {
    const { connectionData, connectionState } = useQueryPoolSanitisersConnection({
        search: {
            organisationType: {
                id: organisationId,
            },
        },
    });
    const tableDef: ConnectionTableDef<NodeType.Sanitiser> = [
        {
            header: 'Name',
            cell: (row) => row.name,
        },
    ];
    return (
        <DefaultConnectionTable
            connectionData={connectionData}
            onRowClick={onSelect}
            connectionState={connectionState}
            tableDef={tableDef}
        />
    );
};
