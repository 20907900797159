import * as React from 'react';
import { useState } from 'react';
import { useDebouncedCallback } from '@ez/tools';
import { Display, Panel, VStack } from '@poolware/components';
import { BarcodeScannerInput } from '../../components/BarcodeScannerInput';
import { useQueryPoolBottleConnection } from './use-query-pool-bottle-connection';
import { Button, Divider, Icon, Message, Segment } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import * as URLBuilder from '../../routes/url-builder';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAppNavigator } from '@poolware/react-app-navigator';

export interface BarcodeScanAndSearchProps {
    onClose: () => any;
}

const ContentContainer = styled.div`
    flex: 1 1 auto;
`;

const ButtonContainer = styled.div`
    flex: 0 0 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

const BottleNotFoundMessage = () => {
    return (
        <div>
            <Message color="olive" header="This scan code is not linked to any pools" />
        </div>
    );
};

const SearchResult: React.FC<{ bottles: NodeType.PoolBottle[] }> = ({ bottles }) => {
    const { AppNavigator } = useAppNavigator();

    bottles = bottles.filter((b) => Boolean(b.lastLease));

    if (bottles.length === 0) {
        return <BottleNotFoundMessage />;
    }

    return (
        <VStack>
            {bottles.map((b) => {
                const isActive = !b.lastLease?.returnedAt;
                if (!b.lastLease || !isActive) {
                    return <BottleNotFoundMessage />;
                }

                const pool = b.lastLease?.pool;
                const customer = pool?.entity?.user?.customer;
                const customerLink = customer ? URLBuilder.Customer(customer.id).view : null;
                const newWaterTestLink = customer
                    ? URLBuilder.Customer(customer.id).Pool(pool.id).WaterTest().new
                    : URLBuilder.Customer('undefined').Pool(pool.id).WaterTest().new;

                const poolLink = customer
                    ? URLBuilder.Customer(customer.id).Pool(pool.id).view
                    : URLBuilder.Customer('undefined').Pool(pool.id).view;

                const onViewCustomer = () => {
                    AppNavigator.navigate(customerLink);
                };
                const onViewPool = () => {
                    AppNavigator.navigate(poolLink);
                };

                const onNewWaterTest = () => {
                    AppNavigator.navigate(newWaterTestLink);
                };

                return (
                    <>
                        <Panel>
                            <Panel.Header basic={true} icon={'barcode'}>
                                Scan Code {b.scanCode}
                            </Panel.Header>
                            <Panel.Body>
                                <Segment.Group att key={b.id}>
                                    <Segment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <ContentContainer>
                                                <Panel.Item label="Customer Name">
                                                    <Link to={customerLink}>
                                                        <Icon name="user" /> <Display.Entity value={pool?.entity} />
                                                    </Link>
                                                </Panel.Item>
                                                <Panel.Item label="Customer CRN">{customer.crn}</Panel.Item>
                                            </ContentContainer>
                                            <ButtonContainer>
                                                {customerLink && (
                                                    <Button
                                                        fluid
                                                        basic={true}
                                                        size={'tiny'}
                                                        onClick={onViewCustomer}
                                                        content={'Open Customer'}
                                                    />
                                                )}
                                            </ButtonContainer>
                                        </div>
                                    </Segment>
                                    <Segment>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <ContentContainer>
                                                <Panel.Item label="Pool Name">
                                                    <Link to={poolLink}>
                                                        <Icon name="life ring" /> {pool.name || 'Pool'}
                                                    </Link>
                                                </Panel.Item>
                                                <Panel.Item label="Pool Number">{pool.bottleNumber}</Panel.Item>
                                                {pool.address && (
                                                    <Panel.ItemAddress label="Pool Address" content={pool.address} />
                                                )}
                                            </ContentContainer>
                                            <ButtonContainer>
                                                <Button
                                                    fluid
                                                    basic={true}
                                                    size={'tiny'}
                                                    onClick={onViewPool}
                                                    content={'Open Pool'}
                                                />
                                                <Divider hidden={true} fitted={true} />
                                                <Button
                                                    fluid
                                                    basic={false}
                                                    size={'tiny'}
                                                    color={'green'}
                                                    onClick={onNewWaterTest}
                                                    content={'New Water Test'}
                                                />
                                            </ButtonContainer>
                                        </div>
                                    </Segment>
                                </Segment.Group>
                            </Panel.Body>
                        </Panel>
                    </>
                );
            })}
        </VStack>
    );
};

export const BarcodeScanAndSearch: React.FC<BarcodeScanAndSearchProps> = ({ onClose }) => {
    const [queryValue, setQueryValue] = useState('');
    const { connectionData, connectionState } = useQueryPoolBottleConnection({ scanCode: queryValue });

    const [searchDebouncer] = useDebouncedCallback((value: string) => {
        setQueryValue(value?.trim());
    }, 800);

    return (
        <Panel>
            <Panel.Header icon={'search'} basic={true} button={{ content: 'Close', onClick: onClose }}>
                Search by Bottle Barcode
            </Panel.Header>
            <Panel.Body>
                <BarcodeScannerInput onChange={searchDebouncer} loading={connectionState.loading} />
                <Divider hidden={true} />
                {queryValue && !connectionState.loading && <SearchResult bottles={connectionData} />}
            </Panel.Body>
        </Panel>
    );
};
