import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { appointmentItemFragment, fromEdges, NodeType, staffFragment } from '@poolware/api';
import { jobTodoFragment } from '../../queries/fragments';
import { OperationVariables } from '@apollo/react-common';
import { QueryHookOptions } from '@apollo/react-hooks';
import { useMemo } from 'react';

const AppointmentItemQuery = gql`
    query AppointmentItemQuery($id: ID!) {
        viewer {
            me {
                franchise {
                    id
                    vend {
                        id
                    }
                }
            }
        }
        appointmentItem: node(id: $id) {
            ...AppointmentItemFragment
            ... on AppointmentItem {
                isWorkOrderPending
                pool {
                    id
                    volume
                    type {
                        id
                        name
                    }
                    sanitiser {
                        id
                        name
                    }
                    site {
                        id
                        name
                        accessKeyLocation
                    }
                }
                customer {
                    id
                    note
                }
                franchise {
                    id
                    name
                    vend {
                        id
                    }
                }
                workOrder {
                    id
                    title
                    workOrderNumber
                    stage {
                        id
                        title
                        type
                        status
                    }
                    jobs {
                        edges {
                            node {
                                id
                                ...JobTodoFragment
                            }
                        }
                    }
                    notes {
                        edges {
                            node {
                                id
                                note
                                createdAt
                                updatedAt
                                addedBy {
                                    id
                                    ...StaffFragment
                                }
                            }
                        }
                    }
                }
                serviceJob {
                    id
                    title
                    serviceJobNumber
                    isRecurring
                    stage {
                        id
                        title
                        type
                        status
                    }
                    group {
                        id
                        title
                    }
                }
                paymentUrl
                vendSale {
                    id
                    historyUrl
                    webRegistryUrl
                }
            }
        }
        viewer {
            appointmentGroups {
                edges {
                    node {
                        id
                        title
                        defaultColor
                        description
                        priority
                        colorPalette
                    }
                }
            }
        }
    }
    ${jobTodoFragment}
    ${appointmentItemFragment}
    ${staffFragment}
`;

export function useAppointmentItemQuery<TData = any, TVariables = OperationVariables>(
    id,
    options?: QueryHookOptions<TData, TVariables>
) {
    const q = options?.query || AppointmentItemQuery;
    const variables = { id: id };
    const opt = useMemo(
        () => ({
            ...options,
            variables: { id: id },
            fetchPolicy: 'cache-and-network' as const,
        }),
        [id, options]
    );
    const { loading, data, ...rest } = useQuery<any>(q, opt);

    return useMemo(() => {
        const appointmentItem = data?.appointmentItem as NodeType.AppointmentItem;
        const canCreateSale = !!appointmentItem?.franchise?.vend?.id;
        const appointmentGroups = fromEdges<NodeType.AppointmentGroup>(data?.viewer?.appointmentGroups);
        const recurrence = appointmentItem?.appointment?.recurrence;
        const refetchQuery = {
            query: q,
            variables: variables,
        };
        return {
            isLoading: loading,
            data,
            appointmentGroups,
            appointmentItem,
            recurrence,
            canCreateSale,
            refetchQuery,
            ...rest,
        };
    }, [id, loading, data]);
}
