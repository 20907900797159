import * as React from 'react';
import { Header, Message, Segment } from 'semantic-ui-react';
import { AdminOnly, ThemeStateProps, useViewer } from '@poolware/app-shell';
import { MenuBarHeaderItem, MenuBarSection, PageLayout, StickyMenuBar } from '@poolware/components';
import JSONTree from 'react-json-tree';
import { getConfig, getRollbar } from '../../config';

interface PageProps extends ThemeStateProps {}

const Page: React.FC<PageProps> = () => {
    const { isAdmin } = useViewer();
    const rollbar = getRollbar() || {};

    if (!isAdmin) {
        return (
            <Message>
                <Message.Content>Not enough permissions to see this page</Message.Content>
            </Message>
        );
    }

    return (
        <AdminOnly>
            <PageLayout>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem title={'System Config'} />
                    </MenuBarSection>
                </StickyMenuBar>
                <PageLayout.BodySection width={'screen-lg'}>
                    <Segment>
                        <Header content={'System config'} />
                        <JSONTree data={getConfig()} />
                    </Segment>
                    <Segment>
                        <Header content={'Rollbar'} />
                        <JSONTree data={rollbar.options || {}} />
                    </Segment>

                    <Segment>
                        <Header content={'Server manifest'} />
                        <JSONTree data={window['serverManifest']} />
                    </Segment>

                    <Segment>
                        <Header content={'process.env'} />
                        <JSONTree data={process.env} />
                    </Segment>
                </PageLayout.BodySection>
            </PageLayout>
        </AdminOnly>
    );
};

export default Page;
