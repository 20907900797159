import * as React from 'react';
import { useMemo } from 'react';
import {
    DebugJsonButton,
    MenuBarDropdownItemWithConfirm,
    MenuBarGroup,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarItemWithConfirmProps,
    MenuBarSection,
    PageLayout,
    PageTabs,
    SectionHeader,
    StickyMenuBar,
    TabPaneConf,
    toastError,
    useTabCtrl,
    VStack,
} from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationServiceJobTemplate } from '@poolware/api';
import { pageSpinner } from '@poolware/app-shell';
import { useQueryServiceJobTemplate } from '../../../queries/use-query-service-job-template';
import { ServiceJobTemplateDetailsPanel } from './ServiceJobTemplateDetailsPanel';
import { ModuleIconNames } from '../../../constants';
import { WorkOrderTemplateAssocsEdit } from './WorkOrderTemplatesAssocsEdit';
import { WorkOrderTemplateAssocsView } from './WorkOrderTemplatesAssocsView';
import { TemplateUsageReport } from './TemplateUsageReport';
import { Divider, Icon } from 'semantic-ui-react';
import { PanelTemplateStages } from './PanelTemplateStages';

const TemplateDeleteMenuItem: React.FC<MenuBarItemWithConfirmProps> = (props) => {
    return (
        <MenuBarDropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            title="Delete"
            confirm={{
                confirmMessage: {
                    header: 'Delete?',
                    content: 'There is no undo! This template will be deleted forever!',
                },
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                    negative: true,
                },
                cancelButton: {
                    content: 'Keep',
                },
            }}
            {...props}
        />
    );
};

export const ServiceJobTemplateView: React.FC = () => {
    const { AppNavigator, params } = useAppNavigator();
    const {
        loading,
        error,
        node: serviceJobTemplate,
        refetchQuery,
    } = useQueryServiceJobTemplate(params.id, {
        errorPolicy: 'ignore',
    });
    const { delete: deleteMutation } = useMutationServiceJobTemplate({
        refetchQueries: ['QueryServiceJobTemplateConnection'],
    });

    const isArchived = !!serviceJobTemplate?.archivedAt;
    const isPublished = !!serviceJobTemplate?.isPublished;
    const canEdit = !isArchived && serviceJobTemplate?.checkMutations?.update;
    const canDelete = !isArchived && serviceJobTemplate?.checkMutations?.delete;

    const panes: TabPaneConf[] = useMemo(() => {
        if (!serviceJobTemplate) return [];
        return [
            {
                key: '1',
                icon: ModuleIconNames.WorkOrder,
                title: 'Template',
                render: () => (
                    <>
                        <SectionHeader icon={ModuleIconNames.WorkOrder}>Associated Work Order Templates</SectionHeader>
                        {canEdit ? (
                            <WorkOrderTemplateAssocsEdit
                                refetchQueries={[refetchQuery]}
                                serviceJobTemplate={serviceJobTemplate}
                            />
                        ) : (
                            <WorkOrderTemplateAssocsView serviceJobTemplate={serviceJobTemplate} />
                        )}
                    </>
                ),
            },
            {
                key: '2',
                icon: ModuleIconNames.ServiceCase,
                title: 'Usage Report',
                render: () => (
                    <>
                        <TemplateUsageReport serviceJobTemplate={serviceJobTemplate} />
                    </>
                ),
            },
        ];
    }, [serviceJobTemplate, canDelete, canDelete]);

    const tabCtrl = useTabCtrl({ panes, defaultTab: AppNavigator.tab, onTabChange: AppNavigator.setTab });

    const spinner = pageSpinner(loading, error, serviceJobTemplate);
    if (spinner) return spinner;

    const goToList = () => {
        AppNavigator.replaceToOrigin('/sj-template', { relativeToModule: true });
    };

    const onDelete = async () => {
        try {
            await deleteMutation({ id: serviceJobTemplate.id });
            goToList();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const barGroupItemConf: MenuBarGroupProps = {
        menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'bars', color: 'red' },
        items: [
            {
                render: <TemplateDeleteMenuItem onClick={onDelete} disabled={!canDelete} />,
            },
        ],
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goToList} icon={'chevron left'}>
                        To List
                    </MenuBarItem>
                    <MenuBarHeaderItem icon={ModuleIconNames.JobTodoTemplate}>
                        Service Job Template
                        {!isPublished && ' (DRAFT)'}
                        {isArchived && (
                            <span>
                                {' '}
                                - Archived <Icon name={'archive'} />
                            </span>
                        )}
                    </MenuBarHeaderItem>
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <DebugJsonButton data={serviceJobTemplate} />
                    {barGroupItemConf && <MenuBarGroup {...barGroupItemConf} />}
                </MenuBarSection>
            </StickyMenuBar>

            <PageLayout.BodySection>
                <VStack>
                    <ServiceJobTemplateDetailsPanel
                        readOnly={false}
                        canEdit={canEdit}
                        template={serviceJobTemplate}
                        refetchQuery={refetchQuery}
                    />

                    {false && <PanelTemplateStages serviceJobTemplate={serviceJobTemplate} />}
                </VStack>
            </PageLayout.BodySection>

            <Divider hidden={true} />
            <PageTabs {...tabCtrl} />
        </PageLayout>
    );
};
