import * as React from 'react';
import { MenuBarHeaderItem, MenuBarSection, StickyMenuBar, VStack } from '@poolware/components';
import { WaterTestPanel } from '@poolware/waterlink';
import { Segment } from 'semantic-ui-react';

export const PageWaterlink = () => {
    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem title={'Waterlink'} />
                </MenuBarSection>
            </StickyMenuBar>
            <Segment>
                <WaterTestPanel />
            </Segment>
        </VStack>
    );
};
