import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { withApolloLoading } from '@poolware/components';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { graphql } from 'react-apollo';
import { queryNames } from '../query-names';
import gql from 'graphql-tag';
import Page from './View';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';
import { productTraitDeclarationFragment } from '../../../queries/fragments';

const traitDeclarationGQL = gql`
    query ProductTraitDeclaration($id: ID!) {
        node(id: $id) {
            id
            ...ProductTraitDeclarationFragment
            ... on ProductTraitDeclaration {
                parentDeclarations {
                    id
                    name
                    ...ProductTraitDeclarationFragment
                }
            }
        }
    }
    ${productTraitDeclarationFragment}
`;

export default compose(
    withAppNavigator(),
    graphql(traitDeclarationGQL, {
        options: (props: IAppNavigatorProps) => {
            return {
                variables: { id: props.params.id },
                // fetchPolicy: "cache-and-network"
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.node.id' }),
    mapProps((props) => {
        const traitDeclaration = _.get(props, 'data.node');
        return {
            ...props,
            traitDeclaration,
        };
    }),
    withProductCatalogMutators(queryNames)
)(Page);

export interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    traitDeclaration: NodeType.ProductTraitDeclaration;
}
