export { CalNavigate, CalViewMode } from '@poolware/react-big-calendar';

export enum CalViewLayoutMode {
    GRID = 'grid',
    LIST = 'list',
}

export enum CalResourceMode {
    SPLIT = 'split',
    COMBINED = 'combined',
}
