// Map Poolware internal tags to Waterlink codes.
import { WaterlinkAPI } from './api-client';

// Note: -1 means no mapping
export const getCodeFactorForTag = (key: string): number[] => {
    const lut = {
        CHLORINE_COMBINED: [4], // Combined Chlorine
        CHLORINE_FREE: [2], // Free Chlorine
        CHLORINE_TOTAL: [3], // Total Chlorine
        BROMINE_FREE: [5], // Bromine
        BROMINE_TOTAL: [-1], //
        BIGUANIDE: [6],
        BIGUANIDE_SHOCK: [7, 42], // Same as Peroxide(42)
        PH: [8],
        ALKALINITY: [9],
        HARDNESS_TOTAL: [10], // Total Hardness
        CALCIUM_HARDNESS: [11], // Calcium Hardness
        CALCIUM_HARDNESS_SALT: [11], // Calcium Hardness (Salt)
        SUNSCREEN_STABILISER: [12], // Cyanuric Acid
        IRON: [13], // Total Iron
        COPPER_TOTAL: [16], // Copper Waterlink measures Copper-Total
        COPPER_FREE: [-1], // Waterlink does not measures Copper-Free
        BORATE: [17],
        PHOSPHATES: [18], // Phosphate
        SALT: [19],
        HYDROGEN_PEROXIDE: [7, 42], // Peroxide. Code 7 is BIGUANIDE_SHOCK, which is equal to Peroxide
        MANGANESE: [46], // Manganese
        TDS: [55], // Total Dissolved Solids
        MAGNESIUM: [-1], //
        MAGNESIUM_HARDNESS: [67], //
    };

    // 11 = 67 - 10
    // 10- Total Hardness
    // 11- Calc Hardness
    // 67- MAGNESIUM_HARDNESS

    return lut[key];
};

export const getNormalizedResultValue = (result: WaterlinkAPI.WatertestResultEntry): number => {
    if (!result) {
        throw new Error('result is not provided');
    }

    switch (result.Units) {
        default:
        case 'ppm':
            return Number(result.Value);
        case 'ppb':
            return Number(result.Value) / 1000;
    }
};
