export const tempByStateCity = {
    ACT: {
        CANBERRA: [28, 27, 24, 13, 8, 6, 6.5, 8, 10.5, 21, 24.4, 27.6],
        TUGGERANONG: [27, 26, 23, 13, 9, 7.2, 6, 7, 10, 20, 24.1, 27.1],
    },
    NSW: {
        APPIN: [28.2, 27, 24, 16, 13, 10, 8, 11, 14, 23, 24, 26],
        BALLINA: [28.3, 28.1, 27.1, 20, 17, 15.1, 15, 16, 18, 24.9, 26.3, 27.5],
        'BATEMANS BAY': [26, 25, 24, 16, 14, 12, 10, 12, 14, 22, 23, 24],
        BATHURST: [28.1, 27.3, 18, 14, 10, 7, 6, 7, 12, 20, 23.4, 26.5],
        BOWRAL: [25, 24, 22, 14, 11, 8, 7, 8.2, 11, 18, 20, 24.2],
        'BROKEN HILL': [32.8, 32.2, 28.9, 18, 14, 11, 10, 12, 16, 25, 28.7, 31.4],
        'BYRON BAY': [27.8, 27.5, 26.5, 21, 19, 16, 15, 18, 20, 23.7, 25.3, 26.6],
        CAMDEN: [29.1, 28.1, 26.3, 18, 14, 11, 9, 12, 15, 24.1, 25.5, 27.4],
        CAMPBELLTOWN: [29.1, 28.1, 26.3, 18, 14, 11, 9, 12, 15, 24.1, 25.5, 27.4],
        'COFFS HARBOUR': [27, 26.8, 25.9, 20, 15, 13, 12, 13, 16, 23.6, 25, 26.3],
        CRONULLA: [26, 26, 25, 19, 16, 14, 12, 14, 16, 21, 23, 24],
        DUBBO: [33.3, 32.1, 27, 18, 14, 10, 9, 11, 14, 25.1, 28.7, 31.5],
        FORBES: [34.4, 32.5, 28, 18, 14, 10, 9, 10, 13, 24.5, 28.9, 31.5],
        FORSTER: [26.5, 26.5, 25.6, 20, 17, 15, 14, 15, 17, 22.8, 23.8, 25.5],
        GOSFORD: [26.7, 25.9, 24, 18, 15, 12, 11, 13, 15, 21, 23, 25],
        GOULBURN: [27, 25, 18, 13, 9, 7, 6, 7, 10, 15, 22.7, 25.8],
        GRAFTON: [30.2, 29.9, 27.9, 22, 17, 14, 13, 14, 18, 25.5, 28.6, 29.9],
        KATOOMBA: [18.2, 17.7, 16, 10, 9, 7, 6, 7.3, 10, 12, 15, 17],
        KENTHURST: [27, 27, 25, 18.4, 15.2, 13, 12, 13.1, 16, 23, 25, 27],
        LISMORE: [29.9, 29.3, 27.9, 25.7, 17, 16, 14, 16, 25.7, 27, 28.3, 29.2],
        MERIMBULA: [24, 24, 23, 17, 14, 12, 11, 12, 16, 18, 20, 23],
        MUDGEE: [31, 30.2, 27.8, 17, 12, 9, 8, 9, 14, 23.4, 26.9, 29.8],
        MURWILLUMBAH: [29.6, 29, 28.1, 24, 18, 16, 15, 17, 20, 26.3, 27.7, 29.1],
        NEWCASTLE: [25.6, 25.4, 24.7, 22.8, 17, 14, 13, 15, 20.2, 22.1, 23.5, 24.9],
        'NORAH HEAD': [26.1, 26.1, 25.1, 23, 19, 16, 15, 17, 19, 22, 23.8, 25],
        NOWRA: [27.7, 26.4, 25.2, 18, 14.5, 12, 12, 13, 15, 23.2, 24.7, 26.1],
        ORANGE: [26.6, 25.9, 20, 13, 9, 7, 5, 6, 9, 18, 21.4, 24.6],
        PARRAMATTA: [27, 27, 25, 18.4, 15.2, 13, 12, 13.1, 16, 23, 25, 27],
        PENRITH: [30.8, 28, 25, 19, 14, 12, 11.7, 14, 23.2, 25.5, 27.1, 29.1],
        PICTON: [27, 26, 25, 17, 14, 11, 9, 15, 20, 22, 24, 26],
        'PORT MACQUARIE': [27.6, 27.5, 26.3, 20, 16, 14, 13, 14, 16, 23.9, 25, 26.5],
        RICHMOND: [29, 28, 25, 18, 17, 13, 10, 13, 15, 22, 26, 28],
        SCONE: [31.6, 30.6, 28, 18, 16, 12, 10, 11, 16, 25.3, 28.1, 30.2],
        SPRINGWOOD: [28, 26, 25, 18, 15, 12, 12, 15, 18, 20, 23, 26],
        SYDNEY: [25.9, 25.8, 24.7, 20, 18, 14, 14, 16, 18, 22.1, 23.6, 25.2],
        TAMWORTH: [32.5, 31.1, 29, 18, 13, 10, 9, 11, 14, 25.1, 28, 30.1],
        TAREE: [29, 28.6, 27.3, 24.7, 16, 14, 13, 15, 20, 24.9, 26.7, 28.4],
        'TERREY HILLS': [26, 26, 23, 18, 15, 14, 12, 14, 16, 20, 22, 24],
        THREDBO: [12, 11, 9, 6, -1, -2, -5, -3, 0, 6, 12, 13],
        'WAGGA WAGGA': [28, 30, 27, 16, 12, 9, 8, 9, 15, 20, 24, 27],
        WARRAGAMBA: [30, 28, 26, 18, 14, 12, 11, 12, 16, 24, 26, 28],
        WOLLONGONG: [26, 25.8, 24.8, 18, 15, 13, 14, 18.1, 20.2, 22.2, 23.7, 25.1],
    },
    NT: {
        'ALICE SPRINGS': [36.4, 35.1, 32.6, 20, 15, 13, 12, 16, 18, 30.9, 33.6, 35.4],
        DARWIN: [31.8, 31.4, 31.9, 32.7, 32, 29, 29, 31.4, 32.6, 33.2, 33.3, 32.6],
        KATHERINE: [34, 34, 34, 34, 30, 22, 22, 24, 32, 37.7, 38, 36.5],
    },
    QLD: {
        BEERBURRUM: [30, 29, 28, 26, 22, 18, 15.6, 17, 24, 26, 27, 29],
        BRISBANE: [30, 30, 29, 27, 22, 17, 15, 16, 23, 26, 27, 28],
        BUNDABERG: [30.3, 30.1, 29.2, 27.5, 22, 20.7, 18, 20, 24, 26, 28.5, 29.6],
        CAIRNS: [31.5, 31.2, 30.6, 29.2, 27.6, 26, 25, 26.6, 28.1, 29.5, 30.6, 31.4],
        CHINCHILLA: [33, 32, 30, 25, 16, 13, 12, 13, 18, 27, 31, 32],
        CLEVELAND: [29, 29, 28, 26, 22, 16, 15, 22, 24, 25.5, 27, 28],
        GLADSTONE: [31.4, 31, 30, 28, 24.7, 21.3, 21, 23, 26, 28.5, 30.1, 31.1],
        GYMPIE: [31, 30, 29, 27, 21, 18, 15, 17, 23, 27, 30, 30],
        'HERVEY BAY': [30, 30, 28.8, 26.9, 24, 20, 18, 19, 23, 26, 28, 29],
        IPSWICH: [32, 31.1, 29, 27, 22, 16, 15, 18, 24, 27, 29, 31],
        MACKAY: [30, 30, 29, 27, 25, 22, 20, 22, 25.7, 28, 29, 30.7],
        MALENY: [27.1, 26.3, 25.2, 23.7, 21.3, 16, 15, 20.3, 22.7, 24.3, 25.2, 26.4],
        MOOLOOLABA: [29, 28.9, 27.9, 26, 23.6, 19, 16, 20, 24.3, 25.6, 27.2, 28.3],
        'NOOSA HEADS': [28, 28, 27, 25, 23, 18, 17, 18, 24, 25, 26, 28],
        NOOSAVILLE: [29, 28, 28, 26, 23, 17, 16, 17, 24.3, 25.6, 26.7, 28],
        REDCLIFFE: [29, 29, 28, 26, 23, 16, 15, 16, 22, 25, 27, 28],
        ROCKHAMPTON: [31.9, 31.2, 30.5, 28.8, 26, 21, 18, 21, 25, 28, 31, 32],
        'SAMFORD VALLEY': [29, 28, 27, 25, 17, 15, 14, 15, 17, 26, 28, 29],
        'SURFERS PARADISE': [28, 28, 28, 26, 22, 18, 17, 18, 22, 24, 26, 27],
        TARINGA: [30, 30, 29, 27, 23, 18, 16, 18, 20, 27, 28, 29],
        TOOWOOMBA: [28, 27.1, 25.9, 22, 16, 13, 12, 16, 22, 24, 25, 27],
        TOWNSVILLE: [31.4, 31.1, 30.7, 29.6, 27.6, 24, 23, 26, 27, 29, 30, 31],
        'TWEED HEADS': [28, 28, 27, 25, 23, 17, 16, 17, 19, 24, 26, 27],
        WARWICK: [29, 28, 27, 22, 16, 11, 10.4, 13, 15, 24, 26, 28],
        WYNNUM: [29, 29, 28, 26, 23, 16, 15, 16, 22, 25, 27, 28],
        YANDINA: [29, 28, 28, 26, 23, 18, 16, 16, 18, 26, 28, 29],
        YATALA: [29, 29, 28, 26, 20, 16, 15, 17, 20, 26, 28, 29],
    },
    SA: {
        BEACHPORT: [20, 20, 18, 15, 13, 11, 11, 12, 13, 14, 16, 18],
        CLARE: [30, 29, 20, 16, 12, 9, 8, 13, 17, 21, 25, 27],
        ELIZABETH: [29, 28, 27, 18, 14, 12, 11, 12, 14, 16, 20, 27],
        KINGSCOTE: [20, 19, 18, 15, 13, 11, 11, 11, 12, 14, 16, 18],
        LOXTON: [30, 29, 21, 16, 13, 11, 10, 11, 14, 17, 20, 28],
        MOONTA: [29, 28, 23, 17, 14, 11, 10, 11, 13, 16, 20, 23],
        'MOUNT BARKER': [25, 24, 18, 15, 13, 10, 9.5, 11, 14, 19, 20, 23],
        'MOUNT GAMBIER': [22, 22, 20, 14, 12, 10, 9, 10, 11, 13, 15, 20],
        'MOUNT LOFTY': [20, 20, 18, 15, 10, 8, 7, 9, 11, 14, 16, 18],
        'PORT LINCOLN': [25, 25, 23, 20, 15, 13, 12, 12, 13, 16, 20, 22],
        'PORT PIRIE': [31, 30, 28, 22, 17, 13, 12, 14, 17, 22, 26, 29],
        'ROXBY DOWNS': [35, 34, 31, 25, 16, 12, 12, 14, 18, 26, 31, 33],
        TANUNDA: [28, 27, 25, 17, 14, 9.6, 9, 13, 15, 16, 23, 25],
        'VICTOR HARBOR': [24, 24, 21, 19, 14, 12, 11, 12, 14, 17, 19, 22],
        WHYALLA: [30.2, 29.5, 27.2, 20, 15, 13, 11.5, 13, 18, 22, 26, 28],
    },
    TAS: {
        BICHENO: [19, 18, 17, 15, 13, 9, 9, 11, 12, 13, 14, 18],
        DEVONPORT: [19, 18, 16, 14, 11, 10, 9, 9, 10, 12, 15, 17],
        HOBART: [19, 19, 17, 13, 11, 9, 8, 9, 11, 13, 15, 18],
        LAUNCESTON: [20, 20, 18, 13, 11, 8, 7, 9, 11, 13, 15, 18],
        STRAHAN: [17, 16, 15, 13, 9, 9, 9, 9, 10, 12, 13, 15],
    },
    VIC: {
        ALEXANDRA: [24, 22, 18, 14, 10, 8, 7, 8, 10, 13, 16, 22],
        'APOLLO BAY': [19, 20, 19, 15, 12, 11, 10, 10, 11, 14, 16, 17],
        'BACCHUS MARSH': [23, 24, 22, 15, 12, 9, 9, 10, 12, 14, 16, 21],
        BAIRNSDALE: [23, 23, 22, 15, 12, 9, 10, 10, 12, 14, 15, 20],
        BALLARAT: [21, 21, 16, 13, 10, 7, 6, 7.6, 9, 12, 14, 18],
        BENALLA: [28, 27, 19, 15, 11, 8, 7, 9, 11, 14, 18, 27],
        BENDIGO: [28, 27, 24, 15, 11, 8, 7, 8.5, 11, 14, 20, 25],
        COBRAM: [28, 29, 26, 16, 12, 9, 8, 10, 12, 15, 22, 26],
        COWES: [21, 21, 19, 14, 9, 9, 8, 9, 11, 12, 17, 19],
        ECHUCA: [30, 30, 25, 16, 12, 9.4, 8.6, 10, 12, 15, 25, 27],
        FLINDERS: [22, 22, 20, 15, 13, 10, 10, 10, 12, 14, 18, 20],
        GEELONG: [23.3, 23.2, 21.6, 17.6, 15, 12, 10, 12, 15.9, 17.5, 19.4, 21.7],
        HAMILTON: [21, 21, 17, 14, 11, 9, 8, 9, 10, 12, 15, 17],
        HASTINGS: [22, 22, 20, 15, 12, 10, 9, 10, 12, 14, 15, 20],
        HORSHAM: [29, 28, 19, 15, 11, 9, 8, 9, 11, 14, 18, 26],
        INVERLOCH: [21, 21, 19, 14, 9, 9, 8, 9, 11, 12, 17, 19],
        'LAKES ENTRANCE': [22, 21, 20, 16, 13, 11, 10, 11, 13, 14, 19, 20],
        LORNE: [20, 20, 18, 16, 13, 11, 10, 11, 12, 14, 17, 19],
        MANSFIELD: [26, 26, 18, 14, 11, 8, 8, 9, 10, 14, 16, 23],
        MELBOURNE: [24, 23, 22, 18, 13, 10, 9, 10, 12, 15, 18, 22],
        MELTON: [23, 24, 22, 15, 12, 9, 9, 10, 12, 14, 16, 21],
        MILDURA: [31, 30, 27, 17, 13.2, 11, 10, 11.3, 14, 17, 25, 28],
        MORNINGTON: [22, 22, 20, 15, 12, 10, 9, 10, 12, 14, 15, 20],
        MORWELL: [23, 23, 21, 15, 12, 9, 8, 9, 12, 14, 16, 21],
        'MT HOTHAM': [12, 12, 9, 6, 2, -1, -3, -3, 1, 4, 7, 10],
        PORTLAND: [20, 19, 18, 16, 13, 11.5, 11, 11.4, 13, 13.6, 15, 17],
        PORTSEA: [22, 22, 20, 15, 12, 10, 9, 10, 12, 14, 15, 20],
        ROSEBUD: [22, 22, 20, 15, 12, 10, 9, 10, 12, 14, 15, 20],
        SALE: [21, 21, 19, 14, 11, 9, 8, 9, 11, 13, 15, 19],
        SEYMOUR: [26, 26, 23, 15, 11, 10, 8, 9, 11, 14, 17, 24],
        SHEPPARTON: [31, 30, 25, 15.6, 11.5, 9, 8.4, 9.3, 12, 15, 20, 27],
        STAWELL: [27, 26, 22, 15, 11, 9, 8, 9, 11, 14, 17, 23],
        'SWAN HILL': [32, 30, 27, 16.5, 12.5, 9.8, 9.2, 10.3, 13, 20, 24, 28],
        TOORADIN: [21, 21, 20, 15, 10, 10, 10, 10, 12, 13, 18, 20],
        TORQUAY: [23.3, 23.2, 21.6, 17.6, 15, 12, 10, 12, 15.9, 17.5, 19.4, 21.7],
        TRARALGON: [23, 23, 21, 15, 12, 9, 8, 9, 12, 14, 16, 21],
        WANGARATTA: [28, 27, 19, 15, 11, 8, 7, 9, 11, 14, 18, 27],
        WARBURTON: [20, 19, 16, 13, 10, 8, 7, 8, 10, 12, 14, 18],
        WARRNAMBOOL: [21, 21, 20, 15, 12, 10, 9, 10, 12, 13, 15, 19],
        WODONGA: [30, 29, 25, 16, 12, 9, 8, 9, 12, 15, 24, 27],
        WONTHAGGI: [22, 22, 20, 15, 10, 10, 9, 10, 12, 13, 18, 20],
        YARRAWONGA: [30, 29, 26, 16, 12, 9, 8, 10, 12, 15, 22, 27],
    },
    WA: {
        ALBANY: [22, 22, 22, 20, 17, 14, 13, 15, 17, 18, 20, 21],
        BICKLEY: [30.6, 30.5, 27.9, 22, 14.7, 12, 11, 12, 13, 16, 23, 27],
        BROOME: [33, 32, 34, 34, 31, 28, 27, 29, 31, 33, 33, 33],
        BUNBURY: [28, 28, 26, 20, 15, 13, 12, 13, 14, 18, 22, 25],
        BUSSELTON: [28, 28, 26, 20, 14, 13, 12, 13, 15, 17, 23, 26],
        CORRIGIN: [30, 29, 28, 19, 15, 13, 11, 12, 14, 18, 26, 29],
        ESPERANCE: [25, 25, 25, 22, 17, 14, 13, 14, 16, 19, 21, 22],
        GERALDTON: [30, 31, 30, 26.6, 23, 16, 15, 16, 18, 23, 25, 28],
        KALGOORLIE: [33, 32, 29, 24, 16, 13, 12, 14, 16, 25, 28, 32],
        KARRATHA: [36, 35, 36, 34, 30, 26, 25, 27, 31, 34, 35, 35],
        MANDURAH: [29, 29, 27, 23, 20, 16, 13, 16, 18, 20, 24, 26.9],
        'MARGARET RIVER': [26, 27, 25, 22, 19, 15.5, 16, 16.8, 18, 19, 22, 24],
        PERTH: [30, 30, 28, 23, 18, 14, 13, 16, 18, 21, 25, 28],
    },
};
