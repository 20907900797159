import * as _ from 'lodash';

export const pwapiDeserializer = (pwapiState) => {
    pwapiState = _.omit(pwapiState, ['isAuthenticating', 'isFetchingProfile']);
    return pwapiState;
};

export const pwapiSerializer = (pwapiState) => {
    _.set(pwapiState, 'isAuthenticating', false);
    _.set(pwapiState, 'isFetchingProfile', false);
    pwapiState = _.omit(pwapiState);
    return pwapiState;
};
