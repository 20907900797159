import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { NodeType, createUseQueryNodeHook } from '@poolware/api';

const QL = gql`
    query QueryProductNode($id: ID!) {
        node(id: $id) {
            ... on Product {
                id
                name
                createdAt
                updatedAt
                organisationType {
                    id
                    name
                }
                franchise {
                    id
                    name
                }
                description
                identification
                sku
                customSku
                familyCode
                isOneOff
                isGrouping
                brand {
                    id
                    name
                    company {
                        id
                        name
                    }
                }
                supplier {
                    id
                    name
                }
                addedBy {
                    id
                }
            }
        }
    }
`;

export const withProductNode = (idResolver: (props) => string) =>
    graphql(QL, {
        options: (props) => {
            const id = idResolver(props);
            return {
                variables: { id: id },
            };
        },
    });

export const useQueryProductNode = createUseQueryNodeHook<NodeType.Product>(QL);
