import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { AppointmentStatusUiConf } from '../utils';
import tw, { css, styled } from 'twin.macro';
import { useViewer } from '@poolware/app-shell';
import { ContainerButtonsChangeStatus } from '../../components/ButtonsChangeJobStage';

const ButtonChangeStatusStyled = styled.button<{ active?: boolean; relaxed?: boolean }>(({ active, relaxed }) => [
    tw`hover:cursor-pointer pl-1 pr-2 py-0 text-sm rounded`,
    tw`text-blue-400 bg-white hover:bg-blue-100 border border-blue-400`,
    active && tw`text-white bg-blue-600 border-blue-600 hover:bg-blue-700`,
    css`
        padding-top: 0.5em;
        padding-bottom: 0.5em;
    `,
    relaxed && tw`p-3 text-base`,
]);

interface ButtonChangeStatusProps extends AppointmentStatusUiConf {
    relaxed?: boolean;
    onChangeStatus: (type: NodeType.AppointmentStatusEnum) => any;
}

const ButtonChangeStatus: React.FC<ButtonChangeStatusProps> = ({
    active,
    title,
    type,
    icon,
    onChangeStatus,
    relaxed,
}) => (
    <ButtonChangeStatusStyled active={active} onClick={() => onChangeStatus(type)} relaxed={relaxed}>
        {icon && <Icon {...icon} inverted={active} />}
        {title}
    </ButtonChangeStatusStyled>
);

export const AppointmentStatusButtons: React.FC<{
    buttonConfigs: AppointmentStatusUiConf[];
    onChangeStatus: (type: NodeType.AppointmentStatusEnum) => any;
}> = (props) => {
    const { appLayoutMode } = useViewer();

    if (appLayoutMode.gteTablet) {
        return (
            <div tw={'p-2 py-4 border-0 border-b border-red-300 border-solid'}>
                <div tw={'flex flex-row flex-wrap gap-1'}>
                    {props.buttonConfigs.map((conf, index) => {
                        return <ButtonChangeStatus key={index} {...conf} onChangeStatus={props.onChangeStatus} />;
                    })}
                </div>
            </div>
        );
    }

    const activeButton = props.buttonConfigs.find((c) => c.active);
    return (
        <>
            <ContainerButtonsChangeStatus>
                <div tw={'flex flex-row'}>
                    <b>Status</b>:
                    <div tw={'pl-3'}>
                        <Icon {...activeButton.icon} /> {activeButton?.title}
                    </div>
                </div>
                <Modal
                    centered={false}
                    trigger={
                        <Button
                            size={'small'}
                            color={'blue'}
                            fluid={true}
                            basic={true}
                            primary={false}
                            content={'Change Status'}
                        />
                    }
                >
                    <div tw={'flex-grow flex flex-col gap-4 p-4 bg-white rounded shadow'}>
                        {props.buttonConfigs.map((conf, index) => {
                            return (
                                <ButtonChangeStatus
                                    relaxed={true}
                                    key={index}
                                    {...conf}
                                    onChangeStatus={props.onChangeStatus}
                                />
                            );
                        })}
                    </div>
                </Modal>
            </ContainerButtonsChangeStatus>
        </>
    );
};
