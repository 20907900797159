import * as React from 'react';
import { Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ConnectionTableDef, DefaultConnectionTable, Display, LinkButton } from '@poolware/components';
import * as URLBuilder from '../../../routes/url-builder';
import { fromEdges, NodeType, useQueryStaffConnection } from '@poolware/api';
import { useViewer } from '@poolware/app-shell';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { StaffIcon } from '../../../components/AppIcons';
import { QueryStaffConnectionQL } from './queries';
import 'twin.macro';

const RenderUser: React.FC<{ staff: NodeType.Staff; onView }> = ({ staff, onView }) => {
    const isRegistered = !!staff?.registration;
    const isActive = isRegistered && !staff?.registration?.disabledAt;

    if (isActive) {
        return (
            <div>
                <StaffIcon active={true} />
                <LinkButton onClick={onView}>
                    <Display.Entity value={staff} />
                </LinkButton>
            </div>
        );
    }

    return (
        <Popup
            content={'Deactivated account'}
            trigger={
                <div>
                    <StaffIcon active={false} />
                    <LinkButton onClick={onView}>
                        <Display.Entity value={staff} />
                    </LinkButton>
                </div>
            }
        />
    );
};

const RenderEmail = ({ email }) => {
    return <Display.Email value={email} />;
};

const RenderFranchise = (staff: NodeType.Staff) => {
    const franchise = staff?.user?.entity?.franchise;
    if (franchise) {
        const link = URLBuilder.Franchise(franchise.id).view;
        return <Link to={link}>{franchise.name}</Link>;
    }
    return '--';
};

export const StaffTable: React.FC<{ userName?: string }> = ({ userName }) => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin, isOrgAdmin, isFranchiseAdmin, modulesAccess } = useViewer();

    const { connectionState, connectionData } = useQueryStaffConnection({
        query: QueryStaffConnectionQL,
        userName: userName,
        showDeleted: isFranchiseAdmin || isAdmin,
        showDisabled: isFranchiseAdmin || isAdmin,
        fetchPolicy: 'cache-and-network',
    });

    const onViewStaff = (staff: NodeType.Staff) => {
        const link = URLBuilder.Staff(staff?.id).view;
        AppNavigator.navigate(link, { setOrigin: true });
    };

    const onViewTeam = (team: NodeType.StaffTeam) => {
        AppNavigator.navigateRelative(`/teams/${team.id}`, { setOrigin: true });
    };

    const tableDef: ConnectionTableDef<NodeType.Staff> = [
        {
            header: 'Name',
            sortKey: 'lastName',
            width: 4,
            cell: (staff) => {
                return <RenderUser staff={staff} onView={() => onViewStaff(staff)} />;
            },
        },
        {
            header: 'Role',
            width: 2,
            cell: (staff) => {
                return <>{staff?.role?.name}</>;
            },
        },
        {
            header: 'Account Email',
            cell: (staff) => {
                return <RenderEmail email={staff?.registration?.email} />;
            },
        },
        modulesAccess.Staff?.teams && {
            header: 'Teams',
            cell: (staff) => {
                const teams = fromEdges(staff.teams);
                return (
                    <div tw={'flex flex-row gap-1'}>
                        {teams.map((t) => {
                            return (
                                <div tw={'rounded px-2 bg-gray-200'} key={t.id}>
                                    <LinkButton onClick={() => onViewTeam(t)}>{t.title}</LinkButton>
                                </div>
                            );
                        })}
                    </div>
                );
            },
        },
    ];

    if (isAdmin || isOrgAdmin) {
        tableDef.push({
            header: 'Franchise',
            cell: RenderFranchise,
        });
    }

    return (
        <DefaultConnectionTable tableDef={tableDef} connectionData={connectionData} connectionState={connectionState} />
    );
};
