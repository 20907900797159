import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Icon } from 'semantic-ui-react';
import { ConnectionTableDef, DefaultConnectionTable, Display } from '@poolware/components';
import { mapServiceJobStageTypeToIconProps } from '../../../constants';
import { useQueryWorkOrdersConnection } from '../../../queries/use-query-work-orders-connection';
import { useAppNavigator } from '@poolware/react-app-navigator';

export const TableWorkOrders: React.FC<{
    serviceJob: NodeType.ServiceJob;
    noDataComponent?: React.ReactElement | null;
}> = ({ serviceJob, noDataComponent }) => {
    const { AppNavigator } = useAppNavigator();
    const { connectionState, connectionData } = useQueryWorkOrdersConnection({ serviceJob, includeArchived: true });

    const onView = (wo) => {
        AppNavigator.navigate(`/wo/${wo.id}`, {
            relativeToModule: true,
            setOrigin: true,
        });
    };

    const tableDef: ConnectionTableDef<NodeType.WorkOrder> = [
        {
            header: 'Status',
            width: 1,
            cell: (workOrder) => {
                return (
                    <span>
                        <Icon {...mapServiceJobStageTypeToIconProps(workOrder.stage?.type)} />
                        {workOrder.workOrderNumber}
                    </span>
                );
            },
        },
        {
            header: 'ID',
            width: 1,
            cell: (workOrder) => {
                return <span style={{ color: '#4183c4' }}>{workOrder.workOrderNumber}</span>;
            },
        },
        {
            header: 'Work Order',
            width: 8,
            cell: (workOrder) => {
                return workOrder.title;
            },
        },
        {
            header: 'Appointment',
            cell: (workOrder) => {
                if (!workOrder.appointmentItem) return null;
                return (
                    <>
                        <Icon name={'calendar alternate outline'} />
                        <Display.Date value={workOrder.appointmentItem.startDate} format={'lll'} />
                    </>
                );
            },
        },
        {
            header: 'Assigned To',
            cell: (workOrder) => {
                return <Display.Entity value={workOrder.assignedTo} />;
            },
        },
    ];

    return (
        <DefaultConnectionTable
            onRowClick={onView}
            tableProps={{ color: 'grey' }}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
            noDataComponent={noDataComponent}
        />
    );
};
