import { getApiClient } from '../../rest-api';
import { FileNode } from '../../rest-api';
import { createAction } from './createAction';
import { REST } from '../..';

//// ***** ACTIONS ******** ////

export const AUTH_ACTION = {
    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    AUTHENTICATED: 'USER_AUTHENTICATED',
    UNAUTHENTICATED: 'USER_UNAUTHENTICATED',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export const AuthAction = {
    loginRequest: () => createAction(AUTH_ACTION.LOGIN_REQUEST),
    authenticated: () => createAction(AUTH_ACTION.AUTHENTICATED),
    unauthenticated: () => createAction(AUTH_ACTION.UNAUTHENTICATED),
    loginSuccess: (payload) => createAction(AUTH_ACTION.LOGIN_SUCCESS, payload),
    loginFailure: (payload) => createAction(AUTH_ACTION.LOGIN_FAILURE, payload),
    logoutRequest: () => createAction(AUTH_ACTION.LOGOUT_REQUEST),
    logoutSuccess: () => createAction(AUTH_ACTION.LOGOUT_SUCCESS),
    logoutFailure: (payload) => createAction(AUTH_ACTION.LOGOUT_FAILURE, payload),
};

export const USER_ACTION = {
    FETCH_PROFILE: 'USER_PROFILE_FETCH_REQUEST',
    FETCH_PROFILE_SUCCESS: 'USER_PROFILE_FETCH_SUCCESS',
    FETCH_PROFILE_FAILURE: 'USER_PROFILE_FETCH_FAILURE',
};

export const UserAction = {
    fetchProfile: () => createAction(USER_ACTION.FETCH_PROFILE),
    fetchProfileSuccess: (userProfile: REST.UserProfile) =>
        createAction(USER_ACTION.FETCH_PROFILE_SUCCESS, userProfile),
    fetchProfileFailure: (payload: { error?; errorMessage? }) =>
        createAction(USER_ACTION.FETCH_PROFILE_FAILURE, payload),
};

/// **** Functions *****////

export const login = (username, password) => (dispatch, getState) => {
    const api = getApiClient();

    dispatch(AuthAction.loginRequest());

    return api.login(username, password).then(
        (response) => {
            dispatch(AuthAction.authenticated());
            dispatch(AuthAction.loginSuccess(response));
            dispatch(fetchUserProfile());
        },
        (error) => {
            dispatch(AuthAction.unauthenticated());
            dispatch(
                AuthAction.loginFailure({ errorMessage: 'Could not login with the provided credentials', error: error })
            );
        }
    );
};

export const logout = () => (dispatch) => {
    const api = getApiClient();

    dispatch(AuthAction.unauthenticated());
    dispatch(AuthAction.logoutRequest());
    return api.logout().then(
        (response) => {
            dispatch(AuthAction.unauthenticated());
            dispatch(AuthAction.logoutSuccess());
        },
        (error) => {
            dispatch(AuthAction.unauthenticated());
            dispatch(AuthAction.logoutFailure({ errorMessage: error.message || 'Something went wrong.' }));
        }
    );
};

export const resetPassword = (email: string) => (dispatch) => {
    const api = getApiClient();
    return api.resetPassword(email);
};

export const fetchUserProfile = () => (dispatch, getState): Promise<REST.UserProfile> => {
    const api = getApiClient();

    dispatch(UserAction.fetchProfile());

    const dispatchError = (error) => {
        dispatch(AuthAction.unauthenticated());
        dispatch(UserAction.fetchProfileFailure({ error, errorMessage: error.message || 'Something went wrong.' }));
    };

    return api
        .fetchMyProfile()
        .then(
            (response) => {
                if (!response.data || !response.data.email) {
                    const error = new Error('Failed to fetch profile data');
                    dispatchError(error);
                    return error;
                }
                dispatch(UserAction.fetchProfileSuccess(response.data));
                dispatch(AuthAction.authenticated());
                return response.data as REST.UserProfile;
            },
            (error) => {
                dispatchError(error);
                return error;
            }
        )
        .catch((error) => {
            console.error(error);
        });
};

// TODO: import definition for `File` type
export const uploadFile = (file: any /*File*/, note?: string): Promise<FileNode> => {
    const api = getApiClient();
    return api.uploadFile(file, note);
};

export const userActions = {
    login,
    logout,
    resetPassword,
    fetchUserProfile,
    uploadFile,
};

export interface AuthActionProps {
    login: (username, password) => Promise<any>;
    logout: () => Promise<any>;
    resetPassword: (email) => Promise<any>;
    fetchUserProfile: () => Promise<REST.UserProfile>;
    uploadFile: (file: any /*File*/, note?: string) => Promise<FileNode>;
}
