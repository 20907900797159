import { createAction, createReducer } from '@ez/tools';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useEffect, useMemo, useReducer } from 'react';

export const ALL = '___ALL___';

type FilterType = {
    pageIndex: number;
};

const reducerInitialState: FilterType = {
    pageIndex: 0,
};

type ActionMap<M extends { [index: string]: any }, Ext> = {
    type: keyof M | Ext;
    payload?: any;
};

const searchReducer = createReducer(
    reducerInitialState,
    (state: FilterType, action: ActionMap<FilterType, 'clear'>) => {
        if (action.type === 'pageIndex') {
            state.pageIndex = action.payload;
            return;
        }

        // Reset pageIndex whenever any of the search queries change.
        // state.pageIndex = 0;
        return state;
    }
);

export const useFilterWarrantyMessages = () => {
    const { query, AppNavigator } = useAppNavigator();
    const initialValues = useMemo<FilterType>(
        () => ({
            pageIndex: Number(query?.page) || 0,
        }),
        []
    );

    const [state, dispatch] = useReducer(searchReducer, initialValues);

    useEffect(() => {
        AppNavigator.setSearchQueries({ page: state.pageIndex?.toString() || '0' });
    }, [state]);

    const setPageIndex = (value: FilterType['pageIndex']) => dispatch(createAction('pageIndex', value));

    return {
        state,
        actions: {
            setPageIndex,
        },
    };
};
