import gql from 'graphql-tag';
import * as _ from 'lodash';
import { useDefaultConnectionState, useQueryConnection, NodeType } from '@poolware/api';

const QL = gql`
    query QueryWarrantyClaimsConnection(
        $first: Int
        $page: Int
        $sort: WarrantyClaimViewerWarrantyClaimsSort
        $search: WarrantyClaimViewerWarrantyClaimsSearch
    ) {
        viewer {
            warrantyClaims(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        status
                        statusMessage
                        statusGroup
                        customerCompanyName
                        customerFirstName
                        customerLastName
                        customerEmail
                        customerPhone
                        warrantyClaimNumber
                        assetName
                        assetSerial
                        assetInstallDate
                        claimedAt
                        submittedAt
                        claimedAt
                        confirmedAt
                        createdAt
                        updatedAt
                        franchise {
                            id
                            name
                            email
                        }
                        addedBy {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        refCustomer {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export type StatusFilterType = 'ALL' | 'OPEN' | 'CLOSED';

export interface UseQueryWarrantyClaimsConnectionProps {
    searchClaimId?: string;
    statusFilter?: StatusFilterType;
}

function mapPropsToSearchQuery(
    props?: UseQueryWarrantyClaimsConnectionProps
): NodeType.WarrantyClaimViewerWarrantyClaimsSearch {
    if (!props) {
        return undefined;
    }

    const { searchClaimId, statusFilter } = props;
    const search = {};
    if (searchClaimId) {
        _.set(search, 'claimNumber', searchClaimId);
    }

    if (statusFilter) {
        switch (statusFilter) {
            case 'ALL':
                break;
            case 'OPEN':
                _.set(search, 'statusGroup.not', NodeType.WarrantyClaimStatusGroupEnum.CLOSED);
                break;
            case 'CLOSED':
                _.set(search, 'statusGroup.is', NodeType.WarrantyClaimStatusGroupEnum.CLOSED);
                break;
            default:
            //noop
        }
    }
    return search;
}

export function useQueryWarrantyClaimsConnection(props?: UseQueryWarrantyClaimsConnectionProps) {
    const connectionPath = 'viewer.warrantyClaims';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 30,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
