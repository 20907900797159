import * as React from 'react';
import { useQueryAbstractDocConnection } from '../queries/use-query-abstract-doc-connection';
import {
    DefaultConnectionTable,
    MenuBar,
    PageLayout,
    StickyMenuBar,
    toastError,
    useModalCtrl,
} from '@poolware/components';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { Modal } from 'semantic-ui-react';
import { FormNewInvoiceDoc } from '../Edit/FormNewInvoiceDoc';
import { useInvoiceListTableDef } from './use-invoice-list-table-def';

export interface PageListProps {}

export const PageList: React.FC<PageListProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { connectionData, connectionState, refetchQuery } = useQueryAbstractDocConnection({
        defaultSortKey: 'issuedAt',
    });
    const newModalCtrl = useModalCtrl();
    const tableDef = useInvoiceListTableDef({ showCaseColumn: true });

    const onDidCreateNew = async (doc: NodeType.AbstractDocument) => {
        try {
            newModalCtrl.onClose();
            AppNavigator.navigateRelative(`/${doc?.id}/edit`);
        } catch (e) {
            toastError(e);
        }
    };

    const onView = (doc: NodeType.AbstractDocument) => {
        AppNavigator.navigateRelative(`/${doc.id}`);
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem color={ModuleColorNames.Invoice} icon={ModuleIconNames.Invoice}>
                        Invoices
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Item onClick={newModalCtrl.onOpen} icon={'plus'} title={'New'} />
                    <MenuBar.Item onClick={() => AppNavigator.navigateRelative('/settings')} icon={'cog'} />
                </MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection>
                <DefaultConnectionTable
                    onRowClick={onView}
                    tableDef={tableDef}
                    connectionData={connectionData}
                    connectionState={connectionState}
                />
            </PageLayout.BodySection>
            <Modal closeOnDimmerClick={false} {...newModalCtrl}>
                <FormNewInvoiceDoc
                    refetchQuery={refetchQuery}
                    onDidCreate={onDidCreateNew}
                    onCancel={newModalCtrl.onClose}
                />
            </Modal>
        </PageLayout>
    );
};
