import { IMPLICIT_FILTER, useServiceJobListActions } from '../../redux';
import { SearchByAssignmentState } from '../../queries/use-query-work-orders-connection';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import {
    QueryServiceJobConnectionProps,
    useQueryServiceJobConnection,
} from '../../queries/use-query-service-job-connection';
import { useViewer } from '@poolware/app-shell';
import { RECUR_FILTER } from '../../redux/helpers';

export const useConnectedQueryServiceJobConnection = (recurFilter: RECUR_FILTER) => {
    const { Actions, State } = useServiceJobListActions();
    const { modulesAccess, viewer } = useViewer();
    const singleStaffMode = modulesAccess.FieldServices?.calendarSingleStaffMode;

    if (!State.filters) {
        console.error('ServiceJobListState.filters are not defined');
        return;
    }

    const { staffIds, isImplicit } = State.filters;

    const queryConf: QueryServiceJobConnectionProps = {};

    if (singleStaffMode) {
        queryConf.relatedStaff = viewer?.me?.staff?.id;
    } else if (staffIds.length > 0) {
        if (staffIds.includes(UNASSIGNED_APPOINTMENT_STAFF_ID)) {
            queryConf.assignmentState = SearchByAssignmentState.UNASSIGNED;
        } else {
            queryConf.relatedStaff = staffIds[0];
        }
    }

    queryConf.workOrderNumber = State.filters.workOrderNumber;
    queryConf.serviceJobNumber = State.filters.serviceJobNumber;
    queryConf.customerCRN = State.filters.customerCRN;
    queryConf.serviceJobGroup = State.filters.serviceGroupId;
    queryConf.usedTemplate = State.filters.serviceJobTemplateId;
    queryConf.stage = State.filters.serviceStageId;
    queryConf.stageType = State.filters.serviceStageType;

    switch (isImplicit) {
        case IMPLICIT_FILTER.ANY:
            queryConf.isImplicit = null;
            break;
        case IMPLICIT_FILTER.IMPLICITY_ONLY:
            queryConf.isImplicit = true;
            break;
        case IMPLICIT_FILTER.NONINMPLICIT_ONLY:
            queryConf.isImplicit = false;
            break;
    }

    switch (recurFilter) {
        default:
        case RECUR_FILTER.ALL:
            queryConf.isRecurring = undefined;
            break;
        case RECUR_FILTER.RECURRING:
            queryConf.isRecurring = true;
            break;
        case RECUR_FILTER.ONEOFF:
            queryConf.isRecurring = false;
            break;
    }

    return useQueryServiceJobConnection({
        ...queryConf,
        /// Redux controlled page state
        pageIndex: State.page.index,
        pageSize: State.page.size,
        sortKey: State.page.sortKey,
        sortDirection: State.page.sortDirection,
        setPageSize: Actions.setPageSize,
        setPageIndex: Actions.setPageIndex,
        setSortKey: Actions.setSortKey,
        setSortDirection: Actions.setSortDirection,
        debugName: 'useConnectedQueryServiceJobConnection',
    });
};
