import { SemanticICONS } from 'semantic-ui-react';
import { ConfirmProps } from '@poolware/components';
import { NodeType } from '@poolware/api';

export const StageStatusOptionsMap: { [key: string]: NodeType.WarrantyClaimStatusEnum[] } = {
    [NodeType.WarrantyClaimStatusGroupEnum.NEW]: [NodeType.WarrantyClaimStatusEnum.PROCESSING],
    [NodeType.WarrantyClaimStatusGroupEnum.PROCESSING]: [NodeType.WarrantyClaimStatusEnum.PROCESSING],
    [NodeType.WarrantyClaimStatusGroupEnum.ON_HOLD]: [NodeType.WarrantyClaimStatusEnum.ON_HOLD_MISSING_INFO],
    [NodeType.WarrantyClaimStatusGroupEnum.CLOSED]: [
        NodeType.WarrantyClaimStatusEnum.CLOSED_REPAIRED,
        NodeType.WarrantyClaimStatusEnum.CLOSED_REPLACED,
        NodeType.WarrantyClaimStatusEnum.CLOSED_OUT_OF_WARRANTY,
        NodeType.WarrantyClaimStatusEnum.CLOSED_NO_FIX,
        NodeType.WarrantyClaimStatusEnum.CLOSED_OTHER,
    ],
};

export const getMessageForStatus = (status: NodeType.WarrantyClaimStatusEnum): string => {
    switch (status) {
        case NodeType.WarrantyClaimStatusEnum.NEW:
            return 'New Claim';
        case NodeType.WarrantyClaimStatusEnum.PROCESSING:
            return 'Processing';
        case NodeType.WarrantyClaimStatusEnum.ON_HOLD_MISSING_INFO:
            return 'On hold. Missing info';
        case NodeType.WarrantyClaimStatusEnum.CLOSED_REPAIRED:
            return 'Closed: Repaired';
        case NodeType.WarrantyClaimStatusEnum.CLOSED_REPLACED:
            return 'Closed: Replaced';
        case NodeType.WarrantyClaimStatusEnum.CLOSED_NO_FIX:
            return 'Closed: No fix';
        case NodeType.WarrantyClaimStatusEnum.CLOSED_OUT_OF_WARRANTY:
            return 'Closed: Out of warranty';
        case NodeType.WarrantyClaimStatusEnum.CLOSED_OTHER:
            return 'Closed: Other';
    }
};

type NextStageActionType = {
    id: NodeType.WarrantyClaimStatusGroupEnum;
    label: string;
    icon: SemanticICONS;
    confirm?: ConfirmProps;
};

export type WarrantyClaimStatusGroupEnumFlowConfigType = {
    value: NodeType.WarrantyClaimStatusGroupEnum;
    name: string;
    statusOptions: NodeType.WarrantyClaimStatusEnum[];
    nextStages: NextStageActionType[];
};

export const WarrantyClaimStatusGroupEnumFlowConfig: WarrantyClaimStatusGroupEnumFlowConfigType[] = [
    {
        value: NodeType.WarrantyClaimStatusGroupEnum.NEW,
        name: 'New',
        statusOptions: [NodeType.WarrantyClaimStatusEnum.NEW],
        nextStages: [
            {
                id: NodeType.WarrantyClaimStatusGroupEnum.PROCESSING,
                label: 'Start Processing',
                icon: 'chevron right',
            },
        ],
    },
    {
        value: NodeType.WarrantyClaimStatusGroupEnum.PROCESSING,
        name: 'Processing',
        statusOptions: [NodeType.WarrantyClaimStatusEnum.PROCESSING],
        nextStages: [
            // {
            //     id: NodeType.WarrantyClaimStatusGroupEnum.ON_HOLD,
            //     label: 'Put On Hold',
            //     icon: 'pause',
            // },
            {
                id: NodeType.WarrantyClaimStatusGroupEnum.CLOSED,
                label: 'Close Case',
                icon: 'chevron right',
            },
        ],
    },
    {
        value: NodeType.WarrantyClaimStatusGroupEnum.CLOSED,
        name: 'Closed',
        statusOptions: StageStatusOptionsMap[NodeType.WarrantyClaimStatusGroupEnum.CLOSED],
        nextStages: [
            {
                id: NodeType.WarrantyClaimStatusGroupEnum.PROCESSING,
                label: 'Re-open',
                icon: 'refresh',
                confirm: {
                    confirmMessage: {
                        header: 'Change Status?',
                    },
                    confirmButton: {
                        content: 'Change',
                    },
                },
            },
        ],
    },
];
