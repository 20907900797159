import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import WaterlinkPage from './WaterlinkPage';
import * as React from 'react';
import WaterlinkConfigPage from './WaterlinkConfigPage';

export const Router = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/'} exact component={WaterlinkPage} />
            <ModuleRoute path={'/settings'} exact component={WaterlinkConfigPage} />
        </ModuleRootSwitch>
    );
};
