import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { Panel } from '@poolware/components';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import NewProductTraitForm from './NewProductTraitForm';
import { TraitDeclarationPicker_Tree } from '../../../components-api-connected';

const DeclarationPickerContainer = styled(Segment)`
    max-height: 500px;
    min-height: 400px;
    overflow-y: auto;
`;

export interface NewProductTraitPageProps {
    products: NodeType.Product[];
    onSubmit: () => any;
    onCancel: () => any;
}

export const NewProductTrait: React.FC<NewProductTraitPageProps> = (props) => {
    const { products, onCancel, onSubmit } = props;
    const [selectedTraitDeclaration, setSelected] = useState(undefined);

    return (
        <Panel>
            <Panel.Header
                button={
                    !selectedTraitDeclaration && {
                        onClick: onCancel,
                        icon: 'close',
                    }
                }
            >
                New Trait Field
            </Panel.Header>
            <Panel.Body>
                <Panel.Item label={'Product'}>{products.map((p) => p.name).join(', ')}</Panel.Item>
                <Divider />

                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column width={6}>
                            <Header sub>Trait Declarations</Header>
                            <DeclarationPickerContainer>
                                <TraitDeclarationPicker_Tree
                                    current={[selectedTraitDeclaration]}
                                    onSelect={(pd) => setSelected(pd)}
                                />
                            </DeclarationPickerContainer>
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <NewProductTraitForm
                                selectedTraitDeclarationId={selectedTraitDeclaration && selectedTraitDeclaration.id}
                                products={products}
                                onCancel={onCancel}
                                onSubmit={onSubmit}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Panel.Body>
        </Panel>
    );
};
