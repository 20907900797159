import { MutationHookOptionsWorkOrder, NodeType, useMutationWorkOrder } from '@poolware/api';
import * as _ from 'lodash';

export const useWorkOrderMutators = (options?: MutationHookOptionsWorkOrder) => {
    const mutateWorkOrder = useMutationWorkOrder(options);

    const createWithJobTodoTemplate = (input: {
        serviceJob: NodeType.NodeOrId<NodeType.ServiceJob>;
        jobTemplate: NodeType.NodeOrId<NodeType.JobTodoTemplate>;
        title?: string;
    }) => {
        const { serviceJob, jobTemplate, title } = input;
        return mutateWorkOrder.create({
            title: title ? _.trim(title) : undefined,
            serviceJob: NodeType.extractId(serviceJob),
            todoTemplates: [NodeType.extractId(jobTemplate)],
        });
    };

    const addFiles = async (workOrder: NodeType.WorkOrder, fileIds: NodeType.ID[]) => {
        return await mutateWorkOrder.update({
            id: workOrder.id,
            files: {
                assign: fileIds,
            },
        });
    };

    const removeFile = async (workOrder: NodeType.WorkOrder, files: NodeType.FileUpload[]) => {
        const fileIds = files.map((f) => f.id);
        return await mutateWorkOrder.update({
            id: workOrder.id,
            files: {
                delete: fileIds,
            },
        });
    };

    return {
        addFiles,
        removeFile,
        createWithJobTodoTemplate,
        ...mutateWorkOrder,
    };
};
