import * as React from 'react';
import * as _ from 'lodash';
import { DebouncedInput } from '@poolware/components';
import { useRecurServiceJobListActions } from '../../redux/reducer-recur-service-job-list';

export const FilterByServiceJobNumber: React.FC<{ label?: string; placeholder?: string }> = ({
    label,
    placeholder,
}) => {
    const { State, Actions } = useRecurServiceJobListActions();

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            Actions.setServiceJobNumber(null);
        } else {
            Actions.setServiceJobNumber(newValue);
        }
    };

    return (
        <DebouncedInput
            label={label}
            initialValue={State.filters?.serviceJobNumber}
            placeholder={placeholder || 'Search by Service Case Number...'}
            onChange={handleChange}
        />
    );
};

export const FilterByWorkOrderNumber: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { State, Actions } = useRecurServiceJobListActions();

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            Actions.setWorkOrderNumber(null);
        } else {
            Actions.setWorkOrderNumber(newValue);
        }
    };

    return (
        <DebouncedInput
            label={label}
            initialValue={State.filters?.workOrderNumber}
            placeholder={placeholder || 'Search by Work Order ID...'}
            onChange={handleChange}
        />
    );
};

export const FilterByCustomerCRN: React.FC<{ label?: string; placeholder?: string }> = ({ label, placeholder }) => {
    const { State, Actions } = useRecurServiceJobListActions();

    const handleChange = (term: string) => {
        const newValue = _.trim(term);
        if (!newValue) {
            Actions.setCustomerCRN(null);
        } else {
            Actions.setCustomerCRN(newValue);
        }
    };

    return (
        <DebouncedInput
            label={label}
            initialValue={State.filters?.customerCRN}
            placeholder={placeholder || 'Search by Customer CRN...'}
            onChange={handleChange}
        />
    );
};
