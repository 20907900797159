import * as React from 'react';
import { Display, Panel } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { CustomerLink, PoolLink } from '../../routes/Links';

interface PoolDetailsProps {
    pool: NodeType.Pool;
}

export default class PoolDetails extends React.Component<PoolDetailsProps> {
    render() {
        let { pool } = this.props;
        const address = pool?.site?.address;
        const entity = pool?.entity;
        const customerCrn = pool?.entity?.user?.customer?.crn || '--';
        const customerId = pool?.entity?.user?.customer?.id;
        const displayCustomer = <Display.Entity value={entity} />;
        const displayCustomerLink = customerId ? (
            <CustomerLink customerId={customerId}>{displayCustomer}</CustomerLink>
        ) : (
            displayCustomer
        );

        const poolName = pool?.name || 'pool';
        const displayPoolLink =
            customerId && pool?.id ? (
                <PoolLink poolId={pool?.id} customerId={customerId}>
                    {poolName}
                </PoolLink>
            ) : (
                poolName
            );

        return (
            <>
                <Panel.Item label="Customer Name">{displayCustomerLink}</Panel.Item>
                <Panel.Item label="Customer CRN">{customerCrn}</Panel.Item>
                <Panel.Divider />
                <Panel.Item label="Pool Number">{pool?.bottleNumber || '--'}</Panel.Item>
                <Panel.Item label="Pool Name">{displayPoolLink}</Panel.Item>
                <Panel.ItemAddress label="Pool Address" content={address} showMapLink={true} />
                <Panel.Item label="Pool Volume">{pool?.volume ? `${pool?.volume} Litres` : '--'}</Panel.Item>
                <Panel.Item label="Pool Type" content={pool?.type?.name || '--'} />
                <Panel.Item label="Sanitiser Type" content={pool?.sanitiser?.name || '--'} />
            </>
        );
    }
}
