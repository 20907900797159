import * as React from 'react';
import { useMemo } from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Icon } from 'semantic-ui-react';
import { ConnectionTableDef, DefaultConnectionTable, Display, IconWithPopup } from '@poolware/components';
import { mapServiceJobStageTypeToIconProps, mapServiceJobStageTypeToName, ModuleIconNames } from '../../../constants';
import { InvoiceStatusLabel } from '../../Invoice/constants';
import { useViewer } from '@poolware/app-shell';

export const WorkOrdersTable: React.FC<{
    serviceJob: NodeType.ServiceJob;
    onView;
    noDataComponent?: React.ReactElement | null;
    selectedWoId?: NodeType.ID;
}> = ({ serviceJob, onView, noDataComponent, selectedWoId }) => {
    const workOrders = fromEdges(serviceJob.workOrders);
    const { modulesAccess } = useViewer();

    const tableDef: ConnectionTableDef<NodeType.WorkOrder> = useMemo(
        () => [
            {
                header: 'Status',
                width: 2,
                cellProps: (workOrder) => {
                    if (workOrder.id !== selectedWoId) {
                        return { style: { borderLeft: '3px solid #FFFFFF' } };
                    }
                    return { style: { borderLeft: '3px solid #831843' } };
                },
                cell: (workOrder) => {
                    return (
                        <>
                            <Icon {...mapServiceJobStageTypeToIconProps(workOrder.stage?.type)} />
                            {mapServiceJobStageTypeToName(workOrder.stage?.type)}
                        </>
                    );
                },
            },
            {
                header: 'ID',
                width: 1,
                cell: (workOrder) => {
                    return <span style={{ color: '#4183c4' }}>{workOrder.workOrderNumber}</span>;
                },
            },
            {
                header: 'Work Order',
                width: 8,
                cell: (workOrder) => {
                    const hasFiles = workOrder?.files?.pageMeta?.totalCount > 0;
                    const hasTestReports = workOrder?.testReports?.pageMeta?.totalCount > 0;
                    const hasNotes = workOrder?.notes?.pageMeta?.totalCount > 0;
                    return (
                        <div tw={'flex flex-row justify-between gap-2'}>
                            <span>{workOrder.title}</span>
                            <div>
                                {hasFiles && <IconWithPopup popup={{ content: 'Files' }} name={'images'} />}
                                {hasTestReports && <IconWithPopup popup={{ content: 'Water Test' }} name={'lab'} />}
                                {hasNotes && (
                                    <IconWithPopup popup={{ content: 'Notes' }} name={'sticky note outline'} />
                                )}
                            </div>
                        </div>
                    );
                },
            },

            {
                header: 'Appointment',
                cell: (workOrder) => {
                    if (!workOrder.appointmentItem) return null;
                    return (
                        <>
                            <Icon name={ModuleIconNames.Appointment} />
                            <Display.Date value={workOrder.appointmentItem.startDate} format={'lll'} />
                        </>
                    );
                },
            },
            {
                header: 'Assigned To',
                cell: (workOrder) => {
                    return <Display.Entity value={workOrder.assignedTo} />;
                },
            },
            modulesAccess.FieldServices?.invoices && {
                header: 'Invoice',
                cell: (workOrder) => {
                    const invoices = fromEdges<NodeType.AbstractDocument>((workOrder as any)?.invoices);
                    return (
                        <div tw={'flex flex-row justify-between gap-2'}>
                            {invoices.map((invoice) => {
                                return (
                                    <div key={invoice.id}>
                                        <InvoiceStatusLabel status={invoice.statusTag} />
                                    </div>
                                );
                            })}
                        </div>
                    );
                },
            },
        ],
        [serviceJob.workOrders, selectedWoId, modulesAccess.FieldServices]
    );

    return (
        <DefaultConnectionTable
            onRowClick={onView}
            tableCellProps={(workOrder) => {
                if (!selectedWoId) {
                    return undefined;
                }

                if (workOrder.id !== selectedWoId) {
                    return { style: { color: '#777777' } };
                } else {
                    return { style: { backgroundColor: '#ebe9f5', fontWeight: 'bold' } };
                }
            }}
            tableProps={{ color: 'grey' }}
            tableDef={tableDef}
            connectionData={workOrders}
            connectionState={undefined}
            noDataComponent={noDataComponent}
        />
    );
};
