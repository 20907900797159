import gql from 'graphql-tag';
import { addressFragment } from '@poolware/api';

export const searchViaCustomerQuery = gql`
    query CustomerSearchList(
        $pageSize: Int
        $page: Int
        $sort: CustomerViewerCustomersSort
        $search: CustomerViewerCustomersSearch
    ) {
        viewer {
            customers(first: $pageSize, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        crn
                        contactName
                        user {
                            id
                            firstName
                            lastName
                        }
                        primaryAddress {
                            id
                            ...AddressFragment
                        }
                    }
                }
                pageInfo {
                    hasPreviousPage
                    hasNextPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
    ${addressFragment}
`;

export const searchViaPoolQuery = gql`
    query CustomerByPoolSearchList(
        $pageSize: Int
        $page: Int
        $sort: PoolViewerPoolsSort
        $search: PoolViewerPoolsSearch
    ) {
        viewer {
            pools(first: $pageSize, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        name
                        bottleNumber
                        address {
                            id
                            ...AddressFragment
                        }
                        entity {
                            id
                            user {
                                id
                                firstName
                                lastName
                                customer {
                                    id
                                    crn
                                    user {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }
                    }
                }
                pageInfo {
                    hasPreviousPage
                    hasNextPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
    ${addressFragment}
`;
