import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import { AuthConnectProps, NodeType } from '@poolware/api';
import * as URLBuilder from '../routes/url-builder';
import {
    SidebarMenuItemButton,
    SidebarMenuItemExternalLink,
    SidebarMenuItemNavLink,
    ThemeStateProps,
    useAppConfig,
    useTheme,
    useViewer,
    Zendesk,
} from '@poolware/app-shell';
import { AppIconNames } from '../components/AppIcons';
import { AppSidebar, AppSidebarConfig, AppSidebarMenuItem, LayoutControlProps } from '@poolware/components';

const isDevelopment = process.env.NODE_ENV === 'development';

interface AppSidebarContainerProps extends AuthConnectProps, ThemeStateProps, LayoutControlProps {}

const ZendeskLinkButton = ({ children, className }) => {
    return (
        <SidebarMenuItemButton
            children={children}
            className={className}
            onClick={(e) => {
                e.preventDefault();
                Zendesk.activate();
            }}
        />
    );
};

const supportButtonItem = (): any => {
    if (!Zendesk.isEnabled()) {
        return null;
    }

    return {
        icon: 'comment outline',
        to: '/',
        label: 'Contact Support',
        as: ZendeskLinkButton,
    };
};

const AppSidebarContainer: React.FC<AppSidebarContainerProps> = (props) => {
    const { layoutState } = props;
    const { ThemeState } = useTheme();
    const { viewer, modulesAccess, canAccessFeature } = useViewer();
    const appConfig = useAppConfig();
    const isWarranter = viewer.franchise?.types.includes(NodeType.FranchiseType.Warranter);

    useEffect(() => {
        Zendesk.hideSupportButton();
    }, []);

    const createItemConf = (input: {
        to: string;
        icon?: SemanticICONS;
        secondaryIcon?: SemanticICONS;
        label: string | React.ReactNode;
        disabled?: boolean;
        sub?: boolean;
        as?: any;
    }): AppSidebarMenuItem => {
        const { disabled, icon, to, sub, label, secondaryIcon, as } = input;
        if (disabled) {
            return null;
        }
        return {
            to,
            icon,
            label: label,
            secondaryIcon: secondaryIcon,
            as: as || SidebarMenuItemNavLink,
            sub: sub,
        };
    };

    const getWarrantyItem = () => {
        if (isWarranter) {
            return createItemConf({
                as: SidebarMenuItemExternalLink,
                to: appConfig.apps?.warrantyHQ,
                icon: 'certificate',
                label: (
                    <>
                        Warranty Cases <Icon name={'external square alternate'} />
                    </>
                ),
            });
        } else {
            return createItemConf({
                to: URLBuilder.WarrantyClaim().all,
                icon: 'certificate',
                label: 'Warranty Claims',
            });
        }
    };

    const themeLogo = ThemeState.theme.logoPath ?? (
        <div tw={'h-full items-center flex justify-start'}>
            <div tw={'pl-2 text-xl rounded-lg'}>
                {/*<Icon name={'map marker'} />*/}
                <span tw={'pl-2'}>{ThemeState.theme.navbar?.logo?.title || 'EZ UI'}</span>
            </div>
        </div>
    );
    const themeImage = ThemeState.theme.iconPath ?? (
        <div tw={'rounded-lg h-full text-xl w-full flex items-center justify-center'}>
            <Icon name={'anchor'} />
        </div>
    );

    const config: AppSidebarConfig = useMemo(() => {
        return {
            logo: {
                icon: themeImage,
                image: themeLogo,
            },
            menu: [
                modulesAccess.Customers?.enabled && {
                    header: 'Customers',
                    items: [
                        createItemConf({ to: URLBuilder.Search.search, icon: 'search', label: 'Search' }),
                        createItemConf({ to: URLBuilder.Customers.all, icon: 'users', label: 'All Customers' }),
                        createItemConf({ to: URLBuilder.Customers.new, icon: 'plus', label: 'New Customer' }),
                    ],
                },
                modulesAccess.FieldServices?.enabled && {
                    header: 'Field Service',
                    items: [
                        modulesAccess.FieldServices?.appointments &&
                            createItemConf({
                                to: URLBuilder.Scheduler.home,
                                icon: 'calendar alternate outline',
                                label: 'Service Calendar',
                            }),
                        modulesAccess.FieldServices?.serviceJobs &&
                            createItemConf({
                                to: '/service-jobs/sj/single',
                                // secondaryIcon: 'caret down',
                                icon: AppIconNames.ServiceJob.ServiceCall,
                                label: 'Service Cases',
                            }),
                        createItemConf({
                            to: '/service-jobs/wo-feed',
                            icon: AppIconNames.ServiceJob.WorkOrder,
                            label: 'Work Orders',
                        }),
                        modulesAccess.FieldServices?.recurringCasesShowPageLink &&
                            createItemConf({
                                to: '/service-jobs/sj/recur',
                                icon: AppIconNames.ServiceJob.RecurringService,
                                label: 'Recurring Services',
                            }),
                        modulesAccess.FieldServices?.invoices &&
                            createItemConf({
                                to: '/service-jobs/invoices',
                                icon: AppIconNames.ServiceJob.Invoice,
                                label: 'Invoices',
                            }),
                    ],
                },
                {
                    header: 'Company',
                    items: [
                        modulesAccess.Reports?.enabled &&
                            createItemConf({
                                to: URLBuilder.Reports().view,
                                icon: 'chart bar',
                                label: 'Overview',
                            }),
                        modulesAccess.Reports?.enabled &&
                            createItemConf({
                                to: URLBuilder.RecentActivity().view,
                                icon: 'time',
                                label: 'Recent Activity',
                            }),
                        modulesAccess.Staff?.enabled &&
                            createItemConf({
                                to: URLBuilder.Staff().all,
                                icon: 'spy',
                                label: 'Staff',
                            }),
                    ],
                },
                {
                    header: 'Resources',
                    items: [
                        modulesAccess.Calculators?.enabled &&
                            createItemConf({
                                to: URLBuilder.Calculators().all,
                                icon: 'calculator',
                                label: 'Heat Calcs',
                            }),
                        modulesAccess.Warranties?.enabled && getWarrantyItem(),

                        modulesAccess.Other?.swimartTrainingForms &&
                            createItemConf({
                                to: '/training-forms',
                                icon: 'wordpress forms',
                                label: 'Training Request',
                            }),

                        modulesAccess.ProductCatalog?.enabled &&
                            createItemConf({
                                to: URLBuilder.ProductCatalog().all,
                                icon: 'cubes',
                                label: 'Products',
                            }),
                        modulesAccess.Downloads?.enabled &&
                            createItemConf({
                                to: URLBuilder.Downloads().all,
                                icon: 'cloud download',
                                label: 'Downloads',
                            }),
                        modulesAccess.SDS_LIST?.enabled &&
                            createItemConf({
                                to: '/sds',
                                icon: 'info circle',
                                label: 'SDS Search',
                            }),
                    ],
                },
                {
                    header: 'Admin',
                    items: [
                        modulesAccess.FranchiseAdmin?.enabled &&
                            createItemConf({
                                to: URLBuilder.FranchiseAdmin().Franchise().view,
                                icon: 'building',
                                label: 'Company Profile',
                            }),
                        modulesAccess.FranchiseAdmin?.enabled &&
                            createItemConf({
                                to: '/settings',
                                icon: 'cog',
                                label: 'Settings',
                            }),
                        modulesAccess.Pools?.poolList &&
                            createItemConf({
                                to: '/pools',
                                icon: 'life ring',
                                label: 'Pools',
                            }),
                    ],
                },
                modulesAccess.HQAdmin?.enabled && {
                    header: 'HQ Admin',
                    items: [
                        createItemConf({
                            to: URLBuilder.HQAdmin().Franchise().all,
                            icon: 'building',
                            label: 'Franchises',
                        }),
                    ],
                },
                isDevelopment && {
                    header: 'Dev Tools',
                    items: [
                        createItemConf({
                            to: '/dev',
                            icon: 'cog',
                            label: 'Dev',
                        }),
                    ],
                },
                modulesAccess.Support?.enabled && {
                    header: 'Support',
                    items: [supportButtonItem()],
                },
            ],
        };
    }, [appConfig, ThemeState, viewer, canAccessFeature, modulesAccess]);

    return (
        <AppSidebar
            layoutState={layoutState}
            config={config}
            onClick={props.onSidebarMenuClick}
            onDimmerClick={() => props.onToggleMenu(true)}
        />
    );
};

export default AppSidebarContainer;
