import { NodeType, useDefaultConnectionState, useQueryConnection } from '@poolware/api';
import gql from 'graphql-tag';
import * as _ from 'lodash';

const QL = gql`
    query QueryJobTotoTemplatesList($first: Int, $page: Int, $search: JobTodoTemplateViewerJobTodoTemplatesSearch) {
        viewer {
            jobTodoTemplates(first: $first, page: $page, search: $search) {
                edges {
                    node {
                        id
                        title
                        description
                        recommendedPrice
                        timeEstimate
                        group {
                            id
                            title
                        }
                        organisation {
                            id
                            name
                        }
                        franchise {
                            id
                            name
                        }
                        ownedByWorkOrderTemplate {
                            id
                            templateTitle
                        }
                        includedInJobTodoTemplateAssocs {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                        items {
                            edges {
                                node {
                                    id
                                    title
                                    orderIndex
                                }
                            }
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
`;

interface UseQueryJobTemplatesConnectionInputConf {
    searchAny?: string;
    title?: string;
    showOwnedTemplates?: boolean;
}

function mapPropsToSearchQuery(
    props?: UseQueryJobTemplatesConnectionInputConf
): NodeType.JobTodoTemplateViewerJobTodoTemplatesSearch {
    const search: NodeType.JobTodoTemplateViewerJobTodoTemplatesSearch = {
        ownedByWorkOrderTemplate: {
            // Don't fetch owned templates by default;
            id: null,
        },
    };
    if (!props) {
        return search;
    }

    const { showOwnedTemplates, searchAny, title } = props;

    if (showOwnedTemplates) {
        // If ownedByWorkOrderTemplate is not set, all templates (including owned) will be returned.
        _.unset(search, 'ownedByWorkOrderTemplate');
    }
    if (searchAny) {
        _.set(search, 'anyString', searchAny);
    }
    if (title) {
        _.set(search, 'title.like', title);
    }
    return search;
}

export function useQueryJobTemplatesConnection(props?: UseQueryJobTemplatesConnectionInputConf) {
    const connectionPath = 'viewer.jobTodoTemplates';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 50,
        sortKey: 'title',
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
