import * as React from 'react';
import { useWorkOrderFeedActions, WorkOrderBookingStatus } from '../../redux';
import { Optionable, SegmentedSelectButtons } from '@poolware/components';
import { jobStageTypeOptions, ModuleColorNames } from '../../constants';
import { TopFilterBar } from '../../components/TopFilterBar';
import { ToolbarFilterByDateRange } from './FilterByDate';
import { StageFilterButtons } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobStageType';

export const FilterByJobStageTypeButtons: React.FC = () => {
    const { State, Action } = useWorkOrderFeedActions();

    return (
        <StageFilterButtons
            color={ModuleColorNames.WorkOrder}
            setServiceJobStageType={Action.setServiceStageType}
            setServiceJobStageStatus={Action.setServiceStageStatus}
            valueStageTypes={State.filters.serviceStageType}
            valueStageStatus={State.filters.serviceStageStatus}
        />
    );
};

export const FilterByJobStageTypeButtons2: React.FC = () => {
    const { State, Action } = useWorkOrderFeedActions();

    const { serviceStageType } = State.filters;

    const ALL = '___ALL___';

    const allOptions = jobStageTypeOptions.map<Optionable<any>>((s) => ({
        text: s.text,
        icon: s.icon?.name,
        value: s.value,
    }));

    allOptions.unshift({
        text: 'All',
        value: ALL,
    });

    const currentValue = serviceStageType?.length > 0 ? serviceStageType : [ALL];

    const valueComparator = (value, option: Optionable<any>) => {
        return value?.includes(option?.value);
    };

    return (
        <SegmentedSelectButtons
            fluid={false}
            color={ModuleColorNames.WorkOrder}
            options={allOptions}
            value={currentValue}
            valueComparator={valueComparator}
            onChange={(o) => {
                if (o.value === ALL) {
                    Action.setServiceStageType([]);
                } else {
                    Action.setServiceStageType([o.value]);
                }
            }}
        />
    );
};

export const FilterByBookingStatusButtons: React.FC = () => {
    const { Action, State } = useWorkOrderFeedActions();
    const { woBookingStatus } = State.filters;

    const allOptions: Optionable<WorkOrderBookingStatus>[] = [
        // {
        //     text: 'All',
        //     value: WorkOrderBookingStatus.ANY,
        // },
        {
            text: 'Scheduled',
            icon: 'calendar check outline',
            value: WorkOrderBookingStatus.BOOKED,
        },
        {
            text: 'Unscheduled',
            icon: 'calendar times outline',
            value: WorkOrderBookingStatus.UNBOOKED,
        },
    ];
    return (
        <SegmentedSelectButtons
            fluid={false}
            color={ModuleColorNames.WorkOrder}
            options={allOptions}
            value={woBookingStatus}
            onChange={(option) => {
                Action.setBookingFilter(option.value);
            }}
        />
    );
};

const FilterSummary: React.FC = () => {
    const { Action, State } = useWorkOrderFeedActions();
    const filters = State.filters;
    if (!filters?.hasActiveFilters) {
        return <div className={'filter-summary'} />;
    }

    return (
        <>
            <TopFilterBar.SummaryFilterIcon />
            {filters.serviceJobTemplateId || filters.serviceGroupId ? (
                <TopFilterBar.SummaryItem>Group</TopFilterBar.SummaryItem>
            ) : null}
            {!!filters.staffIds?.length && <TopFilterBar.SummaryItem>Staff</TopFilterBar.SummaryItem>}
            {!!filters.woNumber && <TopFilterBar.SummaryItem>Work ID</TopFilterBar.SummaryItem>}
            <TopFilterBar.SummaryClearButton onClick={Action.clearAllFilters} />
        </>
    );
};

export const ToolbarQuickFilters: React.FC<{ showSidebar?; setShowSidebar? }> = ({ setShowSidebar, showSidebar }) => {
    const { State } = useWorkOrderFeedActions();
    const { hasActiveFilters } = State.filters;
    return (
        <TopFilterBar data-testid={'ToolbarContainer'}>
            <TopFilterBar.Row>
                <FilterByBookingStatusButtons />
                <TopFilterBar.Spacer />
                <div tw={'hidden xl:block'}>
                    <ToolbarFilterByDateRange />
                </div>
                <TopFilterBar.Spacer />
                <div>
                    <FilterByJobStageTypeButtons />
                    {/*<FilterByStatusButtons />*/}
                </div>
            </TopFilterBar.Row>
            {hasActiveFilters && (
                <TopFilterBar.RowSummary>
                    <FilterSummary />
                </TopFilterBar.RowSummary>
            )}
        </TopFilterBar>
    );
};
