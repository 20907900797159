import * as React from 'react';
import { useEffect } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryServiceJob } from './use-query-service-job';
import {
    ConnectionErrorMessage,
    DebugJsonButton,
    MenuBar,
    MenuBarGroupItemConfType,
    MenuBarGroupMODE,
    MenuBarItemWithConfirmProps,
    PageLayout,
    PageSkeletonLoader,
    PageTabs,
    StickyMenuBar,
    TabPaneConf,
    toastError,
    toastSuccess,
    useTabCtrl,
} from '@poolware/components';
import { PanelFormJobDetails } from './PanelFormJobDetails';
import { PanelFormJobCustomerAndLocation } from './PanelFormJobCustomerAndLocation';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { NodeType } from '@poolware/api';
import { Divider, Icon } from 'semantic-ui-react';
import { PageHeaderToolbarServiceCase } from './PageHeaderToolbarServiceCase';
import { PanelFileAttachments } from './TabFileAttachments/PanelFileAttachments';
import { WorkOrdersTab } from './TabWorkOrders/WorkOrdersTab';
import { useBookAppointmentForServiceJob } from './useBookAppointmentForServiceJob';
import { TabInvoices } from './TabInvoices/TabInvoices';
import * as _ from 'lodash';

const DeleteMessage: React.FC<{ serviceJob: NodeType.ServiceJob }> = ({ serviceJob }) => {
    return (
        <div>
            <p>If you proceed the following will happen:</p>
            <ul>
                <li>Service Case #{serviceJob.serviceJobNumber} will be deleted</li>
                <li>All work orders belonging to this Service Case will be deleted</li>
                <li>All Appointments booked for work orders will be deleted</li>
            </ul>
            <p>There is NO UNDO for this action!</p>
        </div>
    );
};

const DeleteServiceJobMenuButtonItem: React.FC<
    MenuBarItemWithConfirmProps & {
        onDelete: () => any;
        serviceJob: NodeType.ServiceJob;
    }
> = ({ onDelete, serviceJob, ...rest }) => {
    return (
        <MenuBar.DropdownItemWithConfirm
            {...rest}
            icon={'trash'}
            color="orange"
            title={`Delete Service Case`}
            onClick={onDelete}
            confirm={{
                showLoaderOnConfirm: true,
                confirmMessage: {
                    header: `Delete Service Case?`,
                    content: <DeleteMessage serviceJob={serviceJob} />,
                },
                cancelButton: {
                    content: 'Keep',
                },
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                    negative: true,
                },
            }}
        />
    );
};

enum Tabs {
    WorkOrders = 'work-orders',
    Files = 'files',
    Invoices = 'invoices',
}

export const ServiceJobSinglePageView: React.FC = () => {
    const { AppNavigator, params } = useAppNavigator();
    const sjId = params.sjId;
    if (!sjId) {
        throw 'Expected jsId param';
    }

    const { node: serviceJob, loading, refetch, refetchQuery, error } = useQueryServiceJob(sjId);
    const serviceJobMutators = useServiceJobMutators({ refetchQueries: [refetchQuery], awaitRefetchQueries: true });

    const { modulesAccess } = useViewer();
    const canEdit = !modulesAccess.FieldServices?.calendarSingleStaffMode || serviceJob?.checkMutations?.update;
    const canDelete = !modulesAccess.FieldServices?.calendarSingleStaffMode || serviceJob?.checkMutations?.delete;

    const bookFn = useBookAppointmentForServiceJob({ serviceJob });

    useEffect(() => {
        refetch();
    }, []);

    const goBack = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    const onDelete = async () => {
        try {
            const r = window.confirm('Delete Service Case?');
            if (r !== true) {
                return;
            }
            await serviceJobMutators.ServiceJobMutator.delete(serviceJob);
            toastSuccess({ title: 'Deleted successfully' });
            AppNavigator.replace('/', { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const onPrint = () => {
        // TODO: the path prefix below is hardcoded atm.
        // Need to find a way how to dynamically resolve the path.
        AppNavigator.navigate(`/print/service-jobs/sj/${serviceJob.id}`, { setOrigin: true });
    };

    const onBookAppointment = () => {
        bookFn(serviceJob);
    };

    let calendarAllowBookiingFromServiceCase = true; //modulesAccess.FieldServices.calendarAutoCreateWo && ;
    const menuConf: MenuBarGroupItemConfType[] = [
        {
            onClick: onPrint,
            icon: 'print',
            responsive: true,
            title: 'Print',
            color: 'blue',
        },
        calendarAllowBookiingFromServiceCase && {
            onClick: onBookAppointment,
            icon: ModuleIconNames.Appointment,
            responsive: true,
            title: 'Book Appointment',
            color: 'green',
        },
    ];

    const filesCount = serviceJob?.files?.edges?.length || 0;

    const panes: TabPaneConf[] = [
        {
            title: 'Work Orders & Appointments',
            icon: ModuleIconNames.WorkOrder,
            key: Tabs.WorkOrders,
            render: () => <WorkOrdersTab serviceJob={serviceJob} refetchQuery={refetchQuery} />,
        },
        {
            title: 'Service Case Files',
            icon: 'file alternate outline',
            key: Tabs.Files,
            label: filesCount > 0 ? filesCount : undefined,
            render: () => <PanelFileAttachments serviceJob={serviceJob} refetchQuery={refetchQuery} />,
        },
        modulesAccess.FieldServices?.invoices && {
            title: 'Invoices',
            icon: ModuleIconNames.Invoice,
            key: Tabs.Invoices,
            render: () => <TabInvoices serviceJob={serviceJob} refetchQuery={refetchQuery} />,
        },
    ];

    const tabCtrl = useTabCtrl({ panes, defaultTab: AppNavigator.tab, onTabChange: AppNavigator.setTab });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !serviceJob) {
        return <NotFoundPage />;
    }

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} icon={'chevron left'} title={'Cases'} />
                    <MenuBar.HeaderItem>
                        <Icon color={ModuleColorNames.ServiceCall} name={ModuleIconNames.ServiceCall} />
                        Service Case
                        <span tw={'pl-2 hidden lg:block font-normal'}>
                            - {_.truncate(serviceJob.title, { length: 100 })}
                        </span>
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <DebugJsonButton data={serviceJob} />

                    <MenuBar.Group
                        menu={{
                            menuMode: MenuBarGroupMODE.EXPANDED,
                            color: 'blue',
                            icon: 'bars',
                            direction: 'left',
                        }}
                        items={menuConf}
                    />
                    <MenuBar.Dropdown color={'red'} icon={'bars'}>
                        <DeleteServiceJobMenuButtonItem
                            locked={!canDelete}
                            serviceJob={serviceJob}
                            onDelete={onDelete}
                        />
                    </MenuBar.Dropdown>
                </MenuBar.Section>
            </StickyMenuBar>

            <PageHeaderToolbarServiceCase serviceJob={serviceJob} serviceJobMutators={serviceJobMutators} />
            <PageLayout.BodySection width={'screen-wide'}>
                <PageLayout.TwoColumns>
                    <PanelFormJobDetails
                        serviceJobMutators={serviceJobMutators}
                        serviceJob={serviceJob}
                        locked={!canEdit}
                    />
                    <PanelFormJobCustomerAndLocation serviceJob={serviceJob} readOnly={!canEdit} />
                </PageLayout.TwoColumns>

                <Divider hidden={true} />

                <PageTabs {...tabCtrl} />
            </PageLayout.BodySection>
            <div tw={'pb-16'} />
        </PageLayout>
    );
};
