import { MonthlyHeatPumpProfile, MonthProfile } from '../common/types';
import { HeatPumpModel } from '../data';
import * as _ from 'lodash';

interface RecommendedHeatPumpModelInputProps {
    monthlyHeatProfiles: MonthlyHeatPumpProfile[];
    heaterCatalog: HeatPumpModel[];
}

export function computeRecommendedHeatPumpModel(input: RecommendedHeatPumpModelInputProps) {
    const recommendedModels: HeatPumpModel[] = [];
    const { monthlyHeatProfiles } = input;

    if (!input.heaterCatalog || input.heaterCatalog.length === 0) {
        return {
            requiredQuantity: 0,
            minimumOutput: 0,
            recommendedModels: [],
            recommendedHeater: null,
        };
    }

    let isShowAlert = false;

    const electroCapacitySorted = input.heaterCatalog.sort(function (a, b) {
        if (a.capacity > b.capacity) return 1;
        if (a.capacity < b.capacity) return -1;
        return 0;
    });

    const highestCapacityHeater = electroCapacitySorted[electroCapacitySorted.length - 1];
    const highestCapacity = highestCapacityHeater?.capacity || 0;
    const highestInputPower = highestCapacityHeater?.input || 0;

    for (let monthProfile of monthlyHeatProfiles) {
        let heatTotal;
        let recommendedModel: HeatPumpModel;
        let modelInputPower;

        heatTotal = monthProfile.heatTotal;

        //calculate recommended model
        if (heatTotal > highestCapacity) {
            recommendedModel = highestCapacityHeater;
        } else {
            if (heatTotal === 0) {
                recommendedModel = undefined;
            } else {
                recommendedModel = electroCapacitySorted.find((item) => item.capacity > heatTotal);
                if (!recommendedModel) {
                    recommendedModel = highestCapacityHeater;
                }
            }
        }

        //calculate model capacity requirement
        let tempModel;
        if (heatTotal <= 0) {
            tempModel = undefined;
        } else {
            if (heatTotal >= highestCapacity) {
                tempModel = highestCapacityHeater;
            } else {
                tempModel = recommendedModel;
            }
        }

        recommendedModels.push(tempModel);

        //calculate model input power
        if (heatTotal >= highestCapacity) {
            modelInputPower = highestInputPower;
            isShowAlert = true;
        } else {
            if (!recommendedModel) {
                modelInputPower = 0;
            } else {
                modelInputPower = recommendedModel.input;
            }
        }
    }

    //calculate max value
    let quantity = 0;
    const max_value = _.maxBy(monthlyHeatProfiles, (p) => p.heatTotal);

    //calculate minimum output
    let minimumOutput = Math.floor(max_value?.heatTotal);

    let recommendedHeater: HeatPumpModel | undefined = undefined;
    //calculate quantity
    if (minimumOutput > highestCapacity) {
        quantity = minimumOutput / highestCapacity;
        recommendedHeater = highestCapacityHeater;
    } else {
        if (minimumOutput > 0) {
            recommendedHeater = recommendedModels
                .filter((m) => !!m)
                .sort((a, b) => (a.capacity < b.capacity ? -1 : 1))
                .find((m) => {
                    return m.capacity >= minimumOutput;
                });

            quantity = 1;
        } else {
            quantity = 0;
        }
    }

    return {
        recommendedQuantity: Math.round(quantity),
        minimumOutput,
        recommendedModels: recommendedModels,
        recommendedHeater,
    };
}

export type HeatPumpModelResultType = ReturnType<typeof computeRecommendedHeatPumpModel>;
