import * as React from 'react';
import * as _ from 'lodash';
import { Checkbox, Form } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { NodeType } from '@poolware/api';
import * as Yup from 'yup';

import moment from 'moment';
import {
    DatePickerMode,
    FormikDatePickerButtonField,
    FormikFormFieldLabel,
    FormikRadioField,
    FormikSelectField,
} from '@poolware/components';
import tw, { styled } from 'twin.macro';

const patternTypes = [
    { text: 'Daily', value: NodeType.PatternTypeEnum.Daily },
    { text: 'Weekly', value: NodeType.PatternTypeEnum.Weekly },
    // { text: 'Relative Monthly', value: NodeType.PatternTypeEnum.RelativeMonthly },
    { text: 'Absolute Monthly', value: NodeType.PatternTypeEnum.AbsoluteMonthly },
];

const intervalMaxRepeat = {
    [NodeType.PatternTypeEnum.Daily]: 60,
    [NodeType.PatternTypeEnum.Weekly]: 52,
    [NodeType.PatternTypeEnum.RelativeMonthly]: 12,
    [NodeType.PatternTypeEnum.AbsoluteMonthly]: 12,
};

const intervalLabel = {
    [NodeType.PatternTypeEnum.Weekly]: 'Weeks',
    [NodeType.PatternTypeEnum.Daily]: 'Days',
    [NodeType.PatternTypeEnum.RelativeMonthly]: 'Months',
    [NodeType.PatternTypeEnum.AbsoluteMonthly]: 'Months',
};

const rangeTypesAll = [
    { text: 'No End', value: NodeType.RecurrenceTypeEnum.NoEnd },
    { text: 'End Date', value: NodeType.RecurrenceTypeEnum.EndDate },
    { text: 'Numbered', value: NodeType.RecurrenceTypeEnum.Numbered },
];

const rangeTypes_ExcludeNoEnd = [
    { text: 'End Date', value: NodeType.RecurrenceTypeEnum.EndDate },
    { text: 'Numbered', value: NodeType.RecurrenceTypeEnum.Numbered },
];

const daysOfWeekOptions = [
    { text: 'Mon', value: NodeType.WeekdayEnum.Monday },
    { text: 'Tue', value: NodeType.WeekdayEnum.Tuesday },
    { text: 'Wed', value: NodeType.WeekdayEnum.Wednesday },
    { text: 'Thu', value: NodeType.WeekdayEnum.Thursday },
    { text: 'Fri', value: NodeType.WeekdayEnum.Friday },
    { text: 'Sat', value: NodeType.WeekdayEnum.Saturday },
    { text: 'Sun', value: NodeType.WeekdayEnum.Sunday },
];

const validationDayOfMonth = Yup.number()
    .when('type', {
        is: (type) => type === NodeType.PatternTypeEnum.AbsoluteMonthly,
        then: Yup.number().required('Required').nullable(),
        otherwise: Yup.number().notRequired().nullable(),
    })
    .nullable();

const validationDaysOfWeek = Yup.array()
    .when('type', {
        is: (type) => type === NodeType.PatternTypeEnum.Weekly,
        then: Yup.array().min(1, 'Required').required('Required').nullable(),
        otherwise: Yup.array().notRequired().nullable(),
    })
    .nullable();

const validationEndDate = Yup.date()
    .when('type', {
        is: (type) => type === NodeType.RecurrenceTypeEnum.EndDate,
        then: Yup.date().required('Required').nullable(),
        otherwise: Yup.date().notRequired().nullable(),
    })
    .nullable();

const validationNumberOfOccurrences = Yup.number()
    .when('type', {
        is: (type) => type === NodeType.RecurrenceTypeEnum.Numbered,
        then: Yup.number().required('Required').nullable(),
        otherwise: Yup.number().notRequired().nullable(),
    })
    .nullable();

export const validationSchemaRecurrencePattern = Yup.object()
    .nullable()
    .shape({
        pattern: Yup.object()
            .shape({
                daysOfWeek: validationDaysOfWeek,
                dayOfMonth: validationDayOfMonth,
                interval: Yup.number().required('Required').nullable(),
                type: Yup.string().required('Required').nullable(),
            })
            .required('Required'),
        range: Yup.object()
            .shape({
                endDate: validationEndDate,
                numberOfOccurrences: validationNumberOfOccurrences,
                type: Yup.string().required('Required').nullable(),
            })
            .required('Required'),
    });

const FormSection = styled.div(() => [
    tw`px-4 py-2 mb-4 bg-gray-100 shadow rounded flex flex-col space-y-0 border border-gray-200 border-solid `,
]);

interface RecurrencePatternProps extends FieldProps {
    defaultValue?: NodeType.RecurrenceRange;
    disabled?: boolean;
    compact?: boolean;
}

class RecurrencePattern extends React.Component<RecurrencePatternProps> {
    getCompoundName = (fieldName: string) => {
        const { field } = this.props;
        return `${field.name}.${fieldName}`;
    };
    getPattern = (props?: RecurrencePatternProps): NodeType.RecurrencePattern => {
        const inputProps = props || this.props;
        const {
            field: { value = {} },
        } = inputProps;
        const { defaultValue = {} } = inputProps;
        return { ...defaultValue, ...value };
    };

    getIntervalOptions = (type: NodeType.PatternTypeEnum) => {
        const maxRepeat = intervalMaxRepeat[type] || 31;
        return Array.from({ length: maxRepeat }, (v, k) => k + 1).map((v) => {
            return { text: `${v}`, value: v };
        });
    };

    handleChange = (fieldName, value) => {
        const { form } = this.props;
        form.setFieldValue(this.getCompoundName(fieldName), value);
    };

    renderDaysOfWeek() {
        const pattern = this.getPattern();
        const daysOfWeek = pattern?.daysOfWeek || [];

        return (
            <Form.Field>
                <FormikFormFieldLabel
                    label={'Days of Week'}
                    required={true}
                    name={this.getCompoundName('daysOfWeek')}
                />

                <div tw={'flex flex-row flex-wrap gap-x-6 gap-y-0'}>
                    {daysOfWeekOptions.map((option) => {
                        const isChecked = daysOfWeek?.includes(option.value);
                        return (
                            <Form.Field
                                key={option.value}
                                control={Checkbox}
                                label={option.text}
                                disabled={this.props.disabled}
                                value={option.value}
                                checked={isChecked}
                                onChange={(e, res) => {
                                    // console.log(res);
                                    const newValues = res.checked
                                        ? _.union(daysOfWeek, [res.value])
                                        : _.without(daysOfWeek, res.value);
                                    this.handleChange('daysOfWeek', newValues);
                                }}
                            />
                        );
                    })}
                </div>
            </Form.Field>
        );
    }

    renderInterval() {
        const pattern = this.getPattern();
        const type = pattern.type;
        const intervalOptions = this.getIntervalOptions(type);

        const label = intervalLabel[type];

        return (
            <Form.Group inline>
                <FormikSelectField
                    required={true}
                    name={this.getCompoundName('interval')}
                    label="Repeat every"
                    options={intervalOptions}
                    disabled={this.props.disabled}
                />
                <InlineLabel disabled={this.props.disabled}>{label}</InlineLabel>
            </Form.Group>
        );
    }

    renderWeeklyIndex() {
        return 'Monthly index';
    }

    dayOfMonthsOptions = Array.from({ length: 31 }, (v, k) => k + 1).map((v) => {
        return { text: `${v}`, value: v };
    });

    renderDayOfMonth() {
        return (
            <FormikSelectField
                inline
                required={true}
                label={'Day of Month'}
                options={this.dayOfMonthsOptions}
                disabled={this.props.disabled}
                name={this.getCompoundName('dayOfMonth')}
            />
        );
    }

    renderType() {
        return (
            <FormikRadioField
                inline={!this.props.compact}
                required={true}
                disabled={this.props.disabled}
                name={this.getCompoundName('type')}
                label={'Repeat'}
                options={patternTypes}
            />
        );
    }

    render() {
        const pattern = this.getPattern();
        const showDays =
            pattern.type === NodeType.PatternTypeEnum.RelativeMonthly ||
            pattern.type === NodeType.PatternTypeEnum.Weekly;
        const showWeeklyIndex = pattern.type === NodeType.PatternTypeEnum.RelativeMonthly;
        const showDayOfMonths = pattern.type === NodeType.PatternTypeEnum.AbsoluteMonthly;
        return (
            <FormSection>
                {this.renderType()}
                {this.renderInterval()}
                {showDays && this.renderDaysOfWeek()}
                {showWeeklyIndex && this.renderWeeklyIndex()}
                {showDayOfMonths && this.renderDayOfMonth()}
            </FormSection>
        );
    }
}

interface RecurrenceRangeProps extends FieldProps {
    defaultValue?: NodeType.RecurrenceRange;
    disabled?: boolean;
}

const InlineLabel = ({ children, disabled }) => {
    let cn = 'inline-label';
    cn = disabled ? `${cn} disabled` : cn;
    return <span className={cn}>{children}</span>;
};

const tenYearsFromNow = moment().add(10, 'y').endOf('y').toDate();

class RecurrenceRange extends React.Component<RecurrenceRangeProps> {
    getCompoundName = (fieldName: string) => {
        const { field } = this.props;
        return `${field.name}.${fieldName}`;
    };

    getRange = (props?: RecurrenceRangeProps): NodeType.RecurrenceRange => {
        const inputProps = props || this.props;
        const {
            field: { value = {} },
        } = inputProps;
        const { defaultValue = {} } = inputProps;
        return { ...defaultValue, ...value };
    };

    handleChange = (fieldName, value) => {
        const { form, field } = this.props;
        form.setFieldValue(`${field.name}.${fieldName}`, value);
    };

    getNumberOptions = () => {
        return Array.from({ length: 100 }, (v, k) => k + 1).map((v) => {
            return { text: `${v}`, value: v };
        });
    };

    renderType() {
        const noEndAllowed = true;
        const rangeTypes = noEndAllowed ? rangeTypesAll : rangeTypes_ExcludeNoEnd;
        return (
            <FormikRadioField
                inline={true}
                required={true}
                disabled={this.props.disabled}
                name={this.getCompoundName('type')}
                label={'Ends'}
                options={rangeTypes}
            />
        );
    }

    renderNumbered() {
        const options = this.getNumberOptions();
        return (
            <Form.Group inline>
                <FormikSelectField
                    inline
                    label="After"
                    required={true}
                    options={options}
                    disabled={this.props.disabled}
                    name={this.getCompoundName('numberOfOccurrences')}
                />
                <InlineLabel disabled={this.props.disabled}>Occurrences</InlineLabel>
            </Form.Group>
        );
    }

    renderEndDate() {
        return (
            <FormikDatePickerButtonField
                label={'Date'}
                name={this.getCompoundName('endDate')}
                fluid={false}
                required={true}
                disabled={this.props.disabled}
                mode={DatePickerMode.DATE}
                minDate={new Date()}
                maxDate={tenYearsFromNow}
                monthsShown={2}
            />
        );
    }

    renderNoEnd() {
        return <div tw={'mb-2'}>No end date</div>;
    }

    render() {
        const range = this.getRange();

        const showNumbered = range.type === NodeType.RecurrenceTypeEnum.Numbered;
        const showEndDate = range.type === NodeType.RecurrenceTypeEnum.EndDate;
        const showNoEnd = range.type === NodeType.RecurrenceTypeEnum.NoEnd;
        return (
            <FormSection>
                {this.renderType()}
                {showNoEnd && this.renderNoEnd()}
                {showEndDate && this.renderEndDate()}
                {showNumbered && this.renderNumbered()}
            </FormSection>
        );
    }
}

class Recurrence extends React.Component<{ disabled?: boolean; compact?: boolean }> {
    render() {
        const { disabled, compact } = this.props;
        return (
            <React.Fragment>
                <Field
                    name={'recurrence.pattern'}
                    disabled={disabled}
                    component={RecurrencePattern}
                    compact={compact}
                />
                <Field name={'recurrence.range'} disabled={disabled} component={RecurrenceRange} />
            </React.Fragment>
        );
    }
}

export default Recurrence;
