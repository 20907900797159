import moment from 'moment';
import { convertToConfigValue, NodeType } from '@poolware/api';
import invariant from 'invariant';
import * as _ from 'lodash';

export const getClientTimeZone = () => {
    try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
        console.error(e);
    }
    return 'Australia/Sydney';
};

export const sanitizeAppointmentStartDate = (date) => {
    // IMPORTANT: round to minute. There is an issues with recurrence when appointment starts time has seconds.
    return moment(date).startOf('minute').toISOString(true);
};

export const sanitizeRecurrence = (recurrence: NodeType.Recurrence): NodeType.RecurrenceNodeInitInput | null => {
    if (!recurrence) return null;

    invariant(recurrence.pattern && recurrence.range, 'Pattern and range must be provided');
    if (!recurrence.pattern) return null;
    if (!recurrence.range) return null;

    let sanitisedPattern: NodeType.RecurrencePatternNodeInitInput;
    switch (recurrence.pattern.type) {
        case NodeType.PatternTypeEnum.Daily:
            invariant(recurrence.pattern.interval > 0, 'interval must be provided and be greater than 0');
            sanitisedPattern = {
                type: NodeType.PatternTypeEnum.Daily,
                interval: recurrence.pattern.interval,
            };
            break;

        case NodeType.PatternTypeEnum.AbsoluteMonthly:
            invariant(recurrence.pattern.interval > 0, 'interval must be provided and be greater than 0');
            invariant(recurrence.pattern.dayOfMonth, 'dayOfMonth must be provided');
            sanitisedPattern = {
                type: NodeType.PatternTypeEnum.AbsoluteMonthly,
                interval: recurrence.pattern.interval,
                dayOfMonth: recurrence.pattern.dayOfMonth,
            };
            break;

        case NodeType.PatternTypeEnum.RelativeMonthly:
            invariant(recurrence.pattern.index, 'index must be provided');
            invariant(recurrence.pattern.daysOfWeek, 'daysOfWeek must be provided and be greater than 0');
            sanitisedPattern = {
                type: NodeType.PatternTypeEnum.RelativeMonthly,
                interval: recurrence.pattern.interval,
                index: recurrence.pattern.index,
                daysOfWeek: recurrence.pattern.daysOfWeek,
            };
            break;

        case NodeType.PatternTypeEnum.Weekly:
            invariant(recurrence.pattern.interval > 0, 'interval must be provided and be greater than 0');
            invariant(recurrence.pattern.daysOfWeek, 'daysOfWeek must be provided and be greater than 0');
            sanitisedPattern = {
                type: NodeType.PatternTypeEnum.Weekly,
                interval: recurrence.pattern.interval,
                daysOfWeek: recurrence.pattern.daysOfWeek,
            };
            break;

        default:
            invariant(false, 'unknown pattern.type, should not be here');
            return null;
    }

    let sanitisedRange: NodeType.RecurrenceRangeNodeInitInput;

    const timeZone = recurrence.range.timeZone || getClientTimeZone();
    switch (recurrence.range.type) {
        case NodeType.RecurrenceTypeEnum.Numbered:
            invariant(recurrence.range.numberOfOccurrences, 'range.numberOfOccurrences must be provided');
            sanitisedRange = {
                type: NodeType.RecurrenceTypeEnum.Numbered,
                numberOfOccurrences: recurrence.range.numberOfOccurrences,
                timeZone: timeZone,
            };
            break;
        case NodeType.RecurrenceTypeEnum.EndDate:
            invariant(recurrence.range.endDate, 'range.numberOfOccurrences must be provided');
            sanitisedRange = {
                type: NodeType.RecurrenceTypeEnum.EndDate,
                endDate: sanitizeAppointmentStartDate(recurrence.range.endDate),
                timeZone: timeZone,
            };
            break;
        case NodeType.RecurrenceTypeEnum.NoEnd:
            sanitisedRange = {
                type: NodeType.RecurrenceTypeEnum.NoEnd,
                timeZone: timeZone,
            };
            break;
        default:
            invariant(false, 'unknown range.type, should not be here');
            return null;
    }

    return {
        pattern: sanitisedPattern,
        range: sanitisedRange,
    };
};

export const sanitizeAppointmentBaseData = (input: {
    address?: NodeType.NodeOrId<NodeType.Address>;
    color?: String;
    customer?: NodeType.NodeOrId<NodeType.Customer>;
    group?: NodeType.NodeOrId<NodeType.AppointmentGroup>;
    note?: string;
    pool?: NodeType.NodeOrId<NodeType.Pool>;
    staff?: NodeType.NodeOrId<NodeType.Staff>;
}) => {
    const conf = {
        address: convertToConfigValue({ values: input, key: 'address', isSourceNode: true }),
        color: convertToConfigValue({ values: input, key: 'color' }),
        customer: convertToConfigValue({ values: input, key: 'customer', isSourceNode: true }),
        group: convertToConfigValue({ values: input, key: 'group', isSourceNode: true }),
        note: convertToConfigValue({ values: input, key: 'note' }),
        pool: convertToConfigValue({ values: input, key: 'pool', isSourceNode: true }),
        staff: convertToConfigValue({ values: input, key: 'staff', isSourceNode: true }),
    };

    if (conf.note !== undefined && conf.note !== null) {
        conf.note = _.trim(conf.note);
    }
    return conf;
};
