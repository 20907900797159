import { AppLayoutMode, defaultAppLayoutValue, ModulesAccess, useViewer } from '@poolware/app-shell';
import { useCalendarActions } from '../../redux';
import React, { useContext, useEffect, useMemo, useState } from 'react';

export const isDispatcherToolsAllowed = (modulesAccess: ModulesAccess) => {
    return (
        modulesAccess.FieldServices?.calendarDockMode ||
        modulesAccess.FieldServices?.calendarWorkOrderDND ||
        modulesAccess.FieldServices?.calendarStreetsSummary
    );
};

export type CalendarLayoutStateType = {
    isDockOpen: boolean;
    isFilterBarOpen: boolean;
    canCloseDock: boolean;
    canOpenDock: boolean;
    setDockState: (boolean) => void;
    setFilterBarState: (boolean) => void;
    useSlideInDockForPreview: boolean;
    layoutColumns: 1 | 2 | 3;
    appLayoutMode: AppLayoutMode;
};

const defaultValue: CalendarLayoutStateType = {
    layoutColumns: 3,
    isDockOpen: false,
    canCloseDock: true,
    canOpenDock: false,
    isFilterBarOpen: true,
    setDockState: () => {},
    setFilterBarState: () => {},
    useSlideInDockForPreview: false,
    appLayoutMode: defaultAppLayoutValue,
};

const CalendarLayoutStateContext = React.createContext<CalendarLayoutStateType>(defaultValue);

export const CalendarLayoutStateProvider: React.FC = (props) => {
    const { modulesAccess, appLayoutMode } = useViewer();
    const { CalendarState, CalendarAction } = useCalendarActions();
    const [showSidebarMobile, setShowSidebarMobile] = useState(false);

    const layoutColumns = appLayoutMode.gteDesktopAny ? 3 : appLayoutMode.gteTablet ? 2 : 1;

    useEffect(() => {
        if (layoutColumns > 2) {
            setShowSidebarMobile(false);
        }
    }, [layoutColumns]);

    const ctx = useMemo(() => {
        const isFilterBarOpen = layoutColumns > 2 ? CalendarState.showLeftSidebar : showSidebarMobile;
        const setFilterBarState = (open: boolean) => {
            if (layoutColumns > 2) {
                CalendarAction.setSidebarState(open);
            } else {
                setShowSidebarMobile(open);
            }
        };

        // RIGHT DOCK SIDEBAR

        const canOpenDock = isDispatcherToolsAllowed(modulesAccess) && layoutColumns > 1;
        const canCloseDock = modulesAccess.FieldServices?.calendarDockModeScreenSizeXL
            ? !appLayoutMode.gteDesktopXL
            : !appLayoutMode.gteDesktopXXL;
        const isDockOpen = canOpenDock && (CalendarState.showRightSidebar || !canCloseDock);
        const setDockState = (open: boolean) => {
            CalendarAction.setRightSidebarState(open);
        };

        const useSlideInDockForPreview = modulesAccess.FieldServices?.calendarApptPreviewInDockMode; // Math.random() < 0.8;

        const ctx: CalendarLayoutStateType = {
            isDockOpen,
            isFilterBarOpen,
            canCloseDock,
            canOpenDock,
            setDockState,
            setFilterBarState,
            useSlideInDockForPreview,
            appLayoutMode,
            layoutColumns,
        };
        return ctx;
    }, [
        modulesAccess,
        layoutColumns,
        showSidebarMobile,
        CalendarState.showLeftSidebar,
        CalendarState.showRightSidebar,
        appLayoutMode,
    ]);

    return <CalendarLayoutStateContext.Provider value={ctx} {...props} />;
};
export const useCalendarLayoutState = () => {
    const ctx = useContext(CalendarLayoutStateContext);
    // if (!ctx) {
    //     throw new Error('Must be used within of CalendarLayoutStateProvider context');
    // }
    return ctx;
};
