import * as React from 'react';
import { useMemo } from 'react';
import { Icon } from 'semantic-ui-react';
import { ConnectionTableDef, DefaultConnectionTable, Display, TruncatedDiv } from '@poolware/components';
import { ModuleColorNames, ModuleIconNames, UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import { NodeType } from '@poolware/api';
import { useViewer, useViewerContext } from '@poolware/app-shell';
import * as _ from 'lodash';
import {
    QueryServiceJobConnectionProps,
    useQueryServiceJobConnection,
} from '../../queries/use-query-service-job-connection';
import { QLServiceJobRecurList } from './query-service-job-recur-list';
import { IconServiceJobRecurStatus } from '../ServiceJobRecurView/utils';
import { useRecurServiceJobListActions } from '../../redux/reducer-recur-service-job-list';
import { SearchByAssignmentState } from '../../queries/use-query-work-orders-connection';

export interface PageTableProps {
    onView: any;
}

const useTableDef = (isAdmin: boolean) => {
    return useMemo(() => {
        const tableDef: ConnectionTableDef<NodeType.ServiceJob> = [
            {
                header: 'ID',
                sortKey: 'serviceJobNumber',
                width: 1,
                cell: (serviceJob) => {
                    return (
                        <div>
                            <IconServiceJobRecurStatus serviceJob={serviceJob} />
                            {serviceJob.serviceJobNumber}
                        </div>
                    );
                },
            },
            {
                header: 'Service',
                cell: (serviceJob) => {
                    const groupTitle = serviceJob?.group?.title;
                    const title = _.truncate(serviceJob.title || '--', { length: 60 });
                    return (
                        <div>
                            <Icon name={ModuleIconNames.RecurringService} />
                            {groupTitle && <span tw={'font-bold text-purple-600'}>{groupTitle} - </span>} {title}
                        </div>
                    );
                },
            },
            {
                header: 'Customer',
                cell: (value) => {
                    const { customer, address, pool } = value;
                    return (
                        <TruncatedDiv maxWidth={400}>
                            {customer && (
                                <div>
                                    <Icon name={'user'} />
                                    <code style={{ color: '#2185d0' }}>
                                        <b>{customer.crn}</b>
                                    </code>{' '}
                                    - <Display.Entity value={customer} />
                                </div>
                            )}
                            {customer?.companyName && (
                                <div>
                                    <Icon name={'building'} />
                                    {customer.companyName}
                                </div>
                            )}
                            {address && (
                                <div>
                                    <Icon name={'map marker alternate'} />
                                    <Display.Address value={address} />
                                </div>
                            )}
                            {pool && (
                                <div>
                                    <Icon name={'life ring'} />
                                    Pool - {pool.volume}L {pool.type?.name && `, ${pool.type?.name}`}
                                </div>
                            )}
                        </TruncatedDiv>
                    );
                },
            },
            {
                header: 'Opened',
                sortKey: 'createdAt',
                cellProps: { verticalAlign: 'top' },
                width: 2,
                cell: (v) => <Display.Date format={'ll'} value={v.createdAt} />,
            },
            {
                header: 'Last Appt.',
                cellProps: { verticalAlign: 'top' },
                width: 2,
                cell: (v) => {
                    if (!v.lastAppointmentDate) {
                        return <>Not set</>;
                    }
                    return (
                        <span>
                            <Display.Date format={'ll'} value={v.lastAppointmentDate} defaultString={<i>Not set</i>} />
                        </span>
                    );
                },
            },
        ];
        if (isAdmin) {
            tableDef.push({
                header: 'Franchise',
                cell: (sj) => {
                    return sj?.entity?.franchise?.name;
                },
            });
        }
        return tableDef;
    }, [isAdmin]);
};

export const ServiceJobsTable: React.FC<PageTableProps> = ({ onView }) => {
    const { State, Actions } = useRecurServiceJobListActions();
    const { modulesAccess, viewer } = useViewer();

    const singleStaffMode = modulesAccess.FieldServices?.calendarSingleStaffMode;
    const { staffIds } = State.filters;

    const queryConf: QueryServiceJobConnectionProps = {
        /// Redux controlled page state
        pageIndex: State.page.index,
        pageSize: State.page.size,
        sortKey: State.page.sortKey,
        sortDirection: State.page.sortDirection,
        setPageIndex: Actions.setPageIndex,
        setPageSize: Actions.setPageSize,
        setSortDirection: Actions.setSortDirection,
        setSortKey: Actions.setSortKey,
        ///
        isRecurring: true,
        recurJobStatus: State.filters?.status?.[0],
        customerCRN: State.filters.customerCRN,
        workOrderNumber: State.filters.workOrderNumber,
        serviceJobNumber: State.filters.serviceJobNumber,
        serviceJobGroup: State.filters.serviceGroupId,
        usedTemplate: State.filters.serviceJobTemplateId,
        debugName: `useQueryServiceJobConnection`,
        // stage: State.filters.serviceStageId;
        stageType: State.filters.serviceStageType,
    };

    if (singleStaffMode) {
        queryConf.assignedToStaff = viewer?.me?.staff?.id;
    } else if (staffIds?.length > 0) {
        if (State.filters?.staffIds.includes(UNASSIGNED_APPOINTMENT_STAFF_ID)) {
            queryConf.assignmentState = SearchByAssignmentState.UNASSIGNED;
        } else {
            queryConf.assignedToStaff = staffIds[0];
        }
    }

    const { connectionState, connectionData } = useQueryServiceJobConnection({
        query: QLServiceJobRecurList,
        ...queryConf,
    });

    const { isAdmin } = useViewerContext();

    const tableDef = useTableDef(isAdmin);

    return (
        <DefaultConnectionTable
            // debug={true}
            tableProps={{
                color: ModuleColorNames.RecurringService,
            }}
            onRowClick={onView}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
