import * as React from 'react';
import { useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    DisplayEntity,
    MenuBar,
    Panel,
    SuspenseSkeletonLoader,
    VStack,
} from '@poolware/components';
import { useQuerySentEmailsConnection } from '../../../FranchiseAdmin/SentEmails/use-query-sent-emails-connection';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useViewer } from '@poolware/app-shell';
import { Modal } from 'semantic-ui-react';
import { TemplateRendererFrame } from '../../../FranchiseAdmin/components/TemplateRendererFrame';
import { useQueryEmailMessage } from '../../../FranchiseAdmin/SentEmails/use-query-email-message';
import _truncate from 'lodash/truncate';

export interface EmailLogViewerProps {
    testReportId: NodeType.ID;
}

export const EmailMessageView: React.FC<{ emailId: NodeType.ID; onClose: () => any }> = ({ emailId, onClose }) => {
    const id = emailId;
    const { node: email, error, loading } = useQueryEmailMessage(id, { skip: !id });

    return (
        <SuspenseSkeletonLoader error={error} loading={loading}>
            <VStack>
                <MenuBar>
                    <MenuBar.Section>
                        <MenuBar.HeaderItem icon={'mail'}>Email Message</MenuBar.HeaderItem>
                    </MenuBar.Section>
                    <MenuBar.Section position={'right'}>
                        <MenuBar.Item onClick={onClose} icon={'cancel'} title={'Close'} />
                    </MenuBar.Section>
                </MenuBar>

                <Panel>
                    <Panel.Body>
                        <Panel.Item label={'Subject'} content={email?.subject} />
                        <Panel.Item label={'Type'} content={email?.type?.name} />
                        <Panel.ItemDate label={'Created'} content={email?.createdAt} />
                        <Panel.Item label={'To'} content={email?.recipientEmail} />
                        <Panel.ItemEntity label={'Send By'} content={email?.submittedBy} />

                        <Panel.SectionHeader>Message</Panel.SectionHeader>

                        <div style={{ backgroundColor: '#fefefe', padding: '1rem' }}>
                            <TemplateRendererFrame style={{ minHeight: '600px' }} content={email?.messageBody} />
                        </div>
                    </Panel.Body>
                </Panel>
            </VStack>
        </SuspenseSkeletonLoader>
    );
};

export const EmailLogViewer: React.FC<EmailLogViewerProps> = ({ testReportId }) => {
    const [email, setEmail] = useState<NodeType.EmailMessage>(null);
    const { AppNavigator } = useAppNavigator();
    const { isAdmin } = useViewer();
    const id = testReportId || AppNavigator.params.testId;
    const search: NodeType.EmailMessageViewerSentEmailsSearch = {
        report: {
            id: id,
        },
    };

    const { connectionData, connectionState } = useQuerySentEmailsConnection({ search: search, skip: !id });

    const tableDef: ConnectionTableDef<NodeType.EmailMessage> = [
        {
            header: 'Date',
            sortKey: 'createdAt',
            cellProps: { width: '3' },
            cell: (r) => <Display.Date format={'lll'} value={r.createdAt} />,
        },
        {
            header: 'type',
            cell: (r) => r.type?.name || '--',
        },
        {
            header: 'subject',
            cell: (r) => _truncate(r.subject, { length: 60 }),
        },
        {
            header: 'Recipient',
            cell: (r) => r.recipientEmail,
        },
        {
            header: 'Submitted By',
            cell: (r) => {
                return (
                    <span>
                        <DisplayEntity value={r?.submittedBy?.user} />
                    </span>
                );
            },
        },
        isAdmin && {
            header: 'Franchise',
            cell: (r) => {
                return <span>{r?.submittedBy?.franchise?.name}</span>;
            },
        },
    ];

    const onView = (email: NodeType.EmailMessage) => {
        setEmail(email);
    };

    return (
        <Panel>
            <Panel.Header>Email Log</Panel.Header>
            <Panel.Body>
                <DefaultConnectionTable
                    debug={false}
                    onRowClick={onView}
                    // tableRowDef={tableRowProps}
                    tableDef={tableDef}
                    connectionData={connectionData}
                    connectionState={connectionState}
                />
            </Panel.Body>
            <Modal centered={false} open={!!email} onClose={() => setEmail(null)}>
                <EmailMessageView onClose={() => setEmail(null)} emailId={email?.id} />
            </Modal>
        </Panel>
    );
};
