import * as React from 'react';
import { useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    FormikCheckboxField,
    FormikDefaultForm,
    MenuBarHeaderItem,
    MenuBarSection,
    Panel,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { NodeType, useMutationFranchise } from '@poolware/api';
import { useQueryFranchisesConnection } from './use-query-franchises-connection';
import { Modal, Segment } from 'semantic-ui-react';

const FranchisePropEditorModal: React.FC<{ onClose; franchise: NodeType.Franchise; refetchQuery }> = ({
    onClose,
    franchise,
    refetchQuery,
}) => {
    const { update } = useMutationFranchise({ refetchQueries: [refetchQuery] });

    if (!franchise) {
        return null;
    }

    const initialValues = {
        store: franchise.types?.includes(NodeType.FranchiseType.Store),
        warranter: franchise.types?.includes(NodeType.FranchiseType.Warranter),
    };

    const onSubmit = async (values: typeof initialValues) => {
        const newType = [];
        if (values.warranter) newType.push(NodeType.FranchiseType.Warranter);
        if (values.store) newType.push(NodeType.FranchiseType.Store);

        try {
            await update({
                id: franchise.id,
                types: newType,
            });
            onClose();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Modal open={Boolean(franchise)} onClose={onClose} centered={false}>
            <FormikDefaultForm
                header={'Franchise'}
                activateOnDirty={true}
                submitButton={{ content: 'Update' }}
                initialValues={initialValues}
                onCancel={onClose}
                onSubmit={onSubmit}
            >
                <Panel.Item label={'Franchise'} content={franchise.name} />
                <Panel.Divider />
                <Panel.Item label={'Type'}>
                    <FormikCheckboxField name={'store'} label={'Store'} />
                    <FormikCheckboxField name={'warranter'} label={'Warranter'} />
                    <small tw={'pl-2'}>At least one type must be selected</small>
                </Panel.Item>
            </FormikDefaultForm>
        </Modal>
    );
};

export interface WarrantersTypeProps {}

export const WarrantersType: React.FC<WarrantersTypeProps> = ({}) => {
    const [franchise, selectFranchise] = useState<NodeType.Franchise>(undefined);
    const { connectionData, connectionState, refetchQuery } = useQueryFranchisesConnection();

    const tableDef: ConnectionTableDef<NodeType.Franchise> = [
        {
            header: 'Franchise',
            cell: (r) => <>{r?.name}</>,
        },
        {
            header: 'Type',
            cell: (r) => <>{r.types?.join(', ')}</>,
        },
    ];

    function onRowClick(franchise: NodeType.Franchise) {
        selectFranchise(franchise);
    }

    return (
        <>
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem icon={'building'}>Franchises</MenuBarHeaderItem>
                    </MenuBarSection>
                </StickyMenuBar>

                <DefaultConnectionTable
                    onRowClick={onRowClick}
                    tableDef={tableDef}
                    connectionData={connectionData}
                    connectionState={connectionState}
                />

                <Segment tertiary={true} size={'small'}>
                    Only 'Warranter' type franchises can be assigned as the destination office for warranty requests
                </Segment>
            </VStack>
            <FranchisePropEditorModal
                onClose={() => selectFranchise(undefined)}
                franchise={franchise}
                refetchQuery={refetchQuery}
            />
        </>
    );
};
