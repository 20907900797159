import * as React from 'react';
import { fromEdges, NodeType, TraitValueType } from '@poolware/api';
import { Panel } from '@poolware/components';
import { sanitizeValues, TraitDeclarationFieldFormConfig } from '../../types';
import { FormBuilder } from '../../../components/FormBuilder';
import * as _ from 'lodash';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

class EditTraitDeclarationFieldForm extends React.Component<PageControlProps> {
    onSubmitDefault = async (values) => {
        const { traitDeclarationId, config, traitDeclarationField } = this.props;
        values = sanitizeValues(values, config);
        await this.props.ProductCatalogMutator.updateTraitDeclaration_updateField(config.mutationFiledName)(
            traitDeclarationId,
            traitDeclarationField.id,
            values
        );
    };

    onSubmitSelection = async (values) => {
        const { traitDeclarationField, config } = this.props;
        // debugger;

        const initialOptionIds: string[] = fromEdges<NodeType.ProductDeclarationSelectionChoice>(
            _.get(traitDeclarationField, 'options')
        ).map((d) => d.id);

        const valueOptionWithIds: string[] = _.get(values, 'options')
            .filter((d) => !!d.id)
            .map((d) => d.id);

        const toDelete = _.difference(initialOptionIds, valueOptionWithIds);

        // deep copy options and assign new priority based on the order in the array.
        const options = _.cloneDeep(_.get(values, 'options', []))
            .reverse()
            .map((o, index) => ({ ...o, priority: index }));

        const toUpdate = options.filter((d) => d.id);
        const toCreate = options.filter((d) => !d.id);

        const optionsDiff = {
            delete: toDelete,
            update: toUpdate.map((i) => ({ id: i.id, name: i.name, priority: i.priority, description: i.description })),
            create: toCreate.map((i) => ({ name: i.name, priority: i.priority, description: i.description })),
        };

        const { options: dropped, ...rest } = values;

        // console.group('Selection update');
        // console.log('values.options', values.options);
        // console.log('to create', toCreate);
        // console.log('to delete', toDelete);
        // console.log('to update', toUpdate);
        // console.log('diff', optionsUpdate);
        // console.groupEnd();

        const v = config.fields
            .map((fc) => fc.key)
            .reduce((acc, key) => {
                acc[key] = rest[key];
                return acc;
            }, {});

        await this.props.ProductCatalogMutator.updateProductDeclarationSelectionField(
            traitDeclarationField.id,
            v,
            optionsDiff
        );
    };

    onSubmit = async (values, actions) => {
        try {
            // console.log(values);
            const { config } = this.props;
            if (config.type === TraitValueType.Selection) {
                await this.onSubmitSelection(values);
            } else {
                await this.onSubmitDefault(values);
            }
            this.props.onSubmit();
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e.message });
        }
    };

    onCancel = () => {
        this.props.onCancel();
    };

    render() {
        const { config, traitDeclarationField } = this.props;
        const initialValue = config.mapToFormValue(traitDeclarationField);
        return (
            <Panel>
                <Panel.Header>Edit Field - {config.name} type</Panel.Header>
                <Panel.Body>
                    <FormBuilder
                        config={config}
                        initialValues={initialValue}
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                    />
                    {/*<DebugPanel value={{ config}} />*/}
                </Panel.Body>
            </Panel>
        );
    }
}

export interface ExternalProps {
    traitDeclarationId: NodeType.ID;
    traitDeclarationField: NodeType.Node;
    config: TraitDeclarationFieldFormConfig;
    onSubmit: () => any;
    onCancel: () => any;
}

export interface PageControlProps extends IProductCatalogMutators, ExternalProps {}

export default withProductCatalogMutators(queryNames)(EditTraitDeclarationFieldForm) as React.ComponentType<
    ExternalProps
>;
