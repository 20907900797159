import PropTypes from 'prop-types';
import React from 'react';

const ResourceHeader: React.FC<{
    label?: string | React.ReactNode;
    index: number;
    resource: any;
}> = ({ label, resource }) => {
    const icon = resource?.icon;
    return (
        <React.Fragment>
            {icon}
            {label}
        </React.Fragment>
    );
};

ResourceHeader.propTypes = {
    label: PropTypes.node,
    index: PropTypes.number,
    resource: PropTypes.object,
};

export default ResourceHeader;
