import * as React from 'react';
import { ConsumerProps, useCallback, useContext, useEffect, useState } from 'react';
import { WaterlinkAPIClient, WaterlinkAPIClientInterface } from '../api-client';
import { useWaterTestRunner } from './use-water-test-runner';
import { WaterlinkAPIClient_Mock } from '../api-client/mock-client/WaterlinkAPIClient_Mock';
import { isTouch, usePersistedState } from '@ez/tools';
import {
    defaultDeviceOptions,
    defaultDeviceStatus,
    DeviceOptionsType,
    DeviceStatusType,
    useWaterlinkService,
} from './use-waterlink-service';

const API_CLIENT_CONF_STORAGE_KEY = 'waterlink.service.conf';
const API_CLIENT_ENABLED_STORAGE_KEY = 'waterlink.service.enabled';

export interface ServerStatus {
    online: boolean;
    port: number;
    host: string;
    serverBaseUrl: string;
    isMock: boolean;
}

export type WaterlinkProviderContextType = {
    serviceEnabled: boolean;
    canStartTest: boolean;
    error: string | null;
    startService: () => Promise<any>;
    stopService: () => Promise<any>;
    client?: WaterlinkAPIClientInterface;
    server: ServerStatus;
    testRunner: ReturnType<typeof useWaterTestRunner>;
    device: DeviceStatusType;
    options: DeviceOptionsType;
    setClient: (conf: { isMock: boolean; port: number }) => any;
    refreshStatus: () => Promise<any>;
};

const defaultCtxValue: WaterlinkProviderContextType = {
    serviceEnabled: !isTouch, // Disable waterlink service on touch devices by default.
    canStartTest: false,
    error: null,
    client: null,
    testRunner: null,
    server: {
        online: false,
        port: 0,
        host: '',
        serverBaseUrl: '',
        isMock: false,
    },
    device: defaultDeviceStatus,
    options: defaultDeviceOptions,
    startService: () => {
        throw new Error('WaterlinkContext value is not initialized yet (startService).');
    },
    stopService: () => {
        throw new Error('WaterlinkContext value is not initialized yet (stopService)');
    },
    setClient: () => {
        throw new Error('WaterlinkContext value is not initialized yet (setClient)');
    },
    refreshStatus: async () => {
        throw new Error('WaterlinkContext value is not initialized yet (refreshStatus)');
    },
};

// Waterlink context
export const WaterlinkContext = React.createContext<WaterlinkProviderContextType>(defaultCtxValue);

// Waterlink context consumer
export const Waterlink: React.FC<ConsumerProps<WaterlinkProviderContextType>> = (props) => {
    return <WaterlinkContext.Consumer {...props} />;
};

// Hook
export const useWaterlink = () => {
    const waterlink = useContext(WaterlinkContext);
    useEffect(() => {
        if (waterlink.client && waterlink.serviceEnabled) {
            waterlink.refreshStatus();
        }
    }, []);

    return waterlink;
};

// HOC
// export interface WithWaterlinkProps {
//     waterlink: WaterlinkProviderContextType;
// }
// export const withWaterlink = () => (C: React.ElementType<WithWaterlinkProps>): React.FC => (props) => {
//     const waterlink = useWaterlink();
//     return <C waterlink={waterlink} {...props} />;
// };

export interface ViewerProviderCompProps {
    host?: string;
    port?: number;
}

const defaultConfig = {
    host: 'localhost',
    port: 61547,
    isMock: false,
};

type ApiConfig = typeof defaultConfig;

const createApiClient = (conf: ApiConfig) => {
    const APIClient = conf.isMock ? WaterlinkAPIClient_Mock : WaterlinkAPIClient;
    return new APIClient({ host: conf.host, port: conf.port });
};

export const WaterlinkProvider: React.FC<ViewerProviderCompProps> = (props) => {
    const [serviceEnabled, setServiceEnabled] = usePersistedState(
        API_CLIENT_ENABLED_STORAGE_KEY,
        defaultCtxValue.serviceEnabled
    );
    const [apiClientConf, setApiClientConf] = usePersistedState(API_CLIENT_CONF_STORAGE_KEY, defaultConfig);

    const [apiClient, setApiClient] = useState(createApiClient(apiClientConf));

    const waterlinkService = useWaterlinkService(apiClient, serviceEnabled);

    // const { serverOnline, device, refreshAll, deviceOptions } =
    //     waterlinkService;

    const waterTestRunner = useWaterTestRunner(waterlinkService);

    async function startService() {
        // console.log('Waterlink: start service');
        setServiceEnabled(true);
    }

    async function stopService() {
        // console.log('Waterlink: stop service');
        setServiceEnabled(false);
    }

    const setClient = useCallback((conf: { port: number; isMock: boolean }) => {
        const newConf = { ...apiClientConf, ...conf };
        const newClient = createApiClient(newConf);
        setApiClientConf(newConf);
        setApiClient(newClient);
    }, []);

    const isMock = apiClient instanceof WaterlinkAPIClient_Mock;

    const server = {
        isMock: isMock,
        online: waterlinkService.serverOnline,
        port: apiClient.port,
        host: apiClient.host,
        serverBaseUrl: apiClient.serverBaseUrl,
        APIVersion: waterlinkService.serviceAPIVersion,
        isSupportedAPIVersion: waterlinkService.isSupportedAPIVersion,
    };

    const canStartTest = Boolean(waterlinkService.device.canStartTest && waterlinkService.isSupportedAPIVersion);
    let errorMsg = null;
    if (!waterlinkService.isSupportedAPIVersion) {
        errorMsg = 'Not supported Waterlink Service API version. Please update Waterlink Connect.';
    }

    const contextValue: WaterlinkProviderContextType = {
        serviceEnabled: serviceEnabled,
        canStartTest: canStartTest,
        error: errorMsg,
        client: apiClient,
        testRunner: waterTestRunner,
        device: waterlinkService.device,
        server: server,
        options: waterlinkService.deviceOptions,
        setClient: setClient,
        refreshStatus: waterlinkService.refreshAll,
        stopService: stopService,
        startService: startService,
    };

    return <WaterlinkContext.Provider value={contextValue}>{props.children}</WaterlinkContext.Provider>;
};
