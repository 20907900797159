import { fromEdges, NodeType } from '@poolware/api';
import { Display } from '@poolware/components';

export type InputFieldsConfType = ReturnType<typeof prepareInputConf>;

export const prepareInputConf = (props: {
    name: string;
    chemicalTargets: NodeType.PoolChemicalTarget[];
    prevSampleSets?: NodeType.SampleSet[];
}) => {
    let { chemicalTargets, prevSampleSets } = props;

    let prevSamplesLUTs = [];
    if (prevSampleSets) {
        prevSamplesLUTs = prevSampleSets.map((prevSampleSet) => {
            let samples = fromEdges(prevSampleSet?.samples);
            const prevSamplesLUT = samples.reduce((acc, sample) => {
                let measurementTypeId = sample?.measurementType?.id;
                if (!measurementTypeId) {
                    console.error('sample does not have measurementType');
                } else {
                    acc[measurementTypeId] = Number(sample.value);
                }
                return acc;
            }, {});
            return prevSamplesLUT;
        });
    }

    return chemicalTargets.flatMap((chemTarget) => {
        let { measurementType, lowerThreshold, higherThreshold, target } = chemTarget;

        if (!measurementType) {
            console.error("chemical target  does not have 'measurementType'");
            console.error(chemTarget);
            return undefined;
        }
        const measurementTypeId = measurementType.id;

        const lowerThresholdValue = Display.numFormatter(lowerThreshold);
        const higherThresholdValue = Display.numFormatter(higherThreshold);
        const targetValue = Display.numFormatter(target);
        const measurementName = measurementType?.name || '';
        const measurementUnitName = measurementType?.unit?.name || '';
        const prevSampleValues = prevSamplesLUTs.map((lut) => lut[measurementTypeId]);
        const fieldName = `${props.name}.${measurementTypeId}`;
        const tagIdentifier = measurementType?.tagIdentifier || '';

        return {
            measurementTypeId,
            chemTarget,
            lowerThresholdValue,
            higherThresholdValue,
            targetValue,
            measurementName,
            measurementUnitName,
            prevSampleValues,
            fieldName,
            tagIdentifier,
        };
    });
};
