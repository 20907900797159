import { ModuleLoadable } from '@poolware/app-shell';

export const Franchises = {
    ViewFranchise: ModuleLoadable({
        loader: () => import('./Franchises/View' /* webpackChunkName: "hqadmin" */).then((V) => V.ViewFranchise),
    }),
    ViewMyFranchise: ModuleLoadable({
        loader: () => import('./Franchises/View' /* webpackChunkName: "hqadmin" */).then((V) => V.ViewMyFranchise),
    }),
    List: ModuleLoadable({
        loader: () => import('./Franchises/List' /* webpackChunkName: "hqadmin" */).then((V) => V.FranchisesListPage),
    }),
    New: ModuleLoadable({ loader: () => import('./Franchises/New' /* webpackChunkName: "hqadmin" */) }),
    Edit: ModuleLoadable({ loader: () => import('./Franchises/Edit' /* webpackChunkName: "hqadmin" */) }),
    NewFranchiseShop: ModuleLoadable({
        loader: () => import('./Franchises/NewFranchiseShop' /* webpackChunkName: "hqadmin" */),
    }),
    EditFranchiseShop: ModuleLoadable({
        loader: () => import('./Franchises/EditFranchiseShop' /* webpackChunkName: "hqadmin" */),
    }),
};

export { Router } from './Router';
