import * as React from 'react';
import { NodeType } from '@poolware/api';
import { FormRecurApptToRecurCaseConverter } from './FormRecurApptToRecurCaseConverter';
import { useServiceJobMutators } from '../../../queries/mutators-service-job';
import invariant from 'invariant';
import { confirmModalImperative, Panel, toastError, useModalCtrl } from '@poolware/components';
import { ModuleIconNames } from '../../../constants';
import { ButtonSectionHeader } from '../components';
import { Modal } from 'semantic-ui-react';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    customer: Yup.object().required('Required').nullable(),
    sjTemplate: Yup.object().required('Required').nullable(),
    woTemplate: Yup.object().required('Required').nullable(),
});

export const PanelWorkOrderConvertToRecur: React.FC<{
    onCloseAppointment: () => any;
    appointmentItem: NodeType.AppointmentItem;
    canEdit?: boolean;
    serviceJobMutator: ReturnType<typeof useServiceJobMutators>;
}> = (props) => {
    const { appointmentItem, onCloseAppointment, serviceJobMutator, canEdit } = props;
    const modalCtrl = useModalCtrl(false);

    //Simple appointment, not a Service Case
    invariant(appointmentItem?.isRecurring || appointmentItem?.isRecurrentOrigin, 'Requires recurrent appointment');

    const initialValues = {
        appointmentItem: appointmentItem,
        address: appointmentItem.address,
        pool: appointmentItem.pool,
        customer: appointmentItem.customer,
        staff: appointmentItem.staff,
        woTemplate: null,
        sjTemplate: null,
    };

    const onClick = async () => {
        const res = await confirmModalImperative({
            confirmMessage: {
                header: 'Convert to Recurrent Service?',
                content: (
                    <div>
                        <p>This is a simple recurring appointment.</p>
                        <p>
                            If you wish to add a Work Order to the recurrent appointment, you need to convert it to a
                            Recurring Service.
                        </p>
                    </div>
                ),
            },
            confirmButton: { content: 'Proceed...' },
            cancelButton: { content: 'Cancel' },
        });

        if (!res) {
            return;
        }
        modalCtrl.onOpen();
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const result = await serviceJobMutator.createForRecurrentAppointment({
                serviceTemplate: values.sjTemplate?.id,
                workOrderTemplate: values.woTemplate?.id,
                appointmentItem: values.appointmentItem?.id,
                staff: values.staff?.id,
                customer: values.customer?.id,
                pool: values.pool?.id,
                address: values.address?.id,
            });
            // const newServiceJobId = result.data.ServiceJob.ServiceJob.id;

            // TODO: when service job is created, current appointment item id becomes invalid.
            //  Need to fetch new appointment id and swap the current appointment item in the dock.
            onCloseAppointment?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const button = (
        <ButtonSectionHeader locked={!canEdit} disabled={!canEdit} onClick={onClick} icon={'plus'} content={'Add'} />
    );

    return (
        <Panel>
            <Panel.Body>
                <Panel.SectionHeader dividing={false} icon={ModuleIconNames.WorkOrder} button={button}>
                    Work Order
                </Panel.SectionHeader>
            </Panel.Body>
            <Modal {...modalCtrl}>
                <FormRecurApptToRecurCaseConverter
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onCancel={modalCtrl.onClose}
                    appointmentItem={appointmentItem}
                />
            </Modal>
        </Panel>
    );
};
