import {
    HeatLossModel,
    MonthlyHeatPumpProfile,
    MonthProfile,
    PoolCover,
    PoolLocation,
    PoolTypes,
    WindProfile,
} from '../common/types';
import { CLAMP } from '../utils/utils';

const APP_CONST = {
    'KG/M3': 1000,
    'KJ/KGoC': 4.2,
    '1BTU': 0.0002930710701, // KW/H
    '1ft2': 0.92903, // M2
};

function calcHeatUp(requirePoolTemp, outsideTemp, poolType, estimateVol, operatingHours) {
    let heatUp = 0;
    // calculate heatUp
    if (requirePoolTemp - outsideTemp >= 25 && poolType === PoolTypes.Pool) {
        heatUp = (APP_CONST['KG/M3'] * APP_CONST['KJ/KGoC'] * estimateVol * 6) / (operatingHours * 3600);
    } else {
        heatUp =
            (APP_CONST['KG/M3'] * APP_CONST['KJ/KGoC'] * estimateVol * (requirePoolTemp - outsideTemp)) /
            (operatingHours * 3600);
    }
    return heatUp;
}

function calcHeatSurfaceLoss(poolType, poolLocation, withCover, surfaceAreaTop, requirePoolTemp, outsideTemp) {
    // calculate heatSurfaceLost
    let heatSurfaceLost = 0;
    if (poolType === PoolTypes.Pool || poolType === PoolTypes.SPA) {
        if (poolLocation === PoolLocation.Indoor) {
            if (!withCover) {
                heatSurfaceLost = 3 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp);
            } else {
                heatSurfaceLost = 3 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp) * 0.75;
            }
        } else {
            if (!withCover) {
                heatSurfaceLost = 7 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp);
            } else {
                heatSurfaceLost = 7 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp) * 0.45;
            }
        }
    }
    return heatSurfaceLost;
}

interface CalcInputProps {
    yearProfile: MonthProfile[];
    selectedMonths: string[];
    poolTemp: number;
    lowestAirTemp: number;
    poolType: PoolTypes;
    poolLocation: PoolLocation;
    poolCover: PoolCover;
    windProfile: WindProfile;
    poolVolume: number;
    operatingHours: number;
    poolSurfaceAreaTop: number;
}

export function computeHeatPumpHeatProfile(input: CalcInputProps) {
    const {
        yearProfile,
        poolTemp,
        poolType,
        poolLocation,
        poolCover,
        poolVolume,
        poolSurfaceAreaTop,
        operatingHours,
        selectedMonths,
        lowestAirTemp,
        windProfile = WindProfile.Normal,
    } = input;

    const estimateVol = poolVolume;
    const withCover = poolCover === PoolCover.Yes;
    const heatProfile: MonthlyHeatPumpProfile[] = [];

    for (let monthProfile of yearProfile) {
        let heatUp;
        let heatSurfaceLoss;
        let heatTotal;
        // let operatingTemp = monthProfile.temp;

        // Clamp outside temp to lower air temp. setting;
        // operatingTemp = lowestAirTemp > operatingTemp ? lowestAirTemp : operatingTemp;

        let operatingTemp = CLAMP(monthProfile.temp, lowestAirTemp, poolTemp - 0.5);

        const isSelected = selectedMonths.findIndex((sm) => sm === monthProfile.id) !== -1;

        if (!isSelected || operatingTemp <= 0 || operatingTemp >= poolTemp) {
            heatUp = 0;
            heatSurfaceLoss = 0;
        } else {
            heatUp = calcHeatUp(poolTemp, operatingTemp, poolType, estimateVol, operatingHours);
            heatSurfaceLoss = calcHeatSurfaceLoss(
                poolType,
                poolLocation,
                withCover,
                poolSurfaceAreaTop,
                poolTemp,
                operatingTemp
            );
        }

        if (heatUp > 0 && withCover) {
            heatTotal = 0.8 * (heatUp + heatSurfaceLoss) * 0.3;
        } else {
            heatTotal = 0.5 * (heatUp + heatSurfaceLoss);
        }

        if (!isSelected) {
            heatTotal = 0;
        }

        if (windProfile === WindProfile.High) {
            heatTotal = 1.21 * heatTotal; // add 21% for high wind
        }

        if (withCover) {
            // heatTotal = 0.101 * heatTotal;
            // TODO: In the original code, there is a magic number of '5'? Why '5'?
            // Applying this number here as well.
            // It is 4 in here, because counting starts from 0 in my code, but from 1 in theirs.
            // if (monthProfile.index < 4) {
            //     heatTotal = 0.05 * heatTotal;
            // } else {
            // heatTotal = 0.101 * heatTotal;
            // }
        }

        const heatMonthProfile: MonthlyHeatPumpProfile = {
            operatingHoursPerDay: 0,
            monthProfile,
            heatSurfaceLoss,
            operatingTemp,
            heatUp,
            heatTotal,
            isSelected,
        };

        heatProfile.push(heatMonthProfile);
    }

    return heatProfile;
}

export const calcHeatLossDiffModel = (input: {
    yearProfile: MonthProfile[];
    heatProfileCovered: MonthlyHeatPumpProfile[];
    heatProfileUncovered: MonthlyHeatPumpProfile[];
}): HeatLossModel[] => {
    const { heatProfileCovered, heatProfileUncovered } = input;
    const res: HeatLossModel[] = input.yearProfile.map((p, i) => {
        return {
            covered: heatProfileCovered?.[i]?.heatTotal,
            uncovered: heatProfileUncovered?.[i]?.heatTotal,
            month: p.name,
        };
    });

    return res;
};
