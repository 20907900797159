import * as React from 'react';
import gql from 'graphql-tag';
import {
    NodeType,
    QueryConnectionConfigProps,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';

const connectionPath = 'viewer.poolSanitisers';
const QL = gql`
    query QueryPoolSanitisersConnection(
        $after: String
        $before: String
        $first: Int
        $last: Int
        $page: Int
        $search: SanitiserViewerPoolSanitisersSearch
        $sort: SanitiserViewerPoolSanitisersSort
    ) {
        viewer {
            poolSanitisers(
                after: $after
                before: $before
                first: $first
                last: $last
                page: $page
                search: $search
                sort: $sort
            ) {
                pageInfo {
                    hasPreviousPage
                    hasNextPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        id
                        name
                        criteria(first: 100) {
                            edges {
                                node {
                                    id
                                    name
                                    lowerThreshold
                                    targetValue
                                    higherThreshold
                                    measurementType {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                        organisationType {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const useQueryPoolSanitisersConnection = (
    props?: Partial<QueryConnectionConfigProps<NodeType.SanitiserViewerPoolSanitisersSearch>>
) => {
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 20,
        ...props,
    });

    return useQueryConnection<NodeType.Sanitiser>({
        query: QL,
        // fetchPolicy: props?.fetchPolicy || 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
};
