import * as React from 'react';
import gql from 'graphql-tag';
import {
    NodeType,
    QueryConnection,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';

const connectionPath = 'viewer.appointmentGroups';
const QL = gql`
    query SideBarDataQuery_AppointmentGroups(
        $first: Int
        $page: Int
        $search: AppointmentGroupViewerAppointmentGroupsSearch
    ) {
        viewer {
            appointmentGroups(first: $first, page: $page, search: $search) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        title
                        defaultColor
                        description
                        priority
                    }
                }
            }
        }
    }
`;

export interface ExternalProps extends QueryConnectionExternalProps<NodeType.AppointmentGroup> {
    franchiseId?: NodeType.ID;
}

const mapPropsToSearchQuery = (props) => {
    const { franchiseId } = props;
    if (!franchiseId) {
        return null;
    }
    return {
        franchise: {
            id: franchiseId,
        },
    };
};

export const QueryAppointmentGroupsConnection: React.FC<ExternalProps> = (props) => {
    console.log('QueryAppointmentGroupsConnection', props);
    const { variables, connectionState } = useDefaultConnectionState({
        search: mapPropsToSearchQuery(props),
        debugName: 'QueryAppointmentGroupsConnection',
        defaultPageSize: 100,
        ...props,
    });

    return (
        <QueryConnection
            fetchPolicy={props.fetchPolicy}
            query={props.query || QL}
            variables={variables}
            connectionConfig={connectionState}
            connectionPath={connectionPath}
            children={props.children}
        />
    );
};

export const useQueryAppointmentGroupsConnection = (props: ExternalProps) => {
    const { variables, connectionState } = useDefaultConnectionState({
        search: mapPropsToSearchQuery(props),
        debugName: 'QueryAppointmentGroupsConnection',
        defaultPageSize: 100,
        ...props,
    });

    return useQueryConnection<NodeType.AppointmentGroup>({
        query: props.query || QL,
        pollInterval: props.pollInterval,
        skip: props.skip,
        fetchPolicy: props.fetchPolicy || 'cache-and-network',
        errorPolicy: props.errorPolicy || 'ignore',
        variables: variables,
        connectionPath: props.connectionPath || connectionPath,
        connectionConfig: connectionState,
    });
};
