import * as React from 'react';
import styled from 'styled-components';
import { NodeType } from '@poolware/api';
import { ActionBar, IconButton, IconWithPopup } from '@poolware/components';
import { DEFAULT_APPOINTMENT_GROUP_ID } from '../../../../constants';

const ColorBox = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    position: relative;
    display: inline-block;
    vertical-align: bottom;
    min-height: 1.25em;
    min-width: 1.25em;
    border-radius: 1em;
    border: 1px solid #cccccc;
    margin: 0 0.2em;
`;

export interface AppointmentGroupFilterProps {
    excludeIds: string[];
    onFilterChange: ({ excludeIds }) => any;
    appointmentGroups: NodeType.AppointmentGroup[];
    canEdit: boolean;
    onNewAppointmentGroup?: () => any;
    onEditAppointmentGroup?: (ag: NodeType.AppointmentGroup) => any;
}

export const AppointmentGroupFilter: React.FC<AppointmentGroupFilterProps> = (props) => {
    const {
        onNewAppointmentGroup,
        onEditAppointmentGroup,
        onFilterChange,
        excludeIds: excludeAppointmentGroupsIds,
        appointmentGroups,
        canEdit,
    } = props;

    const handleOnFilterChange = (agId: NodeType.ID, checked: boolean) => {
        // Check whether the group is in the exclusion list
        const isInFilterArray = excludeAppointmentGroupsIds.findIndex((id) => id === agId);

        if (!checked) {
            // if group is unchecked, then add it to exclusion list.
            if (isInFilterArray < 0) {
                // it is not in the list, adding it.
                const newFilters = [...excludeAppointmentGroupsIds, agId];
                // setAppointmentGroupFilter(newFilters);
                onFilterChange({ excludeIds: newFilters });
            }
        } else {
            // if group is checked, then remove it from exclusion list.
            if (isInFilterArray >= 0) {
                // it is in the list, removing it
                let a = [...excludeAppointmentGroupsIds];
                a.splice(isInFilterArray, 1);
                // setAppointmentGroupFilter(a);
                onFilterChange({ excludeIds: a });
            }
        }
    };

    const groupActionComp = canEdit ? (
        <IconWithPopup
            name={'plus'}
            color={'teal'}
            onClick={onNewAppointmentGroup}
            popup={{ content: 'Add new group' }}
        />
    ) : (
        <IconWithPopup name={'plus'} color={'grey'} popup={{ content: 'Not enough permission' }} />
    );

    const hasFilter = excludeAppointmentGroupsIds?.length > 0;
    return (
        <ActionBar active={hasFilter}>
            <ActionBar.Header content={'Appointment Groups'} icon={'filter'} actionComponent={groupActionComp} />
            <ActionBar.Body>
                <ActionBar.Item>
                    <ActionBar.Checkbox
                        onChange={(checked) => handleOnFilterChange(DEFAULT_APPOINTMENT_GROUP_ID, checked)}
                        checked={!excludeAppointmentGroupsIds.includes(DEFAULT_APPOINTMENT_GROUP_ID)}
                        highlightChecked={false}
                        content={'Default Group'}
                        labelStyle={{ fontWeight: 'bold' }}
                    />
                </ActionBar.Item>
                {appointmentGroups.map((ag) => {
                    return (
                        <ActionBar.ItemCheckbox
                            key={ag.id}
                            highlightChecked={false}
                            checked={!excludeAppointmentGroupsIds.includes(ag.id)}
                            onChange={(checked) => handleOnFilterChange(ag.id, checked)}
                            content={
                                <>
                                    <ColorBox color={ag.defaultColor} />
                                    {ag.title}
                                </>
                            }
                            actionComponent={
                                canEdit && <IconButton name={'edit'} onClick={() => onEditAppointmentGroup(ag)} />
                            }
                        />
                    );
                })}
            </ActionBar.Body>
        </ActionBar>
    );
};
