import { call, put, race, take, cancelled } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { BOOKING_ACTION, BookingAction, BookingDetails, BookingReturnDest } from './reducer-appointment-booking';
import { CalViewLayoutMode } from '../Scheduler';
import { CalendarAction } from './reducers-calendar-state';

export const createWatchAppointmentBookingFromServiceJob = (store, URLBuilder) => {
    const sagaName = 'SAGA_BOOK_APPOINTMENT_FROM_SERVICE_JOB';

    function* handler(sagaName, { payload }) {
        const navigate = (url, state: any = {}) => {
            return navigateActions.browserNavigation(url, { ...state, saga: sagaName });
        };

        const { details, returnTo }: { details: BookingDetails; returnTo: any } = payload || {};

        try {
            // Reset all
            yield put(BookingAction.setSagaMode(true));
            yield put(BookingAction.setSelectingSlotMode(true));
            yield put(CalendarAction.setLayoutMode(CalViewLayoutMode.GRID));
            yield put(CalendarAction.setPreviewAppt(null));

            // Put initial data for booking
            if (details.workOrder || details.serviceJob) {
                yield put(
                    BookingAction.setDetails({
                        ...details,
                    })
                );
            }

            yield put(navigate(URLBuilder.Scheduler.home));

            yield take([BOOKING_ACTION.TIME_SLOT_SELECTED]);

            yield put(navigate(URLBuilder.Scheduler.new, { modal: true }));

            yield take([BOOKING_ACTION.BOOKING_CREATED]);

            yield put(navigate(URLBuilder.Scheduler.home));

            // yield call(delay, 100);

            yield put(navigate(URLBuilder.Scheduler.where, { modal: true }));

            const formAction = yield take([BOOKING_ACTION.NAVIGATE_TO_AFTER_CREATION]);

            if (formAction.payload === BookingReturnDest.SERVICE_JOB) {
                yield put(navigate(returnTo));
            }

            if (formAction.payload === BookingReturnDest.CALENDAR) {
                yield put(navigate(URLBuilder.Scheduler.home));
            }
        } catch (e) {
            console.error(e);
            yield put(BookingAction.abort());
        } finally {
            if (yield cancelled()) {
                yield put(navigate(returnTo));
            }
        }
    }

    function* watchAppointmentBookingAbort(sagaName) {
        yield race({
            navigationAbort: call(sagaAbortOnNavigation, sagaName),
            actionAbort: take(BOOKING_ACTION.ABORT),
        });
    }

    function* watch() {
        while (true) {
            yield put(BookingAction.reset());
            const startAction = yield take(BOOKING_ACTION.START_FROM_SERVICE_JOB);
            yield race({
                saga: call(handler, sagaName, startAction),
                abort: call(watchAppointmentBookingAbort, sagaName),
            });
        }
    }

    return watch;
};
