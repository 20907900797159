import gql from 'graphql-tag';
import { addressFragment, customerContactDetailsFragment, customerFragment, staffFragment } from '@poolware/api';

export const fragmentAbstractDoc = gql`
    fragment QueryAbstractDocFull on AbstractDocument {
        id
        issuedAt
        refNumber
        typeTag
        statusTag
        jsonBody
        dateDue
        completedAt
        completionNote
        createdAt
        updatedAt
        addedBy {
            id
            ...StaffFragment
        }
        customer {
            id
            ...CustomerFragment
        }
        serviceJob {
            id
            title
            serviceJobNumber
        }
        workOrder {
            id
            title
            workOrderNumber
        }
        completedById {
            id
            ...StaffFragment
        }
        franchise {
            id
            name
        }
        checkMutations {
            update
            delete
        }
    }
    ${customerFragment}
    ${staffFragment}
`;

export const fragmentFranchiseValueStore = gql`
    fragment FranchiseValueFragment on FranchiseValueStore {
        id
        group
        key
        value
        createdAt
        updatedAt
        franchise {
            id
            name
        }
        checkMutations {
            update
            delete
        }
    }
`;
