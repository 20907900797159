import { fromEdges, NodeType, TraitValueType } from '@poolware/api';
import * as _ from 'lodash';

export interface ProductTraitFlattenedValue {
    type: TraitValueType; // trait type
    name: string; // trait name
    formValue: any; // value to use in forms.
    printValue: string; // printable representation of `traitValue`
    traitValue: any; // one of floatValue, IntegerValue, SelectionValue etc,
    traitField: any; // one of integerFields, floatField etc
}

export interface ProductTraitFlattened {
    trait: NodeType.ProductTraitDeclaration;
    traitLevel: number;
    values: ProductTraitFlattenedValue[];
}

type ConfItemType = {
    type: TraitValueType;
    valuesPath: string;
    fieldsPath: string;
    formValuePath: string;
    printValuePath: (rawVal: any) => string;
};
const conf: ConfItemType[] = [
    {
        type: TraitValueType.Float,
        valuesPath: 'floatValues',
        fieldsPath: 'floatFields',
        formValuePath: 'value',
        printValuePath: (rawVal) => _.get(rawVal, 'value'),
    },
    {
        type: TraitValueType.Int,
        valuesPath: 'integerValues',
        fieldsPath: 'integerFields',
        formValuePath: 'value',
        printValuePath: (rawVal) => _.get(rawVal, 'value'),
    },
    {
        type: TraitValueType.String,
        valuesPath: 'stringValues',
        fieldsPath: 'stringFields',
        formValuePath: 'value',
        printValuePath: (rawVal) => _.get(rawVal, 'value'),
    },
    {
        type: TraitValueType.Selection,
        valuesPath: 'selectionValues',
        fieldsPath: 'selectionFields',
        formValuePath: 'value.id',
        printValuePath: (rawVal) => _.get(rawVal, 'value.name'),
    },
    {
        type: TraitValueType.Flag,
        valuesPath: 'flagValues',
        fieldsPath: 'flagFields',
        formValuePath: 'value',
        printValuePath: (rawVal) => (rawVal?.value ? 'true' : 'false'),
    },
];

const mapFields = (fields: NodeType.Node[], values: any, c: ConfItemType) => {
    return fields.map((ff: any) => {
        const correspondingValue = values.find((fv) => {
            return fv.field.id === ff.id;
        });

        return {
            type: c.type,
            name: ff.name,
            formValue: _.get(correspondingValue, c.formValuePath),
            printValue: c.printValuePath(correspondingValue),
            traitField: ff,
            traitValue: correspondingValue,
        };
    });
};

export const flattenTraitValues = (trait: NodeType.ProductTrait): ProductTraitFlattened => {
    const fields: ProductTraitFlattenedValue[] = conf
        .map((c) => {
            const floatValues = fromEdges<any>(_.get(trait, c.valuesPath));
            const floatFields = fromEdges<any>(_.get(trait, 'declaration.' + c.fieldsPath));
            return mapFields(floatFields, floatValues, c);
        })
        .flat(1);

    const offset = fromEdges(trait?.declaration?.parentDeclarations).length;

    return {
        // @ts-ignore
        trait: trait,
        traitLevel: offset,
        values: fields,
    };
};

export const flattenTraitDeclaration = (traitDeclaration: NodeType.ProductTraitDeclaration): ProductTraitFlattened => {
    const fields: ProductTraitFlattenedValue[] = conf
        .map((c) => {
            const floatValues = [];
            const floatFields = fromEdges<any>(_.get(traitDeclaration, c.fieldsPath));
            return mapFields(floatFields, floatValues, c);
        })
        .flat(1);

    const offset = fromEdges(traitDeclaration?.parentDeclarations).length;

    return {
        trait: traitDeclaration,
        traitLevel: offset,
        values: fields,
    };
};
