import gql from 'graphql-tag';
import { Query, QueryResult } from 'react-apollo';
import { PageSkeletonLoader } from '@poolware/components';
import { customerFragment, NodeType } from '@poolware/api';
import ContactsCard, { prepareCustomerContactItems } from '../../../CommonComponents/Contacts/Contacts';
import * as React from 'react';
import { DocumentNode } from 'graphql/language/ast';
import { Message } from 'semantic-ui-react';

const QL = gql`
    query CustomerDetailsQuery($id: ID!) {
        customer: node(id: $id) {
            ... on Customer {
                id
                ...CustomerFragment
            }
        }
    }
    ${customerFragment}
`;

interface QueryCustomerNodeProps {
    query?: DocumentNode;
    customerId: NodeType.ID;
    children: (result: QueryResult & { customer?: NodeType.Customer }) => JSX.Element | null;
}

export const QueryCustomerNode: React.FC<QueryCustomerNodeProps> = ({ query, customerId, children }) => {
    return (
        <Query query={query || QL} variables={{ id: customerId }}>
            {(result) => {
                const { data } = result;
                const customer = data?.customer;
                return children({ ...result, customer });
            }}
        </Query>
    );
};

export const CustomerDetails: React.FC<{ customerId: NodeType.ID }> = ({ customerId }) => {
    return (
        <QueryCustomerNode query={QL} customerId={customerId}>
            {({ customer, loading }) => {
                if (loading) {
                    return <PageSkeletonLoader />;
                }
                if (!customer) {
                    return <Message error>Failed to load customer details</Message>;
                }

                const customerContactItems = prepareCustomerContactItems(customer);
                return <ContactsCard contactItems={customerContactItems} />;
            }}
        </QueryCustomerNode>
    );
};
