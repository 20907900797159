import * as React from 'react';
import { useMemo } from 'react';
import { fromEdges, JobTodoItemStatus, NodeType } from '@poolware/api';
import { JobTodo } from './JobTodo';
import { JobsContainer, SummaryContainer } from './componets-styled';
import { getWorkOrderSummary } from '../utils';

export interface JobTodosLIstProps {
    workOrder: NodeType.WorkOrder;
    onAllJobsComplete?: () => any;
}

export const JobTodosListViewMode: React.FC<JobTodosLIstProps> = ({ workOrder, onAllJobsComplete }) => {
    const sortedJobTodos = useMemo(() => {
        return fromEdges(workOrder.jobs).sort((l, r) => {
            return l.index > r.index ? 1 : -1;
        });
    }, [workOrder?.jobs]);

    const workOrderSummary = useMemo(() => {
        return getWorkOrderSummary(sortedJobTodos);
    }, [sortedJobTodos]);

    const totalTimeString = workOrderSummary.totalTime ? `${workOrderSummary.totalTime} min` : '--';

    const onJobDidChange = async (notification: { id: NodeType.ID; status: number }) => {
        const allJobs = fromEdges(workOrder.jobs);

        //
        // Walk through all status.
        // For those item whose ID matches notification.id, use the status value from `notification` message.
        // We need to do this because workOrder hasn't been updated by appollo yet.
        //
        const statuses = allJobs.flatMap((j) => {
            const items = fromEdges(j.items);
            if (items.length === 0) {
                return j.id === notification.id ? [notification.status] : [j.status];
            } else {
                return items.map((i) => {
                    return i.id === notification.id ? notification.status : i.status;
                });
            }
        });
        const openJobs = statuses.filter((status) => status === JobTodoItemStatus.Open);
        if (allJobs.length > 0 && openJobs.length === 0) {
            return onAllJobsComplete?.();
        }
    };

    return (
        <>
            <SummaryContainer>
                {sortedJobTodos.length > 0 && workOrderSummary.totalTime > 0 && (
                    <div className={'summary-row'}>
                        <div className={'summary-row-label'}>Total Duration:</div>
                        <div className={'summary-row-value'}>{totalTimeString}</div>
                    </div>
                )}
                {false && (
                    <div className={'summary-row'}>
                        <div className={'summary-row-label'}>Total Cost:</div>
                        <div className={'summary-row-value'}>$ {workOrderSummary.totalPrice}.00</div>
                    </div>
                )}
            </SummaryContainer>
            <JobsContainer>
                {sortedJobTodos.map((jobTodo) => {
                    return <JobTodo jobTodo={jobTodo} key={jobTodo.id} onJobDidChange={onJobDidChange} />;
                })}
            </JobsContainer>
        </>
    );
};
