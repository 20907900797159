import { UserProfile } from '@ez/api-core';
import { getAppConfig } from '../app-config';
import { makeDebugger } from '@ez/tools';

const debug = makeDebugger('zendesk');

const hideZendeskSupportButton = () => {
    debug('hide zendesk button');
    // hide zendesk button by default
    window['zE'] && window['zE']('webWidget', 'hide');
};

const showZendeskSupportButton = () => {
    // hide zendesk button by default
    debug('show zendesk button');
    window['zE'] && window['zE']('webWidget', 'show');
};

const activateZendesk = () => {
    try {
        // @ts-ignore
        if (!window['zE']) {
            return;
        }
        window['zE'].activate({ hideOnClose: true });
    } catch (e) {
        console.error(e);
    }
};

const identifyZendesk = (input?: Partial<UserProfile>) => {
    debug('identify zendesk with', input);

    if (!window['zE']) {
        return;
    }

    if (!input) {
        try {
            debug('clearing identification');
            window['zE']('webWidget', 'logout');
        } catch (e) {
            console.error(e);
        }
        return;
    }

    const { firstName, lastName, email } = input;
    const name = firstName || lastName ? `${firstName} ${lastName}` : undefined;

    try {
        window['zE'](function () {
            window['zE'].identify({
                name: name,
                email: email,
            });
        });
    } catch (e) {
        console.error(e);
    }
};

const isEnabled = () => {
    const conf = getAppConfig();
    return !(!window['zE'] || !conf.zendesk.enabled);
};

export const Zendesk = {
    activate: activateZendesk,
    hideSupportButton: hideZendeskSupportButton,
    showSupportButton: showZendeskSupportButton,
    identify: identifyZendesk,
    isEnabled,
};
