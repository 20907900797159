import * as React from 'react';
import { VStack } from '@poolware/components';
import { useProductCatalogActions } from '../../redux';
import { NodeType } from '@poolware/api';
import { ProductTraitFlattened } from '../../utils';
import { preparePillsUIModel } from './prepare-ui-model';
import { PillComponents, PillsSelectRow } from './PillComponents';

interface ActiveFiltersPillsBarProps {
    flattenedDeclarations: ProductTraitFlattened[];
    relatedBrands: NodeType.Brand[];
    relatedCompanies?: NodeType.ProductCompany[];
}

const ActiveFiltersPillsBar: React.FC<ActiveFiltersPillsBarProps> = ({
    flattenedDeclarations,
    relatedBrands,
    relatedCompanies,
}) => {
    const { ProductCatalogAction, ProductCatalogState } = useProductCatalogActions();
    const { filters } = ProductCatalogState;

    if (!filters.hasActiveFilters) {
        return null;
    }

    const { pills, traitDeclarationPills, brandSelect, productCompanySelect } = preparePillsUIModel({
        relatedBrands,
        relatedCompanies,
        flattenedDeclarations,
        ProductCatalogState,
        ProductCatalogAction,
    });

    return (
        <VStack space={0.5}>
            <div>
                {pills.map((pill, index) => (
                    <PillComponents key={index} {...pill} />
                ))}
            </div>

            {productCompanySelect && productCompanySelect.options.length > 0 && (
                <div>
                    <PillsSelectRow label={<b>Product Company</b>} select={productCompanySelect} />
                </div>
            )}

            {brandSelect && brandSelect.options.length > 0 && (
                <div>
                    <PillsSelectRow label={<b>Related Brands</b>} select={brandSelect} />
                </div>
            )}

            {traitDeclarationPills &&
                traitDeclarationPills.map((traitDeclarationPill, index) => {
                    return (
                        <React.Fragment key={index}>
                            {traitDeclarationPill.traitFieldSelects.map((tvs, i) => {
                                const select = tvs.select;
                                const label = (
                                    <>
                                        <span style={{ color: '#6e6e6e' }}>{select.name}</span> :{' '}
                                        <b>{select.subName}</b>
                                    </>
                                );
                                return (
                                    <div key={i}>
                                        <PillsSelectRow label={label} select={select} />
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
        </VStack>
    );
};

export default ActiveFiltersPillsBar;
