import { fromEdges, MutationHookOptionsJobTodo, NodeType, useMutationJobTodo } from '@poolware/api';

import * as _ from 'lodash';

export const useJobTodoMutators = (options?: MutationHookOptionsJobTodo) => {
    const mutateJobTodo = useMutationJobTodo(options);

    const sanitizeJobItem = (item: Partial<NodeType.JobTodoItem>) => {
        const { id, title, orderIndex, description, status } = item;
        const conf: any = {
            id,
        };
        if (title !== undefined) conf.title = _.trim(title);
        if (orderIndex !== undefined) conf.orderIndex = _.toNumber(orderIndex);
        if (description !== undefined) conf.description = _.trim(description);
        if (status !== undefined) conf.status = Number(status);

        return conf;
    };

    const reindexItemsUpdateMutation = (items: NodeType.JobTodoItem[]) => {
        return items
            .map((item, index) => {
                return {
                    id: item.id,
                    orderIndex: index,
                    oldIndex: item.orderIndex,
                };
            })
            .filter((item) => {
                return item.orderIndex !== item.oldIndex;
            })
            .map((item) => sanitizeJobItem(item as any));
    };

    const Job_appendItem = async (job: NodeType.JobTodo, items: NodeType.JobTodoItem[], item: NodeType.JobTodoItem) => {
        const maxOrderIndex = fromEdges(job.items).reduce((acc, cur) => {
            return acc > cur.orderIndex ? acc : cur.orderIndex;
        }, 0);
        const newIndex = maxOrderIndex + 1;

        let mutationConfig: NodeType.UpdateJobTodoMutationInput = {
            id: job.id,
            items: {
                // update: reindexItemsUpdateMutation(items),
                create: [
                    {
                        ...sanitizeJobItem(item),
                        orderIndex: newIndex,
                    },
                ],
            },
        };
        return await mutateJobTodo.update(mutationConfig);
    };

    const Job_reorderItems = async (job: NodeType.JobTodo, newItemIndexes: NodeType.JobTodoItem[]) => {
        const items = fromEdges(newItemIndexes);

        let mutationConfig = {
            id: job.id,
            items: {
                update: reindexItemsUpdateMutation(items),
            },
        };
        return await mutateJobTodo.update(mutationConfig);
    };

    /**
     * Ignores orderIndex
     */
    const Job_updateItem = async (job: NodeType.JobTodo, item: Partial<NodeType.JobTodoItem>) => {
        item = _.omit(item, 'orderIndex');
        let mutationConfig = {
            id: job.id,
            items: {
                update: [
                    {
                        ...sanitizeJobItem(item),
                    },
                ],
            },
        };
        return await mutateJobTodo.update(mutationConfig);
    };

    const Job_deleteItem = async (
        job: NodeType.JobTodo,
        items: NodeType.JobTodoItem[],
        item: Partial<NodeType.JobTodoItem>
    ) => {
        items = fromEdges(items).filter(({ id }) => id !== item.id);

        let mutationConfig = {
            id: job.id,
            items: {
                update: reindexItemsUpdateMutation(items),
                delete: [item.id],
            },
        };
        return await mutateJobTodo.update(mutationConfig);
    };

    const Job_update = async (job: NodeType.NodeOrId<NodeType.JobTodo>, payload: Partial<NodeType.JobTodo>) => {
        let mutationConfig = {
            id: NodeType.extractId(job),
            title: payload.title ? _.trim(payload.title) : undefined,
            description: payload.description ? _.trim(payload.description) : undefined,
        };
        return await mutateJobTodo.update(mutationConfig);
    };

    const Job_create = async (values: Partial<NodeType.CreateJobTodoMutationInput>): Promise<NodeType.JobTodo> => {
        const { title, workOrder, ...rest } = values;
        let mutationConfig: NodeType.CreateJobTodoMutationInput = {
            title: _.trim(title),
            workOrder: NodeType.extractId(workOrder),
            ...rest,
        };
        const response = await mutateJobTodo.create(mutationConfig);
        return _.get(response, 'data.JobTodo.JobTodo');
    };

    return {
        ...mutateJobTodo,
        JobTodoMutator: {
            appendItem: Job_appendItem,
            reorderItems: Job_reorderItems,
            updateItem: Job_updateItem,
            deleteItem: Job_deleteItem,
            update: Job_update,
            create: Job_create,
        },
    };
};
