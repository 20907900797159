import styled from 'styled-components';

export const SummaryContainer = styled.div`
    flex-shrink: 0;
    font-size: 0.9em;
    padding-right: 2em;
    margin-top: 0;

    .summary-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .summary-row-label {
            flex-basis: 100px;
            font-weight: bold;
            flex-grow: 2;
            text-align: end;
        }

        .summary-row-value {
            flex: 0;
            flex-basis: 100px;
            padding-left: 1em;
            text-align: end;
        }
    }
`;

export const JobsContainer = styled.div`
    //margin-top: 1em;
    padding-right: 1em;

    .jobtodo {
        font-size: 1em;

        &:not(:last-child) {
            border-bottom: 1px solid #869eb3;
            padding-bottom: 1em;
            margin-bottom: 1em;
        }
    }

    .jobtodo-content {
        padding-left: 2em;
        border-left: 2px solid rgba(1, 1, 1, 0);
    }

    .jobtodo-description {
        border-radius: 4px;
        padding: 0.5em;
        margin: 0.5em;
        font-style: italic;
        font-size: 0.95em;
        background-color: #f0f0f0;
    }

    .actionable {
        color: #555555;
        //font-weight: bolder;
    }

    .completed {
        color: #111111;
        font-size: 1.1em;
        //font-weight: lighter;
        //text-decoration: line-through;
    }

    .item-row {
        border-left: 2px solid rgba(1, 1, 1, 0);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 1em;
        padding: 0.5em 0.5em;

        &:not(.actionable) {
            color: #222222;
        }

        &.actionable:hover {
            background-color: #f0f8ff;
            color: #2b5578;
            border-left: 2px solid #2b5578;
        }

        &.jobtodo-item {
            :not(:last-child) {
                border-bottom: 1px solid #cccccc;
            }
        }

        & > .item-icon-lvl1 {
            flex: 0 0 2em;
        }

        & > .item-icon-lvl2 {
            flex: 0 0 2em;
        }

        & > .item-title {
            flex-grow: 1;
            padding-right: 0.5em;
        }

        & > .item-button {
            flex-grow: 0;
            flex-basis: 60px;
        }
    }

    .summary-row {
        padding: 0 0.75em;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .summary-row-item {
            text-align: end;
            padding-left: 0.5em;
            margin-left: 0.5em;
            color: #8c8c8c;
            font-size: 0.8em;

            &:not(:first-child) {
                border-left: 1px solid #cccccc;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding: 0;
        margin: 0;
        .jobtodo-content {
            padding-left: 0;
        }

        .item-row,
        .jobtodo-content {
            border-left: 0 solid rgba(1, 1, 1, 0);
        }
    }

    @media screen and (max-width: 370px) {
        .item-icon-lvl2 {
            display: none;
        }
    }
`;
