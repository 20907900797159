import * as React from 'react';
import { useServiceJobListActions } from '../../redux';
import { ActionBarFilterByServiceStageType } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobStageType';

export const FilterByServiceJobStageType: React.FC = () => {
    const { Actions, State } = useServiceJobListActions();

    return (
        <ActionBarFilterByServiceStageType
            setServiceJobStageType={Actions.setServiceStageType}
            setServiceJobStageStatus={Actions.setServiceStageStatus}
            valueStageTypes={State.filters.serviceStageType}
            valueStageStatus={State.filters.serviceStageStatus}
            persistStorageKey={'sj.stagetype.filter.toggle'}
            headerTitle={'Service Case Stage'}
        />
    );
};
