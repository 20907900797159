import { createAction } from '@ez/tools';

//// ***** ACTIONS ******** ////

const NAVIGATION_ACTION = {
    NAVIGATE: 'NAVIGATE',
    BROWSER_NAVIGATED: 'BROWSER_NAVIGATED',
};

const browserNavigation = (pathname: string, state?: any, replace?: boolean) =>
    createAction(NAVIGATION_ACTION.NAVIGATE, { pathname, state, replace });

const browserNavigated = (location, action) => createAction(NAVIGATION_ACTION.BROWSER_NAVIGATED, { location, action });

export const navigateActions = {
    browserNavigation,
    browserNavigated,
    NAVIGATION_ACTION,
};
