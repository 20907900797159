import * as React from 'react';
import { PageContainer } from '../Layout/PageContainer';
import { NotFoundPage } from '@poolware/app-shell';

export const NotFoundPageInContainer = () => {
    return (
        <PageContainer>
            <NotFoundPage />
        </PageContainer>
    );
};
