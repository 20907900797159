import * as React from 'react';
import { useCalendarActions } from '../../../redux';
import { ActionBarFilterByStaff } from '../../../components/ActionBarFilters/ActionBarFilterByStaff';
import { useViewer } from '@poolware/app-shell';

const StaffFilterWrapper: React.FC = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();
    const { modulesAccess } = useViewer();
    const { staffIds = [], franchise, teamId } = CalendarState.filters;

    return (
        <ActionBarFilterByStaff
            franchiseId={franchise?.id}
            teamId={teamId}
            setStaffTeam={CalendarAction.setTeamFilter}
            forceMeFilter={!!modulesAccess.FieldServices?.calendarSingleStaffMode}
            staffIds={staffIds}
            setStaffFilter={CalendarAction.setStaffFilter}
            singleSelect={false}
            enableTeams={true}
        />
    );
};

export default StaffFilterWrapper;
