import * as React from 'react';
import { NodeType, useMutationCustomer } from '@poolware/api';
import { Embedable as NoteDisplay } from '../../../../components/Note';
import { toastError } from '@poolware/components';

interface PanelCustomerNotesProps {
    customer: NodeType.Customer;
}

export const PanelCustomerNotes: React.FC<PanelCustomerNotesProps> = (props) => {
    const { update } = useMutationCustomer({ refetchQueries: ['CustomerQuery'] });
    const onSubmit = async (value) => {
        try {
            return await update({ id: props.customer.id, note: value });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to save notes', description: e.message });
        }
    };

    const { customer } = props;
    return (
        <NoteDisplay
            color={'grey'}
            header={'Customer Notes'}
            text={customer.note}
            onSubmit={onSubmit}
            context={customer.id}
        />
    );
};
