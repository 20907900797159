import * as React from 'react';
import invariant from 'invariant';
import * as _ from 'lodash';
import gql from 'graphql-tag';
import {
    appointmentItemFragment,
    NodeType,
    QueryConnectionExternalProps,
    recurrenceFragment,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import produce from 'immer';
import moment from 'moment';
import { DateRange } from '../../../constants';

const connectionPath = 'viewer.appointmentItems';

export const AppointmentItemsQuery = gql`
    query AppointmentItemsConnectionForServiceCase(
        $startDate: Date!
        $endDate: Date
        $first: Int
        $franchise: ID
        $customer: ID
        $serviceJob: ID
        $reverse: Boolean
        $before: String
        $after: String
        $patternBlockId: String
    ) {
        viewer {
            appointmentItems(
                before: $before
                after: $after
                startDate: $startDate
                endDate: $endDate
                first: $first
                franchise: $franchise
                customer: $customer
                serviceJob: $serviceJob
                reverse: $reverse
                patternBlockId: $patternBlockId
            ) {
                edges {
                    node {
                        id
                        ...AppointmentItemFragment
                        status
                        isRecurring
                        startDate
                        duration
                        workOrder {
                            id
                            title
                            description
                            workOrderNumber
                            stage {
                                id
                                title
                                type
                                status
                            }
                            assignedTo {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                                role {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    cursor
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
            }
        }
    }
    ${recurrenceFragment}
    ${appointmentItemFragment}
`;

export interface QueryAppointmentItemsConnectionExternalProps
    extends QueryConnectionExternalProps<NodeType.AppointmentItem> {
    dateRange: DateRange;
    customerId?: NodeType.ID;
    serviceJobId?: NodeType.ID;
    franchiseId?: NodeType.ID;
    filters?: any;
    pollInterval?: number;
    reverse?: boolean;
    patternBlockId?: string;
}

export const mapPropsToSearchQuery = (props: Omit<QueryAppointmentItemsConnectionExternalProps, 'children'>) => {
    const { dateRange, franchiseId, customerId, serviceJobId } = props || ({} as any);
    const isoStartDate = moment(dateRange.startDate).toISOString();
    const isoEndDate = dateRange.endDate ? moment(dateRange.endDate).add(1, 's').toISOString() : undefined;

    return {
        reverse: props.reverse,
        first: props.pageSize || 1000,
        franchise: franchiseId,
        customer: customerId,
        serviceJob: serviceJobId,
        startDate: isoStartDate,
        endDate: isoEndDate,
        patternBlockId: props?.patternBlockId,
    };
};

export const useQueryAppointmentItemsConnection = (props?: QueryAppointmentItemsConnectionExternalProps) => {
    let { variables, connectionState } = useDefaultConnectionState(props);
    variables = { ...variables, ...mapPropsToSearchQuery(props) };

    return useQueryConnection({
        query: props.query || AppointmentItemsQuery,
        pollInterval: props.pollInterval,
        skip: props.skip,
        fetchPolicy: props.fetchPolicy,
        errorPolicy: props.errorPolicy || 'ignore',
        variables: variables,
        connectionPath: props.connectionPath || connectionPath,
        connectionConfig: connectionState,
    });
};

interface QueryAppointmentsForCustomerProps {
    serviceJobId: NodeType.ID;
    startDate: Date;
    endDate?: Date;
    after?: string;
    before?: string;
    pageSize?: number;
    reverse?: boolean;
    patternBlockId?: string;
}

export const useQueryAppointmentItemsForServiceJob = (props: QueryAppointmentsForCustomerProps) => {
    const { serviceJobId, startDate, endDate, pageSize, after, before, reverse } = props;

    invariant(serviceJobId, 'serviceJobId id is required');

    const { connectionData, connectionState } = useQueryAppointmentItemsConnection({
        after: after,
        before: before,
        query: AppointmentItemsQuery,
        pageSize: pageSize || 100,
        reverse: reverse,
        fetchPolicy: 'cache-and-network',
        dateRange: {
            startDate: startDate,
            endDate: endDate,
        },
        patternBlockId: props?.patternBlockId,
        serviceJobId: serviceJobId,
    });

    const handleLoadMore = async ({ forward }) => {
        const endCursor = _.get(connectionState, 'pageInfo.endCursor');
        // const startCursor = _.get(connectionState, 'pageInfo.startCursor');
        const variables = {
            after: endCursor,
        };
        try {
            await connectionState.fetchMore({
                variables: variables as any,
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    try {
                        const newState = produce(prev, (draft) => {
                            _.get(draft, 'viewer.appointmentItems.edges', []).push(
                                ...fetchMoreResult.viewer.appointmentItems.edges
                            );
                            // TODO: NOTE: the following code works when fetching data with `after=endCursor` query.
                            //  It hasn't been tested for the case when fetching data with `before=starCursor` query.

                            draft.viewer.appointmentItems.pageInfo.endCursor =
                                fetchMoreResult.viewer.appointmentItems.pageInfo.endCursor;
                            draft.viewer.appointmentItems.pageInfo.hasNextPage =
                                fetchMoreResult.viewer.appointmentItems.pageInfo.hasNextPage;
                            draft.viewer.appointmentItems.pageInfo.hasPreviousPage =
                                fetchMoreResult.viewer.appointmentItems.pageInfo.hasPreviousPage;
                        });
                        return newState;
                    } catch (e) {
                        console.error(e);
                        return prev;
                    }
                },
            });
        } catch (e) {
            console.error(e);
        }
    };

    return { connectionData, connectionState, loadMore: handleLoadMore };
};
