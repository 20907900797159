import * as React from 'react';
import { Button, Form, Icon, Input, InputOnChangeData, Popup } from 'semantic-ui-react';
import { Display, LinkButton } from '@poolware/components';
import { NodeType } from '@poolware/api';

import _toNumber from 'lodash/toNumber';
import _round from 'lodash/round';

interface DosageProps {
    recommendedTreatment: NodeType.RecommendedTreatment;
    onOverrideDosage: (recommendedTreatment: NodeType.RecommendedTreatment, newDosage: number) => any | Promise<any>;
    onReset: (recommendedTreatment: NodeType.RecommendedTreatment) => any | Promise<any>;
    isArchived: boolean;
}

interface DosageState {
    isEditing: boolean;
    value: number;
    isDirty: boolean;
    isSubmitting: boolean;
    isResetting: boolean;
    node: any;
}

export default class Dosage extends React.Component<DosageProps, DosageState> {
    textInputRef = null;
    textDomInputRef = null;

    state = {
        isEditing: false,
        value: 0,
        isDirty: false,
        isSubmitting: false,
        isResetting: false,
        node: undefined,
    };

    onChange = (e, data: InputOnChangeData) => {
        const { value } = data;
        this.setState({
            value: _toNumber(value),
            isDirty: true,
        });
    };

    onStartEdit = (e) => {
        e.preventDefault();
        const { recommendedTreatment } = this.props;
        const { dosage } = recommendedTreatment;

        this.setState({
            isEditing: true,
            value: _round(dosage, 2),
        });
        this.focusTextInput();
    };

    onCancelEdit = () => {
        this.setState({
            isEditing: false,
        });
    };

    onSave = async () => {
        const { recommendedTreatment } = this.props;
        const { value } = this.state;
        this.setState({ isSubmitting: true });
        await this.props.onOverrideDosage(recommendedTreatment, value);
        this.setState({ isEditing: false, isSubmitting: false });
    };

    onReset = async (e) => {
        e.preventDefault();

        const { recommendedTreatment } = this.props;
        this.setState({ isEditing: false, isSubmitting: true, isResetting: true });
        await this.props.onReset(recommendedTreatment);
        this.setState({ isSubmitting: false, isResetting: false });
    };

    focusTextInput = () => {
        if (!this.textInputRef) return;
        this.textInputRef.focus();

        setTimeout(() => {
            // there is some delay before textDomInputRef becomes available.
            // Wait for a few ticks.
            this.textDomInputRef.select();
        }, 100);
    };

    renderEditor = () => {
        const { isDirty, isSubmitting, value, isEditing } = this.state;

        // Keep Input mounted at all the time.
        // Otherwise textInputRef behaves funny.

        const style = !isEditing ? { position: 'absolute', left: '-15000px' } : {};
        return (
            <React.Fragment>
                <Form>
                    <Input
                        style={style}
                        type={'number'}
                        step={0.01}
                        min={0}
                        size={'small'}
                        fluid
                        value={value}
                        action
                        onChange={this.onChange}
                        ref={(node) => (this.textInputRef = node)}
                    >
                        <input ref={(node) => (this.textDomInputRef = node)} />
                        <Button
                            basic
                            type={'submit'} // Trigger button on 'Enter/Return' key hit
                            color={'grey'}
                            size={'tiny'}
                            disabled={!isDirty || isSubmitting}
                            icon={'save'}
                            loading={isSubmitting}
                            onClick={this.onSave}
                        />
                        <Button
                            basic
                            size={'tiny'}
                            icon={'close'}
                            disabled={isSubmitting}
                            onClick={this.onCancelEdit}
                        />
                    </Input>
                </Form>
            </React.Fragment>
        );
    };

    renderEditButtons = () => {
        const { isEditing } = this.state;
        const { isArchived, recommendedTreatment } = this.props;
        const { isOverridden } = recommendedTreatment;
        if (isEditing || isArchived) return null;

        return (
            <React.Fragment>
                <div>
                    <LinkButton onClick={this.onStartEdit}>edit</LinkButton>
                </div>

                {isOverridden && (
                    <div>
                        <Popup
                            trigger={<LinkButton onClick={this.onReset}>reset</LinkButton>}
                            closeOnTriggerClick={true}
                            content="Reset to default value"
                            position={'top center'}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    };

    render() {
        const { isEditing, isResetting } = this.state;
        const { recommendedTreatment } = this.props;
        const { dosage, isOverridden, balancedDosage } = recommendedTreatment;
        const dosageUnit = recommendedTreatment?.treatment?.unit?.name;

        if (!recommendedTreatment) {
            return null;
        }

        if (dosage === undefined) {
            return <span>n/a</span>;
        }

        return (
            <div style={{ minWidth: '120px' }}>
                {this.renderEditor()}
                {!isEditing && !isResetting && (
                    <Display.Num style={{ fontSize: '1.1em' }} value={dosage} unit={dosageUnit} />
                )}
                {!isEditing && isResetting && <Icon name={'spinner'} loading={true} />}

                <div style={{ fontSize: '0.9em', padding: '5px' }}>
                    {isOverridden && !isResetting && (
                        <Display.Num
                            style={{ color: '#888', fontSize: '0.8em', textDecoration: 'line-through' }}
                            value={balancedDosage}
                            unit={dosageUnit}
                        />
                    )}
                    {this.renderEditButtons()}
                </div>
            </div>
        );
    }
}
