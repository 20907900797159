import { Button, Table } from 'semantic-ui-react';
import { FormikInputField } from '@poolware/components';
import * as React from 'react';
import { createTableRowDraggable } from '../../../components/table-dnd-helpers';

interface TableRowViewOrEditProps {
    index: number;
    fieldName: string;
    onDelete: (any) => any;
    moveRow;
    onEnter?: () => any;
}

const TableRow = createTableRowDraggable('TableRowViewOrEdit');

export const TableRowViewOrEdit: React.FC<TableRowViewOrEditProps> = ({ fieldName, onEnter, onDelete, ...rest }) => {
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onEnter && onEnter();
        }
    };

    return (
        <TableRow {...rest}>
            <Table.Cell>
                <FormikInputField name={`${fieldName}.title`} onKeyDown={handleKeyDown} />
            </Table.Cell>
            <Table.Cell textAlign="right" width={1}>
                <Button size="mini" icon={'trash'} type={'button'} basic color={'grey'} onClick={onDelete} />
            </Table.Cell>
        </TableRow>
    );
};
