import * as React from 'react';
import gql from 'graphql-tag';
import {
    NodeType,
    QueryConnection,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import _get from 'lodash/get';
import _set from 'lodash/set';

const connectionPath = 'viewer.reports';
const getQL = (excludePageMeta: boolean = false) => gql`
    query PoolTestReportsList(
        $first: Int
        $page: Int
        $search: TestReportViewerReportsSearch
        $sort: TestReportViewerReportsSort
    ) {
        viewer {
            reports(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        refId
                        isArchived
                        isFinalised
                        docVersion
                        createdAt
                        updatedAt
                        previewPdfUrl
                        pdfUrl
                        reportBy {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        reportTo {
                            id
                            user {
                                id
                                customer {
                                    id
                                    crn
                                    user {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                        }
                        pool {
                            id
                            name
                            bottleNumber
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                ${excludePageMeta ? '' : 'pageMeta { pageCount totalCount}'}

            }
        }
    }
`;

export interface QueryWaterTestReportConnectionExternalProps extends QueryConnectionExternalProps<NodeType.TestReport> {
    poolId?: NodeType.ID;
    excludePageMeta?: boolean;
    filter?: {
        createdBefore?: Date;
        createdAfter?: Date;
        updatedBefore?: Date;
        updatedAfter?: Date;
    };
    sort?: {
        updatedAt?: boolean;
        createdAt?: boolean;
    };
}

const mapPropsToSearchQuery = (props: QueryWaterTestReportConnectionExternalProps) => {
    const search = {};

    const map = {
        poolId: 'pool.id',
        'filter.createdAfter': 'createdAt.after',
        'filter.createdBefore': 'createdAt.before',
        'filter.updatedBefore': 'updatedAt.before',
        'filter.updatedAfter': 'updatedAt.before',
    };

    Object.keys(map).forEach((mapKey) => {
        const searchPath = map[mapKey];
        const propValue = _get(props, mapKey);
        if (!searchPath || !propValue) {
            return;
        }

        _set(search, searchPath, propValue);
    });

    return search;
};

export const QueryWaterTestReportConnection: React.FC<QueryWaterTestReportConnectionExternalProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.sort = { createdAt: false, ...variables.sort };
    variables.search = mapPropsToSearchQuery(props);

    const QL = getQL(props.excludePageMeta);

    return (
        <QueryConnection
            fetchPolicy={props.fetchPolicy}
            query={props.query || QL}
            variables={variables}
            connectionPath={connectionPath}
            connectionConfig={connectionState}
            children={props.children}
        />
    );
};

export const useQueryWaterTestReportConnection = (props: QueryWaterTestReportConnectionExternalProps) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.sort = { createdAt: false, ...variables.sort };
    variables.search = mapPropsToSearchQuery(props);

    const QL = getQL(props.excludePageMeta);

    return useQueryConnection<NodeType.TestReport>({
        query: props.query || QL,
        pollInterval: props.pollInterval,
        skip: props.skip,
        fetchPolicy: props.fetchPolicy,
        variables: variables,
        connectionPath: props.connectionPath || connectionPath,
        connectionConfig: connectionState,
    });
};
