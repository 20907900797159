import React, { useMemo } from 'react';
import { useWorkOrderFeedActions } from '../../redux';
import { useQueryServiceJobGroupConnection } from '../../queries/use-query-service-job-group-connection';
import { ActionBarFilterByServiceJobGroup } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobGroup';
import { mapServiceJobGroupsToOptions } from '../../components/ActionBarFilters/ActionBarServiceGroups';

export const FilterByServiceJobGroup: React.FC = () => {
    const { Action, State } = useWorkOrderFeedActions();
    const { connectionData } = useQueryServiceJobGroupConnection();
    const options = useMemo(() => mapServiceJobGroupsToOptions(connectionData), [connectionData]);
    if (!connectionData.length) {
        return null;
    }

    return (
        <ActionBarFilterByServiceJobGroup
            setServiceJobGroup={Action.setServiceJobGroup}
            setServiceJobTemplate={Action.setServiceJobTemplate}
            options={options}
            value={State.filters?.serviceGroupId || State.filters?.serviceJobTemplateId}
        />
    );
};
