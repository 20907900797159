import * as React from 'react';
import { useMemo } from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { GasHeaterModel, HeatPumpModel } from '../data';
import { useQueryProductConnection } from './use-query-product-connection';
import { IProductCatalogMutators, TraitValueType } from '@poolware/app-catalog';
import { BrandingType } from './types';

export const GAS_HEATER_TAG = 'gas-heater';
export const HEATER_PUMP_TAG = 'heater';
export const HEATER_CAPACITY_TAG = 'capacity';
export const HEATPUMP_COP_TAG = 'heatpump_cop';
export const HEATER_INPUT_TAG = 'input';
export const CALC_USAGE_TAG = '__use_in_calc';
export const BRAND_AQUATIGHT_TAG = '__brand_aquatight__';

export const getUseInCalcTraitField = (product: NodeType.Product) => {
    const traits = fromEdges(product.traits);
    let valueField: NodeType.ProductTraitFlagValue = null;
    const trait = traits.find((t) => {
        valueField = t.flagValues?.find((sv) => sv.field?.tag === CALC_USAGE_TAG);
        return Boolean(valueField);
    });
    if (!trait) return null;
    return { trait, valueField };
};

export const getUseInCalcTraitDeclaration = (product: NodeType.Product) => {
    const traits = fromEdges(product?.traits);
    let fieldDeclaration: NodeType.ProductDeclarationStringField = null;
    const declaration = traits.find((t) => {
        fieldDeclaration = t?.declaration?.stringFields?.find((sv) => sv.tag === CALC_USAGE_TAG);
        return Boolean(fieldDeclaration);
    });

    if (!declaration) return null;
    return { declaration, fieldDeclaration };
};

export const isUseInCalc = (product: NodeType.Product) => {
    const t = getUseInCalcTraitField(product);
    return Boolean(t?.valueField?.value);
};

export const findByTag = (fields: any[], tagName: string) => {
    const taggedFields = fields.filter((fv) => fv?.field?.tag === tagName);
    if (taggedFields.length > 0) {
        return taggedFields[0];
    } else {
        return null;
    }
};

export const changeUseInCalcFlag = async (
    ProductCatalogMutator: IProductCatalogMutators['ProductCatalogMutator'],
    product: NodeType.Product,
    checked: boolean
) => {
    const tf = getUseInCalcTraitField(product);
    const td = getUseInCalcTraitDeclaration(product);

    const checkString = !!checked;
    if (tf) {
        // field has been created, just update it.
        await ProductCatalogMutator.updateProductTraits([
            {
                productTrait: tf?.trait,
                updateValues: [{ type: TraitValueType.Flag, valueId: tf.valueField?.id, value: checkString }],
            },
        ]);
    }
    if (!tf && td) {
        // create new trait
        await ProductCatalogMutator.updateProductTraits([
            {
                productTrait: td.declaration,
                createValues: [{ type: TraitValueType.String, fieldId: td?.fieldDeclaration?.id, value: checkString }],
            },
        ]);
    }
};

export const prepareGasHeaterModel = (product: NodeType.Product): GasHeaterModel => {
    if (!isUseInCalc(product)) {
        return null;
    }

    const heaterTraits = fromEdges(product.traits).filter((trait) => {
        return trait.declaration?.tag === GAS_HEATER_TAG;
    });

    const allFloats = heaterTraits.flatMap((t) => t.floatValues || []);
    const capacityTrait = findByTag(allFloats, HEATER_CAPACITY_TAG);
    if (!capacityTrait) {
        return null;
    }

    return {
        id: product.id,
        sku: product.sku,
        name: product.name,
        capacity: capacityTrait.value,
        product: product,
    };
};

export const prepareHeatPumpModel = (product: NodeType.Product): HeatPumpModel => {
    if (!isUseInCalc(product)) {
        return null;
    }

    const { traits = [] } = product;
    const heaterTraits = fromEdges(traits).filter(
        (trait) => trait.declaration && trait.declaration.tag === HEATER_PUMP_TAG
    );

    const allFloats = heaterTraits.flatMap((t) => t.floatValues || []);
    const capacityTrait = findByTag(allFloats, HEATER_CAPACITY_TAG);
    const inputTrait = findByTag(allFloats, HEATER_INPUT_TAG);

    if (!inputTrait || !capacityTrait) {
        return null;
    }

    const copTrait = findByTag(allFloats, HEATPUMP_COP_TAG);

    return {
        id: product.id,
        sku: product.sku,
        name: product.name,
        input: inputTrait.value,
        capacity: capacityTrait.value,
        product: product,
        cop: copTrait?.value,
    };
};

export const useQueryGasHeaterCatalog = () => {
    const c = useQueryProductConnection({
        pageSize: 100,
        flagTag: { declarationTag: GAS_HEATER_TAG, fieldTag: CALC_USAGE_TAG, value: true },
    });

    return useMemo(() => {
        let heaters = c.connectionData.map(prepareGasHeaterModel).filter(Boolean);
        if (heaters.length === 0 && !c.connectionState.loading) {
            // heaters = fallbackGasHeaterCatalog;
        }
        return {
            ...c,
            models: heaters,
        };
    }, [c]);
};

export const useQueryHeatPumpsCatalog = (brandType?: BrandingType) => {
    const c = useQueryProductConnection({
        pageSize: 100,
        flagTag: { declarationTag: HEATER_PUMP_TAG, fieldTag: CALC_USAGE_TAG, value: true },
        brandType: brandType,
    });

    return useMemo(() => {
        let heaters = c.connectionData.map(prepareHeatPumpModel).filter(Boolean);
        if (heaters.length === 0 && !c.connectionState.loading) {
            // heaters = fallbackHeatPumpCatalog;
        }
        return {
            ...c,
            models: heaters,
        };
    }, [c]);
};
