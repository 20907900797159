import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NodeType, useMutationWorkOrder } from '@poolware/api';
import { DebugJSON, DevOnly } from '@poolware/components';
import { css, styled } from 'twin.macro';
import { Portal } from 'semantic-ui-react';
import { UpdateAppointmentItemInput, useAppointmentMutators } from '../../queries/use-appointment-mutators';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import * as _ from 'lodash';
import invariant from 'invariant';
import moment from 'moment';
import { UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import gql from 'graphql-tag';
import { useApolloClient } from 'react-apollo';
import { useCalendarActions } from '../../redux';

const FloatingDockStyled = styled.div(() => [
    css`
        font-size: smaller;
        background-color: #000000;
        padding: 0.5em;
        position: fixed;
        top: 0;
        left: calc(var(--sideBar, 60px) + 2em);
        width: 500px;
        max-height: 300px;
        overflow: auto;
        z-index: 9999999;
    `,
]);

const FloatingDock: React.FC<{ open?: boolean }> = ({ open, children }) => {
    return (
        <DevOnly>
            <Portal open={open}>
                <FloatingDockStyled>{children}</FloatingDockStyled>
            </Portal>
        </DevOnly>
    );
};

// ====

export type AppointmentViewContextType = {
    refetchQueries: any[] | undefined;
    setRefetchQueries: (refetchQueries: any[] | undefined) => void;
    addRefetchQueries: (refetchQueries: any[] | undefined) => void;
    removeRefetchQueries: (refetchQueries: any[] | undefined) => void;
    appointmentItemId?: NodeType.ID | null;
    setAppointmentItemId: (id: NodeType.ID | null) => void;
    refreshId: (id: NodeType.ID) => void;
};

const AppointmentViewContext = React.createContext<AppointmentViewContextType>(null);

export const useAppointmentViewCtx = (refetchQueries?: any[], awaitRefetchQueries?: boolean) => {
    const client = useApolloClient();
    const previewItemCtx = useContext(AppointmentViewContext);
    invariant(previewItemCtx, 'useAppointmentViewCtx must be used inside <AppointmentViewContextProvider>.');

    const appointmentItemMutator = useAppointmentMutators(
        refetchQueries || previewItemCtx.refetchQueries,
        awaitRefetchQueries
    );
    const serviceJobMutator = useServiceJobMutators({
        refetchQueries: refetchQueries || previewItemCtx.refetchQueries,
        awaitRefetchQueries: awaitRefetchQueries,
    });
    const workOrderMutator = useMutationWorkOrder({
        refetchQueries: refetchQueries || previewItemCtx.refetchQueries,
        awaitRefetchQueries: awaitRefetchQueries,
    });

    const updateCache =
        (key: string) =>
        async (input: { item: NodeType.AppointmentItem; duration: number; start: Date; resourceId?: string }) => {
            const { item, start, duration, resourceId } = input;

            let staffValue = undefined;
            let updateStaff = false;

            if (resourceId) {
                updateStaff = true;
                if (resourceId === UNASSIGNED_APPOINTMENT_STAFF_ID) {
                    staffValue = null;
                } else {
                    const staffCacheId = `Staff:${resourceId}`;
                    staffValue = { id: staffCacheId, __typename: 'Staff' };
                }
            }

            // NOTE: HACK: TODO:
            // This way of cache update is more like a hack than a proper solution
            // It will probably break at some point.

            const appCacheId = `AppointmentItem:${item.id}`;

            try {
                await client.writeFragment({
                    id: appCacheId,
                    fragment: gql`
                    fragment AppointmentItemF_${key} on AppointmentItem {
                    duration
                    startDate
                    __typename
                    ${updateStaff ? 'staff' : ''}
                    }
                `,
                    data: {
                        duration: duration,
                        startDate: moment(start).toISOString(),
                        staff: staffValue,
                        __typename: 'AppointmentItem',
                    },
                });
            } catch (e) {
                console.error(e);
            }
        };

    const moveAppointment = async (conf: {
        item: NodeType.AppointmentItem;
        startDate: Date;
        duration: number;
        resourceId?: NodeType.ID;
        invalidateId?: boolean;
    }) => {
        const { item, duration, startDate, resourceId, invalidateId } = conf;
        const newItem: UpdateAppointmentItemInput = { id: item.id, startDate, duration: duration };

        if (resourceId) {
            if (resourceId === UNASSIGNED_APPOINTMENT_STAFF_ID) {
                newItem.staff = null;
            } else {
                newItem.staff = { id: resourceId };
            }
        }
        const timeDiff = Math.abs(moment(item.startDate).diff(startDate, 'minutes'));
        const durationDiff = Math.abs(item.duration - duration);
        const resourceChanged = item?.staff?.id !== newItem?.staff?.id;

        if (timeDiff < 1 && durationDiff < 1 && !resourceChanged) {
            // no changes
            return;
        }

        await updateCache('drop')({ item, duration, start: startDate, resourceId });
        const res = await appointmentItemMutator.AppointmentMutator.updateAppointmentItem(item.id, newItem);
        if (invalidateId) {
            previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
        }
        return res;
    };

    const resizeAppointment = async (conf: {
        item: NodeType.AppointmentItem;
        startDate: Date;
        duration: number;
        resourceId?: NodeType.ID;
        invalidateId?: boolean;
    }) => {
        const { item, duration, startDate, invalidateId } = conf;

        const timeDiff = Math.abs(moment(item.startDate).diff(startDate, 'minutes'));
        const durationDiff = Math.abs(item.duration - duration);
        if (timeDiff < 1 && durationDiff < 1) {
            // no changes
            return;
        }

        const newItem: UpdateAppointmentItemInput = { id: item.id, startDate, duration: duration };

        await updateCache('resize')({ item, duration, start: startDate });
        const res = await appointmentItemMutator.AppointmentMutator.updateAppointmentItem(item, newItem);
        if (invalidateId) {
            previewItemCtx.refreshId(res.data?.AppointmentItem?.AppointmentItem?.id);
        }
        return res;
    };

    return {
        ...previewItemCtx,
        appointmentItemMutator,
        serviceJobMutator,
        workOrderMutator,
        moveAppointment,
        resizeAppointment,
    };
};

export const AppointmentViewContextProvider: React.FC = ({ children }) => {
    const [refetchQueries, setRefetchQueries] = useState<any[] | undefined>(undefined);
    const [appointmentItemId, setAppointmentItemId] = useState<NodeType.ID>(null);
    const { CalendarState, CalendarAction } = useCalendarActions();

    useEffect(() => {
        if (!!CalendarState.previewApptId && CalendarState.previewApptId !== appointmentItemId) {
            setAppointmentItemId(CalendarState.previewApptId);
        }
    }, [CalendarState.previewApptId]);

    const refreshId = (newId: NodeType.ID) => {
        if (appointmentItemId && appointmentItemId !== newId) {
            // console.log(`refresh id ${appointmentItemId} => ${newId}`);
            setAppointmentItemId(newId);
        }
        if (!!CalendarState.previewApptId && CalendarState.previewApptId !== newId) {
            CalendarAction.setPreviewAppt(newId);
        }
    };

    const addRefetchQueries = (queriesToAdd: any[]) => {
        setRefetchQueries((queries) => {
            if (queries?.length > 0) {
                return _.uniq(queries.concat(queriesToAdd));
            } else {
                return _.uniq(queriesToAdd);
            }
        });
    };

    const removeRefetchQueries = (queriesToRemove: any[]) => {
        setRefetchQueries((queries) => {
            if (queries?.length > 0) {
                const res = _.difference(queries, queriesToRemove);
                return res?.length > 0 ? res : undefined;
            } else {
                return undefined;
            }
        });
    };

    const ctxValue: AppointmentViewContextType = useMemo(
        () => ({
            appointmentItemId,
            refetchQueries,
            setAppointmentItemId,
            setRefetchQueries,
            addRefetchQueries,
            removeRefetchQueries,
            refreshId,
        }),
        [appointmentItemId, refetchQueries]
    );

    const debug = false;

    return (
        <AppointmentViewContext.Provider value={ctxValue}>
            {children}
            {debug && (
                <FloatingDock open={debug}>
                    <DebugJSON
                        data={{
                            RDUX_apptPreview: CalendarState.previewApptId,
                            ...ctxValue,
                        }}
                        hideFunctionTypes={true}
                    />
                </FloatingDock>
            )}
        </AppointmentViewContext.Provider>
    );
};
