import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { MutationHookOptionsFranchiseValueStore, NodeType, useMutationFranchiseValueStore } from '@poolware/api';
import { fragmentFranchiseValueStore } from './fragments';
import { InvoiceDoc } from '../constants';

const gqlQuery = gql`
    query QueryResolveFranchiseValue($key: String!, $group: String, $franchise: ID) {
        viewer {
            resolveFranchiseValue(key: $key, group: $group, franchise: $franchise) {
                ...FranchiseValueFragment
            }
        }
    }
    ${fragmentFranchiseValueStore}
`;

const GROUP = 'invoice.defaults';
const KEY_SENDER = 'invoice.defaults.sender';
const KEY_PAYMENT_OPTION = 'invoice.defaults.paymentOption';

export const createUseQueryResolveValue = <V = any>(query: any, defaultVariables: any) => (variables?: any) => {
    const _variables = variables || defaultVariables;
    const { data, ...rest } = useQuery<NodeType.Query>(query, {
        variables: _variables,
    });
    const refetchQuery = {
        query: gqlQuery,
        variables: _variables,
    };

    let _parsedValue: Partial<V> = {};
    const value = data?.viewer?.resolveFranchiseValue?.value;

    try {
        _parsedValue = value ? JSON.parse(value) : {};
    } catch (e) {
        console.error('Error parsing value', { value: value, error: e });
    }

    return {
        ...rest,
        refetchQuery,
        data: _parsedValue,
    };
};

export const useQueryInvoiceDefaultSender = createUseQueryResolveValue<InvoiceDoc.DocContent['sender']>(gqlQuery, {
    group: GROUP,
    key: KEY_SENDER,
});
export const useQueryInvoiceDefaultPayment = createUseQueryResolveValue<InvoiceDoc.DocContent['paymentOption']>(
    gqlQuery,
    {
        group: GROUP,
        key: KEY_PAYMENT_OPTION,
    }
);

export const useMutateInvoiceDefaultSenderSet = (options?: MutationHookOptionsFranchiseValueStore) => {
    const { setOwn } = useMutationFranchiseValueStore(options);
    return (value: any) => setOwn({ key: KEY_SENDER, group: GROUP, value: JSON.stringify(value) });
};
export const useMutateInvoiceDefaultPaymentSet = (options?: MutationHookOptionsFranchiseValueStore) => {
    const { setOwn } = useMutationFranchiseValueStore(options);
    return (value: any) => setOwn({ key: KEY_PAYMENT_OPTION, group: GROUP, value: JSON.stringify(value) });
};
