import React from 'react';
import { InferProps } from 'prop-types';
import { makeDebugger } from '@ez/tools';
import Selection from '../../Selection';
import { DndContext } from './DndContext';
import { ACTION_NOTIFICATION } from '../../types';
import { DND_ACTION } from './types';

const debug = makeDebugger('rbc:dnd');

const propTypes = {};

interface State {
    event?;
}

interface EventContainerWrapperProps extends InferProps<typeof propTypes> {
    devKey?: string;
    className?: string;
}

class RootViewWrapper extends React.Component<EventContainerWrapperProps, State> {
    static propTypes = propTypes;
    static contextType = DndContext;
    declare context: React.ContextType<typeof DndContext>;

    static displayName = `DND(RootViewWrapper)`;

    private draggingOver: boolean;
    private readonly ref: React.RefObject<any>;
    private _selector: Selection;

    constructor(...args) {
        // @ts-ignore
        super(...args);
        this.state = { event: null };
        this.ref = React.createRef();
        this.draggingOver = false;
    }

    componentDidMount() {
        this._selectable();
    }

    componentWillUnmount() {
        this._teardownSelectable();
    }

    reset() {
        if (this.state.event) {
            this.setState({ event: null });
        }
    }

    _selectable = () => {
        let wrapper = this.ref.current;
        let node = wrapper.children[0];
        this._selector = new Selection(() => wrapper.closest('.rbc-time-view'), {
            useDragOverNode: () => wrapper,
            debugKey: RootViewWrapper.displayName,
        });
        let selector = this._selector;
        const devKey = this.props.devKey;

        selector.on(ACTION_NOTIFICATION.dragEnterFromOutside, (point, e) => {
            const event = this.context.draggable.dragFromOutsideItem?.();
            if (!event) {
                return null;
            }

            if (!this.draggingOver) {
                this.context.draggable.onStart();
                this.context.draggable.onBeginAction(event, DND_ACTION.DRAGOVER);
                this.draggingOver = true;
            }
        });

        selector.on(ACTION_NOTIFICATION.dragLeaveFromOutside, (e) => {
            const event = this.context.draggable.dragFromOutsideItem?.();
            if (!event) {
                return null;
            }

            debug(ACTION_NOTIFICATION.dragLeaveFromOutside, devKey);
            this.context.draggable.onEnd(null);
            this.draggingOver = false;
            this.reset();
        });

        selector.on(ACTION_NOTIFICATION.dropFromOutside, (e) => {
            const event = this.context.draggable.dragFromOutsideItem?.();
            if (!event) {
                return null;
            }

            debug(ACTION_NOTIFICATION.dropFromOutside, devKey);
            this.context.draggable.onEnd(null);
            this.draggingOver = false;
            this.reset();
        });

        selector.on(ACTION_NOTIFICATION.reset, () => {
            this.reset();
        });
    };

    _teardownSelectable = () => {
        if (!this._selector) return;
        this._selector.teardown();
        this._selector = null;
    };

    defaultOnDragOver = (event) => {
        event.preventDefault();
    };

    render() {
        return (
            <div className={this.props.className} ref={this.ref} onDragOver={this.defaultOnDragOver}>
                {this.props.children}
            </div>
        );
    }
}

export default RootViewWrapper;
