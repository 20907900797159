import * as React from 'react';
import { useMemo } from 'react';
import { useWindowSize } from '@ez/tools';
import tw, { styled } from 'twin.macro';
import { MenuBar, SlideInDock } from '@poolware/components';
import { useAppLayoutMode } from '../Viewer';

export const MenuBarStyled = styled(MenuBar)`
    &&& {
        ${tw`border-0 rounded-t m-0 rounded-b-none`}
    }
`;

const ContainerGrid = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    grid-template-rows: auto;
    ${tw`gap-4 rounded`}
    @media print {
        display: block;
    }
`;

export const PAGE_BOTTOM_OFFSET_GRID = 76;
const TEMPLATE_M = 'minmax(8em, 1fr)';
const TEMPLATE_L_M = '260px minmax(8em, 1fr)';
const TEMPLATE_L_M_R_WIDE = '260px minmax(8em, 1fr) 36em';
const TEMPLATE_L_M_R_MOB = '260px minmax(8em, 1fr) 28em';
const TEMPLATE_M_R_WIDE = 'minmax(8em, 1fr) 36em';
const TEMPLATE_M_R_MOB = 'minmax(8em, 1fr) 28em';

export const PageLayoutManager: React.FC<{
    main: React.ReactNode;
    showLeft?: boolean;
    leftSidebar?: React.ReactNode;
    showRight?: boolean;
    rightSidebar?: React.ReactNode;
    onCloseLeft?: () => any;
    onCloseRight?: () => any;
    animateSlide?: boolean;
}> = ({
    main,
    leftSidebar,
    rightSidebar,
    showRight = false,
    showLeft,
    onCloseLeft,
    onCloseRight,
    animateSlide = true,
}) => {
    const { innerHeight } = useWindowSize();
    const appLayoutMode = useAppLayoutMode();
    const height = innerHeight - PAGE_BOTTOM_OFFSET_GRID;

    let _layoutColumns = 1;
    if (leftSidebar && main && rightSidebar) {
        _layoutColumns = appLayoutMode.gteDesktopSM ? 3 : appLayoutMode.gteTablet ? 2 : 1;
    } else if (leftSidebar && main) {
        _layoutColumns = appLayoutMode.gteTablet ? 3 : 1;
    } else if (rightSidebar && main) {
        _layoutColumns = appLayoutMode.gteTablet ? 3 : 1;
    } else {
        _layoutColumns = 1;
    }

    let gridStyle = useMemo(() => {
        let gridTemplateColumns = TEMPLATE_M;
        if (_layoutColumns === 3) {
            if (showLeft && showRight) {
                gridTemplateColumns = appLayoutMode.gteDesktopMD ? TEMPLATE_L_M_R_WIDE : TEMPLATE_L_M_R_MOB;
            } else if (showLeft) {
                gridTemplateColumns = TEMPLATE_L_M;
            } else if (showRight) {
                gridTemplateColumns = appLayoutMode.gteDesktopMD ? TEMPLATE_M_R_WIDE : TEMPLATE_M_R_MOB;
            } else {
                gridTemplateColumns = TEMPLATE_M;
            }

            return { gridTemplateColumns, height: height };
        } else if (_layoutColumns === 2) {
            if (showRight) {
                gridTemplateColumns = TEMPLATE_M_R_MOB;
            } else {
                gridTemplateColumns = TEMPLATE_M;
            }

            return { gridTemplateColumns, height: height };
        } else {
            return { gridTemplateColumns, height: '100%' };
        }
    }, [_layoutColumns, appLayoutMode, showLeft, showRight, height]);

    if (_layoutColumns === 3) {
        return (
            <ContainerGrid style={gridStyle}>
                {showLeft && <>{leftSidebar}</>}
                {main}
                {showRight && <>{rightSidebar}</>}
            </ContainerGrid>
        );
    } else if (_layoutColumns === 2) {
        return (
            <>
                <SlideInDock onClose={onCloseLeft} open={showLeft} direction={'left'} animateSlide={animateSlide}>
                    {leftSidebar}
                </SlideInDock>
                <ContainerGrid style={gridStyle}>
                    {main}
                    {showRight && <>{rightSidebar}</>}
                </ContainerGrid>
            </>
        );
    } else {
        return (
            <>
                <SlideInDock onClose={onCloseLeft} open={!!showLeft} direction={'left'} animateSlide={animateSlide}>
                    {leftSidebar}
                </SlideInDock>
                <ContainerGrid style={gridStyle}>{main}</ContainerGrid>
                <SlideInDock onClose={onCloseRight} open={!!showRight} direction={'right'} animateSlide={animateSlide}>
                    {rightSidebar}
                </SlideInDock>
            </>
        );
    }
};
