import * as React from 'react';
import { useMemo } from 'react';
import { FormikDatePickerInputField, FormikDefaultForm, FormikInputField, toastError } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { Message } from 'semantic-ui-react';
import moment from 'moment';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    endsAt: Yup.mixed().required('Required').nullable(),
});

export interface PanelStopServiceProps {
    serviceJob: NodeType.ServiceJob;
    serviceJobMutators: ReturnType<typeof useServiceJobMutators>;
    onDone: () => any;
}

export const PanelStopService: React.FC<PanelStopServiceProps> = ({ serviceJob, serviceJobMutators, onDone }) => {
    const initialValues = {
        ...serviceJob,
        endsAt: new Date(),
    };

    const startDate = useMemo(() => moment().startOf('d').toDate(), []);

    const onSubmit = async (values: typeof initialValues) => {
        try {
            await serviceJobMutators.setStopDate({
                id: serviceJob.id,
                date: moment(values.endsAt).startOf('day').toDate(),
            });
            onDone?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            debug={true}
            validationSchema={validationSchema}
            header={'Stop Recurring Service'}
            initialValues={initialValues}
            submitButton={{ content: 'Stop' }}
            onSubmit={onSubmit}
            onCancel={onDone}
        >
            <FormikInputField readOnly label={'Recurring Service'} name={'title'} />
            <FormikDatePickerInputField
                isClearable={true}
                monthsShown={2}
                name={'endsAt'}
                label={'Stop from date'}
                minDate={startDate}
                required={true}
            />
            <Message info={true}>
                <p>If you proceed the following will happen:</p>
                <ul>
                    <li>
                        Appointments starting or after the <b>Stop Date</b> will be removed.
                    </li>
                    <li>
                        Appointments prior to the <b>Stop Date</b> will not be removed.
                    </li>
                    <li>
                        <ul>
                            <li>
                                Exception: Appointment that were modified by the user will not be removed, regardless of
                                their date.
                            </li>
                        </ul>
                    </li>
                    <li>If you select today's date, the service will stopped immidataly.</li>
                </ul>
            </Message>
        </FormikDefaultForm>
    );
};
