import * as React from 'react';
import { Button, Checkbox, Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { fromEdges } from '@poolware/api';
import PaginationButtons from './PaginationButtons';

const TableButtons = ({ onCancel, onSubmit, submitButtonTitle, submitButtonDisabled, isSubmitting }) => (
    <Segment basic clearing>
        <Button type="button" onClick={onCancel} content={'Cancel'} disabled={isSubmitting} />

        <Button
            type="button"
            name="submitButton"
            floated="right"
            primary
            disabled={isSubmitting || submitButtonDisabled}
            loading={isSubmitting}
            onClick={onSubmit}
            content={submitButtonTitle || 'Submit'}
        />
    </Segment>
);

export interface PickerTableProps<T = any> {
    selectedId?: string;
    onCancel?: () => any;
    onSubmit?: (item: T) => any;

    items: T[];
    cellRenderer: (item: T) => any;
    cellRendererSecondary?: (item: T) => any;

    noContentRenderer?: any;

    isLoadingPage?: boolean;
    canPaginate?: boolean;
    currentPage?: number;
    pageCount?: number;
    setPage?: (number) => any;

    // sortKey: any
    // sortDirection: any
    // setSort: any
}

export default class PickerTable extends React.Component<PickerTableProps, any> {
    constructor(props: PickerTableProps) {
        super(props);
        this.state = {
            selectedItem: props.selectedId ? { id: props.selectedId, name: '' } : null,
            numberOfPlaceholderRows: 12,
            isSubmitting: false,
        };
    }
    isUnmounted = false;

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    registerCheckboxChange = (staff) => (event, data) => {
        event.preventDefault();
        this.handleChange(staff);
    };

    handleChange = (selectedItem) => {
        if (this.state.selectedItem && selectedItem.id === this.state.selectedItem.id) {
            this.setState({ selectedItem: null });
        } else {
            this.setState({ selectedItem: selectedItem });
        }
        this.forceUpdate();
    };

    onSubmit = async () => {
        if (!this.props.onSubmit) {
            return;
        }

        let { selectedItem } = this.state;

        this.setState({ isSubmitting: true });
        await this.props.onSubmit(selectedItem);
        if (!this.isUnmounted) {
            this.setState({ isSubmitting: false });
        }
    };

    onCancel = () => {
        if (!this.props.onCancel) {
            return;
        }

        this.props.onCancel();
    };

    UNSAFE_componentWillReceiveProps(newProps, nextContext) {
        const { items, isLoadingPage } = newProps;

        if (!isLoadingPage) {
            let deEdgedItemsLength = fromEdges(items).length;
            if (deEdgedItemsLength !== this.state.numberOfPlaceholderRows) {
                this.setState({ numberOfPlaceholderRows: deEdgedItemsLength });
            }
        }
    }

    renderTable() {
        let { selectedItem, numberOfPlaceholderRows } = this.state;

        const {
            items,

            isLoadingPage,
            canPaginate,
            currentPage,
            pageCount,
            setPage,

            // sortKey,
            // sortDirection,
            // setSort,

            noContentRenderer: NoContent,
        } = this.props;

        let deEdgedItems = fromEdges(items);
        if (deEdgedItems.length === 0 && isLoadingPage) {
            deEdgedItems = Array.from(Array(numberOfPlaceholderRows)).map((x, i) => ({ id: i, user: {} }));
        }

        return (
            <Dimmer.Dimmable dimmed={isLoadingPage}>
                <Dimmer inverted active={isLoadingPage}>
                    <Loader inverted content={'Loading'} />
                </Dimmer>

                {deEdgedItems.length === 0 && NoContent && <NoContent />}

                {deEdgedItems.length === 0 && !NoContent && <span>No item</span>}

                {deEdgedItems.length > 0 && (
                    <Table celled unstackable={true} compact="very">
                        <Table.Body>
                            {deEdgedItems.map((item) => {
                                const isSelected = !!selectedItem && selectedItem.id === item.id;
                                const rowStyle = isSelected ? { backgroundColor: '#ddeaea' } : {};

                                return (
                                    <Table.Row key={item.id} style={rowStyle}>
                                        <Table.Cell width={1} textAlign="center">
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={this.registerCheckboxChange(item)}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Link
                                                to="#"
                                                onClick={(e) => this.registerCheckboxChange(item)(e, { checked: true })}
                                            >
                                                {this.props.cellRenderer(item)}
                                            </Link>
                                        </Table.Cell>
                                        {this.props.cellRendererSecondary && (
                                            <Table.Cell>
                                                <Link
                                                    to="#"
                                                    onClick={(e) =>
                                                        this.registerCheckboxChange(item)(e, { checked: true })
                                                    }
                                                >
                                                    {this.props.cellRendererSecondary(item)}
                                                </Link>
                                            </Table.Cell>
                                        )}
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                        {canPaginate && (
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={3}>
                                        <PaginationButtons
                                            setPage={setPage}
                                            pageCount={pageCount}
                                            currentPage={currentPage}
                                            isLoadingPage={isLoadingPage}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        )}
                    </Table>
                )}
            </Dimmer.Dimmable>
        );
    }

    render() {
        const { isSubmitting } = this.state;
        return (
            <>
                {this.renderTable()}
                <TableButtons
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                    submitButtonDisabled={this.state.selectedItem === null}
                    submitButtonTitle="Select"
                    isSubmitting={isSubmitting}
                />
            </>
        );
    }
}
