import * as React from 'react';
import { Search } from './index';
import { PageContainer } from '../../Layout/PageContainer';

export const Router = () => {
    return (
        <PageContainer pageWidth={'normal'}>
            <Search />
        </PageContainer>
    );
};
