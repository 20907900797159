import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { fromEdges, JobTodoItemStatus, NodeType, useMutationJobTodo, useMutationJobTodoItem } from '@poolware/api';
import { ConnectionErrorMessage, Display, LinkButton, PageSkeletonLoader, toastError } from '@poolware/components';
import { Button, ButtonProps, Icon } from 'semantic-ui-react';
import { useQueryJobTodo } from '../../../queries/use-query-job-todo';
import { cn, useSafeState } from '@ez/tools';

interface JobItemCompleteButtonProps {
    job: NodeType.JobTodo;
    status: number;
    onStatusChange: (newStatus: number) => any;
}

export const StatusButton: React.FC<JobItemCompleteButtonProps> = ({ onStatusChange, status }) => {
    const [isUpdating, setUpdating] = useSafeState(-1);

    const onButtonClick = (buttonType) => async () => {
        setUpdating(buttonType);
        try {
            const isCompleted = status === buttonType;
            const newStatus = isCompleted ? 0 : buttonType; // toggle value
            await onStatusChange(newStatus);
        } catch (e) {
            console.error(e);
        }
        setUpdating(-1);
    };

    const disabled = isUpdating !== -1;
    const buttonPropsNG: ButtonProps = {
        basic: status !== JobTodoItemStatus.NG,
        size: 'mini',
        disabled: disabled,
        color: status === JobTodoItemStatus.NG ? 'red' : 'grey',
        loading: isUpdating === JobTodoItemStatus.NG,
        icon: 'cancel',
        onClick: onButtonClick(JobTodoItemStatus.NG),
    };

    const buttonPropsOK: ButtonProps = {
        size: 'mini',
        basic: status !== JobTodoItemStatus.OK,
        disabled: disabled,
        color: status === JobTodoItemStatus.OK ? 'green' : 'grey',
        loading: isUpdating === JobTodoItemStatus.OK,
        icon: 'checkmark',
        onClick: onButtonClick(JobTodoItemStatus.OK),
    };

    return (
        <Button.Group floated="right" size={'mini'}>
            <Button {...buttonPropsNG} />
            <Button {...buttonPropsOK} />
        </Button.Group>
    );
};

interface JobTodoProps {
    jobTodo: NodeType.JobTodo;
    onJobDidChange?: (notification: { id: NodeType.ID; status: number }) => any;
}
const getItemIcon = (completed: boolean) => {
    return completed ? 'check circle' : 'circle outline';
};
export const JobTodo: React.FC<JobTodoProps> = ({ jobTodo: jt, onJobDidChange }) => {
    const expandable = true;
    const displayLimit = 16;
    const [expanded, setExpanded] = useState(!expandable);
    const { loading, error, refetchQuery, node: jobTodo } = useQueryJobTodo(jt.id);
    const jobTodoItemMutator = useMutationJobTodoItem({ refetchQueries: [refetchQuery], awaitRefetchQueries: true });
    const jobTodoMutator = useMutationJobTodo({ refetchQueries: [refetchQuery], awaitRefetchQueries: true });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!jobTodo) {
        return <>--</>;
    }

    const items = fromEdges(jobTodo.items).sort((l, r) => {
        return l.orderIndex > r.orderIndex ? 1 : -1;
    });

    const totalCount = items.length;
    const showExpandButton = expandable && totalCount > displayLimit;
    const hiddenCount = totalCount - displayLimit;
    const itemsToDisplay = expandable ? items.slice(0, expanded ? totalCount : displayLimit) : items;

    const toggle = () => setExpanded(!expanded);
    const onJobTodoItemStatusChange = (item: NodeType.JobTodoItem) => async (newStatus: number) => {
        try {
            const itemUpdate = {
                id: item.id,
                status: newStatus,
            };

            await jobTodoItemMutator.update(itemUpdate);
            window.setTimeout(async () => await onJobDidChange?.(itemUpdate), 0);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change status', description: e.message });
        }
    };
    const onJobTodoStatusChange = async (newStatus: number) => {
        try {
            const itemUpdate = {
                id: jobTodo.id,
                status: newStatus,
            };

            await jobTodoMutator.update(itemUpdate);
            await onJobDidChange?.(itemUpdate);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change status', description: e.message });
        }
    };

    //
    // Note:
    // If JobTodo does not have any JobTodoItems, then JobTodo itself becomes an actionable item
    //
    const jobTodoIsActionable = totalCount == 0;
    const jobTodoIsCompleted = jobTodo.status != 0;
    const jobTodoIconName = 'clipboard list';

    const jobTodoCheckIconName = getItemIcon(jobTodoIsCompleted);

    return (
        <div className={'jobtodo'}>
            <div className={'jobtodo-title'}>
                <div
                    className={cn('item-row', {
                        actionable: jobTodoIsActionable,
                        completed: jobTodoIsCompleted,
                    })}
                >
                    {jobTodoIsActionable ? (
                        <>
                            <div className={'item-icon-lvl1'}>
                                <Icon name={jobTodoIconName} />
                            </div>
                            <div className={'item-icon-lvl2'}>
                                <Icon name={jobTodoCheckIconName} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={'item-icon-lvl1'}>
                                <Icon name={jobTodoIconName} />
                            </div>
                        </>
                    )}
                    <div className={'item-title'}>{`${jobTodo.title}`}</div>

                    {jobTodoIsActionable && (
                        <div className={'item-button'}>
                            <StatusButton
                                job={jobTodo}
                                status={jobTodo.status}
                                onStatusChange={onJobTodoStatusChange}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={'jobtodo-content'}>
                {jobTodo.description && (
                    <div className={'jobtodo-description'}>
                        <Display.Text value={jobTodo.description} />
                    </div>
                )}
                {itemsToDisplay.map((item) => {
                    const isCompleted = item.status !== 0;
                    const iconName = getItemIcon(isCompleted);
                    const className = cn('item-row actionable jobtodo-item', { completed: isCompleted });
                    return (
                        <div key={item.id} className={className}>
                            <div className={'item-icon-lvl2'}>
                                <Icon name={iconName} />
                            </div>
                            <div className={'item-title'}>{item.title}</div>
                            <div className={'item-button'}>
                                <StatusButton
                                    job={jobTodo}
                                    status={item.status}
                                    onStatusChange={onJobTodoItemStatusChange(item)}
                                />
                            </div>
                        </div>
                    );
                })}
                {showExpandButton && !expanded && (
                    <div className={'item-row '}>
                        <LinkButton
                            onClick={() => toggle()}
                            content={expanded ? 'Show Less' : `Show More (${hiddenCount})`}
                        />
                    </div>
                )}
            </div>
            {(jobTodo.price > 0 || jobTodo.timeEstimate > 0) && (
                <div className={'summary-row'}>
                    {jobTodo.price > 0 && <div className={'summary-row-item'}>{`$ ${jobTodo.price}.00`}</div>}
                    {jobTodo.timeEstimate > 0 && (
                        <div className={'summary-row-item'}>{`${jobTodo.timeEstimate} min`}</div>
                    )}
                </div>
            )}
        </div>
    );
};
