import * as React from 'react';
import ApolloClient from 'apollo-client';
import { ApolloProvider } from 'react-apollo';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getAppConfig } from './app-config';
import fetch from 'unfetch';

const config = getAppConfig();

export const client = new ApolloClient({
    link: createHttpLink({
        fetch: fetch as any,
        uri: config.api.graphqlAPIURL,
        credentials: 'include',
        // mode: 'cors',
    }),
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

export const ApolloClientProvider: React.FC<{ client?: ApolloClient<any> }> = (props) => (
    <ApolloProvider client={props.client || client}>{props.children}</ApolloProvider>
);
