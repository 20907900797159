import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Manager, Popper, Reference } from 'react-popper';
import { NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { FormLabel, HStack, IconButton } from '@poolware/components';
import { Portal } from 'semantic-ui-react';
import { TraitDeclarationPicker_Tree } from './TraitDeclarationPicker_Tree';

const CategoryInputContainer = styled.div`
    border-radius: 4px;
    border: 1px solid #dededf;
    background-color: #ffffff;

    margin: 0;
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    font-size: 1em;

    height: 38px;

    //padding: 0.5em 1em;
    //font-size: 1em;
    //line-height: 1.4285em;
    //

    cursor: pointer;
    &:hover {
        border: 1px solid #b6b6b7;
    }
    outline: none;
    &:focus {
        box-shadow: 0 0 0 1pt #2684ff;
    }
`;

const PopperBox = styled.div`
    max-height: 400px;
    max-width: 95%;
    overflow-y: auto;
    font-size: 0.95em;
    line-height: 1.4em;
    background-color: #ffffff;
    border: 1px solid #7a7a7a;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 1em;
    z-index: 10000;
    left: -120px;
`;

const PopperContainer: React.FC = ({ children }) => {
    const setWidth = {
        enabled: true,
        order: 840,
        fn: (data) => {
            data.offsets.popper.left = data.offsets.reference.left;
            data.offsets.popper.right = data.offsets.reference.right;
            data.offsets.popper.width = data.styles.width = Math.round(data.offsets.reference.width);
            data.offsets.popper.top = data.offsets.popper.top + 5;
            return data;
        },
    };

    return (
        <Popper placement="bottom-start" modifiers={[{ name: 'setWidth', ...setWidth }]}>
            {({ ref, style, placement }) => {
                return (
                    <PopperBox ref={ref} style={style as any} data-placement={placement}>
                        {children}
                    </PopperBox>
                );
            }}
        </Popper>
    );
};

export const TraitDeclarationPickerSelect: React.FC<{
    value?: NodeType.ProductTraitDeclaration;
    label: React.ReactNode | string;
    rootTag: string | null;
    onChange: (item: NodeType.ProductTraitDeclaration | null) => any;
}> = ({ value, label, onChange, rootTag }) => {
    const [expanded, setExpanded] = useState(false);

    const hasValue = !!value;

    const formatTraitLabel = (trait: NodeType.ProductTraitDeclaration) => {
        const parentName = _.get(trait, 'parent.name', '');
        const name = _.get(trait, 'name', '');
        if (parentName) {
            return `${parentName} / ${name}`;
        } else {
            return name;
        }
    };

    const handleOnClear = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        onChange(null);
        setExpanded(false);
    };

    const onItemSelect = (node) => {
        setExpanded(false);
        onChange(node);
    };

    const toggle = (e) => {
        setExpanded(!expanded);
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    };

    const categoryLabel = hasValue ? formatTraitLabel(value) : '---';

    const inputContainerProps = {
        onClick: toggle,
        onKeyPress: toggle,
    };

    return (
        <div className={'ui field'}>
            <Manager>
                <Reference>
                    {({ ref }) => {
                        return (
                            <>
                                <FormLabel>{label}</FormLabel>
                                <CategoryInputContainer ref={ref} tabIndex={0} {...inputContainerProps}>
                                    <HStack>
                                        <div>{categoryLabel}</div>
                                        <div style={{ flexGrow: 0 }}>
                                            {hasValue && (
                                                <IconButton name={'cancel'} color={'grey'} onClick={handleOnClear} />
                                            )}
                                            <IconButton
                                                color={'grey'}
                                                name={expanded ? 'chevron up' : 'chevron down'}
                                                onClick={toggle}
                                            />
                                        </div>
                                    </HStack>
                                </CategoryInputContainer>
                                {/*{JSON.stringify(current)}*/}
                            </>
                        );
                    }}
                </Reference>
                <Portal open={expanded}>
                    <PopperContainer>
                        <TraitDeclarationPicker_Tree current={[value]} onSelect={onItemSelect} rootTag={rootTag} />
                    </PopperContainer>
                </Portal>
            </Manager>
        </div>
    );
};
