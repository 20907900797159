import * as React from 'react';
import { AppointmentReportsActionProps, ApptStatusFilterItem, withAppointmentReportsActions } from '../../../redux';
import _findIndex from 'lodash/findIndex';
import { ActionBar } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { getIconNameForAppointmentStatus, getLabelForAppointmentStatus } from '@poolware/app-service-jobs';

const mapStatusToFilterItem = (status: NodeType.AppointmentStatusEnum) => {
    return {
        token: status,
        displayString: getLabelForAppointmentStatus(status),
        icon: getIconNameForAppointmentStatus(status),
    };
};
const defaultStatuses: ApptStatusFilterItem[] = [
    mapStatusToFilterItem(NodeType.AppointmentStatusEnum.NotStarted),
    mapStatusToFilterItem(NodeType.AppointmentStatusEnum.Finished),
    mapStatusToFilterItem(NodeType.AppointmentStatusEnum.Cancelled),
];

interface AppointmentStatusFilterProps {
    statuses?: ApptStatusFilterItem[];
    includeStatuses: any[];
    setAppointmentStatusFilter: (val: { includeStatuses: ApptStatusFilterItem[] }) => any;
}

const AppointmentStatusFilter: React.FC<AppointmentStatusFilterProps> = (props) => {
    const { statuses = defaultStatuses, includeStatuses = [], setAppointmentStatusFilter } = props;

    const onFilterChange = (status: ApptStatusFilterItem, checked: boolean) => {
        // Check whether this `status` is in the inclusion list
        const isInFilterArray = includeStatuses.findIndex((token) => token === status.token);

        if (checked) {
            // if status is checked, then add it to exclusion list.
            if (isInFilterArray < 0) {
                // it is not in the list, adding it.
                const newFilters = [...includeStatuses, status.token];
                setAppointmentStatusFilter({ includeStatuses: newFilters });
            }
        } else {
            // if status is unchecked, then remove it from the inclusion list.
            if (isInFilterArray >= 0) {
                // it is already in the list, removing it
                let a = [...includeStatuses];
                a.splice(isInFilterArray, 1);
                setAppointmentStatusFilter({ includeStatuses: a });
            }
        }
    };

    const onShowAll = (checked: boolean) => {
        if (checked) {
            setAppointmentStatusFilter({ includeStatuses: [] });
        }
    };

    return (
        <ActionBar>
            <ActionBar.Header content={'Filter by Status'} icon={'filter'} />
            <ActionBar.ItemCheckbox
                dividing={true}
                checked={includeStatuses.length === 0}
                onChange={(checked) => onShowAll(checked)}
                content={'All'}
            />
            {statuses.map((status) => {
                const isChecked = _findIndex(includeStatuses, (s) => s === status.token) >= 0;
                return (
                    <ActionBar.ItemCheckbox
                        key={status.token}
                        checked={isChecked}
                        onChange={(checked) => onFilterChange(status, checked)}
                        icon={status.icon}
                        content={status.displayString}
                    />
                );
            })}
        </ActionBar>
    );
};

const AppointmentStatusFilterWrapper: React.FC<AppointmentReportsActionProps> = (props) => {
    const { includeStatuses = [] } = props.AppointmentReportsState.filters;
    return (
        <AppointmentStatusFilter
            includeStatuses={includeStatuses}
            setAppointmentStatusFilter={({ includeStatuses }) =>
                props.AppointmentReportsAction.setAppointmentStatusFilter(includeStatuses)
            }
        />
    );
};

export default withAppointmentReportsActions()(AppointmentStatusFilterWrapper);
