import { CalcDocType, CostEstimation, CostEstimationModel, ResourcePriceUnit } from './types';
import { HeatPumpModel } from '../data';

export const getRecommendationSummary = (input: { costEstimation: CostEstimation; type: CalcDocType }) => {
    const getSummary = (input: { costEstimation: CostEstimationModel<HeatPumpModel> }) => {
        const costEstimation = input?.costEstimation;
        const recommendedModel = input?.costEstimation?.recommendedModel;
        if (!costEstimation || !recommendedModel) {
            return null;
        }

        let resourcePrice = null;
        switch (costEstimation.resourcePriceUnit) {
            case ResourcePriceUnit.DollarsPerKW:
                resourcePrice = `${costEstimation.resourcePrice} $/KW`;
                break;
            case ResourcePriceUnit.CentsPerMJ:
                resourcePrice = `${costEstimation.resourcePrice} Cents/MJ`;
                break;
        }

        return {
            model: recommendedModel,
            operatingHours: costEstimation.operatingHours,
            minimumOutput: costEstimation.minimumOutput,
            numberOfUnits: costEstimation.numberOfUnits,
            costYearly: costEstimation?.costYearly?.toFixed(2),
            brochure: recommendedModel?.product?.brochure,
            resourcePrice: resourcePrice,
        };
    };

    switch (input.type) {
        case CalcDocType.HeatPumpAutopool:
        case CalcDocType.HeatPump:
            return getSummary({ costEstimation: input.costEstimation?.hpump });
        case CalcDocType.GasHeater:
            return getSummary({ costEstimation: input.costEstimation?.gas });
    }
    return null;
};
