import { useAppBreakpoints } from '@poolware/components';
import * as React from 'react';
import { styled } from 'twin.macro';

const ContainerGridDiv = styled.div<{ templateColumns?: string }>`
    //height: calc(100% - var(--topBar, 160px) - 20px);
    position: fixed;
    left: var(--sideBar, 160px);
    top: var(--topBar, 160px);
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: ${(props) => props.templateColumns || 'minmax(0, 300px) 1fr'};
    grid-template-areas: 'sidebar main';
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    grid-template-rows: auto;
    //background-color: red;
    @media print {
        grid-template-columns: minmax(0, 1fr);
        grid-template-areas: 'main';
    }
`;

const GridAreaSidebarDiv = styled.div`
    grid-area: sidebar;
    overflow-y: auto;
    padding: 0.5rem;
    @media print {
        display: none;
    }
`;
const GridAreaMainDiv = styled.div`
    overflow-y: auto;
    grid-area: main;
    padding: 0.5rem;
`;

export const SideBarLayout: React.FC<{
    leftSidebar?: React.ReactNode;
    leftOnClose?: () => any;
    main: React.ReactNode;
}> = ({ leftSidebar, main }) => {
    return (
        <ContainerGridDiv>
            <GridAreaSidebarDiv>{leftSidebar}</GridAreaSidebarDiv>
            <GridAreaMainDiv>{main}</GridAreaMainDiv>
        </ContainerGridDiv>
    );
};
