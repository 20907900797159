import * as React from 'react';
import { useMemo } from 'react';
import { BlockGroupType } from './types';
import { NodeType, useMutationAppointment } from '@poolware/api';
import { max, parseISO, startOfDay } from 'date-fns';
import {
    confirmModalImperative,
    ConfirmProps,
    FormikDatePickerInputField,
    FormikDefaultForm,
    FormikRadioField,
    Optionable,
    toastError,
} from '@poolware/components';
import { Message } from 'semantic-ui-react';
import * as Yup from 'yup';
import { FormikRecurAppointmentSplitter } from '../ReplaceTemplateWizard/FormikRecurAppointmentSplitter';

enum DateSelectMode {
    APPOINTMENT = 1,
    DATE = 2,
}

const options: Optionable<number>[] = [
    { text: 'Last Appointment', value: DateSelectMode.APPOINTMENT },
    { text: 'Last Date', value: DateSelectMode.DATE },
];

const validationSchema = Yup.object().shape({
    dateMode: Yup.number().required(),
    endsFrom: Yup.date()
        .when('dateMode', {
            is: (dateMode) => dateMode === DateSelectMode.DATE,
            then: Yup.date().required('Required').nullable(),
            otherwise: Yup.date().notRequired().nullable(),
        })
        .nullable(),
    cancelFromAppointmentItem: Yup.object().when('dateMode', {
        is: (dateMode) => dateMode === DateSelectMode.APPOINTMENT,
        then: Yup.object().required('Required').nullable(),
        otherwise: Yup.object().notRequired().nullable(),
    }),
});

let changeEndDateConfirm: ConfirmProps = {
    confirmMessage: {
        header: 'Change end date?',
        content: 'If you proceed, the end date will be changed. There is NO UNDO!',
    },
    negative: true,
    confirmButton: { content: 'Change End Date' },
    cancelButton: { content: 'Cancel' },
};

export const FormChangeEndDate: React.FC<{
    block: BlockGroupType;
    onDone;
    onCancel;
    refetchQueries;
    serviceJob: NodeType.ServiceJob;
}> = ({ block, onDone, onCancel, serviceJob, refetchQueries }) => {
    const { changeBlockEndDate } = useMutationAppointment();

    const minDate = useMemo(() => startOfDay(new Date()), []);

    const initialValues = {
        dateMode: DateSelectMode.APPOINTMENT,
        endsFrom: block.end,
        cancelFromAppointmentItem: null as NodeType.AppointmentItem,
    };

    const onSubmit = async (values: typeof initialValues) => {
        const isYes = await confirmModalImperative(changeEndDateConfirm);
        if (!isYes) return;
        try {
            const formEndDate =
                values.dateMode === DateSelectMode.DATE
                    ? values.endsFrom
                    : parseISO(values.cancelFromAppointmentItem?.startDate);
            const endDate = max([formEndDate, startOfDay(new Date()), startOfDay(block.start)]);

            // console.log(endDate);

            await changeBlockEndDate(
                { patternBlockId: block.patternBlockId, endDate: endDate },
                { refetchQueries: refetchQueries }
            );
            onDone?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            debug={true}
            header={'Change End Date'}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitButton={{ content: 'Change End Date' }}
        >
            {({ values }) => {
                const mode = values.dateMode;
                return (
                    <>
                        <FormikRadioField name={'dateMode'} options={options} inline={true} />
                        {mode == 1 ? (
                            <FormikRecurAppointmentSplitter
                                patternBlockId={block?.patternBlockId}
                                label={'Stop From Appointment'}
                                mode={'cancellation'}
                                headerLeft={serviceJob.title}
                                headerRight={'Cancel'}
                                name={'cancelFromAppointmentItem'}
                                serviceJob={serviceJob}
                            />
                        ) : (
                            <FormikDatePickerInputField
                                monthsShown={2}
                                minDate={minDate}
                                isClearable={false}
                                shouldCloseOnSelect={false}
                                todayButton="Today"
                                label={'Stop Schedule From Date'}
                                name={'endsFrom'}
                                required={true}
                            />
                        )}
                        <Message info={true}>
                            <p>If you proceed the following will happen:</p>
                            <ul>
                                <li>
                                    Appointments that start or after the <b>Stop Date</b> will be removed.
                                    <br /> Except:
                                    <ul>
                                        <li>
                                            Appointments that were modified by the user will not be removed, regardless
                                            of their date.
                                        </li>
                                    </ul>
                                </li>
                                <li>Appointments that do not belong to this Service Schedule will not be removed.</li>
                                <li>
                                    Appointments prior to the <b>Stop Date</b> will not be removed.
                                </li>
                            </ul>
                        </Message>
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};
