import * as React from 'react';
import { Button, Segment } from 'semantic-ui-react';
import { useRecurServiceJobListActions } from '../../redux/reducer-recur-service-job-list';

export const ClearAllFilters = () => {
    const { Actions } = useRecurServiceJobListActions();

    const hasActiveFilters = false;
    return (
        <Segment basic style={{ padding: '0.25em' }}>
            <Button
                // disabled={!hasActiveFilters}
                basic
                size={'tiny'}
                icon={'close'}
                color={hasActiveFilters ? 'purple' : 'grey'}
                onClick={Actions.clearAllFilters}
                content={'Clear all filters'}
                fluid
                compact
            />
        </Segment>
    );
};
