import { bindActionCreators, combineReducers } from 'redux';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Action, createAction, createAutoBoundReducerActions, createReducer } from '@ez/tools';
import {
    NodeType,
    createPageIndexReducerActions,
    defaultConnectionPageState,
    ConnectionPageStateType,
} from '@poolware/api';
import * as _ from 'lodash';
import { WorkOrderBookingStatus, WorkOrderRecurStatus } from './reducer-work-order-list';
import { DateRange } from '../constants';
import invariant from 'invariant';
import { CalViewMode } from '../Scheduler/types';
import { computeDateRange, sanitizeViewMode } from './helpers';
import moment from 'moment';
import { createStructuredSelector } from 'reselect';
import { useMemo } from 'react';

export type WorkOrderFeedStateType = {
    filters: {
        franchise: { id?: string; name?: string };
        staffIds: string[];
        serviceStageId: string;
        serviceStageType: NodeType.ServiceJobStageType[];
        serviceStageStatus: NodeType.ServiceJobStageStatus;
        serviceGroupId: string;
        serviceJobTemplateId: string;
        woNumber: string;
        woRecurStatus: WorkOrderRecurStatus;
        woBookingStatus: WorkOrderBookingStatus;
        hasActiveFilters: boolean;
        displayingDateRange: DateRange;
        activeDate: Date;
        viewMode: CalViewMode;
    };
    page: ConnectionPageStateType;
};

const initialActiveDate = new Date();
const initialViewMode = CalViewMode.WEEK;

const initialState: WorkOrderFeedStateType = {
    filters: {
        franchise: {},
        staffIds: [],
        serviceStageId: null,
        serviceStageType: [],
        serviceStageStatus: null,
        serviceGroupId: null,
        serviceJobTemplateId: null,
        woNumber: null,
        woRecurStatus: WorkOrderRecurStatus.ANY,
        hasActiveFilters: false,
        woBookingStatus: WorkOrderBookingStatus.UNBOOKED,
        activeDate: initialActiveDate,
        viewMode: initialViewMode,
        displayingDateRange: computeDateRange(initialActiveDate, initialViewMode),
    },
    page: defaultConnectionPageState,
};

const hasActiveFilters = (state: WorkOrderFeedStateType['filters']) => {
    return state.staffIds?.length > 0 || !!state.woNumber || !!state.serviceGroupId || !!state.serviceJobTemplateId;
};

// Actions and action creators

const SCOPE = '@WorkOrderFeed';

export enum WORK_ORDER_FEED_ACTION {
    CLEAR_ALL_FILTER = '@WorkOrderFeed/CLEAR_ALL_FILTER',
    DATE_RANGE = '@WorkOrderFeed/DATERANGE_FILTER',
}

const CustomActions = {
    clearAllFilters: () => createAction(WORK_ORDER_FEED_ACTION.CLEAR_ALL_FILTER, undefined, SCOPE),
    setDateRangeFilter: (input: { activeDate?: Date; viewMode?: CalViewMode }) =>
        createAction(WORK_ORDER_FEED_ACTION.DATE_RANGE, input, SCOPE),
};

const FiltersReducerActions = createAutoBoundReducerActions(initialState.filters, SCOPE, {
    setFranchise: { field: 'franchise' },
    setStaffFilter: { field: 'staffIds' },
    setServiceJobGroup: { field: 'serviceGroupId' },
    setServiceJobTemplate: { field: 'serviceJobTemplateId' },
    setWorkOrderNumber: { field: 'woNumber' },
    setRecurFilter: { field: 'woRecurStatus' },
    setBookingFilter: { field: 'woBookingStatus' },
    setServiceStageType: { field: 'serviceStageType' },
    setServiceStageStatus: { field: 'serviceStageStatus' },
    setServiceStageId: { field: 'serviceStageId' },
});

const handleDateRangeAction = (input: { activeDate?; viewMode? }, currentState) => {
    const { activeDate, viewMode } = input;

    invariant(activeDate || viewMode, `Expected either 'activeDate' or 'viewMode' to be provided`);

    const { viewMode: currentViewMode, activeDate: currentActiveDate } = currentState;

    const newViewMode = sanitizeViewMode(viewMode || currentViewMode, initialState.filters?.viewMode);
    const newActiveDate = activeDate || currentActiveDate;
    const newDateRange = computeDateRange(newActiveDate, newViewMode);

    return {
        activeDate: newActiveDate,
        viewMode: newViewMode,
        displayingDateRange: newDateRange,
    };
};

const filtersReducer = createReducer(
    initialState.filters,
    (state, action: Action<string>): WorkOrderFeedStateType['filters'] => {
        const reducer = FiltersReducerActions.reducers[action.type];
        if (reducer) {
            reducer(state, action.payload);
        } else {
            switch (action.type) {
                case WORK_ORDER_FEED_ACTION.DATE_RANGE:
                    const r = handleDateRangeAction(action.payload, state);
                    state = { ...state, ...r };
                    break;
                case WORK_ORDER_FEED_ACTION.CLEAR_ALL_FILTER:
                    // keep current booking status.
                    state = {
                        ...initialState.filters,
                        woBookingStatus: state.woBookingStatus,
                    };
                    break;
            }
        }
        state.hasActiveFilters = hasActiveFilters(state);
        return state;
    },
    SCOPE
);

const PageIndexReducerActions = createPageIndexReducerActions({
    scope: SCOPE,
    resetIndexActionTypes: [...FiltersReducerActions.actionTypes, WORK_ORDER_FEED_ACTION.CLEAR_ALL_FILTER],
});

export const reducerWOFeed = combineReducers({
    filters: filtersReducer,
    page: PageIndexReducerActions.reducer,
});

const memoizedSelector = createStructuredSelector<WorkOrderFeedStateType, WorkOrderFeedStateType>({
    filters: (state) => state.filters,
    page: (state) => state.page,
});

export const useWorkOrderFeedActions = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => memoizedSelector(state.serviceJob.workOrderFeed));

    return useMemo(() => {
        const allActions = { ...FiltersReducerActions.actions, ...PageIndexReducerActions.actions, ...CustomActions };
        return {
            State: state,
            Action: bindActionCreators(allActions, dispatch),
        };
    }, [state, dispatch]);
};

const serKeys = ['filters.woStatus', 'filters.woBookingStatus', 'filters.viewMode', 'filters.activeDate'];

export const deserializeWorkOrderFeed = (restoredState?: Partial<WorkOrderFeedStateType>) => {
    if (!restoredState || _.isEmpty(restoredState)) {
        return initialState;
    }

    const picked = _.pick(restoredState, serKeys);
    let state = _.defaultsDeep({}, picked, initialState);

    // Sanitize date
    const ad = moment(state.filters?.activeDate);
    state.filters.activeDate = ad?.toDate();
    state.filters.displayingDateRange = computeDateRange(state.filters.activeDate, state.filters.viewMode);
    state.filters.hasActiveFilters = hasActiveFilters(state.filters);

    return state;
};

export const serializeWorkOrderFeed = (state: WorkOrderFeedStateType) => {
    return _.pick(state, serKeys);
};
