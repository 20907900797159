import { CalcDocType, convertDocTypeToLabel, FormikHeatCalcValueType } from './types';
import { FormikSegmentedSelectButtons, Optionable, SectionHeader } from '@poolware/components';
import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import { useFormikContext } from 'formik';
import { usePersistedCalcValues } from '../combined-calculators/PersistedValuesContext';

export const FormikDocTypeToggleButtons: React.FC<{ availableCalcs?: CalcDocType[] }> = ({ availableCalcs }) => {
    const { saveDocType } = usePersistedCalcValues();
    const { values } = useFormikContext<FormikHeatCalcValueType>();

    const calcs = availableCalcs || values.availableCalcs;
    const options: Optionable<CalcDocType>[] = calcs.map((ac) => {
        return {
            value: ac,
            text: convertDocTypeToLabel(ac),
        };
    });

    if (options.length === 0) {
        return null;
    } else if (options.length === 1) {
        return (
            <Segment color={'brown'} style={{ padding: '0.5em' }}>
                <SectionHeader icon={'calculator'}>{options[0].text}</SectionHeader>
            </Segment>
        );
    }

    const onChange = async (newValue: CalcDocType) => {
        await saveDocType(newValue);
    };

    return (
        <Segment color={'brown'}>
            <FormikSegmentedSelectButtons
                name={'docType'}
                options={options}
                label={'Heater Type'}
                onDidChange={onChange}
            />
        </Segment>
    );
};
