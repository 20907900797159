import { createFormHandler } from './create-form-handler';
import * as ContactFormCreator from './create-contact-editor-forms';
import { stringFormatters } from '@poolware/components';
import { ICustomerMutatorsProps, NodeType, withCustomerMutators } from '@poolware/api';

const refetchQuery = ['CustomerEditQuery'];

export default {
    EditCustomerContactNamePage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createCustomerContactNameForm({ header: 'Contact Name' }),
            initialValues: { contactName: customer?.contactName },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props: ICustomerMutatorsProps, value) => {
                return props.CustomerMutator.updateContactName(customer, value.contactName);
            },
        }),

    DeleteCustomerContactNamePage: (customer: NodeType.Customer) =>
        createFormHandler({
            header: 'Contact Name',
            form: ContactFormCreator.createDeleteConfirm({ header: 'Contact Name' }),
            initialValues: { data: customer.contactName },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props: ICustomerMutatorsProps, value) => {
                return props.CustomerMutator.deleteContactName(customer);
            },
        }),

    EditCustomerCompanyNamePage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createEditCustomerCompanyNameForm({ header: 'Company Name' }),
            initialValues: customer,
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props: ICustomerMutatorsProps, value) => {
                return props.CustomerMutator.updateCompanyName(customer, value.companyName);
            },
        }),

    DeleteCustomerCompanyNamePage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createDeleteConfirm({ header: 'Delete Company Name?' }),
            initialValues: { data: customer.companyName },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props, values) => {
                return props.CustomerMutator.deleteCompanyName(customer);
            },
        }),

    EditUserNamePage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createUserNameForm({ header: 'Customer Name' }),
            initialValues: customer.user,
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props: ICustomerMutatorsProps, formValues) => {
                return props.CustomerMutator.updateUserName(customer, {
                    firstName: formValues.firstName,
                    lastName: formValues.lastName,
                    title: formValues.title,
                });
            },
        }),

    EditPrimaryAddressPage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createAddressForm({ header: 'Primary Address' }),
            initialValues: { customer: customer, address: customer.primaryAddress },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props, values) => {
                return props.CustomerMutator.updatePrimaryAddress(values.customer, values.address);
            },
        }),

    NewPrimaryAddressPage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createAddressForm({ header: 'Primary Address' }),
            initialValues: { customer: customer },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props, values) => {
                return props.CustomerMutator.createPrimaryAddress(values.customer, values.address);
            },
        }),

    EditBillAddressPage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createAddressForm({ header: 'Bill Address' }),
            initialValues: { customer: customer, address: customer.billAddress },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props, values) => {
                return props.CustomerMutator.updateBillAddress(values.customer, values.address);
            },
        }),

    NewBillAddressPage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createAddressForm({ header: 'Bill Address' }),
            initialValues: { customer: customer },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props, values) => {
                return props.CustomerMutator.createBillAddress(customer, values.address);
            },
        }),

    DeleteBillAddressPage: (customer: NodeType.Customer) =>
        createFormHandler({
            form: ContactFormCreator.createDeleteConfirm({ header: 'Delete Billing Address?' }),
            initialValues: { data: stringFormatters.formatAddress(customer.billAddress) },
            withMutator: withCustomerMutators(refetchQuery),
            mutate: (props, values) => {
                return props.CustomerMutator.deleteBillAddress(customer);
            },
        }),
};
