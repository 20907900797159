import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';
import { DndContext } from './DndContext';
import { DND_ACTION, RESIZE_DIRECTION } from './types';

class EventWrapper extends React.Component<any, any> {
    static contextType = DndContext;
    declare context: React.ContextType<typeof DndContext>;

    static propTypes = {
        type: PropTypes.oneOf(['date', 'time']),
        event: PropTypes.object.isRequired,

        draggable: PropTypes.bool,
        allDay: PropTypes.bool,
        isRow: PropTypes.bool,
        continuesPrior: PropTypes.bool,
        continuesAfter: PropTypes.bool,
        isDragging: PropTypes.bool,
        isResizing: PropTypes.bool,
    };

    handleResizeUp = (e) => {
        if (e.button !== 0) return;
        this.context.draggable.onBeginAction(this.props.event, DND_ACTION.RESIZE, RESIZE_DIRECTION.UP);
    };

    handleResizeDown = (e) => {
        if (e.button !== 0) return;
        this.context.draggable.onBeginAction(this.props.event, DND_ACTION.RESIZE, RESIZE_DIRECTION.DOWN);
    };

    handleResizeLeft = (e) => {
        if (e.button !== 0) return;
        this.context.draggable.onBeginAction(this.props.event, DND_ACTION.RESIZE, RESIZE_DIRECTION.LEFT);
    };

    handleResizeRight = (e) => {
        if (e.button !== 0) return;
        this.context.draggable.onBeginAction(this.props.event, DND_ACTION.RESIZE, RESIZE_DIRECTION.RIGHT);
    };
    handleStartDragging = (e) => {
        if (e.button !== 0) return;

        // HACK: because of the way the anchors are arranged in the DOM, resize
        // anchor events will bubble up to the move anchor listener. Don't start
        // move operations when we're on a resize anchor.
        const isResizeHandle = e.target.className.includes('rbc-addons-dnd-resize');

        if (!isResizeHandle) {
            this.context.draggable.onBeginAction(this.props.event, DND_ACTION.MOVE);
        }
    };

    renderAnchor(direction: RESIZE_DIRECTION) {
        let clsTop = null;
        let clsIcon = null;
        let onMouseDown = null;

        switch (direction) {
            case RESIZE_DIRECTION.LEFT:
                onMouseDown = this.handleResizeLeft;
                clsTop = 'rbc-addons-dnd-resize-ew-anchor';
                clsIcon = 'rbc-addons-dnd-resize-ew-icon';
                break;
            case RESIZE_DIRECTION.RIGHT:
                onMouseDown = this.handleResizeRight;
                clsTop = 'rbc-addons-dnd-resize-ew-anchor';
                clsIcon = 'rbc-addons-dnd-resize-ew-icon';
                break;
            case RESIZE_DIRECTION.UP:
                onMouseDown = this.handleResizeUp;
                clsTop = 'rbc-addons-dnd-resize-ns-anchor';
                clsIcon = 'rbc-addons-dnd-resize-ns-icon';
                break;
            case RESIZE_DIRECTION.DOWN:
                onMouseDown = this.handleResizeDown;
                clsTop = 'rbc-addons-dnd-resize-ns-anchor';
                clsIcon = 'rbc-addons-dnd-resize-ns-icon';
                break;
        }

        return (
            <div className={clsTop} onMouseDown={onMouseDown}>
                <div className={clsIcon} />
            </div>
        );
    }

    render() {
        const { event, type, continuesPrior, continuesAfter } = this.props;

        let { children } = this.props as any;

        if (event.__isPreview)
            return React.cloneElement(children, {
                className: clsx(children.props.className, 'rbc-addons-dnd-drag-preview'),
            });

        const { draggable } = this.context;
        const { draggableAccessor, resizableAccessor } = draggable;

        const isDraggable = draggableAccessor?.(event) || true;

        /* Event is not draggable, no need to wrap it */
        if (!isDraggable) {
            return children;
        }

        /*
         * The resizability of events depends on whether they are
         * allDay events and how they are displayed.
         *
         * 1. If the event is being shown in an event row (because
         * it is an allDay event shown in the header row or because as
         * in month view the view is showing all events as rows) then we
         * allow east-west resizing.
         *
         * 2. Otherwise the event is being displayed
         * normally, we can drag it north-south to resize the times.
         *
         * See `DropWrappers` for handling of the drop of such events.
         *
         * Notwithstanding the above, we never show drag anchors for
         * events which continue beyond current component. This happens
         * in the middle of events when showMultiDay is true, and to
         * events at the edges of the calendar's min/max location.
         */
        const isResizable = resizableAccessor?.(event) || true;

        if (isResizable || isDraggable) {
            /*
             * props.children is the singular <Event> component.
             * BigCalendar positions the Event absolutely and we
             * need the anchors to be part of that positioning.
             * So we insert the anchors inside the Event's children
             * rather than wrap the Event here as the latter approach
             * would lose the positioning.
             */
            const newProps: any = {
                onMouseDown: this.handleStartDragging,
                onTouchStart: this.handleStartDragging,
            };

            if (isResizable) {
                // replace original event child with anchor-embellished child
                let StartAnchor = null;
                let EndAnchor = null;

                if (type === 'date') {
                    StartAnchor = !continuesPrior && this.renderAnchor(RESIZE_DIRECTION.LEFT);
                    EndAnchor = !continuesAfter && this.renderAnchor(RESIZE_DIRECTION.RIGHT);
                } else {
                    StartAnchor = !continuesPrior && this.renderAnchor(RESIZE_DIRECTION.UP);
                    EndAnchor = !continuesAfter && this.renderAnchor(RESIZE_DIRECTION.DOWN);
                }

                newProps.children = (
                    <div className="rbc-addons-dnd-resizable">
                        {StartAnchor}
                        {children.props.children}
                        {EndAnchor}
                    </div>
                );
            }

            if (
                draggable.dragAndDropAction.interacting && // if an event is being dragged right now
                draggable.dragAndDropAction.event === event // and it's the current event
            ) {
                // add a new class to it
                newProps.className = clsx(children.props.className, 'rbc-addons-dnd-dragged-event');
            }

            children = React.cloneElement(children, newProps);
        }

        return children;
    }
}

export default EventWrapper;
