import gql from 'graphql-tag';
import * as _ from 'lodash';
import { createUseQueryNodeHook, NodeType, useDefaultConnectionState, useQueryConnection } from '@poolware/api';
import { productFragment } from './use-query-product-connection';

const rcFragment = gql`
    fragment HeaterRecommendationFragment on HeaterRecommendation {
        id
        recNumber
        expiryDate
        note
        createdAt
        updatedAt
        payload
        status
        urlPdf
        product {
            ...ProductFragment_Calc
        }
        addedBy {
            id
        }
        entity {
            id
            user {
                id
                firstName
                lastName
                customer {
                    id
                    crn
                }
            }
        }
        checkMutations {
            delete
            update
        }
    }
    ${productFragment}
`;

const QLConnection = gql`
    query QueryHeaterRecommendationConnection(
        $first: Int
        $page: Int
        $sort: HeaterRecommendationViewerHeaterRecommendationsSort
        $search: HeaterRecommendationViewerHeaterRecommendationsSearch
    ) {
        viewer {
            heaterRecommendations(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        ...HeaterRecommendationFragment
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${rcFragment}
`;

export interface UseQueryHeaterRecommendationConnectionProps {
    customerId?: NodeType.ID;
}

function mapPropsToSearchQuery(
    props?: UseQueryHeaterRecommendationConnectionProps
): NodeType.HeaterRecommendationViewerHeaterRecommendationsSearch {
    if (!props) {
        return undefined;
    }

    const { customerId } = props;
    const search = {};
    if (customerId) {
        _.set(search, 'entity.id', customerId);
    }
    return search;
}

export function useQueryHeaterRecommendations(props?: UseQueryHeaterRecommendationConnectionProps) {
    const connectionPath = 'viewer.heaterRecommendations';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 100,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection({
        query: QLConnection,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}

const QLNode = gql`
    query node($id: ID!) {
        node(id: $id) {
            id
            ...HeaterRecommendationFragment
        }
    }

    ${rcFragment}
`;

export const useQueryHeaterRecommendation = createUseQueryNodeHook<NodeType.HeaterRecommendation>(QLNode);
