import * as React from 'react';
import { useMemo } from 'react';
import { NodeType, SortDirection } from '@poolware/api';
import { QL } from './use-query-service-job-connection';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    SectionHeader,
    TruncatedDiv,
    VStack,
} from '@poolware/components';
import { Icon } from 'semantic-ui-react';
import { IconServiceJobRecurStatus, useQueryServiceJobConnection } from '@poolware/app-service-jobs';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppColorNames, AppIconNames } from '../../../../components/AppIcons';
import _truncate from 'lodash/truncate';
import { ServiceJobsHistoryProps } from './types';

const useTableDefRecur = (): ConnectionTableDef<NodeType.ServiceJob> => {
    return useMemo(
        () => [
            {
                header: 'ID',
                width: 1,
                sortKey: 'serviceJobNumber',
                cell: (serviceJob) => {
                    return (
                        <div>
                            <IconServiceJobRecurStatus serviceJob={serviceJob} />
                            {serviceJob.serviceJobNumber}
                        </div>
                    );
                },
            },
            {
                header: 'Service',
                cell: (serviceJob) => {
                    const title = _truncate(serviceJob.title || '--', { length: 60 });
                    const groupTitle = _truncate(serviceJob?.group?.title || '', { length: 60 });
                    return (
                        <div>
                            <Icon name={AppIconNames.ServiceJob.RecurringService} />
                            {groupTitle && <span tw={'font-bold text-purple-600'}>{groupTitle} - </span>} {title}
                        </div>
                    );
                },
            },
            {
                header: 'Opened',
                sortKey: 'createdAt',
                cellProps: { verticalAlign: 'top' },
                width: 2,
                cell: (v) => <Display.Date format={'ll'} value={v.createdAt} />,
            },
            {
                header: 'End Date',
                cellProps: { verticalAlign: 'top' },
                width: 2,
                cell: (v) => {
                    return (
                        <span>
                            <Display.Date format={'ll'} value={v.lastAppointmentDate} defaultString={<i>no end</i>} />
                        </span>
                    );
                },
            },
            {
                header: 'Linked with',
                cell: (value) => {
                    const { customer, address, pool } = value;
                    return (
                        <TruncatedDiv maxWidth={400}>
                            {customer && (
                                <div>
                                    <Icon name={'user'} />
                                    <code style={{ color: '#2185d0' }}>
                                        <b>{customer.crn}</b>
                                    </code>{' '}
                                    - <Display.Entity value={customer} />
                                </div>
                            )}
                            {customer?.companyName && (
                                <div>
                                    <Icon name={'building'} />
                                    {customer.companyName}
                                </div>
                            )}
                            {address && (
                                <div>
                                    <Icon name={'map marker alternate'} />
                                    <Display.Address value={address} />
                                </div>
                            )}
                            {pool && (
                                <div>
                                    <Icon name={'life ring'} />
                                    Pool - {pool.volume}L {pool.type?.name && `, ${pool.type?.name}`}
                                </div>
                            )}
                        </TruncatedDiv>
                    );
                },
            },
        ],
        []
    );
};

export const PanelRecurringServicesHistory: React.FC<ServiceJobsHistoryProps> = ({ customerId, poolId }) => {
    const { connectionState, connectionData } = useQueryServiceJobConnection({
        relatedCustomer: customerId,
        relatedPool: poolId,
        isRecurring: true,
        query: QL,
        defaultSortDirection: SortDirection.DES,
        defaultSortKey: 'serviceJobNumber',
    });
    const { AppNavigator } = useAppNavigator();

    const onView = (serviceJob: NodeType.ServiceJob) => {
        AppNavigator.navigate(`/service-jobs/sj/${serviceJob.id}`, {
            relativeToModule: false,
        });
    };

    const tableDefRecur = useTableDefRecur();

    return (
        <VStack>
            <SectionHeader size={'small'} content={`Recurring Services`} />
            <DefaultConnectionTable
                tableProps={{
                    color: AppColorNames.ServiceJob.ServiceCall,
                }}
                onRowClick={onView}
                tableCellProps={{ verticalAlign: 'top' }}
                tableDef={tableDefRecur}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </VStack>
    );
};
