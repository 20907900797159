import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

export const warrantyAuxDataFragment = gql`
    fragment WarrantyAuxDataFragment on Viewer {
        me {
            franchise {
                id
                email
            }
        }
        franchiseWarranters {
            edges {
                node {
                    id
                    email
                    name
                    createdAt
                    updatedAt
                    checkMutations {
                        delete
                        update
                    }
                }
            }
            pageMeta {
                totalCount
                pageCount
            }
            pageInfo {
                startCursor
                hasPreviousPage
                hasNextPage
                endCursor
            }
        }
    }
`;

const QL = gql`
    query QueryWarranyFormData {
        viewer {
            ...WarrantyAuxDataFragment
        }
    }
    ${warrantyAuxDataFragment}
`;
export const useQueryWarrantyFormData = () => {
    return useQuery(QL, { fetchPolicy: 'cache-first' });
};
