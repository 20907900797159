import * as React from 'react';
import { createUseQueryNodeHook, NodeType, useVendMutators } from '@poolware/api';
import gql from 'graphql-tag';
import {
    ButtonWithConfirm,
    ButtonWithPopup,
    ConnectionErrorMessage,
    Display,
    PageSkeletonLoader,
    Panel,
    toast,
    toastError,
    VStack,
} from '@poolware/components';
import { Message } from 'semantic-ui-react';
import 'twin.macro';
import _truncate from 'lodash/truncate';

const QL = gql`
    query CustomerVendLinkQuery($id: ID!) {
        node(id: $id) {
            ... on Customer {
                id
                vendLink {
                    id
                    vendUUID
                    isDeleted
                    vendURL
                    vendCustomer {
                        id
                        firstName
                        lastName
                        companyName
                        email
                        phone
                        mobile
                        fax
                        cursor
                        customerCode
                        createdAt
                        updatedAt
                        deletedAt
                        note
                        customField1
                        customField2
                        customField3
                        customField4
                        doNotEmail
                        balance
                        loyaltyBalance
                        loyaltyEmailSent
                        physicalAddress {
                            streetLine1
                            streetLine2
                            suburb
                            city
                            postCode
                            state
                            countryId
                        }
                        postalAddress {
                            streetLine1
                            streetLine2
                            suburb
                            city
                            postCode
                            state
                            countryId
                        }
                    }
                }
            }
        }
    }
`;

const useQueryCustomer = createUseQueryNodeHook<NodeType.Customer>(QL);

const PanelVendCustomerDetails: React.FC<{ vendLink: NodeType.VendLink }> = ({ vendLink }) => {
    const { vendCustomer } = vendLink || {};
    if (!vendCustomer) {
        return null;
    }
    const formatVendAddress = (address: NodeType.VendPhysicalAddress | NodeType.VendPostalAddress) => {
        if (!address) {
            return '--';
        }
        return [
            address.streetLine1,
            address.streetLine2,
            address.suburb,
            address.city,
            address.state,
            address.postCode,
            address.countryId,
        ].join(', ');
    };

    return (
        <Panel>
            <Panel.Header icon={'tag'} content={'Vend Record'} color={'green'} />
            <Panel.Body>
                {vendCustomer.deletedAt && (
                    <Panel.Item label={'Deleted'} color={'red'}>
                        <Display.Date value={vendCustomer.deletedAt} />
                    </Panel.Item>
                )}
                <Panel.Item label={'Customer Code'}>{vendCustomer.customerCode}</Panel.Item>
                <Panel.Item label={'First Name'}>{vendCustomer.firstName || '--'}</Panel.Item>
                <Panel.Item label={'Last Name'}>{vendCustomer.lastName || '--'}</Panel.Item>
                <Panel.Item label={'Company Name'}>{vendCustomer.companyName || '--'}</Panel.Item>
                <Panel.Item label={'Email'}>{vendCustomer.email || '--'}</Panel.Item>
                <Panel.Item label={'Phone'}>{vendCustomer.phone || '--'}</Panel.Item>
                <Panel.Item label={'Physical Address'}>{formatVendAddress(vendCustomer.physicalAddress)}</Panel.Item>
                <Panel.Item label={'Postal Address'}>{formatVendAddress(vendCustomer.postalAddress)}</Panel.Item>
                <Panel.Divider />
                <Panel.Item label={'Balance'}>{vendCustomer.balance || '--'}</Panel.Item>
                <Panel.Item label={'Loyalty Balance'}>{vendCustomer.loyaltyBalance || '--'}</Panel.Item>
                <Panel.Item label={'Do not email'}>{vendCustomer.doNotEmail ? 'Y' : 'N'}</Panel.Item>

                <Panel.Divider />
                <Panel.Item label={'Vend Link'}>
                    <a href={vendLink.vendURL} rel="noopener noreferrer" target="_blank">
                        {_truncate(vendLink.vendURL, { length: 60 })}
                    </a>
                </Panel.Item>
                <Panel.Item label={'Vend UUID'}>{vendLink?.vendUUID}</Panel.Item>
                <Panel.Item label={'Custom field 1'}>{vendCustomer.customField1}</Panel.Item>
                <Panel.Item label={'Custom field 2'}>{vendCustomer.customField2}</Panel.Item>
                <Panel.Item label={'Custom field 3'}>{vendCustomer.customField3}</Panel.Item>
                <Panel.Item label={'Custom field 4'}>{vendCustomer.customField4}</Panel.Item>
            </Panel.Body>
            <Panel.Footer>
                <Message info={true}>Customer details fetched from Vend</Message>
            </Panel.Footer>
        </Panel>
    );
};

export interface VendCustomerDetailsProps {
    customerId: NodeType.ID;
}

export const VendCustomerDetails: React.FC<VendCustomerDetailsProps> = ({ customerId }) => {
    const { loading, error, node, refetchQuery } = useQueryCustomer(customerId);
    const { VendMutator } = useVendMutators(['CustomerVendQuery', 'CustomerQuery', refetchQuery]);

    if (loading) {
        return (
            <Panel>
                <Panel.Header icon={'tag'} content={'Vend Record'} />
                <Panel.Body>
                    <PageSkeletonLoader />
                </Panel.Body>
            </Panel>
        );
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <Message>Unable to load</Message>;
    }

    const pushToVend = async () => {
        try {
            await VendMutator.pushCustomerToVend(customerId);
        } catch (error) {
            console.error(error);
            toastError({
                time: 4000,
                title: 'Failed to push customer to VEND',
                description: error.message,
            });
        }
    };

    const restoreOnVend = async () => {
        try {
            await VendMutator.resyncVendCustomer(node.vendLink);
        } catch (error) {
            console.error(error);
            toast({
                type: 'error',
                time: 4000,
                title: 'Failed to restore customer on VEND',
                description: error.message,
            });
        }
    };

    if (!node.vendLink || !node.vendLink?.vendURL) {
        // Not synced yet. Show push button.
        return (
            <Panel>
                <Panel.Header icon={'tag'} content={'Vend Record'} />
                <Panel.Body>
                    <Message info={true}>Customer is not synced with Vend yet</Message>
                </Panel.Body>
                <Panel.Footer>
                    <ButtonWithPopup
                        size={'small'}
                        color={'purple'}
                        icon={'upload'}
                        onClick={pushToVend}
                        content={'Push To Vend'}
                    />
                </Panel.Footer>
            </Panel>
        );
    }

    if (node.vendLink?.isDeleted) {
        // "Reactivate" (sync mutation)
        return (
            <VStack>
                <Panel>
                    <Panel.Header icon={'unlink'} color={'red'} content={'Vend Sync Error'} />
                    <Panel.Body>
                        <Message.Content>
                            <p>
                                Our system has detected that a previously existed record of this customer does not exist
                                on VEND anymore. The most likely reason is that the customer was manually deleted from
                                VEND. Sales history may be lost forever!
                            </p>
                            <p>If you need to recover the deleted customer on Vend, please contact Vend support.</p>
                            <p>If you wish to create a new record of this customer on VEND, press the button below .</p>
                            <p>IMPORTANT: New record will be create on VEND.</p>
                        </Message.Content>
                        <Panel.Divider />
                    </Panel.Body>
                    <Panel.Footer>
                        <ButtonWithConfirm
                            size={'small'}
                            color={'orange'}
                            icon={'upload'}
                            onClick={restoreOnVend}
                            content={'Create New Vend Record'}
                            confirm={{
                                confirmMessage: {
                                    header: 'Create New Customer on Vend?',
                                    content: <p>New record will be create on VEND.</p>,
                                },
                                confirmButton: { content: 'Create New Customer', icon: 'upload' },
                                cancelButton: { content: 'Cancel' },
                            }}
                        />
                    </Panel.Footer>
                </Panel>
                {node.vendLink?.vendCustomer && <PanelVendCustomerDetails vendLink={node.vendLink} />}
            </VStack>
        );
    }

    return <PanelVendCustomerDetails vendLink={node.vendLink} />;
};
