import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { MenuBar, PageTabs, ScrollableLayout, TabPaneConf, useTabCtrl, VStack } from '@poolware/components';
import { useCalendarActions } from '../../../redux';
import { WorkOrders } from '../../WorkOrdersFeed';
import { CalViewMode } from '../../types';
import { useWindowSize } from '@ez/tools';
import { ModuleIconNames } from '../../../constants';
import { useViewer } from '@poolware/app-shell';
import { StreetFilter } from './StreetFilter';
import { QuickActionsTab } from './QuickActionsTab';
import { AppointmentViewDock, useAppointmentViewCtx } from '../../AppointmentDock';
import { useCalendarLayoutState } from '../../utils/use-calendar-layout-state';
import tw, { styled } from 'twin.macro';
import { TabStyleMODE } from '@ez/components';

export interface RightSidebarProps {}

const StreetHighlighter = () => {
    return (
        <VStack space={0.75}>
            <StreetFilter />
        </VStack>
    );
};

enum DispatcherToolType {
    QuickActions = 'QuickActions',
    WorkOrders = 'WorkOrders',
    Streets = 'Streets',
}

const MenuBarStyled = styled(MenuBar)`
    &&& {
        ${tw`border-0 rounded-t m-0 rounded-b-none`}
    }
`;

export const SidebarRight: React.FC<RightSidebarProps> = ({}) => {
    const { modulesAccess } = useViewer();
    const { CalendarAction, CalendarState } = useCalendarActions();
    const previewItemCtx = useAppointmentViewCtx();
    const CalLayoutState = useCalendarLayoutState();
    const { innerHeight } = useWindowSize();
    const maxHeight = innerHeight - 220;

    const [dispToolType, setDispTools] = useState<DispatcherToolType>(DispatcherToolType.QuickActions);

    const streetsEnabled =
        modulesAccess.FieldServices?.calendarStreetsSummary &&
        (CalendarState.viewMode === CalViewMode.DAY || CalendarState.viewMode === CalViewMode.WEEK);

    const woEnabled =
        !modulesAccess.FieldServices?.calendarSingleStaffMode &&
        modulesAccess.FieldServices?.calendarWorkOrderDND &&
        (CalendarState.viewMode === CalViewMode.DAY || CalendarState.viewMode === CalViewMode.WEEK);

    const panes: TabPaneConf[] = useMemo(() => {
        return [
            {
                title: 'Actions',
                icon: 'th',
                key: DispatcherToolType.QuickActions,
                render: () => <QuickActionsTab />,
            },
            woEnabled && {
                title: 'Work Orders',
                icon: ModuleIconNames.WorkOrder,
                key: DispatcherToolType.WorkOrders,
                render: () => <WorkOrders />,
            },
            streetsEnabled && {
                title: 'Streets',
                icon: 'map marker alternate',
                key: DispatcherToolType.Streets,
                render: () => <StreetHighlighter />,
            },
        ];
    }, [woEnabled, streetsEnabled, maxHeight]);

    const onChangeTool = (key: DispatcherToolType) => {
        setDispTools(key);
    };

    const onCloseSidebar = () => {
        CalLayoutState.setDockState(false);
    };

    const tabCtrl = useTabCtrl({
        panes,
        activeTab: dispToolType,
        onTabChange: onChangeTool,
    });

    const closeApptPreview = useCallback(() => {
        setDispTools(DispatcherToolType.QuickActions);
        CalendarAction.setPreviewAppt(null);
        previewItemCtx.setAppointmentItemId(null);
    }, [CalendarState.previewApptId]);

    if (CalLayoutState.isDockOpen && CalendarState.previewApptId) {
        return <AppointmentViewDock onClose={closeApptPreview} appointmentItemId={CalendarState.previewApptId} />;
    }

    return (
        <ScrollableLayout>
            <MenuBarStyled size={'tiny'}>
                <MenuBar.Section position={'left'}>
                    <MenuBar.HeaderItem color={'purple'} icon={'calendar alternate outline'}>
                        Dispatcher Tools
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    {CalLayoutState.canCloseDock && (
                        <MenuBar.Item color={'blue'} title={'Close'} onClick={onCloseSidebar} icon={'close'} />
                    )}
                </MenuBar.Section>
            </MenuBarStyled>

            <ScrollableLayout.BodyScroll>
                <div tw={'p-2 overflow-hidden'}>
                    <PageTabs
                        tabStyle={TabStyleMODE.TABULAR}
                        paneStyle={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
                        {...tabCtrl}
                    />
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};
