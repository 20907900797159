import * as React from 'react';
import { Button, Divider, Message } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import { ButtonWithPopup, Panel, VStack } from '@poolware/components';
import { ServiceJobLink, WorkOrderLink } from '../../../Links';
import { isServiceActive } from '../../../ServiceJobs/ServiceJobRecurView/utils';

export enum ApptDeleteMode {
    SINGLE,
    ALLFUTURE,
    WO_AND_APPT,
}

interface Props {
    appointmentItem: NodeType.AppointmentItem;
    onCancel: () => any;
    onDelete: (mode: ApptDeleteMode) => any;
}

const PanelDeleteAppointmentForWo: React.FC<Props> = ({ appointmentItem, onCancel, onDelete }) => {
    const { serviceJob, workOrder } = appointmentItem;

    if (!serviceJob) {
        return <div>Error</div>;
    }

    return (
        <Panel>
            <Panel.Header content={'Delete Appointment?'} />
            <Panel.Body>
                <Message info={true}>
                    <p>
                        This appointment is managed by{' '}
                        <ServiceJobLink id={serviceJob.id}>
                            {serviceJob.isRecurring ? 'Recurring Service' : 'Service Case '} #
                            {serviceJob?.serviceJobNumber}.
                        </ServiceJobLink>
                    </p>

                    {workOrder && (
                        <p>
                            <WorkOrderLink id={workOrder.id}>Work Order #{workOrder.workOrderNumber}</WorkOrderLink> is
                            associated with this appointment
                        </p>
                    )}
                </Message>

                <VStack>
                    <ButtonWithPopup
                        fluid={true}
                        showLoaderOnClick={true}
                        icon={'trash'}
                        basic={true}
                        color={'orange'}
                        content={'Delete Appointment'}
                        onClick={() => onDelete(ApptDeleteMode.SINGLE)}
                    />

                    {workOrder && (
                        <ButtonWithPopup
                            fluid={true}
                            showLoaderOnClick={true}
                            popup={{ content: 'Delete appointment and work order' }}
                            icon={'trash'}
                            basic={true}
                            color={'red'}
                            content={'Delete Appointment and Work Order'}
                            onClick={() => onDelete(ApptDeleteMode.WO_AND_APPT)}
                        />
                    )}
                </VStack>
                <Divider hidden={true} />

                {serviceJob?.isRecurring && isServiceActive(serviceJob) && (
                    <>
                        <p>
                            To cancel all future appointments created by this service, you need to <b>Stop</b> it via
                            the Service profile page -{' '}
                            <ServiceJobLink id={serviceJob.id}>#{serviceJob?.serviceJobNumber} (view).</ServiceJobLink>
                        </p>
                    </>
                )}
            </Panel.Body>
            <Panel.Footer>
                <Button basic={true} color={'blue'} content={'Cancel'} onClick={onCancel} />
            </Panel.Footer>
        </Panel>
    );
};

const buttonProps: any = {
    style: { margin: '0 .25em 0 0' },
    // size: 'mini',
    fluid: true,
    basic: true,
};

export const DeleteAppointmentButtons: React.FC<Props> = ({ appointmentItem, onCancel, onDelete }) => {
    const { isRecurring, serviceJob } = appointmentItem;

    return (
        <>
            {serviceJob ? (
                <PanelDeleteAppointmentForWo
                    appointmentItem={appointmentItem}
                    onCancel={onCancel}
                    onDelete={onDelete}
                />
            ) : (
                <Panel>
                    <Panel.Header content={'Delete Appointment?'} />
                    <Panel.Body>
                        {isRecurring ? (
                            <VStack>
                                <Button
                                    {...buttonProps}
                                    negative
                                    onClick={() => onDelete(ApptDeleteMode.ALLFUTURE)}
                                    icon={'trash'}
                                    content={'Delete All Future Appointments'}
                                />
                                <Button
                                    {...buttonProps}
                                    color={'purple'}
                                    onClick={() => onDelete(ApptDeleteMode.SINGLE)}
                                    icon={'trash'}
                                    content={'Delete Only This Appointment'}
                                />
                            </VStack>
                        ) : (
                            <Button
                                {...buttonProps}
                                negative
                                onClick={() => onDelete(ApptDeleteMode.SINGLE)}
                                icon={'trash'}
                                content={'Delete Appointment'}
                            />
                        )}
                        <Divider hidden={true} />
                    </Panel.Body>
                    <Panel.Footer>
                        <Button basic={true} color={'blue'} fluid={true} content={'Cancel'} onClick={onCancel} />
                    </Panel.Footer>
                </Panel>
            )}
        </>
    );
};
