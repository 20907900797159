import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { MutationHookOptionsFranchiseSequenceStore, NodeType, useMutationFranchiseSequenceStore } from '@poolware/api';
import invariant from 'invariant';
import moment from 'moment/moment';

const gqlQuery = gql`
    query QueryResolveFranchiseSequence($key: String!, $group: String, $franchise: ID) {
        viewer {
            resolveFranchiseSequence(key: $key, group: $group, franchise: $franchise) {
                id
                group
                key
                sequence
            }
        }
    }
`;

export const createUseQueryResolveFranchiseSequence = <V = any>(defaults: { group: string; key: string }) => (input?: {
    group?: string;
    key?: string;
}) => {
    invariant(defaults.group, 'default group must be provided');
    invariant(defaults.key, 'default key must be provided');

    const _variables = {
        ...defaults,
        ...input,
    };
    const { data, ...rest } = useQuery<NodeType.Query>(gqlQuery, {
        variables: _variables,
        fetchPolicy: 'network-only',
    });
    const refetchQuery = {
        query: gqlQuery,
        variables: _variables,
    };

    const _parsedValue = data?.viewer?.resolveFranchiseSequence;

    return {
        ...rest,
        refetchQuery,
        data: _parsedValue,
    };
};

const SEQ_GROUP = 'invoice';
const SEQ_KEY_DEFAULT = 'global';

export const getInvoicePrefixKey = () => {
    return 'INV' + moment().format('YYMMDD');
};

export const formatInvSequence = (prefix: string, seq: number) => {
    const paddedSeq = `${seq || 1}`.padStart(2, '0');
    return prefix + paddedSeq;
};

export const useQueryResolveInvoiceSequence = createUseQueryResolveFranchiseSequence({
    group: SEQ_GROUP,
    key: SEQ_KEY_DEFAULT,
});

export const useMutateInvoiceIncrementSequence = (options?: MutationHookOptionsFranchiseSequenceStore) => {
    const { incrementOwn } = useMutationFranchiseSequenceStore(options);
    return (props?: { key?: string }) => incrementOwn({ key: props?.key || SEQ_KEY_DEFAULT, group: SEQ_GROUP });
};
