import * as React from 'react';
import { ModuleNavLink, ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { Router as OfficesRouter } from './Warranter/Router';
import { NotFoundPage } from '@poolware/app-shell';
import { Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import { WarrantersType } from './WarrantersType';

const Index = () => {
    return <ModuleRedirect to={'/offices'} />;
};

const StyledMenu = styled(Menu)`
    //margin-bottom: 0 !important;
    border-radius: 0 !important;
    background-color: #e6e6e6 !important;
    .menu .item.active {
        background-color: #ffffff !important;
    }
`;

const SectionsNavbar = () => (
    <StyledMenu fluid>
        <Menu.Menu position={'left'}>
            <Menu.Item as={ModuleNavLink} to={'/offices'}>
                Offices
            </Menu.Item>
            <Menu.Item as={ModuleNavLink} to={'/franchises'}>
                Franchises
            </Menu.Item>
        </Menu.Menu>
    </StyledMenu>
);

export const Router: React.FC = () => {
    return (
        <ModuleRoot>
            <SectionsNavbar />
            <>
                <ModuleSwitch notFound={NotFoundPage}>
                    <ModuleRoute path={'/'} exact component={Index} />
                    <ModuleRoute path={'/offices'} component={OfficesRouter} />
                    <ModuleRoute path={'/franchises'} component={WarrantersType} />
                </ModuleSwitch>
            </>
        </ModuleRoot>
    );
};
