import * as _ from 'lodash';

export type TreeNodeID = string | number;

export interface TreeableItem {
    id: TreeNodeID;
    parent?: {
        id: TreeNodeID;
    };
}

export interface TreeNode<T> {
    id: TreeNodeID;
    parentId?: TreeNodeID;
    children?: TreeNode<T>[];
    depthIndex?: number;
    value: T | null;
}

function boxifyTreeNode<T extends TreeableItem>(value: T): TreeNode<T> {
    return {
        id: value.id,
        parentId: _.get(value, 'parent.id', null),
        value: value,
        children: [],
    };
}

export function unboxTreeNode<T = any>(node: TreeNode<T>): T {
    return node.value;
}

export function unflattenToTree<T extends TreeableItem>(items: T[]): TreeNode<T>[] {
    const recursiveUnflatten = function(allNodes: TreeNode<T>[], parent: TreeNode<T>, depth: number) {
        if (depth > 10) {
            ///prevent from very deep trees
            return;
        }

        const childrenNodes = allNodes.filter((child) => child.parentId === parent.id);

        if (childrenNodes.length > 0) {
            parent.children = childrenNodes;
            childrenNodes.forEach((child) => {
                recursiveUnflatten(allNodes, child, depth + 1);
            });
        }
    };

    if (!items) {
        return [];
    }

    const allNodes = items
        .filter((i) => Boolean(i)) // clear all null-ish items
        .map(boxifyTreeNode);
    const rootItems = allNodes.filter((d) => !d.parentId);

    rootItems.forEach((rootNode) => {
        recursiveUnflatten(allNodes, rootNode, 0);
    });

    return rootItems;
}

export function flattenFromTree<T = any>(tree: TreeNode<T>[]): TreeNode<T>[] {
    const acc: TreeNode<T>[] = [];

    const walkDown = (node: TreeNode<T>, depth: number) => {
        node.depthIndex = depth;
        acc.push(node);

        // const accString = acc.map((i) => i.id).join(',');
        // console.log(`Node ${node.id}, acc [${accString}]`);

        if (!node.children || node.children.length == 0) return;
        return _.map(node.children, (node) => {
            walkDown(node, depth + 1);
        });
    };

    _.map(tree, (node) => {
        return walkDown(node, 0);
    });

    return acc;
}

export function findTreeDescendants<T = any>(tree: TreeNode<T>[], idToFind: TreeNodeID): TreeNode<T>[] {
    const recursiveFind = function(
        array: TreeNode<T>[],
        idToFind: TreeNodeID,
        forceInclude: boolean,
        depth
    ): TreeNode<T>[] {
        if (depth > 10) {
            ///prevent from very deep trees
            return [];
        }

        return _.flatMap(array, (node) => {
            const toInclude = forceInclude || node.id === idToFind;
            const subItems = recursiveFind(node.children, idToFind, toInclude, depth + 1);
            if (toInclude) {
                subItems.push(node);
            }
            return subItems;
        });
    };

    return recursiveFind(tree, idToFind, false, 0);
}

export function findTreeAscendants<T = any>(tree: TreeNode<T>[], idToFind: TreeNodeID): TreeNode<T>[] {
    const walkDown = (node: TreeNode<T>, idToFind: TreeNodeID, acc: TreeNode<T>[]): TreeNode<T>[] => {
        acc.push(node);
        // const accString = acc.map(i=>i.id).join(',');
        // console.log(`Node ${node.id}, acc [${accString}]`);
        const toInclude = node.id === idToFind;
        if (toInclude) {
            return acc;
        }
        return _.flatMap(node.children, (node) => {
            // create new copy of `acc`
            return walkDown(node, idToFind, [...acc]);
        });
    };

    return _.flatMap(tree, (node) => walkDown(node, idToFind, []));
}
