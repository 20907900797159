import * as React from 'react';
import { fromEdges, JobTodoItemStatus, NodeType } from '@poolware/api';
import { PrintPageNoteText, PrintSectionColumn, PrintTable as Table } from '@poolware/components';
import { Icon } from 'semantic-ui-react';

export const WorkOrderJobItemsPanel: React.FC<{ jobTodo: NodeType.JobTodo }> = ({ jobTodo }) => {
    const items = fromEdges(jobTodo.items);
    const totalCount = items.length;
    const jobTodoIsActionable = totalCount == 0;
    if (jobTodoIsActionable) {
        // This JobTodo does not have any JobTodoItems.
        // It becomes actionable itself.
        const isOK = jobTodo.status === JobTodoItemStatus.OK;
        const isNG = jobTodo.status === JobTodoItemStatus.NG;

        return (
            <PrintSectionColumn>
                <h4>{jobTodo.title}</h4>
                {jobTodo.description && (
                    <PrintPageNoteText
                        formatted={false}
                        limit={10000}
                        value={jobTodo.description}
                        style={{ minHeight: '0cm' }}
                    />
                )}
                <Table.Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell style={{ width: '1cm' }}>N</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '1cm' }}>Y</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>{jobTodo.title}</Table.Cell>
                            <Table.Cell style={{ width: '1cm' }}>
                                <Icon name={isNG ? 'check square outline' : 'square outline'} />
                            </Table.Cell>
                            <Table.Cell style={{ width: '1cm' }}>
                                <Icon name={isOK ? 'check square outline' : 'square outline'} />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table.Table>
            </PrintSectionColumn>
        );
    } else {
        return (
            <PrintSectionColumn>
                <h4>{jobTodo.title}</h4>
                {jobTodo.description && (
                    <PrintPageNoteText
                        formatted={false}
                        limit={10000}
                        value={jobTodo.description}
                        style={{ minHeight: '0cm' }}
                    />
                )}
                <Table.Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell style={{ width: '1cm' }}>N</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: '1cm' }}>Y</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map((item, i) => {
                            const isOK = item.status === JobTodoItemStatus.OK;
                            const isNG = item.status === JobTodoItemStatus.NG;
                            return (
                                <Table.Row key={item.id}>
                                    <Table.Cell>{item.title}</Table.Cell>
                                    <Table.Cell style={{ width: '1cm' }}>
                                        <Icon name={isNG ? 'check square outline' : 'square outline'} />
                                    </Table.Cell>
                                    <Table.Cell style={{ width: '1cm' }}>
                                        <Icon name={isOK ? 'check square outline' : 'square outline'} />
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table.Table>
            </PrintSectionColumn>
        );
    }
};
