import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Select, SemanticSIZES } from 'semantic-ui-react';
import { Field, FieldProps, useField } from 'formik';
import moment from 'moment';
import * as _ from 'lodash';
import { FormikDatePicker } from './FormikDatePickerField';
import { FormikFormFieldLabel } from '@poolware/components';
import { FieldContentContainer } from './common';
import { FormSelectProps } from 'semantic-ui-react/dist/commonjs/collections/Form/FormSelect';

const durationTimes = [
    {
        text: '10 mins',
        value: 10,
    },
    {
        text: '15 mins',
        value: 15,
    },
    {
        text: '30 mins',
        value: 30,
    },
    {
        text: '45 mins',
        value: 45,
    },
    {
        text: '1 hour',
        value: 60,
    },
    {
        text: '1 hour 30 mins',
        value: 90,
    },
    {
        text: '2 hours',
        value: 120,
    },
    {
        text: '2 hours 30 mins',
        value: 150,
    },
    {
        text: '3 hours',
        value: 3 * 60,
    },
    {
        text: '3 hours 30 mins',
        value: 210,
    },
    {
        text: '4 hours',
        value: 4 * 60,
    },
    {
        text: '5 hours',
        value: 5 * 60,
    },
    {
        text: '6 hours',
        value: 6 * 60,
    },
    {
        text: '7 hours',
        value: 7 * 60,
    },
    {
        text: '8 hours',
        value: 8 * 60,
    },
    {
        text: '9 hours',
        value: 9 * 60,
    },
    {
        text: '10 hours',
        value: 10 * 60,
    },
    {
        text: '11 hours',
        value: 11 * 60,
    },
    {
        text: '12 hours',
        value: 12 * 60,
    },
    {
        text: '16 hours',
        value: 16 * 60,
    },
    {
        text: '20 hours',
        value: 20 * 60,
    },
    {
        text: '24 hours',
        value: 24 * 60,
    },
];

interface FormikDurationInputFieldProps extends Omit<FormSelectProps, 'options'> {
    label?: string;
    required?: boolean;
    name: string;
}

const FormikDurationInputField: React.FC<FormikDurationInputFieldProps> = ({
    name,
    label,
    required,
    ...selectProps
}) => {
    const [field, meta, helpers] = useField({ name: name });
    const { value } = field;

    const [options, setOptions] = useState([...durationTimes]);

    const handleOnChange = (e, { value }) => {
        helpers.setValue(value);
    };

    useEffect(() => {
        const res = options.find((i) => {
            return i.value === value;
        });

        if (!res) {
            const durationTime = moment.duration(value, 'minutes');
            const hour = durationTime.hours();
            const minutes = durationTime.minutes();
            const plural = hour > 1;
            const label = hour > 0 ? `${hour} hour${plural ? 's' : ''} ${minutes} mins` : `${minutes} mins`;
            const newOptions = [...options, { text: label, value: value }];
            setOptions(_.sortBy(newOptions, ({ value }) => value));
        }
    }, [value, options]);

    return (
        <Form.Field>
            <FormikFormFieldLabel label={label} name={field.name} required={required} />
            <Select {...selectProps} options={options} value={value} onChange={handleOnChange} />
        </Form.Field>
    );
};

const sevenYearsFromNow = moment().add(7, 'y').endOf('y').toDate();
const oneYearsBeforeNow = moment().subtract(1, 'y').startOf('y').toDate();

export interface TimeFieldProps {
    showDay?: boolean;
    monthsShown?: number;
    size?: SemanticSIZES;
    dateLabel?: string;
    timeLabel?: string;
}

export const TimeField: React.FC<TimeFieldProps> = ({ size, monthsShown, timeLabel, dateLabel, showDay = true }) => {
    return (
        <Form.Group widths="equal">
            {showDay && (
                <Form.Field>
                    <FormikFormFieldLabel label={dateLabel || 'Start Date'} name={'startDate'} />
                    <FormikDatePicker
                        size={size}
                        monthsShown={monthsShown}
                        name={'startDate'}
                        fixedHeight
                        dateFormat={'dd MMMM yyyy'}
                        icon={'calendar alternate outline'}
                        fluid
                        arrowPosition={'left'}
                        minDate={oneYearsBeforeNow}
                        maxDate={sevenYearsFromNow}
                        dateFormatCalendar={'MMMM yyyy'}
                        todayButton={'Today'}
                        shouldCloseOnSelect={true}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </Form.Field>
            )}
            <Form.Field>
                <FormikFormFieldLabel label={timeLabel || 'Time'} name={'startDate'} />
                <FormikDatePicker
                    fluid
                    size={size}
                    name={'startDate'}
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat={'HH:mm'}
                    icon={'time'}
                    timeCaption="Time"
                    popperPlacement="bottom"
                />
            </Form.Field>
            <FormikDurationInputField name={'duration'} fluid label="Duration" size={size} />
        </Form.Group>
    );
};

export default TimeField;
