import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Icon, Message } from 'semantic-ui-react';

export const NotFoundPage: React.FC = ({ children }) => {
    return (
        <Container text>
            <Message error>
                <Message.Header>
                    <Icon name={'warning sign'} /> 404 - Page Not Found
                </Message.Header>
                <Message.Content>
                    <br />
                    <Button as={Link} to={'/'}>
                        <Icon name={'home'} /> Take Me Home
                    </Button>
                </Message.Content>
            </Message>
            {children}
        </Container>
    );
};
