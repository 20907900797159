import { ConnectionErrorMessage, PageSkeletonLoader } from '@ez/components';
import * as React from 'react';
import { NotFoundPage } from '../pages';

export const pageSpinner = (loading: boolean, error: Error, data: any) => {
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !data) {
        return <NotFoundPage />;
    }
    return null;
};
