import * as React from 'react';
import { ModuleRoot, ModuleRoute, ModuleSwitch, useAppNavigator } from '@poolware/react-app-navigator';
import { PageList } from './Page.List';
import { PageView } from './View';
import { PageNew } from './Page.New';
import { PageEdit } from './Page.Edit';
import { useViewer } from '@poolware/app-shell';
import * as Message from './MessageInbox';
import { styled } from 'twin.macro';
import { Icon, Menu } from 'semantic-ui-react';
import { MenuItemProps } from 'semantic-ui-react';
import { ModuleNavLink, ModuleNavLinkProps } from '@poolware/react-app-navigator';

export const MenuItemNavLink: React.FC<MenuItemProps & ModuleNavLinkProps> = (props) => {
    return <Menu.Item color={'blue'} as={ModuleNavLink} {...props} />;
};

const StyledMenu = styled(Menu)`
    //margin-bottom: 0 !important;
    border-radius: 0 !important;
    background-color: #e6e6e6 !important;

    .menu .item.active {
        background-color: #ffffff !important;
    }
`;

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    if (AppNavigator.isModal || !modulesAccess.Warranties.franchiseMsgInbox) {
        return null;
    }

    return (
        <StyledMenu fluid>
            <Menu.Menu position={'left'}>
                <MenuItemNavLink to={'/'} exact={true}>
                    <Icon name={'certificate'} /> Warranty Claims
                </MenuItemNavLink>
                <MenuItemNavLink to={'/messages'} exact={true}>
                    <Icon name={'mail'} />
                    Inbox
                </MenuItemNavLink>
            </Menu.Menu>
            <Menu.Menu position={'right'}>
                <MenuItemNavLink to={'/new'} exact={true}>
                    <Icon name={'plus'} color={'green'} />
                    New Claim
                </MenuItemNavLink>
            </Menu.Menu>
        </StyledMenu>
    );
};

export const Router: React.FC = () => {
    return (
        <ModuleRoot>
            <ModuleRoute path={'/'} exact={true} component={SectionsNavbar} />
            <ModuleRoute path={'/messages'} exact={true} component={SectionsNavbar} />
            <ModuleSwitch>
                <ModuleRoute path={'/'} exact={true} component={PageList} />
                <ModuleRoute path={'/new'} component={PageNew} />
                <ModuleRoute path={'/messages'} component={Message.List} />
                <ModuleRoute path={'/:id/edit'} component={PageEdit} />
                <ModuleRoute path={'/:id'} component={PageView} />
            </ModuleSwitch>
        </ModuleRoot>
    );
};
