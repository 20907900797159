import * as React from 'react';
import { useState } from 'react';
import { FeedEvent } from './FeedEvent';
import styled from 'styled-components';
import * as Yup from 'yup';
import { MessageForm } from './MessageForm';
import { DiscussionMessage } from './types';

const FormContainer = styled.div`
    margin: 0 0 0.75em 0.5em;
    padding: 0;
`;
const validationSchema = Yup.object({
    message: Yup.string().max(2).required('Required'),
});

interface NotesFeedItemProps {
    messageNode: DiscussionMessage;
    placeholder?: string;
    onSubmit: (values: DiscussionMessage) => any;
    onDelete: (values: DiscussionMessage) => any;
}

export const MessagesFeedEvent: React.FC<NotesFeedItemProps> = (props) => {
    const [isEditing, setEditing] = useState(false);

    const { messageNode, placeholder, onSubmit, onDelete } = props;

    const handleOnSubmit = async (values: DiscussionMessage) => {
        await onSubmit(values);
        setEditing(false);
    };

    if (!isEditing) {
        return (
            <FeedEvent message={messageNode} onEdit={() => setEditing(true)} onDelete={() => onDelete(messageNode)} />
        );
    }

    const { message, ...rest } = messageNode;
    const initialValue = {
        message: message || '',
        ...rest,
    };

    return (
        <FormContainer>
            <MessageForm
                onSubmit={handleOnSubmit}
                validationSchema={validationSchema}
                submitButton={{ content: 'Update' }}
                onCancel={() => {
                    setEditing(false);
                }}
                initialValues={initialValue}
                placeholder={placeholder}
            />
        </FormContainer>
    );
};
