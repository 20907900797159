import * as React from 'react';
import * as Yup from 'yup';
import { Divider } from 'semantic-ui-react';
import { NodeType, sanitizeAddressInput } from '@poolware/api';
import { PageControl } from './Page.Control';
import {
    FormikDefaultForm,
    FormikInputField,
    FormikRadioField,
    PageLayout,
    Panel,
    stringFormatters,
    toastError,
} from '@poolware/components';
import {
    AddressValidationSchema,
    FormikAddressInputFields,
} from '../../../CommonComponents/Address/FormikAddressInputFields';

const NEW_ADDRESS = '___new_address___';

const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
    address: Yup.string().required('Required').nullable(),
    newAddress: Yup.object().when('address', {
        is: (address) => address === NEW_ADDRESS,
        then: AddressValidationSchema.required('Required'),
        otherwise: Yup.object().notRequired(),
    }),
});

class Page extends React.Component<PageControl> {
    state = {
        isMutating: false,
    };

    onSubmit = async (formValues, action) => {
        const { customer } = this.props;

        try {
            let mutationConfig: NodeType.CreateSiteMutationInput = {
                name: formValues.name,
                entity: customer.id,
                address: {},
            };

            let { address } = formValues;
            if (address !== NEW_ADDRESS) {
                mutationConfig.address = { id: address };
            } else {
                const { newAddress } = formValues;
                let addressInput = sanitizeAddressInput(newAddress);
                mutationConfig.address = {
                    create: {
                        entity: customer.id,
                        ...addressInput,
                    },
                };
            }

            await this.props.mutateSite.create(mutationConfig);
            this.props.AppNavigator.replaceToOrigin();
        } catch (error) {
            console.error('there was an error sending the query', error);
            toastError({ title: 'Failed to create site', description: error.message });
        }
    };

    onCancel = () => {
        this.props.AppNavigator.replaceToOrigin();
    };

    render() {
        const { customer } = this.props;

        let primaryAddress = customer?.primaryAddress;

        const addresses = primaryAddress ? [primaryAddress] : [];
        const addressOptions = addresses.map((a) => ({
            key: a.id,
            value: a.id,
            text: stringFormatters.formatAddress(a),
        }));
        addressOptions.push({
            key: NEW_ADDRESS,
            value: NEW_ADDRESS,
            text: 'New address',
        });

        const initialValues = {
            name: 'Main Site',
            address: addressOptions[0].value,
            newAddress: {
                city: undefined,
                state: undefined,
                postCode: undefined,
            },
        };

        return (
            <PageLayout>
                <PageLayout.BodySection width={'screen-md'}>
                    <FormikDefaultForm
                        validationSchema={validationSchema}
                        debug={false}
                        header={'Add new site'}
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                    >
                        {(bag) => {
                            const { values } = bag;
                            const addressValue = values['address'];
                            return (
                                <>
                                    <Panel.ItemEntity label="Site owner" content={this.props.customer} />
                                    <Divider />
                                    <FormikInputField label={'Site Name'} name={'name'} required={true} autoFocus />
                                    <FormikRadioField
                                        label={'Address'}
                                        name={'address'}
                                        required={true}
                                        options={addressOptions}
                                    />
                                    {addressValue === NEW_ADDRESS && <FormikAddressInputFields name={'newAddress'} />}
                                </>
                            );
                        }}
                    </FormikDefaultForm>
                </PageLayout.BodySection>
            </PageLayout>
        );
    }
}

export default Page;
