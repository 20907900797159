import { all, throttle } from 'redux-saga/effects';
import { AUTH_ACTION, USER_ACTION, userSelectors } from '@poolware/api';
import { Zendesk } from '@poolware/app-shell';
import { store } from '../store';
import { makeDebugger } from '@ez/tools';

const debug = makeDebugger('saga:zendesk');

/* eslint-disable require-yield */

function* handleUnauth() {
    debug('handleUnauth');
    Zendesk.showSupportButton();
    Zendesk.identify(null);
}

function* handleProfileFetch(data) {
    const user = userSelectors.getUser(store.getState());
    debug('handleProfileFetch', data);
    debug('store.getState().user', userSelectors.getUser(store.getState()));
    Zendesk.hideSupportButton();
    Zendesk.identify(user);
}

function* watchZendesk() {
    yield all([
        yield throttle(1000, AUTH_ACTION.UNAUTHENTICATED, handleUnauth),
        yield throttle(1000, USER_ACTION.FETCH_PROFILE_SUCCESS, handleProfileFetch),
    ]);
}

export default watchZendesk;
