import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';
import { warrantyClaimFragment } from '../../fragments';

const QL = gql`
    query QueryWarrantyClaimCase($id: ID!) {
        node(id: $id) {
            id
            ... on WarrantyClaimCase {
                id
                externalSRN
                externalSO
                externalRMA
                note
                lastMessageAt
                lastMessageReadAt
                claim {
                    id
                    ...WarrantyClaimFragment
                }
            }
        }
    }
    ${warrantyClaimFragment}
`;

export const useQueryWarrantyClaimCase = createUseQueryNodeHook<NodeType.WarrantyClaimCase>(QL);
