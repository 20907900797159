import { call, cancelled, put, race, take } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { NodeType } from '@poolware/api';
import { isTouch } from '@ez/tools';
import { BOOKING_ACTION, BookingAction } from './reducer-appointment-booking';
import { CalViewLayoutMode } from '../Scheduler';
import { CalendarAction } from './reducers-calendar-state';

export const createWatchAppointmentBookingDuplicate = (store, URLBuilder) => {
    const sagaName = 'SAGA_APPOINTMENT_DUPLICATE';

    function* handler(sagaName, { payload }) {
        const navigate = (url, state: any = {}) => {
            return navigateActions.browserNavigation(url, { ...state, saga: sagaName });
        };

        const appointmentItem: NodeType.AppointmentItem = payload.appointmentItem || {};

        try {
            yield put(BookingAction.setSagaMode(true));
            yield put(CalendarAction.setLayoutMode(CalViewLayoutMode.GRID));
            yield put(
                BookingAction.setDetails({
                    startDate: appointmentItem.startDate,
                    duration: appointmentItem.duration,
                    staff: appointmentItem.staff,
                    customer: appointmentItem.customer,
                    // disable recurrence when duplicating appointment
                    isRecurring: false, // appointmentItem.isRecurring,
                    recurrence: appointmentItem?.appointment?.recurrence,
                    pool: appointmentItem.pool,
                    address: appointmentItem.address,
                    color: appointmentItem.color,
                    group: appointmentItem.group,
                    note: appointmentItem.note,
                    // serviceJob: appointmentItem.serviceJob,
                    followUpFromAppointment: appointmentItem,
                    followUpFromWorkOrder: appointmentItem?.workOrder,
                })
            );

            yield put(navigate(URLBuilder.Scheduler.home));

            if (!isTouch) {
                yield put(BookingAction.setSelectingSlotMode(true));
                yield take([BOOKING_ACTION.TIME_SLOT_SELECTED]);
            }

            yield put(navigate(URLBuilder.Scheduler.new, { modal: true }));

            yield take([BOOKING_ACTION.BOOKING_CREATED]);

            yield put(navigate(URLBuilder.Scheduler.home));
        } catch (e) {
            yield put(BookingAction.abort());
        } finally {
            if (yield cancelled()) {
                yield put(navigate(URLBuilder.Scheduler.home));
            }
        }
    }

    function* abortWatcher(sagaName) {
        yield race({
            navigationAbort: call(sagaAbortOnNavigation, sagaName),
            actionAbort: take(BOOKING_ACTION.ABORT),
        });
    }

    function* watch() {
        while (true) {
            yield put(BookingAction.reset());
            const startAction = yield take(BOOKING_ACTION.START_APPT_DUPLICATE);
            yield race({
                saga: call(handler, sagaName, startAction),
                abort: call(abortWatcher, sagaName),
            });
        }
    }

    return watch;
};
