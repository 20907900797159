import { Action, createAction, createReducer } from '@ez/tools';
import { SortDirection } from '../../graphql-api';

const _createActionType = (actionScope: string, field: string) => {
    return actionScope + '/' + field;
};

export type ConnectionPageStateType = {
    index: number;
    size: number;
    sortDirection: SortDirection;
    sortKey: string;
};

export const defaultConnectionPageState: ConnectionPageStateType = {
    index: 0,
    size: 30,
    sortDirection: SortDirection.DES,
    sortKey: null,
};

export type CreatePageIndexReducerOptionType = {
    // List of action types that would reset page index to 0 when issued
    resetIndexActionTypes?: string[];
    // initial page state
    initialState?: ConnectionPageStateType;
    // scope
    scope?: string;
};

export const createPageIndexReducerActions = (opt: CreatePageIndexReducerOptionType) => {
    const initialState = { ...defaultConnectionPageState, ...opt?.initialState };
    const indexActionType = _createActionType(opt.scope, 'PAGE_INDEX');
    const sizeActionType = _createActionType(opt.scope, 'PAGE_SIZE');
    const sortKeyActionType = _createActionType(opt.scope, 'PAGE_SORT_KEY');
    const sortDirActionType = _createActionType(opt.scope, 'PAGE_SORT_DIR');

    const reducer = createReducer(
        initialState,
        (state: CreatePageIndexReducerOptionType['initialState'], action: Action<string, any>) => {
            if (opt?.resetIndexActionTypes?.includes(action.type)) {
                state.index = 0;
            } else if (indexActionType === action.type) {
                state.index = action.payload;
            } else if (sizeActionType === action.type) {
                state.size = action.payload;
                state.index = 0;
            } else if (sortKeyActionType === action.type) {
                state.sortKey = action.payload;
                state.index = 0;
            } else if (sortDirActionType === action.type) {
                state.sortDirection = action.payload;
                state.index = 0;
            }
            return state;
        },
        opt.scope
    );

    const actions = {
        setPageIndex: (index: number) => createAction(indexActionType, index, opt.scope),
        setPageSize: (size: number) => createAction(sizeActionType, size, opt.scope),
        setSortKey: (key: string) => createAction(sortKeyActionType, key, opt.scope),
        setSortDirection: (dir: SortDirection) => createAction(sortDirActionType, dir, opt.scope),
    };

    return {
        reducer,
        actions,
    };
};
