import * as React from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display, TruncatedDiv } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { useQueryWarrantyClaimMessages } from './use-query-warranty-claim-case-connection';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Icon } from 'semantic-ui-react';
import { CellText } from '../components';
import { useFilterWarrantyMessages } from './use-filter-warranty-messages';

function truncateString(str, num) {
    if (str?.length <= num) {
        return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...';
}

const checkIsUnread = (message: NodeType.WarrantyClaimMessage) => {
    return !message.isRead;
};

export const PageList: React.FC = ({}) => {
    const { AppNavigator } = useAppNavigator();
    const { state, actions } = useFilterWarrantyMessages();
    const { connectionData, connectionState } = useQueryWarrantyClaimMessages({
        pageIndex: state.pageIndex,
        setPageIndex: actions.setPageIndex,
    });

    const tableDef: ConnectionTableDef<NodeType.WarrantyClaimMessage> = [
        {
            header: 'Date',
            width: 1,
            cell: (r) => {
                const isUnread = checkIsUnread(r);
                return (
                    <>
                        <div style={{ display: 'inline-block', width: '20px' }}>
                            {isUnread && <Icon name={'mail'} color={'blue'} />}
                        </div>
                        <CellText bold={isUnread}>{<Display.Date format={'lll'} value={r.updatedAt} />}</CellText>
                    </>
                );
            },
        },

        {
            header: 'From',
            width: 2,
            cell: (r) => {
                return (
                    <div>
                        {r.addedBy?.root?.franchise?.name} <Icon name={'caret right'} />
                        <i style={{ color: '#555555', fontSize: '0.9em' }}>
                            <Display.Entity value={r.addedBy} />
                        </i>
                    </div>
                );
            },
        },

        {
            header: 'Message',
            width: 8,
            cell: (r) => {
                return <TruncatedDiv maxWidth={500}>{truncateString(r.message, 1000)}</TruncatedDiv>;
            },
        },
        {
            header: 'Claim ID',
            width: 1,
            cell: (r) => {
                return r.claim?.warrantyClaimNumber;
            },
        },
    ];

    const onView = (message: NodeType.WarrantyClaimMessage) => {
        const caseId = message?.claim?.case?.id;
        AppNavigator.navigateRelative(`/wrt-cases/${caseId}`, { setOrigin: true });
    };

    return (
        <DefaultConnectionTable
            onRowClick={onView}
            tableProps={{ color: 'grey' }}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
