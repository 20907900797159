import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { ModuleLink, useAppNavigator } from '@poolware/react-app-navigator';
import { Message } from 'semantic-ui-react';
import { ConnectionErrorMessage, DebugJSON, DevOnly, PageSkeletonLoader, toastError } from '@poolware/components';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { fromEdges, NodeType } from '@poolware/api';

const QL = gql`
    query findPoolByNumber($poolNumber: String!) {
        viewer {
            resolvePoolByBottleNumber(bottleNumber: $poolNumber) {
                id
                bottleNumber
            }
        }
    }
`;

const useQueryResolvePoolId = (input: { poolNumber: string }) => {
    const res = useQuery<NodeType.Query>(QL, {
        variables: { poolNumber: input.poolNumber },
        skip: !input.poolNumber,
    });
    let pool = undefined;
    if (!res.loading && !res.error) {
        pool = res.data?.viewer?.resolvePoolByBottleNumber;
    }

    return {
        ...res,
        pool,
    };
};

type URLParams = {};
type URLQuery = {
    testRefId: string;
    poolNumber: string;
};

// Required for LS Retail Integration
// Must not be removed
export const RedirectToNewWaterTest: React.FC = () => {
    const { query, AppNavigator } = useAppNavigator<URLParams, URLQuery>();
    const { testRefId, poolNumber } = query;
    const openedRef = useRef(false);

    const queryPool = useQueryResolvePoolId({ poolNumber });
    const poolId = queryPool?.pool?.id;

    const redirectURL = useMemo(() => {
        if (!poolId) {
            return null;
        }
        return `/new?testRefId=${testRefId}&poolId=${poolId}`;
    }, [testRefId, poolId]);

    useEffect(() => {
        if (!poolId || !testRefId || openedRef.current) {
            return;
        }
        openedRef.current = true;
        try {
            AppNavigator.replace('/new', { moduleId: 'WATER_TEST', query: { testRefId, poolId } });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    }, [redirectURL, testRefId, poolId, openedRef]);

    if (!poolNumber) {
        return (
            <Message>Expected a pool number to be provided in the URL (e.g. .../new?poolNumber='P001-000123' )</Message>
        );
    }
    if (!testRefId) {
        return (
            <Message>
                Expected <code style={{ color: 'blue' }}>testRefId</code> to be provided in the URL (e.g.
                .../new?testRefId='UNIQUE_RANDOM_ID' )
            </Message>
        );
    }
    if (queryPool.loading) {
        return <PageSkeletonLoader />;
    } else if (queryPool.error) {
        return <ConnectionErrorMessage error={queryPool.error} />;
    } else if (!queryPool.loading && !queryPool.pool) {
        return (
            <Message>
                Pool with Pool Number <code style={{ color: 'blue' }}>{poolNumber}</code> is not found
            </Message>
        );
    }

    return (
        <div>
            <h3>Redirecting...</h3>
            {redirectURL && (
                <div>
                    <p>If page does not redirect automatically, please click on the link below.</p>
                    <p>
                        <ModuleLink moduleId={'WATER_TEST'} to={redirectURL}>
                            {redirectURL}
                        </ModuleLink>
                    </p>
                </div>
            )}
            <DevOnly>
                <div style={{ paddingTop: '3rem', color: '#888888', flexDirection: 'column', fontSize: 'small' }}>
                    <div>Pool Number: {poolNumber}</div>
                    <div>Pool id: {queryPool.pool?.id}</div>
                    <div>Ref id: {testRefId}</div>
                    <DebugJSON data={queryPool.pool} />
                </div>
            </DevOnly>
        </div>
    );
};
