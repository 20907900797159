import * as React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { Display, Panel } from '@poolware/components';
import WaterTestMeasurementTable from '../WaterTestMeasurementTable';
import { fromEdges } from '@poolware/api';
import { PageControlProps } from './TreatmentRecommendations.Control';
import TreatmentRecommendationsTable from './TreatmentRecommendationsTable';

class Page extends React.Component<PageControlProps, any> {
    render() {
        const { report, chemicalTargets = [] } = this.props;

        if (!report) return <i>Report is not available</i>;

        let { recommendations } = report;

        const deEdgedRecommendations = fromEdges(recommendations);

        if (deEdgedRecommendations.length === 0) return <i>No problems or recommendations discovered</i>;

        const { isArchived } = report;

        const samples = fromEdges(report.outcome);
        return (
            <>
                <>
                    {deEdgedRecommendations.map((recommendation, index) => {
                        let { problem } = recommendation;

                        if (!problem) {
                            return null;
                        }

                        return (
                            <React.Fragment key={index}>
                                <Segment color={'orange'}>
                                    <Header
                                        key={recommendation.id + index}
                                        size={'small'}
                                        content={`Problem #${index + 1} - ${problem?.name}`}
                                        dividing
                                    />

                                    {problem.description && (
                                        <Display.Text
                                            style={{ padding: '5px 0', fontSize: '1em' }}
                                            value={problem.description}
                                        />
                                    )}

                                    <TreatmentRecommendationsTable
                                        {...this.props}
                                        isArchived={isArchived}
                                        recommendation={recommendation}
                                    />
                                </Segment>
                                {/*<Divider hidden/>*/}
                            </React.Fragment>
                        );
                    })}
                </>

                {!isArchived && (
                    <>
                        <Header as="h2" dividing>
                            <Icon name="treatment" />
                            Treatment Outcome
                        </Header>

                        <Panel>
                            <Panel.Header content="Expected Treatment Outcome" />
                            <Panel.Body>
                                <WaterTestMeasurementTable
                                    samples={samples}
                                    chemicalTargets={chemicalTargets}
                                    color="green"
                                />
                            </Panel.Body>
                        </Panel>
                    </>
                )}
            </>
        );
    }
}

export default Page;
