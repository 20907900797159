import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { NodeType, QueryConnectionResult } from '@poolware/api';
import { ConnectionTableDef, DefaultConnectionTable, Panel, PanelHeaderProps } from '@poolware/components';
import { Button, Checkbox } from 'semantic-ui-react';
import { useSafeState } from '@ez/tools';

export interface TablePickerPanelProps<Item extends NodeType.Node = NodeType.Node> extends QueryConnectionResult<Item> {
    onSubmit: (selectedItems: Item[]) => any;
    onCancel: () => any;
    hideItems?: Item[];
    defaultSelectedItems?: Item[];
    tableDef: ConnectionTableDef<Item>;
    panelHeader?: PanelHeaderProps;
    multiSelect?: Boolean;
    isRowSelectable?: (item: Item) => Boolean;
}

export const TablePickerPanel: React.FC<TablePickerPanelProps> = ({
    connectionData,
    connectionState,
    onSubmit,
    onCancel,
    hideItems: externalSelectedItems = [],
    defaultSelectedItems = [],
    tableDef: externalTableDef,
    panelHeader,
    multiSelect = true,
    isRowSelectable = () => true,
}) => {
    const [submitting, setSubmitting] = useSafeState(false);
    const [selectedItems, setSelectedItems] = useState<NodeType.JobTodoTemplate[]>(defaultSelectedItems);

    const filteredConnectionData = connectionData.filter((t) => {
        return externalSelectedItems.findIndex((et) => et.id === t.id) === -1;
    });

    const handleOnCancel = () => {
        onCancel?.();
    };

    const handleOnSubmit = async () => {
        setSubmitting(true);
        await onSubmit?.(selectedItems);
        setSubmitting(false);
    };

    const onRowClick = (item: NodeType.JobTodoTemplate) => {
        const isSelected = selectedItems.findIndex((p) => p.id === item.id) !== -1;

        if (multiSelect) {
            if (isSelected) {
                // remove clicked row from the list
                setSelectedItems((selectedItems) => {
                    return selectedItems.filter((i) => i.id !== item.id);
                });
            } else {
                // add clicked row to the list
                setSelectedItems((selectedItems) => {
                    return [...selectedItems, item];
                });
            }
        } else {
            if (isSelected) {
                // deselect all
                setSelectedItems([]);
            } else {
                // select only one
                setSelectedItems([item]);
            }
        }
    };

    const memoizedTableDef: ConnectionTableDef<NodeType.JobTodoTemplate> = useMemo(() => {
        const tableDef: ConnectionTableDef<NodeType.JobTodoTemplate> = [
            {
                header: '',
                width: 1,
                cell: (item) => {
                    const isSelected = selectedItems.findIndex((p) => p.id === item.id) !== -1;
                    return <Checkbox checked={isSelected} disabled={!isRowSelectable?.(item)} />;
                },
            },
            ...externalTableDef,
        ];
        return tableDef;
    }, [selectedItems, externalTableDef, externalSelectedItems, connectionData]);

    return (
        <Panel>
            <Panel.Header {...panelHeader} />
            <Panel.Body>
                <DefaultConnectionTable
                    isRowSelectable={isRowSelectable}
                    onRowClick={onRowClick}
                    connectionData={filteredConnectionData}
                    connectionState={connectionState}
                    tableDef={memoizedTableDef}
                />
            </Panel.Body>
            <Panel.Footer>
                <Button
                    floated={'left'}
                    onClick={handleOnCancel}
                    content={'Cancel'}
                    secondary={true}
                    basic={true}
                    disabled={submitting}
                />
                <Button
                    floated={'right'}
                    loading={submitting}
                    onClick={handleOnSubmit}
                    content={'Select'}
                    primary={true}
                    disabled={submitting || selectedItems.length === 0}
                />
            </Panel.Footer>
        </Panel>
    );
};
