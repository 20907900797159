import * as React from 'react';
import {
    DebugJsonButton,
    MenuBar,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    Panel,
    Stack,
    StickyMenuBar,
    toastError,
    toastSuccess,
} from '@poolware/components';
import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';
import { Divider, Header, Menu, Modal, Segment } from 'semantic-ui-react';
import { PanelItemEditable } from '../../../components/PanelItemEditable';
import TraitDeclarationFieldsTable from './TraitDeclarationFieldsTable';
import { availableTraitDeclarationFieldTypes, TraitDeclarationFieldFormConfig } from '../../types';
import NewTraitDeclarationField from './NewTraitDeclarationFieldForm';
import EditTraitDeclarationFieldForm from './EditTraitDeclarationFieldForm';
import { TraitRelationshipTree } from './TraitRelationshipViewer';
import { FranchiseEditableItem } from '../../Products/View/EditableItems';
import { PageControlProps } from './View.Control';

const TraitTagInfoString = `Tag is a unique string that is used to mark a trait that have a special meaning.
    Tags can be used to fetch products with certain traits (e.g. "heater" products).
Then those products can be used in various scenarios, e.g. in the heat pump calculator.`;

export default class ViewPage extends React.Component<PageControlProps> {
    state = {
        updatingDeclarations: false,
        showNewFieldForm: undefined,
        showEditFieldForm_config: undefined,
        showEditFieldForm_value: undefined,
    };

    goToList = () => {
        this.props.AppNavigator.navigate('/', { relativeToModule: true });
    };

    onUpdate = (name: string) => async (newValue: string) => {
        try {
            await this.props.ProductCatalogMutator.updateTraitDeclaration(this.props.traitDeclaration, {
                [name]: newValue ? _.trim(newValue) : null,
            });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    onAddSubdeclaration = (declaration: NodeType.ProductTraitDeclaration) => {
        this.props.AppNavigator.navigate('/new', {
            relativeToModule: true,
            setOrigin: true,
            modal: false,
            state: {
                parent: declaration,
            },
        });
    };
    onDelete = async (pd: NodeType.ProductTraitDeclaration) => {
        try {
            await this.props.ProductCatalogMutator.deleteDeclaration(pd);
            toastSuccess({ title: 'Trait Declaration Deleted!' });
            this.goToList();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    onAddField = (config: TraitDeclarationFieldFormConfig) => {
        this.setState({ showNewFieldForm: config });
    };

    hideNewFieldForm = () => {
        this.setState({ showNewFieldForm: undefined });
    };

    onEditField = (config: TraitDeclarationFieldFormConfig, fieldValue: any) => {
        this.setState({ showEditFieldForm_config: config, showEditFieldForm_value: fieldValue });
    };

    hideEditFieldForm = () => {
        this.setState({ showEditFieldForm_config: undefined, showEditFieldForm_value: undefined });
    };

    render() {
        const { traitDeclaration } = this.props;
        const menuItems = availableTraitDeclarationFieldTypes.map((conf, index) => ({
            key: index,
            name: conf.name,
            onClick: () => this.onAddField(conf),
        }));

        return (
            <Stack>
                <StickyMenuBar>
                    <MenuBar.Section>
                        {/*<MenuBarItem icon={'chevron left'} color={'grey'} onClick={this.goToList} content={'Back'} />*/}
                    </MenuBar.Section>
                    <MenuBar.Section position={'right'}>
                        <DebugJsonButton data={traitDeclaration} />
                        <MenuBar.Dropdown icon={'bars'} color={'red'}>
                            <MenuBar.DropdownItemWithConfirm
                                icon={'trash'}
                                color="red"
                                title={'Delete'}
                                confirm={{
                                    confirmMessage: {
                                        header: 'Delete?',
                                        content: 'Will be deleted permanently!!!',
                                    },
                                    confirmButton: {
                                        content: 'Delete Declaration',
                                        icon: 'trash',
                                    },
                                    negative: true,
                                }}
                                name="deleteButton"
                                onClick={() => this.onDelete(traitDeclaration)}
                            />
                        </MenuBar.Dropdown>
                    </MenuBar.Section>
                </StickyMenuBar>

                <Panel>
                    <Panel.Header content={'Trait Declaration'} />
                    <Panel.Body>
                        <PanelItemEditable
                            label={'Name'}
                            content={traitDeclaration.name}
                            onCommit={this.onUpdate('name')}
                        />
                        <PanelItemEditable
                            label={'Trait Tag'}
                            labelInfo={TraitTagInfoString}
                            content={traitDeclaration.tag}
                            onCommit={this.onUpdate('tag')}
                        />
                        <Divider fitted />
                        <Header size={'small'} content={'Declaration owner'} />
                        <Panel.Item label={'Organisation'}>
                            {_.get(traitDeclaration, 'organisationType.name')}
                        </Panel.Item>
                        <Panel.Item label={'Franchise'}>
                            <div style={{ maxWidth: '400px' }}>
                                <FranchiseEditableItem
                                    value={traitDeclaration.franchise}
                                    onChange={(value) => this.onUpdate('franchise')(value ? value.id : null)}
                                />
                            </div>
                        </Panel.Item>
                        <Divider />
                        <Panel.Item label={'Hierarchy'}>
                            <TraitRelationshipTree
                                onAddSubdeclaration={this.onAddSubdeclaration}
                                onUpdate={this.onUpdate('parent')}
                                traitDeclaration={traitDeclaration}
                            />
                        </Panel.Item>
                    </Panel.Body>
                </Panel>

                <Panel>
                    <Panel.Header
                        button={[
                            {
                                icon: 'plus',
                                content: 'Add Field',
                                menuItems: menuItems,
                            },
                        ]}
                        content={'Fields'}
                    />
                    <Panel.Body>
                        <div tw={'space-y-8'}>
                            {availableTraitDeclarationFieldTypes.map((config) => {
                                const fields = fromEdges(traitDeclaration[config.mutationFiledName]);
                                if (fields.length === 0) {
                                    return null;
                                }
                                return (
                                    <div key={config.name}>
                                        <Header sub>{config.name}</Header>
                                        <TraitDeclarationFieldsTable
                                            traitDeclarationId={traitDeclaration.id}
                                            onEdit={(value) => this.onEditField(config, value)}
                                            config={config}
                                            fields={fields}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Panel.Body>
                </Panel>

                <Modal open={!!this.state.showNewFieldForm}>
                    <NewTraitDeclarationField
                        traitDeclarationId={traitDeclaration.id}
                        onCancel={this.hideNewFieldForm}
                        onSubmit={this.hideNewFieldForm}
                        config={this.state.showNewFieldForm || availableTraitDeclarationFieldTypes[3]}
                    />
                </Modal>

                <Modal open={!!this.state.showEditFieldForm_config}>
                    <EditTraitDeclarationFieldForm
                        traitDeclarationId={traitDeclaration.id}
                        traitDeclarationField={this.state.showEditFieldForm_value}
                        onCancel={this.hideEditFieldForm}
                        onSubmit={this.hideEditFieldForm}
                        config={this.state.showEditFieldForm_config}
                    />
                </Modal>
            </Stack>
        );
    }
}
