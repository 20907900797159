export type CRUD = {
    root: string;
    paramName?: string;
    all?: string;
    new?: string;
    view?: string;
    edit?: string;
} & { [key: string]: any };

export const Scheduler = {
    home: `/scheduler`,
    new: `/scheduler/new`,
    where: `/scheduler/where`,
    view: (id?: string) => `/scheduler/${id}`,
    edit: (id?: string) => `/scheduler/${id}/edit`,
    editAppointmentGroup: (id?: string) => `/scheduler/appointment-group/${id}`,
    newAppointmentGroup: `/scheduler/appointment-group/new`,
};

export const Customer = (customerId?: string) => {
    let customerView = `/customers/${customerId}`;
    return {
        all: `/customers`,
        new: `/customers/new`,
        view: customerView,
        edit: customerView + '/edit',

        addSite: `${customerView}/add-site`,
        Site: (siteId?: string) => {
            let siteView = `${customerView}/site/${siteId}`;
            return {
                edit: `${siteView}/edit`,
                editAccessKeyLocation: `${siteView}/edit-access-key`,
                transferOwnership: `${siteView}/transfer-ownership`,
            };
        },

        addPool: `${customerView}/add-pool`,
        Pool: (poolId?: string) => {
            let poolView = `${customerView}/pools/${poolId}`;
            return {
                view: poolView,
                edit: `${customerView}/pools/${poolId}/edit`,
                editEquipmentList: `${customerView}/pools/${poolId}/equipment`,
                addRawDataSample: `${customerView}/pools/${poolId}/add-raw-data-sample`,
                WaterTest: (testId?: string) => {
                    return {
                        new: `${poolView}/tests/new`,
                        view: `${poolView}/tests/${testId}`,
                        edit: `${poolView}/tests/${testId}/edit`,
                    };
                },
            };
        },
    };
};

export const Print = () => {
    const root = `/print`;
    return {
        root: root,
        AppointmentItem: (appointmentItemId?: string) => {
            const subPath = 'scheduler/appt';
            const fullPath = `${root}/${subPath}`;
            return {
                subPath: subPath,
                paramName: ':appointmentItemId',
                list: `${fullPath}/list`,
                view: `${fullPath}/${appointmentItemId}`,
            };
        },
    };
};
