import * as React from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    Panel,
    StickyMenuBar,
    toastError,
    toastSuccess,
    VStack,
    withApolloLoading,
} from '@poolware/components';
import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import * as _ from 'lodash';
import gql from 'graphql-tag';
import { IMutatePoolProductDeclaration, NodeType, withPoolProductDeclarationMutator } from '@poolware/api';
import { PanelItemEditable } from '../../../components/PanelItemEditable';
import { Divider, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { QueryProductConnection } from '../../../queries';

const Page: React.FC<PageControlProps> = ({ poolTrait, AppNavigator, mutatePoolProductDeclaration }) => {
    const onDelete = async () => {
        try {
            await mutatePoolProductDeclaration.delete({ id: poolTrait.id });
            toastSuccess({ title: 'Deleted successfully', icon: 'trash' });
            AppNavigator.replace('/', { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };
    const onGoBack = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    const onUpdate = (name: string) => async (newValue: string) => {
        try {
            await mutatePoolProductDeclaration.update({
                id: poolTrait.id,
                [name]: newValue ? _.trim(newValue) : null,
            });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    const tableDef: ConnectionTableDef<NodeType.Product> = [
        {
            header: 'SKU',
            cellProps: {
                width: 2,
            },
            cell: (pd) => _.get(pd, 'sku', '--'),
        },
        {
            header: 'Name',
            cell: (pd) => <Link to={`/product-catalog/products/${pd.id}`}>{_.get(pd, 'name', '--')}</Link>,
        },
        {
            header: '',
            cell: (pd) => {
                const { franchise } = pd;
                if (franchise) {
                    return (
                        <div>
                            <Icon name={'building'} />
                            {franchise.name}
                        </div>
                    );
                }
                return null;
            },
        },
    ];

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem icon={'chevron left'} title={'Back'} onClick={onGoBack} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown icon={'bars'} color={'red'}>
                        <MenuBarDropdownItemWithConfirm
                            icon={'trash'}
                            title={'Delete'}
                            color={'red'}
                            onClick={onDelete}
                            confirm={{
                                confirmButton: {
                                    content: 'Delete',
                                    icon: 'trash',
                                    negative: true,
                                },
                            }}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>
            <Panel>
                <Panel.Header>Pool Trait Declaration</Panel.Header>
                <Panel.Body>
                    <PanelItemEditable label={'Name'} content={poolTrait.name} onCommit={onUpdate('name')} />
                    <Panel.Item label={'Trait Declaration'}>{poolTrait.declaration.name}</Panel.Item>
                </Panel.Body>
            </Panel>

            <Divider />
            <Header size={'small'} content={`Products associated with "${poolTrait.name}"`} />

            <QueryProductConnection declarationIds={[poolTrait.declaration.id]}>
                {({ connectionData, connectionState }) => {
                    return (
                        <DefaultConnectionTable
                            connectionData={connectionData}
                            connectionState={connectionState}
                            tableDef={tableDef}
                            noDataComponent={<span>No products</span>}
                        />
                    );
                }}
            </QueryProductConnection>
        </VStack>
    );
};

const QL = gql`
    query PoolTraitDeclaration($id: ID!) {
        poolTrait: node(id: $id) {
            id
            ... on PoolProductDeclaration {
                name
                declaration {
                    id
                    name
                }
                optional
                priority
            }
        }
    }
`;

export default compose(
    withAppNavigator(),
    graphql(QL, {
        options: (props: IAppNavigatorProps) => {
            return {
                variables: { id: props.params.id },
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.poolTrait.id' }),
    mapProps((props) => {
        const poolTrait = _.get(props, 'data.poolTrait');
        return {
            ...props,
            poolTrait,
        };
    }),
    withPoolProductDeclarationMutator(['PoolProductDeclarations'])
)(Page);

interface PageControlProps extends IAppNavigatorProps, IMutatePoolProductDeclaration {
    poolTrait: NodeType.PoolProductDeclaration;
}
