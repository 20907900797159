import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import {
    MenuBarItem,
    MenuBarSection,
    Panel,
    StickyMenuBar,
    toastError,
    toastSuccess,
    VStack,
    withApolloLoading,
} from '@poolware/components';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import ProductTraitsPanel from './ProductTraitsPanel';
import { queryNames } from '../query-names';
import { withProductNode } from '../../../queries/withProductNode';
import { Container } from 'semantic-ui-react';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

const BrandLabel: React.FC<{ brand: NodeType.Brand }> = ({ brand }) => {
    if (!brand) return null;
    const brandName = _.get(brand, 'name');
    const companyName = _.get(brand, 'company.name');

    return (
        <>
            {companyName ? (
                <>
                    <span style={{ fontStyle: 'italic' }}>{companyName}</span>
                    {' > '} <>{brandName}</>
                </>
            ) : (
                <>{brandName}</>
            )}
        </>
    );
};

const ProductDescriptionPanel: React.FC<{ product: NodeType.Product }> = ({ product }) => {
    return (
        <Panel>
            <Panel.Header content={'Product'} />
            <Panel.Body>
                <Panel.Item label="SKU" content={product.sku || '--'} />
                <Panel.Item label="Name" content={product.name} />
                <Panel.Item label={'Brand'}>
                    <BrandLabel brand={product.brand} />
                </Panel.Item>
                <Panel.Item label={'Description'} content={product.description} />
            </Panel.Body>
        </Panel>
    );
};

class Page extends React.Component<PageControlProps> {
    state = {
        updatingDeclarations: false,
    };

    goToList = () => {
        this.props.AppNavigator.navigate('/', { relativeToModule: true });
    };

    onUpdate = (name: string) => async (newValue: string | object) => {
        // console.log('Update', name, newValue);
        try {
            const values: any = { [name]: newValue };
            await this.props.ProductCatalogMutator.updateProducts([{ product: this.props.product, ...values }]);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    onDelete = async () => {
        try {
            await this.props.ProductCatalogMutator.deleteProducts([this.props.product]);
            toastSuccess({ title: 'Product deleted!', icon: 'archive' });
            this.goToList();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    renderStickyMenu() {
        return (
            <StickyMenuBar>
                <MenuBarSection position={'left'}>
                    <MenuBarItem icon={'chevron left'} color={'grey'} onClick={this.goToList}>
                        Products
                    </MenuBarItem>
                </MenuBarSection>
                <MenuBarSection position={'right'} />
            </StickyMenuBar>
        );
    }

    render() {
        const { product } = this.props;
        return (
            <VStack>
                {this.renderStickyMenu()}
                <Container>
                    <VStack>
                        <ProductDescriptionPanel product={product} />
                        <ProductTraitsPanel productId={product.id} />
                    </VStack>
                </Container>
            </VStack>
        );
    }
}

export default compose(
    withAppNavigator(),
    withProductNode((props: IAppNavigatorProps) => props.params.id),
    withApolloLoading({ show404ForPath: 'data.node.id' }),
    mapProps((props) => {
        const product = _.get(props, 'data.node');
        return {
            ...props,
            product,
        };
    }),
    withProductCatalogMutators(queryNames)
)(Page);

export interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    product: NodeType.Product;
    brands: NodeType.Brand[];
    companies: NodeType.ProductCompany[];
}
