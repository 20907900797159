import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import * as React from 'react';
import View from './View';
import List from './List';

export default () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={'/:id'} component={View} />
            <ModuleRoute path={'/'} exact component={List} />
        </ModuleRootSwitch>
    );
};
