import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormikHeatCalcValueType, MonthlyHeatPumpProfile, PoolCover } from '../common/types';
import { computeHeatPumpHeatProfile } from './calc-heat-profile';
import { computeRecommendedHeatPumpModel } from './calc-heater-model';
import { useFormikContext } from 'formik';
import { computeCostEstimationHeatPump } from './calc-cost-estimation';
import { useCatalog } from '../common/use-catalog';
import { CalcLayoutConfigurable, LayoutConfig } from '../common/CalcLayout';
import {
    FormikHeatPumpTempRequirements,
    FormikPoolProfile,
    FormikSelectLocationYearProfile,
} from '../common/FormikInputComponents';
import { FormikDocTypeToggleButtons } from '../common/FormikDocTypeToggleButtons';
import { FormikEquipmentOptionsTable } from '../common/FormikEquipmentOptionsTable';
import { PanelDocumentDetails } from '../common/PanelDocumentDetails';
import { PanelHeaterRecommendation } from '../common/PanelHeaterRecommendation';
import { PanelProductBrochure } from '../common/PanelProductBrochure';
import { MonthlyDataTable } from './MonthlyDataTable';
import { FormikQuickMonthSelectButtons } from '../common/FormikQuickMonthSelectButtons';

interface HeatingCalcsProps {
    print?: boolean;
}

// const AUTOPOOL_operatingDailyHoursPerMonth = [3, 4, 6, 7, 8, 10, 11, 10, 8, 7, 6, 3];
export const AUTOPOOL_operatingDailyHoursPerMonth = [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8];

export const HeatPumpAutopoolCalc: React.FC<HeatingCalcsProps> = ({ print }) => {
    const { values, setFieldValue } = useFormikContext<FormikHeatCalcValueType>();
    const { heatPumps } = useCatalog();

    const poolProfileSettings = useMemo(() => {
        let operatingDailyHoursPerMonth = AUTOPOOL_operatingDailyHoursPerMonth;
        // if (operatingDailyHoursPerMonth?.length !== 12) {
        //     operatingDailyHoursPerMonth = Array.from({ length: 12 }, () => values.operatingHours);
        // }
        return {
            selectedMonths: values.selectedMonths,
            yearProfile: values.yearProfile,
            operatingDailyHours: values.operatingHours,
            operatingDailyHoursPerMonth: operatingDailyHoursPerMonth,
            poolType: values.poolType,
            poolVolume: values.poolVolume,
            poolSurfaceAreaTop: values.poolSurfaceAreaTop,
            poolLocation: values.poolLocation,
            poolTemp: values.poolTemp,
            lowestAirTemp: values.lowestAirTemp,
            poolCover: values.poolCover,
            surfaceAreaTop: values.poolSurfaceAreaTop,
        };
    }, [
        values.selectedMonths,
        values.yearProfile,
        values.operatingHours,
        values.poolType,
        values.poolVolume,
        values.poolSurfaceAreaTop,
        values.poolLocation,
        values.poolTemp,
        values.lowestAirTemp,
        values.poolCover,
        values.electCost,
    ]);

    // console.log(poolProfileSettings);
    const monthHeatProfiles = computeHeatPumpHeatProfile(poolProfileSettings);

    const recommendations = useMemo(
        () =>
            computeRecommendedHeatPumpModel({
                monthlyHeatProfiles: monthHeatProfiles,
                heaterCatalog: heatPumps.models,
            }),
        [heatPumps, monthHeatProfiles]
    );

    useEffect(() => {
        setFieldValue('autoRecommendedModel.hpump', recommendations.recommendedHeater);
    }, [recommendations?.recommendedHeater?.sku]);

    const selectedModel = values.userSelectedModel?.hpump || recommendations?.recommendedHeater;

    useEffect(() => {
        const cost = computeCostEstimationHeatPump({
            effectiveCapacityFactor: 0.65,
            monthHeatProfiles: monthHeatProfiles,
            selectedModel: selectedModel,
            withCover: poolProfileSettings.poolCover === PoolCover.Yes,
            electricityCost: values.electCost,
            operatingDailyHoursPerMonth: poolProfileSettings.operatingDailyHoursPerMonth,
            minimumOutput: recommendations.minimumOutput,
        });
        setFieldValue('costEstimation.hpump', cost);
    }, [selectedModel, poolProfileSettings]);

    const costEstimation = values.costEstimation?.hpump;
    const selectedMonths = values.selectedMonths;
    const setSelectedItems = (selectedItems) => {
        setFieldValue('selectedMonths', selectedItems);
    };

    const conf: LayoutConfig = {
        left: [
            <FormikSelectLocationYearProfile />, //
            <FormikPoolProfile poolType={false} poolPosition={false} poolLocation={false} />,
            <FormikHeatPumpTempRequirements operatingHours={false} />,
        ],
        middle: [
            <FormikDocTypeToggleButtons />,
            <FormikQuickMonthSelectButtons />,
            <MonthlyDataTable
                monthlyHeatProfiles={monthHeatProfiles as MonthlyHeatPumpProfile[]}
                costEstimation={costEstimation}
                selectedMonths={selectedMonths}
                setSelectedItems={setSelectedItems}
            />,
            <FormikEquipmentOptionsTable />,
        ],
        right: [
            <PanelDocumentDetails />,
            <PanelHeaterRecommendation />,
            <PanelProductBrochure />,
            // <FormikQuickLocationSelectDebug key={'debug'} />,
        ],
    };

    return <CalcLayoutConfigurable config={conf} />;
};
