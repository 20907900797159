import gql from 'graphql-tag';
import { NodeType } from '@poolware/api';
import { compose, mapProps } from '@ez/tools';
import { withAppNavigator } from '@poolware/react-app-navigator';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@poolware/components';

export const itemFragment = gql`
    fragment ItemFragment on Item {
        id
        installedAt
        serial
        note
        label
        uuid
        product {
            id
            name
            description
            identification
            name
            brand {
                id
                name
                company {
                    id
                    name
                }
            }
            traits {
                id
                declaration {
                    id
                    name
                }
            }
        }
        checkMutations {
            delete
            update
        }
    }
`;

export const ItemQuery = gql`
    query QueryItem($id: ID!) {
        node(id: $id) {
            id
            ...ItemFragment
        }
    }
    ${itemFragment}
`;

export const withItemQuery = (propMapper: (props: any) => string) =>
    compose(
        withAppNavigator(),
        graphql(ItemQuery, {
            options: (props: any) => ({
                fetchPolicy: 'cache-and-network',
                variables: { id: propMapper(props) },
            }),
        }),
        withApolloLoading({ show404ForPath: 'data.node.id' }),
        mapProps((props) => {
            const item = props?.data?.node;
            return {
                ...props,
                item,
            };
        })
    );

export interface WithItemQueryProps {
    item: NodeType.Item;
}
