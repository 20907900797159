import * as React from 'react';
import {
    MenuBarHeaderItem,
    MenuBarSection,
    PageSkeletonLoader,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { fromEdges } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { NotFoundPage, PageContent, useViewerContext } from '@poolware/app-shell';
import { useQueryJobTodoTemplate } from '../../../queries/use-query-job-todo-template';
import { useJobTodoTemplateMutators } from '../../../queries/mutators-job-todo-templates';
import * as _ from 'lodash';
import { JobTodoTemplateForm } from '../JobTodoTemplateForm';
import { Icon } from 'semantic-ui-react';
import { ModuleIconNames } from '../../../constants';

export const JobTodoTemplateEdit: React.FC<any> = () => {
    const { AppNavigator, params } = useAppNavigator();
    const { loading, error, node: jobTodoTemplate, refetchQuery } = useQueryJobTodoTemplate(params.id);
    const { JobTodoTemplateMutator, delete: mutateDelete, update } = useJobTodoTemplateMutators({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });
    const viewerCtx = useViewerContext();

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (!jobTodoTemplate) {
        return <NotFoundPage />;
    }

    const initialValues = {
        title: jobTodoTemplate.title,
        description: jobTodoTemplate.description,
        items: fromEdges(jobTodoTemplate.items),
        'items-item-pending': { title: '', description: '', orderIndex: 1000 },
        timeEstimate: _.toNumber(jobTodoTemplate.timeEstimate),
        recommendedPrice: jobTodoTemplate.recommendedPrice,
    };

    const goBack = () => {
        AppNavigator.navigateToOrigin('/job-todo-template', { relativeToModule: true });
    };

    const initialItemsIds = fromEdges(jobTodoTemplate.items).map((i) => i.id);

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            const newItemIds = values.items.map((i) => i.id);
            // Remove items with IDs that not present in the initial IDS array anymore
            const toDelete = initialItemsIds.filter((initialId) => !newItemIds.includes(initialId));

            const prepareItemPayload = (item) => {
                return {
                    title: item.title?.trim() || '',
                    description: item.description?.trim() || '',
                    orderIndex: item.orderIndex,
                };
            };

            const reIndexed = values.items
                .map((item, index) => ({ ...item, orderIndex: index }))
                .filter((i) => !!i.title); // remove items with empty titles

            const toUpdate = reIndexed
                .filter((item: any) => !item.isNew)
                .map((item) => ({
                    id: item.id,
                    ...prepareItemPayload(item),
                }));

            const toCreate = reIndexed.filter((item: any) => item.isNew).map(prepareItemPayload);
            if (values['items-item-pending']?.title) {
                toCreate.push(
                    prepareItemPayload({
                        ...values['items-item-pending'],
                        orderIndex: toCreate.length + toUpdate.length,
                    })
                );
            }

            await update({
                id: jobTodoTemplate.id,
                title: values.title?.trim() || '',
                description: values.description?.trim() || '',
                timeEstimate: _.toNumber(values.timeEstimate),
                recommendedPrice: values.recommendedPrice ? _.toNumber(values.recommendedPrice) : undefined,
                items: {
                    delete: toDelete,
                    create: toCreate,
                    update: toUpdate,
                },
            });
            goBack();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem>Job Task Template Edit</MenuBarHeaderItem>
                    {/*<MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'}/>*/}
                </MenuBarSection>
            </StickyMenuBar>
            <PageContent pageWidth={'narrow'}>
                <VStack>
                    <JobTodoTemplateForm
                        header={
                            <>
                                <Icon name={ModuleIconNames.JobTodoTemplate} /> Edit Job Task Template
                            </>
                        }
                        initialValues={initialValues}
                        onCancel={goBack}
                        onSubmit={handleSubmit}
                    />
                </VStack>
            </PageContent>
        </VStack>
    );
};
