import * as React from 'react';
import { useState } from 'react';
import { ConfirmButton } from '@poolware/components';
import { fromEdges, NodeType } from '@poolware/api';
import { Button, Checkbox, Table } from 'semantic-ui-react';

import { TraitDeclarationFieldFormConfig } from '../../types';
import { FormFieldPrimitiveType } from '../../../components/FormBuilder';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

type TraitDeclarationFieldsTableProps = {
    traitDeclarationId: NodeType.ID;
    fields: any[];
    onEdit: (field) => any;
    config: TraitDeclarationFieldFormConfig;
};

const BooleanFieldRenderer: React.FC<{ value: any; disabled?: boolean; onChange?: any }> = ({
    value,
    onChange,
    disabled,
}) => {
    return <Checkbox disabled={disabled} checked={value} onChange={onChange} />;
};

const StringFieldRenderer: React.FC<{ value: any; disabled?: boolean; onChange?: any }> = ({
    value,
    onChange,
    disabled,
}) => {
    return <>{value}</>;
};

const SelectFieldRenderer: React.FC<{ value: any; disabled?: boolean; onChange?: any }> = ({
    value,
    onChange,
    disabled,
}) => {
    const options = fromEdges<any>(value);
    return <>{options.map((o) => o.name).join(', ')}</>;
};

const mapPrimitiveTypeToCell = (type: FormFieldPrimitiveType) => {
    switch (type) {
        case FormFieldPrimitiveType.Int:
            return StringFieldRenderer;
        case FormFieldPrimitiveType.Float:
            return StringFieldRenderer;
        case FormFieldPrimitiveType.Boolean:
            return BooleanFieldRenderer;
        case FormFieldPrimitiveType.String:
            return StringFieldRenderer;
        case FormFieldPrimitiveType.Text:
            return StringFieldRenderer;
        case FormFieldPrimitiveType.ID:
            return StringFieldRenderer;
        case FormFieldPrimitiveType.Select:
            return SelectFieldRenderer;
        default:
            throw new Error('Unknown form input type');
    }
};

const TraitDeclarationFieldsTable: React.FC<TraitDeclarationFieldsTableProps & IProductCatalogMutators> = ({
    traitDeclarationId,
    fields,
    config,
    onEdit,
    ProductCatalogMutator,
}) => {
    const [mutatingId, setMutatingId] = useState<string>(null);

    const onDelete = async (value) => {
        setMutatingId(value.id);
        await ProductCatalogMutator.updateTraitDeclaration_deleteField(config.mutationFiledName)(
            traitDeclarationId,
            value.id
        );
        setMutatingId(undefined);
    };

    const onToggle = (fieldId: string, key: string) => async (e, { checked }) => {
        setMutatingId(fieldId);
        await ProductCatalogMutator.updateTraitDeclaration_updateField(config.mutationFiledName)(
            traitDeclarationId,
            fieldId,
            {
                [key]: checked,
            }
        );
        setMutatingId(undefined);
    };

    return (
        <Table size="small" compact="very" unstackable>
            <Table.Header>
                <Table.Row>
                    {config.fields.map((f) => (
                        <Table.HeaderCell key={f.key}>{f.name}</Table.HeaderCell>
                    ))}
                    <Table.HeaderCell />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {fields.map((value) => (
                    <React.Fragment key={value.id}>
                        <Table.Row key={value.id} disabled={value.id === mutatingId} active={value.id === mutatingId}>
                            {config.fields.map((f, index) => {
                                const fieldValue = value[f.key];
                                const FieldRenderer = mapPrimitiveTypeToCell(f.type);
                                // Note: Constrain the width of the first two cells. It looks slightly better this way.
                                return (
                                    <Table.Cell key={f.key} width={index < 2 ? 3 : undefined}>
                                        <FieldRenderer value={fieldValue} onChange={onToggle(value.id, f.key)} />
                                    </Table.Cell>
                                );
                            })}
                            <Table.Cell width={2}>
                                <ConfirmButton
                                    basic
                                    size={'tiny'}
                                    onClick={() => onDelete(value)}
                                    icon={'trash'}
                                    loading={value.id === mutatingId}
                                    confirmMessage={{
                                        header: 'Delete Field?',
                                    }}
                                    confirmButton={{
                                        negative: true,
                                        icon: 'trash',
                                        content: 'Delete',
                                    }}
                                />
                                <Button basic size={'tiny'} onClick={() => onEdit(value)} icon={'edit'} />
                            </Table.Cell>
                        </Table.Row>
                    </React.Fragment>
                ))}
            </Table.Body>
        </Table>
    );
};

export default withProductCatalogMutators(queryNames)(
    TraitDeclarationFieldsTable
) as React.ComponentType<TraitDeclarationFieldsTableProps>;
