import * as React from 'react';

import { compose } from '@ez/tools';

interface PageProps {
    onSubmit: (any) => any;
    onCancel: () => any;
    initialValues: any;
    mutatorAction?: any;
}

interface CreateFormHandlerConfig {
    header?: string;
    form: any;
    initialValues: any;
    mutate?: (props: any, values: any) => Promise<any>;
    withMutator: any;
    mutatorAction?: string;
    mutationConfigMapper?: (any) => any;
}

export const createFormHandler = (config: CreateFormHandlerConfig) => {
    const { form: ContactForm, initialValues = {}, mutate, withMutator, mutatorAction, mutationConfigMapper } = config;

    class Page extends React.Component<PageProps> {
        onCancel = async () => {
            this.props.onCancel();
        };

        performMutation = async (values) => {
            if (mutate) {
                return mutate(this.props, values);
            }

            const mutationConfig = mutationConfigMapper(values);
            const mutateFn = this.props?.mutatorAction;
            return await mutateFn(mutationConfig);
        };

        onSubmit = async (values) => {
            try {
                const res = (await this.performMutation(values)) || {};
                this.props.onSubmit(res.data);
            } catch (e) {
                console.error(e);
                throw e;
            }
        };

        render() {
            return (
                <ContactForm
                    initialValues={{ ...initialValues, ...this.props.initialValues }}
                    onSubmit={this.onSubmit}
                    onCancel={this.onCancel}
                />
            );
        }
    }

    return compose(withMutator)(Page);
};
