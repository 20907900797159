import { toastError } from '@poolware/components';
import { fromEdges, NodeType } from '@poolware/api';
import * as React from 'react';
import { useCallback } from 'react';
import { FileAttachmentsViewer } from '@poolware/app-shell';
import { useWorkOrderMutators } from '../../../queries/mutators-work-order';

export interface FileAttachmentPanelProps {
    workOrder: NodeType.WorkOrder;
    readOnly: boolean;
    refetchQuery: any;
}

export const PanelFileAttachments: React.FC<FileAttachmentPanelProps> = ({ workOrder, readOnly, refetchQuery }) => {
    const { addFiles, removeFile } = useWorkOrderMutators({ refetchQueries: [refetchQuery] });

    const onFileAttachmentRemove = useCallback(
        async (file: NodeType.FileUpload[]) => {
            try {
                return removeFile(workOrder, file);
            } catch (e) {
                toastError(e);
            }
        },
        [workOrder]
    );

    const onFileUploadComplete = async (fileId) => {
        await addFiles(workOrder, [fileId]);
    };

    const fileAttachments = fromEdges(workOrder.files);

    return (
        <FileAttachmentsViewer
            files={fileAttachments}
            onFileRemove={onFileAttachmentRemove}
            onFileDidUpload={onFileUploadComplete}
            readOnly={readOnly}
            title={'Work Order File Attachments'}
            titleIcon={'file image outline'}
            asPanel={true}
        />
    );
};
