import { NodeType } from '@poolware/api';
import { createAction, createReducer, usePersistedString, usePersistedToggle } from '@ez/tools';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useEffect, useMemo, useReducer } from 'react';

export const ALL = '___ALL___';

type FilterType = {
    statusGroup: string;
    showSearch: boolean;
    claimID: string | null;
    customerName: string | null;
    franchiseNode: NodeType.Franchise | null;
    pageIndex: number;
};

const searchReducerInitialState: FilterType = {
    statusGroup: null,
    showSearch: true,
    claimID: null,
    customerName: null,
    franchiseNode: null,
    pageIndex: 0,
};

type ActionMap<M extends { [index: string]: any }, Ext> = {
    type: keyof M | Ext;
    payload?: any;
};

const searchReducer = createReducer(
    searchReducerInitialState,
    (state: FilterType, action: ActionMap<FilterType, 'clear'>) => {
        if (action.type === 'pageIndex') {
            state.pageIndex = action.payload;
            return;
        }

        switch (action.type) {
            case 'clear':
                state.franchiseNode = null;
                state.claimID = null;
                state.customerName = null;
                break;
            case 'statusGroup':
                state.statusGroup = action.payload;
                break;
            case 'showSearch':
                state.showSearch = action.payload;
                break;
            case 'claimID':
                state.claimID = action.payload;
                break;
            case 'customerName':
                state.customerName = action.payload;
                break;
            case 'franchiseNode':
                state.franchiseNode = action.payload;
                break;
        }
        // Reset pageIndex whenever any of the search queries change.
        state.pageIndex = 0;
        return state;
    }
);

export const useFilterWarrantyCases = () => {
    const { query, AppNavigator } = useAppNavigator();
    const [statusGroup, _setStatusGroup] = usePersistedString<string>('warranty-claims.hq.list.filter', ALL);
    const [showSearch, _setShowSearch] = usePersistedToggle('warranty-claims.hq.list.show-search', true);

    const initialValues = useMemo<FilterType>(() => {
        const fid = query?.fid;
        const fin = query?.fin;
        return {
            ...searchReducerInitialState,
            statusGroup: statusGroup,
            showSearch: showSearch,
            franchiseNode: fid && fin ? { id: fid, name: fin } : null,
            pageIndex: Number(query?.page) || 0,
        };
    }, []);

    const [state, dispatch] = useReducer(searchReducer, initialValues);

    useEffect(() => {
        _setStatusGroup(state.statusGroup);
        _setShowSearch(state.showSearch);
        AppNavigator.setSearchQueries({
            fid: state.franchiseNode?.id,
            fin: state.franchiseNode?.name,
            page: state.pageIndex?.toString() || '0',
        });
    }, [state]);

    const setStatusGroup = (value: string) => dispatch({ type: 'statusGroup', payload: value });
    const setShowSearch = (value: boolean) => dispatch({ type: 'showSearch', payload: value });
    const setClamID = (value: string) => dispatch(createAction('claimID', value));
    const setCustomerName = (value: string) => dispatch(createAction('customerName', value));
    const setFranchiseNode = (value: FilterType['franchiseNode']) => dispatch(createAction('franchiseNode', value));
    const setPageIndex = (value: FilterType['pageIndex']) => dispatch(createAction('pageIndex', value));
    const clearFilters = () => dispatch({ type: 'clear' });

    return {
        state,
        actions: {
            setStatusGroup,
            setShowSearch,
            setCustomerName,
            setFranchiseNode,
            setClamID,
            setPageIndex,
            clearFilters,
        },
    };
};
