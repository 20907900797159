import * as React from 'react';

import { Field } from 'formik';
import AppointmentGroupSingleSelector from '../../Components/AppointmentGroupSingleSelector';
import { FieldContentContainer } from './common';
import * as _ from 'lodash';
import { FormikFormFieldLabel } from '@poolware/components';

interface AppointmentGroupFieldProps {
    name: string;
    label: string;
    required?: boolean;
}

const AppointmentGroupField: React.FC<AppointmentGroupFieldProps> = ({ name, label, required }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (newValue) => form.setFieldValue(field.name, newValue);
                const value = _.get(field, 'value.id', undefined);
                return (
                    <div className="field">
                        <FormikFormFieldLabel label={label} name={name} required={required} />
                        <FieldContentContainer className={'ui selection'}>
                            <AppointmentGroupSingleSelector selectedGroupId={value} onChange={handleChange} />
                        </FieldContentContainer>
                    </div>
                );
            }}
        </Field>
    );
};

export default AppointmentGroupField;
