import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';

type StackPanel<Props = any> = {
    component: React.ComponentType<Props>;
    name?: string;
    props?: Props;
    [key: string]: any;
};
type PanelNavigationStackContextType = {
    stack: StackPanel[];
    replaceStackPanel: (panel: StackPanel) => any;
    pushStackPanel: (panel: StackPanel) => any;
    popStackPanel: (toRoot?: boolean) => any;
};

const PanelNavigationStackContext = React.createContext<PanelNavigationStackContextType>(null);

export const usePanelNavigationStack = () => useContext(PanelNavigationStackContext);

export const PanelNavigationStackProvider: React.FC = <T extends StackPanel>(props) => {
    const [localStack, setLocalStack] = React.useState<T[]>([]);

    const pushStackPanel = (stackPanel: T) => {
        setLocalStack((prevStack) => [stackPanel, ...prevStack]);
    };
    const popStackPanel = (toRoot?: boolean) => {
        if (toRoot) {
            setLocalStack([]);
        }
        setLocalStack((prevStack) => prevStack.slice(1));
    };

    const replaceStackPanel = (stackPanel: T) => {
        popStackPanel();
        pushStackPanel(stackPanel);
    };

    const ctxValue: PanelNavigationStackContextType = useMemo(
        () => ({
            stack: localStack,
            pushStackPanel,
            popStackPanel,
            replaceStackPanel,
        }),
        [localStack]
    );

    return <PanelNavigationStackContext.Provider value={ctxValue} {...props} />;
};

export interface PanelNavigationStackProps {
    root: StackPanel;
    stackId?: string;
}

export const PanelNavigationStack: React.FC<PanelNavigationStackProps> = ({ root, stackId }) => {
    const { stack, popStackPanel } = usePanelNavigationStack();

    useEffect(() => {
        popStackPanel(true);
    }, [stackId]);

    const panel = stack[0] || root;
    return <panel.component name={panel.name} {...panel.props} />;
};
