import * as React from 'react';
import { useCalendarActions } from '../../../redux';
import { ActionBar, HeaderButtonProps, LinkButton, SectionHeader } from '@poolware/components';
import { useCalendarPageContext } from '../../utils/CalendarPageContext';
import { useDebouncedCallback, usePersistedToggle } from '@ez/tools';
import { useViewer } from '@poolware/app-shell';
import { Divider, Icon } from 'semantic-ui-react';
import { CalendarEventAddressAreaType, CalendarEventStreetType } from '../../CalendarView/street-summaries';

const FilterByArea: React.FC = () => {
    const { areasSummary } = useCalendarPageContext();
    const { CalendarState, CalendarAction } = useCalendarActions();

    const streetFilter = CalendarState.filters.streets || [];
    const areaFilter = CalendarState.filters.areas || [];
    const streetHoverFilter = CalendarState.filters.hoverStreets || [];

    const setFilter = (items: string[]) => {
        CalendarAction.setStreetFilter({ areas: items });
    };

    const [setHoverFilter] = useDebouncedCallback((hoverAreas) => {
        CalendarAction.setStreetFilter({ hoverAreas: hoverAreas });
    }, 50);

    const onAreaFilterChange = (changedArea: CalendarEventAddressAreaType, checked: boolean) => {
        if (!checked) {
            CalendarAction.setStreetFilter({ hoverAreas: [] });
        }
        const changedAreaKey = changedArea.areaKey;

        // Check whether the staff is in the inclusion list
        const isInFilterArray = areaFilter.findIndex((item) => item === changedAreaKey);

        if (checked) {
            // if staff is checked, then add it to exclusion list.
            if (isInFilterArray < 0) {
                // it is not in the list, adding it.
                const newFilters = [...areaFilter, changedAreaKey];
                CalendarAction.setStreetFilter({ areas: newFilters });
            }
        } else {
            // if staff is unchecked, then remove it from inclusion list.
            if (isInFilterArray >= 0) {
                // it is already in the list, removing it
                let a = [...areaFilter];
                a.splice(isInFilterArray, 1);
                CalendarAction.setStreetFilter({ areas: a });
            }
        }
    };

    const onMouseEnterArea = (areaSummary: CalendarEventAddressAreaType, isChecked: boolean) => {
        // console.log('onMouseEnter', streetSummary);
        CalendarAction.setStreetFilter({ hoverAreas: [areaSummary.areaKey] });
    };

    const onMouseLeaveArea = (streetSummary: CalendarEventAddressAreaType, isChecked: boolean) => {
        // console.log('onMouseLeave', streetSummary);
        if (CalendarState.filters.hoverAreas?.length > 0) {
            setHoverFilter([]);
        }
    };

    const onShowAll = (checked: boolean) => {
        CalendarAction.setStreetFilter({ hoverStreets: [], streets: [], hoverAreas: [], areas: [] });
    };

    const onChangeShowAdderssOnly = (checked: boolean) => {
        CalendarAction.setStreetFilter({ showWithAddressOnly: checked });
    };

    const hasFilter = streetFilter.length !== 0 || areaFilter.length !== 0;
    const clearButton = hasFilter ? <LinkButton content={'clear'} onClick={() => onShowAll(false)} /> : null;

    return (
        // @ts-ignore
        <ActionBar
        // onMouseLeave={() => onMouseLeaveArea(null, false)}
        >
            <ActionBar.Header content={'Highlight by Area'} icon={'filter'} actionComponent={clearButton} />
            <ActionBar.ItemCheckbox
                dividing={true}
                checked={!hasFilter}
                onChange={(checked) => onShowAll(checked)}
                content={'All'}
            />
            <ActionBar.Scroll maxHeight={600}>
                {areasSummary.map((areaSummary) => {
                    const isAreaChecked = areaFilter?.includes?.(areaSummary.areaKey);
                    const streetsSummary = areaSummary.streets;
                    return (
                        <div key={areaSummary.areaKey}>
                            <div>
                                <ActionBar.ItemCheckbox
                                    // onMouseEnter={() => onMouseEnterArea(areaSummary, isAreaChecked)}
                                    // onMouseLeave={() => onMouseLeaveArea(areaSummary, isAreaChecked)}
                                    checked={isAreaChecked}
                                    onChange={(checked) => onAreaFilterChange(areaSummary, checked)}
                                    level={'first'}
                                    content={
                                        <>
                                            {areaSummary.postCode && <>{areaSummary.postCode} - </>}
                                            {areaSummary.city}
                                            {areaSummary.suburb && (
                                                <>
                                                    <Icon name={'caret right'} />
                                                    {areaSummary.suburb}
                                                </>
                                            )}
                                            <span tw={'text-blue-700'}> - {areaSummary.count || 0}</span>
                                        </>
                                    }
                                />
                            </div>
                            {streetsSummary.map((streetSummary) => {
                                if (!streetSummary.streetName) return null;
                                return (
                                    <div tw={'pl-8 text-xs text-gray-500'} key={streetSummary.streetKey}>
                                        <>
                                            <Icon name={'caret right'} />
                                            {streetSummary.streetName}
                                        </>
                                        <span tw={'text-gray-500'}> - {streetSummary.count || 0}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </ActionBar.Scroll>
            <Divider />
            <ActionBar.ItemCheckbox
                checked={CalendarState.filters.showWithAddressOnly}
                onChange={(checked) => onChangeShowAdderssOnly(checked)}
                content={
                    <small>
                        <i>Hide without address</i>
                    </small>
                }
            />
        </ActionBar>
    );
};

const FilterByStreet: React.FC = () => {
    const { streetsSummary } = useCalendarPageContext();
    const { CalendarState, CalendarAction } = useCalendarActions();

    const areaFilter = CalendarState.filters.areas || [];
    const streetFilter = CalendarState.filters.streets || [];
    const streetHoverFilter = CalendarState.filters.hoverStreets || [];

    const setFilter = (items: string[]) => {
        CalendarAction.setStreetFilter({ streets: items });
    };

    const [setHoverFilter] = useDebouncedCallback((hoverStreets) => {
        CalendarAction.setStreetFilter({ hoverStreets: hoverStreets });
    }, 50);

    const onStreetFilterChange = (changedStreet: CalendarEventStreetType, checked: boolean) => {
        if (!checked) {
            CalendarAction.setStreetFilter({ hoverStreets: [] });
        }
        const changedStreetKey = changedStreet.streetKey;

        // Check whether the staff is in the inclusion list
        const isInFilterArray = streetFilter.findIndex((item) => item === changedStreetKey);

        if (checked) {
            // if staff is checked, then add it to exclusion list.
            if (isInFilterArray < 0) {
                // it is not in the list, adding it.
                const newFilters = [...streetFilter, changedStreetKey];
                setFilter(newFilters);
            }
        } else {
            // if staff is unchecked, then remove it from inclusion list.
            if (isInFilterArray >= 0) {
                // it is already in the list, removing it
                let a = [...streetFilter];
                a.splice(isInFilterArray, 1);
                setFilter(a);
            }
        }
    };

    const onMouseEnterStreet = (streetSummary: CalendarEventStreetType, isChecked: boolean) => {
        // console.log('onMouseEnter', streetSummary);
        setHoverFilter([streetSummary.streetKey]);
    };
    const onMouseLeaveStreet = (streetSummary: CalendarEventStreetType, isChecked: boolean) => {
        // console.log('onMouseLeave', streetSummary);
        if (CalendarState.filters.hoverStreets?.length > 0) {
            setHoverFilter([]);
        }
    };

    const onShowAll = (checked: boolean) => {
        CalendarAction.setStreetFilter({ hoverStreets: [], streets: [], hoverAreas: [], areas: [] });
    };

    const onChangeShowAdderssOnly = (checked: boolean) => {
        CalendarAction.setStreetFilter({ showWithAddressOnly: checked });
    };

    const hasFilter = streetFilter.length !== 0 || areaFilter.length !== 0;
    const clearButton = hasFilter ? <LinkButton content={'clear'} onClick={() => onShowAll(false)} /> : null;

    return (
        // @ts-ignore
        <ActionBar
        // onMouseLeave={() => onMouseLeaveStreet(null, false)}
        >
            <ActionBar.Header content={'Highlight by Street Name'} icon={'filter'} actionComponent={clearButton} />
            <ActionBar.ItemCheckbox
                dividing={true}
                checked={!hasFilter}
                onChange={(checked) => onShowAll(checked)}
                content={'All'}
            />
            <ActionBar.Scroll maxHeight={600}>
                {streetsSummary.map((streetSummary) => {
                    const isChecked = streetFilter?.includes?.(streetSummary.streetKey);
                    const isHighlited = isChecked || streetHoverFilter?.includes(streetSummary.streetName);
                    return (
                        <ActionBar.ItemCheckbox
                            level={'first'}
                            // onMouseEnter={() => onMouseEnterStreet(streetSummary, isChecked)}
                            // onMouseLeave={() => onMouseLeaveStreet(streetSummary, isChecked)}
                            radio={false}
                            key={streetSummary.streetKey}
                            checked={isChecked}
                            // indeterminate={isIndeterminate}
                            onChange={(checked) => onStreetFilterChange(streetSummary, checked)}
                            // icon={'map marker alternate'}
                            content={
                                <span style={isHighlited ? { textDecoration: 'underline' } : {}}>
                                    {streetSummary.postCode && <>{streetSummary.postCode} - </>}
                                    {streetSummary.city}
                                    {streetSummary.suburb && (
                                        <>
                                            <Icon name={'caret right'} />
                                            {streetSummary.suburb}
                                        </>
                                    )}
                                    {streetSummary.streetName && (
                                        <>
                                            <Icon name={'caret right'} />
                                            {streetSummary.streetName}
                                        </>
                                    )}
                                    <span tw={'text-blue-700'}> - {streetSummary.count || 0}</span>
                                </span>
                            }
                        />
                    );
                })}
            </ActionBar.Scroll>
            <Divider />
            <ActionBar.ItemCheckbox
                checked={CalendarState.filters.showWithAddressOnly}
                onChange={(checked) => onChangeShowAdderssOnly(checked)}
                content={
                    <small>
                        <i>Hide without address</i>
                    </small>
                }
            />
        </ActionBar>
    );
};

export const StreetFilter: React.FC = () => {
    const { modulesAccess } = useViewer();
    const { CalendarAction } = useCalendarActions();
    const [toggle, setToggle] = usePersistedToggle('service-jobs.disp-tools.street.toggle', true);
    if (!modulesAccess.FieldServices?.calendarStreetsSummary) {
        return null;
    }

    const toggleMode = (value: boolean) => {
        CalendarAction.setStreetFilter({ hoverStreets: [], streets: [], hoverAreas: [], areas: [] });
        setToggle(value);
    };

    const buttons: HeaderButtonProps[] = [
        {
            content: 'Area',
            onClick: () => toggleMode(true),
            active: toggle === true,
            icon: 'indent',
            size: 'mini',
        },
        {
            content: 'Street',
            onClick: () => toggleMode(false),
            active: toggle === false,
            icon: 'list',
            size: 'mini',
        },
    ];
    return (
        <div>
            <SectionHeader content={'Streets'} button={buttons} />
            {toggle ? <FilterByArea /> : <FilterByStreet />}
        </div>
    );
};
