// Const
import { MonthlyHeatGasProfile, MonthProfile, PoolCover, PoolLocation, PoolTypes, WindProfile } from '../common/types';
import { CLAMP } from '../utils/utils';

const APP_CONST = {
    'KG/M3': 1000,
    'KJ/KGoC': 4.2,
    '1BTU': 0.0002930710701, // KW/H
    '1BTU/MJ': 1.055, // BTU to MJ
    '1ft2': 0.92903, // M2
};

function calcHeatUpMJ({ poolTemp, outsideTemp, poolType, poolVolume, operatingHours, poolSurfaceAreaTop }) {
    /**
     *
     *  Pool:
     *  Energy = surfaceArea * 0.33 * (requiredTemp - outsideTemp) / timeToHeatUp * 24
     *
     *  SPA:
     *  Energy = volume * 0.00495 * (requiredTemp - outsideTemp) / timeToHeatUp
     */

    const volume = APP_CONST['KG/M3'] * poolVolume;
    let heatUpMJ = 0;
    if (poolType === PoolTypes.Pool) {
        heatUpMJ = poolSurfaceAreaTop * 0.33 * 24 * (poolTemp - outsideTemp);
    } else {
        heatUpMJ = volume * 0.00495 * (poolTemp - outsideTemp);
    }
    return heatUpMJ;
}

/// GAS
function calcHeatDailyLoss({
    poolType,
    poolLocation,
    withCover,
    poolVolume,
    poolSurfaceAreaTop,
    poolTemp,
    outsideTemp,
}) {
    // calculate heatSurfaceLost
    let lossPerDayDeg = 1;
    if (poolType === PoolTypes.SPA) {
        lossPerDayDeg = 4;
    }

    const volume = APP_CONST['KG/M3'] * poolVolume;

    if (poolType === PoolTypes.Pool) {
        if (poolLocation === PoolLocation.Indoor) {
            lossPerDayDeg = 1;
        } else {
            if (!withCover) {
                lossPerDayDeg = 4;
            } else {
                lossPerDayDeg = 3;
            }
        }
    }

    // Take outside temperature into account.

    const envTempDelta = Math.max(poolTemp - outsideTemp, 0);
    const lossDeg = Math.min(envTempDelta, lossPerDayDeg);
    // const lossDeg = lossPerDayDeg;

    // console.log({ requiredTemp, outsideTemp, lossDeg, lossPerDayDeg });
    return volume * 0.00495 * lossDeg;
}

function calcHeatDailyLoss2({
    poolType,
    poolLocation,
    withCover,
    poolVolume,
    poolSurfaceAreaTop,
    poolTemp,
    outsideTemp,
}) {
    // calculate heatSurfaceLost
    let heatSurfaceLost = 0;
    if (poolLocation === PoolLocation.Indoor) {
        if (!withCover) {
            heatSurfaceLost = 3 * APP_CONST['1BTU/MJ'] * poolSurfaceAreaTop * (poolTemp - outsideTemp);
        } else {
            heatSurfaceLost = 3 * APP_CONST['1BTU/MJ'] * poolSurfaceAreaTop * (poolTemp - outsideTemp) * 0.75;
        }
    } else {
        if (!withCover) {
            heatSurfaceLost = 7 * APP_CONST['1BTU/MJ'] * poolSurfaceAreaTop * (poolTemp - outsideTemp);
        } else {
            heatSurfaceLost = 7 * APP_CONST['1BTU/MJ'] * poolSurfaceAreaTop * (poolTemp - outsideTemp) * 0.45;
        }
    }
    return heatSurfaceLost;
}

interface CalcInputProps {
    yearProfile: MonthProfile[];
    selectedMonths: string[];
    lowestAirTemp: number;
    poolType: PoolTypes;
    poolVolume: number;
    poolLocation: PoolLocation;
    poolCover: PoolCover;
    windProfile: WindProfile;
    operatingHours: number;
    poolTemp: number;
    poolSurfaceAreaTop: number;
}

export function computeGasHeaterHeatProfile(input: CalcInputProps): MonthlyHeatGasProfile[] {
    const {
        yearProfile,
        poolType,
        poolLocation,
        poolCover,
        poolVolume,
        operatingHours,
        selectedMonths,
        lowestAirTemp,
        poolSurfaceAreaTop,
        poolTemp,
        windProfile = WindProfile.Normal,
    } = input;

    const withCover = poolCover === PoolCover.Yes;
    const heatProfile: MonthlyHeatGasProfile[] = [];

    for (let monthProfile of yearProfile) {
        let heatUpMJ;
        let heatSurfaceLoss;
        const isSelected = selectedMonths.findIndex((sm) => sm === monthProfile.id) !== -1;

        // Clamp outside temp to lower air temp. setting;
        let operatingTemp = CLAMP(monthProfile.temp, lowestAirTemp, poolTemp - 0.5);

        if (!isSelected || operatingTemp <= 0 || operatingTemp >= poolTemp) {
            heatUpMJ = 0;
            heatSurfaceLoss = 0;
        } else {
            const loss1 = calcHeatDailyLoss({
                poolType,
                poolLocation,
                withCover,
                poolVolume,
                poolSurfaceAreaTop,
                outsideTemp: operatingTemp,
                poolTemp,
            });
            const loss2 = calcHeatDailyLoss2({
                poolType,
                poolLocation,
                withCover,
                poolVolume,
                poolSurfaceAreaTop,
                outsideTemp: operatingTemp,
                poolTemp,
            });
            heatSurfaceLoss = loss2;
            heatUpMJ = calcHeatUpMJ({
                poolTemp,
                outsideTemp: operatingTemp,
                poolType,
                poolVolume,
                operatingHours,
                poolSurfaceAreaTop,
            });
        }
        let heatRunningMJ = heatSurfaceLoss;

        let heatTotal;
        if (heatUpMJ > 0 && withCover) {
            heatTotal = 0.8 * (heatUpMJ + heatSurfaceLoss) * 0.3;
        } else {
            heatTotal = 0.5 * (heatUpMJ + heatSurfaceLoss);
        }

        if (!isSelected) {
            heatTotal = 0;
        }

        if (withCover) {
            // heatTotal = 0.101 * heatTotal;
            // TODO: In the original code, there is a magic number of '5'? Why '5'?
            // Applying this number here as well.
            // It is 4 in here, because counting starts from 0 in my code, but from 1 in theirs.
            // if (monthProfile.index < 4) {
            //     heatTotal = 0.05 * heatTotal;
            // } else {
            // heatTotal = 0.101 * heatTotal;
            // }
        }

        if (windProfile === WindProfile.High) {
            heatTotal = 1.21 * heatTotal; // add 21% for high wind
        }

        let minimumOutputMJ = Math.round(heatUpMJ / operatingHours);

        const heatMonthProfile: MonthlyHeatGasProfile = {
            monthProfile,
            heatUpMJ,
            operatingHoursPerDay: operatingHours,
            heatUpTimeHr: operatingHours,
            heatRunningMJ: heatTotal,
            operatingTemp: operatingTemp,
            minimumOutputMJ,
            isSelected,
        };

        heatProfile.push(heatMonthProfile);
    }

    return heatProfile;
}
