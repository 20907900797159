import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Feed, Icon } from 'semantic-ui-react';
import { Display } from '@poolware/components';
import { getMessageForStatus } from '../status-configs';

export interface StatusHistoryProps {
    claim: NodeType.WarrantyClaim;
}

export const StatusHistory: React.FC<StatusHistoryProps> = ({ claim }) => {
    const history = fromEdges(claim.statusHistory);
    return (
        <Feed>
            {history.map((h) => {
                return (
                    <Feed.Event>
                        <Feed.Label>
                            <Icon name="clock outline" />
                        </Feed.Label>
                        <Feed.Content>
                            <Feed.Summary>{getMessageForStatus(h.status)}</Feed.Summary>
                            <Feed.Meta>
                                <Feed.Date>
                                    <Display.Date value={h.createdAt} />
                                </Feed.Date>
                            </Feed.Meta>
                            {h.message && (
                                <Feed.Extra text>
                                    <Display.Text
                                        limit={1000}
                                        formatted={false}
                                        maxHeightPx={200}
                                        value={h.message || ' '}
                                        style={{
                                            backgroundColor: 'hsl(210, 12%, 94%)',
                                            padding: '0 0.5em',
                                            borderRadius: '4px',
                                            minHeight: '2rem',
                                        }}
                                    />
                                </Feed.Extra>
                            )}
                        </Feed.Content>
                    </Feed.Event>
                );
            })}
        </Feed>
    );
};
