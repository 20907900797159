import { NodeType } from '@poolware/api';

export enum CalendarEventMode {
    APPOINTMENT_ITEM,
    DAY_SUMMARY,
}

export interface CalendarDaySummaryItem {
    count: number;
}

export enum CalendarEventDensity {
    Normal = 'normal',
    Reduced = 'reduced',
    Low = 'low',
}

export type CalendarEventType = {
    id: string;
    mode: CalendarEventMode;
    item?: NodeType.AppointmentItem;
    daySummary?: CalendarDaySummaryItem;
    resourceId?: string;
    staff?: NodeType.Staff;
    selected?: boolean;
    highlighted?: boolean;
    highlightedPop?: boolean;
    hidden?: boolean;
    dimmed?: boolean;
    start: Date;
    end: Date;
    formattedDate: string;
    streetKey?: string;
    // used for rendering optimisations on heavy loaded calendars.
    dataDensity?: CalendarEventDensity;
};
