import PropTypes, { InferProps } from 'prop-types';
import clsx from 'clsx';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import React from 'react';

import * as dates from './utils/dates';
import DateContentRow from './DateContentRow';
import Header from './Header';
import ResourceHeader from './ResourceHeader';
import { notify } from './utils/helpers';
import { commonProps } from './CalendarPropsTypes';
import { RBCContext } from './CalendarContext';

const propTypes = {
    ...commonProps,
    range: PropTypes.array.isRequired,
    events: PropTypes.array.isRequired,
    resources: PropTypes.any,
    getNow: PropTypes.func.isRequired,
    isOverflowing: PropTypes.bool,
    width: PropTypes.number,
    selected: PropTypes.object,
    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    onSelectSlot: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onDoubleClickEvent: PropTypes.func,
    onDrillDown: PropTypes.func,
    getDrilldownView: PropTypes.func.isRequired,
    scrollRef: PropTypes.any,
};

interface TimeGridHeaderProps extends InferProps<typeof propTypes> {
    style?: any;
    className?: string;
}

class TimeGridHeader extends React.Component<TimeGridHeaderProps> {
    public static propTypes = propTypes;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;

    handleHeaderClick = (date, view, e) => {
        e.preventDefault();
        notify(this.props.onDrillDown, [date, view]);
    };

    renderHeaderCells(range) {
        let { getDrilldownView, getNow } = this.props;
        const {
            localizer,
            components: { header: HeaderComponent = Header },
            getters: { dayProp },
        } = this.context;

        const today = getNow();

        return range.map((date, i) => {
            let drilldownView = getDrilldownView(date);
            let label = localizer.format(date, 'dayFormat');

            const { className, style } = dayProp(date);

            let header = <HeaderComponent date={date} label={label} localizer={localizer} />;

            return (
                <div
                    key={i}
                    style={style}
                    className={clsx('rbc-header', className, dates.eq(date, today, 'day') && 'rbc-today')}
                >
                    {drilldownView ? (
                        <a href="#" onClick={(e) => this.handleHeaderClick(date, drilldownView, e)}>
                            {header}
                        </a>
                    ) : (
                        <span>{header}</span>
                    )}
                </div>
            );
        });
    }
    renderRow = (resource) => {
        let { events, selectable, getNow, range } = this.props;
        const { accessors } = this.context;

        const resourceId = accessors.resourceId(resource);
        let eventsToDisplay = resource ? events.filter((event) => accessors.resource(event) === resourceId) : events;

        return (
            <DateContentRow
                isAllDay
                getNow={getNow}
                minRows={2}
                range={range}
                events={eventsToDisplay}
                resourceId={resourceId}
                className="rbc-allday-cell"
                selectable={selectable}
                selected={this.props.selected}
                onSelect={this.props.onSelectEvent}
                onDoubleClick={this.props.onDoubleClickEvent}
                onSelectSlot={this.props.onSelectSlot}
            />
        );
    };

    render() {
        let { width, resources, range, events, getNow, selectable, scrollRef, isOverflowing } = this.props;
        const {
            accessors,
            rtl,
            components: {
                timeGutterHeader: TimeGutterHeader,
                resourceHeader: ResourceHeaderComponent = ResourceHeader,
            },
        } = this.context;

        let style = {};
        if (isOverflowing) {
            style[rtl ? 'marginLeft' : 'marginRight'] = `${scrollbarSize()}px`;
        }

        const groupedEvents = resources.groupEvents(events);

        return (
            <div style={style} ref={scrollRef} className={clsx('rbc-time-header', isOverflowing && 'rbc-overflowing')}>
                <div className="rbc-label rbc-time-header-gutter" style={{ width, minWidth: width, maxWidth: width }}>
                    {TimeGutterHeader && <TimeGutterHeader />}
                </div>

                {resources.map(([id, resource], idx) => {
                    let headerCellClassName = 'rbc-row rbc-time-header-cell';
                    const isSingleDay = range.length <= 1;
                    if (isSingleDay) {
                        headerCellClassName += ' rbc-time-header-cell-single-day';
                    }
                    const shouldHideHeaderCell = isSingleDay && !!resource;

                    return (
                        <div className="rbc-time-header-content" key={id || idx}>
                            {resource && (
                                <div className="rbc-row rbc-row-resource" key={`resource_${idx}`}>
                                    <div className="rbc-header">
                                        <ResourceHeaderComponent
                                            index={idx}
                                            label={accessors.resourceTitle(resource)}
                                            resource={resource}
                                        />
                                    </div>
                                </div>
                            )}
                            {!shouldHideHeaderCell && (
                                <div className={headerCellClassName}>{this.renderHeaderCells(range)}</div>
                            )}
                            {false && (
                                <DateContentRow
                                    isAllDay
                                    getNow={getNow}
                                    minRows={2}
                                    range={range}
                                    events={groupedEvents.get(id) || []}
                                    resourceId={resource && id}
                                    className="rbc-allday-cell"
                                    selectable={selectable}
                                    selected={this.props.selected}
                                    onSelect={this.props.onSelectEvent}
                                    onDoubleClick={this.props.onDoubleClickEvent}
                                    onSelectSlot={this.props.onSelectSlot}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default TimeGridHeader;
