import * as React from 'react';
import { NodeType, useMutationHeaterRecommendation } from '@poolware/api';
import { ConnectionTableDef, DefaultConnectionTable, Display, LinkButton } from '@poolware/components';
import { Icon } from 'semantic-ui-react';
import { useQueryHeaterRecommendations } from './use-query-heater-recommendations-connection';
import { convertDocTypeToLabel, useHeatCalcActions } from '@poolware/app-calculators';
import { CustomerLink } from '../../../routes/Links';
import { useViewer } from '@poolware/app-shell';

export interface PanelCustomContractProps {
    customerId?: NodeType.ID;
}

export const HeaterRecommendationTable: React.FC<PanelCustomContractProps> = ({ customerId }) => {
    const { HeatCalcAction } = useHeatCalcActions();
    const { isAdmin } = useViewer();

    const { connectionData, connectionState, refetchQuery } = useQueryHeaterRecommendations({ customerId });
    const { delete: deleteHR } = useMutationHeaterRecommendation({ refetchQueries: [refetchQuery] });

    const onView = (hr: NodeType.HeaterRecommendation) => {
        HeatCalcAction.openCalc({ heaterRecommendation: hr });
    };

    const tableDef: ConnectionTableDef<NodeType.HeaterRecommendation> = [
        {
            header: 'Date',
            sortKey: 'createdAt',
            cellProps: {
                width: '2',
            },
            cell: (h) => <Display.Date value={h.createdAt} format={'llll'} />,
        },
        {
            header: 'Rec. ID',
            width: '1',
            cell: (r) => {
                return <LinkButton onClick={() => onView(r)}>{r.recNumber}</LinkButton>;
            },
        },
        {
            header: 'PDF',
            textAlign: 'center',
            cellProps: {
                textAlign: 'center',
            },
            width: '1',
            cell: (r) => {
                const linkPDF = r.urlPdf;
                return (
                    <div>
                        <a target={'_blank'} href={linkPDF} rel="noopener noreferrer">
                            <Icon name={'file pdf'} color={'red'} />
                        </a>
                    </div>
                );
            },
        },
        {
            header: 'Type',
            width: '1',
            cell: (r) => {
                const title = convertDocTypeToLabel(r.payload?.docType);
                return <>{title}</>;
            },
        },
        {
            header: 'Product',
            cell: (r) => {
                return <div>{r.product?.name}</div>;
            },
        },
        {
            header: 'Customer',
            cell: (r) => {
                const customer = r.entity?.user?.customer;
                return (
                    <CustomerLink customerId={customer?.id}>
                        {customer?.crn} | <Display.Entity value={r.entity} />
                    </CustomerLink>
                );
            },
        },
        isAdmin && {
            header: 'Franchise',
            cell: (r) => {
                return r?.entity?.franchise?.name;
            },
        },
    ];

    return (
        <>
            <DefaultConnectionTable
                tableDef={tableDef}
                // onRowClick={onView}
                connectionData={connectionData}
                connectionState={connectionState}
            />
        </>
    );
};
