import { poolFragment, staffFragment } from '@poolware/api';
import gql from 'graphql-tag';

export const measurementTypeFragment = gql`
    fragment MeasurementFragment on Measurement {
        id
        name
        priority
        tagIdentifier
        solutionCoefficient
        unit {
            id
            name
        }
    }
`;

export const recommendedTreatmentFragment = gql`
    fragment RecommendedTreatmentFragment on RecommendedTreatment {
        id
        sampleInstruction
        dosage
        isOverridden
        balancedDosage
        problem {
            id
        }
        treatment {
            id
            consumableProduct {
                id
                product {
                    id
                    name
                }
            }
            isRadical
            unit {
                id
                name
            }
        }
        report {
            id
        }
    }
`;

export const criteriaFragment = gql`
    fragment TestCriteriaFramgent on TestCriteria {
        id
        name
        description
        lowerThreshold
        targetValue
        higherThreshold
        isUniversal
        universal {
            id
        }
        measurementType {
            id
            name
            unit {
                id
                name
            }
        }
        toHighValueProblem {
            id
        }
        toLowValueProblem {
            id
        }
    }
`;

export const testTreatmentFragment = gql`
    fragment TestTreatmentFragment on TestTreatment {
        id
        name
        instruction
        decimalPlaces
        isRadical
        unit {
            id
            name
        }
        group {
            id
            name
        }
        consumableProduct {
            id
            product {
                id
                name
                description
                identification
            }
        }
        affects(first: 100) {
            edges {
                node {
                    id
                    measurementType {
                        id
                        ...MeasurementFragment
                    }
                    primary
                    value
                }
            }
        }
    }
    ${measurementTypeFragment}
`;

export const testProblemFragment = gql`
    fragment TestProblemFragment on TestProblem {
        id
        name
        description
        organisationType {
            id
            name
        }
        criteria(first: 100) {
            edges {
                node {
                    ...TestCriteriaFramgent
                }
            }
        }
        treatments(first: 100) {
            edges {
                node {
                    ...TestTreatmentFragment
                }
            }
        }
    }
    ${testTreatmentFragment}
    ${criteriaFragment}
`;

export const testReportFragment = gql`
    fragment TestReportFragment on TestReport {
        id
        isArchived
        createdAt
        updatedAt
        reportBy {
            id
            ...StaffFragment
        }
        previewPdfUrl
        pdfUrl
        refId
        pool {
            id
            ...PoolFragment
            ... on Pool {
                id
                entity {
                    id
                    user {
                        id
                        customer {
                            id
                            contactName
                            crn
                        }
                    }
                    franchise {
                        id
                        name
                    }
                }
            }
            chemicalTargets(first: 1000) {
                edges {
                    node {
                        higherThreshold
                        lowerThreshold
                        target
                        isCalibrated
                        measurementType {
                            ...MeasurementFragment
                        }
                    }
                }
            }
        }
        sampleSet {
            id
            createdAt
            samples(first: 1000) {
                edges {
                    node {
                        id
                        value
                        createdAt
                        measurementType {
                            ...MeasurementFragment
                        }
                    }
                }
            }
        }
        attachments {
            edges {
                node {
                    id
                    comment
                    file {
                        id
                        isImage
                        url
                        imageUrl
                    }
                }
            }
        }
        manualProblems(first: 1000) {
            edges {
                node {
                    id
                    name
                    description
                    solution
                }
            }
        }
        note
    }

    ${measurementTypeFragment}
    ${poolFragment}
    ${staffFragment}
`;
