import * as React from 'react';
import {
    FormikInputField,
    FormikPanelForm,
    FormikTextareaField,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    Panel,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { NodeType } from '@poolware/api';
import { Button, Divider, Grid, GridColumn, Header, Menu } from 'semantic-ui-react';
import BrandsTable from './BrandsTable';
import { FranchiseEditableItem } from '../../Products/View/EditableItems';
import { FormikHelpers as FormikActions } from 'formik';
import { PageControlProps } from './ViewCompany.Control';
import { ProductCompany_UpdateInput } from '../../../queries';

const ProductCompanyDetailsFormPanel: React.FC<{ company: NodeType.ProductCompany; ProductCatalogMutator }> = ({
    company,
    ProductCatalogMutator,
}) => {
    const initialValues = company;

    const onSubmit = async (values: typeof initialValues, actions: FormikActions<typeof initialValues>) => {
        try {
            const updateFields = {
                name: values.name,
                description: values.description,
            };
            await ProductCatalogMutator.updateProductCompany(company, updateFields);
            actions.resetForm({ values: { ...company, ...updateFields } });
        } catch (e) {
            console.error(e);
            actions.setStatus({ error: e });
            toastError({ title: 'Failed to update', description: e.message });
        }
        actions.setSubmitting(false);
    };

    return (
        <FormikPanelForm header={'Product Company'} initialValues={initialValues} onSubmit={onSubmit}>
            <FormikInputField label="Company Name" name={'name'} />
            <FormikTextareaField rows={8} label={'Description'} name={'description'} />
        </FormikPanelForm>
    );
};

export default class extends React.Component<PageControlProps> {
    state = {
        updatingDeclarations: false,
    };

    goToList = () => {
        this.props.AppNavigator.navigate('/', { relativeToModule: true });
    };

    onDelete = async () => {
        try {
            const { company } = this.props;
            await this.props.mutateProductCompany.delete({ id: company.id });
            this.goToList();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete company', description: e.message });
        }
    };

    onUpdate = (name: keyof ProductCompany_UpdateInput) => async (newValue: string) => {
        try {
            await this.props.ProductCatalogMutator.updateProductCompany(this.props.company, {
                [name]: newValue,
            });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    onNewBrand = () => {
        this.props.AppNavigator.navigate(`/new-brand`, {
            relativeToModule: true,
            setOrigin: true,
            query: {
                companyId: this.props.company.id,
            },
        });
    };

    render() {
        const { company } = this.props;
        return (
            <VStack>
                <StickyMenuBar>
                    <Menu.Menu position={'left'}>
                        <MenuBarItem icon={'chevron left'} color={'grey'} onClick={this.goToList}>
                            All Companies
                        </MenuBarItem>
                    </Menu.Menu>
                    <Menu.Menu position={'right'}>
                        <MenuBarDropdown icon={'wrench'} title={'More...'}>
                            <MenuBarDropdownItemWithConfirm
                                title={'Delete'}
                                icon={'trash'}
                                color={'red'}
                                confirm={{
                                    confirmButton: {
                                        content: 'Delete',
                                        negative: true,
                                        icon: 'trash',
                                    },
                                    confirmMessage: {
                                        header: 'Delete Company',
                                        content:
                                            'This company will be deleted permanently. This is irreversible operation!',
                                    },
                                }}
                                onClick={this.onDelete}
                            />
                        </MenuBarDropdown>
                    </Menu.Menu>
                </StickyMenuBar>

                <Grid
                    columns={2}
                    stackable={true}
                    padded={false}
                    style={{ marginLeft: '-0.5em', marginRight: '-0.5em' }}
                >
                    <GridColumn width={8}>
                        <ProductCompanyDetailsFormPanel
                            company={company}
                            ProductCatalogMutator={this.props.ProductCatalogMutator}
                        />
                    </GridColumn>
                    <GridColumn width={8}>
                        <Panel>
                            <Panel.Header content={'Owner'} />
                            <Panel.Body>
                                <Panel.Item label={'Franchise'}>
                                    <FranchiseEditableItem
                                        value={company.franchise}
                                        onChange={(value) => this.onUpdate('franchise')(value)}
                                    />
                                </Panel.Item>
                            </Panel.Body>
                        </Panel>
                    </GridColumn>
                </Grid>

                <Divider />

                <div style={{ marginLeft: '0.5em', marginRight: '0.5em' }}>
                    <Header>
                        Company Brands
                        <Button
                            size={'tiny'}
                            color={'grey'}
                            basic
                            floated={'right'}
                            icon={'plus'}
                            content={'Add Brand'}
                            onClick={this.onNewBrand}
                        />
                    </Header>
                    <BrandsTable companyId={company.id} />
                </div>
            </VStack>
        );
    }
}
