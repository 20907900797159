import * as React from 'react';
import { Table } from 'semantic-ui-react';
import _range from 'lodash/range';
import styled, { keyframes } from 'styled-components';
import './styles.scss';
import { QueryConnectionState } from '@poolware/api';
import { ConnectionErrorMessage, EmptyBoxImage } from '@poolware/components';

export interface TableColumnDef<T = any> {
    header: string | React.ReactNode;
    cellProps?: Object;
    headerCellProps?: Object;
    cell: (props: T) => any;
    [key: string]: any;
}

export type SectionDef<T> = { sectionTitle: string; data: T[] };

export type TableDef<T> = {
    sections: SectionDef<T>[];
    columns: TableColumnDef<T>[];
};

const baseColor = '#F4F4F4';
const shineColor = 'rgba(229,229,229,0.8)';
const animationDuration = '2.0s';

// this unfortunately uses set px widths for the background-gradient, I never got around to changing it to work with all widths :(
const backgroundGradient = () => `
    background-image: linear-gradient(90deg, ${baseColor} 0px, ${shineColor} 40px, ${baseColor} 80px);
    background-size: 280px;
`;

const ShineLines = keyframes`
    0% {
        background-position: -100px;
    }
    100%{
        background-position: 200px;
    }
`;

export const Line = styled.div`
    //float: left;
    width: 100%;
    min-width: 20px;
    height: 19px;
    border-radius: 7px;
    ${backgroundGradient()};
    animation: ${ShineLines} ${animationDuration} infinite ease-out;
`;

export interface ConnectionTableProps<T = any> {
    connectionState: QueryConnectionState;
    tableDef: TableDef<T>;
}

export const SectionedTable: React.FC<ConnectionTableProps> = ({ tableDef, connectionState }) => {
    const { error, loading } = connectionState;

    const renderEmpty = () => {
        const columnNum = tableDef.columns.length;
        return (
            <Table.Row>
                <Table.Cell colSpan={columnNum} textAlign={'center'}>
                    <EmptyBoxImage style={{ height: '60px', margin: '20px auto 10px' }} />
                </Table.Cell>
            </Table.Row>
        );
    };

    const renderBody = () => {
        if (tableDef.sections.length === 0) {
            return renderEmpty();
        }
        const columnNum = tableDef.columns.length;

        return tableDef.sections.map((section, i) => {
            return (
                <React.Fragment key={i}>
                    <Table.Row className={'pw-report-section-header'}>
                        <Table.Cell colSpan={columnNum} textAlign={'left'}>
                            {section.sectionTitle}
                        </Table.Cell>
                    </Table.Row>
                    {section.data.map((connectionItem) => {
                        return (
                            <Table.Row key={connectionItem.id}>
                                {tableDef.columns.map((td, index) => {
                                    return (
                                        <Table.Cell key={index} {...td.cellProps}>
                                            {td.cell(connectionItem)}
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        );
                    })}
                </React.Fragment>
            );
        });
    };

    const renderLoader = () => {
        // Show 5 lines on initial load.
        const size = 5;
        return _range(size).map((i, index) => {
            return (
                <Table.Row key={index}>
                    {tableDef.columns.map((_, index) => (
                        <Table.Cell key={index}>
                            <Line />
                        </Table.Cell>
                    ))}
                </Table.Row>
            );
        });
    };
    const renderError = () => {
        return (
            <Table.Row>
                <Table.Cell colSpan={tableDef.columns.length}>
                    <ConnectionErrorMessage error={error} />
                </Table.Cell>
            </Table.Row>
        );
    };

    const showLoader = loading && !error;

    return (
        <Table size={'small'} compact={'very'} unstackable={true}>
            <Table.Header>
                <Table.Row>
                    {tableDef.columns.map((col, index) => {
                        const { header, headerCellProps } = col;
                        return (
                            <Table.HeaderCell key={index} {...headerCellProps}>
                                {header}
                            </Table.HeaderCell>
                        );
                    })}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {showLoader && renderLoader()}
                {error && renderError()}
                {!error && !showLoader && renderBody()}
            </Table.Body>
        </Table>
    );
};
