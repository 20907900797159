import * as React from 'react';
import { NodeType, useQueryConnection } from '@poolware/api';
import { FormikSuggestInputProps, SuggestInput, withFormikSuggestField } from '@poolware/components';
import { useDefaultConnectionState } from '@ez/api-core';
import gql from 'graphql-tag';

const query = gql`
    query getEmailTypes {
        viewer {
            emailTypes {
                edges {
                    node {
                        id
                        defaultBody
                        name
                    }
                }
            }
        }
    }
`;

const useQueryEmailTypeConnection = (props?) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    return useQueryConnection<NodeType.EmailType>({
        query: query,
        connectionPath: 'viewer.emailTypes',
        connectionConfig: connectionState,
        variables: variables,
    });
};
export const SuggestInputEmailType: React.FC<FormikSuggestInputProps<NodeType.EmailType>> = ({ onChange, ...rest }) => {
    const { connectionData, connectionState } = useQueryEmailTypeConnection();
    const handleOnSearchChange = (value: string) => {};

    const itemRenderer = (item: NodeType.OrganisationType): string => {
        return item?.name || '-';
    };

    return (
        <SuggestInput
            {...rest}
            items={connectionData}
            loading={connectionState.loading}
            onItemSelect={onChange}
            onQueryChange={handleOnSearchChange}
            itemTextLabel={itemRenderer}
        />
    );
};

export const FormikSuggestInputEmailType = withFormikSuggestField(SuggestInputEmailType);
