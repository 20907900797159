import * as React from 'react';
import { FilterType, useProductCatalogActions } from '../redux';
import { NodeType } from '@poolware/api';
import { TraitDeclarationPickerSelect } from '../components-api-connected';

export interface FilterByTraitExternalProps {
    label?: React.ReactNode | string;
    rootTag: string | null;
}
export interface FilterByTraitProps extends FilterByTraitExternalProps {}

const FilterByTraitDeclaration_Select: React.FC<FilterByTraitProps> = ({ label, rootTag }) => {
    const { ProductCatalogAction, ProductCatalogState } = useProductCatalogActions();

    const filterType = FilterType.DECLARATION;
    const traitFilters = ProductCatalogState.getFilterItemsByType(filterType);

    const current = traitFilters.map((i) => i.value);

    const onChange = (node) => {
        if (!node) {
            return ProductCatalogAction.removeFilterItem({ type: filterType, filterId: null, value: null });
        }

        const checkActive = (pdId) => {
            const found = current.find((c) => {
                return pdId === NodeType.extractId(c);
            });
            return !!found;
        };

        const isActive = checkActive(node.id);

        if (isActive) {
            ProductCatalogAction.removeFilterItem({ type: filterType, filterId: node.id, value: null });
        } else {
            ProductCatalogAction.removeFilterItem({ type: filterType, filterId: null, value: null });
            ProductCatalogAction.addFilterItem({ type: filterType, filterId: node.id, value: node });
        }
    };

    return (
        <TraitDeclarationPickerSelect
            label={label || 'Search by Trait'}
            onChange={onChange}
            value={current[0]}
            rootTag={rootTag}
        />
    );
};

export default FilterByTraitDeclaration_Select;
