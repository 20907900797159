import * as React from 'react';
import { MenuBar, PageLayout, SectionHeader, StickyMenuBar } from '@poolware/components';

export const Page: React.FC = () => {
    return (
        <PageLayout sectionWidth={'screen-md'}>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.HeaderItem icon={'wordpress forms'}>
                        Swimart Training Request Forms - Waterco
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
            </StickyMenuBar>

            <div style={{ height: '20px' }} />
            <PageLayout.BodySection>
                <SectionHeader>QLD</SectionHeader>
                <a
                    target={'_blank'}
                    href={
                        'https://forms.office.com/Pages/ResponsePage.aspx?id=j3SuFJ_UnEe3RCFqvI4wLAjjI2jOpVBClXbeSI6l9R9UMUhJTUxHNEdQNFIzTk1HSFhSS1NYUDlVSy4u'
                    }
                >
                    QLD Training Request Form
                </a>
                <SectionHeader>NSW</SectionHeader>
                <a
                    target={'_blank'}
                    href={
                        'https://forms.office.com/Pages/ResponsePage.aspx?id=j3SuFJ_UnEe3RCFqvI4wLAjjI2jOpVBClXbeSI6l9R9URElOS0hORktTUlZYTlo5ODE1T1NSWElGQS4u'
                    }
                >
                    NSW Training Request Form
                </a>
                <SectionHeader>VIC</SectionHeader>
                <a
                    target={'_blank'}
                    href={
                        'https://forms.office.com/Pages/ResponsePage.aspx?id=j3SuFJ_UnEe3RCFqvI4wLAjjI2jOpVBClXbeSI6l9R9UQzJPVldNOUUzRU5KU081QUZQTkhaWEM5Mi4u'
                    }
                >
                    VIC Training Request Form
                </a>

                <SectionHeader>NZ</SectionHeader>
                <a
                    target={'_blank'}
                    href={
                        'https://forms.office.com/Pages/ResponsePage.aspx?id=j3SuFJ_UnEe3RCFqvI4wLAjjI2jOpVBClXbeSI6l9R9URERTTVVLUjFNSkxEU01FNE02V0ZTQVlCMS4u'
                    }
                >
                    NZ Training Request Form
                </a>
            </PageLayout.BodySection>
        </PageLayout>
    );
};
