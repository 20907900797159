import { Panel, PhotoFileGalleryPanel, SectionHeader, useModalCtrl } from '@poolware/components';
import { NodeType } from '@poolware/api';
import * as React from 'react';
import { FileListTable } from './FileListTable';
import { Icon, Modal, SemanticICONS } from 'semantic-ui-react';
import { FileAttachmentUploader } from '../../connected-components';

export interface FileAttachmentsViewerProps {
    files: NodeType.FileUpload[];
    readOnly?: boolean;
    onFileRemove: (file: NodeType.FileUpload[]) => any;
    onFileDidUpload: (fileId) => any;
    title?: React.ReactNode | string;
    titleIcon?: React.ReactNode | string;
    asPanel?: boolean;
}

const defaultTitle = 'File Attachments';

export const FileAttachmentsViewer: React.FC<FileAttachmentsViewerProps> = ({
    files,
    readOnly,
    onFileRemove,
    onFileDidUpload,
    title = defaultTitle,
    titleIcon,
    asPanel = false,
}) => {
    const modalCtrl = useModalCtrl(false);

    const fileAttachments = files;

    const photoFiles = fileAttachments
        .filter((f) => f?.isImage)
        .map((f) => {
            if (f.imageUrl) {
                return { ...f, urlThumbnail: f.imageUrl + '?size=sm' };
            } else {
                return f;
            }
        });

    const nonPhotoFiles = fileAttachments.filter((f) => !f.isImage);

    const WrapperComp = asPanel ? Panel : React.Fragment;
    const HeaderComp = asPanel ? Panel.Header : SectionHeader;
    const BodyComp = asPanel ? Panel.Body : React.Fragment;

    let titleIconComp = titleIcon ? (
        typeof titleIcon === 'string' ? (
            <Icon name={titleIcon as SemanticICONS} />
        ) : (
            titleIcon
        )
    ) : undefined;

    return (
        <WrapperComp>
            <HeaderComp button={!readOnly && { content: 'Add', icon: 'plus', onClick: modalCtrl.onOpen }}>
                {titleIconComp}
                {title}
            </HeaderComp>
            <BodyComp>
                <PhotoFileGalleryPanel files={photoFiles} onDeleteFiles={onFileRemove} />

                {nonPhotoFiles?.length > 0 && (
                    <>
                        <SectionHeader>Documents</SectionHeader>
                        <FileListTable
                            fileAttachments={nonPhotoFiles}
                            onFileAttachmentRemove={(f) => onFileRemove([f])}
                            readOnly={readOnly}
                        />
                    </>
                )}
            </BodyComp>
            {modalCtrl.open && (
                <Modal {...modalCtrl}>
                    <Panel>
                        <Panel.Header
                            content={'Upload Files'}
                            button={{ content: 'Close', icon: 'cancel', onClick: modalCtrl.onClose }}
                        />
                        <Panel.Body>
                            <FileAttachmentUploader onFileUploadComplete={onFileDidUpload} onDone={modalCtrl.onClose} />
                        </Panel.Body>
                    </Panel>
                </Modal>
            )}
        </WrapperComp>
    );
};
