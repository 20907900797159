import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import { useQueryCustomContracts } from './use-query-custom-contracts-connection';
import { ConnectionTableDef, DefaultConnectionTable, Display, SectionHeader, useModalCtrl } from '@poolware/components';
import { Divider, Modal } from 'semantic-ui-react';
import { FormCustomContractNew } from './FormCustomContract';
import _truncate from 'lodash/truncate';
import { CustomContractView } from './CustomContractView';
import { ContractStatus } from './components';

export interface PanelCustomContractProps {
    customerId: NodeType.ID;
}

export const CustomContractList: React.FC<PanelCustomContractProps> = ({ customerId }) => {
    const modalCtrlNew = useModalCtrl(false);
    const [editContract, setEditContract] = useState<NodeType.CustomContract>(null);

    const { connectionData, connectionState, refetchQuery } = useQueryCustomContracts({ customerId });
    const tableDef: ConnectionTableDef<NodeType.CustomContract> = [
        {
            header: 'Status',
            width: '1',
            cell: (r) => {
                return <ContractStatus contract={r} />;
            },
        },
        {
            header: 'Contract Number',
            width: '1',
            cell: (r) => {
                return <>{r.contractNumber}</>;
            },
        },
        {
            header: 'Start Date',
            width: '1',
            cell: (r) => {
                return <Display.Date format={'ll'} value={r.startDate} />;
            },
        },
        {
            header: 'End Date',
            width: '1',
            cell: (r) => {
                return <Display.Date format={'ll'} value={r.endDate} />;
            },
        },

        {
            header: 'Frequency',
            width: '1',
            cell: (r) => {
                return r.frequency || '';
            },
        },
        {
            header: 'Contract Term',
            width: '1',
            cell: (r) => {
                return <>{r.term}</>;
            },
        },
        {
            header: 'Rate',
            width: '1',
            cell: (r) => {
                return r.rate || '';
            },
        },

        {
            header: 'Notes',
            cell: (r) => {
                return _truncate(r.note, { length: 100 });
            },
        },
    ];

    const onPreview = (contract) => {
        setEditContract(contract);
    };

    if (editContract) {
        return (
            <>
                <Divider />
                <CustomContractView
                    refetchQueries={[refetchQuery]}
                    contractId={editContract.id}
                    onClose={() => setEditContract(null)}
                />
            </>
        );
    }

    return (
        <>
            <SectionHeader
                size={'small'}
                content={'Contracts'}
                button={{ icon: 'plus', content: 'Add', onClick: modalCtrlNew.onOpen }}
            />

            <DefaultConnectionTable
                tableDef={tableDef}
                onRowClick={onPreview}
                connectionData={connectionData}
                connectionState={connectionState}
            />
            <Modal {...modalCtrlNew} closeOnDimmerClick={false}>
                <FormCustomContractNew
                    customerId={customerId}
                    refetchQuery={refetchQuery}
                    onComplete={modalCtrlNew.onClose}
                />
            </Modal>
        </>
    );
};
