import styled from 'styled-components';

export const SummaryContainer = styled.div`
    flex-shrink: 0;
    font-size: 0.9em;
    padding-right: 2em;
    margin-bottom: 1em;

    .summary-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .summary-row-label {
            flex-basis: 100px;
            font-weight: bold;
            flex-grow: 2;
            text-align: end;
        }

        .summary-row-value {
            flex: 0;
            flex-basis: 100px;
            padding-left: 1em;
            text-align: end;
        }
    }
`;
