import * as React from 'react';
import { AppointmentsTableView } from '../AppointmentsTableView';
import { CalNavigate } from '@poolware/react-big-calendar';
import * as dates from 'date-arithmetic';

export function range(start, end, unit = 'day') {
    let current = start,
        days = [];

    while (dates.lte(current, end, unit)) {
        days.push(current);
        current = dates.add(current, 1, unit);
    }

    return days;
}

export class ListDayView extends React.Component<any> {
    public static navigate = (date, action) => {
        switch (action) {
            case CalNavigate.PREVIOUS:
                return dates.add(date, -1, 'day');

            case CalNavigate.NEXT:
                return dates.add(date, 1, 'day');

            default:
                return date;
        }
    };
    public static range = (date) => {
        return [dates.startOf(date, 'day')];
    };

    public static title = (date, { localizer }) => localizer.format(date, 'dayHeaderFormat');

    render() {
        return <AppointmentsTableView events={this.props.events} onSelectEvent={this.props.onSelectEvent} />;
    }
}

export class ListWeekView extends React.Component<any> {
    public static navigate = (date, action) => {
        switch (action) {
            case CalNavigate.PREVIOUS:
                return dates.add(date, -1, 'week');

            case CalNavigate.NEXT:
                return dates.add(date, 1, 'week');

            default:
                return date;
        }
    };
    public static range = (date, { localizer }) => {
        let firstOfWeek = localizer.startOfWeek();
        let start = dates.startOf(date, 'week', firstOfWeek);
        let end = dates.endOf(date, 'week', firstOfWeek);

        return range(start, end);
    };

    public static title = (date, { localizer }) => {
        let [start, ...rest] = ListWeekView.range(date, { localizer });
        return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
    };

    render() {
        return <AppointmentsTableView events={this.props.events} onSelectEvent={this.props.onSelectEvent} />;
    }
}

export class ListMonthView extends React.Component<any> {
    public static navigate = (date, action) => {
        switch (action) {
            case CalNavigate.PREVIOUS:
                return dates.add(date, -1, 'month');

            case CalNavigate.NEXT:
                return dates.add(date, 1, 'month');

            default:
                return date;
        }
    };
    public static range = (date, { localizer }) => {
        let start = dates.startOf(date, 'month');
        let end = dates.endOf(date, 'month');
        return range(start, end);
    };

    public static title = (date, { localizer }) => {
        let [start, ...rest] = ListWeekView.range(date, { localizer });
        return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
    };

    render() {
        return <AppointmentsTableView events={this.props.events} onSelectEvent={this.props.onSelectEvent} />;
    }
}
