import * as React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { JobNote, NotesFeedEvent } from './NotesFeedEvent';
import { NotesFeedNewItem } from './NotesFeedNewItem';
import { ScrollY } from '@poolware/components';

export interface NotesFeedProps {
    notes?: JobNote[];
    onUpdate?: (values: JobNote) => any;
    onAdd?: (values: JobNote) => any;
    onDelete?: (values: JobNote) => any;
    maxHeight?: number;
    mode?: 'inputtop' | 'inputbottom';
}

export const NotesFeed: React.FC<NotesFeedProps> = ({
    onUpdate,
    onAdd,
    onDelete,
    notes,
    maxHeight = 480,
    mode = 'inputtop',
}) => {
    const messagesEndRef = useRef(null);
    const scrollToStart = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = 0;
        }
    };
    useEffect(scrollToStart, [notes]);

    const sortedNotes = useMemo(() => {
        return notes.sort((n1, n2) => {
            if (mode === 'inputtop') {
                return n1.createdAt > n2.createdAt ? -1 : 1;
            } else {
                return n1.createdAt < n2.createdAt ? -1 : 1;
            }
        });
    }, [notes, mode]);

    const hasNotes = notes?.length > 0;

    return (
        <div>
            {mode === 'inputtop' && onAdd && (
                <div style={hasNotes ? { marginBottom: '1rem' } : undefined}>
                    <NotesFeedNewItem placeholder={'Add notes..'} submitButtonLabel={'Add'} onSubmit={onAdd} />
                </div>
            )}
            <ScrollY ref={messagesEndRef} maxHeight={maxHeight}>
                {sortedNotes.map((note) => {
                    return <NotesFeedEvent key={note.id} onSubmit={onUpdate} onDelete={onDelete} note={note} />;
                })}
            </ScrollY>
            {mode === 'inputbottom' && onAdd && (
                <div style={hasNotes ? { marginBottom: '1rem' } : undefined}>
                    <NotesFeedNewItem placeholder={'Add notes..'} submitButtonLabel={'Add'} onSubmit={onAdd} />
                </div>
            )}
        </div>
    );
};
