import * as React from 'react';
import { useQueryServiceJobGroupConnection } from '../../queries/use-query-service-job-group-connection';
import { ActionBarFilterByServiceJobGroup } from '../../components/ActionBarFilters/ActionBarFilterByServiceJobGroup';
import { useRecurServiceJobListActions } from '../../redux/reducer-recur-service-job-list';
import { mapServiceJobGroupsToOptions } from '../../components/ActionBarFilters/ActionBarServiceGroups';

export const FilterByServiceJobGroup: React.FC = () => {
    const { State, Actions } = useRecurServiceJobListActions();
    const { connectionData } = useQueryServiceJobGroupConnection({});
    if (!connectionData.length) {
        return null;
    }
    const options = mapServiceJobGroupsToOptions(connectionData);

    return (
        <ActionBarFilterByServiceJobGroup
            setServiceJobGroup={Actions.setServiceJobGroup}
            setServiceJobTemplate={Actions.setServiceJobTemplate}
            options={options}
            value={State.filters.serviceJobTemplateId || State.filters.serviceGroupId}
            persistStorageKey={'sj.recur.filter.service-group.toggle'}
        />
    );
};
