import { MonthlyHeatPumpProfile, MonthProfile, PoolCover, PoolLocation, PoolTypes } from '../common/types';
import { CLAMP } from '../utils/utils';

const APP_CONST = {
    'KG/M3': 1000,
    'KJ/KGoC': 4.2,
    '1BTU': 0.0002930710701, // KW/H
    '1ft2': 0.92903, // M2
};

function calcHeatUp(requirePoolTemp, outsideTemp, poolType, estimateVol, operatingHours) {
    let heatUp = 0;
    heatUp =
        (APP_CONST['KG/M3'] * APP_CONST['KJ/KGoC'] * estimateVol * (requirePoolTemp - outsideTemp)) /
        (operatingHours * 3600);

    return heatUp;
}

function calcHeatSurfaceLoss(
    poolType = PoolTypes.Pool,
    poolLocation = PoolLocation.Outdoor,
    withCover,
    surfaceAreaTop,
    requirePoolTemp,
    outsideTemp
) {
    // calculate heatSurfaceLost
    let heatSurfaceLost = 0;
    if (poolType === PoolTypes.Pool || poolType === PoolTypes.SPA) {
        if (poolLocation === PoolLocation.Indoor) {
            if (!withCover) {
                heatSurfaceLost = 3 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp);
            } else {
                heatSurfaceLost = 3 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp) * 0.75;
            }
        } else {
            if (!withCover) {
                heatSurfaceLost = 7 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp);
            } else {
                heatSurfaceLost = 7 * APP_CONST['1BTU'] * surfaceAreaTop * (requirePoolTemp - outsideTemp) * 0.45;
            }
        }
    }
    return heatSurfaceLost;
}

export interface HeatPumpHeatProfileInputType {
    yearProfile: MonthProfile[];
    selectedMonths: string[];
    poolTemp: number;
    lowestAirTemp: number;
    poolType: PoolTypes;
    poolLocation: PoolLocation;
    poolCover: PoolCover;
    poolVolume: number;
    operatingDailyHoursPerMonth: number[];
    poolSurfaceAreaTop: number;
    effectiveCapacityFactor?: number;
}

export function computeHeatPumpHeatProfile(input: HeatPumpHeatProfileInputType) {
    const {
        yearProfile,
        poolTemp,
        poolType,
        poolLocation,
        poolCover,
        poolVolume,
        poolSurfaceAreaTop,
        selectedMonths,
        effectiveCapacityFactor = 1,
    } = input;

    const withCover = poolCover === PoolCover.Yes;
    const heatProfile: MonthlyHeatPumpProfile[] = [];
    let operatingDailyHoursPerMonth = input.operatingDailyHoursPerMonth;

    if (operatingDailyHoursPerMonth?.length !== 12) {
        throw new Error('Expected operatingDailyHoursPerMonth to have data for 12 months');
    }

    for (let monthProfile of yearProfile) {
        const ambientTemp = CLAMP(monthProfile.temp * 0.75, 11, poolTemp - 1);
        const isSelected = selectedMonths.findIndex((sm) => sm === monthProfile.id) !== -1;
        let heatTotal = 0;
        let opHours = 0;
        let heatSurfaceLoss = 0;
        let heatUp = 0;

        if (isSelected) {
            opHours = operatingDailyHoursPerMonth[monthProfile.index];
            heatUp = calcHeatUp(poolTemp, ambientTemp, poolType, poolVolume, 12) / 6;
            heatTotal = heatUp;
        }

        const heatMonthProfile = {
            operatingHoursPerDay: opHours,
            monthProfile,
            heatSurfaceLoss,
            operatingTemp: poolTemp,
            heatUp,
            heatTotal: heatTotal,
            isSelected,
        };

        heatProfile.push(heatMonthProfile);
    }

    return heatProfile;
}
