import * as React from 'react';
import { useMemo, useState } from 'react';
import { Button, Divider, Header, Menu, Modal, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { NodeType } from '@poolware/api';
import { AdminOnly, useViewer } from '@poolware/app-shell';
import * as URLBuilder from '../../../../routes/url-builder';
import PoolDetails from '../../../CommonComponents/PoolDetails';
import WaterReportsList from './WaterTestHistory';
import { Embedable as NoteDisplay } from '../../../../components/Note';
import ChemicalTargetList from './ChemicalTargetList';
import EquipmentList from './EquipmentList';
import { PageControlProps } from './Page.Control';
import {
    MenuBar,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    PageHeader,
    PageLayout,
    PageTabs,
    Panel,
    StickyMenuBar,
    TabPaneConf,
    useTabCtrl,
    VStack,
} from '@poolware/components';
import { AppIconNames, IconAppointment, IconRecurServiceCall, IconServiceCall } from '../../../../components/AppIcons';
import { PanelRecurringServicesHistory, PanelServiceCasesHistory } from '../../common-panels/ServiceJobHistoryPanel';
import { PanelBottleLeases } from './BottleLeases';
import { PanelFileAttachments } from './PanelFileAttachments/PanelFileAttachments';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentsPanel } from '../../common-panels/AppointmentsPanel';
import { AppointmentPresentationMode } from '../../common-panels/AppointmentsPanel/AppointmetnsList';
import { PanelAttachedFields } from './PanelAttachedFields';

const Tabs = Object.freeze({
    waterTest: 'waterTest',
    appointments: 'appointments',
    services: 'services',
    recServices: 'rec-services',
    files: 'files',
});

const CalibrationTab: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    return (
        <div>
            <Panel.Item label="Pool Type" content={pool?.type?.name || '--'} />
            <Panel.Item label="Sanitiser Type" content={pool?.sanitiser?.name || '--'} />
            <ChemicalTargetList poolId={pool.id} />
        </div>
    );
};

const EquipmentPanel: React.FC<{ pool: NodeType.Pool; onEdit: () => any }> = ({ pool, onEdit }) => {
    return (
        <Panel>
            <Panel.Header
                basic={true}
                color={'grey'}
                icon={'cubes'}
                content={'Installed Equipment'}
                button={{
                    content: 'Manage',
                    icon: 'edit',
                    onClick: onEdit,
                }}
            />
            <Panel.Body>
                <EquipmentList poolId={pool.id} />
            </Panel.Body>
        </Panel>
    );
};

const PoolDetailsBody: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    const accessKey = pool?.site?.accessKeyLocation || '';
    return (
        <>
            <AdminOnly>
                <Panel.Item color={'red'} label="Franchise">
                    <Link to={URLBuilder.Franchise(pool?.entity?.franchise?.id).view}>
                        {pool?.entity?.franchise?.name}
                    </Link>
                </Panel.Item>
            </AdminOnly>

            <PoolDetails pool={pool} />
            {accessKey && (
                <>
                    <Panel.Divider />
                    <Panel.Item
                        label={'Site Access Key'}
                        // iconMarker={'key'}
                        content={pool?.site?.accessKeyLocation || ''}
                    />
                </>
            )}
        </>
    );
};

const NoteTab = ({ pool, onEdit }) => {
    return <NoteDisplay header={'Pool Notes'} text={pool.note} onSubmit={onEdit} context={pool.id} />;
};

enum HistoryViewMode {
    TABLE,
    GRAPH,
}

const PoolCalibrationModal: React.FC<{ pool: NodeType.Pool }> = ({ pool }) => {
    return (
        <>
            <Modal
                closeOnEscape={true}
                closeIcon={'close'}
                trigger={
                    <Button
                        icon={'settings'}
                        size={'mini'}
                        content={'Calibrations'}
                        popup={{ content: 'Pool Calibrations' }}
                    />
                }
            >
                <Panel>
                    <Panel.Header>Pool Calibrations</Panel.Header>
                    <Panel.Body>
                        <CalibrationTab pool={pool} />
                    </Panel.Body>
                </Panel>
            </Modal>
        </>
    );
};

const WaterTestsHistory: React.FC<{ onViewTest; pool; onNewWaterTest }> = ({ onViewTest, pool, onNewWaterTest }) => {
    const [historyMode, setHistoryMode] = useState(HistoryViewMode.TABLE);

    const handleClick = (newMode: HistoryViewMode) => () => {
        setHistoryMode(newMode);
    };

    return (
        <div>
            <Header size={'small'} icon={'lab'} content={'Water Test History'} />

            <Segment basic clearing style={{ padding: '0' }}>
                {false && (
                    // TODO: Remove Graph from the project.
                    // This features is not really used by anyone,
                    // Pond.js takes to much space in the project.
                    <Button.Group basic={true} size={'mini'}>
                        <Button
                            size={'mini'}
                            active={historyMode === HistoryViewMode.TABLE}
                            icon={'table'}
                            onClick={handleClick(HistoryViewMode.TABLE)}
                        />
                        <Button
                            size={'tiny'}
                            active={historyMode === HistoryViewMode.GRAPH}
                            icon={'chart line'}
                            onClick={handleClick(HistoryViewMode.GRAPH)}
                        />
                    </Button.Group>
                )}
                <div style={{ display: 'inline-block' }}>
                    <PoolCalibrationModal pool={pool} />
                </div>
                <Button
                    content={'New Test'}
                    color={'green'}
                    icon={'plus'}
                    size={'mini'}
                    onClick={onNewWaterTest}
                    floated={'right'}
                />
            </Segment>

            <WaterReportsList poolId={pool.id} onSelect={onViewTest} />
            {/*{historyMode === HistoryViewMode.TABLE ? (*/}
            {/*    <WaterReportsList poolId={pool.id} onSelect={onViewTest} />*/}
            {/*) : (*/}
            {/*    <Segment style={{ marginTop: '0' }} color={'green'}>*/}
            {/*        <ChemicalHistory poolId={pool.id} />*/}
            {/*    </Segment>*/}
            {/*)}*/}
        </div>
    );
};

const PoolPage: React.FC<PageControlProps> = (props) => {
    const { modulesAccess } = useViewer();
    const { AppNavigator, params } = useAppNavigator();
    const { poolId, customerId } = params;
    let { pool, viewerContext } = props;

    const onEditPool = () => {
        let link = URLBuilder.Customer(customerId).Pool(poolId).edit;
        AppNavigator.navigate(link, {
            setOrigin: true,
            query: {
                customerId: customerId,
            },
            modal: false,
        });
    };

    const onEditEquipment = () => {
        let link = URLBuilder.Customer(customerId).Pool(poolId).editEquipmentList;
        AppNavigator.navigate(link);
    };

    const updateNote = async (updatedContent) => {
        await props.PoolMutator.updateNote(props.pool, updatedContent);
    };

    const onNewWaterTest = () => {
        let link = URLBuilder.Customer(customerId).Pool(poolId).WaterTest().new;

        AppNavigator.navigate(link, {
            setOrigin: true,
            query: {
                customerId: customerId,
                poolId: poolId,
            },
        });
    };

    const onViewWaterTest = (test: NodeType.TestReport) => {
        let link = URLBuilder.Customer(customerId).Pool(pool?.id).WaterTest(test.id).view;

        AppNavigator.navigate(link, {
            setOrigin: true,
            query: {
                customerId: customerId,
                poolId: poolId,
            },
        });
    };

    const onPrint = () => {
        const url = URLBuilder.Print().Pool(pool.id).view;
        AppNavigator.navigate(url, { setOrigin: true });
    };

    const onBookAppointment = async () => {
        const customer = pool?.entity?.user?.customer;
        props.BookingAction.startFromPool({
            details: { customer: customer, pool: pool },
            returnTo: props.location.pathname,
        });
    };

    const onServiceCall = () => {
        const customer = pool?.entity?.user?.customer;
        const address = pool?.address;
        props.ServiceJobAction.newServiceJob({
            address: address,
            pool: pool,
            customer: customer,
            returnTo: props.location,
        });
    };

    const onRecurServiceCall = () => {
        const customer = pool?.entity?.user?.customer;
        const address = pool?.site?.address;
        props.ServiceJobAction.newRecurServiceJob({
            address: address,
            pool: pool,
            customer: customer,
            returnTo: props.location.pathname,
        });
    };

    const onGoBack = () => {
        const customerLink = URLBuilder.Customer(customerId).view;
        AppNavigator.navigate(customerLink);
    };

    const panes: TabPaneConf[] = useMemo(() => {
        return [
            modulesAccess.WaterTest?.enabled && {
                title: 'Water Tests',
                icon: 'lab',
                key: Tabs.waterTest,
                render: () => (
                    <WaterTestsHistory pool={pool} onNewWaterTest={onNewWaterTest} onViewTest={onViewWaterTest} />
                ),
            },
            modulesAccess.FieldServices?.appointments && {
                key: Tabs.appointments,
                title: 'Appointments',
                icon: AppIconNames.ServiceJob.Appointment,
                render: () => <AppointmentsPanel pool={pool} mode={AppointmentPresentationMode.Pool} />,
            },
            modulesAccess.FieldServices?.serviceJobs && {
                title: 'Cases',
                icon: AppIconNames.ServiceJob.ServiceCall,
                key: Tabs.services,
                render: () => <PanelServiceCasesHistory pool={pool} customer={pool?.entity?.user?.customer} />,
            },
            modulesAccess.FieldServices?.recurringCases && {
                key: Tabs.recServices,
                title: 'Rec. Services',
                icon: AppIconNames.ServiceJob.RecurringService,
                render: () => <PanelRecurringServicesHistory poolId={pool.id} />,
            },
            modulesAccess.System?.fileUpload && {
                title: 'Files',
                icon: 'file',
                key: Tabs.files,
                render: () => <PanelFileAttachments pool={pool} readOnly={false} />,
            },
        ];
    }, [pool, modulesAccess]);

    const tabCtrl = useTabCtrl({ panes, defaultTab: AppNavigator.tab, onTabChange: AppNavigator.setTab });

    const menuGroup1: MenuBarGroupProps = {
        menu: {
            icon: 'plus',
            color: 'green',
            title: 'New...',
            direction: 'left',
            menuMode: MenuBarGroupMODE.DROPDOWN,
        },
        items: [
            modulesAccess.WaterTest?.enabled && {
                icon: 'lab',
                color: 'green',
                onClick: onNewWaterTest,
                title: 'New Water Test',
            },
            modulesAccess.FieldServices?.serviceJobs && {
                icon: <IconServiceCall />,
                onClick: onServiceCall,
                title: 'New Case',
            },
            modulesAccess.FieldServices?.recurringCases && {
                icon: <IconRecurServiceCall />,
                onClick: onRecurServiceCall,
                title: 'New Recurring Service',
            },
            modulesAccess.FieldServices?.appointments && {
                icon: <IconAppointment />,
                onClick: onBookAppointment,
                title: 'Book Appointment',
            },
        ],
    };
    const menuGroup2: MenuBarGroupProps = {
        menu: {
            icon: 'bars',
            color: 'red',
            menuMode: MenuBarGroupMODE.DROPDOWN_ON_MOBILE,
        },
        items: [
            modulesAccess.System?.printAccess && {
                icon: 'print',
                color: 'blue',
                onClick: onPrint,
                title: 'Print',
            },
        ],
    };

    return (
        <VStack>
            <StickyMenuBar>
                <Menu.Menu position={'left'}>
                    <MenuBar.Item responsive icon={'chevron left'} color={'grey'} onClick={onGoBack}>
                        To Customer
                    </MenuBar.Item>
                    <MenuBar.HeaderItem icon={'life ring'}>Pool</MenuBar.HeaderItem>
                </Menu.Menu>
                <Menu.Menu position={'right'}>
                    <MenuBar.Group {...menuGroup1} />
                    <MenuBar.Group {...menuGroup2} />
                </Menu.Menu>
            </StickyMenuBar>

            <PageHeader>
                {pool.bottleNumber} - {pool.name}
            </PageHeader>

            <PageLayout.TwoColumns>
                <VStack>
                    <Panel>
                        <Panel.Header
                            color={'grey'}
                            icon={'life ring'}
                            button={{ content: 'Edit', icon: 'edit', onClick: onEditPool }}
                        >
                            Pool Details
                        </Panel.Header>
                        <Panel.Body>
                            <PoolDetailsBody pool={pool} />
                        </Panel.Body>
                    </Panel>
                    {modulesAccess.Pools?.poolFlagFields && <PanelAttachedFields pool={pool} />}
                </VStack>
                <VStack>
                    <NoteTab pool={pool} onEdit={updateNote} />
                    <EquipmentPanel pool={pool} onEdit={onEditEquipment} />
                    {modulesAccess.Pools?.barcodedBottles && <PanelBottleLeases pool={pool} />}
                </VStack>
            </PageLayout.TwoColumns>

            <Divider hidden={true} />

            <PageTabs {...tabCtrl} />
        </VStack>
    );
};

export default PoolPage;
