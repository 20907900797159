import * as React from 'react';
import { Button, Header, Modal, Segment } from 'semantic-ui-react';
import { connectWithTheme, getThemeByName, useTheme } from '@poolware/app-shell';
import {
    DebugJSON,
    MenuBarHeaderItem,
    MenuBarSection,
    Panel,
    SemanticToastType,
    StickyMenuBar,
    toast,
    VStack,
} from '@poolware/components';
import { compose, detectBrowser, isMobileDevice, usePersistedString } from '@ez/tools';
import { withAppNavigator } from '@poolware/react-app-navigator';
import 'twin.macro';

const browser = detectBrowser();
console.log(browser);

export const ThemeSwitcher: React.FC = ({}) => {
    const { ThemeAction } = useTheme();
    const [persistedTheme, setThemePersist] = usePersistedString<string>('debug.theme-key', '');

    const setTheme = (themeName?: string) => () => {
        const baseURL = process.env.PUBLIC_URL;
        if (!themeName) {
            ThemeAction.setDefaultTheme();
            setThemePersist('');
        } else {
            const theme = getThemeByName(baseURL, themeName);
            ThemeAction.setTheme(theme);
            setThemePersist(themeName);
        }
    };

    return (
        <Segment>
            <Header content={'App Theme'} />
            <div tw={'flex flex-col gap-2'}>
                <div>
                    <Button.Group basic>
                        <Button onClick={setTheme()}>Default</Button>
                        <Button onClick={setTheme('poolware')}>Poolware</Button>
                        <Button onClick={setTheme('poolware-admin')}>Poolware Admin</Button>
                        <Button onClick={setTheme('poolware-dark')}>Poolware Dark</Button>
                        <Button onClick={setTheme('aquatest')}>Aquatest</Button>
                    </Button.Group>
                </div>

                <Panel>
                    <Panel.Body>
                        <Panel.Item label={'Persisted Theme'} content={persistedTheme || '-'} />
                    </Panel.Body>
                </Panel>
            </div>
        </Segment>
    );
};

class Page extends React.Component<any, any> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            throwTest: false,
            showModal: false,
        };
    }

    showToast = (toastType: SemanticToastType) => () => {
        toast({
            type: toastType,
            title: 'Alert ' + toastType,
            description: 'In Icon (created by Message), SemanticToastContainer.',
        });
    };

    renderToastButtons = () => {
        return (
            <Button.Group basic>
                <Button onClick={this.showToast('error')} content={'Error'} />
                <Button onClick={this.showToast('info')} content={'Info'} />
                <Button onClick={this.showToast('success')} content={'Success'} />
                <Button onClick={this.showToast('warning')} content={'Warning'} />
            </Button.Group>
        );
    };

    setTheme = (themeName?: string) => () => {
        const baseURL = process.env.PUBLIC_URL;
        if (!themeName) {
            this.props.ThemeAction.setDefaultTheme();
        } else {
            const theme = getThemeByName(baseURL, themeName);
            this.props.ThemeAction.setTheme(theme);
        }
    };

    render() {
        return (
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem title={'UI'} />
                    </MenuBarSection>
                </StickyMenuBar>
                <VStack>
                    <Segment>
                        <DebugJSON data={{ browser, isMobile: isMobileDevice() }} />
                    </Segment>
                    <ThemeSwitcher />

                    <Segment.Group vertical>
                        <Segment>
                            <Header content={'Toaster'} />
                            {this.renderToastButtons()}
                        </Segment>
                        <Segment>
                            <Modal trigger={<Button content={'Test in Modal'} />} closeIcon>
                                <Header content="Toasters" />
                                <Modal.Content>{this.renderToastButtons()}</Modal.Content>
                            </Modal>
                        </Segment>
                    </Segment.Group>

                    <Segment>
                        <Header content={'Crash Logger Tests'} />
                        {this.state.throwTest && this.state.throwTestError()}
                        <p>Tests crash loggers</p>
                        <Button onClick={this.crash} content={'Crash'} color={'red'} basic icon={'warning sign'} />
                    </Segment>

                    <div tw={'p-2 text-xs rounded bg-white'}>
                        <Panel>
                            <Panel.Header content={'Text-xs base'} button={{ content: 'Edit', icon: 'edit' }} />
                            <Panel.Body>
                                <Panel.Item label={'Customer'} content="Test" />
                            </Panel.Body>
                        </Panel>
                        <p tw={'text-sm'}>text-sm</p>
                        <p tw={'text-base'}>text-base</p>
                        <p tw={'text-lg'}>text-lg</p>
                    </div>
                    <div tw={'text-xl p-2 rounded bg-white'}>
                        <Panel>
                            <Panel.Header content={'Text-xl base'} button={{ content: 'Edit', icon: 'edit' }} />
                            <Panel.Body>
                                <Panel.Item label={'Customer'} content="Test" />
                            </Panel.Body>
                        </Panel>
                        <p tw={'text-sm'}>text-sm</p>
                        <p tw={'text-base'}>text-base</p>
                        <p tw={'text-lg'}>text-lg</p>
                    </div>
                </VStack>
            </VStack>
        );
    }

    private crash = () => {
        this.setState({ throwTest: true });
    };
}

export default compose(withAppNavigator(), connectWithTheme())(Page);
