import { DocumentNode } from 'graphql';
import { NodeType } from '../api-types';
import { ErrorPolicy, WatchQueryFetchPolicy } from 'apollo-client';
import { QueryResult } from 'react-apollo';

export enum SortDirection {
    ASC = 'ASC',
    DES = 'DES',
}

export interface QueryConnectionConfigProps<TVariablesSearch = any> {
    after: string;
    setAfter: (cursor: string) => any;
    before: string;
    setBefore: (cursor: string) => any;
    pageIndex: number;
    setPageIndex: (n: number) => any;
    pageSize: number;
    defaultPageSize?: number;
    setPageSize: (n: number) => any;
    sortKey: string;
    defaultSortKey: string;
    setSortKey: (key: string) => any;
    sortDirection: SortDirection;
    defaultSortDirection: SortDirection;
    setSortDirection: (dir: SortDirection) => any;
    search: TVariablesSearch;
    defaultSearch: TVariablesSearch;
    setSearch: (props: TVariablesSearch) => any;
    variables?: { [key: string]: any };
    debugName?: string;
}

export interface QueryConnectionFetchResultProps {
    pageInfo?: NodeType.PageInfo;
    pageMeta?: NodeType.PageMeta;
    pageCount?: number;
    totalCount?: number;
    error?;
    loading: boolean;
    isFetchingMore: boolean;
    isPolling: boolean;
    isInitialLoading: boolean;
    refetch: QueryResult['refetch'];
    fetchMore: QueryResult['fetchMore'];
}

export interface QueryConnectionState extends QueryConnectionConfigProps, QueryConnectionFetchResultProps {}

export interface QueryConnectionResult<T = any> {
    connectionData: T[];
    connectionState?: QueryConnectionState;
}

export interface GenericQueryConnection<T extends NodeType.Node = NodeType.AnyNode> {
    query?: DocumentNode;
    connectionPath?: string;
    pollInterval?: number;
    skip?: boolean;
    errorPolicy?: ErrorPolicy;
    fetchPolicy?: WatchQueryFetchPolicy;
    children?: (props: QueryConnectionResult<T>) => any;
}

export interface QueryConnectionExternalProps<T extends NodeType.Node, TVariablesSearch = any>
    extends Partial<QueryConnectionConfigProps<TVariablesSearch>>,
        GenericQueryConnection<T> {}
