import gql from 'graphql-tag';
import _set from 'lodash/set';
import { NodeType, useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const contractFragment = gql`
    fragment CustomContractFragment on CustomContract {
        id
        contractNumber
        startDate
        endDate
        terminationDate
        note
        rate
        createdAt
        updatedAt
        status
        term
        frequency
        entity {
            id
        }
        checkMutations {
            delete
            update
        }
    }
`;

const QL = gql`
    query QueryWarrantyClaimsConnection(
        $first: Int
        $page: Int
        $sort: CustomContractViewerCustomContractsSort
        $search: CustomContractViewerCustomContractsSearch
    ) {
        viewer {
            customContracts(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        ...CustomContractFragment
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${contractFragment}
`;

export interface UseQueryCustomContractsConnectionProps {
    customerId?: NodeType.ID;
}

function mapPropsToSearchQuery(
    props?: UseQueryCustomContractsConnectionProps
): NodeType.CustomContractViewerCustomContractsSearch {
    if (!props) {
        return undefined;
    }

    const { customerId } = props;
    const search = {};
    if (customerId) {
        _set(search, 'entity.id', customerId);
    }
    return search;
}

export function useQueryCustomContracts(props?: UseQueryCustomContractsConnectionProps) {
    const connectionPath = 'viewer.customContracts';
    const { variables, connectionState } = useDefaultConnectionState({ defaultPageSize: 10 });
    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
