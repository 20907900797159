import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { StaffLink } from '../../Links';
import { confirmModalImperative, Display, MenuButton, MenuButtonItemConf } from '@poolware/components';
import styled from 'styled-components';

const EventContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #d2d2d2;
    margin: 0 0 0.75em 0.5em;
    padding: 0.5em;
    border-radius: 5px;
    background-color: white;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        border-style: solid;
        display: block;
        width: 0;
        top: 10px;
        bottom: auto;
        left: -6px;
        border-width: 6px 6px 6px 0;
        border-color: transparent #d0d0d0;
    }

    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        display: block;
        width: 0;
        top: 10px;
        bottom: auto;
        left: -5px;
        border-width: 6px 6px 6px 0;
        border-color: transparent #fff;
    }
`;

const EventHeader = styled.div`
    display: grid;
    grid-template-columns: 36px 1fr 50px;
    grid-template-areas: 'avatar content action';
    align-items: center;
    margin-bottom: 0.5em;
`;

const HeaderAvatar = styled.div`
    grid-area: avatar;
    align-self: center;
    justify-self: start;
`;

const HeaderContent = styled.div`
    grid-area: content;
    align-self: center;
`;
const HeaderAction = styled.div`
    grid-area: action;
    justify-self: end;
`;

const EventContent = styled.div`
    //font-size: 0.9em;
    //display: inline-block;
    //color: #818181;
    font-size: 0.9em;
    border-top: 1px solid #d2d2d2;
    padding: 0.25em 0.5em;
`;

const EventUser = styled.div`
    font-size: 0.9em;
    display: inline-block;

    //color: #818181;
`;

const EventDate = styled.div`
    font-size: 0.9em;
    display: inline-block;
    padding-left: 1em;
    color: #818181;
`;

export const FeedEvent = ({ note, onEdit, onDelete }) => {
    const handleDelete = async (v) => {
        const answer = await confirmModalImperative({
            confirmMessage: {
                header: `Delete Note?`,
                content: 'This cannot be undone!',
            },
            confirmButton: {
                content: 'Delete',
                icon: 'trash',
                negative: true,
            },
        });

        if (answer) {
            await onDelete(v);
        }
    };

    const menuItems: MenuButtonItemConf[] = [
        onEdit && {
            key: 1,
            name: 'Edit',
            icon: 'edit',
            onClick: onEdit,
        },
        onDelete && {
            key: 2,
            name: 'Delete',
            icon: 'trash',
            onClick: handleDelete,
        },
    ].filter(Boolean);

    const hasMenus = menuItems?.length > 0;

    return (
        <EventContainerDiv>
            <EventHeader>
                <HeaderAvatar>
                    <Icon name={'spy'} circular={true} />
                </HeaderAvatar>
                <HeaderContent>
                    <EventUser>
                        <b>
                            <Display.Entity value={note?.addedBy} />
                        </b>
                    </EventUser>
                    <EventDate>
                        <Display.Date value={note?.createdAt} />
                    </EventDate>
                </HeaderContent>
                {hasMenus && (
                    <HeaderAction>
                        <MenuButton
                            // @ts-ignore
                            style={{ padding: '0.5em 0.25em 0.4em 0.75em' }} // HACK
                            size={'tiny'}
                            icon={'ellipsis horizontal'}
                            menuItems={menuItems}
                        />
                    </HeaderAction>
                )}
            </EventHeader>
            <EventContent>
                <Display.TextShortened
                    showLines={8}
                    value={note?.note}
                    expandOnly={true}
                    // style={{ backgroundColor: '#fffff0', padding: '0.5em' }}
                />
            </EventContent>
        </EventContainerDiv>
    );
};
