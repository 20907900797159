import * as React from 'react';
import { useState } from 'react';
import { useField } from 'formik';
import { Button, Form, Icon, Modal } from 'semantic-ui-react';
import { FormikFormFieldLabel, LinkButton, useModalCtrl } from '@poolware/components';
import { ServiceJobTemplatePicker, ServiceJobTemplatePickerProps } from './ServiceJobTemplatePicker';
import { NodeType } from '@poolware/api';
import styled from 'styled-components';
import { ServiceJobTemplatePreview } from '../components/ServiceJobTemplatePreview';
import { ModuleIconNames } from '../constants';

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin: -0.75em 0 2em 1em;
    background-color: #f3f4f5;

    .tp-main {
        padding-left: 0;
        flex-grow: 1;
    }

    .tp-button {
        flex-grow: 0;
    }
`;

export interface FormikServiceJobTemplateInputProps
    extends Pick<
        ServiceJobTemplatePickerProps,
        'defaultSelectAllWorkOrders' | 'requiredWorkOrder' | 'selectWorkOrder'
    > {
    name: string;
    required?: boolean;
    onDidSelect?: (
        serviceJobTemplate: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => any;
    // limit preview to only work orders with the provided ids
    previewWorkOrderIds?: NodeType.ID[];
    onClear?: () => any;
    label?: string;
}

export const FormikServiceJobTemplateSelect: React.FC<FormikServiceJobTemplateInputProps> = ({
    name,
    onDidSelect,
    onClear,
    required,
    selectWorkOrder = false as const,
    previewWorkOrderIds,
    label = 'Template',
    requiredWorkOrder,
    defaultSelectAllWorkOrders,
}) => {
    const [fieldSJ, metaSJ, helpersSJ] = useField<NodeType.ServiceJobTemplate>({ name: name });
    const [fieldWOs, metaWOs, helpersWOs] = useField<NodeType.WorkOrderTemplate[]>({ name: name + '_wos' });
    const [expanded, setExpanded] = useState(false);
    const modalWOPicker = useModalCtrl(false);
    const template = fieldSJ.value;

    const onSubmit = (
        serviceJobTemplate: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        helpersSJ.setValue(serviceJobTemplate);
        helpersWOs.setValue(workOrderTemplates);
        onDidSelect?.(serviceJobTemplate, workOrderTemplates);
        modalWOPicker.onClose();
    };

    const onRemove = () => {
        setExpanded(false);
        helpersSJ.setValue(null);
        onClear?.();
    };

    let hasError = Boolean(metaSJ.touched && metaSJ.error) || Boolean(metaWOs.touched && metaWOs.error);

    const labelComp = <FormikFormFieldLabel name={name} label={label} required={required} />;

    return (
        <>
            <Form.Input
                value={template?.templateTitle || ''}
                type={'text'}
                label={labelComp}
                readOnly={true}
                action
                error={hasError}
                // size={'mini'}
            >
                <input style={{ cursor: 'pointer', backgroundColor: '#f8f8f8' }} onClick={modalWOPicker.onOpen} />
                {template && <Button onClick={onRemove} type={'button'} basic icon={'delete'} />}
                <Button
                    type={'button'}
                    icon={ModuleIconNames.ServiceCall}
                    content={'Select'}
                    onClick={modalWOPicker.onOpen}
                />
            </Form.Input>
            {template && (
                <ContainerDiv>
                    <ServiceJobTemplatePreview
                        serviceJobTemplate={template}
                        full={expanded}
                        workOrderIds={previewWorkOrderIds}
                    />
                    <LinkButton onClick={() => setExpanded(!expanded)}>
                        <Icon name={expanded ? 'caret up' : 'caret down'} />
                        {expanded ? 'Collapse' : 'Expand'}
                    </LinkButton>
                </ContainerDiv>
            )}
            <Modal {...modalWOPicker} size={'large'}>
                {modalWOPicker.open && (
                    <ServiceJobTemplatePicker
                        requiredWorkOrder={requiredWorkOrder}
                        onCancel={modalWOPicker.onClose}
                        onSubmit={onSubmit}
                        selectWorkOrder={selectWorkOrder}
                        defaultSelectAllWorkOrders={defaultSelectAllWorkOrders}
                    />
                )}
            </Modal>
        </>
    );
};
