import Page from './Page';
import { PoolMutatorProps, withPoolMutators } from '@poolware/api';

import { compose } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { withPoolQuery, WithPoolQueryProps } from '../withPoolQuery';

export default compose(
    withAppNavigator(),
    withPoolQuery((props) => props.params.poolId),
    withPoolMutators(['QueryPoolAndPoolEquipmentDetails', 'PoolDetailsQuery'])
)(Page);

export interface PageControlProps extends WithPoolQueryProps, PoolMutatorProps, IAppNavigatorProps {}
