import * as _ from 'lodash';
import moment from 'moment';

import { CalendarStateType, hasActiveFilters, schedulerInitialState } from './reducers-calendar-state';
import { CalResourceMode, CalViewLayoutMode, CalViewMode } from '../Scheduler/types';
import { computeDateRange } from './helpers';
import { isScreenWider } from '@ez/tools';

const isWidescreen = isScreenWider(768);

export const deserializeSchedulerState = (schedulerState?: any) => {
    if (!schedulerState || _.isEmpty(schedulerState)) {
        return _.cloneDeep(schedulerInitialState);
    }

    const state: CalendarStateType = _.cloneDeep(schedulerInitialState);

    if (schedulerState.autoHideEmptyResources !== undefined) {
        _.set(state, 'autoHideEmptyResources', schedulerState.autoHideEmptyResources);
    }

    if (schedulerState.showLeftSidebar !== undefined) {
        if (isWidescreen) {
            state.showLeftSidebar = schedulerState.showLeftSidebar;
        } else {
            // don't show sidebar on mobile devices by default
            state.showLeftSidebar = false;
        }
    }

    if (schedulerState.showRightSidebar !== undefined) {
        state.showRightSidebar = schedulerState.showRightSidebar;
    }

    if (schedulerState.filters !== undefined) {
        const filters = _.pick<CalendarStateType['filters']>(
            schedulerState.filters,
            'staffIds',
            'teamId',
            'isRecurring',
            'excludeAppointmentGroupsIds',
            'includeStatuses',
            'autoHideEmptyResources'
        );
        state.filters = _.merge(state.filters, filters);
        state.filters.hasActiveFilters = hasActiveFilters(state.filters);
    }

    if (schedulerState.viewMode !== undefined) {
        const vm = schedulerState.viewMode;
        const modes = [CalViewMode.MONTH, CalViewMode.DAY, CalViewMode.WEEK, CalViewMode.AGENDA];
        if (modes.includes(vm)) {
            state.viewMode = vm;
        }
    }

    if (schedulerState.activeDate !== undefined) {
        state.activeDate = moment(schedulerState.activeDate).toDate();
    }

    if (schedulerState.previewApptId) {
        state.previewApptId = schedulerState.previewApptId;
    }

    if (schedulerState.zoomFactor !== undefined) {
        state.zoomFactor = schedulerState.zoomFactor;
    }

    if (state.activeDate && state.viewMode) {
        state.displayingDateRange = computeDateRange(state.activeDate, state.viewMode);
    }

    if (schedulerState.layoutMode !== undefined) {
        const vm = schedulerState.layoutMode;
        const modes = [CalViewLayoutMode.GRID, CalViewLayoutMode.LIST];
        if (modes.includes(vm)) {
            state.layoutMode = vm;
        }
    }

    if (schedulerState.resourceMode !== undefined) {
        const rm = schedulerState.resourceMode;
        const modes = [CalResourceMode.COMBINED, CalResourceMode.SPLIT];
        if (modes.includes(rm)) {
            state.resourceMode = rm;
        }
    }

    return state;
};

export const serializeSchedulerState = (state: CalendarStateType) => {
    return _.pick(state, [
        'showLeftSidebar',
        'viewMode',
        'layoutMode',
        'resourceMode',
        'showRightSidebar',
        'filters',
        'previewApptId',
        'zoomFactor',
        'autoHideEmptyResources',
    ]);
};
