import * as React from 'react';
import { useState } from 'react';
import { NodeType } from '@poolware/api';
import {
    Display,
    FormikDatePickerInputField,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    Panel,
    toastError,
} from '@poolware/components';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { ModuleColorNames, ModuleIconNames } from '../../constants';
import { DueDate } from '../../components/DueDate';
import { Modal } from 'semantic-ui-react';
import * as _ from 'lodash';
import { AdminOnly, DropdownStaff, FormikSuggestInputStaff } from '@poolware/app-shell';

import * as Yup from 'yup';

const PanelJobDescription: React.FC<{
    serviceJob: NodeType.ServiceJob;
    onEdit?: () => any;
    serviceJobMutators: ReturnType<typeof useServiceJobMutators>;
    locked?: boolean | string;
}> = ({ serviceJob, onEdit, serviceJobMutators, locked }) => {
    const { update } = serviceJobMutators;
    const title = (
        <>
            <b>{'Service Case'}</b> - {serviceJob.serviceJobNumber}
        </>
    );

    const onChangeStaff = async (newStaff) => {
        try {
            await update({ id: serviceJob.id, staff: newStaff?.id || null });
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header
                color={ModuleColorNames.ServiceCall}
                icon={ModuleIconNames.ServiceCall}
                content={title}
                button={!!onEdit && { icon: 'edit', content: 'Edit', onClick: onEdit, locked: locked }}
            />
            <Panel.Body>
                <AdminOnly>
                    <Panel.Item color={'red'} label="Franchise">
                        {serviceJob?.entity?.franchise?.name}
                    </Panel.Item>
                </AdminOnly>
                <Panel.Item label={'Subject'} content={_.truncate(serviceJob.title, { length: 90 })} />
                {serviceJob.group && (
                    <Panel.Item label={'Group'}>
                        <span tw={'font-bold text-purple-600'}>{serviceJob.group?.title || '--'}</span>
                    </Panel.Item>
                )}
                <Panel.Item iconMarker={'spy'} label={'Assigned To'}>
                    <DropdownStaff value={serviceJob?.staff?.id} onChange={onChangeStaff} />
                </Panel.Item>
                <Panel.Divider />
                <Panel.Item label={'Opened'}>
                    <Display.Date value={serviceJob.createdAt} />{' '}
                </Panel.Item>
                <Panel.Item label={'Opened By'}>
                    <Display.Entity value={serviceJob.createdBy} />
                </Panel.Item>
                <Panel.Item label={'Due Date'}>
                    {serviceJob.dueDate ? (
                        <DueDate
                            format={'LL'}
                            dueDate={serviceJob.dueDate}
                            warnIfOverdue={serviceJob.stage?.status === NodeType.ServiceJobStageStatus.Active}
                        />
                    ) : (
                        <>--</>
                    )}
                </Panel.Item>
                <Panel.Divider />
                <Panel.ItemText
                    maxHeightPx={320}
                    label={'Service Case Description'}
                    labelWidth={'100%'}
                    content={serviceJob.description || '--'}
                />
            </Panel.Body>
        </Panel>
    );
};

const validationSchema = Yup.object().shape({
    description: Yup.string().max(4000, 'Too Long!').nullable(),
    title: Yup.string().required('Required').max(256, 'Too Long!').nullable(),
});

export const PanelFormJobDetails: React.FC<{
    serviceJob: NodeType.ServiceJob;
    serviceJobMutators: ReturnType<typeof useServiceJobMutators>;
    locked?: boolean;
    readonly?: boolean;
}> = ({ serviceJob, serviceJobMutators, readonly, locked }) => {
    const { ServiceJobMutator } = useServiceJobMutators({ refetchQueries: ['QueryServiceJob'] });
    const initialValues = { ...serviceJob };
    const [isEditing, setEditing] = useState(false);

    const onSubmit = async (values: typeof initialValues) => {
        const updateConf = {
            description: values.description,
            customer: values.customer,
            title: values.title,
            staff: values.staff,
            dueDate: values.dueDate,
        };
        try {
            await ServiceJobMutator.update(values.id, updateConf);
            setEditing(false);
            // return updateConf;
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    const title = (
        <>
            <b>Service Case</b> - {serviceJob.serviceJobNumber}
        </>
    );

    const handleOnEdit = () => {
        setEditing(true);
    };
    return (
        <>
            <PanelJobDescription
                serviceJobMutators={serviceJobMutators}
                serviceJob={serviceJob}
                locked={locked}
                onEdit={!readonly && handleOnEdit}
            />
            <Modal size={'small'} open={isEditing} centered={false}>
                <FormikDefaultForm
                    icon={ModuleIconNames.ServiceCall}
                    color={ModuleColorNames.ServiceCall}
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    onCancel={() => setEditing(false)}
                    validationSchema={validationSchema}
                    header={title}
                >
                    {serviceJob.group?.title && (
                        <Panel.Item color={'purple'} label={'Group'}>
                            <div tw={'border-2 '}>{serviceJob.group?.title}</div>
                        </Panel.Item>
                    )}
                    <Panel.Item label={'Service Case ID'} content={serviceJob.serviceJobNumber} />
                    <Panel.Divider />
                    <FormikInputField name={'title'} label={'Subject'} required={true} />
                    <FormikSuggestInputStaff name={'staff'} label="Assigned To" />
                    <FormikDatePickerInputField name={'dueDate'} label={'Due date'} />
                    <FormikTextareaField label={'Service Case Details'} name={'description'} rows={14} />
                </FormikDefaultForm>
            </Modal>
        </>
    );
};
