import * as React from 'react';
import { Button, Icon, List, Table } from 'semantic-ui-react';
import { fromEdges, NodeType, useMutationWorkOrderTemplate } from '@poolware/api';
import { reorderDNDRows, TableRowDraggable } from '../../../components/table-dnd-helpers';
import { ModuleIconNames } from '../../../constants';
import { useEffect, useState } from 'react';
import { ButtonWithPopup } from '@poolware/components';

export interface WorkOrderJobTodosDndTableProps {
    workOrderTemplate: NodeType.WorkOrderTemplate;
    onRemoveAssoc: (assoc: NodeType.JobTodoTemplateAssoc) => any;
    onEditJobTodoTemplate: (assoc: NodeType.JobTodoTemplateAssoc) => any;
    templateMutation: ReturnType<typeof useMutationWorkOrderTemplate>;
}

export const JobTodoTemplateAssocDNDTable: React.FC<WorkOrderJobTodosDndTableProps> = ({
    workOrderTemplate,
    templateMutation,
    onRemoveAssoc,
    onEditJobTodoTemplate,
}) => {
    const [tableRowItems, setTableRowItems] = useState<NodeType.JobTodoTemplateAssoc[]>([]);

    const mutateReorderItems = async (
        template: NodeType.WorkOrderTemplate,
        assocs: NodeType.JobTodoTemplateAssoc[]
    ) => {
        const reindexedItems = assocs
            .map((assoc, index) => ({
                id: assoc.id,
                oldPriority: assoc.priority,
                newPriority: index,
            }))
            .filter((item) => item.oldPriority !== item.newPriority)
            .map((item) => ({ id: item.id, priority: item.newPriority }));

        return await templateMutation.update({
            id: template.id,
            jobTodoTemplateAssocs: {
                update: reindexedItems,
            },
        });
    };

    useEffect(() => {
        if (!workOrderTemplate) {
            setTableRowItems([]);
        } else {
            const items: any[] = fromEdges(workOrderTemplate.jobTodoTemplateAssocs);
            items.sort((l, r) => {
                return l.priority > r.priority ? 1 : -1;
            });
            setTableRowItems(items);
        }
    }, [JobTodoTemplateAssocDNDTable]);

    const dropRow = async () => {
        await mutateReorderItems(workOrderTemplate, tableRowItems);
    };

    const moveRow = (dragIndex, hoverIndex) => {
        const newOrder = reorderDNDRows(tableRowItems, dragIndex, hoverIndex);
        setTableRowItems(newOrder);
    };

    return (
        <Table unstackable={true} compact={true}>
            <Table.Header fullWidth={true}>
                <Table.HeaderCell colSpan={3} textAlign={'center'}>
                    Job Task Templates
                </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
                {tableRowItems.map((assoc, index) => {
                    const items = fromEdges(assoc.jobTodoTemplate.items).sort((a, b) =>
                        a.orderIndex > b.orderIndex ? 1 : -1
                    );

                    return (
                        <TableRowDraggable
                            key={assoc.id}
                            style={{ backgroundColor: '#CCCCC5' }}
                            verticalAlign={'top'}
                            color={'grey'}
                            index={index}
                            moveRow={moveRow}
                            dropRow={dropRow}
                        >
                            <Table.Cell>
                                <details open={true}>
                                    <summary>
                                        (TEMPLATE) <Icon name={ModuleIconNames.WorkOrderItem} /> {assoc.priority} -{' '}
                                        {assoc.jobTodoTemplate.title}
                                    </summary>
                                    <div style={{ padding: '1em 2em' }}>
                                        <List>
                                            {items.map((item) => {
                                                return (
                                                    <List.Item key={item.id}>
                                                        <List.Icon name={'checkmark box'} />
                                                        <List.Content>
                                                            {item.orderIndex} - {item.title}
                                                        </List.Content>
                                                    </List.Item>
                                                );
                                            })}
                                        </List>
                                    </div>
                                </details>
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'} textAlign={'right'} width={2}>
                                <Button
                                    size={'tiny'}
                                    basic
                                    icon={'edit'}
                                    onClick={() => onEditJobTodoTemplate(assoc)}
                                />
                                <ButtonWithPopup
                                    popup={{ content: 'Remove this item' }}
                                    size={'tiny'}
                                    basic
                                    icon={'cancel'}
                                    onClick={() => onRemoveAssoc(assoc)}
                                />
                            </Table.Cell>
                        </TableRowDraggable>
                    );
                })}
            </Table.Body>
        </Table>
    );
};
