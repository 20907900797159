import * as React from 'react';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { NodeType, QueryConnection, QueryConnectionExternalProps, useDefaultConnectionState } from '@poolware/api';

export const brandFragment = gql`
    fragment BrandFragment on Brand {
        id
        name
        identification
        company {
            id
            name
        }
        franchise {
            id
            name
        }
    }
`;

const connectionPath = 'viewer.brands';
const QL = gql`
    query BrandsList($first: Int, $page: Int, $search: BrandViewerBrandsSearch, $sort: BrandViewerBrandsSort) {
        viewer {
            brands(first: $first, page: $page, search: $search, sort: $sort) {
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        id
                        ...BrandFragment
                    }
                }
            }
        }
    }
    ${brandFragment}
`;

export interface QueryBrandConnectionExternalProps extends QueryConnectionExternalProps<NodeType.Brand> {
    nameSearch?: string;
    companyId?: NodeType.ID;
}

function mapPropsToSearchQuery(props: QueryBrandConnectionExternalProps) {
    const { nameSearch, companyId } = props;
    const search = {};
    if (nameSearch) {
        _.set(search, 'name.like', nameSearch);
    }
    if (companyId !== undefined) {
        _.set(search, 'company.id', companyId);
    }
    return search;
}

export const QueryBrandConnection: React.FC<QueryBrandConnectionExternalProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.search = mapPropsToSearchQuery(props);

    return (
        <QueryConnection
            query={props.query || QL}
            variables={variables}
            connectionConfig={connectionState}
            connectionPath={connectionPath}
            children={props.children}
            fetchPolicy={props.fetchPolicy}
        />
    );
};
