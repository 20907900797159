import * as React from 'react';
import { ConnectionTableDef, DefaultConnectionTable, TableRowClickable, TableRowDef } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { useQueryJobTemplatesConnection } from '../../../queries/use-query-job-templates-connection';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { Icon } from 'semantic-ui-react';
import { JobTodoTemplatePreview } from '../../../components/ServiceJobTemplatePreview';

export interface PageTableProps {
    onView: any;
    searchTerm?: string;
}

export const PageTable: React.FC<PageTableProps> = ({ onView, searchTerm }) => {
    const showOwned = false;
    const { connectionState, connectionData } = useQueryJobTemplatesConnection({
        searchAny: searchTerm,
        showOwnedTemplates: showOwned,
    });

    const tableDef: ConnectionTableDef<NodeType.JobTodoTemplate> = [
        {
            header: 'Title',
            sortKey: 'title',
            cell: (item) => (
                <span>
                    <Icon name={ModuleIconNames.WorkOrderItem} />
                    {item.title}
                </span>
            ),
        },
        {
            header: 'Duration',
            cell: (item) => <span>{item.timeEstimate || '--'}</span>,
        },
        false && {
            header: 'Recommended Price',
            cell: (item) => <span>{item.recommendedPrice || '--'}</span>,
        },
        showOwned && {
            header: 'Owned by W/O',
            width: 3,
            cell: (item) => {
                const isOwned = Boolean(item.ownedByWorkOrderTemplate?.id);
                if (!isOwned) return null;
                return (
                    <>
                        <Icon name={'linkify'} />
                        {item.ownedByWorkOrderTemplate?.templateTitle}
                    </>
                );
            },
        },
        {
            header: 'Ownership',
            width: 3,
            cell: (item) =>
                item.franchise ? (
                    item.franchise.name
                ) : (
                    <>
                        System <Icon name={'globe'} />
                    </>
                ),
        },
    ];

    const tableRowProps: TableRowDef<NodeType.JobTodoTemplate> = (connectionDataItem, connectionState) => {
        return {
            comp: (props) => (
                <TableRowClickable
                    popup={{
                        content: (
                            <div style={{ minWidth: '200px' }}>
                                <JobTodoTemplatePreview jobTemplate={connectionDataItem} />
                            </div>
                        ),
                        position: 'top right',
                        positionFixed: true,
                        hoverable: false,
                        basic: true,
                        mouseEnterDelay: 400,
                        // size: "large",
                        // wide: "very"
                    }}
                    onClick={() => {
                        onView(connectionDataItem);
                    }}
                    {...props}
                />
            ),
        };
    };

    return (
        <DefaultConnectionTable
            tableProps={{
                color: ModuleColorNames.JobTodoTemplate,
            }}
            tableRowDef={tableRowProps}
            tableDef={tableDef}
            connectionData={connectionData}
            connectionState={connectionState}
        />
    );
};
