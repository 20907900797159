import { Icon, List } from 'semantic-ui-react';
import { Display, LinkButton, Panel, PanelItemProps } from '@poolware/components';
import {
    getLabelForAppointmentStatus,
    IconAppointmentStatus,
    recurrenceToString,
    useAppointmentViewCtx,
} from '@poolware/app-service-jobs';
import * as React from 'react';
import { NodeType } from '@poolware/api';
import _truncate from 'lodash/truncate';
import { Link } from 'react-router-dom';
import * as URLBuilder from '../../../../routes/url-builder';
import tw, { styled } from 'twin.macro';

export enum AppointmentPresentationMode {
    Pool = 'pool',
    Customer = 'customer',
}

const RepeatCalendarIcon = () => {
    return (
        <Icon.Group>
            <Icon name="calendar outline" />
            <Icon corner name="refresh" />
        </Icon.Group>
    );
};
const CalendarIcon = () => {
    return <Icon name="calendar outline" />;
};

const PanelItem: React.FC<PanelItemProps> = (props) => <Panel.Item labelWidth={'9em'} {...props} />;

const AppointmentItemDetailsContainer = styled.div<{ isActive }>(({ isActive }) => [
    tw`space-y-0 ml-1 pl-3 border-0 border-l-2 border-solid border-blue-50`,
    isActive && tw`border-blue-500 border-l-2`,
]);

const AppointmentsListItem: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    onSelect: (item: NodeType.AppointmentItem) => any;
    mode: AppointmentPresentationMode;
    isActive?: boolean;
}> = ({ appointmentItem, onSelect, mode, isActive }) => {
    const {
        startDate,
        duration,
        staff,
        status,
        isRecurring,
        pool,
        address,
        customer,
        appointment: { recurrence } = { recurrence: null },
    } = appointmentItem;
    const saleUrl = appointmentItem?.vendSale?.historyUrl;

    const poolName = _truncate(pool?.name || '--', { length: 40 });
    const poolVolume = pool?.volume || '--';
    const poolType = pool?.type?.name;
    const poolString = `${poolName} (${poolVolume} L, ${poolType})`;
    let poolURL = null;
    let customerURL = null;
    if (customer && pool) {
        poolURL = URLBuilder.Customer(customer.id).Pool(pool.id).view;
    }
    if (customer) {
        customerURL = URLBuilder.Customer(customer.id).view;
    }

    const handleClick = (e) => {
        e.preventDefault();
        onSelect(appointmentItem);
    };

    return (
        <List.Item>
            <List.Content>
                <div tw={'text-base py-2'}>
                    <LinkButton tw={'font-medium'} onClick={handleClick}>
                        {isRecurring ? <RepeatCalendarIcon /> : <CalendarIcon />}{' '}
                        <Display.DateRange dayFormat={'ddd, DD MMM YYYY'} startDate={startDate} duration={duration} />
                    </LinkButton>
                </div>

                <AppointmentItemDetailsContainer isActive={isActive}>
                    <PanelItem label="Status" labelIcon={<IconAppointmentStatus status={status} />}>
                        {getLabelForAppointmentStatus(status)}
                    </PanelItem>

                    {isRecurring && (
                        <PanelItem label="Recurrence" labelIcon={'refresh'}>
                            {recurrenceToString(recurrence)}
                        </PanelItem>
                    )}

                    {staff && (
                        <PanelItem label="Staff" labelIcon="spy">
                            <Display.Entity value={staff} />
                        </PanelItem>
                    )}

                    {mode === AppointmentPresentationMode.Pool && customerURL && (
                        <PanelItem label="Customer" labelIcon="user">
                            <Link to={customerURL}>
                                <Display.Entity value={customer} />
                            </Link>
                        </PanelItem>
                    )}

                    {mode === AppointmentPresentationMode.Customer && (
                        <>
                            {pool && (
                                <>
                                    <PanelItem label="Pool" labelIcon="life ring">
                                        {poolURL ? <Link to={poolURL}>{poolString}</Link> : poolString}
                                    </PanelItem>
                                    {!address && (
                                        <PanelItem label="Pool Address" labelIcon="marker">
                                            <Display.Address value={pool.address} showMapLink={false} />
                                        </PanelItem>
                                    )}
                                </>
                            )}

                            {address && (
                                <PanelItem label="Address" labelIcon="marker">
                                    <Display.Address value={address} showMapLink={false} />
                                </PanelItem>
                            )}
                        </>
                    )}

                    {saleUrl && (
                        <div tw={'text-sm'}>
                            <Icon name="tag" color={'green'} />
                            <a href={saleUrl} target={'_blank'} rel="noopener noreferrer">
                                Sale Created
                            </a>
                        </div>
                    )}
                </AppointmentItemDetailsContainer>
            </List.Content>
        </List.Item>
    );
};

export const AppointmentsList: React.FC<{
    appointments: NodeType.AppointmentItem[];
    onSelect: (item: NodeType.AppointmentItem) => any;
    mode: AppointmentPresentationMode;
}> = ({ appointments, onSelect, mode }) => {
    const previewItemCtx = useAppointmentViewCtx();

    return (
        <List size={'small'} relaxed={'very'} divided={true}>
            {appointments.map((a) => (
                <AppointmentsListItem
                    isActive={previewItemCtx.appointmentItemId === a.id}
                    key={a.id}
                    appointmentItem={a}
                    onSelect={onSelect}
                    mode={mode}
                />
            ))}
        </List>
    );
};
