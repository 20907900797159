import * as React from 'react';
import { Panel, useModalCtrl } from '@poolware/components';
import { Icon, Modal } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import { Stepper, StepperStep } from '../../components/Stepper';
import { getMessageForStatus, WarrantyClaimStatusGroupEnumFlowConfig } from '../../status-configs';
import { StatusHistory } from '../../components/StatusHistory';

export interface PanelCaseStatusProps {
    claim: NodeType.WarrantyClaim;
}

export const PanelClaimStatus: React.FC<PanelCaseStatusProps> = ({ claim }) => {
    const modal = useModalCtrl(false);

    const status = claim?.status;
    const message = claim?.statusMessage;

    const stageProgressIndex = WarrantyClaimStatusGroupEnumFlowConfig.findIndex(
        (s) => s.statusOptions.findIndex((cs) => cs === status) != -1
    );

    return (
        <>
            <Panel>
                <Panel.Header
                    content={'Status'}
                    button={{ content: 'History', icon: 'history', onClick: modal.onOpen }}
                />
                <Panel.Body>
                    <Stepper className={'horizontal'}>
                        {WarrantyClaimStatusGroupEnumFlowConfig.map((s, i) => {
                            const isActive = stageProgressIndex == i;
                            const isComplete =
                                stageProgressIndex > i ||
                                stageProgressIndex === WarrantyClaimStatusGroupEnumFlowConfig.length - 1;
                            const progressStep = WarrantyClaimStatusGroupEnumFlowConfig[i];
                            return (
                                <StepperStep key={i} className={isActive ? 'active' : undefined}>
                                    {isComplete ? (
                                        <Icon name={'checkmark'} size={'large'} color={'teal'} />
                                    ) : (
                                        <span className={'stepper-number'}>{i + 1}</span>
                                    )}
                                    <span className={'title'}>{progressStep?.name}</span>
                                </StepperStep>
                            );
                        })}
                    </Stepper>
                    <Panel.Item label={'Status'}>
                        <b>{getMessageForStatus(status)}</b>
                    </Panel.Item>
                    <Panel.Divider />
                    <Panel.Item label={'SRN'}>{claim.externalSRN || '--'}</Panel.Item>
                    {/*<Panel.Item label={'SO'}>{claim.externalSO || '--'}</Panel.Item>*/}
                    <Panel.Item label={'RMA'}>{claim.externalRMA || '--'}</Panel.Item>
                    {message && (
                        <Panel.ItemText labelWidth={'100%'} content={message} label={'Comment'} maxHeightPx={300} />
                    )}
                </Panel.Body>
            </Panel>
            <Modal {...modal}>
                <Panel>
                    <Panel.Header
                        content={'Status change history'}
                        button={{ icon: 'close', onClick: modal.onClose }}
                    />
                    <Panel.Body>
                        <StatusHistory claim={claim} />
                    </Panel.Body>
                </Panel>
            </Modal>
        </>
    );
};
