import gql from 'graphql-tag';
import { appointmentItemFragment } from '@poolware/api';

export const PrintAppointmentsQuery = gql`
    query SchedulerAppointmentsPrintList(
        $startDate: Date!
        $endDate: Date!
        $first: Int
        $franchise: ID
        $customer: ID
        $after: String
        $staff: ID
        $search: AppointmentItemSearchCommon
        $isRecurrent: Boolean
    ) {
        viewer {
            appointmentItems(
                startDate: $startDate
                endDate: $endDate
                first: $first
                after: $after
                franchise: $franchise
                customer: $customer
                staff: $staff
                search: $search
                isRecurrent: $isRecurrent
            ) {
                edges {
                    node {
                        ...AppointmentItemFragment
                        ... on AppointmentItem {
                            customer {
                                id
                                note
                            }
                            pool {
                                id
                                volume
                                type {
                                    id
                                    name
                                }
                                sanitiser {
                                    id
                                    name
                                }
                                site {
                                    id
                                    name
                                    accessKeyLocation
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${appointmentItemFragment}
`;
