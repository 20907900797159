import * as React from 'react';
import { useState } from 'react';

import { Button, Form, Popup } from 'semantic-ui-react';
import { PoolVolumeCalculator } from '@poolware/app-calculators';
import { useField } from 'formik';
import { FormikFormFieldLabel } from '@poolware/components';

interface PoolVolumeInputComponentProps {
    name: string;
    label: string;
    required?: boolean;
}

export const FormikPoolVolumeInputField: React.FC<PoolVolumeInputComponentProps> = ({ name, label, required }) => {
    const [showCalc, setShowCalc] = useState(false);
    const [field, meta, helpers] = useField<string>({ name: name });

    const onVolumeSubmit = (volume) => {
        helpers.setValue(volume);
        setShowCalc(false);
    };

    const style = {
        padding: '0',
        backgroundColor: '#ececeec',
    };

    const labelComp = <FormikFormFieldLabel label={label} name={name} required={required} />;

    return (
        <Form.Input label={labelComp} {...field} action>
            <input type={'number'} />
            <Popup
                trigger={<Button type={'button'} onClick={() => setShowCalc(true)} icon="calculator" />}
                position="left center"
                open={showCalc}
                onClose={() => setShowCalc(false)}
                size="mini"
                on="click"
                wide
                style={style}
                content={<PoolVolumeCalculator onVolumeSubmit={onVolumeSubmit} />}
            />
        </Form.Input>
    );
};
