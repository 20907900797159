import * as React from 'react';
import { Route } from 'react-router-dom';
import * as _ from 'lodash';
import { userSelectors } from '@ez/api-core';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { Switch, withRouter } from 'react-router';
import { SystemRouter, LoginRouter } from './pages';

const userIsAuthenticated = connectedRouterRedirect({
    authenticatedSelector: (state) => userSelectors.getIsAuthenticated(state),
    redirectPath: '/login',
    wrapperDisplayName: 'UserIsAuthenticated',
});

class ScrollToTop extends React.Component<any> {
    public static displayName = 'ScrollToTop';

    componentDidUpdate(prevProps: any) {
        const { location } = this.props;
        const ignoreScroll = _.get(this.props, 'location.state.ignoreScroll', false);
        if (!ignoreScroll && location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

const Authenticated = withRouter(userIsAuthenticated(ScrollToTop));

export const AuthenticatedSwitch: React.FC = ({ children }) => (
    <Switch>
        <Route path="/login" component={LoginRouter} />
        <Route path="/system" component={SystemRouter} />
        <Authenticated>
            <Switch>{children}</Switch>
        </Authenticated>
    </Switch>
);
