import * as React from 'react';
import { ButtonWithPopup, ButtonWithPopupProps } from '@poolware/components';

export const ButtonSectionHeader: React.FC<ButtonWithPopupProps> = (props) => {
    return <ButtonWithPopup basic={true} size={'tiny'} style={{ padding: '0.4em 1em' }} {...props} />;
};

export enum AppointmentEditMode {
    Single = 'Single',
    Future = 'Future',
    Recurrence = 'Recurrence',
}
