import { usePersistedNumber, usePersistedString } from '@ez/tools';
import { CalcDocType, PoolCover } from '../common/types';
import * as React from 'react';
import { useContext } from 'react';

const _usePersistedValues = () => {
    const [poolLength, setPoolLength] = usePersistedNumber('HEATER_CALC.poolLength', 8);
    const [poolCover, setPoolCover] = usePersistedString<PoolCover>('HEATER_CALC.PoolCover', PoolCover.Yes);
    const [docType, saveDocType] = usePersistedString<CalcDocType>('HEATER_CALC.DOC_TYPE', CalcDocType.GasHeater);
    const [country, setCountry] = usePersistedString<string>('HEATER_CALC.COUNTRY', 'AUS');
    const [state, setState] = usePersistedString<string>('HEATER_CALC.STATE', 'NSW');
    const [city, setCity] = usePersistedString<string>('HEATER_CALC.CITY', 'SYDNEY');
    const [gasCost, setGasCost] = usePersistedNumber('HEATER_CALC.GAS_COST', 3.1);
    const [operatingHours, setOperatingHours] = usePersistedNumber('HEATER_CALC.operatingHours', 8);
    const [electCost, setElectCost] = usePersistedNumber('HEATER_CALC.ELEC_COST', 0.29);
    return {
        docType,
        saveDocType,
        country,
        setCountry,
        state,
        setState,
        city,
        setCity,
        operatingHours,
        setOperatingHours,
        poolCover,
        setPoolCover,
        poolLength,
        setPoolLength,
        // gasCost,
        // setGasCost,
        electCost,
        setElectCost,
    };
};

const noop = () => {};

const _initContext: PersistedValuesContextType = {
    docType: null,
    saveDocType: noop,
    country: null,
    setCountry: noop,
    state: null,
    setState: noop,
    city: null,
    setCity: noop,
    operatingHours: null,
    setOperatingHours: noop,
    poolCover: null,
    setPoolCover: noop,
    poolLength: null,
    setPoolLength: noop,
    // gasCost: null,
    // setGasCost: null,
    electCost: null,
    setElectCost: noop,
};

export type PersistedValuesContextType = ReturnType<typeof _usePersistedValues>;
export const persistedValuesContext = React.createContext<PersistedValuesContextType>(_initContext);
export const usePersistedCalcValues = () => useContext(persistedValuesContext);
export const PersistedValuesProvider = (props) => {
    const val = _usePersistedValues();
    return <persistedValuesContext.Provider value={val} {...props} />;
};
