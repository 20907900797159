import { Query, QueryResult } from 'react-apollo';
import { fromEdges, NodeType } from '@poolware/api';
import * as React from 'react';
import { DocumentNode } from 'graphql';
import _get from 'lodash/get';

export enum DateRangeResolutionEnum {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Quarter = 'Quarter',
    Year = 'Year',
}

export enum StatsGroupByEnum {
    None = 'None',
    Franchise = 'Franchise',
    Staff = 'Staff',
}

export interface QueryStatsProps {
    startDate: Date;
    endDate: Date;
    groupBy?: StatsGroupByEnum;
    resolution: DateRangeResolutionEnum;
    children: (result: QueryResult & { activitySamples: NodeType.ActivitySample[] }) => JSX.Element | null;
}

export interface QueryStatsComponentProps extends QueryStatsProps {
    queryPath: string;
    query: DocumentNode;
}

export const QueryStats: React.FC<QueryStatsComponentProps> = (props) => {
    const groupBy = props.groupBy && props.groupBy !== StatsGroupByEnum.None ? props.groupBy : undefined;
    const variables = {
        startDate: props.startDate,
        endDate: props.endDate,
        resolution: props.resolution,
        groupBy: groupBy,
    };

    return (
        <Query query={props.query} variables={variables}>
            {(res) => {
                const { data } = res;
                const activitySamples = fromEdges<NodeType.ActivitySample>(_get(data, props.queryPath));
                return props.children({ ...res, activitySamples });
            }}
        </Query>
    );
};
