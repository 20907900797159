import * as React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';

const QL = gql`
    query QueryProductDeclarationByTag($tag: String!) {
        viewer {
            productDeclarationByTag(tag: $tag) {
                id
                tag
                name
                allChildren {
                    id
                    tag
                    name
                    franchise {
                        id
                        name
                    }
                    organisationType {
                        id
                        name
                    }
                    parent {
                        id
                        name
                    }
                    subDeclarations {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const QueryProductDeclarationByTag: React.FC<{
    tag: string;
    children: (props: { declarations: NodeType.ProductTraitDeclaration[]; error?: Error; loading: boolean }) => any;
}> = ({ tag, ...props }) => {
    return (
        <Query fetchPolicy={'cache-first'} query={QL} variables={{ tag: tag }} {...props}>
            {(queryResult) => {
                const { loading, error, data } = queryResult;

                let declarations = [];

                if (!loading && !error) {
                    try {
                        const rootParent = _.get(data, 'viewer.productDeclarationByTag');
                        if (rootParent) {
                            declarations = [rootParent];
                        }
                        const subDeclarations = _.get(data, 'viewer.productDeclarationByTag.allChildren', []);
                        declarations = [...declarations, ...subDeclarations];
                    } catch (e) {
                        console.error(e);
                    }
                }

                return props.children({ declarations, error, loading });
            }}
        </Query>
    );
};
