import { useFormikContext } from 'formik';
import { FormikHeatCalcValueType } from './types';
import { useMutationHeaterRecommendation } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { serializeForm } from './state-serializer';
import { ButtonWithPopup, Display, StickyMenuBar, toastError } from '@poolware/components';
import { Button, Icon } from 'semantic-ui-react';
import * as React from 'react';

export const PageHeaderOpenDocument = () => {
    const { values, dirty } = useFormikContext<FormikHeatCalcValueType>();
    const { update } = useMutationHeaterRecommendation({
        refetchQueries: ['QueryHeaterRecommendationConnection'],
    });

    const { AppNavigator } = useAppNavigator();
    const hr = values.heaterRecommendation;
    const entity = values.entity;

    const onUpdate = async () => {
        try {
            const data = serializeForm(values);
            await update({
                id: values.heaterRecommendation.id,
                payload: data.payload,
                product: data.productId,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onClose = () => {
        AppNavigator.navigateRelative('/combined');
    };

    return (
        <StickyMenuBar>
            <div tw={'bg-yellow-50 flex flex-row w-full p-2 rounded shadow'}>
                <div tw={'flex flex-grow flex-row items-center space-x-4'}>
                    <Button
                        color={'blue'}
                        size={'tiny'}
                        icon={'cancel'}
                        content={'Close'}
                        onClick={() => onClose()}
                        basic={true}
                    />
                    <div tw={'font-bold text-lg'}>
                        <Icon name={'calculator'} />
                        Heater Recommendation - {hr.recNumber}
                    </div>
                    <div tw={'text-indigo-900'}>
                        <Display.Entity value={entity} />
                    </div>
                </div>
                <div tw={'flex flex-grow flex-row justify-end space-x-4'}>
                    {/*<ButtonWithPopup*/}
                    {/*    color={'red'}*/}
                    {/*    size={'tiny'}*/}
                    {/*    icon={'file pdf'}*/}
                    {/*    content={'Generate PDF'}*/}
                    {/*    onClick={() => onOpenPdf()}*/}
                    {/*    basic={true}*/}
                    {/*/>*/}
                    <ButtonWithPopup
                        size={'tiny'}
                        icon={'save outline'}
                        content={'Save'}
                        disabled={!dirty}
                        onClick={() => onUpdate()}
                    />
                </div>
            </div>
        </StickyMenuBar>
    );
};
