import styled from 'styled-components';

export const Stepper = styled.ul`
    counter-reset: section;

    padding: 0;
    display: inline-flex;
    flex-direction: row;
    align-items: stretch;
    margin: 2px 0 0.5em 0;
    background: none;
    box-shadow: none;
    line-height: 1.14285714em;
    border-radius: 0.28571429rem;
    overflow: hidden;
    border: 1px solid rgba(34, 36, 38, 0.25);

    &.horizontal {
        position: relative;
        display: flex;
        justify-content: space-between;
    }
`;

export const StepperStep = styled.li`
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    flex: 1 0 auto;
    flex-wrap: wrap;
    flex-direction: row;
    vertical-align: middle;
    justify-content: center;
    margin: 0 0;
    padding: 0.75em;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: none;
    border-radius: 0;
    border: none;

    &.title {
    }

    &:not(:last-child) {
        border-right: 1px solid rgba(34, 36, 38, 0.15);
    }

    &:not(:last-child):after {
        display: block;
        position: absolute;
        z-index: 2;
        content: '';
        top: 50%;
        right: 0;
        background-color: #fff;
        width: 1.14285714em;
        height: 1.14285714em;
        border: 0 solid rgba(34, 36, 38, 0.15);
        border-right-width: 1px;
        border-bottom-width: 1px;
        transition: background-color 0.1s ease, opacity 0.1s ease, color 0.1s ease, box-shadow 0.1s ease;
        transform: translateY(-50%) translateX(50%) rotate(-45deg);
    }

    .stepper-number {
        padding-right: 0.5em;
        font-size: 2em;
        font-weight: normal;
    }

    color: #495d66;
    background-color: #fff;

    &.active {
        cursor: auto;
        background: #dae0e6;
        &:not(:last-child):after {
            background: #dae0e6;
        }

        & > .title {
            font-weight: bold;
            color: #1d5d90;
        }

        & > .stepper-number {
            font-weight: bold;
        }
    }
`;
