import { useState } from 'react';

const HEARTBEAT_INTERVAL_MS = 3500;
const HEARTBEAT_INTERVAL_INCREASE_STEP_MS = 4000;
const HEARTBEAT_INTERVAL_MS_MAX = 600000;

export const useHeartbeat = (defaultIntervalMs = HEARTBEAT_INTERVAL_MS) => {
    const [intervalMs, setIntervalMS] = useState(defaultIntervalMs);

    const pushError = () => {
        // slightly increase heartbeat interval on any error.
        const newHeartbeat = intervalMs + HEARTBEAT_INTERVAL_INCREASE_STEP_MS;
        if (newHeartbeat > HEARTBEAT_INTERVAL_MS_MAX) {
            // stop heartbeat
            setIntervalMS(null);
        } else {
            setIntervalMS(newHeartbeat);
        }
    };

    const reset = () => {
        // reset to default value
        setIntervalMS(HEARTBEAT_INTERVAL_MS);
    };

    return {
        intervalMs,
        pushError,
        reset,
    };
};
