import * as React from 'react';
import TimeFieldGroup from './FormFields/TimeField';
import { Button, Form, Message, Segment } from 'semantic-ui-react';
import RecurrenceField from './FormFields/RecurrenceField';
import { useFormikContext } from 'formik';
import { AppointmentFormValuesType } from './AppointmentForm';
import { ServiceJobLink, WorkOrderLink } from '../../Links';
import { Panel } from '@poolware/components';
import { recurrenceToString } from '../utils';

export interface RecurrenceTabProps {}

export const FormTabRecurrence: React.FC<RecurrenceTabProps> = ({}) => {
    const { values, setFieldValue, initialValues } = useFormikContext<AppointmentFormValuesType>();

    const { isRecurrenceLocked, isRecurring, isEditing, serviceJob } = values;

    const onIsRecurringChange = (checked: boolean) => {
        const { defaultRecurrence, recurrence } = values;

        if (!recurrence && checked) {
            // When user clicks on the checkbox for the first time,
            // set recurrence value to defaultRecurrence.
            setFieldValue('recurrence', defaultRecurrence);
        }

        setFieldValue('isRecurring', checked);
    };
    const abortRecurrenceChange = () => {
        setFieldValue('isRecurrenceLocked', true);
        setFieldValue('recurrence', initialValues.recurrence);
    };

    if (serviceJob?.isRecurring) {
        return (
            <Panel>
                <Panel.Body>
                    <Panel.Item label="Recurrence Pattern" labelIcon="refresh">
                        {recurrenceToString(values.recurrence)}
                    </Panel.Item>
                    <Message info={true}>
                        <p>
                            This appointment is managed by Recurring Service{' '}
                            <ServiceJobLink id={serviceJob.id}>#{serviceJob?.serviceJobNumber}</ServiceJobLink>.
                        </p>
                        <p>
                            Recurrence pattern can be changed via the{' '}
                            <ServiceJobLink id={serviceJob.id}>Service Case page</ServiceJobLink>.
                        </p>
                    </Message>
                </Panel.Body>
            </Panel>
        );
    }

    return (
        <>
            <TimeFieldGroup />

            <Form.Group widths={'equal'}>
                <Form.Checkbox
                    // className={'recurrence-checkbox'}
                    type="checkbox"
                    disabled={isRecurrenceLocked}
                    label="Recurring event"
                    checked={isRecurring}
                    onChange={(e, { checked }) => {
                        onIsRecurringChange(checked);
                    }}
                />

                {isEditing && isRecurrenceLocked && (
                    <Form.Button
                        floated={'right'}
                        content={'Change Recurrence Pattern'}
                        size={'tiny'}
                        basic
                        onClick={() => {
                            setFieldValue('isRecurrenceLocked', false);
                            // setFieldValue('future', true);
                        }}
                    />
                )}
            </Form.Group>

            {isRecurring && <RecurrenceField disabled={isRecurrenceLocked} />}

            {isRecurring && isEditing && !isRecurrenceLocked && (
                <Segment style={{ backgroundColor: 'pink' }} size={'mini'}>
                    <p>IMPORTANT: Editing recurrence pattern!</p>
                    <p>
                        All future appointments will be affected. New recurrence pattern will be used for current and
                        all future appointments.
                    </p>
                    <Button
                        size={'small'}
                        compact
                        content={'Abort Recurrence Change'}
                        onClick={abortRecurrenceChange}
                    />
                </Segment>
            )}
        </>
    );
};
