import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

/// Fetch entity details via customer
const QL = gql`
    query CustomerAsEntity($id: ID!) {
        node(id: $id) {
            ... on Customer {
                id
                crn
                contactName
                user {
                    id
                    entity {
                        id
                        user {
                            id
                            firstName
                            lastName
                            customer {
                                id
                                crn
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useQueryCustomerDetails = createUseQueryNodeHook<NodeType.Customer>(QL);
