import gql from 'graphql-tag';
import { NodeType, createUseQueryNodeHook } from '@poolware/api';

const QL = gql`
    query QueryWorkOrderTemplate($id: ID!) {
        node(id: $id) {
            id
            ... on WorkOrderTemplate {
                templateTitle
                templateDescription
                providedTitle
                providedDescription
                requiresAddress
                requiresPool
                requiresCustomer
                requiresStaff
                requiresContact
                createdAt
                updatedAt
                ownedByServiceJobTemplate {
                    id
                }
                checkMutations {
                    delete
                    update
                }
                jobTodoTemplateAssocs {
                    edges {
                        node {
                            id
                            isSuggestion
                            priority
                            jobTodoTemplate {
                                id
                                title
                                description
                                recommendedPrice
                                timeEstimate
                                ownedByWorkOrderTemplate {
                                    id
                                }
                                items {
                                    edges {
                                        node {
                                            id
                                            orderIndex
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const useQueryWorkOrderTemplate = createUseQueryNodeHook<NodeType.WorkOrderTemplate>(QL);
