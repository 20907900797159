import contains from 'dom-helpers/contains';
import closest from 'dom-helpers/closest';
import listen from 'dom-helpers/listen';
import { ACTION_NOTIFICATION } from './types';
import { makeDebugger } from '@ez/tools';

const debug = makeDebugger('rbc:selection');

function addEventListener(type, handler, target: any = document, srcNode?: any) {
    debug('📗: Added listener ', { type, target, srcNode });
    // console.trace();
    const clearFn = listen(target, type, handler, { passive: false });
    return () => {
        debug('📗: Remove listener ', { type, target, srcNode });
        return clearFn();
    };
}

function isOverContainer(container, x, y) {
    return !container || contains(container, document.elementFromPoint(x, y));
}

export function getEventNodeFromPoint(node, { clientX, clientY }) {
    let target = document.elementFromPoint(clientX, clientY);
    return closest(target, '.rbc-event', node);
}

export function isEvent(node, bounds) {
    return !!getEventNodeFromPoint(node, bounds);
}

function getEventCoordinates(e) {
    let target = e;

    if (e.touches && e.touches.length) {
        target = e.touches[0];
    }

    return {
        clientX: target.clientX,
        clientY: target.clientY,
        pageX: target.pageX,
        pageY: target.pageY,
    };
}

const clickTolerance = 5;
const clickInterval = 250;

type ListenerHandlerType = any;

class Selection {
    private isDetached: boolean;
    private readonly getContainer?: () => any;
    private readonly globalMouse: boolean;
    private readonly longPressThreshold: number;
    private _listeners: { [key: string]: ListenerHandlerType[] };

    private readonly _removeTouchMoveWindowListener: () => void;
    private readonly _removeKeyDownListener: () => void;
    private readonly _removeKeyUpListener: () => void;

    private _removeDropFromOutsideListener?: () => void;
    private _removeDragOverFromOutsideListener?: () => void;
    private _removeDragEnterFromOutsideListener?: () => void;
    private _removeDragLeaveFromOutsideListener?: () => void;

    private _removeInitialEventListener?: () => void;
    private _removeEndListener?: () => void;
    private _onEscListener: () => void;
    private _removeMoveListener: () => void;
    private _selectRect: any;
    private selecting: boolean;
    private _initialEventData: {
        clientY: any;
        clientX: any;
        x: any;
        y: any;
        isTouch: boolean;
    };
    private _lastClickData?: { timestamp };
    private ctrl: any;
    private onDragEnterTarget = null;
    private readonly debugKey: string;

    consoleGroup = (...args) => {
        return debug.groupCollapsed(`[${this.debugKey}]`, ...args);
    };

    consoleGroupEnd = debug.groupEnd;

    private addEventListener = (type, handler, target: any = document) => {
        const node = this.getContainer?.();
        return addEventListener(type, handler, target, node);
    };

    constructor(
        getNodeFn: () => any,
        conf?: { global?: boolean; longPressThreshold?: any; useDragOverNode?: () => any; debugKey?: string }
    ) {
        const { global: isGlobal = false, longPressThreshold = 250, useDragOverNode, debugKey } = conf || {};
        this.isDetached = false;
        this.debugKey = debugKey || '*';
        this.getContainer = getNodeFn;
        this.globalMouse = !getNodeFn || isGlobal;
        this.longPressThreshold = longPressThreshold;
        this._listeners = Object.create(null);

        this.consoleGroup('new Listener() : ', getNodeFn?.());
        this._handleInitialEvent = this._handleInitialEvent.bind(this);
        this._handleMoveEvent = this._handleMoveEvent.bind(this);
        this._handleTerminatingEvent = this._handleTerminatingEvent.bind(this);
        this._keyListener = this._keyListener.bind(this);
        this._dropFromOutsideListener = this._dropFromOutsideListener.bind(this);
        this._dragOverFromOutsideListener = this._dragOverFromOutsideListener.bind(this);
        this._dragEnterFromOutsideListener = this._dragEnterFromOutsideListener.bind(this);
        this._dragLeaveFromOutsideListener = this._dragLeaveFromOutsideListener.bind(this);

        // Fixes an iOS 10 bug where scrolling could not be prevented on the window.
        // https://github.com/metafizzy/flickity/issues/457#issuecomment-254501356
        this._removeTouchMoveWindowListener = this.addEventListener('touchmove', () => {}, window);
        this._removeKeyDownListener = this.addEventListener('keydown', this._keyListener);
        this._removeKeyUpListener = this.addEventListener('keyup', this._keyListener);

        this._addInitialEventListener();
        if (useDragOverNode) {
            this._addDragAndDropEventListeners(useDragOverNode);
        }
        this.consoleGroupEnd();
    }

    _addDragAndDropEventListeners = (useDragOverNode) => {
        this._removeDropFromOutsideListener = this.addEventListener(
            'drop',
            this._dropFromOutsideListener,
            useDragOverNode?.()
        );

        if (false) {
            this._removeDragOverFromOutsideListener = this.addEventListener(
                'dragover',
                this._dragOverFromOutsideListener,
                useDragOverNode?.()
            );
        }
        this._removeDragEnterFromOutsideListener = this.addEventListener(
            'dragenter',
            this._dragEnterFromOutsideListener,
            useDragOverNode?.()
        );
        this._removeDragLeaveFromOutsideListener = this.addEventListener(
            'dragleave',
            this._dragLeaveFromOutsideListener,
            useDragOverNode?.()
        );
    };

    on(type: ACTION_NOTIFICATION, handler) {
        let handlers = this._listeners[type] || (this._listeners[type] = []);

        handlers.push(handler);

        return {
            remove() {
                let idx = handlers.indexOf(handler);
                if (idx !== -1) handlers.splice(idx, 1);
            },
        };
    }

    emit(type: ACTION_NOTIFICATION, ...args) {
        let result;
        let handlers = this._listeners[type] || [];
        handlers.forEach((fn) => {
            if (result === undefined) result = fn(...args);
        });
        // console.log(`EMIT: ${type} - ${this.debugKey}`, args);

        return result;
    }

    teardown() {
        this.consoleGroup('Selection.teardown', this.getContainer?.());
        this.isDetached = true;
        this._listeners = null;
        this._removeTouchMoveWindowListener?.();
        this._removeInitialEventListener?.();
        this._removeEndListener?.();
        this._onEscListener?.();
        this._removeMoveListener?.();
        this._removeKeyUpListener?.();
        this._removeKeyDownListener?.();
        this._removeDropFromOutsideListener?.();
        this._removeDragEnterFromOutsideListener?.();
        this._removeDragLeaveFromOutsideListener?.();
        this.consoleGroupEnd();
    }

    isSelected(node) {
        let box = this._selectRect;

        if (!box || !this.selecting) return false;

        return objectsCollide(box, getBoundsForNode(node));
    }

    filter(items) {
        let box = this._selectRect;

        //not selecting
        if (!box || !this.selecting) return [];

        return items.filter(this.isSelected, this);
    }

    // Adds a listener that will call the handler only after the user has pressed on the screen
    // without moving their finger for 250ms.
    _addLongPressListener(handler, initialEvent) {
        let timer = null;
        let removeTouchMoveListener = null;
        let removeTouchEndListener = null;
        const handleTouchStart = (initialEvent) => {
            timer = setTimeout(() => {
                cleanup();
                handler(initialEvent);
            }, this.longPressThreshold);
            removeTouchMoveListener = this.addEventListener('touchmove', () => cleanup());
            removeTouchEndListener = this.addEventListener('touchend', () => cleanup());
        };
        const removeTouchStartListener = this.addEventListener('touchstart', handleTouchStart);
        const cleanup = () => {
            if (timer) {
                clearTimeout(timer);
            }
            if (removeTouchMoveListener) {
                removeTouchMoveListener();
            }
            if (removeTouchEndListener) {
                removeTouchEndListener();
            }

            timer = null;
            removeTouchMoveListener = null;
            removeTouchEndListener = null;
        };

        if (initialEvent) {
            handleTouchStart(initialEvent);
        }

        return () => {
            cleanup();
            removeTouchStartListener();
        };
    }

    // Listen for mousedown and touchstart events. When one is received, disable the other and setup
    // future event handling based on the type of event.
    _addInitialEventListener() {
        this.consoleGroup('📕: _addInitialEventListener');
        const removeMouseDownListener = this.addEventListener('mousedown', (e) => {
            this.consoleGroup('📕: _addInitialEventListener-> on mousedown');
            this._removeInitialEventListener();
            this._handleInitialEvent(e);
            this._removeInitialEventListener = this.addEventListener('mousedown', this._handleInitialEvent);
            this.consoleGroupEnd();
        });
        const removeTouchStartListener = this.addEventListener('touchstart', (e) => {
            this._removeInitialEventListener();
            this._removeInitialEventListener = this._addLongPressListener(this._handleInitialEvent, e);
        });

        this._removeInitialEventListener = () => {
            removeMouseDownListener();
            removeTouchStartListener();
        };
        this.consoleGroupEnd();
    }

    _dropFromOutsideListener(e) {
        const { pageX, pageY, clientX, clientY } = getEventCoordinates(e);

        this.emit(ACTION_NOTIFICATION.dropFromOutside, {
            x: pageX,
            y: pageY,
            clientX: clientX,
            clientY: clientY,
        });

        e.preventDefault();
    }

    _dragOverFromOutsideListener(e) {
        const { pageX, pageY, clientX, clientY } = getEventCoordinates(e);
        debug('drag over', { pageX, pageY, clientX, clientY });

        this.emit(ACTION_NOTIFICATION.dragOverFromOutside, {
            x: pageX,
            y: pageY,
            clientX: clientX,
            clientY: clientY,
        });

        e.preventDefault();
    }

    _dragEnterFromOutsideListener(e) {
        const { pageX, pageY, clientX, clientY } = getEventCoordinates(e);
        this.emit(
            ACTION_NOTIFICATION.dragEnterFromOutside,
            {
                x: pageX,
                y: pageY,
                clientX: clientX,
                clientY: clientY,
            },
            e
        );
        this.onDragEnterTarget = e.target;
        // e.stopPropagation();
        e.preventDefault();
    }

    _dragLeaveFromOutsideListener(e) {
        if (e.target !== this.onDragEnterTarget) {
            return;
        }
        this.emit(ACTION_NOTIFICATION.dragLeaveFromOutside, e);
        // e.stopPropagation();
        e.preventDefault();
    }

    _handleInitialEvent(e) {
        if (this.isDetached) {
            return;
        }
        const { clientX, clientY, pageX, pageY } = getEventCoordinates(e);
        const node = this.getContainer();

        // Right clicks
        if (e.which === 3 || e.button === 2 || !isOverContainer(node, clientX, clientY)) return;

        if (!this.globalMouse && node && !contains(node, e.target)) {
            let { top, left, bottom, right } = normalizeDistance(0);

            const offsetData = getBoundsForNode(node);

            const collides = objectsCollide(
                {
                    top: offsetData.top - top,
                    left: offsetData.left - left,
                    bottom: offsetData.bottom + bottom,
                    right: offsetData.right + right,
                },
                { top: pageY, left: pageX }
            );

            if (!collides) return;
        }

        let result = this.emit(
            ACTION_NOTIFICATION.beforeSelect,
            (this._initialEventData = {
                isTouch: /^touch/.test(e.type),
                x: pageX,
                y: pageY,
                clientX,
                clientY,
            })
        );

        if (result === false) return;

        this.consoleGroup('📕: _handleInitialEvent', { srcNode: node });
        switch (e.type) {
            case 'mousedown':
                this._removeEndListener = this.addEventListener('mouseup', this._handleTerminatingEvent);
                this._onEscListener = this.addEventListener('keydown', this._handleTerminatingEvent);
                this._removeMoveListener = this.addEventListener('mousemove', this._handleMoveEvent);
                break;
            case 'touchstart':
                this._handleMoveEvent(e);
                this._removeEndListener = this.addEventListener('touchend', this._handleTerminatingEvent);
                this._removeMoveListener = this.addEventListener('touchmove', this._handleMoveEvent);
                break;
            default:
                break;
        }
        this.consoleGroupEnd();
    }

    _handleTerminatingEvent(e) {
        const { pageX, pageY } = getEventCoordinates(e);

        this.consoleGroup('📕: _handleTerminatingEvent', this.getContainer?.());

        if (!!e.key) {
            if (e.key === 'Escape') {
                return this.emit(ACTION_NOTIFICATION.reset);
            }
            return;
        }

        const _isSelecting = this.selecting;
        this.selecting = false;

        this._removeEndListener?.();
        this._removeMoveListener?.();
        this._onEscListener?.();

        this.consoleGroupEnd();

        if (!this._initialEventData) return;

        let inRoot = !this.getContainer || contains(this.getContainer(), e.target);
        let bounds = this._selectRect;

        let click = this.isClick(pageX, pageY) && !_isSelecting;

        this._initialEventData = null;

        if (!inRoot) {
            return this.emit(ACTION_NOTIFICATION.reset);
        } else if (click && inRoot) {
            return this._handleClickEvent(e);
        } else if (!click) {
            // User drag-clicked in the Selectable area
            return this.emit(ACTION_NOTIFICATION.selected, bounds);
        } else {
            // return this.emit(ACTION_NOTIFICATION.reset);
        }
    }

    _handleClickEvent(e) {
        const { pageX, pageY, clientX, clientY } = getEventCoordinates(e);
        const now = new Date().getTime();

        if (this._lastClickData && now - this._lastClickData.timestamp < clickInterval) {
            // Double click event
            this._lastClickData = null;
            return this.emit(ACTION_NOTIFICATION.doubleClick, {
                x: pageX,
                y: pageY,
                clientX: clientX,
                clientY: clientY,
            });
        }

        // Click event
        this._lastClickData = {
            timestamp: now,
        };
        return this.emit(ACTION_NOTIFICATION.click, {
            x: pageX,
            y: pageY,
            clientX: clientX,
            clientY: clientY,
        });
    }

    _handleMoveEvent(e) {
        if (this._initialEventData === null || this.isDetached) {
            return;
        }

        let { x, y } = this._initialEventData;
        const { pageX, pageY } = getEventCoordinates(e);
        let w = Math.abs(x - pageX);
        let h = Math.abs(y - pageY);

        let left = Math.min(pageX, x),
            top = Math.min(pageY, y),
            old = this.selecting;

        // Prevent emitting selectStart event until mouse is moved.
        // in Chrome on Windows, mouseMove event may be fired just after mouseDown event.
        if (this.isClick(pageX, pageY) && !old && !(w || h)) {
            return;
        }

        this.selecting = true;
        this._selectRect = {
            top,
            left,
            x: pageX,
            y: pageY,
            right: left + w,
            bottom: top + h,
        };

        if (!old) {
            this.emit(ACTION_NOTIFICATION.selectStart, this._initialEventData);
        }

        if (!this.isClick(pageX, pageY)) this.emit(ACTION_NOTIFICATION.selecting, this._selectRect);

        e.preventDefault();
    }

    _keyListener(e) {
        this.ctrl = e.metaKey || e.ctrlKey;
    }

    isClick(pageX, pageY) {
        let { x, y, isTouch } = this._initialEventData;
        return !isTouch && Math.abs(pageX - x) <= clickTolerance && Math.abs(pageY - y) <= clickTolerance;
    }
}

/**
 * Resolve the distance prop from either an Int or an Object
 * @return {Object}
 */
function normalizeDistance(distance = 0) {
    if (typeof distance !== 'object') {
        return {
            top: distance,
            left: distance,
            right: distance,
            bottom: distance,
        };
    }

    return distance;
}

/**
 * Given two objects containing "top", "left", "offsetWidth" and "offsetHeight"
 * properties, determine if they collide.
 * @param  {Object|HTMLElement} nodeA
 * @param nodeB
 * @param tolerance
 * @return {bool}
 */
export function objectsCollide(nodeA, nodeB, tolerance = 0) {
    let { top: aTop, left: aLeft, right: aRight = aLeft, bottom: aBottom = aTop } = getBoundsForNode(nodeA);
    let { top: bTop, left: bLeft, right: bRight = bLeft, bottom: bBottom = bTop } = getBoundsForNode(nodeB);

    return !(
        // 'a' bottom doesn't touch 'b' top
        (
            aBottom - tolerance < bTop ||
            // 'a' top doesn't touch 'b' bottom
            aTop + tolerance > bBottom ||
            // 'a' right doesn't touch 'b' left
            aRight - tolerance < bLeft ||
            // 'a' left doesn't touch 'b' right
            aLeft + tolerance > bRight
        )
    );
}

/**
 * Given a node, get everything needed to calculate its boundaries
 * @param  {HTMLElement|Element|Text} node
 * @return {Object}
 */
export function getBoundsForNode(node) {
    if (!node) {
        console.error(node);
    }
    if (!node.getBoundingClientRect) {
        return node;
    }

    let rect = node.getBoundingClientRect();
    const left = rect.left + pageOffset('left');
    const top = rect.top + pageOffset('top');

    return {
        top,
        left,
        right: (node.offsetWidth || 0) + left,
        bottom: (node.offsetHeight || 0) + top,
    };
}

function pageOffset(dir) {
    if (dir === 'left') {
        return window.pageXOffset || document.body.scrollLeft || 0;
    }
    if (dir === 'top') {
        return window.pageYOffset || document.body.scrollTop || 0;
    }
}

export default Selection;
