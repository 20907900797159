import * as React from 'react';
import { SchedulerPages as Scheduler } from './index';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import { AppointmentPreviewPage } from './AppointmentPreview';
import { CalendarLayoutStateProvider } from './utils/use-calendar-layout-state';

export const Router = () => {
    return (
        <React.Suspense fallback={null}>
            <CalendarLayoutStateProvider>
                <ModuleRootSwitch notFound={NotFoundPage}>
                    <ModuleRoute path="/" exact component={Scheduler.View} />
                    <ModuleRoute path="/new" component={Scheduler.New} />
                    <ModuleRoute path="/new-wo" component={Scheduler.NewWorkOrderAppointment} />
                    <ModuleRoute path="/where" component={Scheduler.WhereToGo} />
                    <ModuleRoute path="/reschedule-confirm" component={Scheduler.PageRescheduleConfirm} />
                    <ModuleRoute path="/appointment-group/new" component={Scheduler.CalendarGroupEditorNew} />
                    <ModuleRoute path="/appointment-group/:id" component={Scheduler.CalendarGroupEditorEdit} />
                    <ModuleRoute path="/:appointmentItem/edit" component={Scheduler.Edit} />
                    <ModuleRoute path="/:appointmentItem/preview" component={AppointmentPreviewPage} />
                </ModuleRootSwitch>
            </CalendarLayoutStateProvider>
        </React.Suspense>
    );
};
