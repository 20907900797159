import gql from 'graphql-tag';
import {
    NodeType,
    poolFragment,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import _set from 'lodash/set';
import { FilterType } from './use-filter-pools';

const QL = gql`
    query QueryPoolConnection($first: Int, $page: Int, $sort: PoolViewerPoolsSort, $search: PoolViewerPoolsSearch) {
        viewer {
            pools(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        ...PoolFragment
                        id
                        createdAt
                        site {
                            id
                            accessKeyLocation
                        }
                        poolBottleLeases(search: { returnedAt: { is: null } }) {
                            edges {
                                node {
                                    id
                                    leasedAt
                                    returnedAt

                                    bottle {
                                        id
                                        scanCode
                                    }
                                }
                            }
                        }
                        franchise {
                            id
                            name
                        }
                        entity {
                            id
                            user {
                                id
                                customer {
                                    id
                                    contactName
                                    crn
                                    user {
                                        id
                                        firstName
                                        lastName
                                    }
                                }
                            }
                            franchise {
                                id
                                name
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${poolFragment}
`;

export interface UseQueryWarrantyClaimsConnectionProps
    extends QueryConnectionExternalProps<NodeType.Pool>,
        Partial<FilterType> {}

function mapPropsToSearchQuery(props?: UseQueryWarrantyClaimsConnectionProps): NodeType.PoolViewerPoolsSearch {
    if (!props) {
        return undefined;
    }

    const search = {};
    if (props.sanitiser) {
        _set(search, 'sanitiser.name.like', props.sanitiser);
    }

    if (props.franchiseNode) {
        _set(search, 'entity.franchise.id', props.franchiseNode.id);
    }
    if (props.poolType) {
        _set(search, 'type.name.like', props.poolType);
    }
    if (props.lastTestDateRange?.startDate) {
        _set(search, 'reports.createdAt.after', props.lastTestDateRange?.startDate);
    }
    if (props.lastTestDateRange?.endDate) {
        _set(search, 'reports.createdAt.before', props.lastTestDateRange?.endDate);
    }
    if (props.lastTestBefore) {
        _set(search, 'reports.createdAt.before', props.lastTestBefore);
    }
    if (props.lastTestAfter) {
        _set(search, 'reports.createdAt.after', props.lastTestAfter);
    }
    if (props.volumeGT) {
        _set(search, 'volume.from', Number(props.volumeGT));
    }
    if (props.volumeLT) {
        _set(search, 'volume.to', Number(props.volumeLT));
    }

    return search;
}

export function useQueryPoolsConnection(props?: UseQueryWarrantyClaimsConnectionProps) {
    const connectionPath = 'viewer.pools';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 100,
        search: mapPropsToSearchQuery(props),
        // ...props,
    });

    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
