import * as React from 'react';
import { FieldArray } from 'formik';
import { ProductTraitFlattened } from '../../../utils';
import { Table } from 'semantic-ui-react';
import { fromEdges, TraitValueType } from '@poolware/api';
import { BooleanInput, NumberInput, SelectInput, TextInput } from '../../../components/FormBuilder';

export const mapPrimitiveTypeToComponent = (type: TraitValueType) => {
    switch (type) {
        case TraitValueType.Float:
        case TraitValueType.Int:
            return NumberInput;
        case TraitValueType.String:
            return TextInput;
        case TraitValueType.Flag:
            return BooleanInput;
        case TraitValueType.Selection:
            return SelectInput;
        default:
            throw new Error('Unknown form input type');
    }
};

export const ProductTraitInputFields: React.FC<{ flattenedTraits: ProductTraitFlattened[]; name: string }> = ({
    name,
    flattenedTraits,
}) => {
    return (
        <Table size={'small'} compact={'very'} unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Trait Name</Table.HeaderCell>
                    <Table.HeaderCell>Value</Table.HeaderCell>
                    <Table.HeaderCell>Misc</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <FieldArray
                    name={name}
                    render={(arrayHelpers) => {
                        return flattenedTraits.map((f, fi) =>
                            f.values.map((ff, ffi) => {
                                const InputComp = mapPrimitiveTypeToComponent(ff.type);
                                const extraProps: any = {};
                                if (ff.type === TraitValueType.Selection) {
                                    const traitField = ff.traitField;
                                    const opts = fromEdges(traitField.options).map((o: any) => ({
                                        key: o.id,
                                        value: o.id,
                                        text: o.name,
                                    }));
                                    extraProps.options = opts;
                                    extraProps.clearable = true;
                                }
                                return (
                                    <Table.Row key={ffi}>
                                        <Table.Cell width={3}>{ff.type}</Table.Cell>
                                        <Table.Cell width={5}>{ff.name} </Table.Cell>
                                        <Table.Cell>
                                            <InputComp
                                                name={`${name}[${fi}].values[${ffi}].formValue`}
                                                {...extraProps}
                                            />
                                        </Table.Cell>
                                        <Table.Cell>{ff.traitField.unit}</Table.Cell>
                                    </Table.Row>
                                );
                            })
                        );
                    }}
                />
            </Table.Body>
        </Table>
    );
};
