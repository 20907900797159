import {
    NodeType,
    QueryConnectionConfigProps,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';
import { fragmentAbstractDoc } from './fragments';
import * as _ from 'lodash';

const defaultQL = gql`
    query QueryAbstractDocConnection(
        $first: Int
        $page: Int
        $sort: AbstractDocumentViewerAbstractDocumentsSort
        $search: AbstractDocumentViewerAbstractDocumentsSearch
    ) {
        viewer {
            abstractDocuments(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        ...QueryAbstractDocFull
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${fragmentAbstractDoc}
`;

export interface QueryServiceJobGroupConnectionProps extends Partial<QueryConnectionConfigProps> {
    search?: NodeType.AbstractDocumentViewerAbstractDocumentsSearch;
}

export function useQueryAbstractDocConnection(
    props?: QueryConnectionExternalProps<NodeType.AbstractDocument> & QueryServiceJobGroupConnectionProps
) {
    const connectionPath = 'viewer.abstractDocuments';
    const { variables, connectionState } = useDefaultConnectionState({
        debugName: 'useQueryAbstractDocConnection',
        defaultSortKey: 'createdAt',
        defaultSortDirection: SortDirection.DES,
        ...props,
    });

    return useQueryConnection<NodeType.ServiceJobGroup>({
        query: props?.query || defaultQL,
        fetchPolicy: props?.fetchPolicy,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}

export const useQueryInvoicesConnection = (
    props?: QueryConnectionExternalProps<NodeType.AbstractDocument> & QueryServiceJobGroupConnectionProps
) => {
    let _p = props;
    if (_p?.search?.typeTag === undefined) {
        _p = _.set(props || {}, 'search.typeTag.is', 'INVOICE');
    }
    return useQueryAbstractDocConnection(_p);
};
