import {
    CalcDocType,
    FormikHeatCalcValueType,
    HeatRecNodePayloadType,
    PoolCover,
    PoolLocation,
    PoolPosition,
    PoolShape,
    PoolTypes,
    WindProfile,
} from './types';
import * as _ from 'lodash';
import { getRecommendationSummary } from './compute-recommendation-summary';
import { NodeType } from '@poolware/api';
import { prepareGasHeaterModel, prepareHeatPumpModel } from './use-equipment-catalog';
import { CalcMode } from '../redux';

export const formDefaultValues: FormikHeatCalcValueType = {
    mode: CalcMode.DEFAULT,
    docType: CalcDocType.GasHeater,
    newCustomerMode: false,
    customer: null,
    newCustomer: {
        firstName: '',
        lastName: '',
        contactName: '',
        companyName: '',
        email: '',
        mobile: '',
    },
    autoRecommendedModel: undefined,
    costEstimation: undefined,
    summary: undefined,
    version: '',
    poolDiameter: 5,
    poolWidth1: 6,
    poolWidth2: 8,
    poolDepth: 1.2,
    poolLength: 8,
    poolWidth: 4,
    poolVolume: 0,
    gasCost: 3.1,
    electCost: 0.29,
    lowestAirTemp: 0,
    operatingHours: 6,
    poolSurfaceAreaTop: 0,
    poolTemp: 28,
    selectedMonths: [],
    yearProfile: [],
    city: 'SYDNEY',
    country: 'AUS',
    state: 'NSW',
    poolCover: PoolCover.Yes,
    poolLocation: PoolLocation.Outdoor,
    poolPosition: PoolPosition.Inground,
    poolShape: PoolShape.Rectangle,
    windProfile: WindProfile.Normal,
    poolType: PoolTypes.Pool,
    userSelectedModel: null,
    availableCalcs: [CalcDocType.GasHeater, CalcDocType.HeatPump],
    availableBrandings: undefined,
};

type KEYS = keyof FormikHeatCalcValueType;

const serializeWhiteList: KEYS[] = [
    'version',
    'docType',
    'brandingType',
    'city',
    'state',
    'country',
    'poolType',
    'poolPosition',
    'poolLocation',
    'poolCover',
    'poolShape',
    'poolLength',
    'poolWidth',
    'poolWidth1',
    'poolWidth2',
    'poolDiameter',
    'poolDepth',
    'poolVolume',
    'poolSurfaceAreaTop',
    'poolTemp',
    'operatingHours',
    'lowestAirTemp',
    'gasCost',
    'electCost',
];

const deserializeWhiteList = serializeWhiteList; //.find((i) => i !== 'summary');

export type FormSerializeResult<T> = {
    expiryDate?: Date;
    note?: string;
    payload: T;
    entityId?: string;
    productId?: string;
};

const pathKey = {
    [CalcDocType.GasHeater]: 'gas' as const,
    [CalcDocType.HeatPump]: 'hpump' as const,
    [CalcDocType.HeatPumpAutopool]: 'hpump' as const,
};

export const serializeForm = (
    values: Partial<FormikHeatCalcValueType>,
    docType?: CalcDocType
): FormSerializeResult<Partial<HeatRecNodePayloadType>> => {
    let document: Partial<HeatRecNodePayloadType> = _.pick(values, serializeWhiteList);
    document.docType = docType || values.docType;
    document.version = '1.0.0';

    const key = pathKey[document.docType];
    if (!key) {
        throw new Error('Unknown doc type ' + docType);
    }

    const productId = values.userSelectedModel?.[key]?.id || values.autoRecommendedModel?.[key]?.id;
    document.selectedMonths = values.selectedMonths;
    document.yearProfile = values.yearProfile?.map((mp) => {
        const costMonthly = values.costEstimation?.[key]?.costByMonth.find((c) => c.monthId == mp.id);
        const isEnabled = values.selectedMonths?.findIndex((m) => m == mp.id) !== -1;
        return {
            ...mp,
            ...costMonthly,
            enabled: isEnabled,
        };
    });
    document.poolVolume = Number(document.poolVolume?.toFixed(2));
    document.windProfile = values.windProfile;
    document.costEstimation = {
        costYearly: values.costEstimation?.[key]?.costYearly,
        numberOfUnits: values.costEstimation?.[key]?.numberOfUnits,
        minimumOutput: values.costEstimation?.[key]?.minimumOutput,
    };

    const summary = getRecommendationSummary({
        type: document.docType,
        costEstimation: values.costEstimation,
    });
    document.model = {
        id: summary?.model?.id,
        sku: summary?.model?.sku,
        name: summary?.model?.name,
        capacity: summary?.model?.capacity,
        input: summary?.model?.input,
        cop: summary?.model?.cop,
    };
    const entityId = values?.entity?.id;
    return {
        entityId: entityId,
        productId: productId,
        payload: document,
    };
};

export const deSerializeToForm = <T extends FormikHeatCalcValueType>(
    node?: NodeType.HeaterRecommendation
): Partial<T> => {
    try {
        const payload = node.payload as FormSerializeResult<T>['payload'];
        if (!node.payload?.docType) {
            throw new Error('Doc type is not provided');
        }

        let userSelectedModel: T['userSelectedModel'] = { gas: null, hpump: null };
        if (node.product) {
            switch (node.payload?.docType) {
                case CalcDocType.HeatPump:
                    userSelectedModel.hpump = prepareHeatPumpModel(node.product);
                    break;
                case CalcDocType.GasHeater:
                    userSelectedModel.gas = prepareGasHeaterModel(node.product);
                    break;
            }
        }
        return {
            // ..._.pick<T>(payload, deserializeWhiteList),
            ...payload,
            userSelectedModel: userSelectedModel,
            entity: node.entity,
            heaterRecommendation: node,
        };
    } catch (e) {
        console.error(e);
        return {
            // @ts-ignore
            error: e,
        };
    }
};
