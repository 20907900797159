import { ModuleLink } from '@poolware/react-app-navigator';
import { NodeType } from '@poolware/api';
import * as React from 'react';
import { unflattenToTree } from '../utils';
import { TreeRenderer } from './TreeRenderer';

const renderPDLink = (pd) => <ModuleLink to={`/${pd.id}`}>{pd.name}</ModuleLink>;

export interface DeclarationTreeProps {
    declarations: NodeType.ProductTraitDeclaration[];
    itemRenderer?: (pd: NodeType.ProductTraitDeclaration) => any;
}

export const DeclarationTree: React.FC<DeclarationTreeProps> = ({ declarations, itemRenderer = renderPDLink }) => {
    const tree = unflattenToTree(declarations);
    return <TreeRenderer tree={tree} itemRenderer={itemRenderer} />;
};
