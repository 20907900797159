import * as React from 'react';
import { FormikDefaultForm, FormikInputField, FormikSelectField, toastError } from '@poolware/components';
import { getConfig } from '../../../config';
import { PageControlProps } from './Page.Control';
import { NodeType } from '@poolware/api';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    roleId: Yup.string().required('Required'),
    firstName: Yup.string().max(100, 'Too Long!').required('Required'),
    lastName: Yup.string().max(100).required('Required'),
    email: Yup.string().email().required('Required'),
});

const StaffEditPage: React.FC<PageControlProps> = (props) => {
    const { staff, roles, AppNavigator } = props;

    const initialValues = {
        firstName: staff?.user?.firstName,
        lastName: staff?.user?.lastName,
        roleId: staff?.role?.id,
        email: staff?.registration?.email,
    };

    const roleOptions = roles?.map((node: any) => {
        return { text: node.name, value: node.id };
    });

    const onSubmit = async (values: typeof initialValues) => {
        const { mutateStaffRegistration } = props;
        try {
            let mutationConfig: NodeType.UpdateStaffRegistrationMutationInput = {
                id: staff?.registration?.id,
                email: values.email?.trim(),
                staff: {
                    user: {
                        firstName: values.firstName?.trim(),
                        lastName: values.lastName?.trim(),
                    },
                },
            };
            if (values.roleId) {
                mutationConfig.staff.role = values.roleId;
            }

            await mutateStaffRegistration.update(mutationConfig);
            AppNavigator.navigateToOrigin();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to update', description: e.message });
        }
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin();
    };

    const resetPassword = () => {
        window.open(getConfig().api.passwordResetURL, '_blank');
    };

    const headerButton = {
        content: 'Reset Password',
        onClick: resetPassword,
        color: 'orange',
    };

    return (
        <FormikDefaultForm
            debug={true}
            validationSchema={validationSchema}
            header={'Edit Staff Details'}
            headerButton={headerButton}
            initialValues={initialValues}
            submitButton={{ content: 'Update' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <FormikInputField name={'firstName'} label={'First Name'} required={true} />
            <FormikInputField name={'lastName'} label={'Last Name'} required={true} />
            <FormikSelectField options={roleOptions} name={'roleId'} label={'Role'} required={true} />
            <FormikInputField name={'email'} label={'email'} required={true} />
        </FormikDefaultForm>
    );
};

export default StaffEditPage;
