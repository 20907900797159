import gql from 'graphql-tag';
import { customerFragment, NodeType } from '@poolware/api';
import { useQuery } from 'react-apollo';

const QL = gql`
    query findCustomerByCrn($crn: String!) {
        viewer {
            resolveCustomerByCRN(crn: $crn) {
                id
                ...CustomerFragment
            }
        }
    }
    ${customerFragment}
`;

export const useQueryResolveCustomer = (input: { crn: string }) => {
    const res = useQuery<NodeType.Query>(QL, {
        variables: { crn: input.crn },
        skip: !input.crn,
    });
    let customer = undefined;
    if (!res.loading && !res.error) {
        customer = res.data?.viewer?.resolveCustomerByCRN;
    }

    return {
        ...res,
        customer,
    };
};
