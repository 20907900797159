import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { NodeType, QueryConnectionExternalProps, useDefaultConnectionState, useQueryConnection } from '@poolware/api';
import moment from 'moment';
import * as _ from 'lodash';
import gql from 'graphql-tag';
import { appointmentItemFragment } from './query-appointment-items-calendar';
import { DateRange, UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import { filterAppointmentItems } from './filter-appointment-items';
import { RECUR_FILTER } from '../../redux/helpers';

const connectionPath = 'viewer.appointmentItems';
export const AppointmentItemsDefaultQuery = gql`
    query SchedulerAppointmentsList_Report(
        $startDate: Date!
        $endDate: Date!
        $first: Int
        $franchise: ID
        $customer: ID
        $after: String
        $staff: ID
        $search: AppointmentItemSearchCommon
        $isRecurrent: Boolean
    ) {
        viewer {
            appointmentItems(
                startDate: $startDate
                endDate: $endDate
                first: $first
                after: $after
                franchise: $franchise
                customer: $customer
                staff: $staff
                search: $search
                isRecurrent: $isRecurrent
            ) {
                edges {
                    node {
                        ...Calendar_AppointmentItemFragment
                        vendSale {
                            id
                            historyUrl
                            webRegistryUrl
                        }
                    }
                }
            }
        }
    }
    ${appointmentItemFragment}
`;

export interface QueryAppointmentItemsConnectionExternalProps
    extends QueryConnectionExternalProps<NodeType.AppointmentItem> {
    dateRange: DateRange;
    customerId?: string;
    franchiseId?: string;
    filters?: any;
    pollInterval?: number;
    reverse?: boolean;
    staffId?: NodeType.ID;
    staffIds?: NodeType.ID[];
    // When searching by `staffId` only, the request will also include search for unassigned appointments,
    // i.e. appointments with `staff = null`.
    // To exclude unassigned appointments, `staffIds` as null
    teamId?: NodeType.ID;
}

export const mapPropsToSearchQuery = (props: Omit<QueryAppointmentItemsConnectionExternalProps, 'children'>) => {
    const { dateRange, franchiseId, customerId } = props || ({} as any);
    const isoStartDate = moment(dateRange.startDate).toISOString();
    const isoEndDate = dateRange.endDate ? moment(dateRange.endDate).add(1, 's').toISOString() : undefined;

    let search: NodeType.AppointmentItemSearchCommon = {};
    if (props.filters?.serviceJobTemplateId) {
        _.set(search, 'serviceJob.usedTemplate', [props.filters.serviceJobTemplateId]);
    }
    if (props.filters?.serviceGroupId) {
        _.set(search, 'serviceJob.group', [props.filters.serviceGroupId]);
    }

    if (props.filters?.staffIds?.length > 0) {
        const ids = props.filters.staffIds.filter(Boolean).map((id) => {
            if (id === UNASSIGNED_APPOINTMENT_STAFF_ID) {
                // Use 'null' for fetching unassigned appointments
                return null;
            }
            return id;
        });
        if (ids.length > 0) {
            _.set(search, 'staff.id', ids);
        }
    }

    if (props.filters?.teamId) {
        _.set(search, 'staff.team', props.filters?.teamId);
        if (props.filters?.staffIds?.length === 0) {
            _.set(search, 'staff.id', [null]);
        }
    }

    let isRecurrent = undefined;
    if (props.filters?.isRecurring === RECUR_FILTER.RECURRING) {
        isRecurrent = true;
    } else if (props.filters?.isRecurring === RECUR_FILTER.ONEOFF) {
        isRecurrent = false;
    }

    return {
        staff: props?.staffId || undefined,
        reverse: props.reverse,
        first: props.pageSize || 1500,
        franchise: franchiseId || undefined,
        customer: customerId || undefined,
        startDate: isoStartDate,
        endDate: isoEndDate,
        search: search,
        isRecurrent: isRecurrent,
    };
};

export const useQueryAppointmentItemsConnection = (props?: QueryAppointmentItemsConnectionExternalProps) => {
    let { variables, connectionState } = useDefaultConnectionState({
        variables: mapPropsToSearchQuery(props),
        debugName: 'AppointmentItemsQuery',
        ...props,
    });

    // if staffId is set, ignore filters.staffIds array.
    // `staffId` field will be used as a part of gql query.
    const ignoreStaffFiler = !!props.staffId || props.staffIds?.length > 0;
    const postfilter = useCallback(
        (connectionData) => filterAppointmentItems(connectionData, props?.filters, ignoreStaffFiler),
        [ignoreStaffFiler, props?.filters]
    );

    return useQueryConnection<NodeType.AppointmentItem>({
        query: props.query || AppointmentItemsDefaultQuery,
        pollInterval: props.pollInterval,
        skip: props.skip,
        fetchPolicy: props.fetchPolicy || 'cache-and-network',
        errorPolicy: props.errorPolicy || 'ignore',
        variables: variables,
        connectionPath: props.connectionPath || connectionPath,
        connectionConfig: connectionState,
        postfetchFilter: postfilter,
    });
};
