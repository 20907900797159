import * as React from 'react';
import { fromEdges, NodeType } from '@poolware/api';
import { Display } from '@poolware/components';
import { Icon } from 'semantic-ui-react';

export const JobTodoTemplatePreview: React.FC<{ jobTodoTemplate: NodeType.JobTodoTemplate }> = ({
    jobTodoTemplate,
}) => {
    const items = fromEdges(jobTodoTemplate.items).sort((l, r) => {
        return l.orderIndex > r.orderIndex ? 1 : -1;
    });

    const totalCount = items.length;

    const jobTodoIsActionable = totalCount == 0;
    const jobTodoClipboardIconName = 'clipboard list';
    const jobTodoCheckIconName = 'square outline';

    return (
        <div className={'jobtodo'}>
            <div className={'jobtodo-title'}>
                <div className={`item-row ${jobTodoIsActionable ? 'actionable' : ''}`}>
                    {jobTodoIsActionable ? (
                        <>
                            <div className={'item-icon-lvl1'}>
                                <Icon name={jobTodoClipboardIconName} />
                            </div>
                            <div className={'item-icon-lvl2'}>
                                <Icon name={jobTodoCheckIconName} />
                            </div>
                        </>
                    ) : (
                        <div className={'item-icon-lvl1'}>
                            <Icon name={jobTodoClipboardIconName} />
                        </div>
                    )}
                    <div className={'item-title'}>{`${jobTodoTemplate.title}`}</div>
                </div>
            </div>
            <div className={'jobtodo-content'}>
                {jobTodoTemplate.description && (
                    <div className={'jobtodo-description'}>
                        <Display.Text value={jobTodoTemplate.description} />
                    </div>
                )}
                {items.map((item, i) => {
                    const iconName = 'square outline';
                    return (
                        <div key={item.id} className={`item-row actionable jobtodo-item`}>
                            <div className={'item-icon-lvl2'}>
                                <Icon name={iconName} />
                            </div>
                            <div className={'item-title'}>{item.title}</div>
                            <div className={'item-button'} />
                        </div>
                    );
                })}
            </div>
            {(jobTodoTemplate.recommendedPrice > 0 || jobTodoTemplate.timeEstimate > 0) && (
                <div className={'summary-row'}>
                    {jobTodoTemplate.recommendedPrice > 0 && (
                        <div className={'summary-row-item'}>{`$ ${jobTodoTemplate.recommendedPrice}.00`}</div>
                    )}
                    {jobTodoTemplate.timeEstimate > 0 && (
                        <div className={'summary-row-item'}>{`${jobTodoTemplate.timeEstimate} min`}</div>
                    )}
                </div>
            )}
        </div>
    );
};
