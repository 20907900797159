import { FilterType, ProductCatalogActionProps, useProductCatalogActions } from '../redux';
import * as React from 'react';
import { SuggestBrandInput, SuggestProductCompanyInput, SuggestSupplierInput } from '../components-api-connected';

export interface FilterByOptionValueExternalProps {
    label?: string;
    placeholder?: string;
}

type ConfType = {
    filterType: FilterType;
    defaultLabel: string;
};

const suggestInputs = {
    [FilterType.PRODUCT_COMPANY]: SuggestProductCompanyInput,
    [FilterType.BRAND]: SuggestBrandInput,
    [FilterType.SUPPLIER]: SuggestSupplierInput,
};

const createConnectedInput = (conf: ConfType): React.FC<FilterByOptionValueExternalProps> => {
    return ({ label, placeholder }) => {
        const { ProductCatalogAction, ProductCatalogState } = useProductCatalogActions();
        const filterType = conf.filterType;
        const onItemSelect = (node) => {
            if (!node) {
                ProductCatalogAction.removeFilterItem({ type: filterType, filterId: null, value: node });
            } else {
                ProductCatalogAction.replaceFilterItem({
                    type: filterType,
                    filterId: filterType.toString(),
                    value: node,
                });
            }
        };

        const filterValue: any = ProductCatalogState.getFilterValueByType(filterType);
        const SuggestInputComponent = suggestInputs[filterType];
        return (
            <SuggestInputComponent
                label={label || conf.defaultLabel}
                placeholder={placeholder}
                value={filterValue}
                onChange={onItemSelect}
            />
        );
    };
};

export const FilterByProductCompany = createConnectedInput({
    filterType: FilterType.PRODUCT_COMPANY,
    defaultLabel: 'Product Company',
});

export const FilterByBrand = createConnectedInput({
    filterType: FilterType.BRAND,
    defaultLabel: 'Brand',
});

export const FilterBySupplier = createConnectedInput({
    filterType: FilterType.SUPPLIER,
    defaultLabel: 'Supplier',
});
