import * as React from 'react';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { MenuBar, MenuBarHeaderItem, MenuBarSection, PageLayout, VStack } from '@poolware/components';
import { Menu } from 'semantic-ui-react';
import { InvoiceSettingsDefaultSender } from './InvoiceSettingsDefaultSender';
import { InvoiceSettingsDefaultPayment } from './InvoiceSettingsDefaultPayment';

export interface InvoiceSettingsProps {}

export const InvoiceSettingsPage: React.FC<InvoiceSettingsProps> = ({}) => {
    const { AppNavigator } = useAppNavigator();
    return (
        <ModuleRoot>
            <VStack>
                <MenuBar>
                    <MenuBarSection>
                        <MenuBar.Item
                            icon={'chevron left'}
                            title={'Back'}
                            onClick={() => AppNavigator.navigateToOrigin('/', { relativeToModule: true })}
                        />
                        <MenuBarHeaderItem icon={'puzzle'}>Invoice Setting</MenuBarHeaderItem>
                    </MenuBarSection>
                </MenuBar>
                <PageLayout.ThreeColumnManaged
                    layoutColumns={3}
                    main={
                        <ModuleSwitch notFound={() => <div>Not found</div>}>
                            <ModuleRedirect path={'/'} exact to={'/payment'} />
                            <ModuleRoute path={'/payment'} component={InvoiceSettingsDefaultPayment} />
                            <ModuleRoute path={'/sender'} component={InvoiceSettingsDefaultSender} />
                        </ModuleSwitch>
                    }
                    leftSidebar={
                        <div>
                            <Menu pointing secondary vertical style={{ width: '100%' }} color={'blue'}>
                                <Menu.Item to={'/payment'} as={ModuleNavLink}>
                                    Payment
                                </Menu.Item>
                                <Menu.Item to={'/sender'} as={ModuleNavLink}>
                                    Sender
                                </Menu.Item>
                            </Menu>
                        </div>
                    }
                />
            </VStack>
        </ModuleRoot>
    );
};
