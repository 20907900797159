import { bindActionCreators, combineReducers } from 'redux';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { ActionsUnion, createAction, createReducer } from '@ez/tools';
import { ConnectionPageStateType, defaultConnectionPageState, NodeType } from '@poolware/api';
import * as _ from 'lodash';
import { createStructuredSelector } from 'reselect';

export enum WorkOrderBookingStatus {
    ANY = 'ANY',
    BOOKED = 'BOOKED',
    UNBOOKED = 'UNBOOKED',
}

export enum WorkOrderRecurStatus {
    ANY = 'ANY',
    RECURRING = 'RECURRING',
    ONEOFF = 'ONEOFF',
}

export type WorkOrderListStateType = {
    filters: {
        franchise: { id?: string; name?: string };
        staffIds: string[];
        workOrderNumber: string;
        recurStatus: WorkOrderRecurStatus;
        bookingStatus: WorkOrderBookingStatus;
        hasActiveFilters: boolean;
    };
    page: ConnectionPageStateType;
};

export const initialState: WorkOrderListStateType = {
    filters: {
        franchise: {},
        staffIds: [],
        workOrderNumber: null,
        recurStatus: WorkOrderRecurStatus.ANY,
        hasActiveFilters: false,
        bookingStatus: WorkOrderBookingStatus.UNBOOKED,
    },
    page: defaultConnectionPageState,
};

const hasActiveFilters = (state: WorkOrderListStateType['filters']) => {
    return (
        state.staffIds?.length > 0 || !!state.workOrderNumber
        // || state.includeStatuses.length > 0
        // || state?.bookingStatus !== WorkOrderBookingStatus.UNBOOKED
    );
};

// Actions and action creators

export enum WORK_ORDER_LIST_ACTION {
    CLEAR_ALL_FILTER = '@WorkOrderList/CLEAR_ALL_FILTER',
    DATERANGE_FILTER = '@WorkOrderList/DATERANGE_FILTER',
    PAGE_INDEX = '@WorkOrderList/PAGE_INDEX',
    RECUR_FILTER = '@WorkOrderList/RECUR_FILTER',
    STAFF_FILTER = '@WorkOrderList/VIEW_STAFF_FILTER',
    BOOKING_FILTER = '@WorkOrderList/BOOKING_FILTER',
    FRANCHISE_FILTER = '@WorkOrderList/FRANCHISE_FILTER',
    WORK_ORDER_ID = '@WorkOrderList/WORK_ORDER_ID',
}

const WorkOrderListAction = {
    clearAllFilters: () => createAction(WORK_ORDER_LIST_ACTION.CLEAR_ALL_FILTER),
    setPageIndex: (index: number) => createAction(WORK_ORDER_LIST_ACTION.PAGE_INDEX, { index }),
    setStaffFilter: ({ includeIds }) => createAction(WORK_ORDER_LIST_ACTION.STAFF_FILTER, { includeIds: includeIds }),
    setFranchiseFilter: (franchise?: { id?: string; name?: string }) =>
        createAction(WORK_ORDER_LIST_ACTION.FRANCHISE_FILTER, { franchise }),
    setRecurFilter: (recurStatus: WorkOrderRecurStatus) =>
        createAction(WORK_ORDER_LIST_ACTION.RECUR_FILTER, recurStatus),
    setBookingFilter: (bookingStatus: WorkOrderBookingStatus) =>
        createAction(WORK_ORDER_LIST_ACTION.BOOKING_FILTER, { bookingStatus }),
    setWorkOrderId: (woId: string) => createAction(WORK_ORDER_LIST_ACTION.WORK_ORDER_ID, { woId }),
};

export type WorkOrderListAction = ActionsUnion<typeof WorkOrderListAction>;

const filters = createReducer(
    initialState.filters,
    (newState, action: WorkOrderListAction): WorkOrderListStateType['filters'] => {
        switch (action.type) {
            case WORK_ORDER_LIST_ACTION.CLEAR_ALL_FILTER:
                return initialState.filters;
            case WORK_ORDER_LIST_ACTION.WORK_ORDER_ID:
                newState.workOrderNumber = action.payload.woId;
                break;
            case WORK_ORDER_LIST_ACTION.STAFF_FILTER: {
                newState.staffIds = action.payload.includeIds;
                break;
            }
            case WORK_ORDER_LIST_ACTION.FRANCHISE_FILTER: {
                const { franchise } = action.payload;
                const { id, name } = franchise || { id: null, name: null };
                newState.franchise = { id: id, name: name };
                break;
            }
            case WORK_ORDER_LIST_ACTION.BOOKING_FILTER:
                newState.bookingStatus = action.payload.bookingStatus;
                break;
            case WORK_ORDER_LIST_ACTION.RECUR_FILTER:
                newState.recurStatus = action.payload;
                break;

            default:
                return newState;
        }
        newState.hasActiveFilters = hasActiveFilters(newState);
        return newState;
    }
);

const page = createReducer(initialState.page, (state, action: WorkOrderListAction): WorkOrderListStateType['page'] => {
    switch (action.type) {
        default:
            return state;
        case WORK_ORDER_LIST_ACTION.STAFF_FILTER:
        case WORK_ORDER_LIST_ACTION.FRANCHISE_FILTER:
        case WORK_ORDER_LIST_ACTION.WORK_ORDER_ID:
        case WORK_ORDER_LIST_ACTION.CLEAR_ALL_FILTER:
        case WORK_ORDER_LIST_ACTION.BOOKING_FILTER:
        case WORK_ORDER_LIST_ACTION.RECUR_FILTER:
            state.index = 0;
            break;
        case WORK_ORDER_LIST_ACTION.PAGE_INDEX:
            state.index = action.payload.index;
            break;
    }
    return state;
});

export const reducerWOList = combineReducers({
    filters,
    page,
});

const memoizedSelector = createStructuredSelector<WorkOrderListStateType, WorkOrderListStateType>({
    filters: (state) => state.filters,
    page: (state) => state.page,
});

export interface WorkOrderListActionProps {
    WorkOrderListAction: typeof WorkOrderListAction;
    WorkOrderListState: WorkOrderListStateType;
}

export const useWorkOrderListActions = (): WorkOrderListActionProps => {
    const state = useSelector((state: RootStateOrAny) => memoizedSelector(state.serviceJob.workOrderList));
    const dispatch = useDispatch();

    return {
        WorkOrderListState: state,
        WorkOrderListAction: bindActionCreators(WorkOrderListAction, dispatch),
    };
};

export const deserializeWorkOrderList = (restoredState?: Partial<WorkOrderListStateType>) => {
    const state: WorkOrderListStateType = _.cloneDeep(initialState);

    if (!restoredState || _.isEmpty(restoredState)) {
        return state;
    }

    if (restoredState.filters) {
        state.filters.hasActiveFilters = hasActiveFilters(state.filters);
    }

    return state;
};

export const serializeWorkOrderList = (state: WorkOrderListStateType) => {
    return _.pick(state, 'filters.includeStatuses');
};
