import * as React from 'react';
import {
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    PageLayout,
    Panel,
    StickyMenuBar,
    toastError,
} from '@poolware/components';
import { PageControlProps } from './Page.Control';
import { PoolForm } from '../Components/PoolForm';
import { Icon, Message } from 'semantic-ui-react';
import * as URLBuilder from '../../../../routes/url-builder';

const WarningMessage = () => {
    return (
        <Message negative>
            <Message.Header>
                <Icon name={'warning sign'} color={'yellow'} />
                DANGER!!!
                <Icon name={'warning sign'} color={'yellow'} />
            </Message.Header>
            <Message.Content>
                <p />
                <p>This pool and all its history will be deleted PERMANENTLY!</p>
                <p>This action is irreversible!</p>
            </Message.Content>
        </Message>
    );
};

interface DeletePermission {
    deletable: boolean;
    reason?: string;
}

const DropdownItemDeleteButton: React.FC<{ onDelete: any; deletePermission: DeletePermission }> = ({
    onDelete,
    deletePermission,
}) => {
    return (
        <MenuBarDropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            title="Delete Pool"
            onClick={onDelete}
            disabled={!deletePermission.deletable}
            popup={!deletePermission.deletable ? { content: deletePermission.reason } : undefined}
            confirm={{
                confirmMessage: {
                    header: 'Delete Pool?',
                    content: <WarningMessage />,
                },
                confirmButton: {
                    content: 'Delete Pool',
                    icon: 'trash',
                    negative: true,
                },
            }}
        />
    );
};

class Page extends React.Component<PageControlProps, { isMutating: boolean }> {
    state = {
        isMutating: false,
    };

    onSubmit = async (formValues) => {
        this.setState({ isMutating: true });
        try {
            await this.props.PoolMutator.updatePool(this.props.pool, {
                name: formValues.name,
                type: formValues.type,
                sanitiser: formValues.sanitiser,
                volume: formValues.volume,
            });
            this.setState({ isMutating: false }, () => {
                this.props.AppNavigator.navigateToOrigin();
            });
        } catch (error) {
            console.error('there was an error sending the query', error);
            this.setState({ isMutating: false });
            throw new Error(error);
        }
    };

    onCancel = () => {
        this.goBack();
    };

    goBack = () => {
        this.props.AppNavigator.navigateToOrigin();
    };

    deletePermission = () => {
        // if this.props.Permissions.Site is not provider, show button.
        // The server will fail request anyway if not enough permissions to delete site.
        const deletePermission =
            this.props.Permissions && this.props.Permissions.Pool ? this.props.Permissions.Pool.delete : true;
        if (!deletePermission) {
            return {
                deletable: false,
                reason: 'Not enough permission',
            };
        }

        return {
            deletable: true,
            reason: undefined,
        };
    };

    onDelete = async () => {
        const r = window.confirm('Delete Pool?');
        if (r !== true) {
            return;
        }

        const redirectUrl = this.props.customerId ? URLBuilder.Customer(this.props.customerId).view : '/';

        try {
            this.setState({ isMutating: true });
            await this.props.mutatePool.delete({ id: this.props.pool.id });
            this.setState({ isMutating: false }, () => {
                this.props.AppNavigator.replace(redirectUrl);
            });
        } catch (error) {
            toastError({ title: 'Failed to Delete', description: error.message });
            console.error(error);
            this.setState({ isMutating: false });
        }
    };

    render() {
        const organisationId = this.props?.pool?.entity?.franchise?.organisationType?.id;
        const { pool } = this.props;
        const { site } = pool;
        const initialValues = {
            name: pool.name,
            volume: pool.volume,
            type: pool.type,
            sanitiser: pool.sanitiser,
        };

        return (
            <PageLayout>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={this.goBack} icon={'chevron left'} title={'Back to site'} />
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        <MenuBarDropdown icon={'bars'} color={'red'}>
                            <DropdownItemDeleteButton
                                deletePermission={this.deletePermission()}
                                onDelete={this.onDelete}
                            />
                        </MenuBarDropdown>
                    </MenuBarSection>
                </StickyMenuBar>
                <PageLayout.BodySection width={'screen-md'}>
                    <PoolForm
                        header={'Edit Pool'}
                        icon={'life ring'}
                        initialValues={initialValues}
                        onSubmit={this.onSubmit}
                        onCancel={this.onCancel}
                        organisationId={organisationId}
                        submitButton={{ content: 'Update' }}
                    >
                        <Panel.Item label="Site Name" content={site.name} />
                        <Panel.Item label="Pool Number" content={pool.bottleNumber} />
                        <Panel.ItemAddress label="Address" content={site.address} />
                        <Panel.ItemEntity label="Customer" content={pool?.entity?.user} />
                        <Panel.Divider />
                    </PoolForm>
                </PageLayout.BodySection>
            </PageLayout>
        );
    }
}

export default Page;
