import { connect, useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { ThemeInterface, getThemeByName } from '@poolware/themes';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import invariant from 'invariant';

const createAction = (type, payload = {}) => {
    return { type, payload };
};
export type ThemeStateType = {
    theme: ThemeInterface;
};

const baseURL = process.env.PUBLIC_URL;

const defaultTheme = getThemeByName(baseURL, 'poolware');

/// ***** ACTIONS ***** ////
export const THEME_ACTION = {
    SET_THEME: '@@THEME_ACTION/SET_THEME',
};

export const ThemeAction = {
    setTheme: (theme: ThemeInterface) => createAction(THEME_ACTION.SET_THEME, theme),
    setDefaultTheme: () => createAction(THEME_ACTION.SET_THEME, defaultTheme),
};

////******** REDUCERS **********////

export const themeReducer = (state = defaultTheme, action) => {
    switch (action.type) {
        case THEME_ACTION.SET_THEME:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export const themeSelectors = {
    getTheme: (state) => state,
};

const getThemStateSelector = createStructuredSelector<ThemeStateType, ThemeStateType>({
    theme: themeSelectors.getTheme,
});

//// ***** TYPES ****** ////
export interface ThemeStateProps {
    ThemeState: { theme: ThemeInterface };
    ThemeAction: typeof ThemeAction;
}

// HOC
export const connectWithTheme = () =>
    connect(
        (state: RootStateOrAny) => ({
            ThemeState: getThemStateSelector(state.theme),
        }),
        (dispatch) => ({
            ThemeAction: bindActionCreators(ThemeAction, dispatch),
        })
    );

// HOOK
export const useTheme = () => {
    const dispatch = useDispatch();
    const ThemeState: ThemeStateType = useSelector((state: RootStateOrAny) => {
        invariant(
            state.theme,
            'Could not find `state.theme` in redux store. ' +
                'Make sure that the theme reducer is connected to the main Redux store of your application. '
        );
        return getThemStateSelector(state.theme);
    });

    const ta = bindActionCreators(ThemeAction, dispatch);
    return {
        ThemeState,
        ThemeAction: ta,
    };
};
