import gql from 'graphql-tag';
import { fromEdges, NodeType, poolFragment } from '@poolware/api';
import { compose, mapProps } from '@ez/tools';
import { withAppNavigator } from '@poolware/react-app-navigator';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@poolware/components';
import { itemFragment } from './withItemQuery';

export const PoolQuery = gql`
    query QueryPoolAndPoolEquipmentDetails($poolId: ID!) {
        pool: node(id: $poolId) {
            id
            ...PoolFragment
            ... on Pool {
                items(first: 1000) {
                    #Devices installed in the pool , e.g. sanitiser, filter
                    edges {
                        node {
                            id
                            ...ItemFragment
                        }
                    }
                }
            }
        }
        viewer {
            poolProductDeclarations {
                edges {
                    node {
                        id
                        name
                        optional
                        priority
                        declaration {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
    ${poolFragment}
    ${itemFragment}
`;

export const withPoolQuery = (propMapper: (props: any) => string) =>
    compose(
        withAppNavigator(),
        graphql(PoolQuery, {
            options: (props: any) => ({
                fetchPolicy: 'cache-and-network',
                variables: { poolId: propMapper(props) },
            }),
        }),
        withApolloLoading({ show404ForPath: 'data.pool.id' }),
        mapProps((props) => {
            const pool = props?.data?.pool;
            const poolId = props.params.poolId;
            const customerId = props.params.customerId;
            const poolProductDeclarations = fromEdges(props?.data?.viewer?.poolProductDeclarations);

            return {
                ...props,
                poolId,
                customerId,
                pool,
                poolProductDeclarations,
            };
        })
    );

export interface WithPoolQueryProps {
    pool: NodeType.Pool;
    customerId: string;
    poolId: string;
    poolProductDeclarations: NodeType.PoolProductDeclaration[];
}
