import * as React from 'react';
import { NodeType } from '@poolware/api';
import { usePanelNavigationStack } from '../../PanelNavigationStack';
import { Icon } from 'semantic-ui-react';
import { MenuBar, ScrollableLayout } from '@poolware/components';
import { useCalendarActions } from '../../../redux';
import { useAppointmentViewCtx } from '../AppointmentViewContext';
import { ModuleColorNames, ModuleIconNames } from '../../../constants';
import { RelatedAppointmentsMode, RelatedAppointmentsTable } from './RelatedAppointmentsTable';

export interface PanelRelatedAppointmentsProps {
    appointmentItem: NodeType.AppointmentItem;
}

export const PanelRelatedAppointments: React.FC<PanelRelatedAppointmentsProps> = ({ appointmentItem }) => {
    const { CalendarAction } = useCalendarActions();
    const previewItemCtx = useAppointmentViewCtx();
    const panelStack = usePanelNavigationStack();

    const onRelatedAppointmentSelect = (item: NodeType.AppointmentItem) => {
        CalendarAction.setPreviewAppt(item.id);
        previewItemCtx.setAppointmentItemId(item.id);
    };

    const onCancel = () => {
        panelStack.popStackPanel();
    };

    return (
        <ScrollableLayout>
            <ScrollableLayout.MenuBar size={'tiny'}>
                <MenuBar.Section>
                    <MenuBar.Item onClick={onCancel} icon={'chevron left'} />
                    <MenuBar.HeaderItem>
                        <Icon color={ModuleColorNames.Appointment} name={ModuleIconNames.Appointment} />
                        Related Appointments
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
            </ScrollableLayout.MenuBar>

            <ScrollableLayout.BodyScroll>
                <div tw={'px-2 py-4 mb-4 space-y-4 text-sm'}>
                    <RelatedAppointmentsTable
                        appointmentItem={appointmentItem}
                        mode={RelatedAppointmentsMode.FuturePast}
                        onAppointmentSelect={onRelatedAppointmentSelect}
                    />
                </div>
            </ScrollableLayout.BodyScroll>
        </ScrollableLayout>
    );
};
