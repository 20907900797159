import * as React from 'react';
import { Table } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import './styles.scss';
import { Display, PageSkeletonLoader, ScrollX } from '@poolware/components';
import { StatsGroupByEnum } from '../Queries/QueryStats';
import * as URLBuilder from '../../../routes/url-builder';

const HeaderCell = ({ children, rotate, ...props }) => (
    <Table.HeaderCell className={rotate ? 'rotate-header' : undefined} {...props}>
        <div>
            <span>{children}</span>
        </div>
    </Table.HeaderCell>
);

export interface DataTableProps {
    data: NodeType.ActivitySample[];
    dateFormat?: string;
    loading: boolean;
    groupBy?: StatsGroupByEnum;
}

const columnReducers_none = (acc, d: NodeType.ActivitySample) => {
    acc['--'] = null;
    return acc;
};

const columnReducers_franchise = (acc, d: NodeType.ActivitySample) => {
    const f = d.franchise || { id: '--', name: '--' };
    acc[f.id] = f;
    return acc;
};

const columnReducers_staff = (acc, d: NodeType.ActivitySample) => {
    const staff = d.staff || { id: '--', user: null };
    acc[staff.id] = staff;
    return acc;
};

const sampleColumnMapper_none = (columnKeys) => (sample: NodeType.ActivitySample) => {
    const colIndex = 0;
    return {
        groupByNode: null,
        sample: sample,
        colIndex: colIndex,
    };
};
const sampleColumnMapper_franchise = (columnKeys) => (sample: NodeType.ActivitySample) => {
    const f = sample.franchise || { id: '--', name: '' };
    const colIndex = columnKeys.indexOf(f.id);
    return {
        groupByNode: f,
        sample: sample,
        colIndex: colIndex,
    };
};

const sampleColumnMapper_staff = (columnKeys) => (sample: NodeType.ActivitySample) => {
    const staff = sample.staff || { id: '--', user: null };
    const colIndex = columnKeys.indexOf(staff.id);
    return {
        groupByNode: staff,
        sample: sample,
        colIndex: colIndex,
    };
};

const statsReducerLUT = {
    [StatsGroupByEnum.None]: {
        columnReducer: columnReducers_none,
        sampleToColumnMapper: sampleColumnMapper_none,
    },
    [StatsGroupByEnum.Franchise]: {
        columnReducer: columnReducers_franchise,
        sampleToColumnMapper: sampleColumnMapper_franchise,
    },
    [StatsGroupByEnum.Staff]: {
        columnReducer: columnReducers_staff,
        sampleToColumnMapper: sampleColumnMapper_staff,
    },
};

export class StatsTable extends React.Component<DataTableProps> {
    prepareTable = (data: NodeType.ActivitySample[]) => {
        const { groupBy = StatsGroupByEnum.None } = this.props;
        const dateFormat = this.props.dateFormat || 'DD MMMM YYYY';

        let conf = statsReducerLUT[groupBy];
        const columns = data.reduce(conf.columnReducer, {});
        const columnKeys = Object.keys(columns);
        const samples = data.map(conf.sampleToColumnMapper(columnKeys));
        const rowDef = { acc: {}, offset: 0 };
        const rowDefs = samples.reduce(({ acc, offset }, s) => {
            const sampleDate = moment(s.sample.date).format(dateFormat);
            const sampleCount = s.sample.count;

            if (!sampleDate) return { acc, offset };
            if (!acc[sampleDate]) {
                acc[sampleDate] = {
                    count: sampleCount,
                    sortIndex: offset,
                    date: sampleDate,
                    samples: {},
                };
                offset = offset + 1;
            }
            acc[sampleDate].samples[s.colIndex] = s.sample;

            return {
                acc,
                offset,
            };
        }, rowDef);

        const rows = Object.keys(rowDefs.acc)
            .map((key) => rowDefs.acc[key])
            .sort((a, b) => (a.sortIndex > b.sortIndex ? 1 : -1));

        return {
            columns: columns,
            columnKeys: columnKeys,
            rows: rows,
        };
    };

    renderHeaderCell = (column, rotate: boolean, key) => {
        const groupBy = this.props.groupBy || StatsGroupByEnum.None;

        let HeaderTitle = null;
        switch (groupBy) {
            case StatsGroupByEnum.None:
                HeaderTitle = <div>Count</div>;
                break;
            case StatsGroupByEnum.Franchise:
                const link = URLBuilder.Franchise(column.id).view;
                HeaderTitle = (
                    <Link to={link}>
                        <div>{column?.name}</div>
                    </Link>
                );
                break;
            case StatsGroupByEnum.Staff:
                HeaderTitle = <Display.Entity value={column} />;
                break;
        }
        return (
            <HeaderCell rotate={rotate} key={key} textAlign={'right'}>
                {HeaderTitle}
            </HeaderCell>
        );
    };

    render() {
        const { data, loading } = this.props;
        const table = this.prepareTable(data);
        const columnCount = table.columnKeys.length;
        if (loading) {
            return <PageSkeletonLoader />;
        }
        return (
            <ScrollX className={'stats-table'}>
                <Table compact={'very'} size={'small'} unstackable celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell className={'pw-stats-date-cell'}>Date</Table.HeaderCell>
                            {table.columnKeys.map((col, i) =>
                                this.renderHeaderCell(table.columns[col], columnCount > 8, i)
                            )}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {table.rows.map((row) => {
                            return (
                                <Table.Row key={row.date}>
                                    <Table.Cell singleLine>
                                        <Display.Date value={row.date} format={this.props.dateFormat} />
                                    </Table.Cell>
                                    {table.columnKeys.map((col, index) => {
                                        const sample = row.samples[index];
                                        return (
                                            <Table.Cell key={index} textAlign={'right'} singleLine>
                                                <span style={{ fontSize: '0.9em' }}>{sample ? sample.count : ''}</span>
                                            </Table.Cell>
                                        );
                                    })}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell textAlign={'right'}>TOTAL</Table.HeaderCell>
                            {table.columnKeys.map((col, index) => {
                                const total = table.rows.reduce((acc, row) => {
                                    const sample = row.samples[index];
                                    const count = sample ? sample.count : 0;
                                    return acc + count;
                                }, 0);
                                return (
                                    <Table.HeaderCell key={index} textAlign={'right'}>
                                        {total}
                                    </Table.HeaderCell>
                                );
                            })}
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </ScrollX>
        );
    }
}
