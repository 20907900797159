import { combineReducers } from 'redux';
import * as HC from './reducer-heat-calc';

export const reducerHeatCalc = combineReducers({
    heater: HC.reducer,
});

export const deserializeHeatCalcs = (restoredState?: any) => {
    return {
        heater: restoredState.heater,
    };
};

export const serializeHeatCalcs = (state: any) => {
    return {
        heater: state.heater,
    };
};
