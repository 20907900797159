import { NodeType } from '@poolware/api';
import { GasHeaterModel, HeatPumpModel } from '../data';
import { CalcMode } from '../redux';

export enum PoolTypes {
    Pool = 'Pool',
    SPA = 'SPA',
}

export enum PoolPosition {
    Inground = 'In Ground',
    AboveGround = 'Above Ground',
}

export enum PoolLocation {
    Indoor = 'Indoor',
    Outdoor = 'Outdoor',
}

export enum PoolShape {
    Square = 'Square',
    Rectangle = 'Rectangle',
    Kidney = 'Kidney',
    Round = 'Round',
}

export enum PoolCover {
    Yes = 'Yes',
    No = 'No',
}

export enum WindProfile {
    Normal = 'Normal',
    High = 'High',
}

export interface MonthProfile {
    id: string;
    index: number;
    name: string;
    days: number;
    temp: number;
}

export interface HeatLossModel {
    covered: number;
    uncovered: number;
    month: string;
}

export interface MonthlyHeatPumpProfile {
    monthProfile: MonthProfile;
    heatUp: number;
    heatSurfaceLoss: number;
    heatTotal: number;
    operatingTemp: number;
    operatingHoursPerDay: number;
    isSelected: boolean;
}

export interface MonthlyHeatGasProfile {
    monthProfile: MonthProfile;
    heatUpMJ: number;
    heatUpTimeHr: number;
    minimumOutputMJ: number;
    heatRunningMJ: number;
    operatingTemp: number;
    operatingHoursPerDay: number;
    isSelected: boolean;
}

export enum CalcDocType {
    HeatPump = 'HeatPump',
    GasHeater = 'GasHeater',
    HeatPumpAutopool = 'HeatPumpAutopool',
}

export interface CostEstimationMonthly {
    monthId: string;
    costToHeatUp?: number;
    costRunningMonthly?: number;
    costRunningDaily?: number;
    loadCoef?: number;
    runningHours?: number;
}

export enum ResourcePriceUnit {
    DollarsPerKW = '$/kw',
    CentsPerMJ = 'Cents/MJ',
}

export interface CostEstimationModel<T> {
    numberOfUnits: number;
    minimumOutput: number;
    costYearly: number;
    recommendedModel?: T;
    costByMonth: CostEstimationMonthly[];
    operatingHours: number;
    // Gas cost or electricity cost
    resourcePrice: number;
    // $/kw or Cents per MJ
    resourcePriceUnit: ResourcePriceUnit;
}

export interface CostEstimation {
    gas?: CostEstimationModel<GasHeaterModel>;
    hpump?: CostEstimationModel<HeatPumpModel>;
}

export interface FormikHeatCalcValueType {
    version?: string;
    mode: CalcMode;
    availableCalcs: CalcDocType[];
    availableBrandings?: BrandingType[];
    docType?: CalcDocType;
    brandingType?: BrandingType;
    newCustomerMode?: boolean;
    customer: any;
    newCustomer: {
        firstName: string;
        lastName: string;
        contactName: string;
        companyName: string;
        email: string;
        mobile: string;
    };
    yearProfile: MonthProfile[];
    selectedMonths: string[];
    city: string;
    state: string;
    country: string;
    poolType: PoolTypes;
    poolPosition: PoolPosition;
    poolLocation: PoolLocation;
    poolCover: PoolCover;
    windProfile: WindProfile;
    poolShape: PoolShape;
    poolLength: number;
    poolWidth: number;
    poolWidth1: number;
    poolWidth2: number;
    poolDiameter: number;
    poolDepth: number;
    poolVolume: number;
    poolSurfaceAreaTop: number;
    poolTemp: number;
    operatingHours: number;
    operatingDailyHoursPerMonth?: number[];
    lowestAirTemp: number;
    gasCost: number;
    electCost: number;
    summary: any;
    //
    autoRecommendedModel?: { gas: GasHeaterModel; hpump: HeatPumpModel };
    userSelectedModel?: { gas: GasHeaterModel; hpump: HeatPumpModel };
    costEstimation?: CostEstimation;
    entity?: NodeType.Entity;
    heaterRecommendation?: NodeType.HeaterRecommendation;
}

export enum BrandingType {
    Swimart = 'swimart',
    Zane = 'zane',
    Autopool = 'autopool',
    Aquatight = 'aquatight',
}

export const brandTypeToString = (brandType: BrandingType): string => {
    switch (brandType) {
        case BrandingType.Swimart:
            return 'Swimart';
        case BrandingType.Zane:
            return 'Zane';
        case BrandingType.Autopool:
            return 'Autopool';
        case BrandingType.Aquatight:
            return 'Aquatight';
        default:
            return brandType;
    }
};

export interface HeatRecNodePayloadType {
    version?: string;
    docType?: CalcDocType;
    brandingType?: BrandingType;
    city: string;
    state: string;
    country: string;
    poolType: PoolTypes;
    poolPosition: PoolPosition;
    poolLocation: PoolLocation;
    poolCover: PoolCover;
    windProfile: WindProfile;
    poolShape: PoolShape;
    poolLength: number;
    poolWidth: number;
    poolWidth1: number;
    poolWidth2: number;
    poolDiameter: number;
    poolDepth: number;
    poolVolume: number;
    poolSurfaceAreaTop: number;
    poolTemp: number;
    operatingHours: number;
    operatingHoursPerMonth: number[];
    lowestAirTemp: number;
    gasCost: number;
    electCost: number;
    yearProfile: MonthProfile[];
    selectedMonths: string[];
    costEstimation: {
        numberOfUnits?: number;
        costYearly?: number;
        minimumOutput?: number;
    } & { [key: string]: any };
    model?: {
        id?: string;
        sku?: string;
        name?: string;
        capacity?: number;
        input?: number;
        cop?: number;
    };
}

const _DocTypeToText = {
    [CalcDocType.HeatPump]: 'Heat Pump',
    [CalcDocType.HeatPumpAutopool]: 'Heat Pump',
    [CalcDocType.GasHeater]: 'Gas Heater',
};

export const convertDocTypeToLabel = (type: CalcDocType): string => {
    return _DocTypeToText[type] || type;
};
