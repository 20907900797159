import { SemanticICONS } from 'semantic-ui-react';

export const getIconNameForMimeType = (mimeType: string): SemanticICONS => {
    if (!mimeType) return null;
    if (mimeType.startsWith('image/')) return 'image';
    if (mimeType === 'application/pdf') return 'file pdf';

    return 'file';
};

export function truncateString(str, num) {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...';
}
