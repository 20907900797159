import { bindActionCreators, combineReducers } from 'redux';
import { connect, useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ActionsUnion, createAction as _createAction, createReducer } from '@ez/tools';
import { NodeType, TraitValueType, createPageIndexReducerActions } from '@poolware/api';
import invariant from 'invariant';

const SCOPE = '@productCatalog';
const createAction = (type, payload?) => _createAction(type, payload, SCOPE);

export enum FilterType {
    NAME,
    SKU,
    CUSTOM_SKU,
    BRAND,
    PRODUCT_COMPANY,
    SUPPLIER,
    DECLARATION,
    TRAIT_VALUE,
    TRAIT_FIELD,
}

export type AnyNode = NodeType.Node & { [key: string]: any };
export type FilterItemPayloadStringType = string;
export type FilterItemPayloadNodeType = AnyNode;
export type FilterItemPayloadTraitValueSelectionType = {
    type: TraitValueType;
    traitFieldId: string;
    selectionChoice: NodeType.ProductDeclarationSelectionChoice;
};

export type FilterItemPayloadType =
    | FilterItemPayloadStringType
    | FilterItemPayloadNodeType
    | FilterItemPayloadTraitValueSelectionType;

export type FilterItem<T extends FilterItemPayloadType = any> = {
    type: FilterType;
    // unique id for differentiation of filters with the same type.
    filterId: string | null;
    parentFilterId?: string;
    value: T;
};

export type ProductCatalogStateType = {
    filters: {
        filterItems: FilterItem[];
        hasActiveFilters: boolean;
    };
    page: { index: number };
};

export const initialState: ProductCatalogStateType = {
    filters: {
        filterItems: [],
        hasActiveFilters: false,
    },
    page: { index: 0 },
};

// Actions and action creators

export enum PRODUCT_CATALOG_ACTION {
    ADD_FILTER_ITEM = '@productCatalog/ADD_FILTER_ITEM',
    REPLACE_FILTER_ITEM = '@productCatalog/REPLACE_FILTER_ITEM',
    REMOVE_FILTER_ITEM = '@productCatalog/REMOVE_FILTER_ITEM',
    REMOVE_ALL_FILTER_ITEMS = '@productCatalog/REMOVE_ALL_FILTER_ITEMS',

    PAGE_INDEX = '@productCatalog/PAGE_INDEX',
}

const setPageIndex = (index: number) => createAction(PRODUCT_CATALOG_ACTION.PAGE_INDEX, { index });

export const addFilterItem = (filterItem: FilterItem) =>
    createAction(PRODUCT_CATALOG_ACTION.ADD_FILTER_ITEM, { filterItem });

export const replaceFilterItem = (filterItem: FilterItem) =>
    createAction(PRODUCT_CATALOG_ACTION.REPLACE_FILTER_ITEM, { filterItem });

export const removeFilterItem = (filterItem: FilterItem) =>
    createAction(PRODUCT_CATALOG_ACTION.REMOVE_FILTER_ITEM, { filterItem });

export const removeAllFilterItems = () => createAction(PRODUCT_CATALOG_ACTION.REMOVE_ALL_FILTER_ITEMS);

const ProductCatalogActions = {
    addFilterItem,
    replaceFilterItem,
    removeFilterItem,
    removeAllFilterItems,
    setPageIndex,
};

export type ProductCatalogActions = ActionsUnion<typeof ProductCatalogActions>;

const hasActiveFilters = (filters: ProductCatalogStateType['filters']) => {
    return filters.filterItems.length > 0;
};

const StateMutator = {
    removeFilterItem: (state: ProductCatalogStateType['filters'], filterItem: FilterItem) => {
        if (filterItem.type === FilterType.DECLARATION) {
            // Reset all TRAIT_VALUE filters if any of DECLARATION filters is removed
            StateMutator.removeFilterItem(state, { type: FilterType.TRAIT_VALUE, filterId: null, value: null });
            StateMutator.removeFilterItem(state, { type: FilterType.TRAIT_FIELD, filterId: null, value: null });
        }
        if (!filterItem.filterId) {
            // if filterItem.filterId is null, remove all filter items of its type `type`
            state.filterItems = state.filterItems.filter((fi) => fi.type !== filterItem.type);
        } else {
            // remove only filter items that have the same `type` and `filterId` values
            state.filterItems = state.filterItems.filter((fi) => {
                if (fi.type === filterItem.type && fi.filterId === filterItem.filterId) {
                    return false;
                } else {
                    return true;
                }
            });
        }
    },
    replaceFilterItem: (state: ProductCatalogStateType['filters'], filterItem: FilterItem) => {
        if (filterItem.type === FilterType.DECLARATION) {
            // Reset all TRAIT_VALUE filters if any of DECLARATION filters changes
            StateMutator.removeFilterItem(state, { type: FilterType.TRAIT_VALUE, filterId: null, value: null });
            StateMutator.removeFilterItem(state, { type: FilterType.TRAIT_FIELD, filterId: null, value: null });
        }
        state.filterItems = state.filterItems.filter((fi) => fi.filterId !== filterItem.filterId);
        state.filterItems.push(filterItem);
    },
    addFilterItem: (state: ProductCatalogStateType['filters'], filterItem: FilterItem) => {
        if (!state.filterItems.find((fi) => fi.filterId === filterItem.filterId)) {
            // Push filter item it is not in the store yet.
            state.filterItems.push(filterItem);
        } else {
            // if filter item with `filterId` already exist, replace it.
            StateMutator.replaceFilterItem(state, filterItem);
        }
    },
};

const filtersReducerFn = (
    state: ProductCatalogStateType['filters'],
    action: ProductCatalogActions
): ProductCatalogStateType['filters'] => {
    if (!state.filterItems) {
        // precaution
        state.filterItems = [];
    }
    switch (action.type) {
        case PRODUCT_CATALOG_ACTION.ADD_FILTER_ITEM: {
            StateMutator.addFilterItem(state, action.payload.filterItem);
            break;
        }
        case PRODUCT_CATALOG_ACTION.REPLACE_FILTER_ITEM: {
            StateMutator.replaceFilterItem(state, action.payload.filterItem);
            break;
        }

        case PRODUCT_CATALOG_ACTION.REMOVE_FILTER_ITEM: {
            StateMutator.removeFilterItem(state, action.payload.filterItem);
            break;
        }
        case PRODUCT_CATALOG_ACTION.REMOVE_ALL_FILTER_ITEMS: {
            state.filterItems = [];
            break;
        }

        default:
            return state;
    }

    state.hasActiveFilters = hasActiveFilters(state);
};

export const filtersReducer = createReducer(initialState.filters, filtersReducerFn, SCOPE);

const PageIndexReducerActions = createPageIndexReducerActions({
    scope: SCOPE,
    resetIndexActionTypes: [
        PRODUCT_CATALOG_ACTION.REPLACE_FILTER_ITEM,
        PRODUCT_CATALOG_ACTION.REMOVE_FILTER_ITEM,
        PRODUCT_CATALOG_ACTION.ADD_FILTER_ITEM,
        PRODUCT_CATALOG_ACTION.REMOVE_ALL_FILTER_ITEMS,
    ],
});

export const reducer = combineReducers({
    filters: filtersReducer,
    page: PageIndexReducerActions.reducer,
});

// Memoization
const sel_getFilterValueByType = (state: ProductCatalogStateType) => (
    filterType: FilterType
): FilterItemPayloadType => {
    const filterItem = state.filters.filterItems.find((fi) => fi.type === filterType);
    return filterItem ? filterItem.value : null;
};
const sel_getFilterItemsByType = (state: ProductCatalogStateType) => (filterType: FilterType): FilterItem[] => {
    return state.filters.filterItems.filter((fi) => fi.type === filterType);
};

export interface StateBind extends ProductCatalogStateType {
    getFilterValueByType: ReturnType<typeof sel_getFilterValueByType>;
    getFilterItemsByType: ReturnType<typeof sel_getFilterItemsByType>;
}

const memoizedSelector = createStructuredSelector<ProductCatalogStateType, StateBind>({
    filters: (state) => state.filters,
    page: (state) => state.page,
    getFilterValueByType: sel_getFilterValueByType,
    getFilterItemsByType: sel_getFilterItemsByType,
});

const allActions = { ...ProductCatalogActions, ...PageIndexReducerActions.actions };

export const withProductCatalogActions = () =>
    connect(
        (state: RootStateOrAny) => {
            invariant(
                state.catalog,
                'Could not find `state.catalog`. ' +
                    "Make sure that the product catalog's reducer is connected to the main Redux store of you application. " +
                    "Redux field must equal 'catalog' "
            );
            return {
                ProductCatalogState: memoizedSelector(state.catalog),
            };
        },
        (dispatch) => ({
            ProductCatalogAction: bindActionCreators(allActions, dispatch),
        })
    );

export const useProductCatalogActions = () => {
    const dispatch = useDispatch();
    const state: StateBind = useSelector((state: RootStateOrAny) => {
        invariant(
            state.catalog,
            "Could not find 'state.catalog'. " +
                "Make sure that the product catalog's reducer is connected to the main Redux store of your application."
        );
        return memoizedSelector(state.catalog);
    });

    const pa = bindActionCreators(allActions, dispatch);
    return {
        ProductCatalogState: state,
        ProductCatalogAction: pa,
    };
};

export interface ProductCatalogActionProps {
    ProductCatalogAction: typeof ProductCatalogActions;
    ProductCatalogState: StateBind;
}
