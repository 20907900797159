import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute } from '@poolware/react-app-navigator';
import { EmailLogViewer } from './ViewReport/EmailLogViewer';
import { default as ViewReport } from './ViewReport';
import { default as New } from './New';
import { default as Edit } from './Edit';

export const Router = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path="/new" component={New} />
            <ModuleRoute path="/:testId/edit" component={Edit} />
            <ModuleRoute path="/:testId/email-logs" component={EmailLogViewer} />
            <ModuleRoute path="/:testId" component={ViewReport} />
        </ModuleRootSwitch>
    );
};
