import * as React from 'react';
import { CSSProperties } from 'react';
import { Divider, Header, Icon, List, Message, Tab, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as URLBuilder from '../../routes/url-builder';
import { fromEdges, NodeType } from '@poolware/api';
import { Display } from '@poolware/components';
import { SearchValuesType } from './SearchForm';
import Highlighter from './Highlighter';
import { usePersistedString } from '@ez/tools';
import { useAppLayoutMode, useViewer } from '@poolware/app-shell';
import 'twin.macro';

export const DetailsItem: React.FC<{ label; content? }> = ({ label, content, children }) => {
    const labelStyle: CSSProperties = {
        display: 'inline-block',
        width: '100px',
        marginRight: '20px',
        textAlign: 'left',
    };
    return (
        <List.Item>
            <span style={labelStyle}>
                <b>{label}</b>
            </span>{' '}
            <span tw={'whitespace-nowrap'}>
                {content}
                {children}
            </span>
        </List.Item>
    );
};

export const DetailsItemAddress = ({ label, address, ...rest }) => (
    <DetailsItem label={label} {...rest}>
        <Display.Address value={address} />
    </DetailsItem>
);

export const DetailsItemContact = ({ contact }) => {
    return (
        <DetailsItem label={contact?.type?.name}>
            <Display.ContactItem value={contact} noLink={true} />
        </DetailsItem>
    );
};

const SpanPool = (icon, text, pool) => {
    return (
        <>
            {icon && <Icon name="life ring" />}
            <span>{text ? text : pool ? pool.name : 'N/A'}</span>
        </>
    );
};

export const LinkPool: React.FC<{ customer; pool; icon?; text? }> = ({ customer, pool, icon, text }) => {
    try {
        const poolLink = customer
            ? URLBuilder.Customer(customer.id).Pool(pool.id).view
            : URLBuilder.Customer('undefined').Pool(pool.id).view;
        return <Link to={poolLink}>{SpanPool(icon, text, pool)}</Link>;
    } catch (e) {
        return SpanPool(icon, text, pool);
    }
};

const SpanSite = (icon, text, site) => {
    return (
        <>
            {icon && <Icon name="life ring" />}
            <span>{text ? text : site ? site.name : 'N/A'}</span>
        </>
    );
};

const LinkSite: React.FC<{ customer: NodeType.Customer; site: NodeType.Site; icon?; text? }> = ({
    customer,
    site,
    icon,
    text,
}) => {
    try {
        if (customer && customer.id) {
            const link = URLBuilder.Customer(customer.id).view;
            return <Link to={link}>{SpanPool(icon, text, site)}</Link>;
        }
    } catch (e) {}
    return SpanSite(icon, text, site);
};

export const LinkCustomer: React.FC<{ customer; icon?; text? }> = ({ customer, icon, text }) => {
    const link = URLBuilder.Customer(customer.id).view;
    return (
        <Link to={link}>
            {icon && <Icon name="user" />}
            {text ? <span>{text}</span> : <Display.Entity value={customer} />}
        </Link>
    );
};

const CustomersTable: React.FC<{
    customers: NodeType.Customer[];
    searchValues: SearchValuesType;
    opt?: {
        showNextAppointment?: boolean;
    };
}> = ({ customers, searchValues, opt }) => {
    const { gteTablet } = useAppLayoutMode();
    return (
        <Table color="grey" celled size="small">
            {gteTablet && (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Customer CRN </Table.HeaderCell>
                        <Table.HeaderCell>Customer </Table.HeaderCell>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.HeaderCell>Contacts</Table.HeaderCell>
                        {opt?.showNextAppointment && <Table.HeaderCell>Next Appointment</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )}
            <Table.Body>
                {customers.map((customer) => {
                    const nextAppDate = customer.nextAppointment?.startDate;
                    const primaryAddress = customer.primaryAddress;
                    const billAddress = customer.billAddress;
                    const contacts = fromEdges(customer?.root?.contacts);
                    const isSameAddr = primaryAddress?.id === billAddress?.id || !billAddress || !primaryAddress;

                    return (
                        <Table.Row key={customer.id}>
                            <Table.Cell textAlign="left" verticalAlign={'top'} singleLine={true}>
                                <LinkCustomer
                                    customer={customer}
                                    icon
                                    text={<Highlighter inputText={customer.crn} token={searchValues.crn} />}
                                />
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'} width={3}>
                                <LinkCustomer customer={customer} />
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'} width={5}>
                                <List>
                                    {isSameAddr ? (
                                        <Display.Address value={primaryAddress} />
                                    ) : (
                                        <>
                                            {primaryAddress && (
                                                <List.Item>
                                                    <Display.Address value={primaryAddress} /> -{' '}
                                                    <b>
                                                        <i>(Prim)</i>
                                                    </b>
                                                </List.Item>
                                            )}
                                            {billAddress && (
                                                <List.Item>
                                                    <Display.Address value={billAddress} /> -{' '}
                                                    <b>
                                                        <i>(Bill)</i>
                                                    </b>
                                                </List.Item>
                                            )}
                                        </>
                                    )}
                                </List>
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'} width={5}>
                                <List>
                                    {contacts.length > 0 &&
                                        contacts.map((contact: any) => {
                                            return <DetailsItemContact key={contact.id} contact={contact} />;
                                        })}
                                </List>
                            </Table.Cell>
                            {opt?.showNextAppointment && gteTablet && (
                                <Table.Cell verticalAlign={'top'} width={2}>
                                    {nextAppDate && <Display.Date value={nextAppDate} />}
                                </Table.Cell>
                            )}
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

export const PoolsTable: React.FC<{ pools: NodeType.Pool[]; searchValues?: SearchValuesType }> = ({
    pools,
    searchValues,
}) => {
    const { gteTablet } = useAppLayoutMode();

    return (
        <Table color="grey" celled size="small">
            {gteTablet && (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Pool Number</Table.HeaderCell>
                        <Table.HeaderCell>Pool Name</Table.HeaderCell>
                        <Table.HeaderCell>Pool Address</Table.HeaderCell>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )}
            <Table.Body>
                {pools.map((pool) => {
                    const customer = pool?.entity?.user?.customer;
                    // if (!customer) return null;

                    return (
                        <Table.Row key={pool.id}>
                            <Table.Cell textAlign="left" width="3">
                                <LinkPool
                                    pool={pool}
                                    customer={customer}
                                    icon
                                    text={
                                        <Highlighter inputText={pool.bottleNumber} token={searchValues?.bottleNumber} />
                                    }
                                />
                            </Table.Cell>
                            <Table.Cell>{pool.name}</Table.Cell>
                            <Table.Cell>
                                <List>{pool.address && <Display.Address value={pool.address} />}</List>
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'}>
                                {pool.entity && <Display.Entity value={pool.entity} />}
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

const SitesTable: React.FC<{ sites: NodeType.Site[]; searchValues: SearchValuesType }> = ({ sites, searchValues }) => {
    const { gteTablet } = useAppLayoutMode();

    return (
        <Table color="grey" celled size="small">
            {gteTablet && (
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Site Name</Table.HeaderCell>
                        <Table.HeaderCell>Site Address</Table.HeaderCell>
                        <Table.HeaderCell>Site Pools</Table.HeaderCell>
                        <Table.HeaderCell>Customer</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            )}
            <Table.Body>
                {sites.map((site) => {
                    const address = site?.address;
                    const customer = site?.entity?.user?.customer;
                    const pools = fromEdges(site?.pools);
                    if (!customer) {
                        return null;
                    }

                    return (
                        <Table.Row key={site.id}>
                            <Table.Cell textAlign="left" width="3" verticalAlign={'top'}>
                                <LinkSite site={site} customer={customer} icon text={site.name} />
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'}>
                                {address && <Display.Address value={address} />}
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'}>
                                {pools.length > 0 && customer && (
                                    <div>
                                        {pools.map((pool: NodeType.Pool, index) => {
                                            return (
                                                <span key={pool.id}>
                                                    <LinkPool icon pool={pool} customer={customer} />
                                                    {index < pools.length - 1 ? <span>, </span> : null}
                                                </span>
                                            );
                                        })}
                                    </div>
                                )}
                            </Table.Cell>
                            <Table.Cell verticalAlign={'top'}>
                                {customer && <LinkCustomer customer={customer} icon />}
                            </Table.Cell>
                        </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
};

interface CustomerListProps {
    searchValues: SearchValuesType;
    searchResult: {
        customers: NodeType.Customer[];
        pools: NodeType.Pool[];
        sites: NodeType.Site[];
    };
}

type TabKeyType = 'customers' | 'sites' | 'pools';

const TabKeys: { [key: string]: TabKeyType } = {
    CUSTOMERS: 'customers',
    POOLS: 'pools',
    SITES: 'sites',
};

export const SearchResult: React.FC<CustomerListProps> = (props) => {
    const { modulesAccess } = useViewer();

    const [preferredTab, setPreferredTab] = usePersistedString<TabKeyType>('pw.search', 'customers');
    let { customers, pools, sites } = props.searchResult;
    customers = fromEdges(customers);
    pools = fromEdges(pools).filter((pool) => {
        return !!pool?.entity?.user?.customer;
    });
    sites = fromEdges(sites);

    const onTabChange = (e, data) => {
        const { activeIndex = 0 } = data;
        if (!data.panes) {
            return;
        }
        const pane = data.panes[activeIndex];
        setPreferredTab(pane.key);
    };

    if (customers.length === 0 && pools.length === 0 && sites.length === 0) {
        return <Message color="olive" header="No results found" />;
    }

    const panes = [];
    if (modulesAccess.Customers?.enabled && customers.length) {
        const pane = {
            menuItem: `Customers (${customers.length})`,
            key: 'customers',
            render: () => (
                <Tab.Pane attached={false}>
                    {customers.length > 0 && (
                        <div>
                            <Header size="medium">Customers</Header>
                            <CustomersTable
                                customers={customers}
                                searchValues={props.searchValues}
                                opt={{
                                    showNextAppointment: modulesAccess.FieldServices?.appointments,
                                }}
                            />
                            <Divider hidden />
                        </div>
                    )}
                </Tab.Pane>
            ),
        };
        panes.push(pane);
    }

    if (modulesAccess.Pools.enabled && sites.length > 0) {
        const pane = {
            menuItem: `Sites (${sites.length})`,
            active: true,
            key: TabKeys.SITES,
            render: () => (
                <Tab.Pane attached={false}>
                    {sites.length > 0 && (
                        <div>
                            <Header size="medium">Sites</Header>
                            <SitesTable sites={sites} searchValues={props.searchValues} />
                            <Divider hidden />
                        </div>
                    )}
                </Tab.Pane>
            ),
        };
        panes.push(pane);
    }

    if (modulesAccess.Pools.enabled && pools.length > 0) {
        const pane = {
            menuItem: `Pools (${pools.length})`,
            key: TabKeys.POOLS,
            render: () => (
                <Tab.Pane attached={false}>
                    {pools.length > 0 && (
                        <div>
                            <Header size="medium">Pools</Header>
                            <PoolsTable pools={pools} searchValues={props.searchValues} />
                            <Divider hidden />
                        </div>
                    )}
                </Tab.Pane>
            ),
        };
        panes.push(pane);
    }

    let activeIndex = panes.findIndex((p) => p.key === preferredTab);
    if (activeIndex === -1) {
        activeIndex = 0;
    }

    if (panes.length === 0) {
        return <Message color="olive" header="No results found" />;
    }

    return <Tab onTabChange={onTabChange} activeIndex={activeIndex} menu={{ pointing: true }} panes={panes} />;
};
