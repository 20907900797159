import * as React from 'react';
import { useCalendarActions } from '../../../redux';
import { useQueryServiceJobGroupConnection } from '../../../queries/use-query-service-job-group-connection';
import { ActionBarFilterByServiceJobGroup } from '../../../components/ActionBarFilters/ActionBarFilterByServiceJobGroup';
import { mapServiceJobGroupsToOptions } from '../../../components/ActionBarFilters/ActionBarServiceGroups';

export const FilterByServiceJobGroup: React.FC = () => {
    const { CalendarState, CalendarAction } = useCalendarActions();
    const { connectionData } = useQueryServiceJobGroupConnection({
        franchiseId: CalendarState.filters?.franchise?.id,
        fetchPolicy: 'cache-first',
    });
    if (!connectionData.length) {
        return null;
    }

    const options = mapServiceJobGroupsToOptions(connectionData);

    return (
        <ActionBarFilterByServiceJobGroup
            persistStorageKey={'sj.cal.filter.service-group.toggle'}
            setServiceJobGroup={CalendarAction.setServiceJobGroup}
            setServiceJobTemplate={CalendarAction.setServiceJobTemplate}
            options={options}
            value={CalendarState.filters?.serviceGroupId || CalendarState.filters?.serviceJobTemplateId}
        />
    );
};
