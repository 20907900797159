import * as React from 'react';
import { useContext } from 'react';
import { useQueryGasHeaterCatalog, useQueryHeatPumpsCatalog } from './use-equipment-catalog';
import { BrandingType, FormikHeatCalcValueType } from './types';
import { useFormikContext } from 'formik';

export type CatalogCtxType = {
    gasHeaters: ReturnType<typeof useQueryGasHeaterCatalog>;
    heatPumps: ReturnType<typeof useQueryHeatPumpsCatalog>;
    heatPumpsAquatight: ReturnType<typeof useQueryHeatPumpsCatalog>;
};
const calcCatalogContext = React.createContext<CatalogCtxType>(undefined);

export const CatalogProvider: React.FC = (props) => {
    const gasHeaters = useQueryGasHeaterCatalog();
    const heatPumps = useQueryHeatPumpsCatalog();
    const heatPumpsAquatight = useQueryHeatPumpsCatalog(BrandingType.Aquatight);

    const context: CatalogCtxType = {
        gasHeaters,
        heatPumps,
        heatPumpsAquatight,
    };
    return <calcCatalogContext.Provider value={context} {...props} />;
};

export const useCatalog = () => {
    const { values } = useFormikContext<FormikHeatCalcValueType>();
    const ctx = useContext(calcCatalogContext);
    const heatPumps = values.brandingType === BrandingType.Aquatight ? ctx.heatPumpsAquatight : ctx.heatPumps;

    return {
        gasHeaters: ctx.gasHeaters,
        heatPumps: heatPumps,
    };
};
