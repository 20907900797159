import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import * as dates from '../../utils/dates';
import Selection, { getBoundsForNode, getEventNodeFromPoint } from '../../Selection';
import { TimeGridEvent } from '../../TimeGridEvent';
import { eventTimes, pointInColumn } from './common';
import NoopWrapper from '../../NoopWrapper';
import { DndContext } from './DndContext';
import { ACTION_NOTIFICATION, CalEvent } from '../../types';
import { DND_ACTION, RESIZE_DIRECTION } from './types';
import { commonProps } from '../../CalendarPropsTypes';
import { CalendarContextValueType, useRbcContext } from '../../CalendarContext';
import { makeDebugger } from '@ez/tools';

const debug = makeDebugger('rbc:dnd');

const propTypes = {
    ...commonProps,
    slotMetrics: PropTypes.objectOf(PropTypes.any).isRequired,
    resource: PropTypes.any,
};

interface State {
    event?;
    top?;
    height?;
    isBeingDragged?: boolean;
}

interface EventContainerWrapperProps extends InferProps<typeof propTypes> {
    devKey?: string;
    rbcContext?: CalendarContextValueType;
}

class EventContainerWrapper extends React.Component<EventContainerWrapperProps, State> {
    static propTypes = propTypes;
    static contextType = DndContext;
    declare context: React.ContextType<typeof DndContext>;

    static displayName = `DND(EventContainerWrapper)`;

    private eventOffsetTop: number;
    private draggingOver: boolean;
    private readonly ref: React.RefObject<any>;
    private _selector: Selection;

    constructor(...args) {
        // @ts-ignore
        super(...args);
        this.state = {
            isBeingDragged: false,
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        this._selectable();
    }

    componentWillUnmount() {
        this._teardownSelectable();
    }

    reset(cb?) {
        this.setState({ event: null, top: null, height: null, isBeingDragged: false }, cb);
    }

    update(event: CalEvent, { startDate, endDate, top, height }) {
        const { event: lastEvent } = this.state;
        if (lastEvent && startDate === lastEvent.start && endDate === lastEvent.end) {
            return;
        }

        this.setState({
            top,
            height,
            event: { ...event, start: startDate, end: endDate },
        });
    }

    handleMove = (point, bounds) => {
        if (!pointInColumn(bounds, point)) {
            return this.reset();
        }

        const { event } = this.context.draggable.dragAndDropAction;
        const { slotMetrics } = this.props;
        const { accessors } = this.props.rbcContext;

        let newSlot = slotMetrics.closestSlotFromPoint({ y: point.y - this.eventOffsetTop, x: point.x }, bounds);

        const { duration } = eventTimes(event, accessors);
        let newEnd = dates.add(newSlot, duration, 'milliseconds');
        this.update(event, slotMetrics.getRange(newSlot, newEnd, false, true));
    };

    handleResize(point, bounds) {
        const { slotMetrics } = this.props;
        const { accessors } = this.props.rbcContext;
        const { event, direction } = this.context.draggable.dragAndDropAction;

        if (direction === RESIZE_DIRECTION.UP) {
            const newTime = slotMetrics.closestSlotFromPoint(point, bounds, 0);
            // let { start, end } = eventTimes(event, accessors);
            let end = accessors.end(event);
            let start = dates.min(newTime, slotMetrics.closestSlotFromDate(end, -1));
            this.update(event, slotMetrics.getRange(start, end));
        } else if (direction === RESIZE_DIRECTION.DOWN) {
            const newTime = slotMetrics.closestSlotFromPoint(point, bounds, +1);
            let { start, end } = eventTimes(event, accessors);
            end = dates.max(newTime, slotMetrics.closestSlotFromDate(start));
            this.update(event, slotMetrics.getRange(start, end));
        } else {
            const newTime = slotMetrics.closestSlotFromPoint(point, bounds);
            let { start, end } = eventTimes(event, accessors);
            this.update(event, slotMetrics.getRange(start, end));
        }
    }

    _selectable = () => {
        let wrapper = this.ref.current;
        let node = wrapper.children[0];
        const devKey = this.props.devKey || '';

        this._selector = new Selection(() => wrapper.closest('.rbc-time-view'), {
            useDragOverNode: () => wrapper,
            debugKey: 'EventContainerWrapper:' + devKey,
        });
        let selector = this._selector;

        selector.on(ACTION_NOTIFICATION.beforeSelect, (point) => {
            const { dragAndDropAction } = this.context.draggable;

            if (!dragAndDropAction.action) {
                return false;
            }

            if (dragAndDropAction.action === DND_ACTION.RESIZE) {
                return pointInColumn(getBoundsForNode(node), point);
            }

            const eventNode = getEventNodeFromPoint(node, point);
            if (!eventNode) {
                return false;
            }

            // eventOffsetTop is distance from the top of the event to the initial
            // mouseDown position. We need this later to compute the new top of the
            // event during move operations, since the final location is really a
            // delta from this point. note: if we want to DRY this with WeekWrapper,
            // probably better just to capture the mouseDown point here and do the
            // placement computation in handleMove()...
            this.eventOffsetTop = point.y - getBoundsForNode(eventNode).top - 10;
        });

        selector.on(ACTION_NOTIFICATION.selecting, (point) => {
            const bounds = getBoundsForNode(node);
            const { dragAndDropAction } = this.context.draggable;

            if (dragAndDropAction.action === DND_ACTION.MOVE) {
                this.handleMove(point, bounds);
            } else if (dragAndDropAction.action === DND_ACTION.RESIZE) {
                this.handleResize(point, bounds);
            }
        });

        selector.on(ACTION_NOTIFICATION.selectStart, () => {
            this.setState({ isBeingDragged: true }, () => {
                this.context.draggable.onStart();
            });
        });

        selector.on(ACTION_NOTIFICATION.selected, (point) => {
            const bounds = getBoundsForNode(node);
            const { action, event } = this.context.draggable.dragAndDropAction;

            // console.log('EventContainerWrapper: on selected', {
            //     dragAndDropAction: this.context.draggable.dragAndDropAction,
            //     ...this.state,
            // });

            if (!this.state.event) {
                if (this.state.isBeingDragged && pointInColumn(bounds, point)) {
                    this.reset(() => {
                        this.context.draggable.onEnd();
                    });
                }
                return;
            }

            if (!pointInColumn(bounds, point) && action !== DND_ACTION.RESIZE) {
                // NOOP
                return;
            }
            this.handleInteractionEnd();
        });

        const getOnDragBoundsAndPoint = (point) => {
            const bounds = getBoundsForNode(node);
            const isInColumn = pointInColumn(bounds, point);
            if (!isInColumn) {
                return null;
            }
            return { bounds, point };
        };

        selector.on(ACTION_NOTIFICATION.dragEnterFromOutside, (point, e) => {
            const dragItem = this.context.draggable.dragFromOutsideItem?.();
            if (!dragItem) {
                debug(ACTION_NOTIFICATION.dragEnterFromOutside, 'Drag item is null');
            }

            const bp = getOnDragBoundsAndPoint(point);
            if (!bp || !dragItem) {
                this.reset();
                return;
            }
            const { slotMetrics } = this.props;

            let newStart = slotMetrics.closestSlotFromPoint(bp.point, bp.bounds);
            let newEnd = slotMetrics.nextSlot(newStart);
            const { event } = this.context.draggable.dragAndDropAction;
            const { top, height } = slotMetrics.getRange(newStart, newEnd, false, true);

            const patch = { startDate: newStart, endDate: newEnd, top, height };
            debug(ACTION_NOTIFICATION.dragEnterFromOutside, devKey, patch);
            this.update(event, patch);
            // this.setState({
            //     top: top,
            //     height,
            //     event: event,
            // });
        });

        selector.on(ACTION_NOTIFICATION.dragOverFromOutside, (point) => {
            const dragItem = this.context.draggable.dragFromOutsideItem?.();
            const bp = getOnDragBoundsAndPoint(point);
            if (!bp || !dragItem) {
                debug('reset', point, this.props.devKey);
                this.reset();
                return;
            }

            debug(ACTION_NOTIFICATION.dragOverFromOutside, point, this.props.devKey);

            const { slotMetrics } = this.props;

            let newStart = slotMetrics.closestSlotFromPoint(bp.point, bp.bounds);
            let newEnd = slotMetrics.nextSlot(newStart);
            const { event } = this.context.draggable.dragAndDropAction;
            const { top, height } = slotMetrics.getRange(newStart, newEnd, false, true);
            this.setState({
                top: top,
                height,
                event: event,
            });
        });

        selector.on(ACTION_NOTIFICATION.dropFromOutside, (point) => {
            const dragItem = this.context.draggable.dragFromOutsideItem?.();
            const bp = getOnDragBoundsAndPoint(point);
            if (!bp || !dragItem) {
                return;
            }
            debug(ACTION_NOTIFICATION.dropFromOutside);
            const { slotMetrics, resource } = this.props;

            let start = slotMetrics.closestSlotFromPoint(bp.point, bp.bounds);

            this.context.draggable.onDropFromOutside({
                start,
                end: slotMetrics.nextSlot(start),
                isAllDay: false,
                resourceId: resource,
            });
            this.reset();
        });

        selector.on(ACTION_NOTIFICATION.dragLeaveFromOutside, (e) => {
            const dragItem = this.context.draggable.dragFromOutsideItem?.();
            if (!dragItem) {
                return null;
            }
            debug(ACTION_NOTIFICATION.dragLeaveFromOutside, devKey);
            this.reset();
        });

        selector.on(ACTION_NOTIFICATION.click, () => {
            if (this.state.isBeingDragged) {
                this.reset();
            }
            this.context.draggable.onEnd(null);
        });

        selector.on(ACTION_NOTIFICATION.reset, () => {
            this.reset();
            this.context.draggable.onEnd(null);
        });
    };

    handleInteractionEnd = () => {
        const { resource } = this.props;
        const { event } = this.state;

        this.reset();

        this.context.draggable.onEnd({
            event: event,
            start: event.start,
            end: event.end,
            resourceId: resource,
        });
    };

    _teardownSelectable = () => {
        if (!this._selector) return;
        this._selector.teardown();
        this._selector = null;
    };

    renderContent() {
        const { children, slotMetrics } = this.props;
        const { localizer, components } = this.props.rbcContext;

        let { event, top, height } = this.state;

        if (!event) return children;

        // @ts-ignore
        const events = children.props.children;
        const { start, end } = event;

        let label;
        let format = 'eventTimeRangeFormat';

        const startsBeforeDay = slotMetrics.startsBeforeDay(start);
        const startsAfterDay = slotMetrics.startsAfterDay(end);

        if (startsBeforeDay) format = 'eventTimeRangeEndFormat';
        else if (startsAfterDay) format = 'eventTimeRangeStartFormat';

        if (startsBeforeDay && startsAfterDay) label = localizer.messages.allDay;
        else label = localizer.format({ start, end }, format);

        // @ts-ignore
        return React.cloneElement(children, {
            children: (
                <>
                    {events}
                    {event && (
                        <TimeGridEvent
                            event={event}
                            label={label}
                            className="rbc-addons-dnd-drag-preview"
                            style={{ top, height, width: 100, pointerEvents: 'none' }}
                            componentEvent={components.event}
                            componentEventWrapper={NoopWrapper}
                            // components={{
                            //     ...components,
                            //     eventWrapper: NoopWrapper,
                            // }}
                            continuesEarlier={startsBeforeDay}
                            continuesLater={startsAfterDay}
                        />
                    )}
                </>
            ),
        });
    }

    render() {
        return <div ref={this.ref}>{this.renderContent()}</div>;
    }
}

export default (props) => {
    const context = useRbcContext();
    return <EventContainerWrapper rbcContext={context} {...props} />;
};
