import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';
import clsx from 'clsx';

import * as dates from './utils/dates';
import chunk from 'lodash/chunk';

import { CalNavigate } from './types';
import { notify } from './utils/helpers';
import * as animationFrame from 'dom-helpers/animationFrame';
import DateContentRow from './DateContentRow';
import Header from './Header';
import DateHeader from './DateHeader';

import { inRange, sortEvents } from './utils/eventLevels';
import { commonProps, commonViewProps } from './CalendarPropsTypes';
import { RBCContext } from './CalendarContext';

let eventsForWeek = (evts, start, end, accessors) => evts.filter((e) => inRange(e, start, end, accessors));

const propTypes = {
    ...commonProps,
    ...commonViewProps,
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),

    step: PropTypes.number,

    scrollToTime: PropTypes.instanceOf(Date),
    rtl: PropTypes.bool,
    width: PropTypes.number,
    selected: PropTypes.object,
    selectable: PropTypes.oneOf([true, false, 'ignoreEvents']),
    longPressThreshold: PropTypes.number,
    onNavigate: PropTypes.func,
    onSelectSlot: PropTypes.func,
    onSelectEvent: PropTypes.func,
    onDoubleClickEvent: PropTypes.func,
    onDrillDown: PropTypes.func,
    getDrilldownView: PropTypes.func.isRequired,
    handleDragStart: PropTypes.func,
};

interface MonthViewProps extends InferProps<typeof propTypes> {
    className?: string;
}
class MonthView extends React.Component<MonthViewProps, any> {
    public static propTypes = propTypes;
    static contextType = RBCContext;
    declare context: React.ContextType<typeof RBCContext>;
    private _pendingSelection: any[];
    private readonly slotRowRef: React.RefObject<any>;
    private _weekCount: any;
    private _resizeListener: () => void;
    private _selectTimer: number;
    constructor(props, ctx) {
        super(props, ctx);

        // this._bgRows = []

        this._pendingSelection = [];
        this.slotRowRef = React.createRef();
        this.state = {
            rowLimit: 5,
            needLimitMeasure: true,
        };
    }

    public static range = (date, { localizer }) => {
        let start = dates.firstVisibleDay(date, localizer);
        let end = dates.lastVisibleDay(date, localizer);
        return { start, end };
    };

    public static navigate = (date: Date, action: CalNavigate) => {
        switch (action) {
            case CalNavigate.PREVIOUS:
                return dates.add(date, -1, 'month');

            case CalNavigate.NEXT:
                return dates.add(date, 1, 'month');

            default:
                return date;
        }
    };

    public static title = (date, { localizer }) => localizer.format(date, 'monthHeaderFormat');

    UNSAFE_componentWillReceiveProps({ date }) {
        this.setState({
            needLimitMeasure: !dates.eq(date, this.props.date, 'month'),
        });
    }

    componentDidMount() {
        let running;

        if (this.state.needLimitMeasure) {
            this.measureRowLimit();
        }

        window.addEventListener(
            'resize',
            (this._resizeListener = () => {
                if (!running) {
                    animationFrame.request(() => {
                        running = false;
                        this.setState({ needLimitMeasure: true }); //eslint-disable-line
                    });
                }
            }),
            false
        );
    }

    componentDidUpdate() {
        if (this.state.needLimitMeasure) {
            this.measureRowLimit();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resizeListener, false);
    }

    getContainer = () => {
        return findDOMNode(this);
    };

    render() {
        let { date, className } = this.props;
        const { localizer } = this.context;

        const month = dates.visibleDays(date, localizer);
        const weeks = chunk(month, 7);

        this._weekCount = weeks.length;

        return (
            <div className={clsx('rbc-month-view', className)}>
                <div className="rbc-row rbc-month-header">{this.renderHeaders(weeks[0])}</div>
                {weeks.map(this.renderWeek)}
            </div>
        );
    }

    renderWeek = (week, weekIdx) => {
        let { events, selectable, getNow, selected, date } = this.props;
        const { accessors, components } = this.context;

        const { needLimitMeasure, rowLimit } = this.state;

        events = eventsForWeek(events, week[0], week[week.length - 1], accessors);

        events.sort((a, b) => sortEvents(a, b, accessors));

        return (
            <DateContentRow
                key={weekIdx}
                ref={weekIdx === 0 ? this.slotRowRef : undefined}
                container={this.getContainer}
                className="rbc-month-row"
                getNow={getNow}
                date={date}
                range={week}
                events={events}
                maxRows={rowLimit}
                selected={selected}
                selectable={selectable}
                renderHeader={this.readerDateHeading}
                renderForMeasure={needLimitMeasure}
                onSelect={this.handleSelectEvent}
                onDoubleClick={this.handleDoubleClickEvent}
                onSelectSlot={this.handleSelectSlot}
            />
        );
    };

    readerDateHeading = ({ date, className, ...props }) => {
        let { date: currentDate, getDrilldownView } = this.props;
        const { localizer, components } = this.context;

        let isOffRange = dates.month(date) !== dates.month(currentDate);
        let isCurrent = dates.eq(date, currentDate, 'day');
        let drilldownView = getDrilldownView(date);
        let label = localizer.format(date, 'dateFormat');
        let DateHeaderComponent = components.month?.dateHeader || DateHeader;

        return (
            <div {...props} className={clsx(className, isOffRange && 'rbc-off-range', isCurrent && 'rbc-current')}>
                <DateHeaderComponent
                    label={label}
                    date={date}
                    drilldownView={drilldownView}
                    isOffRange={isOffRange}
                    onDrillDown={(e) => this.handleHeadingClick(date, drilldownView, e)}
                />
            </div>
        );
    };

    renderHeaders(row) {
        const { localizer, components } = this.context;

        let first = row[0];
        let last = row[row.length - 1];
        let HeaderComponent = components?.month?.header || Header;

        return dates.range(first, last, 'day').map((day, idx) => (
            <div key={'header_' + idx} className="rbc-header">
                <HeaderComponent date={day} localizer={localizer} label={localizer.format(day, 'weekdayFormat')} />
            </div>
        ));
    }

    measureRowLimit() {
        this.setState({
            needLimitMeasure: false,
            rowLimit: this.slotRowRef.current?.getRowLimit(),
        });
    }

    handleSelectSlot = (range, slotInfo) => {
        this._pendingSelection = this._pendingSelection.concat(range);

        clearTimeout(this._selectTimer);
        this._selectTimer = window.setTimeout(() => this.selectDates(slotInfo));
    };

    handleHeadingClick = (date, view, e) => {
        e.preventDefault();
        this.clearSelection();
        notify(this.props.onDrillDown, [date, view]);
    };

    handleSelectEvent = (...args) => {
        this.clearSelection();
        notify(this.props.onSelectEvent, args);
    };

    handleDoubleClickEvent = (...args) => {
        this.clearSelection();
        notify(this.props.onDoubleClickEvent, args);
    };

    selectDates(slotInfo) {
        let slots = this._pendingSelection.slice();

        this._pendingSelection = [];

        slots.sort((a, b) => +a - +b);

        notify(this.props.onSelectSlot, {
            slots,
            start: slots[0],
            end: slots[slots.length - 1],
            action: slotInfo.action,
            bounds: slotInfo.bounds,
            box: slotInfo.box,
        });
    }

    clearSelection() {
        clearTimeout(this._selectTimer);
        this._pendingSelection = [];
    }
}

export default MonthView;
