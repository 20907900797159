import * as React from 'react';
import { Button, Header, Message, Modal, Segment, Tab } from 'semantic-ui-react';
import { MenuBar, MenuBarItem, MenuBarSection, SemanticToastType, toast, VStack } from '@poolware/components';
import JSONTree from 'react-json-tree';
import { compose } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { getRollbar, withAppConfig, WithAppConfigProps } from '../../app-config';
import { connectWithTheme, ThemeStateProps } from '../../app-theme';

interface PageProps extends ThemeStateProps, WithAppConfigProps, IAppNavigatorProps {}

class Page extends React.Component<PageProps, any> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            throwTest: false,
            showModal: false,
        };
    }

    goBack = () => {
        this.props.AppNavigator.navigate('/');
    };

    showToast = (toastType: SemanticToastType) => () => {
        toast({
            type: toastType,
            title: 'Alert ' + toastType,
            description: 'In Icon (created by Message), SemanticToastContainer.',
        });
    };

    renderSystemConf = () => {
        const rollbar = getRollbar() || {};
        const appConfig = this.props.appConfig;

        return (
            <div>
                <Segment>
                    <Header content={'App Config'} />
                    <JSONTree data={appConfig} />
                </Segment>
                <Segment>
                    <Header content={'Rollbar'} />
                    <JSONTree data={rollbar.options || {}} />
                </Segment>

                <Segment>
                    <Header content={'Server manifest'} />
                    <JSONTree data={window['serverManifest']} />
                </Segment>

                <Segment>
                    <Header content={'process.env'} />
                    <JSONTree data={process.env} />
                </Segment>
            </div>
        );
    };

    renderToastButtons = () => {
        return (
            <Button.Group basic>
                <Button onClick={this.showToast('error')} content={'Error'} />
                <Button onClick={this.showToast('info')} content={'Info'} />
                <Button onClick={this.showToast('success')} content={'Success'} />
                <Button onClick={this.showToast('warning')} content={'Warning'} />
            </Button.Group>
        );
    };

    renderUIComps = () => {
        return (
            <div>
                <Segment.Group horizontal>
                    <Segment>
                        <Header content={'Toaster'} />
                        {this.renderToastButtons()}
                    </Segment>
                    <Segment>
                        <Modal trigger={<Button content={'Test in Modal'} />} closeIcon>
                            <Header content="Toasters" />
                            <Modal.Content>{this.renderToastButtons()}</Modal.Content>
                        </Modal>
                    </Segment>
                </Segment.Group>

                <Segment>
                    <Header content={'Crash Logger Tests'} />
                    {this.state.throwTest && this.state.throwTestError()}
                    <p>Tests crash loggers</p>
                    <Button onClick={this.crash} content={'Crash'} color={'red'} basic icon={'warning sign'} />
                </Segment>
            </div>
        );
    };

    panes = [
        { menuItem: 'System', render: () => <Tab.Pane>{this.renderSystemConf()}</Tab.Pane> },
        { menuItem: 'Other', render: () => <Tab.Pane>{this.renderUIComps()}</Tab.Pane> },
    ];

    render() {
        return (
            <VStack>
                <MenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={this.goBack} icon={'chevron left'} title={'Back'} />
                    </MenuBarSection>
                </MenuBar>
                <Header size={'small'} content={'Dev Tools - Admins ONLY'} />
                <Tab panes={this.panes} />
            </VStack>
        );
    }

    private crash = () => {
        this.setState({ throwTest: true });
    };
}

const renderNoPermissions = () => () =>
    (
        <Message>
            <Message.Content>Not enough permissions to see this page</Message.Content>
        </Message>
    );

export default compose(withAppNavigator(), connectWithTheme(), withAppConfig())(Page);
