export const stringToArr = (str: string) => {
    console.log(str);
    if (typeof str !== 'string') return [];
    return str
        ? str
              .split(',')
              ?.map((s) => s.trim())
              ?.filter(Boolean)
        : [];
};

export const loadManifest = () => {
    const serverManifest = window['serverManifest'] || {};
    const gitSha = process.env.REACT_APP_CODE_VERSION || 'latest';

    const getByKey = (key: string) => {
        return serverManifest[key] || process.env[key];
    };

    return {
        NODE_ENV: process.env.NODE_ENV,
        APP_ENV: process.env.APP_ENV,
        REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
        REACT_APP_THEME_NAME: getByKey('REACT_APP_THEME_NAME'),
        REACT_APP_THEME_NAME_ADMIN: getByKey('REACT_APP_THEME_NAME_ADMIN'),
        REACT_APP_APP_NAME: getByKey('REACT_APP_APP_NAME'),
        REACT_APP_DISABLE_ORGS: getByKey('REACT_APP_DISABLED_ORGS'),
        codeVersion: gitSha,
        disabledOrgs: stringToArr(getByKey('REACT_APP_DISABLED_ORGS')),
        apps: {
            frontend: getByKey('REACT_APP_FRONTEND_URL'),
            admin: getByKey('REACT_APP_ADMIN_URL'),
            configurator: getByKey('REACT_APP_CONFIGURATOR_URL'),
            account: getByKey('REACT_APP_ACCOUNT_URL'),
            warrantyHQ: getByKey('REACT_APP_WARRANTY_HQ_URL'),
        },
        hotjar: {
            enabled: getByKey('REACT_APP_HOTJAR_ENABLED') === 'true',
        },
        zendesk: {
            // zendesk is enabled by default
            enabled: !(getByKey('REACT_APP_ZENDESK_DISABLED') === 'false'),
        },
        rollbar: {
            // rollbar is enabled by default
            enabled: !(getByKey('REACT_APP_ROLLBAR_DISABLED') === 'true'),
            accessToken: getByKey('REACT_APP_ROLLBAR_ACCESS_KEY'),
            code_version: gitSha,
            environment: process.env.NODE_ENV,
        },
        google_maps: {
            enabled: getByKey('REACT_APP_GOOGLE_MAPS_ENABLED') === 'true',
            apiToken: getByKey('REACT_APP_GOOGLE_MAPS_API_TOKEN'),
        },
        google_analytics: {
            enabled: getByKey('REACT_APP_GOOGLE_ANALYTICS_ENABLED') === 'true',
            analyticsId: getByKey('REACT_APP_GOOGLE_ANALYTICS_ID'),
        },
    };
};
