import * as React from 'react';
import {
    StickyMenuBar,
    MenuBar,
    MenuBarGroupItemConfType,
    MenuBarGroupMODE,
    MenuBarSection,
    Panel,
    VStack,
    ButtonWithPopup,
} from '@poolware/components';
import { Button, Divider, Icon } from 'semantic-ui-react';
import { ButtonChangeStatus } from './ActionButtons';
import { mapConfFromStatusMap } from '../../utils';
import { ModuleColorNames, ModuleIconNames, ServiceJobModuleRouterID } from '../../../constants';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { AppointmentDetailsContentItems } from './AppointmentDetailsContentItems';
import { AppointmentDetailsViewCompProps } from './types';
import { AppointmentViewPanelJobNote } from '../../AppointmentDock/AppointmentView.PanelJobNote';
import { AppointmentStatusButtons } from '../../AppointmentDock/AppointmentView.HeaderStatusButtons';

export const AppointmentDetailsPageMode: React.FC<AppointmentDetailsViewCompProps> = (props) => {
    const {
        appointmentItem,
        onEdit,
        onDelete,
        onClose,
        appointmentGroups,
        onChangeStaff,
        onGroupChange,
        onColorChange,
        onStartWorkOrder,
        onChangeStatus,
        onChangeDescription,
        canEdit,
    } = props;

    const { AppNavigator, location } = useAppNavigator();

    const { isRecurring, workOrder, serviceJob } = appointmentItem || { isRecurring: false };
    const headerTitle = `Appointment ${isRecurring ? '(Recurring)' : ''}`;
    const headerIcon = isRecurring ? 'refresh' : 'calendar outline';

    const menu: MenuBarGroupItemConfType[] = [
        {
            icon: 'edit',
            title: 'Edit',
            onClick: onEdit,
            locked: !canEdit,
        },
        onDelete && {
            icon: 'trash',
            title: 'Delete',
            onClick: onDelete,
        },
    ];
    const { status, isWorkOrderPending } = appointmentItem;
    const buttonConfs = mapConfFromStatusMap(status);

    const hasWo = !!workOrder?.id;

    const onOpenWorkOrder = async () => {
        AppNavigator.navigateRelative(`/wo/${workOrder?.id}`, {
            setOrigin: true,
            moduleId: ServiceJobModuleRouterID,
            query: {
                'return-appt': location.pathname,
            },
        });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={onClose} icon={'cancel'} />
                    <MenuBar.HeaderItem>
                        <Icon color={ModuleColorNames.Appointment} name={headerIcon} />
                        {headerTitle}
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBarSection position={'right'}>
                    <MenuBar.Group
                        menu={{
                            color: 'red',
                            icon: 'bars',
                            direction: 'left',
                            menuMode: MenuBarGroupMODE.DROPDOWN,
                        }}
                        items={menu}
                    />
                </MenuBarSection>
            </StickyMenuBar>
            <AppointmentStatusButtons onChangeStatus={onChangeStatus} buttonConfigs={buttonConfs} />

            <div tw={'text-base'}>
                <Panel>
                    <Panel.Body>
                        <AppointmentDetailsContentItems
                            canEdit={canEdit}
                            appointmentItem={appointmentItem}
                            appointmentGroups={appointmentGroups}
                            onChangeStaff={onChangeStaff}
                            onGroupChange={onGroupChange}
                            onColorChange={onColorChange}
                            onStartWorkOrder={onStartWorkOrder}
                            showNote={false}
                        />
                    </Panel.Body>
                </Panel>
            </div>
            <AppointmentViewPanelJobNote appointmentItem={appointmentItem} onChange={onChangeDescription} />
            <Divider hidden={true} />
            {isWorkOrderPending && (
                <ButtonWithPopup
                    fluid={true}
                    basic={false}
                    color={ModuleColorNames.WorkOrder}
                    icon={ModuleIconNames.WorkOrder}
                    onClick={props.onStartWorkOrder}
                    showLoaderOnClick={true}
                    // Note: It is actually "Start work order" operation.
                    content={'Start Work Order'}
                />
            )}
            {hasWo && (
                <ButtonWithPopup
                    fluid={true}
                    basic={false}
                    color={ModuleColorNames.WorkOrder}
                    icon={ModuleIconNames.WorkOrder}
                    onClick={onOpenWorkOrder}
                    showLoaderOnClick={true}
                    content={'Open Work Order'}
                />
            )}
        </VStack>
    );
};
