import * as React from 'react';
import withBreadcrumbs, { BreadcrumbsRoute, Options, InjectedProps } from 'react-router-breadcrumbs-hoc';
import { Helmet } from 'react-helmet-async';
import { useAppConfigContext } from '../app-config';
import { compose } from '@ez/tools';

export type {
    InjectedProps as WithAppBreadcrumbsInjectedProps,
    BreadcrumbsRoute as AppBreadcrumbsRoute,
    Options as AppBreadcrumbsOptions,
} from 'react-router-breadcrumbs-hoc';

const breadcrumbWithPageTitleSetter = (breadcrumb: string | any): React.FC => {
    if (typeof breadcrumb !== 'string') {
        return breadcrumb;
    }

    return () => {
        const appConfig = useAppConfigContext();
        const pageName = breadcrumb;
        let pageTitle = `${appConfig.appName} - ${pageName}`;
        return (
            <>
                <Helmet title={pageTitle} />
                {breadcrumb}
            </>
        );
    };
};

/**
 * withDefaultPageHeader sets default page header title if no breadcrumbs are set for a page.
 */
const withDefaultPageHeader = (C) => (props) => {
    const { breadcrumbs } = props;
    const count = breadcrumbs.length;

    const appConfig = useAppConfigContext();
    const appName = `${appConfig.appName}`;

    return (
        <>
            {count === 0 && appName && <Helmet title={appName} />}
            <C {...props} />
        </>
    );
};

export const withAppBreadcrumbs = (routes: BreadcrumbsRoute[], options?: Options) => {
    const routesConf = routes.map((r) => ({
        ...r,
        breadcrumb: breadcrumbWithPageTitleSetter(r.breadcrumb),
    }));
    return compose(withBreadcrumbs(routesConf, options), withDefaultPageHeader);
};
