import React, { useContext, useMemo, useState } from 'react';
import { CalendarEventAddressAreaType, CalendarEventStreetType } from '../CalendarView/street-summaries';

export type CalendarPageContextType = {
    setDragFromOutsideItem: (item: any) => any;
    getDragFromOutsideItem: () => any;
    setStreetsSummary: (streetNames: CalendarEventStreetType[]) => any;
    streetsSummary: CalendarEventStreetType[];
    setAreasSummary: (streetNames: CalendarEventAddressAreaType[]) => any;
    areasSummary: CalendarEventAddressAreaType[];
};

const CalendarPageContext = React.createContext<CalendarPageContextType>(null);

export const useCalendarPageContext = () => useContext(CalendarPageContext);

export const CalendarPageContextProvider: React.FC = (props) => {
    const [dragFromOutsideItem, setDragFromOutsideItem] = useState(null);
    const [streetsSummary, setStreetsSummary] = useState<CalendarEventStreetType[]>([]);
    const [areasSummary, setAreasSummary] = useState<CalendarEventAddressAreaType[]>([]);
    const ctxValue: CalendarPageContextType = useMemo(
        () => ({
            getDragFromOutsideItem: () => dragFromOutsideItem,
            setDragFromOutsideItem: setDragFromOutsideItem,
            streetsSummary: streetsSummary || [],
            setStreetsSummary,
            areasSummary: areasSummary || [],
            setAreasSummary,
        }),
        [dragFromOutsideItem, streetsSummary, areasSummary]
    );

    return <CalendarPageContext.Provider value={ctxValue} {...props} />;
};
