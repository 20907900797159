import * as React from 'react';
import {
    ConnectionErrorMessage,
    MenuBarDropdownItemWithConfirm,
    MenuBarGroup,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    PageSkeletonLoader,
    Panel,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationProductDocument } from '@poolware/api';
import { NotFoundPage } from '@poolware/app-shell';
import { useQueryProductDoc } from './use-query-product-doc';

export interface PageProps {}

export const PageView: React.FC<PageProps> = () => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { node, error, loading, refetchQuery } = useQueryProductDoc(params.id);
    const { delete: deleteMutation } = useMutationProductDocument({ refetchQueries: [refetchQuery] });

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <NotFoundPage />;
    }

    const goBack = () => {
        AppNavigator.navigateRelative('/');
    };

    const onDelete = async () => {
        try {
            await deleteMutation({ id: params.id });
            goBack();
        } catch (e) {
            toastError(e);
        }
    };

    const canDelete = node.checkMutations?.delete;

    const barGroupItemConf: MenuBarGroupProps = !canDelete
        ? undefined
        : {
              menu: { menuMode: MenuBarGroupMODE.DROPDOWN, icon: 'bars', color: 'red' },
              items: [
                  {
                      render: (
                          <MenuBarDropdownItemWithConfirm
                              icon={'trash'}
                              color="orange"
                              title="Delete"
                              onClick={onDelete}
                              popup={{ content: 'Delete File' }}
                              confirm={{
                                  confirmMessage: {
                                      header: 'Delete?',
                                      content: 'There is no undo! This tag will be deleted forever! ',
                                  },
                                  confirmButton: {
                                      content: 'Delete',
                                      icon: 'trash',
                                      negative: true,
                                  },
                              }}
                          />
                      ),
                  },
              ],
          };

    const brandName = node?.brand?.name;
    const brandCompany = node?.brand?.company?.name;
    const brand = `${brandCompany ? `${brandCompany} > ` : ''} ${brandName || ''} `;

    const isImage = node.isImage;
    const isPdf = node.mimeType === 'application/pdf';

    return (
        <>
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'} />
                        <MenuBarHeaderItem icon={'tag'}>Product Document</MenuBarHeaderItem>
                    </MenuBarSection>
                    {barGroupItemConf && (
                        <MenuBarSection position={'right'}>
                            <MenuBarGroup {...barGroupItemConf} />
                        </MenuBarSection>
                    )}
                </StickyMenuBar>

                <Panel>
                    <Panel.Header content={'Product Document'} />
                    <Panel.Body>
                        <Panel.Item label={'Type'}>{node.type}</Panel.Item>
                        <Panel.Item label={'Brand'} content={brand} />
                        <Panel.ItemDate label={'Created'} content={node.createdAt} />
                        <Panel.Item label={'Organisation'}>{node.organisationType?.name}</Panel.Item>
                        <Panel.Item label={'Ownership'}>{node.franchise?.name}</Panel.Item>
                        <Panel.Divider />
                        <Panel.Item label={'Title'} content={node.title} />
                        <Panel.Item label={'URL'}>
                            <a href={node.url} target={'_blank'} rel="noopener noreferrer">
                                {node.url}
                            </a>
                        </Panel.Item>
                        <Panel.Item label={'MIME'} content={node.mimeType} />
                    </Panel.Body>
                </Panel>
            </VStack>
            <div tw={'2xl:w-2/3 w-full m-auto mt-8 bg-gray-800 shadow-md rounded p-1'} style={{ height: '600px' }}>
                {isPdf && (
                    <object tw={'w-full h-full'} data={node.url} type="application/pdf">
                        <embed src={node.url} type="application/pdf" />
                    </object>
                )}
                {isImage && <img tw={'w-full h-full object-contain'} src={node.url} />}
            </div>
        </>
    );
};
