import * as React from 'react';
import { connect, getIn } from 'formik';
import { useModalCtrl } from '@poolware/components';
import { NodeType } from '@poolware/api';
import styled from 'styled-components';
import { FormikServiceJobTemplateSelect } from '../../connected-components/FormikServiceJobTemplateSelect';

export interface FormikServiceJobTemplateInputProps {
    name?: string;
}

export const FormikPickerServiceJobTemplate = connect<FormikServiceJobTemplateInputProps>(({ name, formik }) => {
    const nn = (fieldName) => (name ? `${name}.${fieldName}` : fieldName);

    const modalWOPicker = useModalCtrl();

    const onSubmit = (
        serviceJobTemplate: NodeType.ServiceJobTemplate,
        workOrderTemplates: NodeType.WorkOrderTemplate[]
    ) => {
        modalWOPicker.onClose();
        if (!serviceJobTemplate) {
            return null;
        }

        formik.setFieldValue(nn('sjTemplate'), serviceJobTemplate);
        formik.setFieldValue(nn('woTemplates'), workOrderTemplates);
    };

    const onRemove = () => {
        formik.setFieldValue(nn('sjTemplate'), null);
        formik.setFieldValue(nn('woTemplates'), null);
    };

    const woTemplates: NodeType.WorkOrderTemplate[] = getIn(formik.values, nn('woTemplates'), []);
    const woPreviewIds = woTemplates?.map((w) => w.id);

    return (
        <>
            <FormikServiceJobTemplateSelect
                name={nn('sjTemplate')}
                selectWorkOrder={'multiple'}
                requiredWorkOrder={true}
                onDidSelect={onSubmit}
                onClear={onRemove}
                previewWorkOrderIds={woPreviewIds}
            />
        </>
    );
});
