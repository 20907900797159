import { createUseQueryNodeHook } from '@ez/api-core';
import { NodeType } from '@poolware/api';
import gql from 'graphql-tag';

const QL = gql`
    query QueryServiceJobGroupConnection($id: ID!) {
        node(id: $id) {
            ... on ServiceJobGroup {
                id
                title
                priority
                organisationType {
                    id
                    name
                }
                franchise {
                    id
                    name
                }
                templates {
                    edges {
                        node {
                            id
                            templateTitle
                        }
                    }
                }
            }
        }
    }
`;
export const useQueryServiceJobGroup = createUseQueryNodeHook<NodeType.ServiceJobGroup>(QL);
