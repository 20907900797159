import gql from 'graphql-tag';
import { jobTodoFragment } from './fragments';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const QL = gql`
    query QueryJobTodo($id: ID!) {
        node(id: $id) {
            id
            ...JobTodoFragment
            ... on JobTodo {
                workOrder {
                    id
                    title
                    workOrderNumber
                    serviceJob {
                        id
                        title
                        serviceJobNumber
                    }
                }
            }
        }
    }
    ${jobTodoFragment}
`;

export const useQueryJobTodo = createUseQueryNodeHook<NodeType.JobTodo>(QL);
