import gql from 'graphql-tag';
import { fromEdges, NodeType, poolFragment } from '@poolware/api';
import { useQuery } from 'react-apollo';
import { useMemo } from 'react';

const QL = gql`
    query NewWaterTest($poolId: ID!) {
        pool: node(id: $poolId) {
            id
            ... on Pool {
                id
                ...PoolFragment
                entity {
                    id
                    user {
                        id
                        customer {
                            id
                            contactName
                            crn
                        }
                    }
                }
                reports(first: 2) {
                    edges {
                        node {
                            id
                            createdAt
                            sampleSet {
                                id
                                createdAt
                                samples {
                                    edges {
                                        node {
                                            id
                                            value
                                            measurementType {
                                                id
                                                tagIdentifier
                                                name
                                                unit {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                chemicalTargets(first: 1000) {
                    edges {
                        node {
                            higherThreshold
                            lowerThreshold
                            target
                            isCalibrated
                            measurementType {
                                id
                                id
                                name
                                priority
                                tagIdentifier
                                unit {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
        viewer {
            measurementTypes(first: 1000) {
                edges {
                    node {
                        id
                        name
                        tagIdentifier
                        unit {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
    ${poolFragment}
`;

export const useQueryPoolDetails = (poolId: string) => {
    const res = useQuery(QL, {
        variables: { poolId: poolId },
        skip: !poolId,
        fetchPolicy: 'cache-and-network',
    });

    return useMemo(() => {
        let pool: NodeType.Pool = res?.data?.pool;
        let chemicalTargets = fromEdges(pool?.chemicalTargets);
        let measurementTypeIds = chemicalTargets.map((c) => c?.measurementType?.id).filter((c) => !!c);

        // NOTE: Overwrite sampleSet.createdAt timestamp with report's timestamp.
        // SampleSet's timestamp is not set correctly during migration from old Poolware DB.
        let prevSampleSets = fromEdges(pool?.reports).map((r) => ({
            ...r.sampleSet,
            createdAt: r.createdAt || r.sampleSet?.createdAt,
        }));

        return {
            ...res,
            pool,
            prevSampleSets,
            chemicalTargets,
            measurementTypeIds,
        };
    }, [res]);
};
