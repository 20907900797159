import * as React from 'react';
import { BlockGroupType } from './types';
import { useMutationAppointment } from '@poolware/api';
import { ButtonWithConfirm, ConfirmProps, Panel, toastError } from '@poolware/components';
import { Button, Message } from 'semantic-ui-react';

let confirmProps: ConfirmProps = {
    confirmMessage: {
        header: 'Delete Schedule?',
        content: 'If you proceed, this Service Schedule and scheduled Appointments will be deleted. There is NO UNDO!',
    },
    negative: true,
    confirmButton: { content: 'Delete Schedule', icon: 'trash' },
    cancelButton: { content: 'Keep' },
};

export const FormDeleteSchedule: React.FC<{ block: BlockGroupType; onDone; onCancel; refetchQueries }> = ({
    block,
    onDone,
    onCancel,
    refetchQueries,
}) => {
    const { deleteBlock } = useMutationAppointment();

    const onDelete = async () => {
        const r = window.confirm('Delete Schedule?');
        if (r !== true) {
            return;
        }
        try {
            await deleteBlock({ patternBlockId: block.patternBlockId }, { refetchQueries: refetchQueries });
            onDone?.();
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header content={'Delete Schedule?'} />
            <Panel.Body>
                <Message info={true}>
                    <p>If you proceed the following will happen:</p>
                    <ul>
                        <li>
                            All scheduled Appointments will be removed. <br /> Except:
                            <ul>
                                <li>Appointments that were in any way modified by the user will not be removed.</li>
                                <li>Appointments with initiated work orders will not be removed.</li>
                            </ul>
                        </li>
                        <li>Appointments that do not belong to this Service Schedule will not be removed.</li>
                    </ul>
                    <div tw={'flex justify-between '}>
                        <Button basic secondary={true} content={'Cancel'} onClick={onCancel} />
                        <ButtonWithConfirm
                            confirm={confirmProps}
                            onClick={onDelete}
                            content={'Delete'}
                            negative={true}
                            icon={'trash'}
                        />
                    </div>
                </Message>
            </Panel.Body>
        </Panel>
    );
};
