import * as React from 'react';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import _trim from 'lodash/trim';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import _extend from 'lodash/extend';
import { withApollo, WithApolloClient } from 'react-apollo';
import { compose } from '@ez/tools';

import { SearchResult } from './SearchResult';
import CustomerSearchForm, { SearchValuesType } from './SearchForm';
import { performCustomerSearch, performPoolsSearch, performSiteSearch } from './searchRequests';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { VStack } from '@poolware/components';

const WAIT_INTERVAL = 800;
const AUTOSEARCH_MIN_LENGTH = process.env.NODE_ENV === 'test' ? 0 : 3;

interface SearchPageState {
    searchValues: SearchValuesType;
    isSearching: boolean;
    searchResult: any;
}

interface SearchPageProps extends IAppNavigatorProps {
    waitInterval?: number;
}

class SearchPage extends React.Component<WithApolloClient<SearchPageProps>, SearchPageState> {
    private readonly delayedSubmit: any;

    constructor(props) {
        super(props);
        const initialValues = this.props.query || {};

        this.state = {
            searchValues: initialValues,
            isSearching: false,
            searchResult: null,
        };

        const debounceInterval = props.waitInterval || WAIT_INTERVAL;
        this.delayedSubmit = _debounce(async () => {
            return await this.updateSearchResult();
        }, debounceInterval);
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        const newValues = nextProps.query || {};
        const oldValues = this.props.query || {};
        if (_isEqual(newValues, oldValues)) return;

        this.setState({
            searchValues: newValues,
            isSearching: false,
            searchResult: null,
        });
    }

    async componentDidMount() {
        await this.updateSearchResult();
    }

    updateLocation = (sanitisedValues) => {
        this.props.AppNavigator.replace(this.props.location.pathname, {
            query: sanitisedValues,
        });
    };

    onClear = () => {
        this.setState({
            searchValues: {},
            isSearching: false,
            searchResult: null,
        });
        this.updateLocation(null);
    };

    onChange = async ({ name, value }) => {
        let values = this.state.searchValues || {};
        values[name] = value;
        this.setState({ searchValues: values }, async () => {
            const maxRequestLength = Object.keys(values).reduce((acc, key) => {
                const value = values[key] || '';
                return value.length > acc ? value.length : acc;
            }, 0);

            // send request only if the length of the search terms is `AUTOSEARCH_MIN_LENGTH` characters or longer.
            if (maxRequestLength >= AUTOSEARCH_MIN_LENGTH) {
                await this.delayedSubmit();
            }
        });
    };

    onSubmit = async () => {
        await this.updateSearchResult(true);
    };

    sanitiseValues = (values) => {
        if (!values) return {};

        const keys = ['crn', 'bottleNumber', 'address', 'siteName', 'customerName', 'contact'];
        return keys
            .filter((key) => !!values[key])
            .reduce((acc, key) => {
                const val = _trim(values[key]);
                if (val) acc[key] = val;
                return acc;
            }, {});
    };

    prepareSearches = (values: SearchValuesType, forceFetch = false) => {
        let { crn, customerName, contact, bottleNumber, address, siteName } = values;
        const searchPromises = [];

        if (address || crn || customerName || contact) {
            searchPromises.push(performCustomerSearch(this.props.client, values, forceFetch));
        }

        if (address || bottleNumber) {
            searchPromises.push(performPoolsSearch(this.props.client, values, forceFetch));
        }

        if (address || siteName) {
            searchPromises.push(performSiteSearch(this.props.client, values, forceFetch));
        }

        return searchPromises;
    };

    requestToken = null;

    updateSearchResult = async (forceFetch = false) => {
        this.updateLocation(this.state.searchValues);

        const values = this.sanitiseValues(this.state.searchValues);
        this.requestToken = values;

        if (_isEmpty(values)) {
            this.setState({ searchResult: null, isSearching: false });
            return;
        }

        const searchPromises = this.prepareSearches(values, forceFetch);

        if (searchPromises.length === 0) {
            this.setState({ searchResult: null, isSearching: false });
            return;
        }

        this.setState({ isSearching: true });

        try {
            const performRequest = async (token) => {
                //console.log("requesting for token", token);
                const data = await Promise.all(searchPromises);
                const searchResult = _reduce(data, _extend); // merge array of objects to one object
                return { token, searchResult };
            };

            const { token, searchResult } = await performRequest(this.requestToken);

            //console.log(searchResult);

            if (!_isEqual(this.requestToken, token)) {
                //console.log("Drop request result: this.requestToken != token", this.requestToken, token);
                return;
            }
            //console.log("update state token", token);
            this.setState({ isSearching: false, searchResult: searchResult });
        } catch (err) {
            console.error(err);
            this.setState({ isSearching: false });
        }
    };

    render() {
        const { isSearching, searchResult, searchValues = {} } = this.state;
        return (
            <VStack>
                <CustomerSearchForm
                    value={searchValues}
                    isSearching={isSearching}
                    onChange={this.onChange}
                    onSubmit={this.onSubmit}
                    onClear={this.onClear}
                />
                {searchResult && <SearchResult searchResult={searchResult} searchValues={searchValues} />}
            </VStack>
        );
    }
}

export default compose(withApollo, withAppNavigator())(SearchPage);
