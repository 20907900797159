import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
    ConnectionTableDef,
    DefaultConnectionTable,
    LinkButton,
    Panel,
    TableDefColumn,
    TruncatedDivWithPopup,
    VStack,
} from '@poolware/components';
import { Button, Checkbox, Header, Segment } from 'semantic-ui-react';
import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';
import { ActiveFiltersPillsBar } from '../components-redux-connected/ActiveFiltersPillsBar';
import { TruncatedDiv } from '../components/TruncatedDiv';
import { useConnectedQueryProductConnection, useQueryProductSearchAuxData } from '../queries';
import { FilterByProductName, FilterByTraitDeclaration_Select } from '../components-redux-connected';
import { FilterType, useProductCatalogActions } from '../redux';
import { NewProductCreateContextType } from './Page';

const declarationsListColumn = (): TableDefColumn[] => {
    return [
        {
            header: 'Categories',
            style: { width: '260px' },
            cellProps: {
                singleLine: true,
            },
            cell: (pd) => {
                const traits = fromEdges(_.get(pd, 'traits'));
                const text = traits.map((t) => _.get(t, 'declaration.name', '')).join(', ');

                // const text = _.get(traits.slice(0), '[0].declaration.name');
                return <TruncatedDivWithPopup maxWidth={260}>{text}</TruncatedDivWithPopup>;
            },
        },
    ];
};

const useTableDefinition = (selectedItems, onSelectChange) => {
    const tableDef: ConnectionTableDef<any> = useMemo(() => {
        const handleSingleCheckbox = (pd, checked) => {
            if (checked) {
                onSelectChange([pd]);
            } else {
                onSelectChange([]);
            }
        };

        const tableDef: ConnectionTableDef<any> = [];

        tableDef.push(
            {
                header: '',
                cell: (pd) => {
                    const isSelected = selectedItems.findIndex((p) => p.id === pd.id) !== -1;
                    return (
                        <Checkbox checked={isSelected} onChange={(e, data) => handleSingleCheckbox(pd, data.checked)} />
                    );
                },
            },
            {
                header: 'Name',
                width: 5,
                sortKey: 'name',
                cell: (pd) => {
                    const name = _.get(pd, 'name', '--');
                    const isSelected = selectedItems.findIndex((p) => p.id === pd.id) !== -1;
                    return (
                        <TruncatedDiv expandable={false}>
                            <LinkButton onClick={() => handleSingleCheckbox(pd, !isSelected)}>{name}</LinkButton>
                        </TruncatedDiv>
                    );
                },
            },
            {
                header: 'Company > Brand',
                cell: (pd) => {
                    const brandName = _.get(pd, 'brand.name');
                    const brandCompany = _.get(pd, 'brand.company.name');
                    return `${brandCompany ? `${brandCompany} > ` : ''} ${brandName || ''} `;
                },
            }
        );

        tableDef.push(...declarationsListColumn());

        return tableDef;
    }, [selectedItems, onSelectChange]);

    return tableDef;
};

export interface ExternalPageControlProps {
    onSelectedItemsChange: (products: NodeType.Product[]) => any;
    onNewProductCreate?: (context: NewProductCreateContextType) => any;
}

interface PageControlProps extends ExternalPageControlProps {}

const SearchResultTable: React.FC<PageControlProps> = (props) => {
    const { onSelectedItemsChange, onNewProductCreate } = props;
    const { ProductCatalogState, ProductCatalogAction } = useProductCatalogActions();
    const { connectionData, connectionState } = useConnectedQueryProductConnection({
        ProductCatalogState,
        ProductCatalogAction,
        pageSize: 10,
    });
    const { relatedBrands, flattenedDeclarations, relatedCompanies } = useQueryProductSearchAuxData(
        ProductCatalogState
    );
    const { filters } = ProductCatalogState;

    const [selectedItems, _setSelectedItems] = useState<NodeType.Product[]>([]);

    const clearAll = () => {
        ProductCatalogAction.removeAllFilterItems();
    };

    const handleOnNewProductCreate = () => {
        const declaration = ProductCatalogState.getFilterItemsByType(FilterType.DECLARATION)[0];
        const brand = ProductCatalogState.getFilterItemsByType(FilterType.BRAND)[0];
        const prodNameValue = ProductCatalogState.getFilterItemsByType(FilterType.NAME)[0];

        const newProductSuggestedValues: NewProductCreateContextType = {
            declaration: null,
            name: null,
            brand: null,
        };

        newProductSuggestedValues.declaration = declaration ? declaration.value : null;
        newProductSuggestedValues.name = prodNameValue ? prodNameValue.value : null;
        newProductSuggestedValues.brand = brand ? brand.value : null;

        onNewProductCreate && onNewProductCreate(newProductSuggestedValues);
    };

    const handleSelectChange = (products) => {
        _setSelectedItems(products);
        onSelectedItemsChange(products);
    };

    useEffect(() => {
        handleSelectChange([]);
        return () => {
            handleSelectChange([]);
        };
    }, [ProductCatalogState]);

    const tableDef: ConnectionTableDef<any> = useTableDefinition(selectedItems, handleSelectChange);

    const searchResultIsEmpty =
        !connectionState.isInitialLoading && !connectionState.error && connectionData.length === 0;

    const hasSelection = selectedItems.length > 0;

    const noDataComponent = (
        <Button
            icon={'plus'}
            basic={true}
            fluid={true}
            disabled={hasSelection}
            onClick={handleOnNewProductCreate}
            content="Create new"
            color={searchResultIsEmpty ? 'green' : undefined}
        />
    );

    return (
        <VStack>
            <Panel>
                <Panel.Header
                    icon={'search'}
                    button={
                        filters.hasActiveFilters
                            ? { content: 'Clear All Filters', onClick: clearAll, color: 'purple' }
                            : null
                    }
                >
                    Search Product
                </Panel.Header>
                <Panel.Body as={Segment.Group} style={{ padding: 0, margin: 0 }}>
                    <Segment secondary={true}>
                        <div className={'form ui'}>
                            <div className={'equal width fields'}>
                                <div className={'field'}>
                                    <FilterByTraitDeclaration_Select label={'Product Type'} rootTag={'equipment'} />
                                </div>
                                <FilterByProductName
                                    label={'Product'}
                                    placeholder={'Search by name, brand, company...'}
                                />
                            </div>
                        </div>
                    </Segment>
                    {filters.hasActiveFilters && (
                        <Segment>
                            <ActiveFiltersPillsBar
                                relatedCompanies={relatedCompanies}
                                flattenedDeclarations={flattenedDeclarations}
                                relatedBrands={relatedBrands}
                            />
                        </Segment>
                    )}
                </Panel.Body>
            </Panel>

            <Header>Search Results</Header>

            <DefaultConnectionTable
                connectionData={connectionData}
                connectionState={connectionState}
                tableDef={tableDef}
                tableFooter={noDataComponent}
            />
        </VStack>
    );
};

export default SearchResultTable;
