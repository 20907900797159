import * as React from 'react';
import { PageContainer } from '../../Layout/PageContainer';
import { Router as WaterlinkRouter } from '@poolware/waterlink';

export const Router = () => {
    return (
        <PageContainer>
            <WaterlinkRouter />
        </PageContainer>
    );
};
