import { compose, mapProps } from '@ez/tools';
import { fromEdges } from '@poolware/api';
import { graphql } from 'react-apollo';
import Page from './Page';
import gql from 'graphql-tag';
import { withApolloLoading } from '@poolware/components';

const computeSearchAndSortVariables = (props) => {
    return {
        pageSize: 100,
        page: props.pageNumber,
    };
};

const query = gql`
    query ManualProblemsSearchList(
        $pageSize: Int
        $page: Int
        $sort: ManualProblemViewerManualProblemsSort
        $search: ManualProblemViewerManualProblemsSearch
    ) {
        viewer {
            manualProblems(first: $pageSize, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        name
                        description
                        solution
                    }
                }
            }
        }
    }
`;

export default compose(
    // withState('pageNumber', 'setPageNumber', 0),
    // withState('pageSize', 'setPageSize', 5),
    // withState('sort', 'setSort', null),
    // withState('searchString', 'setSearchString', ''),
    graphql(query, {
        options: (props) => ({
            variables: computeSearchAndSortVariables(props),
            fetchPolicy: 'cache-first',
        }),
    }),
    withApolloLoading({ showError: true }),
    mapProps((props) => {
        let { data } = props;
        const { loading } = data;
        const paginatedConnection = data?.viewer?.manualProblems || {};
        const { pageMeta = {} } = paginatedConnection;

        const { page, pageSize } = data.variables;
        const pageCount = pageMeta.pageCount || 1;
        const canPaginate = pageCount > 1;

        return {
            ...props,
            canPaginate: canPaginate,
            currentPage: page,
            pageSize: pageSize,
            pageCount: pageCount,
            isLoadingPage: loading,
            items: fromEdges(paginatedConnection),
        };
    })
)(Page);

export interface PageControl {
    canPaginate: boolean;
    currentPage: number;
    pageSize: number;
    pageCount: number;
    isLoadingPage: boolean;
    items: any[];
}
