import * as React from 'react';
import PoolList from './PoolList';
import { fromEdges } from '@poolware/api';
import { ControlProps } from './Sites.Control';
import {
    Display,
    PrintPageNoteText,
    PrintSection,
    PrintSectionBody,
    PrintSectionBodySection,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionItem,
    PrintSectionProps,
} from '@poolware/components';
import 'twin.macro';

export interface SiteListProps extends ControlProps {}

class SiteList extends React.PureComponent<SiteListProps> {
    state = {
        editAccessKeyMode: false,
    };

    render() {
        let { sites } = this.props;
        // sites = fromEdges(sites);
        // const numOfSites = sites.length;

        if (sites.length === 0) {
            return (
                <PrintSectionColumn>
                    <p style={{ color: '#555', padding: '0.1cm' }}>
                        <i>No sites registered</i>
                    </p>
                </PrintSectionColumn>
            );
        }

        return (
            <PrintSectionColumn>
                {sites.map((site, index) => {
                    let pools = fromEdges(site.pools);
                    return (
                        <React.Fragment key={site.id}>
                            <PrintSectionBodySection>
                                <PrintSectionBody>
                                    <PrintSectionColumn>
                                        <PrintSectionItem label={'Site Name'} content={site.name} />
                                        <PrintSectionItem
                                            label="Address"
                                            content={<Display.Address value={site.address} />}
                                        />
                                    </PrintSectionColumn>
                                    <PrintSectionColumn>
                                        <PrintSectionItem label={'Site Access'}>
                                            <PrintPageNoteText
                                                tw={'min-h-0'}
                                                formatted={false}
                                                limit={10000}
                                                value={site.accessKeyLocation || '--'}
                                            />
                                        </PrintSectionItem>
                                    </PrintSectionColumn>
                                </PrintSectionBody>
                                <PrintSectionBody>
                                    <>
                                        <PoolList pools={pools} />
                                    </>
                                </PrintSectionBody>
                            </PrintSectionBodySection>
                        </React.Fragment>
                    );
                })}
            </PrintSectionColumn>
        );
    }
}

const Sites: React.FC<PrintSectionProps & SiteListProps> = (props) => {
    const { showPrintToggle, noPrint, onPrintChange, ...rest } = props;
    return (
        <PrintSection {...{ showPrintToggle, noPrint, onPrintChange }}>
            <PrintSectionHeader>Customer Sites</PrintSectionHeader>
            <PrintSectionBody>
                <SiteList {...props} />
            </PrintSectionBody>
        </PrintSection>
    );
};

export default Sites;
