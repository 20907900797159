import { ModulesAccess } from './module-access-default';

export const orgAdminAccess: ModulesAccess = {
    SuperAdmin: {
        enabled: false,
    },
    OrgAdmin: {
        enabled: true,
    },
    FranchiseAdmin: {
        enabled: false,
    },
    FieldServices: {
        enabled: false,
    },
    Staff: {
        enabled: false,
    },
    Support: {
        enabled: true,
    },
    HQAdmin: {
        enabled: true,
    },
    Calculators: {
        enabled: false,
    },
};
