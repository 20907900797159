import * as React from 'react';
import AppBreadcrumbs from './AppBreadcrumbs';
import { AppErrorBoundary, PageContent, PageLayoutContentWidth } from '@poolware/app-shell';
import { VStack } from '@poolware/components';

export const PageContainer: React.FC<{ pageWidth?: PageLayoutContentWidth }> = ({ pageWidth, children }) => {
    return (
        <AppErrorBoundary>
            <VStack>
                <AppBreadcrumbs />
                <PageContent pageWidth={pageWidth}>{children}</PageContent>
            </VStack>
        </AppErrorBoundary>
    );
};
