import { WaterlinkAPI } from './interfaces';
import { OptionVersion, WaterlinkAPIClientInterface } from './WaterlinkAPIClientInterface';

const queryString = require('query-string');

const defaultConfig = {
    host: 'localhost',
    port: 61547,
};

const handleErrors = async (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
};

function status(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response.statusText));
    }
}

function json(response) {
    return response.json();
}

function fetchRespToJson(resp) {
    return handleErrors(resp).then(status).then(json);
}

export class WaterlinkAPIClient implements WaterlinkAPIClientInterface {
    public host: string = null;
    public port: number = null;

    public readonly serverBaseUrl: string = '';

    constructor(conf: { host?: string; port?: number } = defaultConfig) {
        this.host = conf.host || defaultConfig.host;
        this.port = conf.port || defaultConfig.port;
        this.serverBaseUrl = this.getBase();
    }

    private getBase = () => {
        return `http://${this.host}:${this.port}`;
    };

    private getEndpoint = (method: string): URL => {
        return new URL(`${this.serverBaseUrl}/wlc/${method}`);
    };

    private getRequest = async <T>(
        method: string,
        options?: { params?: { [key: string]: string | number } }
    ): Promise<T> => {
        try {
            let endpoint = String(this.getEndpoint(method));
            if (options && options.params) {
                endpoint = endpoint + '?' + queryString.stringify(options.params);
            }
            return await window.fetch(endpoint).then(fetchRespToJson);
        } catch (error) {
            console.error('Waterlink request failed: ', error.message);
            throw error;
        }
    };

    public getStatus = async (): Promise<WaterlinkAPI.StatusReply | null> => {
        return await this.getRequest('status');
    };

    public getOptions = async (version?: OptionVersion): Promise<WaterlinkAPI.OptionsReply | null> => {
        return await this.getRequest<WaterlinkAPI.OptionsReply>('options', version && { params: { version: version } });
    };

    public getLog = async (): Promise<any> => {
        return await this.getRequest('log');
    };

    public getAbout = async (): Promise<WaterlinkAPI.AboutReply> => {
        return await this.getRequest<WaterlinkAPI.AboutReply>('about');
    };

    ///
    // IMPORTANT NOTE:
    // The "update" command is commented out (removed) because if it is invoked it will start a firmware update.
    // This is a risky operation. Let the official Waterlink Connect application to do the firmware update.
    //
    // public startUpdate = async (): Promise<any> => {
    //     return await this.getRequest<any>('update');
    // };

    /**
     * Starts water testing. May take up to 3 min to complete.
     * @param input
     */
    public doWaterTest = async (input: {
        reagent: number;
        sample: number;
    }): Promise<WaterlinkAPI.WatertestReply | null> => {
        if (!input || input.reagent === undefined) {
            throw new Error('Reagent is not provided');
        }
        if (!input || !input.sample === undefined) {
            throw new Error('Sample is not provided');
        }

        const status = await this.getStatus();

        if (!status.CanDoWatertest) {
            throw new Error('Cannot perform water test. Status.CanDoWatertest is false');
        }
        return await this.getRequest('watertest', { params: { reagent: input.reagent, sample: input.sample } });
    };
}
