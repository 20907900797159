import { NodeType } from '@poolware/api';
import * as React from 'react';
import { FormikPanelForm, Panel, toastError } from '@poolware/components';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import { DropdownStaff, FormikSuggestInputStaff } from '@poolware/app-shell';

export interface WorkOrderStatusPanelProps {
    workOrder: NodeType.WorkOrder;
    workOrderMutators: ReturnType<typeof useWorkOrderMutators>;
    readonly?: boolean;
}

export const WorkOrderStaffPanel: React.FC<WorkOrderStatusPanelProps> = ({
    workOrder,
    readonly,
    workOrderMutators,
}) => {
    const onChangeStaff = async (newStaff) => {
        try {
            await workOrderMutators.update(
                {
                    id: workOrder.id,
                    assignedTo: newStaff?.id || null,
                },
                { awaitRefetchQueries: true }
            );
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Header icon={'spy'} content={'Staff'} />
            <Panel.Body>
                <Panel.Item label={'Assigned To'} labelWidth={130}>
                    <DropdownStaff readonly={readonly} value={workOrder?.assignedTo?.id} onChange={onChangeStaff} />
                </Panel.Item>
            </Panel.Body>
        </Panel>
    );
};
