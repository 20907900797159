import * as React from 'react';
import * as ProductConfig from './WaterTreatmentConfig';
import Integrations from './Integrations';
import { Franchises } from '../HQAdmin';
import * as Staff from '../Staff';
import { PageContainer } from '../../Layout/PageContainer';
import {
    ModuleRedirect,
    ModuleRoot,
    ModuleRootSwitch,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { NotFoundPage } from '@poolware/app-shell';
import { Router as CustomerTagsRouter } from './CustomerTags';
import { Router as DataExport } from './DataExport';
import { Sidebar } from './Sidebar';
import { ServiceJobs } from '../ServiceJobs';
import { Router as Perm } from './Permissions';
import { Router as SentEmailsRouter } from './SentEmails';
import { SideBarLayout } from './SideBarLayout';
import { matchPath } from 'react-router';

export const FranchiseDetailsRoute = () => {
    return (
        <PageContainer pageWidth={'full'}>
            <ModuleRoot>
                <ModuleSwitch>
                    <ModuleRoute path={'/'} exact component={Franchises.ViewMyFranchise} />
                    <ModuleRoute path={'/:franchiseId/edit'} component={Franchises.Edit} />
                    <ModuleRoute path={'/:franchiseId/shop/new'} component={Franchises.NewFranchiseShop} />
                    <ModuleRoute path={'/:franchiseId/shop/:shopId/edit'} component={Franchises.EditFranchiseShop} />
                    <ModuleRoute path="/:franchiseId/staff/new" component={Staff.New} />
                    <ModuleRoute path="/:franchiseId/staff/:staffId/edit" component={Staff.Edit} />
                    <ModuleRoute path="/:franchiseId/staff/:staffId" component={Staff.View} />
                </ModuleSwitch>
            </ModuleRoot>
        </PageContainer>
    );
};

const SettingsRouter = () => {
    return (
        <ModuleRootSwitch notFound={NotFoundPage}>
            {/*<ModuleRedirect path={'/'} exact to={'/jobs-templates'} />*/}
            <ModuleRoute path={'/permissions'} component={Perm} />
            <ModuleRoute path={'/integrations'} component={Integrations} />
            <ModuleRoute path={'/water-treatment'} component={ProductConfig.List} />
            <ModuleRoute path={'/customer-tags'} component={CustomerTagsRouter} />
            <ModuleRoute path={'/export'} component={DataExport} />
            <ModuleRoute path={'/sent-emails'} component={SentEmailsRouter} />
            <ModuleRoute
                path={'/jobs-templates'}
                moduleId="SERVICE_JOB_TEMPLATES"
                component={ServiceJobs.TemplatesManagerRouter}
            />
        </ModuleRootSwitch>
    );
};
export const FranchiseSettingsRouter = () => {
    const { isModal, location } = useAppNavigator();
    // TODO: find a better way to hide settings sidebar
    const match = matchPath(location.pathname, {
        path: '/settings/jobs-templates',
        exact: false,
        strict: false,
    });

    if (isModal || match) {
        return <SettingsRouter />;
    }

    return <SideBarLayout leftSidebar={<Sidebar />} main={<SettingsRouter />} />;
};
