import {
    FormikCheckboxField,
    FormikDefaultForm,
    FormikDefaultFormProps,
    FormikHTMLSelectField,
    FormikInputField,
    FormikTextareaField,
} from '@poolware/components';
import * as React from 'react';

import * as Yup from 'yup';
import { AddressValidationSchema, FormikAddressInputFields } from '../Address/FormikAddressInputFields';
import { validationSchemaContactItem } from './validation-schemas';
import { customerTitleOptions } from '../../CustomerManagement/Customers/New/NewCustomerForm';

enum FormFieldType {
    INPUT,
    TEXT,
    ADDRESS,
    CHECKBOX,
    SELECT_HTML,
}

const FormFieldTypeMap = {
    [FormFieldType.INPUT]: FormikInputField,
    [FormFieldType.TEXT]: FormikTextareaField,
    [FormFieldType.ADDRESS]: FormikAddressInputFields,
    [FormFieldType.SELECT_HTML]: FormikHTMLSelectField,
    [FormFieldType.CHECKBOX]: FormikCheckboxField,
};

type FormFieldConf = {
    type: FormFieldType;
    name: string;
    label?: string;
    required?: boolean;
    [key: string]: any;
};

const FormikFieldsBuilder: React.FC<{ fields: FormFieldConf[] }> = ({ fields }) => {
    return (
        <>
            {fields.map((f, i) => {
                const { type, ...props } = f;
                const Comp = FormFieldTypeMap[type];
                if (!Comp) return null;
                return <Comp key={i} {...props} />;
            })}
        </>
    );
};

const createForm = (input: { fields: FormFieldConf[]; formProps?: Partial<FormikDefaultFormProps> }) => {
    const CreatedForm: React.FC<FormikDefaultFormProps> = (props) => {
        return (
            <FormikDefaultForm debug={true} {...input.formProps} {...props}>
                <FormikFieldsBuilder fields={input.fields} />
            </FormikDefaultForm>
        );
    };
    return CreatedForm;
};

export const createCustomerContactNameForm = (formProps?: Partial<FormikDefaultFormProps>) => {
    return createForm({
        fields: [
            {
                type: FormFieldType.INPUT,
                name: 'contactName',
                label: 'Contact Name',
            },
        ],
        formProps: {
            ...formProps,
            validationSchema: Yup.object({ contactName: Yup.string().max(300, 'Too Long!') }),
        },
    });
};

export const createEditCustomerCompanyNameForm = (formProps?: Partial<FormikDefaultFormProps>) => {
    return createForm({
        fields: [
            {
                label: 'Company Name',
                type: FormFieldType.INPUT,
                name: 'companyName',
            },
        ],
        formProps: {
            ...formProps,
            validationSchema: Yup.object({ companyName: Yup.string().max(300, 'Too Long!') }),
        },
    });
};

export const createAddressForm = (formProps?: Partial<FormikDefaultFormProps>) => {
    return createForm({
        fields: [
            {
                type: FormFieldType.ADDRESS,
                name: 'address',
            },
        ],
        formProps: {
            ...formProps,
            validationSchema: Yup.object({ address: AddressValidationSchema }),
        },
    });
};

export const createContactItemForm = (contactTypeName) => {
    return createForm({
        fields: [
            {
                type: FormFieldType.INPUT,
                name: 'data',
                label: contactTypeName,
            },
            {
                type: FormFieldType.INPUT,
                name: 'label',
                label: 'Label',
                placeholder: 'e.g. Owner, Tenant',
            },
            {
                type: FormFieldType.CHECKBOX,
                name: 'isPrimary',
                label: `Primary ${contactTypeName}`,
            },
        ],
        formProps: {
            validationSchema: validationSchemaContactItem,
        },
    });
};

export const createMakePrimaryContactItemForm = (contactTypeName) => {
    return createForm({
        fields: [
            {
                type: FormFieldType.INPUT,
                name: 'data',
                label: contactTypeName,
                readOnly: true,
            },
            {
                type: FormFieldType.INPUT,
                name: 'label',
                label: 'Label',
                placeholder: 'e.g. Owner, Tenant',
                readOnly: true,
            },
        ],
        formProps: {
            submitButton: { content: `Set as Primary ${contactTypeName}` },
        },
    });
};

export const createDeleteConfirm = (formProps?: Partial<FormikDefaultFormProps>) => {
    return createForm({
        formProps: {
            submitButton: {
                content: 'Delete',
            },
            ...formProps,
        },
        fields: [
            {
                type: FormFieldType.INPUT,
                name: 'data',
                readOnly: true,
            },
        ],
    });
};

export const createUserNameForm = (formProps?: Partial<FormikDefaultFormProps>) => {
    return createForm({
        fields: [
            {
                type: FormFieldType.INPUT,
                name: 'title',
                label: 'Title',
                required: false,
                options: customerTitleOptions,
            },
            {
                type: FormFieldType.INPUT,
                name: 'firstName',
                label: 'First Name',
                required: false,
            },
            {
                type: FormFieldType.INPUT,
                name: 'lastName',
                label: 'Last Name',
                required: false,
            },
        ],
        formProps: {
            ...formProps,
            validationSchema: Yup.object().shape({
                firstName: Yup.string().max(350, 'Too Long!'),
                lastName: Yup.string().max(350, 'Too Long!'),
            }),
        },
    });
};
