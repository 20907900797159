import * as React from 'react';
import {
    MenuBarGroup,
    MenuBarGroupConfType,
    MenuBarGroupItemConfType,
    MenuBarGroupMODE,
    MenuBarItem,
    StickyMenuBar,
} from '@poolware/components';
import { Menu } from 'semantic-ui-react';
import { useViewer } from '@poolware/app-shell';

interface PageProps {
    isArchived: boolean;
    PDFPreviewURL: string;
    onViewEmailLog: () => any;
    onAddPhoto: () => any;
    onArchive: () => any;
    onDelete: () => any;
    onBack: () => any;
    onBackTitle?: string;
    onReportEdit: () => any;
    onSendReport: () => any;
    disableDelete?: boolean;
    disableUpdate?: boolean;
}

const MenuBar: React.FC<PageProps> = (props) => {
    const { modulesAccess } = useViewer();
    const emailPDFReport = () => {
        if (props.onSendReport) {
            return props.onSendReport();
        }
    };

    const openPDFReport = (pdfURL) => {
        window.open(pdfURL);
    };

    const { isArchived, PDFPreviewURL, disableDelete } = props;
    const allowPhotoUpload = modulesAccess.System?.fileUpload;
    const allowEmailReport = modulesAccess.System?.emailReports || modulesAccess.WaterTest?.sendViaEmail;

    const menu: MenuBarGroupConfType = {
        color: 'black',
        // icon: 'wrench',
        icon: 'share square',
        // title: 'BARS',
        menuMode: MenuBarGroupMODE.DROPDOWN_ON_MOBILE,
    };

    const items: MenuBarGroupItemConfType[] = [
        allowPhotoUpload && {
            onClick: props.onAddPhoto,
            icon: 'camera',
            title: 'Attach Photo',
            color: 'blue',
            disabled: isArchived,
        },
        {
            icon: 'edit',
            title: 'Edit Report',
            color: 'blue',
            disabled: isArchived,
            onClick: props.onReportEdit,
        },
        PDFPreviewURL && {
            onClick: () => openPDFReport(PDFPreviewURL),
            icon: 'file pdf outline',
            title: 'Download PDF',
            color: 'red',
        },
        allowEmailReport && {
            color: 'blue',
            icon: 'send',
            onClick: () => emailPDFReport(),
            title: 'Send Report',
        },
    ];

    const items2: MenuBarGroupItemConfType[] = [
        allowEmailReport && {
            onClick: props.onViewEmailLog,
            icon: 'mail',
            title: 'View Email Log',
            color: 'grey',
        },
        !isArchived && {
            onClick: props.onArchive,
            icon: 'lock',
            title: 'Archive Report',
            color: 'orange',
            confirm: {
                confirmMessage: {
                    header: 'Archive Report?',
                    content: 'This report will be archived and locked. No edits will be allowed!',
                },
                confirmButton: {
                    content: 'Archive Report',
                    negative: true,
                    icon: 'lock',
                },
            },
        },
        {
            icon: 'trash',
            title: 'Delete Report',
            color: 'red',
            disabled: disableDelete,
            onClick: props.onDelete,
            confirm: {
                confirmMessage: {
                    header: 'Delete Report?',
                    content: 'This report will be deleted. This is irreversible operation!',
                },
                confirmButton: {
                    content: 'Delete Report',
                    icon: 'trash',
                    negative: true,
                },
            },
        },
    ];

    return (
        <StickyMenuBar>
            <Menu.Menu position={'left'}>
                {props.onBack && (
                    <MenuBarItem icon={'chevron left'} onClick={props.onBack} title={props.onBackTitle || 'Back'} />
                )}
            </Menu.Menu>

            <Menu.Menu position="right">
                <MenuBarGroup menu={menu} items={items} />
                <MenuBarGroup
                    menu={{
                        color: 'red',
                        icon: 'bars',
                        menuMode: MenuBarGroupMODE.DROPDOWN,
                    }}
                    items={items2}
                />
            </Menu.Menu>
        </StickyMenuBar>
    );
};

export default MenuBar;
