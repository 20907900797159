import { take } from 'redux-saga/effects';
import { Action, History, Location } from 'history';
import { navigateActions } from './browser-navigation';
import { makeDebugger } from '@ez/tools';
import { getHistory } from '../create-app-router';

const debug = makeDebugger('saga:browser-nav');

export const createWatchBrowserNavigate = (store, extHistory?: History<any>) => {
    const locationListener: History.LocationListener = (location: Location<any>, action: Action) => {
        store.dispatch(navigateActions.browserNavigated(location, action));
    };

    const h = extHistory || getHistory().history;
    h.listen(locationListener);

    // trigger router navigation via history
    function* watchBrowserNavigate() {
        while (true) {
            const { payload } = yield take(navigateActions.NAVIGATION_ACTION.NAVIGATE);
            const { pathname, state, replace } = payload;
            debug('navigating with payload', payload);
            const props = typeof pathname === 'string' ? { pathname: pathname } : pathname;
            if (replace) {
                h.replace({ ...props, state: state });
            } else {
                h.push({ ...props, state: state });
            }
        }
    }

    return {
        watchBrowserNavigate,
    };
};
