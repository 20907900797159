import * as React from 'react';
import { useQueryStaffConnection } from '@poolware/api';
import { ActionBarFilterByStaff } from '../../components/ActionBarFilters/ActionBarFilterByStaff';
import { useWorkOrderFeedActions } from '../../redux';
import { useViewer } from '@poolware/app-shell';

export const FilterByStaff: React.FC = () => {
    const { Action, State } = useWorkOrderFeedActions();
    const { modulesAccess } = useViewer();

    const { staffIds = [] } = State.filters;

    return (
        <ActionBarFilterByStaff
            forceMeFilter={modulesAccess.FieldServices?.calendarSingleStaffMode}
            staffIds={staffIds}
            setStaffFilter={({ includeIds }) => Action.setStaffFilter(includeIds)}
            singleSelect={true}
        />
    );
};
