import moment from 'moment';

export const convertDateRange = (dateRange: { startDate; endDate }) => {
    const { startDate, endDate } = dateRange;
    const d = moment(endDate).diff(moment(startDate), 'minutes');
    return {
        startDate: new Date(startDate),
        duration: d,
    };
};
