import * as React from 'react';
import ContactItem from '../../CommonComponents/Contacts/ContactItem';
import { NodeType } from '@poolware/api';
import {
    Display,
    PrintSection,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintSectionItem,
    PrintSectionProps,
} from '@poolware/components';
import styled from 'styled-components';
import { ContactItemsGroupType } from './prepare-contacts';

const ContactsDiv = styled.div`
    :not(:last-child) {
        border-bottom: 1px solid #555;
    }
`;

export const Contacts: React.FC<{ contactItems: ContactItem[] }> = ({ contactItems }) => {
    if (contactItems.length === 0) return null;
    return (
        <ContactsDiv>
            {contactItems.map((ci, index) => {
                return <PrintSectionItem key={index} label={ci.label} content={ci.view()} />;
            })}
        </ContactsDiv>
    );
};

export const CustomerDetailsPanel: React.FC<
    PrintSectionProps & {
        customer?: NodeType.Customer;
        contactItems?: ContactItemsGroupType;
    }
> = ({ contactItems, customer, ...rest }) => {
    return (
        <PrintSection {...rest}>
            <PrintSectionHeader>
                {customer ? (
                    <>
                        <Display.Entity value={customer} /> - <Display.Span value={customer.crn} />
                    </>
                ) : (
                    <span>Customer</span>
                )}
            </PrintSectionHeader>
            <PrintSectionBody>
                {contactItems ? (
                    <>
                        <PrintSectionColumn>
                            <Contacts contactItems={contactItems.customerDetailsGroup} />
                            <Contacts contactItems={contactItems.addressGroup} />
                        </PrintSectionColumn>
                        <PrintSectionColumn>
                            <Contacts contactItems={contactItems.contactItemsGroup} />
                        </PrintSectionColumn>
                    </>
                ) : (
                    <div>
                        <PrintSectionColumn>-</PrintSectionColumn>
                    </div>
                )}
            </PrintSectionBody>
        </PrintSection>
    );
};
