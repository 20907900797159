import * as React from 'react';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { BookingActionProps, BookingReturnDest, withBookingActions } from '../../redux';

class PageWhereToGo extends React.Component<BookingActionProps> {
    render() {
        const go = this.props.BookingAction.navigateToAfterCreation;
        const returnToDetails = this.props.BookingState.returnToDetails;
        return (
            <Segment.Group>
                <Segment secondary>
                    <Header>
                        <Icon name="checkmark" color="green" /> Booking Successful!
                    </Header>
                </Segment>
                <Segment basic clearing>
                    <Grid centered columns={2}>
                        <Segment basic>
                            <Button basic onClick={() => go(BookingReturnDest.CALENDAR)} content={'Go To Calendar'} />
                            <Button
                                basic
                                onClick={() => go(returnToDetails.returnDestination)}
                                content={returnToDetails.label}
                            />
                        </Segment>
                    </Grid>
                </Segment>
            </Segment.Group>
        );
    }
}

export default withBookingActions()(PageWhereToGo) as React.ComponentType<BookingActionProps>;
