import { FormikCheckboxField, FormikInputField, FormikPanelForm } from '@poolware/components';
import * as React from 'react';
import { Divider, Form } from 'semantic-ui-react';
import { AdminOnly, useViewer } from '@poolware/app-shell';
import { useWaterlink } from '../api-react';

export const WaterlinkConfigForm: React.FC = () => {
    const waterlink = useWaterlink();
    const { modulesAccess } = useViewer();
    const canAccessMockApi = modulesAccess.WaterlinkIntegration.mockAPI;

    const initialValues = {
        port: waterlink.server.port,
        host: waterlink.server.host,
        isMock: waterlink.server.isMock,
    };

    const onSubmit = async (values: typeof initialValues, actions) => {
        const updateConf = { port: values.port, isMock: values.isMock };
        await waterlink.setClient(updateConf);
        actions.resetForm({ values: { ...values, ...updateConf } });
    };

    const handleEnableCheckbox = async (_, { checked }) => {
        if (checked) {
            await waterlink.startService();
        } else {
            await waterlink.stopService();
        }
    };

    return (
        <FormikPanelForm initialValues={initialValues} onSubmit={onSubmit} header={'Waterlink Connect 2 Service'}>
            <Form.Checkbox
                checked={waterlink.serviceEnabled}
                toggle
                label={'Enable Waterlink Service'}
                onChange={handleEnableCheckbox}
            />
            <Divider />
            <Form.Group widths={'equal'}>
                <FormikInputField
                    disabled={!waterlink.serviceEnabled}
                    label="Port"
                    name={'port'}
                    type={'number'}
                    required={true}
                />
                <Form.Field disabled={!waterlink.serviceEnabled}>
                    <label>Host</label>
                    <pre>{waterlink.server.host}</pre>
                </Form.Field>
            </Form.Group>
            {canAccessMockApi && (
                <FormikCheckboxField disabled={!waterlink.serviceEnabled} label="Mock API" name={'isMock'} />
            )}

            <i>Default port number "61547"</i>
        </FormikPanelForm>
    );
};
