import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormikHeatCalcValueType, PoolCover } from '../common/types';
import { calcHeatLossDiffModel, computeHeatPumpHeatProfile } from './calc-heat-profile';
import { computeRecommendedHeatPumpModel } from './calc-heater-model';
import { useFormikContext } from 'formik';
import { PagePrint } from './print/PagePrint';
import { computeCostEstimationHeatPump } from './calc-cost-estimation';
import { useCatalog } from '../common/use-catalog';
import { CalcLayoutConfigurable, LayoutConfig } from '../common/CalcLayout';
import {
    FormikHeatPumpTempRequirements,
    FormikPoolProfile,
    FormikSelectLocationYearProfile,
} from '../common/FormikInputComponents';
import { FormikDocTypeToggleButtons } from '../common/FormikDocTypeToggleButtons';
import { FormikMonthlyDataTable } from '../common/FormikMonthlyDataTable';
import { FormikEquipmentOptionsTable } from '../common/FormikEquipmentOptionsTable';
import { PanelDocumentDetails } from '../common/PanelDocumentDetails';
import { PanelHeaterRecommendation } from '../common/PanelHeaterRecommendation';
import { PanelProductBrochure } from '../common/PanelProductBrochure';
import HeatGraph from './HeatGraph';
import { FormikQuickMonthSelectButtons } from '../common/FormikQuickMonthSelectButtons';

interface HeatingCalcsProps {
    print?: boolean;
}

export const HeatPumpCalc: React.FC<HeatingCalcsProps> = ({ print }) => {
    const { values, setFieldValue } = useFormikContext<FormikHeatCalcValueType>();
    const { heatPumps } = useCatalog();

    const poolProfileSettings = useMemo(
        () => ({
            selectedMonths: values.selectedMonths,
            yearProfile: values.yearProfile,
            operatingHours: values.operatingHours,
            poolType: values.poolType,
            poolVolume: values.poolVolume,
            poolSurfaceAreaTop: values.poolSurfaceAreaTop,
            poolLocation: values.poolLocation,
            poolTemp: values.poolTemp,
            lowestAirTemp: values.lowestAirTemp,
            poolCover: values.poolCover,
            surfaceAreaTop: values.poolSurfaceAreaTop,
        }),
        [
            values.selectedMonths,
            values.yearProfile,
            values.operatingHours,
            values.poolType,
            values.poolVolume,
            values.poolSurfaceAreaTop,
            values.poolLocation,
            values.poolTemp,
            values.lowestAirTemp,
            values.poolCover,
            values.electCost,
        ]
    );

    const heatCalcResult_Covered = computeHeatPumpHeatProfile({ ...values, poolCover: PoolCover.Yes });
    const heatCalcResult_Uncovered = computeHeatPumpHeatProfile({ ...values, poolCover: PoolCover.No });
    const withCover = values.poolCover === PoolCover.Yes;
    const heatCalcResult = withCover ? heatCalcResult_Covered : heatCalcResult_Uncovered;

    const heatLossDiffModel = calcHeatLossDiffModel({
        yearProfile: values.yearProfile,
        heatProfileUncovered: heatCalcResult_Uncovered,
        heatProfileCovered: heatCalcResult_Covered,
    });

    const recommendations = useMemo(
        () =>
            computeRecommendedHeatPumpModel({
                monthlyHeatProfiles: heatCalcResult,
                heaterCatalog: heatPumps.models,
            }),
        [heatPumps, heatCalcResult]
    );

    useEffect(() => {
        setFieldValue('autoRecommendedModel.hpump', recommendations.recommendedHeater);
    }, [recommendations?.recommendedHeater?.sku]);

    const selectedModel = values.userSelectedModel?.hpump || recommendations?.recommendedHeater;

    useEffect(() => {
        const cost = computeCostEstimationHeatPump({
            monthHeatProfiles: heatCalcResult,
            selectedModel: selectedModel,
            withCover: poolProfileSettings.poolCover === PoolCover.Yes,
            electricityCost: values.electCost,
            operatingHours: poolProfileSettings.operatingHours,
        });
        setFieldValue('costEstimation.hpump', cost);
    }, [selectedModel, poolProfileSettings]);

    if (print) {
        return <PagePrint costEstimation={values.costEstimation} heatCalcResult={heatCalcResult} />;
    }

    const conf: LayoutConfig = {
        left: [
            <FormikSelectLocationYearProfile />, //
            <FormikPoolProfile />,
            <FormikHeatPumpTempRequirements />,
        ],
        middle: [
            <FormikDocTypeToggleButtons />,
            <FormikQuickMonthSelectButtons />,
            <FormikMonthlyDataTable monthlyHeatProfiles={heatCalcResult} />,
            <FormikEquipmentOptionsTable />,
        ],
        right: [
            <PanelDocumentDetails />,
            <PanelHeaterRecommendation />,
            <PanelProductBrochure />,
            <HeatGraph withCover={values.poolCover === PoolCover.Yes} heatLoss={heatLossDiffModel} />,
        ],
    };

    return <CalcLayoutConfigurable config={conf} />;
};
