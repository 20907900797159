import * as React from 'react';
import { useState } from 'react';
import {
    FormikCheckboxField,
    FormikDefaultForm,
    FormikInputField,
    FormikTextareaField,
    MenuBarItem,
    PageLayout,
    Panel,
    toastError,
    useModalCtrl,
} from '@poolware/components';
import { Checkbox, Icon, Message, Modal } from 'semantic-ui-react';
import { NodeType, useMutationServiceJobTemplate } from '@poolware/api';
import { FormikServiceJobGroupSuggestInput } from '../../../connected-components/ServiceJobGroupSuggestInput';
import { AdminOnly, useViewer } from '@poolware/app-shell';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    templateTitle: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').required('Required'),
    providedTitle: Yup.string().min(2, 'Too Short!').max(250, 'Too Long!').notRequired().nullable(),
});

export interface ServiceJobTemplateDetailsPanelProps {
    refetchQuery?: any;
    template: NodeType.ServiceJobTemplate;
    // if false, the edit button will be disable with "Not enough permissions" message
    canEdit: boolean;
    // If true, the edit button won't be shown regardless of `canEdit` flag value.
    readOnly: boolean;
}

export interface ServiceJobTemplateDetailsFormProps extends ServiceJobTemplateDetailsPanelProps {
    onCancel: () => any;
    onSubmit: () => any;
}

const FormServiceJobTemplateDetails: React.FC<ServiceJobTemplateDetailsFormProps> = ({
    refetchQuery,
    template,
    onCancel,
    onSubmit,
}) => {
    const { update } = useMutationServiceJobTemplate({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    const initialValues = {
        ...template,
    };

    const onUpdate = async (values: typeof initialValues) => {
        try {
            await update({
                id: template.id,
                templateTitle: values.templateTitle?.trim() || '',
                providedTitle: values.templateTitle?.trim() || '',
                templateDescription: values.templateDescription?.trim() || '',
                requiresAddress: Boolean(values.requiresAddress),
                requiresContact: Boolean(values.requiresContact),
                requiresCustomer: Boolean(values.requiresCustomer),
                requiresPool: Boolean(values.requiresPool),
                requiresStaff: Boolean(values.requiresStaff),
                group: values.group?.id,
                canBeRecurring: values.canBeRecurring,
            });
            await onSubmit?.();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    // const providedTitleLabel = (
    //     <span tw={'font-medium flex flex-col'}>
    //         <b>Custom Title</b>
    //         <span tw={'italic mt-1'}>
    //             The value of this field will be used by default when creating a new service case. Leave it blank to use
    //             'Template Title' as the default value.
    //         </span>
    //     </span>
    // );
    //
    return (
        <FormikDefaultForm
            header={'Service Template'}
            initialValues={template}
            validationSchema={validationSchema}
            onSubmit={onUpdate}
            onCancel={onCancel}
            submitOnEnter={false}
            debug={true}
        >
            <FormikInputField label={'Template Title'} name={'templateTitle'} required={true} />
            {/*<FormikInputField label={providedTitleLabel} name={'providedTitle'} />*/}
            <FormikServiceJobGroupSuggestInput name={'group'} label={'Template Group'} />
            <FormikTextareaField label={'Template Description'} name={'templateDescription'} />
            {/*<Divider />*/}
            {/*<h4>Required Fields</h4>*/}
            {/*<p>*/}
            {/*    <i>*/}
            {/*        When new service case is being created from this template, the following data will be required to be*/}
            {/*        filled*/}
            {/*    </i>*/}
            {/*</p>*/}
            {/*<FormikCheckboxField label={'Address is required'} name={'requiresAddress'} />*/}
            {/*<FormikCheckboxField label={'Pool is required'} name={'requiresPool'} />*/}
            {/*<FormikCheckboxField label={'Customer is required'} name={'requiresCustomer'} />*/}
            {/*<FormikCheckboxField label={'Contact is required'} name={'requiresContact'} />*/}
        </FormikDefaultForm>
    );
};

interface PanelServiceJobTemplateDetailsProps {
    template: NodeType.ServiceJobTemplate;
    onEdit?: () => any;
    canEdit: boolean;
    readOnly: boolean;
    isGlobal: boolean;
    refetchQuery: any;
}

const TemplateRestrictionsPanel: React.FC<PanelServiceJobTemplateDetailsProps> = ({
    template,
    canEdit,
    readOnly,
    refetchQuery,
}) => {
    const { update } = useMutationServiceJobTemplate({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    const onChange =
        (fieldName: keyof NodeType.UpdateServiceJobTemplateMutationInput) => async (event, data: CheckboxProps) => {
            try {
                await update({
                    id: template.id,
                    [fieldName]: data.checked,
                });
            } catch (e) {
                console.error(e);
                toastError(e);
            }
        };

    return (
        <Panel>
            <Panel.Header content={'Template'} />
            <Panel.Body>
                <Panel.Item label={'Published'}>
                    <Checkbox
                        size={'small'}
                        toggle={true}
                        checked={template.isPublished}
                        onChange={onChange('isPublished')}
                        disabled={!canEdit}
                    />
                </Panel.Item>
                <Panel.Item
                    label={'Recurrence'}
                    labelInfo={'Indicates whether this template is preferred for recurring services'}
                >
                    <Checkbox
                        size={'small'}
                        toggle={true}
                        checked={template.canBeRecurring}
                        onChange={onChange('canBeRecurring')}
                        disabled={!canEdit}
                    />
                </Panel.Item>
                {/*<Panel.Item label={'Address is required'} content={template.requiresAddress ? 'Yes' : 'No'} />*/}
                {/*<Panel.Item label={'Pool is required'} content={template.requiresPool ? 'Yes' : 'No'} />*/}
                {/*<Panel.Item label={'Customer is required'} content={template.requiresCustomer ? 'Yes' : 'No'} />*/}
                {/*<Panel.Item label={'Contact is required'} content={template.requiresContact ? 'Yes' : 'No'} />*/}
            </Panel.Body>
        </Panel>
    );
};

export const PanelServiceJobTemplateDetails: React.FC<PanelServiceJobTemplateDetailsProps> = (props) => {
    const { viewer, isAdmin } = useViewer();
    const { template, onEdit, canEdit, readOnly, isGlobal } = props;
    let button = undefined;
    if (readOnly) {
        button = undefined;
    } else if (!canEdit) {
        button = { content: 'Edit', icon: 'lock', disabled: true, popup: { content: 'Not enough permissions' } };
    } else if (onEdit) {
        button = { content: 'Edit', icon: 'edit', onClick: onEdit };
    }
    const isHQ = template?.franchise?.id !== viewer?.franchise?.id;

    return (
        <PageLayout.TwoColumns>
            <Panel>
                <Panel.Header content={'Service Job Template'} button={button} />
                <Panel.Body>
                    <AdminOnly>
                        <Panel.Item label={'Organisation'} content={template.organisationType?.name} color={'purple'} />
                    </AdminOnly>
                    {(isAdmin || isHQ) && <Panel.Item label={'Franchise'} content={template.franchise?.name} />}
                    {isGlobal && (
                        <Panel.Item label={'Template Ownership'} color={'purple'}>
                            System Template <Icon name={'globe'} />
                        </Panel.Item>
                    )}
                    <Panel.Item label={'Service Group'}>
                        <b tw={'text-purple-600'}>{template.group?.title}</b>
                    </Panel.Item>
                    <Panel.Divider />
                    <Panel.Item label={'Template Title'} content={template.templateTitle} />
                    {template.providedTitle && template.templateTitle != template.providedTitle && (
                        <Panel.Item
                            labelInfo={'This title will be used by default instead of "Template Title"'}
                            label={'Provided Title'}
                            content={template.providedTitle}
                        />
                    )}
                    <Panel.ItemText label={'Template Description'} content={template.templateDescription} />
                </Panel.Body>
            </Panel>
            <TemplateRestrictionsPanel {...props} />
        </PageLayout.TwoColumns>
    );
};

export const ServiceJobTemplateDetailsPanel: React.FC<ServiceJobTemplateDetailsPanelProps> = ({
    template,
    refetchQuery,
    canEdit,
    readOnly,
}) => {
    const modalCtr = useModalCtrl();

    const onEdit = canEdit ? modalCtr.onOpen : undefined;
    const isGlobal = !template.franchise?.id && !!template.organisationType?.id;

    return (
        <>
            {!!template.archivedAt && (
                <Message warning>
                    <Message.Header>
                        <Icon name="lock" /> This template has been archived!
                    </Message.Header>
                </Message>
            )}
            <PanelServiceJobTemplateDetails //
                isGlobal={isGlobal}
                template={template}
                onEdit={onEdit}
                canEdit={canEdit}
                readOnly={readOnly}
                refetchQuery={refetchQuery}
            />
            {modalCtr.open && (
                <Modal open={modalCtr.open} centered={false}>
                    <FormServiceJobTemplateDetails
                        onCancel={modalCtr.onClose}
                        onSubmit={modalCtr.onClose}
                        refetchQuery={refetchQuery}
                        template={template}
                        readOnly={readOnly}
                        canEdit={canEdit}
                    />
                </Modal>
            )}
        </>
    );
};
