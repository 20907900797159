import * as fromUser from './reducers/user';

export const userSelectors = {
    getIsAuthenticated: (state) => fromUser.getIsAuthenticated(state.pwapi),
    getIsAuthenticating: (state) => fromUser.getIsAuthenticating(state.pwapi),
    getUser: (state) => fromUser.getUser(state.pwapi),
    getAuthError: (state) => fromUser.getAuthError(state.pwapi),
    getFeatureAccess: (state) => fromUser.getFeatureAccess(state.pwapi),
    canAccessFeature: (state) => (feature: string): boolean => fromUser.canAccessFeature(state.pwapi)(feature),
    isAdmin: (state): boolean => fromUser.canAccessFeature(state.pwapi)('ADMIN_ACCESS'),
};
