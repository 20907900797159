import { bindActionCreators, combineReducers } from 'redux';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { createAction, createAutoBoundReducerActions, createReducer } from '@ez/tools';
import {
    ConnectionPageStateType,
    createPageIndexReducerActions,
    defaultConnectionPageState,
    NodeType,
} from '@poolware/api';
import { createStructuredSelector } from 'reselect';
import * as _ from 'lodash';
import { RECUR_FILTER } from './helpers';
import { useMemo } from 'react';

const SCOPE = '@ServiceJobList';

enum SJL_ACTION {
    CLEAR_ALL_FILTER = '@ServiceJobList/CLEAR_ALL_FILTER',
}

export enum IMPLICIT_FILTER {
    ANY,
    IMPLICITY_ONLY,
    NONINMPLICIT_ONLY,
}

export type ServiceJobListStateType = {
    filters: {
        franchise: { id?: string; name?: string };
        staffIds: string[];
        serviceStageId: string;
        serviceStageType: NodeType.ServiceJobStageType[];
        serviceStageStatus: NodeType.ServiceJobStageStatus;
        serviceGroupId: string;
        serviceJobTemplateId: string;
        workOrderNumber: string;
        serviceJobNumber: string;
        customerCRN: string;
        isRecurring: RECUR_FILTER;
        isImplicit: IMPLICIT_FILTER;
        hasActiveFilters: boolean;
    };
    page: ConnectionPageStateType;
};

const initialState: ServiceJobListStateType = {
    filters: {
        franchise: {},
        staffIds: [],
        serviceStageId: null,
        serviceStageType: [],
        serviceStageStatus: null,
        serviceGroupId: null,
        serviceJobTemplateId: null,
        workOrderNumber: null,
        serviceJobNumber: null,
        customerCRN: null,
        hasActiveFilters: false,
        isRecurring: RECUR_FILTER.ALL,
        isImplicit: IMPLICIT_FILTER.NONINMPLICIT_ONLY,
    },
    page: defaultConnectionPageState,
};

const FiltersReducerActions = createAutoBoundReducerActions(initialState.filters, SCOPE, {
    setFranchise: { field: 'franchise' },
    setStaffFilter: { field: 'staffIds' },
    setServiceStageId: { field: 'serviceStageId' },
    setServiceStageType: { field: 'serviceStageType' },
    setServiceStageStatus: { field: 'serviceStageStatus' },
    setServiceJobGroup: { field: 'serviceGroupId' },
    setServiceJobTemplate: { field: 'serviceJobTemplateId' },
    setWorkOrderNumber: { field: 'workOrderNumber' },
    setServiceJobNumber: { field: 'serviceJobNumber' },
    setCustomerCRN: { field: 'customerCRN' },
    setRecurFilter: { field: 'isRecurring' },
    setImplicitFilter: { field: 'isImplicit' },
});

const CustomActions = {
    clearAllFilters: () => createAction(SJL_ACTION.CLEAR_ALL_FILTER, undefined, SCOPE),
};

const PageIndexReducerActions = createPageIndexReducerActions({
    scope: SCOPE,
    resetIndexActionTypes: [...FiltersReducerActions.actionTypes, SJL_ACTION.CLEAR_ALL_FILTER],
    initialState: initialState.page,
});

const hasActiveFilters = (state: ServiceJobListStateType['filters']) => {
    return (
        !!state.customerCRN ||
        state.staffIds?.length > 0 ||
        !!state.serviceJobNumber ||
        !!state.workOrderNumber ||
        !!state.serviceJobTemplateId ||
        !!state.serviceGroupId ||
        !!state.serviceStageId
        // || !!state.serviceStageType
    );
};

const filtersReducer = createReducer(
    initialState.filters, //
    (state, action: any): ServiceJobListStateType['filters'] => {
        const reducer = FiltersReducerActions.reducers[action.type];
        if (reducer) {
            reducer(state, action.payload);
        } else {
            if (action.type === SJL_ACTION.CLEAR_ALL_FILTER) {
                // reset all fields except stage filter
                return {
                    ...initialState.filters,
                    serviceStageType: state.serviceStageType,
                    serviceStageStatus: state.serviceStageStatus,
                };
            }
        }
        state.hasActiveFilters = hasActiveFilters(state);
        return state;
    },
    SCOPE
);

export const reducerSJList = combineReducers({
    filters: filtersReducer,
    page: PageIndexReducerActions.reducer,
});

const _memoizedSelector = createStructuredSelector<ServiceJobListStateType, ServiceJobListStateType>({
    filters: (state) => state.filters,
    page: (state) => state.page,
});

export const useServiceJobListActions = () => {
    const state = useSelector((state: RootStateOrAny) => _memoizedSelector(state.serviceJob.serviceJobList));
    const dispatch = useDispatch();

    return useMemo(() => {
        const allActions = { ...FiltersReducerActions.actions, ...PageIndexReducerActions.actions, ...CustomActions };
        return {
            State: state,
            Actions: bindActionCreators(allActions, dispatch),
        };
    }, [state, dispatch]);
};

////

export const deserializeServiceJobList = (restoredState?: Partial<ServiceJobListStateType>) => {
    const state: ServiceJobListStateType = _.cloneDeep(initialState);

    if (!restoredState || _.isEmpty(restoredState)) {
        return state;
    }

    if (restoredState.filters) {
        state.filters.serviceStageStatus = restoredState.filters?.serviceStageStatus;
        state.filters.serviceStageType = restoredState.filters?.serviceStageType;
        state.filters.hasActiveFilters = hasActiveFilters(state.filters);
    }

    return state;
};

export const serializeServiceJobList = (state: ServiceJobListStateType) => {
    return _.pick(state, ['filters.serviceStageType', 'filters.serviceStageStatus']);
};
