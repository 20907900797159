import * as React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { Field, useFormikContext } from 'formik';

import { FormikFormFieldLabel, stringFormatters, useModalCtrl } from '@poolware/components';
import { NodeType } from '@poolware/api';
import CustomerPicker from './Pickers/CustomerPicker';

interface CustomerFieldProps {
    name: string;
    label?: string;
    // onPoolSelect?: (pool: NodeType.Pool) => any;
    required?: boolean;
    onRemove?: () => any;
    readOnly?: boolean;
}

export const FormikCustomerLookupField: React.FC<CustomerFieldProps> = ({
    name,
    label,
    required,
    onRemove,
    readOnly,
}) => {
    const { setFieldValue } = useFormikContext();

    const modalCtrlSearch = useModalCtrl(false);
    const modalCtrlNew = useModalCtrl();

    const onNewCustomer = () => {
        modalCtrlSearch.onClose();
        modalCtrlNew.onOpen();
    };

    const onPoolSelect = (pool: NodeType.Pool) => {
        setFieldValue('pool', pool);
        if (pool && pool.address) {
            setFieldValue('address', pool.address);
        } else {
            setFieldValue('address', null);
        }
    };

    return (
        <>
            <Field name={name}>
                {({ field, form }) => {
                    const labelComp = <FormikFormFieldLabel label={label} name={name} required={required} />;

                    const handleRemove = () => {
                        form.setFieldValue(field.name, null);
                        onRemove?.();
                    };

                    const handlePickerSubmit = ({ customer, pool }) => {
                        modalCtrlSearch.onClose();

                        // remove previous customer first.
                        handleRemove();
                        // set new customer;
                        form.setFieldValue(field.name, customer);
                        // if Pool is provided, set pool as well
                        onPoolSelect(pool);
                    };

                    const openPicker = modalCtrlSearch.onOpen;

                    const inputStyle = readOnly
                        ? { cursor: 'not-allowed', backgroundColor: '#f8f8f8' }
                        : { cursor: 'pointer', backgroundColor: '#f8f8f8' };

                    return (
                        <>
                            <Form.Input
                                value={stringFormatters.formatEntityName(field.value, '--')}
                                type={'text'}
                                label={labelComp}
                                readOnly={true}
                                action
                                // size={'mini'}
                            >
                                <input
                                    style={inputStyle}
                                    disabled={readOnly}
                                    onClick={!readOnly ? openPicker : undefined}
                                />
                                {field.value ? (
                                    <Button
                                        disabled={readOnly}
                                        onClick={handleRemove}
                                        type={'button'}
                                        basic={true}
                                        icon={'delete'}
                                    />
                                ) : (
                                    <Button
                                        disabled={readOnly}
                                        type={'button'}
                                        icon={'address book outline'}
                                        content={'Find'}
                                        onClick={openPicker}
                                    />
                                )}
                            </Form.Input>
                            <Modal closeOnDimmerClick={true} size={'small'} {...modalCtrlSearch}>
                                <CustomerPicker
                                    onCancel={modalCtrlSearch.onClose}
                                    onSubmit={handlePickerSubmit}
                                    // onNewCustomer={onNewCustomer}
                                />
                            </Modal>
                            <Modal closeOnDimmerClick={false} size={'small'} {...modalCtrlNew}>
                                <CustomerPicker onCancel={modalCtrlNew.onClose} onSubmit={handlePickerSubmit} />
                            </Modal>
                        </>
                    );
                }}
            </Field>
        </>
    );
};
