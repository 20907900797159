import { Segment } from 'semantic-ui-react';
import * as React from 'react';
import { Field } from 'formik';
import {
    FormikCheckboxField,
    FormikFormFieldLabel,
    FormikInputField,
    IconWithPopup,
    SectionHeader,
} from '@poolware/components';
import { FormikSuggestBrandInput, TraitDeclarationPickerSelect } from '@poolware/app-catalog';

export interface NewProductInputFieldsProps {
    name: string;
    onCancel: () => any;
}

const TraitDeclarationField: React.FC<{ label; name; required }> = ({ label, name, required }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const onChange = (newValue) => {
                    form.setFieldValue(field.name, newValue);
                };

                const value = field.value;
                // const touched = getIn(form.touched, name);
                // const error = getIn(form.errors, name);
                //let hasError = Boolean(touched && !!error);
                const labelComp = <FormikFormFieldLabel htmlFor={name} label={label} name={name} required={required} />;

                return (
                    <TraitDeclarationPickerSelect
                        label={labelComp}
                        value={value}
                        onChange={onChange}
                        rootTag={'equipment'}
                    />
                );
            }}
        </Field>
    );
};

export const NewProductInputFields: React.FC<NewProductInputFieldsProps> = (props) => {
    const nn = (n) => {
        return `${props.name}.${n}`;
    };

    return (
        <Field name={props.name}>
            {({ field, form }) => {
                return (
                    <>
                        <SectionHeader
                            button={{ onClick: props.onCancel, icon: 'close', color: 'purple', content: '' }}
                        >
                            Create New Product
                        </SectionHeader>
                        <Segment.Group>
                            <Segment compact>
                                <FormikInputField
                                    required={true}
                                    name={nn('name')}
                                    autoComplete={'off'}
                                    label="Product Name"
                                />

                                <TraitDeclarationField
                                    label={'Product Type'}
                                    name={nn('declaration')}
                                    required={false}
                                />

                                <FormikSuggestBrandInput name={nn('brand')} label={'Brand'} />

                                {/*Note: 'isOneOff' is in the root of the form values object, i.e. don't prefix with nn() method */}
                                <span style={{ display: 'flex', flexDirection: 'row' }}>
                                    <FormikCheckboxField name={'isGlobal'} label="Add to Product Catalog" />

                                    <div style={{ paddingLeft: '0.5em' }}>
                                        <IconWithPopup
                                            name={'info circle'}
                                            color={'grey'}
                                            popup={{
                                                content:
                                                    'If checked, the new product will be also added to the Product Catalog for future reuse.',
                                            }}
                                        />
                                    </div>
                                </span>
                            </Segment>
                        </Segment.Group>
                    </>
                );
            }}
        </Field>
    );
};
