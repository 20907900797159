import * as React from 'react';

import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { createAutocompleteEditableItem } from '../../../components/create-autocomplete-editable-item';
import { SuggestBrandInput, SuggestProductCompanyInput, SuggestSupplierInput } from '../../../components-api-connected';
import { SuggestInputFranchise } from '@poolware/app-shell';

export const BrandEditableItem = createAutocompleteEditableItem<NodeType.Brand>({
    inputComp: SuggestBrandInput,
    renderItem: (item: NodeType.Brand) => {
        const brandName = _.get(item, 'name');
        const companyName = _.get(item, 'company.name');
        return (
            <>
                {companyName ? (
                    <>
                        <span style={{ color: '#888' }}>{companyName} </span>
                        {' > '} {brandName}
                    </>
                ) : (
                    <>{brandName}</>
                )}
            </>
        );
    },
});

export const SuplierEditableItem = createAutocompleteEditableItem({
    inputComp: SuggestSupplierInput,
    renderItem: (value) => _.get(value, 'name'),
});

export const ProductCompanyEditableItem = createAutocompleteEditableItem({
    inputComp: SuggestProductCompanyInput,
    renderItem: (value) => _.get(value, 'name'),
});

export const FranchiseEditableItem = createAutocompleteEditableItem({
    inputComp: SuggestInputFranchise,
    renderItem: (value) => _.get(value, 'name'),
});
