import * as React from 'react';
import { useFormikContext } from 'formik';
import { BrandingType, CalcDocType, FormikHeatCalcValueType } from './types';
import { useCatalog } from './use-catalog';
import { EquipmentListTable as EquipmentListTableHeatPump } from '../heat-pump-calculators/EquipmentListTable';
import { EquipmentListTable as EquipmentListTableGasHeater } from '../gas-heater-calculators/EquipmentListTable';
import { DevOnly, Panel } from '@poolware/components';

export const FormikEquipmentOptionsTable: React.FC = () => {
    const { setFieldValue, values } = useFormikContext<FormikHeatCalcValueType>();
    const { gasHeaters, heatPumps } = useCatalog();

    if (values.docType === CalcDocType.GasHeater) {
        const selectedHeater = values.userSelectedModel?.gas;
        const autoRecommendedHeater = values.autoRecommendedModel?.gas;
        const onSelectGas = (newSelectedHeater) => {
            setFieldValue('userSelectedModel.gas', newSelectedHeater);
        };
        return (
            <Panel>
                <Panel.Header basic={true}>Gas Heaters</Panel.Header>
                <EquipmentListTableGasHeater
                    onSelect={onSelectGas}
                    autoRecommendedHeater={autoRecommendedHeater}
                    heaterCatalog={gasHeaters.models}
                    userSelectedHeater={selectedHeater || autoRecommendedHeater}
                />
            </Panel>
        );
    }
    if (values.docType === CalcDocType.HeatPump || values.docType === CalcDocType.HeatPumpAutopool) {
        const selectedHeater = values.userSelectedModel?.hpump;
        const autoRecommendedHeater = values.autoRecommendedModel?.hpump;
        const onSelectHP = (newSelectedHeater) => {
            setFieldValue('userSelectedModel.hpump', newSelectedHeater);
        };
        return (
            <Panel>
                <Panel.Header basic={true}>Heat Pumps</Panel.Header>
                <EquipmentListTableHeatPump
                    onSelect={onSelectHP}
                    autoRecommendedHeater={autoRecommendedHeater}
                    heaterCatalog={heatPumps.models}
                    userSelectedHeater={selectedHeater || autoRecommendedHeater}
                />
            </Panel>
        );
    }
    return <DevOnly>Unknown doc type</DevOnly>;
};
