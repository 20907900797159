import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { CalendarActionProps, withCalendarActions } from '../../redux';
import { Icon, Popup } from 'semantic-ui-react';
import { TopFilterBar } from '../../components/TopFilterBar';
import { BarProgress, DevOnly } from '@poolware/components';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';

const DebugAppLayoutMode = () => {
    const layout = useCalendarLayoutState();
    return (
        <DevOnly>
            {layout.appLayoutMode.mode} {layout.layoutColumns}
        </DevOnly>
    );
};

const ActiveFiltersWarning = styled.div`
    color: #b44dd2;
    user-select: none;
`;

const getTimezoneName = () => {
    try {
        if (!Intl) {
            return null;
        }
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
        // console.error(e);
        return null;
    }
};

const StyledDiv = styled.div(() => [
    tw`text-sm rounded print:hidden shadow h-8 pt-0 mt-2 overflow-hidden`,
    tw`bg-gradient-to-r from-gray-50 via-blue-50 to-gray-100`,
    tw`bg-gray-300 border border-gray-200 border-solid`,
]);

const CalendarInfo: React.FC<CalendarActionProps & { isLoading?: boolean }> = ({
    CalendarState,
    CalendarAction,
    isLoading,
}) => {
    if (!CalendarState) {
        return null;
    }
    const filters = CalendarState.filters;

    const { hasActiveFilters } = filters;

    const timeZone = getTimezoneName();

    return (
        <StyledDiv>
            <BarProgress style={{ marginTop: '0px' }} isLoading={isLoading} />

            <div tw={'flex justify-between items-center pb-1 px-2'}>
                {hasActiveFilters && (
                    <div tw={'flex flex-row flex-wrap gap-4 text-sm h-6 items-center'}>
                        <TopFilterBar.SummaryFilterIcon />

                        <ActiveFiltersWarning>
                            <Popup
                                size={'small'}
                                trigger={
                                    <span>
                                        <Icon name={'warning sign'} /> Active Filters
                                    </span>
                                }
                            >
                                <Popup.Content>
                                    You have active filters.
                                    <br />
                                    Not all appointments may be visible.
                                </Popup.Content>
                            </Popup>
                        </ActiveFiltersWarning>

                        <>
                            {filters.excludeAppointmentGroupsIds?.length > 0 && (
                                <TopFilterBar.SummaryItem>Group</TopFilterBar.SummaryItem>
                            )}
                        </>
                        <>{!!filters.isRecurring && <TopFilterBar.SummaryItem>Recur</TopFilterBar.SummaryItem>}</>
                        <>{!!filters.teamId && <TopFilterBar.SummaryItem>Team</TopFilterBar.SummaryItem>}</>
                        <>
                            {!!filters.showWithAddressOnly && (
                                <TopFilterBar.SummaryItem>Address</TopFilterBar.SummaryItem>
                            )}
                        </>

                        <>
                            {filters.staffIds?.length > 0 && <TopFilterBar.SummaryItem>Staff</TopFilterBar.SummaryItem>}
                        </>
                        <>
                            {filters.includeStatuses.length > 0 && (
                                <TopFilterBar.SummaryItem>Status</TopFilterBar.SummaryItem>
                            )}
                        </>
                        <>
                            {(!!filters.serviceGroupId || !!filters.serviceJobTemplateId) && (
                                <TopFilterBar.SummaryItem>Service Type</TopFilterBar.SummaryItem>
                            )}
                        </>
                        <TopFilterBar.SummaryClearButton onClick={CalendarAction.clearAllFilters} />
                    </div>
                )}
                <div tw={'flex-grow'} />
                <div tw={'pr-2'}>
                    {!!filters.autoHideEmptyResources && (
                        <span tw={'text-sm text-purple-500'}>Empty columns hidden</span>
                    )}
                </div>

                {/*<DebugAppLayoutMode />*/}
                {timeZone && <div tw={'text-gray-500 text-sm text-right'}>Timezone: {timeZone}</div>}
            </div>
        </StyledDiv>
    );
};
export default withCalendarActions()(CalendarInfo);
