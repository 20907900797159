import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Icon } from 'semantic-ui-react';

export interface TagOwnershipLabelProps {
    franchise?: NodeType.Franchise;
}

export const TagOwnershipLabel: React.FC<TagOwnershipLabelProps> = ({ franchise }) => {
    return (
        <>
            {franchise ? (
                <>
                    <Icon name={'building'} />
                    {franchise?.name}
                </>
            ) : (
                <>
                    <Icon name={'globe'} />
                    System Global
                </>
            )}
        </>
    );
};
