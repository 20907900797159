import * as React from 'react';
import { compose, mapProps } from '@ez/tools';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import gql from 'graphql-tag';
import { withApolloLoading } from '@poolware/components';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';
import { graphql } from 'react-apollo';
import ViewCompany from './ViewCompany';

const QL = gql`
    query ProductCompanyDetails($id: ID!) {
        node(id: $id) {
            ... on ProductCompany {
                id
                name
                createdAt
                updatedAt
                franchise {
                    id
                    name
                }
                description
                parentCompany {
                    id
                    name
                }
            }
        }
    }
`;

export default compose(
    withAppNavigator(),
    graphql(QL, {
        options: (props: IAppNavigatorProps) => {
            return {
                variables: { id: props.params.id },
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.node.id' }),
    mapProps((props) => {
        const company = _.get(props, 'data.node');
        return {
            ...props,
            company,
        };
    }),
    withProductCatalogMutators(['ProductCompanyDetails'])
)(ViewCompany);

export interface PageControlProps extends IAppNavigatorProps, IProductCatalogMutators {
    company: NodeType.ProductCompany;
}
