import * as React from 'react';
import { useState } from 'react';
import { Panel, toastError } from '@poolware/components';
import { fromEdges, NodeType } from '@poolware/api';
import { Button, Divider, List } from 'semantic-ui-react';
import * as _ from 'lodash';
import { findTreeDescendants, unflattenToTree } from '../../../utils';
import { TreeRenderer } from '../../../components/TreeRenderer';
import { queryNames } from '../query-names';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../queries';

const BulkTraitRemove: React.FC<PageControlProps> = ({ ProductCatalogMutator, products, onFinish }) => {
    const productCount = products.length;

    const [isSubmitting, setSubmitting] = useState(false);
    const [declarationToDelete, setDeclarationToDelete] = useState(null);
    const [declarationsToDelete, setDeclarationsToDelete] = useState([]);

    const productTraits = products.flatMap((p) =>
        fromEdges(p.traits).map((t) => ({
            product: p,
            productId: p.id,
            traitId: t.id,
            declarationId: _.get(t, 'declaration.id'),
            declarationName: _.get(t, 'declaration.name'),
            id: _.get(t, 'declaration.id'),
            name: _.get(t, 'declaration.name'),
            parent: _.get(t, 'declaration.parent'),
        }))
    );
    const uniqueDeclarationTraits = _.uniqBy(productTraits, 'declarationId');

    const tree = unflattenToTree(uniqueDeclarationTraits);

    const productsAffected = _.uniqBy(
        productTraits.filter((pt) => {
            return declarationsToDelete.includes(pt.id);
        }),
        'productId'
    );

    const canUpdate = productsAffected.length > 0;

    const itemRenderer = (pd: NodeType.ProductTraitDeclaration) => {
        const isToDelete = declarationsToDelete.includes(pd.id);
        const isSelected = declarationToDelete === pd.id;

        return (
            <List.Item>
                <List.Content>
                    <span>
                        <span style={isToDelete ? { textDecoration: 'line-through' } : null}>{pd.name}</span>{' '}
                        {!isSelected && !isToDelete && (
                            <a href={'#'} onClick={handleToggle({ id: pd.id, toRemove: true })}>
                                Remove
                            </a>
                        )}{' '}
                        {isSelected && (
                            <a href={'#'} onClick={handleToggle({ id: pd.id, toRemove: false })}>
                                Keep
                            </a>
                        )}
                    </span>
                </List.Content>
            </List.Item>
        );
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        try {
            await await ProductCatalogMutator.deleteProductTraits(productsAffected.map((pt) => pt.traitId));
            setSubmitting(false);
            onFinish();
        } catch (e) {
            setSubmitting(false);
            console.error(e);
            toastError({ title: 'Failed to Update', description: e.message });
        }
    };

    const handleToggle = (input: { id: NodeType.ID; toRemove: boolean }) => (e) => {
        e.preventDefault();

        const { id, toRemove } = input;
        if (toRemove) {
            setDeclarationToDelete(id);
            const idsToRemove = findTreeDescendants(tree, id).map((i) => i.id);
            setDeclarationsToDelete((items) => {
                return [...idsToRemove];
            });
        } else {
            setDeclarationToDelete(null);
            setDeclarationsToDelete([]);
        }
    };

    return (
        <Panel>
            <Panel.Header content={`Bulk edit ${productCount} products`} />
            <Panel.Body>
                <Panel.Item label={'Traits'}>
                    <TreeRenderer tree={tree} itemRenderer={itemRenderer} />
                </Panel.Item>
                <Divider />
                <Panel.Item label={'Products To Update'}>
                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        <List size={'small'}>
                            {productsAffected.length === 0 && '--'}
                            {productsAffected.map((p, i) => {
                                return <List.Item key={i}>{p.product.name}</List.Item>;
                            })}
                        </List>
                    </div>
                </Panel.Item>
            </Panel.Body>
            <Panel.Footer>
                <Button primary type="button" basic disabled={isSubmitting} floated={'left'} onClick={onFinish}>
                    Cancel
                </Button>

                <Button
                    primary
                    type={'submit'}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                    disabled={isSubmitting || !canUpdate}
                    content={`Update ${productsAffected.length} products`}
                    floated={'right'}
                />
            </Panel.Footer>
        </Panel>
    );
};

export default withProductCatalogMutators(queryNames)(BulkTraitRemove) as React.ComponentType<ExternalProps>;

export interface ExternalProps {
    products: NodeType.Product[];
    onFinish: () => any;
}

export interface PageControlProps extends IProductCatalogMutators, ExternalProps {}
