import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { getAppConfig } from '../app-config';

const conf = getAppConfig();

const getScriptTags = () => {
    let scripst = [];
    if (conf.hotjar.enabled) {
        scripst.push({ src: '/hotjar.js', type: 'text/javascript', async: true });
    }
    return scripst;
};

export const AppHelmet: React.FC = () => {
    return <Helmet script={getScriptTags()} />;
};
