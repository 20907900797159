import * as React from 'react';
import { DetailsViewContainer, LeftSidebarContainer, PageContainer } from './page-layout';
import { Menu } from 'semantic-ui-react';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRootSwitch,
    ModuleRoute,
} from '@poolware/react-app-navigator';
import VendIntegration from './VendIntegration';
import WaterlinkIntegration from './WaterlinkIntegration';
import { useViewer } from '@poolware/app-shell';
import { MenuBar, MenuBarHeaderItem, MenuBarSection, VStack } from '@poolware/components';

const Integrations: React.FC = () => {
    const { modulesAccess } = useViewer();
    const canAccessVend = modulesAccess.VendIntegration?.admin;
    const canAccessWaterlink = modulesAccess.WaterlinkIntegration?.enabled;
    const defaultRedirect = canAccessVend ? '/vend' : canAccessWaterlink ? '/waterlink' : '/noaccess';
    return (
        <ModuleRoot>
            <VStack>
                <MenuBar>
                    <MenuBarSection>
                        <MenuBarHeaderItem icon={'puzzle'}>Integrations</MenuBarHeaderItem>
                    </MenuBarSection>
                </MenuBar>
                <PageContainer>
                    <LeftSidebarContainer>
                        <Menu pointing secondary vertical style={{ width: '100%' }} color={'blue'}>
                            {canAccessVend && (
                                <Menu.Item to={'/vend'} as={ModuleNavLink}>
                                    Vend
                                </Menu.Item>
                            )}
                            {canAccessWaterlink && (
                                <Menu.Item to={'/waterlink'} as={ModuleNavLink}>
                                    Waterlink
                                </Menu.Item>
                            )}
                        </Menu>
                    </LeftSidebarContainer>

                    <DetailsViewContainer>
                        <ModuleRootSwitch>
                            <ModuleRedirect path={'/'} exact to={defaultRedirect} />
                            <ModuleRoute path={'/vend'} component={VendIntegration} />
                            <ModuleRoute path={'/waterlink'} component={WaterlinkIntegration} />
                            <ModuleRoute path={'/noaccess'} component={() => <>No access</>} />
                        </ModuleRootSwitch>
                    </DetailsViewContainer>
                </PageContainer>
            </VStack>
        </ModuleRoot>
    );
};

export default Integrations;
