export interface CalEvent {
    title: string;
    start: Date;
    end: Date;
    allDay?: boolean;
    resource?: any;
    item: any;
}

export enum ACTION_NOTIFICATION {
    'reset' = 'reset',
    'click' = 'click',
    'doubleClick' = 'doubleClick',
    'beforeSelect' = 'beforeSelect',
    'selectStart' = 'selectStart',
    'selected' = 'selected',
    'selecting' = 'selecting',
    'dropFromOutside' = 'dropFromOutside',
    'dragOverFromOutside' = 'dragOverFromOutside',
    'dragEnterFromOutside' = 'dragEnterFromOutside',
    'dragLeaveFromOutside' = 'dragLeaveFromOutside',
}

export enum CalNavigate {
    PREVIOUS = 'PREV',
    NEXT = 'NEXT',
    TODAY = 'TODAY',
    DATE = 'DATE',
}

export enum CalViewMode {
    MONTH = 'month',
    WEEK = 'week',
    WORK_WEEK = 'work_week',
    DAY = 'day',
    AGENDA = 'agenda',
}
