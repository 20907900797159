import { Button, Input, Table } from 'semantic-ui-react';
import * as React from 'react';
import { useState } from 'react';
import * as _ from 'lodash';
import { toastError } from '@poolware/components';
import { getIn, useFormikContext } from 'formik';

interface TableRowAddItemProps {
    onAppendItem: (input: { title: string }) => any;
    colSpan: number;
    name: string;
}

export const TableRowAddNew = React.forwardRef<any, TableRowAddItemProps>(({ name, onAppendItem, colSpan }, ref) => {
    const [isMutating, setMutating] = useState(false);
    // const [inputValue, setInputValue] = useState('');
    const { setFieldValue, values } = useFormikContext();

    const inputValue = getIn(values, name, '');

    const setInputValue = (newValue: string) => {
        setFieldValue(name, newValue);
    };

    const handleOnChange = (e, { value }) => {
        !isMutating && setFieldValue(name, value);
    };

    const canAdd = _.trim(inputValue).length > 0;

    const handleOnAddNew = async () => {
        if (!canAdd) {
            return;
        }
        const title = inputValue;
        try {
            setMutating(true);
            setInputValue('');
            await onAppendItem({ title: title });
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to add', description: e.message });
            // revert back
            setInputValue(title);
        }
        setMutating(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleOnAddNew();
        }
    };

    return (
        <Table.Row>
            <Table.HeaderCell width={'1'} textAlign={'right'} style={{ width: '10' }}>
                {/*<Icon name={"plus"}/>*/}
            </Table.HeaderCell>
            <Table.HeaderCell colSpan={colSpan - 1}>
                <Input
                    ref={ref}
                    fluid={true}
                    className={'input'}
                    name={'title'}
                    value={inputValue}
                    onKeyDown={handleKeyDown}
                    onChange={handleOnChange}
                    action={
                        <Button loading={isMutating} disabled={!canAdd} content={'+Add'} onClick={handleOnAddNew} />
                    }
                />
            </Table.HeaderCell>
        </Table.Row>
    );
});
