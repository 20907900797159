import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { confirmModalImperative, Display, MenuButtonItemConf } from '@poolware/components';
import styled from 'styled-components';
import { DiscussionMessage } from './types';

const EventContainerDiv = styled.div<{ backgroundColor?: string }>`
    display: flex;
    flex-direction: column;
    border: 1px solid #d2d2d2;
    margin: 0 0 0.75em 0.5em;
    padding: 0.5em;
    border-radius: 5px;
    background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
    position: relative;

    &:before {
        content: '';
        position: absolute;
        border-style: solid;
        display: block;
        width: 0;
        top: 10px;
        bottom: auto;
        left: -6px;
        border-width: 6px 6px 6px 0;
        border-color: transparent #d0d0d0;
    }

    &:after {
        content: '';
        position: absolute;
        border-style: solid;
        display: block;
        width: 0;
        top: 10px;
        bottom: auto;
        left: -5px;
        border-width: 6px 6px 6px 0;
        border-color: transparent ${({ backgroundColor }) => backgroundColor || 'white'};
    }
`;

const EventHeader = styled.div`
    display: grid;
    grid-template-columns: 36px 1fr 50px;
    grid-template-areas: 'avatar content action';
    align-items: center;
    margin-bottom: 0.5em;
`;

const HeaderAvatar = styled.div`
    grid-area: avatar;
    align-self: center;
    justify-self: start;
`;

const HeaderContent = styled.div`
    grid-area: content;
    align-self: center;
`;
const HeaderAction = styled.div`
    grid-area: action;
    justify-self: end;
`;

const EventContent = styled.div`
    //font-size: 0.9em;
    //display: inline-block;
    //color: #818181;
    font-size: 0.9em;
    border-top: 1px solid #d2d2d2;
    padding: 0.25em 0.5em;
`;

const EventUser = styled.div`
    font-size: 0.9em;
    display: inline-block;

    //color: #818181;
`;

const EventDate = styled.div`
    font-size: 0.9em;
    display: inline-block;
    padding-left: 1em;
    color: #818181;
`;

export const FeedEvent: React.FC<{
    message: DiscussionMessage;
    onEdit: (message: DiscussionMessage) => any;
    onDelete: (message: DiscussionMessage) => any;
}> = ({ message, onEdit, onDelete }) => {
    if (!message) {
        return null;
    }
    const handleDelete = async (message) => {
        const answer = await confirmModalImperative({
            confirmMessage: {
                header: `Delete Note?`,
                content: 'This cannot be undone!',
            },
            confirmButton: {
                content: 'Delete',
                icon: 'trash',
                negative: true,
            },
        });

        if (answer) {
            await onDelete(message);
        }
    };

    const menuItems: MenuButtonItemConf[] = [
        {
            key: 1,
            name: 'Edit',
            icon: 'edit',
            onClick: () => onEdit(message),
        },
        {
            key: 2,
            name: 'Delete',
            icon: 'trash',
            onClick: handleDelete,
        },
    ];

    const backgroundColor = message.isUnread ? 'hsl(210, 60%, 96%)' : 'hsl(210, 20%, 98%)';
    return (
        <EventContainerDiv backgroundColor={backgroundColor}>
            <EventHeader>
                <HeaderAvatar>
                    <Icon name={'spy'} color={message.isUnread ? 'blue' : undefined} circular={true} />
                </HeaderAvatar>
                <HeaderContent>
                    <EventUser>
                        {/*<StaffLink id={message?.addedBy?.id}>*/}
                        <strong>
                            <Display.Entity value={message?.addedBy} />
                        </strong>
                        {/*</StaffLink>*/}
                    </EventUser>
                    <EventDate>
                        <Display.Date value={message?.createdAt} />{' '}
                        {message.isUnread && <Icon name={'mail'} color={'blue'} />}
                    </EventDate>
                </HeaderContent>
            </EventHeader>
            <EventContent>
                <Display.TextShortened value={message?.message} />
            </EventContent>
        </EventContainerDiv>
    );
};
