import * as React from 'react';
import { useMemo } from 'react';
import { useWindowSize } from '@ez/tools';
import tw, { css, styled } from 'twin.macro';
import { SlideInDock } from '@poolware/components';
import { useCalendarLayoutState } from '../utils/use-calendar-layout-state';

export const PAGE_BOTTOM_OFFSET_GRID = 76;
export const PAGE_BOTTOM_OFFSET_CAL = PAGE_BOTTOM_OFFSET_GRID + 34;
const TEMPLATE_M = 'minmax(8em, 1fr)';
const TEMPLATE_L_M = '240px minmax(8em, 1fr)';
const TEMPLATE_L_M_R_WIDE = '230px minmax(8em, 1fr) 36em';
const TEMPLATE_L_M_R_MOB = '230px minmax(8em, 1fr) 30em';
const TEMPLATE_M_R_WIDE = 'minmax(8em, 1fr) 36em';
const TEMPLATE_M_R_MOB = 'minmax(8em, 1fr) 28em';

export const CalendarContainerGrid = styled.div(() => [
    tw`gap-2`,
    css`
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: stretch;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;
        grid-template-rows: auto;
        @media print {
            display: block;
        }
    `,
]);

export const SidebarContainer = styled.div<{ darker?: boolean; maxHeight?: number }>(({ darker, maxHeight }) => [
    tw`shadow rounded bg-gray-100 border-0 z-10`,
    css`
        max-height: ${maxHeight}px;
    `,
    darker && tw`bg-gradient-to-b from-indigo-50 via-blue-50 to-gray-100`,
]);

const eventTextColor = '#202020';
const eventTextColorInverted = '#f9ffde';

export const CalendarViewContainer = styled.div`
    @keyframes highlight {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.3);
        }
    }

    .pw-scheduler {
        user-select: none;
        background-color: inherit;

        .rbc-day-slot {
            .rbc-time-slot {
                &.workingHours {
                    background-color: #fffefa;
                    border-top: 1px solid #f3f3f3;
                }

                &.nonWorkingHours {
                    background-color: #ececec;
                    border-top: 1px solid #fafafa;
                }
            }
        }

        .table-cont,
        .rbc-time-header,
        .rbc-time-content,
        .rbc-time-view {
            background-color: white !important;
        }

        .rbc-event {
            &.highlighted-pop,
            &.highlighted {
                border: 2px solid #ff191e;
                border-radius: 10px;
                z-index: 1;
            }

            &.highlighted-pop {
                animation: highlight 250ms ease-out 2 alternate;
            }

            &.selected {
                border: 4px solid #ff191e;
                border-radius: 6px;
                box-shadow: 0 0 4px 1px rgb(255 6 6 / 72%);
            }
        }

        .rbc-addons-dnd {
            &.rbc-addons-dnd-is-dragging {
                .rbc-event.rbc-addons-dnd-drag-preview {
                    border: 2px solid #ff4348;
                    border-radius: 2px;
                }

                /// "rbc-addons-dnd-dragged-event" class is set in dragAndDrop/EventWrapper.tsx
                /// "rbc-addons-dnd-dragged-event" class is set in dragAndDrop/EventContainerWrapper.tsx
                .rbc-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview) {
                    /// "density-xxx" class is set in CalendarEventAppItem.tsx
                    .density-normal {
                        .pw-item-extra {
                            display: none;
                        }
                    }

                    .density-reduced {
                        .pw-item {
                            display: none;
                        }
                    }

                    .density-low {
                        display: none;
                    }
                }
            }
        }

        .rbc-current-time-indicator {
            background-color: red !important;
            right: 2px !important;
            height: 2px;

            &::before {
                display: block;

                position: absolute;
                left: -3px;
                top: -3px;

                content: ' ';
                background-color: red;

                border-radius: 50%;
                width: 8px;
                height: 8px;
            }
        }

        .pw-dropdown-padding-fix.ui.labeled.icon.button {
            padding-left: 3em !important;
            white-space: nowrap;
            text-overflow: clip;
        }

        .pw-calendar-event {
            padding: 1px;
            display: flex;
            height: 100%;

            line-height: 1em;

            flex-direction: column;
            position: relative;

            &.popover {
                width: auto;
            }

            &.horizontal {
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: stretch;
            }

            // default text color
            color: ${eventTextColor};

            * > a {
                color: ${eventTextColor};
            }

            &.dimmed {
                color: #404040;
            }

            &.highlighted {
                color: #000000;
            }

            // text color for black background (e.g. when in popover)
            &.inverted {
                color: ${eventTextColorInverted};

                * > a {
                    color: ${eventTextColorInverted};
                }
            }

            .pw-item {
                font-size: 0.8em;
                padding: 1px 2px;
                line-height: 1.2em;

                &.pw-time {
                    font-weight: bold;
                    font-color: #3b3b3b;
                    //margin-top: -4px;
                }

                &.pw-customer {
                    & > a {
                        //color: #202020;
                    }

                    text-decoration: underline;
                }

                &.pw-job {
                    color: #5e0a73;
                }

                &.pw-address {
                    margin-top: 4px;
                    font-size: 0.7em;
                }

                .pw-note {
                    background-color: ivory;
                    border: 1px solid #bfbfbf;
                    padding: 2px;
                    margin: 0;
                    border-radius: 2px;
                    color: black;

                    p {
                        line-height: 1em;
                        font-size: 0.9em;
                    }

                    max-height: 140px;
                    overflow: auto;

                    &.preview {
                        background-color: rgba(0, 0, 0, 0.1);
                        border: unset;
                        margin-top: 2px;
                        padding-left: 4px;
                        padding-right: 4px;
                        //border-left: 1px solid white;
                            //color: ${eventTextColor};
                    }
                }

                &-icons {
                    color: #2a63a1;
                    position: absolute;
                    background-color: rgba(255, 255, 255, 0.75);
                    border-radius: 3px;
                    right: 1px;
                    top: 1px;
                    padding: 0.125em 0.25em;
                    overflow: visible;
                    display: flex;
                    flex-direction: row;
                    gap: 2px;
                    text-align: center;
                    height: 1.5em;

                    & i.icon {
                        text-align: center;
                        margin: 0;
                    }
                }
            }
        }
    }
`;

export const CalendarPageLayoutManager: React.FC<{
    leftSidebar: React.ReactNode;
    rightSidebar: React.ReactNode;
    main: React.ReactNode;
}> = ({ leftSidebar, main, rightSidebar }) => {
    const { innerHeight } = useWindowSize();
    const CalLayoutState = useCalendarLayoutState();
    const height = innerHeight - PAGE_BOTTOM_OFFSET_GRID;

    const onCloseFilters = () => CalLayoutState.setFilterBarState(false);

    const showLeft = CalLayoutState.isFilterBarOpen && !!leftSidebar;
    const showRight = CalLayoutState.isDockOpen && !!rightSidebar;

    let gridStyle = useMemo(() => {
        const isWideScreen = CalLayoutState.appLayoutMode.gteDesktopMD;
        let gridTemplateColumns = TEMPLATE_M;
        if (CalLayoutState.layoutColumns === 3) {
            if (showLeft && showRight) {
                gridTemplateColumns = isWideScreen ? TEMPLATE_L_M_R_WIDE : TEMPLATE_L_M_R_MOB;
            } else if (showLeft) {
                gridTemplateColumns = TEMPLATE_L_M;
            } else if (showRight) {
                gridTemplateColumns = isWideScreen ? TEMPLATE_M_R_WIDE : TEMPLATE_M_R_MOB;
            } else {
                gridTemplateColumns = TEMPLATE_M;
            }

            return { gridTemplateColumns, height: height };
        } else if (CalLayoutState.layoutColumns === 2) {
            if (showRight) {
                gridTemplateColumns = TEMPLATE_M_R_MOB;
            } else {
                gridTemplateColumns = TEMPLATE_M;
            }

            return { gridTemplateColumns, height: height };
        } else {
            return { gridTemplateColumns, height: height };
        }
    }, [CalLayoutState.layoutColumns, CalLayoutState.appLayoutMode, showLeft, showRight, height, innerHeight]);

    if (CalLayoutState.layoutColumns === 3) {
        return (
            <CalendarContainerGrid style={gridStyle}>
                {showLeft && <>{leftSidebar}</>}
                <CalendarViewContainer>{main}</CalendarViewContainer>
                {showRight && (
                    <SidebarContainer darker={true} maxHeight={height} className={'no-print'}>
                        {rightSidebar}
                    </SidebarContainer>
                )}
            </CalendarContainerGrid>
        );
    } else if (CalLayoutState.layoutColumns === 2) {
        return (
            <>
                <SlideInDock onClose={onCloseFilters} open={showLeft} direction={'left'} animateSlide={false}>
                    {leftSidebar}
                </SlideInDock>
                <CalendarContainerGrid style={gridStyle}>
                    <CalendarViewContainer>{main}</CalendarViewContainer>
                    {CalLayoutState.isDockOpen && (
                        <SidebarContainer darker={true} maxHeight={height} className={'no-print'}>
                            {rightSidebar}
                        </SidebarContainer>
                    )}
                </CalendarContainerGrid>
            </>
        );
    } else {
        return (
            <>
                <SlideInDock onClose={onCloseFilters} open={showLeft} direction={'left'} animateSlide={false}>
                    {leftSidebar}
                </SlideInDock>
                <CalendarContainerGrid style={gridStyle}>
                    <CalendarViewContainer>{main}</CalendarViewContainer>
                </CalendarContainerGrid>
            </>
        );
    }
};
