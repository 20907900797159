import * as React from 'react';
import { CalendarEventType } from '../CalendarView/types';
import { Display, TruncatedDiv } from '@poolware/components';
import { Link } from 'react-router-dom';
import * as URLBuilder from '../url-builder';
import { Icon } from 'semantic-ui-react';
import { IconServiceJobStage, mapServiceJobStageTypeToIconProps, ModuleIconNames } from '../../constants';
import { ServiceJobLink, WorkOrderLink } from '../../Links';
import * as _ from 'lodash';
import { NodeType } from '@poolware/api';

export const CellCustomer: React.FC<{ event: CalendarEventType }> = ({ event }) => {
    const { customer, pool, address, color, group } = event.item;
    const _address = address || pool?.address;

    return (
        <TruncatedDiv maxWidth={400} style={{ minHeight: '3rem' }}>
            {customer && (
                <Link to={URLBuilder.Customer(customer.id).view}>
                    <Icon name={'user'} />
                    <code style={{ color: '#2185d0' }}>
                        <b>{customer.crn}</b>
                    </code>{' '}
                    - <Display.Entity value={customer} />
                </Link>
            )}
            {_address && (
                <div>
                    <Icon name={'map marker alternate'} />
                    <Display.Address value={_address} showMapLink={true} />
                </div>
            )}
        </TruncatedDiv>
    );
};

export const CellServiceCase: React.FC<{ event: CalendarEventType }> = ({ event }) => {
    const { serviceJob, workOrder } = event.item || {};

    if (!serviceJob && !workOrder) {
        return null;
    }

    const isRecurring = serviceJob?.isRecurring;
    const woStatusIcon = <IconServiceJobStage stage={workOrder?.stage} />;
    const sjStatusIcon = <IconServiceJobStage stage={serviceJob?.stage} />;

    const isPendingWo = !workOrder && serviceJob?.isRecurring;
    return (
        <div>
            {serviceJob && !isRecurring && (
                <div>
                    {sjStatusIcon && <Icon {...sjStatusIcon} />}
                    <Icon name={ModuleIconNames.ServiceCase} />
                    <ServiceJobLink id={serviceJob.id}>#{serviceJob.serviceJobNumber}</ServiceJobLink>-{' '}
                    {_.truncate(serviceJob.title, { length: 40 })}{' '}
                </div>
            )}

            {workOrder && (
                <div>
                    {woStatusIcon && <Icon {...woStatusIcon} />}
                    <Icon name={ModuleIconNames.WorkOrder} />
                    <WorkOrderLink id={workOrder.id}>#{workOrder.workOrderNumber}</WorkOrderLink>
                    <span tw={'pl-1'}>{_.truncate(workOrder.title, { length: 40 })}</span>
                </div>
            )}
            {isPendingWo && (
                <div>
                    <Icon {...mapServiceJobStageTypeToIconProps(NodeType.ServiceJobStageType.Opened)} />
                    <Icon name={ModuleIconNames.WorkOrder} />
                    <span>{_.truncate(serviceJob.title, { length: 40 })}</span> <i>pending...</i>
                </div>
            )}
        </div>
    );
};
