import * as React from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import { withApolloLoading } from '@poolware/components';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';
import { DEFAULT_APPOINTMENT_GROUP_ID } from '../../constants';
import { compose, mapProps } from '@ez/tools';

const prepareOptions = (agList: NodeType.AppointmentGroup[]) => {
    let options = agList.map((ag) => ({
        key: ag.id,
        text: ag.title,
        value: ag.id,
        color: ag.defaultColor,
        ag: ag,
    }));

    const defaultGroupOption = {
        key: '0',
        text: 'Default Group',
        value: DEFAULT_APPOINTMENT_GROUP_ID,
        color: 'white',
        ag: null,
    };

    return [defaultGroupOption].concat(options);
};

interface ExternalProps {
    selectedGroupId?: NodeType.ID;
    onChange: (ag: NodeType.AppointmentGroup) => any;
    readonly?: boolean;
}

const AppointmentGroupSingleSelector: React.FC<ExternalProps & ControlProps> = ({
    selectedGroupId,
    appointmentGroups,
    onChange,
    readonly,
}) => {
    const options = prepareOptions(appointmentGroups);

    const handleChange = (value) => {
        if (options.length < 2) {
            // do nothing if list contains 0 or 1 item;
            return;
        }
        const option = _.find<any>(options, { value: value }) || options[0];
        onChange && onChange(option.ag);
    };

    const value = !selectedGroupId ? DEFAULT_APPOINTMENT_GROUP_ID : selectedGroupId;

    const selectedOption = _.find(options, { value: value }) || options[0];

    const trigger = (
        <div tw={'inline-flex flex-row items-stretch gap-1'}>
            <div
                style={{
                    backgroundColor: selectedOption.color,
                    border: '1px solid #aaa',
                    marginTop: 1,
                    width: '1em',
                    height: '1em',
                    borderRadius: 9999,
                }}
            />
            <div>{selectedOption.text}</div>
        </div>
    );

    if (readonly) {
        return trigger;
    }

    return (
        <Dropdown trigger={trigger} item>
            <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                    {options.map((op) => {
                        const props = {
                            key: op.key,
                            value: op.value,
                            selected: op.value === selectedOption.value,
                            onClick: () => {
                                handleChange(op.value);
                            },
                        };
                        return (
                            <Dropdown.Item {...props}>
                                <Label style={{ backgroundColor: op.color, border: '1px solid #aaa' }} empty circular />
                                {op.text}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown.Menu>
        </Dropdown>
    );
};

const QUERY = gql`
    query AppointmentGroup_Picker {
        viewer {
            appointmentGroups(first: 100) {
                edges {
                    node {
                        id
                        title
                        defaultColor
                        description
                        priority
                        colorPalette
                    }
                }
            }
        }
    }
`;

export default compose(
    graphql(QUERY),
    withApolloLoading({ showLoader: false }),
    mapProps((props) => {
        const appointmentGroups = fromEdges(_.get(props, 'data.viewer.appointmentGroups'));
        return {
            ...props,
            appointmentGroups,
        };
    })
)(AppointmentGroupSingleSelector) as React.ComponentType<ExternalProps>;

interface ControlProps {
    appointmentGroups: NodeType.AppointmentGroup[];
}
