import * as React from 'react';

import { Display, IconWithPopup } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { Checkbox, Input, Table } from 'semantic-ui-react';
import { connect, getIn } from 'formik';
import { FormTableRow } from './components';

interface CustomerAddressListProps {
    name: string;
    contacts: NodeType.Contact[];
}

export const CustomerContactsList = connect<CustomerAddressListProps>((props) => {
    const { name, formik, contacts } = props;

    const nn = (n) => {
        return `${name}.${n}`;
    };
    const fieldValue = getIn(formik.values, nn('value'));
    const adhocValue = getIn(formik.values, nn('adhocValue'));
    const isAdhoc = getIn(formik.values, nn('isAdhoc'));

    const onSelectPhoneContact = (contact: NodeType.Contact) => {
        formik.setFieldValue(nn('value'), contact?.data || null);
        formik.setFieldValue(nn('isAdhoc'), false);
    };

    const onSelectPhoneAdhoc = (iSelected: boolean) => {
        formik.setFieldValue(nn('isAdhoc'), iSelected);
    };

    const onInputAdhocPhoneChange = (e) => {
        formik.setFieldValue(nn('isAdhoc'), true);
        formik.setFieldValue(nn('value'), e.target.value || '');
        formik.setFieldValue(nn('adhocValue'), e.target.value || '');
    };

    return (
        <Table size={'small'} compact padded={false} celled={false} basic>
            <Table.Body>
                {contacts.map((contact) => {
                    const isSelected = !isAdhoc && contact.data === fieldValue;
                    return (
                        <TableRowContact
                            key={contact.id}
                            contact={contact}
                            isSelected={isSelected}
                            onSelect={onSelectPhoneContact}
                        />
                    );
                })}
                <FormTableRow isSelected={isAdhoc} onClick={() => onSelectPhoneAdhoc(!isAdhoc)}>
                    <Table.Cell>
                        <Checkbox radio checked={isAdhoc} label={'Other'} />
                    </Table.Cell>
                </FormTableRow>
                {isAdhoc && (
                    <FormTableRow isSelected={isAdhoc}>
                        <Table.Cell width={'1'}>
                            {isAdhoc && <Input onChange={onInputAdhocPhoneChange} value={adhocValue} />}
                        </Table.Cell>
                    </FormTableRow>
                )}
            </Table.Body>
        </Table>
    );
});

export const TableRowContact: React.FC<{ contact: NodeType.Contact; onSelect; isSelected }> = ({
    contact,
    onSelect,
    isSelected,
}) => {
    if (!contact && !onSelect) return null;

    const handelClick = () => {
        // deselect if selected
        onSelect && onSelect(isSelected ? null : contact);
    };

    const formattedLabel = (
        <span style={{ paddingLeft: '0.7rem' }}>
            <Display.ContactItem noLink={true} value={contact} />
            {contact.label && <b> - {contact.label}</b>}{' '}
            {contact.isPrimary && (
                <IconWithPopup name={'star'} color={'yellow'} popup={{ content: 'Primary contact' }} />
            )}
        </span>
    );

    return (
        <FormTableRow onClick={handelClick} isSelected={isSelected}>
            <Table.Cell>
                <Checkbox radio checked={isSelected} />
                {formattedLabel}
            </Table.Cell>
        </FormTableRow>
    );
};
