import * as React from 'react';
import * as URLBuilder from '../../routes/url-builder';
import { ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch, useAppNavigator } from '@poolware/react-app-navigator';
import * as Staff from './index';
import { ListPageStaffTeams } from './StaffTeams/List';
import { NewPageStaffTeam } from './StaffTeams/New';
import { ViewPageStaffTeam } from './StaffTeams/View';
import { PageContent, useViewer } from '@poolware/app-shell';
import { Icon, Menu } from 'semantic-ui-react';
import { styled } from 'twin.macro';
import { MenuItemNavLink } from '../../components/MenuItemNavLink';

const StyledMenu = styled(Menu)`
    //margin-bottom: 0 !important;
    border-radius: 0 !important;
    background-color: #e6e6e6 !important;
    .menu .item.active {
        background-color: #ffffff !important;
    }
`;

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { modulesAccess } = useViewer();
    if (AppNavigator.isModal || !modulesAccess.Staff?.teams) {
        return null;
    }

    return (
        <StyledMenu fluid>
            <Menu.Menu position={'left'}>
                <MenuItemNavLink to={'/all'}>
                    <Icon name={'spy'} /> Staff
                </MenuItemNavLink>
                {modulesAccess.Staff?.teams && (
                    <MenuItemNavLink to={'/teams'}>
                        <Icon name={'users'} />
                        Teams
                    </MenuItemNavLink>
                )}
            </Menu.Menu>
        </StyledMenu>
    );
};

export const Router = () => {
    const { paramName } = URLBuilder.Staff();

    let pathId = `:${paramName}`;

    return (
        <ModuleRoot>
            <PageContent pageWidth={'wide'}>
                <ModuleRoute path={'/teams'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/all'} exact={true} component={SectionsNavbar} />

                <ModuleSwitch>
                    <ModuleRedirect path={'/'} exact to={'/all'} />
                    <ModuleRoute path={'/all'} exact component={Staff.List} />
                    <ModuleRoute path="/teams/new" component={NewPageStaffTeam} />
                    <ModuleRoute path="/teams/:id" component={ViewPageStaffTeam} />
                    <ModuleRoute path="/teams" component={ListPageStaffTeams} />
                    <ModuleRoute path="/new" component={Staff.New} />
                    <ModuleRoute path={`/${pathId}/edit`} component={Staff.Edit} />
                    <ModuleRoute path={`/${pathId}`} component={Staff.View} />
                </ModuleSwitch>
            </PageContent>
        </ModuleRoot>
    );
};
