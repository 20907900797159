import * as React from 'react';
import 'twin.macro';
import { Button, Divider, Icon, Menu, Message, Modal, Table } from 'semantic-ui-react';
import {
    DebugJsonButton,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarHeaderItem,
    MenuBarItem,
    MenuBarSection,
    MenuButton,
    Panel,
    SectionHeader,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { PageControlProps } from './Page.Control';
import * as URLBuilder from '../../../../routes/url-builder';
import ContactItem from '../../../CommonComponents/Contacts/ContactItem';
import { VendCustomerDetails } from './VendCustomerDetails';
import { AppErrorBoundary } from '@poolware/app-shell';

interface AddNewContactMenuProps {
    contactTypes: ContactItem[];
    onClick: (ContactItem) => any;
}

class AddNewContactMenu extends React.Component<AddNewContactMenuProps, any> {
    render() {
        const { contactTypes, onClick } = this.props;

        return (
            <Menu vertical secondary>
                {contactTypes.map((contactType) => {
                    return (
                        <Menu.Item
                            key={contactType.label}
                            name={contactType.label}
                            onClick={() => onClick(contactType)}
                        />
                    );
                })}
            </Menu>
        );
    }
}

const FormType = {
    None: 0,
    New: 1,
    Edit: 2,
    Delete: 3,
    SetPrimary: 4,
};

const TableRow: React.FC<{
    contactItem;
    children;
    onEdit?;
    onDelete?;
    onNew?;
    onMakePrimary?;
}> = ({ contactItem, children, onEdit, onDelete, onNew, onMakePrimary }) => (
    <Table.Row>
        <Table.Cell>
            <b>{contactItem.label}</b>
        </Table.Cell>
        <Table.Cell>{children}</Table.Cell>
        <Table.Cell textAlign="right">
            <span>
                {onMakePrimary && (
                    <Button name={`edit-${contactItem.label}`} compact basic size="mini" onClick={onMakePrimary}>
                        Set Primary
                    </Button>
                )}
                {onNew && (
                    <Button name={`new-${contactItem.label}`} compact basic size="mini" color="teal" onClick={onNew}>
                        New
                    </Button>
                )}
                {onDelete && (
                    <Button
                        name={`delete-${contactItem.label}`}
                        compact
                        basic
                        size="mini"
                        color="red"
                        onClick={onDelete}
                    >
                        Delete
                    </Button>
                )}
                {onEdit && (
                    <Button name={`edit-${contactItem.label}`} compact basic size="mini" onClick={onEdit}>
                        Edit
                    </Button>
                )}
            </span>
        </Table.Cell>
    </Table.Row>
);

const WarningMessage = () => {
    return (
        <Message negative>
            <Message.Header>
                <Icon name={'warning sign'} color={'yellow'} />
                DANGER!!!
                <Icon name={'warning sign'} color={'yellow'} />
            </Message.Header>
            <Message.Content>
                <p />
                <p>This customer will be deleted PERMANENTLY!</p>
                <p>This action is irreversible!</p>
            </Message.Content>
        </Message>
    );
};

const CustomerDetailsChangeDisclaimerWarning = () => {
    return (
        <Message>
            <Message.Header>
                <Icon name={'warning sign'} color={'yellow'} />
                Important note regarding changing customer details!
            </Message.Header>
            <Message.Content>
                <p tw={'pt-4'}>
                    When you change customer details, the changes are also pushed to Vend. Changing customer details is
                    suited for cases such as adding new contact information, fixing errors and typos.
                </p>
                <p>
                    If you change customer name to a different person, this will have a significant affect on customer's
                    sale history on Vend.
                </p>
                <p>
                    In case you need to change pool ownership, please consider registering new customer and transferring
                    the site to the new customer. This will preserve existing customer's sale history.
                </p>
                <p>Site ownership transfer functionality is available under "Edit Site ⇒ Change Owner".</p>
            </Message.Content>
        </Message>
    );
};

interface PageProps extends PageControlProps {}

class Page extends React.Component<PageProps, any> {
    state: any = {};

    constructor(props, context) {
        super(props, context);

        this.state = {
            openModal: false,
            openFormType: FormType.None,
            editContactItem: null,
        };
    }

    showForm = (contactItem: any, formType: any) => () => {
        this.setState({ openModal: true, editContactItem: contactItem, openFormType: formType });
    };

    hideForm = () => {
        this.setState({ openModal: false, editContactItem: null, openFormType: FormType.None });
    };

    onAddNewContactMenuClick = (menuItem: any) => {
        this.showForm(menuItem, FormType.New)();
    };

    onSubmit = () => {
        this.hideForm();
    };

    onDelete = async () => {
        const r = window.confirm('Delete Customer?');
        if (r !== true) {
            return;
        }

        try {
            await this.props.mutateCustomer.delete({ id: this.props.customer.id });
            await this.props.AppNavigator.navigate(URLBuilder.Customer().all);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete customer', description: e.message });
        }
    };

    onGoBack = () => {
        const customerLink = URLBuilder.Customer(this.props.customer.id).view;
        this.props.AppNavigator.navigate(customerLink);
    };

    render() {
        const { contactTypes, contactItems: displayItems, viewerContext, customer } = this.props;
        const { editContactItem, openModal, openFormType } = this.state;

        const showAddButton = contactTypes?.length > 0;

        const menu = (props) => <AddNewContactMenu contactTypes={contactTypes} {...props} />;

        const canDelete = this.props.viewerContext?.viewer?.Permissions?.Customer?.delete;
        const isVendEnabled =
            viewerContext?.viewer?.isVendPresent && viewerContext?.modulesAccess?.VendIntegration?.enabled;
        return (
            <VStack>
                <StickyMenuBar>
                    <MenuBarSection>
                        <MenuBarItem icon={'chevron left'} color={'grey'} onClick={this.onGoBack}>
                            To Customer
                        </MenuBarItem>
                        <MenuBarHeaderItem icon={'user'}>Edit Customer</MenuBarHeaderItem>
                    </MenuBarSection>
                    <MenuBarSection position={'right'}>
                        <DebugJsonButton data={customer} />
                        <MenuBarDropdown icon={'bars'} color={'red'}>
                            <MenuBarDropdownItemWithConfirm
                                icon={'trash'}
                                title={'Delete Customer'}
                                color="red"
                                disabled={!canDelete}
                                popup={canDelete ? undefined : { content: 'Not enough permissions' }}
                                confirm={{
                                    confirmMessage: {
                                        header: 'Delete Customer?',
                                        content: <WarningMessage />,
                                    },
                                    confirmButton: {
                                        content: 'Delete Customer',
                                        negative: true,
                                    },
                                }}
                                onClick={this.onDelete}
                            />
                        </MenuBarDropdown>
                    </MenuBarSection>
                </StickyMenuBar>

                <Table color="grey" selectable unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="1" width="3">
                                Type
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="1">Details</Table.HeaderCell>
                            <Table.HeaderCell colSpan="1" width="4" textAlign="center" />
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {displayItems.detailsItems.map((contactItem, index) => {
                            return (
                                <TableRow
                                    key={contactItem.label + index}
                                    contactItem={contactItem}
                                    onNew={contactItem.newView ? this.showForm(contactItem, FormType.New) : null}
                                    onEdit={contactItem.editView ? this.showForm(contactItem, FormType.Edit) : null}
                                    onDelete={
                                        contactItem.deleteView ? this.showForm(contactItem, FormType.Delete) : null
                                    }
                                >
                                    <contactItem.view />
                                </TableRow>
                            );
                        })}
                        {displayItems.entityContactItems.map((contactItem, index) => {
                            return (
                                <TableRow
                                    key={contactItem.label + index}
                                    contactItem={contactItem}
                                    onMakePrimary={
                                        contactItem.makePrimaryView
                                            ? this.showForm(contactItem, FormType.SetPrimary)
                                            : null
                                    }
                                    onNew={contactItem.newView ? this.showForm(contactItem, FormType.New) : null}
                                    onEdit={contactItem.editView ? this.showForm(contactItem, FormType.Edit) : null}
                                    onDelete={
                                        contactItem.deleteView ? this.showForm(contactItem, FormType.Delete) : null
                                    }
                                >
                                    <contactItem.view />
                                </TableRow>
                            );
                        })}
                    </Table.Body>
                </Table>
                <div>
                    {showAddButton && (
                        <MenuButton
                            menu={menu}
                            menuPosition="right center"
                            onMenuClick={this.onAddNewContactMenuClick}
                            icon="plus"
                            content="Add Contact"
                        />
                    )}
                </div>

                <Modal size="small" centered={false} open={openModal}>
                    {editContactItem && (
                        <div>
                            {openFormType === FormType.SetPrimary && (
                                <editContactItem.makePrimaryView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}
                            {openFormType === FormType.Edit && (
                                <editContactItem.editView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}

                            {openFormType === FormType.Delete && (
                                <editContactItem.deleteView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}

                            {openFormType === FormType.New && (
                                <editContactItem.newView onCancel={this.hideForm} onSubmit={this.onSubmit} />
                            )}
                        </div>
                    )}
                </Modal>

                {isVendEnabled && (
                    <AppErrorBoundary>
                        <Divider />
                        <SectionHeader icon={'exchange'}>External Integrations</SectionHeader>
                        <div tw={'flex flex-col lg:flex-row gap-4'}>
                            <div tw={'max-w-screen-md w-full'}>
                                <VendCustomerDetails customerId={this.props.customer.id} />
                            </div>
                            {customer?.vendLink && (
                                <div tw={'max-w-screen-sm w-full'}>
                                    <CustomerDetailsChangeDisclaimerWarning />
                                </div>
                            )}
                        </div>
                    </AppErrorBoundary>
                )}

                <Divider />
                <div tw={'max-w-screen-sm mt-4'}>
                    <Panel>
                        <Panel.Body>
                            <Panel.ItemDate
                                label={'Registered'}
                                content={customer?.registeredAt || customer?.createdAt}
                            />
                            <Panel.ItemEntity label={'Registered by'} content={customer?.addedBy} />
                        </Panel.Body>
                    </Panel>
                </div>
            </VStack>
        );
    }
}

export default Page;
