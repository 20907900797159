import * as React from 'react';
import { ModuleNavLink, ModuleRedirect, ModuleRoot, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import { Menu } from 'semantic-ui-react';
import { PageContentContainer } from '../components/PageContentContainer';
import { NotFoundPage, SuggestInputOrgSwitcher, useViewer } from '@poolware/app-shell';
import { styled } from 'twin.macro';
import * as PoolTraits from './PoolTraitDeclarations';
import * as Declaration from './Declarations';
import * as Product from './Products';
import * as ProductCompany from './ProductCompany';

const Index = () => {
    return <ModuleRedirect to={'/products'} />;
};

const StyledMenu = styled(Menu)`
    //margin-bottom: 0 !important;
    border-radius: 0 !important;
    background-color: #e6e6e6 !important;
    .menu .item.active {
        background-color: #ffffff !important;
    }
`;

const SectionsNavbar = () => {
    const { isAdmin } = useViewer();
    return (
        <StyledMenu fluid>
            <Menu.Menu position={'left'}>
                <Menu.Item as={ModuleNavLink} to={'/products'}>
                    Products
                </Menu.Item>
                <Menu.Item as={ModuleNavLink} to={'/declarations'}>
                    Trait Declarations
                </Menu.Item>
                <Menu.Item as={ModuleNavLink} to={'/companies'}>
                    Companies & Brands
                </Menu.Item>
                {isAdmin && (
                    <>
                        <Menu.Item as={ModuleNavLink} to={'/pool-declarations'}>
                            Pool Traits
                        </Menu.Item>
                    </>
                )}
            </Menu.Menu>
            <Menu.Menu position={'right'}>
                <div tw={'p-1 w-80'}>
                    <SuggestInputOrgSwitcher />
                </div>
            </Menu.Menu>
        </StyledMenu>
    );
};

export const Router = () => {
    return (
        <ModuleRoot>
            <SectionsNavbar />
            <ModuleSwitch notFound={NotFoundPage}>
                <ModuleRoute path={'/'} exact component={Index} />
                <ModuleRoute path={'/declarations'} component={Declaration.Router} />
                <ModuleRoute path={'/products'} component={Product.Router} />
                <ModuleRoute path={'/companies'} component={ProductCompany.Router} />
                <ModuleRoute path={'/pool-declarations'} component={PoolTraits.Router} />
            </ModuleSwitch>
        </ModuleRoot>
    );
};
