import * as React from 'react';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';
import { ErrorBoundaryFallbackComponentProps } from './AppErrorBoundary';
import { removeDimmer } from './remove-app-dimmer';
import { useEffect } from 'react';

export const AppFatalErrorMessage: React.FC<ErrorBoundaryFallbackComponentProps> = () => {
    useEffect(() => {
        // Remove spinner no matter what
        removeDimmer();
    }, []);

    return (
        <Container>
            <Segment basic>
                <Segment>
                    <Header>
                        Something went wrong <Icon name={'frown'} />
                    </Header>
                    <React.Fragment>
                        <p>We are terribly sorry! Here are the things you can try: </p>
                        <ul>
                            <li>Hard reload the web page (Windows: ctrl + F5, Mac: Command + R, Linux: F5)</li>
                            <li>
                                Clear browser cache (
                                <a
                                    href="https://refreshyourcache.com/en/home/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    See how
                                </a>
                                )
                            </li>
                            <li>Try another browser</li>
                            <li>
                                If nothing helps please contact support at{' '}
                                <a href={'mailto:support@poolware.zendesk.com?subject=Poolware%20support'}>
                                    support@poolware.zendesk.com
                                </a>
                            </li>
                        </ul>
                    </React.Fragment>
                </Segment>
            </Segment>
        </Container>
    );
};
