import * as React from 'react';
import { useState } from 'react';
import { Panel, toastError, withApolloLoading } from '@poolware/components';
import { fromEdges, NodeType } from '@poolware/api';
import { Modal } from 'semantic-ui-react';
import { NewProductTrait } from '../../ProductTrait';
import { compose, mapProps } from '@ez/tools';
import { graphql } from 'react-apollo';
import * as _ from 'lodash';
import gql from 'graphql-tag';
import { ProductTraitsForm } from './ProductTraitsForm';
import { SingleTraitRemove } from './TraitRemovePanel';
import { queryNames } from '../../query-names';
import { productTraitFragment } from '../../../../queries/fragments';
import { IProductCatalogMutators, withProductCatalogMutators } from '../../../../queries';

const ProductTraitsPanel: React.FC<PageControlProps> = ({ product, ProductCatalogMutator, ...props }) => {
    const [showNewTraitForm, setShowNewTraitForm] = useState(false);
    const [showRemoveTraitsForm, setShowRemoveTraitsForm] = useState(false);
    const [isEditMode, setEditMode] = useState(false);

    const onEdit = () => {
        setEditMode(true);
    };

    const onAddTrait = () => {
        setShowNewTraitForm(true);
    };

    const hideNewForm = () => {
        setShowNewTraitForm(false);
    };

    const onRemoveTraits = () => {
        setShowRemoveTraitsForm(true);
    };

    const hideRemoveTraitForm = () => {
        setShowRemoveTraitsForm(false);
    };

    const onTraitEditDone = () => {
        setEditMode(false);
    };

    const onDeleteProductTrait = async (trait: NodeType.ProductTrait) => {
        try {
            await ProductCatalogMutator.deleteProductTraits([trait]);
            hideRemoveTraitForm();
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to delete', description: e.message });
        }
    };

    const productTraits = fromEdges(product?.traits);

    const buttons = isEditMode
        ? []
        : [
              { content: 'Edit', onClick: onEdit },
              { content: 'Add Trait', onClick: onAddTrait },
              { content: 'Remove Traits', onClick: onRemoveTraits },
          ];

    return (
        <Panel>
            <Panel.Header button={buttons} content={'Product Traits'} />
            <Panel.Body>
                <ProductTraitsForm
                    {...props}
                    ProductCatalogMutator={ProductCatalogMutator}
                    traits={productTraits}
                    editMode={isEditMode}
                    onDone={onTraitEditDone}
                />
            </Panel.Body>

            <Modal open={showNewTraitForm}>
                <NewProductTrait products={[product]} onCancel={hideNewForm} onSubmit={hideNewForm} />
            </Modal>
            <Modal open={showRemoveTraitsForm} size={'small'}>
                <SingleTraitRemove
                    product={product}
                    onCancel={hideRemoveTraitForm}
                    onDeleteProductTrait={onDeleteProductTrait}
                />
            </Modal>
        </Panel>
    );
};

const QL = gql`
    query ProductDetails_traitsTable($id: ID!) {
        product: node(id: $id) {
            ... on Product {
                id
                name
                addedBy {
                    id
                }
                traits {
                    ...ProductTraitFragment_Catalog
                    declaration {
                        id
                        tag
                        name
                        parent {
                            id
                            name
                        }
                        subDeclarations {
                            id
                            name
                        }
                        integerFields {
                            id
                            name
                            unit
                        }
                        floatFields {
                            id
                            name
                            unit
                        }
                        stringFields {
                            id
                            name
                        }
                        flagFields {
                            id
                            tag
                            name
                        }
                        selectionFields {
                            id
                            name
                            hint
                            tag
                            options {
                                id
                                name
                                priority
                                description
                            }
                        }
                        parentDeclarations {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
    ${productTraitFragment}
`;

export default compose(
    graphql(QL, {
        options: (props: ProductTraitsTableExternalProps) => {
            return {
                variables: { id: props.productId },
            };
        },
    }),
    withApolloLoading({ show404ForPath: 'data.product.id' }),
    mapProps((props) => {
        const product = _.get(props, 'data.product');
        return {
            ...props,
            product,
        };
    }),
    withProductCatalogMutators([...queryNames, 'ProductDetails_traitsTable'])
)(ProductTraitsPanel) as React.ComponentType<ProductTraitsTableExternalProps>;

interface ProductTraitsTableExternalProps {
    productId: NodeType.ID;
}

export interface PageControlProps extends ProductTraitsTableExternalProps, IProductCatalogMutators {
    product: NodeType.Product;
}
