import * as React from 'react';
import { useMemo, useState } from 'react';
import {
    ConnectionErrorMessage,
    MenuBar,
    PageSkeletonLoader,
    StickyMenuBar,
    toastError,
    VStack,
} from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { BrandingType, CalcDocType, FormikHeatCalcValueType } from '../common/types';
import { deSerializeToForm, formDefaultValues, serializeForm } from '../common/state-serializer';
import { getYearProfile } from '../common/use-city-temperature-profile';
import { computePoolVolume } from '../common/FormikInputComponents';
import { HeatPumpCalc } from '../heat-pump-calculators/Calc';
import { HeatPumpAutopoolCalc } from '../heat-pump-autopool-calculators';
import { GasHeaterCalc } from '../gas-heater-calculators/Calc';
import { Formik, useFormikContext } from 'formik';
import { Container } from 'semantic-ui-react';
import { CatalogProvider } from '../common/use-catalog';
import { useQueryHeaterRecommendation } from '../common/use-query-heater-recommendations-connection';
import { NotFoundPage } from '@poolware/app-shell';
import { PageHeaderOpenDocument } from '../common/PageHeaderOpenDocument';
import { CalcMode, useHeatCalcActions } from '../redux';
import { PageHeaderNewDocument } from '../common/PageHeaderNewDocument';
import { useQueryCustomerDetails } from '../common/use-query-customer-details';
import { useViewer } from '@poolware/app-shell';
import { FormikHelpers as FormikActions } from 'formik/dist/types';
import { NodeType, useMutationHeaterRecommendation } from '@poolware/api';
import { calculatorValidationSchema } from './validators';
import { PersistedValuesProvider, usePersistedCalcValues } from './PersistedValuesContext';
import { FormikDocTypeToggleButtons } from '../common/FormikDocTypeToggleButtons';
import { useIsMounted } from '@ez/tools';

const Calculator: React.FC<{ print }> = ({ print }) => {
    const { values } = useFormikContext<FormikHeatCalcValueType>();
    const docType = values.docType;
    if (docType === CalcDocType.HeatPump) return <HeatPumpCalc print={print} />;
    if (docType === CalcDocType.GasHeater) return <GasHeaterCalc print={print} />;
    if (docType === CalcDocType.HeatPumpAutopool) return <HeatPumpAutopoolCalc print={print} />;
    return (
        <div>
            <Container>
                <FormikDocTypeToggleButtons availableCalcs={[CalcDocType.GasHeater, CalcDocType.HeatPump]} />
            </Container>
            {/*<DebugJSON data={values} />*/}
        </div>
    );
};

export const CombinedCalcPage: React.FC<{
    initialValues?: Partial<FormikHeatCalcValueType>;
}> = ({ initialValues }) => {
    const { AppNavigator } = useAppNavigator();
    const { create } = useMutationHeaterRecommendation({
        refetchQueries: ['QueryHeaterRecommendationConnection'],
    });
    const { modulesAccess } = useViewer();
    const isMounted = useIsMounted();

    const [print, setPrint] = useState(false);
    const onPrintModeChange = () => {
        setPrint(!print);
    };

    const persistedValues = usePersistedCalcValues();

    const _initialValues = useMemo<FormikHeatCalcValueType>(() => {
        const availableCalcs: CalcDocType[] = [];
        let availableBrandings: BrandingType[] = [];
        let _brandingType: BrandingType = initialValues?.brandingType;
        if (modulesAccess.Calculators.gasheat) {
            availableCalcs.push(CalcDocType.GasHeater);
        }

        const pushBrandindIfNotExists = (item: BrandingType) => {
            if (!availableBrandings.includes(item)) {
                availableBrandings.push(item);
            }
        };

        // Validate deserialized branding type
        if (modulesAccess.Calculators.heatpump) {
            availableCalcs.push(CalcDocType.HeatPump);
            if (modulesAccess.Calculators.heatpumpZaneSwimartBrandingSelect) {
                pushBrandindIfNotExists(BrandingType.Swimart);
                pushBrandindIfNotExists(BrandingType.Zane);
                if (!availableBrandings.includes(_brandingType)) {
                    // reset to default value
                    _brandingType = BrandingType.Swimart;
                }
            }
            if (modulesAccess.Calculators.heatpumpAquatightBrandingSelect) {
                pushBrandindIfNotExists(BrandingType.Swimart);
                pushBrandindIfNotExists(BrandingType.Aquatight);
                if (!availableBrandings.includes(_brandingType)) {
                    // reset to default value
                    _brandingType = BrandingType.Swimart;
                }
            }
        }
        if (modulesAccess.Calculators.heatpumpAutopool) {
            availableCalcs.push(CalcDocType.HeatPumpAutopool);
        }
        if (!availableBrandings.includes(_brandingType)) {
            // reset to default value
            _brandingType = undefined;
        }

        // Validate deserialized Doctype
        let _docType = persistedValues.docType || CalcDocType.GasHeater;
        const validValues = Object.keys(CalcDocType).map((key) => CalcDocType[key]);
        if (availableCalcs.length === 1) {
            _docType = availableCalcs[0];
        } else if (availableCalcs.length === 0) {
            _docType = CalcDocType.HeatPump;
        } else if (!validValues.includes(_docType) || !availableCalcs.includes(_docType)) {
            _docType = availableCalcs[0];
        }

        const yearlyProfile = getYearProfile(persistedValues.country, persistedValues.state, persistedValues.city);
        const selectedMonths = yearlyProfile.map((y) => y.id);
        const defaultConf: FormikHeatCalcValueType = {
            ...formDefaultValues,
            availableCalcs: availableCalcs,
            docType: _docType,
            electCost: persistedValues.electCost,
            operatingHours: persistedValues.operatingHours,
            city: persistedValues.city,
            country: persistedValues.country,
            state: persistedValues.state,
            poolCover: persistedValues.poolCover,
            selectedMonths: selectedMonths,
            yearProfile: yearlyProfile,
            poolLength: persistedValues.poolLength,
            ...initialValues,
            // apply branding type after `initialValues`
            brandingType: _brandingType,
            availableBrandings: availableBrandings,
        };
        const { volume, surfaceAreaTop } = computePoolVolume(defaultConf);
        return {
            ...defaultConf,
            poolVolume: volume,
            poolSurfaceAreaTop: surfaceAreaTop,
        };
    }, [initialValues]);

    const mode = _initialValues.mode || CalcMode.DEFAULT;

    const handleOnSubmit = async (values: FormikHeatCalcValueType, actions: FormikActions<FormikHeatCalcValueType>) => {
        actions.setSubmitting(true);
        try {
            const data = serializeForm(values);
            const conf: NodeType.CreateHeaterRecommendationMutationInput = {
                payload: data.payload,
                product: data.productId,
            };
            if (values.customer) {
                conf.entity = values.customer?.id;
            } else if (values.newCustomerMode && values.newCustomer) {
                // @ts-ignore
                conf.newCustomer = values.newCustomer;
            }

            const result = await create(conf);
            const id = result.data?.HeaterRecommendation?.HeaterRecommendation?.id;
            AppNavigator.navigateRelative(`/combined/rc/${id}`);
        } catch (e) {
            actions.setStatus({ error: e });
            toastError(e);
        }
        if (isMounted()) {
            actions.setSubmitting(false);
        }
    };

    return (
        <CatalogProvider>
            <Formik
                onSubmit={handleOnSubmit}
                validationSchema={calculatorValidationSchema}
                initialValues={_initialValues}
                enableReinitialize={true}
            >
                <VStack>
                    {print ? (
                        <StickyMenuBar>
                            <MenuBar.Section>
                                <MenuBar.Item onClick={() => setPrint(false)} icon={'close'} title={'Cancel'} />
                            </MenuBar.Section>
                        </StickyMenuBar>
                    ) : (
                        <>
                            {mode === CalcMode.OPEN_DOC && <PageHeaderOpenDocument />}
                            {mode === CalcMode.NEW_DOC && <PageHeaderNewDocument />}
                            {mode === CalcMode.DEFAULT && (
                                <StickyMenuBar>
                                    <MenuBar.Section>
                                        <MenuBar.HeaderItem icon={'calculator'}>Heater Calculator</MenuBar.HeaderItem>
                                    </MenuBar.Section>
                                    <MenuBar.Section position={'right'}>
                                        <MenuBar.Item icon={'print'} onClick={onPrintModeChange} />
                                    </MenuBar.Section>
                                </StickyMenuBar>
                            )}
                        </>
                    )}
                    <Calculator print={print} />
                </VStack>
            </Formik>
        </CatalogProvider>
    );
};

export const CombinedCalcPageNew: React.FC = () => {
    const { HeatCalcState } = useHeatCalcActions();

    const customerId = HeatCalcState?.details?.customer?.id;
    const { node, error, loading } = useQueryCustomerDetails(customerId, { skip: !customerId });
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
    }

    let initialValues: Partial<FormikHeatCalcValueType> = {};
    if (HeatCalcState.mode === CalcMode.NEW_DOC && customerId) {
        initialValues = {
            entity: node.user?.entity,
            mode: CalcMode.NEW_DOC,
        };
    }

    return (
        <PersistedValuesProvider>
            <CombinedCalcPage initialValues={initialValues} />{' '}
        </PersistedValuesProvider>
    );
};

export const CombinedCalcPageDefault: React.FC = () => {
    return (
        <PersistedValuesProvider>
            <CombinedCalcPage />
        </PersistedValuesProvider>
    );
};

export const CombinedCalcPageOpen: React.FC = () => {
    const { params } = useAppNavigator();

    const { node, error, loading } = useQueryHeaterRecommendation(params.id);
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !node) {
        return <NotFoundPage />;
    }

    const initialValues = deSerializeToForm(node);
    initialValues.mode = CalcMode.OPEN_DOC;
    return (
        <PersistedValuesProvider>
            <CombinedCalcPage initialValues={initialValues} />
        </PersistedValuesProvider>
    );
};
