import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { mapContractStatusToIconProps, mapContractStatusToName } from './constants';
import { NodeType } from '@poolware/api';
import { Display } from '@poolware/components';

export const ContractStatus: React.FC<{ contract: NodeType.CustomContract }> = ({ contract }) => {
    if (contract.terminationDate) {
        return (
            <>
                <Icon {...mapContractStatusToIconProps(NodeType.CustomContractStatusEnum.Closed)} />
                Terminated, <Display.Date format={'ll'} value={contract.terminationDate} />
            </>
        );
    }

    return (
        <>
            <Icon {...mapContractStatusToIconProps(contract.status)} />
            {mapContractStatusToName(contract.status)}
        </>
    );
};
