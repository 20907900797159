import { MenuBarHeaderItem, MenuBarItem, MenuBarSection, StickyMenuBar, VStack } from '@poolware/components';
import * as React from 'react';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { WaterlinkConfigForm } from './WaterlinkConfigFrom';
import { DeviceStatusPanelDev } from './DeviceStatusPanel';

const WaterlinkConfigPage: React.FC<IAppNavigatorProps> = ({ AppNavigator }) => {
    const goBack = () => {
        AppNavigator.navigateToOrigin('/waterlink');
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Back'} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarHeaderItem>Waterlink Configuration</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <WaterlinkConfigForm />
            <DeviceStatusPanelDev />
        </VStack>
    );
};

export default withAppNavigator()(WaterlinkConfigPage);
