import * as React from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { fromEdges, NodeType, useMutationWarrantyClaim } from '@poolware/api';
import {
    prepareInitValues,
    prepareMutation,
    WarrantyClaimForm,
    WarrantyClaimFormValues,
} from '../components/WarrantyClaimForm';
import {
    DebugJSON,
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    SuspenseSkeletonLoader,
} from '@poolware/components';
import { SubmissionSteps } from './SubmissionSteps';
import { Segment } from 'semantic-ui-react';
import { NotFoundPage } from '@poolware/app-shell';
import { useQueryWarrantyFormData } from './use-query-form-aux-data';
import { useWarrantyActions } from '../redux';
import { usePersistedString } from '@ez/tools';

export interface PageProps {}

export const PageNew: React.FC<PageProps> = () => {
    const [receiverId] = usePersistedString('WRT.reciever-id', null);
    const Warranty = useWarrantyActions();
    const { AppNavigator } = useAppNavigator<{ id?: string }>();
    const { create } = useMutationWarrantyClaim({ refetchQueries: ['QueryWarrantyClaimsConnection'] });
    const { loading, data: formAuxData, error } = useQueryWarrantyFormData();

    if (loading || error) {
        return <SuspenseSkeletonLoader error={error} loading={loading} />;
    }

    if (!loading && !formAuxData) {
        return <NotFoundPage />;
    }

    const initialValues: WarrantyClaimFormValues = prepareInitValues();
    const offices = fromEdges<NodeType.FranchiseWarranter>(formAuxData.viewer?.franchiseWarranters);
    const myFranchiseEmail = formAuxData.viewer?.me?.franchise?.email;

    const initialValuesEnhanced = {
        ...initialValues,
        offices,
        emailReplyTo: myFranchiseEmail,
        // emailCC: myFranchiseEmail,
    };

    if (receiverId) {
        initialValuesEnhanced.receiverWarranter = { id: receiverId };
    }
    if (Warranty.State.isSagaMode) {
        initialValuesEnhanced.refCustomer = Warranty.State.details?.customer;
    }

    const onCancel = () => {
        if (Warranty.State.isSagaMode) {
            Warranty.Action.abort();
        } else {
            AppNavigator.navigateToOrigin('/', { relativeToModule: true });
        }
    };

    const onSubmit = async (values: WarrantyClaimFormValues) => {
        const res = await create({
            entity: {},
            ...prepareMutation(values),
        });

        const id = res?.data?.WarrantyClaim?.WarrantyClaim?.id;
        if (Warranty.State.isSagaMode) {
            Warranty.Action.created();
        }
        AppNavigator.navigateRelative(`/${id}`);
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'certificate'}>New Warranty Claim</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <SubmissionSteps step={'edit'} />

            <PageLayout.BodySection width={'screen-lg'}>
                <Segment>
                    <WarrantyClaimForm
                        debug={true}
                        header={'New Warranty Claim Request'}
                        icon={'certificate'}
                        offices={offices}
                        initialValues={initialValuesEnhanced}
                        submitButton={{ content: 'Save & Review' }}
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                    />
                </Segment>
            </PageLayout.BodySection>
            <DebugJSON data={Warranty} />
        </PageLayout>
    );
};
