import * as React from 'react';
import { FormikDefaultForm, toastError } from '@poolware/components';
import { fromEdges, NodeType } from '@poolware/api';
import { addHours, startOfHour } from 'date-fns';
import { useAppointmentMutators } from '../../../queries/use-appointment-mutators/use-appointment-mutators';
import TimeField from '../../../Scheduler/AppointmentForm/FormFields/TimeField';
import Recurrence from '../../../Scheduler/AppointmentForm/FormFields/RecurrenceField';
import { defaultRecurrence } from '../../../Scheduler/AppointmentForm/common';

export interface FormNewAppointmentProps {
    serviceJob: NodeType.ServiceJob;
    onDone: () => any;
    refetchQueries: any[];
}

export const FormNewSchedule: React.FC<FormNewAppointmentProps> = ({ serviceJob, onDone, refetchQueries }) => {
    const { AppointmentMutator } = useAppointmentMutators(refetchQueries);

    const initialValues = {
        startDate: addHours(startOfHour(new Date()), 1),
        duration: 60,
        recurrence: defaultRecurrence,
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const workOrderTemplate =
                fromEdges(serviceJob?.usedTemplate?.workOrderTemplateAssocs)[0]?.workOrderTemplate || undefined;

            const res = await AppointmentMutator.createAppointment({
                serviceJob: serviceJob,
                duration: values.duration,
                recurrence: values.recurrence,
                isRecurring: true,
                startDate: values.startDate,
                addedBy: undefined,
                endPatternDate: undefined,
                franchise: undefined,
                workOrderTemplate: workOrderTemplate,
            });

            // const id = res?.Appointment?.Appointment?.id;
            onDone();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            debug={true}
            header={'New Schedule'}
            initialValues={initialValues}
            submitButton={{ content: 'Create' }}
            onSubmit={onSubmit}
            onCancel={onDone}
        >
            <TimeField />
            <Recurrence />
        </FormikDefaultForm>
    );
};
