import * as React from 'react';
import { useFormikContext } from 'formik';
import { AppointmentFormValuesType } from '../AppointmentForm';
import { useAppointmentMutators } from '../../../queries/use-appointment-mutators/use-appointment-mutators';
import { ButtonWithPopup, Panel, toastError } from '@poolware/components';
import { Message } from 'semantic-ui-react';
import { ServiceJobLink } from '../../../Links';

export const StartWorkOrderView: React.FC<{ refetchQuery: any }> = ({ refetchQuery }) => {
    const { values } = useFormikContext<AppointmentFormValuesType>();
    const { appointmentItem, serviceJob } = values;
    const { mutateAppointmentItem } = useAppointmentMutators([refetchQuery]);

    const onStartWorkOrder = async () => {
        try {
            await mutateAppointmentItem.startWorkOrder({ id: appointmentItem.id });
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <Panel>
            <Panel.Body>
                <Message info={true}>
                    <div>
                        This work order is managed by Recurring Service{' '}
                        <ServiceJobLink id={serviceJob.id}>
                            {serviceJob.serviceJobNumber} ({serviceJob.title})
                        </ServiceJobLink>
                        .
                    </div>
                </Message>
                <ButtonWithPopup
                    basic={true}
                    size={'tiny'}
                    color={'teal'}
                    showLoaderOnClick={true}
                    onClick={onStartWorkOrder}
                >
                    Start Work Order
                </ButtonWithPopup>
            </Panel.Body>
        </Panel>
    );
};
