import * as React from 'react';
import SearchResultsList from './SearchResultsList';
import SearchBar from './SearchSidebar';
import styled from 'styled-components';
import { useAppointmentReportsActions } from '../../redux';
import { MenuBarItem, MenuBarSection, StickyMenuBar, VStack } from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useQueryAppointmentItemsConnection } from '@poolware/app-service-jobs';

const PageContainer = styled.div`
    display: flex;
`;

const SearchBarContainer = styled.div`
    flex: 0 0 240px;
    max-width: 240px;
    font-size: 0.9rem;
`;

const SearchResultsContainer = styled.div`
    margin-left: 1rem;
    flex: 1 1 auto;
    font-size: 0.9rem;
    overflow-x: auto;
    height: 100%;
    padding-bottom: 2rem;
`;

const ConnectedSearchResultsList: React.FC = (props) => {
    const { AppointmentReportsState } = useAppointmentReportsActions();
    const { dateRange, franchise } = AppointmentReportsState.filters;

    const { connectionData, connectionState } = useQueryAppointmentItemsConnection({
        dateRange: dateRange,
        franchiseId: franchise.id,
        filters: AppointmentReportsState.filters,
    });

    return <SearchResultsList {...props} connectionData={connectionData} connectionState={connectionState} />;
};

export const Page: React.FC = () => {
    const { AppNavigator } = useAppNavigator();
    const goBack = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Dashboard'} />
                </MenuBarSection>
            </StickyMenuBar>
            <PageContainer>
                <SearchBarContainer className={'no-print'}>
                    <SearchBar />
                </SearchBarContainer>
                <SearchResultsContainer>
                    <ConnectedSearchResultsList />
                </SearchResultsContainer>
            </PageContainer>
        </VStack>
    );
};

export default Page;
