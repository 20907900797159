import { NodeType } from '@poolware/api';
import * as _ from 'lodash';

export type CalendarEventStreetType = {
    streetName: string;
    postCode?: string;
    suburb?: string;
    city?: string;
    count: number;
    streetKey: string;
    areaKey: string;
};

export type CalendarEventAddressAreaType = {
    postCode?: string;
    city?: string;
    suburb?: string;
    count: number;
    areaKey: string;
    streets: CalendarEventStreetType[];
};

export const getStreetSummaryKeys = (ai: NodeType.AppointmentItem): { streetKey: string; areaKey: string } => {
    const address = ai.address || ai.pool?.address;
    if (!address) return null;
    const streetName = address?.streetName?.toLowerCase()?.trim() || '';
    const postCode = address?.postCode?.toLowerCase()?.trim() || '';
    const city = address?.city?.toLowerCase()?.trim() || '';
    const sub = address?.suburb?.toLowerCase()?.trim() || '';
    if (!streetName && !city && !postCode && !sub) {
        return null;
    }

    return {
        streetKey: streetName + '|' + postCode + '|' + city,
        areaKey: postCode + '|' + city + '|' + sub,
    };
};

const toTitleCase = (phrase) => {
    return _.startCase(_.toLower(phrase));
};

export const computeStreetSummaries = (appointmentItems: NodeType.AppointmentItem[]) => {
    let streetsSummary: CalendarEventStreetType[] = [];

    let streetMaps = appointmentItems.reduce((acc, ai) => {
        const address = ai.address || ai.pool?.address;
        const keys = getStreetSummaryKeys(ai);
        if (!keys || !keys?.streetKey) {
            return acc;
        }
        const { streetKey, areaKey } = keys;

        const streetMapItem = acc[streetKey];
        if (!streetMapItem) {
            acc[streetKey] = {
                streetKey: streetKey,
                areaKey: areaKey,
                count: 1,
                streetName: toTitleCase(address?.streetName),
                postCode: toTitleCase(address?.postCode),
                city: toTitleCase(address?.city),
                suburb: toTitleCase(address?.suburb),
            };
        } else {
            acc[streetKey] = {
                ...streetMapItem,
                count: streetMapItem.count + 1,
            };
        }
        return acc;
    }, {} as { [key: string]: CalendarEventStreetType });

    streetsSummary = Object.keys(streetMaps)
        .map<CalendarEventStreetType>((key) => streetMaps[key])
        .sort((a, b) => (a.city > b.city ? 1 : -1));

    let areaMaps = streetsSummary.reduce((acc, street) => {
        const { areaKey } = street;

        const areaMapItem = acc[areaKey];
        if (!areaMapItem) {
            acc[areaKey] = {
                areaKey: areaKey,
                count: street.count,
                city: street.city,
                suburb: street.suburb,
                postCode: street.postCode,
                streets: [street],
            };
        } else {
            acc[areaKey] = {
                ...areaMapItem,
                count: areaMapItem.count + street.count,
                streets: areaMapItem.streets.concat(street),
            };
        }
        return acc;
    }, {} as { [key: string]: CalendarEventAddressAreaType });

    const areaSummary = Object.keys(areaMaps)
        .map<CalendarEventAddressAreaType>((key) => areaMaps[key])
        .sort((a, b) => (a.city > b.city ? 1 : -1));

    return { streetsSummary, areaSummary };
};
