import * as React from 'react';
import { MessagesFeed } from './MessagesFeed';
import {
    createUseQueryNodeHook,
    fromEdges,
    NodeType,
    staffFragment,
    useMutationWarrantyClaimMessage,
} from '@poolware/api';
import { IconWithPopup, PageSkeletonLoader, Panel, toastError } from '@poolware/components';
import gql from 'graphql-tag';
import { NotFoundPage } from '@poolware/app-shell';
import { DiscussionMessage } from './MessagesFeed/types';

const QL = gql`
    query QueryWarrantyClaimMessages($id: ID!) {
        node(id: $id) {
            id
            ... on WarrantyClaim {
                id
                messages {
                    edges {
                        node {
                            id
                            message
                            createdAt
                            updatedAt
                            addedBy {
                                ...StaffFragment
                            }
                            checkMutations {
                                delete
                                update
                            }
                        }
                    }
                }
            }
        }
    }
    ${staffFragment}
`;

const useQueryWarrantyClaimMessages = createUseQueryNodeHook<NodeType.WarrantyClaim>(QL);

export enum DiscussionPanelParticipantType {
    WARRANTY_REQUESTOR,
    WARRANTER,
}

export interface DiscussionPanelProps {
    claim: NodeType.WarrantyClaim;
    viewerType: DiscussionPanelParticipantType;
    maxHeight?: number;
    lastReadThreshold?: Date;
    refetchQueries?: any;
}

export const PanelDiscussion: React.FC<DiscussionPanelProps> = ({
    claim,
    viewerType,
    maxHeight,
    lastReadThreshold,
    refetchQueries: refetchQueriesExt,
}) => {
    const { loading, error, node, refetchQuery } = useQueryWarrantyClaimMessages(claim.id, {
        fetchPolicy: 'cache-and-network',
    });
    const mutator = useMutationWarrantyClaimMessage({
        awaitRefetchQueries: true,
        refetchQueries: refetchQueriesExt ? [refetchQueriesExt, refetchQuery] : [refetchQuery],
    });

    if (loading && !node) {
        return <PageSkeletonLoader />;
    } else if (!node) {
        return <NotFoundPage />;
    } else if (error) {
        return <div>Error</div>;
    }

    const messages: DiscussionMessage[] = fromEdges(node?.messages).map((m) => {
        const isUnread = Boolean(lastReadThreshold && new Date(m.createdAt) > lastReadThreshold);
        return { ...m, isUnread: isUnread };
    });

    const onUpdate = async (values: DiscussionMessage) => {
        try {
            await mutator.update({ id: values.id, message: values.message });
        } catch (e) {
            toastError(e);
        }
    };

    const onDelete = async (values: DiscussionMessage) => {
        try {
            await mutator.delete({ id: values.id });
        } catch (e) {
            toastError(e);
        }
    };

    const onAdd = async (values) => {
        await mutator.postMessage({
            claimId: claim.id,
            message: values.message,
        });
    };

    return (
        <Panel>
            <Panel.Header basic={true} size={'small'} icon={'sticky note outline'}>
                Discussion{' '}
                <IconWithPopup
                    name={'question circle'}
                    popup={{ content: 'Post a message. All participants will be notified via email.' }}
                />
            </Panel.Header>
            <Panel.Body>
                <Panel.Item label={'Public reply'}>
                    {viewerType === DiscussionPanelParticipantType.WARRANTY_REQUESTOR && (
                        <>
                            To: {claim.receiverWarranter?.name} {`<${claim.receiverWarranter?.email}>`}
                        </>
                    )}
                    {viewerType === DiscussionPanelParticipantType.WARRANTER && (
                        <>
                            To: {claim.franchise?.name} {`<${claim.emailReplyTo || claim.franchise?.email}>`}
                        </>
                    )}
                </Panel.Item>
                <MessagesFeed
                    maxHeight={maxHeight}
                    onUpdate={onUpdate}
                    onAdd={onAdd}
                    messages={messages}
                    onDelete={onDelete}
                />
            </Panel.Body>
        </Panel>
    );
};
