import * as React from 'react';
import { ConsumerProps, useContext } from 'react';
import { getAppConfig } from './get-app-config';

const config = getAppConfig();

type ConfigTypes = ReturnType<typeof getAppConfig>;

export type AppConfigContextType = ConfigTypes;

export const AppConfigContext = React.createContext<AppConfigContextType>(config);

export const useAppConfigContext = () => useContext(AppConfigContext);
export const useAppConfig = useAppConfigContext; // alias

export interface WithAppConfigProps {
    appConfig: AppConfigContextType;
}

export const AppConfig: React.FC<ConsumerProps<AppConfigContextType>> = (props) => {
    return <AppConfigContext.Consumer {...props} />;
};

export const withAppConfig =
    () =>
    (C: React.ElementType<WithAppConfigProps>): React.FC =>
    (props) => {
        const config = useAppConfigContext();
        return <C appConfig={config} {...props} />;
    };

export const AppConfigProvider: React.FC = (props) => {
    return <AppConfigContext.Provider value={config}>{props.children}</AppConfigContext.Provider>;
};
