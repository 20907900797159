import * as React from 'react';
import { NodeType } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Display, LinkButton, Panel, VStack } from '@poolware/components';
import { Message, Segment } from 'semantic-ui-react';

export const DisplayWarranter: React.FC<{ warranter?: NodeType.FranchiseWarranter }> = ({ warranter }) => {
    if (!warranter) {
        return <>--</>;
    }
    return (
        <span style={{ color: 'blue' }}>
            {warranter?.name} &lt;{warranter?.email}&gt;
        </span>
    );
};

export const WarrantyPreview: React.FC<{ claim: NodeType.WarrantyClaim }> = ({ claim }) => {
    const isSubmitted = Boolean(claim.submittedAt);
    const { AppNavigator } = useAppNavigator();

    const onViewCustomer = () => {
        AppNavigator.navigate(`/customers/${claim.refCustomer?.id}`, { query: { claimId: claim.id } });
    };

    return (
        <VStack>
            <Panel>
                <Panel.Header
                    color={isSubmitted ? 'green' : 'orange'}
                    content={`Warranty Claim ${!isSubmitted ? ' - DRAFT' : ''}`}
                />
                <Panel.Body>
                    <Panel.Item label={'Destination Office'}>
                        <DisplayWarranter warranter={claim.receiverWarranter} />
                    </Panel.Item>
                    <Panel.Item label={'Claim Number'}>{claim.warrantyClaimNumber}</Panel.Item>
                    <Panel.Item label={'Franchise'}>{claim.franchise?.name}</Panel.Item>
                    <Panel.Item label={'Reply-To Email'}>{claim.emailReplyTo}</Panel.Item>
                    <Panel.ItemEntity label={'Created By'} content={claim.addedBy} />
                    <Panel.Item label={'Submission Date'}>
                        {isSubmitted ? (
                            <Display.Date value={claim.submittedAt} />
                        ) : (
                            <i style={{ color: 'red' }}>This claim is not submitted yet</i>
                        )}
                    </Panel.Item>
                    <Panel.Item label={'From'}>{claim.emailReplyTo}</Panel.Item>
                </Panel.Body>
            </Panel>

            <Panel>
                <Panel.Header basic={true} content={'Customer Details'} />
                <Panel.Body>
                    <Panel.Item label={'Customer'}>
                        {claim.refCustomer ? (
                            <LinkButton onClick={onViewCustomer}>
                                <Display.Entity value={claim.refCustomer} />
                            </LinkButton>
                        ) : (
                            '--'
                        )}
                    </Panel.Item>
                    <Panel.ItemAddress label={'Address'} content={claim.address} />
                    <Panel.Item label={'Phone'} content={claim.customerPhone} />
                    <Panel.Item label={'Email'} content={claim.customerEmail} />
                </Panel.Body>
            </Panel>

            <Panel>
                <Panel.Header basic={true} content={'Product/Asset Details'} />
                <Panel.Body>
                    <Panel.Item content={claim.assetName || '--'} label={'Asset Name'} />
                    <Panel.Item content={claim.assetSerial || '--'} label={'Serial Number'} />
                    <Panel.ItemDate content={claim.claimedAt} label={'Claim Date'} />
                    <Panel.ItemDate content={claim.assetInstallDate} label={'Date of installation'} />
                    <Panel.Item content={claim.installerName || '--'} label={'Installer Supplier'} />
                    <Panel.ItemText
                        labelWidth={'100%'}
                        label={'Fault Description'}
                        content={claim.faultDescription || '--'}
                    />
                </Panel.Body>
            </Panel>

            <Panel>
                <Panel.Header basic={true} content={'Other Details'} />
                <Panel.Body>
                    <Panel.ItemText
                        labelWidth={'100%'}
                        label={'Comments and Special Instructions'}
                        content={claim?.comment || '--'}
                    />
                </Panel.Body>
            </Panel>
        </VStack>
    );
};
