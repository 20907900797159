import { InferProps } from 'prop-types';
import React, { useMemo } from 'react';
import * as dates from './utils/dates';
import { CalNavigate } from './types';
import TimeGrid from './TimeGrid';
import { useRbcContext } from './CalendarContext';

export interface WeekProps extends InferProps<typeof TimeGrid.propTypes> {}

const _range = (date, { localizer }) => {
    let firstOfWeek = localizer.startOfWeek();
    let start = dates.startOf(date, 'week', firstOfWeek);
    let end = dates.endOf(date, 'week', firstOfWeek);
    return dates.range(start, end);
};

const _title = (date, { localizer }) => {
    let [start, ...rest] = _range(date, { localizer });
    return localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};
const _navigate = (date: Date, action: CalNavigate) => {
    switch (action) {
        case CalNavigate.PREVIOUS:
            return dates.add(date, -1, 'week');
        case CalNavigate.NEXT:
            return dates.add(date, 1, 'week');
        default:
            return date;
    }
};

export const Week: React.FC<WeekProps> = (props) => {
    const { localizer } = useRbcContext();
    let { date, ...rest } = props;
    let range = useMemo(() => {
        return _range(date, { localizer });
    }, [date]);

    return <TimeGrid date={date} {...rest} range={range} eventOffset={15} />;
};

Week.propTypes = TimeGrid.propTypes;
Week.defaultProps = TimeGrid.defaultProps;
export default Object.assign(Week, { range: _range, title: _title, navigate: _navigate });
