import {
    NodeType,
    QueryConnectionConfigProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';
import * as _ from 'lodash';

const QL = gql`
    query QueryWorkOrderTemplateConnection(
        $pageSize: Int
        $page: Int
        $search: WorkOrderTemplateViewerWorkOrderTemplatesSearch
        $sort: WorkOrderTemplateViewerWorkOrderTemplatesSort
    ) {
        viewer {
            workOrderTemplates(first: $pageSize, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        templateTitle
                        ownedByServiceJobTemplate {
                            id
                        }
                        includedInWorkOrderTemplateAssocs {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                        jobTodoTemplateAssocs {
                            edges {
                                node {
                                    id
                                    priority
                                    jobTodoTemplate {
                                        id
                                        title
                                    }
                                }
                            }
                        }
                    }
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
`;

export interface QueryWorkdOrderTemplateConnectionProps extends Partial<QueryConnectionConfigProps> {
    showOwnedTemplates?: boolean;
}

const mapPropsToSearchQuery = (
    props: QueryWorkdOrderTemplateConnectionProps
): NodeType.WorkOrderTemplateViewerWorkOrderTemplatesSearch => {
    const search: NodeType.WorkOrderTemplateViewerWorkOrderTemplatesSearch = {
        ownedByServiceJobTemplate: {
            // Don't fetch owned templates by default;
            id: null,
        },
    };

    if (!props) {
        return search;
    }

    const { showOwnedTemplates } = props;

    if (showOwnedTemplates) {
        // If ownedByWorkOrderTemplate is not set, all templates (including owned) will be returned.
        _.unset(search, 'ownedByServiceJobTemplate');
    }

    return search;
};

export function useQueryWorkOrderTemplateConnection(props?: QueryWorkdOrderTemplateConnectionProps) {
    const connectionPath = 'viewer.workOrderTemplates';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultSortKey: 'createdAt',
        defaultSortDirection: SortDirection.DES,
        ...props,
    });

    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection({
        query: QL,
        // fetchPolicy: 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
