import * as React from 'react';
import _throttle from 'lodash/throttle';

import { Button, Segment } from 'semantic-ui-react';

interface FormButtonsProps {
    onSubmit: (any) => any | Promise<any>;
    onCancel: (any) => any | Promise<any>;
    submitButtonTitle: string;
    allButtonsDisabled?: boolean;
    submitButtonDisabled?: boolean;
    submitButtonLoading?: boolean;
}

export class FormButtons extends React.PureComponent<FormButtonsProps> {
    private readonly delayedSubmit: any;

    constructor(props, context) {
        super(props, context);
        // Delayed submit prevents double click action
        this.delayedSubmit = _throttle(this.onSubmit, 1000, { leading: true, trailing: false });
    }

    onSubmit = (...args) => {
        const { onSubmit } = this.props;
        onSubmit && onSubmit(args);
    };

    render() {
        const {
            onCancel,
            submitButtonTitle,
            submitButtonDisabled,
            submitButtonLoading,
            allButtonsDisabled,
        } = this.props;
        return (
            <Segment basic compact>
                <Button type="button" disabled={allButtonsDisabled} onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    type="button"
                    name="submitButton"
                    floated="right"
                    primary
                    disabled={submitButtonDisabled || allButtonsDisabled}
                    loading={submitButtonLoading}
                    onClick={this.delayedSubmit}
                >
                    {submitButtonTitle || 'Submit'}
                </Button>
            </Segment>
        );
    }
}

export default FormButtons;
