import * as React from 'react';
import gql from 'graphql-tag';
import { QueryStats, QueryStatsProps } from './QueryStats';

const QL_reportsVolumeTotal = gql`
    query GetFranchiseActivity($resolution: DateRangeResolutionEnum, $startDate: Date, $endDate: Date) {
        viewer {
            stats {
                reportsVolumeTotal(resolution: $resolution, startDate: $startDate, endDate: $endDate) {
                    edges {
                        node {
                            date
                            count
                            franchiseId
                            franchiseName
                        }
                    }
                }
            }
        }
    }
`;

const QL_reportsVolumePerFranchise = gql`
    query GetFranchiseActivity($resolution: DateRangeResolutionEnum, $startDate: Date, $endDate: Date) {
        viewer {
            stats {
                reportsVolumePerFranchise(resolution: $resolution, startDate: $startDate, endDate: $endDate) {
                    edges {
                        node {
                            date
                            count
                            franchiseId
                            franchiseName
                        }
                    }
                }
            }
        }
    }
`;

const QL_statsWaterTests = gql`
    query QueryStatsWaterTests(
        $resolution: DateRangeResolutionEnum
        $startDate: Date
        $endDate: Date
        $groupBy: StatsGroupByEnum
    ) {
        viewer {
            stats {
                waterTests(resolution: $resolution, startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
                    edges {
                        node {
                            date
                            count
                            franchise {
                                id
                                name
                            }
                            staff {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const QueryReportsVolumePerFranchise: React.FC<QueryStatsProps> = (props) => {
    return (
        <QueryStats
            query={QL_reportsVolumePerFranchise}
            queryPath={'viewer.stats.reportsVolumePerFranchise'}
            {...props}
        />
    );
};

export const QueryReportsVolumeTotal: React.FC<QueryStatsProps> = (props) => {
    return <QueryStats query={QL_reportsVolumeTotal} queryPath={'viewer.stats.reportsVolumeTotal'} {...props} />;
};

export const QueryStatsWaterTests: React.FC<QueryStatsProps> = (props) => {
    return <QueryStats query={QL_statsWaterTests} queryPath={'viewer.stats.waterTests'} {...props} />;
};
