import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';

const QL = gql`
    query QueryFranchiseWarranter($id: ID!) {
        node(id: $id) {
            id
            ... on FranchiseWarranter {
                id
                name
                email
                actingFranchise {
                    id
                    name
                    types
                }
                checkMutations {
                    delete
                    update
                }
            }
        }
    }
`;

export const useQueryFranchiseWarranter = createUseQueryNodeHook<NodeType.FranchiseWarranter>(QL);
