import { bindActionCreators, combineReducers } from 'redux';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { NodeType } from '@poolware/api';
import { createStructuredSelector } from 'reselect';
import { ActionsUnion, createAction, createReducer } from '@ez/tools';

//// ***** TYPES ****** ////

export type HeatCalcDetails = {
    customer?: NodeType.Customer;
    entity?: NodeType.Entity;
    staff?: NodeType.Staff;
    pool?: NodeType.Pool;
    address?: NodeType.Address;
    returnTo?: any;
    heaterRecommendation?: NodeType.HeaterRecommendation;
};

export enum CalcMode {
    OPEN_DOC = 'OPEN',
    NEW_DOC = 'NEW',
    DEFAULT = 'DEFAULT',
}

export interface HeatCalcDetailsStateType {
    details: HeatCalcDetails;
    mode: CalcMode;
}

const initialState: HeatCalcDetailsStateType = {
    details: {},
    mode: CalcMode.DEFAULT,
};

//// ***** ACTIONS ******** ////

export enum HEAT_CALC_ACTION {
    OPEN = '@HeatCalc/OPEN',
    NEW = '@HeatCalc/NEW',
    SAVE = '@HeatCalc/SAVE',
    RESET = '@HeatCalc/RESET',
    ABORT = '@HeatCalc/ABORT',
}

export const HeatCalcAction = {
    newCalc: (details?: HeatCalcDetails) => createAction(HEAT_CALC_ACTION.OPEN, { details, mode: CalcMode.NEW_DOC }),
    openCalc: (details?: HeatCalcDetails) => createAction(HEAT_CALC_ACTION.OPEN, { details, mode: CalcMode.OPEN_DOC }),
    abort: () => createAction(HEAT_CALC_ACTION.ABORT),
    reset: () => createAction(HEAT_CALC_ACTION.RESET),
};

export type HeatCalcAction = ActionsUnion<typeof HeatCalcAction>;

////******** REDUCERS **********////

const details = createReducer(initialState.details, (state, action: HeatCalcAction) => {
    switch (action.type) {
        case HEAT_CALC_ACTION.OPEN:
            // Reset state and set new payload
            return { ...initialState.details, ...action.payload.details };
        case HEAT_CALC_ACTION.RESET:
        case HEAT_CALC_ACTION.ABORT:
            return initialState.details;
        default:
            return state;
    }
});

const mode = createReducer(initialState.mode, (state, action: HeatCalcAction) => {
    switch (action.type) {
        case HEAT_CALC_ACTION.OPEN:
            if (action.payload.details?.customer && action?.payload?.mode === CalcMode.NEW_DOC) {
                return CalcMode.NEW_DOC;
            }
            return CalcMode.DEFAULT;
        case HEAT_CALC_ACTION.RESET:
        case HEAT_CALC_ACTION.ABORT:
            return CalcMode.DEFAULT;
        default:
            return state;
    }
});

export const reducer = combineReducers({
    details,
    mode,
});

// Selectors

const memoizedSelector = createStructuredSelector<HeatCalcDetailsStateType, HeatCalcDetailsStateType>({
    details: (state) => state?.details,
    mode: (state) => state?.mode,
});

export interface HeatCalcActionProps {
    HeatCalcAction: typeof HeatCalcAction;
    HeatCalcState: ReturnType<typeof memoizedSelector>;
}

export const useHeatCalcActions = (): HeatCalcActionProps => {
    const state = useSelector((state: RootStateOrAny) => memoizedSelector(state.heatCalcs.heater));
    const dispatch = useDispatch();

    return {
        HeatCalcState: state,
        HeatCalcAction: bindActionCreators(HeatCalcAction, dispatch),
    };
};
