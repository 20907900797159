import {
    NodeType,
    QueryConnectionConfigProps,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';

const defaultQL = gql`
    query QueryServiceJobGroupConnection(
        $first: Int
        $page: Int
        $search: ServiceJobGroupViewerServiceJobGroupsSearch
        $sort: ServiceJobGroupViewerServiceJobGroupsSort
    ) {
        viewer {
            serviceJobGroups(first: $first, page: $page, search: $search, sort: $sort) {
                edges {
                    node {
                        id
                        title
                        priority
                        organisationType {
                            id
                            name
                        }
                        franchise {
                            id
                            name
                        }
                        templates(first: $first) {
                            edges {
                                node {
                                    id
                                    templateTitle
                                }
                            }
                        }
                        parent {
                            id
                            title
                        }
                        children(first: $first) {
                            edges {
                                node {
                                    id
                                    title
                                    parent {
                                        id
                                        title
                                    }
                                    checkMutations {
                                        update
                                        delete
                                    }
                                    franchise {
                                        id
                                        name
                                    }
                                    templates {
                                        edges {
                                            node {
                                                id
                                                templateTitle
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        checkMutations {
                            update
                            delete
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
`;

export interface QueryServiceJobGroupConnectionProps extends Partial<QueryConnectionConfigProps> {
    organisationId?: string;
    franchiseId?: string;
    includeSubgroups?: boolean;
}

const mapPropsToSearchQuery = (
    props: QueryServiceJobGroupConnectionProps
): NodeType.ServiceJobGroupViewerServiceJobGroupsSearch => {
    let variables: NodeType.ServiceJobGroupViewerServiceJobGroupsSearch = {};
    if (props?.franchiseId) {
        variables.franchise = { id: props.franchiseId };
    }
    if (props?.organisationId) {
        variables.organisationType = { id: props.organisationId };
    }
    if (!props?.includeSubgroups) {
        variables.parent = { id: null };
    }

    return variables;
};

export function useQueryServiceJobGroupConnection(
    props?: QueryConnectionExternalProps<NodeType.ServiceJobGroup> & QueryServiceJobGroupConnectionProps
) {
    const connectionPath = 'viewer.serviceJobGroups';
    const { variables, connectionState } = useDefaultConnectionState({
        debugName: 'useQueryServiceJobGroupConnection',
        defaultSortKey: 'priority',
        defaultPageSize: 1000,
        defaultSortDirection: SortDirection.DES,
        ...props,
        search: mapPropsToSearchQuery(props),
    });

    return useQueryConnection<NodeType.ServiceJobGroup>({
        query: props?.query || defaultQL,
        fetchPolicy: props?.fetchPolicy,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
