import * as React from 'react';
import { useEffect, useRef } from 'react';
import { MessagesFeedEvent } from './MessagesFeedEvent';
import { MessagesFeedNewItem } from './MessagesFeedNewItem';
import { ScrollY } from '@poolware/components';
import { DiscussionMessage } from './types';

export interface NotesFeedProps {
    messages?: DiscussionMessage[];
    onUpdate: (values: DiscussionMessage) => any;
    onAdd: (values: DiscussionMessage) => any;
    onDelete: (values: DiscussionMessage) => any;
    maxHeight?: number;
}

export const MessagesFeed: React.FC<NotesFeedProps> = ({ onUpdate, onAdd, onDelete, messages, maxHeight = 480 }) => {
    const messagesEndRef = useRef(null);
    const scrollToStart = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTop = 0;
        }
    };
    useEffect(scrollToStart, [messages]);

    const hasNotes = messages?.length > 0;

    return (
        <div>
            <div style={hasNotes ? { marginBottom: '1rem' } : undefined}>
                <MessagesFeedNewItem onSubmit={onAdd} />
            </div>
            <ScrollY ref={messagesEndRef} maxHeight={maxHeight}>
                {messages.map((message) => {
                    return (
                        <MessagesFeedEvent
                            key={message.id}
                            onSubmit={onUpdate}
                            onDelete={onDelete}
                            messageNode={message}
                        />
                    );
                })}
            </ScrollY>
        </div>
    );
};
