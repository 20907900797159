import * as React from 'react';

import { Icon } from 'semantic-ui-react';

import { fromEdges, NodeType } from '@poolware/api';
import { PrintSectionItem, PrintTable } from '@poolware/components';

const PoolList: React.FC<{ pools: NodeType.Pool[] }> = ({ pools }) => {
    pools = fromEdges(pools);
    if (pools.length === 0) {
        return (
            <div style={{ color: '#555', padding: '0.1cm' }}>
                <i>No pools registered for this site</i>
            </div>
        );
    }

    return (
        <PrintTable.TABLE>
            <PrintTable.TBODY>
                {pools.map((pool) => {
                    const bottleNumber = pool.bottleNumber;
                    return (
                        <PrintTable.TR key={pool.id}>
                            <PrintTable.TD width={'4%'}>
                                <Icon name={'life ring'} />
                            </PrintTable.TD>
                            <PrintTable.TD width={'35%'}>
                                <PrintSectionItem label={'Pool Number'} content={bottleNumber} />
                                <PrintSectionItem label={'Pool Name'} content={pool.name || ''} />
                                <PrintSectionItem label={'Volume'} content={pool?.volume || ''} />
                            </PrintTable.TD>
                            <PrintTable.TD valign={'top'}>
                                <PrintSectionItem label={'Type'} content={pool?.type?.name || ''} />
                                <PrintSectionItem label={'Sanitiser'} content={pool?.sanitiser?.name || ''} />
                            </PrintTable.TD>
                        </PrintTable.TR>
                    );
                })}
            </PrintTable.TBODY>
        </PrintTable.TABLE>
    );
};

export default PoolList;
