import gql from 'graphql-tag';
import { appointmentItemFragment, jobTodoFragment } from '../../../queries/fragments';
import { createUseQueryNodeHook, customerContactDetailsFragment, NodeType } from '@poolware/api';

const QL = gql`
    query QueryWorkOrder($id: ID!) {
        node(id: $id) {
            id
            ... on WorkOrder {
                title
                workOrderNumber
                description
                stage {
                    id
                    title
                    type
                    status
                }
                address {
                    id
                    ...AddressFragment
                }
                pool {
                    id
                    name
                    address {
                        id
                        ...AddressFragment
                    }
                }

                customer {
                    id
                    ...CustomerContactDetailsFragment
                }
                assignedTo {
                    id
                    user {
                        id
                        firstName
                        lastName
                    }
                    role {
                        id
                        name
                    }
                }
                address {
                    ...AddressFragment
                }
                pool {
                    id
                    name
                    bottleNumber
                    volume
                    type {
                        id
                        name
                    }
                    site {
                        id
                        accessKeyLocation
                    }
                    sanitiser {
                        id
                        name
                    }
                }
                appointmentItem {
                    id
                    ...AppointmentItemFragment_ServiceJob
                }
                serviceJob {
                    id
                    title
                    serviceJobNumber
                    description
                    stage {
                        id
                        title
                        type
                        status
                    }
                    dueDate
                    isRecurring
                    address {
                        id
                        ...AddressFragment
                    }
                    customer {
                        id
                        ...CustomerContactDetailsFragment
                    }
                    pool {
                        id
                        name
                        bottleNumber
                        volume
                        type {
                            id
                            name
                        }
                        site {
                            id
                            accessKeyLocation
                        }
                        sanitiser {
                            id
                            name
                        }
                    }
                    createdBy {
                        id
                        user {
                            id
                            firstName
                            lastName
                            staff {
                                id
                            }
                        }
                    }
                    workOrders {
                        edges {
                            node {
                                id
                                workOrderNumber
                                title
                            }
                        }
                    }

                    createdAt
                    updatedAt
                }
                jobs {
                    edges {
                        node {
                            id
                            ...JobTodoFragment
                        }
                    }
                }
            }
        }
    }
    ${jobTodoFragment}
    ${customerContactDetailsFragment}
    ${appointmentItemFragment}
`;

export const useQueryWorkOrder = createUseQueryNodeHook<NodeType.WorkOrder>(QL);
