import * as React from 'react';
import {
    MenuBarHeaderItem,
    MenuBarSection,
    PageLayout,
    StickyMenuBar,
    SuspenseSkeletonLoader,
    VStack,
} from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { fromEdges, NodeType, useMutationWarrantyClaim } from '@poolware/api';
import {
    prepareInitValues,
    prepareMutation,
    WarrantyClaimForm,
    WarrantyClaimFormValues,
} from '../components/WarrantyClaimForm';
import { useQueryWarrantyClaim } from './use-query-warranty-claim';
import { NotFoundPage } from '@poolware/app-shell';
import { SubmissionSteps } from './SubmissionSteps';
import { Segment } from 'semantic-ui-react';

export interface PageProps {}

export const PageEdit: React.FC<PageProps> = () => {
    const { AppNavigator, params } = useAppNavigator<{ id?: string }>();
    const { node: warrantyClaim, data: formAuxData, error, loading, refetchQuery } = useQueryWarrantyClaim(params.id);
    const { update } = useMutationWarrantyClaim({
        refetchQueries: ['QueryWarrantyClaimsConnection', refetchQuery],
    });

    if (loading || error) {
        return <SuspenseSkeletonLoader error={error} loading={loading} />;
    }

    if (!loading && !warrantyClaim) {
        return <NotFoundPage />;
    }

    const goToView = () => {
        AppNavigator.navigateRelative(`/${warrantyClaim.id}`);
    };

    const onSubmit = async (values: WarrantyClaimFormValues) => {
        await update({
            id: warrantyClaim.id,
            ...prepareMutation(values),
        });
        goToView();
    };

    const offices = fromEdges<NodeType.FranchiseWarranter>(formAuxData.viewer?.franchiseWarranters);
    const initialValues: WarrantyClaimFormValues = prepareInitValues(warrantyClaim);

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'certificate'}>
                        Warranty Claim - {warrantyClaim.warrantyClaimNumber}
                    </MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>

            <SubmissionSteps step={'edit'} />

            <PageLayout.BodySection width={'screen-lg'}>
                <WarrantyClaimForm
                    header={'Edit Warranty Claim Request'}
                    icon={'certificate'}
                    offices={offices}
                    initialValues={initialValues}
                    submitButton={{ content: 'Save & Review' }}
                    onSubmit={onSubmit}
                    onCancel={goToView}
                />
            </PageLayout.BodySection>
        </PageLayout>
    );
};
