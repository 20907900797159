import * as React from 'react';
import { SemanticICONS } from 'semantic-ui-react';
import { Sidebar } from '@ez/components';
import { NavLink } from 'react-router-dom';
import { LinkButton, AppSidebarConfig, AppSidebarMenuGroup } from '@poolware/components';
import { useAppUpdater } from '../AppUpdater';

// export type Maybe<P> = P | null | undefined | false;

// export interface AppSidebarMenuItem {
//     to: string;
//     exact?: boolean;
//     icon: SemanticICONS;
//     secondaryIcon?: SemanticICONS;
//     label: string | React.ReactNode;
//     as: typeof SidebarMenuItemExternalLink | typeof SidebarMenuItemNavLink | typeof SidebarMenuItemButton;
//     sub?: boolean;
//     [key: string]: any;
// }

// export interface AppSidebarMenuGroup {
//     header: string;
//     items: Maybe<AppSidebarMenuItem>[];
// }

// export interface AppSidebarConfig {
//     logo?: {
//         image: string;
//         icon: string;
//     };
//     menu: Maybe<AppSidebarMenuGroup>[];
// }

const ExternalLinkComp: React.FC<{ to; className }> = ({ to, children, className }) => (
    <a href={to} className={className} children={children} />
);

const ReloadableLinkComp = React.forwardRef<any, any>(({ to, router, onClick, exact = false, ...rest }, ref) => {
    const appUpdater = useAppUpdater();
    return (
        <div ref={ref}>
            <NavLink
                to={to}
                exact={exact}
                {...rest}
                onClick={(e) => {
                    try {
                        if (appUpdater.pendingReload) {
                            e.preventDefault();
                            return appUpdater.reloadPage(to);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    onClick && onClick();
                }}
            />
        </div>
    );
});

export const SidebarMenuItemExternalLink = Sidebar.createMenuItem(ExternalLinkComp);
export const SidebarMenuItemNavLink = Sidebar.createMenuItem(ReloadableLinkComp);
export const SidebarMenuItemButton = Sidebar.createMenuItem(LinkButton);
