import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { FormikHeatCalcValueType, PoolCover } from '../common/types';
import { computeGasHeaterHeatProfile } from './calc-heat-profile';
import { computeRecommendedGasHeaterModel } from './calc-heater-model';
import { PagePrint } from './print/PagePrint';
import { computeCostEstimationGasHeater } from './calc-cost-estimation';
import { useCatalog } from '../common/use-catalog';
import { CalcLayoutConfigurable, LayoutConfig } from '../common/CalcLayout';
import {
    FormikGasHeaterTempRequirements,
    FormikPoolProfile,
    FormikSelectLocationYearProfile,
} from '../common/FormikInputComponents';
import { FormikDocTypeToggleButtons } from '../common/FormikDocTypeToggleButtons';
import { FormikMonthlyDataTable } from '../common/FormikMonthlyDataTable';
import { FormikEquipmentOptionsTable } from '../common/FormikEquipmentOptionsTable';
import { PanelDocumentDetails } from '../common/PanelDocumentDetails';
import { PanelHeaterRecommendation } from '../common/PanelHeaterRecommendation';
import { PanelProductBrochure } from '../common/PanelProductBrochure';
import { FormikQuickMonthSelectButtons } from '../common/FormikQuickMonthSelectButtons';

interface GasHeaterCalcProps {
    print?: boolean;
}

export const GasHeaterCalc: React.FC<GasHeaterCalcProps> = ({ print }) => {
    const { values, setFieldValue } = useFormikContext<FormikHeatCalcValueType>();
    const { gasHeaters } = useCatalog();

    const poolProfileSettings = useMemo(
        () => ({
            yearProfile: values.yearProfile,
            selectedMonths: values.selectedMonths,
            requirePoolTemp: values.poolTemp,
            lowestAirTemp: values.lowestAirTemp,
            poolType: values.poolType,
            poolVolume: values.poolVolume,
            poolLocation: values.poolLocation,
            poolCover: values.poolCover,
            poolTemp: values.poolTemp,
            poolSurfaceAreaTop: values.poolSurfaceAreaTop,
            operatingHours: values.operatingHours,
            windProfile: values.windProfile,
        }),
        [
            values.selectedMonths,
            values.yearProfile,
            values.operatingHours,
            values.poolType,
            values.poolVolume,
            values.poolLocation,
            values.poolTemp,
            values.lowestAirTemp,
            values.poolCover,
            values.poolSurfaceAreaTop,
            values.gasCost,
            values.poolTemp,
            values.windProfile,
        ]
    );

    const monthlyHeatGasProfiles = useMemo(() => {
        return computeGasHeaterHeatProfile(poolProfileSettings);
    }, [poolProfileSettings]);

    const recommendations = useMemo(
        () =>
            computeRecommendedGasHeaterModel({
                monthlyHeatProfiles: monthlyHeatGasProfiles,
                heaterCatalog: gasHeaters.models,
            }),
        [monthlyHeatGasProfiles, gasHeaters]
    );

    useEffect(() => {
        setFieldValue('autoRecommendedModel.gas', recommendations.recommendedModel);
    }, [recommendations?.recommendedModel]);

    const selectedModel = values.userSelectedModel?.gas || recommendations?.recommendedModel;

    const costEstimation = useMemo(
        () =>
            computeCostEstimationGasHeater({
                gasHeatProfile: monthlyHeatGasProfiles,
                selectedModel: selectedModel,
                withCover: poolProfileSettings.poolCover === PoolCover.Yes,
                gasCost: values.gasCost,
                operatingHours: poolProfileSettings.operatingHours,
            }),
        [monthlyHeatGasProfiles, selectedModel, poolProfileSettings]
    );

    useEffect(() => {
        setFieldValue('costEstimation.gas', costEstimation);
    }, [costEstimation]);

    if (print) {
        return <PagePrint monthlyHeatProfiles={monthlyHeatGasProfiles} costEstimation={values.costEstimation} />;
    }

    const conf: LayoutConfig = {
        left: [
            <FormikSelectLocationYearProfile />, //
            <FormikPoolProfile />,
            <FormikGasHeaterTempRequirements />,
        ],
        middle: [
            <FormikDocTypeToggleButtons />,
            <FormikQuickMonthSelectButtons />,
            <FormikMonthlyDataTable monthlyHeatProfiles={monthlyHeatGasProfiles} />,
            <FormikEquipmentOptionsTable />,
        ],
        right: [
            <PanelDocumentDetails />, //
            <PanelHeaterRecommendation />,
            <PanelProductBrochure />,
        ],
    };

    return <CalcLayoutConfigurable config={conf} />;
};
