import { combineReducers } from 'redux';
import { AUTH_ACTION, USER_ACTION } from '../actions';
import * as _ from 'lodash';
import { REST } from '../../rest-api/types';

/// ***** TYPES ***** ///
export type UserProfile = REST.UserProfile & {
    username: 'guest';
};

export type UserFeatureAccess = {
    rawFeatureFlags: REST.FeatureFlag[];
    flags: REST.FlagTag[];
};

/// **** REDUCERS **** ////

const unauthenticatedUser: UserProfile = {
    username: 'guest',
};
const user = (state = unauthenticatedUser, { type, payload }): UserProfile => {
    switch (type) {
        case USER_ACTION.FETCH_PROFILE:
            return state;
        case USER_ACTION.FETCH_PROFILE_SUCCESS:
            let data = payload;
            return { ...data, username: data.email || 'guest' };
        case USER_ACTION.FETCH_PROFILE_FAILURE:
        case AUTH_ACTION.UNAUTHENTICATED:
            return unauthenticatedUser;
        default:
            return state;
    }
};

const defaultUserAccess: UserFeatureAccess = {
    rawFeatureFlags: [],
    flags: [],
};

const userFeatureAccess = (state = defaultUserAccess, { type, payload }): UserFeatureAccess => {
    const userProfile: REST.UserProfile = payload || {};

    switch (type) {
        case USER_ACTION.FETCH_PROFILE_SUCCESS:
            const { flags = [] } = userProfile;
            return {
                rawFeatureFlags: flags,
                flags: flags.map((f) => f.tag),
            };
        case AUTH_ACTION.UNAUTHENTICATED:
        // fallthrough
        case USER_ACTION.FETCH_PROFILE_FAILURE:
            return defaultUserAccess;

        default:
            return state;
    }
};

const isAuthenticating = (state = false, action) => {
    switch (action.type) {
        case AUTH_ACTION.LOGIN_REQUEST:
            return true;
        case AUTH_ACTION.LOGIN_SUCCESS:
        case AUTH_ACTION.LOGIN_FAILURE:
            return false;
        default:
            return state;
    }
};

const isAuthenticated = (state = false, action) => {
    switch (action.type) {
        case AUTH_ACTION.AUTHENTICATED:
            return true;
        case AUTH_ACTION.UNAUTHENTICATED:
            return false;
        default:
            return state;
    }
};

const isFetchingProfile = (state = false, action) => {
    switch (action.type) {
        case USER_ACTION.FETCH_PROFILE:
            return true;
        case USER_ACTION.FETCH_PROFILE_FAILURE:
        // fallthrough
        case USER_ACTION.FETCH_PROFILE_SUCCESS:
            return false;
        default:
            return state;
    }
};

const isProfileReady = (state = false, action) => {
    switch (action.type) {
        case USER_ACTION.FETCH_PROFILE_SUCCESS:
            return true;
        case USER_ACTION.FETCH_PROFILE_FAILURE:
        case AUTH_ACTION.LOGOUT_REQUEST:
        case AUTH_ACTION.UNAUTHENTICATED:
            return false;
        case USER_ACTION.FETCH_PROFILE:
            return state;
        default:
            return state;
    }
};

const authError = (state = null, { type, payload }) => {
    switch (type) {
        case AUTH_ACTION.LOGIN_FAILURE:
            return payload;
        case AUTH_ACTION.LOGIN_REQUEST:
        case AUTH_ACTION.LOGIN_SUCCESS:
            return null;
        default:
            return state;
    }
};

export const reducer: any = combineReducers({
    user,
    isAuthenticated,
    isAuthenticating,
    isFetchingProfile,
    isProfileReady,
    authError,
    userFeatureAccess,
});

export const getIsAuthenticating = (state): boolean => state.isAuthenticating;
export const getIsAuthenticated = (state): boolean => state.isAuthenticated;
export const getIsFetchingProfile = (state): boolean => state.isFetchingProfile;
export const getIsProfileReady = (state): boolean => state.isProfileReady;
export const getUser = (state): UserProfile => state.user || unauthenticatedUser;
export const getFeatureAccess = (state): UserFeatureAccess => state.userFeatureAccess;
export const getAuthError = (state): string | null => _.toString(_.get(state.authError, 'errorMessage'));

export const canAccessFeature = (state) => (feature: string): boolean => {
    const { flags } = state.userFeatureAccess;
    return _.includes(flags, feature);
};
