import * as React from 'react';

import { AppErrorBoundary } from '@poolware/app-shell';

export class PrintPageLayout extends React.Component<any> {
    render() {
        return (
            <AppErrorBoundary>
                <main className={'page-print'}>{this.props.children}</main>
            </AppErrorBoundary>
        );
    }
}

export default PrintPageLayout;
