import * as React from 'react';
import { NotFoundPage, PageContent } from '@poolware/app-shell';
import { ModuleRedirect, ModuleRootSwitch, ModuleRoute, useAppNavigator } from '@poolware/react-app-navigator';
import ServiceJobsRecurList from './ServiceJobRecurList';
import ServiceJobsSingleList from './ServiceJobSingleList';
import { WorkOrderFeed } from './WorkOrdersFeed';
import { WorkOrdersList } from './WorkOrdersList';
import { PageViewDispatcher, ServiceJobSinglePageView } from './ServiceJobSingleView';
import { WorkOrderNew } from './WorkOrderNew/WorkOrderNew';
import { WorkOrderView } from './WorkOrderView';
import { ServiceJobRecurPageView } from './ServiceJobRecurView';
import { ServiceJobSingleNew } from './ServiceJobSingleNew';
import { ServiceJobRecurNew } from './ServiceJobRecurNew';
import { Router as InvoicesRouter } from './Invoice';

const StubPage = () => {
    const nav = useAppNavigator();
    return (
        <div>
            Stub Page <button onClick={() => nav.history.goBack()}>go back</button>
        </div>
    );
};

export const Router = () => {
    return (
        <PageContent pageWidth={'full'}>
            <ModuleRootSwitch notFound={NotFoundPage}>
                <ModuleRedirect path={'/'} exact to={'/sj/single'} />
                <ModuleRedirect path={'/sj/'} exact to={'/sj/single'} />

                <ModuleRoute path={'/sj/single/new'} component={ServiceJobSingleNew} />
                <ModuleRoute path={`/sj/single/:sjId`} component={ServiceJobSinglePageView} />
                <ModuleRoute path={'/sj/single'} component={ServiceJobsSingleList} />
                <ModuleRoute path={'/sj/recur/new'} component={ServiceJobRecurNew} />
                <ModuleRoute path={`/sj/recur/:sjId`} component={ServiceJobRecurPageView} />
                <ModuleRoute path={'/sj/recur'} component={ServiceJobsRecurList} />
                <ModuleRoute path={`/sj/:sjId`} component={PageViewDispatcher} />

                <ModuleRoute path={`/wo/new`} component={WorkOrderNew} />
                <ModuleRoute path={`/wo/:woId`} component={WorkOrderView} />
                <ModuleRoute path={`/wo-feed`} component={WorkOrderFeed} />
                <ModuleRoute path={`/wo`} component={WorkOrdersList} />

                <ModuleRoute path={`/invoices`} component={InvoicesRouter} />

                <ModuleRoute path={`/stub/:id`} component={StubPage} />
            </ModuleRootSwitch>
        </PageContent>
    );
};
