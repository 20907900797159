import { NodeType } from '@poolware/api';
import moment from 'moment';

export const patternToString = (pattern: NodeType.RecurrencePattern) => {
    if (!pattern) {
        console.error('pattern is not provided');
        return '';
    }
    let type = '';
    let unit = '';
    const interval = pattern.interval;
    const plural = pattern.interval > 1;
    switch (pattern.type) {
        case NodeType.PatternTypeEnum.Daily:
            type = 'daily';
            unit = plural ? 'days' : 'day';
            return `Repeats every ${interval} ${unit}.`;
        case NodeType.PatternTypeEnum.Weekly:
            const { daysOfWeek = [] } = pattern;
            type = `${daysOfWeek?.join(', ')}`;
            unit = plural ? 'weeks' : 'week';
            return `Repeats every ${interval} ${unit} (${type}).`;
        case NodeType.PatternTypeEnum.Monthly:
            type = 'monthly';
            unit = plural ? 'months' : 'month';
            return `Repeats every ${interval} ${unit}.`;
        case NodeType.PatternTypeEnum.AbsoluteMonthly:
            const { dayOfMonth } = pattern;
            unit = plural ? 'months' : 'month';
            return `Repeats every ${interval} ${unit}, on ${dayOfMonth} day of month.`;
        case NodeType.PatternTypeEnum.RelativeMonthly:
            return `Repeats every ${interval} ${unit}`;
    }

    return '';
};

export const recurrenceToString = (recurrence: NodeType.Recurrence) => {
    if (!recurrence) {
        return '';
    }

    const rangeToString = (range: NodeType.RecurrenceRange) => {
        if (!range) {
            console.error('range is not provided');
            return '';
        }
        switch (range.type) {
            case NodeType.RecurrenceTypeEnum.EndDate:
                return `Ends on ${moment(range.endDate).format('ll')}.`;
            case NodeType.RecurrenceTypeEnum.NoEnd:
                return `Never ends.`;
            case NodeType.RecurrenceTypeEnum.Numbered:
                const { numberOfOccurrences } = range;
                const unit = numberOfOccurrences > 1 ? 'occurrences' : 'occurrence';
                return `Ends after ${numberOfOccurrences} ${unit}.`;
        }

        return '';
    };

    return `${patternToString(recurrence.pattern)} ${rangeToString(recurrence.range)}`;
};

export const apptItemRecurrenceToString = (appointmentItem: NodeType.AppointmentItem) => {
    if (appointmentItem?.appointment?.recurrence) {
        return recurrenceToString(appointmentItem?.appointment?.recurrence);
    } else {
        return recurrenceToString(appointmentItem?.appointment?.parentAppointment?.recurrence);
    }
};

export const extractRecurrenceData = (appointmentItem: NodeType.AppointmentItem) => {
    const patternBlockId =
        appointmentItem?.patternBlockId ||
        appointmentItem?.appointment?.patternBlockId ||
        appointmentItem?.appointment?.parentAppointment?.patternBlockId;

    const isRecurring = Boolean(
        !!patternBlockId ||
            appointmentItem?.isRecurring ||
            appointmentItem?.isRecurrentOrigin ||
            appointmentItem?.serviceJob?.isRecurring
    );

    const recurrence =
        appointmentItem?.appointment?.recurrence || appointmentItem?.appointment?.parentAppointment?.recurrence;

    return {
        isRecurring,
        patternBlockId,
        recurrence,
        isRecurringService: appointmentItem?.serviceJob?.isRecurring,
    };
};
