import * as React from 'react';
import { compose } from '@ez/tools';
import {
    FormikDatePickerInputField,
    FormikInputField,
    FormikPanelForm,
    FormikTextareaField,
    MenuBarDropdown,
    MenuBarDropdownItemWithConfirm,
    MenuBarItem,
    MenuBarSection,
    Panel,
    SectionHeader,
    StickyMenuBar,
    VStack,
} from '@poolware/components';
import { fromEdges, IMutateItem, NodeType, withItemMutator } from '@poolware/api';
import * as URLBuilder from '../../../../routes/url-builder';
import { BrandLabel } from '../RegisterNew/components';
import { withItemQuery, WithItemQueryProps } from '../withItemQuery';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { Container, Divider, Header, Segment } from 'semantic-ui-react';

interface DeletePermission {
    deletable: boolean;
    reason?: string;
}

const DropdownItemDeleteButton: React.FC<{ onDelete: any; deletePermission: DeletePermission }> = ({
    onDelete,
    deletePermission,
}) => {
    return (
        <MenuBarDropdownItemWithConfirm
            icon={'trash'}
            color="orange"
            title="Delete Equipment"
            onClick={onDelete}
            disabled={!deletePermission.deletable}
            popup={!deletePermission.deletable ? { content: deletePermission.reason } : undefined}
            confirm={{
                confirmMessage: {
                    header: 'Delete Equipment?',
                    content: 'There is NO UNDO. This will permanently remove equipment from this pool.',
                },
                confirmButton: {
                    content: 'Delete',
                    icon: 'trash',
                    negative: true,
                },
            }}
        />
    );
};

interface EquipmentTableProps extends WithItemQueryProps, IAppNavigatorProps, IMutateItem {}

const EquipmentItemDetails: React.FC<EquipmentTableProps> = (props) => {
    const { item } = props;

    const itemLabel = item?.label;
    const productName = item?.product?.name;
    const brand = item?.product?.brand;
    const traits = fromEdges(item?.product?.traits);
    const declarationsString = traits.map((t) => t?.declaration?.name || '').join(', ');

    const onGoBack = () => {
        const customerLink = URLBuilder.Customer(props.params.customerId).Pool(props.params.poolId).view;
        props.AppNavigator.navigateToOrigin(customerLink);
    };

    const initialValues = {
        ...item,
    };

    const deletePermission = () => {
        const deletePermission = item.checkMutations.delete;
        if (!deletePermission) {
            return {
                deletable: false,
                reason: 'Not enough permissions',
            };
        } else {
            return {
                deletable: true,
                reason: undefined,
            };
        }
    };

    const onDelete = async (item: NodeType.Item) => {
        await props.mutateItem.delete({ id: item.id });
        props.AppNavigator.replaceToOrigin();
    };

    const onSubmit = async (values: typeof initialValues, actions) => {
        const updateFields = {
            id: values.id,
            note: values.note,
            label: values.label,
            serial: values.serial,
            installedAt: values.installedAt,
        };
        await props.mutateItem.update(updateFields);
        actions.resetForm({ values: { ...values, ...updateFields } });
    };

    const headerString = itemLabel ? `${productName}  (${itemLabel})` : `${productName}`;

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem title={'Back'} icon={'chevron left'} onClick={onGoBack} />
                </MenuBarSection>
                <MenuBarSection position={'right'}>
                    <MenuBarDropdown title={'More...'} icon={'wrench'}>
                        <DropdownItemDeleteButton
                            onDelete={() => onDelete(item)}
                            deletePermission={deletePermission()}
                        />
                    </MenuBarDropdown>
                </MenuBarSection>
            </StickyMenuBar>

            <Container text={true}>
                <SectionHeader content={headerString} />
                <FormikPanelForm header={'Equipment Asset'} initialValues={initialValues} onSubmit={onSubmit}>
                    <Segment>
                        <Header>Product</Header>
                        <Panel.Item label={'Product Name'}>{productName}</Panel.Item>
                        <Panel.Item label={'Brand'}>
                            <BrandLabel brand={brand} />
                        </Panel.Item>
                        <Panel.Item label={'Categories'}>{declarationsString}</Panel.Item>
                    </Segment>

                    <Divider />

                    <Header>Equipment Details</Header>
                    <FormikInputField name={'serial'} label={'Serial Number'} />
                    <FormikInputField name={'label'} label={'Equipment Label'} />
                    <FormikDatePickerInputField name={'installedAt'} label={'Installation Date'} />
                    <FormikTextareaField name={'note'} label={'Equipment Notes'} rows={10} />

                    <div style={{ display: 'flex', alignItems: 'baseline', fontSize: 'small', color: '#9e9e9e' }}>
                        <div style={{ paddingRight: '0.5em' }}>Asset UUID</div>
                        <pre>{item.uuid}</pre>
                    </div>
                </FormikPanelForm>
            </Container>
        </VStack>
    );
};

export default compose(
    withAppNavigator(),
    withItemQuery((props) => props.params.itemId),
    withItemMutator(['QueryPoolAndPoolEquipmentDetails'])
)(EquipmentItemDetails);
