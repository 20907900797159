import * as React from 'react';
import { CalendarZoomFactor, useCalendarActions } from '../../redux';
import { IconButton } from '../Components/IconButton';

export interface CalendarTimeGutterHeaderProps {}

export const CalendarTimeGutterHeader: React.FC<CalendarTimeGutterHeaderProps> = ({}) => {
    const { CalendarState, CalendarAction } = useCalendarActions();
    const zooms: CalendarZoomFactor[] = [1, 2, 3, 4, 5, 6, 7, 8];

    const onZoomIn = () => {
        const nextValue = zooms.find((z) => z === CalendarState.zoomFactor + 1);
        if (nextValue) {
            CalendarAction.setZoomFactor(nextValue);
        }
    };
    const onZoomOut = () => {
        const nextValue = zooms.find((z) => z === CalendarState.zoomFactor - 1);
        if (nextValue) {
            CalendarAction.setZoomFactor(nextValue);
        }
    };

    return (
        <div tw={'flex flex-row pt-1 items-center justify-evenly'}>
            <IconButton style={{ padding: 0 }} onClick={onZoomOut} icon={'zoom out'} />
            <IconButton style={{ padding: 0 }} onClick={onZoomIn} icon={'zoom in'} />
        </div>
    );
};
