import { NodeType } from '@poolware/api';
import * as React from 'react';
import { Icon, IconProps } from 'semantic-ui-react';

export enum ModuleIconNames {
    Staff = 'spy',
    Appointment = 'calendar alternate outline',
    ServiceCall = 'briefcase',
    ServiceGroup = 'folder',
    RecurringService = 'sync',
    Customer = 'user',
    ServiceCase = 'briefcase',
    WorkOrder = 'clipboard',
    WorkOrderItem = 'clipboard list',
    WorkOrderItemTask = 'checkmark box',
    JobTodoTemplate = 'clone outline',
    Invoice = 'credit card outline',
}

export enum ModuleColorNames {
    Appointment = 'blue',
    ServiceGroup = 'blue',
    ServiceCall = 'orange',
    RecurringService = 'pink',
    WorkOrder = 'blue',
    WorkOrderItem = 'purple',
    WorkOrderItemTask = 'black',
    JobTodoTemplate = 'black',
    StatusChangeButton = 'violet',
    Invoice = 'violet',
}

export enum RecurServiceJobStatus {
    Active = 0,
    Stopped = 1,
}

export const mapServiceJobRecurStatusToName = (status: RecurServiceJobStatus) => {
    switch (status) {
        case RecurServiceJobStatus.Active:
            break;
        case RecurServiceJobStatus.Stopped:
            break;
        default:
            return status;
    }
};

export const mapServiceJobRecurStatusToIconProps = (status: RecurServiceJobStatus): IconProps => {
    switch (status) {
        case RecurServiceJobStatus.Active:
            return { name: 'dot circle', color: 'green' };
        case RecurServiceJobStatus.Stopped:
            return { name: 'stop', color: 'grey' };
        default:
            return status;
    }
};

export const mapServiceJobStageStatusToStageTypes = (
    st: NodeType.ServiceJobStageStatus
): NodeType.ServiceJobStageType[] => {
    switch (st) {
        case NodeType.ServiceJobStageStatus.Active:
            return [
                NodeType.ServiceJobStageType.Opened,
                NodeType.ServiceJobStageType.InProgress,
                NodeType.ServiceJobStageType.ActionRequired,
            ];
        case NodeType.ServiceJobStageStatus.Suspended:
            return [NodeType.ServiceJobStageType.Suspended];
        case NodeType.ServiceJobStageStatus.Closed:
            return [NodeType.ServiceJobStageType.Finished, NodeType.ServiceJobStageType.Canceled];
    }
};

export const mapServiceJobStageTypeToIconProps = (status: NodeType.ServiceJobStageType): IconProps => {
    switch (status) {
        default:
        case NodeType.ServiceJobStageType.Opened:
            return { name: 'circle outline', color: 'blue' };
        case NodeType.ServiceJobStageType.InProgress:
            return { name: 'dot circle outline', color: 'blue' };
        case NodeType.ServiceJobStageType.Finished:
            return { name: 'check circle outline', color: 'green' };
        case NodeType.ServiceJobStageType.Suspended:
            return { name: 'pause', color: 'grey' };
        case NodeType.ServiceJobStageType.Canceled:
            return { name: 'ban', color: 'grey' };
        case NodeType.ServiceJobStageType.ActionRequired:
            return { name: 'alarm', color: 'orange' };
    }
};

export const mapServiceJobStageTypeToName = (status: NodeType.ServiceJobStageType): string => {
    switch (status) {
        default:
        case NodeType.ServiceJobStageType.Opened:
            return 'Opened';
        case NodeType.ServiceJobStageType.InProgress:
            return 'In Progress';
        case NodeType.ServiceJobStageType.Finished:
            return 'Finished';
        case NodeType.ServiceJobStageType.Suspended:
            return 'Suspended';
        case NodeType.ServiceJobStageType.Canceled:
            return 'Cancelled';
        case NodeType.ServiceJobStageType.ActionRequired:
            return 'Action Required';
    }
};

export const IconServiceJobStage: React.FC<{ stage?: NodeType.ServiceJobStage }> = ({ stage }) => {
    if (!stage) return null;
    return <Icon {...mapServiceJobStageTypeToIconProps(stage?.type)} />;
};

export const jobStageTypes: NodeType.ServiceJobStageType[] = [
    NodeType.ServiceJobStageType.Opened,
    NodeType.ServiceJobStageType.InProgress,
    NodeType.ServiceJobStageType.ActionRequired,
    NodeType.ServiceJobStageType.Suspended,
    NodeType.ServiceJobStageType.Finished,
    NodeType.ServiceJobStageType.Canceled,
];

export const jobStageTypeOptions = jobStageTypes.map((s) => ({
    value: s,
    icon: mapServiceJobStageTypeToIconProps(s),
    text: mapServiceJobStageTypeToName(s),
}));

export const UNASSIGNED_APPOINTMENT_STAFF_ID = '__UNASSIGNED_APPOINTMENT_STAFF_ID__';
export const DEFAULT_APPOINTMENT_GROUP_ID = '__DEFAULT_AG_GROUP_ID__';

export type DateRange = {
    startDate: Date;
    endDate: Date;
};

export const ServiceJobModuleRouterID = 'SERVICE_JOBS';
export const SchedulerModuleRouterID = 'SCHEDULER';
