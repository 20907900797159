import * as React from 'react';
import gql from 'graphql-tag';
import {
    NodeType,
    QueryConnection,
    QueryConnectionConfigProps,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
} from '@poolware/api';

const connectionPath = 'viewer.poolProductDeclarations';
const QL = gql`
    query PoolProductDeclarations($first: Int, $page: Int) {
        viewer {
            poolProductDeclarations(first: $first, page: $page) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        name
                        optional
                        priority
                        declaration {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export interface QueryPoolProductDeclarationsConnectionProps
    extends QueryConnectionExternalProps<NodeType.PoolProductDeclaration> {}

export const QueryPoolProductDeclarationsConnection: React.FC<QueryPoolProductDeclarationsConnectionProps> = (
    props
) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    return (
        <QueryConnection
            query={props.query || QL}
            variables={variables}
            connectionConfig={connectionState}
            connectionPath={connectionPath}
            children={props.children}
        />
    );
};

export const withQueryPoolProductDeclarationsConnection = (options: Partial<QueryConnectionConfigProps>) => (C) => {
    return (props) => (
        <QueryPoolProductDeclarationsConnection {...options}>
            {(connectionProps) => {
                return <C {...props} {...connectionProps} />;
            }}
        </QueryPoolProductDeclarationsConnection>
    );
};
