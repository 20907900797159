import * as React from 'react';
import { Button, ButtonProps, Checkbox, Icon, Label } from 'semantic-ui-react';
import { PillSelect, PillSelectOption } from './prepare-ui-model';
import styled from 'styled-components';

export interface FilterPillProps {
    typeName: string;
    filterValue: string;
    onClick: () => any;
}

export const PillComponents: React.FC<FilterPillProps> = ({ typeName, filterValue, onClick }) => {
    return (
        <Label color={'blue'}>
            <span style={{ textTransform: 'uppercase', color: 'white' }}> {typeName} </span> : {filterValue}
            <Icon name="delete" onClick={onClick} />
        </Label>
    );
};

export const PillsOptionButton: React.FC<PillSelectOption & ButtonProps> = ({
    active,
    onClick,
    name,
    basic,
    ...rest
}) => {
    const style = active ? { backgroundColor: '#2185d0', color: 'white' } : undefined;
    // Don't set `basic` prop if `active` flat is set;
    const isBasic = basic !== undefined && active ? false : basic;
    return (
        <Button {...rest} size={'mini'} style={style} onClick={onClick} basic={isBasic}>
            {name}
        </Button>
    );
};

const TwoColumns = styled.div<{ breakPoint?: number }>`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    ${(props) => {
        if (props.breakPoint) {
            return `
            @media (max-width: ${props.breakPoint}px) {
               flex-direction: column;
            }`;
        }
    }}
`;

const LabelContainer = styled.div`
    min-width: 180px;
    font-size: 0.9em;
    margin-right: 1em;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    &&& > * {
        margin-top: 0.5em;
    }
`;

const ButtonSeparator = styled.div`
    display: inline-block;
    margin-left: 0.5em;
    margin-right: 0.5em;
    border-left: 1px solid #d4d4d4;
    width: 1px;
`;

export interface PillsSelectRowProps {
    label: string | React.ReactNode;
    select: PillSelect;
}

export const PillsSelectRow: React.FC<PillsSelectRowProps> = ({ label, select }) => {
    const { options, invertOption } = select;
    return (
        <TwoColumns breakPoint={680}>
            <LabelContainer>{label}</LabelContainer>
            <ButtonsContainer>
                {options.map((o, ii) => (
                    <PillsOptionButton key={ii} {...o} />
                ))}

                {invertOption && (
                    <>
                        <ButtonSeparator />
                        <PillsOptionButton basic={true} {...invertOption} />
                    </>
                )}
            </ButtonsContainer>
        </TwoColumns>
    );
};

export const TraitDeclarationGroup = ({ label, filterValue, onClick }) => {
    const isChecked = !!filterValue;
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ minWidth: '150px', fontSize: '0.8em' }}>{label}</div>
            <Checkbox checked={isChecked} onClick={onClick} />({isChecked ? 'must include' : 'must exclude'})
        </div>
    );
};
