import * as React from 'react';
import { deserializeColorPalette } from '@poolware/api';
import TimeFieldGroup from './FormFields/TimeField';
import { Form, Icon } from 'semantic-ui-react';
import { recurrenceToString } from '../utils';
import AppointmentGroupField from './FormFields/AppointmentGroupField';
import ColorField from './FormFields/ColorField';
import { FormikCustomerAndLocationInputFields, FormikSuggestInputStaff } from '@poolware/app-shell';
import { useFormikContext } from 'formik';
import { AppointmentFormValuesType } from './AppointmentForm';

export interface AppointmentDetailsTabProps {}

export const FormTabAppointmentDetails: React.FC<AppointmentDetailsTabProps> = ({}) => {
    const { values } = useFormikContext<AppointmentFormValuesType>();

    const { group, isRecurring, recurrence } = values;
    const palette = deserializeColorPalette(group);

    return (
        <>
            <TimeFieldGroup />
            {isRecurring && (
                <Form.Field>
                    <Icon name={'refresh'} />
                    {recurrenceToString(recurrence)}
                </Form.Field>
            )}
            <Form.Group widths={'equal'}>
                <AppointmentGroupField label={'Appointment Group'} name={'group'} />
                <ColorField label={'Color Tag'} name={'color'} palette={palette} />
            </Form.Group>

            <FormikSuggestInputStaff label={'Assigned To'} readOnly={values.staffReadOnly} name={'staff'} />
            <FormikCustomerAndLocationInputFields readOnly={values.customerReadOnly} />
        </>
    );
};
