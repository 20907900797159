import { AuthConnectProps, NodeType } from '@poolware/api';
import { ViewerPermissionsType } from './use-query-me';
import { FeatureFlag } from './feature-flags';
import { defaultModuleAccess, ModulesAccess } from './module-access-default';
import { AppBreakpoints, defaultAppBreakpointsValue } from '@poolware/components';

export type ViewerContextType = {
    checkingAuthState: boolean;
    isAuthenticated: boolean;
    allowBackgroundAuthCheck: boolean;
    isAdmin: boolean;
    isOrgAdmin: boolean;
    isFranchiseAdmin: boolean;
    isPretending: boolean;
    viewer: {
        trackId?: string;
        Permissions?: ViewerPermissionsType;
        me?: NodeType.Me;
        franchise?: { id: string; name: string; types: NodeType.FranchiseType[] };
        organisation?: { id: string; name: string };
        refetch?: () => any;
        isVendPresent?: boolean;
    };
    Auth: AuthConnectProps;
    canAccessFeature: (flag: string | string[]) => boolean;
    getFeatureFlag: (flag: FeatureFlag | string) => false | string | undefined;
    modulesAccess: ModulesAccess;
    error?: any;
    appLayoutMode: AppBreakpoints;
};

export const defaultViewerContextValue: ViewerContextType = {
    Auth: undefined,
    checkingAuthState: false,
    allowBackgroundAuthCheck: false,
    isAdmin: false,
    isFranchiseAdmin: false,
    isAuthenticated: false,
    isOrgAdmin: false,
    isPretending: false,
    canAccessFeature: () => false,
    getFeatureFlag: () => false,
    viewer: {},
    modulesAccess: defaultModuleAccess,
    appLayoutMode: defaultAppBreakpointsValue,
};
