import { withStaffUnpretend } from '@poolware/api';
import { Dropdown } from 'semantic-ui-react';
import * as React from 'react';
import { useAppConfig } from '../app-config';
import { useViewerContext } from '../Viewer';
import { Navbar } from '@poolware/components';

export const AppSwitcherMenuItems = withStaffUnpretend()(({ unpretend }) => {
    const appConfig = useAppConfig();
    const { isPretending, isAdmin, viewer } = useViewerContext();

    const unpretendMenuItem = () => {
        if (!isPretending) return null;

        return (
            <>
                <Navbar.MenuItemHeader
                    tw={'hidden lg:block'}
                    content={<small tw={'text-gray-300'}>{viewer?.franchise?.name}</small>}
                />
                <Navbar.MenuItem onClick={unpretend} color="yellow" icon={'hide'} content={'Unpretend'} />
            </>
        );
    };

    const menuItems = [
        {
            icon: 'shield alternate',
            text: 'Main App',
            url: appConfig?.apps?.frontend,
        },
        {
            icon: 'shield alternate',
            text: 'Admin Console',
            url: appConfig?.apps?.admin,
        },
        {
            icon: 'shield alternate',
            text: 'Water Test Config',
            url: appConfig?.apps?.configurator,
        },
        {
            icon: 'shield alternate',
            text: 'Warranty HQ',
            url: appConfig?.apps?.warrantyHQ,
        },
    ];

    menuItems.push({
        icon: 'shield alternate',
        text: 'My Account',
        url: appConfig?.apps?.account,
    });

    return (
        <>
            {unpretendMenuItem()}
            {isAdmin && (
                <Navbar.DropdownMenu color="yellow" icon={'grid layout'}>
                    {menuItems.map((item, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                as={'a'}
                                icon={item.icon}
                                text={item.text}
                                href={item.url}
                                // target={'_blank'}
                            />
                        );
                    })}
                </Navbar.DropdownMenu>
            )}
        </>
    );
});
