import gql from 'graphql-tag';
import _set from 'lodash/set';
import {
    createUseQueryNodeHook,
    NodeType,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';

export const productDocumentFragment = gql`
    fragment ProductDocumentFragment on ProductDocument {
        id
        title
        type
        url
        mimeType
        isImage
        company {
            id
            name
        }
        brand {
            id
            name
        }
    }
`;

const rcFragment = gql`
    fragment HeaterRecommendationFragment on HeaterRecommendation {
        id
        recNumber
        expiryDate
        note
        createdAt
        updatedAt
        payload
        status
        urlPdf
        product {
            id
            name
            sku
            customSku
            supplier {
                id
                name
            }
            brand {
                id
                name
                company {
                    id
                    name
                }
            }
        }
        entity {
            id
            user {
                id
                firstName
                lastName
                customer {
                    id
                    crn
                }
            }
            franchise {
                id
                name
            }
        }
        checkMutations {
            delete
            update
        }
    }
`;

const QLConnection = gql`
    query QueryHeaterRecommendationConnection(
        $first: Int
        $page: Int
        $sort: HeaterRecommendationViewerHeaterRecommendationsSort
        $search: HeaterRecommendationViewerHeaterRecommendationsSearch
    ) {
        viewer {
            heaterRecommendations(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        ...HeaterRecommendationFragment
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
    ${rcFragment}
`;

export interface UseQueryHeaterRecommendationConnectionProps {
    customerId?: NodeType.ID;
}

function mapPropsToSearchQuery(
    props?: UseQueryHeaterRecommendationConnectionProps
): NodeType.HeaterRecommendationViewerHeaterRecommendationsSearch {
    if (!props) {
        return undefined;
    }

    const { customerId } = props;
    const search = {};
    if (customerId) {
        _set(search, 'entity.id', customerId);
    }
    return search;
}

export function useQueryHeaterRecommendations(props?: UseQueryHeaterRecommendationConnectionProps) {
    const connectionPath = 'viewer.heaterRecommendations';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 30,
        defaultSortKey: 'createdAt',
        defaultSortDirection: SortDirection.DES,
    });
    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection({
        query: QLConnection,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}

const QLNode = gql`
    query node($id: ID!) {
        node(id: $id) {
            id
            ...HeaterRecommendationFragment
        }
    }

    ${rcFragment}
`;

export const useQueryHeaterRecommendation = createUseQueryNodeHook<NodeType.HeaterRecommendation>(QLNode);
