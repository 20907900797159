import * as React from 'react';
import invariant from 'invariant';
import { Button, Menu } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import { AppointmentStatusUiConf, mapConfFromStatusMap } from '../../utils';
import { useAppLayoutMode } from '@poolware/app-shell';

export const ButtonChangeStatus: React.FC<{ onChangeStatus: (string) => any } & AppointmentStatusUiConf> = ({
    active,
    title,
    type,
    icon,
    onChangeStatus,
    ...props
}) => (
    <Button
        basic={!active}
        onClick={() => onChangeStatus(type)}
        icon={icon}
        active={active}
        content={title}
        color={'teal'}
        {...props}
    />
);

const ActionButtonsFull: React.FC<ActionButtonProps & { buttonConfs: AppointmentStatusUiConf[] }> = ({
    buttonConfs,
    onChangeStatus,
    onDelete,
}) => {
    return (
        <Menu stackable size={'mini'} fluid borderless secondary compact>
            {onDelete && (
                <Menu.Menu position="left">
                    <Menu.Item>
                        <Button
                            floated={'left'}
                            size="mini"
                            fluid
                            color="red"
                            basic
                            onClick={onDelete}
                            icon={'trash'}
                            content={'Delete'}
                        />
                    </Menu.Item>
                </Menu.Menu>
            )}
            <Menu.Menu position={'right'}>
                <Menu.Item>
                    <Button.Group size="mini" color={'teal'}>
                        {buttonConfs.map((conf, index) => {
                            return <ButtonChangeStatus key={index} {...conf} onChangeStatus={onChangeStatus} />;
                        })}
                    </Button.Group>
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    );
};

const ActionButtonsCompact: React.FC<ActionButtonProps & { buttonConfs: AppointmentStatusUiConf[] }> = ({
    buttonConfs,
    onChangeStatus,
    onDelete,
}) => {
    return (
        <Menu stackable size={'mini'} borderless secondary compact fluid>
            <Menu.Item>
                <Button.Group size="mini" compact fluid>
                    {buttonConfs.map((conf, index) => {
                        return <ButtonChangeStatus key={index} {...conf} onChangeStatus={onChangeStatus} />;
                    })}
                </Button.Group>
            </Menu.Item>
            {onDelete && (
                <Menu.Item>
                    <Button
                        floated={'left'}
                        size="mini"
                        fluid
                        color="red"
                        basic
                        onClick={onDelete}
                        icon={'trash'}
                        content={'Delete'}
                    />
                </Menu.Item>
            )}
        </Menu>
    );
};

type ActionButtonProps = {
    appointmentItem: NodeType.AppointmentItem;
    onChangeStatus: (string) => any;
    onDelete: () => any;
};

const ActionButtons: React.FC<ActionButtonProps> = ({ appointmentItem, ...rest }) => {
    invariant(appointmentItem, 'Expected appointment item');

    const { gteTablet } = useAppLayoutMode();
    const { status } = appointmentItem;

    const isWide = gteTablet;

    const buttonConfs = mapConfFromStatusMap(status);

    return isWide ? (
        <ActionButtonsFull {...rest} buttonConfs={buttonConfs} appointmentItem={appointmentItem} />
    ) : (
        <ActionButtonsCompact {...rest} buttonConfs={buttonConfs} appointmentItem={appointmentItem} />
    );
};
export default ActionButtons;
