import * as React from 'react';
import { Button, Dropdown, Form, Segment } from 'semantic-ui-react';
import { fromEdges, NodeType } from '@poolware/api';
import { FormikInputField } from '@poolware/components';
import { FieldArray, getIn } from 'formik';
import { useQueryContactTypes } from './use-query-contact-types';

const ContactItemField: React.FC<{ onRemove; name; contact }> = (props) => {
    const { name, contact } = props;
    const n = (fieldName: string) => `${name}.${fieldName}`;
    return (
        <Segment compact style={{ paddingTop: '5px', paddingBottom: 0 }}>
            <Form.Group widths={'equal'}>
                <FormikInputField
                    name={n('data')}
                    label={getIn(contact, 'type.name')}
                    action
                    required={true}
                    actionButton={<Button onClick={props.onRemove} basic icon={'delete'} />}
                />
                <FormikInputField
                    label={'Label'}
                    name={n('label')}
                    value={contact.name}
                    placeholder={'e.g. Owner, Tenant'}
                />
            </Form.Group>
        </Segment>
    );
};

export const FormikContactsInputFields: React.FC<{ name: string }> = (props) => {
    const { name } = props;

    const { data } = useQueryContactTypes();

    const types: NodeType.ContactType[] = fromEdges(data?.viewer?.contactTypes);

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers) => {
                const { form } = arrayHelpers;
                // const errors = form.errors[name];
                const contacts = getIn(form.values, name);

                return (
                    <>
                        {contacts &&
                            contacts.map((contact, index) => {
                                const n = (index) => `${name}[${index}]`;

                                // console.log(contact);
                                return (
                                    <ContactItemField
                                        contact={contact}
                                        name={n(index)}
                                        key={index}
                                        onRemove={() => arrayHelpers.remove(index)}
                                    />
                                );
                            })}
                        <Dropdown text="Add Contact" icon="address book" floating labeled button className="icon">
                            <Dropdown.Menu>
                                {types.map((contactType) => {
                                    const props = {
                                        key: contactType.id,
                                        text: contactType.name,
                                        value: contactType.id,
                                        onClick: () => {
                                            arrayHelpers.push({ type: contactType, data: '', label: '' });
                                        },
                                    };

                                    return <Dropdown.Item {...props} />;
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                );
            }}
        />
    );
};

export default FormikContactsInputFields;
