import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { Modal, SemanticICONS } from 'semantic-ui-react';
import { ModuleColorNames, ModuleIconNames } from '../../../../constants';
import {
    TabContainer,
    TabMenuContainer,
    TabMenuItemAddNew,
    TabMenuItemDraggable,
    TabMenuItemSeparator,
    TabPaneContainer,
} from '../../../../components/SideMenuLayout';
import { useDndTable } from '../../../../components/table-dnd-helpers';
import { EmptyBoxImage, FormikDefaultForm, FormikInputField, MenuButton, toastError } from '@poolware/components';
import { fromEdges, NodeType, useMutationServiceJobTemplate, useMutationWorkOrderTemplate } from '@poolware/api';
import { WorkOrderTemplate } from './WorkOrderTemplate';
import { WorkOrderTemplatePicker } from './WorkOrderTemplatePicker';
import { useModalCtrl } from '@poolware/components';
import * as Yup from 'yup';

export type TabMenuPaneType = {
    assoc?: NodeType.WorkOrderTemplateAssoc;
    key?: string;
    title: string;
    icon: SemanticICONS;
    priority: number;
};

interface WorkOrderMenuItemsProps {
    panes: TabMenuPaneType[];
    onSelectPane: (TabNavControlledTabConf) => any;
    activePaneKey: string;
    serviceJobTemplate: NodeType.ServiceJobTemplate;
    serviceJobTemplateMutator: ReturnType<typeof useMutationServiceJobTemplate>;
}
const WorkOrdersMenuItems: React.FC<WorkOrderMenuItemsProps> = ({
    serviceJobTemplate,
    panes,
    activePaneKey,
    onSelectPane,
    serviceJobTemplateMutator,
}) => {
    const { update } = serviceJobTemplateMutator;

    const onReorder = async (rowItems: TabMenuPaneType[]) => {
        const reIndexedItems = rowItems
            .map((pane, index) => ({
                id: pane.assoc.id,
                oldPriority: pane.priority,
                newPriority: index,
            }))
            .filter((item) => item.oldPriority !== item.newPriority)
            .map((item) => ({ id: item.id, priority: item.newPriority }));

        return await update({
            id: serviceJobTemplate.id,
            workOrderTemplateAssocs: {
                update: reIndexedItems,
            },
        });
    };

    const { rowItems, dropRow, moveRow } = useDndTable({ items: panes, onReorder });

    return (
        <>
            {rowItems.map((pane, index) => {
                const active = pane.key === activePaneKey;
                return (
                    <TabMenuItemDraggable
                        key={pane.key}
                        index={index}
                        moveRow={moveRow}
                        dropRow={dropRow}
                        label={pane.title}
                        onClick={() => onSelectPane(pane)}
                        active={active}
                        iconColor={ModuleColorNames.WorkOrder}
                        iconName={pane.icon}
                    />
                );
            })}
        </>
    );
};

const NoWorkOrdersMessage = () => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                padding: '2rem 0 1rem 0',
            }}
        >
            <EmptyBoxImage />
            <i style={{ textAlign: 'center', fontSize: 'x-small', color: '#777777' }}>No Work Order Templates</i>
        </div>
    );
};

const NewWorkOrderFormValidation = Yup.object().shape({
    templateTitle: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Required'),
});

const FormNewWorkOrderTemplate: React.FC<{ onSubmit; onCancel }> = ({ onSubmit, onCancel }) => {
    const initialValues = {
        templateTitle: '',
    };
    const handleOnSubmit = async (values: typeof initialValues) => {
        try {
            const title = values?.templateTitle?.trim();
            await onSubmit({
                templateTitle: title,
                providedTitle: title,
            });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            validationSchema={NewWorkOrderFormValidation}
            header={'New Work Order Template'}
            initialValues={initialValues}
            submitButton={{ content: 'Next...' }}
            onSubmit={handleOnSubmit}
            onCancel={onCancel}
        >
            <FormikInputField label={'Template Title'} name={'templateTitle'} />
        </FormikDefaultForm>
    );
};

export interface TabNavControlledProps {
    refetchQueries: any[];
    serviceJobTemplate: NodeType.ServiceJobTemplate;
}

export const WorkOrderTemplateAssocsEdit: React.FC<TabNavControlledProps> = ({
    refetchQueries,
    serviceJobTemplate,
}) => {
    const { AppNavigator } = useAppNavigator();
    const modalWOPicker = useModalCtrl();
    const modalWONew = useModalCtrl();

    const serviceJobTemplateMutator = useMutationServiceJobTemplate({ refetchQueries: refetchQueries });
    const workOrderTemplateMutator = useMutationWorkOrderTemplate({ refetchQueries: refetchQueries });

    const panes = useMemo(() => {
        const panes: TabMenuPaneType[] = fromEdges(serviceJobTemplate.workOrderTemplateAssocs)
            .sort((l, r) => {
                return l.priority > r.priority ? 1 : -1;
            })
            .map((assoc) => ({
                assoc: assoc,
                icon: ModuleIconNames.WorkOrder,
                key: assoc.workOrderTemplate?.id,
                title: assoc.workOrderTemplate?.templateTitle || 'unknown',
                priority: assoc.priority,
            }));
        return panes;
    }, [serviceJobTemplate]);

    // Get active pane
    let activePaneKey = AppNavigator.query['pane'] || panes[0]?.key;
    let activePane = panes.find((pane) => pane.key === activePaneKey);
    if (!activePane) {
        activePane = panes[0];
        activePaneKey = activePane?.key;
    }

    useEffect(() => {
        // Set active pane key in URL
        if (!AppNavigator.query['pane'] && activePaneKey) {
            AppNavigator.setSearchQueries({ pane: activePaneKey });
        }
    }, [activePaneKey]);

    const onSelectPane = (pane?: TabMenuPaneType) => {
        AppNavigator.setSearchQueries({ pane: pane.key });
    };

    const onRemoveWorkOrderTemplate = async (assoc: NodeType.WorkOrderTemplateAssoc) => {
        try {
            if (assoc.workOrderTemplate?.ownedByServiceJobTemplate) {
                await workOrderTemplateMutator.delete({ id: assoc.workOrderTemplate.id });
            } else {
                await serviceJobTemplateMutator.update({
                    id: serviceJobTemplate.id,
                    workOrderTemplateAssocs: {
                        delete: [assoc.id],
                    },
                });
            }
            AppNavigator.setTab(null);
        } catch (e) {
            toastError(e);
        }
    };
    const attachedTemplates = fromEdges(serviceJobTemplate?.workOrderTemplateAssocs).map(
        (assoc) => assoc.workOrderTemplate
    );

    const handleOnTemplatePickerSubmit = async (selectedItems: NodeType.WorkOrderTemplate[]) => {
        try {
            const newAssocs = selectedItems.map<NodeType.UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput>(
                (woTemplate) => {
                    return {
                        isSuggestion: false,
                        priority: attachedTemplates.length,
                        workOrderTemplate: { id: woTemplate.id },
                    };
                }
            );
            await serviceJobTemplateMutator.update({
                id: serviceJobTemplate.id,
                workOrderTemplateAssocs: {
                    create: newAssocs,
                },
            });
            modalWOPicker.onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const handleOnNewWorkOrderTemplateAssoc = async (wo: any) => {
        try {
            const newAssocs: NodeType.UpdateServiceJobTemplateListWorkOrderTemplateAssocCreateInput = {
                isSuggestion: false,
                priority: attachedTemplates.length,
                workOrderTemplate: {
                    create: {
                        templateTitle: wo.templateTitle,
                        providedTitle: wo.providedTitle,
                    },
                },
            };

            await serviceJobTemplateMutator.update({
                id: serviceJobTemplate.id,
                workOrderTemplateAssocs: {
                    create: [newAssocs],
                },
            });
            modalWONew.onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <>
            <TabContainer>
                <TabMenuContainer>
                    <WorkOrdersMenuItems
                        serviceJobTemplate={serviceJobTemplate}
                        panes={panes}
                        activePaneKey={activePaneKey}
                        onSelectPane={onSelectPane}
                        serviceJobTemplateMutator={serviceJobTemplateMutator}
                    />
                    <TabMenuItemSeparator />
                    <TabMenuItemAddNew label={'Add Work Order Template'} onClick={modalWONew.onOpen} />
                    {false && (
                        <MenuButton
                            menuPosition={'bottom center'}
                            as={<TabMenuItemAddNew label={'Add Work Order Template'} />}
                            menuItems={[
                                {
                                    name: 'Create New',
                                    onClick: modalWONew.onOpen,
                                },
                                {
                                    name: 'Choose from templates',
                                    onClick: modalWOPicker.onOpen,
                                },
                            ]}
                        />
                    )}
                </TabMenuContainer>
                <TabPaneContainer>
                    {activePane ? (
                        <WorkOrderTemplate
                            workOrderTemplateId={activePane.assoc.workOrderTemplate?.id}
                            onRemove={() => onRemoveWorkOrderTemplate(activePane.assoc)}
                        />
                    ) : (
                        <NoWorkOrdersMessage />
                    )}
                </TabPaneContainer>
            </TabContainer>

            <Modal {...modalWONew}>
                <FormNewWorkOrderTemplate onSubmit={handleOnNewWorkOrderTemplateAssoc} onCancel={modalWONew.onClose} />
            </Modal>
            <Modal {...modalWOPicker}>
                <WorkOrderTemplatePicker
                    onCancel={modalWOPicker.onClose}
                    onSubmit={handleOnTemplatePickerSubmit}
                    selectedItems={attachedTemplates}
                />
            </Modal>
        </>
    );
};
