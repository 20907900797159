import { CalViewMode } from './types';
import Month from './Month';
import Day from './Day';
import Week from './Week';
import WorkWeek from './WorkWeek';
import Agenda from './Agenda';

const VIEWS = {
    [CalViewMode.MONTH]: Month,
    [CalViewMode.WEEK]: Week,
    [CalViewMode.WORK_WEEK]: WorkWeek,
    [CalViewMode.DAY]: Day,
    [CalViewMode.AGENDA]: Agenda,
};

export default VIEWS;
