import { RecurServiceJobStatus } from '../constants';
import { createAction, createReducer, createAutoBoundReducerActions } from '@ez/tools';
import { bindActionCreators, combineReducers } from 'redux';
import { createStructuredSelector } from 'reselect';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
    SortDirection,
    createPageIndexReducerActions,
    defaultConnectionPageState,
    ConnectionPageStateType,
    NodeType,
} from '@poolware/api';
import { useMemo } from 'react';

const SCOPE = '@RecurServiceJobList';
enum SJL_ACTION {
    CLEAR_ALL_FILTER = '@RecurServiceJobList/CLEAR_ALL_FILTER',
}

type RecurServiceJobListStateType = {
    filters: {
        staffIds: string[];
        serviceStageType: NodeType.ServiceJobStageType[];
        serviceStageStatus: NodeType.ServiceJobStageStatus;

        status: RecurServiceJobStatus[];
        serviceJobNumber: string | null;
        workOrderNumber: string | null;
        serviceGroupId: string;
        serviceJobTemplateId: string;
        customerCRN: string | null;
        hasActiveFilters: boolean;
    };
    page: ConnectionPageStateType;
};

const initialState: RecurServiceJobListStateType = {
    filters: {
        staffIds: [],
        status: [],
        serviceStageType: [],
        serviceStageStatus: null,

        serviceJobNumber: null,
        workOrderNumber: null,
        serviceGroupId: null,
        serviceJobTemplateId: null,
        customerCRN: null,
        hasActiveFilters: false,
    },
    page: defaultConnectionPageState,
};

const FiltersReducerActions = createAutoBoundReducerActions(initialState.filters, SCOPE, {
    setStatusFilter: { field: 'status' },
    setServiceStageType: { field: 'serviceStageType' },
    setServiceStageStatus: { field: 'serviceStageStatus' },
    setStaffFilter: { field: 'staffIds' },
    setServiceJobGroup: { field: 'serviceGroupId' },
    setServiceJobTemplate: { field: 'serviceJobTemplateId' },
    setWorkOrderNumber: { field: 'workOrderNumber' },
    setServiceJobNumber: { field: 'serviceJobNumber' },
    setCustomerCRN: { field: 'customerCRN' },
});

const hasActiveFilters = (state: RecurServiceJobListStateType['filters']) => {
    return (
        state.staffIds?.length > 0 ||
        !!state.serviceJobNumber ||
        !!state.workOrderNumber ||
        !!state.serviceJobTemplateId ||
        !!state.serviceGroupId
    );
};

const CustomActions = {
    clearAllFilters: () => createAction(SJL_ACTION.CLEAR_ALL_FILTER, undefined, SCOPE),
};

const PageIndexReducerActions = createPageIndexReducerActions({
    scope: SCOPE,
    resetIndexActionTypes: [...FiltersReducerActions.actionTypes, SJL_ACTION.CLEAR_ALL_FILTER],
    initialState: initialState.page,
});

const filtersReducer = createReducer(
    initialState.filters,
    (state, action: any): RecurServiceJobListStateType['filters'] => {
        const reducer = FiltersReducerActions.reducers[action.type];
        if (reducer) {
            reducer(state, action.payload);
        } else if (action.type === SJL_ACTION.CLEAR_ALL_FILTER) {
            // reset all fields except stage filter
            return {
                ...initialState.filters,
                serviceStageType: state.serviceStageType,
                serviceStageStatus: state.serviceStageStatus,
            };
        }
        state.hasActiveFilters = hasActiveFilters(state);

        return state;
    },
    SCOPE
);

export const reducerRSJList = combineReducers({
    filters: filtersReducer,
    page: PageIndexReducerActions.reducer,
});

const _memoizedSelector = createStructuredSelector<RecurServiceJobListStateType, RecurServiceJobListStateType>({
    filters: (state) => state.filters,
    page: (state) => state.page,
});

export const useRecurServiceJobListActions = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => _memoizedSelector(state.serviceJob.recurJobList));

    return useMemo(() => {
        const allActions = { ...FiltersReducerActions.actions, ...PageIndexReducerActions.actions, ...CustomActions };
        return {
            State: state,
            Actions: bindActionCreators(allActions, dispatch),
        };
    }, [state, dispatch]);
};
