import * as React from 'react';
import { toastError } from '@poolware/components';
import { NodeType, useMutationJobTodoTemplateAssoc } from '@poolware/api';
import * as _ from 'lodash';
import { JobTodoTemplateForm } from '../../../../JobTodoTemplate/JobTodoTemplateForm';

export interface JobTodoTemplateNewFormProps {
    onDone: () => any;
    onCancel?: () => any;
    refetchQuery: any;
    workOrderTemplate: NodeType.WorkOrderTemplate;
}
export const JobTodoTemplateAssocNewForm: React.FC<JobTodoTemplateNewFormProps> = ({
    refetchQuery,
    workOrderTemplate,
    onDone,
}) => {
    const { create: createJobTodoTemplateAssoc } = useMutationJobTodoTemplateAssoc({
        awaitRefetchQueries: true,
        refetchQueries: [refetchQuery],
    });

    const initialValues = {
        title: '',
        description: '',
        timeEstimate: '',
        recommendedPrice: '',
        items: [],
    };

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            const toCreate = values.items.map((item, index) => ({
                title: item.title?.trim() || '',
                description: item.description?.trim() || '',
                orderIndex: index,
            }));

            try {
                await createJobTodoTemplateAssoc({
                    workOrderTemplate: workOrderTemplate.id,
                    priority: workOrderTemplate?.jobTodoTemplateAssocs?.edges?.length,
                    jobTodoTemplate: {
                        create: {
                            ownedByWorkOrderTemplate: workOrderTemplate.id,
                            description: values.description?.trim(),
                            title: values.title?.trim(),
                            timeEstimate: _.toNumber(values.timeEstimate),
                            recommendedPrice: _.toNumber(values.recommendedPrice),
                            items: toCreate,
                        },
                    },
                });
                onDone();
            } catch (e) {
                toastError(e);
            }
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <JobTodoTemplateForm debug={false} initialValues={initialValues} onCancel={onDone} onSubmit={handleSubmit} />
    );
};
