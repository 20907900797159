import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Panel } from '@poolware/components';

export enum PickerMode {
    SELECT_EXISTING,
    CREATE_NEW,
}

export const BrandLabel: React.FC<{ brand: NodeType.Brand }> = ({ brand }) => {
    if (!brand) return null;
    const brandName = brand?.name;
    const companyName = brand?.company?.name;

    return (
        <>
            {companyName ? (
                <>
                    <span style={{ fontStyle: 'italic' }}>{companyName}</span>
                    {' > '} <>{brandName}</>
                </>
            ) : (
                <>{brandName}</>
            )}
        </>
    );
};

export const ProductPreview: React.FC<{ product: NodeType.Product }> = ({ product }) => {
    return (
        <Panel>
            <Panel.Body>
                <Panel.Item label="SKU" content={product.sku || '--'} />
                <Panel.Item label="Name" content={product.name} />
                {product.brand && (
                    <Panel.Item label={'Brand'}>
                        <BrandLabel brand={product.brand} />
                    </Panel.Item>
                )}
                {/*<Panel.Item label={'Description'} content={product.description} />*/}
            </Panel.Body>
        </Panel>
    );
};
