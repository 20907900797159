import { useFormikContext } from 'formik';
import { FormikHeatCalcValueType } from './types';
import { useMutationHeaterRecommendation } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { serializeForm } from './state-serializer';
import { ButtonWithPopup, Display, StickyMenuBar, toastError } from '@poolware/components';
import { Button, Icon } from 'semantic-ui-react';
import * as React from 'react';

export const PageHeaderNewDocument = () => {
    const { values, dirty } = useFormikContext<FormikHeatCalcValueType>();
    const { create, mutationResult } = useMutationHeaterRecommendation({
        refetchQueries: ['QueryHeaterRecommendationConnection'],
    });

    const { AppNavigator } = useAppNavigator();

    const onSave = async () => {
        try {
            const data = serializeForm(values);
            const result = await create({ payload: data.payload, product: data.productId, entity: data.entityId });
            const id = result.data?.HeaterRecommendation?.HeaterRecommendation?.id;
            AppNavigator.navigateRelative(`/combined/rc/${id}`);
        } catch (e) {
            toastError(e);
        }
    };

    const onClose = () => {
        AppNavigator.navigateRelative('/combined');
    };

    return (
        <StickyMenuBar>
            <div tw={'bg-yellow-50 flex flex-row w-full p-2 rounded shadow'}>
                <div tw={'flex flex-grow flex-row items-center space-x-4'}>
                    <Button
                        color={'blue'}
                        size={'tiny'}
                        icon={'cancel'}
                        content={'Close'}
                        onClick={() => onClose()}
                        basic={true}
                    />
                    <div tw={'font-bold text-lg'}>
                        <Icon name={'calculator'} />
                        Heater Recommendation
                    </div>
                    <div tw={'text-indigo-900'}>
                        <Display.Entity value={values?.entity} />
                    </div>
                </div>
                <div tw={'flex flex-grow flex-row justify-end space-x-4'}>
                    {/*<ButtonWithPopup*/}
                    {/*    color={'red'}*/}
                    {/*    size={'tiny'}*/}
                    {/*    icon={'file pdf'}*/}
                    {/*    content={'Generate PDF'}*/}
                    {/*    onClick={() => onOpenPdf()}*/}
                    {/*    basic={true}*/}
                    {/*/>*/}
                    <ButtonWithPopup
                        size={'tiny'}
                        icon={'save outline'}
                        content={'Save'}
                        disabled={!dirty}
                        onClick={() => onSave()}
                    />
                </div>
            </div>
        </StickyMenuBar>
    );
};
