// import { ModuleLoadable } from '@poolware/app-shell';
import { ServiceJobsRouter } from '@poolware/app-service-jobs';
import { TemplatesManagerRouter } from '@poolware/app-service-jobs';

export const ServiceJobs = {
    Router: ServiceJobsRouter,
    TemplatesManagerRouter,
};

// export const ServiceJobs = {
//     Router: ModuleLoadable({
//         loader: () =>
//             import('@poolware/app-service-jobs' /* webpackChunkName: "service-jobs" */).then(
//                 (V) => V.ServiceJobsRouter
//             ),
//     }),
//     TemplatesManagerRouter: ModuleLoadable({
//         loader: () =>
//             import('@poolware/app-service-jobs' /* webpackChunkName: "service-jobs" */).then(
//                 (V) => V.TemplatesManagerRouter
//             ),
//     }),
// };
