import * as React from 'react';
import { fromEdges } from '@ez/api-core';
import { NodeType, useMutationStaffTeam } from '@poolware/api';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    confirmModalImperative,
    ConnectionErrorMessage,
    ConnectionTableDef,
    DefaultConnectionTable,
    Display,
    FormikDefaultForm,
    FormikInputField,
    IconButton,
    LinkButton,
    MenuBar,
    MenuBarGroupMODE,
    MenuBarGroupProps,
    PageLayout,
    PageSkeletonLoader,
    Panel,
    SectionHeader,
    StickyMenuBar,
    toastError,
    useModalCtrl,
    VStack,
} from '@poolware/components';
import { Icon, Modal } from 'semantic-ui-react';
import { NotFoundPage } from '@poolware/app-shell';
import { StaffPicker } from './StaffPicker';
import { useQueryStaffTeam } from './use-query-staff-team';
import { AdminOnly } from '@poolware/app-shell';
import * as URLBuilder from '../../../routes/url-builder';

const EditTeamPanel: React.FC<{
    team: NodeType.StaffTeam;
    updateMutator: (input: NodeType.UpdateStaffTeamMutationInput) => Promise<any>;
    onClose;
}> = ({ team, updateMutator, onClose }) => {
    const initialValues = {
        title: team.title,
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const res = await updateMutator({
                id: team.id,
                title: values.title?.trim() || '',
            });
            onClose();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            header={'New Team'}
            initialValues={initialValues}
            submitButton={{ content: 'Update' }}
            onSubmit={onSubmit}
            onCancel={onClose}
        >
            <FormikInputField label={'Title'} name={'title'} />
        </FormikDefaultForm>
    );
};

export const ViewPageStaffTeam: React.FC = () => {
    const modalStaffPicker = useModalCtrl();
    const modalEditForm = useModalCtrl();
    const { AppNavigator, params } = useAppNavigator();
    const { loading, node: team, error, refetchQuery } = useQueryStaffTeam(params?.id);
    const { addStaff, update, delete: deleteTeam } = useMutationStaffTeam({ refetchQueries: [refetchQuery] });
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !team) {
        return <NotFoundPage />;
    }

    const goBack = () => {
        AppNavigator.navigateToOrigin('/teams', { relativeToModule: true });
    };

    const onDeleteTeam = async () => {
        const res = await confirmModalImperative({
            confirmMessage: {
                header: 'Delete team?',
                content: (
                    <div>
                        If you click "Delete" the following will happen:
                        <ul>
                            <li>Staff members will be removed from team</li>
                            <li>Team will be deleted</li>
                            <li>Staff members will not be deleted</li>
                        </ul>
                    </div>
                ),
            },
            confirmButton: { content: 'Delete', negative: true, icon: 'trash' },
            cancelButton: { content: 'Keep' },
        });
        if (!res) {
            return null;
        }
        try {
            await deleteTeam({ id: team.id });
            AppNavigator.navigateRelative('/teams');
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onStaffPickerSubmit = async (selectedStaff: NodeType.Staff[]) => {
        modalStaffPicker.onClose();
        try {
            await addStaff(
                selectedStaff.map((staff) => ({
                    id: team.id,
                    staff: staff.id,
                }))
            );
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onRemoveStaff = async (staff: NodeType.Staff) => {
        const isConfirmed = await confirmModalImperative({
            confirmMessage: {
                header: 'Remove staff member from team?',
            },
            confirmButton: { content: 'Remove', negative: true, icon: 'remove circle' },
            cancelButton: { content: 'Keep' },
        });
        if (!isConfirmed) {
            return;
        }
        try {
            await update({ id: team.id, staff: { delete: [staff.id] } });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const onViewStaff = (staff: NodeType.Staff) => {
        const link = URLBuilder.Staff(staff.id).view;
        AppNavigator.navigate(link, { setOrigin: true });
    };

    const staff = fromEdges(team.staff);
    const canDeleteTeam = team.checkMutations?.delete;
    const canAddStaff = team.checkMutations?.addStaff;
    const canEdit = team.checkMutations?.update;

    const tableDef: ConnectionTableDef<NodeType.Staff> = [
        {
            header: 'Test',
            cell: (staff) => {
                return (
                    <LinkButton
                        onClick={() => {
                            onViewStaff(staff);
                        }}
                    >
                        <Display.Entity value={staff} />
                    </LinkButton>
                );
            },
        },
        {
            header: 'Role',
            cell: (r) => {
                return <>{r.role?.name}</>;
            },
        },
        canEdit && {
            header: '',
            width: 1,
            cell: (staff) => {
                return <IconButton name={'remove circle'} color={'red'} onClick={() => onRemoveStaff(staff)} />;
            },
        },
    ];

    const menuGroup2: MenuBarGroupProps = {
        menu: {
            icon: 'bars',
            color: 'red',
            menuMode: MenuBarGroupMODE.DROPDOWN,
        },
        items: [{ icon: 'trash', color: 'red', onClick: onDeleteTeam, title: 'Delete Team', disabled: !canDeleteTeam }],
    };

    return (
        <PageLayout>
            <StickyMenuBar>
                <MenuBar.Section>
                    <MenuBar.Item onClick={goBack} title={'Back'} icon={'chevron left'} />
                    <MenuBar.HeaderItem>
                        <Icon name={'users'} />
                        Team
                    </MenuBar.HeaderItem>
                </MenuBar.Section>
                <MenuBar.Section position={'right'}>
                    <MenuBar.Group {...menuGroup2} />
                </MenuBar.Section>
            </StickyMenuBar>
            <PageLayout.BodySection width={'screen-xl'}>
                <VStack>
                    <Panel>
                        <Panel.Header
                            content={'Team'}
                            button={canEdit && { icon: 'edit', onClick: modalEditForm.onOpen, content: 'Edit' }}
                        />
                        <Panel.Body>
                            <AdminOnly>
                                <Panel.Item
                                    color={'purple'}
                                    label={'Organisation'}
                                    content={team.franchise?.organisationType?.name}
                                />
                                <Panel.Item color={'purple'} label={'Franchise'} content={team.franchise?.name} />
                            </AdminOnly>
                            <Panel.Item label={'Team Name'} content={team.title} />
                        </Panel.Body>
                    </Panel>

                    <SectionHeader
                        button={{
                            content: 'Add',
                            icon: 'plus',
                            onClick: modalStaffPicker.onOpen,
                            disabled: !canAddStaff,
                            popup: !canAddStaff ? { content: 'Not enough permissions' } : undefined,
                        }}
                    >
                        Team Members
                    </SectionHeader>

                    <DefaultConnectionTable
                        onRowClick={onViewStaff}
                        tableProps={{ color: 'grey' }}
                        tableDef={tableDef}
                        connectionData={staff}
                    />
                </VStack>
            </PageLayout.BodySection>

            <Modal {...modalStaffPicker}>
                <StaffPicker onCancel={modalStaffPicker.onClose} onSubmit={onStaffPickerSubmit} selectedItems={staff} />
            </Modal>

            <Modal {...modalEditForm}>
                <EditTeamPanel team={team} updateMutator={update} onClose={modalEditForm.onClose} />
            </Modal>
        </PageLayout>
    );
};
