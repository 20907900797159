import * as React from 'react';
import { NodeType, useMutationPoolAttachedFlagField } from '@poolware/api';
import { AsyncCheckbox, Panel } from '@poolware/components';
import { useQueryPoolField } from './use-query-pool-fields';

export interface PanelAttachedFieldsProps {
    pool: NodeType.Pool;
}

export const PanelAttachedFields: React.FC<PanelAttachedFieldsProps> = ({ pool }) => {
    const { loading, error, node, refetchQuery } = useQueryPoolField(pool.id);
    const { setValue } = useMutationPoolAttachedFlagField({ refetchQueries: [refetchQuery] });
    const onToggle = async (field: NodeType.PoolAttachedFlagField, value: boolean) => {
        await setValue({
            id: field.id,
            value: value,
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    } else if (error) {
        return null;
    } else if (!node) {
        return null;
    }
    if (node.attachedFlagFields?.length === 0) return null;

    return (
        <div>
            <Panel>
                {/*<Panel.Header content={'Flags'} />*/}
                <Panel.Body>
                    {node.attachedFlagFields?.map((field) => {
                        return (
                            <Panel.Item
                                key={field.id}
                                label={field.fieldDeclaration?.name}
                                labelInfo={field.fieldDeclaration?.description}
                                labelWidth={180}
                            >
                                <AsyncCheckbox onToggle={(checked) => onToggle(field, checked)} checked={field.value} />
                            </Panel.Item>
                        );
                    })}
                </Panel.Body>
            </Panel>
        </div>
    );
};
