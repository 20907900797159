import * as React from 'react';
import moment from 'moment';
import { Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { DataPoint, dayTickFormatter, renderValueLabel } from './graph-helpers';

interface DataGraphInputProps {
    dateFormat?: string;
    color?: string;
    data: DataPoint[];
    loading?: boolean;
}

export const StatsGraph: React.FC<DataGraphInputProps> = (props) => {
    const { data, color } = props;
    const fill = color || `hsl(200, 40%, 20%, 1)`;

    return (
        <ResponsiveContainer width="100%" height={250}>
            <BarChart data={data} margin={{ top: 15, right: 5, bottom: 60, left: -20 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    tickCount={5}
                    padding={{ left: 20, right: 20 }}
                    dataKey="time"
                    domain={['auto', 'auto']}
                    name="Time"
                    angle={-90}
                    dy={45}
                    dx={-4}
                    interval={0}
                    scale={'time'}
                    tickFormatter={dayTickFormatter(props.dateFormat)}
                    tick={{ fontSize: '0.85em' }}
                    type="number"
                />
                <YAxis dataKey="value" name="Value" allowDecimals={false} tick={{ fontSize: '0.85em' }} />
                <Tooltip
                    isAnimationActive={false}
                    labelFormatter={(value) => moment(value).format(props.dateFormat || 'll')}
                    formatter={(value) => [value, 'Count']}
                />

                <Bar minPointSize={2} barSize={20} isAnimationActive={false} dataKey={'value'} fill={fill}>
                    <LabelList dataKey="value" position="top" content={renderValueLabel} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
