import { FormikFormFieldLabel, FormikInputField, FormikTextareaField } from '@poolware/components';
import { FormikInputJobTodoItems, validationSchemaWorkOrderItems } from './FormikInputJobTodoItems';
import { default as React } from 'react';
import * as Yup from 'yup';

enum NewOrderType {
    NEW = 'NEW',
    TEMPLATE = 'TEMPLATE',
}

export const validationSchemaWorkOrderItem = Yup.object({
    title: Yup.string().max(100).required('Required').nullable(),
    todoItems: validationSchemaWorkOrderItems,
    description: Yup.string().max(2048).notRequired().nullable(),
});

export const FormikFragmentInitialStateWorkOrderItem = {
    jobTemplate: null,
    title: '',
    description: '',
    todoItems: [
        {
            orderIndex: 0,
            id: 1,
            title: 'Job Complete',
        },
    ],
    workOrderItemsType: NewOrderType.TEMPLATE,
};

export const FormikInputJobTodo: React.FC<{ name }> = ({ name }) => {
    const nn = (fieldName) => (name ? `${name}.${fieldName}` : fieldName);

    return (
        <>
            <FormikInputField name={nn('title')} label={'Job Title'} required={true} />
            <FormikTextareaField name={nn('description')} rows={8} label={'Job Description'} />
            <div className={'field'}>
                <FormikFormFieldLabel name={nn('todoItems')} label={`Job Todo Items`} />
                <FormikInputJobTodoItems name={nn('todoItems')} />
            </div>
        </>
    );
};
