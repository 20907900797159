import { call, put, race, take, takeLatest, fork, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { CALENDAR_ACTION, CalendarAction, CalendarStateType, setCalendar } from './reducers-calendar-state';
import { CalViewLayoutMode, CalViewMode } from '../Scheduler/types';
import moment from 'moment';

export const createWatchShowAppointmentInCalendar = (store, URLBuilder) => {
    const sagaName = 'SAGA_DISPLAY_APPOINTMENT_IN_CALENDAR';

    function* handler(startAction) {
        const navigate = (url, state: any = {}) => {
            return navigateActions.browserNavigation(url, { ...state, saga: sagaName });
        };

        try {
            const { payload } = startAction;
            const { item, viewMode } = payload;

            yield put(navigate(URLBuilder.Scheduler.home));

            yield setCalendar({
                activeDate: moment(item.startDate).toDate(),
                viewMode: viewMode || CalViewMode.DAY,
                layoutMode: CalViewLayoutMode.GRID,
            })(store.dispatch, store.getState);

            yield put(CalendarAction.setPreviewAppt(item.id));
            yield delay(1000);
            yield put(CalendarAction.highlightAppointmentInCalendar(item));
            yield delay(8000);
            yield put(CalendarAction.highlightAppointmentInCalendar(null));
        } catch (e) {
        } finally {
            if (yield cancelled()) {
                yield put(CalendarAction.highlightAppointmentInCalendar(null));
            }
        }
    }

    function* abortWatcher(sagaName) {
        yield race({
            navigationAbort: call(sagaAbortOnNavigation, sagaName),
            startAction: take(CALENDAR_ACTION.SHOW_APPOINTMENT_IN_CALENDAR),
        });
    }

    function* watchAppointmentShowInCalendar() {
        // while (true) {
        yield takeLatest(CALENDAR_ACTION.SHOW_APPOINTMENT_IN_CALENDAR, handler);
        // const startAction =
        // const bsTasks = fork(handler, startAction);
        // yield race({
        //     saga: bsTasks,
        //     abort: take(CALENDAR_ACTION.SHOW_APPOINTMENT_IN_CALENDAR),
        // });
        // }
    }

    return watchAppointmentShowInCalendar;
};
