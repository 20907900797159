import * as React from 'react';
import { useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { StatsGraph } from '../components/StatsGraph';
import {
    DateRange,
    DateRangeOptionItem,
    DateRangePickerButton,
    FormLabel,
    HStack,
    MenuBarItem,
    MenuBarSection,
    SegmentedSelectButtons,
    StickyMenuBar,
    VStack,
} from '@poolware/components';
import moment from 'moment';
import { QueryReportsVolumeTotal, QueryStatsWaterTests } from '../Queries/QueryWaterTestStats';
import { NodeType } from '@poolware/api';
import { StatsTable } from '../components/StatsTable';
import { defaultDateRange, getDateRangeSelectOptions } from './dateRangeSelectOptions';
import { DataPoint } from '../components/graph-helpers';
import { getDateFormatterForResolution, getGroupByOptions, getResolutionOptions } from './helpers';
import { StatsGroupByEnum } from '../Queries/QueryStats';
import { IWithPageProps, withPageProps } from '../../../components/withPageProps';

const dateRangeSelectOptions: DateRangeOptionItem[] = getDateRangeSelectOptions();
const resolutionOptions = getResolutionOptions();

const DashboardPage: React.FC<IWithPageProps> = ({ AppNavigator, AuthState }) => {
    const groupByOptions = getGroupByOptions(AuthState.isAdmin);

    const [dateRange, setDateRange] = useState(defaultDateRange);
    const [resolution, setResolution] = useState<NodeType.DateRangeResolutionEnum>(
        NodeType.DateRangeResolutionEnum.Day
    );
    const [groupBy, setGroupBy] = useState<StatsGroupByEnum>(StatsGroupByEnum.None);

    const goBack = () => {
        AppNavigator.navigate('/', { relativeToModule: true });
    };

    const handleResolutionChange = (option) => {
        setResolution(option?.value);
    };

    const handleOnDateRangeChange = (newDaterange: DateRange) => {
        setDateRange(newDaterange);
    };

    const handleOnGroupByChange = (option) => {
        setGroupBy(option?.value);
    };

    const variables = {
        ...dateRange,
        groupBy: groupBy,
        resolution: resolution,
    };

    const dateFormat = getDateFormatterForResolution(resolution);

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarItem onClick={goBack} icon={'chevron left'} title={'Dashboard'} />
                </MenuBarSection>
            </StickyMenuBar>

            <Segment>
                <Header>Water tests over time</Header>
                <HStack style={{ display: 'inline-flex' }}>
                    <div>
                        <FormLabel>Date range</FormLabel>
                        <DateRangePickerButton
                            buttonProps={{
                                color: 'teal',
                            }}
                            dateRangeSelectOptions={dateRangeSelectOptions}
                            initialDateRange={dateRange}
                            onChange={handleOnDateRangeChange}
                        />
                    </div>
                    <SegmentedSelectButtons
                        label={'Group by'}
                        options={resolutionOptions}
                        onChange={handleResolutionChange}
                        value={resolution}
                        fluid={false}
                    />
                </HStack>
            </Segment>

            <QueryReportsVolumeTotal {...variables}>
                {({ activitySamples, loading, error }) => {
                    if (error) {
                        return <div>Error</div>;
                    }

                    const dataPoints: DataPoint[] = activitySamples.map((i) => ({
                        time: moment(i.date).valueOf(),
                        value: i.count,
                    }));

                    return (
                        <Segment>
                            <Header size={'tiny'}>Water Tests</Header>
                            <StatsGraph loading={loading} dateFormat={dateFormat} data={dataPoints} />
                        </Segment>
                    );
                }}
            </QueryReportsVolumeTotal>

            <QueryStatsWaterTests {...variables}>
                {({ activitySamples, loading, error }) => {
                    if (error) {
                        return <div>Error</div>;
                    }
                    return (
                        <VStack>
                            <HStack style={{ display: 'inline-flex' }}>
                                <SegmentedSelectButtons
                                    label={'Group by'}
                                    options={groupByOptions}
                                    onChange={handleOnGroupByChange}
                                    value={groupBy}
                                    fluid={false}
                                />
                            </HStack>
                            <StatsTable
                                data={activitySamples}
                                dateFormat={dateFormat}
                                loading={loading}
                                groupBy={groupBy}
                            />
                        </VStack>
                    );
                }}
            </QueryStatsWaterTests>
        </VStack>
    );
};

export default withPageProps()(DashboardPage);
