import { Dropdown, Icon } from 'semantic-ui-react';
import * as React from 'react';
import { useAppConfig } from '../app-config';
import { useViewer } from '../Viewer';
import { useApolloClient } from 'react-apollo';
import { Navbar } from '@poolware/components';

export const DropdownMenuAccount = () => {
    const client = useApolloClient();
    const appConfig = useAppConfig();
    const { isAdmin, checkingAuthState, Auth } = useViewer();
    const profileLInk = appConfig?.apps?.account || '/profile';

    const logOut = async () => {
        try {
            await Auth.AuthAction.logout();
            await client.resetStore();
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <>
            <Navbar.DropdownMenu
                color={'blue'}
                icon={isAdmin ? 'spy' : 'user'}
                iconLoading={checkingAuthState}
                label={Auth.AuthState.user.username}
            >
                <Dropdown.Item as={'a'} href={profileLInk}>
                    <Icon name="spy" />
                    Profile
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item icon="log out" text="Log Out" onClick={logOut} />
            </Navbar.DropdownMenu>
        </>
    );
};
