import * as React from 'react';
import { NodeType, useQueryConnectionOrganisationTypes } from '@poolware/api';
import { FormikSuggestInputProps, SuggestInput, withFormikSuggestField } from '@poolware/components';

export const SuggestInputOrganisationType: React.FC<FormikSuggestInputProps<NodeType.OrganisationType>> = ({
    onChange,
    ...rest
}) => {
    const { connectionState, connectionData } = useQueryConnectionOrganisationTypes();

    const handleOnSearchChange = (value: string) => {};

    const itemRenderer = (item: NodeType.OrganisationType): string => {
        return item?.name || '-';
    };

    return (
        <SuggestInput
            {...rest}
            items={connectionData}
            loading={connectionState.loading}
            onItemSelect={onChange}
            onQueryChange={handleOnSearchChange}
            itemTextLabel={itemRenderer}
        />
    );
};

export const FormikSuggestInputOrganisationType = withFormikSuggestField(SuggestInputOrganisationType);

const S = () => {
    return <FormikSuggestInputOrganisationType />;
};
