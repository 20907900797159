import { usePersistedToggle } from '@ez/tools';

const AUTO_OPEN_PDF = 'pw.watertest.auto-open-pdf';
const AUTO_SUBMIT_TEST_REPORT = 'pw.watertest.auto-submit-pdf';
const WATERLINK_PANEL_EXPANDED = 'pw.watertest.waterlink.expanded';

export const usePersistedFormState = () => {
    const [autoOpenPDF, setAutoOpenPDF] = usePersistedToggle(AUTO_OPEN_PDF, false);
    const [autoSubmitReport, setAutoSubmitReport] = usePersistedToggle(AUTO_SUBMIT_TEST_REPORT, false);
    const [expandWaterlinkPanel, setExpandWaterlinkPanel] = usePersistedToggle(WATERLINK_PANEL_EXPANDED, false);
    return {
        autoOpenPDF,
        setAutoOpenPDF,
        autoSubmitReport,
        setAutoSubmitReport,
        expandWaterlinkPanel,
        setExpandWaterlinkPanel,
    };
};
