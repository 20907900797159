import * as React from 'react';
import { NodeType, useQueryStaffConnection } from '@poolware/api';
import { ConnectionTableDef, Display, TablePickerPanel } from '@poolware/components';

export interface StaffPickerProps {
    onCancel: () => any;
    onSubmit: (selectedItems: NodeType.Staff[]) => any;
    selectedItems?: NodeType.Staff[];
}

export const StaffPicker: React.FC<StaffPickerProps> = ({ onCancel, selectedItems, onSubmit }) => {
    const { connectionState, connectionData } = useQueryStaffConnection({});

    const tableDef: ConnectionTableDef<NodeType.Staff> = [
        {
            header: 'Staff',
            cell: (r) => {
                return <Display.Entity value={r} />;
            },
        },
        {
            header: 'Role',
            cell: (r) => {
                return <>{r.role?.name}</>;
            },
        },
    ];

    return (
        <div>
            <TablePickerPanel
                // isRowSelectable={getRowSelectable}
                multiSelect={true}
                panelHeader={{ content: 'Staff' }}
                onCancel={onCancel}
                onSubmit={onSubmit}
                selectedItems={selectedItems}
                connectionState={connectionState}
                connectionData={connectionData}
                tableDef={tableDef}
            />
        </div>
    );
};
