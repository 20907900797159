import * as React from 'react';
import { FormikDefaultForm, MenuBarHeaderItem, MenuBarSection, StickyMenuBar, VStack } from '@poolware/components';
import { FormikAddressInputFields } from '../CommonComponents/Address/FormikAddressInputFields';
import { usePersistedString } from '@ez/tools';

export const PageAddress = () => {
    const [defaultCountry, setDefaultCountry] = usePersistedString('address.country-code', 'AU');

    const initialValue = {
        address: { country: defaultCountry },
    };
    const onSubmit = (values) => {
        const country = values?.address?.country;
        setDefaultCountry(country);
    };

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem title={'Address'} />
                </MenuBarSection>
            </StickyMenuBar>
            <FormikDefaultForm
                header={'Address Fields test'}
                initialValues={initialValue}
                onSubmit={onSubmit}
                debug={true}
            >
                <FormikAddressInputFields name={'address'} />
            </FormikDefaultForm>
        </VStack>
    );
};
