import { applyMiddleware, combineReducers, compose, createStore, Middleware, ReducersMapObject } from 'redux';
import * as _ from 'lodash';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { END } from 'redux-saga';
import { systemDeserializers, systemReducers, systemSerializers } from './system-reducers';
import { ReduxStoreDeserializers, ReduxStateSerializerFn, ReduxStoreSerializers } from './types';
import { EZStorage, isLocalStorageUsable } from '@ez/tools';

const localStorageEnabled = isLocalStorageUsable();
const usePersistedState = localStorageEnabled; // process.env.NODE_ENV !== 'production';
const REDUX_STORAGE_KEY = 'reduxState';

const defaultSerializer: ReduxStateSerializerFn = (state) => state;
const defaultDeserializer: ReduxStateSerializerFn = (state) => state;

const saveStore = (storeState, serializers: ReduxStoreSerializers = {}) => {
    // Pick only selected states
    let state = _.pick(storeState, [
        //'pwapi', //
        'theme',
        'scheduler',
        'catalog',
        'serviceJob',
    ]);

    try {
        const serializedState = Object.keys(state).reduce((acc, key) => {
            const serializer = serializers[key] || defaultSerializer;
            const res = serializer(state[key]);
            if (res) {
                acc[key] = res;
            }
            return acc;
        }, {});

        EZStorage.setItem(REDUX_STORAGE_KEY, JSON.stringify(serializedState));
    } catch (e) {
        console.error(e);
    }
};

const restoreInitialState = (deserializers: ReduxStoreDeserializers = {}) => {
    try {
        const storageValue = EZStorage.getItem(REDUX_STORAGE_KEY);
        let persistedState = storageValue ? JSON.parse(storageValue) || {} : {};
        const deserializedState = Object.keys(persistedState).reduce((acc, key) => {
            const serializer = deserializers[key] || defaultDeserializer;
            if (serializer) {
                acc[key] = serializer(persistedState[key]);
            }
            return acc;
        }, {});

        return deserializedState;
    } catch (e) {
        console.error(e);
    }
    return {};
};

const saveStoreDebounced = _.debounce(saveStore, 1500);

export type CreateReduxStoreConfig = {
    rootReducersMapObject: ReducersMapObject;
    rootSaga?: any;
    rootSagaCreator?: (store) => any;
    initialState?: any;
    middlewares?: Middleware[];
    deserializers?: ReduxStoreDeserializers;
    serializers?: ReduxStoreSerializers;
};

export const createReduxStore = (storeConfig: CreateReduxStoreConfig) => {
    const {
        rootReducersMapObject,
        initialState,
        middlewares = [],
        deserializers,
        serializers,
        rootSaga,
        rootSagaCreator,
    } = storeConfig;

    const sagaMiddleware = createSagaMiddleware();

    const _middlewares = [
        thunkMiddleware,
        sagaMiddleware,
        ...middlewares,
        // createLogger({diff: false, collapsed: true}),
    ];

    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const enhancer = composeEnhancers(applyMiddleware(..._middlewares));

    let persistedState = {};
    if (usePersistedState && localStorageEnabled) {
        const rootDeserializers = {
            ...systemDeserializers,
            ...deserializers,
        };
        persistedState = restoreInitialState(rootDeserializers);
    }

    // console.log('loaded persisted store:');
    // console.log(persistedState);
    let state = { ...persistedState, ...initialState };

    const rootReducer = combineReducers({
        ...systemReducers,
        ...rootReducersMapObject,
    });

    let store: any = createStore(rootReducer, state, enhancer);

    if (usePersistedState && localStorageEnabled) {
        const rootSerializers = {
            ...systemSerializers,
            ...serializers,
        };
        store.subscribe(() => saveStoreDebounced(store.getState(), rootSerializers));
    }

    store.runSaga = sagaMiddleware.run;
    if (rootSaga) {
        store.runSaga(rootSaga);
    }
    if (rootSagaCreator) {
        store.runSaga(rootSagaCreator(store));
    }
    store.close = () => store.dispatch(END);

    return store;
};
