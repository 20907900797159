export const removeDimmer = () => {
    const delay = 500;
    const dimmer: any = document.querySelector('#app-loading-dimmer');
    if (!dimmer) {
        return;
    }

    dimmer.style.pointerEvents = 'none';
    dimmer.style.transition = 'opacity ' + delay + 'ms linear';
    dimmer.style.opacity = '0';
    setTimeout(function () {
        const dimmer = document.querySelector('#app-loading-dimmer');
        if (!dimmer) {
            return;
        }
        try {
            document.body.removeChild(dimmer);
        } catch (err) {}
    }, delay);
};
