import * as React from 'react';
import { Form, Menu, Segment } from 'semantic-ui-react';
import { MenuBarHeaderItem, VStack } from '@poolware/components';
import {
    FilterByBrand,
    FilterByProductCompany,
    FilterByProductName,
    FilterBySku,
    FilterByTraitDeclaration_Tree,
} from '../../../components-redux-connected';

const SearchSideBar: React.FC = () => {
    return (
        <Form>
            <VStack>
                <div>
                    <Menu attached={'top'} compact={true}>
                        <Menu.Menu position={'left'}>
                            <MenuBarHeaderItem icon={'search'}>Search</MenuBarHeaderItem>
                        </Menu.Menu>
                    </Menu>
                    <Segment attached={'bottom'}>
                        <FilterByProductName />
                        <FilterBySku />
                        <FilterByBrand label={'Brand'} />
                        <FilterByProductCompany label={'Product Company'} />
                    </Segment>
                </div>

                <FilterByTraitDeclaration_Tree header={'Search By Category'} />
            </VStack>
        </Form>
    );
};

export default SearchSideBar;
