import gql from 'graphql-tag';
import { addressFragment } from '@poolware/api';

export const warrantyClaimFragment = gql`
    fragment WarrantyClaimFragment on WarrantyClaim {
        id
        entity {
            id
        }
        status
        statusMessage
        statusGroup
        statusHistory {
            edges {
                node {
                    id
                    message
                    createdAt
                    status
                    group
                    changedBy {
                        id
                        user {
                            id
                            firstName
                            lastName
                        }
                    }
                }
            }
        }
        customerCompanyName
        customerFirstName
        customerLastName
        customerEmail
        customerPhone
        warrantyClaimNumber
        siteAccessKey
        assetName
        assetSerial
        assetLocation
        assetInstallDate
        claimedAt
        installerName
        faultDescription
        comment
        submittedAt
        claimedAt
        confirmedAt
        receiverEmail
        receiverWarranter {
            id
            email
            name
        }
        createdAt
        updatedAt
        refSite {
            id
            name
        }
        address {
            id
            ...AddressFragment
        }
        receiverStaff {
            id
        }
        createdAt
        franchise {
            id
            name
            email
        }
        addedBy {
            id
            user {
                id
                firstName
                lastName
            }
        }
        refAsset {
            id
        }
        refCustomer {
            id
            user {
                id
                firstName
                lastName
            }
        }
        checkMutations {
            delete
            update
        }
        files {
            edges {
                node {
                    id
                    label
                    file {
                        id
                        isPublic
                        note
                        fileName
                        mimeType
                        location
                        createdAt
                        isImage
                        url
                    }
                    checkMutations {
                        delete
                        update
                    }
                }
            }
        }
        emailCC
        emailReplyTo
        externalSRN
        externalSO
        externalRMA
    }
    ${addressFragment}
`;
