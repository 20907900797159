import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { connect, getIn, useFormikContext } from 'formik';
import { fromEdges, NodeType } from '@poolware/api';
import { CustomerAddressList } from './CustomerAddressList';
import { FormFieldLabel, PageSkeletonLoader } from '@poolware/components';
import { Message } from 'semantic-ui-react';
import { useQueryCustomerDetails } from './useQueryCustomerDetails';
import { CustomerContactsList } from './CustomerContactsList';
import { FormikCustomerLookupField } from '@poolware/app-shell';
import { WarrantyClaimFormValues } from '../WarrantyClaimForm';

export const FormikCustomerFields = connect(({ formik }) => {
    const { values } = useFormikContext<WarrantyClaimFormValues>();
    const customer = values.refCustomer;
    const customerReadOnly = !values.isNew && values.customerReadOnly;

    const onCustomerRemove = () => {
        formik.setFieldValue('pool', null);
        formik.setFieldValue('address', null);
        formik.setFieldValue('customerPhone', null);
        formik.setFieldValue('customerEmail', null);
    };

    return (
        <>
            <FormikCustomerLookupField
                name={'refCustomer'}
                label={'Customer'}
                onRemove={onCustomerRemove}
                readOnly={customerReadOnly}
            />
            {customer && customer.id && <CustomerDetailFields customerId={customer.id} />}
        </>
    );
});

const CustomerDetailFields = connect<{ customerId: NodeType.ID }>(({ formik, customerId }) => {
    const { error, loading, node: customer } = useQueryCustomerDetails(customerId);

    const formikBag = formik;
    const pool = getIn(formikBag.values, 'pool');
    const address = getIn(formikBag.values, 'address');

    const { phones, emails } = useMemo(() => {
        const contacts = fromEdges(customer?.root?.contacts);
        const phones = contacts.filter((c) => {
            return c.type?.validatorTag === 'PHONE';
        });

        const emails = contacts.filter((c) => {
            return c.type?.validatorTag === 'EMAIL';
        });
        return { phones, emails };
    }, [loading, customer]);

    useEffect(() => {
        const customerPhone = getIn(formikBag.values, 'customerPhone.value');
        const customerEmail = getIn(formikBag.values, 'customerEmail.value');

        //////
        const selectedPhoneId = phones.reduce((acc, c) => {
            return c?.data === customerPhone ? c.id : acc;
        }, null);

        const isCustomPhone = Boolean(customerPhone && !selectedPhoneId);
        formik.setFieldValue('customerPhone.isAdhoc', isCustomPhone);
        formik.setFieldValue('customerPhone.adhocValue', isCustomPhone ? customerPhone : '');

        //////
        const selectedEmailId = emails.reduce((acc, c) => {
            return c?.data === customerEmail ? c.id : acc;
        }, null);

        const isAdhocEmail = Boolean(customerEmail && !selectedEmailId);
        formik.setFieldValue('customerEmail.isAdhoc', isAdhocEmail);
        formik.setFieldValue('customerEmail.adhocValue', isAdhocEmail ? customerEmail : '');
    }, [phones, emails]);

    if (loading) {
        return <PageSkeletonLoader />;
    }

    if (error) {
        return (
            <Message>
                <pre>{JSON.stringify(error, null, 2)}</pre>
            </Message>
        );
    }

    const onAddressSelect = (address: NodeType.Address) => {
        formikBag.setFieldValue('pool', null);
        formikBag.setFieldValue('address', address);
    };

    const onPoolSelect = (pool: NodeType.Pool) => {
        formikBag.setFieldValue('pool', pool);
        if (pool && pool.address) {
            formikBag.setFieldValue('address', pool.address);
        } else {
            formikBag.setFieldValue('address', null);
        }
    };

    return (
        <>
            <div className={'field'}>
                <FormFieldLabel label={'Address'} />
                <div style={{ paddingLeft: '1rem' }}>
                    <CustomerAddressList
                        selectedPoolId={pool && pool.id}
                        selectedAddressId={address && address.id}
                        customer={customer}
                        onAddressSelect={onAddressSelect}
                        onPoolSelect={onPoolSelect}
                    />
                </div>
            </div>
            <div className={'field'}>
                <FormFieldLabel label={'Phone'} />
                <div style={{ paddingLeft: '1rem' }}>
                    <CustomerContactsList contacts={phones} name={'customerPhone'} />
                </div>
            </div>
            <div className={'field'}>
                <FormFieldLabel label={'Email'} />
                <div style={{ paddingLeft: '1rem' }}>
                    <CustomerContactsList contacts={emails} name={'customerEmail'} />
                </div>
            </div>
        </>
    );
});
