import { NodeType } from '@poolware/api';

export default class ContactItem {
    contact?: NodeType.Contact;
    label: string;
    view: any;
    newView?: any;
    editView?: any;
    deleteView?: any;
    makePrimaryView?: any;

    constructor(value: {
        contact?: NodeType.Contact;
        label: string;
        view: any;
        editView?: any;
        deleteView?: any;
        newView?: any;
        makePrimaryView?: any;
    }) {
        this.contact = value.contact;
        this.label = value.label;
        this.view = value.view;
        this.editView = value.editView;
        this.newView = value.newView;
        this.deleteView = value.deleteView;
        this.makePrimaryView = value.makePrimaryView;
    }
}
