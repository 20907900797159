import { useWorkOrderFeedActions, WorkOrderBookingStatus, WorkOrderRecurStatus } from '../../redux';
import { SearchByAssignmentState } from '../../queries/use-query-work-orders-connection';
import { DateRange, UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';
import { useMemo } from 'react';
import gql from 'graphql-tag';
import {
    addressFragment,
    NodeType,
    QueryConnectionConfigProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import * as _ from 'lodash';
import { useViewer } from '@poolware/app-shell';

const QL = gql`
    query QueryWorkOrderFeed(
        $after: String
        $before: String
        $first: Int
        $search: WorkOrderFeedItemViewerWorkOrderFeedSearch
        $reverse: Boolean
    ) {
        viewer {
            workOrderFeed(after: $after, before: $before, first: $first, search: $search, reverse: $reverse) {
                edges {
                    node {
                        id
                        date
                        title
                        staff {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        address {
                            id
                            ...AddressFragment
                        }
                        customer {
                            id
                            crn
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        serviceJob {
                            id
                            isRecurring
                            title
                            serviceJobNumber
                            group {
                                id
                                title
                            }
                        }
                        workOrder {
                            id
                            workOrderNumber
                            title
                            updatedAt
                            createdAt
                            statusClosedAt
                            checkedInAt
                            checkedOutAt
                            stage {
                                id
                                title
                                status
                                type
                            }
                        }
                        pool {
                            id
                            name
                            id
                            name
                            bottleNumber
                            volume
                            type {
                                id
                                name
                            }
                            site {
                                id
                                accessKeyLocation
                            }
                            sanitiser {
                                id
                                name
                            }
                            address {
                                id
                                ...AddressFragment
                            }
                        }
                        appointmentItem {
                            id
                            startDate
                            duration
                        }
                    }
                }
                pageInfo {
                    startCursor
                    endCursor
                    hasNextPage
                    hasPreviousPage
                }
            }
        }
    }
    ${addressFragment}
`;

export const customWorkOrderFeedItemMutation = gql`
    mutation WorkOrderFeedItemMutation($input: WorkOrderFeedItemMutationInput!) {
        WorkOrderFeedItem(input: $input) {
            WorkOrderFeedItem {
                id
                workOrder {
                    id
                    stage {
                        id
                        title
                        type
                        status
                    }
                    workOrderNumber
                }
            }
        }
    }
`;

interface QueryWorkOrdersFeedConnectionProps extends Partial<QueryConnectionConfigProps> {
    isRecurring?: boolean;
    workOrderNumber?: string;
    hasAppointment?: boolean;
    assignedToStaff?: NodeType.NodeOrId<NodeType.Staff>;
    serviceJobGroup?: NodeType.NodeOrId<NodeType.ServiceJobGroup>;
    usedTemplate?: NodeType.NodeOrId<NodeType.ServiceJobTemplate>;
    assignmentState?: SearchByAssignmentState;
    serviceJob?: NodeType.NodeOrId<NodeType.ServiceJob>;
    dateRange?: DateRange;
    reverse?: Boolean;
    stageId?: NodeType.ID;
    stageType?: NodeType.ServiceJobStageType[];
}

const mapPropsToSearchQuery = (
    props: QueryWorkOrdersFeedConnectionProps
): NodeType.WorkOrderFeedItemViewerWorkOrderFeedSearch => {
    if (!props) {
        return undefined;
    }

    const {
        assignedToStaff,
        assignmentState,
        workOrderNumber,
        hasAppointment,
        serviceJob,
        isRecurring,
        dateRange,
        serviceJobGroup,
    } = props;

    const search: NodeType.WorkOrderFeedItemViewerWorkOrderFeedSearch = {};

    if (dateRange) {
        _.set(search, 'dateRange.from', dateRange.startDate);
        _.set(search, 'dateRange.to', dateRange.endDate);
    }
    if (hasAppointment !== null && hasAppointment !== undefined) {
        _.set(search, 'hasAppointment', Boolean(hasAppointment));
    }

    if (isRecurring !== undefined && isRecurring !== null) {
        _.set(search, 'serviceJob.isRecurring.is', Boolean(isRecurring));
    }

    if (assignmentState === SearchByAssignmentState.ASSIGNED) {
        _.set(search, 'assignedTo.not', null);
    }

    if (assignmentState === SearchByAssignmentState.UNASSIGNED) {
        _.set(search, 'staff', [null]);
    }

    if (assignedToStaff) {
        _.set(search, 'staff', NodeType.extractId(assignedToStaff));
    }

    if (serviceJobGroup) {
        _.set(search, 'serviceJobGroup', [NodeType.extractId(serviceJobGroup)]);
    }

    if (props.usedTemplate) {
        _.set(search, 'usedTemplate', NodeType.extractId(props.usedTemplate));
    }

    if (workOrderNumber !== undefined && workOrderNumber !== null) {
        _.set(search, 'workOrderNumber', workOrderNumber);
    }

    if (serviceJob) {
        _.set(search, 'serviceJob.id', NodeType.extractId(serviceJob));
    }

    if (0 /* TODO: waiting for implementation */) {
        if (props.stageId) {
            _.set(search, 'stage.id', props.stageId);
        }
    }
    if (props.stageType) {
        _.set(search, 'stageType', props.stageType?.filter(Boolean));
    }

    return search;
};

export function useQueryWorkOrdersFeedConnection(props?: QueryWorkOrdersFeedConnectionProps) {
    const connectionPath = 'viewer.workOrderFeed';
    const { variables, connectionState } = useDefaultConnectionState({
        search: mapPropsToSearchQuery(props),
        pageSize: 1000,
        ...props,
    });

    variables.reverse = props.reverse;

    return useQueryConnection<NodeType.WorkOrderFeedItem>({
        query: QL,
        fetchPolicy: 'cache-and-network',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}

export const useConnectedQueryWorkOrdersFeed = () => {
    const { State } = useWorkOrderFeedActions();
    const { modulesAccess, viewer } = useViewer();

    const queryConf = useMemo(() => {
        const { staffIds, woNumber, woBookingStatus, woRecurStatus, displayingDateRange } = State.filters;
        const queryConf: QueryWorkOrdersFeedConnectionProps = {};

        const singleStaffMode = modulesAccess.FieldServices?.calendarSingleStaffMode;

        if (singleStaffMode) {
            queryConf.assignedToStaff = viewer?.me?.staff?.id;
        } else if (staffIds.length > 0) {
            if (staffIds.includes(UNASSIGNED_APPOINTMENT_STAFF_ID)) {
                queryConf.assignmentState = SearchByAssignmentState.UNASSIGNED;
            } else {
                queryConf.assignedToStaff = staffIds[0];
            }
        }
        queryConf.serviceJobGroup = State.filters.serviceGroupId;
        queryConf.usedTemplate = State.filters.serviceJobTemplateId;

        if (woNumber) {
            queryConf.workOrderNumber = woNumber;
        }

        switch (woBookingStatus) {
            // case WorkOrderBookingStatus.ANY:
            //     queryConf.hasAppointment = null;
            //     break;
            case WorkOrderBookingStatus.BOOKED:
                queryConf.hasAppointment = true;
                queryConf.dateRange = displayingDateRange;
                queryConf.reverse = false;
                break;
            case WorkOrderBookingStatus.UNBOOKED:
                queryConf.hasAppointment = false;
                queryConf.reverse = true;
                break;
        }

        switch (woRecurStatus) {
            case WorkOrderRecurStatus.ANY:
                queryConf.isRecurring = null;
                break;
            case WorkOrderRecurStatus.RECURRING:
                queryConf.isRecurring = true;
                break;
            case WorkOrderRecurStatus.ONEOFF:
                queryConf.isRecurring = false;
                break;
        }

        queryConf.stageId = State.filters.serviceStageId;
        queryConf.stageType = State.filters.serviceStageType;

        return queryConf;
    }, [State.filters, modulesAccess.FieldServices]);

    return useQueryWorkOrdersFeedConnection(queryConf);
};
