import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType, staffFragment } from '@poolware/api';

export const queryQueryStaffTeamsConnection = gql`
    query QueryStaffTeamsConnection(
        $after: String
        $first: Int
        $before: String
        $last: Int
        $page: Int
        $sort: StaffTeamViewerStaffTeamsSort
        $search: StaffTeamViewerStaffTeamsSearch
    ) {
        viewer {
            staffTeams(
                after: $after
                first: $first
                before: $before
                last: $last
                page: $page
                sort: $sort
                search: $search
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                pageMeta {
                    pageCount
                    totalCount
                }
                edges {
                    node {
                        id
                        title
                        tag
                        staff(sort: { firstName: true }) {
                            edges {
                                node {
                                    ...StaffFragment
                                }
                            }
                        }
                        franchise {
                            id
                            name
                            organisationType {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
    ${staffFragment}
`;

export const queryStaffTeamNode = gql`
    query queryConexpNodeStaffTeam($id: ID!) {
        node(id: $id) {
            ... on StaffTeam {
                id
                title
                tag
                staff(sort: { firstName: true }) {
                    edges {
                        node {
                            ...StaffFragment
                        }
                    }
                }
                franchise {
                    id
                    name
                    organisationType {
                        id
                        name
                    }
                }
                checkMutations {
                    delete
                    addStaff
                    update
                }
            }
        }
    }
    ${staffFragment}
`;

export const useQueryStaffTeam = createUseQueryNodeHook<NodeType.StaffTeam>(queryStaffTeamNode);
