import { createAuthReducer, pwapiDeserializer, pwapiSerializer } from '@ez/api-core';
import { getAppConfig } from '../app-config';
import { themeReducer } from '../app-theme';

const config = getAppConfig();

const authReducer = createAuthReducer(config.api.restAPIBaseUrl);

export const systemReducers = {
    pwapi: authReducer,
    theme: themeReducer,
};

export const systemDeserializers = {
    pwapi: pwapiDeserializer,
};

export const systemSerializers = {
    pwapi: pwapiSerializer,
};
