import {
    convertToConfigValue,
    IMutateServiceJob,
    MutationHookOptionsServiceJob,
    NodeType,
    useMutationServiceJob,
} from '@poolware/api';
import * as _ from 'lodash';
import { sanitizeAppointmentStartDate, sanitizeRecurrence } from './use-appointment-mutators';

export interface ServiceJobCreateInput {
    title?: string;
    description?: string;
    dueDate?: Date;
    address?: NodeType.NodeOrId<NodeType.Address>;
    customer?: NodeType.Customer;
    staff?: NodeType.Staff;
    pool?: NodeType.Pool;
    template?: NodeType.ServiceJobTemplate;
    entity: {
        id?: NodeType.ID;
        create?: any;
    };
    workOrder?: any;
    recurrentAppointment?: NodeType.ServiceJobBookingNodeInitInput;
}

export interface IServiceJobMutatorInjected extends IMutateServiceJob {}

const createMutator = ({ mutateServiceJob }: IServiceJobMutatorInjected) => {
    interface UpdateServiceJobMutationInput {
        id: NodeType.ID;
        entity?: any;
        title?: string;
        description?: string;
        dueDate?: Date;
        address?: any;
        customer?: NodeType.ID;
        staff?: NodeType.ID;
        pool?: NodeType.ID;
        files?: any;
    }

    const update = async (serviceJob: NodeType.NodeOrId<NodeType.ServiceJob>, input: Partial<NodeType.ServiceJob>) => {
        const mutationConfig: UpdateServiceJobMutationInput = {
            id: NodeType.extractId(serviceJob),
        };

        mutationConfig.description = convertToConfigValue({ values: input, key: 'description' });
        mutationConfig.dueDate = convertToConfigValue({ values: input, key: 'dueDate' });
        mutationConfig.title = convertToConfigValue({ values: input, key: 'title' });
        if (mutationConfig.title) {
            mutationConfig.title = _.trim(mutationConfig.title);
        }
        mutationConfig.customer = convertToConfigValue({ values: input, key: 'customer', isSourceNode: true });
        mutationConfig.staff = convertToConfigValue({ values: input, key: 'staff', isSourceNode: true });
        mutationConfig.address = convertToConfigValue({ values: input, key: 'address', isSourceNode: true });
        mutationConfig.pool = convertToConfigValue({ values: input, key: 'pool', isSourceNode: true });

        return await mutateServiceJob.update(mutationConfig);
    };

    const addFiles = async (serviceJob: NodeType.ServiceJob, fileIds: NodeType.ID[]) => {
        return await mutateServiceJob.update({
            id: serviceJob.id,
            files: {
                assign: fileIds,
            },
        });
    };

    const removeFile = async (serviceJob: NodeType.ServiceJob, files: NodeType.FileUpload[]) => {
        const fileIds = files.map((f) => f.id);
        return await mutateServiceJob.update({
            id: serviceJob.id,
            files: {
                delete: fileIds,
            },
        });
    };

    const createServiceJob = async (input: Partial<ServiceJobCreateInput>) => {
        const mutationConfig: NodeType.CreateServiceJobMutationInput = {
            entity: { create: {} },
        };

        mutationConfig.description = convertToConfigValue({ values: input, key: 'description' });
        mutationConfig.dueDate = convertToConfigValue({ values: input, key: 'dueDate' });
        mutationConfig.title = _.trim(convertToConfigValue({ values: input, key: 'title' }));
        mutationConfig.customer = convertToConfigValue({ values: input, key: 'customer', isSourceNode: true });
        mutationConfig.staff = convertToConfigValue({ values: input, key: 'staff', isSourceNode: true });
        mutationConfig.address = convertToConfigValue({ values: input, key: 'address', isSourceNode: true });
        mutationConfig.pool = convertToConfigValue({ values: input, key: 'pool', isSourceNode: true });
        mutationConfig.workOrders = input?.workOrder;
        mutationConfig.template = convertToConfigValue({ values: input, key: 'template', isSourceNode: true });
        if (mutationConfig.template) {
            mutationConfig.autoInflateTemplate = true;
        }
        if (input?.recurrentAppointment) {
            mutationConfig.recurrentAppointment = {
                startDate: sanitizeAppointmentStartDate(input.recurrentAppointment.startDate),
                duration: input.recurrentAppointment.duration || 60,
                recurrence: sanitizeRecurrence(input.recurrentAppointment.recurrence),
                workOrderTemplate: input.recurrentAppointment?.workOrderTemplate,
                group: input.recurrentAppointment.group,
                selectedColor: input.recurrentAppointment.selectedColor,
            };
        }

        const response = await mutateServiceJob.create(mutationConfig);
        return _.get(response, 'data.ServiceJob.ServiceJob');
    };

    const deleteServiceJob = async (serviceJob: NodeType.ServiceJob) => {
        const response = await mutateServiceJob.delete({ id: serviceJob.id });
        return _.get(response, 'data.ServiceJob.ServiceJob');
    };

    const changeStage = async (serviceJob: NodeType.ServiceJob, newStage: NodeType.ServiceJobStage) => {
        const response = await mutateServiceJob.update({ id: serviceJob.id, stage: newStage?.id });
        return _.get(response, 'data.ServiceJob.ServiceJob');
    };

    return {
        ServiceJobMutator: {
            changeStage: changeStage,
            update: update,
            create: createServiceJob,
            delete: deleteServiceJob,
            addFiles: addFiles,
            removeFile: removeFile,
        },
    };
};

export const useServiceJobMutators = (options?: MutationHookOptionsServiceJob) => {
    const mutateServiceJob = useMutationServiceJob(options);
    return {
        ...mutateServiceJob,
        ...createMutator({ mutateServiceJob: mutateServiceJob }),
    };
};
