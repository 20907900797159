import * as React from 'react';
import { useMemo, useState } from 'react';
import { Button, Checkbox, Icon, Message } from 'semantic-ui-react';
import { PageContainer } from '../../../Layout/PageContainer';
import { MenuBarHeaderItem, MenuBarSection, Panel, StickyMenuBar, VStack } from '@poolware/components';
import { AppConfigInterface, useAppConfig, useViewer } from '@poolware/app-shell';

const useExportLinks = (config: AppConfigInterface, franchiseId: string) => {
    return useMemo(
        () => [
            {
                type: 'csv',
                title: 'Export customers and pools (CSV format)',
                url: `${config.api.restAPIBaseUrl}/export-csv/${franchiseId}`,
            },
            {
                type: 'csv-aggregate',
                title: 'Export customers and water test summary (CSV format)',
                url: `${config.api.restAPIBaseUrl}/export-aggregate-csv/${franchiseId}`,
            },
            {
                type: 'ls-retail-json',
                title: 'Export customers (Business Central JSON format)',
                url: `${config.api.restAPIBaseUrl}/export-json/${franchiseId}`,
            },
        ],
        [config, franchiseId]
    );
};

export const DataExportPage = () => {
    const { viewer, modulesAccess, isPretending } = useViewer();
    const config = useAppConfig();
    const exportLinks = useExportLinks(config, viewer.franchise?.id);

    // HACK: use isPretending to allow export in pretending mode
    const hasAccess = modulesAccess.Export?.enabled || isPretending;
    const isAccessOverwritten = !modulesAccess.Export?.enabled && isPretending;

    return (
        <VStack>
            <StickyMenuBar>
                <MenuBarSection>
                    <MenuBarHeaderItem icon={'share square'}>Data Export</MenuBarHeaderItem>
                </MenuBarSection>
            </StickyMenuBar>
            <PageContainer pageWidth={'narrow'}>
                <Panel>
                    <Panel.Header>Export Data - {viewer?.franchise?.name}</Panel.Header>
                    <Panel.Body>
                        {hasAccess ? (
                            <div tw={'flex flex-col gap-2'}>
                                {exportLinks.map((link, index) => {
                                    return (
                                        <div tw={'flex flex-row items-center'} key={index}>
                                            <div tw={'flex-grow'}>{link.title}</div>
                                            <Button
                                                basic={true}
                                                size={'mini'}
                                                href={link.url}
                                                target={'_blank'}
                                                icon={'cloud download'}
                                                content={'Download'}
                                            ></Button>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <Message warning={true}>Access denied</Message>
                        )}
                        {isAccessOverwritten && (
                            <div tw={'mt-4 p-2 rounded bg-secondary/50 text-sm'}>
                                Note: This user account does not have access to export data. Access to export is allowed
                                in pretend mode.
                            </div>
                        )}
                    </Panel.Body>
                </Panel>
            </PageContainer>
        </VStack>
    );
};
