import * as React from 'react';
import { NodeType } from '@poolware/api';
import { FormikFormFieldLabel, stringFormatters, useModalCtrl } from '@poolware/components';
import { useField } from 'formik';
import { Button, Form, Modal, Segment } from 'semantic-ui-react';
import { ModuleIconNames } from '../../../constants';
import { AppointmentsSplitter, AppointmentsSplitterMode } from './AppointmentsSplitterTable';

export const FormikRecurAppointmentSplitter: React.FC<{
    label: string;
    name: string;
    patternBlockId?: string;
    serviceJob: NodeType.ServiceJob;
    headerLeft: string;
    headerRight: string;
    mode: AppointmentsSplitterMode;
}> = ({ name, serviceJob, headerLeft, headerRight, mode, label, patternBlockId }) => {
    const modalWOPicker = useModalCtrl();

    const [field, meta, helpers] = useField<NodeType.AppointmentItem>(name);

    const onSelectStartAppointment = (appointmentItem: NodeType.AppointmentItem) => {
        helpers.setValue(appointmentItem);
        modalWOPicker.onClose();
    };

    const selectedAppt = field.value;
    let hasError = Boolean(meta.touched && meta.error);
    const onRemove = () => {
        helpers.setValue(null);
    };
    const labelComp = <FormikFormFieldLabel name={name} label={label} required={true} />;

    return (
        <>
            <Form.Input
                label={labelComp}
                value={selectedAppt ? stringFormatters.formatDate(selectedAppt?.startDate) : ''}
                type={'text'}
                readOnly={true}
                action
                error={hasError}
            >
                <input style={{ cursor: 'pointer', backgroundColor: '#f8f8f8' }} onClick={modalWOPicker.onOpen} />
                {selectedAppt && <Button onClick={onRemove} type={'button'} basic icon={'delete'} />}
                <Button
                    type={'button'}
                    icon={ModuleIconNames.ServiceCall}
                    content={'Select'}
                    onClick={modalWOPicker.onOpen}
                />
            </Form.Input>
            <Modal {...modalWOPicker}>
                {modalWOPicker.open && (
                    <Segment>
                        <div tw={'text-blue-800'}>
                            Please select appointment day from which the new service template should start.
                        </div>
                        <AppointmentsSplitter
                            patternBlockId={patternBlockId}
                            serviceJob={serviceJob}
                            mode={mode}
                            defaultSelection={selectedAppt}
                            onCancel={modalWOPicker.onClose}
                            onSelect={onSelectStartAppointment}
                            headerLeft={`FROM: ${headerLeft || serviceJob?.title}`}
                            headerRight={`TO: ${headerRight || ''}`}
                        />
                    </Segment>
                )}
            </Modal>
        </>
    );
};
