import {
    addressFragment,
    NodeType,
    poolFragment,
    QueryConnectionConfigProps,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';

const QL = gql`
    query QueryPoolBottleLeasesConnection($first: Int, $page: Int, $search: PoolBottleViewerPoolBottlesSearch) {
        viewer {
            poolBottles(first: $first, page: $page, search: $search) {
                edges {
                    node {
                        id
                        scanCode
                        lastLease {
                            leasedAt
                            returnedAt
                            bottle {
                                id
                            }
                            leaseTo {
                                id
                            }
                            pool {
                                id
                                ...PoolFragment
                                address {
                                    id
                                    ...AddressFragment
                                }
                            }
                            leasedBy {
                                id
                            }
                            receivedBy {
                                id
                            }
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
    ${poolFragment}
    ${addressFragment}
`;

export interface QueryPoolBottlesConnectionProps extends Partial<QueryConnectionConfigProps> {
    scanCode?: string;
}

const mapPropsToSearchQuery = (props: QueryPoolBottlesConnectionProps): NodeType.PoolBottleViewerPoolBottlesSearch => {
    const scanCode = props.scanCode?.trim();
    if (scanCode) {
        return { scanCode: { is: scanCode } };
    }
    return undefined;
};

export function useQueryPoolBottleConnection(props?: QueryPoolBottlesConnectionProps) {
    const connectionPath = 'viewer.poolBottles';
    const { variables, connectionState } = useDefaultConnectionState(props);

    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection<NodeType.PoolBottle>({
        query: QL,
        // fetchPolicy: 'cache-first',
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
        skip: !props.scanCode,
    });
}
