import { customerContactDetailsFragment, recurrenceFragment, staffFragment } from '@poolware/api';
import gql from 'graphql-tag';
import { jobTodoFragment } from '../../queries/fragments';

export const appointmentItemFragmentForForm = gql`
    fragment AppointmentItemFragmentForForm on AppointmentItem {
        id
        startDate
        duration
        status
        isRecurring
        group {
            id
            title
            description
            defaultColor
            priority
        }
        color
        appointment {
            id
            recurrence {
                ...RecurrenceFragment
            }
        }
        staff {
            ...StaffFragment
        }
        customer {
            ...CustomerContactDetailsFragment
        }
        address {
            ...AddressFragment
        }
        pool {
            id
            name
            volume
            bottleNumber
            address {
                ...AddressFragment
            }
        }
        deleted
        note
        workOrder {
            id
            title
            workOrderNumber
            stage {
                id
                title
                type
                status
            }
            jobs {
                edges {
                    node {
                        id
                        ...JobTodoFragment
                    }
                }
            }
        }
        serviceJob {
            id
            title
            serviceJobNumber
            isRecurring
            stage {
                id
                title
                type
                status
            }
            group {
                id
                title
            }
        }
    }
    ${jobTodoFragment}
    ${customerContactDetailsFragment}
    ${staffFragment}
    ${recurrenceFragment}
`;

export const AppointmentItemQuery = gql`
    query AppointmentItem($itemId: ID!) {
        appointmentItem: node(id: $itemId) {
            ...AppointmentItemFragmentForForm
        }
    }
    ${appointmentItemFragmentForForm}
`;

export const CustomerQuery = gql`
    query CustomerAppointmentList($customerId: ID!) {
        customer: node(id: $customerId) {
            ... on Customer {
                id
                crn
                contactName
                user {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;
