import * as React from 'react';
import { ModuleLink } from '@poolware/react-app-navigator';
import { ServiceJobModuleRouterID } from './constants';

const createLink =
    (prefix: string, moduleId?: string): React.FC<{ id }> =>
    ({ id, children }) => {
        return (
            <ModuleLink moduleId={moduleId} to={`/${prefix}/${id}`}>
                {children}
            </ModuleLink>
        );
    };

export const ServiceJobLink = createLink('sj', ServiceJobModuleRouterID);
export const WorkOrderLink = createLink('wo', ServiceJobModuleRouterID);
export const WorkOrderItemLink = createLink('wo-item', ServiceJobModuleRouterID);
export const StaffLink = createLink('/', 'STAFF');
export const CustomerLink = createLink('/', 'CUSTOMERS');
export const WorkOrderReportLink = createLink('stub');

export const PoolLink = ({ poolId, customerId, children }) => {
    if (!poolId || !customerId) {
        return <>{children}</>;
    }
    return (
        <ModuleLink relativeToModule={false} to={`/${'customers'}/${customerId}/pools/${poolId}`}>
            {children}
        </ModuleLink>
    );
};
