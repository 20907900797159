import { AddressType } from './constants';

const typeFilter = (target) => {
    return (addressComponent) => {
        return addressComponent.types.indexOf(target) !== -1;
    };
};

const getComponentByType = (components: any[], target) => {
    return components.find(typeFilter(target));
};

const getComponentNameByType = (components: any[], target, longName = true) => {
    const result = getComponentByType(components, target);
    if (result) {
        if (longName) {
            return result.long_name;
        }
        return result.short_name;
    }
    return '';
};

const placesApiMap = {
    destinationName: {
        name: 'subpremise',
    },
    streetName: {
        name: 'route',
    },
    suburb: {
        name: 'sublocality',
    },
    city: {
        name: 'locality',
    },
    postCode: {
        name: 'postal_code',
    },
    state: {
        name: 'administrative_area_level_1',
        short: false,
    },
    streetNumber: {
        name: 'street_number',
    },
    country: {
        name: 'country',
        short: false,
    },
};

export const parseGeocodeResults = (geocodeResult?: any): AddressType | undefined => {
    if (!geocodeResult?.address_components) {
        return undefined;
    }

    const { address_components } = geocodeResult;
    const res: AddressType = {};
    for (const fieldName in placesApiMap) {
        const data = placesApiMap[fieldName];
        const result = getComponentNameByType(address_components, data?.name, data?.short);
        if (result) {
            res[fieldName] = result;
        } else {
            res[fieldName] = null;
        }
    }
    return res;
};
