import invariant from 'invariant';
import { CalNavigate } from '../types';
import VIEWS from '../Views';

export default function moveDate(View, { action, date, today, ...rest }) {
    View = typeof View === 'string' ? VIEWS[View] : View;

    switch (action) {
        case CalNavigate.TODAY:
            date = today || new Date();
            break;
        case CalNavigate.DATE:
            break;
        default:
            invariant(
                View && typeof View.navigate === 'function',
                'Calendar View components must implement a static `.navigate(date, action)` method.s'
            );
            date = View.navigate(date, action, rest);
    }
    return date;
}
