import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import {
    Display,
    PrintSection,
    PrintSectionBody,
    PrintSectionColumn,
    PrintSectionHeader,
    PrintTable,
} from '@poolware/components';

import { PageControlProps } from './EquipmentList.Control';

const { TABLE, TBODY, TD, TR } = PrintTable;

interface EquipmentListProps extends PageControlProps, PageControlProps {
    poolId: string;
}

class EquipmentList extends React.Component<EquipmentListProps> {
    render() {
        let { items, ...rest } = this.props;

        return (
            <PrintSection {...rest}>
                <PrintSectionHeader>Pool Equipment</PrintSectionHeader>
                <PrintSectionBody>
                    <PrintSectionColumn paddingVal={2}>
                        {items.length === 0 && <span>No equipment registered for this pool</span>}
                        <TABLE>
                            <TBODY>
                                {items.map((item) => {
                                    const { product } = item;
                                    if (!product) {
                                        console.error('error: expected `product`');
                                        return null;
                                    }
                                    return (
                                        <TR key={product.id}>
                                            <TD width={'4%'}>
                                                <Icon name={'cogs'} />
                                            </TD>
                                            <TD width={'35%'}>
                                                <b>{product.name}</b>
                                            </TD>
                                            <TD>
                                                <Display.Description limit={10000} value={product.description} />
                                            </TD>
                                        </TR>
                                    );
                                })}
                            </TBODY>
                        </TABLE>
                    </PrintSectionColumn>
                </PrintSectionBody>
            </PrintSection>
        );
    }
}

export default EquipmentList;
