import * as React from 'react';
import {
    Display,
    FormikDefaultForm,
    FormikTextareaField,
    HeaderButtonType,
    Panel,
    useModalCtrl,
} from '@poolware/components';
import { NodeType } from '@poolware/api';
import { Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { ButtonSectionHeader } from './components';

const validationSchema = Yup.object().shape({
    description: Yup.string().max(4000, 'Too Long!').nullable(),
});

export const AppointmentViewPanelJobNote: React.FC<{
    appointmentItem: NodeType.AppointmentItem;
    onChange: (description: string) => any;
    locked?: boolean;
}> = ({ appointmentItem, onChange, locked }) => {
    const { workOrder } = appointmentItem;
    const modalCtrl = useModalCtrl();

    const openButton: HeaderButtonType = (
        <ButtonSectionHeader disabled={locked} onClick={modalCtrl.onOpen} content={'Edit'} icon={'edit'} />
    );

    const onSubmit = async ({ description }) => {
        await onChange(_.trim(description));
        modalCtrl.onClose();
    };

    const description = workOrder?.description || appointmentItem?.note;
    return (
        <Panel>
            <Panel.Body>
                <Panel.SectionHeader size={'small'} icon={'sticky note'} button={openButton}>
                    Job Description
                </Panel.SectionHeader>
                {/*<Panel.ItemText content={description} backgroundColor={!!description && 'hsl(38,100%,97%)'} />*/}

                <Display.TextShortened
                    showLines={8}
                    value={description || ' '}
                    style={{
                        backgroundColor: !!description ? 'hsl(38,100%,97%)' : 'hsl(210, 12%, 94%)',
                        padding: '0 0.5em',
                        borderRadius: 4,
                        minHeight: '2em',
                    }}
                />
            </Panel.Body>
            {modalCtrl.open && (
                <Modal closeOnEscape={false} closeOnDimmerClick={false} {...modalCtrl}>
                    <FormikDefaultForm
                        validationSchema={validationSchema}
                        submitButton={{ content: 'Update' }}
                        initialValues={{ description }}
                        onSubmit={onSubmit}
                        onCancel={modalCtrl.onClose}
                    >
                        <FormikTextareaField
                            autoFocus={true}
                            name={'description'}
                            label={'Job Description'}
                            rows={10}
                        />
                    </FormikDefaultForm>
                </Modal>
            )}
        </Panel>
    );
};
