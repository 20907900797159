import { fromEdges, NodeType } from '@poolware/api';
import { DEFAULT_APPOINTMENT_GROUP_ID, UNASSIGNED_APPOINTMENT_STAFF_ID } from '../../constants';

type FilterOptions = {
    excludeAppointmentGroupsIds?: string[];
    staffIds?: string[];
    includeStatuses?: string[];
};

export const filterAppointmentItems = (
    appointmentItems: NodeType.AppointmentItem[],
    filters: FilterOptions = {},
    ignoreStaffFiler?: boolean
): NodeType.AppointmentItem[] => {
    const items = fromEdges(appointmentItems);

    return items
        .filter((appointmentItem) => {
            // Filter by Appointment group
            const { excludeAppointmentGroupsIds } = filters;

            if (!(excludeAppointmentGroupsIds instanceof Array)) {
                return true;
            }

            // if group is no assigned to the appointment, it is considered to be in the default.
            const groupId = appointmentItem?.group?.id || DEFAULT_APPOINTMENT_GROUP_ID;

            return excludeAppointmentGroupsIds?.includes(groupId) !== true;
        })
        .filter((appointmentItem) => {
            // Filter by staff Id
            const { staffIds } = filters;

            if (ignoreStaffFiler || !staffIds || staffIds.length === 0) {
                // no filter applied
                return true;
            }

            const id = appointmentItem?.staff?.id || UNASSIGNED_APPOINTMENT_STAFF_ID;
            if (!id) {
                // staff is not assigned to this appointment item
                return false;
            }

            const res = staffIds?.find((val) => {
                return val === id;
            });

            return !!res;
        })
        .filter((appointmentItem) => {
            // Filter by status
            const { includeStatuses } = filters;

            if (!includeStatuses || includeStatuses.length === 0) {
                // no filter applied
                return true;
            }

            const status = appointmentItem?.status || NodeType.AppointmentStatusEnum.NotStarted;
            if (!status) {
                // staff is not assigned to this appointment item
                return false;
            }

            const res = includeStatuses?.find((val) => {
                return val === status;
            });
            return !!res;
        })
        .reverse();
};
