import * as Yup from 'yup';
import * as React from 'react';
import { FormikDefaultForm, FormikDefaultFormProps, FormikTextareaField } from '@poolware/components';
import { JobNote } from './NotesFeedNewItem';

const validationSchema = Yup.object({
    note: Yup.string()
        .max(2048)
        .required('Must not be empty'),
});

export const NotesForm: React.FC<FormikDefaultFormProps & { placeholder?: string }> = ({
    onSubmit,
    placeholder,
    ...props
}) => {
    const handleOnSubmit = async (value: Omit<JobNote, 'id'>, action) => {
        const { note, ...rest } = value;
        await onSubmit({ note: note?.trim(), ...rest }, action);
    };

    return (
        <FormikDefaultForm
            debug={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
            submitButton={{ content: 'Post' }}
            {...props}
        >
            {({ errors }) => {
                const error = errors?.note;
                return (
                    <>
                        <FormikTextareaField autoFocus name={'note'} rows={8} placeholder={placeholder} />
                        {error}
                    </>
                );
            }}
        </FormikDefaultForm>
    );
};
