import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { ConnectionTableDef, DefaultConnectionTable, Display, SectionHeader } from '@poolware/components';
import { NodeType } from '@poolware/api';

const RenderMoney = (money: number) => {
    return '$' + money.toLocaleString('en', { currency: 'aus', minimumFractionDigits: 2 });
};

const RenderLink = (url: string) => {
    return (
        <a target={'_blank'} href={url} rel="noopener noreferrer">
            <Icon name="external" /> Open in Vend
        </a>
    );
};

const SalesPanel: React.FC<{ sales }> = ({ sales }) => {
    const tableDef: ConnectionTableDef<NodeType.VendSale> = [
        {
            header: 'Date',
            width: '1',
            cell: (r) => {
                return <Display.Date value={r.date} />;
            },
        },
        {
            header: 'Sold by',
            cell: (r) => {
                if (r.vendUser) {
                    return (
                        <a href={r.vendUser.url} target="_blank" rel="noopener noreferrer">
                            {r.vendUser.displayName}
                        </a>
                    );
                }
                return 'N/A';
            },
            width: 3,
        },
        {
            header: 'Notes',
            width: 5,
            cell: (r) => <Display.Text value={r.note} limit={100} />,
        },
        { header: 'Status', cell: (r) => r.status },
        { header: 'Total', cell: (r) => RenderMoney(r.totalPriceInclTax) },
        { header: 'Link', cell: (r) => RenderLink(r.historyUrl), width: 3 },
    ];

    return (
        <>
            <SectionHeader size={'small'} content={`Vend Sales`} />
            <DefaultConnectionTable
                tableProps={{ color: 'purple' }}
                tableCellProps={{ verticalAlign: 'top' }}
                tableDef={tableDef}
                connectionData={sales}
            />
        </>
    );
};

export default SalesPanel;
