//
// The breadcrumbs are hardcoded for now.
//
// TODO: use router config to generate breadcrumbs
//

export const breadcrumbRoutes = [
    { path: '/customers/:customerId/site/:siteId/edit', breadcrumb: 'Edit Site' },
    { path: '/customers/:customerId/add-site', breadcrumb: 'New Site' },
    { path: '/customers/:customerId/pools/:poolId/add-water-test', breadcrumb: 'New Water Test' },
    { path: '/customers/:customerId/pools/:poolId/tests/:testId/email-logs', breadcrumb: 'Email Logs' },
    { path: '/customers/:customerId/pools/:poolId/tests/:testId/edit', breadcrumb: 'Edit Report' },
    { path: '/customers/:customerId/pools/:poolId/tests/:testId', breadcrumb: 'Water Test' },
    { path: '/customers/:customerId/pools/:poolId/tests', breadcrumb: null },
    { path: '/customers/:customerId/pools/:poolId/equipment/new', breadcrumb: 'Register Equipment' },
    { path: '/customers/:customerId/pools/:poolId/equipment', breadcrumb: 'Pool Equipment' },
    { path: '/customers/:customerId/pools/:poolId/edit', breadcrumb: 'Edit Pool' },
    { path: '/customers/:customerId/pools/:poolId', breadcrumb: 'Pool' },
    { path: '/customers/:customerId/pools', breadcrumb: null },
    { path: '/customers/:customerId', breadcrumb: 'Customer' },
    { path: '/customers', breadcrumb: 'All Customers', exact: true },
];
