import * as React from 'react';
import { PrintPageContainer as PPC } from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';

interface PrintPageContainerProps {
    menuComponent?: any;
}

const PrintPageContainer: React.FC<PrintPageContainerProps> = (props) => {
    const { AppNavigator } = useAppNavigator();
    return <PPC onCancel={() => AppNavigator.replaceToOrigin()} {...props} />;
};

export default PrintPageContainer;
