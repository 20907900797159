import * as React from 'react';
import { ModuleRootSwitch, ModuleRoute, ModuleSwitch } from '@poolware/react-app-navigator';
import AppointmentsList from './AppointmentsList';
import AppointmentDetails from './AppointmentDetails';

export const SchedulerPrintRouter = () => {
    return (
        <ModuleRootSwitch>
            <ModuleRoute path={`/appt/list`} component={AppointmentsList} />
            <ModuleRoute path={`/appt/:appointmentItemId`} component={AppointmentDetails} />
        </ModuleRootSwitch>
    );
};
