import { useState } from 'react';

export const enumToOptions = (stringEnum) => {
    return Object.keys(stringEnum).map((key) => ({ value: stringEnum[key], text: stringEnum[key] }));
};

export function useSelectState<T = string>(enumVal: T, enumObj): [T, any[], any] {
    const [value, setVal] = useState(enumVal);
    const handleSelectChange = (_, { value }) => {
        setVal(value);
    };

    const options = enumToOptions(enumObj);

    return [value, options, handleSelectChange];
}
