import * as React from 'react';

import { Button, Grid } from 'semantic-ui-react';

interface PaginationButtonsProps {
    isLoadingPage?: boolean;
    setPage: (page: number) => void;
    pageCount: number;
    currentPage: number;
}

export default class PaginationButtons extends React.PureComponent<PaginationButtonsProps> {
    render() {
        const { isLoadingPage, currentPage, pageCount, setPage } = this.props;

        if (pageCount < 2) {
            return null;
        }
        const hasNextPage = currentPage + 1 < pageCount;

        return (
            <Grid style={{ padding: 0, margin: '-16px auto' }}>
                <Grid.Row columns={3}>
                    <Grid.Column style={{ padding: 0, margin: 0 }}>
                        <Button
                            basic
                            labelPosition="left"
                            size="tiny"
                            content="Prev."
                            onClick={() => setPage(currentPage - 1)}
                            icon="chevron left"
                            disabled={currentPage === 0 || isLoadingPage}
                            loading={isLoadingPage}
                        />
                    </Grid.Column>
                    <Grid.Column textAlign="center" verticalAlign="middle">
                        Page: {currentPage + 1} / {pageCount}
                    </Grid.Column>
                    <Grid.Column style={{ padding: 0, margin: 0 }}>
                        <Button
                            basic
                            floated={'right'}
                            compact
                            content="Next"
                            labelPosition="right"
                            icon="right chevron"
                            onClick={() => setPage(currentPage + 1)}
                            disabled={!hasNextPage || isLoadingPage}
                            loading={isLoadingPage}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
