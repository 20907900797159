import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { DevOnly, Panel, VStack } from '@poolware/components';
import { WaterTestPanel } from './WaterlinkTestPanel';
import { WaterlinkDevicesTestOptions } from './WaterlinkDevicesTestOptions';
import { IAppNavigatorProps, withAppNavigator } from '@poolware/react-app-navigator';
import { DeviceStatusPanelDev } from './DeviceStatusPanel';
import { WaterTestUnavailablePanel } from './WaterTestUnavailablePanel';
import { useWaterlink } from '../api-react';

const ConnectedPanel: React.FC<{ title: string; reqFn: () => Promise<any> }> = ({ title, reqFn }) => {
    const [res, setRes] = useState(null);

    const fetchData = useCallback(async () => {
        const r = await reqFn();
        setRes(r);
    }, [reqFn, setRes]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Panel>
            <Panel.Header button={{ content: 'Refresh', icon: 'refresh', onClick: () => fetchData() }}>
                {title}
            </Panel.Header>
            <Panel.Body>
                <Panel.Items content={res} />
            </Panel.Body>
        </Panel>
    );
};

const TestRunnerStatus: React.FC = () => {
    const waterlink = useWaterlink();
    const { testRunner } = waterlink;

    return (
        <Panel>
            <Panel.Header>Test runner</Panel.Header>
            <Panel.Body>
                <Panel.Items content={testRunner} />
            </Panel.Body>
        </Panel>
    );
};

const WaterlinkPage: React.FC<IAppNavigatorProps> = ({ AppNavigator }) => {
    const waterlink = useWaterlink();

    const isOnline = waterlink.device.online;

    const navToSettings = () => {
        AppNavigator.navigate('/settings', { relativeToModule: true, setOrigin: true });
    };

    return (
        <VStack>
            {isOnline && (
                <Panel>
                    <Panel.Header icon={'lab'}>Waterlink</Panel.Header>
                    <Panel.Body>
                        <WaterTestPanel />
                    </Panel.Body>
                </Panel>
            )}

            <WaterTestUnavailablePanel />

            <Button icon={'settings'} content={'Settings'} onClick={navToSettings} />

            <Modal centered={false} closeIcon={true} trigger={<Button content={'Device Status'} />} size={'large'}>
                <DeviceStatusPanelDev />
            </Modal>

            <DevOnly>
                <Modal centered={false} closeIcon={true} trigger={<Button content={'Show Options'} />} size={'large'}>
                    <WaterlinkDevicesTestOptions />
                </Modal>
                <Modal centered={false} closeIcon={true} trigger={<Button content={'Show Log'} />} size={'large'}>
                    <ConnectedPanel title={'Log'} reqFn={waterlink.client.getLog} />
                </Modal>
                <Modal centered={false} closeIcon={true} trigger={<Button content={'Test Runner'} />} size={'large'}>
                    <TestRunnerStatus />
                </Modal>
            </DevOnly>
        </VStack>
    );
};

export default withAppNavigator()(WaterlinkPage);
