import * as React from 'react';
import { useMemo } from 'react';
import { ConnectionTableDef, DefaultConnectionTable, Display } from '@poolware/components';
import { patternToString } from '../../../Scheduler';
import { Icon } from 'semantic-ui-react';
import { BlockGroupType } from './types';
import { CSSProperties } from 'styled-components';
import { getColorForPatternBlockId } from './utils';

type ActionFnType = (blockGroup: BlockGroupType) => React.ReactNode;
const useMemoTableDef = (conf: { actions?: ActionFnType }): ConnectionTableDef<BlockGroupType> => {
    return useMemo(() => {
        return [
            {
                header: 'Dates',
                cellProps: (r) => ({
                    style: {
                        paddingLeft: '0px',
                    },
                }),
                cell: (r) => {
                    const colorStyle: CSSProperties = {
                        borderLeft: '5px',
                        borderLeftStyle: 'solid',
                        borderLeftColor: getColorForPatternBlockId(r.patternBlockId) || 'transparent',
                        paddingLeft: '0.5em',
                    };
                    return (
                        <div style={colorStyle}>
                            <Display.Date value={r.start} format={'LL (dddd)'} />{' '}
                            <Icon name={'long arrow alternate right'} />{' '}
                            <Display.Date value={r.end} format={'LL (dddd)'} defaultString={'no end'} />
                        </div>
                    );
                },
            },
            {
                header: 'Recurrence Pattern',
                cell: (r) => {
                    return <div>{patternToString(r.recurrence?.pattern) || '--'}</div>;
                },
            },
            {
                header: 'Appointment Time',
                cell: (r) => {
                    return (
                        <div>
                            <Display.Date value={r.start} format={'LT'} /> ({r.appointmentDuration || '--'} min)
                        </div>
                    );
                },
            },

            conf.actions && {
                header: '',
                cellProps: { textAlign: 'center' },
                width: 1,
                cell: (r) => {
                    return <>{conf.actions(r)}</>;
                },
            },
        ];
    }, [conf]);
};

export const TableServiceSchedules: React.FC<{
    connectionData: BlockGroupType[];
    actions?: ActionFnType;
}> = ({ connectionData, actions }) => {
    const blocksTableDef = useMemoTableDef({ actions });
    return <DefaultConnectionTable tableDef={blocksTableDef} connectionData={connectionData} />;
};
