import * as React from 'react';
import { ActionBarFilterByStaff } from '../../components/ActionBarFilters/ActionBarFilterByStaff';
import { useWorkOrderListActions } from '../../redux';
import { useViewer } from '@poolware/app-shell';

export const FilterByStaff: React.FC = () => {
    const { WorkOrderListAction, WorkOrderListState } = useWorkOrderListActions();
    const { modulesAccess } = useViewer();
    const { staffIds = [] } = WorkOrderListState.filters;

    return (
        <ActionBarFilterByStaff
            forceMeFilter={modulesAccess.FieldServices?.calendarSingleStaffMode}
            staffIds={staffIds}
            setStaffFilter={WorkOrderListAction.setStaffFilter}
            singleSelect={true}
        />
    );
};
