import gql from 'graphql-tag';
import { createUseQueryNodeHook, fromEdges, NodeType } from '@poolware/api';
import { useMemo } from 'react';
import { testReportFragment } from '../graphql-fragments';

const QL = gql`
    query EditWaterTest($id: ID!) {
        node(id: $id) {
            id
            ...TestReportFragment
        }
    }
    ${testReportFragment}
`;

const useQueryTestReport = createUseQueryNodeHook<NodeType.TestReport>(QL);

export const useQueryWaterTestReport = (testId: string) => {
    const res = useQueryTestReport(testId, {
        fetchPolicy: 'cache-and-network',
    });

    return useMemo(() => {
        if (!res.node) {
            return {
                ...res,
                pool: null,
                report: null,
                chemicalTargets: [],
                manualProblems: [],
                measurements: {},
                measurementIds: {},
            };
        }

        let report = res.node;
        let pool = report?.pool;
        let chemicalTargets = fromEdges(pool?.chemicalTargets);
        let manualProblems = fromEdges(report?.manualProblems);
        let samples = fromEdges(report?.sampleSet?.samples);

        let measurements = samples.reduce<{ values; ids }>(
            (returnObj: any, sample: any) => {
                let measurementTypeId = sample?.measurementType?.id;
                if (!measurementTypeId) {
                    console.error('sample does not have measurementType');
                } else {
                    returnObj.values[measurementTypeId] = Number(sample.value);
                    returnObj.ids[measurementTypeId] = sample.id;
                }
                return returnObj;
            },
            { values: {}, ids: {} }
        );

        return {
            ...res,
            report,
            pool,
            chemicalTargets,
            measurements: measurements.values,
            measurementIds: measurements.ids,
            manualProblems: manualProblems,
        };
    }, [res]);
};
