import { call, cancelled, put, race, take } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import * as URLBuilder from '../../routes/url-builder';

import {
    BOOKING_ACTION,
    BookingAction,
    BookingDetails,
    BookingReturnDest,
    CalendarAction,
    CalViewLayoutMode,
} from '@poolware/app-service-jobs';

const sagaName = 'SAGA_BOOK_APPOINTMENT_FROM_CUSTOMER';

function* watchAppointmentBookingFromPool(sagaName, { payload }) {
    const navigate = (url, state: any = {}) => {
        return navigateActions.browserNavigation(url, { ...state, saga: sagaName });
    };

    const { details, returnTo }: { details: BookingDetails; returnTo: any } = payload || {};

    const { customer, pool } = details;

    try {
        // Reset all
        yield put(BookingAction.setSagaMode(true));
        yield put(BookingAction.setSelectingSlotMode(true));
        yield put(CalendarAction.setLayoutMode(CalViewLayoutMode.GRID));
        yield put(CalendarAction.setPreviewAppt(null));
        yield put(BookingAction.setDetails({ customer, pool }));

        yield put(navigate(URLBuilder.Scheduler.home));

        yield take([BOOKING_ACTION.TIME_SLOT_SELECTED]);

        yield put(navigate(URLBuilder.Scheduler.new, { modal: true }));

        yield take([BOOKING_ACTION.BOOKING_CREATED]);

        yield put(navigate(URLBuilder.Scheduler.home));

        // yield call(delay, 100);

        yield put(navigate(URLBuilder.Scheduler.where, { modal: true }));

        const formAction = yield take([BOOKING_ACTION.NAVIGATE_TO_AFTER_CREATION]);

        if (formAction.payload === BookingReturnDest.POOL) {
            yield put(navigate(returnTo));
        }

        if (formAction.payload === BookingReturnDest.CALENDAR) {
            yield put(navigate(URLBuilder.Scheduler.home));
        }
    } catch (e) {
        yield put(BookingAction.abort());
    } finally {
        if (yield cancelled()) {
            yield put(navigate(returnTo));
        }
    }
}

function* watchAppointmentBookingAbort(sagaName) {
    yield race({
        navigationAbort: call(sagaAbortOnNavigation, sagaName),
        actionAbort: take(BOOKING_ACTION.ABORT),
    });
}

function* watchBookAppointment() {
    while (true) {
        yield put(BookingAction.reset());
        const startAction = yield take(BOOKING_ACTION.START_FROM_POOL);
        yield race({
            saga: call(watchAppointmentBookingFromPool, sagaName, startAction),
            abort: call(watchAppointmentBookingAbort, sagaName),
        });
    }
}

export default watchBookAppointment;
