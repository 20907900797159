import * as React from 'react';
import { Optionable, PageLayout, SegmentedSelectButtons, toastError } from '@poolware/components';
import { Icon } from 'semantic-ui-react';
import { mapServiceJobStageTypeToIconProps, ModuleColorNames, ModuleIconNames } from '../../constants';
import { ToolbarContentContainer } from '../../components/page-layout';
import { fromEdges, NodeType } from '@poolware/api';
import { useServiceJobMutators } from '../../queries/mutators-service-job';
import { ButtonsChangeJobStage } from '../../components/ButtonsChangeJobStage';
import * as _ from 'lodash';

export const stageToOptionable = (stage: NodeType.ServiceJobStage): Optionable<NodeType.ServiceJobStage> => {
    return {
        value: stage,
        icon: mapServiceJobStageTypeToIconProps(stage.type),
        text: stage.title,
    };
};

export const StageButtons: React.FC<{
    onChangeStage: (opt: Optionable<NodeType.ServiceJobStage>) => Promise<any>;
    stages: Optionable<NodeType.ServiceJobStage>[];
    value: NodeType.ServiceJobStage;
}> = ({ onChangeStage, stages, value }) => {
    return (
        <SegmentedSelectButtons
            color={ModuleColorNames.StatusChangeButton}
            options={stages}
            value={value}
            onChange={onChangeStage}
            valueComparator={(a, b) => a?.id === b?.id}
        />
    );
};

export interface ServiceCasePageHeaderToolbarProps {
    serviceJob: NodeType.ServiceJob;
    serviceJobMutators: ReturnType<typeof useServiceJobMutators>;
}
export const PageHeaderToolbarServiceCase: React.FC<ServiceCasePageHeaderToolbarProps> = ({
    serviceJob,
    serviceJobMutators,
}) => {
    const onChangeStage = async (value: NodeType.ServiceJobStage) => {
        if (value?.id === serviceJob.stage?.id) {
            return;
        }
        try {
            await serviceJobMutators.ServiceJobMutator.changeStage(serviceJob, value);
        } catch (e) {
            console.error(e);
            toastError({ title: 'Failed to change status', description: e.message });
        }
    };
    const stageHistory = fromEdges(serviceJob.stageHistory);

    return (
        <ToolbarContentContainer
            main={
                <PageLayout.PageHeader>
                    <Icon name={ModuleIconNames.ServiceCall} style={{ marginRight: '0.25rem' }} />
                    {serviceJob.serviceJobNumber} - {_.truncate(serviceJob.title, { length: 160 })}
                </PageLayout.PageHeader>
            }
            actions={
                <ButtonsChangeJobStage
                    stage={serviceJob.stage}
                    stages={serviceJob.stageCandidates}
                    onChangeStage={onChangeStage}
                    stageHistory={stageHistory}
                />
            }
        />
    );
};
