import * as React from 'react';
import { Panel, PanelHeaderProps } from '@poolware/components';
import { Grid } from 'semantic-ui-react';
import { useWaterlink } from '../api-react';

const PanelHeaderStatus: React.FC<{ isOnline: boolean } & PanelHeaderProps> = ({ isOnline, ...props }) => {
    const iconName = isOnline ? 'checkmark' : 'cancel';
    const color = isOnline ? 'green' : 'orange';
    return <Panel.Header icon={iconName} color={color} {...props} />;
};

const DeviceStatusPanelComp: React.FC<{ keyMapper? }> = ({ keyMapper }) => {
    const waterlink = useWaterlink();

    const isServiceEnabled = waterlink.serviceEnabled;
    const isServerOnline = waterlink.server.online;
    const isDeviceOnline = waterlink.device.online;

    const headerTitle = isServiceEnabled ? 'Waterlink' : 'Waterlink (Service disabled)';
    return (
        <Panel>
            <Panel.Header content={headerTitle} />
            <Panel.Body disabled={!isServiceEnabled}>
                <Grid stackable columns={2} doubling>
                    <Grid.Column width={8}>
                        <Panel>
                            <PanelHeaderStatus isOnline={isDeviceOnline}>Device Status</PanelHeaderStatus>
                            <Panel.Body>
                                <Panel.Item label="Online" content={waterlink.device.online ? 'online' : 'offline'} />
                                <Panel.Items content={waterlink.device.rawStatus} keyMapper={keyMapper} />
                            </Panel.Body>
                        </Panel>
                    </Grid.Column>

                    <Grid.Column width={8}>
                        <Panel>
                            <PanelHeaderStatus isOnline={isServerOnline}>Server Status</PanelHeaderStatus>
                            <Panel.Body>
                                <Panel.Items content={waterlink.server} keyMapper={keyMapper} />
                            </Panel.Body>
                        </Panel>
                    </Grid.Column>
                </Grid>
            </Panel.Body>
        </Panel>
    );
};

export const DeviceStatusPanelDev = DeviceStatusPanelComp;

const KeyToLabelMapper = {
    StatusString: 'Status',
    CanDoWatertest: 'Can do water test',
    SerialNum: 'Serial Number',
    CanDoUpdate: 'Update Required',
    CurrentFirmware: 'Current Meter Version', // Backup in case Waterlink renames the API.
    CurentFirmware: 'Current Meter Version',
    AvailableFirmware: 'Available Version',
    serverBaseUrl: 'Service URL',
    port: 'Port',
    host: 'Hostname',
    online: 'Online',
    APIVersion: 'API Version',
    isSupportedAPIVersion: 'Is Supported Version?',
};

export const DeviceStatusPanel = () => <DeviceStatusPanelComp keyMapper={KeyToLabelMapper} />;
