import { bindActionCreators, combineReducers } from 'redux';
import invariant from 'invariant';
import { batch, connect, RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { NodeType } from '@poolware/api';
import { CalResourceMode, CalViewLayoutMode, CalViewMode } from '../Scheduler/types';
import {
    ActionsUnion,
    createAction as _createAction,
    createAutoBoundReducerActions,
    createReducer,
    isScreenWider,
} from '@ez/tools';
import { DateRange } from '../constants';
import { computeDateRange, RECUR_FILTER, sanitizeViewMode } from './helpers';

/// State

const SCOPE = '@scheduler';
const createAction = (type, payload?) => _createAction(type, payload, SCOPE);

export type CalendarZoomFactor = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export type CalendarStateType = {
    displayingDateRange: DateRange;
    activeDate: Date;
    previewApptId: string;
    resourceMode: CalResourceMode;
    viewMode: CalViewMode;
    layoutMode: CalViewLayoutMode;
    zoomFactor: CalendarZoomFactor;
    // autoHideEmptyResources: boolean;
    filters: {
        franchise: { id?: string; name?: string };
        teamId: string;
        staffIds: string[];
        excludeAppointmentGroupsIds: string[];
        includeStatuses: string[];
        hasActiveFilters: boolean;
        autoHideEmptyResources?: boolean;
        showWithAddressOnly?: boolean;
        serviceGroupId: string;
        serviceJobTemplateId: string;
        isRecurring: RECUR_FILTER;
        streets?: string[];
        hoverStreets?: string[];
        areas?: string[];
        hoverAreas?: string[];
    };
    showRightSidebar: boolean;
    showLeftSidebar: boolean;
    highlightedAppointment: NodeType.AppointmentItem;
};

const initialActiveDate = new Date();
const initialViewMode = CalViewMode.WEEK;
const initialLayoutMode = CalViewLayoutMode.GRID;

const isWidescreen = isScreenWider(768);
const isXL = isScreenWider(1400);

const initialState: CalendarStateType = {
    activeDate: initialActiveDate,
    previewApptId: null,
    viewMode: initialViewMode,
    layoutMode: initialLayoutMode,
    resourceMode: CalResourceMode.COMBINED,
    zoomFactor: 3,
    filters: {
        franchise: {},
        teamId: null,
        staffIds: [],
        excludeAppointmentGroupsIds: [],
        includeStatuses: [],
        hasActiveFilters: false,
        streets: [],
        areas: [],
        hoverStreets: [],
        hoverAreas: [],
        autoHideEmptyResources: false,
        showWithAddressOnly: false,
        serviceGroupId: null,
        serviceJobTemplateId: null,
        isRecurring: RECUR_FILTER.ALL,
    },
    displayingDateRange: computeDateRange(initialActiveDate, initialViewMode),
    showLeftSidebar: isWidescreen, // Left sidebar is active by default on wide screens
    showRightSidebar: isXL, // Right sidebar is active by default on wide screens
    highlightedAppointment: null,
};

export const schedulerInitialState = initialState;

// Actions and action creators

export enum CALENDAR_ACTION {
    ACTIVE_DATE = '@scheduler/ACTIVE_DATE',
    PREVIEW_APPT = '@scheduler/PREVIEW_APPT',
    DATE_RANGE = '@scheduler/DISPLAYING_DATE_RANGE',
    VIEW_MODE = '@scheduler/VIEW_MODE',
    ZOOM_FACTOR = '@scheduler/ZOOM_FACTOR',
    LAYOUT_MODE = '@scheduler/LAYOUT_MODE',
    AUTO_HIDE_EMPTY_RESOURCES = '@scheduler/AUTO_HIDE_EMPTY_RESOURCES',
    RESOURCE_MODE = '@scheduler/RESOURCE_MODE',
    STAFF_FILTER = '@scheduler/VIEW_STAFF_FILTER',
    TEAM_FILTER = '@scheduler/TEAM_FILTER',
    RECUR_FILTER = '@scheduler/RECUR_FILTER',
    STREET_FILTER = '@scheduler/STREET_FILTER',
    APPOINTMENT_STATUS_FILTER = '@scheduler/APPOINTMENT_STATUS_FILTER',
    APPOINTMENT_GROUP_FILTER = '@scheduler/VIEW_APPOINTMENT_GROUP_FILTER',
    CALENDAR_LEFT_SIDEBAR_STATE = '@scheduler/LEFT_SIDEBAR_STATE',
    CALENDAR_RIGHT_SIDEBAR_STATE = '@scheduler/RIGHT_SIDEBAR_STATE',
    SHOW_APPOINTMENT_IN_CALENDAR = '@scheduler/SHOW_APPOINTMENT_IN_CALENDAR',
    HIGHLIGHT_APPOINTMENT_IN_CALENDAR = '@scheduler/HIGHLIGHT_APPOINTMENT_IN_CALENDAR',
    CLEAR_ALL_FILTERS = '@scheduler/CLEAR_ALL_FILTERS',
}

export const setSidebarState = (isActive: boolean) =>
    createAction(CALENDAR_ACTION.CALENDAR_LEFT_SIDEBAR_STATE, {
        isActive: isActive,
    });

export const setRightSidebarState = (isActive: boolean) =>
    createAction(CALENDAR_ACTION.CALENDAR_RIGHT_SIDEBAR_STATE, { isActive });

export const setResourceMode = (resourceMode: CalResourceMode) =>
    createAction(CALENDAR_ACTION.RESOURCE_MODE, {
        resourceMode: resourceMode,
    });

export const setAutoHideEmptyResources = (autoHideEmptyResources: boolean) =>
    createAction(CALENDAR_ACTION.AUTO_HIDE_EMPTY_RESOURCES, autoHideEmptyResources);

export const setStaffFilter = ({ includeIds }) =>
    createAction(CALENDAR_ACTION.STAFF_FILTER, {
        includeIds: includeIds,
    });

export const setTeamFilter = (teamId) =>
    createAction(CALENDAR_ACTION.TEAM_FILTER, {
        teamId: teamId,
    });

export const setStreetFilter = (opt: {
    streets?: string[];
    areas?: string[];
    hoverStreets?: string[];
    hoverAreas?: string[];
    showWithAddressOnly?: boolean;
}) => createAction(CALENDAR_ACTION.STREET_FILTER, opt);

// const autoHideEmptyResources = createReducer(
//     initialState.autoHideEmptyResources,
//     (state, action: CalendarAction): CalendarStateType['autoHideEmptyResources'] => {
//         if (action.type === CALENDAR_ACTION.AUTO_HIDE_EMPTY_RESOURCES) {
//             return action.payload.autoHideEmptyResources;
//         }
//     },
//     SCOPE
// );

export const setRecurFilter = (filter: RECUR_FILTER) => createAction(CALENDAR_ACTION.RECUR_FILTER, filter);

export const setAppointmentStatusFilter = ({ includeStatuses }) =>
    createAction(CALENDAR_ACTION.APPOINTMENT_STATUS_FILTER, {
        includeStatuses: includeStatuses,
    });

export const setAppointmentGroupFilter = ({ excludeIds }) =>
    createAction(CALENDAR_ACTION.APPOINTMENT_GROUP_FILTER, {
        excludeIds: excludeIds,
    });

const setViewMode = (viewMode: CalViewMode) => createAction(CALENDAR_ACTION.VIEW_MODE, { viewMode: viewMode });
const setLayoutMode = (mode: CalViewLayoutMode) => createAction(CALENDAR_ACTION.LAYOUT_MODE, { layoutMode: mode });
const setDateRange = (dateRange: DateRange) => createAction(CALENDAR_ACTION.DATE_RANGE, { dateRange: dateRange });
const setActiveDate = (activeDate: Date) => createAction(CALENDAR_ACTION.ACTIVE_DATE, { activeDate: activeDate });
const setPreviewAppt = (apptId: string) => createAction(CALENDAR_ACTION.PREVIEW_APPT, apptId);
const setZoomFactor = (zoomFactor: CalendarZoomFactor) => createAction(CALENDAR_ACTION.ZOOM_FACTOR, zoomFactor);

export const showAppointmentInCalendar = (item: NodeType.AppointmentItem, viewMode?: CalViewMode) =>
    createAction(CALENDAR_ACTION.SHOW_APPOINTMENT_IN_CALENDAR, { item, viewMode });

export const highlightAppointmentInCalendar = (item: NodeType.AppointmentItem) =>
    createAction(CALENDAR_ACTION.HIGHLIGHT_APPOINTMENT_IN_CALENDAR, { item });

export const clearAllFilters = () => createAction(CALENDAR_ACTION.CLEAR_ALL_FILTERS);

interface SetCalendarInputType {
    activeDate?: Date;
    viewMode?: CalViewMode;
    layoutMode?: CalViewLayoutMode;
}

export const CalendarAction = {
    setSidebarState,
    setRightSidebarState,
    setResourceMode,
    setTeamFilter,
    setStaffFilter,
    setStreetFilter,
    setAppointmentGroupFilter,
    setAppointmentStatusFilter,
    setViewMode,
    setZoomFactor,
    setLayoutMode,
    setDateRange,
    setPreviewAppt,
    setActiveDate,
    showAppointmentInCalendar,
    highlightAppointmentInCalendar,
    setAutoHideEmptyResources,
    setRecurFilter,
    clearAllFilters,
};

export type CalendarAction = ActionsUnion<typeof CalendarAction>;

export const setCalendar = (input: SetCalendarInputType) => async (dispatch, getState) => {
    return Promise.resolve().then(() => {
        // Use async promise here. It avoids immediate state change and delays UI rendering.
        // This reduces lags on UI, when calendar buttons are clicked.

        const { activeDate, viewMode } = input;

        invariant(activeDate || viewMode, `Expected either 'activeDate' or 'viewMode' to be provided`);

        const { viewMode: currentViewMode, activeDate: currentActiveDate } = getState().scheduler;

        const newViewMode = sanitizeViewMode(viewMode || currentViewMode, initialState.viewMode);
        const newActiveDate = activeDate || currentActiveDate;
        const newDateRange = computeDateRange(newActiveDate, newViewMode);
        const newLayoutMode = input.layoutMode;

        batch(() => {
            dispatch(CalendarAction.setActiveDate(newActiveDate));
            dispatch(CalendarAction.setDateRange(newDateRange));
            dispatch(CalendarAction.setViewMode(newViewMode));
            newLayoutMode && dispatch(CalendarAction.setLayoutMode(newLayoutMode));
            dispatch(CalendarAction.setStreetFilter({ streets: [], hoverStreets: [] }));
        });
    });
};

/// Reducers

const activeDate = createReducer(
    initialState.activeDate,
    (state, action: CalendarAction): CalendarStateType['activeDate'] => {
        if (action.type === CALENDAR_ACTION.ACTIVE_DATE) {
            return action.payload.activeDate;
        }
    },
    SCOPE
);

const previewApptId = createReducer(
    initialState.previewApptId,
    (state, action: CalendarAction): CalendarStateType['previewApptId'] => {
        if (action.type === CALENDAR_ACTION.PREVIEW_APPT) {
            return action.payload;
        }
    },
    SCOPE
);

const displayingDateRange = createReducer(
    initialState.displayingDateRange,
    (state, action: CalendarAction): CalendarStateType['displayingDateRange'] => {
        if (action.type === CALENDAR_ACTION.DATE_RANGE) {
            return action.payload.dateRange;
        }
    },
    SCOPE
);

const viewMode = createReducer(
    initialState.viewMode,
    (newState, action: CalendarAction): CalendarStateType['viewMode'] => {
        if (action.type === CALENDAR_ACTION.VIEW_MODE) {
            return action.payload.viewMode;
        }
    },
    SCOPE
);

const zoomFactor = createReducer(
    initialState.zoomFactor,
    (newState, action: CalendarAction): CalendarStateType['zoomFactor'] => {
        if (action.type === CALENDAR_ACTION.ZOOM_FACTOR) {
            return action.payload;
        }
    },
    SCOPE
);

const layoutMode = createReducer(
    initialState.layoutMode,
    (state, action: CalendarAction): CalendarStateType['layoutMode'] => {
        if (action.type === CALENDAR_ACTION.LAYOUT_MODE) {
            return action.payload.layoutMode;
        }
    },
    SCOPE
);

const resourceMode = createReducer(
    initialState.resourceMode,
    (state, action: CalendarAction): CalendarStateType['resourceMode'] => {
        if (action.type === CALENDAR_ACTION.RESOURCE_MODE) {
            return action.payload.resourceMode;
        }
    },
    SCOPE
);

const showRightSidebar = createReducer(
    initialState.showRightSidebar,
    (state, action: CalendarAction): CalendarStateType['showRightSidebar'] => {
        if (action.type === CALENDAR_ACTION.CALENDAR_RIGHT_SIDEBAR_STATE) {
            return action.payload.isActive;
        }
    },
    SCOPE
);

const showLeftSidebar = createReducer(
    initialState.showLeftSidebar,
    (state, action: CalendarAction): CalendarStateType['showLeftSidebar'] => {
        if (action.type === CALENDAR_ACTION.CALENDAR_LEFT_SIDEBAR_STATE) {
            return action.payload.isActive;
        }
    },
    SCOPE
);

const highlightedAppointment = createReducer(
    initialState.highlightedAppointment,
    (state, action: CalendarAction): CalendarStateType['highlightedAppointment'] => {
        if (action.type === CALENDAR_ACTION.HIGHLIGHT_APPOINTMENT_IN_CALENDAR) {
            return action.payload.item;
        }
    },
    SCOPE
);

export const hasActiveFilters = (state: CalendarStateType['filters']) => {
    return (
        state.isRecurring !== RECUR_FILTER.ALL ||
        state.staffIds.length > 0 ||
        state.excludeAppointmentGroupsIds.length > 0 ||
        state.includeStatuses.length > 0 ||
        !!state.showWithAddressOnly ||
        !!state.teamId ||
        !!state.serviceJobTemplateId ||
        !!state.serviceGroupId
    );
};

const FiltersReducerActions = createAutoBoundReducerActions(initialState.filters, SCOPE, {
    setFranchise: { field: 'franchise' },
    setServiceJobGroup: { field: 'serviceGroupId' },
    setServiceJobTemplate: { field: 'serviceJobTemplateId' },
});

const filters = createReducer(
    initialState.filters, //
    (state, action: CalendarAction): CalendarStateType['filters'] => {
        const reducer = FiltersReducerActions.reducers[action.type];
        if (reducer) {
            reducer(state, action.payload);
        } else {
            switch (action.type) {
                case CALENDAR_ACTION.CLEAR_ALL_FILTERS:
                    {
                        state.teamId = null;
                        state.staffIds = [];
                        state.excludeAppointmentGroupsIds = [];
                        state.includeStatuses = [];
                        state.serviceGroupId = null;
                        state.serviceJobTemplateId = null;
                        state.showWithAddressOnly = false;
                        // state.autoHideEmptyResources = false;
                    }
                    break;
                case CALENDAR_ACTION.CALENDAR_RIGHT_SIDEBAR_STATE:
                    {
                        if (!action.payload.isActive) {
                            state.streets = [];
                            state.hoverStreets = [];
                            state.areas = [];
                            state.hoverAreas = [];
                            state.showWithAddressOnly = false;
                        }
                    }
                    break;
                case CALENDAR_ACTION.RECUR_FILTER:
                    state.isRecurring = action.payload;
                    break;
                case CALENDAR_ACTION.TEAM_FILTER:
                    state.teamId = action.payload.teamId || null;
                    break;
                case CALENDAR_ACTION.STAFF_FILTER:
                    state.staffIds = action.payload.includeIds || [];
                    break;
                case CALENDAR_ACTION.AUTO_HIDE_EMPTY_RESOURCES:
                    state.autoHideEmptyResources = action.payload;
                case CALENDAR_ACTION.STREET_FILTER:
                    {
                        if (action.payload.streets) {
                            state.streets = action.payload.streets || [];
                        }
                        if (action.payload.areas) {
                            state.areas = action.payload.areas || [];
                        }
                        if (action.payload.hoverStreets) {
                            state.hoverStreets = action.payload.hoverStreets || [];
                        }
                        if (action.payload.hoverAreas) {
                            state.hoverAreas = action.payload.hoverAreas || [];
                        }
                        if (action.payload.showWithAddressOnly !== undefined) {
                            state.showWithAddressOnly = !!action.payload.showWithAddressOnly;
                        }
                    }
                    break;
                case CALENDAR_ACTION.APPOINTMENT_GROUP_FILTER:
                    state.excludeAppointmentGroupsIds = action.payload.excludeIds || [];
                    break;
                case CALENDAR_ACTION.APPOINTMENT_STATUS_FILTER: {
                    state.includeStatuses = action.payload.includeStatuses || [];
                    break;
                }
            }
        }
        state.hasActiveFilters = hasActiveFilters(state);
        return state;
    },
    SCOPE
);

export const reducerCalendarState = combineReducers({
    displayingDateRange,
    activeDate,
    previewApptId,
    resourceMode,
    zoomFactor,
    viewMode,
    layoutMode,
    filters,
    showLeftSidebar,
    showRightSidebar,
    highlightedAppointment,
});

// Memoization

const memoizedSelector = createStructuredSelector<CalendarStateType, CalendarStateType>({
    zoomFactor: (state) => state.zoomFactor,
    displayingDateRange: (state) => state.displayingDateRange,
    activeDate: (state) => state.activeDate,
    previewApptId: (state) => state.previewApptId,
    resourceMode: (state) => state.resourceMode,
    viewMode: (state) => state.viewMode,
    layoutMode: (state) => state.layoutMode,
    filters: (state) => state.filters,
    showLeftSidebar: (state) => state.showLeftSidebar,
    showRightSidebar: (state) => state.showRightSidebar,
    highlightedAppointment: (state) => state.highlightedAppointment,
});

const CalendarActionBind = {
    ...CalendarAction,
    setCalendar,
    ...FiltersReducerActions.actions,
};

export const withCalendarActions = () =>
    connect(
        (state: RootStateOrAny) => ({
            CalendarState: memoizedSelector(state.scheduler),
        }),
        (dispatch) => ({
            CalendarAction: bindActionCreators(CalendarActionBind, dispatch),
        })
    );

export interface CalendarActionProps {
    CalendarAction: typeof CalendarActionBind;
    CalendarState: CalendarStateType;
}

export const useCalendarActions = (): CalendarActionProps => {
    const dispatch = useDispatch();
    const CalendarState = useSelector((state: RootStateOrAny) => {
        invariant(
            state.scheduler,
            'Could not find `state.scheduler`. ' +
                "Make sure that the product catalog's reducer is connected to the main Redux store of you application. " +
                "Redux field must equal 'scheduler' "
        );
        return memoizedSelector(state.scheduler);
    });

    const CalendarAction = bindActionCreators(CalendarActionBind, dispatch);
    return {
        CalendarState,
        CalendarAction,
    };
};
