import { call, cancelled, put, race, take } from 'redux-saga/effects';
import { navigateActions, sagaAbortOnNavigation } from '@poolware/app-shell';
import { BOOKING_ACTION, BookingAction } from './reducer-appointment-booking';

export const createWatchAppointmentBookingFromCalendar = (store, URLBuilder) => {
    const sagaName = 'SAGA_BOOK_APPOINTMENT_FROM_CALENDAR';

    function* watchAppointmentBookingFromCalendar(sagaName) {
        const navigate = (url, state: any = {}) => {
            return navigateActions.browserNavigation(url, { ...state, saga: sagaName });
        };

        try {
            // Reset all
            yield put(BookingAction.setSagaMode(true));

            yield put(navigate(URLBuilder.Scheduler.new, { modal: true }));

            yield take([BOOKING_ACTION.BOOKING_CREATED]);

            yield put(navigate(URLBuilder.Scheduler.home));
        } catch (e) {
            yield put(BookingAction.abort());
        } finally {
            if (yield cancelled()) {
                yield put(navigate(URLBuilder.Scheduler.home));
            }
        }
    }

    function* watchAppointmentBookingAbort(sagaName) {
        yield race({
            navigationAbort: call(sagaAbortOnNavigation, sagaName),
            actionAbort: take(BOOKING_ACTION.ABORT),
        });
    }

    function* watch() {
        while (true) {
            yield put(BookingAction.reset());
            yield take(BOOKING_ACTION.START_FROM_CALENDAR);
            yield race({
                saga: call(watchAppointmentBookingFromCalendar, sagaName),
                abort: call(watchAppointmentBookingAbort, sagaName),
            });
        }
    }

    return watch;
};
