import * as React from 'react';
import gql from 'graphql-tag';
import {
    fragmentPoolOwnershipHistory,
    NodeType,
    poolFragment,
    QueryConnection,
    QueryConnectionExternalProps,
    useDefaultConnectionState,
} from '@poolware/api';

const connectionPath = 'viewer.poolTransfers';
const QL = gql`
    query PoolTransfersConnection($first: Int, $page: Int, $sort: PoolOwnershipHistoryViewerPoolTransfersSort) {
        viewer {
            poolTransfers(first: $first, page: $page, sort: $sort) {
                edges {
                    node {
                        id
                        ...PoolOwnershipHistoryFragment
                        pool {
                            id
                            entity {
                                id
                                user {
                                    id
                                    firstName
                                    lastName
                                    customer {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
                pageMeta {
                    pageCount
                    totalCount
                }
            }
        }
    }
    ${poolFragment}
    ${fragmentPoolOwnershipHistory}
`;

export interface QueryPoolTransfersConnectionExternalProps
    extends QueryConnectionExternalProps<NodeType.PoolOwnershipHistory> {}

export const QueryPoolTransfersConnection: React.FC<QueryPoolTransfersConnectionExternalProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.sort = { createdAt: false };

    return (
        <QueryConnection
            query={props.query || QL}
            variables={variables}
            connectionPath={connectionPath}
            connectionConfig={connectionState}
            children={props.children}
        />
    );
};
