import gql from 'graphql-tag';
import { createUseQueryNodeHook, NodeType } from '@poolware/api';
import { warrantyAuxDataFragment } from './use-query-form-aux-data';
import { warrantyClaimFragment } from '../fragments';

const QL = gql`
    query QueryWarrantyClaim($id: ID!) {
        node(id: $id) {
            id
            ... on WarrantyClaim {
                ...WarrantyClaimFragment
            }
        }
        viewer {
            ...WarrantyAuxDataFragment
        }
    }
    ${warrantyClaimFragment}
    ${warrantyAuxDataFragment}
`;

export const useQueryWarrantyClaim = createUseQueryNodeHook<NodeType.WarrantyClaim>(QL);
