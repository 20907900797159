import * as React from 'react';
import { LinkButton, SidebarSecondary } from '@poolware/components';
import { useAppUpdater } from '../AppUpdater';
import { NavLink } from 'react-router-dom';

const ExternalLinkComp: React.FC<{ to; className }> = ({ to, children, className }) => (
    <a href={to} className={className} children={children} />
);

const ReloadableLinkComp = React.forwardRef<any, any>(({ to, router, onClick, exact = false, ...rest }, ref) => {
    const appUpdater = useAppUpdater();
    return (
        <div ref={ref}>
            <NavLink
                to={to}
                exact={exact}
                {...rest}
                onClick={(e) => {
                    try {
                        if (appUpdater.pendingReload) {
                            e.preventDefault();
                            return appUpdater.reloadPage(to);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    onClick && onClick();
                }}
            />
        </div>
    );
});

export const SidebarSecondaryMenuItemExternalLink = SidebarSecondary.createMenuItem(ExternalLinkComp);
export const SidebarSecondaryMenuItemNavLink = SidebarSecondary.createMenuItem(ReloadableLinkComp);
export const SidebarSecondaryMenuItemButton = SidebarSecondary.createMenuItem(LinkButton);
