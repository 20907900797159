import gql from 'graphql-tag';
import _set from 'lodash/set';
import { NodeType, useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const QL = gql`
    query QueryWarrantyClaimsConnection(
        $first: Int
        $page: Int
        $sort: WarrantyClaimViewerWarrantyClaimsSort
        $search: WarrantyClaimViewerWarrantyClaimsSearch
    ) {
        viewer {
            warrantyClaims(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        confirmedAt
                        customerCompanyName
                        customerFirstName
                        customerLastName
                        customerEmail
                        customerPhone
                        warrantyClaimNumber
                        assetName
                        assetSerial
                        assetInstallDate
                        claimedAt
                        submittedAt
                        claimedAt
                        createdAt
                        updatedAt
                        addedBy {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        refCustomer {
                            id
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export interface UseQueryWarrantyClaimsConnectionProps {
    // searchClaimId?: string;
    customerId?: string;
}

function mapPropsToSearchQuery(
    props?: UseQueryWarrantyClaimsConnectionProps
): NodeType.WarrantyClaimViewerWarrantyClaimsSearch {
    if (!props) {
        return undefined;
    }

    const { customerId } = props;
    const search = {};
    if (customerId) {
        _set(search, 'refCustomer.id', customerId);
    }
    return search;
}

export function useQueryWarrantyClaimsConnection(props?: UseQueryWarrantyClaimsConnectionProps) {
    const connectionPath = 'viewer.warrantyClaims';
    const { variables, connectionState } = useDefaultConnectionState({ defaultPageSize: 10 });
    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
