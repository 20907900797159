import moment from 'moment';
import * as React from 'react';
import { NodeType } from '@poolware/api';

export interface DataPoint {
    time: number;
    value: number;
}

export const dayTickFormatter = (dateFormatter) => (time) => {
    return moment(time).format(dateFormatter || 'DD-MMM-YYYY');
};

export const monthsTickFormatter = (time) => {
    return moment(time).format('MM/YY');
};

export const renderValueLabel = (props) => {
    const { x, y, width, value } = props;
    if (value === 0) {
        return '';
    }
    let valueString = value;
    if (value >= 1000) {
        const kV = (props.value / 1000).toFixed(1);
        valueString = `${kV}k`;
    } else {
        valueString = props.value;
    }
    return (
        <g>
            <text
                x={x + width / 2}
                y={y - 5}
                fill="#444444"
                textAnchor="middle"
                fontSize="10"
                dominantBaseline="middle"
            >
                {valueString}
            </text>
        </g>
    );
};

export const samplesToDataPoints = (activitySamples: NodeType.ActivitySample[]) =>
    activitySamples.map((i) => ({
        time: moment(i.date).valueOf(),
        value: i.count,
    }));
