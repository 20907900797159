import * as React from 'react';
import { useMemo } from 'react';
import { NodeType } from '@poolware/api';
import {
    ConnectionTableDef,
    DateRange,
    DefaultConnectionTable,
    Display,
    IconButton,
    SectionHeader,
} from '@poolware/components';
import { useQueryRelatedAppointmentItems } from './query-appointment-items-upcoming';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import { extractRecurrenceData, IconAppointmentStatus, recurrenceToString } from '../../utils';

export enum RelatedAppointmentsMode {
    Future = 'Future',
    Past = 'Past',
    FuturePast = 'FuturePast',
}

export interface RelatedAppointmentsProps {
    appointmentItem: NodeType.AppointmentItem;
    limit?: number;
    mode: RelatedAppointmentsMode;
    onAppointmentSelect?: (item: NodeType.AppointmentItem) => void;
}

export const RelatedAppointmentsTable: React.FC<RelatedAppointmentsProps> = (props) => {
    const { appointmentItem, limit = 30, mode, onAppointmentSelect } = props;
    const { recurrence } = extractRecurrenceData(appointmentItem);
    const recurrenceString = recurrenceToString(recurrence);
    const vars = useMemo(() => {
        const { patternBlockId } = extractRecurrenceData(appointmentItem);
        let _dateRange: DateRange;
        let _limit = limit;
        switch (mode) {
            case RelatedAppointmentsMode.Future:
                _dateRange = {
                    startDate: moment(appointmentItem.startDate).subtract(1, 'm').toDate(),
                    endDate: moment(appointmentItem.startDate).add(6, 'month').toDate(),
                };
                break;
            case RelatedAppointmentsMode.Past:
                _dateRange = {
                    startDate: moment(appointmentItem.startDate).subtract(6, 'month').toDate(),
                    endDate: moment(appointmentItem.startDate).add(1, 'm').toDate(),
                };
                break;
            case RelatedAppointmentsMode.FuturePast:
                _dateRange = {
                    startDate: moment(appointmentItem.startDate).subtract(6, 'month').toDate(),
                    endDate: moment(appointmentItem.startDate).add(6, 'month').toDate(),
                };
                break;
        }
        return {
            patternBlockId: patternBlockId,
            startDate: _dateRange.startDate,
            pivotDate: appointmentItem?.startDate,
            endDate: _dateRange.endDate,
            first: _limit,
        };
    }, [appointmentItem.id, mode, limit]);

    const { connectionData, connectionState } = useQueryRelatedAppointmentItems(vars);

    const tableDef: ConnectionTableDef<NodeType.AppointmentItem> = useMemo(
        () => [
            {
                header: '',
                width: 1,
                cellProps: (item) => ({
                    style: {
                        textAlign: 'center',
                        borderLeft: '4px solid',
                        borderLeftColor: item?.id === appointmentItem.id ? 'orange' : 'white',
                    },
                }),
                cell: (item) => {
                    const isCurrent = item?.id === appointmentItem.id;
                    if (isCurrent) {
                        return <Icon name={'star'} color={'yellow'} />;
                    } else if (onAppointmentSelect) {
                        return <IconButton name={'eye'} onClick={() => onAppointmentSelect(item)} />;
                    } else {
                        return <div />;
                    }
                },
            },
            {
                header: 'Date',
                width: '8',
                cell: (item) => {
                    return (
                        <div>
                            <IconAppointmentStatus status={item.status} />
                            <Display.Date value={item.startDate} format={'ll'} />
                            {' @'}
                            <Display.DateRange startDate={item.startDate} duration={item.duration} dayFormat={null} />
                            {/*<div>{item.id}</div>*/}
                        </div>
                    );
                },
            },
            {
                header: 'Staff',
                width: '8',
                cell: (item) => {
                    return <Display.Entity value={item.staff} />;
                },
            },
        ],
        [connectionData]
    );
    const hasNextPage = connectionState?.pageInfo?.hasNextPage;

    const header = useMemo(() => {
        switch (mode) {
            case RelatedAppointmentsMode.Future:
                return hasNextPage ? `Next ${limit} appointments` : 'Future Appointments';
            case RelatedAppointmentsMode.Past:
                return 'Previous Appointments';
            case RelatedAppointmentsMode.FuturePast:
                return 'Related Appointments';
        }
    }, [mode, limit, connectionState?.pageInfo]);

    if (connectionData.length == 0) {
        return null;
    }
    return (
        <div>
            <SectionHeader dividing={true} content={header} />
            <span>{recurrenceString}</span>
            <DefaultConnectionTable
                debug={false}
                tableProps={{ celled: false, compact: true, size: 'small' }}
                tableDef={tableDef}
                connectionData={connectionData}
                connectionState={{ loading: connectionState.loading } as any}
                showPagination={false}
                tableFooter={hasNextPage ? <span tw={'px-2'}>. . .</span> : undefined}
            />
        </div>
    );
};
