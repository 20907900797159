import { WaterlinkAPI } from '../interfaces';
import { WaterlinkAPIClientInterface } from '../WaterlinkAPIClientInterface';

const wait = async (x = 0) => {
    return await new Promise((resolve) => setTimeout(resolve, x));
};

const defaultConfig = {
    host: 'localhost',
    port: 61547,
};

export class WaterlinkAPIClient_Mock implements WaterlinkAPIClientInterface {
    public host: string = null;
    public port: number = null;
    private progress: number = 0;

    private isRunning: boolean = false;
    private isFailing: boolean = false;

    public readonly serverBaseUrl: string = '';

    constructor(conf: { host?: string; port?: number } = defaultConfig) {
        this.host = conf.host || defaultConfig.host;
        this.port = conf.port || defaultConfig.port;
        this.serverBaseUrl = this.getBase();
        this.progress = 0;
    }

    private getBase = () => {
        return `http://${this.host}:${this.port}`;
    };

    public getStatus = async (): Promise<WaterlinkAPI.StatusReply | null> => {
        if (this.isFailing) {
            const status = (await import('./status-busy')).default;
            return status;
        }

        if (this.isRunning) {
            const status = (await import('./status-busy')).default;
            return { ...status, Progress: this.progress };
        }

        const readyStatus = {
            Status: 5,
            StatusString: 'Ready',
            Code: 0,
            Progress: this.progress,
            CanDoWatertest: true,
            CanGetLog: true,
            CanDoUpdate: false,
            Name: 'MOCK Spin Touch',
            RMN: '',
            SerialNum: 'DEADBEEF',
            CurentFirmware: 258,
            CurrentFirmwareString: '1.2',
            AvailableFirmware: 258,
            AvailFirmwareString: '1.2',
        };

        return readyStatus;
    };

    public getOptions = async (
        version?: string | 'pool' | 'spa' | 'drinkingwater' | 'coolingwater'
    ): Promise<WaterlinkAPI.OptionsReply | null> => {
        return (await import('./options.json')).default;
    };

    public getLog = async (): Promise<any> => {
        return (await import('./log.json')).default;
    };

    public getAbout = async (): Promise<WaterlinkAPI.AboutReply> => {
        return {
            version: '1.5',
            documentation: 'MOCK CLIENT',
        };
    };

    public doWaterTest = async (input: {
        reagent: number;
        sample: number;
    }): Promise<WaterlinkAPI.WatertestReply | null> => {
        if (!input || input.reagent === undefined) {
            throw new Error('Reagent is not provided');
        }
        if (!input || !input.sample === undefined) {
            throw new Error('Sample is not provided');
        }

        this.isRunning = true;
        this.progress = 0;
        await wait(110);
        this.progress = 10;
        await wait(410);
        this.progress = 23;
        await wait(110);
        this.progress = 45;
        await wait(630);
        this.progress = 66;
        await wait(810);
        this.progress = 77;
        await wait(110);
        this.progress = 90;
        await wait(110);
        this.progress = 100;
        this.isRunning = false;

        // See options.json.ts
        const disk2xx = [2, 3, 18, 26];
        const disk3xx = [4, 5, 19, 27];
        const disk4xx = [8, 6];

        if (disk2xx.includes(input.reagent)) {
            return (await import('./watertest-salt-204')).default as any;
        }

        if (disk3xx.includes(input.reagent)) {
            return (await import('./watertest-304')).default as any;
        }

        if (disk4xx.includes(input.reagent)) {
            return (await import('./watertest-401')).default as any;
        }

        return (await import('./watertest-MCD')).default as any;
    };
}
