import * as React from 'react';
import { NodeType } from '@poolware/api';
import { useFormikContext } from 'formik';
import { AppointmentFormMode, AppointmentFormValuesType } from '../AppointmentForm';
import { WorkOrderDetailsView } from './WorkOrderDetailsView';
import { StartWorkOrderView } from './StartWorkOrderView';
import { AddServiceTemplateView } from './AddServiceTemplateView';
import { FormikServiceJobTemplateSelect } from '../../../connected-components/FormikServiceJobTemplateSelect';

export interface WorkOrderTabProps {
    refetchQuery: any;
    onOpenWorkOrder: (wo: NodeType.WorkOrder) => any;
}

export const FormTabWorkOrder: React.FC<WorkOrderTabProps> = ({ refetchQuery, onOpenWorkOrder }) => {
    const { values, setFieldValue } = useFormikContext<AppointmentFormValuesType>();
    const { serviceJob, workOrder, mode, appointmentItem } = values;

    if (mode === AppointmentFormMode.New) {
        const onDidSelectTemplate = (
            serviceJobTemplate: NodeType.ServiceJobTemplate,
            workOrderTemplates: NodeType.WorkOrderTemplate[]
        ) => {
            setFieldValue('woTemplate', workOrderTemplates?.[0]);
        };
        return (
            <>
                <FormikServiceJobTemplateSelect
                    name={'sjTemplate'}
                    selectWorkOrder={'single'}
                    requiredWorkOrder={true}
                    onDidSelect={onDidSelectTemplate}
                    previewWorkOrderIds={[values.woTemplate?.id]}
                />
            </>
        );
    }

    if (!serviceJob) {
        if (appointmentItem.isRecurring) {
            return <>Changing work order template for a recurring appointment is not supported yet</>;
        } else if (!workOrder) {
            return <AddServiceTemplateView refetchQuery={refetchQuery} />;
        }
    } else {
        if (serviceJob?.isRecurring && !workOrder) {
            return <StartWorkOrderView refetchQuery={refetchQuery} />;
        } else {
            return <WorkOrderDetailsView refetchQuery={refetchQuery} onOpenWorkOrder={onOpenWorkOrder} />;
        }
    }
};
