import * as React from 'react';
import { NodeType, useMutationWorkOrderFeedItem } from '@poolware/api';
import { customWorkOrderFeedItemMutation } from './useConnectedQueryWorkOrdersFeed';
import { Display, Panel, toastError } from '@poolware/components';
import { Button, Icon, Message } from 'semantic-ui-react';
import { ServiceJobLink } from '../../Links';

export interface PromptWorkOrderCreateProps {
    woFeedItem: NodeType.WorkOrderFeedItem;
    onDone: () => any;
    refetchQuery?: any;
}

export const PromptWorkOrderCreate: React.FC<PromptWorkOrderCreateProps> = ({
    woFeedItem = {},
    onDone,
    refetchQuery,
}) => {
    const { startWorkOrder, mutationResult } = useMutationWorkOrderFeedItem({
        mutation: customWorkOrderFeedItemMutation,
    });

    const onCreate = async () => {
        try {
            const res = await startWorkOrder({ id: woFeedItem.id }, refetchQuery && { refetchQueries: [refetchQuery] });
            const wo = res.data?.WorkOrderFeedItem?.WorkOrderFeedItem?.workOrder;
            if (!wo) {
                console.error('failed to fetch work order after `startWorkOrder` mutation');
                toastError({ title: 'Error', description: 'Failed to fetch work order details' });
                return;
            }
            onDone();
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    const { customer, address, workOrder, pool, appointmentItem, staff, serviceJob } = woFeedItem;

    return (
        <Panel>
            <Panel.Header content={'Pending Work Order'} />
            <Panel.Body>
                <Panel.Item label={'Appointment'}>
                    <Icon name={'calendar alternate outline'} />
                    <Display.Date value={appointmentItem?.startDate} format={'lll'} />
                </Panel.Item>
                <Panel.Item label={'Assigned To'}>
                    <>
                        <Icon name={'spy'} />
                        {staff ? <Display.Entity value={staff} /> : '--'}
                    </>
                </Panel.Item>
                {customer && (
                    <Panel.Item label={'Customer'}>
                        <span>
                            <Icon name={'user'} />
                            <b>{customer.crn}</b> - <Display.Entity value={customer} />
                        </span>
                    </Panel.Item>
                )}
                {address && (
                    <Panel.ItemAddress label={'Address'} content={address} iconMarker={'map marker alternate'} />
                )}
                {pool && (
                    <Panel.Item label={'Pool'}>
                        <Icon name={'life ring'} />
                        Pool - {pool.volume}L {pool.type?.name && `, ${pool.type?.name}`}
                    </Panel.Item>
                )}
                <Message info={true}>
                    <div>
                        This work order is generated by Recurring Service{' '}
                        <ServiceJobLink id={serviceJob.id}>{serviceJob.serviceJobNumber}</ServiceJobLink>
                    </div>
                </Message>
            </Panel.Body>
            <Panel.Footer>
                <Button
                    floated={'left'}
                    onClick={onDone}
                    content={'Cancel'}
                    secondary={true}
                    basic={true}
                    disabled={mutationResult.loading}
                />
                <Button
                    floated={'right'}
                    loading={mutationResult.loading}
                    onClick={onCreate}
                    content={'Start Work Order'}
                    primary={true}
                    disabled={mutationResult.loading}
                />
            </Panel.Footer>
        </Panel>
    );
};
