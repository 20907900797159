import * as React from 'react';
import { NodeType } from '@poolware/api';
import { DeclarationTree } from '../components/TraitDeclarationTree';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import { LinkButton, PageSkeletonLoader } from '@poolware/components';
import { QueryProductDeclarationByTag } from './QueryProductDeclarationByTag';
import { QueryProductDeclarationsConnection } from '../queries/QueryProductDeclarationsConnection';

const PickerItemActive = styled(LinkButton)`
    padding: 0.25em 0.5em;
    margin-top: -0.25em;
    font-weight: bold;
    color: white;
    background-color: #4278a8;
    border-radius: 4px;
    & > * {
        color: white;
    }
`;

const PickerItem = styled(LinkButton)`
    padding: 0.25em 0.5em;
    margin-top: -0.25em;
    &:hover {
        font-weight: bold;
        color: white;
        background-color: #559fde;
        padding: 0.25em 0.5em;
        border-radius: 4px;
    }
`;

export interface TraitDeclarationPickerProps {
    onSelect: (pd: NodeType.ProductTraitDeclaration) => any;
    current?: NodeType.NodeOrId<NodeType.ProductTraitDeclaration>[];
    rootTag?: string;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}

export const TraitDeclarationPicker_Tree: React.FC<TraitDeclarationPickerProps> = ({
    onSelect,
    current = [],
    rootTag,
    organisationType,
}) => {
    const itemRenderer = (pd: NodeType.ProductTraitDeclaration) => {
        const handleClick = (e) => {
            e.preventDefault();
            onSelect && onSelect(pd);
        };

        const { franchise } = pd;
        const icon = franchise ? (
            <Popup position={'top center'} trigger={<Icon name={'building'} />}>
                {franchise.name}
            </Popup>
        ) : null;

        const checkActive = (pdId) => {
            if (!current) {
                return false;
            }
            const found = current.find((c) => {
                if (!c) return false;
                return pdId === NodeType.extractId(c);
            });
            return !!found;
        };

        const isActive = checkActive(pd.id);
        if (isActive) {
            return (
                <PickerItemActive onClick={handleClick}>
                    {pd.name} {icon}
                </PickerItemActive>
            );
        }

        return (
            <PickerItem onClick={handleClick}>
                {pd.name} {icon}
            </PickerItem>
        );
    };

    if (rootTag) {
        return (
            <QueryProductDeclarationByTag tag={rootTag}>
                {(queryResult) => {
                    const { error, declarations, loading } = queryResult;

                    if (loading) {
                        return (
                            <div style={{ width: '95%' }}>
                                <PageSkeletonLoader lineCount={3} />
                            </div>
                        );
                    }
                    if (error) {
                        return <div style={{ width: '95%', color: 'red' }}>Error: {JSON.stringify(error)}</div>;
                    }

                    return <DeclarationTree declarations={declarations} itemRenderer={itemRenderer} />;
                }}
            </QueryProductDeclarationByTag>
        );
    } else {
        return (
            <QueryProductDeclarationsConnection
                pageSize={100}
                pageIndex={0}
                fetchPolicy={'cache-first'}
                organisationType={organisationType}
            >
                {({ connectionData, connectionState }) => {
                    if (connectionState.isInitialLoading) {
                        return (
                            <div style={{ width: '95%' }}>
                                <PageSkeletonLoader lineCount={3} />
                            </div>
                        );
                    }
                    return <DeclarationTree declarations={connectionData} itemRenderer={itemRenderer} />;
                }}
            </QueryProductDeclarationsConnection>
        );
    }
};
