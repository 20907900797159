import * as React from 'react';
import { NotFoundPage, PageContent, useViewer } from '@poolware/app-shell';
import {
    ModuleNavLink,
    ModuleRedirect,
    ModuleRoot,
    ModuleRoute,
    ModuleSwitch,
    useAppNavigator,
} from '@poolware/react-app-navigator';
import { ServiceJobTemplateNew } from './ServiceJobTemplate/New/Page';
import { ServiceJobTemplateList } from './ServiceJobTemplate/List/Page';
import { ServiceJobTemplateView } from './ServiceJobTemplate/ServiceJobTemplateView';
import { WorkOrderTemplateNew } from './WorkOrderTemplate/WorkOrderTemplateNew';
import { WorkOrderTemplateView } from './WorkOrderTemplate/WorkOrderTemplateView/WorkOrderTemplateView';
import { WorkOrderTemplateList } from './WorkOrderTemplate/WorkOrderTemplateList';
import styled from 'styled-components';
import { Icon, Menu } from 'semantic-ui-react';
import { JobTodoTemplateEdit, JobTodoTemplateList, JobTodoTemplateNew, JobTodoTemplateView } from './JobTodoTemplate';
import { ModuleIconNames } from '../constants';
import { ServiceGroupsEditor } from './ServiceJobGroup/ServiceGroupEdit/ServiceGroupsEditor';

const StubPage = () => {
    const nav = useAppNavigator();
    return (
        <div>
            Stub Page <button onClick={() => nav.history.goBack()}>go back</button>
        </div>
    );
};

const StyledMenu = styled(Menu)`
    //margin-bottom: 0 !important;
    border-radius: 0 !important;
    background-color: #e6e6e6 !important;

    .menu .item.active {
        background-color: #ffffff !important;
    }
`;

const SectionsNavbar = () => {
    const { AppNavigator } = useAppNavigator();
    const { isAdmin, modulesAccess } = useViewer();
    const allowSJTemplates = modulesAccess.FieldServices?.caseTemplates;
    const allowTaskTemplates = modulesAccess.FieldServices?.serviceJobTaskTemplates;

    if (AppNavigator.isModal || !allowSJTemplates) {
        return null;
    }

    return (
        <StyledMenu fluid>
            <Menu.Menu position={'left'}>
                {allowSJTemplates && (
                    <Menu.Item as={ModuleNavLink} to={'/sj-template'}>
                        <Icon name={ModuleIconNames.ServiceCase} />
                        Service Case Templates
                    </Menu.Item>
                )}
                {allowTaskTemplates && (
                    <Menu.Item as={ModuleNavLink} to={'/job-todo-template'}>
                        <Icon name={ModuleIconNames.WorkOrderItem} /> Job Task Templates
                    </Menu.Item>
                )}
            </Menu.Menu>
            <Menu.Menu position={'right'}>
                {isAdmin && false && (
                    <Menu.Item as={ModuleNavLink} to={'/wo-template'}>
                        <Icon name={ModuleIconNames.WorkOrder} />
                        Work Order Templates
                    </Menu.Item>
                )}
            </Menu.Menu>
        </StyledMenu>
    );
};

export const Router = () => {
    const defaultRedirect = '/sj-template';

    return (
        <>
            <ModuleRoot>
                <ModuleRoute path={'/sj-template'} exact={true} component={SectionsNavbar} />
                <ModuleRoute path={'/job-todo-template'} exact={true} component={SectionsNavbar} />

                <ModuleSwitch notFound={NotFoundPage}>
                    <ModuleRedirect path={'/'} exact to={defaultRedirect} />

                    <ModuleRoute path={'/sj-template/edit-groups'} component={ServiceGroupsEditor} />
                    <ModuleRoute path={'/sj-template/new'} component={ServiceJobTemplateNew} />
                    <ModuleRoute path={`/sj-template/:id`} component={ServiceJobTemplateView} />
                    <ModuleRoute path={'/sj-template'} component={ServiceJobTemplateList} />

                    <ModuleRoute path={'/wo-template/new'} component={WorkOrderTemplateNew} />
                    <ModuleRoute path={`/wo-template/:id`} component={WorkOrderTemplateView} />
                    <ModuleRoute path={'/wo-template'} component={WorkOrderTemplateList} />

                    <ModuleRoute path={`/job-todo-template/new`} component={JobTodoTemplateNew} />
                    <ModuleRoute path={`/job-todo-template/:id/edit`} component={JobTodoTemplateEdit} />
                    <ModuleRoute path={`/job-todo-template/:id`} component={JobTodoTemplateView} />
                    <ModuleRoute path={`/job-todo-template`} component={JobTodoTemplateList} />

                    <ModuleRoute path={`/stub/:id`} component={StubPage} />
                </ModuleSwitch>
            </ModuleRoot>
        </>
    );
};
