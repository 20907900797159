import gql from 'graphql-tag';
import * as _ from 'lodash';
import { useDefaultConnectionState, useQueryConnection } from '@poolware/api';

const QL = gql`
    query QueryFranchiseWarrantersConnection(
        $first: Int
        $page: Int
        $sort: FranchiseWarranterViewerFranchiseWarrantersSort
        $search: FranchiseWarranterViewerFranchiseWarrantersSearch
    ) {
        viewer {
            franchiseWarranters(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        email
                        name
                        actingFranchise {
                            id
                            name
                        }
                        createdAt
                        updatedAt
                        checkMutations {
                            delete
                            update
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export interface UseQueryFranchiseWarrantersConnectionProps {
    searchName?: string;
}

function mapPropsToSearchQuery(props?: UseQueryFranchiseWarrantersConnectionProps) {
    if (!props) {
        return undefined;
    }

    const { searchName } = props;
    const search = {};
    if (searchName) {
        _.set(search, 'name.like', searchName);
    }
    return search;
}

export function useQueryFranchiseWarrantersConnection(props?: UseQueryFranchiseWarrantersConnectionProps) {
    const connectionPath = 'viewer.franchiseWarranters';
    const { variables, connectionState } = useDefaultConnectionState({ defaultPageSize: 20 });
    variables.search = mapPropsToSearchQuery(props);

    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
