import * as React from 'react';

import { Button, Form, Icon, Modal } from 'semantic-ui-react';

import { Panel, useModalCtrl } from '@poolware/components';
import { BarcodeScanAndSearch } from './BarcodeScanAndSearch';
import { useViewer } from '@poolware/app-shell';

interface CustomerSearchFormProps {
    isSearching: boolean;
    onSubmit: (values?: any) => any;
    onChange?: (any) => any;
    onClear: () => any;
    value: any;
}

export interface SearchValuesType {
    crn?: string;
    bottleNumber?: string;
    address?: string;
    siteName?: string;
    customerName?: string;
    contact?: string;
}

const CustomerSearchForm: React.FC<CustomerSearchFormProps> = ({
    value = {},
    onChange,
    onSubmit,
    isSearching,
    onClear,
}) => {
    const { modulesAccess } = useViewer();

    const modalCtl = useModalCtrl(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };

    const handleChange = (e, { name, value }) => {
        onChange({ name, value });
    };

    const buttons = [
        modulesAccess.Pools?.barcodedBottles && {
            content: 'Scan Bottle Barcode',
            onClick: modalCtl.onOpen,
            icon: 'barcode',
            color: 'blue',
        },
        { content: 'Clear', onClick: onClear },
    ];

    const addressLabel = modulesAccess.Pools?.enabled ? 'Site, Pool or Customer Address' : 'Address';

    return (
        <>
            <Panel>
                <Panel.Header icon={'search'} button={buttons}>
                    Search
                </Panel.Header>
                <Panel.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group widths="equal">
                            <Form.Input
                                label="Customer CRN"
                                name="crn"
                                id="crn"
                                size={'small'}
                                autoComplete="off"
                                value={value['crn'] || ''}
                                onChange={handleChange}
                            />
                            <Form.Input
                                label="Customer Name"
                                name="customerName"
                                id="customerName"
                                size={'small'}
                                autoComplete="off"
                                value={value['customerName'] || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group widths="equal">
                            <Form.Input
                                label={addressLabel}
                                name="address"
                                id="address"
                                size={'small'}
                                autoComplete="off"
                                value={value['address'] || ''}
                                onChange={handleChange}
                            />
                            <Form.Input
                                label="Phone/Email"
                                name="contact"
                                id="contact"
                                autoComplete="off"
                                size={'small'}
                                value={value['contact'] || ''}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        {modulesAccess.Pools?.enabled && (
                            <Form.Group widths="equal">
                                <Form.Input
                                    label="Pool Number"
                                    size={'small'}
                                    name="bottleNumber"
                                    id="bottleNumber"
                                    autoComplete="off"
                                    value={value['bottleNumber'] || ''}
                                    onChange={handleChange}
                                />
                                <Form.Input
                                    label="Site Name"
                                    name="siteName"
                                    size={'small'}
                                    id="site_name"
                                    autoComplete="off"
                                    value={value['siteName'] || ''}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        )}

                        <Button fluid type="submit" name="submitButton" color="teal">
                            {isSearching ? <Icon loading name="spinner" /> : <Icon name="search" />}
                            {isSearching ? 'Searching' : 'Search'}
                        </Button>
                    </Form>
                </Panel.Body>
            </Panel>

            <Modal {...modalCtl} closeOnDimmerClick={false}>
                <BarcodeScanAndSearch onClose={modalCtl.onClose} />
            </Modal>
        </>
    );
};

export default CustomerSearchForm;
