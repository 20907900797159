import * as React from 'react';
import { Panel } from '@poolware/components';
import { Icon } from 'semantic-ui-react';
import { getIconNameForMimeType } from '../admin/ProductDocs/utils';
import { truncateMiddle } from '@ez/tools';
import { useFormikContext } from 'formik';
import { CalcDocType, FormikHeatCalcValueType } from './types';

export interface PanelBrochureDetailsProps {}

export const PanelProductBrochure: React.FC<PanelBrochureDetailsProps> = ({}) => {
    const { values } = useFormikContext<FormikHeatCalcValueType>();
    const type = values.docType;
    let productDoc = null;
    switch (type) {
        case CalcDocType.HeatPumpAutopool:
        case CalcDocType.HeatPump:
            productDoc = values.costEstimation?.hpump?.recommendedModel?.product?.brochure;
            break;
        case CalcDocType.GasHeater:
            productDoc = values.costEstimation?.gas?.recommendedModel?.product?.brochure;
    }

    if (!productDoc) {
        return null;
    }
    return (
        <Panel>
            <Panel.Body>
                <Panel.Item
                    label={'Product Brochure'}
                    labelWidth={'100%'}
                    labelIcon={<Icon name={getIconNameForMimeType(productDoc.mimeType)} />}
                >
                    <a href={productDoc.url} target={'_blank'}>
                        {truncateMiddle(productDoc.title, 40, 4)} <Icon name={'external'} />
                    </a>
                </Panel.Item>
            </Panel.Body>
        </Panel>
    );
};
