import Page from './Page';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withApolloLoading } from '@poolware/components';
import { compose, mapProps } from '@ez/tools';

import { NodeType, VendMutatorProps, withVendMutators } from '@poolware/api';
import { withViewer, WithViewerProps } from '@poolware/app-shell';

const QL = gql`
    query VendAPIQuery {
        viewer {
            me {
                staff {
                    id
                    root {
                        id
                        franchise {
                            id
                            name
                            vend {
                                id
                                key
                                shopUri
                                vendTest
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default compose(
    withViewer(),
    graphql(QL),
    withApolloLoading(),
    mapProps((props) => {
        let franchise = props?.data?.viewer?.me?.staff?.root?.franchise;
        let vend = franchise?.vend;

        return {
            ...props,
            vend,
            franchise,
        };
    }),
    withVendMutators(['VendAPIQuery'])
)(Page);

export interface PageControlProps extends VendMutatorProps, WithViewerProps {
    vend: NodeType.VendAPI;
    franchise: NodeType.Franchise;
}
