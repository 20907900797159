import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import { useViewer } from '@poolware/app-shell';
import { useAppNavigator } from '@poolware/react-app-navigator';
import * as URLBuilder from '../../url-builder';
import { useBookingActions, useCalendarActions } from '../../../redux';
import { QuickActionCalendarWidget } from './CalendarWidget';
import { VStack } from '@poolware/components';
import { CalViewMode } from '../../types';
import { mapCalViewModeToLabel } from '../../CalendarToolbar';

export interface QuickActionsTabProps {}

const defaultActionButtonProps: ButtonProps = {
    // size: 'mini',
    fluid: false,
    basic: true,
    color: 'grey',
    style: { fontSize: '0.9em', padding: '0.5em 1em', lineHeight: '1.1em', minHeight: '1em' },
};

export const QuickActionsTab: React.FC<QuickActionsTabProps> = ({}) => {
    const { modulesAccess } = useViewer();
    const { AppNavigator } = useAppNavigator();
    const { BookingAction, BookingState } = useBookingActions();
    const { CalendarState, CalendarAction } = useCalendarActions();

    const canPrint = modulesAccess.System?.printAccess;

    const onPrint = () => {
        const url = URLBuilder.Print().AppointmentItem().list;
        AppNavigator.navigate(url, { setOrigin: true });
    };

    const startBooking = () => {
        BookingAction.setDetails({ startDate: new Date(), duration: 60 });
        if (!BookingState.isSagaMode) {
            // Start new saga
            BookingAction.startFromCalendar();
        }
    };

    const renderNewAppointmentButton = () => {
        if (BookingState.isSagaMode) {
            // TODO: don't show this button if already in saga mode
            // When in saga mode, pressing on this button will start another saga and it screws the hole thing up.
            return null;
        }
        return (
            <Button
                {...defaultActionButtonProps}
                color={'green'}
                icon="plus"
                onClick={startBooking}
                content={'New Booking'}
            />
        );
    };

    const renderViewRangeModeSwitch = () => {
        let viewNames = [CalViewMode.MONTH, CalViewMode.WEEK, CalViewMode.DAY];

        if (viewNames.length > 1) {
            return (
                <Button.Group size={'tiny'} fluid={true}>
                    {viewNames.map((name) => {
                        const isActive = CalendarState.viewMode === name;
                        const label = mapCalViewModeToLabel(name, false);
                        return (
                            <Button
                                key={name}
                                basic={!isActive}
                                color={isActive ? 'teal' : 'grey'}
                                active={isActive}
                                onClick={() => CalendarAction.setCalendar({ viewMode: name })}
                                content={label}
                            />
                        );
                    })}
                </Button.Group>
            );
        }
    };

    return (
        <VStack>
            <div tw={'flex flex-row justify-between'}>
                {/**/}
                {renderViewRangeModeSwitch()}
            </div>
            <div tw={'flex flex-col border-0 border-b border-gray-200 border-solid'}>
                <QuickActionCalendarWidget />
            </div>
            <div tw={'gap-2 p-2 py-4 w-full flex flex-row flex-wrap'}>
                {renderNewAppointmentButton()}
                {canPrint && (
                    <Button {...defaultActionButtonProps} icon="print" content="Print" color="blue" onClick={onPrint} />
                )}
            </div>
        </VStack>
    );
};
