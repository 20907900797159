import * as React from 'react';
import { Divider, Segment } from 'semantic-ui-react';
import PoolList from './PoolList';
import { fromEdges, NodeType } from '@poolware/api';
import { TabControlProps } from './Tab.Control';
import * as URLBuilder from '../../../../../routes/url-builder';
import { Panel, SectionHeader } from '@poolware/components';

export interface SiteListProps extends TabControlProps {}

class SiteList extends React.PureComponent<SiteListProps & { addSite: any }> {
    state = {
        editAccessKeyMode: false,
    };

    addAccessKey = (site: NodeType.Site) => {
        const link = URLBuilder.Customer(this.props.customerId).Site(site.id).editAccessKeyLocation;
        this.props.AppNavigator.navigate(link, {
            setOrigin: true,
            modal: true,
            query: { customerId: this.props.customerId },
        });
    };

    editSite = (site) => {
        const link = URLBuilder.Customer(this.props.customerId).Site(site.id).edit;
        this.props.AppNavigator.navigate(link, {
            setOrigin: true,
            modal: false,
            query: { customerId: this.props.customerId },
        });
    };

    addPool = (site) => {
        const link = URLBuilder.Customer(this.props.customerId).addPool;
        this.props.AppNavigator.navigate(link, {
            setOrigin: true,
            modal: true,
            query: { customerId: this.props.customerId, siteId: site.id },
        });
    };

    changeOwner = (site) => {
        const link = URLBuilder.Customer(this.props.customerId).Site(site.id).transferOwnership;
        this.props.AppNavigator.navigate(link, {
            setOrigin: true,
            modal: false,
        });
    };

    render() {
        let { sites, customerId, viewerContext } = this.props;
        sites = fromEdges(sites);
        const numOfSites = sites.length;

        const showSiteOwnerChangeButton = viewerContext.modulesAccess?.Pools.siteOwnershipChange;

        return (
            <>
                {sites.length === 0 ? (
                    <Segment>
                        <p style={{ color: '#555' }}>
                            <i>No sites registered</i>
                        </p>
                        {/*<Button onClick={this.props.addSite} icon="plus" color="olive" content={'Add Site'} fluid />*/}
                    </Segment>
                ) : (
                    sites.map((site, index) => {
                        let pools = fromEdges(site.pools);
                        const menuItems = [
                            {
                                key: 1,
                                name: 'Edit Site Details',
                                icon: 'edit',
                                onClick: () => this.editSite(site),
                            },
                            { key: 3, name: 'Edit Access Key', icon: 'key', onClick: () => this.addAccessKey(site) },
                            { key: 2, name: 'Add New Pool', icon: 'plus', onClick: () => this.addPool(site) },
                            showSiteOwnerChangeButton && {
                                key: 2,
                                name: 'Change Owner',
                                icon: 'exchange',
                                onClick: () => this.changeOwner(site),
                            },
                        ];

                        return (
                            <React.Fragment key={site.id}>
                                <Panel>
                                    <Panel.Header
                                        color={'grey'}
                                        icon={'home'}
                                        content={`Site - ${site.name}`}
                                        button={{
                                            icon: 'chevron down',
                                            content: 'Edit Site',
                                            menuItems: menuItems,
                                        }}
                                    />
                                    <Panel.Body>
                                        <Panel.ItemAddress
                                            showMapLink={true}
                                            label="Address"
                                            labelWidth={'100%'}
                                            // labelIcon={'map marker alternate'}
                                            content={site.address}
                                        />
                                        <Panel.ItemText
                                            label={'Site Access Key'}
                                            // labelIcon={'key'}
                                            // maxHeightPx={160}
                                            labelWidth={'100%'}
                                            content={site.accessKeyLocation || '--'}
                                        />
                                        {/*<Divider hidden={true} />*/}

                                        <PoolList
                                            pools={pools}
                                            customerId={customerId}
                                            onAddPool={() => this.addPool(site)}
                                        />
                                    </Panel.Body>
                                </Panel>
                                {index !== numOfSites - 1 && <Divider hidden />}
                            </React.Fragment>
                        );
                    })
                )}
            </>
        );
    }
}

const TabSites: React.FC<SiteListProps> = (props) => {
    const addSite = () => {
        const link = URLBuilder.Customer(props.customerId).addSite;
        props.AppNavigator.navigate(link, {
            setOrigin: true,
            modal: false,
            query: { customerId: props.customerId },
        });
    };

    const sites = fromEdges(props.sites);

    const buttons = {
        content: 'Add New Site',
        icon: 'plus',
        color: sites.length === 0 ? 'blue' : undefined,
        onClick: addSite,
    };

    return (
        <>
            <SectionHeader content={'Sites'} button={buttons} />
            <Divider />
            <SiteList {...props} addSite={addSite} />
        </>
    );
};

export default TabSites;
