import * as React from 'react';
import { useMemo } from 'react';
import { useAppNavigator } from '@poolware/react-app-navigator';
import {
    AsyncCheckbox,
    ConnectionTableDef,
    DefaultConnectionTable,
    IconWithPopup,
    toastError,
} from '@poolware/components';
import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';

import { useQueryProductConnection } from '../../common/use-query-product-connection';
import { truncateMiddle, usePersistedToggle } from '@ez/tools';
import { CALC_USAGE_TAG, changeUseInCalcFlag, findByTag, isUseInCalc } from '../../common/use-equipment-catalog';
import { useProductCatalogMutators } from '@poolware/app-catalog';
import { BrandingType } from '../../common/types';

interface ProductSearchResultsTableProps {
    declarationTag: string;
    fieldTags: string[];
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
    hideInactive: boolean;
    brandType?: BrandingType;
}

export const ProductSearchResultsTable: React.FC<ProductSearchResultsTableProps> = ({
    declarationTag,
    fieldTags,
    organisationType,
    hideInactive,
    brandType,
}) => {
    const { connectionData, connectionState, refetchQuery } = useQueryProductConnection({
        declarationTag: declarationTag,
        flagTag: hideInactive
            ? {
                  declarationTag: declarationTag,
                  fieldTag: CALC_USAGE_TAG,
                  value: true,
              }
            : undefined,
        brandType: brandType,
        pageSize: 40,
        organisationType,
    });

    const { ProductCatalogMutator } = useProductCatalogMutators({ refetchQueries: [refetchQuery] });

    const { AppNavigator } = useAppNavigator();

    const preparedConnectionData = useMemo(() => {
        return connectionData.map((p) => {
            const heaterTraits = fromEdges(p.traits).filter(
                (trait) => trait.declaration && trait.declaration.tag === declarationTag
            );
            const allFloats = heaterTraits.flatMap((t) => t.floatValues || []);
            const fields = fieldTags
                ?.map((tag) => {
                    return findByTag(allFloats, tag);
                })
                .filter(Boolean);
            const isValidConfig = fields?.length === fieldTags?.length;
            const isEnabledForCalc = isUseInCalc(p);
            return {
                ...p,
                isEnabledForCalc,
                isValidConfig,
            };
        });
    }, [connectionData]);

    const onView = (product: NodeType.Product) => {
        AppNavigator.navigateRelative(`/products/${product.id}`, { setOrigin: true });
    };

    const onToggle = async (product: NodeType.Product, checked: boolean) => {
        try {
            await changeUseInCalcFlag(ProductCatalogMutator, product, checked);
        } catch (e) {
            toastError(e);
        }
    };

    const tableDef: ConnectionTableDef<typeof preparedConnectionData[0]> = useMemo(
        () => [
            {
                header: 'Use In Calc.',
                width: 1,
                cellProps: { textAlign: 'center' },
                cell: (p) => {
                    if (!p.isValidConfig) {
                        return (
                            <IconWithPopup
                                name={'warning sign'}
                                popup={{ content: 'Incomplete product configuration' }}
                            />
                        );
                    }
                    return (
                        <AsyncCheckbox
                            toggle={true}
                            checked={p.isEnabledForCalc}
                            onToggle={(checked) => onToggle(p, checked)}
                        />
                    );
                },
            },
            {
                header: 'SKU',
                width: 2,
                // sortKey: 'sku',
                cell: (pd) => _.get(pd, 'sku', '--'),
            },
            {
                header: 'Brand',
                width: 3,
                cell: (pd) => {
                    const brandName = _.get(pd, 'brand.name');
                    const brandCompany = _.get(pd, 'brand.company.name');
                    return `${brandCompany ? `${brandCompany} > ` : ''} ${brandName || ''} `;
                },
            },
            {
                header: 'Name',
                width: 4,
                sortKey: 'name',
                cell: (pd) => {
                    const name = _.get(pd, 'name', '--');
                    return <span tw={'text-blue-900'}>{name}</span>;
                },
            },

            {
                header: 'Docs',
                cell: (p) => {
                    const docs = fromEdges(p.documents);
                    return (
                        <div tw={'flex flex-col'}>
                            {docs.map((d) => {
                                return <div key={d.id}>{truncateMiddle(d.title, 50, 4)}</div>;
                            })}
                        </div>
                    );
                },
            },
            {
                header: 'Ownership',
                cell: (p) => {
                    return (
                        <>
                            {p.organisationType?.name}
                            {p.franchise?.name}
                        </>
                    );
                },
            },
            // ...declarationsListColumn(),
        ],
        [preparedConnectionData]
    );

    return (
        <DefaultConnectionTable
            onRowClick={onView}
            connectionData={preparedConnectionData}
            connectionState={connectionState}
            tableDef={tableDef}
        />
    );
};
