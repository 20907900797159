import {
    appointmentItemFragment,
    NodeType,
    recurrenceFragment,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import gql from 'graphql-tag';

export const QueryAppointmentItemsUpcoming = gql`
    query RelatedAppointmentItems(
        $startDate: Date!
        $pivotDate: Date
        $endDate: Date
        $first: Int
        $franchise: ID
        $customer: ID
        $serviceJob: ID
        $reverse: Boolean
        $before: String
        $after: String
        $patternBlockId: String
    ) {
        viewer {
            appointmentItems(
                before: $before
                after: $after
                startDate: $startDate
                pivotDate: $pivotDate
                endDate: $endDate
                first: $first
                franchise: $franchise
                customer: $customer
                serviceJob: $serviceJob
                reverse: $reverse
                patternBlockId: $patternBlockId
            ) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                edges {
                    node {
                        startDate
                        duration
                        status
                        isRecurring
                        isRecurrentOrigin
                        patternBlockId
                        ...AppointmentItemFragment
                        appointment {
                            id
                            recurrence {
                                ...RecurrenceFragment
                            }
                        }
                    }
                }
            }
        }
    }
    ${appointmentItemFragment}
    ${recurrenceFragment}
`;

const connectionPath = 'viewer.appointmentItems';
export const useQueryRelatedAppointmentItems = (props: { pivotDate; startDate; endDate; patternBlockId; first }) => {
    let { variables, connectionState } = useDefaultConnectionState({
        variables: {
            reverse: false,
            first: props.first,
            startDate: props.startDate,
            endDate: props.endDate,
            pivotDate: props.pivotDate,
            patternBlockId: props.patternBlockId,
        },
    });
    return useQueryConnection<NodeType.AppointmentItem>({
        query: QueryAppointmentItemsUpcoming,
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore',
        variables: variables,
        skip: !variables.patternBlockId,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
    });
};
