import React, { useContext } from 'react';
import { CalEvent } from '../../types';
import { DND_ACTION, RESIZE_DIRECTION } from './types';

export interface DragAndDropActionState {
    interacting: boolean;
    event?: CalEvent;
    action?: DND_ACTION;
    direction?: RESIZE_DIRECTION;
}

export type DnDInteractionInfoType = {
    event?: CalEvent;
    start?: Date;
    end?: Date;
    resourceId?: string;
    isAllDay?: boolean;
};

export interface DndContextValueType {
    draggable: {
        onStart: () => any;
        onEnd: (interactionInfo?: DnDInteractionInfoType) => any;
        onBeginAction: (event: CalEvent, action: DND_ACTION, direction?: RESIZE_DIRECTION) => any;
        onContinueAction: (event: CalEvent, action: DND_ACTION, direction?: RESIZE_DIRECTION) => any;
        onDropFromOutside: (event: DnDInteractionInfoType) => any;
        dragFromOutsideItem: () => any;
        draggableAccessor: (any) => boolean;
        resizableAccessor: (any) => boolean;
        dragAndDropAction: DragAndDropActionState;
    };
}

export const DndContext = React.createContext<DndContextValueType>(null);

export const useDndContext = () => useContext(DndContext);
