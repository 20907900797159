import * as React from 'react';
import { NodeType } from '@poolware/api';
import { Table } from 'semantic-ui-react';
import * as _ from 'lodash';
import {
    flattenFromTree,
    flattenTraitValues,
    ProductTraitFlattened,
    unboxTreeNode,
    unflattenToTree,
} from '../../../../utils';

interface FlattenedTraitRows {
    flattenedTrait: ProductTraitFlattened;
}

const TraitValueRows: React.FC<FlattenedTraitRows> = ({ flattenedTrait }) => {
    const { trait, values, traitLevel } = flattenedTrait;

    const TraitCell = ({ rowSpan }) => (
        <Table.Cell rowSpan={rowSpan} verticalAlign={'top'}>
            <b>
                {'-'.repeat(traitLevel)} {_.get(trait, 'declaration.name')}
            </b>
        </Table.Cell>
    );

    if (values.length == 0) {
        return (
            <Table.Row key={trait.id}>
                <TraitCell rowSpan={1} />
                <Table.Cell disabled>-</Table.Cell>
                <Table.Cell disabled>-</Table.Cell>
                <Table.Cell disabled>-</Table.Cell>
            </Table.Row>
        );
    }

    return (
        <>
            {values.map((ff, i) => {
                return (
                    <Table.Row key={i}>
                        {i === 0 && <TraitCell rowSpan={values.length} />}
                        <Table.Cell>{ff.name}</Table.Cell>
                        <Table.Cell>{ff.printValue}</Table.Cell>
                        <Table.Cell>{ff.traitField.unit}</Table.Cell>
                    </Table.Row>
                );
            })}
        </>
    );
};

export interface ProductTraitsFormProps {
    traits: NodeType.ProductTrait[];
}

export const ProductTraitsTable: React.FC<ProductTraitsFormProps> = ({ traits }) => {
    const declarationTree = traits.map((t) => ({
        id: _.get(t, 'declaration.id'),
        name: _.get(t, 'declaration.name'),
        parent: _.get(t, 'declaration.parent'),
        flattenedTrait: flattenTraitValues(t),
    }));

    const tree = unflattenToTree(declarationTree);
    const sortedTraits = flattenFromTree(tree)
        .map(unboxTreeNode)
        .map((i) => i.flattenedTrait);

    return (
        <>
            <Table size={'small'} compact={'very'} celled structured unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign={'center'} rowSpan={2} width={4}>
                            Category
                        </Table.HeaderCell>
                        <Table.HeaderCell rowSpan={1} colSpan={3} textAlign={'center'}>
                            Parameters
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell textAlign={'center'}>Name</Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'} width={4}>
                            Value
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>Misc.</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedTraits.map((ft, index) => {
                        return <TraitValueRows key={index} flattenedTrait={ft} />;
                    })}
                </Table.Body>
            </Table>
        </>
    );
};
