import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { useDndRow } from '../../../../../components/table-dnd-helpers';

export const JobTodoContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const JobTodoHeader = styled.div`
    margin: 1rem 0 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0.5rem;
    align-items: center;
`;

export const JobTodoHeaderLabel = styled.div`
    font-weight: bold;
    font-size: 1.4em;
    flex-grow: 1;
    flex-shrink: 0;
    padding-right: 2rem;
    margin-bottom: 1rem;
`;
export const JotTodoHeaderSummary = styled.div`
    flex-shrink: 0;
    flex-basis: 220px;
    font-size: 0.9em;
`;

export const JobTodoBody = styled.div`
    display: flex;
    flex-direction: column;
`;

const segment = () => `
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border: 1px solid #d2cfcd;
`;

const JobTodoRowDragHandle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 32px;
    cursor: grab;
    flex-shrink: 0;
    background-color: #dee5e8;
    &.nondraggable {
        cursor: unset;
    }
`;

export const JobTodoDetailItem = styled.div`
    display: flex;
    flex-direction: row;
`;

export const JobTodoDetailItemLabel = styled.div`
    flex-basis: 100px;
    font-weight: bold;
`;

export const JobTodoDetailItemValue = styled.div`
    flex-grow: 1;
`;

export const JobTodoRowContainer = styled.div`
    ${segment}

    display: flex;
    margin-bottom: 1rem;
    justify-content: stretch;
    align-items: stretch;

    border-radius: 4px;
    overflow-clip: true;

    font-size: 1rem;
    font-weight: 100;

    .drag-handle {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 32px;
        cursor: grab;
        flex-shrink: 0;
        background-color: #dee5e8;
        &.nondraggable {
            cursor: unset;
        }
    }

    .row-content {
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        & > .row-content-main {
            flex-grow: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            &.disable {
                opacity: 0.2;
            }

            &.linked {
                background-color: #fffaf5;
            }
        }

        .row-content-details {
            flex-grow: 1;
            flex-basis: 320px;
            flex-shrink: 1;
            //background-color: red;
            padding: 0.5rem;
        }

        .row-content-price {
            display: flex;
            flex-direction: column;
            padding: 0.5rem;
            flex-basis: 180px;
            flex-grow: 0;
            flex-shrink: 0;
            //background-color: lightskyblue;
        }

        & > .row-content-actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0.5rem;
            flex-basis: 40px;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: flex-start;
            align-content: start;
            //background-color: #f8fff8;
            & > * {
                margin-bottom: 0.5rem;
            }
        }
    }
`;

export const SummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 0.5em 0 1em 2em;

    > * {
        :not(:last-child) {
            margin-bottom: 0.5em !important;
        }
    }

    & .item-row {
        border-left: 2px solid rgba(1, 1, 1, 0);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 1.05em;
        padding: 0.5rem;

        & > .item-icon {
            flex: 0 0 2em;
        }
        & > .item-title {
            flex-grow: 1;
            padding-right: 0.5em;
        }
    }
`;

export const JobTodoRowDraggable: React.FC<any> = ({ index, moveRow, dropRow, children }) => {
    const { isDragging, dragRef, previewRef } = useDndRow({ index, moveRow, dropRow, type: 'JobTodoRow' });

    return (
        <JobTodoRowContainer
            ref={previewRef}
            style={{
                opacity: isDragging ? 0.1 : 1,
            }}
        >
            <JobTodoRowDragHandle ref={dragRef}>
                <Icon name={'ellipsis vertical'} color={'grey'} />
            </JobTodoRowDragHandle>
            <div className={'row-content'}>{children}</div>
        </JobTodoRowContainer>
    );
};
