import * as React from 'react';
import * as _ from 'lodash';
import { fromEdges, NodeType } from '@poolware/api';
import { Display, Panel } from '@poolware/components';

import styled from 'styled-components';

// View-Model for Contact rendering.
export class ContactItemVM {
    contact: NodeType.Contact;
    label: string;
    isPrimary: boolean;
    renderComp: any;

    constructor(value: { contact: NodeType.Contact; label: string; renderComp: React.ReactNode }) {
        this.contact = value.contact;
        this.label = value.label;
        this.renderComp = value.renderComp;
        this.isPrimary = Boolean(value.contact?.isPrimary);
    }
}

const ContactLabel = styled.i`
    padding-left: 0.5em;
    color: #656565;
    @media print {
        color: #333333;
    }
`;

export const lookupSpanFormatter = (contact: NodeType.Contact) => {
    if (!contact.type) return null;

    const { validatorTag = 'default' } = contact.type;
    const lookup = {
        PHONE: Display.Phone,
        EMAIL: Display.Email,
        default: Display.Span,
    };

    const displayFormatter = lookup[validatorTag] || lookup['default'];

    const display = (Tag) => () => (
        <>
            <Tag value={contact.data} />
            {contact.label && <ContactLabel>({contact.label})</ContactLabel>}
        </>
    );

    return display(displayFormatter);
};

export const createContactItemView = (contact: NodeType.Contact) => {
    const typeName = _.get(contact, 'type.name');
    return new ContactItemVM({
        label: typeName,
        contact: contact,
        renderComp: lookupSpanFormatter(contact),
    });
};

export const mapEntityContactsToContactItems = (entityContacts) => {
    return _.sortBy(entityContacts, (c) => _.get(c, 'type.name')).map(createContactItemView);
};

export const prepareCustomerContactItems = (contacts: NodeType.Contact[]): ContactItemVM[][] => {
    // Contact items extracted from "Customer" and "User" objects

    let groups = [];

    // Contact items extracted from "Entity" object
    const entityContacts = fromEdges(contacts);
    const group3 = mapEntityContactsToContactItems(entityContacts);

    if (group3.length > 0) {
        groups.push(group3);
    }
    return groups;
};

interface ContactItemsProps {
    as?: any;
    contactItems: ContactItemVM[][];
    [key: string]: any;
}

export const ContactItems: React.FC<ContactItemsProps> = ({
    as: ItemComponent = Panel.Item,
    contactItems,
    ...rest
}) => {
    if (!contactItems) return null;

    let lastIndex = contactItems.length - 1;
    return (
        <>
            {contactItems.map((contactItemGroup, groupIndex) => {
                return (
                    <React.Fragment key={groupIndex}>
                        {contactItemGroup.map((contactItemVM, index) => {
                            const { renderComp: RC, isPrimary } = contactItemVM;
                            return (
                                <ItemComponent
                                    key={index}
                                    {...rest}
                                    label={contactItemVM.label}
                                    labelInfoIcon={isPrimary && 'star'}
                                    labelInfo={isPrimary && 'Primary'}
                                >
                                    <RC />
                                </ItemComponent>
                            );
                        })}
                        {lastIndex !== groupIndex && <Panel.Divider />}
                    </React.Fragment>
                );
            })}
        </>
    );
};
