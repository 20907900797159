import * as React from 'react';
import { FormikDefaultForm, FormikInputField, toastError } from '@poolware/components';
import { useAppNavigator } from '@poolware/react-app-navigator';
import { useMutationStaffTeam } from '@poolware/api';

export interface FormNewProps {}

export const NewPageStaffTeam: React.FC<FormNewProps> = () => {
    const { AppNavigator } = useAppNavigator();

    const { create } = useMutationStaffTeam();

    const initialValues = {
        title: '',
    };

    const onCancel = () => {
        AppNavigator.replaceToOrigin('/teams', { relativeToModule: true });
    };

    const onSubmit = async (values: typeof initialValues) => {
        try {
            const res = await create({
                title: values.title?.trim() || '',
            });

            const id = res?.data?.StaffTeam?.StaffTeam?.id;
            AppNavigator.replace(`/teams/${id}`, { relativeToModule: true });
        } catch (e) {
            console.error(e);
            toastError(e);
        }
    };

    return (
        <FormikDefaultForm
            header={'New Team'}
            initialValues={initialValues}
            submitButton={{ content: 'Create' }}
            onSubmit={onSubmit}
            onCancel={onCancel}
        >
            <FormikInputField label={'Title'} name={'title'} />
        </FormikDefaultForm>
    );
};
