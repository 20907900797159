import * as React from 'react';
import { FormikFormDebug, VStack } from '@poolware/components';
import tw, { css, styled } from 'twin.macro';

export type LayoutConfig = {
    left: React.ReactNode[];
    middle: React.ReactNode[];
    right: React.ReactNode[];
};

const Col1 = styled.div(() => [
    tw`w-auto md:w-1/5`,
    css`
        min-width: 280px;
    `,
]);

const Col2 = styled.div(() => [
    tw`flex-auto overflow-y-auto`,
    css`
        min-width: 200px;
    `,
]);

const Col3 = styled.div(() => [
    tw`w-auto md:w-1/3`,
    css`
        min-width: 280px;
    `,
]);

export const CalcLayoutConfigurable: React.FC<{ config: LayoutConfig }> = ({ config }) => {
    return (
        <>
            <div tw="w-full flex flex-col md:flex-row gap-4" className={'ui small form'}>
                <Col1>
                    <VStack>
                        {config.left?.map((c, index) => (
                            <div key={index}>{c}</div>
                        ))}
                    </VStack>
                </Col1>
                <Col2>
                    <VStack>
                        {config.middle?.map((c, index) => (
                            <div key={index}>{c}</div>
                        ))}
                    </VStack>
                </Col2>
                <Col3>
                    <VStack>
                        {config.right?.map((c, index) => (
                            <div key={index}>{c}</div>
                        ))}
                    </VStack>
                </Col3>
            </div>
            <FormikFormDebug hidden={true} />
        </>
    );
};
