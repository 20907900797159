import { useAppNavigator } from '@poolware/react-app-navigator';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { WorkOrderDetailsPanelForm } from './WorkOrderDetailsPanelForm';
import { useQueryWorkOrder } from './use-query-work-order';
import { NotFoundPage, useViewer } from '@poolware/app-shell';
import { useWorkOrderMutators } from '../../queries/mutators-work-order';
import { WorkOrderAppointmentPanel } from './WorkOrderAppoinmentPanel';
import { WorkOrderCustomerAndLocationPanelForm } from './WorkOrderCustomerAndLocationPanelForm';
import {
    ConnectionErrorMessage,
    PageLayout,
    PageSkeletonLoader,
    Panel,
    SectionHeader,
    SlideInDock,
    VStack,
} from '@poolware/components';
import { PageHeaderToolbar } from './PageHeaderToolbar';
import { JobTodosListEditMode } from './JobTodosListEditMode';
import { JobTodosListViewMode } from './JobTodosListViewMode';
import { PageMenuBar } from './PageMenuBar';
import { NodeType } from '@poolware/api';
import { Divider } from 'semantic-ui-react';
import { WorkOrderStaffPanel } from './WorkOrderStaffPanel';
import { ModuleColorNames } from '../../constants';
import { WorkOrderNotesPanel } from './WorkOrderNotesPanel';
import { PanelFileAttachments } from './PanelFileAttachments/PanelFileAttachments';
import { WorkOrderWaterTestReportsPanel } from './WorkOrderWaterTestReportsPanel';

interface WorkOrderViewProps {
    id?: NodeType.ID;
    embeddedMode?: boolean;
}

export const WorkOrderView: React.FC<WorkOrderViewProps> = ({ id, embeddedMode = false }) => {
    const { params, query } = useAppNavigator();
    const workOrderId = params.woId || id;
    const [editList, setEditList] = useState(true);
    const isViewingFromAppt = !!query['return-appt'];

    const { modulesAccess } = useViewer();
    const canChangeStaff = !modulesAccess.FieldServices?.calendarSingleStaffMode;
    const canDeleteAppt = !modulesAccess.FieldServices.calendarSingleStaffMode;
    const canChangeJobDescription = !modulesAccess.FieldServices?.calendarSingleStaffMode;

    const {
        loading,
        error,
        refetchQuery,
        node: workOrder,
    } = useQueryWorkOrder(workOrderId, {
        fetchPolicy: 'cache-and-network',
    });

    const workOrderMutators = useWorkOrderMutators({
        refetchQueries: [refetchQuery],
        awaitRefetchQueries: true,
    });

    useEffect(() => {
        setEditList(false);
        // refetch({ id: workOrderId });
    }, [workOrderId]);

    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return <ConnectionErrorMessage error={error} />;
    } else if (!loading && !workOrder) {
        return <NotFoundPage />;
    }

    const onEditJobItems = () => {
        setEditList(true);
    };

    if (editList && false) {
        return (
            <SlideInDock onClose={() => setEditList(false)}>
                <JobTodosListEditMode
                    onDone={() => setEditList(false)}
                    workOrder={workOrder}
                    refetchQuery={refetchQuery}
                />
            </SlideInDock>
        );
    }

    return (
        <PageLayout>
            {!embeddedMode && <PageMenuBar workOrder={workOrder} />}

            <PageLayout.BodySection width={'screen-xl'}>
                <VStack>
                    <PageHeaderToolbar
                        workOrderMutators={workOrderMutators}
                        workOrder={workOrder}
                        embeddedMode={embeddedMode || isViewingFromAppt}
                    />
                    <PageLayout.TwoColumns mobileColReverse={true}>
                        <VStack>
                            <WorkOrderStaffPanel
                                workOrder={workOrder}
                                workOrderMutators={workOrderMutators}
                                readonly={!canChangeStaff}
                            />
                            <WorkOrderAppointmentPanel
                                canDelete={canDeleteAppt}
                                workOrder={workOrder}
                                refetchQuery={refetchQuery}
                            />
                        </VStack>
                        <VStack>
                            <WorkOrderCustomerAndLocationPanelForm
                                workOrder={workOrder}
                                workOrderMutators={workOrderMutators}
                                locked={!canChangeJobDescription}
                            />
                        </VStack>
                    </PageLayout.TwoColumns>
                </VStack>

                <Divider />
                <VStack space={2}>
                    <div>
                        <SectionHeader>Work Order - {workOrder.title}</SectionHeader>
                        <WorkOrderDetailsPanelForm
                            workOrder={workOrder}
                            workOrderMutators={workOrderMutators}
                            readonly={!canChangeJobDescription}
                        />
                    </div>
                    <div>
                        <SectionHeader>Job Tasks</SectionHeader>
                        <Panel>
                            <Panel.Header
                                color={ModuleColorNames.WorkOrderItem}
                                button={{
                                    content: 'Edit',
                                    icon: 'edit',
                                    autoHideContent: true,
                                    onClick: onEditJobItems,
                                }}
                            >
                                Job Tasks
                            </Panel.Header>
                            <Panel.Body>
                                <JobTodosListViewMode workOrder={workOrder} />
                            </Panel.Body>
                        </Panel>
                    </div>

                    {modulesAccess.FieldServices?.waterTestFromWorkOrder && (
                        <div>
                            <SectionHeader>Water Test</SectionHeader>
                            <WorkOrderWaterTestReportsPanel workOrder={workOrder} />
                        </div>
                    )}
                    <div>
                        <SectionHeader>Files</SectionHeader>
                        <PanelFileAttachments workOrder={workOrder} refetchQuery={refetchQuery} readOnly={false} />
                    </div>
                    <div>
                        <SectionHeader>Job Notes</SectionHeader>
                        <WorkOrderNotesPanel workOrder={workOrder} />
                    </div>
                </VStack>
            </PageLayout.BodySection>
            <div tw={'mb-16 w-full'} />
            <SlideInDock open={editList} preferredWidth={'800px'} onClose={() => setEditList(false)}>
                <JobTodosListEditMode
                    onDone={() => setEditList(false)}
                    workOrder={workOrder}
                    refetchQuery={refetchQuery}
                />
            </SlideInDock>
            <div tw={'pb-16'} />
        </PageLayout>
    );
};
