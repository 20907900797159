import * as Yup from 'yup';
import { FormBuilderConfig, FormFieldInputConfig, FormFieldPrimitiveType } from '../components/FormBuilder';
import SelectionTypeFormFieldComponent from './Declarations/SelectionTypeFormFieldComponent';
import { fromEdges, TraitValueType } from '@poolware/api';
import * as _ from 'lodash';

type MutationFieldName =
    | 'integerFields'
    | 'floatFields'
    | 'stringFields'
    | 'measuredFields'
    | 'dosageFields'
    | 'selectionFields'
    | 'flagFields';

export interface TraitDeclarationFieldFormConfig extends FormBuilderConfig {
    type: TraitValueType;
    name: string;
    // TODO: temporary location
    mutationFiledName: MutationFieldName;
    mapToFormValue?: (value: any) => any;
}

const createValidationSchema = (fields: FormFieldInputConfig[]) => {
    const shape = {};
    fields.map((f) => {
        let yup: any = Yup.mixed();
        switch (f.type) {
            case FormFieldPrimitiveType.Int:
                yup = Yup.number().integer();
                break;
            case FormFieldPrimitiveType.Float:
                yup = Yup.number();
                break;
            case FormFieldPrimitiveType.Boolean:
                yup = Yup.boolean();
                break;
            case FormFieldPrimitiveType.String:
                yup = Yup.string().min(0, 'Too Short!');
                break;
            case FormFieldPrimitiveType.Select:
                yup = Yup.array()
                    .of(
                        // Yup.object().shape({
                        //     name: Yup.string()
                        //         .min(4, 'too short')
                        //         .required('Required'), // these constraints take precedence
                        //     description: Yup.string().min(3, 'to short'),
                        // })
                        Yup.string().ensure().required('Required') // these constraints take precedence
                    )
                    .required('Must have options') // these constraints are shown if and only if inner constraints are satisfied
                    .min(2, 'Minimum of 2 options');
                break;
            case FormFieldPrimitiveType.ID:
                yup = Yup.string().min(0, 'Too Short!');
                break;
        }
        if (f.required) {
            yup = yup.required('Required');
        } else {
            yup = yup.nullable();
        }
        shape[f.key] = yup;
    });

    return Yup.object().shape(shape);
};

const createInitialValues = (fields: FormFieldInputConfig[]) => {
    // const shape = {};
    return fields.reduce((shape, f) => {
        if (!f.required) {
            return shape;
        }
        if (f.initialValue) {
            shape[f.key] = f.initialValue;
            return shape;
        }
        switch (f.type) {
            case FormFieldPrimitiveType.Int:
            case FormFieldPrimitiveType.Float:
                shape[f.key] = '';
                break;
            case FormFieldPrimitiveType.Boolean:
                shape[f.key] = false;
                break;
            case FormFieldPrimitiveType.String:
                shape[f.key] = '';
                break;
            case FormFieldPrimitiveType.ID:
                shape[f.key] = undefined;
                break;
        }
        return shape;
    }, {});
};

const defaultMapper = (v) => v;
export const createTraitDeclarationFieldFormConfig = (input: {
    name: string;
    type: TraitValueType;
    mutationFiledName: MutationFieldName;
    fields: FormFieldInputConfig[];
    mapToFormValue?: any;
}): TraitDeclarationFieldFormConfig => {
    return {
        name: input.name,
        type: input.type,
        mutationFiledName: input.mutationFiledName,
        fields: input.fields,
        initialValues: createInitialValues(input.fields),
        validationSchema: createValidationSchema(input.fields),
        mapToFormValue: input.mapToFormValue || defaultMapper,
    };
};

// TODO: Make it better,  add sorting flag or something
const baseFieldsG1 = [
    { type: FormFieldPrimitiveType.String, key: 'name', name: 'Name', required: true },
    { type: FormFieldPrimitiveType.String, key: 'tag', name: 'Tag', required: false },
];

const baseFieldsG2 = [
    // { type: FormFieldPrimitiveType.Boolean, key: 'optional', name: 'Optional', required: false },
    // { type: FormFieldPrimitiveType.Boolean, key: 'searchAsDistinct', name: 'Distinct', required: false },
    // { type: FormFieldPrimitiveType.Text, key: 'hint', name: 'Hint', required: false },
    // { type: FormFieldPrimitiveType.Int, key: 'searchOrder', name: 'Search Order', required: false },
    // { type: PrimitiveType.ID, key: 'group', name: 'Group', required: false },
];

export const TraitDeclarationIntegerFieldConfig: TraitDeclarationFieldFormConfig =
    createTraitDeclarationFieldFormConfig({
        name: 'Integer',
        type: TraitValueType.Int,
        mutationFiledName: 'integerFields',
        fields: [
            ...baseFieldsG1,
            { type: FormFieldPrimitiveType.Int, key: 'minValue', name: 'Min Value', required: false },
            { type: FormFieldPrimitiveType.Int, key: 'maxValue', name: 'Max Value', required: false },
            { type: FormFieldPrimitiveType.String, key: 'unit', name: 'Unit', required: false },
            ...baseFieldsG2,
        ],
    });

export const TraitDeclarationFlagFieldConfig: TraitDeclarationFieldFormConfig = createTraitDeclarationFieldFormConfig({
    name: 'Flag',
    type: TraitValueType.Int,
    mutationFiledName: 'flagFields',
    fields: [...baseFieldsG1, ...baseFieldsG2],
});

export const TraitDeclarationFloatFieldConfig: TraitDeclarationFieldFormConfig = createTraitDeclarationFieldFormConfig({
    name: 'Float',
    type: TraitValueType.Float,
    mutationFiledName: 'floatFields',
    fields: [
        ...baseFieldsG1,
        { type: FormFieldPrimitiveType.Float, key: 'minValue', name: 'Min Value', required: false },
        { type: FormFieldPrimitiveType.Float, key: 'maxValue', name: 'Max Value', required: false },
        { type: FormFieldPrimitiveType.String, key: 'unit', name: 'Unit', required: false },
        ...baseFieldsG2,
    ],
});

export const TraitDeclarationStringFieldConfig: TraitDeclarationFieldFormConfig = createTraitDeclarationFieldFormConfig(
    {
        name: 'String',
        type: TraitValueType.String,
        mutationFiledName: 'stringFields',
        fields: [
            ...baseFieldsG1,
            { type: FormFieldPrimitiveType.Int, key: 'maxLength', name: 'Max Length', required: false },
            { type: FormFieldPrimitiveType.Boolean, key: 'isHtml', name: 'Is Html', required: false },
            ...baseFieldsG2,
        ],
    }
);

const mapSelectionToFormValue = (val) => {
    const { options, ...rest } = val;
    return { ...rest, options: fromEdges(options) };
};

export const TraitDeclarationSelectionFieldConfig: TraitDeclarationFieldFormConfig =
    createTraitDeclarationFieldFormConfig({
        name: 'Selection',
        type: TraitValueType.Selection,
        mutationFiledName: 'selectionFields',
        fields: [
            ...baseFieldsG1,
            {
                type: FormFieldPrimitiveType.Select,
                key: 'options',
                name: 'Options',
                required: true,
                component: SelectionTypeFormFieldComponent,
                initialValue: [{ name: 'a' }, { name: 'b' }],
            },
            ...baseFieldsG2,
        ],
        mapToFormValue: mapSelectionToFormValue,
    });

export const availableTraitDeclarationFieldTypes = [
    TraitDeclarationIntegerFieldConfig,
    TraitDeclarationFloatFieldConfig,
    TraitDeclarationStringFieldConfig,
    TraitDeclarationSelectionFieldConfig,
    TraitDeclarationFlagFieldConfig,
];

export const sanitizeValues = (values, config) => {
    // TODO: move this somewhere else
    const sanitisedValues = config.fields.reduce((acc, cf) => {
        const value = values[cf.key];
        if (value === null || value === undefined) {
            return acc;
        }
        if (cf.type === FormFieldPrimitiveType.Int || cf.type === FormFieldPrimitiveType.Float) {
            const num = Number(value);
            if (!_.isFinite(num)) {
                delete acc[cf.key];
            } else {
                acc[cf.key] = num;
            }
        } else {
            acc[cf.key] = value;
        }
        return acc;
    }, {});

    return sanitisedValues;
};
