import { OperationVariables } from '@apollo/react-common';
import { DocumentNode } from 'graphql';
import { QueryHookOptions } from '@apollo/react-hooks';
import { NodeType } from '../api-types';
import { useQueryConnection } from './use-query-connection';
import { QueryConnectionConfigProps, QueryConnectionExternalProps } from './types';
import { useDefaultConnectionState } from './use-default-connection-state';

export function createUseQueryConnectionHook<
    TNodeType extends NodeType.AnyNode = NodeType.AnyNode,
    TQueryVarsSearch = any,
    TCustomSearchVars = any,
    TQueryVars = OperationVariables
>(defaultConf: {
    query: DocumentNode;
    connectionPath: string;
    connectionConfig?: Partial<QueryConnectionConfigProps<TQueryVarsSearch>>;
    mapPropsToSearchVariables?: (props: TCustomSearchVars) => TQueryVarsSearch;
}) {
    return (
        props?: QueryConnectionExternalProps<TNodeType, TQueryVarsSearch> & TCustomSearchVars,
        optionsWithoutVars?: Omit<QueryHookOptions<TNodeType, TQueryVars>, 'variables'>
    ) => {
        const { variables, connectionState } = useDefaultConnectionState<TQueryVarsSearch>({
            ...defaultConf.connectionConfig,
            search: defaultConf?.mapPropsToSearchVariables?.(props),
            ...props,
        });

        // @ts-ignore
        if (!!optionsWithoutVars?.variables) {
            console.error('optionsWithoutVars object has `variables` field');
            // Delete `variables` in case they passed by mistake
            // @ts-ignore
            delete optionsWithoutVars?.variables;
        }

        return useQueryConnection<TNodeType>({
            query: props?.query || defaultConf.query,
            fetchPolicy: 'cache-and-network',
            connectionPath: defaultConf.connectionPath,
            connectionConfig: connectionState,
            variables: variables,
            ...optionsWithoutVars,
        });
    };
}
