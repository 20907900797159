import * as React from 'react';
import { useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import { MessageForm } from './MessageForm';
import { DiscussionMessage } from './types';
import { toastError } from '@poolware/components';

interface NotesFeedItemProps {
    placeholder?: string;
    onSubmit: (values: Omit<DiscussionMessage, 'id'>) => any;
}

export const MessagesFeedNewItem: React.FC<NotesFeedItemProps> = (props) => {
    const [isEditing, setEditing] = useState(false);

    const { placeholder, onSubmit } = props;

    const startEdit = () => setEditing(true);

    if (!isEditing) {
        return (
            <>
                <Input
                    size={'small'}
                    onClick={startEdit}
                    onChange={startEdit}
                    fluid={true}
                    placeholder={'Send a message...'}
                    label={<Button basic={true} content={'Post'} onClick={startEdit} />}
                    labelPosition={'right'}
                />
            </>
        );
    }

    const handleOnSubmit = async (value: Omit<DiscussionMessage, 'id'>) => {
        try {
            await onSubmit(value);
            setEditing(false);
        } catch (e) {
            toastError(e);
        }
    };

    return (
        <MessageForm
            onSubmit={handleOnSubmit}
            submitButton={{ content: 'Post' }}
            onCancel={() => {
                setEditing(false);
            }}
            initialValues={{
                note: '',
            }}
            placeholder={placeholder}
        />
    );
};
