import { Icon } from 'semantic-ui-react';
import { NodeType } from '@poolware/api';
import * as React from 'react';
import { Display, Panel } from '@poolware/components';
import { mapServiceJobStageTypeToIconProps } from '../constants';

export type PanelStageHistoryType = NodeType.ServiceJobStageDuration[] | NodeType.WorkOrderStageDuration[];

export interface PanelStageHistoryProps {
    stageHistory: PanelStageHistoryType;
}

export const PanelStageHistory: React.FC<PanelStageHistoryProps> = ({ stageHistory }) => {
    const count = stageHistory.length;
    return (
        <Panel>
            <Panel.Header content={'Stage Change History'} />
            <Panel.Body>
                <div tw="w-full">
                    {stageHistory.reverse()?.map((s, index) => {
                        const isFirst = index === 0;
                        const isLast = index + 1 === count;
                        const isCurrentStage = index === 0;
                        const isOpenStage = !s.fromStage;
                        const activeIconStyle = isCurrentStage
                            ? {
                                  backgroundColor: '#b1ddb2',
                              }
                            : undefined;
                        return (
                            <div key={index} tw={'flex'}>
                                <div tw="flex flex-col items-center mr-4">
                                    <div>
                                        <div
                                            tw="flex items-center justify-center w-10 h-10 pl-1 border rounded-full bg-gray-50"
                                            style={activeIconStyle}
                                        >
                                            <Icon {...mapServiceJobStageTypeToIconProps(s.toStage?.type)} />
                                        </div>
                                    </div>

                                    {!isLast && <div tw="w-px h-full bg-gray-300" />}
                                </div>
                                <div tw="pb-8">
                                    <div
                                        tw="my-2 text-lg font-bold text-gray-600"
                                        style={{ textDecoration: isCurrentStage ? 'underline' : undefined }}
                                    >
                                        {s.toStage?.title} {isOpenStage && '(Open)'}
                                    </div>
                                    <div tw="text-gray-700">
                                        <Display.Date value={s.startedAt} />, by{' '}
                                        <b>
                                            <Display.Entity value={s.startedBy} />
                                        </b>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Panel.Body>
            {/*<DebugJSON data={stageHistory} />*/}
        </Panel>
    );
};
