import { NodeType } from '@poolware/api';
import _toString from 'lodash/toString';
import { FormikFieldOptionType } from '@poolware/components';
import { IconProps } from 'semantic-ui-react';

const _contractTermDatalist = [
    'Monthly', //
    'Annual',
    'Annual Upfront',
    'DLP',
];
const _contractFrequencyDatalist = [
    '1x per week', //
    '2x per week',
    '3x per week',
    '4x per week',
    '5x per week',
    'Fortnightly',
    'Monthly',
];

export const contractTermDatalist = _contractTermDatalist.map((c, index) => ({
    value: c,
    key: index,
    text: c,
}));

export const contractFrequencyDatalist = _contractFrequencyDatalist.map((c, index) => ({
    value: c,
    key: index,
    text: c,
}));

export const contractStatusOptions: FormikFieldOptionType[] = [
    {
        key: NodeType.CustomContractStatusEnum.Active,
        value: NodeType.CustomContractStatusEnum.Active,
        text: 'Active',
    },
    {
        key: NodeType.CustomContractStatusEnum.OnHold,
        value: NodeType.CustomContractStatusEnum.OnHold,
        text: 'On Hold',
    },
    {
        key: NodeType.CustomContractStatusEnum.Closed,
        value: NodeType.CustomContractStatusEnum.Closed,
        text: 'Close',
    },
];

export const mapContractStatusToName = (status: NodeType.CustomContractStatusEnum) => {
    switch (status) {
        case NodeType.CustomContractStatusEnum.Active:
            return 'Active';
        case NodeType.CustomContractStatusEnum.Closed:
            return 'Closed';
        case NodeType.CustomContractStatusEnum.OnHold:
            return 'On Hold';
        default:
            return _toString(status);
    }
};

export const mapContractStatusToIconProps = (status: NodeType.CustomContractStatusEnum): IconProps => {
    switch (status) {
        case NodeType.CustomContractStatusEnum.Active:
            return { name: 'circle', color: 'blue' };
        case NodeType.CustomContractStatusEnum.Closed:
            return { name: 'circle', color: 'grey' };
        case NodeType.CustomContractStatusEnum.OnHold:
            return { name: 'pause', color: 'orange' };
        default:
            return {};
    }
};
