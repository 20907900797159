import * as React from 'react';
import { NodeType, useQueryConnectionRoles } from '@poolware/api';
import { FormikSuggestInputProps, SuggestInput, withFormikSuggestField } from '@poolware/components';

export const SuggestInputRole: React.FC<FormikSuggestInputProps<NodeType.Role>> = ({ onChange, ...rest }) => {
    const { connectionState, connectionData } = useQueryConnectionRoles();

    const handleOnSearchChange = (value: string) => {
        // setAcSearchQuery(value);
    };

    const onItemSelect = (brand: NodeType.Role) => {
        onChange(brand);
    };

    const itemRenderer = (item: NodeType.Measurement): string => {
        return item?.name || '-';
    };

    return (
        <SuggestInput
            {...rest}
            items={connectionData}
            loading={connectionState.loading}
            onItemSelect={onItemSelect}
            onQueryChange={handleOnSearchChange}
            itemTextLabel={itemRenderer}
        />
    );
};

export const FormikSuggestInputRole = withFormikSuggestField(SuggestInputRole);
