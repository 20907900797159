import { FeatureFlag } from './feature-flags';
import { defaultModuleAccess, ModulesAccess } from './module-access-default';

// Iterates over root fields, and if `enable=false`, set all other subfields to `false`
const validateRootKeys = (ma: ModulesAccess) => {
    const validateSubKeys = (enabled: boolean, obj: any) => {
        return Object.keys(obj).reduce((acc, key) => {
            if (!enabled) {
                if (typeof acc[key] === 'boolean') {
                    // flip off only boolean type kes.
                    acc[key] = false;
                }
            }
            return acc;
        }, obj);
    };

    return Object.keys(ma).reduce((acc, key) => {
        acc[key] = validateSubKeys(ma[key].enabled, ma[key]);
        return acc;
    }, ma);
};

type FlagType = string | string[];

export const getModuleAccess = (getters: {
    canAccessFeature: (flag: string | string[]) => boolean | undefined;
    getFeatureFlag: (flag: FeatureFlag | string) => false | string | undefined;
}): ModulesAccess => {
    const { canAccessFeature } = getters;
    const modulesAccess: ModulesAccess = defaultModuleAccess;

    const applyFlag = <T extends Object = any>(object: T, conf: Partial<Record<keyof T, FlagType>>): T => {
        if (!conf) return object;
        Object.keys(conf).forEach((key) => {
            const flag = conf[key];
            const canAccess = canAccessFeature(flag);
            if (!(flag instanceof Array)) {
                // unwrap array if it only contains 1 element
                object[key + '_FLAG_KEY'] = [flag];
            } else {
                object[key + '_FLAG_KEY'] = flag;
            }

            // if (canAccess === undefined) {
            //     return;
            // }
            object[key] = !!canAccess;
        });
        return object;
    };

    // Super Admin
    applyFlag(modulesAccess.SuperAdmin, { enabled: FeatureFlag.ADMIN });

    // Franchise Admin
    applyFlag(modulesAccess.FranchiseAdmin, {
        enabled: [FeatureFlag.ADMIN, FeatureFlag.FRANCHISE_ADMIN],
        integrationConf: ['INTEGRATIONS_CONFIGURATION_ACCESS'],
    });

    // HQ Admin
    applyFlag(modulesAccess.HQAdmin, {
        enabled: ['FRANCHISE_MANAGEMENT_ACCESS', FeatureFlag.ADMIN],
    });

    // VEND
    applyFlag(modulesAccess.VendIntegration, {
        enabled: ['VEND'],
        admin: 'VEND/ADMIN',
        allowDelete: ['VEND/ALLOW_DELETE', FeatureFlag.ADMIN],
    });

    // Emails
    applyFlag(modulesAccess.Emails, {
        enabled: ['EMAILS'],
        admin: ['EMAILS/ADMIN', FeatureFlag.ADMIN],
    });

    // Calculators
    applyFlag(modulesAccess.Calculators, {
        enabled: 'CALCULATORS',
        heatpump: 'CALCULATORS/HEATPUMP',
        heatpumpAutopool: 'CALCULATORS/HEATPUMP_AUTOPOOL',
        gasheat: 'CALCULATORS/GASHEAT',
        saveRecommendations: 'CALCULATORS/HEAT_CALC_REC_SAVE',
        heatpumpZaneSwimartBrandingSelect: 'CALCULATORS/HEAT_PUMP_ZANE_SWIMART_BRAND_SELECT',
        heatpumpAquatightBrandingSelect: 'CALCULATORS/HEATPUMP_AQUATIGHT_BRAND_SELECT',
        admin: ['CALCULATORS/ADMIN', FeatureFlag.FRANCHISE_ADMIN, FeatureFlag.ADMIN],
    });

    // Product Catalog
    applyFlag(modulesAccess.ProductCatalog, {
        enabled: 'PRODUCT_CATALOG',
        admin: ['PRODUCT_CATALOG/ADMIN', FeatureFlag.ADMIN],
    });

    // Customer
    applyFlag(modulesAccess.Customers, {
        enabled: 'CUSTOMERS',
        nameTitle: 'CUSTOMERS/CUSTOMER_USE_NAME_TITLE',
        contracts: 'CUSTOMERS/CUSTOM_CONTRACT',
        cxofficer: 'CUSTOMERS/CX_OFFICER',
        registrationDate: 'CUSTOMERS/REG_DATE',
        forceContactsOnNewForm: 'CUSTOMERS/FORCE_CONTACTS_ON_NEW_FORM',
    });

    // Staff
    applyFlag(modulesAccess.Staff, {
        enabled: 'STAFF',
        teams: 'STAFF/TEAMS',
    });

    // Customer export
    applyFlag(modulesAccess.Export, {
        enabled: ['CUSTOMERS_EXPORT_ACCESS', 'EXPORT', FeatureFlag.ADMIN],
        singleFranchise: ['EXPORT/SINGLE_FRANCHISE', FeatureFlag.ADMIN],
        bulkExport: ['EXPORT/BULK', FeatureFlag.ADMIN],
        singleFranchiseImport: ['EXPORT/IMPORT_SINGLE_FRANCHISE'],
    });

    // Pools
    applyFlag(modulesAccess.Pools, {
        enabled: 'POOLS',
        siteOwnershipChange: 'POOLS/SITE_OWNERSHIP_CHANGE',
        barcodedBottles: 'POOLS/BOTTLE_BARCODES',
        poolList: 'POOLS/POOL_LIST',
        poolFlagFields: 'POOLS/POOL_FLAG_FIELDS',
    });

    //
    applyFlag(modulesAccess.WaterlinkIntegration, {
        enabled: 'WATERLINK_INTEGRATION',
        admin: ['WATERLINK_INTEGRATION', 'WATERLINK_INTEGRATION/ADMIN', FeatureFlag.FRANCHISE_ADMIN, FeatureFlag.ADMIN],
        mockAPI: ['WATERLINK_INTEGRATION/MOCK_API', FeatureFlag.ADMIN],
    });

    // Water Testing
    applyFlag(modulesAccess.WaterTest, {
        enabled: 'WATER_TESTING',
        admin: ['WATER_TESTING/ADMIN', FeatureFlag.ADMIN],
        waterlink: 'WATERLINK_INTEGRATION',
        sendViaEmail: ['WATER_TESTING/SEND_VIA_EMAIL'],
    });

    // Warranties
    applyFlag(modulesAccess.Warranties, {
        enabled: 'WARRANTY_CLAIMS',
        franchiseMsgInbox: ['WARRANTY_CLAIMS/FRANCHISE_MSG_INBOX'],
    });

    // Field Services
    applyFlag(modulesAccess.FieldServices, {
        enabled: 'FIELD_SERVICES',
        serviceJobs: 'FIELD_SERVICES/SERVICE_JOBS',
        recurringCases: 'FIELD_SERVICES/RECUR_CASES',
        recurringCasesShowPageLink: 'FIELD_SERVICES/RECUR_CASES_SHOW_PAGE_LINK',
        multiScheduledRecurringCases: 'FIELD_SERVICES/RECUR_CASES/MULTI_SCHEDULE',
        caseTemplates: 'FIELD_SERVICES/CASE_TEMPLATES',
        appointments: 'FIELD_SERVICES/APPOINTMENTS',
        recurringAppointment: 'FIELD_SERVICES/APPOINTMENTS',
        calendarWorkOrderDND: 'FIELD_SERVICES/CALENDAR/WO_DND',
        calendarSingleStaffMode: 'FIELD_SERVICES/CALENDAR/SINGLE_STAFF_MODE',
        calendarStreetsSummary: 'FIELD_SERVICES/CALENDAR/STREETS_SUMMARY',
        calendarDockMode: 'FIELD_SERVICES/CALENDAR/DOCK_MODE',
        calendarApptPreviewInDockMode: 'FIELD_SERVICES/CALENDAR/APPT_PREVIEW_DOCK_MODE',
        calendarDockModeScreenSizeXL: 'FIELD_SERVICES/CALENDAR/APPT_DOCK_MODE_FROM_SCREEN_XL',
        calendarAutoCreateWo: 'FIELD_SERVICES/CALENDAR/AUTO_CREATE_WORK_ORDERS',
        waterTestFromWorkOrder: 'FIELD_SERVICES/CALENDAR/WATER_TEST_FROM_WORKORDER',
        serviceJobTaskTemplates: 'FIELD_SERVICES/JOB_TASK_TEMPLATES',
        invoices: 'FIELD_SERVICES/INVOICES',
        printAppointmentCustomerNotes: 'FIELD_SERVICES/PRINT_APPOINTMENT_CUSTOMER_NOTES',
    });

    // requires water tests enabled.
    modulesAccess.FieldServices.waterTestFromWorkOrder =
        modulesAccess.WaterTest.enabled && modulesAccess.FieldServices.waterTestFromWorkOrder;

    // requires `caseTemplates` flag to be true
    modulesAccess.FieldServices.serviceJobTaskTemplates =
        modulesAccess.FieldServices.caseTemplates && modulesAccess.FieldServices.serviceJobTaskTemplates;

    modulesAccess.FieldServices.recurringCasesShowPageLink =
        modulesAccess.FieldServices.recurringCasesShowPageLink && modulesAccess.FieldServices.recurringCases;

    // Other
    applyFlag(modulesAccess.Other, { swimartTrainingForms: 'SWIMART_TRAINING_FORMS' });
    applyFlag(modulesAccess.SDS_LIST, { enabled: 'SDS_LIST' });
    applyFlag(modulesAccess.Downloads, { enabled: 'DOWNLOADS_ACCESS' });
    applyFlag(modulesAccess.System, {
        printAccess: ['PRINT_ACCESS', 'SYSTEM/PRINT_ACCESS'],
        fileUpload: ['FILE_UPLOAD_ACCESS', 'SYSTEM/FILE_UPLOAD_ACCESS'],
        emailReports: ['EMAIL_REPORT', 'SYSTEM/EMAIL_REPORT'],
    });

    // modulesAccess.FieldServices.invoices = false;

    /*
    modulesAccess.FieldServices.multiScheduledRecurringCases = true;
    modulesAccess.FieldServices.serviceJobTaskTemplates = true;
    modulesAccess.FieldServices.calendarDockMode = true;
    modulesAccess.FieldServices.caseTemplates = true;
    modulesAccess.FieldServices.recurringCases = true;
    // modulesAccess.FieldServices.calendarAutoCreateWo = true;
    modulesAccess.FieldServices.calendarApptPreviewInDockMode = true;
    modulesAccess.FieldServices.calendarDockModeScreenSizeXL = false;
    modulesAccess.FieldServices.waterTestFromWorkOrder = true;
    // modulesAccess.FieldServices.calendarSingleStaffMode = true;

    modulesAccess.Customers.contracts = true;

    modulesAccess.Calculators.heatpump = true;
    modulesAccess.Calculators.heatpumpAutopool = false;
    modulesAccess.Calculators.gasheat = true;
    modulesAccess.Calculators.saveRecommendations = true;
    modulesAccess.Calculators.heatpumpZaneSwimartBrandingSelect = true;
    modulesAccess.Pools.siteOwnershipChange = true;

    // modulesAccess.VendIntegration.allowDelete = true;

    modulesAccess.Staff.teams = true;
     */

    return validateRootKeys(modulesAccess);
};
