import * as React from 'react';
import { ActionBar, VStack } from '@poolware/components';
import { ClearAllFilters } from './ClearAllFilters';
import { FilterByCustomerCRN, FilterByServiceJobNumber, FilterByWorkOrderNumber } from './FilterByInputs';
import { FilterByServiceJobGroup } from './FilterByServiceJobGroup';
import { FilterByStaff } from './FilterByStaff';
// import { FilterByServiceJobStageType } from './FilterByStageType';

export interface SearchSideBarProps {}

export const FilterBar: React.FC<SearchSideBarProps> = () => {
    return (
        <VStack>
            {/*<FilterByServiceJobStageType />*/}
            <FilterByServiceJobGroup />
            <FilterByStaff />
            <ActionBar>
                <ActionBar.Header content={'Search'} icon={'filter'} />
                <FilterByServiceJobNumber label={'Service Case ID'} />
                <FilterByWorkOrderNumber label={'Work Order ID'} />
                <FilterByCustomerCRN label={'Customer CRN'} />
            </ActionBar>
            <ClearAllFilters />
        </VStack>
    );
};
