import * as React from 'react';
import { IAppNavigatorProps, useAppNavigator, withAppNavigator } from '@poolware/react-app-navigator';
import { MenuBar, MenuBarHeaderItem, MenuBarItem, MenuBarSection, StickyMenuBar } from '@poolware/components';
import { NodeType } from '@poolware/api';
import { ProductCatalogActionProps, useProductCatalogActions, withProductCatalogActions } from '../../../redux';

export interface PageMenuBarExternalProps {
    selectedItems: NodeType.Product[];
}

export interface PageMenuBarProps extends PageMenuBarExternalProps {
    showSidebar;
    setShowSidebar;
}

export const PageMenuBar: React.FC<PageMenuBarProps> = ({ selectedItems, setShowSidebar, showSidebar }) => {
    const { ProductCatalogState, ProductCatalogAction } = useProductCatalogActions();
    const clearAll = () => {
        ProductCatalogAction.removeAllFilterItems();
    };

    let leftMenuButtons = null;

    if (selectedItems.length > 0) {
        leftMenuButtons = <></>;
    } else if (ProductCatalogState.filters.hasActiveFilters) {
        leftMenuButtons = (
            <MenuBarItem title={`Clear All Filters`} color={'purple'} icon={'delete'} onClick={clearAll} />
        );
    } else {
        // leftMenuButtons = <MenuBarHeaderItem icon={'cubes'}>Product Catalog</MenuBarHeaderItem>;
    }

    const rightMenuButtons = <MenuBarHeaderItem icon={'cubes'}>Product Catalog</MenuBarHeaderItem>;

    return (
        <StickyMenuBar>
            <MenuBarSection position={'left'}>
                {!showSidebar && (
                    <MenuBar.Item onClick={() => setShowSidebar(!showSidebar)} icon={'filter'} color={'grey'} />
                )}
                {leftMenuButtons}
            </MenuBarSection>
            <MenuBarSection position={'right'}>{rightMenuButtons}</MenuBarSection>
        </StickyMenuBar>
    );
};
