import * as React from 'react';
import gql from 'graphql-tag';
import * as _ from 'lodash';

import {
    NodeType,
    QueryConnection,
    QueryConnectionExternalProps,
    TraitValueType,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';
import { productTraitFragment } from './fragments';

const connectionPath = 'viewer.products';
const QL = gql`
    query QueryProductsConnection(
        $first: Int
        $page: Int
        $search: ProductViewerProductsSearch
        $sort: ProductViewerProductsSort
        $searchTraits: ProductViewerProductsSearchTraits
    ) {
        viewer {
            products(first: $first, page: $page, search: $search, sort: $sort, searchTraits: $searchTraits) {
                pageMeta {
                    pageCount
                    totalCount
                }
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                }
                edges {
                    node {
                        id
                        traits {
                            id
                            ...ProductTraitFragment_Catalog
                        }
                        name
                        createdAt
                        updatedAt
                        sku
                        customSku
                        familyCode
                        supplier {
                            id
                            name
                        }
                        brand {
                            id
                            name
                            company {
                                id
                                name
                            }
                        }
                        organisationType {
                            id
                            name
                        }
                        franchise {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
    ${productTraitFragment}
`;

export interface QueryProductConnectionExternalProps extends QueryConnectionExternalProps<NodeType.Product> {
    name?: string;
    exactName?: string;
    sku?: string;
    customSku?: string;
    brandId?: NodeType.ID;
    supplierId?: NodeType.ID;
    productCompanyId?: NodeType.ID;
    declarationTag?: string;
    declarationIds?: NodeType.ID[];
    traits?: {
        op: 'and' | 'or';
        ids: NodeType.ID[];
    };
    traitsValues?: {
        op: 'and';
        values: {
            type: TraitValueType;
            missing?: boolean;
            missingFieldId?: string;
            isOr: NodeType.ID[];
        }[];
    };
    isOneOff?: boolean;
    organisationType?: NodeType.NodeOrId<NodeType.OrganisationType>;
}

function mapPropsToSearchQuery(props: QueryProductConnectionExternalProps) {
    const {
        brandId,
        name,
        sku,
        supplierId,
        customSku,
        declarationTag,
        productCompanyId,
        isOneOff,
        exactName,
        organisationType,
    } = props;
    let search = {};

    if (brandId) {
        _.set(search, 'brand.id', brandId);
    }
    if (productCompanyId) {
        _.set(search, 'brand.company.id', productCompanyId);
    }

    if (supplierId) {
        _.set(search, 'supplier.id', supplierId);
    }

    if (sku) {
        _.set(search, 'sku.like', sku);
    }

    if (customSku) {
        _.set(search, 'customSku.like', customSku);
    }

    if (name !== undefined && name !== '') {
        // _.set(search, 'name.like', name);
        _.set(search, 'relatedNames', name);
    }

    if (exactName !== undefined && exactName !== '') {
        _.set(search, 'name.like', name);
    }

    if (declarationTag) {
        _.set(search, 'declarations.tag.is', declarationTag);
    }
    if (isOneOff !== undefined) {
        _.set(search, 'isOneOff.is', isOneOff);
    }

    if (organisationType) {
        _.set(search, 'organisationType.id', NodeType.extractId(organisationType));
    }

    return search;
}

const nonulls = (items: any[]) => {
    if (!items) return [];
    items = items.filter(Boolean);
    if (items.length === 0) return undefined;
    else return items;
};

const mapPropsToSearchTraitsQuery = (props: QueryProductConnectionExternalProps) => {
    const { traits, traitsValues, declarationIds } = props;
    const search = {};

    if (declarationIds && declarationIds.length > 0) {
        // remove nulls
        const ids = declarationIds.filter((id) => !!id);
        _.set(search, 'traits.and', nonulls(ids));
    }

    if (traits && traits.ids && traits.ids.length > 0) {
        if (traits.op === 'and') {
            _.set(search, 'traits.and', nonulls(traits.ids));
        } else if (traits.op === 'or') {
            _.set(search, 'traits.or', nonulls(traits.ids));
        }
    }

    if (traitsValues && traitsValues.values && traitsValues.values.length > 0) {
        const andSelections = traitsValues.values
            .filter((tv) => tv.type === TraitValueType.Selection)
            .map((tv) => ({
                selection: {
                    id: tv.missingFieldId,
                    isOr: nonulls(tv.isOr),
                    missing: tv.missing,
                },
            }));

        _.set(search, 'traitsValues.and', andSelections);
    }

    return search;
};

export const QueryProductConnection: React.FC<QueryProductConnectionExternalProps> = (props) => {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.search = mapPropsToSearchQuery(props);
    variables.searchTraits = mapPropsToSearchTraitsQuery(props);
    variables.sort = {
        ...variables.sort,
    };

    return (
        <QueryConnection
            query={props.query || QL}
            variables={variables}
            connectionConfig={connectionState}
            connectionPath={connectionPath}
            children={props.children}
            fetchPolicy={props.fetchPolicy}
        />
    );
};

export function useQueryProductConnection(props?: Omit<QueryProductConnectionExternalProps, 'children'>) {
    const { variables, connectionState } = useDefaultConnectionState(props);
    variables.search = mapPropsToSearchQuery(props);
    variables.searchTraits = mapPropsToSearchTraitsQuery(props);
    variables.sort = {
        ...variables.sort,
    };
    return useQueryConnection<NodeType.Product>({
        query: props.query || QL,
        fetchPolicy: props.fetchPolicy,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
    });
}
