import { monthAndDates, tempConf } from '../data';
import { MonthProfile } from './types';

export const getCountryOptions = () => [
    { text: 'Australia', value: 'AUS' },
    { text: 'New Zealand', value: 'NZ' },
];

export const getCountryTextForValue = (value: string) => {
    return getCountryOptions().find((o) => o.value === value)?.text || value;
};

export const getStateOptions = (country) => {
    const countryConf = tempConf[country];
    if (!countryConf) return [];
    return {
        AUS: Object.keys(countryConf).map((key) => ({ text: key, value: key, data: countryConf[key] })),
        NZ: Object.keys(countryConf).map((key) => ({ text: key, value: key, data: countryConf[key] })),
    }[country];
};

export const getCityOptions = (country, state) => {
    const countryConf = tempConf[country];
    const stateConf = countryConf?.[state] || [];
    return Object.keys(stateConf).map((key) => ({ text: key, value: key }));
};

export const getYearProfile = (country, state, city): MonthProfile[] => {
    const cityProfile = tempConf[country]?.[state]?.[city];
    if (!cityProfile) return [];
    return cityProfile.map((temp, index) => {
        const p: MonthProfile = {
            id: `${monthAndDates[index].index}`,
            index: monthAndDates[index].index,
            name: monthAndDates[index].month,
            days: monthAndDates[index].Days,
            temp: cityProfile[index],
        };
        return p;
    });
};

export const getStateForLocation = (country: string, state: string, city: string) => {
    return {
        country: country,
        countryOptions: getCountryOptions(),
        state: state,
        stateOption: getStateOptions(country),
        city: city,
        cityOptions: getCityOptions(country, state),
    };
};
