export enum RESIZE_DIRECTION {
    UP = 'UP',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
}

export enum DND_ACTION {
    MOVE = 'move',
    RESIZE = 'resize',
    DRAGOVER = 'dragover',
}
