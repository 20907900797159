import gql from 'graphql-tag';
import {
    NodeType,
    QueryConnectionExternalProps,
    SortDirection,
    useDefaultConnectionState,
    useQueryConnection,
} from '@poolware/api';

const QL = gql`
    query QueryWarranterClaimMessagesConnection(
        $first: Int
        $page: Int
        $sort: WarrantyClaimMessageViewerWarranterClaimMessagesSort
        $search: WarrantyClaimMessageViewerWarranterClaimMessagesSearch
    ) {
        viewer {
            warranterClaimMessages(first: $first, page: $page, sort: $sort, search: $search) {
                edges {
                    node {
                        id
                        message
                        createdAt
                        updatedAt
                        addedBy {
                            id
                            root {
                                id
                                franchise {
                                    id
                                    name
                                }
                            }
                            user {
                                id
                                firstName
                                lastName
                            }
                        }
                        claim {
                            id
                            warrantyClaimNumber
                            franchise {
                                id
                                name
                            }
                        }
                    }
                }
                pageMeta {
                    totalCount
                    pageCount
                }
                pageInfo {
                    startCursor
                    hasPreviousPage
                    hasNextPage
                    endCursor
                }
            }
        }
    }
`;

export interface UseQueryWarrantyClaimsConnectionProps
    extends QueryConnectionExternalProps<NodeType.WarrantyClaimMessage> {}

function mapPropsToSearchQuery(
    props?: UseQueryWarrantyClaimsConnectionProps
): NodeType.WarrantyClaimViewerWarrantyClaimsSearch {
    if (!props) {
        return undefined;
    }

    return {};
}

export function useQueryWarrantyClaimMessages(props?: UseQueryWarrantyClaimsConnectionProps) {
    const connectionPath = 'viewer.warranterClaimMessages';
    const { variables, connectionState } = useDefaultConnectionState({
        defaultPageSize: 40,
        defaultSortKey: 'updatedAt',
        defaultSortDirection: SortDirection.DES,
        ...props,
    });
    variables.search = mapPropsToSearchQuery({ ...props });

    return useQueryConnection({
        query: QL,
        connectionPath: connectionPath,
        connectionConfig: connectionState,
        variables: variables,
        pollInterval: 60000, //ms
    });
}
