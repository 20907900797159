import { IMenuItem, IRouteConfig } from './types';
import { FeatureFlag } from '@poolware/app-shell';
import PageSystemConfig from './PageSystemConfig';
import { ModulesAccess } from './ModulesAccess';
import PageUI from './PageUI';
import { PageAddress } from './PageAddress';
import { PageWaterlink } from './PageWaterlink';
import { PageFeatureFlagsTest } from './PageFeatureFlagsTest';
import { PageSjTemplatePicker } from './PageSJTemplatePicker';

type RouteConfig = {
    flag?: string;
    route: IRouteConfig;
    menuItem?: IMenuItem;
};

export const routeConfig: RouteConfig[] = [
    {
        flag: FeatureFlag.ADMIN,
        route: {
            path: '/system-config',
            component: PageSystemConfig,
        },
        menuItem: {
            label: 'System Config',
        },
    },
    {
        menuItem: {
            label: 'Modules Access',
        },
        route: {
            path: '/modules-access',
            component: ModulesAccess,
        },
    },
    {
        menuItem: {
            label: 'UI',
        },
        route: {
            path: '/UI',
            component: PageUI,
        },
    },
    {
        menuItem: {
            label: 'Feature Flags',
        },
        route: {
            path: '/feature-flags',
            component: PageFeatureFlagsTest,
        },
    },
    {
        menuItem: {
            label: 'Waterlink',
        },
        route: {
            path: '/waterlink',
            component: PageWaterlink,
        },
    },
    {
        menuItem: {
            label: 'Address',
        },
        route: {
            path: '/address',
            component: PageAddress,
        },
    },
    {
        menuItem: {
            label: 'Template Picker',
        },
        route: {
            path: '/service-job-template-picker',
            component: PageSjTemplatePicker,
        },
    },
    // {
    //     menuItem: {
    //         label: 'Stripe',
    //     },
    //     route: {
    //         path: '/stripe',
    //         component: PageStripe,
    //     },
    // },
];
