import { useFormikContext } from 'formik';
import { getCityOptions, getCountryOptions, getStateOptions, getYearProfile } from './use-city-temperature-profile';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Form, Label, Segment } from 'semantic-ui-react';
import { enumToOptions } from './use-select-state';
import {
    FormikHeatCalcValueType,
    PoolCover,
    PoolLocation,
    PoolPosition,
    PoolShape,
    PoolTypes,
    WindProfile,
} from './types';
import { FormikInputField, FormikInputFieldProps, FormikSegmentedSelectButtons } from '@poolware/components';
import { PoolSizeCalc } from '../utils/pool-size-calcs';
import { usePersistedCalcValues } from '../combined-calculators/PersistedValuesContext';
import { useAppLayoutMode } from '@poolware/app-shell';

const countryOptions = getCountryOptions();

export const FormikSelectLocationYearProfile = () => {
    const { gteDesktopMD } = useAppLayoutMode();
    const isWide = gteDesktopMD;

    const { setFieldValue, values } = useFormikContext<FormikHeatCalcValueType>();
    const persistedValues = usePersistedCalcValues();

    const stateOptions = useMemo(() => getStateOptions(values.country), [values.country]);
    const cityOptions = useMemo(() => getCityOptions(values.country, values.state), [values.country, values.state]);

    useEffect(() => {
        persistedValues.setCountry(values.country);
        persistedValues.setState(values.state);
        persistedValues.setCity(values.city);
        const yearlyProfile = getYearProfile(values.country, values.state, values.city);
        setFieldValue('yearProfile', yearlyProfile);
    }, [values.city, values.state, values.country]);

    const onCountryChange = (_, { value: country }) => {
        const stateOptions = getStateOptions(country);
        const state = stateOptions[0]?.value;
        const cityOptions = getCityOptions(country, state);
        const city = cityOptions[0]?.value;
        setFieldValue('country', country);
        setFieldValue('state', state);
        setFieldValue('city', city);
    };

    const onStateChange = (_, { value: state }) => {
        const country = values.country;
        const cityOptions = getCityOptions(country, state);
        const city = cityOptions[0]?.value;
        setFieldValue('state', state);
        setFieldValue('city', city);
    };
    const onCityChange = (_, { value: city }) => {
        setFieldValue('city', city);
    };

    const Wrapper = isWide
        ? (props) => <Form.Group widths="equal" {...props} />
        : (props) => <React.Fragment {...props} />;

    return (
        <Segment color={'grey'}>
            <Wrapper>
                <Form.Select
                    fluid={true}
                    label={'Country'}
                    selectOnNavigation={false}
                    onChange={onCountryChange}
                    value={values.country}
                    options={countryOptions}
                />
                <Form.Select
                    fluid={true}
                    label={'State'}
                    selectOnNavigation={false}
                    options={stateOptions}
                    onChange={onStateChange}
                    value={values.state}
                />
            </Wrapper>
            <Form.Select
                fluid={true}
                label={'City'}
                selectOnNavigation={false}
                options={cityOptions}
                onChange={onCityChange}
                value={values.city}
            />
        </Segment>
    );
};

export const FormikInputFieldDeb: React.FC<FormikInputFieldProps> = (props) => (
    <FormikInputField fluid={true} type={'number'} debounceMs={400} step={0.5} min={0} {...props} />
);

const FormikSquarePoolInput: React.FC = () => {
    return (
        <>
            <FormikInputFieldDeb name={'poolLength'} label={'Pool Length'} subLabel={'m'} />
            <FormikInputFieldDeb step={0.1} name={'poolDepth'} label={'Pool Depth (Average)'} subLabel={'m'} />
        </>
    );
};

const FormikRoundPoolInput: React.FC = () => {
    return (
        <>
            <FormikInputFieldDeb name={'poolDiameter'} label={'Pool Diameter'} subLabel={'m'} />
            <FormikInputFieldDeb step={0.1} name={'poolDepth'} label={'Pool Depth (Average)'} subLabel={'m'} />
        </>
    );
};

const FormikRectPoolInput: React.FC = () => {
    const persistedValues = usePersistedCalcValues();

    return (
        <>
            <FormikInputFieldDeb
                name={'poolLength'}
                label={'Pool Length'}
                subLabel={'m'}
                onDidChange={persistedValues.setPoolLength}
            />
            <FormikInputFieldDeb name={'poolWidth'} label={'Pool Width'} subLabel={'m'} />
            <FormikInputFieldDeb step={0.1} name={'poolDepth'} label={'Pool Depth (Average)'} subLabel={'m'} />
        </>
    );
};

const FormikKidneyPoolInput: React.FC = () => {
    return (
        <>
            <FormikInputFieldDeb name={'poolLength'} label={'Pool Length'} subLabel={'m'} />
            <FormikInputFieldDeb name={'poolWidth1'} label={'Pool Width (A)'} subLabel={'m'} />
            <FormikInputFieldDeb name={'poolWidth2'} label={'Pool Width (B)'} subLabel={'m'} />
            <FormikInputFieldDeb step={0.1} name={'poolDepth'} label={'Pool Depth (Average)'} subLabel={'m'} />
        </>
    );
};

const getCompForPoolShape = (poolShape: PoolShape): React.FunctionComponent => {
    switch (poolShape) {
        case PoolShape.Square:
            return FormikSquarePoolInput;
        case PoolShape.Kidney:
            return FormikKidneyPoolInput;
        case PoolShape.Round:
            return FormikRoundPoolInput;
        case PoolShape.Rectangle:
        default:
            return FormikRectPoolInput;
    }
};
const getSizeCalcForPoolShape = (poolShape: PoolShape) => {
    switch (poolShape) {
        case PoolShape.Square:
            return PoolSizeCalc.square;
        case PoolShape.Kidney:
            return PoolSizeCalc.kidney;
        case PoolShape.Round:
            return PoolSizeCalc.round;
        case PoolShape.Rectangle:
        default:
            return PoolSizeCalc.rect;
    }
};

export const computePoolVolume = (values: FormikHeatCalcValueType) => {
    const compute = getSizeCalcForPoolShape(values.poolShape);
    const { volume, surfaceAreaTop } = compute({
        length: parseFloat(values.poolLength as any),
        width: parseFloat(values.poolWidth as any),
        width1: parseFloat(values.poolWidth1 as any),
        width2: parseFloat(values.poolWidth2 as any),
        depthDeepEnd: parseFloat(values.poolDepth as any),
        depthShallowEnd: parseFloat(values.poolDepth as any),
        diameter: parseFloat(values.poolDiameter as any),
    } as any);
    return { volume: volume, surfaceAreaTop };
};

export const FormikPoolSizeInputs = () => {
    const { values, setFieldValue } = useFormikContext<FormikHeatCalcValueType>();

    useEffect(() => {
        const { volume, surfaceAreaTop } = computePoolVolume(values);
        setFieldValue('poolVolume', volume || 0);
        setFieldValue('poolSurfaceAreaTop', surfaceAreaTop);
    }, [
        setFieldValue,
        values.poolShape,
        values.poolLength,
        values.poolDepth,
        values.poolWidth,
        values.poolWidth1,
        values.poolWidth2,
        values.poolDiameter,
    ]);

    const InputComp = useMemo(() => {
        return getCompForPoolShape(values.poolShape);
    }, [values.poolShape]);

    return (
        <>
            <FormikSegmentedSelectButtons name={'poolShape'} options={poolShapeOptions} label={'Pool Shape'} />
            <InputComp />
            <Form.Input
                readOnly={true}
                className={'readonly'}
                label={'Pool Volume'}
                value={values.poolVolume?.toFixed(1)}
                labelPosition={'right'}
            >
                <input />
                <Label>m3</Label>
            </Form.Input>
        </>
    );
};

const poolPositionOptions = enumToOptions(PoolPosition);
const poolTypeOptions = enumToOptions(PoolTypes);
const poolShapeOptions = enumToOptions(PoolShape);
const poolLocationOptions = enumToOptions(PoolLocation);
const poolCoverOption = enumToOptions(PoolCover);
const windProfileOptions = enumToOptions(WindProfile);

export const FormikPoolProfile: React.FC<{
    poolType?: boolean;
    poolPosition?: boolean;
    poolLocation?: boolean;
    poolCover?: boolean;
    windProfile?: boolean;
}> = ({ poolLocation = true, poolPosition = true, poolCover = true, poolType = true, windProfile = true }) => {
    const persistedValues = usePersistedCalcValues();

    return (
        <Segment color={'grey'}>
            {poolType && (
                <FormikSegmentedSelectButtons name={'poolType'} options={poolTypeOptions} label={'Pool Type'} />
            )}
            {poolPosition && (
                <FormikSegmentedSelectButtons
                    name={'poolPosition'}
                    options={poolPositionOptions}
                    label={'Pool Position'}
                />
            )}
            {poolLocation && (
                <FormikSegmentedSelectButtons
                    name={'poolLocation'}
                    options={poolLocationOptions}
                    label={'Pool Location'}
                />
            )}
            {poolCover && (
                <FormikSegmentedSelectButtons
                    name={'poolCover'}
                    options={poolCoverOption}
                    label={'With Cover'}
                    onDidChange={persistedValues.setPoolCover}
                />
            )}
            {windProfile && (
                <FormikSegmentedSelectButtons
                    name={'windProfile'}
                    options={windProfileOptions}
                    label={'Wind Profile'}
                />
            )}
            <FormikPoolSizeInputs />
        </Segment>
    );
};

export const FormikGasHeaterTempRequirements = () => {
    const persistedValues = usePersistedCalcValues();

    return (
        <Segment color={'grey'}>
            <FormikInputFieldDeb
                step={1}
                name={'poolTemp'}
                label={'Required Pool Temp'}
                subLabel={
                    <>
                        C<sup>o</sup>
                    </>
                }
            />{' '}
            <FormikInputFieldDeb
                step={0.5}
                max={24}
                min={0}
                name={'operatingHours'}
                label={'Operating hours'}
                subLabel={'hrs'}
                onDidChange={persistedValues.setOperatingHours}
            />
            <FormikInputFieldDeb
                step={0.01}
                name={'gasCost'}
                label={'Cost of Gas'}
                subLabel={'Cents per MJ'}
                // onDidChange={persistedValues.setGasCost}
            />
        </Segment>
    );
};

export const FormikHeatPumpTempRequirements: React.FC<{ operatingHours?: boolean }> = ({ operatingHours = true }) => {
    const persistedValues = usePersistedCalcValues();
    return (
        <Segment color={'grey'}>
            <FormikInputFieldDeb
                step={1}
                name={'poolTemp'}
                label={'Required Pool Temp'}
                subLabel={
                    <>
                        C<sup>o</sup>
                    </>
                }
            />
            {operatingHours && (
                <FormikInputFieldDeb
                    step={0.5}
                    max={24}
                    min={0}
                    name={'operatingHours'}
                    label={'Operating hours'}
                    subLabel={'hrs'}
                    onDidChange={persistedValues.setOperatingHours}
                />
            )}
            <FormikInputFieldDeb
                step={0.01}
                name={'electCost'}
                label={'Electricity Cost'}
                subLabel={'$/kw'}
                onDidChange={persistedValues.setElectCost}
            />
        </Segment>
    );
};
